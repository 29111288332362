import React, { FC } from 'react';
import { Box, Center, Flex } from '@chakra-ui/react';
import ReactPlayer from 'react-player';

import { ReactComponent as VideoIcon } from 'assets/images/training/empty-video.svg';

interface IProps {
  title?: string;
  caption?: string;
  videoUrl?: string;
}

const VideoPreviewContent: FC<IProps> = ({ title, caption, videoUrl }) => {
  if (videoUrl?.trim()) {
    return (
      <Flex
        flexDir='column'
        gap='16px'
        height='full'
        alignItems='initial'
        paddingX='12px'
        paddingTop='16px'
      >
        <Flex flexDir='column' gap='8px'>
          <Box fontSize='14px' fontWeight='500' color='#111315' paddingX='4px'>
            {title?.trim()}
          </Box>

          {caption ? (
            <Box
              fontSize='10px'
              fontWeight='500'
              color='#6F767E'
              paddingX='4px'
              whiteSpace='pre-line'
              noOfLines={6}
            >
              {caption}
            </Box>
          ) : null}
        </Flex>

        <ReactPlayer url={videoUrl} controls height='unset' width='100%' />
      </Flex>
    );
  }

  return (
    <Center flexDir='column' gap='32px' height='full'>
      <VideoIcon />

      <Box
        fontSize='15px'
        fontWeight='500'
        color='#6F767E'
        textAlign='center'
        maxWidth='148px'
      >
        Add a video to preview
      </Box>
    </Center>
  );
};

export default VideoPreviewContent;
