import { gql } from '@apollo/client';
import {
  QuizQuestionType,
  TAccessPermission,
  TAssignee,
  TAssigneeUserType,
  TDuration,
  TrainingContType,
  TRepeatCycle,
} from '../../../../types';

interface IQuizQuesOption {
  uid: string;
  text: string;
  thumbnail?: string;
  /**
   * This field available only in MULTI_CHOICE question
   */
  isCorrect?: boolean;
}

interface IQuizMatchSetOption {
  uid: string;
  left: string;
  leftThumbnail?: string;
  right: string;
  rightThumbnail?: string;
}

interface IQuizQuesBlank {
  uid: string;
  answer: string;
}

export interface IQuizQuestion {
  eid?: string;
  type: QuizQuestionType;
  label: string;
  thumbnail?: string;
  score: number;

  /**
   * @enum {QuizQuestionType.MULTI_CHOICE}
   */
  options: IQuizQuesOption[];

  /**
   * @enum {QuizQuestionType.TRUE_FALSE}
   */
  isTrue?: boolean;

  /**
   * @enum {QuizQuestionType.MATCH_SET}
   */
  pairs: IQuizMatchSetOption[];

  /**
   * @enum {QuizQuestionType.MATCH_SET}
   */
  sequence: IQuizQuesOption[];

  /**
   * @enum {QuizQuestionType.FILL_IN_BLANK}
   */
  textWithBlanks: string;
  blanks: IQuizQuesBlank[];

  hasMultipleCorrect?: boolean;
}

export interface ITrainingContent {
  type: TrainingContType;
  eid: string;
  title: string;
  thumbnail: string;
  addedBy: string;

  questions: IQuizQuestion[];
  hasScoring: boolean;
}

interface ISkillVerifier {
  name: string;
  skill: string;
}

interface IDependantTraining {
  hasMinScore: boolean;
  minScorePercent: number;
  trainingId: string;
}

export interface ISchedulePayload {
  isRepeating: boolean;
  repeatCycle: TRepeatCycle | null;
  repeatDetails: JSON;

  deadlineType: 'noDeadline' | 'date' | 'duration';
  deadlineDate: Date | null;
  duration: TDuration | null;

  assignmentType: 'now' | 'date' | 'training';
  startDate: Date | null;
  dependantTraining: IDependantTraining | null;
}

interface IAssignee {
  assigneeUserType: TAssigneeUserType;

  assignees: TAssignee[];

  supervisors: string[];
}

export interface CreateTrainingInput extends IAssignee, ISchedulePayload {
  eid?: string; // Used for updating training

  currentEditPage: string;
  title: string;
  description?: string;
  thumbnail: string;
  thumbnailColor: string;
  category: string;
  contents: ITrainingContent[];

  hasScoring: boolean;
  maxScore: number;

  hasSkillVerifier: boolean;
  skillVerifier?: ISkillVerifier;

  accessPermission: TAccessPermission;
}

export interface CreateVariable {
  input: CreateTrainingInput;
}

export interface TrainingResponse {
  CreateTP: {
    eid: string;
  };
  UpdateTPDraft: {
    eid: string;
  };
}

export const CREATE_TRAINING = gql`
  mutation CreateTP($input: AddTPInputInput) {
    CreateTP(input: $input) {
      eid
    }
  }
`;

export const UPDATE_TRAINING = gql`
  mutation UpdateTPDraft($input: UpdateTPDraftInputInput) {
    UpdateTPDraft(input: $input) {
      eid
    }
  }
`;

export interface TPublishResponse {
  StartTP: {
    eid: string;
  };
}

export const PUBLISH_TRAINING = gql`
  mutation StartTP($eid: ID!) {
    StartTP(eid: $eid) {
      eid
    }
  }
`;
