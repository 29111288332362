import React, { FC } from 'react';
import {
  Center,
  Flex,
  CenterProps,
  useControllableState,
} from '@chakra-ui/react';

import { ReactComponent as AiIcon } from 'assets/images/AI.svg';
import { ReactComponent as SearchIcon } from 'assets/images/searchIcon.svg';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';

const config: CenterProps = {
  flex: 1,
  minH: '41px',
  color: '#6F767E',
  cursor: 'pointer',
  borderRadius: 'full',
  userSelect: 'none',
  gap: 2,
};

const selectConfig = {
  bg: '#FCFCFC',
  color: '#1A1D1F',
  cursor: 'initial',
  boxShadow:
    '0px 4px 8px -4px #00000040, 0px 2px 0px 0px #FFFFFF40 inset, 0px -1px 1px 0px #0000000A inset',
};

interface IProps {
  value?: string;
  onChange?: (value: string) => void;
}

const SearchTypeSelect: FC<IProps> = ({ value, onChange }) => {
  const [state, updateState] = useControllableState({
    value: value,
    onChange: onChange,
    defaultValue: 'standard',
  });

  const eventFire = () => {
    deployEvent(AmplitudeEventNames.TAB_SEARCH_CLICK, {
      search_funnel_id: 3,
    });
  };

  return (
    <Flex
      bg='#F4F4F4'
      padding={1}
      borderRadius='full'
      fontSize='15px'
      fontWeight='600'
    >
      <Center
        {...config}
        _selected={{
          ...selectConfig,
        }}
        aria-selected={state === 'standard'}
        onClick={() => {
          eventFire();
          updateState('standard');
        }}
      >
        <SearchIcon />
        Standard
      </Center>

      <Center
        {...config}
        _selected={{
          ...selectConfig,
          bg: '#8E59FF',
          color: '#FFFFFF',
          svg: {
            color: '#FFFFFF',
          },
        }}
        aria-selected={state === 'AI'}
        onClick={() => {
          eventFire();
          updateState('AI');
        }}
      >
        <AiIcon />
        AI powered
      </Center>
    </Flex>
  );
};

export default SearchTypeSelect;
