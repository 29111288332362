import { Box, Flex, Text, Tooltip, Image, Badge } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowDownShortWide,
  faArrowDownToLine,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';
import { TrainingData } from 'sub-components/training-v2/dashboard/components/myTrainings/types/myTraining.types';
import CustomProgressBar from 'sub-components/training-v2/dashboard/components/TrackPathDashboard/components/CustomProgressBar';
import { getImage } from 'utils';

const getCellStyles = (record: TrainingData) => {
  if (record?.tpTerminatedStatus === 'TRAINING_TERMINATED') {
    return {
      cursor: 'not-allowed',
      opacity: '0.5',
    };
  } else {
    return {};
  }
};

export const colorSchemes = {
  overdue: {
    bg: '#FFBC9933',
    color: '#FF6A55',
  },
  yettostart: {
    bg: '#2A85FF1A',
    color: '#2A85FF',
  },
  inprogress: {
    bg: '#FFD88D1A',
    color: '#FECE70',
  },
  completed: {
    bg: '#83BF6E33',
    color: '#83BF6E',
  },
};

export const PathNameTitle = ({ setSortType, sortType }) => {
  return (
    <Flex alignItems={'center'} gap={2}>
      <Text fontWeight={500} color={'#6F767E'}>
        Path Name
      </Text>
      <Tooltip label={sortType?.tooltipLabel}>
        <Box
          onClick={() => {
            setSortType((prevState) => {
              if (prevState?.sort === 'CREATEDAT_DESC') {
                return {
                  sort: 'TITLE_ASC',
                  order: 'asc',
                  tooltipLabel: 'Sort Z-A',
                };
              } else if (prevState?.sort === 'TITLE_ASC') {
                return {
                  sort: 'TITLE_DESC',
                  order: 'desc',
                  tooltipLabel: 'No Sort',
                };
              } else if (prevState?.sort === 'TITLE_DESC') {
                return {
                  sort: 'CREATEDAT_DESC',
                  order: 'none',
                  tooltipLabel: 'Sort A-Z',
                };
              }
            });
          }}
        >
          <FontAwesomeIcon
            color={sortType?.order === 'none' ? '#6F767E' : '#2A85FF'}
            cursor={'pointer'}
            icon={faArrowDownShortWide as IconProp}
          />
        </Box>
      </Tooltip>
    </Flex>
  );
};

export const PathName = ({ value, row, categoriesColorCode }) => {
  return (
    <Flex style={getCellStyles(row)} align='center' gap={3}>
      <Image
        borderRadius='8px'
        height='62px'
        width='62px'
        src={getImage(row?.thumbnail, value)}
      />

      <Flex direction={'column'} gap={0}>
        <Tooltip label={value}>
          <Box
            as={'span'}
            isTruncated
            maxW='150px'
            fontSize='15px'
            fontWeight='600'
          >
            {value}
          </Box>
        </Tooltip>
        <Text fontWeight='500' fontSize='12px' color='#6F767E'>
          Last Updated: {row?.lastUpdated}
        </Text>
        {row?.categoryName && (
          <Tooltip label={row?.categoryName}>
            <Badge
              variant='subtle'
              borderRadius='5px'
              fontSize='12px'
              fontWeight={500}
              textTransform='capitalize'
              w='fit-content'
              display='flex'
              bg={categoriesColorCode?.[row?.categoryId]?.bg}
              color={categoriesColorCode?.[row?.categoryId]?.color}
              isTruncated
              maxW={'150px'}
            >
              {row?.categoryName}
            </Badge>
          </Tooltip>
        )}
      </Flex>
    </Flex>
  );
};

export const Progress = ({ value, row }) => {
  return (
    <Flex
      style={getCellStyles(row)}
      alignItems={'center'}
      gap={2}
      //   flexDir={mytarainingMode ? 'column' : 'row'}
    >
      <Flex
        minW={'70px'}
        borderRadius={'6px'}
        p={'2px 10px'}
        alignItems={'center'}
        backgroundColor={'#EFEFEF'}
        // flexDir={mytarainingMode ? 'column' : 'row'}
      >
        <Flex width={'100%'} justifyContent={'center'}>
          <Text fontWeight={600}>{row?.progress?.current}/</Text>
          <Text>{row?.progress?.total}</Text>
        </Flex>
      </Flex>
      <CustomProgressBar
        // width={mytarainingMode ? '65px' : '44px'}
        percent={(row?.progress?.current / row?.progress?.total) * 100}
      />
    </Flex>
  );
};

export const Status = ({ value, row, mytarainingMode }) => {
  const statusKey = value?.title.toLowerCase().replaceAll(' ', '');
  return (
    <>
      {statusKey ? (
        <Flex
          style={getCellStyles(row)}
          gap={2}
          alignItems={'center'}
          flexDir={mytarainingMode ? 'column' : 'row'}
        >
          <Badge
            borderRadius={'4px'}
            p={'4px 8px'}
            backgroundColor={colorSchemes[statusKey]?.bg}
            color={colorSchemes[statusKey]?.color}
            fontWeight={700}
            textTransform={'capitalize'}
            fontSize={'12px'}
          >
            {value?.title}
          </Badge>

          <Box fontWeight={500} fontSize={'12px'}>
            {value?.dueDate && value?.dueDate !== '-' ? (
              <>
                {mytarainingMode ? '' : <>&bull;</>} {value?.dueDate}
              </>
            ) : (
              '-'
            )}
          </Box>
        </Flex>
      ) : (
        '-'
      )}
    </>
  );
};

export const CreatedBy = ({ value, row }) => {
  return (
    <Text
      fontSize={'14px'}
      fontWeight={500}
      color={'#272B30'}
      style={getCellStyles(row)}
    >
      {row?.createdBy}
    </Text>
  );
};

export const CreatedOn = ({ value, row }) => {
  return (
    <Text
      fontSize={'14px'}
      fontWeight={500}
      color={'#272B30'}
      style={getCellStyles(row)}
    >
      {moment(row?.createdOn).format('D MMM')}
    </Text>
  );
};
