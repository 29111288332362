import { Tooltip } from '@chakra-ui/react';
import React from 'react';

const CustomizedAxisTick: React.FC<any> = ({ x, y, payload }) => {
  const truncatedText =
    payload?.value?.length > 9
      ? `${payload?.value?.substring(0, 5)}...`
      : payload?.value;

  return (
    <g transform={`translate(${x},${y + 5})`}>
      {/**The <g> (group) element in SVG is used to group multiple SVG elements together. */}
      <foreignObject x={-20} y={-10} width='50' height='20'>
        {' '}
        {/** lets you allow HTMl inside an svg */}
        <Tooltip label={payload?.value} hasArrow placement='bottom'>
          <div style={{ textAlign: 'center', fontSize: '12px', color: '#666' }}>
            {truncatedText}
          </div>
        </Tooltip>
      </foreignObject>
    </g>
  );
};

export default CustomizedAxisTick;
