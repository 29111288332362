import React, { FC, useMemo, useState } from 'react';
import { Box, Center, Flex, Image, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { Authorization, AuthRole } from 'authorization';
import { PrimaryButton } from 'atoms';
import { ChatGroupEntity } from 'types';

import { Column, SortingTable } from 'sub-components/ChakraTable/SortingTable';

import ChannelListAction from './ChannelListAction';
import ChannelListMembers from './ChannelListMembers';

import EmptyDataIcon from '../../../../assets/images/empty-state/task-chapter-empty-state.svg';
import ChannelNameCell from './ChannelNameCell';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { cloneDeep } from 'lodash';
import { faSort } from '@fortawesome/pro-solid-svg-icons';

const Wrapper = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
  margin-bottom: 3rem;

  table {
    .action-button {
      svg {
        path {
          fill: #6f767e;
        }
      }
    }
  }
  table > thead > tr > th:nth-of-type(3) {
    padding-inline: 10px !important;
  }

  table > tbody > tr > td:nth-of-type(3) {
    padding-inline: 10px !important;
  }

  table > tbody > tr > td:nth-of-type(2) {
    display: table-cell;
  }

  table > tbody > tr > td:last-child {
    display: table-caption;
    padding-top: 25px !important;
  }

  .sop-list-loader {
    min-height: 400px;
    height: unset;
  }
`;

interface IProps {
  channels?: ChatGroupEntity[];
  loading: boolean;
  onClickedHandler?: (
    action: 'edit' | 'delete' | 'viewMembers' | 'setting',
    data: ChatGroupEntity
  ) => void;
  onAddNewClick?: () => void;
}

const ChannelList: FC<IProps> = ({
  channels,
  loading,
  onClickedHandler,
  onAddNewClick,
}) => {
  const { t } = useTranslation(['common', 'setting', 'team']);
  const [page, setPage] = useState(1);
  const [sortType, setSortType] = useState('noSort'); //state to maintain the sortType

  let clonedChannels = cloneDeep(channels);
  //sorting based on the chat channel name
  const sortedChannels = useMemo(() => {
    if (sortType === 'asc') {
      return clonedChannels?.sort((a, b) => a.name.localeCompare(b.name));
    } else if (sortType === 'desc') {
      return clonedChannels?.sort((a, b) => b.name.localeCompare(a.name));
    } else if (sortType === 'noSort') {
      return channels;
    }
    return channels;
  }, [channels, sortType]);

  const columns = useMemo((): Column<ChatGroupEntity>[] => {
    return [
      // {
      //   Header: '',
      //   id: 'temp',
      //   accessor: 'guid',
      //   width: '20px',
      //   Cell: () => <Box width='12px' />,
      //   disableSortBy: true,
      // },
      {
        accessor: 'name',
        id: 'name',
        width: '32%',
        Header: () => (
          <Flex as='span' align={'center'} gap={2}>
            Channel
            <FontAwesomeIcon
              icon={faSort as IconProp}
              onClick={() => {
                setSortType((prev) => {
                  if (prev === 'noSort') {
                    return 'asc';
                  } else if (prev === 'asc') {
                    return 'desc';
                  } else if (prev === 'desc') {
                    return 'noSort';
                  }
                  return prev;
                });
              }}
            />
          </Flex>
        ),
        Cell: ({ cell: { value, row } }) => {
          return <ChannelNameCell channelName={value} channel={row.original} />;
        },
        disableSortBy: true,
      },
      {
        Header: t('team:members'),
        accessor: 'membersCount',
        width: 'calc(68% - 105px)',
        Cell: ({ cell: { row } }) => {
          return (
            <ChannelListMembers
              data={row?.original}
              onClick={() => onClickedHandler?.('viewMembers', row.original)}
            />
          );
        },
        disableSortBy: true,
      },
      {
        Header: t('common:action'),
        accessor: 'guid',
        width: '105px',
        disableSortBy: true,
        Cell: ({ cell: { row } }) => {
          return (
            <ChannelListAction
              channelData={row?.original}
              onClick={(action) => {
                // @ts-ignore
                document.activeElement?.blur();
                return onClickedHandler?.(action, row.original);
              }}
            />
          );
        },
      },
    ];
  }, [onClickedHandler]);

  const channelList = useMemo(() => {
    return sortedChannels?.slice((page - 1) * 10, page * 10);
  }, [sortedChannels, page]);

  return (
    <Wrapper>
      <SortingTable
        colorScheme='blue'
        showDivider
        isLoading={loading}
        page={page}
        columns={columns}
        data={channelList || []}
        isResponsive
        totalRegisters={channels?.length}
        onPageChange={setPage}
        emptyData={{
          content: (
            <Center flexDir='column' height='full' minH='50vh' gap={2}>
              <Image src={EmptyDataIcon} mb={4} />
              <Text as='b' fontWeight={700} fontSize='16px'>
                {t('setting:noChannelFound')}
              </Text>
              <Box
                maxW='230px'
                fontWeight={500}
                fontSize='14px'
                color='rgba(158, 158, 158, 1)'
                textAlign='center'
                mb={2}
              >
                {t('setting:noChannelFoundDesc')}
              </Box>
              <Authorization
                permittedFor='user'
                permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
              >
                <PrimaryButton
                  colorScheme='blue'
                  width='auto'
                  title={t('setting:createNewChannel')}
                  onClick={onAddNewClick}
                />
              </Authorization>
            </Center>
          ),
        }}
      />
    </Wrapper>
  );
};

export default ChannelList;
