import React, { FC } from 'react';
import { CloseButton, Flex } from '@chakra-ui/react';
import { SelectOption } from '../../../../../../../atoms';
import { AuthRole } from '../../../../../../../authorization';

interface IProps {
  values: SelectOption[];
  onRemoveClick?: (value: AuthRole) => void;
}

const SelectedAuthRoles: FC<IProps> = ({ values, onRemoveClick }) => {
  return (
    <>
      {values.map((value) => (
        <Flex
          key={value.value}
          gap='10px'
          align='center'
          justify='space-between'
          bg='#F4F4F4'
          paddingY='5px'
          paddingLeft='10px'
          paddingRight='6px'
          borderRadius='7px'
        >
          <span>{value.label}</span>
          {onRemoveClick ? (
            <CloseButton
              size='sm'
              onClick={(event) => {
                event.preventDefault();
                onRemoveClick?.(value.value as never);
              }}
            />
          ) : (
            <span />
          )}
        </Flex>
      ))}
    </>
  );
};

export default SelectedAuthRoles;
