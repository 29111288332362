import React, { FC } from 'react';
import { useWatch } from 'react-hook-form';
import { TFormInput } from '../create';
import TrainingActions from '../create/components/TrainingActions';
import PublishButton from './PublishButton';

interface IProps {}

const EditTrainingAction: FC<IProps> = () => {
  const status = useWatch<TFormInput, 'status'>({ name: 'status' });

  if (!status) {
    return null;
  }

  if (status === 'TRAINING_DRAFT') {
    return <TrainingActions />;
  }

  return <PublishButton />;
};

export default EditTrainingAction;
