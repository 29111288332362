import React, { ChangeEvent, FC, useMemo } from 'react';
import {
  Box,
  Checkbox,
  CheckboxGroup,
  Flex,
  PopoverBody,
  useControllableState,
  usePopoverContext,
} from '@chakra-ui/react';
import { useWatch } from 'react-hook-form';
import { Button, SearchInput } from '../../../../../../../atoms';
import { useSafeState } from '../../../../../../../hooks';
import { searchRegExp, toArray } from '../../../../../../../utils';
import { UserEntityData } from '../../../../../../../shared/graphql/shared-types';

import { ListScrollContainer, ListWrapper } from '../common';
import { MemberFilter } from './member-filter';
import { MFilterInput } from './member-filter/member-filter.types';
import MemberOption from './MemberOption';

interface IProps {
  members: UserEntityData[];
  value: string[];
  updateValue: (value: string[]) => void;
  index: number;
}

const MemberSelectContent: FC<IProps> = ({
  members,
  value,
  updateValue,
  index,
}) => {
  const { onClose } = usePopoverContext();
  const [internalValue, internalUpdate] = useControllableState<string[]>({
    defaultValue: value,
  });

  const [searchQuery, updateQuery] = useSafeState('');
  const [allChecked, setAllChecked] = useSafeState(false);

  const memberFilter = useWatch<
    MFilterInput,
    `assignees.${number}.memberFilter`
  >({
    name: `assignees.${index}.memberFilter`,
  });

  const filteredMembers = useMemo(() => {
    const roleValue = memberFilter?.byRole?.values || [];
    const locValues = memberFilter?.byLocation?.values || [];

    if (roleValue.length || locValues.length) {
      return toArray(members).filter((mem) => {
        return (
          roleValue.includes(mem.role) ||
          mem.locations?.some((loc) => locValues.includes(loc.eid))
        );
      });
    }
    return toArray(members);
  }, [members, memberFilter]);

  const memberList = useMemo(() => {
    if (!searchQuery?.trim()) {
      return filteredMembers;
    }

    const reg = searchRegExp(searchQuery, 'gi');

    return filteredMembers.filter((value) => {
      return value.name?.match(reg);
    });
  }, [filteredMembers, searchQuery]);

  const onSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
    const keys = memberList?.map((val) => val.eid);

    internalUpdate((prev = []) => {
      if (event.target.checked) {
        return [...new Set([...prev, ...keys])];
      } else {
        return prev.filter((p) => !keys.includes(p));
      }
    });
    setAllChecked(event.target.checked);
  };

  const [allItemChecked, isIndeterminate] = useMemo(() => {
    const all = memberList?.every((val) => internalValue?.includes(val.eid));
    const some = memberList?.some((val) => internalValue?.includes(val.eid));
    return [all && !!memberList?.length, some && !all];
  }, [memberList, internalValue]);

  return (
    <PopoverBody paddingInline='20px' paddingBlock='16px'>
      <Flex gap={3}>
        <SearchInput
          size='lg'
          placeholder='Search member'
          hideShortcuts
          value={searchQuery}
          onChange={(event) => updateQuery(event.target.value)}
        />

        <MemberFilter index={index} />
      </Flex>

      <ListScrollContainer>
        <Flex paddingInline='12px' paddingY={4}>
          <Box
            flex={1}
            fontSize='13px'
            fontWeight='600'
            color='#6F767E'
            textTransform='uppercase'
          >
            Select all
          </Box>
          <Checkbox
            value='all'
            size='lg'
            isChecked={allChecked && allItemChecked}
            isIndeterminate={allChecked && isIndeterminate}
            onChange={onSelectAll}
          />
        </Flex>

        <CheckboxGroup value={internalValue} onChange={internalUpdate as never}>
          <ListWrapper
            haveData={memberList?.length > 0}
            hevSearchQuery={!!searchQuery}
          >
            {memberList.map((item) => {
              return <MemberOption key={item.eid} item={item} />;
            })}
          </ListWrapper>
        </CheckboxGroup>
      </ListScrollContainer>

      <Button
        size='lg'
        width='full'
        colorScheme='blue'
        onClick={() => {
          setTimeout(onClose);
          updateValue?.(internalValue as string[]);
        }}
      >
        Select
      </Button>
    </PopoverBody>
  );
};

export default MemberSelectContent;
