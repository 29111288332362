import moment from 'moment-timezone';
import {
  AssignToType,
  EndCondition,
  IFormInput,
  RepeatDetails,
} from '../create-task/task.types';
import { TaskEditableResponse } from './task.graphql';
import { getTimeZone } from '../create-task/validation/helper';

type Response = TaskEditableResponse['TaskById'];

const getAssignToType = (value: Response): AssignToType[] => {
  const result: AssignToType[] = [];
  if (value.assignedToLocations.length) {
    result.push('location');
  }
  if (value.assignedRoles.length) {
    result.push('job');
  }
  if (value.assignedTo.length) {
    result.push('member');
  }

  return result;
};

const getDateValue = (value?: moment.MomentInput) => {
  return value ? moment(value).toDate() : undefined;
};

const extractEndCondition = (
  value: EndCondition<string>
): EndCondition<Date> => {
  return {
    endType: value.endType,
    repeatCount: value.repeatCount,
    endDate: getDateValue(value.endDate),
  };
};

const getStartDate = (data: Response) => {
  const [first, second] = [
    moment().utcOffset(),
    moment().tz(getTimeZone(data.timezone)).utcOffset(),
  ]
    .sort()
    .reverse();

  return moment
    .parseZone(data.startDate)
    .add(first - second, 'minute')
    .format('LLL');
};

const extractRepeatDetails = (data: Response): RepeatDetails<Date> => {
  const values = data.repeatDetails;

  const startDate = moment(data.startDate).format('LLL');

  const result: RepeatDetails<Date> = {
    taskRunFor: values.taskRunFor,
    taskDue: values.taskDue,

    taskDueDate: getDateValue(values.taskDueDate),
    dueTime: getDateValue(values.dueTime),

    // startDateType: values.startDateType,
    startDateType: 'date', // When editing now will not be there
    startDate: getDateValue(values.startDate || startDate),
    startTime: getDateValue(values.startTime || startDate),
  };
  if (values.taskRunFor === 'repeat') {
    Object.assign(result, {
      repeatMonthly:
        values.repeatMonthly === 'lastDay'
          ? 'lastDay'
          : getDateValue(values.repeatMonthly),
      repeatYearly: getDateValue(values.repeatYearly),

      noOfDays: values.noOfDays,
      weekDays: values.weekDays,

      repeatCycle: values.repeatCycle,
    });
  }

  // if (result.)

  return result;
};

export const toEditableTask = (
  values: TaskEditableResponse['TaskById']
): IFormInput => {
  return {
    title: values.title,
    description: values.description,
    currentStep: undefined,
    completed: {
      details: true,
      scheduler: true,
      taskSteps: true,
      assignees: true,
      supervisors: true,
    },

    lastSession: {
      isLastSession: values?.lastSession?.isLastSession,
    },

    // Schedule
    ...extractRepeatDetails(values),

    endCondition: extractEndCondition(values.endCondition),

    contents: values.taskContentExpanded?.map((value) => {
      return {
        eid: value.eid,
        tempEid: value.eid,
        type: value.type,
        title: value.title,
        thumbnail: value.thumbnail,
        isMandatory: value.isMandatory,
        dateAdded: moment(value.dateAdded).format('D MMMM'),
        formData: value.type === 'form' ? value : undefined,
        sopData: value.type === 'sop' ? value : undefined,
      };
    }),

    // assignee
    assignToType: getAssignToType(values),

    assignedLocationsType: values.assignedLocationsType,
    assignedToLocation: values.assignedToLocations.map((it) => it.eid),
    assignedToRole: values.assignedRoles.map((it) => it.name),
    assignedToUsers: values.assignedTo.map((it) => it.eid),

    supervisorType: 'all',
    supervisors: values.supervisors,

    status: values.status,
    repeatString: values.repeatString,
    isEditing: true,
  };
};
