import React, { FC, useEffect } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useUserDataSelector } from '../../../hooks';
import { BaseAiContent } from './BaseAiContent';
import SourceCard from './SourceCard';
import { AiResponse } from './ai-search.types';
import ResponseFooter from './ResponseFooter';
import Navigation from './Navigation';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';

interface IProps {
  data: AiResponse;
  onRegenerate?: () => void;
}

const SearchResultView: FC<IProps> = ({ data, onRegenerate }) => {
  const authRole = useUserDataSelector((state) => state.authRole);
  const source = data?.searchResult.find((res) => res.id === data.documentId);

  const convertGenerateTimeToNumber = (generateTime: string): number => {
    try {
      const match = generateTime.match(/^(\d+\.?\d*)([a-zA-Z]+)$/);

      if (!match || match.length < 3) {
        return 0;
      }

      const value = parseFloat(match[1]);
      const unit = match[2].toLowerCase();

      switch (unit) {
        case 's':
          return value;
        case 'm':
        case 'min':
          return value * 60;
        case 'h':
        case 'hr':
        case 'hour':
          return value * 3600;
        default:
          console.warn(`Unknown time unit: ${unit}, defaulting to seconds`);
          return value;
      }
    } catch (error) {
      console.error('Error converting generate time:', error);
      return 0;
    }
  };

  useEffect(() => {
    deployEvent(AmplitudeEventNames.AI_POWERED_SEARCH_RESPONSE_GENERATED, {
      search_funnel_id: 3,
      response_generate: true,
      time_taken_in_seconds: convertGenerateTimeToNumber(
        data?.generateTime || '0s'
      ),
    });
  }, [data]);

  return (
    <BaseAiContent flexDir='column' gap={3}>
      <Box
        fontSize='14px'
        fontWeight='500'
        color='#1A1D1F'
        whiteSpace='pre-line'
      >
        {data.response}
      </Box>

      <Flex align='center' justify='space-between' hidden={!source}>
        <Box fontSize='14px' fontWeight='500' color='#6F767E' hidden={!source}>
          Sources and citations:
        </Box>

        <Navigation />
      </Flex>

      <Flex overflowX='auto' gap={2} hidden={!source} id='sources-citations'>
        {source ? <SourceCard source={source} authRole={authRole} /> : null}

        {data?.searchResult?.map((res) => {
          if (res.id === data.documentId) {
            return null;
          }
          return <SourceCard key={res.id} source={res} authRole={authRole} />;
        })}
      </Flex>

      <ResponseFooter searchId={data.eid} onRegenerate={onRegenerate} />
    </BaseAiContent>
  );
};

export default SearchResultView;
