import React, { FC, useRef } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { IQuizQuestion } from '../../training.graphql';
import { QuizFormInput } from './quiz-resource.graphql';
import { AddQuizQuestion } from './quiz-form';
import { QuizQFormInput } from './quiz-form/quiz-form.types';
import QuizQuestion from './QuizQuestion';
import QuestionListSortable from './QuestionListSortable';
import QuestionDeletedAlert from './QuestionDeletedAlert';

interface IProps {}

const QuizQuestionList: FC<IProps> = () => {
  const { getValues, setValue } = useFormContext<QuizFormInput>();
  const { fields, append, remove, update, insert, move } = useFieldArray<
    QuizFormInput,
    'questions'
  >({
    name: 'questions',
  });

  const timeoutRef = useRef<unknown>();

  const resetIndex = (shouldSetAlso?: boolean) => {
    timeoutRef.current && clearTimeout(timeoutRef.current as never);

    if (shouldSetAlso) {
      timeoutRef.current = setTimeout(() => {
        setValue('savedIndex', undefined);
      }, 3000);
    } else {
      setValue('savedIndex', undefined);
    }
  };

  const length = fields?.length || 0;

  const onAddClick = (value: QuizQFormInput) => {
    setValue('savedIndex', fields.length);
    append(value);
    resetIndex(true);
  };

  const onUpdate = (index: number, data: IQuizQuestion) => {
    setValue('savedIndex', index);
    update(index, data);
    resetIndex(true);
  };

  const onRemoveClick = (index: number) => {
    resetIndex();
    setValue('deletedQuestion', {
      index: index,
      question: getValues(`questions.${index}`),
    });
    remove(index);
  };

  const onCopyClicked = (
    index: number,
    values: QuizQFormInput,
    newValues: QuizQFormInput
  ) => {
    resetIndex();
    insert(index, values);
    setValue('questionIndex', index + 1);
    update(index + 1, newValues);
  };

  const onUndoClick = (index: number, values: QuizQFormInput) => {
    insert(index, values);
    setValue('deletedQuestion', undefined);
  };

  return (
    <>
      <QuestionDeletedAlert onUndoClick={onUndoClick} />

      <QuestionListSortable
        items={fields}
        onDragEnd={(oldIndex, newIndex) => {
          resetIndex();
          move(oldIndex, newIndex);
        }}
      >
        {fields.map((field, index) => (
          <QuizQuestion
            fieldId={field.id}
            data={field}
            key={field.id}
            index={index}
            onUpdate={onUpdate}
            onRemoveClick={onRemoveClick}
            onCopyClick={onCopyClicked}
          />
        ))}
      </QuestionListSortable>
      <AddQuizQuestion
        totalQuestion={length}
        onAddQuestion={onAddClick}
        onCopy={(values) => append(values)}
      />
    </>
  );
};

export default QuizQuestionList;
