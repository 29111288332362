import React, { FC } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { Box, Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { CustomNumberInput } from '../../../common';
import { IRepeatInput } from './repeating.types';

interface IProps {}

const MonthDateSelect: FC<IProps> = () => {
  const type = useWatch<IRepeatInput, 'type'>({
    name: 'type',
  });

  if (!(type === 'EVERY_N_MONTHS' || type === 'EVERY_N_YEARS')) {
    return null;
  }

  return (
    <Flex gap={5} align='center'>
      <Box
        alignSelf='start'
        fontWeight='600'
        color='#6F767E'
        whiteSpace='nowrap'
        lineHeight='48px'
      >
        on
      </Box>

      <Controller<IRepeatInput, 'dayOfTheMonth'>
        name='dayOfTheMonth'
        defaultValue={1}
        rules={{
          required: 'Day of month si required',
          min: {
            value: 1,
            message: 'Please enter a valid day of the month (1-31).',
          },
          max: {
            value: 31,
            message: 'Please enter a valid day of the month (1-31).',
          },
        }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid}>
            <Flex gap={5} align='center'>
              <CustomNumberInput
                maxWidth='64px'
                size='lg'
                variant='auditOutline'
                textAlign='center'
                min={1}
                max={31}
                {...field}
                onChange={(_, newValue) => field.onChange(newValue)}
              />
              <Box fontWeight='600' color='#6F767E' whiteSpace='nowrap'>
                of the month
              </Box>
            </Flex>
            <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
          </FormControl>
        )}
      />
    </Flex>
  );
};

export default MonthDateSelect;
