import styled from '@emotion/styled';

export const MemberComponentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;

  .member-div {
    display: flex;
    align-items: center;
    padding: 8px 10px;
    border-radius: 7px;
    background-color: #e0f5fe;
    margin-right: 5px;
    width: fit-content;
  }

  .member-img {
    border-radius: 5px;
    height: 25px;
    width: 25px;
    min-width: 25px;
    margin-right: 10px;
  }

  .member-span {
    max-width: 140px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;
