import React, { FC } from 'react';
import { DashboardContainer } from 'sub-components';
import { TrainingPathPlayContainer } from 'sub-components/training-v2/play';

const TrainingPathPlay: FC = () => {
  return (
    <DashboardContainer>
      <TrainingPathPlayContainer />
    </DashboardContainer>
  );
};

TrainingPathPlay.displayName =
  'displayName:pages/TrainingV2/play/TrainingPathPlay';

export default TrainingPathPlay;
