import React, { FC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useToast } from '@chakra-ui/react';
import { cloneDeep } from '@apollo/client/utilities';
import { ActionButton } from '../../../ui-components';
import { useHistory } from '../../../routes';
import { useLatest, useUserDataSelector } from '../../../hooks';
import { AuthRole } from '../../../authorization';
import { AccessPermissionInput, TFormInput } from '../create';
import {
  useErrorHandler,
  useTrainingAccessRule,
  useTrainingCreator,
} from '../create/components';
import { useBeforePublish } from './before-publish';
import { useUpdateHandler } from './useUpdateHandler';

interface IProps {}

const PublishButton: FC<IProps> = () => {
  const history = useHistory();
  const {
    getValues,
    setValue,
    handleSubmit,
    formState: { isDirty },
  } = useFormContext<TFormInput>();
  const isReassigning = useWatch<TFormInput, 'isReassigning'>({
    name: 'isReassigning',
  });
  const toast = useToast({
    position: 'top-right',
    isClosable: true,
    duration: 3000,
  });

  const authUser = useUserDataSelector((state) => ({
    eid: state.eid,
    authRole: state.authRole,
  }));

  const creator = useTrainingCreator();
  const accessRule = useTrainingAccessRule();
  const updateHandler = useUpdateHandler();
  const beforePublish = useBeforePublish();

  const toastRef = useLatest(() => {
    return toast({
      status: 'success',
      title: 'Success',
      description: 'Training path successfully updated',
    });
  });

  const onPublishClick = async (permission?: AccessPermissionInput) => {
    if (permission) {
      setValue('accessPermission', permission);
      await Promise.resolve();
    }

    await updateHandler(getValues());
    toastRef.current();
    // history.goBack()
    setTimeout(() => history.goBack(), 1000); //TODO: change this manual timeout
  };

  const getRuleProps = () => {
    const values = getValues();
    return {
      initialValue: values.accessPermission,
      hasSkillVerifier: values.hasSkillVerifier,
      creator: creator,
      details: {
        assigneeUserType: values.assigneeUserType,
        assignBy: values.assignBy,
        assignees: values.assignees,
        userIds: values.userIds,
        locationIds: values.userIds,

        supervisors: values.supervisors,
      },
    };
  };

  const confirmBeforePublish = async (permission?: AccessPermissionInput) => {
    if (isReassigning) {
      if (permission) {
        await onPublishClick(permission);
      } else {
        accessRule({
          ...getRuleProps(),
          onPublishClick: async (permission) => {
            await onPublishClick(permission);
          },
        });
      }
    } else {
      const clonedData = cloneDeep(getValues());
      beforePublish({
        values: {
          ...clonedData,
          accessPermission: permission || clonedData?.accessPermission,
        },
        onPublishClick: () => onPublishClick(permission),
      });
    }
  };

  const actionHandler = async () => {
    if (
      authUser?.eid === getValues('createdBy') ||
      authUser?.authRole === AuthRole.SUPER_ADMIN
    ) {
      accessRule({
        ...getRuleProps(),
        onPublishClick: async (permission) => {
          await confirmBeforePublish(permission);
        },
      });
    } else {
      await confirmBeforePublish();
    }
  };

  const errorHandler = useErrorHandler();

  return (
    <ActionButton
      variant='solid'
      colorScheme='blue'
      actionFn={handleSubmit(actionHandler, errorHandler)}
      isDisabled={!isDirty && !isReassigning}
    >
      Publish Changes
    </ActionButton>
  );
};

export default PublishButton;
