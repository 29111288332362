import { Box, Flex, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { Cell, Pie, PieChart, Tooltip as RechartsTooltip } from 'recharts';
import { IAnalyticData } from '../useLauncherDashboardEventTrack';
import OverallStatusEmpty from './OverallStatusEmptystate';

interface IProps {
  piechartData: IAnalyticData[];
}

const OverallStatus: FC<IProps> = ({ piechartData }) => {
  const getPieData = (data: IAnalyticData) => {
    return [
      {
        name:
          data.key === 'overallStatus'
            ? 'No. of locations on time'
            : 'Locations gone live',
        value: Number(data.greenValue),
        var: 'onTime',
      },
      {
        name:
          data.key === 'overallStatus'
            ? 'No. of locations lagging'
            : 'Locations deleted in launcher',
        value: Number(data.orangeValue),
        var: 'lagging',
      },
    ];
  };

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <Box
          bg='#272B30'
          p={'8px'}
          opacity={0.5}
          borderRadius='8px'
          display={'flex'}
          flexDirection={'column'}
          width={'300px'}
        >
          <Text
            fontSize={'12px'}
            fontWeight={500}
            color={'#FCFCFC'}
            as={'span'}
          >
            {payload[0].name}
          </Text>
          <Text
            fontSize={'12px'}
            fontWeight={600}
            color={'#FCFCFC'}
            as={'span'}
          >
            {payload[0].value} {payload[0].value > 1 ? 'locations' : 'location'}
          </Text>
        </Box>
      );
    }
    return null;
  };

  if (piechartData?.length === 0) return <OverallStatusEmpty />;

  return (
    // @ts-ignore
    <Flex gap={'20px'}>
      {piechartData?.map((data) => (
        <Flex
          key={data.key}
          cursor='pointer'
          px={6}
          py={4}
          borderRadius='8px'
          bg='white'
          flexDir='column'
          w='100%'
          gap='16px'
          mb='20px'
          height={'113px'}
        >
          <Box
            display='flex'
            gap={'20px'}
            justifyContent={'space-between'}
            alignItems='center'
            width={'100%'}
          >
            <Flex direction={'column'}>
              <Text
                fontSize={'14px'}
                fontWeight={500}
                color={'#6F767E'}
                as={'span'}
              >
                {data.key === 'overallStatus' &&
                  'Locations currently in launcher'}
              </Text>
              <Text
                fontSize={'24px'}
                fontWeight={600}
                color={'#1A1D1F'}
                as={'span'}
              >
                {data.majorValue}
              </Text>
            </Flex>
            <Flex alignItems={'center'} gap={'14px'}>
              <Box w={'40px'} h={'40px'} bg={'#B5E4CA'} borderRadius={'2px'} />
              <Text
                fontSize={'24px'}
                fontWeight={600}
                color={'#33383F'}
                display={'flex'}
                flexDirection={'column'}
                as={'span'}
                mt={'2px'}
              >
                <Text
                  fontSize={'14px'}
                  fontWeight={500}
                  color={'#6F767E'}
                  as={'span'}
                  mt={'-2px'}
                >
                  {data.key === 'overallStatus'
                    ? 'No. of locations on time'
                    : 'Locations gone live'}
                </Text>
                {data.greenValue}
              </Text>
            </Flex>
            <Flex alignItems={'center'} gap={'14px'}>
              <Box
                w={'40px'}
                h={'40px'}
                bg={'#FF6A55CC'}
                borderRadius={'2px'}
              />
              <Text
                fontSize={'24px'}
                fontWeight={600}
                color={'#33383F'}
                display={'flex'}
                flexDirection={'column'}
                as={'span'}
                mt={'2px'}
              >
                <Text
                  fontSize={'14px'}
                  fontWeight={500}
                  color={'#6F767E'}
                  as={'span'}
                  mt={'-2px'}
                >
                  {data.key === 'overallStatus'
                    ? 'No. of locations lagging'
                    : 'Locations deleted in launcher'}
                </Text>
                {data.orangeValue}
              </Text>
            </Flex>

            <Box mr={'20px'}>
              <PieChart width={80} height={80}>
                <Pie
                  cursor={'pointer'}
                  data={getPieData(data)}
                  cx='50%'
                  cy='50%'
                  innerRadius={20}
                  outerRadius={40}
                  dataKey='value'
                  labelLine={false}
                >
                  {getPieData(data).map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={entry.var === 'onTime' ? '#B5E4CA' : '#FF6A55CC'}
                    />
                  ))}
                </Pie>
                <RechartsTooltip content={<CustomTooltip />} />
              </PieChart>
            </Box>
          </Box>
        </Flex>
      ))}
    </Flex>
  );
};

export default OverallStatus;
