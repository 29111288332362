import React, { FC } from 'react';
import { Box } from '@chakra-ui/react';
import { useWatch } from 'react-hook-form';

import { MobilePreviewContainer } from '../common';
import VideoPreviewContent from './VideoPreviewContent';

interface IProps {}

const VideoPreview: FC<IProps> = () => {
  const video = useWatch();

  return (
    <MobilePreviewContainer>
      <Box flex={1} overflow='hidden' paddingTop='32px'>
        <VideoPreviewContent
          title={video?.title}
          caption={video?.caption}
          videoUrl={video?.videoUrl}
        />
      </Box>
    </MobilePreviewContainer>
  );
};

export default React.memo(VideoPreview);
