import React, { FC } from 'react';
import { Center, Flex, Spacer } from '@chakra-ui/react';
import { DragHandleIcon } from '@chakra-ui/icons';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { BaseOptionContainer, QuizIconButton, QuizTextInput } from '../common';
import { ReactComponent as DeleteIcon } from 'assets/images/deleteBin.svg';
import { ReactComponent as TaskIcon } from 'assets/images/sidebar/task.svg';
import { QuizQFormInput } from '../quiz-form.types';
import { Controller, useController } from 'react-hook-form';
import { ThumbnailAddButton, ThumbnailView } from '../thumbnail-input';

interface IProps {
  optionIndex: number;
  totalLength: number;
  optionId: string;
  onRemoveClick?: () => void;
}

const MatchSetOption: FC<IProps> = ({
  optionIndex,
  totalLength,
  optionId,
  onRemoveClick,
}) => {
  const { field } = useController<
    QuizQFormInput,
    `pairs.${number}.leftThumbnail`
  >({
    name: `pairs.${optionIndex}.leftThumbnail`,
  });

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef,
  } = useSortable({ id: optionId, data: { index: optionIndex } });

  return (
    <BaseOptionContainer
      optionIndex={optionIndex}
      baseKey='pairs'
      fieldKeys={['left', 'right']}
      ref={setNodeRef}
      transform={CSS.Translate.toString(transform)}
      transition={transition}
    >
      <Flex
        flexDir='column'
        borderTopRadius='10px'
        borderBottom='2px solid #EFEFEF'
        height='122px'
      >
        <Flex
          justify='space-between'
          padding='6px'
          gap='6px'
          borderTopRadius='10px'
          align='center'
        >
          {field.value ? null : <ThumbnailAddButton {...field} />}

          <QuizIconButton
            aria-label='delete-image'
            borderRadius='4px'
            borderWidth='1px'
            icon={<DeleteIcon width={12} height={12} />}
            isDisabled={totalLength <= 2}
            onClick={onRemoveClick}
          />

          <Spacer />

          <Center {...attributes} {...listeners} ref={setActivatorNodeRef}>
            <DragHandleIcon boxSize='12px' color='#2A85FF' />
          </Center>
        </Flex>
        <Flex flex={1} padding='12px 16px' overflow='hidden' gap={1}>
          {field.value ? (
            <ThumbnailView
              boxSize={60}
              alignSelf='center'
              {...field}
              value={field.value}
            />
          ) : null}

          <Controller<QuizQFormInput, `pairs.${number}.left`>
            name={`pairs.${optionIndex}.left`}
            rules={{
              required: 'Prompt is required',
              validate: (value) => {
                if (!value?.trim()) {
                  return 'Enter valid right prompt';
                }
              },
            }}
            render={({ field }) => (
              <QuizTextInput placeholder='Type prompt here' {...field} />
            )}
          />
        </Flex>
      </Flex>

      <Flex
        height='122px'
        borderBottomRadius='10px'
        borderTop='2px solid #EFEFEF'
        padding='12px 16px'
        overflow='hidden'
      >
        <Controller<QuizQFormInput, `pairs.${number}.right`>
          name={`pairs.${optionIndex}.right`}
          rules={{
            required: 'Right response is required',
            validate: (value) => {
              if (!value?.trim()) {
                return 'Enter valid right response';
              }
            },
          }}
          render={({ field }) => (
            <QuizTextInput placeholder='Type right response here' {...field} />
          )}
        />
      </Flex>
    </BaseOptionContainer>
  );
};

export default MatchSetOption;
