import { useLazyQuery } from '@apollo/client';
import { cloneDeep } from '@apollo/client/utilities';
import { Center, Flex, Text } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IFormInput } from 'pages/Locations/AddLocation/add-location.types';
import AddLocationHeader from 'pages/Locations/AddLocation/AddLocationHeader';
import {
  Address,
  EntityDetails,
  OtherDetails,
} from 'pages/Locations/Components';
import BasicDetails from 'pages/Locations/Components/BasicDetails';
import { convertToFormData } from 'pages/Locations/EditLocation/data-transform';
import { LOCATION_DATA_QUERY } from 'pages/Locations/EditLocation/edit-location.graphql';
import UpdateLoginDetails from 'pages/Locations/EditLocation/UpdateLoginDetails';
import React, { FC, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { getTimezones } from 'shared/graphql/SharedGraphql';
import { useLocationLoginDetailModal } from 'sub-components/LocationLaunchDashboard/LocationLoginDetails/useLocationLoginDetail';
import Loader from 'ui-components/Loader';
import { LOCATIONS_PAGE } from '../../appRoutes';
import { Button } from '../../atoms';
import DashboardContainer from '../../sub-components/DashboardContainer';

const ReviewAddLocation: FC = () => {
  const history = useHistory();
  const updateLoginDetails = useLocationLoginDetailModal();
  const methods = useForm<IFormInput>();
  const params = useParams<{ locationId: string }>();

  const { execute: getTimezonesData, data: timezoneListData } = getTimezones();

  const [locationByIdHandler, { loading, data: locationByIdData }] =
    useLazyQuery(LOCATION_DATA_QUERY, {
      fetchPolicy: 'network-only',
    });

  useEffect(() => {
    locationByIdHandler({
      variables: {
        eid: params?.locationId,
      },
    });
    getTimezonesData();
  }, [params?.locationId]);

  useEffect(() => {
    if (!locationByIdData?.userById?.eid) return;

    const clonedData = cloneDeep(locationByIdData);
    clonedData.userById.locationStatus = 'open';
    const convertedData = convertToFormData(clonedData?.userById);

    methods.reset({
      ...convertedData,
      timezonesData: timezoneListData?.TimeZones,
    });
  }, [timezoneListData, locationByIdData, methods]);

  const onSubmit = (data: IFormInput) => {
    updateLoginDetails({
      type: 'preToOpen',
      locationName: data?.locationName,
      locationData: {
        isLive: false,
        launchId: '',
        locationDetails: data,
        locationId: data?.eid!,
        startDate: '',
        tasks: [],
        isLauncherDue: false,
      },
      shouldUpdateLocation: true,
      locationOwnersArray:
        data?.locationOwners
          ?.map((loc) => loc?.owner?.value)
          ?.filter(Boolean) || [],
      onClose: () => {},
      afterSuccess: () => history.push(`/locations/${data?.eid}`),
      afterError: () => history.push(LOCATIONS_PAGE),
    });
  };

  return (
    <DashboardContainer>
      {loading ? (
        <Center h='99vh'>
          <Loader />
        </Center>
      ) : (
        <FormProvider {...methods}>
          <Flex flexDir='column' gap={4} pb={5}>
            <Flex w='full' justify='space-between' align='center'>
              <AddLocationHeader title={'Review & add'} />
              <Button
                colorScheme='blue'
                variant='solid'
                onClick={methods.handleSubmit(onSubmit)}
              >
                Make it live
              </Button>
            </Flex>

            <BasicDetails />

            {/* <UpdateLoginDetails locationId={''} /> */}

            <Address />

            <EntityDetails />

            <OtherDetails />
          </Flex>
        </FormProvider>
      )}
      {/* <Flex justify='space-between' align='center' pt={4}>
                <Flex
                    gap={4}
                    cursor='pointer'
                    onClick={() => history.push(LOCATIONS_PAGE)}
                >
                    <FontAwesomeIcon icon={faArrowLeft as IconProp} size='2x' />
                    <Flex flexDir='column'>
                        <Text fontWeight={700} fontSize='24px'>
                            Review & add
                        </Text>
                        <Text fontSize='15px' color='rgba(111, 118, 126, 1)'>
                            Back to location
                        </Text>
                    </Flex>
                </Flex>
                <Button variant='solid' colorScheme='blue'>
                    Make it live
                </Button>
            </Flex> */}
    </DashboardContainer>
  );
};

ReviewAddLocation.displayName =
  'displayName:pages/ReviewAddLocation/ReviewAddLocation';

export default ReviewAddLocation;
