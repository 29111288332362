import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { Button } from '../../../../../../../atoms';
import { QuizQuestionType } from '../../../../../../../types';
import { FlexContainer } from '../../../common';
import { previewExample } from '../preview';

import { ReactComponent as MultiChoice } from '../../../../../../../assets/images/training/multi-choice.svg';
import { ReactComponent as TrueFalse } from '../../../../../../../assets/images/training/true-false.svg';
import { ReactComponent as MatchSet } from '../../../../../../../assets/images/training/match-set.svg';
import { ReactComponent as Reorder } from '../../../../../../../assets/images/training/reorder.svg';
import { ReactComponent as FillBlank } from '../../../../../../../assets/images/training/fill-blank.svg';

interface ItemProps {
  title: string;
  description: string;
  icon: typeof MultiChoice;
  onAddClick?: () => void;
  onMouseOver?: () => void;
}

const Item: FC<ItemProps> = ({
  icon,
  title,
  description,
  onAddClick,
  onMouseOver,
}) => {
  const IconEml = icon || Box;

  return (
    <Flex
      padding='12px 16px'
      gap={5}
      borderRadius='12px'
      userSelect='none'
      _hover={{
        bgColor: '#F4F4F4',
        color: '#2A85FF',
        '> svg': {
          color: '#2A85FF',
        },
        '.description': {
          opacity: 1,
        },
      }}
      onMouseEnter={onMouseOver}
    >
      <IconEml />

      <Flex flex={1} flexDir='column'>
        <Box fontSize='15px' fontWeight='600' color='#33383F' lineHeight='24px'>
          {title}
        </Box>
        <Box
          className='description'
          fontSize='12px'
          fontWeight='500'
          color='#6F767E'
          opacity={0}
        >
          {description}
        </Box>
      </Flex>

      <Button
        size='sm'
        width='94px'
        variant='outline'
        leftIcon={<AddIcon />}
        bgColor='#FCFCFC'
        fontSize='12px'
        fontWeight='500'
        onClick={onAddClick}
      >
        Add
      </Button>
    </Flex>
  );
};

interface IProps {
  index: number;
  onAddClick: (type: QuizQuestionType) => void;
}

const QuizTypeSelect: FC<IProps> = ({ index = 0, onAddClick }) => {
  const [showButton, update] = React.useState(true);

  if (index === 0 && showButton) {
    return (
      <Flex>
        <Button
          fontSize='13px'
          variant='outline'
          leftIcon={<AddIcon />}
          onClick={() => update(false)}
        >
          Add question
        </Button>
      </Flex>
    );
  }

  return (
    <FlexContainer
      flexDir='column'
      bg='#FCFCFC'
      borderColor='#6F767E80'
      p='20px 24px'
      gap={4}
    >
      <Flex flexDir='column' gap='2px'>
        <Box fontSize='14px' fontWeight='600' color='#272B30'>
          Question {index + 1}
        </Box>
        <Box fontSize='12px' color='#6F767E'>
          Choose the type of response you want
        </Box>
      </Flex>

      <Flex flexDir='column' gap={2}>
        <Item
          icon={MultiChoice}
          title='Multiple choice'
          description='Check for retention by asking participants to pick one or more correct answers.'
          onAddClick={() => onAddClick?.(QuizQuestionType.MULTI_CHOICE)}
          onMouseOver={() => previewExample(QuizQuestionType.MULTI_CHOICE)}
        />

        <Item
          icon={TrueFalse}
          title='True or False'
          description="Test participant's quick comprehension with straightforward true-or-false statements."
          onAddClick={() => onAddClick?.(QuizQuestionType.TRUE_FALSE)}
          onMouseOver={() => previewExample(QuizQuestionType.TRUE_FALSE)}
        />

        <Item
          icon={MatchSet}
          title='Match the set'
          description='Encourage critical thinking by matching related items in two lists.'
          onAddClick={() => onAddClick?.(QuizQuestionType.MATCH_SET)}
          onMouseOver={() => previewExample(QuizQuestionType.MATCH_SET)}
        />

        <Item
          icon={Reorder}
          title='Reorder'
          description='Challenge users to arrange items in the correct sequence.'
          onAddClick={() => onAddClick?.(QuizQuestionType.SEQUENCE)}
          onMouseOver={() => previewExample(QuizQuestionType.SEQUENCE)}
        />

        <Item
          icon={FillBlank}
          title='Fill in the blanks'
          description='Let users recall and type the correct word or phrase, perfect for testing detailed knowledge or key terms.'
          onAddClick={() => onAddClick?.(QuizQuestionType.FILL_IN_BLANK)}
          onMouseOver={() => previewExample(QuizQuestionType.FILL_IN_BLANK)}
        />
      </Flex>
    </FlexContainer>
  );
};

export default QuizTypeSelect;
