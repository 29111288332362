import React, { FC } from 'react';
import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import moment from 'moment';
import { PieChart, Pie, Cell } from 'recharts-new';
import { IOverallAnalytics } from 'sub-components/training-v2/shared/types';

interface IPieChartIdentifierProps {
  overallAnalyticsData: IOverallAnalytics;
}

export const PieChartIdentifier: FC<IPieChartIdentifierProps> = ({
  overallAnalyticsData,
}) => {
  const identifier = [
    {
      color: '#FF6A55',
      count: overallAnalyticsData?.uniqueOverdueAssignee,
      title: 'Overdue',
    },
    {
      color: '#FFBC99',
      count: overallAnalyticsData?.notStartedAssignee,
      title: 'Yet to start',
    },
    {
      color: '#FFD88D',
      count: overallAnalyticsData?.inProgressAssignee,
      title: 'In Progress',
    },
    {
      color: '#83BF6E',
      count: overallAnalyticsData?.completedAssignee,
      title: 'Completed',
    },
  ];

  return (
    <Flex gap='14px' flexDir='column' ml='-25px' mt='65px'>
      {identifier?.map((item) => (
        <Flex
          alignItems='center'
          borderRadius='8px'
          px='8px'
          backgroundColor='#F1F2FA'
          gap={2}
          key={item?.title}
          width='143px'
          height='32px'
        >
          <Box
            borderRadius='4px'
            width='12px'
            height='12px'
            backgroundColor={item?.color}
          />
          <Text fontWeight={700} fontSize='14px'>
            {item?.count}
          </Text>
          <Text fontWeight={400} fontSize='14px'>
            {item?.title}
          </Text>
        </Flex>
      ))}
    </Flex>
  );
};

interface IProps {
  overallAnalyticsData: IOverallAnalytics;
}

const ProgressTrackerGraph: FC<IProps> = ({ overallAnalyticsData }) => {
  const COLOR_MAP = {
    InProgress: '#FFD88D',
    Overdue: '#FF6A55',
    YetToStart: '#FFBC99',
    Completed: '#83BF6E',
    NoData: '#F4F4F4',
  };

  const locationOwnerGraphData =
    !overallAnalyticsData?.inProgressAssignee &&
    !overallAnalyticsData?.uniqueOverdueAssignee &&
    !overallAnalyticsData?.completedAssignee &&
    !overallAnalyticsData?.notStartedAssignee
      ? [{ name: 'NoData', value: 1 }]
      : [
          {
            name: 'InProgress',
            value: overallAnalyticsData?.inProgressAssignee || 0,
          },
          {
            name: 'Overdue',
            value: overallAnalyticsData?.uniqueOverdueAssignee || 0,
          },
          {
            name: 'YetToStart',
            value: overallAnalyticsData?.notStartedAssignee || 0,
          },
          {
            name: 'Completed',
            value: overallAnalyticsData?.completedAssignee || 0,
          },
        ];

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    name,
  }) => {
    // Don't show percentage label for the NoData segment
    if (name === 'NoData') return null;

    const RADIAN = Math.PI / 180;
    const offset = 25;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5 + offset;

    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <foreignObject
        x={x - 20}
        y={y - 20}
        width={40}
        height={40}
        style={{ overflow: 'visible' }}
      >
        {percent > 0 && (
          <Tooltip label={`${(percent * 100).toFixed(0)}%`}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#FFFFFF',
                borderRadius: '50%',
                width: '40px',
                height: '40px',
                boxShadow: '2px 2px 5px #B0B0B040',
              }}
            >
              <span
                style={{
                  fontWeight: 500,
                  color: 'black',
                  fontSize: '12px',
                }}
              >
                {`${(percent * 100).toFixed(0)}%`}
              </span>
            </div>
          </Tooltip>
        )}
      </foreignObject>
    );
  };

  const pieChart = () => {
    return (
      <PieChart width={300} height={300}>
        <text
          x={152}
          y={130}
          textAnchor='middle'
          dominantBaseline='middle'
          style={{
            fontSize: '10px',
            fontWeight: '400',
            fill: '#6F767E',
          }}
        >
          Active members
        </text>
        <text
          x={150}
          y={150}
          textAnchor='middle'
          dominantBaseline='middle'
          style={{
            fontSize: '18px',
            fontWeight: '600',
            fill: '#111315',
          }}
        >
          {overallAnalyticsData?.uniqueAssignees
            ? overallAnalyticsData?.uniqueAssignees
            : 0}
        </text>
        <Pie
          data={locationOwnerGraphData}
          cx={150}
          cy={140}
          innerRadius={60}
          outerRadius={90}
          fill='#8884d8'
          paddingAngle={0}
          dataKey='value'
          labelLine={false}
          label={renderCustomizedLabel}
        >
          {locationOwnerGraphData.map((entry, index) => (
            <Cell key={entry.name} fill={COLOR_MAP[entry.name]} />
          ))}
        </Pie>
      </PieChart>
    );
  };

  return (
    <Flex
      width='100%'
      height='100%'
      flexDir='column'
      borderRadius='12px'
      border='2px solid rgba(239, 239, 239, 1)'
    >
      <Box pl='20px' pt='24px' fontWeight={600} fontSize='20px'>
        Progress Tracker (Members)
      </Box>
      <Box
        pl='24px'
        color='rgba(111, 118, 126, 1)'
        fontWeight={500}
        fontSize='12px'
      >
        Date as of {moment().format('DD MMM YYYY')}
      </Box>
      <Flex alignItems='center' gap={4}>
        <Flex mt='25px'>
          {pieChart()}
          <PieChartIdentifier overallAnalyticsData={overallAnalyticsData} />
        </Flex>

        <Flex flexDir='column' alignItems='center' mt='30px'>
          <Text fontWeight={600} fontSize='32px'>
            {overallAnalyticsData?.completedAssignee
              ? (
                  (overallAnalyticsData?.completedAssignee /
                    overallAnalyticsData?.totalAssignee) *
                  100
                )?.toFixed(2)
              : 0}
            %
          </Text>
          <Text fontWeight={500} fontSize='14px' color='#6D6D6D'>
            Avg completion rate
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

ProgressTrackerGraph.displayName =
  'packages/sop-web/src/sub-components/training-v2/dashboard/components/locationOwner/ProgressTrackerGraph';
export default ProgressTrackerGraph;
