import React, { FC } from 'react';
import { Flex, Skeleton } from '@chakra-ui/react';
import EmptyState from '../../../../../sub-components/EmptyState';

const CategoryListSkeleton: FC = () => {
  return (
    <Flex flexDir='column' gap='10px' minH='200px'>
      {Array.from({ length: 5 }).map((_, i) => (
        <Flex gap={2} align='center' key={i} py={1}>
          <Skeleton boxSize='16px' borderRadius='2px' />
          <Skeleton height='14px' width='45%' />
        </Flex>
      ))}
    </Flex>
  );
};

const EmptyCategoryList: FC = () => {
  return (
    <EmptyState
      height='200px'
      imageSize='120px'
      image='Search'
      title='No filter found.'
      description='Oops! Try a different search to get results.'
    />
  );
};

interface IProps {
  isLoading?: boolean;
  searchQuery?: string;
  isEmpty?: boolean;
}

const CategoryListLoader: FC<IProps> = ({
  children,
  isLoading,
  searchQuery,
  isEmpty,
}) => {
  if (isLoading) {
    return <CategoryListSkeleton />;
  }

  if (searchQuery && isEmpty) {
    return <EmptyCategoryList />;
  }

  return <>{children}</>;
};

CategoryListLoader.displayName =
  'displayName:pages/forms/modules/FormListInternal/form-filter/CategoryListLoader';

export default CategoryListLoader;
