import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { MobilePreviewContainer } from '../../../resources';
import SkillVerifierInfoContainer from './SkillVerifierInfoContainer';
import SkillVerifierSamplePreview from './SkillVerifierSamplePreview';

interface IProps {}

const SkillVerifierInfoContent: FC<IProps> = () => {
  return (
    <Flex flex={1} flexDir='column' width='100%'>
      <Flex
        flex={1}
        gap='40px'
        marginX={7}
        // align='start'
        // maxHeight='calc(100% - 61px)'
        maxHeight='100%'
      >
        <SkillVerifierInfoContainer />

        <MobilePreviewContainer>
          <SkillVerifierSamplePreview />
        </MobilePreviewContainer>
      </Flex>
    </Flex>
  );
};

export default SkillVerifierInfoContent;
