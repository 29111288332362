import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useResourceModal } from '../common';
import FormResourceContent, { FResourceProps } from './FormResourceContent';

type FormResource = (props: FResourceProps) => void;

export const useFormResource = (): FormResource => {
  const { t } = useTranslation('form');

  const resourceModal = useResourceModal();

  return useCallback((props) => {
    resourceModal({
      title: t('forms'),
      content: (
        <FormResourceContent
          {...props}
          onCreateClick={() => window.open('/forms/create', '_blank')}
        />
      ),
    });
  }, []);
};
