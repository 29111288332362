import React from 'react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Box, Flex, forwardRef } from '@chakra-ui/react';
import { Button, ButtonProps } from '../../../../../../../atoms';
import { TAssLocationType } from '../../../../../../../types';
import { UserEntityData } from '../../../../../../../shared/graphql/shared-types';
import SelectedLocations, { ALLCheckType } from './SelectedLocations';

interface IProps extends ButtonProps {
  values?: UserEntityData[];
  onRemoveClick?: (value?: string, locationType?: TAssLocationType) => void;
  locationType?: TAssLocationType;
}

const LocationSelectButton = forwardRef<IProps, 'button'>(
  ({ values, disabled, onRemoveClick, locationType, ...props }, ref) => {
    const haveValue = values?.length || ALLCheckType.includes(locationType!);
    return (
      <Button
        ref={ref}
        as='div'
        size='lg'
        borderRadius='12px'
        rightIcon={<ChevronDownIcon boxSize='28px' color='#6F767E' />}
        justifyContent='space-between'
        alignItems='initial'
        paddingX='12px'
        paddingBlock='6px'
        variant='outline'
        bg='white'
        width='full'
        height='initial'
        minHeight={12}
        disabled={disabled}
        {...props}
      >
        <Flex gap={2} align='center' flexWrap='wrap'>
          {haveValue ? (
            <SelectedLocations
              locationType={locationType}
              values={values || []}
              onRemoveClick={onRemoveClick}
            />
          ) : (
            <Box fontSize='14px' fontWeight='600' opacity='0.65'>
              Select location(s)
            </Box>
          )}
        </Flex>
      </Button>
    );
  }
);

export default LocationSelectButton;
