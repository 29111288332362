import React, { FC, useMemo } from 'react';
import { Box } from '@chakra-ui/react';
import { useWatch } from 'react-hook-form';
import { TrainingContType } from '../../../../../../../types';
import { TFormInput } from '../../../training-input.types';

import {
  FormPreview,
  MilestonePreview,
  MobilePreviewContainer,
  PreviewEmpty,
  SopPreview,
} from '../../../resources';

import VideoPreview from './VideoPreview';
import TrainingQuizPreview from './TrainingQuizPreview';

interface IProps {}

const TrainingContentPreview: FC<IProps> = () => {
  const preview = useWatch<TFormInput, 'preview'>({
    name: 'preview',
  });

  const component = useMemo(() => {
    switch (preview?.type) {
      case TrainingContType.MILESTONE:
        return (
          <MilestonePreview
            title={preview.title}
            thumbnail={preview.thumbnail}
          />
        );
      case TrainingContType.CARD:
        return <VideoPreview cardId={preview.eid} />;
      case TrainingContType.FORM:
        return <FormPreview formId={preview.eid} />;
      case TrainingContType.SOP:
        return <SopPreview sopId={preview.eid} />;
      case TrainingContType.QUIZ:
        return <TrainingQuizPreview quizData={preview} />;
      default:
        return (
          <Box flex={1}>
            <PreviewEmpty
              emptyTitle='No item to preview'
              emptyCaption='Click on any item from the list on the left to preview.'
            />
          </Box>
        );
    }
  }, [preview]);

  const isForm = preview?.type === TrainingContType.FORM;

  return (
    <MobilePreviewContainer headerBg={isForm ? 'blackAlpha.200' : undefined}>
      {component}
    </MobilePreviewContainer>
  );
};

export default TrainingContentPreview;
