import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { QuizIconButton } from '../common';
import { ReactComponent as TaskIcon } from 'assets/images/sidebar/task.svg';

interface IProps {
  label: string;
  isCorrect?: boolean;
  isInvalid?: boolean;
  onCheckClick?: () => void;
}

const TrueFalseOption: FC<IProps> = ({
  label,
  isCorrect,
  isInvalid,
  onCheckClick,
}) => {
  return (
    <Flex
      flex={1}
      flexDir='column'
      borderRadius='12px'
      border='2px solid'
      borderColor='#EFEFEF'
      height='80px'
      aria-invalid={isInvalid}
      _invalid={{
        borderColor: 'red.300',
        boxShadow: '0 0 0 1px #FF6A55',
      }}
    >
      <Flex flex={1} justify='end' padding='6px' borderTopRadius='10px'>
        <QuizIconButton
          aria-label='check-action'
          borderRadius='full'
          variant='ghost'
          aria-checked={isCorrect}
          _checked={{ color: '#83BF6E' }}
          icon={<TaskIcon width={16} height={16} color='inherit' />}
          onClick={onCheckClick}
        />
      </Flex>
      <Box
        flex={1}
        fontSize='12px'
        fontWeight='500'
        textAlign='center'
        color='#6F767E'
        aria-checked={isCorrect}
        _checked={{ color: '#83BF6E', fontWeight: '600' }}
      >
        {label}
      </Box>
      <Flex flex={1} />
    </Flex>
  );
};

export default TrueFalseOption;
