import { useReactiveVar } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import { AuthRole } from 'authorization';
import { useUserDataSelector, useUserEntity } from 'hooks';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';
import { usersEntityObj } from 'sub-components/Header';
import { useService } from 'sub-components/nexus/NexusBase/layers';
import { LeaderboardResponse } from 'sub-components/nexus/NexusBase/types';
import generateExcelNew from '../../../utils/generateExcelNew';
import {
  ExportData,
  LocationsRating,
  Metrics,
  RangeValue,
  SelectOption,
} from '../types';
import {
  filteredDataFn,
  getDateRange,
  processExportData,
  rangeOptions,
  selectedColumnsFn,
} from '../utils';

export const useControl = (
  customerFeedback: LocationsRating[],
  onClose: () => void
) => {
  const toast = useToast({
    duration: 3000,
    position: 'top-right',
    isClosable: true,
  });
  const { t } = useTranslation(['common']);
  const [metrics, setMetrics] = useState<Metrics>({
    taskCompletion: true,
    attrition: true,
    age: true,
    customerFeedback: true,
    auditScore: true,
  });
  const [selectedDataRange, setSelectedDataRange] = useState<SelectOption>(
    rangeOptions[0]
  );
  const entityData = useReactiveVar(usersEntityObj);
  const { loggedInUserAuthRole, loggedInUserLocations } = useUserDataSelector(
    (state) => ({
      loggedInUserAuthRole: state?.authRole,
      loggedInUserLocations: state?.locations,
    })
  );
  const { businessLocations: initBusinessLocations } = useUserEntity(
    (entity) => ({ businessLocations: entity?.locations })
  );

  const onLeaderboardSuccess = (data: LeaderboardResponse) => {
    exportDataProcess(data);
  };

  const onLeaderboardError = () => {
    toast({
      status: 'error',
      title: t('common:error'),
      description: t('common:something_wrong'),
    });
  };

  const {
    leaderboardService: { leaderboardData, leaderboardRes },
  } = useService({
    onLeaderboardSuccess,
    onLeaderboardError,
  });

  const businessLocations = useMemo(() => {
    return initBusinessLocations?.filter((loc) => !loc?.isRoot) || [];
  }, [initBusinessLocations]);

  const locations = useMemo(() => {
    if ([AuthRole.SUPER_ADMIN, AuthRole.ADMIN].includes(loggedInUserAuthRole)) {
      return (
        businessLocations?.map((loc) => ({
          label: loc?.name,
          value: loc?.eid,
        })) || []
      );
    } else {
      return (
        loggedInUserLocations?.map((loc) => ({
          label: loc?.name,
          value: loc?.eid,
        })) || []
      );
    }
  }, [loggedInUserAuthRole, loggedInUserLocations, businessLocations]);

  const [selectedLocations, setSelectedLocations] =
    useState<SelectOption[]>(locations);

  const selectedColumns: string[] = useMemo(() => {
    return selectedColumnsFn(metrics);
  }, [metrics]);

  const exportDataProcess = (data: LeaderboardResponse) => {
    const filteredData: ExportData = filteredDataFn(selectedLocations, {
      attritionData: data?.NexusDashboard?.attrition || [],
      auditData: data?.NexusDashboard?.audit,
      taskData: data?.NexusDashboard?.task,
      businessLocationsData:
        entityData?.filter((entity) => entity?.type === 'branch') || [],
      customerFeedback: customerFeedback || [],
    });

    const processedData = processExportData({
      exportData: filteredData,
      dateRange: selectedDataRange,
      selectedLocationIds: selectedLocations.map((loc) => loc.value),
      businessLocations,
    });

    const metricsColumns = selectedColumns.filter(
      (column) => column.toLowerCase() !== 'location'
    );

    generateExcelNew({
      data: processedData,
      selectedColumns: metricsColumns,
      reportingPeriod: moment().format('MMMM'),
      selectedDataRange: selectedDataRange,
    });
    onClose();
  };

  const handleGenerate = () => {
    const metricMapping = {
      age: 'Age',
      attrition: 'Attrition',
      auditScore: 'Audit Score',
      customerFeedback: 'Customer Feedback',
      taskCompletion: 'Task Completion',
    };

    const selectedMetrics = Object.entries(metrics || {})
      .filter(([_, value]) => value)
      .map(([key]) => metricMapping[key as keyof typeof metricMapping])
      .filter(Boolean);

    deployEvent(AmplitudeEventNames.XLS_REPORT_DOWNLOAD, {
      event: {
        metrics_included: selectedMetrics,
        data_range: selectedDataRange?.label,
        locations_to_track: selectedLocations?.map((loc) => loc?.label) || [],
      },
    });

    const { startDate, endDate } = getDateRange(
      selectedDataRange?.value as RangeValue
    );
    leaderboardData({
      variables: {
        locationIds: selectedLocations?.map((loc) => loc?.value),
        startDate: startDate,
        endDate: endDate,
      },
    });
  };

  const handleSelectionChange = (newSelectedLocations: SelectOption[]) => {
    setSelectedLocations(newSelectedLocations);
  };

  const getLabel = () => {
    if (
      !metrics?.taskCompletion &&
      !metrics?.attrition &&
      !metrics?.age &&
      !metrics?.customerFeedback &&
      !metrics?.auditScore
    ) {
      return 'Please select a metric to continue';
    }
    if (selectedLocations.length === 0) {
      return 'Please select a location to continue';
    }
    return '';
  };

  const disabled = useMemo(() => {
    return (
      selectedLocations.length === 0 ||
      (!metrics?.taskCompletion &&
        !metrics?.attrition &&
        !metrics?.age &&
        !metrics?.customerFeedback &&
        !metrics?.auditScore)
    );
  }, [
    selectedLocations,
    metrics?.taskCompletion,
    metrics?.attrition,
    metrics?.age,
    metrics?.customerFeedback,
    metrics?.auditScore,
  ]);

  return {
    disabled,
    loading: leaderboardRes?.loading,
    locations,
    metrics,
    selectedDataRange,
    selectedLocations,
    getLabel,
    handleGenerate,
    handleSelectionChange,
    setMetrics,
    setSelectedDataRange,
  };
};
