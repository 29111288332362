import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useQuery } from '@apollo/client';
import { GET_SOP_BY_ID } from 'sub-components/training-v2/shared/api';
import { Box, CloseButton, Flex, Skeleton } from '@chakra-ui/react';
import { usePlayContext } from '../../../store/context';
import EditorPreview, { ICON_TYPE, JSONContent } from 'delightree-editor';
import { SopDetailResponse } from 'pages/Chapters/ChapterView/chapter.graphql';
import { useTrainingPreview } from 'pages/Training/CreateTrainingPath/Preview/component/useTrainingPreview';
import { useService } from 'sub-components/training-v2/play/layers';
import { AccessibilityType } from 'types';
import { NotAccessibleContainer } from 'ui-components';
import ChapterNotAccessible from 'pages/Chapters/ChapterView/ChapterNotAccessible';
import { useHistory } from 'react-router-dom';
import ChapterFilePreview from 'pages/Training/CreateTrainingPath/Preview/component/ChapterFilePreview';
import {
  UserDetailsComponent,
  UserDetailsRef,
} from 'sub-components/UserLocationDetails';
import { useSubmitForm } from 'sub-components/forms';
import ReactPlayer from 'react-player';

interface SopContentProps {
  content: any;
}

const convertTrainingData = (data: any) => {
  if (['document', 'video'].includes(data?.sopType)) {
    return [data];
  }

  if (data.smartPageEnabled || data.isMultiPage) {
    return data.cards?.map((card: any, index: number, arr: any[]) => ({
      ...card,
      isLastCard: index + 1 === arr.length,
      sopId: data.eid,
      subType: data.type,
      type: 'sop',
      files: data.files || [],
      media: data.media || [],
    }));
  }

  return data.cardView?.map((card: any, index: number, arr: any[]) => ({
    ...card,
    isLastCard: index + 1 === arr.length,
    sopId: data.eid,
    subType: data.type,
    type: 'sop',
    files: data.files || [],
    media: data.media || [],
  }));
};

const SopContent: FC<SopContentProps> = () => {
  const {
    contents,
    isRetake,
    selectedIndex,
    selectedSubIndex,
    tpSessionData,
    updateTpSessionData,
    updateContentByIndex,
  } = usePlayContext((state) => ({
    isRetake: state.isRetake,
    selectedIndex: state.selectedIndex,
    selectedSubIndex: state.selectedSubIndex,
    contents: state.tpSessionData?.contents || [],
    tpSessionData: state.tpSessionData,
    updateTpSessionData: state.updateTpSessionData,
    updateContentByIndex: state.updateContentByIndex,
  }));

  const [unAuthorised, setUnauthorised] = useState<
    AccessibilityType | undefined
  >(undefined);
  const useDetailRef = useRef<UserDetailsRef>(null);

  const history = useHistory();
  const submitForm = useSubmitForm();
  const service = useService();

  const isLastItem = useMemo(() => {
    const renderContent =
      contents?.[selectedIndex]?.sopContent?.renderContent || [];
    return selectedSubIndex === renderContent.length - 1;
  }, [contents, selectedIndex, selectedSubIndex]);

  const handleSOPCompletion = async (
    itemId: string,
    sessionId: string,
    trainingId: string
  ) => {
    const userProgress = tpSessionData?.userProgress;

    if (
      userProgress?.[0]?.progress?.some(
        (item) => item.id === itemId && item.isCompleted
      )
    ) {
      return;
    }
    await service.updateTPProgress
      .updateTPProgress({
        variables: {
          input: {
            isCompleted: true,
            itemId: itemId,
            sessionId: sessionId,
            trainingId: trainingId,
            responses: [],
          },
        },
      })
      ?.then((res) => {
        if (res?.data?.AddTpProgress?.eid && tpSessionData) {
          updateTpSessionData({
            ...tpSessionData,
            userProgress: res?.data?.AddTpProgress
              ? [res?.data?.AddTpProgress]
              : [],
          });
        }
      });
  };

  useEffect(() => {
    const currentContent = contents?.[selectedIndex];
    if (
      isLastItem &&
      tpSessionData?.eid &&
      tpSessionData?.trainingId &&
      !unAuthorised &&
      !isRetake
    ) {
      handleSOPCompletion(
        currentContent.eid,
        tpSessionData.eid,
        tpSessionData.trainingId
      );
    }
  }, [selectedSubIndex, isLastItem, unAuthorised]);

  useEffect(() => {
    setUnauthorised(undefined);
  }, [selectedIndex]);

  const { loading } = useQuery<SopDetailResponse>(GET_SOP_BY_ID, {
    variables: { eid: contents[selectedIndex].eid },
    onCompleted: (data) => {
      setUnauthorised(undefined);
      let convertedTPData = convertTrainingData(data?.SopById);
      updateContentByIndex(selectedIndex, {
        ...contents[selectedIndex],
        sopContent: {
          ...data?.SopById,
          renderContent: convertedTPData,
        },
      });
    },
    onError: (error) => {
      for (let err of error.graphQLErrors) {
        if (err.extensions.code === 'FORBIDDEN') {
          setUnauthorised('UNAUTHORISED');
          break;
        }
        if (err.extensions.code === 'BAD_USER_INPUT') {
          setUnauthorised('NOT_FOUND');
          break;
        }
      }
    },
  });

  const selectedData = useMemo(() => {
    return contents?.[selectedIndex]?.sopContent?.renderContent?.[
      selectedSubIndex
    ];
  }, [contents, selectedIndex, selectedSubIndex]);

  const content = useTrainingPreview(selectedData as JSONContent);

  const value = content?.content?.[0]?.tiptap || content?.content || content;

  const onItemClick = useCallback((event: MouseEvent, node: any) => {
    console.log('on item click : ', node);
    switch (node?.type?.name) {
      case 'member':
        useDetailRef.current?.openDetail('user', node.attrs);
        break;
      case 'location':
        useDetailRef.current?.openDetail('branch', node.attrs);
        break;
      case 'form':
        submitForm({
          formId: node.attrs?.eid,
          title: node.attrs?.label,
        });
        break;
      case 'chapter':
        window.open(`/chapters/view/${node.attrs?.eid}`, '_blank');
        break;
    }
  }, []);

  if (loading) {
    return (
      <Flex w='full' flexDir='column' gap={4}>
        <Skeleton h='40px' w='200px' />
        <Skeleton h='20px' w='full' />
        <Skeleton h='20px' w='full' />
        <Skeleton h='20px' w='full' />
        <Skeleton h='20px' w='full' />
        <Skeleton h='20px' w='full' />
        <Skeleton h='20px' w='full' />
        <Skeleton h='20px' w='full' />
        <Skeleton h='20px' w='full' />
      </Flex>
    );
  }

  if (unAuthorised) {
    return (
      <NotAccessibleContainer>
        <CloseButton ml='auto' onClick={history.goBack} />
        <ChapterNotAccessible type={unAuthorised} />
      </NotAccessibleContainer>
    );
  }

  if (!selectedData) return null;

  if (selectedData.sopType === 'video') {
    const mediaUrl = selectedData.media?.[0]?.mediaUrl;
    return (
      <Flex justify='center' align='center' h='full'>
        {mediaUrl && (
          // @ts-ignore
          <ReactPlayer
            url={mediaUrl}
            controls
            playing
            height='100%'
            width='100%'
          />
        )}
      </Flex>
    );
  }

  if (selectedData.sopType === 'document') {
    // const fileUrl = selectedData.files?.[0]?.url;
    // return (
    //   <Flex justify='center' align='center' h='full'>
    //     {fileUrl && (
    //       <iframe
    //         src={fileUrl}
    //         style={{ width: '100%', height: '70vh', border: 'none' }}
    //       />
    //     )}
    //   </Flex>
    // );
    return (
      <Box
        flex={1}
        // overflow='hidden'
        w='full'
        sx={{
          '.training-desktop': {
            height: '100%',
            paddingInline: '12px',
            paddingBottom: '16px',
            '.embed-container': {
              paddingInline: 0,
            },
            '.pdf-container': {
              paddingInline: 0,
              height: 'max-content',
            },
            '.pdf-container > div': {
              padding: 0,
            },
          },
          '.embed-container': {
            height: '100%',
          },
        }}
      >
        <ChapterFilePreview
          isNotDesktop={false}
          sopData={selectedData as never}
        />
      </Box>
    );
  }

  return (
    <Box
      width='100%'
      maxW='962px'
      borderRadius='8px'
      p='32px'
      pt='28px'
      bg='#FFFFFF'
      overflow='auto'
      className='training-desktop'
    >
      <Flex
        height='full'
        sx={{
          ' > div': {
            flex: 1,
            width: '100%',
          },
        }}
      >
        <EditorPreview
          readonly
          value={value}
          chapterIcon={contents?.[selectedIndex]?.sopContent?.icon?.native}
          onItemClick={onItemClick}
          iconType={
            contents?.[selectedIndex]?.sopContent?.icon?.id === ICON_TYPE
              ? 'icon'
              : 'emoji'
          }
        />
      </Flex>
      <UserDetailsComponent ref={useDetailRef} />
    </Box>
  );
};

export default SopContent;
