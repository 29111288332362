import React, { FC } from 'react';
import { Box, Flex, IconButton, Image } from '@chakra-ui/react';
import { FieldArrayWithId } from 'react-hook-form';
import { CloseIcon } from '@chakra-ui/icons';
import { ContentItemInput, TFormInput } from '../../training-input.types';
import { IconImage } from '../../../../../../ui-components';
import { TrainingContType } from '../../../../../../types';

//TODO  Update all assets
import handbook from '../../../../../../assets/images/sidebar/handbook.svg';
import formImage from '../../../../../../assets/images/training/training-form.svg';
import videoImage from '../../../../../../assets/images/training/training-video.svg';
import quizImage from '../../../../../../assets/images/zap-dark.svg';
import milestone from '../../../../../../assets/images/flagSvg.svg';
import quizThumbnail from '../../../../../../assets/images/training/quiz-varanit01.svg';

import { ReactComponent as FlagIcon } from 'assets/images/audits/FlagFilled.svg';
import { ReactComponent as EditIcon } from 'assets/images/edit-dark.svg';

// @ts-ignore
const ThumbnailIcon: Record<ContentItemInput['type'], string> = {
  [TrainingContType.QUIZ]: quizThumbnail,
};

// @ts-ignore
const ContentIcon: Record<ContentItemInput['type'], string> = {
  [TrainingContType.SOP]: handbook,
  [TrainingContType.FORM]: formImage,
  [TrainingContType.CARD]: videoImage,
  [TrainingContType.QUIZ]: quizImage,
  [TrainingContType.MILESTONE]: milestone,
};

// @ts-ignore
const ContentText: Record<ContentItemInput['type'], string> = {
  [TrainingContType.SOP]: 'Chapter',
  [TrainingContType.FORM]: 'Form',
  [TrainingContType.CARD]: 'Video',
  [TrainingContType.QUIZ]: 'Quiz',
  [TrainingContType.MILESTONE]: 'Milestone',
};

export interface ContentItemProps {
  index: number;
  data: FieldArrayWithId<TFormInput, 'contents'>;
  onRemoveClick?: () => void;
  onEditClick?: () => void;
  onClick?: () => void;
}

const ContentItem: FC<ContentItemProps> = ({
  data,
  onClick,
  onRemoveClick,
  onEditClick,
}) => {
  if (data.type === TrainingContType.MILESTONE) {
    return (
      <Flex
        flex={1}
        gap={5}
        align='center'
        padding='12px 16px'
        bgColor='#D0CAE8'
        justify='space-between'
        overflow='hidden'
        onClick={onClick}
      >
        <Flex></Flex>

        <Flex gap='16px' align='center' overflow='hidden'>
          <FlagIcon color='#8E59FF' />
          <Box fontSize='14px' fontWeight='600' color='#111315' isTruncated>
            {data.title}
          </Box>
        </Flex>

        <Flex gap={2}>
          {onEditClick ? (
            <IconButton
              variant='ghost'
              size='sm'
              aria-label='edit'
              // colorScheme='blue'
              borderRadius='full'
              onClick={onEditClick}
            >
              <EditIcon width='17' height='17' color='inherit' />
            </IconButton>
          ) : null}

          {onRemoveClick ? (
            <IconButton
              variant='ghost'
              size='sm'
              aria-label='delete'
              // colorScheme='blue'
              borderRadius='full'
              onClick={onRemoveClick}
            >
              <CloseIcon boxSize='12px' />
            </IconButton>
          ) : null}
        </Flex>
      </Flex>
    );
  }

  const isQuiz = data.type === TrainingContType.QUIZ;

  return (
    <Flex
      flex={1}
      gap={5}
      align='center'
      padding='10px 16px'
      borderRadius='12px'
      border='1px solid transparent'
      overflow='hidden'
      className='content-item'
      onClick={onClick}
    >
      <IconImage
        name={data.title}
        thumbnail={data.thumbnail || ThumbnailIcon[data.type]}
        boxSize={60}
      />

      <Flex flex={1} flexDir='column' gap={1} overflow='hidden'>
        <Flex gap='6px' align='center'>
          <Image src={ContentIcon[data.type]} boxSize='16px' minWidth='16px' />
          <Box fontSize='13px' fontWeight='500' color='#6F767E'>
            {ContentText[data.type]}
          </Box>
        </Flex>

        <Box fontSize='15px' fontWeight='600' color='#33383F' isTruncated>
          {data.title}
        </Box>
      </Flex>

      <Flex gap={2}>
        {onEditClick && isQuiz ? (
          <IconButton
            display='none'
            variant='ghost'
            size='sm'
            aria-label='edit'
            color='#111315'
            onClick={onEditClick}
          >
            <EditIcon width='17' height='17' color='inherit' />
          </IconButton>
        ) : null}

        {onRemoveClick ? (
          <IconButton
            display='none'
            variant='ghost'
            size='sm'
            aria-label='delete'
            color='#111315'
            onClick={onRemoveClick}
          >
            <CloseIcon boxSize='12px' />
          </IconButton>
        ) : null}
      </Flex>
    </Flex>
  );
};

export default React.memo(ContentItem);
