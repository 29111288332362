import React, { FC } from 'react';
import { useWatch } from 'react-hook-form';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { AlertBox } from '../../../../../../atoms';
import { TFormInput } from '../../training-input.types';

interface IProps {}

const ContentAlertInfo: FC<IProps> = () => {
  const status = useWatch<TFormInput, 'status'>({ name: 'status' });

  if (!status || status === 'TRAINING_DRAFT') {
    return null;
  }

  return (
    <AlertBox icon={faCircleExclamation}>
      Content changes will only be visible to assignees who have not yet
      completed the path.
    </AlertBox>
  );
};

export default ContentAlertInfo;
