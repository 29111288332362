import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { Controller } from 'react-hook-form';
import { QuizQFormInput } from '../quiz-form.types';
import TrueFalseOption from './TrueFalseOption';

interface IProps {}

const TrueFalseInput: FC<IProps> = () => {
  return (
    <Controller<QuizQFormInput, 'isTrue'>
      name='isTrue'
      rules={{
        validate: (value) => {
          if (typeof value !== 'boolean') {
            return 'Select right answer';
          }
        },
      }}
      render={({ field, fieldState }) => (
        <Flex flexDir='column'>
          <Flex align='center' gap={3}>
            <TrueFalseOption
              label='True'
              isInvalid={fieldState.invalid}
              isCorrect={field.value === true}
              onCheckClick={() => field.onChange(true)}
            />
            <TrueFalseOption
              label='False'
              isInvalid={fieldState.invalid}
              isCorrect={field.value === false}
              onCheckClick={() => field.onChange(false)}
            />
          </Flex>

          {fieldState?.invalid && (
            <Box color='red.500' mt='6px'>
              {fieldState?.error?.message}
            </Box>
          )}
        </Flex>
      )}
    />
  );
};

export default TrueFalseInput;
