import React, { FC } from 'react';
import { useSafeState } from '../../../../../../../hooks';
import { QuizQuestionType } from '../../../../../../../types';
import QuizTypeSelect from './QuizTypeSelect';
import QuizQuestionForm from './QuizQuestionForm';
import { getDefaultValue } from './quiz-form.default';
import { QuizQFormInput } from './quiz-form.types';

interface IProps {
  totalQuestion: number;
  onAddQuestion: (values: QuizQFormInput) => void;
  onCopy: (values: QuizQFormInput, newValues: QuizQFormInput) => void;
}

const AddQuizQuestion: FC<IProps> = ({
  totalQuestion,
  onAddQuestion,
  onCopy,
}) => {
  const [formOpen, update] = useSafeState<QuizQuestionType>();
  if (formOpen) {
    return (
      <QuizQuestionForm
        index={totalQuestion}
        initialValue={getDefaultValue({ type: formOpen })}
        onRemoveClick={() => update(undefined)}
        onAddQuestion={(values) => {
          onAddQuestion(values);
          update(undefined);
        }}
        onCloseClick={totalQuestion ? () => update(undefined) : undefined}
        onCopyClick={(values, newValues) => onCopy(values, newValues)}
      />
    );
  }

  return <QuizTypeSelect index={totalQuestion} onAddClick={update} />;
};

export default AddQuizQuestion;
