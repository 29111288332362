import React, { useCallback } from 'react';
import { useResourceModal } from '../common';
import QuizResourceContent, { QResourceProps } from './QuizResourceContent';

type QuizResource = (props: QResourceProps) => void;

export const useQuizResource = (): QuizResource => {
  const resourceModal = useResourceModal();

  return useCallback((props) => {
    resourceModal({
      title: props?.initialValues ? 'Edit Quiz' : 'Quiz',
      content: <QuizResourceContent {...props} />,
    });
  }, []);
};
