import React, { FC, useState } from 'react';
import {
  Box,
  Center,
  Checkbox,
  Flex,
  IconButton,
  Spacer,
} from '@chakra-ui/react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faGripDotsVertical } from '@fortawesome/pro-solid-svg-icons';
import { faPen } from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { IconImage } from 'ui-components';

import { IFormInput } from '../task.types';
import EditSteps from './EditSteps';

interface IProps {
  data: IFormInput['contents'][number];
  onDelete?: (value: IFormInput['contents'][number], index: number) => void;
  index: number;
  fieldId: string;
}

const TaskItem: FC<IProps> = ({ data, onDelete, index, fieldId }) => {
  const [editing, setEditing] = useState(false);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    setActivatorNodeRef,
    isDragging,
  } = useSortable({ id: fieldId, data: { index: index } });

  if (editing) {
    return (
      <Flex
        gap={4}
        ref={setNodeRef}
        transform={CSS.Translate.toString(transform)}
        border='1px solid transparent'
        borderRadius='8px'
        paddingLeft={2}
        data-grabbed={isDragging ? isDragging : undefined}
        _grabbed={{ zIndex: 2, bgColor: '#F4F4F4', borderColor: '#2A85FF' }}
      >
        <Center
          {...attributes}
          {...listeners}
          ref={setActivatorNodeRef}
          width={6}
          height='40px'
          color='#6F767E'
          zIndex={1}
          _hover={{ color: '#2A85FF' }}
          _disabled={{ opacity: '0.4', cursor: 'not-allowed' }}
        >
          <FontAwesomeIcon
            icon={faGripDotsVertical as IconProp}
            fontSize='20px'
          />
        </Center>

        <EditSteps data={data} close={() => setEditing(false)} />
      </Flex>
    );
  }

  return (
    <Flex
      pos='relative'
      gap={4}
      _hover={{
        '.step-action': {
          display: 'flex',
          opacity: 1,
        },
      }}
      ref={setNodeRef}
      transform={CSS.Translate.toString(transform)}
      border='1px solid transparent'
      borderRadius='8px'
      paddingLeft={2}
      data-grabbed={isDragging ? isDragging : undefined}
      _grabbed={{ zIndex: 2, bgColor: '#F4F4F4', borderColor: '#2A85FF' }}
    >
      <Center
        {...attributes}
        {...listeners}
        ref={setActivatorNodeRef}
        width={6}
        height='40px'
        color='#6F767E'
        zIndex={1}
        _hover={{ color: '#2A85FF' }}
        _disabled={{ opacity: '0.4', cursor: 'not-allowed' }}
      >
        <FontAwesomeIcon
          icon={faGripDotsVertical as IconProp}
          fontSize='20px'
        />
      </Center>

      <Checkbox isReadOnly />

      <Flex flexDir='column' justify='center' gap='2px' overflow='hidden'>
        {/*<Box fontSize='12px' color='#6F767E'>*/}
        {/*  Added on {data.dateAdded}*/}
        {/*</Box>*/}
        <Box fontSize='15px' fontWeight='500' color='#000000' isTruncated>
          {data.title}
        </Box>
      </Flex>
      <Spacer />

      {data?.type === 'todo' ? (
        <Box boxSize='40px' />
      ) : (
        <IconImage
          icon={data.sopData?.icon}
          thumbnail={data.thumbnail}
          name={data.title}
          files={data.sopData?.files}
          boxSize={40}
          borderRadius='6px'
        />
      )}

      <Flex
        display='none'
        opacity='0'
        className='step-action'
        gap={4}
        pos='absolute'
        right='0'
        paddingRight={2}
        boxSize='full'
        justify='flex-end'
        bg='linear-gradient(270deg, #FFFFFF 16%, rgba(255, 255, 255, 0) 86%)'
        transition='opacity 200ms, display 200ms'
        borderRightRadius='7px'
      >
        {data?.type === 'todo' && (
          <IconButton
            aria-label='edit'
            borderRadius='full'
            bg='#BBBBBB'
            size='xs'
            alignSelf='center'
            icon={<FontAwesomeIcon icon={faPen as IconProp} color='white' />}
            onClick={() => setEditing(true)}
          />
        )}

        <IconButton
          aria-label='action'
          borderRadius='full'
          bg='#BBBBBB'
          size='xs'
          alignSelf='center'
          icon={
            <FontAwesomeIcon
              size='lg'
              icon={faClose as IconProp}
              color='white'
            />
          }
          onClick={() => onDelete?.(data, index)}
        />
      </Flex>
    </Flex>
  );
};

export default TaskItem;
