import { useLazyQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { ITPCategory } from 'sub-components/training-v2/shared/types';
import { SortConfig, TableItem } from './types';
import { QUERIES } from './utils';

export const useEvaluationsData = (
  loggedInUserEid: string,
  trainingCategories: ITPCategory[],
  sortConfig: SortConfig
) => {
  const [data, setData] = React.useState<TableItem[]>([]);
  const [totalItems, setTotalItems] = React.useState(0);

  const [getMyEvaluationsCount, { loading: gettingEvaluationsCount }] =
    useLazyQuery(QUERIES.GET_MY_EVALUATIONS_COUNT, {
      onCompleted: (response) => {
        setTotalItems(response?.UserTpSessions?.count);
        getCertificateData({
          variables: {
            page: 1,
            perPage: response?.UserTpSessions?.count,
            userIds: [loggedInUserEid],
            filter: { hasSkillVerifier: true },
            sort: sortConfig.value,
          },
        });
      },
      onError: (error) => {
        console.error('Error fetching count:', error);
      },
    });

  const [getCertificateData, { loading, error }] = useLazyQuery(
    QUERIES.MY_EVALUATIONS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (response) => {
        const processedData = processEvaluationsData(
          response.UserTpSessions.items
        );
        setData(processedData);
      },
      onError: (error) => {
        console.error('Error fetching data:', error);
      },
    }
  );

  const processEvaluationsData = (items: any[]): TableItem[] => {
    return items
      .filter((item) => {
        const userProgress = item?.assignedUsers?.[0];
        return userProgress?.isEvaluated && !userProgress?.evaluationViewed;
      })
      .map((item) => ({
        eid: item.eid,
        title: item.title,
        trainingId: item.trainingId,
        score: item?.assignedUsers?.[0]?.score || 0,
        isEvaluated: true,
        certificateDownloaded: item?.assignedUsers?.[0]?.certificateDownloaded,
        evaluationViewed: item?.assignedUsers?.[0]?.evaluationViewed,
        profilePic: item?.training?.thumbnail,
        category: trainingCategories?.filter(
          (cat) => cat?.eid === item?.training?.category
        ),
        certificateId: item?.userProgress?.[0]?.certificate,
      }));
  };

  const refetch = () => {
    getCertificateData({
      variables: {
        page: 1,
        perPage: totalItems,
        userIds: [loggedInUserEid],
        filter: { hasSkillVerifier: true },
        sort: sortConfig.value,
      },
    });
  };

  const fetchData = () => {
    getMyEvaluationsCount({
      variables: {
        userIds: [loggedInUserEid],
        filter: { hasSkillVerifier: true },
        sort: sortConfig.value,
      },
    });
  };

  useEffect(() => {
    if (totalItems > 0) {
      refetch();
    }
  }, [sortConfig.value]);

  return {
    data,
    totalItems,
    loading: loading || gettingEvaluationsCount,
    error,
    fetchData,
    refetch,
  };
};
