import { Box, Flex, IconButton, Text } from '@chakra-ui/react';
import { HeaderColors } from 'configs';
import { FC, useMemo, useState } from 'react';
import { CiLocationOn } from 'react-icons/ci';
import {
  HiOutlineArrowNarrowLeft,
  HiOutlineArrowNarrowRight,
} from 'react-icons/hi';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import GenericColorHeader from 'sub-components/GenericColorHeader';
import BoardDropDown from './BoardDropdown/BoardDropDown';
import { daysToMonthsDays, getPhaseColor, months } from './helper';
import { EmptyState } from '.';

interface LocationLaunch {
  tasks: any[];
  phases: string[];
  startDate: string;
  isLive: boolean;
  liveDate: string;
  board: string;
}

interface IProps {
  locationData: {
    LocationLaunchList: LocationLaunch[];
  };
}

const CustomTooltip = ({ active, payload, year }: any) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    const totalTime = data.launchTime;
    const phases = data.phases;

    // Filter and map to include phase names
    const phaseEntries = Object.entries(phases)
      .filter(
        ([phaseName, value]) =>
          value !== null && Number(value) > 0 && typeof phaseName === 'string'
      )
      .map(([phaseName, value]) => [phaseName, Math.max(0, Number(value))]);

    const totalPhasesTime = phaseEntries.reduce(
      (sum, [_, value]) => Number(sum) + Number(value),
      0
    );

    return (
      <Box
        bg='white'
        borderRadius='8px'
        py='12px'
        px='16px'
        boxShadow='md'
        width='329px'
        zIndex={1000000}
      >
        <Flex justifyContent={'space-between'}>
          <Box>
            <Text fontSize='12px' fontWeight={500} color='#6F767E'>
              Average launch time
            </Text>
            <Text fontSize='15px' fontWeight={600} color='#111315'>
              {daysToMonthsDays(totalTime)}
            </Text>
          </Box>
          <Box>
            <Text fontSize={'11px'} fontWeight={500} color={'#6F767E'}>
              As of {data.name}’{year.toString().slice(-2)}
            </Text>
            <Text
              fontSize={'11px'}
              fontWeight={500}
              color={'#6F767E'}
              display={'flex'}
              alignItems={'center'}
              gap={'2px'}
              justifyContent={'flex-end'}
            >
              <CiLocationOn size={12} /> {data.count}
            </Text>
          </Box>
        </Flex>

        <Flex width='100%' direction='row'>
          {phaseEntries.map(([phase, time], index) => {
            const isFirst = index === 0;
            const isLast = index === phaseEntries.length - 1;
            const borderRadius = `${isFirst ? '4px' : '0'} ${
              isLast ? '4px' : '0'
            } ${isLast ? '4px' : '0'} ${isFirst ? '4px' : '0'}`;

            return (
              <Box
                my={'12px'}
                height='25px'
                borderRadius={borderRadius}
                key={index}
                width={`${(Number(time) / totalPhasesTime) * 100}%`}
                backgroundColor={getPhaseColor(index)}
                transition='width 0.3s ease-in-out'
              />
            );
          })}
        </Flex>

        <Flex flexDir='column' mt={2} gap={1}>
          {phaseEntries.map(([phase, time], index) => (
            <Flex align='center' key={phase} justifyContent='space-between'>
              <Flex alignItems='center'>
                <Box
                  w='8px'
                  h='8px'
                  bg={getPhaseColor(index)}
                  borderRadius='50%'
                  mr={2}
                />
                <Text fontSize='12px' fontWeight={500} w={'170px'}>
                  {phase}
                </Text>
              </Flex>
              <Text fontSize='12px' fontWeight={500} color='#6F767E'>
                {daysToMonthsDays(Number(time))}
              </Text>
            </Flex>
          ))}
        </Flex>
      </Box>
    );
  }
  return null;
};
const LaunchRateGraph: FC<IProps> = ({ locationData }) => {
  const [selectedBoard, setSelectedBoard] = useState<string>(() => {
    const boards = Array.from(
      new Set(
        locationData.LocationLaunchList.map((l: LocationLaunch) => l.board)
      )
    );
    return boards[0] || '';
  });
  const [year, setYear] = useState(new Date().getFullYear());

  const liveLocations = locationData?.LocationLaunchList?.filter(
    (location: LocationLaunch) => location.isLive
  );

  //Boards logic
  const boards = useMemo(() => {
    const uniqueBoards = Array.from(
      new Set(
        locationData.LocationLaunchList.map((l: LocationLaunch) => l.board)
      )
    );
    return [...uniqueBoards];
  }, [locationData]);

  //Monthly data, Board averages logic for the chart Data
  const { monthlyData, boardAverages } = useMemo(() => {
    const filteredLocations = locationData.LocationLaunchList.filter(
      (l: LocationLaunch) => l.board === selectedBoard
    );

    type PhaseStats = {
      total: number;
      count: number;
    };

    type StatsType = {
      totalTime: number;
      count: number;
      phases: { [phase: string]: PhaseStats };
    };

    const monthlyStats: { [key: string]: StatsType } = {};
    const boardStats: { [key: string]: StatsType } = {};

    filteredLocations.forEach((location: LocationLaunch) => {
      if (location.isLive) {
        const phaseNameMap = new Map<string, string>();
        location.tasks.forEach((task) => {
          phaseNameMap.set(task.phaseEid, task.phase);
        });

        // console.log(location, "location");

        const start = new Date(location.startDate);
        const live = new Date(location.liveDate);

        let durationDays = 0;
        if (live >= start) {
          const durationMs = live.getTime() - start.getTime();
          durationDays = durationMs / (1000 * 3600 * 24);
        } else {
          console.warn(
            `Invalid date range: liveDate (${location.liveDate}) is before startDate (${location.startDate})`
          );
        }

        // Calculate phase durations
        const phaseDurations: { [phase: string]: number } = {};
        location.phases.forEach((phaseEid) => {
          const tasksInPhase = location.tasks.filter(
            (task) =>
              task.phaseEid === phaseEid &&
              task.isCompleted &&
              task.completedAt &&
              task.startDate
          );

          if (tasksInPhase.length > 0) {
            const validTasks = tasksInPhase.filter((t) => {
              const startDate = new Date(t.startDate);
              const completedDate = new Date(t.completedAt!);
              return startDate < completedDate;
            });

            // console.log(validTasks, 'validTasks');

            if (validTasks.length > 0) {
              const start = Math.min(
                ...validTasks.map((t) => new Date(t.startDate).getTime())
              );
              const end = Math.max(
                ...validTasks.map((t) => new Date(t.completedAt!).getTime())
              );
              const duration = Math.max(0, (end - start) / (1000 * 3600 * 24));

              const phaseName = phaseNameMap.get(phaseEid) || phaseEid;
              phaseDurations[phaseName] = duration;
            }
          }
        });

        // Monthly stats processing
        const liveYear = live.getFullYear();
        const liveMonth = live.getMonth();
        const monthKey = `${liveYear}-${liveMonth}`;

        if (liveYear === year) {
          if (!monthlyStats[monthKey]) {
            monthlyStats[monthKey] = {
              totalTime: 0,
              count: 0,
              phases: {},
            };
          }
          monthlyStats[monthKey].totalTime += durationDays;
          monthlyStats[monthKey].count++;

          // Phase-specific accumulation with count tracking
          Object.entries(phaseDurations).forEach(([phase, duration]) => {
            if (!monthlyStats[monthKey].phases[phase]) {
              monthlyStats[monthKey].phases[phase] = { total: 0, count: 0 };
            }
            monthlyStats[monthKey].phases[phase].total += duration;
            monthlyStats[monthKey].phases[phase].count++;
          });
        }

        // Board averages processing
        const boardKey = location.board;
        if (!boardStats[boardKey]) {
          boardStats[boardKey] = {
            totalTime: 0,
            count: 0,
            phases: {},
          };
        }
        boardStats[boardKey].totalTime += durationDays;
        boardStats[boardKey].count++;

        // Phase-specific accumulation with count tracking
        Object.entries(phaseDurations).forEach(([phase, duration]) => {
          if (!boardStats[boardKey].phases[phase]) {
            boardStats[boardKey].phases[phase] = { total: 0, count: 0 };
          }
          boardStats[boardKey].phases[phase].total += duration;
          boardStats[boardKey].phases[phase].count++;
        });
      }
    });

    // Process monthly data with phase-specific averages
    const chartData = months.map((month, index) => {
      const monthKey = `${year}-${index}`;
      const stats = monthlyStats[monthKey] || {
        totalTime: 0,
        count: 0,
        phases: {},
      };

      const averagePhases = Object.fromEntries(
        Object.entries(stats.phases).map(([phase, phaseData]) => [
          phase,
          phaseData.count > 0 ? phaseData.total / phaseData.count : 0,
        ])
      );

      return {
        name: month,
        launchTime: stats.count > 0 ? stats.totalTime / stats.count : 0,
        phases: averagePhases,
        count: stats.count,
      };
    });

    // Process board averages with phase-specific averages
    const averages: {
      [key: string]: { time: number; phases: any; count: number };
    } = {};
    Object.entries(boardStats).forEach(
      ([board, { totalTime, count, phases }]) => {
        averages[board] = {
          count: count,
          time: count > 0 ? totalTime / count : 0,
          phases: Object.fromEntries(
            Object.entries(phases).map(([phase, phaseData]) => [
              phase,
              phaseData.count > 0 ? phaseData.total / phaseData.count : 0,
            ])
          ),
        };
      }
    );

    return { monthlyData: chartData, boardAverages: averages };
  }, [locationData, selectedBoard, year]);

  // Calculate y-axis ticks
  const maxLaunchTime = Math.max(...monthlyData.map((d) => d.launchTime || 0));
  const tickValues = [];
  for (let i = 0; i <= Math.ceil(maxLaunchTime / 30); i++) {
    tickValues.push(i * 30);
  }

  if (liveLocations?.length === 0) return <EmptyState />;

  return (
    // @ts-ignore
    <Flex
      cursor='pointer'
      p={6}
      borderRadius='8px'
      bg='white'
      flexDir='column'
      w='100%'
      gap='16px'
      mb='20px'
    >
      <Flex justifyContent='space-between' alignItems='center'>
        <GenericColorHeader title='Launch time' color={HeaderColors.Purple} />
        <BoardDropDown
          options={boards?.map((board) => ({ name: board, eid: board }))}
          value={selectedBoard}
          onChange={setSelectedBoard}
        />
      </Flex>

      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Flex direction='column'>
          <Text fontSize='18px' fontWeight={600}>
            {daysToMonthsDays(boardAverages[selectedBoard]?.time || 0)}
          </Text>
          <Text fontSize='13px' fontWeight={500} color='#6F767E'>
            Average launch time
            {boardAverages[selectedBoard]?.count > 0 &&
              `, for ${boardAverages[selectedBoard]?.count}
            ${
              boardAverages[selectedBoard]?.count > 1 ? 'locations' : 'location'
            }
              launched so far`}
          </Text>
        </Flex>
      </Box>

      <Box my='12px'>
        <ResponsiveContainer width='100%' height={249}>
          <LineChart
            data={monthlyData}
            margin={{ top: 0, right: 0, left: 40, bottom: 0 }}
          >
            <XAxis dataKey='name' axisLine={false} tickLine={false} />
            <YAxis
              axisLine={false}
              tickLine={false}
              domain={[0, 'dataMax+5']}
              ticks={tickValues}
              tickFormatter={(value) => Math.round(value / 30).toString()}
              label={{
                value: 'Launch Time (months)',
                angle: -90,
                offset: -30,
                position: 'insideLeft',
                style: {
                  textAnchor: 'middle',
                  fill: '#6F767E',
                  fontSize: 13,
                  fontWeight: 500,
                },
              }}
              tickMargin={56}
            />
            <Tooltip
              wrapperStyle={{ zIndex: 100000, position: 'absolute' }}
              content={<CustomTooltip year={year} />}
            />
            <CartesianGrid
              horizontal={true}
              vertical={false}
              stroke='#E8E8E8'
              strokeDasharray='3 3'
            />
            <Line
              dataKey='launchTime'
              stroke='#FF6A55'
              strokeWidth='2px'
              dot={false}
              activeDot={{
                stroke: '#FF6A55',
                strokeWidth: 2,
                fill: '#FF6A55',
                r: 4,
              }}
            />
          </LineChart>
        </ResponsiveContainer>

        <Flex align='center' gap='25px' mt={'10px'} justifyContent={'center'}>
          <IconButton
            aria-label='Previous Year'
            icon={<HiOutlineArrowNarrowLeft />}
            onClick={() => setYear((y) => y - 1)}
            variant='outline'
            height='33px'
            width='33px'
            p='8px'
            border='1px solid #EBEAED'
            zIndex={1}
          />
          <Text mx={2} fontSize='14px' fontWeight={400} color='#000000'>
            {year}
          </Text>
          <IconButton
            aria-label='Next Year'
            icon={<HiOutlineArrowNarrowRight />}
            onClick={() => setYear((y) => y + 1)}
            isDisabled={year === new Date().getFullYear()}
            variant='outline'
            height='33px'
            width='33px'
            p='8px'
            border='1px solid #EBEAED'
            zIndex={1}
          />
        </Flex>
      </Box>
    </Flex>
  );
};

export default LaunchRateGraph;
