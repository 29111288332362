import {
  Badge,
  Box,
  Center,
  Flex,
  Image,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEllipsis } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table } from 'antd';
import { TRAINING_PATH_V2_EDIT, TRAINING_PATH_V2_PATHS } from 'appRoutes';
import { ReactComponent as ArchiveIcon } from 'assets/images/archive.svg';
import { ReactComponent as FormresponseIcon } from 'assets/images/training/bar_chart.svg';
import { ReactComponent as EditiconIcon } from 'assets/images/training/edit.svg';
import { ReactComponent as TrashIcon } from 'assets/images/trash.svg';
import { ColumnsType } from 'atoms';
import { FC } from 'react';
import Loader from 'sub-components/Loader';
import { ActionMenu, IActionMenuData } from 'ui-components';
import { getImage } from 'utils';
import { useTrainingContext } from '../../TrainingTableContainer';
import { CustomProgressBar } from '../components';
import EmptyState from './EmptyState';
import { getCategoriesColorCode } from './helper';
import SortUI from './SortingUI/SortUI';
import { useHistory } from 'routes';
import { useDeleteTP } from 'sub-components/training-v2/shared/delete-operation-tp';
import moment from 'moment';
import { useUserDataSelector } from 'hooks';
import { RoleAccessPermission } from 'sub-components/training-v2/shared/types';
import { AuthRole } from 'sop-commons/src/client';
import { eventBus } from 'shared/eventEmit';

export interface ITrackingTPPagination {
  key: string;
  eid?: string;
  sessionId: string;
  pathName: string;
  thumbnail: string;
  category: {
    eid: string;
    name: string;
  };
  createdBy: string;
  createdById: string;
  createdOn: string;
  progress: {
    current: number;
    total: number;
  };
  status: string;
  overdue: number | null;
  lastUpdated: string;
  lastActivity: string;
  accessPermission: RoleAccessPermission;
}

const getTrackProgress = (sessionId?: string) => {
  if (!sessionId) {
    return [];
  }
  return [
    {
      name: 'Track progress',
      value: 'track',
      icon: <FormresponseIcon />,
      normalIcon: 'chakraUIIcon',
      textColor: '#6F767E',
    },
  ] as IActionMenuData[];
};

interface IProps {}

const PathTable: FC<IProps> = () => {
  const history = useHistory();
  const {
    trackingTpPaginationData,
    trainingCategories,
    trainingPathItemCount,
    getTrackingTpPaginationData,
    getTrackingTPFilters,
    trackingTpPaginationLoader,
    trainingCategoriesLoader,
    setSelectedPageIndex,
    selectedPageIndex,
    filters,
    trainingContextData,
  } = useTrainingContext();

  const { userAuthRole, loggedInUserId } = useUserDataSelector((state) => ({
    userAuthRole: state?.authRole,
    loggedInUserId: state?.eid,
  }));

  const categoriesColorCode = getCategoriesColorCode(trainingCategories);
  const deleteTP = useDeleteTP();

  const getRowClassName = (record: ITrackingTPPagination) => {
    if (record.status === 'TRAINING_TERMINATED') {
      return 'inactive-row';
    }
    return '';
  };
  const getMenuItems = (row: ITrackingTPPagination): IActionMenuData[] => {
    //@ts-ignore
    const allowedPermission = row?.accessPermission?.[userAuthRole];

    if (row.status === 'TRAINING_TERMINATED') {
      if (loggedInUserId === row?.createdById) {
        return [
          {
            name: 'Edit',
            value: 'edit',
            icon: <EditiconIcon />,
            normalIcon: 'chakraUIIcon',
            textColor: '#6F767E',
          },
          ...getTrackProgress(row?.sessionId),
          {
            name: 'Mark as active',
            value: 'active',
            icon: <ArchiveIcon />,
            normalIcon: 'chakraUIIcon',
            textColor: '#6F767E',
          },
          {
            name: 'Delete',
            value: 'delete',
            icon: <TrashIcon />,
            normalIcon: 'chakraUIIcon',
            textColor: 'red',
          },
        ];
      } else {
        if (allowedPermission?.includes('EDIT')) {
          return [
            {
              name: 'Edit',
              value: 'edit',
              icon: <EditiconIcon />,
              normalIcon: 'chakraUIIcon',
              textColor: '#6F767E',
            },
            ...getTrackProgress(row?.sessionId),
            {
              name: 'Mark as active',
              value: 'active',
              icon: <ArchiveIcon />,
              normalIcon: 'chakraUIIcon',
              textColor: '#6F767E',
            },
            {
              name: 'Delete',
              value: 'delete',
              icon: <TrashIcon />,
              normalIcon: 'chakraUIIcon',
              textColor: 'red',
            },
          ];
        } else {
          return getTrackProgress(row?.sessionId);
        }
      }
    }

    if (loggedInUserId === row?.createdById) {
      return [
        {
          name: 'Edit',
          value: 'edit',
          icon: <EditiconIcon />,
          normalIcon: 'chakraUIIcon',
          textColor: '#6F767E',
        },
        ...getTrackProgress(row?.sessionId),
        {
          name: 'Mark as inactive',
          value: 'inactive',
          icon: <ArchiveIcon />,
          normalIcon: 'chakraUIIcon',
          textColor: '#6F767E',
        },
        {
          name: 'Delete',
          value: 'delete',
          icon: <TrashIcon />,
          normalIcon: 'chakraUIIcon',
          textColor: 'red',
        },
      ];
    } else {
      if (allowedPermission?.includes('EDIT')) {
        return [
          {
            name: 'Edit',
            value: 'edit',
            icon: <EditiconIcon />,
            normalIcon: 'chakraUIIcon',
            textColor: '#6F767E',
          },
          ...getTrackProgress(row?.sessionId),
          {
            name: 'Mark as inactive',
            value: 'inactive',
            icon: <ArchiveIcon />,
            normalIcon: 'chakraUIIcon',
            textColor: '#6F767E',
          },
          {
            name: 'Delete',
            value: 'delete',
            icon: <TrashIcon />,
            normalIcon: 'chakraUIIcon',
            textColor: 'red',
          },
        ];
      } else {
        return getTrackProgress(row?.sessionId);
      }
    }
  };

  const getCellStyle = (record: ITrackingTPPagination) => {
    return record.status === 'TRAINING_TERMINATED'
      ? { opacity: 0.5, cursor: 'not-allowed' }
      : {};
  };

  const columns: ColumnsType<ITrackingTPPagination> = [
    {
      title: (
        <Flex alignItems={'center'} gap={2} p='0px'>
          <Text fontWeight={500} color={'#6F767E'} fontSize={'14px'}>
            Path Name
          </Text>
          <SortUI columnKey='pathName' />
        </Flex>
      ),
      dataIndex: 'pathName',
      key: 'pathName',
      render: (text, record) => (
        <Flex
          align='center'
          gap={3}
          style={getCellStyle(record)}
          _hover={
            record.status === 'TRAINING_TERMINATED'
              ? {
                  _after: {
                    content: '"Marked as inactive"',
                    position: 'absolute',
                    right: '16px',
                    background: 'rgba(0, 0, 0, 0.75)',
                    color: 'white',
                    padding: '4px 8px',
                    borderRadius: '4px',
                  },
                }
              : {}
          }
        >
          <Image
            borderRadius='8px'
            height='62px'
            width='62px'
            src={getImage(record?.thumbnail, text)}
          />

          <Flex direction={'column'} gap={0}>
            <Tooltip label={text}>
              <Box
                as={'span'}
                isTruncated
                maxW='150px'
                fontSize='15px'
                fontWeight='600'
              >
                {text}
              </Box>
            </Tooltip>
            <Text fontWeight='500' fontSize='12px' color='#6F767E'>
              Last Updated: {moment(record?.lastUpdated).format('DD MMM')}
            </Text>
            {record?.category?.name && (
              <Tooltip label={record?.category?.name}>
                <Badge
                  variant='subtle'
                  borderRadius='5px'
                  fontSize='12px'
                  fontWeight={500}
                  textTransform='capitalize'
                  w='fit-content'
                  display='flex'
                  bg={categoriesColorCode?.[record?.category?.eid]?.bg}
                  color={categoriesColorCode?.[record?.category?.eid]?.color}
                  isTruncated
                  maxW={'150px'}
                >
                  {record?.category?.name}
                </Badge>
              </Tooltip>
            )}
          </Flex>
        </Flex>
      ),
    },
    {
      title: (
        <Flex alignItems={'center'} gap={2}>
          <Text fontWeight={500} color={'#6F767E'}>
            Created by
          </Text>
          {/* <SortUI columnKey='createdBy' /> */}
        </Flex>
      ),
      dataIndex: 'createdBy',
      key: 'createdBy',
      render: (_, record) => (
        <Text
          fontSize={'14px'}
          fontWeight={500}
          color={'#272B30'}
          style={getCellStyle(record)}
        >
          {record?.createdBy}
        </Text>
      ),
    },
    {
      title: (
        <Flex alignItems={'center'} gap={2}>
          <Text fontWeight={500} color={'#6F767E'}>
            Created on
          </Text>
          <SortUI columnKey='createdOn' />
        </Flex>
      ),
      dataIndex: 'createdOn',
      key: 'createdOn',
      render: (_, record) => (
        <Text
          fontSize={'14px'}
          fontWeight={500}
          color={'#272B30'}
          style={getCellStyle(record)}
        >
          {moment(record?.createdOn).format('D MMM')}
        </Text>
      ),
    },
    {
      title: (
        <Flex alignItems={'center'} gap={2}>
          <Text fontWeight={500} color={'#6F767E'}>
            Completed vs Assigned
          </Text>
        </Flex>
      ),
      key: 'progress',
      render: (_, record) => {
        const isDemoGreator =
          record?.progress?.total >= record?.progress?.current;
        return isDemoGreator ? (
          <Flex alignItems={'center'} gap={2} style={getCellStyle(record)}>
            <Flex
              minW={'70px'}
              borderRadius={'6px'}
              p={'2px 10px'}
              alignItems={'center'}
              backgroundColor={'#EFEFEF'}
            >
              <Flex width={'100%'} justifyContent={'center'}>
                <Text fontWeight={600}>{record?.progress?.current}/</Text>
                <Text>{record?.progress?.total}</Text>
              </Flex>
            </Flex>
            <CustomProgressBar
              width='44px'
              percent={
                (record?.progress?.current / record?.progress?.total) * 100
              }
            />
          </Flex>
        ) : (
          '-'
        );
      },
    },
    {
      title: (
        <Flex alignItems={'center'} gap={2}>
          <Text fontWeight={500} color={'#6F767E'}>
            Overdue
          </Text>
        </Flex>
      ),
      dataIndex: 'overdue',
      key: 'overdue',
      render: (_, record) => (
        <>
          {record.overdue ? (
            <Badge
              variant='subtle'
              borderRadius='5px'
              fontSize='12px'
              fontWeight={500}
              textTransform='capitalize'
              w='fit-content'
              display='flex'
              bg={'#FFBC9933'}
              color={'#FF6A55'}
              style={getCellStyle(record)}
            >
              {record.overdue} overdue
            </Badge>
          ) : (
            <Text style={getCellStyle(record)}>-</Text>
          )}
        </>
      ),
    },
    {
      title: (
        <Flex alignItems={'center'} gap={2}>
          <Text fontWeight={500} color={'#6F767E'}>
            Last activity
          </Text>
          <SortUI columnKey='lastActivity' />
        </Flex>
      ),
      dataIndex: 'lastActivity',
      key: 'lastActivity',
      render: (_, record) => (
        <Text
          fontSize={'14px'}
          fontWeight={500}
          color={'#272B30'}
          style={getCellStyle(record)}
        >
          {moment.utc(record.lastActivity).format('D MMM')}
        </Text>
      ),
    },
    {
      title: '',
      key: 'actions',
      render: (record, row) => {
        return (
          <Box minWidth='50px' textAlign='center'>
            <ActionMenu
              strategy='fixed'
              menuData={getMenuItems(row)}
              arrowSize={10}
              closeOnBlur={true}
              offset={[0, 0]}
              clickedItem={(_, clickedItem) => {
                if (clickedItem === 'edit') {
                  return history.push({
                    pathname: TRAINING_PATH_V2_EDIT,
                    params: {
                      trainingId: row.key,
                    },
                  });
                }
                if (clickedItem === 'track') {
                  if (row?.key && row?.sessionId) {
                    history.push({
                      pathname: TRAINING_PATH_V2_PATHS,
                      params: {
                        trainingId: row?.key,
                        sessionId: row?.sessionId,
                      },
                    });
                  }
                } else if (
                  clickedItem === 'inactive' ||
                  clickedItem === 'delete' ||
                  clickedItem === 'active'
                ) {
                  return deleteTP({
                    sessionId: row.sessionId,
                    trainingId: row.key,
                    clickedItem: clickedItem,
                    type: record?.status,
                    cb: () => {
                      getTrackingTpPaginationData({
                        variables: getTrackingTPFilters?.({
                          pageIndex: selectedPageIndex,
                        }),
                      });
                    },
                  });
                }
              }}
            >
              <Box
                cursor='pointer'
                style={
                  record.status === 'TRAINING_TERMINATED'
                    ? { opacity: 0.5 }
                    : {}
                }
              >
                <FontAwesomeIcon
                  icon={faEllipsis as IconProp}
                  size='2x'
                  color='#33383F'
                />
              </Box>
            </ActionMenu>
          </Box>
        );
      },
    },
  ];

  return (
    <>
      {trackingTpPaginationData?.length > 0 ? (
        <Box overflowX='auto' width='100%'>
          <Table
            style={{ overflowX: 'hidden', tableLayout: 'fixed' }}
            loading={
              (trackingTpPaginationLoader || trainingCategoriesLoader) && {
                indicator: <Loader />,
              }
            }
            columns={columns}
            dataSource={trackingTpPaginationData}
            rowKey='id'
            rowClassName={getRowClassName}
            pagination={{
              pageSize: 10,
              total: trainingPathItemCount,
              showSizeChanger: false,
              onChange: (pageNum) => {
                setSelectedPageIndex(pageNum);
              },
            }}
          />
        </Box>
      ) : (
        <>
          {trackingTpPaginationLoader || trainingCategoriesLoader ? (
            <Center maxBlockSize={'100%'} height={'40vh'}>
              <Loader />
            </Center>
          ) : (
            <EmptyState
              filterCount={filters?.totalFiltersTypes}
              trackingTpSearchQuery={trainingContextData?.trackingTpSearchQuery}
            />
          )}
        </>
      )}
    </>
  );
};

PathTable.displayName =
  'packages/sop-web/src/sub-components/training-v2/dashboard/components/TrackPathDashboard/bottomSection/PathTable';

export default PathTable;
