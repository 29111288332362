import React, { useCallback } from 'react';
import { useResourceModal } from '../common';
import MilestoneResourceContent, {
  SResourceProps,
} from './MilestoneResourceContent';

type FormResource = (props: SResourceProps) => void;

export const useMilestoneResource = (): FormResource => {
  const resourceModal = useResourceModal();

  return useCallback((props) => {
    resourceModal({
      title: props?.initialValues ? 'Edit Milestone' : 'Add a Milestone',
      content: <MilestoneResourceContent {...props} />,
    });
  }, []);
};
