import React, { FC, useState } from 'react';
import MyTrainingTable from 'sub-components/training-v2/dashboard/components/myTrainings/bottomSection/MyTrainingTable';
import { TExcueteCallbackVars } from 'sub-components/training-v2/dashboard/components/GenericFilter/types/filter-types';
import {
  MyTrainingContextType,
  MyTrainingProvider,
} from 'sub-components/training-v2/dashboard/components/MyTrainingContext';
import { Button, Flex } from '@chakra-ui/react';
import { BoxHeader } from 'ui-components';
import { HeaderColors } from 'configs';
import { useHistory } from 'routes';
import { TRAINING_PATH_V2_DASHBOARD } from 'appRoutes';

interface IProps {
  loading: boolean;
  trainingCategories: any[];
  trainingPathData: any;
}

const TableMode: FC<IProps> = ({
  loading,
  trainingCategories,
  trainingPathData,
}) => {
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedPageIndex, setSelectedPageIndex] = useState(1);
  const [sortType, setSortType] = useState({
    sort: 'CREATEDAT_DESC',
    order: 'none',
    tooltipLabel: 'sort asc',
  });
  const [filters, setFilters] = useState({
    types: [],
    statuses: [],
    categories: [],
    selectedTypes: [],
    selectedTypesId: [],
    selectedStatuses: [],
    selectedStatusesId: [],
    selectedCategories: [],
    selectedCategoriesId: [],
    totalFiltersTypes: 0,
    mergedFiltersArray: [],
  });

  const trainingPathFilters: TExcueteCallbackVars = (
    page = 1,
    sort = 'CREATEDAT_DESC'
  ) => {
    return {
      page: page,
      perPage: 10,
      sort: sort,
      filter: {
        query: '',
        status: [
          'TRAINING_CREATED',
          'TRAINING_STARTED',
          'TRAINING_REPEAT',
          'TRAINING_COMPLETED',
        ],
      },
      userProgressFilter2: {
        userId: '',
      },
      userIds: [''],
    };
  };

  const getTrainingPathSessionData = (options?: any) => {};

  const contextValue: MyTrainingContextType = {
    searchQuery,
    setSearchQuery,
    selectedPageIndex,
    setSelectedPageIndex,
    getTrainingPathSessionData,
    trainingPathFilters,
    sortType,
    setSortType,
    filters,
    setFilters,
    trainingCategories: [],
  };

  return (
    <MyTrainingProvider value={contextValue}>
      <Flex gap={4} flexDir='column' bg='white' borderRadius='8px' p={6}>
        <Flex align='center' justify='space-between'>
          <BoxHeader title='My Trainings' color={HeaderColors.Purple} />
          {trainingPathData?.length > 0 ? (
            <Button
              variant='outline'
              bg='white'
              size='lg'
              onClick={() =>
                history.push({ pathname: TRAINING_PATH_V2_DASHBOARD })
              }
            >
              View all
            </Button>
          ) : (
            <></>
          )}
        </Flex>
        <MyTrainingTable
          hideSorting
          backToLabel='Badk to Dashboard'
          loader={loading}
          trainingPathData={trainingPathData}
          trainingCategory={trainingCategories}
          pageInfo={{
            currentPage: 1,
            itemCount: trainingPathData?.length,
            pageCount: 1,
            perPage: trainingPathData?.length,
            hasNextPage: false,
            hasPreviousPage: false,
          }}
          mytarainingMode={true}
        />
      </Flex>
    </MyTrainingProvider>
  );
};

export default TableMode;
