import { ApolloProvider, useApolloClient } from '@apollo/client';
import { Box, ModalCloseButton } from '@chakra-ui/react';
import { useCallback, useRef } from 'react';
import { AppRouter } from 'routes';
import { UseConfirm, useConfirm } from 'ui-components';
import PathByMemberIdContainer from './PathByMemberIdContainer';
import { IOnViewActivityClick } from '../MemberAnalytics';
interface IProps {}

const usePathsByMemberId = () => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();
  const apolloClient = useApolloClient();

  return useCallback(
    ({
      userName,
      userId,
      onViewActivityClick,
    }: {
      userName: string;
      userId: string;
      onViewActivityClick: (data: IOnViewActivityClick) => void;
    }) => {
      if (confirmRef.current) {
        confirmRef.current.destroy();
      }
      confirmRef.current = confirm({
        Wrapper: ({ children }: any) => {
          return (
            <ApolloProvider client={apolloClient}>
              <AppRouter>{children}</AppRouter>
            </ApolloProvider>
          );
        },
        size: '5xl',
        title: (
          <>
            <Box>{`${userName} trainings`}</Box>
            <ModalCloseButton top='1.5rem' right='1.5rem' fontSize='18px' />
          </>
        ),
        content: (
          <PathByMemberIdContainer
            userId={userId}
            userName={userName}
            onViewActivityClick={onViewActivityClick}
          />
        ),
        contentProps: {
          borderRadius: '12px',
          p: '2rem 2.5rem',
        },
        headerProps: {
          p: '0px',
        },
        bodyProps: {
          p: '0px',
        },
        isCentered: true,
        footer: null,
      });
    },
    [confirm]
  );
};

usePathsByMemberId.displayName =
  'displayName:sub-components/training-v2/dashboard/components/TrackPathDashboard/components/usePathsByMemberId.tsx';

export default usePathsByMemberId;
