import React from 'react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Box, Flex, forwardRef } from '@chakra-ui/react';
import { Button, ButtonProps } from '../../../../../../../atoms';
import { UserEntityData } from '../../../../../../../shared/graphql/shared-types';
import SelectedMembers from './SelectedMembers';

interface IProps extends ButtonProps {
  values?: UserEntityData[];
  onRemoveClick?: (value: string) => void;
}

const MemberSelectButton = forwardRef<IProps, 'button'>(
  ({ values, disabled, onRemoveClick, ...props }, ref) => {
    return (
      <Button
        ref={ref}
        as='div'
        size='lg'
        borderRadius='12px'
        rightIcon={<ChevronDownIcon boxSize='28px' color='#6F767E' />}
        justifyContent='space-between'
        alignItems='initial'
        paddingX='12px'
        paddingBlock='6px'
        variant='outline'
        bg='white'
        width='full'
        height='initial'
        minHeight={12}
        fontSize='14px'
        isDisabled={disabled}
        {...props}
      >
        <Flex gap={2} align='center' flexWrap='wrap'>
          {values?.length ? (
            <SelectedMembers values={values} onRemoveClick={onRemoveClick} />
          ) : (
            <Box fontSize='14px' fontWeight='600' opacity='0.5'>
              Select member(s)
            </Box>
          )}
        </Flex>
      </Button>
    );
  }
);

export default MemberSelectButton;
