import React, { FC } from 'react';
import { Box, Button, Center, Flex } from '@chakra-ui/react';
import { ErrorCode, FileError, useDropzone } from 'react-dropzone';
import AssetLinkPaste from './AssetLinkPaste';

const FIVE_MB = 5000000;

interface IProps {
  isInvalid?: boolean;
  isUploading?: boolean;
  onFileChange: (files: FileList) => Promise<void> | void;
  onLinkChange?: (value: string) => Promise<void> | void;
  onError?: (error: FileError) => void;
}

const AddImageDropZone: FC<IProps> = ({
  isInvalid,
  isUploading,
  onFileChange,
  onLinkChange,
  onError,
}) => {
  const { getRootProps, getInputProps, open } = useDropzone({
    onDropAccepted: async (acceptedFiles) => {
      if (onFileChange) {
        await onFileChange(acceptedFiles as unknown as FileList);
      }
    },
    onDropRejected: (rejected) => {
      const error = rejected?.[0]?.errors?.[0];
      if (error && onError) {
        if (error.code === ErrorCode.FileTooLarge) {
          onError({
            code: 'file-too-large',
            message: `The selected file exceeds maximum file size limit (${
              FIVE_MB! / 1000000
            } Mb)`,
          });
        }
      }
    },
    accept: { 'image/*': ['.jpg', '.jpeg', '.png', '.gif'] },
    maxSize: FIVE_MB,
    multiple: false,
    noClick: true,
    noKeyboard: true,
    disabled: isUploading,
  });

  return (
    <Flex
      boxSize='full'
      flexDir='column'
      gap='2px'
      border='1px dashed #9A9FA5'
      borderRadius='11px'
      aria-invalid={isInvalid}
      aria-disabled={isUploading}
      _disabled={{ opacity: 0.5, cursor: 'not-allowed' }}
      _invalid={{ borderColor: 'red.300', boxShadow: '0 0 0 1px #FF6A55' }}
    >
      <Box
        {...getRootProps()}
        height='100%'
        p={2}
        borderRadius='11px'
        aria-disabled={isUploading}
        _disabled={{ pointerEvents: 'none' }}
      >
        <input {...getInputProps()} />
        <Center h='full' flexDir='column' gap='10px' color='#33383F'>
          <Box fontWeight='500' fontSize='15px'>
            Drop your image here
          </Box>
          <Box fontWeight='500' fontSize='15px'>
            or
          </Box>
          <Button
            variant='outline'
            fontSize='13px'
            fontWeight='600'
            onClick={open}
          >
            Upload from device
          </Button>

          <Box fontSize='12px' color='#6F767E' mt={6}>
            PNG, JPEG, GIF · Max size 5Mb
          </Box>
        </Center>
      </Box>

      <AssetLinkPaste
        placeholder='or paste a link'
        isDisabled={isUploading}
        onLinkAdd={async (newLink) => {
          await onLinkChange?.(newLink);
        }}
      />
    </Flex>
  );
};

export default React.memo(AddImageDropZone);
