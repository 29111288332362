import { Box } from '@chakra-ui/react';
import React, { FC } from 'react';

interface IProgress {
  current: number;
  total: any;
  width: string;
}
interface IProps {
  percent?: number;
  progress?: IProgress;
  width: string;
}

const CustomProgressBar: FC<IProps> = ({ percent, width = '44px' }) => {
  return (
    <Box
      position='relative'
      width={width}
      height='12px'
      borderRadius='2px'
      backgroundColor='rgba(0, 0, 0, 0.1)'
      overflow='hidden'
    >
      {percent > 0 && (
        <Box
          width={`${percent}%`}
          height='100%'
          borderRadius={Number(percent) < 100 ? '0px 2px 2px 0px' : '2px'}
          backgroundColor='#83BF6E'
          transition='width 0.3s ease-in-out'
        />
      )}
    </Box>
  );
};

export default CustomProgressBar;
