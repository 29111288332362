import React, { FC } from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input } from 'atoms';
import { TFormInput } from '../training-input.types';
import { FlexContainer } from '../common';
import { TrainingCategorySelect } from './training-category';
import TrainingThumbnail from './TrainingThumbnail';

interface IProps {
  disabled?: boolean;
}

const TrainingPathDetailsSection: FC<IProps> = ({ disabled }) => {
  const { t } = useTranslation(['category', 'training']);

  return (
    <FlexContainer flexDir='column' gap={4} w='full'>
      <Flex flexDir='column'>
        <Box fontWeight={600} fontSize='20px' lineHeight='32px'>
          {t('training:addTrainingPathDetails')}
        </Box>
        <Box color='#6F767E' fontWeight={500} fontSize='15px' lineHeight='24px'>
          {t('training:addTrainingPathDetailsDesc')}
        </Box>
      </Flex>

      <Flex gap={8}>
        <Controller<TFormInput, 'thumbnail'>
          name='thumbnail'
          defaultValue=''
          disabled={disabled}
          // rules={{ required: 'Course thumbnail is required' }}
          render={({ field, fieldState }) => (
            <FormControl
              width='214px'
              minW='214px'
              isInvalid={fieldState.invalid}
            >
              <TrainingThumbnail {...field} />
              <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
            </FormControl>
          )}
        />

        <Flex flexDir='column' w='full' gap={4}>
          <Controller<TFormInput, 'title'>
            name='title'
            defaultValue=''
            disabled={disabled}
            rules={{
              required: 'Please enter a course title',
              maxLength: {
                value: 60,
                message: 'Course title must be at most 60 characters',
              },
              validate: (value) => {
                if (!value?.trim()) {
                  return 'Enter valid course title';
                }
              },
            }}
            render={({ field, fieldState }) => (
              <FormControl isInvalid={fieldState.invalid}>
                <FormLabel fontSize='14px'>
                  {t('training:courseTitle')}
                  <span style={{ color: 'red' }}> *</span>
                </FormLabel>
                <Input size='lg' variant='auditOutline' {...field} />
                <FormErrorMessage>
                  {fieldState?.error?.message}
                </FormErrorMessage>
              </FormControl>
            )}
          />

          <Controller<TFormInput, 'description'>
            name='description'
            defaultValue=''
            disabled={disabled}
            render={({ field, fieldState }) => (
              <FormControl isInvalid={fieldState.invalid}>
                <FormLabel fontSize='14px'>
                  {t('training:courseDescOptional')}
                </FormLabel>
                <Input size='lg' variant='auditOutline' {...field} />
                <FormErrorMessage>
                  {fieldState?.error?.message}
                </FormErrorMessage>
              </FormControl>
            )}
          />

          <Controller<TFormInput, 'category'>
            name='category'
            disabled={disabled}
            rules={{
              required: 'Select course category',
            }}
            render={({ field, fieldState }) => (
              <FormControl isInvalid={fieldState.invalid}>
                <FormLabel fontSize='14px'>
                  {t('category:select_category')}
                  <span style={{ color: 'red' }}> *</span>
                </FormLabel>

                <TrainingCategorySelect {...field} />
                <FormErrorMessage>
                  {fieldState?.error?.message}
                </FormErrorMessage>
              </FormControl>
            )}
          />
        </Flex>
      </Flex>
    </FlexContainer>
  );
};

TrainingPathDetailsSection.displayName =
  'displayName:sub-components/training-v2/create/components/TrainingPathDetailsSection/index.tsx';
export default TrainingPathDetailsSection;
