import React, { FC } from 'react';
import {
  Box,
  Text,
  Avatar,
  Badge,
  AvatarGroup,
  Image,
  Flex,
  Tooltip,
} from '@chakra-ui/react';
import { BoxHeader } from 'ui-components';
import calenndar from '../../../../../../assets/images/training/calendar.svg';
import { TTPSummary } from 'sub-components/training-v2/shared/types';
import { FiRepeat } from 'react-icons/fi';

interface SummaryRowProps {
  label: string;
  children: React.ReactNode;
}

const SummaryRow: FC<SummaryRowProps> = ({ label, children }) => (
  <Flex gap={'28px'} align='center'>
    <Text color='#1A1D1F' fontSize='14px' fontWeight={600} width={'117px'}>
      {label}:
    </Text>
    {children}
  </Flex>
);

const formatFrequencyWithTooltip = (
  frequency: string,
  maxWords: number = 3,
  fontSize: string = '14px',
  fontWeight: number = 500,
  color: string = '#6F767E'
) => {
  const words = frequency.trim().split(/\s+/).filter(Boolean);
  const wordCount = words.length;

  const displayText =
    wordCount > maxWords ? `${words.slice(0, maxWords).join(' ')}…` : frequency;
  const showRepeatIcon = frequency.trim() !== 'Once';

  const textElement = (
    <Text
      fontSize={fontSize}
      fontWeight={fontWeight}
      color={color}
      isTruncated={wordCount > maxWords}
      maxW='200px'
      display='flex'
      alignItems='center'
      gap='8px'
    >
      {showRepeatIcon && <FiRepeat />}
      {displayText}
    </Text>
  );

  if (wordCount > maxWords) {
    return (
      <Tooltip label={frequency} fontSize='12px'>
        {textElement}
      </Tooltip>
    );
  }

  return textElement;
};
const SummaryCard: FC<{ summaryData: TTPSummary }> = ({ summaryData }) => {
  const {
    tpSessionCreatedBy,
    tpSessionCreatedOn,
    tpSessionSupervisedBy,
    tpSessionSummaryCategory,
    tpSessionRepeatFrequency,
    tpSessionStatus,
  } = summaryData;

  return (
    <Box
      bg='#FFFFFF'
      p='20px'
      borderRadius='12px'
      minW='353px'
      pl={'30px'}
      border='2px solid #EFEFEF'
      w='30%'
    >
      <BoxHeader
        fontSize='18px'
        fontWeight={600}
        title='Summary'
        color='#FFBC99'
      />

      <Flex flexDirection='column' gap={'16px'} mt={8}>
        <SummaryRow label='Created on'>
          <Flex align='center' gap='8px'>
            <Image src={calenndar} height='20px' width='20px' />
            <Text fontSize='14px' fontWeight={500} color='#6F767E'>
              {tpSessionCreatedOn}
            </Text>
          </Flex>
        </SummaryRow>

        <SummaryRow label='Created by'>
          <Text fontSize='14px' fontWeight={500} color='#6F767E'>
            {tpSessionCreatedBy}
          </Text>
        </SummaryRow>

        <SummaryRow label='Category'>
          <Tooltip label={tpSessionSummaryCategory} fontSize='12px'>
            <Badge
              bg={'#FFD88D'}
              px={'8px'}
              py={'2px'}
              borderRadius='6px'
              maxW='250px'
              noOfLines={1}
              fontWeight={500}
              fontSize={'14px'}
              textColor={'#1A1D1F'}
              textTransform={'capitalize'}
              isTruncated
            >
              <Text isTruncated maxW={'200px'}>
                {tpSessionSummaryCategory}
              </Text>
            </Badge>
          </Tooltip>
        </SummaryRow>

        {tpSessionSupervisedBy && tpSessionSupervisedBy.length > 0 && (
          <SummaryRow label='Supervised by'>
            <Tooltip
              label={
                <Text
                  fontSize={'12px'}
                  fontWeight={600}
                  color={'#F4F4F4'}
                  lineHeight={'24px'}
                >
                  {tpSessionSupervisedBy
                    ?.map((supervisor) => supervisor?.name)
                    .join(', ')}
                </Text>
              }
              // offset={[90, 2]}
              p={'2px 8px'}
              borderRadius={'md'}
            >
              <AvatarGroup
                max={3}
                spacing='-0.6rem'
                size='sm'
                cursor={'pointer'}
              >
                {tpSessionSupervisedBy?.map((supervisor, index) => (
                  <Avatar
                    size='sm'
                    showBorder
                    name={supervisor?.name}
                    src={supervisor?.avatar}
                    key={supervisor?.name}
                  />
                ))}
              </AvatarGroup>
            </Tooltip>
          </SummaryRow>
        )}

        <SummaryRow label='Frequency'>
          {formatFrequencyWithTooltip(tpSessionRepeatFrequency)}
        </SummaryRow>

        <SummaryRow label='Status'>
          <Flex align='center' gap={'8px'}>
            <Box
              width='6px'
              height='6px'
              borderRadius='full'
              bg={tpSessionStatus === 'Live' ? '#83BF6E' : 'red.500'}
            />
            <Text fontSize='14px' fontWeight={500} color='#1A1D1F'>
              {tpSessionStatus}
            </Text>
          </Flex>
        </SummaryRow>
      </Flex>
    </Box>
  );
};

export default SummaryCard;
