import { Box, Center, Flex, Text, VStack } from '@chakra-ui/react';
import { ReactComponent as Crown } from 'assets/images/nexus/crown.svg';
import { AuthRole } from 'authorization';
import { HeaderColors } from 'configs';
import { useUserDataSelector } from 'hooks';
import React, { useCallback } from 'react';
import { ReactComponent as LocationopeniconIcon } from 'assets/images/training/LocationOpenIcon.svg';
import { ReactComponent as LocationlaunchiconIcon } from 'assets/images/training/LocationLaunchIcon.svg';
// import { ViewType } from './ViewAll/types';

interface ScoreCardProps {
  score: string;
  label: string;
  locationId: string;
  position: number;
  hideRank?: boolean;
  isCrown?: boolean;
  isOwnLocation?: boolean;
}

const ScoreCard: React.FC<ScoreCardProps> = ({
  score,
  label,
  locationId,
  position,
  hideRank,
  isCrown,
  isOwnLocation,
}) => {
  const { loggedInUserAuthRole, loggedInUserLocations } = useUserDataSelector(
    (state) => ({
      loggedInUserAuthRole: state?.authRole,
      loggedInUserLocations: state?.locations || [],
    })
  );

  const renderIcon = (isOwnLocation: boolean) => {
    if (isOwnLocation) {
      return <LocationlaunchiconIcon />;
    } else {
      <LocationopeniconIcon />;
    }
  };

  const yourLocLabel = useCallback(() => {
    let loggedInUserLocationsIds = loggedInUserLocations?.map(
      (loc) => loc?.eid
    );
    if (
      [AuthRole.SUPER_ADMIN, AuthRole.ADMIN]?.includes(loggedInUserAuthRole)
    ) {
      return null;
    }
    if (loggedInUserLocationsIds?.includes(locationId)) {
      return (
        <Flex
          justifyContent={'center'}
          as='span'
          color={HeaderColors.DarkBlue}
          fontWeight={500}
          fontSize='10px'
          noOfLines={1}
        >
          (Your location)
        </Flex>
      );
    }
    return null;
  }, [loggedInUserLocations, loggedInUserAuthRole, locationId]);

  return (
    <VStack
      borderWidth='2px'
      borderRadius='12px'
      // p='24px'
      py='20px'
      w={isCrown ? '10vw' : '8vw'}
      border={`2px solid ${isCrown ? '#8E59FF' : '#2A85FF'}`}
      bg={isCrown ? 'rgba(142, 89, 255, 0.2)' : 'rgba(42, 133, 255, 0.1)'}
      position='relative'
    >
      {isCrown && <Crown style={{ position: 'absolute', top: '-3.2rem' }} />}
      <Box
        as='span'
        px='24px'
        color={isCrown ? '#8E59FF' : '#2A85FF'}
        fontSize={isCrown ? '32px' : '20px'}
        fontWeight={isCrown ? 600 : 500}
      >
        {score}
        {/* {type === 'overall' ? '' : '%'} */}
      </Box>
      <Flex
        justifyContent={'center'}
        alignItems={'center'}
        flexDir='column'
        px='15px'
      >
        <Flex alignItems={'center'} gap={1}>
          {/* <LocationopeniconIcon />{' '} */}
          <Box as='span' fontSize='12px' fontWeight={600} noOfLines={1}>
            {label}
          </Box>
        </Flex>

        {yourLocLabel()}
      </Flex>
      {!hideRank && (
        <Center
          pos='absolute'
          bottom='-1rem'
          boxSize='32px'
          color='white'
          borderRadius='50%'
          fontWeight={500}
          border='3px solid #F4F4F4'
          bg={isCrown ? '#8E59FF' : '#2A85FF'}
        >
          {position}
        </Center>
      )}
    </VStack>
  );
};
export default ScoreCard;
