import { gql } from '@apollo/client';

export const DELETE_TRAINING_PATH = gql`
  mutation DeleteTP($eid: ID!) {
    DeleteTP(eid: $eid) {
      succeed
    }
  }
`;

export const TERMINATE_TRAINING_PATH = gql`
  mutation TerminateTP($eid: ID!) {
    TerminateTP(eid: $eid) {
      succeed
    }
  }
`;
