import React, { FC } from 'react';
import { Flex, FlexProps, IconButton } from '@chakra-ui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-solid-svg-icons';

interface IProps {
  isScrollable?: boolean;
  direction: 'left' | 'right';
  containerProps: {
    left?: FlexProps['left'];
    right?: FlexProps['right'];
    justify: FlexProps['justify'];
    hidden?: boolean;
  };
  isDisabled?: boolean;
  onClick?: () => void;
}

const NavigateButton: FC<IProps> = ({
  isScrollable,
  direction,
  isDisabled,
  containerProps,
  onClick,
}) => {
  const rtDeg = direction === 'left' ? '90deg' : '270deg';

  if (!isScrollable) {
    return null;
  }

  return (
    <Flex
      position='absolute'
      height='36px'
      width='80px'
      bg={`linear-gradient(${rtDeg}, #FFFFFF 0%, rgba(255, 255, 255, 0.6) 81.5%, rgba(255, 255, 255, 0) 100%)`}
      align='center'
      {...containerProps}
    >
      <IconButton
        size='sm'
        variant='ghost'
        aria-label={direction === 'left' ? 'prev' : 'next'}
        isDisabled={isDisabled}
        icon={
          <FontAwesomeIcon
            icon={
              (direction === 'left'
                ? faChevronLeft
                : faChevronRight) as IconProp
            }
            color='#6F767E'
          />
        }
        onClick={onClick}
      />
    </Flex>
  );
};

export default NavigateButton;
