import React, { FC, useCallback, useContext } from 'react';
import { Box, Button, Center, Tooltip } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';
import { ActionType } from 'react-table';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEllipsis } from '@fortawesome/pro-regular-svg-icons';

import { TSortBy } from 'types';
import { shallowEqual, toArray } from 'utils';
import { AuthRole, useAuthorization } from 'authorization';
import { SortByName, SortByNumber } from 'utils/sorting';
import { ActionMenu } from 'ui-components';
import { CHAT_URL } from 'appRoutes';
import { useUserDataSelector } from 'hooks';
import { AmplitudeEvent, deployEvent } from 'shared';

import FormDataContext from 'pages/forms/FormStore/form-data-context';

import { SortingTable } from 'sub-components/ChakraTable/SortingTable';
import EmptyState from 'sub-components/EmptyState';
import FormName from '../FormName';
import FormType from './FormType';
import { getMenuDataHelperFn } from './helper';
import { IFormItemsEntity, IFormListEntity } from 'pages/forms/forms-types';

import DashboardDataContext from 'sub-components/DashboardContainer/DashboardStore/DashboardData/dashboard-data-context';
import { FormSortEntity } from './AllResponseTabPanel';
import { CommonAmplitudeEvents } from 'shared/amplitudeEvents';

type FormEntity = IFormListEntity['items'][number];

interface IProps {
  clickedItemsHandler: (clickedItem: string, form: FormEntity) => void;
  onPageChangeHandler: (newPage: number) => void;
  loading: boolean;
  onSortByChange?: (value: FormSortEntity) => void;
}

const AllResponseFormTable: FC<IProps> = ({
  clickedItemsHandler,
  onPageChangeHandler,
  loading,
  onSortByChange,
}) => {
  const authUser = useUserDataSelector(
    (state) => ({
      eid: state.eid,
      authRole: state.authRole,
      type: state.type,
    }),
    shallowEqual
  );
  const history = useHistory();
  const { t } = useTranslation('form');
  const formCtx = useContext(FormDataContext);
  const dashboardCtx = useContext(DashboardDataContext);
  const selectedPage = dashboardCtx?.navigationPersistData?.forms?.selectedPage;
  const authorization = useAuthorization({
    permittedFor: 'user',
    permittedRoles: [AuthRole.SUPER_ADMIN],
  });

  const hasAccessToForm = useCallback(
    (form: FormEntity) => {
      return (
        authUser?.eid === form?.createdBy?.eid ||
        (authUser?.authRole === AuthRole.SUPER_ADMIN &&
          authUser?.type === 'user') ||
        form?.contributors?.includes(authUser?.eid)
      );
    },
    [authUser]
  );

  const getMenuData = useCallback(
    ({ createdBy, contributors, appearsInSop, status }: FormEntity) => {
      return getMenuDataHelperFn(
        createdBy,
        contributors,
        appearsInSop,
        t,
        authorization,
        status
      );
    },
    [authorization]
  );

  const getColumns = (isTablet: boolean) => {
    let tableColumns: any = [
      {
        Header: t('form_name_header'),
        accessor: 'formName' as const,
        width: 'calc(60% - 80px)',
        Cell: ({ cell: { value, row } }: any) => {
          // Determine if the row should be faded
          const isInactive = value?.status === 'inactive';
          const hasAccess = hasAccessToForm(value);

          return (
            <Box
              onClick={() => {
                if (hasAccess) {
                  // SA/creator/contributor flow: Redirect to Form Response page
                  deployEvent(CommonAmplitudeEvents.VIEW_FORM);
                  history.push(`/forms/response/${value?.eid}`);
                } else if (!isInactive) {
                  // For other users with active forms: Redirect to Form Submit page
                  deployEvent(AmplitudeEvent.FORMS_FILL_FROM_FORMS_LIST);
                  history.push(`/form/submit/${value?.eid}`);
                }
                // Else: Do nothing as form is inactive and you don't have permission to perform any action and inactive form can't be sumitted
              }}
              cursor={hasAccess || !isInactive ? 'pointer' : 'no-drop'}
              opacity={isInactive ? 0.5 : 1}
            >
              <FormName formData={value} />
            </Box>
          );
        },
        sortType: SortByName,
      },
      {
        Header: t('responses'),
        accessor: 'responses' as const,
        width: '20%',
        Cell: ({ cell: { value, row } }: any) => {
          // Determine if the row should be faded
          const isInactive = row?.original?.formName?.status === 'inactive';
          const hasAccess = hasAccessToForm(row?.original?.formName);

          return (
            <Box
              cursor={hasAccess || !isInactive ? 'default' : 'no-drop'}
              opacity={isInactive ? 0.5 : 1}
            >
              <span>{value}</span>
            </Box>
          );
        },
        sortType: SortByNumber,
      },
      {
        Header: t('form_type'),
        accessor: 'formType' as const,
        width: '20%',
        Cell: ({ cell: { value, row } }: any) => {
          // Determine if the row should be faded
          const isInactive = row?.original?.formName?.status === 'inactive';
          const hasAccess = hasAccessToForm(row?.original?.formName);

          return (
            <Box
              cursor={hasAccess || !isInactive ? 'default' : 'no-drop'}
              opacity={isInactive ? 0.5 : 1}
            >
              <FormType text={value} index={row?.index} />
            </Box>
          );
        },
        sortType: SortByName,
      },
      {
        Header: '',
        accessor: 'action' as const,
        width: '80px',
        disableSortBy: true,
      },
    ];
    return tableColumns;
  };

  const actionMenuRender = (item: IFormItemsEntity) => {
    const isInactive = item.status === 'inactive';
    const opacityStyle = isInactive ? { opacity: 0.5 } : {};
    if (
      authUser?.eid === item?.createdBy?.eid ||
      (authUser?.authRole === AuthRole.SUPER_ADMIN &&
        authUser?.type === 'user') ||
      item?.contributors?.includes(authUser?.eid)
    ) {
      return (
        <Center>
          <ActionMenu
            menuData={getMenuData(item)}
            arrowSize={10}
            closeOnBlur={true}
            offset={[0, 0]}
            clickedItem={(_, clickedItem) => {
              clickedItem && clickedItemsHandler(clickedItem, item);
            }}
          >
            <Box cursor='pointer'>
              <FontAwesomeIcon
                icon={faEllipsis as IconProp}
                size='2x'
                color='#33383F'
                style={opacityStyle}
              />
            </Box>
          </ActionMenu>
        </Center>
      );
    }
    return (
      <Center>
        <Tooltip label='You are not allowed to perform the action' hasArrow>
          <Box cursor='no-drop' w='fit-content'>
            <FontAwesomeIcon
              icon={faEllipsis as IconProp}
              size='2x'
              color='#33383F'
              style={opacityStyle}
            />
          </Box>
        </Tooltip>
      </Center>
    );
  };

  const getTableData = (isTablet: boolean) => {
    const tableData = formCtx.formsListData?.items?.map(
      (item, index: number) => {
        return {
          item: item,
          formName: item,
          responses: item.responseCount,
          formType: item.category,

          action: actionMenuRender(item),
        };
      }
    );
    return [...tableData];
  };

  const updateSortBy = (values: TSortBy) => {
    const newValue: FormSortEntity = match(values)
      .with({ id: 'formName' }, (sel) =>
        sel.desc ? 'TITLE_ASC' : 'TITLE_DESC'
      )
      // .with({ id: 'responses' }, (sel) =>
      //   sel.desc ? 'CREATEDAT_ASC' : 'CREATEDAT_DESC'
      // )
      .with({ id: 'formType' }, (sel) =>
        sel.desc ? 'CATEGORY_ASC' : 'CATEGORY_DESC'
      )
      .otherwise(() => '_ID_DESC');
    onSortByChange?.(newValue);
  };

  const stateReducer = <T extends unknown>(newState: T, action: ActionType) => {
    switch (action?.type) {
      case 'toggleSortBy':
        // @ts-ignore
        updateSortBy(toArray<TSortBy>(newState?.sortBy)[0]);
        break;
    }

    return newState;
  };

  return (
    <SortingTable
      colorScheme='blue'
      customWrapperStyles={{ marginTop: '1rem' }}
      emptyData={{
        content: (
          <>
            {[
              AuthRole.SUPER_ADMIN,
              AuthRole,
              AuthRole.ADMIN,
              AuthRole.LOCATION_OWNER,
            ]?.includes(authUser?.authRole) &&
              authUser?.type === 'user' && (
                <EmptyState
                  image='FormPermission'
                  title={t('formEmptyStateHighLvlTitle')}
                  description={t('formEmptyStateHighLvlDesc')}
                  showButton
                  buttonProps={
                    <Button
                      colorScheme='blue'
                      variant='solid'
                      onClick={() => {
                        deployEvent(AmplitudeEvent.FORMS_ADD_WHEN_NO_FORM);
                        history.push('/forms/create');
                      }}
                    >
                      + {t('addNewForm')}
                    </Button>
                  }
                />
              )}
            {/* <Authorize
                      permittedFor={['user']}
                      permittedRoles={[
                        AuthRole.SUPER_ADMIN,
                        AuthRole.ADMIN,
                        AuthRole.LOCATION_OWNER,
                      ]}
                    >
                      <Center height={'100%'} flexDir='column'>
                        <img src={FormEmptyState} />
                        <strong style={{ fontWeight: '700', fontSize: '16px' }}>
                          {t('no_form_found')}
                        </strong>
                        <span
                          style={{
                            fontWeight: '500',
                            fontSize: '14px',
                            color: '#6F767E',
                          }}
                        >
                          {t('click_on_button')}
                        </span>
                        <span
                          style={{
                            fontWeight: '500',
                            fontSize: '14px',
                            color: '#6F767E',
                          }}
                        >
                          {t('add_form')}
                        </span>
                      </Center>
                    </Authorize> */}
            {([AuthRole.WORKER]?.includes(authUser?.authRole) ||
              authUser?.type === 'branch') && (
              <EmptyState
                image='FormPermission'
                title={t('formEmptyStateLowLvlTitle')}
                description={t('formEmptyStateLowLvlDesc')}
                showButton
                buttonProps={
                  <Button
                    colorScheme='blue'
                    variant='solid'
                    onClick={() => history.push(CHAT_URL)}
                  >
                    {t('contactLocationOwner')}
                  </Button>
                }
              />
            )}
            {/* <Authorize
                      permittedFor={['branch']}
                      permittedRoles={[AuthRole.WORKER]}
                    >
                      <Center height={'100%'} flexDir='column'>
                        <img src={FormEmptyState} />
                        <strong style={{ fontWeight: '700', fontSize: '16px' }}>
                          {t('no_form_found')}
                        </strong>
                        <span
                          style={{
                            fontWeight: '500',
                            fontSize: '14px',
                            color: '#6F767E',
                          }}
                        >
                          {t('form_will_appear')}
                        </span>
                        <span
                          style={{
                            fontWeight: '500',
                            fontSize: '14px',
                            color: '#6F767E',
                          }}
                        >
                          {t('admin_creates')}
                        </span>
                      </Center>
                    </Authorize> */}
          </>
        ),
      }}
      page={selectedPage}
      onPageChange={onPageChangeHandler}
      columns={getColumns(false)}
      data={getTableData(false)}
      isLoading={loading}
      totalRegisters={formCtx?.formsListData?.pageInfo?.itemCount}
      stateReducer={stateReducer}
      options={{
        autoResetSortBy: false,
      }}
      tableLayout='auto'
      isResponsive
      isHidePagination={
        (selectedPage === 1 && formCtx?.formsListData?.pageInfo?.hasNextPage) ||
        selectedPage > 1
          ? false
          : true
      }
    />
  );
};

AllResponseFormTable.displayName =
  'displayName:pages/forms/modules/FormListInternal/AllResponseFormTable';

export default AllResponseFormTable;
