import { FormCategory, QuestionType, VisibilityConfig } from '../../../types';

interface Option {
  eid?: string;
  label: string;
  subTitle: string;
  isCorrect: boolean;
  isDisabled: boolean;
  isArchieved?: boolean;
  isOther?: boolean;
}

export interface Question {
  eid?: string;
  label: string;
  subTitle?: string;
  qType: QuestionType;
  isRequired?: boolean;
  options: Option[];
  hasAnalytics?: boolean;
  isDisabled?: boolean;
  isArchieved?: boolean;
  hasOtherOption?: boolean;
  hasImageNote?: boolean;
}

export type FormStatus = 'active' | 'inactive' | 'archieved';

export interface IFormInput extends Partial<VisibilityConfig> {
  eid?: string;
  formCategory: FormCategory;
  title: string;
  thumbnail: string;
  description: string;
  questions: Question[];
  status?: FormStatus;
  contributors?: string[];
  isReadOnly?: boolean;
  notifyVia?: ('sms' | 'email')[];
  responseVisibility?: 'public' | 'private';

  originalQues?: Question[]; // Used to track original question
}

export const OptionWhiteList: QuestionType[] = [
  QuestionType.CHECKBOX,
  QuestionType.MULTI_CHOICE,
  QuestionType.DROPDOWN,
];
