import React, { FC, useMemo } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { Box, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { v4 as generateEid } from 'uuid';
import { Button } from 'atoms';
import { AuditHelper } from 'configs';
import { ListSortable } from 'ui-components';

import { IFormInput, OptionWhiteList } from '../../../form-types';
import AuditOptionInput from './AuditOptionInput';
import AuditFlagAndScoring from '../AuditFlagAndScoring';

interface IProps {
  questionIndex: number;
  isReadOnly?: boolean;
}

const AuditOptionList: FC<IProps> = ({ questionIndex, isReadOnly }) => {
  const { t } = useTranslation('form');
  const { getValues } = useFormContext<IFormInput>();
  const { fields, append, remove, insert, update, move } = useFieldArray<
    IFormInput,
    `questions.${number}.options`
  >({
    name: `questions.${questionIndex}.options`,
  });

  const [isAuditQuestion, questionType] = useWatch<
    IFormInput,
    ['isAuditQuestion', `questions.${number}.qType`]
  >({
    name: ['isAuditQuestion', `questions.${questionIndex}.qType`],
  });

  const onAddOptionClick = () => {
    const options = getValues(`questions.${questionIndex}.options`) || [];
    const filteredOption = options.filter((val) => !val.isOther);

    insert(
      filteredOption.length,
      {
        eid: undefined,
        tempEid: generateEid(),
        label: '',
        subTitle: '',
        score: undefined,
        isCorrect: false,
        isDisabled: false,
        isFlagged: false,
        requiresEvidence: false,
        isOther: false,
      },
      {
        shouldFocus: true,
        focusName: `questions.${questionIndex}.options.${filteredOption.length}.label`,
      }
    );
  };

  const onAddOtherClick = () => {
    const options = getValues(`questions.${questionIndex}.options`) || [];
    const filteredOption = options.filter((val) => !val.isOther);

    if (options.length === filteredOption.length) {
      append({
        label: 'Other',
        isCorrect: false,
        isDisabled: false,
        subTitle: '',
        isOther: true,
      });
    }
  };

  const onRemove = (index: number) => {
    const option = getValues(`questions.${questionIndex}.options.${index}`);
    if (isAuditQuestion && option?.eid) {
      update(index, {
        ...option,
        isDisabled: true,
      });
    } else {
      remove(index);
    }
    const options = getValues(`questions.${questionIndex}.options`) || [];
    if (!options.filter((O) => !O.isDisabled).length) {
      const filteredOption = options.filter((val) => !val.isOther);
      insert(
        filteredOption.length,
        {
          eid: undefined,
          tempEid: generateEid(),
          label: '',
          subTitle: '',
          score: undefined,
          isCorrect: false,
          isDisabled: false,
          isFlagged: false,
          requiresEvidence: false,
          isOther: false,
        },
        {
          shouldFocus: true,
          focusName: `questions.${questionIndex}.options.${filteredOption.length}.label`,
        }
      );
    }
    setTimeout(AuditHelper.save, 100);
  };

  const hideAddOther = useMemo(() => {
    return true;
    // if (questionType === QuestionType.DROPDOWN) {
    //   return true;
    // }
    // return fields?.findIndex((val) => val.isOther) > -1;
  }, [fields, questionType]);

  const hasScoring = useWatch<IFormInput, `questions.${number}.hasScoring`>({
    name: `questions.${questionIndex}.hasScoring`,
  });

  if (!OptionWhiteList.includes(questionType)) {
    return null;
  }

  return (
    <Box
      border='1px solid #C7C7C7'
      borderRadius='10px'
      padding='15px 20px'
      bg='white'
    >
      <AuditFlagAndScoring questionIndex={questionIndex} />

      <ListSortable
        disabled={isReadOnly}
        items={fields}
        onDragEnd={(oldIndex, newIndex) => move(oldIndex, newIndex)}
      >
        {fields?.map((field, index, arr) => {
          return (
            <AuditOptionInput
              questionIndex={questionIndex}
              optionIndex={index}
              totalOptions={arr.length}
              key={field.id}
              fieldId={field.id}
              hasScoring={hasScoring}
              isReadOnly={isReadOnly}
              onRemove={() => !isReadOnly && onRemove(index)}
            />
          );
        })}
      </ListSortable>

      {!isReadOnly ? (
        <Flex gap='16px' mt={5}>
          <Button
            variant='link'
            colorScheme='blue'
            fontSize='14px'
            onClick={onAddOptionClick}
          >
            {t('add_option')}
          </Button>
          {!hideAddOther && (
            <Button
              variant='link'
              colorScheme='blue'
              fontSize='14px'
              onClick={onAddOtherClick}
            >
              {t('addOther')}
            </Button>
          )}
        </Flex>
      ) : null}
    </Box>
  );
};

export default AuditOptionList;
