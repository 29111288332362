import React, { useCallback } from 'react';
import { useService } from 'sub-components/training-v2/play/layers';

type DownloadCertificateType = {
  certificateId?: string;
  tpSessionId?: string;
  isCertificateDownloaded?: boolean;
};

export const useDownloadCertificate = () => {
  const {
    downloadCertificateFlag: { updateDownloadCertificateFlag },
  } = useService();

  return useCallback(
    ({
      certificateId,
      tpSessionId,
      isCertificateDownloaded,
    }: DownloadCertificateType) => {
      if (process.env.REACT_APP_OPS_ENDPOINT) {
        if (tpSessionId && !isCertificateDownloaded) {
          updateDownloadCertificateFlag({
            variables: {
              sessionId: tpSessionId,
            },
          });
        }
        window.open(
          `${process.env.REACT_APP_OPS_ENDPOINT}/tp/certify/${certificateId}`,
          '_blank'
        );
      }
    },
    []
  );
};
