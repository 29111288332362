import React, { FC } from 'react';
import { Tooltip as ChakraTooltip, TooltipProps } from '@chakra-ui/react';

interface IProps extends TooltipProps {
  truncateLength?: number;
}

const Tooltip: FC<IProps> = ({
  children,
  truncateLength = 25,
  label,
  ...props
}) => {
  const isTruncated =
    typeof children === 'string' && children.length > truncateLength;
  const showTooltip = !!label || isTruncated;
  let content = children;

  if (isTruncated) {
    content = `${(children as string).substring(0, truncateLength)}...`;
  }

  return showTooltip ? (
    <ChakraTooltip label={label || (isTruncated ? children : '')} {...props}>
      <span>{content}</span>
    </ChakraTooltip>
  ) : (
    <>{content}</>
  );
};

export default Tooltip;
