import {
  Box,
  Center,
  Checkbox,
  Divider,
  Flex,
  TabList,
  Tabs,
  useControllableState,
} from '@chakra-ui/react';
import SearchInput from 'atoms/SearchInput';
import React, { ChangeEvent, forwardRef, useMemo } from 'react';
import EmptyState from './EmptyState';
import { TabItem } from 'atoms';
import { useSafeState } from 'hooks';
import { searchRegExp } from 'utils';
import { ReactComponent as RocketIcon } from '../../../../../../assets/images/rocket.svg';
import { ReactComponent as ZapIcon } from '../../../../../../assets/images/zap-light.svg';
import { ITypes } from '../bottomSection/Header';

interface IProps {
  filters: any;
  handleLocationsSelection?: (
    e: React.ChangeEvent<HTMLInputElement>,
    status: ITypes
  ) => void;
  value?: string[];
  onChange?: (value: string[]) => void;
  clearFilters?: () => void;
}

const LocationFilter = forwardRef<HTMLElement, IProps>(
  (
    { filters, handleLocationsSelection, clearFilters, value = [], onChange },
    ref
  ) => {
    const [tabValue, updateTabValue] = useControllableState<number>({
      defaultValue: 0,
    });
    const [locationsSearchText, setLocationsSearchText] = useSafeState('');
    const locations = filters?.locations || [];

    const { openLocation, launching } = useMemo(() => {
      return locations.reduce(
        (acc, value) => {
          if (value.locStatus === 'development') {
            acc.launching.push(value);
          } else {
            acc.openLocation.push(value);
          }
          return acc;
        },
        {
          openLocation: [] as typeof locations,
          launching: [] as typeof locations,
        }
      );
    }, [locations]);

    const locationList = useMemo(() => {
      const data =
        tabValue === 2 ? launching : tabValue === 1 ? openLocation : locations;
      if (!locationsSearchText?.trim()) {
        return data;
      }
      const reg = searchRegExp(locationsSearchText, 'gi');
      return data.filter((value) => value.name?.match(reg));
    }, [locations, openLocation, launching, tabValue, locationsSearchText]);

    const onSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
      const selectedIds = event.target.checked
        ? locationList.map((loc) => loc.eid)
        : [];
      onChange?.(selectedIds);
    };

    const handleCheckboxChange = (
      e: ChangeEvent<HTMLInputElement>,
      loc: ITypes
    ) => {
      const newValue = e.target.checked
        ? [...value, loc.eid]
        : value.filter((id) => id !== loc.eid);
      onChange?.(newValue);
      handleLocationsSelection?.(e, loc);
    };

    return (
      <>
        <SearchInput
          className='uploaded-by-search-field'
          value={locationsSearchText}
          onChange={(e) => setLocationsSearchText(e.target.value)}
          placeholder='Search by location name'
          hideShortcuts
        />
        <Divider mt='12px' mb='12px' />
        <Tabs index={tabValue} onChange={updateTabValue} isLazy>
          <TabList
            borderBottom='1px solid'
            position='sticky'
            top='0'
            zIndex='1'
            bg='white'
          >
            <TabItem fontSize='12px' color='#6F767E' fontWeight={600}>
              All ({locations?.length})
            </TabItem>
            <TabItem fontSize='12px' color='#6F767E' fontWeight={600}>
              Open ({openLocation.length})
            </TabItem>
            <TabItem fontSize='12px' color='#6F767E' fontWeight={600}>
              Launching ({launching.length})
            </TabItem>
          </TabList>
        </Tabs>
        <Box minH='200px' maxH='400px' overflowY='auto'>
          <Flex paddingInline='12px' paddingY={4}>
            <Box
              flex={1}
              fontSize='13px'
              fontWeight='600'
              color='#6F767E'
              textTransform='uppercase'
            >
              Select all
            </Box>
            <Checkbox value='all' size='lg' onChange={onSelectAll} />
          </Flex>
          {locationList.length > 0 ? (
            locationList.map((item) => {
              const isInDev = item.locStatus === 'development';
              const Icon = isInDev ? RocketIcon : ZapIcon;
              return (
                <React.Fragment key={item.eid}>
                  <Flex
                    gap={3}
                    height='48px'
                    minHeight='48px'
                    alignItems='center'
                    paddingInline='12px'
                    borderRadius='12px'
                    _hover={{ bg: '#F4F4F3' }}
                  >
                    <Center
                      boxSize='24px'
                      borderRadius='5px'
                      bg={isInDev ? '#8E59FF' : '#83BF6E'}
                    >
                      <Icon width={12} height={12} />
                    </Center>
                    <Box
                      flex={1}
                      fontSize='14px'
                      fontWeight='400'
                      lineHeight='21px'
                      isTruncated
                      color='#1A1D1F'
                    >
                      {item.name}
                    </Box>
                    <Checkbox
                      value={item.eid}
                      size='lg'
                      isChecked={value.includes(item.eid)}
                      onChange={(e) => handleCheckboxChange(e, item)}
                    />
                  </Flex>
                  <Divider />
                </React.Fragment>
              );
            })
          ) : (
            <EmptyState view='statuses' />
          )}
        </Box>
      </>
    );
  }
);

LocationFilter.displayName =
  'sub-components/training-v2/dashboard/components/TrackPathDashboard/bottomSection/Filter/LocationFilter.tsx';
export default LocationFilter;
