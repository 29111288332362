import { cloneDeep } from '@apollo/client/utilities';
import { UserData } from 'sop-commons/src';
import { AuthRole } from '../../../../../../../authorization';
import { UserEntityData } from '../../../../../../../shared/graphql/shared-types';
import { TFormInput } from '../../../training-input.types';
import { haveOnlyWorkers } from './useHaveOnlyWorkers';

const Admins = [AuthRole.SUPER_ADMIN, AuthRole.ADMIN];

const getUserLocation = (entityUsers: UserEntityData[], userIds: string[]) => {
  if (!userIds?.length) {
    return [];
  }

  return entityUsers.reduce((acc, user) => {
    if (userIds.includes(user.eid)) {
      acc.push(...user.locations.map((loc) => loc.eid));
    }

    return acc;
  }, [] as string[]);
};

const getAssigneeLocations = (
  onlyWorkers: boolean,
  assignees: TFormInput['assignees']
) => {
  if (!onlyWorkers) {
    return [];
  }

  return assignees.flatMap((assignee) => {
    if (assignee.type === 'authRole') {
      return assignee.locationIds;
    }
    return [];
  });
};

export function getSuperVisors(
  authUser: Partial<UserData>,
  entityUsers: UserEntityData[] = [],
  values: Partial<TFormInput> = {}
): UserEntityData[] {
  // const authUserLocations = (authUser?.locations || []).map((loc) => loc.eid);

  const assignees = values?.assignees || [];

  // const userIds = values.userIds || [];
  const userIds = assignees.flatMap((assignee) => {
    if (assignee.type === 'user') {
      return assignee.userIds;
    }
    return [];
  });

  const userLocations = getUserLocation(entityUsers, userIds);

  const _entityUsers = cloneDeep(entityUsers);

  const onlyWorkers = haveOnlyWorkers(_entityUsers, assignees);

  const assigneeLocations = getAssigneeLocations(onlyWorkers, assignees);

  const haveAssignByUser = assignees.some(
    (assignee) => assignee.type === 'user'
  );
  const haveAssignByRole = assignees.some(
    (assignee) => assignee.type === 'authRole'
  );

  return _entityUsers.filter((user) => {
    if (
      user.status !== 'active' ||
      user.type === 'branch' ||
      user.authRole === AuthRole.WORKER
    ) {
      return false;
    }

    // Superadmin or Admin roles
    if (Admins.includes(user.authRole)) {
      return true;
    }

    // If the user is a locationOwner and is connected to any location of the authUser, include them
    // if (authUser.authRole === AuthRole.LOCATION_OWNER) {
    //   return (
    //     user.authRole === AuthRole.LOCATION_OWNER &&
    //     user.locations.some((loc) => authUserLocations.includes(loc.eid))
    //   );
    // }

    if (haveAssignByUser) {
      // If the creator is an Admin or Superadmin, add user filters for worker roles
      if (
        Admins.includes(authUser?.authRole!) &&
        user.authRole === AuthRole.LOCATION_OWNER
      ) {
        if (
          onlyWorkers &&
          userLocations.some((loc) => user.locations.some((l) => loc === l.eid))
        ) {
          return true;
        }
      }
    } else if (
      haveAssignByRole &&
      onlyWorkers &&
      user.authRole === AuthRole.LOCATION_OWNER
    ) {
      if (
        assigneeLocations.some((loc) =>
          user.locations.some((l) => loc === l.eid)
        )
      ) {
        return true;
      }
    }

    return false;
  });
}
