import React, { useCallback, useRef } from 'react';
import { AlertDialogCloseButton, Flex } from '@chakra-ui/react';
import { HeaderColors } from '../../../../../../../configs';
import {
  BoxHeader,
  useConfirm,
  UseConfirm,
} from '../../../../../../../ui-components';

interface IProps {
  onConfirm?: () => void;
}

type MultiChoiceConfirm = (props?: IProps) => void;

export const useMultiChoiceConfirm = (): MultiChoiceConfirm => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();

  return useCallback((props) => {
    if (confirmRef.current) {
      confirmRef.current?.destroy();
    }

    confirmRef.current = confirm({
      title: (
        <Flex gap='12px' justify='space-between'>
          <BoxHeader title='Just a quick check!' color={HeaderColors.Purple} />

          <AlertDialogCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
          />
        </Flex>
      ),

      content: (
        <Flex fontSize='15px' fontWeight='600' color='#6F767E'>
          You’ve enabled the toggle for multiple correct answers, but only one
          answer is selected. Are you sure you want to proceed without selecting
          more answers?
        </Flex>
      ),

      isCentered: true,
      contentProps: {
        borderRadius: '8px',
        maxWidth: '640px',
        paddingY: 2,
      },
      bodyProps: { paddingY: 4 },
      okText: 'Proceed',
      okButtonProps: { flex: 1, size: 'lg' },
      onOK: props?.onConfirm,
      cancelText: 'Go back',
      cancelButtonProps: { flex: 1, size: 'lg', variant: 'outline' },
    });
  }, []);
};
