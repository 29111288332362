import {
  Box,
  Center,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import React, { FC, useEffect, useState } from 'react';
import RenderScoreCard from './RenderScoreCards';
import BaseListRender from './BaseListRender';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowRight, faRocketLaunch } from '@fortawesome/pro-light-svg-icons';
import { Button, Table } from 'atoms';
import { BoxHeader, Image } from 'ui-components';
import { HeaderColors } from 'configs';
import { ColumnsType } from 'antd/es/table';
import { useLazyQuery } from '@apollo/client';
import { GET_OVERALL_LOCATION_WISE_ANALYTICS } from 'sub-components/training-v2/shared/query/dashboard.graphql';
import {
  IFinalOverallLocationWiseAnalytics,
  IOverallLocationWiseAnalytics,
} from 'sub-components/training-v2/shared/types';
import { EntityLocations, LocationsEntity } from 'sop-commons/src/client';
import { sortArray } from './helper';
import { ReactComponent as Sort_ascendingIcon } from 'assets/images/nexus/compliance/sort_ascending.svg';
import openLocation from '../../../../../assets/images/training/openLocationTypeIcon.svg';
import launchLocation from '../../../../../assets/images/training/launcherLocationIcon.svg';
import EmptyState from 'sub-components/EmptyState';

interface IProps {
  tab: string;
  businessLocations: EntityLocations[] | undefined;
  loggedInUserLocations: LocationsEntity[] | undefined;
}

const LocationList: FC<IProps> = ({
  tab,
  businessLocations,
  loggedInUserLocations,
}) => {
  const [overallLocationWiseData, setOverallLocationWiseData] = useState<
    IFinalOverallLocationWiseAnalytics[]
  >([]);
  const { isOpen, onClose, onOpen } = useDisclosure();

  // get location wise data from API
  const [getOverallLocationWiseAnalytics, { loading: locationWiseLoader }] =
    useLazyQuery(GET_OVERALL_LOCATION_WISE_ANALYTICS, {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        // Start with all business locations

        const locationWiseData =
          businessLocations
            ?.map((location) => {
              if (!location?.isRoot) {
                const analytics = data?.TpOverallLocationsWiseAnalytics?.find(
                  (item: IOverallLocationWiseAnalytics) =>
                    item?.locationId === location?.eid
                );
                const isOwnLocation = !!loggedInUserLocations?.find(
                  (loc) => loc?.eid === location?.eid
                );
                const completed = analytics?.completed || 0;
                const total = analytics?.total || 0;
                const completionPercent = total ? (completed / total) * 100 : 0;

                return {
                  locationId: location?.eid,
                  name: location?.name,
                  completed,
                  total,
                  completionPercent,
                  isOwnLocation,
                  status: location.locationStatus,
                };
              }
            })
            ?.filter((item) => item) || [];

        // Sort and assign ranks
        const rankedData = sortArray(
          locationWiseData,
          'completionPercent',
          'desc'
        ).map((item, index) => ({
          ...item,
          rank: index + 1,
        }));

        setOverallLocationWiseData(rankedData);
      },
    });

  const columns: ColumnsType = [
    {
      title: 'Rank',
      dataIndex: 'rank',
      key: 'rank',
      sorter: (a, b) => a.rank - b.rank,
      sortIcon: ({ sortOrder }) => <Sort_ascendingIcon cursor={'pointer'} />,
      width: '20%',
      align: 'center',
      render: (rank) => {
        return (
          <Flex as='span' fontWeight={500} justifyContent='center'>
            {rank}
          </Flex>
        );
      },
    },
    {
      title: 'Location',
      dataIndex: 'name',
      key: 'name',
      width: '60%',
      render: (_, record) => (
        <Flex color='#171725' fontWeight={500} gap={2}>
          {record.status === 'open' ? (
            <Center
              width='24px'
              height='24px'
              background='#83BF6E'
              borderRadius='5px'
            >
              <Image src={openLocation} width={16} height={16} />
            </Center>
          ) : (
            <Center
              width='24px'
              height='24px'
              background='#8E59FF'
              borderRadius='5px'
            >
              <Image src={launchLocation} width={16} height={16} />
            </Center>
          )}

          {record.name}
        </Flex>
      ),
    },

    {
      title: 'Rate(%)',
      dataIndex: 'completionPercent',
      key: 'completionPercent',
      width: '25%',
      align: 'center',
      render: (completionPercent) => (
        <Flex color='#000000' fontWeight={500} gap={2} justifyContent='center'>
          {completionPercent?.toFixed(2)}
        </Flex>
      ),
    },
  ];

  useEffect(() => {
    getOverallLocationWiseAnalytics();
  }, []);

  return (
    <Flex
      width={'100%'}
      gap={8}
      flexDir={'column'}
      mt={'35px'}
      alignItems={'center'}
    >
      {overallLocationWiseData?.length ? (
        <>
          <RenderScoreCard
            type='overall'
            completeData={overallLocationWiseData}
          />
          <BaseListRender
            tab={tab}
            overallLocationWiseData={
              overallLocationWiseData?.length > 3
                ? overallLocationWiseData.slice(
                    3,
                    overallLocationWiseData?.length
                  )
                : []
            }
          />

          <Button
            variant='unstyled'
            color='#2A85FF'
            fontWeight={600}
            alignItems='center'
            gap={2}
            display='flex'
            onClick={onOpen}
          >
            View all
            <FontAwesomeIcon
              icon={faArrowRight as IconProp}
              fontSize='15px'
              color='#2A85FF'
            />
          </Button>

          <Modal isOpen={isOpen} onClose={onClose} isCentered size='2xl'>
            <ModalOverlay />
            <ModalContent padding='12px' borderRadius='12px'>
              <ModalHeader>
                <BoxHeader
                  title='Training completion rate'
                  color={HeaderColors.Yellow}
                />
              </ModalHeader>
              <ModalCloseButton top='1.5rem' right='1.5rem' fontSize='18px' />
              <ModalBody display='flex' flexDirection='column' gap={5}>
                <Text as='span' color='#272B30' fontWeight={600}>
                  All locations at a glance
                </Text>
                <Box maxHeight={'504px'} overflowY={'scroll'}>
                  <Table
                    columns={columns}
                    dataSource={overallLocationWiseData}
                    rowKey='id'
                    pagination={false}
                    bordered
                  />
                </Box>
              </ModalBody>
            </ModalContent>
          </Modal>
        </>
      ) : (
        <Center height='100%' width='100%'>
          <EmptyState image='Report' title='No data found' imageSize='300px' />
        </Center>
      )}
    </Flex>
  );
};

export default LocationList;
