import { FC } from 'react';
import { Box, Flex, Text, Img, Divider } from '@chakra-ui/react';
import {
  MultipleChoice,
  CheckboxQuestions,
  DropdownQuestions,
  ShortAnswerQuestions,
  ParagraphQuestions,
  RecordVideo,
  FileUpload,
  Members,
  Location,
  ImageUpload,
} from './audit-response-type';
import { QuestionType } from 'types';
import MediaSummary from '../MediaSummary';
import { generateValidId } from '../helper';
import { ReactComponent as FlagFilledRedIcon } from 'assets/images/audits/FlagFilledRed.svg';
import { Image } from 'ui-components';

interface IProps {
  title: string;
  qType?: QuestionType;
  section?: string;
  index?: number;
  entityUsers?: any;
  setCheckIfMembersLoaded?: any;
  isFlaggedSection?: boolean;
  flaggedItemsId?: string[];
}

const AuditResponse: FC<IProps> = ({
  children,
  title,
  index,
  section,
  entityUsers,
  setCheckIfMembersLoaded,
  qType,
  isFlaggedSection = false,
  flaggedItemsId,
  ...rest
}) => {
  const render = () => {
    switch (qType) {
      case QuestionType.MULTI_CHOICE:
        return <MultipleChoice {...rest} />;
      case QuestionType.CHECKBOX:
        return <CheckboxQuestions {...rest} />;
      case QuestionType.DROPDOWN:
        return <DropdownQuestions {...rest} />;
      case QuestionType.SHORT_TEXT:
        return <ShortAnswerQuestions {...rest} />;
      case QuestionType.LONG_TEXT:
        return <ParagraphQuestions {...rest} />;
      case QuestionType.VIDEO_UPLOAD:
        return <RecordVideo {...rest} />;
      case QuestionType.FILE_UPLOAD:
        return <FileUpload {...rest} />;
      case QuestionType.MEMBER:
        return <Members {...rest} entityUserFromPublicReport={entityUsers} />;
      case QuestionType.LOCATION:
        return <Location {...rest} entityUserFromPublicReport={entityUsers} />;
      case QuestionType.IMAGE_UPLOAD:
        return <ImageUpload {...rest} />;

      default:
        return <MediaSummary />;
    }
  };

  const imgDomain =
    process.env.REACT_APP_DEPLOYMENT_TYPE === 'production'
      ? 'https://images.delightree.com/prod'
      : 'https://images.delightree.com/staging';

  return (
    <Box>
      <Flex
        flexDir={qType === 'longText' ? 'column' : 'row'}
        // alignItems={'center'}
        fontWeight={500}
        padding={'0px 1.5rem'}
        marginTop={'10px'}
        paddingBottom={'10px'}
        scrollMargin={'8vh'}
        id={generateValidId(section + '-' + title)}
      >
        <Flex flexDir={'column'} width={'50%'}>
          <Flex alignItems={'center'} gap={4}>
            <Text fontWeight={600} fontSize={'18px'}>
              {`Q${rest?.questionIndex}. ${title}`}{' '}
            </Text>
            {flaggedItemsId?.includes(rest?.questionId) && (
              <FlagFilledRedIcon height={'20px'} />
            )}
          </Flex>
        </Flex>

        <Flex
          width={qType === 'longText' ? '100%' : '50%'}
          direction={qType !== 'longText' && 'column'}
          margin={qType === 'longText' && '20px 50px 0px 0px'}
          justifyContent={'flex-start'}
          alignItems={'flex-end'}
          gap={2}
          fontSize={'15px'}
        >
          {render()}
        </Flex>
      </Flex>
      <Flex flexDir={'column'}>
        {rest?.hasOwnProperty('answer') && (
          <Flex>
            {rest?.noteText && (
              <Flex
                gap={2}
                border={'1px solid #EFEFEF'}
                width={'100%'}
                mx={'1.5rem'}
                mb={'10px'}
                alignItems={'center'}
                p={'12px 16px'}
                borderRadius={'16px'}
                backgroundColor={isFlaggedSection ? '#FF6A550D' : '#FCFCFC'}
                minHeight={'30px'}
              >
                <Text fontSize={'14px'}>Auditor Note: </Text>
                {rest?.noteText && (
                  <Text color={'#7E6F6F'} fontWeight={500} fontSize={'14px'}>
                    {rest?.noteText}
                  </Text>
                )}
              </Flex>
            )}
          </Flex>
        )}
        <Flex mx={'1.5rem'} gap={'17px'} wrap={'wrap'}>
          {rest?.media?.length > 0 &&
            rest?.media?.map((item: string, index: number) => {
              return (
                <Flex cursor={'pointer'} flexDir={'column'} key={index}>
                  <Img
                    // lqip={undefined}
                    // loading={undefined}
                    // style={{
                    //   margin: '5px',
                    //   height: '120px',
                    //   borderRadius: '12px',
                    // }}
                    borderRadius={'12px'}
                    margin={'5px'}
                    height={'120px'}
                    src={`${imgDomain}/resize?height=${120}&image=${item}`}
                    alt={'media image'}
                  />
                </Flex>
              );
            })}
        </Flex>
      </Flex>
      <Flex mx={'1.5rem'}>
        <Divider />
      </Flex>
    </Box>
  );
};

export default AuditResponse;
