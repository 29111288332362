import React, { FC, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Box, Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { callAll } from '@chakra-ui/utils';
import moment from 'moment';

import TitleHeader from '../../../CardEditor/TitleHeader';
import CheckButton from './CheckButton';
import { IFormInput } from '../task.types';
import { CustomDatePicker } from './CustomDatePicker';
import { CustomTimePicker } from './CustomTimePicker';
import { startTimeValidation } from '../validation/startTimeValidation';
import { TimezoneInfo } from './TimezoneInfo';
import DatePickerCustom from 'ui-components/DatePicker/DatePicker';

interface IProps {
  isEdit?: boolean;
}

const PublishDate: FC<IProps> = ({ isEdit }) => {
  const { t } = useTranslation(['task']);
  const { control, setValue, getValues } = useFormContext<IFormInput>();

  const startDateType = useWatch<IFormInput, 'startDateType'>({
    name: 'startDateType',
  });
  const startDate = useWatch<IFormInput, 'startDate'>({
    name: 'startDate',
  });

  const resetField = (value: IFormInput['startDateType']) => {
    switch (value) {
      case 'now':
        setValue('startDate', undefined);
        setValue('startTime', undefined);
    }
  };

  const minTime = useMemo(() => {
    if (startDateType === 'now') {
      return new Date();
    }
    if (moment(startDate).isSame(moment(), 'day')) {
      return new Date();
    }

    return undefined;
  }, [startDateType, startDate]);

  return (
    <div>
      <TitleHeader
        title={t('task:whenToPublish')}
        desc={t('task:whenToPublishDesc')}
      />

      <Controller
        control={control}
        name='startDateType'
        rules={{
          required: t('task:validation.publishRequired'),
          deps: ['taskDue', 'endCondition.endDate', 'startTime'],
        }}
        render={({ field, fieldState }) => {
          return (
            <FormControl
              my={3}
              isInvalid={!!fieldState.error}
              isReadOnly={isEdit}
            >
              <Flex gap={2}>
                <CheckButton
                  isReadOnly={isEdit}
                  isSelected={field.value === 'now'}
                  onClick={() => callAll(field.onChange, resetField)('now')}
                >
                  <Trans t={t} i18nKey='task:now' />
                </CheckButton>

                <Controller
                  control={control}
                  name='startDate'
                  rules={{
                    deps: ['startTime'],
                  }}
                  render={({ field }) => {
                    return (
                      <DatePickerCustom
                        placeholder={t('task:customChooseCal')}
                        minDate={new Date()}
                        onChange={(date) => {
                          setValue('startDateType', 'date');
                          field.onChange(date);
                        }}
                        value={getValues('startDate')}
                        transKey='task:customDateValue'
                        disabled={isEdit}
                      />
                    );
                  }}
                />
              </Flex>

              <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
            </FormControl>
          );
        }}
      />

      {startDateType === 'date' && (
        <Box>
          <TitleHeader
            title={t('task:setPublishTime')}
            desc={t('task:setPublishTimeDesc')}
          />
          <Box mt={3}>
            <CustomTimePicker
              isReadOnly={isEdit}
              name='startTime'
              isSelected
              placeholderText={t('task:selectTime')}
              minTime={minTime}
              maxTime={minTime ? moment().endOf('date').toDate() : undefined}
              validate={(value) => {
                if (isEdit) {
                  return undefined;
                }
                return startTimeValidation(value as Date, getValues());
              }}
            />
          </Box>
        </Box>
      )}

      <TimezoneInfo startDateType={startDateType} />
    </div>
  );
};

export default PublishDate;
