import React, { FC, useMemo } from 'react';
import { Box, Checkbox, Flex, FormControl } from '@chakra-ui/react';
import { Control, Controller, useFormState } from 'react-hook-form';
import { useReactiveVar } from '@apollo/client';
import { ActionButton } from '../../../../../../../ui-components';
import { usersEntityObj } from '../../../../../../../sub-components/Header';

import { IFormInput } from './useSubmitHandler';

interface IProps {
  locationId: string;
  noteId?: string;
  control: Control<IFormInput>;
  onClose?: () => void;
  onSubmitClick?: (...args: any[]) => any | PromiseLike<any>;
}

const LogFormFooter: FC<IProps> = ({
  locationId,
  noteId,
  onSubmitClick,
  onClose,
  control,
}) => {
  const errors = useFormState({
    control: control,
    name: 'content',
    exact: true,
  }).errors;

  const entityList = useReactiveVar(usersEntityObj);

  const locationName = useMemo(() => {
    const name = entityList?.find((l) => l.eid === locationId)?.name;
    return name ? name?.concat('’s') : null;
  }, []);

  return (
    <Flex flexDir='column' gap={3}>
      <Box
        color='#FF6A55'
        fontSize='12px'
        fontWeight={500}
        bg='#FF6A5514'
        padding='10px 14px'
        borderRadius='6px'
        hidden={!errors.content?.message}
      >
        {errors.content?.message}
      </Box>
      <Box fontSize='12px' color='#6F767E'>
        This conversation log is visible to all Superadmins & Admins
      </Box>

      <Box opacity='0.7'>
        <Controller
          name='showLO'
          control={control}
          defaultValue={false}
          render={({ field, fieldState }) => {
            return (
              // @ts-ignore
              <FormControl mt={2} isInvalid={!!fieldState?.error}>
                <Flex alignItems={'center'} gap={2}>
                  <Checkbox isChecked={field?.value} {...field} />
                  <Box
                    fontWeight={600}
                    fontSize={'13px'}
                    hidden={!locationName}
                  >
                    {`Make it visible to ${locationName}'s Location Owners`}
                  </Box>
                </Flex>
              </FormControl>
            );
          }}
        />
      </Box>
      <Flex gap={3}>
        <ActionButton
          flex={1}
          size='lg'
          fontSize='15px'
          variant='outline'
          borderColor='#C6C6C6'
          borderWidth='1.6px'
          close={onClose}
        >
          Close
        </ActionButton>

        <ActionButton
          flex={2}
          size='lg'
          fontSize='15px'
          colorScheme='blue'
          actionFn={onSubmitClick}
        >
          {noteId ? 'Update' : 'Create'}
        </ActionButton>
      </Flex>
    </Flex>
  );
};

export default LogFormFooter;
