import React, { forwardRef, useMemo } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './quiz-input.css';
import { ReQuillValue } from '../../../../../../../../atoms';

interface IProps {
  value?: ReQuillValue;
  onChange?: (value: string) => void;
  readOnly?: boolean;
  placeholder?: string;
  isSelected?: boolean;
}

const QuizTextInputTest = forwardRef<never, IProps>(
  ({ value, onChange, readOnly, placeholder, isSelected }) => {
    const styles = useMemo((): React.CSSProperties => {
      if (isSelected) {
        return {
          color: '#83BF6E',
          fontWeight: '600',
        };
      }
      return {};
    }, [isSelected]);

    return (
      <ReactQuill
        className='qz-textarea'
        modules={{ toolbar: false }}
        readOnly={readOnly}
        value={value}
        placeholder={placeholder}
        onChange={(_, __, ___, editor) => onChange?.(editor.getText())}
        style={styles}
        aria-selecetd={true}
      />
    );
  }
);

QuizTextInputTest.displayName = 'QuizTextInput';

export default QuizTextInputTest;
