import { gql } from '@apollo/client';

export interface SkillVerifierInput {
  comment?: string | null;
  files?: string[] | null;
  name?: string | null;
  rating?: number | null;
}

export interface AddTPSkillVerifierInput {
  sessionId?: string | null;
  skillVerifier: SkillVerifierInput[];
  trainingId?: string | null;
  userId?: string | null;
}
export interface AddTpSkillVerifierResponseItem {
  eid: string;
  skillVerifierResponse: {
    rating: number;
  };
  score: number;
}
export interface AddTpSkillVerifierResponse {
  AddTpSkillVerifierResponse: AddTpSkillVerifierResponseItem;
}

export interface AddTpSkillVerifierResponseVariables {
  input: AddTPSkillVerifierInput;
}
export const ATTACH_TP_SKILL_VERIFIER_RESPONSE = gql`
  mutation AddTpSkillVerifierResponse($input: AddTPSkillVerifierInputInput) {
    AddTpSkillVerifierResponse(input: $input) {
      eid
      skillVerifierResponse {
        rating
      }
      score
    }
  }
`;
export interface TpEvaluateVariables {
  sessionId: string;
  userId: string;
}
export const TP_EVALUATE = gql`
  mutation TpEvaluate($sessionId: String!, $userId: String!) {
    TpEvaluate(sessionId: $sessionId, userId: $userId) {
      succeed
    }
  }
`;
