import React, { FC, useContext, useMemo } from 'react';
import { Box, Button, Flex, IconButton, Image } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import Dropdown from 'atoms/Dropdown';

import TrashIcon from '../../../../../assets/images/trash.svg';
import { MemberContext } from './context';

const commonStyles = {
  singleValue: {
    color: '#1A1D1F',
    fontWeight: '500',
    fontSize: '15px',
  },
  control: {
    borderRadius: '12px',
  },
  container: {
    width: '100%',
  },
};

const ToggleButton: FC = () => {
  const { t } = useTranslation('form');
  const { operation, setOperation } = useContext(MemberContext);

  const onPress = (value: typeof operation, currentVal?: typeof operation) => {
    if (value === currentVal || !value) {
      return;
    }
    setOperation?.(value);
  };

  return (
    <Flex align='center'>
      <Button
        size='lg'
        borderRightRadius='0'
        borderLeftRadius='12px'
        variant='solid'
        textTransform='uppercase'
        fontSize='15px'
        fontWeight='600'
        colorScheme={operation === 'or' ? 'blue' : 'gray'}
        onClick={() => onPress('or', operation)}
      >
        {t('or_text')}
      </Button>
      <Button
        size='lg'
        borderRightRadius='12px'
        borderLeftRadius='0'
        variant='solid'
        textTransform='uppercase'
        fontSize='15px'
        fontWeight='600'
        colorScheme={operation === 'and' ? 'blue' : 'gray'}
        onClick={() => onPress('and', operation)}
      >
        {t('and_text')}
      </Button>
      <Box flex={1} />
      {operation && (
        <IconButton
          aria-label={'delete'}
          variant='ghost'
          icon={<Image src={TrashIcon} w='22px' h='22px' />}
          onClick={() => setOperation?.(undefined)}
        />
      )}
    </Flex>
  );
};

interface IProps {}

const MemberFilterContent: FC<IProps> = () => {
  const { t } = useTranslation(['common', 'deck']);
  const {
    allOptions,
    dataObject,
    operation,
    filterBy,
    setFilterBy,
    filterValue,
    setFilterValue,
    otherFilterBy,
    setOtherFilterBy,
    otherFilterValue,
    setOtherFilterValue,
  } = useContext(MemberContext);

  const secondOptions = useMemo(() => {
    return allOptions.filter(
      (it) => it.value !== (filterBy?.value || filterBy)
    );
  }, [allOptions, filterBy]);

  return (
    <Flex flexDir='column' gap='22px'>
      <Flex minW='217px' gap='32px' align='center'>
        <Dropdown
          value={filterBy as any}
          onChange={(e) => setFilterBy(e)}
          options={allOptions}
          selectStyles={commonStyles}
        />
        <Box fontSize='15px' fontWeight='600'>
          {t('deck:visibility.is_text')}
        </Box>
        <Dropdown
          value={filterValue as any}
          onChange={(e) => setFilterValue(e)}
          options={dataObject[filterBy?.value as never]}
          selectStyles={commonStyles}
        />
      </Flex>

      <ToggleButton />

      {operation && (
        <Flex minW='217px' gap='32px' align='center'>
          <Dropdown
            value={otherFilterBy as any}
            onChange={(e) => setOtherFilterBy(e)}
            options={secondOptions}
            selectStyles={commonStyles}
          />
          <Box fontSize='15px' fontWeight='600'>
            {t('deck:visibility.is_text')}
          </Box>
          <Dropdown
            value={otherFilterValue as any}
            onChange={(e) => setOtherFilterValue(e)}
            options={dataObject[otherFilterBy?.value as never]}
            selectStyles={commonStyles}
          />
        </Flex>
      )}
    </Flex>
  );
};

export default MemberFilterContent;
