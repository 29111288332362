import React, { FC, useMemo } from 'react';
import { Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { callAll } from '@chakra-ui/utils';
import { useController, useFormContext, useWatch } from 'react-hook-form';
import { SelectOption } from '../../../../../../../atoms';
import { truncateString } from '../../../../../../../utils';
import { TFormInput } from '../../../training-input.types';
import TrainingSelect from './TrainingSelect';
import DependentTrainingAssigneeInfo from './DependentTrainingAssigneeInfo';
import SetMinimumScore from './SetMinimumScore';
import { useTrainingList } from './useTrainingList';

interface IProps {
  disabled?: boolean;
}

const DependantTrainingSelect: FC<IProps> = ({ disabled }) => {
  const [trainingId, userType] = useWatch<
    TFormInput,
    ['eid', 'assigneeUserType']
  >({
    name: ['eid', 'assigneeUserType'],
  });

  const { data, loading } = useTrainingList(userType);
  const { getValues, setValue } = useFormContext<TFormInput>();

  const options = useMemo(() => {
    return data?.TpPagination?.items?.reduce<SelectOption[]>(
      (acc, training) => {
        if (training?.eid === trainingId) return acc;

        acc.push({
          label: truncateString(training.title, 46),
          value: training.eid,
        });

        return acc;
      },
      []
    );
  }, [data, trainingId]);

  const { field, fieldState } = useController<
    TFormInput,
    'dependantTraining.trainingId'
  >({
    name: 'dependantTraining.trainingId',
    defaultValue: '',
    disabled: disabled,
    rules: {
      required: 'Please select a prerequisite training path',
    },
  });

  const selectedTraining = useMemo(() => {
    return data?.TpPagination?.items?.find((t) => t.eid === field.value);
  }, [data, field.value]);

  const onTrainingChange = (value: string) => {
    const training = data?.TpPagination?.items?.find((t) => t.eid === value);
    if (training && getValues('dependantTraining.hasMinScore')) {
      setValue('dependantTraining.hasMinScore', training?.hasScoring === true);
    }
  };

  return (
    <Flex gap={4}>
      <Flex width='160px' />
      <Flex flex={3} flexDir='column' gap={3}>
        <FormControl isInvalid={fieldState.invalid}>
          <TrainingSelect
            options={options}
            isLoading={loading}
            {...field}
            onChange={callAll(field.onChange, onTrainingChange)}
          />
          <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
          {fieldState.invalid ? null : (
            <DependentTrainingAssigneeInfo training={selectedTraining} />
          )}
        </FormControl>

        {field.value && selectedTraining?.hasScoring ? (
          <SetMinimumScore disabled={disabled} trainingId={field.value} />
        ) : null}
      </Flex>
    </Flex>
  );
};

export default DependantTrainingSelect;
