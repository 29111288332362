import React, { FC, useState } from 'react';
import { Box, Flex, Progress, useControllableState } from '@chakra-ui/react';
import { useUploadImage } from '../../../../../../../../../hooks';
import {
  convertToWebp,
  fileToDtaUrl,
  getWebpFilename,
  validateIsImage,
} from '../../../../../../../../../utils';
import AddImageDropZone from './AddImageDropZone';
import AddImageView from './AddImageView';

interface IProps {
  value: string;
  onChange: (value: string) => void;
}

const AddImageContainer: FC<IProps> = ({ value, onChange }) => {
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [errorMessage, setError] = useState<string>();
  const uploadImage = useUploadImage();
  const [internalValue, updateValue] = useControllableState<string>({
    value: value,
    onChange: onChange,
  });

  const _onFileChange = async (files: FileList) => {
    if (files?.[0]) {
      try {
        setError(undefined);
        setProgress(0);
        setIsUploading(true);
        const file = files[0];

        const dataUrl = await fileToDtaUrl(file);

        const blobData = await convertToWebp(dataUrl);

        const responseUrl = await uploadImage(
          blobData,
          getWebpFilename(file?.name),
          setProgress
        );
        updateValue?.(responseUrl);
        setIsUploading(false);
      } catch (e) {
        setError(
          'Failed to upload image, an error occurred on the server. Please try again.'
        );
        setIsUploading(false);
      }
    }
  };

  const onLinkChange = async (newLink: string) => {
    if (newLink) {
      try {
        setError(undefined);
        setProgress(99);
        setIsUploading(true);

        await validateIsImage(newLink, 5000);

        updateValue?.(newLink);
        setIsUploading(false);
      } catch (e) {
        setError('Please enter a valid image URL');
        setIsUploading(false);
      }
    }
  };

  return (
    <Flex boxSize='full' flexDir='column' gap={4}>
      <AddImageView value={internalValue} onRemove={() => updateValue?.('')}>
        <AddImageDropZone
          isInvalid={!!errorMessage}
          isUploading={isUploading}
          onFileChange={_onFileChange}
          onLinkChange={onLinkChange}
          onError={(error) => setError(error?.message)}
        />
      </AddImageView>

      {isUploading ? (
        <Flex flexDir='column' gap={3} minHeight='40px'>
          <Box fontSize='13px' fontWeight='500' color='#272B30'>
            Your file is uploading... Hang tight!
          </Box>

          <Progress
            size='sm'
            borderRadius='full'
            value={progress}
            colorScheme={progress >= 99 ? 'green' : 'blue'}
            isIndeterminate={progress < 1}
            hasStripe={isUploading}
            isAnimated={isUploading}
          />
        </Flex>
      ) : (
        <Flex minHeight='40px'>
          {errorMessage && <Box color='red.500'>{errorMessage}</Box>}
        </Flex>
      )}
    </Flex>
  );
};

export default AddImageContainer;
