import { useQuery } from '@apollo/client';
import { Box, Center, Flex, Skeleton, Text } from '@chakra-ui/react';
import ChapterFilePreview from 'pages/Training/CreateTrainingPath/Preview/component/ChapterFilePreview';
import React, { FC, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { useService } from 'sub-components/training-v2/play/layers';
import { usePlayContext } from 'sub-components/training-v2/play/store/context';
import {
  getQuestionResponse,
  isQuestionCompleted,
} from 'sub-components/training-v2/play/utils';
import { GET_CARD_BY_ID } from 'sub-components/training-v2/shared/api';

const CardContent: FC = () => {
  const {
    contents,
    selectedIndex,
    selectedSubIndex,
    tpSessionData,
    updateContentByIndex,
    updateTpSessionData,
  } = usePlayContext((state) => ({
    selectedIndex: state.selectedIndex,
    selectedSubIndex: state.selectedSubIndex,
    contents: state.tpSessionData?.contents || [],
    tpSessionData: state.tpSessionData,
    updateContentByIndex: state.updateContentByIndex,
    updateTpSessionData: state.updateTpSessionData,
  }));

  const service = useService();
  const [hasTriggeredCompletion, setHasTriggeredCompletion] = useState(false);

  const handleCompletion = async (
    itemId: string,
    sessionId: string,
    trainingId: string
  ) => {
    const userProgress = tpSessionData?.userProgress;

    if (
      userProgress?.[0]?.progress?.some(
        (item) => item.id === itemId && item.isCompleted
      )
    ) {
      return;
    }
    await service.updateTPProgress
      .updateTPProgress({
        variables: {
          input: {
            isCompleted: true,
            itemId: itemId,
            sessionId: sessionId,
            trainingId: trainingId,
            responses: [],
          },
        },
      })
      ?.then((res) => {
        if (res?.data?.AddTpProgress?.eid && tpSessionData) {
          updateTpSessionData({
            ...tpSessionData,
            userProgress: res?.data?.AddTpProgress
              ? [res?.data?.AddTpProgress]
              : [],
          });
        }
      });
  };

  const { loading, error } = useQuery(GET_CARD_BY_ID, {
    skip: !contents[selectedIndex]?.eid,
    variables: {
      eid: contents[selectedIndex]?.eid,
    },
    onCompleted: (data) => {
      if (data?.findCardById) {
        updateContentByIndex(selectedIndex, {
          ...contents[selectedIndex],
          cardContent: {
            ...data?.findCardById,
          },
        });
      }
    },
  });

  const selectedData = React.useMemo(() => {
    return contents?.[selectedIndex];
  }, [contents, selectedIndex, selectedSubIndex]);

  const isCompleted = React.useMemo(() => {
    return isQuestionCompleted(
      selectedData?.eid,
      tpSessionData?.userProgress || []
    );
  }, [selectedData, tpSessionData]);

  const questionResponse = React.useMemo(() => {
    return getQuestionResponse(
      selectedData?.eid,
      tpSessionData?.userProgress || []
    );
  }, [selectedData, tpSessionData]);

  useEffect(() => {
    setHasTriggeredCompletion(false);
  }, [selectedIndex]);

  const handlePlay = () => {
    if (hasTriggeredCompletion) return;

    const currentContent = contents?.[selectedIndex];
    if (
      tpSessionData?.eid &&
      tpSessionData?.trainingId &&
      currentContent?.eid
    ) {
      handleCompletion(
        currentContent.eid,
        tpSessionData.eid,
        tpSessionData.trainingId
      );
      setHasTriggeredCompletion(true);
    }
  };

  if (loading) {
    return (
      <Flex w='full' flexDir='column' gap={4}>
        <Skeleton h='40px' w='200px' />
        <Skeleton h='20px' w='full' />
        <Skeleton h='20px' w='full' />
        <Skeleton h='20px' w='full' />
        <Skeleton h='20px' w='full' />
        <Skeleton h='20px' w='full' />
        <Skeleton h='20px' w='full' />
        <Skeleton h='20px' w='full' />
        <Skeleton h='20px' w='full' />
      </Flex>
    );
  }

  if (error) {
    return (
      <Center w='full' h='300px'>
        <Text>Error loading content. Please try again.</Text>
      </Center>
    );
  }

  if (!selectedData || !selectedData?.cardContent) {
    return (
      <Center w='full' h='60vh'>
        <Text fontWeight={600}>Content not available</Text>
      </Center>
    );
  }

  const videoRender = () => {
    const description = selectedData?.cardContent?.content
      ?.filter((con: any) => con?.type === 'caption')
      ?.map((con: any) => con?.caption);

    const mediaUrl = selectedData?.cardContent?.media?.[0]?.mediaUrl;
    const hasValidUrl =
      mediaUrl && typeof mediaUrl === 'string' && mediaUrl.trim() !== '';

    return (
      <Flex flexDir='column' gap={4} w='full' h='full'>
        <Box fontWeight={700} fontSize='32px'>
          {selectedData?.cardContent?.title || 'No Title'}
        </Box>
        {description?.length > 0 && <Box>{description}</Box>}
        <Center w='full' h='full' minH='300px'>
          {hasValidUrl ? (
            <ReactPlayer
              url={mediaUrl}
              controls
              playing={false}
              height='100%'
              width='100%'
              onError={(e) => {
                console.error('ReactPlayer error:', e);
              }}
              onPlay={handlePlay}
              fallback={<Text>Video loading failed. Please try again.</Text>}
            />
          ) : (
            <Flex
              direction='column'
              align='center'
              justify='center'
              border='1px dashed'
              borderColor='gray.300'
              borderRadius='md'
              p={8}
              width='100%'
              height='60vh'
            >
              <Text mb={2} fontWeight={600}>
                Video url could not be found
              </Text>
              <Text fontSize='sm' color='gray.500'>
                The media content may be missing or unavailable
              </Text>
            </Flex>
          )}
        </Center>
      </Flex>
    );
  };

  const render = () => {
    switch (selectedData?.cardContent?.cardType) {
      case 'video':
        return videoRender();
      case 'document':
        return (
          <ChapterFilePreview sopData={selectedData?.cardContent as never} />
        );
      default:
        return (
          <Center w='full' h='60vh'>
            <Text fontWeight={600}>Unsupported content type</Text>
          </Center>
        );
    }
  };

  return render();
};

export default CardContent;
