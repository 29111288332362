import React, { FC, useState, useCallback } from 'react';
import { Box, Flex, HStack, VStack } from '@chakra-ui/react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
} from 'recharts-new';
import { RatingData } from '../types';
import SingleLocationCard from './SingleLocationCard';
import LegendComponent from './LegendComponent';
import PaginationComponent from './PaginationComponent';
import CustomTooltip from './CustomTooltip';
import CustomizedAxisTick from './CustomizedAxisTick';

export const GOOGLE_COLOR = '#CABDFF';
export const YELP_COLOR = '#B1E5FC';
export const NPS_COLOR = '#FFC9C9';

interface CustomerFeedbackChartProps {
  data: RatingData[];
}

const ITEMS_PER_PAGE = 15;

const CustomerFeedbackChart: FC<CustomerFeedbackChartProps> = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [hoveredBar, setHoveredBar] = useState<string | null>(null);
  const [lock, setLock] = useState(false);

  const totalPages = Math.ceil(data.length / ITEMS_PER_PAGE);

  const handlePrev = useCallback(() => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  }, []);

  const handleNext = useCallback(() => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  }, [totalPages]);

  const paginatedData =
    data.length > ITEMS_PER_PAGE
      ? data.slice(
          (currentPage - 1) * ITEMS_PER_PAGE,
          currentPage * ITEMS_PER_PAGE
        )
      : data;

  if (data.length === 1) {
    return <SingleLocationCard location={data[0]} />;
  }

  const graphContainer = () => (
    <ResponsiveContainer width='100%' height={400}>
      <BarChart
        data={paginatedData}
        margin={{ top: 20, right: 30, left: 0, bottom: 5 }}
      >
        <XAxis dataKey='location' tick={<CustomizedAxisTick />} interval={0} />
        <YAxis domain={[0, 5]} ticks={[0, 1, 2, 3, 4, 5]} />

        <Tooltip
          cursor={false}
          wrapperStyle={{ pointerEvents: 'auto' }}
          content={(tooltipProps) => (
            <CustomTooltip
              {...tooltipProps}
              // hoveredBar={hoveredBar}
              // setHoveredBar={setHoveredBar}
              // lock={lock}
              // setLock={setLock}
            />
          )}
          position={{ y: 120 }}
          offset={-80}
          useTranslate3d={true}
        />

        <Bar
          dataKey='googleRating'
          name='Google Rating'
          fill={GOOGLE_COLOR}
          barSize={20}
          onMouseEnter={(e) => setHoveredBar(e.location)}
          onMouseLeave={() => !lock && setHoveredBar(null)}
        />

        <ReferenceLine
          y={2.5}
          stroke='rgba(142, 89, 255, 1)'
          strokeDasharray='5 5'
        />
      </BarChart>
    </ResponsiveContainer>
  );

  return (
    <Box width='100%'>
      {data.length > ITEMS_PER_PAGE ? (
        <VStack align='flex-start' w='full'>
          {graphContainer()}
          <Flex
            justifyContent='space-between'
            alignItems='center'
            mt={4}
            pl={4}
            w='full'
          >
            <LegendComponent />
            <PaginationComponent
              currentPage={currentPage}
              totalPages={totalPages}
              handlePrev={handlePrev}
              handleNext={handleNext}
            />
          </Flex>
        </VStack>
      ) : (
        <HStack align='flex-start'>
          <Flex w='80%'>{graphContainer()}</Flex>
          <Flex w='20%' justify='flex-end' flexWrap='wrap'>
            <LegendComponent />
          </Flex>
        </HStack>
      )}
    </Box>
  );
};

export default CustomerFeedbackChart;
