import React from 'react';
import { match } from 'ts-pattern';
import { TFuncKey } from 'i18next';
import { toArray } from '../../../../../../utils';
import { AuthRole } from '../../../../../../authorization';
import { usersEntityObj } from '../../../../../Header';
import { UserEntityData } from '../../../../../../shared/graphql/shared-types';
import { IAssigneeInput, TFormInput } from '../../training-input.types';
import {
  getLocations,
  haveJobAssignee,
  haveRoleAssignee,
  haveValue,
} from '../../common/info.helpers';
import { haveOnlyWorkers } from './supervisor-select/useHaveOnlyWorkers';
import { TAssignee } from '../../../../../../types';

type UserRecordRef = React.MutableRefObject<Record<string, UserEntityData>>;

const getUsers = (supervisors: string[] = [], userRef: UserRecordRef) => {
  const { superadmin, admins, owners } = supervisors.reduce(
    (acc, loc) => {
      const _loc = userRef.current[loc];

      if (!_loc) return acc; // Early exit if _loc is not found

      const { authRole, name } = _loc; // Destructuring for clarity

      switch (authRole) {
        case AuthRole.SUPER_ADMIN:
          acc.superadmin.push(name);
          break;
        case AuthRole.ADMIN:
          acc.admins.push(name);
          break;
        case AuthRole.LOCATION_OWNER:
          acc.owners.push(name);
          break;
      }

      return acc;
    },
    {
      superadmin: [] as string[],
      admins: [] as string[],
      owners: [] as string[],
    }
  );

  let context: string | undefined;

  if (superadmin.length && admins.length) {
    context = 'both';
  } else if (superadmin.length) {
    context = 'superadmin';
  } else if (admins.length) {
    context = 'admin';
  }

  return {
    superadmin: superadmin,
    admins: admins,
    owners: owners,
    context: context,
  };
};

interface TransObj {
  i18nKey: TFuncKey<'traininginfo', 'supervisorInfo'> | undefined;
  values?: object;
  context?: string;
}

const getUsersLocation = (userIds: string[] = [], userRef: UserRecordRef) => {
  return userIds?.reduce<string[]>((acc, loc) => {
    const _loc = userRef.current[loc]?.locations;

    if (!_loc?.length) return acc; // Early exit if _loc is not found

    acc.push(..._loc.map((l) => l.eid));

    return acc;
  }, []);
};

const getAssigneeLocation = (
  assignees: TFormInput['assignees'],
  userRef: UserRecordRef
) => {
  if (assignees) {
    // return assignees?.map(assignee => {
    //   if (assignee.type === 'user') {
    //     return getLocations(getUsersLocation(assignee.userIds, userRef), userRef);
    //   }
    //
    //   if (assignee.type === 'authRole') {
    //     const combination = assignees?.flatMap((as) => as.locationIds);
    //
    //     return getLocations(combination, userRef);
    //   }
    //
    //   if (assignee.type === 'role') {
    //     const combination = assignees?.flatMap((as) => as.locationIds);
    //
    //     return getLocations(combination, userRef);
    //   }
    // })

    const combination = assignees?.flatMap((assignee) => {
      if (assignee.type === 'user') {
        return getUsersLocation(assignee.userIds, userRef);
      }

      if (assignee.type === 'authRole') {
        return assignee.locationIds;
      }

      if (assignee.type === 'role') {
        return assignee.locationIds;
      }
      return [];
    });

    return getLocations(combination, userRef);
  }

  // if (assignBy === 'user') {
  //   return getLocations(getUsersLocation(userIds, userRef), userRef);
  // }
  //
  // if (assignBy === 'authRole') {
  //   const combination = assignees?.flatMap((as) => as.locationIds);
  //
  //   return getLocations(combination, userRef);
  // }
  //
  // if (assignBy === 'role') {
  //   const combination = assignees?.flatMap((as) => as.locationIds);
  //
  //   return getLocations(combination, userRef);
  // }

  return getLocations([], userRef);
};

interface TCreator {
  eid: string;
  name: string;
  authRole: AuthRole;
}

export const getSupervisorTransObject = (
  values: Partial<TFormInput>,
  userRef: UserRecordRef,
  creator?: TCreator
): TransObj | TransObj[] | undefined => {
  const onlyWorkers = haveOnlyWorkers(usersEntityObj(), values.assignees!);

  const getOwnerInfo = (_onlyWorkers?: boolean): TransObj | undefined => {
    if (!_onlyWorkers) {
      return undefined;
    }

    const { open, launching, context } = getAssigneeLocation(
      values.assignees!,
      userRef
    );

    if (context) {
      return {
        i18nKey: 'owner',
        values: {
          openLocations: open,
          launchingLocations: launching,
        },
        context: context,
      };
    }

    return undefined;
  };

  const haveNoSupervisor = !(
    values?.hasSkillVerifier && toArray(values?.supervisors).length > 0
  );

  const transObjects = toArray(values?.assignees)
    .flatMap((assignee) => {
      return match<TAssignee, TransObj | (TransObj | undefined)[] | undefined>(
        assignee
      )
        .with({ type: 'user', userIds: haveValue }, () => {
          const data: TransObj[] = [];

          const ownerInfo = getOwnerInfo(onlyWorkers);
          ownerInfo && data.push(ownerInfo);

          if (!haveNoSupervisor) {
            const { superadmin, admins, context } = getUsers(
              values?.supervisors,
              userRef
            );

            if (context) {
              data.push({
                i18nKey: 'member',
                values: { superAdmins: superadmin, admins: admins },
                context: context,
              });
            }
          }

          return data;
        })
        .with(
          { type: 'location' },
          (_value) => {
            if (!_value.locationType || _value.locationType === 'custom') {
              return toArray(_value?.locationIds).length > 0;
            }
            return true;
          },
          () => {
            let ownerTrans: TransObj | undefined = undefined;
            if (creator?.authRole === AuthRole.LOCATION_OWNER) {
              const { open, launching, context } = getLocations(
                assignee?.locationIds,
                userRef
              );

              if (context) {
                ownerTrans = {
                  i18nKey: 'owner',
                  values: {
                    openLocations: open,
                    launchingLocations: launching,
                  },
                  context: context,
                };
              }
            }

            if (haveNoSupervisor) {
              return ownerTrans ? [ownerTrans] : [];
            }

            if (ownerTrans) {
              const { superadmin, admins, context } = getUsers(
                values?.supervisors,
                userRef
              );
              return [
                ownerTrans,
                {
                  i18nKey: 'member',
                  values: { superAdmins: superadmin, admins: admins },
                  context: context,
                },
              ];
            }

            const { admins, superadmin, context } = getUsers(
              values?.supervisors,
              userRef
            );

            if (context) {
              return [
                {
                  i18nKey: 'location',
                  values: { users: [...superadmin, ...admins] },
                  context: context,
                },
              ];
            }

            return [];
          }
        )
        .with({ type: 'role', assignees: haveJobAssignee }, () => {
          if (haveNoSupervisor) {
            return [getOwnerInfo(true)];
          }

          const { superadmin, admins, context } = getUsers(
            values?.supervisors,
            userRef
          );

          return [
            getOwnerInfo(true),
            {
              i18nKey: 'member',
              values: { superAdmins: superadmin, admins: admins },
              context: context,
            },
            { i18nKey: 'caption' },
          ];
        })
        .with({ type: 'authRole', assignees: haveRoleAssignee }, () => {
          if (haveNoSupervisor) {
            return [getOwnerInfo(onlyWorkers)];
          }

          const { superadmin, admins, context } = getUsers(
            values?.supervisors,
            userRef
          );

          return [
            getOwnerInfo(onlyWorkers),
            {
              i18nKey: 'member',
              values: { superAdmins: superadmin, admins: admins },
              context: context,
            },
          ];
        })
        .otherwise(() => []);
    })
    .filter(Boolean) as TransObj[];

  if (transObjects?.length) {
    transObjects.push({ i18nKey: 'caption' });
    return transObjects;
  }

  return { i18nKey: 'placeholder' };
};
