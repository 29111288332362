import { FC, useEffect } from 'react';

import { Box, Center, Flex, Image, Text, Tooltip } from '@chakra-ui/react';
import { Button } from 'atoms';
import { FaCheck } from 'react-icons/fa6';
import { FiAlertCircle } from 'react-icons/fi';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_TP_SESSION_LOG } from '../Tracking/query/tracking.graphql';
import Loader from 'sub-components/Loader';
import moment from 'moment';
import { useTrainingCategories } from 'sub-components/training-v2/create/components/TrainingPathDetailsSection/training-category/useTrainingCategories';
import { getImage } from 'utils';

interface IProps {
  sessionId: string;
  userId: string;
  userName: string;
  tp: any;
}

const TimelineItem = ({
  activity,
  userName,
  tp,
  hasScoring,
  isOldEvaluation,
  isEvaluationDone,
}: {
  activity: any;
  userName: string;
  tp: any;
  hasScoring?: boolean;
  isOldEvaluation?: boolean;
  isEvaluationDone?: boolean;
}) => {
  const { categories } = useTrainingCategories();

  const getTpCategoryName = (categoryId: string) => {
    const category = categories?.find(
      (category) => category.eid === categoryId
    );
    return category?.name;
  };

  return (
    <Flex gap={3}>
      <Box>
        <Center
          width='12px'
          height='12px'
          background='#2A85FF'
          borderRadius='100%'
          marginTop='5px'
        ></Center>
        <Box
          marginLeft='5px'
          width='2px'
          background='#EFEFEF'
          marginTop='5px'
          height='100%'
        ></Box>
      </Box>
      <Flex flexDirection='column' gap={2}>
        {(activity?.action === 'assigned' ||
          activity?.action === 'reassigned') && (
          <>
            <Flex gap={1} color='#6F767E' fontSize='14px' flexWrap='wrap'>
              <Text as='span' color='#111315' fontWeight={600}>
                You
              </Text>
              {` ${
                activity?.action === 'assigned' ? 'assigned' : 'reassigned'
              } this path to`}
              <Text as='span' color='#111315' fontWeight={600}>
                {userName}
              </Text>
            </Flex>
            <Flex
              background='#FCFCFC'
              border='2px solid #EFEFEF'
              padding='12px 16px'
              borderRadius='8px'
              width='fit-content'
              gap={3}
              alignItems='center'
            >
              <Image
                src={getImage(tp?.training?.thumbnail, tp?.training?.title)}
                width='16'
                height='16'
                borderRadius='8px'
              />
              <Flex flexDirection='column' gap={1}>
                <Tooltip label={tp?.training?.title}>
                  <Text
                    as='span'
                    color='#111315'
                    fontWeight={600}
                    isTruncated
                    maxWidth={'200px'}
                  >
                    {tp?.training?.title}
                  </Text>
                </Tooltip>

                <Text
                  as='span'
                  color='#6F767E'
                  fontSize='12px'
                  fontWeight={500}
                >
                  Due date:{' '}
                  {tp?.endDate ? moment(tp?.endDate).format('DD MMMM') : '-'}
                </Text>
                <Tooltip label={getTpCategoryName(tp?.training?.category)}>
                  <Box
                    background='#8E59FF33'
                    color='#8E59FF'
                    padding='4px 8px'
                    width='fit-content'
                    borderRadius='4px'
                    fontWeight={500}
                    fontSize='12px'
                    isTruncated
                    maxWidth={'100px'}
                  >
                    {getTpCategoryName(tp?.training?.category)}
                  </Box>
                </Tooltip>
              </Flex>
            </Flex>
          </>
        )}

        {activity?.action === 'started' && (
          <Flex gap={1} color='#6F767E' fontSize='14px' flexWrap='wrap'>
            <Text as='span' color='#111315' fontWeight={600}>
              {userName}
            </Text>
            started
            <Tooltip label={tp?.training?.title}>
              <Text
                as='span'
                color='#111315'
                fontWeight={600}
                isTruncated
                maxWidth={'200px'}
              >
                {`${tp?.training?.title} path`}
              </Text>
            </Tooltip>
          </Flex>
        )}

        {activity?.action === 'completed' && (
          <Flex flexDirection='column' gap={2}>
            <Flex gap={1} color='#6F767E' fontSize='14px' flexWrap='wrap'>
              <Text as='span' color='#111315' fontWeight={600}>
                {userName}
              </Text>
              completed
              <Tooltip label={tp?.training?.title}>
                <Text
                  as='span'
                  color='#111315'
                  fontWeight={600}
                  isTruncated
                  maxWidth={'200px'}
                >
                  {tp?.training?.title}
                </Text>
              </Tooltip>
            </Flex>
            {isEvaluationDone && (
              <Button
                fontSize='13px'
                gap={2}
                width='fit-content'
                colorScheme='green'
              >
                Evaluation done
                <FaCheck />
              </Button>
            )}
            {tp?.hasSkillVerifier &&
              !tp?.assignedUser?.isEvaluated &&
              !isOldEvaluation && (
                <Button
                  fontSize='13px'
                  gap={2}
                  width='fit-content'
                  colorScheme='red'
                >
                  Evaluate
                  <FiAlertCircle fill='white' color='#FF6A55' size='20px' />
                </Button>
              )}
          </Flex>
        )}

        {(activity.action === 'evaluated' || hasScoring) && (
          <Flex gap={1} color='#6F767E' fontSize='14px'>
            <Text as='span' color='#111315' fontWeight={600}>
              {userName}
            </Text>
            received a score of
            <Text as='span' color='#111315' fontWeight={600}>
              {typeof activity?.metadata?.score === 'number'
                ? `${activity.metadata.score.toFixed(2)}%`
                : !isNaN(Number(activity?.metadata?.score))
                ? `${Number(activity.metadata.score).toFixed(2)}%`
                : 'N/A'}
            </Text>
          </Flex>
        )}

        <Text fontSize='12px' color='#6F767E' fontWeight={500}>
          {moment(activity.timestamp).format('DD MMM YYYY, [at] HH:mm')}
        </Text>
      </Flex>
    </Flex>
  );
};

const MemberActivityTimeline: FC<IProps> = ({
  sessionId,
  userId,
  userName,
  tp,
}) => {
  const { data, loading } = useQuery(GET_TP_SESSION_LOG, {
    variables: {
      sessionId: sessionId,
      userId: userId,
    },
    fetchPolicy: 'network-only',
  });

  return (
    <Flex
      flexDirection='column'
      w='full'
      maxW='360px'
      mx='auto'
      paddingTop='.5rem'
      gap={8}
    >
      {loading ? (
        <Center height='100%'>
          <Loader />
        </Center>
      ) : (
        data?.TpSessionLogById?.activities?.map(
          (activity: any, index: number) => {
            const isOldEvaluation =
              activity.action === 'completed' &&
              data?.TpSessionLogById?.activities?.[index + 2]?.action ===
                'reassigned';

            const isEvaluationDone =
              activity.action === 'completed' &&
              data?.TpSessionLogById?.activities?.[index + 1]?.action ===
                'evaluated';

            if (activity.action === 'completed' && !tp.hasSkillVerifier)
              return (
                <>
                  <Flex key={index} flexDirection='column' gap={8}>
                    <TimelineItem
                      activity={activity}
                      userName={userName}
                      key={index}
                      tp={tp}
                      isOldEvaluation={isOldEvaluation}
                      isEvaluationDone={isEvaluationDone}
                    />
                  </Flex>

                  <Flex key={index} flexDirection='column' gap={8}>
                    <TimelineItem
                      activity={{
                        ...activity,
                        action: 'score',
                      }}
                      userName={userName}
                      key={index}
                      tp={tp}
                      isOldEvaluation={isOldEvaluation}
                      isEvaluationDone={isEvaluationDone}
                      hasScoring={true}
                    />
                  </Flex>
                </>
              );

            return (
              <Flex key={index} flexDirection='column' gap={8}>
                <TimelineItem
                  activity={activity}
                  userName={userName}
                  key={index}
                  tp={tp}
                  isOldEvaluation={isOldEvaluation}
                  isEvaluationDone={isEvaluationDone}
                  hasScoring={false}
                />
              </Flex>
            );
          }
        )
      )}
    </Flex>
  );
};

MemberActivityTimeline.displayName =
  'displayName:sub-components/training-v2/dashboard/components/memberActivity/MemberActivityTimeline';

export default MemberActivityTimeline;
