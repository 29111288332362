import React, { forwardRef } from 'react';
import { Flex, IconButton } from '@chakra-ui/react';
import { Image } from '../../../../../../../../ui-components';
import { useAddImage } from './add-image';

import { ReactComponent as DeleteIcon } from '../../../../../../../../assets/images/deleteBin.svg';
import { ReactComponent as EditIcon } from '../../../../../../../../assets/images/edit-dark.svg';

interface IProps {
  value: string;
  boxSize: number;
  onChange?: (value: string) => void;
  alignSelf?: 'center';
}

const ThumbnailView = forwardRef<HTMLInputElement, IProps>(
  ({ value, boxSize, onChange, alignSelf }, ref) => {
    const addImage = useAddImage();

    return (
      <Flex
        ref={ref}
        position='relative'
        borderRadius='8px'
        userSelect='none'
        alignSelf={alignSelf}
      >
        <Image
          src={value}
          height={boxSize}
          width={boxSize}
          style={{
            minWidth: boxSize,
            objectFit: 'cover',
            borderRadius: '8px',
          }}
        />
        <Flex
          position='absolute'
          boxSize='full'
          borderRadius='8px'
          gap={1}
          padding={1}
          justify='end'
          opacity={0}
          _hover={{ opacity: 1 }}
        >
          <IconButton
            size='xs'
            aria-label='edit-image'
            borderRadius='4px'
            borderWidth='1px'
            icon={<EditIcon width={16} height={16} />}
            onClick={() => addImage({ value: '', onAddClick: onChange })}
          />

          <IconButton
            size='xs'
            aria-label='delte-image'
            borderRadius='4px'
            borderWidth='1px'
            icon={<DeleteIcon width={16} height={16} />}
            onClick={() => onChange?.('')}
          />
        </Flex>
      </Flex>
    );
  }
);

ThumbnailView.displayName = 'ThumbnailView';

export default ThumbnailView;
