import React, { forwardRef } from 'react';
import { Any } from '../../../../../../types';
import { TFormInput } from '../../training-input.types';
import SelectInput from './SelectInput';
import { useDeadlineOptions } from './useScheduleOptions';

interface IProps {
  value?: TFormInput['deadlineType'];
  onChange?: (value: TFormInput['deadlineType']) => void;
}

const DeadlineTypeSelect = forwardRef<never, IProps>((field, ref) => {
  const deadlineOptions = useDeadlineOptions();

  return (
    <SelectInput
      ref={ref}
      options={deadlineOptions}
      {...field}
      value={field.value as string}
      onChange={field.onChange as Any}
    />
  );
});

DeadlineTypeSelect.displayName = 'DeadlineTypeSelect';

export default DeadlineTypeSelect;
