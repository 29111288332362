import React, { useCallback, useRef } from 'react';
import { AlertDialogCloseButton, Box, Flex } from '@chakra-ui/react';
import ModalContent from './ModalContent';
import { ApolloProvider, useApolloClient } from '@apollo/client';
import { BoxHeader, useConfirm, UseConfirm } from 'ui-components';
import { HeaderColors } from 'configs';
import { AuthRole } from 'authorization';

export type ReminSupervisorsEntity = {
  eid: string;
  name: string;
  profilePic: string;
  authRole: AuthRole;
};

interface IProps {
  trainingId?: string;
  sessionId?: string;
  supervisors?: string[];
  cb?: () => void;
}

type SendReminderModal = (props: IProps) => void;

export const useSendReminder = (): SendReminderModal => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();
  const apolloClient = useApolloClient();

  return useCallback(({ trainingId, sessionId, supervisors = [], cb }) => {
    if (confirmRef.current) {
      confirmRef.current.destroy();
    }

    const cbHandler = () => {
      confirmRef?.current?.destroy();
      cb?.();
    };

    confirmRef.current = confirm({
      Wrapper: ({ children }: any) => {
        return (
          <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
        );
      },
      title: (
        <Flex gap='12px'>
          <Box flex={1}>
            <BoxHeader title='Select supervisors' color={HeaderColors.Yellow} />
          </Box>
          <AlertDialogCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
          />
        </Flex>
      ),
      content: (
        <ModalContent
          sessionId={sessionId}
          supervisors={supervisors}
          onSuccess={cbHandler}
          onCancel={() => {
            confirmRef?.current?.destroy();
          }}
        />
      ),
      size: 'lg',
      isCentered: true,
      contentProps: {
        py: '12px',
        px: '8px',
        borderRadius: '16px',
        minW: '617px',
      },
      okText: null,
      cancelText: null,
      footer: null,
    });
  }, []);
};
