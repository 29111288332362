import { useMutation, useReactiveVar } from '@apollo/client';
import { AddIcon } from '@chakra-ui/icons';
import {
  AlertDialogCloseButton,
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faX } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dropdown, FormInput } from 'atoms';
import { AuthRole } from 'authorization';
import { HeaderColors } from 'configs';
import { FC, useMemo } from 'react';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';
import TitleHeader from 'sub-components/CardEditor/TitleHeader';
import { ActionButton, BoxHeader } from 'ui-components';
import { roleObj } from 'ui-components/DashboardMenu';
import {
  INVITE_USER_QUERY,
  InviteResponse,
  InviteVariable,
  SEND_INVITE_QUERY,
  SendInviteResponse,
  SendInviteVariable,
} from 'ui-components/InviteUserNew/invite.graphql';
import { IFormInput } from 'ui-components/InviteUserNew/invite.types';
import { EMAIL_REGEX } from 'utils/constants';

interface IProps {
  branchIds: string[];
}

const AddNewOwner: FC<IProps> = ({ branchIds }) => {
  const { onClose, onOpen, isOpen } = useDisclosure({
    onOpen() {
      deployEvent(AmplitudeEventNames.LAUNCHER_ADD_NEW_OWNER_CLICK);
    },
  });
  const { t } = useTranslation(['common', 'invite', 'profile', 'location']);

  const methods = useForm<IFormInput>({
    defaultValues: {
      currentStep: 'inviteForm',
      permissionLevel: AuthRole.LOCATION_OWNER,
      linkSend: {
        email: false,
        phone: false,
      },
    },
  });

  const { control, getValues, setValue, handleSubmit, trigger, reset } =
    methods;

  const smsSent = useWatch({
    control,
    name: 'linkSend.phone',
  });

  const emailSent = useWatch({
    control,
    name: 'linkSend.email',
  });

  const invitedUserId = useWatch({
    control,
    name: 'invitedUserId',
  });

  const roleColorByName = useReactiveVar<Record<string, string>>(roleObj);

  const toast = useToast({
    position: 'top-right',
    duration: 3000,
  });

  const roleList = useMemo(() => {
    return Object.keys(roleColorByName || {}).map((key) => {
      return { label: key, value: key };
    });
  }, [roleColorByName]);

  const [inviteUser] = useMutation<InviteResponse, InviteVariable>(
    INVITE_USER_QUERY,
    {
      onCompleted: (response) => {
        setValue('inviteUrl', response.inviteUser.inviteUrl);
        setValue('invitedUserId', response.inviteUser.eid);
      },
      onError: (error) => {
        toast({
          status: 'error',
          title: t('error'),
          description: error?.message,
        });
      },
    }
  );

  const submitInviteForm = async (values: IFormInput) => {
    deployEvent(AmplitudeEventNames.MEMBER_SEND_INVITE, {
      name: values.name,
      job: values.job?.value,
      permissionLevel: values.permissionLevel,
      location: branchIds,
      add_member_funnel_id: 5,
      resend_funnel_id: 7,
      method: values?.setupLater
        ? t('invite:inviteAndSetupLater')
        : t('invite:inviteAndSetupNow'),
      member_id: getValues('invitedUserId'),
    });

    await inviteUser({
      variables: {
        input: {
          name: values.name?.trim(),
          authRole: values.permissionLevel!,
          role: values.job?.value,
          branchIds: branchIds,
        },
      },
    });

    deployEvent(AmplitudeEventNames.LAUNCHER_ADD_NEW_OWNER, {
      name: values.name,
    });
  };

  const inviteAndSetupNow = async (values: IFormInput) => {
    setValue('setupLater', false);
    await submitInviteForm(values);
  };

  const [sendSmsInvite] = useMutation<SendInviteResponse, SendInviteVariable>(
    SEND_INVITE_QUERY,
    {
      onCompleted: () => {
        toast({
          status: 'success',
          title: t('success'),
          description: t('invite:invite_send_to_success'),
        });
        setValue('linkSend.phone', true);
      },
      onError: () => {
        toast({
          status: 'error',
          title: t('error'),
        });
      },
    }
  );

  const [sendEmailInvite] = useMutation<SendInviteResponse, SendInviteVariable>(
    SEND_INVITE_QUERY,
    {
      onCompleted: () => {
        toast({
          status: 'success',
          title: t('success'),
          description: t('invite:invite_send_to_success'),
        });
        setValue('linkSend.email', true);
      },
      onError: () => {
        toast({
          status: 'error',
          title: t('error'),
        });
      },
    }
  );

  const handleSMSClick = async () => {
    const isValid = await trigger('mobile');

    deployEvent(AmplitudeEventNames.MEMBER_SEND_EMAIL_SMS, {
      add_member_funnel_id: 5,
      resend_funnel_id: 7,
      member_id: getValues('invitedUserId'),
      sms_or_email: 'SMS',
    });

    if (isValid && !getValues('linkSend.phone')) {
      await sendSmsInvite({
        variables: {
          input: {
            eid: getValues('invitedUserId')!,
            // contact: `+1${getValues('mobile')}`,
            contact: getValues('mobile')?.includes('+')
              ? getValues('mobile')
              : `+1${getValues('mobile')}`,
            type: 'sms',
          },
        },
      });
    }
  };

  const handleEmailClick = async () => {
    const isValid = await trigger('email');

    deployEvent(AmplitudeEventNames.MEMBER_SEND_EMAIL_SMS, {
      add_member_funnel_id: 5,
      resend_funnel_id: 7,
      member_id: getValues('invitedUserId'),
      sms_or_email: 'EMAIL',
    });

    if (isValid && !getValues('linkSend.email')) {
      await sendEmailInvite({
        variables: {
          input: {
            eid: getValues('invitedUserId')!,
            contact: getValues('email'),
            type: 'email',
          },
        },
      });
    }
  };

  return (
    <div>
      <Button
        fontSize={15}
        fontWeight={600}
        leftIcon={<AddIcon />}
        color='#2A85FF'
        variant='outline'
        border='1px solid #DDDDDD'
        bgColor='#FFFFFF'
        onClick={onOpen}
      >
        Add new owner
      </Button>

      <Modal isOpen={isOpen} isCentered={true} onClose={onClose}>
        <ModalOverlay />
        <ModalContent paddingY='8px'>
          <ModalHeader
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <BoxHeader
              title='Add new owner'
              color={HeaderColors.Purple}
              fontSize='18px'
            />
            <AlertDialogCloseButton
              pos='relative'
              borderRadius='full'
              top='0'
              right='0'
            />
          </ModalHeader>
          <ModalBody>
            <FormProvider {...methods}>
              <Box display='flex' flexDirection='column' gap={4}>
                <Controller
                  name='name'
                  control={control}
                  defaultValue=''
                  rules={{
                    required: t('invite:validation.name_required'),
                    pattern: {
                      value: /^\S.*\S$|^\S$/,
                      message: t('invite:validation.invalid_name'),
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <FormControl isInvalid={!!fieldState.error}>
                      <FormLabel
                        fontSize='14px'
                        fontWeight='600'
                        htmlFor='name'
                      >
                        {t('invite:inviteName')}
                      </FormLabel>
                      <FormInput
                        {...field}
                        size='lg'
                        id='name'
                        placeholder={t('invite:enter_name')}
                        isDisabled={!!invitedUserId}
                      />
                      <FormErrorMessage>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />

                <Controller
                  name='job'
                  control={control}
                  rules={{ required: t('invite:validation.jobRequired') }}
                  render={({ field, fieldState }) => (
                    <FormControl isInvalid={!!fieldState.error}>
                      <FormLabel fontSize='14px' fontWeight='600' htmlFor='job'>
                        {t('invite:inviteJob')}
                      </FormLabel>
                      <Dropdown
                        size='lg'
                        id='job'
                        placeholder={t('invite:inviteSelectJob')}
                        {...field}
                        options={roleList}
                        isDisabled={roleList?.length === 0 || !!invitedUserId}
                      />
                      <FormErrorMessage>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />

                {invitedUserId && (
                  <>
                    <Controller
                      name='mobile'
                      control={control}
                      rules={{
                        required: t('profile:validation.phoneRequired'),
                        pattern: {
                          // value: /^[0-9]{10}$/,
                          value: /^[0-9()+\- ]{10,20}$/,
                          message: t('location:validation.phone_invalid'),
                        },
                      }}
                      defaultValue=''
                      render={({ field, fieldState }) => {
                        return (
                          <FormControl isInvalid={!!fieldState.error}>
                            <TitleHeader
                              title={t('invite:inviteViaPhoneNumber')}
                            />
                            <Box h={1.5} />
                            <FormInput
                              size='lg'
                              placeholder='556 456 3456'
                              {...field}
                              rightElementProps={{
                                width: 'auto',
                                pr: '6px',
                              }}
                              rightIcon={
                                <ActionButton
                                  width='fit-content'
                                  variant='solid'
                                  colorScheme={smsSent ? 'green' : 'blue'}
                                  borderRadius='10px'
                                  height='36px'
                                  lineHeight='unset'
                                  size='sm'
                                  actionFn={handleSMSClick}
                                  isDisabled={!field.value}
                                >
                                  {t(
                                    smsSent ? 'common:sent' : 'invite:sendSMS'
                                  )}
                                </ActionButton>
                              }
                            />
                            <FormErrorMessage>
                              <span>{fieldState.error?.message}</span>
                            </FormErrorMessage>
                          </FormControl>
                        );
                      }}
                    />

                    <Controller
                      name='email'
                      control={control}
                      rules={{
                        required: t('common:validation.email_required'),
                        pattern: {
                          value: EMAIL_REGEX,
                          message: t('common:validation.email_invalid'),
                        },
                      }}
                      defaultValue=''
                      render={({ field, fieldState }) => {
                        return (
                          <FormControl isInvalid={!!fieldState.error}>
                            <TitleHeader title={t('invite:inviteViaEmail')} />
                            <Box h={1.5} />
                            <FormInput
                              size='lg'
                              placeholder='joey@gmail.com'
                              {...field}
                              rightElementProps={{
                                width: 'auto',
                                pr: '6px',
                              }}
                              rightIcon={
                                <ActionButton
                                  width='fit-content'
                                  variant='solid'
                                  colorScheme={emailSent ? 'green' : 'blue'}
                                  borderRadius='10px'
                                  height='36px'
                                  lineHeight='unset'
                                  size='sm'
                                  actionFn={handleEmailClick}
                                  isDisabled={!field.value}
                                >
                                  {t(
                                    emailSent
                                      ? 'common:sent'
                                      : 'invite:sendEmail'
                                  )}
                                </ActionButton>
                              }
                            />
                            <FormErrorMessage>
                              <span>{fieldState.error?.message}</span>
                            </FormErrorMessage>
                          </FormControl>
                        );
                      }}
                    />
                  </>
                )}

                {emailSent || smsSent ? (
                  <ActionButton
                    w='full'
                    fontSize='15px'
                    fontWeight='700'
                    colorScheme='blue'
                    marginTop={3}
                    actionFn={() => {
                      reset();
                      onClose();
                    }}
                  >
                    Done
                  </ActionButton>
                ) : (
                  <ActionButton
                    w='full'
                    fontSize='15px'
                    fontWeight='700'
                    colorScheme='blue'
                    marginTop={3}
                    actionFn={handleSubmit(inviteAndSetupNow)}
                    disabled={!!invitedUserId}
                  >
                    Add new owner
                  </ActionButton>
                )}
              </Box>
            </FormProvider>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

AddNewOwner.displayName =
  'sub-components/Launcher/dashboard/Location/AddNewOwner';

export default AddNewOwner;
