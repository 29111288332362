import { useLazyQuery, useQuery } from '@apollo/client';
import { Box, Center, Flex } from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import EmptyState from 'sub-components/EmptyState/EmptyState';
import Loader from 'sub-components/Loader';
import {
  generateFormResponseTable,
  generateQuizResponsesTable,
} from 'sub-components/training-v2/dashboard/utils/util';
import { ProgressTimeLine } from 'sub-components/training-v2/play/components/ProgressSection/ProgressTimeLine';
import {
  FormResponseTable,
  ResponsesTable,
} from 'sub-components/training-v2/shared/quiz/components/responses/components';
import {
  GET_TP_SESSION_BY_ID,
  TpFormResponse,
} from '../../Tracking/query/tracking.graphql';
interface IProps {
  sessionId: string;
  userId: string;
}

const ViewResponseContainer: FC<IProps> = ({ sessionId, userId }) => {
  const [selectedTimelineItem, setSelectedTimelineItem] = useState();
  const [response, setResponse] = useState();
  const [questionMaxScore, setQuestionMaxScore] = useState(0);

  const { data: userTpProgress, loading } = useQuery(GET_TP_SESSION_BY_ID, {
    variables: {
      eid: sessionId,
      filter: {
        userId: userId,
      },
    },
  });

  const [getTpFormResponse, { loading: formResponseLoading }] = useLazyQuery(
    TpFormResponse,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!selectedTimelineItem) return;

        setResponse(
          generateFormResponseTable(
            data?.TpFormResponse?.form?.questions,
            data?.TpFormResponse?.response
          )
        );
      },
    }
  );

  const onProgressTimelineItemClick = (timelineItem: any) => {
    setSelectedTimelineItem({ ...timelineItem });
    setResponse(undefined);
  };

  const getMaxScoreForQuiz = () => {
    const questions = userTpProgress?.TpSessionById?.contents?.find(
      (c) => c?.eid === selectedTimelineItem?.eid
    )?.questions;

    return questions
      ?.map((question) => Number(question?.score) || 0)
      ?.reduce((a, b) => a + b, 0);
  };

  useEffect(() => {
    if (!selectedTimelineItem) return;

    if (selectedTimelineItem?.type === 'form') {
      getTpFormResponse({
        variables: {
          userId: userId,
          trainingSessionId: sessionId,
          formId: selectedTimelineItem?.eid,
        },
      });
    } else {
      setResponse(
        generateQuizResponsesTable(
          selectedTimelineItem.eid,
          userTpProgress?.TpSessionById?.contents,
          userTpProgress?.TpSessionById?.userProgress?.[0]
        )
      );

      setQuestionMaxScore(getMaxScoreForQuiz());
    }
  }, [selectedTimelineItem]);

  return loading ? (
    <Center height='75vh'>
      <Loader />
    </Center>
  ) : (
    <Flex gap={10} marginTop='1rem'>
      <Box width='350px' overflow='scroll' maxHeight='75vh'>
        <ProgressTimeLine
          userProgress={userTpProgress?.TpSessionById?.userProgress?.[0]}
          contents={userTpProgress?.TpSessionById?.contents}
          onProgressTimelineItemClick={(timelineItem) => {
            if (selectedTimelineItem?.eid !== timelineItem.eid) {
              setResponse(undefined);
            }
            onProgressTimelineItemClick(timelineItem);
          }}
        />
      </Box>

      <Box
        overflow='scroll'
        maxHeight='75vh'
        alignItems='center'
        width='fit-content'
      >
        {!selectedTimelineItem && (
          <Center height='100%'>
            <EmptyState
              image='NoActivitySelected'
              title='Click on a form/ quiz to preview'
              imageSize='400px'
            />
          </Center>
        )}

        {selectedTimelineItem?.type === 'quiz' && response?.length ? (
          <ResponsesTable responseData={response} maxScore={questionMaxScore} />
        ) : null}
        {formResponseLoading && (
          <Center>
            <Loader />
          </Center>
        )}
        {selectedTimelineItem?.type === 'form' && response?.length ? (
          <FormResponseTable responseData={response} />
        ) : null}
      </Box>
    </Flex>
  );
};

ViewResponseContainer.displayName =
  'displayName:sub-components/training-v2/dashboard/components/myTrainings/viewResponse/ViewResponseContainer.tsx';

export default ViewResponseContainer;
