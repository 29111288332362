import { TCyclesSession } from 'sub-components/training-v2/shared/types';
import { StateCreator } from 'zustand';

export interface ITrainingSlice {
  locationIdArray: string[] | undefined;
  updateLocationIds: (locationIds: string[] | undefined) => void;

  tableTabIndex: number;
  updateTableTabIndex: (index: number) => void;

  trainingCategoryIndex: number;
  updateTrainingCategoryIndex: (index: number) => void;

  currentRepeatingTrainingSessionCycle: TCyclesSession;
  setCurrentRepeatingTrainingSessionCycle: (
    currentCycle: TCyclesSession
  ) => void;
}

export const trainingSlice: StateCreator<ITrainingSlice> = (set) => ({
  locationIdArray: [],
  updateLocationIds: (locationIds) =>
    set(() => ({ locationIdArray: locationIds })),

  tableTabIndex: 0,
  updateTableTabIndex: (index) => set(() => ({ tableTabIndex: index })),

  trainingCategoryIndex: 0,
  updateTrainingCategoryIndex: (index) =>
    set(() => ({ trainingCategoryIndex: index })),

  currentRepeatingTrainingSessionCycle: {
    startDate: undefined,
    endDate: undefined,
    eid: '',
  },
  setCurrentRepeatingTrainingSessionCycle: (currentCycle) =>
    set(() => ({
      currentRepeatingTrainingSessionCycle: {
        startDate: currentCycle?.startDate,
        endDate: currentCycle?.endDate,
        eid: currentCycle?.eid,
      },
    })),
});
