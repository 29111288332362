import { useCallback } from 'react';
import { useToast } from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import { JSONContent, useReadOnlyEditor } from 'delightree-editor';
import { userObj } from 'sop-commons/src/client';
import { useTranslation } from 'react-i18next';

import { SelectOption } from '../../../../../../../atoms';
import { toArray } from '../../../../../../../utils';

import {
  AddInputVariable,
  CREATE_LOGS_QUERY,
  UPDATE_LOGS_QUERY,
  UpdateInputVariable,
} from './create-log.graphql';

export const extractContent = (
  content?: JSONContent
): [string, JSONContent] => {
  const head = toArray(content?.content).at(0);

  if (head?.content?.[0]?.text) {
    return [
      head?.content[0]?.text,
      {
        type: content?.type,
        content: toArray(content?.content).slice(1),
      },
    ];
  }

  return ['', content!];
};

export interface IFormInput {
  showLO?: boolean;
  // subject?: string;
  content?: JSONContent;
  occurrenceDate: Date | null;
  // occurredOnTime: Date | null;
  category?: SelectOption<string>;
  creator?: string;

  // For editing
  eid?: string;
  locationId?: string;
}

interface IProps {
  locationId: string;
}

export const useSubmitHandler = ({ locationId }: IProps) => {
  const { t } = useTranslation(['common']);
  const readOnlyEditor = useReadOnlyEditor();

  const toast = useToast({
    position: 'top-right',
    isClosable: true,
    duration: 3000,
  });

  const [createLog] = useMutation<never, AddInputVariable>(CREATE_LOGS_QUERY, {
    onCompleted: () => {
      toast({
        status: 'success',
        title: t('common:success'),
        description: 'Conversation log successfully created.',
      });
    },
    onError: () => {
      toast({
        status: 'error',
        title: t('common:error'),
        description: 'Conversation log creation failed.',
      });
    },
  });

  const [updateLog] = useMutation<never, UpdateInputVariable>(
    UPDATE_LOGS_QUERY,
    {
      onCompleted: () => {
        toast({
          status: 'success',
          title: t('common:success'),
          description: 'Conversation log successfully updated.',
        });
      },
      onError: () => {
        toast({
          status: 'error',
          title: t('common:error'),
          description: 'Conversation log updating failed.',
        });
      },
    }
  );

  return useCallback(
    async (values: IFormInput) => {
      const [subject, content] = extractContent(values.content);

      const raw_content = readOnlyEditor({
        content: content,
      }).getText();

      if (values.eid) {
        const updateRes = await updateLog({
          variables: {
            input: {
              eid: values.eid,
              subject: subject,
              raw_content: raw_content,
              content: content!,
              category: values.category?.value!,
              showLO: values?.showLO,
            },
          },
        });

        if (updateRes.errors) {
          return Promise.reject(updateRes.errors);
        }

        return updateRes;
      }

      const createRes = await createLog({
        variables: {
          input: {
            subject: subject,
            raw_content: raw_content,
            content: content!,
            locationId: locationId,
            category: values.category?.value!,
            occurrenceDate: values.occurrenceDate?.toISOString()!,
            createdBy: userObj().eid!,
            showLO: values?.showLO,
          },
        },
      });

      if (createRes.errors) {
        return Promise.reject(createRes.errors);
      }

      return createRes;
    },
    [locationId]
  );
};
