import React, { FC } from 'react';
import { Flex, FlexProps } from '@chakra-ui/react';
import { getSxScrollStyles } from '../../../common';

interface IProps extends Omit<FlexProps, 'sx'> {}

const ListScrollContainer: FC<IProps> = (props) => {
  return (
    <Flex
      flexDir='column'
      gap={1}
      marginTop='20px'
      maxHeight='400px'
      // minHeight='300px'
      overflow='auto'
      {...props}
      sx={getSxScrollStyles()}
    />
  );
};

export default ListScrollContainer;
