import React, { FC } from 'react';
import {
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/react';
import { callAll } from '@chakra-ui/utils';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { IAssigneeInput, TFormInput } from '../../training-input.types';

import { ReactComponent as DeleteIcon } from 'assets/images/trash-red.svg';
import AssignBySelect from './AssignBySelect';
import AssigneeInput from './AssigneeInput';

interface IProps {
  index: number;
  totalLength: number;
  onDeleteClick?: () => void;
  disabled?: boolean;
}

const AssigneeGroup: FC<IProps> = ({
  index,
  totalLength,
  onDeleteClick,
  disabled,
}) => {
  const { setValue } = useFormContext<TFormInput>();
  const assignBy = useWatch<IAssigneeInput, `assignees.${number}.type`>({
    name: `assignees.${index}.type`,
  });

  const canShowDelete = totalLength > 1 && !disabled;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onAssignByChange = (__: string) => {
    setValue(`assignees.${index}.locationIds`, []);
    setValue(`assignees.${index}.authRoles`, []);
    setValue(`assignees.${index}.roles`, []);
    setValue(`assignees.${index}.userIds`, []);
    setValue(`assignees.${index}.locationType`, undefined);
    setValue(`assignees.${index}.memberFilter`, undefined);
  };

  return (
    <>
      {index !== 0 && <Divider borderColor='#6F767E80' marginY={2} />}
      <Flex
        flexDir='column'
        gap={4}
        bgColor='rgba(142, 89, 255, 0.1)'
        padding='12px 16px'
        borderRadius='12px'
      >
        <Controller<IAssigneeInput, `assignees.${number}.type`>
          name={`assignees.${index}.type`}
          disabled={disabled}
          rules={{
            required: 'Select assign training by',
          }}
          render={({ field, fieldState }) => (
            <FormControl isInvalid={fieldState.invalid}>
              <Flex justify='space-between' marginBottom={2}>
                <FormLabel color='#8E59FF'>Group {index + 1}</FormLabel>

                {canShowDelete ? (
                  <DeleteIcon
                    width={20}
                    height={20}
                    cursor='pointer'
                    onClick={onDeleteClick}
                  />
                ) : null}
              </Flex>

              <AssignBySelect
                {...field}
                onChange={callAll(field.onChange, onAssignByChange)}
              />
              <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
            </FormControl>
          )}
        />

        <AssigneeInput index={index} assignBy={assignBy} disabled={disabled} />
      </Flex>
    </>
  );
};

export default AssigneeGroup;
