import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { Button } from '../../../atoms';
import { Authorize, AuthRole } from '../../../authorization';
import { AuditStatus } from '../../../types';
import { AppRoute, useHistory } from '../../../routes';
import { useEditQuestionWarning } from '../../../sub-components/audits/edit-questions';

// @ts-ignore
import { ReactComponent as SettingIcon } from '../../../assets/images/setting.svg';
// @ts-ignore
import { ReactComponent as EditIcon } from '../../../assets/images/edit-dark.svg';
import { deployEvent } from '../../../shared';
import { AmplitudeEventNames } from '../../../shared/amplitudeEvents';

interface IProps {
  auditId: string;
  status?: AuditStatus;
  loading?: boolean;
  isCompleted?: boolean;
}

const AuditDetailsActions: FC<IProps> = ({
  auditId,
  status,
  loading,
  isCompleted,
}) => {
  const history = useHistory();

  const editWarning = useEditQuestionWarning(auditId);

  if (
    loading ||
    !status ||
    status === 'AUDIT_COMPLETED' ||
    status === 'AUDIT_DELETED' ||
    isCompleted
  ) {
    return null;
  }

  return (
    <Flex gap={2}>
      <Authorize permittedFor='user' permittedRoles={[AuthRole.SUPER_ADMIN]}>
        <Button
          size='lg'
          bg='white'
          variant='outline'
          leftIcon={<SettingIcon color='currentColor' />}
          onClick={() => {
            history.push({
              pathname: AppRoute.AUDIT_SETTINGS,
              params: {
                auditId: auditId,
              },
            });
          }}
        >
          Audit settings
        </Button>
      </Authorize>
      <Authorize permittedFor='user' permittedRoles={[AuthRole.SUPER_ADMIN]}>
        <Button
          size='lg'
          bg='white'
          variant='outline'
          leftIcon={<EditIcon color='currentColor' />}
          onClick={() => {
            deployEvent(AmplitudeEventNames.EDIT_QUESTIONS_AUDITS, {
              event: {
                audit_id: auditId,
                audit_funnel_id: 12,
                template_name: '',
              },
            });
            editWarning?.();
          }}
        >
          Edit questions
        </Button>
      </Authorize>
    </Flex>
  );
};

AuditDetailsActions.displayName =
  'displayName:pages/Audits/Detail/AuditDetailsActions';

export default AuditDetailsActions;
