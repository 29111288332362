import { useReactiveVar } from '@apollo/client';
import { Box, chakra } from '@chakra-ui/react';
import FileComponent from 'pages/forms/FormResponse/FormResponseTable/FileComponent';
import ImageComponent from 'pages/forms/FormResponse/FormResponseTable/ImageComponent';
import LocationComponent from 'pages/forms/FormResponse/FormResponseTable/LocationComponent';
import LongAnswerComponent from 'pages/forms/FormResponse/FormResponseTable/LongAnswerComponent';
import MemberComponent from 'pages/forms/FormResponse/FormResponseTable/MemberComponent';
import {
  AcknowledgeCell,
  CheckBoxCell,
  DropDownCell,
} from 'pages/forms/FormResponse/FormResponseTable/ResponseCell';
import ShortAnswerComponent from 'pages/forms/FormResponse/FormResponseTable/ShortAnswerComponent';
import { UserFormResponseWrapper } from 'pages/forms/FormResponse/FormResponseTable/UserFormResponse/user-form-response.styles';
import VideoComponent from 'pages/forms/FormResponse/FormResponseTable/VideoComponent';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { usersEntityObj } from 'sub-components/Header';
import { QuestionType } from 'types';

interface IProps {
  responseData: {
    question: any[];
    response: any[];
  }[];
}

const FormResponseTable: FC<IProps> = ({ responseData }) => {
  const { t } = useTranslation(['card', 'form']);

  const userList = useReactiveVar(usersEntityObj);

  return (
    <UserFormResponseWrapper>
      <Box className='user-form-table'>
        <chakra.table>
          <thead>
            <tr>
              <chakra.th width='300px'>{t('card:question')}</chakra.th>
              <th>{t('form:response')}</th>
            </tr>
          </thead>
          <tbody>
            {responseData?.map(({ response, question }) => {
              return (
                <tr key={response?.qid}>
                  <td style={{ minWidth: '300px', maxWidth: '300px' }}>
                    <strong>{question.label}</strong>
                  </td>
                  <td>
                    {response?.qType === 'dropDown' && (
                      <DropDownCell response={response} question={question} />
                    )}

                    {response?.qType === 'longText' && (
                      <LongAnswerComponent
                        longAnswer={{
                          answer: response?.response,
                          eid: response?.qid!,
                        }}
                        defaultLength={response?.response?.length}
                        noteImages={response?.noteImage}
                      />
                    )}
                    {response?.qType === 'shortText' && (
                      <ShortAnswerComponent
                        shortAnswer={response?.response || ''}
                        noteImages={response?.noteImage}
                      />
                    )}

                    {(response?.qType === 'singleChoice' ||
                      response?.qType === 'multiChoice') && (
                      <CheckBoxCell
                        response={response}
                        question={question}
                        isMultiChoice
                        showUnselectedOption
                      />
                    )}

                    {response?.qType === 'checkBox' && (
                      <CheckBoxCell
                        response={response}
                        question={question}
                        showUnselectedOption
                      />
                    )}

                    {response?.qType === 'fileUpload' && (
                      <FileComponent
                        files={response?.responseUrl}
                        noteText={response?.noteText}
                        noteImages={response?.noteImage}
                      />
                    )}
                    {response?.qType === 'member' && (
                      <MemberComponent
                        memberIds={response?.responseId || []}
                        userList={userList}
                      />
                    )}

                    {response?.qType === 'location' && (
                      <LocationComponent
                        locations={response?.responseId || []}
                        userList={userList}
                      />
                    )}

                    {response?.qType === 'imageUpload' && (
                      <ImageComponent
                        images={response?.responseUrl}
                        noteText={response?.noteText}
                      />
                    )}
                    {response?.qType === 'videoUpload' && (
                      <VideoComponent
                        videos={response?.responseUrl || []}
                        noteText={response?.noteText}
                      />
                    )}

                    {response?.qType === QuestionType.ACKNOWLEDGMENT && (
                      <AcknowledgeCell
                        text={response?.response || ''}
                        font={response?.font || ''}
                      />
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </chakra.table>
      </Box>
    </UserFormResponseWrapper>
  );
};

FormResponseTable.displayName =
  'displayName:sub-components/training-v2/shared/quiz/components/responses/components/FormResponseTable.tsx';

export default FormResponseTable;
