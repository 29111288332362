import { gql } from '@apollo/client';

export interface LocationLiveResp {
  MakeLauncherLocationLive: {
    succeed: boolean;
  };
}

export const MAKE_LOCATION_LIVE = gql`
  mutation MakeLauncherLocationLive($locationId: String!) {
    MakeLauncherLocationLive(locationId: $locationId) {
      succeed
    }
  }
`;

export const MOVE_LOCATION_TO_PRELAUNCH = gql`
  mutation MoveLocationToPrelaunch($locationId: String!) {
    MoveLocationToPrelaunch(locationId: $locationId) {
      succeed
    }
  }
`;

export interface LocationPrelaunchResp {
  MoveLocationToPrelaunch: {
    succeed: boolean;
  };
}
