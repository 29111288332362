import { Flex, Box } from '@chakra-ui/react';
import SearchInput from 'atoms/SearchInput';
import React, { FC, useEffect } from 'react';
import { GenericFilter } from '../../GenericFilter';
import { _myTrainingsFilterData } from 'sub-components/training-v2/constants';
import { useTrainingContext } from '../../TrainingTableContainer';
import { FilterContainer } from './Filter';
import useCombinedStore from 'zustandStore/store';

export interface ITypes {
  eid: string;
  name: string;
  locStatus?: 'open' | 'development' | 'preLaunch';
}

const Header: FC = () => {
  const {
    setTrainingContextData,
    trainingContextData,
    filters,
    setFilters,
    trainingCategories,
  } = useTrainingContext();
  const { tableTabIndex } = useCombinedStore();
  // initialize type filters
  const typesFilter: ITypes[] = [
    {
      eid: 'static_supervised_by_me1',
      name: 'Supervised by me',
    },
    {
      eid: 'static_created_by_me',
      name: 'Created by me',
    },
  ];

  // initialize status filters
  const statusesFilter: ITypes[] = [
    {
      eid: 'static_1_active1',
      name: 'Active',
    },
    {
      eid: 'static_2_inactive2',
      name: 'Inactive',
    },
  ];

  //init member status filters
  const statusesMemberFilter: ITypes[] = [
    {
      eid: 'static_1_overdue1',
      name: 'Overdue',
    },
    // {
    //   eid: 'static_2_evaluation_required2',
    //   name: 'Evaluation required',
    // },
  ];

  //code to handle type filter
  const handleTypeSelection = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: ITypes
  ) => {
    if (e?.target?.checked) {
      setFilters((prevFilterState) => {
        return {
          ...prevFilterState,
          selectedTypes: [...prevFilterState?.selectedTypes, type],
          selectedTypesId: [...prevFilterState?.selectedTypesId, type?.eid],
          totalFiltersTypes: prevFilterState.totalFiltersTypes + 1,
          mergedFiltersArray: [
            ...prevFilterState.mergedFiltersArray,
            { ...type, customType: 'types' },
          ],
        };
      });
    } else {
      setFilters((prevFilterState) => {
        return {
          ...prevFilterState,
          selectedTypes: filters?.selectedTypes?.filter(
            (item) => item?.eid !== type?.eid
          ),
          selectedTypesId: filters?.selectedTypesId?.filter(
            (item) => item !== type?.eid
          ),
          totalFiltersTypes: prevFilterState.totalFiltersTypes - 1,
          mergedFiltersArray: prevFilterState.mergedFiltersArray?.filter(
            (item) => item?.eid !== type?.eid
          ),
        };
      });
    }
  };

  //code to handle status filter
  const handleStatusSelection = (
    e: React.ChangeEvent<HTMLInputElement>,
    status: ITypes
  ) => {
    if (e?.target?.checked) {
      setFilters((prevFilterState) => {
        return {
          ...prevFilterState,
          selectedStatuses: [...prevFilterState?.selectedStatuses, status],
          selectedStatusesId: [
            ...prevFilterState?.selectedStatusesId,
            status?.eid,
          ],
          totalFiltersTypes: prevFilterState.totalFiltersTypes + 1,
          mergedFiltersArray: [
            ...prevFilterState.mergedFiltersArray,
            { ...status, customType: 'statuses' },
          ],
        };
      });
    } else {
      setFilters((prevFilterState) => {
        return {
          ...prevFilterState,
          selectedStatuses: filters?.selectedStatuses?.filter(
            (item) => item?.eid !== status?.eid
          ),
          selectedStatusesId: filters?.selectedStatusesId?.filter(
            (item) => item !== status?.eid
          ),
          totalFiltersTypes: prevFilterState.totalFiltersTypes - 1,
          mergedFiltersArray: prevFilterState.mergedFiltersArray?.filter(
            (item) => item?.eid !== status?.eid
          ),
        };
      });
    }
  };

  //code to handle jobs filter
  const handleJobSelection = (
    e: React.ChangeEvent<HTMLInputElement>,
    job: ITypes
  ) => {
    setFilters((prevFilterState) => {
      const isChecked = e.target.checked;
      const updatedSelectedJobs = isChecked
        ? [...prevFilterState.selectedJobs, job]
        : prevFilterState.selectedJobs.filter((item) => item.eid !== job.eid);

      const updatedSelectedJobsId = isChecked
        ? [...prevFilterState.selectedJobsId, job.eid]
        : prevFilterState.selectedJobsId.filter((item) => item !== job.eid);

      const updatedMergedFiltersArray = isChecked
        ? [
            ...prevFilterState.mergedFiltersArray,
            { ...job, customType: 'jobs' },
          ]
        : prevFilterState.mergedFiltersArray.filter(
            (item) => item.eid !== job.eid
          );

      //set the filteredDatainside the context within using updatedSelectedJobs
      setTrainingContextData((prevTainingContextData) => ({
        ...prevTainingContextData,
        filteredTrackMembersData: updatedSelectedJobs.length
          ? prevTainingContextData.trackMembersData?.filter((mem) =>
              updatedSelectedJobs.some((job) => job.name === mem.role)
            )
          : prevTainingContextData.trackMembersData,
      }));
      return {
        ...prevFilterState,
        selectedJobs: updatedSelectedJobs,
        selectedJobsId: updatedSelectedJobsId,
        totalFiltersTypes: isChecked
          ? prevFilterState.totalFiltersTypes + 1
          : prevFilterState.totalFiltersTypes - 1,
        mergedFiltersArray: updatedMergedFiltersArray,
      };
    });
  };

  //code to handle member status filter
  const handleMembersStatusSelection = (
    e: React.ChangeEvent<HTMLInputElement>,
    status: ITypes
  ) => {
    const isChecked = e?.target?.checked;

    setFilters((prevFilterState) => {
      const updatedSelectedStatuses = isChecked
        ? [...prevFilterState?.selectedStatuses, status]
        : prevFilterState?.selectedStatuses?.filter(
            (item) => item?.eid !== status?.eid
          );

      const updatedSelectedStatusesId = isChecked
        ? [...prevFilterState?.selectedStatusesId, status?.eid]
        : prevFilterState?.selectedStatusesId?.filter(
            (item) => item !== status?.eid
          );

      const updatedMergedFiltersArray = isChecked
        ? [
            ...prevFilterState.mergedFiltersArray,
            { ...status, customType: 'statuses' },
          ]
        : prevFilterState.mergedFiltersArray?.filter(
            (item) => item?.eid !== status?.eid
          );

      // Update Training Context Data based on status selection
      setTrainingContextData((prevState) => {
        let filteredMembers = prevState?.trackMembersData || [];

        // Apply filters based on selected statuses
        updatedSelectedStatuses.forEach((selectedStatus) => {
          if (selectedStatus?.name === 'Overdue') {
            filteredMembers = filteredMembers?.filter(
              (member) => member?.overdue > 0
            );
          } else if (selectedStatus?.name === 'Evaluation required') {
            filteredMembers = filteredMembers?.filter(
              (member) => member?.avgScore < 50
            );
          }
        });

        // Reset to all members if no status is selected
        if (!updatedSelectedStatuses.length) {
          filteredMembers = prevState.trackMembersData;
        }

        return {
          ...prevState,
          filteredTrackMembersData: filteredMembers,
        };
      });

      return {
        ...prevFilterState,
        selectedStatuses: updatedSelectedStatuses,
        selectedStatusesId: updatedSelectedStatusesId,
        totalFiltersTypes: isChecked
          ? prevFilterState.totalFiltersTypes + 1
          : prevFilterState.totalFiltersTypes - 1,
        mergedFiltersArray: updatedMergedFiltersArray,
      };
    });
  };

  //code to handle members locations select filter
  const handleLocationsSelection = (
    e: React.ChangeEvent<HTMLInputElement>,
    location: ITypes
  ) => {
    setFilters((prevFilterState) => {
      const isChecked = e.target.checked;
      const updatedSelectedLocations = isChecked
        ? [...prevFilterState.selectedLocations, location]
        : prevFilterState.selectedLocations.filter(
            (item) => item.eid !== location.eid
          );

      const updatedSelectedLocationsId = isChecked
        ? [...prevFilterState.selectedLocationsId, location.eid]
        : prevFilterState.selectedLocationsId.filter(
            (item) => item !== location.eid
          );

      const updatedMergedFiltersArray = isChecked
        ? [
            ...prevFilterState.mergedFiltersArray,
            { ...location, customType: 'locations' },
          ]
        : prevFilterState.mergedFiltersArray.filter(
            (item) => item.eid !== location.eid
          );

      //set the filteredDatainside the context within using updatedSelectedLocations
      setTrainingContextData((prevTainingContextData) => ({
        ...prevTainingContextData,
        filteredTrackMembersData: updatedSelectedLocations.length
          ? //filter out the locations with same eid
            prevTainingContextData.filteredTrackMembersData?.filter((memData) =>
              memData.locations?.some((loc) =>
                updatedSelectedLocations.some(
                  (selectedLocations) => selectedLocations.eid === loc.eid
                )
              )
            )
          : prevTainingContextData.trackMembersData,
      }));
      return {
        ...prevFilterState,
        selectedLocations: updatedSelectedLocations,
        selectedLocationsId: updatedSelectedLocationsId,
        totalFiltersTypes: isChecked
          ? prevFilterState.totalFiltersTypes + 1
          : prevFilterState.totalFiltersTypes - 1,
        mergedFiltersArray: updatedMergedFiltersArray,
      };
    });
  };
  //code to handle categories filter
  const handleCategorySelection = (
    e: React.ChangeEvent<HTMLInputElement>,
    category: ITypes
  ) => {
    if (e?.target?.checked) {
      setFilters((prevFilterState) => {
        return {
          ...prevFilterState,
          selectedCategories: [
            ...prevFilterState?.selectedCategories,
            category,
          ],
          selectedCategoriesId: [
            ...prevFilterState?.selectedCategoriesId,
            category?.eid,
          ],
          totalFiltersTypes: prevFilterState.totalFiltersTypes + 1,
          mergedFiltersArray: [
            ...prevFilterState.mergedFiltersArray,
            { ...category, customType: 'categories' },
          ],
        };
      });
    } else {
      setFilters((prevFilterState) => {
        return {
          ...prevFilterState,
          selectedCategories: filters?.selectedCategories?.filter(
            (item) => item?.eid !== category?.eid
          ),
          selectedCategoriesId: filters?.selectedCategoriesId?.filter(
            (item) => item !== category?.eid
          ),
          totalFiltersTypes: prevFilterState.totalFiltersTypes - 1,
          mergedFiltersArray: prevFilterState.mergedFiltersArray?.filter(
            (item) => item?.eid !== category?.eid
          ),
        };
      });
    }
  };

  //code to clear filters
  const clearFilters = (type = 'all') => {
    if (type === 'all') {
      setFilters((prevFilterState) => {
        return {
          ...prevFilterState,
          totalFiltersTypes: 0,
          selectedCategories: [],
          selectedCategoriesId: [],
          selectedTypes: [],
          selectedTypesId: [],
          selectedStatuses: [],
          selectedStatusesId: [],
          mergedFiltersArray: [],
          selectedJobs: [],
          selectedJobsId: [],
          selectedLocations: [],
          selectedLocationIds: [],
        };
      });
    }
    if (type === 'types') {
      setFilters((prevFilterState) => {
        return {
          ...prevFilterState,
          selectedTypes: [],
          selectedTypesId: [],
          totalFiltersTypes:
            prevFilterState.totalFiltersTypes -
            prevFilterState.selectedTypesId?.length,
          mergedFiltersArray: prevFilterState?.mergedFiltersArray?.filter(
            (item) => item?.customType !== 'types'
          ),
        };
      });
    }
    if (type === 'categories') {
      setFilters((prevFilterState) => {
        return {
          ...prevFilterState,
          selectedCategories: [],
          selectedCategoriesId: [],
          totalFiltersTypes:
            prevFilterState.totalFiltersTypes -
            prevFilterState.selectedCategoriesId?.length,
          mergedFiltersArray: prevFilterState?.mergedFiltersArray?.filter(
            (item) => item?.customType !== 'categories'
          ),
        };
      });
    }
    if (type === 'statuses') {
      setFilters((prevFilterState) => {
        return {
          ...prevFilterState,
          selectedStatuses: [],
          selectedStatusesId: [],
          totalFiltersTypes:
            prevFilterState.totalFiltersTypes -
            prevFilterState.selectedStatusesId?.length,
          mergedFiltersArray: prevFilterState?.mergedFiltersArray?.filter(
            (item) => item?.customType !== 'statuses'
          ),
        };
      });
      tableTabIndex === 1 &&
        setTrainingContextData((prevState) => {
          //clear out the status selections for membersSection
          return {
            ...prevState,
            filteredTrackMembersData: prevState.trackMembersData,
          };
        });
    }
    if (type === 'jobs') {
      setFilters((prevFilterState) => {
        return {
          ...prevFilterState,
          selectedJobs: [],
          selectedJobsId: [],
          totalFiltersTypes:
            prevFilterState.totalFiltersTypes -
            prevFilterState.selectedJobsId?.length,
          mergedFiltersArray: prevFilterState?.mergedFiltersArray?.filter(
            (item) => item?.customType !== 'jobs'
          ),
        };
      });
      setTrainingContextData((prevState) => {
        //clear out the jobs selections
        return {
          ...prevState,
          filteredTrackMembersData: prevState.trackMembersData,
        };
      });
    }

    if (type === 'locations') {
      setFilters((prevFilterState) => {
        return {
          ...prevFilterState,
          selectedLocations: [],
          selectedLocationsId: [],
          totalFiltersTypes:
            prevFilterState.totalFiltersTypes -
            prevFilterState.selectedLocationsId?.length,
          mergedFiltersArray: prevFilterState?.mergedFiltersArray?.filter(
            (item) => item?.customType !== 'locations'
          ),
        };
      });
      setTrainingContextData((prevState) => {
        //clear out the locations selections
        return {
          ...prevState,
          filteredTrackMembersData: prevState.trackMembersData,
        };
      });
    }
  };

  // Prevent the default behavior of tab switching when focused on input
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
      e.stopPropagation();
    }
  };

  useEffect(() => {
    if (tableTabIndex !== 0) {
      //for memberTable
      setFilters((prevState) => {
        return {
          ...prevState,
          jobs: filters.jobs,
          locations: filters.locations,
          statuses: statusesMemberFilter,
          types: [],
          categories: [],
        };
      });
    } else {
      //for PathsTable
      setFilters((prevState) => {
        return {
          ...prevState,
          types: typesFilter,
          statuses: statusesFilter,
          categories: trainingCategories,
        };
      });
    }
    clearFilters();
  }, [trainingCategories, tableTabIndex]);
  return (
    <Flex width={'400px'} gap={4} alignItems={'center'}>
      <SearchInput
        disabled={true}
        size='md'
        hideShortcuts={true}
        placeholder={
          tableTabIndex === 0
            ? 'Search by training names'
            : 'Search by member names'
        }
        value={trainingContextData?.trackingTpSearchQuery}
        onChange={(e) =>
          setTrainingContextData((prevState) => {
            const searchQuery = e?.target?.value;
            const filteredArray = prevState.trackMembersData?.filter((member) =>
              member.name
                ?.toLowerCase()
                .includes(searchQuery.trim().toLowerCase())
            );
            return {
              ...prevState,
              trackingTpSearchQuery: e?.target?.value,
              filteredTrackMembersData: filteredArray,
            };
          })
        }
        onKeyDown={(e) => handleKeyDown(e)}
      />

      <Box>
        <FilterContainer
          filters={filters}
          setFilters={setFilters}
          handleTypeSelection={handleTypeSelection}
          handleStatusSelection={handleStatusSelection}
          handleCategorySelection={handleCategorySelection}
          handleJobSelection={handleJobSelection}
          handleMembersStatusSelection={handleMembersStatusSelection}
          handleLocationsSelection={handleLocationsSelection}
          clearFilters={clearFilters}
        />
      </Box>
    </Flex>
  );
};

Header.displayName =
  'packages/sop-web/src/sub-components/training-v2/dashboard/components/TrackPathDashboard/bottomSection/Header';
export default Header;
