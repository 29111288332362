import { useMutation } from '@apollo/client';
import { Box, Flex, useToast } from '@chakra-ui/react';
import React, { FC } from 'react';
import { Button } from 'atoms';
import { DELETE_TRAINING_PATH } from 'sub-components/training-v2/shared/api';

interface IProps {
  trainingId: string;
  cb?: () => void;
  closeModal: () => void;
}

const ModalContent: FC<IProps> = ({ trainingId, cb, closeModal }) => {
  const toast = useToast({
    duration: 3000,
    isClosable: true,
    position: 'top-right',
  });
  const [deleteTp, { loading: deletingTp }] = useMutation<
    { DeleteTP: { succeed: boolean } },
    { eid: string }
  >(DELETE_TRAINING_PATH);

  const onDeleteClick = () => {
    deleteTp({
      variables: {
        eid: trainingId,
      },
    })
      ?.then((res) => {
        toast({
          status: 'success',
          title: 'Success',
          description: 'Training path deleted successfully',
        });
      })
      ?.catch((err) => {
        toast({
          status: 'error',
          title: 'Error',
          description: 'Training path could not be deleted',
        });
      })
      ?.finally(() => {
        cb?.();
        closeModal();
      });
  };

  return (
    <Flex flexDir='column' gap={4}>
      <Box
        as='span'
        fontWeight={500}
        fontSize='15px'
        color='rgba(111, 118, 126, 1)'
      >
        This action will permanently erase all progress you've made while
        creating this draft. Are you sure you want to continue ?
      </Box>
      <Flex w='full' align='center' gap={4}>
        <Button
          w='50%'
          size='lg'
          variant='outline'
          bg='white'
          border='2px solid rgba(239, 239, 239, 1)'
          onClick={closeModal}
        >
          Cancel
        </Button>
        <Button
          w='50%'
          size='lg'
          variant='solid'
          colorScheme='red'
          disabled={deletingTp}
          isLoading={deletingTp}
          onClick={onDeleteClick}
        >
          Delete
        </Button>
      </Flex>
    </Flex>
  );
};

export default ModalContent;
