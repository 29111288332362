export const getBoardViewName = (): string => {
  const href = window?.location?.href || '';

  if (!href || !href.includes('launcher/boards/config')) {
    return '';
  }

  if (window.location?.search?.includes('viewMode=editor')) {
    return 'Editor mode';
  }

  return 'Board view';
};
