import React, { useCallback } from 'react';
import { useResourceModal } from '../common';
import VideoResourceContent, { SResourceProps } from './VideoResourceContent';

type FormResource = (props: SResourceProps) => void;

export const useVideoResource = (): FormResource => {
  const resourceModal = useResourceModal();

  return useCallback((props) => {
    resourceModal({
      title: 'Video',
      content: <VideoResourceContent {...props} />,
    });
  }, []);
};
