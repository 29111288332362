import { Box, Button, Center, Flex, Text } from '@chakra-ui/react';
import { PrimaryButton } from 'atoms';
import { useUserData } from 'hooks';
import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { HeaderColors } from 'shared/headerColors/header-colors';
import { AuthRole } from 'sop-commons/src/client';
import BaseLayoutWrapper from 'sub-components/BaseLayoutWrapper';
import GenericColorHeader from 'sub-components/GenericColorHeader';
import AssignedToMe from './AssignedToMe';
import TrackMembers from './TrackMembers';
import {
  deployEvent,
  CommonAmplitudeEvents,
  AmplitudeEventNames,
} from 'shared/amplitudeEvents';
import { NexusMyTraining } from 'sub-components/nexus/NexusBase/components/RightSection/components/TasksTraining/components/NexusMyTraining';

type IButtonValue = 'track' | 'assigned';

interface HProps {
  value: IButtonValue;
  onClick: (newValue: IButtonValue) => void;
  event?: any;
}

const styles = {
  variant: 'ghost',
  color: '#6F7E7C',
  _hover: {
    bg: 'gray.50',
  },
};

const Header: FC<HProps> = ({ value, onClick, event }) => {
  return (
    <Flex
      align='center'
      gap='12px'
      paddingBlock='8px'
      fontSize='15px'
      fontWeight={500}
      color='#6F767E'
    >
      <Button
        {...(value === 'track' ? {} : styles)}
        onClick={() => {
          onClick('track');
          event && deployEvent(event.TRAINING_TRACK_TAB);
        }}
      >
        Track
      </Button>
      <Button
        {...(value === 'assigned' ? {} : styles)}
        onClick={() => {
          onClick('assigned');
          event && deployEvent(event.TRAINING_ASSIGNED_TO_YOU_TAB);
        }}
      >
        <Flex align='center' justify='center' gap='5px'>
          <Text>Assigned to you</Text>
        </Flex>
      </Button>
      <Box flex={1} />
    </Flex>
  );
};

const TrainingBoard: FC = ({ event }) => {
  const history = useHistory();
  const userData = useUserData();
  const [value, setValue] = useState<IButtonValue>('track');
  const [showViewAllBtn, setShowViewAllBtn] = useState(true);
  const onClick = (newValue: IButtonValue) => {
    setValue(newValue);
  };
  return (
    // <BaseLayoutWrapper>
    //   <Flex p={6} flexDir='column' w='full' gap={4}>
    //     <Flex align='center' justify='space-between'>
    //       <GenericColorHeader
    //         title={
    //           userData?.authRole !== AuthRole.WORKER &&
    //           userData?.type !== 'branch'
    //             ? 'Trainings'
    //             : 'My Trainings'
    //         }
    //         color={HeaderColors.Blue}
    //       />
    //       {showViewAllBtn && (
    //         <Box>
    //           <PrimaryButton
    //             title='View all'
    //             variant='outline'
    //             style={{ color: '#6F767E' }}
    //             onClick={() => {
    //               deployEvent(AmplitudeEventNames.TRAINING_VISIT, {
    //                 from_where: 'Home Page',
    //               });
    //               history.push('/training');
    //             }}
    //           />
    //         </Box>
    //       )}
    //     </Flex>
    //     {userData?.authRole !== AuthRole.WORKER &&
    //     userData?.type !== 'branch' ? (
    //       <>
    //         <Header value={value} onClick={onClick} event={event} />
    //         <Flex w='full'>
    //           {value === 'track' && (
    //             <TrackMembers
    //               setShowViewAllBtn={setShowViewAllBtn}
    //               event={CommonAmplitudeEvents.MEMBER_HOME}
    //             />
    //           )}
    //           {value === 'assigned' && (
    //             <AssignedToMe setShowViewAllBtn={setShowViewAllBtn} />
    //           )}
    //         </Flex>
    //       </>
    //     ) : (
    //       <Flex w='full'>
    //         <AssignedToMe setShowViewAllBtn={setShowViewAllBtn} />
    //       </Flex>
    //     )}
    //   </Flex>
    // </BaseLayoutWrapper>
    <NexusMyTraining mode='table' />
  );
};

export default TrainingBoard;
