import React, { FC } from 'react';
import { DashboardContainer } from 'sub-components';
import { TrackPathDashboard } from 'sub-components/training-v2/dashboard';
import TrainingPathDashboard from './TrainingPathDashboard';

type Props = {};

const TrainingPathTracking: FC = () => {
  return (
    <DashboardContainer>
      <TrackPathDashboard />
    </DashboardContainer>
  );
};

export default TrainingPathTracking;
