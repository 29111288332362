import { Avatar, Box, Flex, Image, Text } from '@chakra-ui/react';
import { Table } from 'antd/lib';
import { Button, ColumnsType } from 'atoms';
import { FC, useEffect, useState } from 'react';
import { useViewResponse } from '../viewResponse';
import { useLazyQuery } from '@apollo/client';
import { GET_TP_SESSION_BY_ID } from '../../Tracking/query/tracking.graphql';
import { useEvaluateResponses } from 'sub-components/training-v2/shared/quiz';
import Loader from 'sub-components/Loader';
import { LocationBadge } from '../../Tracking/components';
import { getImage } from 'utils';

interface IProps {
  users: any[];
  sessionId: string;
  onEvaluateComplete?: () => void;
}

const PendingUserList: FC<IProps> = ({
  users,
  sessionId,
  onEvaluateComplete,
}) => {
  const userFormResponse = useViewResponse();
  const evaluate = useEvaluateResponses();
  const [userData, setUserData] = useState({
    name: '',
    id: '',
  });

  const [getTpSessionById, { loading }] = useLazyQuery(GET_TP_SESSION_BY_ID, {
    onCompleted(data) {
      const tpScheduleInfo = {
        startDate: data?.TpSessionById?.training?.startDate,
        assignmentType: data?.TpSessionById?.training?.assignmentType,
        deadlineType: data?.TpSessionById?.training?.deadlineType,
        deadlineDate: data?.TpSessionById?.training?.deadlineDate,
        duration: data?.TpSessionById?.training?.duration,
      };

      evaluate({
        evalAssigneeMeta: {
          name: userData.name,
          tpName: data?.TpSessionById?.title,
        },
        quizResponses: [],
        mode: 'summarized',
        skillVerifier: data?.TpSessionById?.skillVerifier,
        trainingId: data?.TpSessionById?.trainingId,
        userId: userData.id,
        sessionId: sessionId,
        maxScore: data?.TpSessionById?.userProgress?.[0].maxScore || 0,
        score: data?.TpSessionById?.userProgress?.[0].score || 0,
        userName: userData.name,
        tpScheduleInfo,
        onEvaluateComplete: onEvaluateComplete,
        hasSkillVerifier: data?.TpSessionById?.hasSkillVerifier,
        tp: data?.TpSessionById?.training,
      });
    },
    fetchPolicy: 'no-cache',
  });

  const columns: ColumnsType<any> = [
    {
      title: 'Members',
      dataIndex: 'name',
      key: 'name',
      render: (data, user) => (
        <Flex
          gap={3}
          flex={1}
          onClick={() => {
            userFormResponse({
              sessionId: sessionId,
              userId: user.eid,
              userName: user.name,
            });
          }}
          cursor='pointer'
        >
          <Image
            src={getImage(user.profilePic, user.name)}
            width='10'
            height='10'
            borderRadius='8px'
          />
          <Flex flexDirection='column'>
            <Text as='span' color='#111315' fontWeight={600}>
              {data}
            </Text>
            <Box color='#6F767E' fontWeight={500} fontSize='12px'>
              {user.role}
            </Box>
          </Flex>
        </Flex>
      ),
    },
    {
      title: 'Job',
      dataIndex: 'role',
      key: 'role',
      render: (data) => (
        <Box as='span' color='#8E59FF' fontWeight={500} fontSize='12px'>
          {data}
        </Box>
      ),
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      render: (data, record) => {
        return <LocationBadge locations={record.locations || []} />;
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (data, record) => (
        <Button
          variant='unstyled'
          as='span'
          color='#2A85FF'
          fontWeight={700}
          cursor='pointer'
          isLoading={loading && record.eid === userData.id}
          disabled={loading && record.eid === userData.id}
          onClick={() => {
            setUserData({
              id: record.eid,
              name: record.name,
            });

            getTpSessionById({
              variables: {
                eid: sessionId,
              },
            });
          }}
        >
          Evaluate
        </Button>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={users}
      rowKey='eid'
      pagination={{
        hideOnSinglePage: true,
      }}
    />
  );
};

PendingUserList.displayName =
  'displayName:sub-components/training-v2/dashboard/components/myTrainings/pendingEvaluation/PendingUserList.tsx';

export default PendingUserList;
