import React, { FC } from 'react';
import { CloseIcon } from '@chakra-ui/icons'; // Import CloseIcon
import {
  Flex,
  FormControl,
  FormErrorMessage,
  IconButton,
} from '@chakra-ui/react';
import { Controller } from 'react-hook-form';
import { IFormInput } from '../../audit-schedule.types';
import LocationSelect from './LocationSelect';
import { useAuditorOptions } from './useAuditorOptions';
import AuditorUserSelect from './AuditorUserSelect';

interface IProps {
  fieldId: string;
  fieldIndex: number;
  removeAuditor: () => void;
  totalAuditors: number;
}

const AuditorInput: FC<IProps> = ({
  fieldIndex,
  removeAuditor,
  totalAuditors,
}) => {
  const options = useAuditorOptions(fieldIndex);

  return (
    <Flex gap={'12px'} alignItems='center'>
      <Controller<IFormInput, `auditors.${number}.userId`>
        name={`auditors.${fieldIndex}.userId`}
        rules={{
          required: 'Please select an auditor',
        }}
        render={({ field, fieldState }) => (
          <FormControl width='unset' flex={2} isInvalid={fieldState.invalid}>
            <AuditorUserSelect {...field} options={options.auditors} />
            <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
          </FormControl>
        )}
      />
      <Controller<IFormInput, `auditors.${number}.locations`>
        name={`auditors.${fieldIndex}.locations`}
        rules={{
          required: 'Please select a location',
        }}
        render={({ field, fieldState }) => (
          <FormControl
            display='flex'
            flexDir='column'
            alignItems='flex-end'
            width='unset'
            flex={1}
            isInvalid={fieldState.invalid}
          >
            <LocationSelect
              options={options.locations}
              value={field.value}
              onChange={field.onChange}
              disabled={field.disabled}
            />
            <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
          </FormControl>
        )}
      />
      {totalAuditors > 1 && (
        <IconButton
          aria-label='Remove auditor'
          icon={<CloseIcon />}
          size='sm'
          variant='ghost'
          color='#6F767E'
          onClick={removeAuditor}
          ml={'24px'}
        />
      )}
    </Flex>
  );
};

export default React.memo(AuditorInput);
