import { gql } from '@apollo/client';

export const GET_ALL_LOCATION_OWNERS = gql`
  query GetAllLocationOwners(
    $entityId: String!
    $authRole: [userAuthRoleEnum]
    $status: [userStatusEnum]
  ) {
    EntityUser(entityId: $entityId, authRole: $authRole, status: $status) {
      authRole
      eid
      locations {
        eid
        name
      }
      name
      profilePic
      role
    }
  }
`;

export const UPDATE_LOCATION_OWNERS_FOR_LOCATION = gql`
  mutation UpdateLocationOwnersForLocation($input: UserUpdateIdInput) {
    updateUserById(input: $input) {
      eid
    }
  }
`;

export const NOTIFY_LOCATION_OWNERS_FOR_LOCATION = gql`
  query LocationLaunchNotify($eid: String!) {
    LocationLaunchNotify(eid: $eid) {
      succeed
    }
  }
`;
