import React from 'react';
import { TFunction } from 'i18next';
import { IFormResponseItemsEntity, Respondent } from 'pages/forms/forms-types';
import FileComponent from './FileComponent';
import { ILocationFilter } from './FormResponseTable';
import ImageComponent from './ImageComponent';
import LocationCellComponent from './LocationCellComponent';
import LocationComponent from './LocationComponent';
import LocationHeaderComponent from './LocationHeaderComponent';
import LongAnswerComponent from './LongAnswerComponent';
import MemberComponent from './MemberComponent';
import QuestionCell from './QuestionCell';
import ShortAnswerComponent from './ShortAnswerComponent';
import UserDateComponent from './UserDateComponent';
import UserDetailsComponent from './UserDetailsComponent';
import UserHeaderComponent from './UserHeaderComponent';
import VideoComponent from './VideoComponent';
import { toArray } from '../../../../utils';
import { AcknowledgeCell, CheckBoxCell, DropDownCell } from './ResponseCell';
import { QuestionType } from '../../../../types';
import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { UserEntityData } from 'shared/graphql/shared-types';

export const getTableData = (
  questions: IFormResponseItemsEntity['form']['questions'],
  items: IFormResponseItemsEntity[]
) => {
  if (questions?.length === 0) return [];
  let tableData: any[] = [];
  items?.map((item) => {
    let responseDate = item?.completedAt;
    let user = item;
    let loggedInUserLocation = item?.createdBy?.locations?.[0]?.name;
    let remainingLoggedInUserLocations = item?.createdBy?.locations?.slice(1);

    const response = toArray(item?.response).reduce<
      Record<string, IFormResponseItemsEntity['response'][number]>
    >((previousValue, currentValue) => {
      previousValue[currentValue.qid] = currentValue;
      return previousValue;
    }, {});

    tableData.push({
      responseDateOfUser: responseDate,
      user: user,
      loggedInUserLocation: loggedInUserLocation,
      remainingLoggedInUserLocations: remainingLoggedInUserLocations,
      ...response,
    });
  });
  return tableData;
};

const getHeaderComponent = (
  question: any,
  questions: IFormResponseItemsEntity['form']['questions'],
  setQuestions: React.Dispatch<
    React.SetStateAction<IFormResponseItemsEntity['form']['questions']>
  >,
  onQuestionFilterChange: (ids?: string[]) => void
) => {
  return (
    <QuestionCell
      question={question}
      questions={questions}
      setQuestions={setQuestions}
      onQuestionFilterChange={onQuestionFilterChange}
    />
  );
};

export const getTableHeader = (
  t: TFunction<('common' | 'form')[], undefined, ('common' | 'form')[]>,
  showFilters: boolean | undefined,
  usersData: Respondent[] | undefined,
  getSelectedCountHandler: (type: 'user' | 'location') => number,
  onFilterCloseHandler: () => void,
  userFilterClickHandler: ((user: Respondent) => void) | undefined,
  formId: string,
  showLocation: boolean | undefined,
  locationsData: ILocationFilter[] | undefined,
  locationFilterClickHandler: ((location: ILocationFilter) => void) | undefined,
  questions: IFormResponseItemsEntity['form']['questions'],
  setQuestions: React.Dispatch<
    React.SetStateAction<IFormResponseItemsEntity['form']['questions']>
  >,
  onQuestionFilterChange: (ids?: string[]) => void,
  allUsers?: UserEntityData[]
) => {
  let headers: any[] = [
    {
      Header: t('form:date'),
      sticky: 'left',
      accessor: 'responseDateOfUser',
      width: 150,
      Cell: ({ cell: { value, row } }: any) => {
        return <UserDateComponent date={value} />;
      },
    },
    {
      Header: (
        <UserHeaderComponent
          showFilters={showFilters}
          usersData={usersData}
          getSelectedCountHandler={getSelectedCountHandler}
          onFilterCloseHandler={onFilterCloseHandler}
          userFilterClickHandler={userFilterClickHandler}
        />
      ),
      sticky: 'left',
      accessor: 'user',
      width: 250,
      Cell: ({ cell: { value, row } }: any) => {
        return <UserDetailsComponent userDetails={value} formId={formId} />;
      },
    },
  ];
  if (showLocation) {
    headers.push({
      Header: (
        <LocationHeaderComponent
          locationsData={locationsData}
          showFilters={showFilters}
          getSelectedCountHandler={getSelectedCountHandler}
          locationFilterClickHandler={locationFilterClickHandler}
          onFilterCloseHandler={onFilterCloseHandler}
        />
      ),
      sticky: 'left',
      accessor: 'loggedInUserLocation',
      width: 250,
      Cell: ({ cell: { value, row } }: any) => {
        // return <LocationCellComponent value={value} />;
        return (
          <Flex align='center' gap='5px'>
            <LocationCellComponent value={value} />
            {row?.original?.remainingLoggedInUserLocations?.length > 1 && (
              <Tooltip
                label={row?.original?.remainingLoggedInUserLocations
                  ?.slice(1)
                  ?.map((loc: any) => loc?.name)
                  ?.join(', ')}
                hasArrow
                placement='top'
              >
                <Box
                  border='1px solid rgba(221, 221, 221, 1)'
                  borderRadius='5px'
                  p='2px 4px'
                  cursor='pointer'
                >
                  <Text color='#6F767E'>
                    + {row?.original?.remainingLoggedInUserLocations?.length}
                  </Text>
                </Box>
              </Tooltip>
            )}
          </Flex>
        );
      },
    });
  }
  questions?.map((question, index) => {
    headers?.push({
      Header: getHeaderComponent(
        question,
        questions,
        setQuestions,
        onQuestionFilterChange
      ),
      id: question.eid + index,
      accessor: question.eid,
      width: 300,
      Cell: ({ cell: { value, row } }: any) => {
        switch (value?.qType as QuestionType) {
          case 'shortText':
            return (
              <ShortAnswerComponent
                shortAnswer={value?.response || ''}
                noteImages={value?.noteImage}
              />
            );
          case 'longText':
            return (
              <LongAnswerComponent
                longAnswer={{
                  answer: value?.response!,
                  eid: value?.eid!,
                }}
                noteImages={value?.noteImage}
              />
            );

          case 'dropDown':
            return <DropDownCell response={value} question={question} />;
          case 'fileUpload':
            return (
              <FileComponent
                files={value?.responseUrl}
                noteText={value?.noteText}
                noteImages={value?.noteImage}
              />
            );
          case 'imageUpload':
            return (
              <ImageComponent
                images={value?.responseUrl || []}
                noteText={value?.noteText}
              />
            );
          case 'location':
            return (
              <LocationComponent
                locations={value?.responseId || []}
                userList={allUsers}
              />
            );

          case 'member':
            return (
              <MemberComponent
                memberIds={value?.responseId || []}
                userList={allUsers}
              />
            );

          // @ts-ignore
          case 'singleChoice':
          case 'multiChoice':
            return (
              <CheckBoxCell
                response={value}
                question={question}
                isMultiChoice
              />
            );
          case 'checkBox': {
            return <CheckBoxCell response={value} question={question} />;
          }

          case 'videoUpload':
            return (
              <td>
                <VideoComponent
                  videos={value?.responseUrl || []}
                  noteText={value?.noteText}
                />
              </td>
            );

          case QuestionType.ACKNOWLEDGMENT:
            return (
              <td>
                <AcknowledgeCell
                  text={value?.response || ''}
                  font={value?.font || ''}
                  // date={row.original?.responseDateOfUser}
                />
              </td>
            );
          default:
            return (
              <td>
                <div>-</div>
              </td>
            );
        }
      },
    });
  });
  return headers;
};

export const getLocationIds = (
  locationsData: ILocationFilter[] | undefined
) => {
  const selectedLocationIds = locationsData
    ?.filter((location) => location.selected)
    .map((location) => location.eid);
  return selectedLocationIds || [];
};

export const getUserIds = (usersData: Respondent[] | undefined) => {
  return (usersData || [])
    ?.filter((user) => user?.selected)
    ?.map((user) => user?.eid);
};
