import React, { FC, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, Button, CloseButton, Flex, Spacer } from '@chakra-ui/react';
import { useSafeState } from '../../../../../../hooks';
import { IQuizQuestion } from '../../training.graphql';
import { QuizFormInput } from './quiz-resource.graphql';

import { ReactComponent as UndoIcon } from 'assets/images/chapter-editor/undo.svg';

interface IProps {
  onUndoClick: (index: number, question: IQuizQuestion) => void;
}

const QuestionDeletedAlert: FC<IProps> = ({ onUndoClick }) => {
  const { setValue, watch } = useFormContext<QuizFormInput>();

  const [value, updateValue] = useSafeState<QuizFormInput['deletedQuestion']>();

  const timeoutRef = useRef<unknown>();

  const resetUndoTimeout = (shouldSetAlso?: boolean) => {
    timeoutRef.current && clearTimeout(timeoutRef.current as never);

    if (shouldSetAlso) {
      timeoutRef.current = setTimeout(() => {
        setValue('deletedQuestion', undefined);
      }, 5000);
    } else {
      setValue('deletedQuestion', undefined);
    }
  };

  useEffect(() => {
    const subs = watch((values, input) => {
      if (input.name === 'deletedQuestion') {
        const data = values['deletedQuestion'];
        if (data) {
          resetUndoTimeout(true);
        } else {
          timeoutRef.current && clearTimeout(timeoutRef.current as never);
        }
        updateValue(data as never);
      } else if (input.name === 'savedIndex') {
        resetUndoTimeout();
      }
    });

    return () => subs.unsubscribe();
  }, []);

  if (!value) return null;

  return (
    <Flex
      position='sticky'
      top={-1}
      flexDir='column'
      bg='white'
      zIndex={4}
      paddingBottom='4px'
      marginBottom='-4px'
    >
      <Flex
        gap={3}
        padding='4px 16px'
        bg='#2A85FF1A'
        minHeight='40px'
        align='center'
        borderRadius='4px'
      >
        <Box fontSize='13px' fontWeight='500'>
          Question {value?.index + 1} has been deleted
        </Box>

        <Spacer />

        <Button
          size='sm'
          colorScheme='blue'
          variant='ghost'
          fontSize='12px'
          fontWeight='500'
          leftIcon={<UndoIcon width={16} height={16} color='currentColor' />}
          onClick={() => onUndoClick?.(value.index, value.question)}
        >
          Undo
        </Button>

        <CloseButton
          variant='ghost'
          size='sm'
          onClick={() => resetUndoTimeout()}
        />
      </Flex>
    </Flex>
  );
};

export default React.memo(QuestionDeletedAlert);
