import { FilterItem } from './Summary/types';
import { NotificationModule } from './types/types';

export const moduleData: FilterItem[] = [
  {
    eid: '123',
    label: 'Forms',
    value: 'form',
    checked: false,
  },
  {
    eid: '1df3',
    label: 'Trainings',
    value: 'training',
    checked: false,
  },
  {
    eid: '12adsfgas3',
    label: 'Tasks',
    value: 'task',
    checked: false,
  },
  {
    eid: 'adsfs',
    label: 'Audits',
    value: 'audit',
    checked: false,
  },
  {
    eid: 'asdfasdf',
    label: 'Knowledge-base',
    value: 'sop',
    checked: false,
  },
  {
    eid: '12asdfg2w3',
    label: 'Chats',
    value: 'chat',
    checked: false,
  },
  {
    eid: 'Casdcgh2',
    label: 'Location Launcher',
    value: 'locationLauncher',
    checked: false,
  },
  {
    eid: 'asdfaghh',
    label: 'System alerts',
    value: 'system',
    checked: false,
  },
  // {
  //   eid: 'asdvabw2561',
  //   label: 'Others',
  //   value: 'other',
  //   checked: false,
  // },
];

export const categoryTreeWithoutTemplateName = {
  task: {
    taskCreated: {
      //
      label: 'New task creation',
    },
    taskAssigned: {
      //
      label: 'Tasks assigned to employees',
    },
    taskCompleted: {
      // ?
      label: 'Tasks completed',
    },
    taskReminder: {
      //?
      label: 'Task Overdue',
    },
  },
  training: {
    trainingCreated: {
      label: 'New training creation',
    },
    trainingAssignment: {
      label: 'Trainings Assigned',
    },
    trainingReassigned: {
      label: 'Trainings Reassigned',
    },
    trainingCompleted: {
      label: 'Trainings Completed',
    },
    trainingReminder: {
      label: 'Trainings Reminders',
    },
  },
  audit: {
    auditReminder: {
      label: 'Audits assigned',
    },
    auditUpdates: {
      label: 'Audits overdue',
    },
    auditCompletion: {
      label: 'Audits completed',
    },
    auditCreated: {
      label: 'New audit creation',
    },
  },
  form: {
    formResponse: {
      label: 'Forms completed',
    },
  },
  // chat: {
  //   directMessage: {
  //     label: 'Direct Messages',
  //   },
  //   groupMessage: {
  //     label: 'Group Messges',
  //   },
  // },
  location: {
    newLocation: {
      label: 'New location added',
    },
  },
  // other: {
  //   complianceReminder: {
  //     label: 'Compliance documents Reminder',
  //   },
  //   conversationLogAdded: {
  //     label: 'Conversation log added',
  //   },
  // },
  sop: {
    //
    //chapter shared
    sopUpdates: {
      label: 'Chapter updated',
    },
    sopAdded: {
      label: 'Chapter created',
    },
    newFolderAdded: {
      label: 'Folder created',
    },
  },
  systemSettings: {
    delightreeUpdates: {
      label: 'Delightree updates',
    },
    newFeatures: {
      label: 'New Features',
    },
  },
  locationLaunch: {
    llReminder: {
      label: 'Reminder',
    },
    llAssignment: {
      label: 'Assigned to me',
    },
  },
};

export const dummyConfigData = [
  {
    key: 'task',
    title: 'Task notifications',
    description: 'Creating new tasks, managing task locations etc',
  },
  {
    key: 'training',
    title: 'Training notifications',
    description: 'Creating new trainings, managing training paths etc',
  },
  {
    key: 'audit',
    title: 'Audit notifications',
    description: 'Creating new audits, managing audit progress etc',
  },
  {
    key: 'form',
    title: 'Form notifications',
    description: 'Managing form submission',
  },
  {
    key: 'systemSetting',
    title: 'System settings',
    description: 'Delightree updates, new features etc',
  },
  {
    key: 'locationLaunch',
    title: 'Location launcher',
    description: 'Phase Assignment and launcher related reminders',
  },
  {
    key: 'sop',
    title: 'Knowledge-Base',
    description: 'Chapter related updates, Adding chapters etc',
  },
  {
    key: 'chat',
    title: 'Chat notifications',
    description: 'Chats received and sent across various groups',
  },
  // {
  //   key: 'other',
  //   title: 'Other notifications',
  //   description: 'Compliance documents reminders, Conversations logs additions',
  // },
];

export const _notificationModulesOffered: NotificationModule[] = [
  {
    title: 'Tasks',
    value: 'task',
    label:
      'Task activated, Task complete, Assignees changed, New task added etc.',
  },
  {
    title: 'Chats',
    value: 'chat',
    label: 'Chats received and sent across various groups',
  },
  {
    title: 'Audits',
    value: 'audit',
    label:
      'Audit completion, Delayed audits, Audit report generated, Audit assignee changed etc.',
  },
  {
    title: 'Trainings',
    value: 'training',
    label: 'Training assigned, Training completion, Training reminders etc.',
  },
  {
    title: 'Forms',
    value: 'form',
    label: 'Form assigned, Form completion, Form reminders etc.',
  },
  {
    title: 'Location launcher',
    value: 'location',
    label:
      'New phases and tasks added, New locations added, tasks and phase completed or overdue',
  },
  {
    title: 'System alerts',
    value: 'system',
    label: 'Scheduled maintenance, Delightree updates, New features etc.',
  },
];
