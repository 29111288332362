import { ArrowForwardIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Text,
  useTheme,
} from '@chakra-ui/react';
import { FC } from 'react';
import { useViewResponse } from 'sub-components/training-v2/dashboard/components/myTrainings/viewResponse';

interface ScoreContainerProps {
  score: number;
  totalQuestions: number;
  sessionId: string;
  userName: string;
  userId: string;
}

const ScoreContainer: FC<ScoreContainerProps> = ({
  score,
  totalQuestions,
  sessionId,
  userId,
  userName,
}) => {
  const userFormResponse = useViewResponse();
  const { colors } = useTheme();
  return (
    <Flex
      p={'20px 24px'}
      bg='#FCFCFC'
      borderRadius='lg'
      boxShadow='sm'
      border='1px'
      borderColor='gray.100'
      justify={'space-between'}
      alignItems={'center'}
    >
      <Flex gap={2} align='flex-start'>
        <Heading size='md'>Quiz score:</Heading>
        <Flex alignItems={'center'} gap={'2px'}>
          <Text
            as='span'
            fontSize='20px'
            fontWeight='bold'
            color={colors.blue[500]}
          >
            {score}
          </Text>
          <Box fontSize='13px' fontWeight={500} color='#6F767E'>
            /{totalQuestions}
          </Box>
        </Flex>
      </Flex>

      <Button
        rightIcon={<ArrowForwardIcon color='#2A85FF' boxSize='22px' />}
        variant='ghost'
        size='sm'
        color={'#2A85FF'}
        fontWeight={700}
        fontSize={'15px'}
        onClick={() => {
          userFormResponse({
            sessionId,
            userId,
            userName,
          });
        }}
      >
        View responses
      </Button>
    </Flex>
  );
};

export default ScoreContainer;
