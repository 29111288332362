import { Center, Grid } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowTrendUp,
  faCircleCheck,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { calculatePercentage } from 'utils';
import useCombinedStore from 'zustandStore/store';
import { usePendingEvaluation } from './myTrainings/pendingEvaluation';
import PerformanceCard from './PerformanceCard';
import { TpOverallAnalytics } from './Tracking/tracking-types';

interface PerformanceSectionProps {
  tpOverallAnalytics: TpOverallAnalytics;
  refetchTrackingAnalytics?: () => void;
}

const PerformanceSection = ({
  tpOverallAnalytics,
  refetchTrackingAnalytics,
}: PerformanceSectionProps) => {
  const usePendingEvaluationModal = usePendingEvaluation();
  const { updateTableTabIndex } = useCombinedStore();

  const trackingPathTable = document.querySelector('#trainingPathTable');

  const scrollToTable = (key: string) => {
    if (key === 'overdue') {
      updateTableTabIndex(1);
    } else if (key === 'totalActivePath') {
      updateTableTabIndex(0);
    }

    if (trackingPathTable) {
      trackingPathTable?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const cardData = [
    {
      title: 'Total active paths',
      value: tpOverallAnalytics?.totalActiveTp || 0,
      icon: (
        <Center
          boxSize='24px'
          borderRadius='50%'
          bg='rgba(111, 118, 126, 1)'
          justifyContent='space-between'
        >
          <FontAwesomeIcon
            icon={faArrowTrendUp as IconProp}
            style={{ color: 'white' }}
          />
        </Center>
      ),
      key: 'totalActivePath',
      onClick: true,
    },
    {
      title: 'Pending Evaluation',
      value: tpOverallAnalytics?.needsEvaluationAssignee || 0,
      icon: (
        <Center boxSize='24px' borderRadius='50%' bg='rgba(111, 118, 126, 1)'>
          <FontAwesomeIcon
            icon={faArrowTrendUp as IconProp}
            style={{ color: 'white' }}
          />
        </Center>
      ),
      onClick: () => {
        usePendingEvaluationModal({
          pendingEvaluationCount:
            tpOverallAnalytics?.needsEvaluationAssignee || 0,
          refetchTrackingAnalytics: refetchTrackingAnalytics,
        });
      },
    },
    {
      title: 'Avg. completion rate',
      value:
        `${calculatePercentage(
          tpOverallAnalytics?.completedAssignee,
          tpOverallAnalytics?.totalAssignee
        )}%` || 0,
      icon: (
        <Center boxSize='24px' borderRadius='50%' bg='rgba(111, 118, 126, 1)'>
          <FontAwesomeIcon
            icon={faCircleCheck as IconProp}
            style={{ color: 'white' }}
          />
        </Center>
      ),
    },
  ];
  return (
    <Grid gap={'12px'} templateColumns='repeat(3, 1fr)' width={'full'}>
      {cardData?.map((card) => (
        <PerformanceCard
          key={card.title}
          title={card?.title}
          value={card?.value}
          icon={card?.icon}
          onClick={card.onClick}
          scrollToTable={() => scrollToTable(card?.key)}
        />
      ))}
    </Grid>
  );
};

export default PerformanceSection;
