import React, { useCallback } from 'react';
import { AlertDialogCloseButton, Flex } from '@chakra-ui/react';
import { ViewType } from './types';
import { BoxHeader, useConfirm } from 'ui-components';
import { ILocationPerformance } from '../../types';
import ModalContent from './ModalContent';
import { HeaderColors } from 'configs';

interface IProps {
  type: ViewType;
  data: ILocationPerformance[];
}

type IViewAll = (props: IProps) => void;

export const useViewAll = (): IViewAll => {
  const confirm = useConfirm();

  return useCallback(({ data, type }) => {
    confirm({
      title: (
        <Flex gap='12px' justify='space-between'>
          <BoxHeader
            title={
              type === 'attrition'
                ? 'Attrition rate'
                : type === 'audit'
                ? 'Audit rate'
                : type === 'overall'
                ? 'Overall rate'
                : type === 'training'
                ? 'Training completion rate'
                : 'Task completion rate'
            }
            color={HeaderColors.Yellow}
          />
          <AlertDialogCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
          />
        </Flex>
      ),
      content: <ModalContent data={data} type={type} />,
      isCentered: false,
      contentProps: {
        py: '16px',
        px: '10px',
        minW: '617px',
      },
      footer: null,
    });
  }, []);
};
