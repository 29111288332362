import { Box, Flex } from '@chakra-ui/react';
import { SearchInput } from 'atoms';
import React, { FC, useEffect, useState } from 'react';
import {
  IAnalyticsContainerHeaderProps,
  TMemberAnalyticsItem,
} from 'sub-components/training-v2/shared/types';
import { IDetailsFilterTypes } from './AnalyticsContainer';
import DetailsFilterContainer from './DetailsFilterContainer';

const AnalyticsContainerHeader: FC<IAnalyticsContainerHeaderProps> = ({
  trackMembersData,
  crossTrainMembersData,
  setCrossTrainMembersData,
  setTrackMembersData,
  detailsFilters,
  setDetailsFilters,
  tabIndex,
  originalTrackMembersData,
  originalCrossTrainMembersData,
}) => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [originalData, setOriginalData] = useState<TMemberAnalyticsItem[]>([]);

  //effect to reset the filters on tab change
  useEffect(() => {
    setDetailsFilters((prevFilterState) => {
      return {
        ...prevFilterState,
        totalFiltersTypes: 0,
        selectedStatuses: [],
        selectedStatusesId: [],
        selectedScores: [],
        selectedScoresId: [],
        mergedFiltersArray: [],
      };
    });
  }, [tabIndex]);
  useEffect(() => {
    if (tabIndex === 1) {
      setOriginalData(originalTrackMembersData);
    } else if (tabIndex === 2) {
      setOriginalData(originalCrossTrainMembersData);
    }
    setSearchQuery('');
    // applyFilters(detailsFilters?.selectedStatuses, detailsFilters?.selectedScores);
  }, [tabIndex, originalTrackMembersData, originalCrossTrainMembersData]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
      e.stopPropagation();
    }
  };

  const applyFilters = (statuses: string[], scores: string[]) => {
    const baseData =
      tabIndex === 1
        ? originalTrackMembersData
        : tabIndex === 2
        ? originalCrossTrainMembersData
        : [];
    let filteredData = [...baseData];

    if (statuses?.length > 0) {
      filteredData = filteredData?.filter((member) =>
        statuses?.includes(member?.status)
      );
    }

    if (scores?.length > 0) {
      filteredData = filteredData?.filter((member) => {
        const score = Number(member?.score);
        return scores?.some((filterScore) =>
          filterScore === 'Above 60%' ? score >= 60 : score < 60
        );
      });
    }

    if (searchQuery) {
      filteredData = filteredData?.filter((member) =>
        member?.name
          ?.toLowerCase()
          ?.includes(searchQuery?.toLowerCase()?.trim())
      );
    }

    // Update the correct state based on the active tab
    if (tabIndex === 1) {
      setTrackMembersData(filteredData);
    } else if (tabIndex === 2) {
      setCrossTrainMembersData(filteredData);
    }
  };

  const handleStatusSelection = (
    e: React.ChangeEvent<HTMLInputElement>,
    status: IDetailsFilterTypes
  ) => {
    const isChecked = e?.target?.checked;

    setDetailsFilters((prevFilterState) => {
      const updatedStatuses = isChecked
        ? [...prevFilterState?.selectedStatuses, status?.name]
        : prevFilterState?.selectedStatuses?.filter((s) => s !== status?.name);

      const updatedStatusIds = isChecked
        ? [...prevFilterState?.selectedStatusesId, status?.eid]
        : prevFilterState?.selectedStatusesId?.filter((s) => s !== status?.eid);

      const newFilters = {
        ...prevFilterState,
        selectedStatuses: updatedStatuses,
        selectedStatusesId: updatedStatusIds,
        mergedFiltersArray: isChecked
          ? [
              ...prevFilterState?.mergedFiltersArray,
              { ...status, customType: 'memberStatuses' },
            ]
          : prevFilterState?.mergedFiltersArray?.filter(
              (item) => item?.eid !== status?.eid
            ),
        totalFiltersTypes: isChecked
          ? prevFilterState?.totalFiltersTypes + 1
          : prevFilterState?.totalFiltersTypes - 1,
      };

      applyFilters(updatedStatuses, prevFilterState?.selectedScores);
      return newFilters;
    });
  };

  const handleScoreSelection = (
    e: React.ChangeEvent<HTMLInputElement>,
    score: IDetailsFilterTypes
  ) => {
    const isChecked = e?.target?.checked;

    setDetailsFilters((prevFilterState) => {
      const updatedScores = isChecked
        ? [...prevFilterState?.selectedScores, score?.name]
        : prevFilterState?.selectedScores?.filter((s) => s !== score?.name);

      const updatedScoreIds = isChecked
        ? [...prevFilterState?.selectedScoresId, score?.eid]
        : prevFilterState?.selectedScoresId?.filter((s) => s !== score?.eid);

      const newFilters = {
        ...prevFilterState,
        selectedScores: updatedScores,
        selectedScoresId: updatedScoreIds,
        mergedFiltersArray: isChecked
          ? [
              ...prevFilterState?.mergedFiltersArray,
              { ...score, customType: 'memberScores' },
            ]
          : prevFilterState?.mergedFiltersArray?.filter(
              (item) => item?.eid !== score?.eid
            ),
        totalFiltersTypes: isChecked
          ? prevFilterState?.totalFiltersTypes + 1
          : prevFilterState?.totalFiltersTypes - 1,
      };

      applyFilters(prevFilterState?.selectedStatuses, updatedScores);
      return newFilters;
    });
  };

  useEffect(() => {
    applyFilters(
      detailsFilters?.selectedStatuses,
      detailsFilters?.selectedScores
    );
  }, [searchQuery, detailsFilters]);

  const clearFilters = (type = 'all') => {
    setDetailsFilters((prevFilterState) => {
      let newFilters = { ...prevFilterState };

      if (type === 'all') {
        newFilters = {
          ...prevFilterState,
          totalFiltersTypes: 0,
          selectedStatuses: [],
          selectedStatusesId: [],
          selectedScores: [],
          selectedScoresId: [],
          mergedFiltersArray: [],
        };
      } else if (type === 'detailsStatuses') {
        newFilters.selectedStatuses = [];
        newFilters.selectedStatusesId = [];
        newFilters.mergedFiltersArray =
          prevFilterState?.mergedFiltersArray?.filter(
            (item) => item?.customType !== 'memberStatuses'
          );
      } else if (type === 'detailsScores') {
        newFilters.selectedScores = [];
        newFilters.selectedScoresId = [];
        newFilters.mergedFiltersArray =
          prevFilterState?.mergedFiltersArray?.filter(
            (item) => item?.customType !== 'memberScores'
          );
      }

      applyFilters(newFilters?.selectedStatuses, newFilters?.selectedScores);
      return newFilters;
    });
  };

  return (
    <Flex width={'400px'} gap={4} alignItems={'center'}>
      <SearchInput
        size='md'
        hideShortcuts={true}
        placeholder='Search by member names'
        value={searchQuery}
        onChange={(e) => setSearchQuery(e?.target?.value)}
        onKeyDown={handleKeyDown}
      />
      <Box>
        <DetailsFilterContainer
          detailFilters={detailsFilters}
          setDetailsFilters={setDetailsFilters}
          clearFilters={clearFilters}
          handleStatusSelection={handleStatusSelection}
          handleScoreSelection={handleScoreSelection}
        />
      </Box>
    </Flex>
  );
};

export default AnalyticsContainerHeader;
