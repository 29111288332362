import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { TpCategoryEntity } from './tp-category.graphql';

interface IProps {
  item: TpCategoryEntity;
  isSelected?: boolean;
  onClick?: () => void;
}

const CategoryItem: FC<IProps> = ({ item, isSelected, onClick, children }) => {
  return (
    <Flex
      gap={3}
      height='48px'
      minHeight='48px'
      alignItems='center'
      paddingInline='12px'
      borderRadius='12px'
      // cursor='pointer'
      _hover={{ bg: '#F4F4F3' }}
      color='#6F767E'
      aria-selected={isSelected}
      _selected={{
        bgColor: '#2A85FF1A',
        color: '#111315',
      }}
      onClick={onClick}
    >
      <Box flex={1} fontSize='15px' fontWeight='600' isTruncated>
        {item.name}
      </Box>

      {children}
    </Flex>
  );
};

export default CategoryItem;
