import React, { FC } from 'react';
import { Box, Flex, Tooltip } from '@chakra-ui/react';
import moment from 'moment';
import { stringToColor } from '../../../../../../utils';
import { BaseResourceItem } from '../common';
import { FormResourceItem } from './form-resource.graphql';

interface IProps {
  data: FormResourceItem;
  onClick?: () => void;
}

const FormItem: FC<IProps> = ({ data, onClick }) => {
  const by = data?.lastUpdatedBy || data?.createdBy;
  const date = data?.lastUpdated || data?.createdAt;

  return (
    <BaseResourceItem
      data={data}
      onClick={onClick}
      visibilityCopy='This form has limited visibility'
    >
      <Flex
        flex={1}
        flexDir='column'
        gap={1}
        overflow='hidden'
        userSelect='none'
      >
        <Tooltip
          hasArrow
          padding='6px 10px'
          bgColor='#000000BD'
          label={data.title}
          isDisabled={data.title?.length < 40}
        >
          <Box
            fontSize='15px'
            fontWeight='600'
            color='#33383F'
            lineHeight='24px'
            maxWidth='max-content'
            isTruncated
          >
            {data.title}
          </Box>
        </Tooltip>

        <Flex fontSize='12px' color='#6F767E' align='center' gap={1}>
          last updated on {moment(date).format('DD MMM')}
          <Box
            boxSize='2px'
            minWidth='2px'
            bgColor='#1A1D1F'
            borderRadius='full'
          />
          by {by?.name}
        </Flex>
      </Flex>

      <Flex width='120px' align='center' gap={2} paddingX='10px'>
        <Box
          boxSize='8px'
          minWidth='8px'
          bgColor={stringToColor(data.category)}
          borderRadius='full'
        />
        <Box fontSize='12px' fontWeight='500' color='#33383F' isTruncated>
          {data.category}
        </Box>
      </Flex>
    </BaseResourceItem>
  );
};

export default FormItem;
