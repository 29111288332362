import React, { FC, useMemo } from 'react';
import {
  CheckboxGroup,
  Divider,
  Flex,
  PopoverBody,
  useControllableState,
  usePopoverContext,
} from '@chakra-ui/react';
import { Button } from '../../../../../../../atoms';
import { AuthRole } from '../../../../../../../authorization';
import { StringOrNumber } from '../../../../../../../types';
import { useUserDataSelector } from '../../../../../../../hooks';
import { sxScrollStyle } from '../common.styles';
import CheckItem from './CheckItem';

export const roleOptions = [
  {
    label: 'Superadmin',
    value: AuthRole.SUPER_ADMIN,
  },
  {
    label: 'Admin',
    value: AuthRole.ADMIN,
  },
  {
    label: 'Location Owner',
    value: AuthRole.LOCATION_OWNER,
  },
  {
    label: 'Worker',
    value: AuthRole.WORKER,
  },
];

interface IProps {
  value: string[];
  updateValue: (value: string[]) => void;
}

const AuthRoleSelectContent: FC<IProps> = ({ value, updateValue }) => {
  const userAuthRole = useUserDataSelector((state) => state.authRole);
  const { onClose } = usePopoverContext();
  const [internalValue, internalUpdate] = useControllableState<
    StringOrNumber[]
  >({
    defaultValue: value,
  });

  const options = useMemo(() => {
    if (userAuthRole === AuthRole.LOCATION_OWNER) {
      return roleOptions.filter((role) =>
        [AuthRole.LOCATION_OWNER, AuthRole.WORKER].includes(role.value)
      );
    }

    return roleOptions;
  }, [userAuthRole]);

  return (
    <PopoverBody paddingInline='20px' paddingBlock='16px'>
      <Flex
        flexDir='column'
        gap={1}
        maxHeight='400px'
        overflow='auto'
        sx={sxScrollStyle}
      >
        <CheckboxGroup value={internalValue} onChange={internalUpdate}>
          {options.map((item) => {
            return (
              <React.Fragment key={item.value}>
                <CheckItem value={item.value} label={item.label} />
                <Divider />
              </React.Fragment>
            );
          })}
        </CheckboxGroup>
      </Flex>

      <Button
        size='lg'
        width='full'
        colorScheme='blue'
        onClick={() => {
          setTimeout(onClose);
          updateValue?.(internalValue as string[]);
        }}
      >
        Select
      </Button>
    </PopoverBody>
  );
};

export default AuthRoleSelectContent;
