import React, { FC, useMemo } from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  useModalContext,
} from '@chakra-ui/react';
import { Controller, useController, useForm } from 'react-hook-form';
import { Button } from '../../../../../../../atoms';
import { ActionButton } from '../../../../../../../ui-components';
import { TDuration } from '../../../../../../../types';
import { toNumber } from '../../../../../../../utils';
import { CustomNumberInput } from '../../../common';
import { IRepeatInput } from '../repeating/repeating.types';
import { convertDurationToDays, convertRepeatCycle } from '../schedule.hepers';
import RepeatOnSelect from './RepeatOnSelect';

export interface DurationFormProps {
  repeatCycle?: IRepeatInput | null;
  values?: TDuration;
  onSaveChange?: (values: TDuration) => void;
}

const DurationForm: FC<DurationFormProps> = ({
  repeatCycle,
  values: defaultValues,
  onSaveChange,
}) => {
  const { onClose } = useModalContext();
  const methods = useForm<TDuration>({
    defaultValues: defaultValues,
  });

  const onSubmit = async (values: TDuration) => {
    await new Promise((resolve) => setTimeout(resolve, 1000));

    if (values) {
      onSaveChange?.({
        value: toNumber(values.value)!,
        type: values.type,
      });
    } else {
      onSaveChange?.(values);
    }

    setTimeout(onClose, 100);
  };

  const repeatDur = useMemo(
    () => convertRepeatCycle(repeatCycle!),
    [repeatCycle]
  );

  const { field, fieldState } = useController<TDuration, 'value'>({
    control: methods.control,
    name: 'value',
    defaultValue: 1,
    rules: {
      deps: ['type'],
      validate: (_, duration) => {
        if (repeatDur && repeatDur.dayValue < convertDurationToDays(duration)) {
          return `Path duration must be less than ${repeatDur.value} ${repeatDur.type}.`;
        }
      },
    },
  });

  return (
    <Flex flexDir='column' minHeight='200px' justify='space-between' gap={8}>
      <Flex flexDir='column' padding='12px 24px' gap={3}>
        <Flex gap={5} align='center'>
          <Box
            alignSelf='start'
            fontWeight='600'
            color='#6F767E'
            whiteSpace='nowrap'
            lineHeight='48px'
          >
            Path duration:
          </Box>

          <Flex flex={1} flexDir='column'>
            <Flex gap={5}>
              <FormControl maxWidth='100px' isInvalid={fieldState.invalid}>
                <CustomNumberInput
                  size='lg'
                  variant='auditOutline'
                  textAlign='center'
                  min={1}
                  max={999}
                  {...field}
                  onChange={(_, newValue) => field.onChange(newValue)}
                />
              </FormControl>

              <Controller
                control={methods.control}
                name='type'
                rules={{
                  required: 'Select duration type',
                  deps: ['value'],
                }}
                render={({ field, fieldState }) => (
                  <FormControl maxWidth='150px' isInvalid={fieldState.invalid}>
                    <RepeatOnSelect {...field} />
                    <FormErrorMessage>
                      {fieldState?.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </Flex>
            {fieldState?.invalid ? (
              <Box color='red.500' mt={2}>
                {fieldState?.error?.message}
              </Box>
            ) : null}
          </Flex>
        </Flex>
      </Flex>

      <Flex
        gap={5}
        justify='flex-end'
        padding='12px 20px'
        bgColor='#FCFCFC'
        borderTop='1px solid #EFEFEF'
        borderBottomRadius='md'
      >
        <Button variant='outline' onClick={onClose}>
          Cancel
        </Button>
        <ActionButton
          colorScheme='blue'
          actionFn={methods.handleSubmit(onSubmit)}
        >
          Save
        </ActionButton>
      </Flex>
    </Flex>
  );
};

export default DurationForm;
