import React, { FC } from 'react';
import { Flex, Spacer, useModalContext } from '@chakra-ui/react';
import { ActionButton } from '../../../../../../../../../ui-components';
import AddImageContainer from './AddImageContainer';

export interface AddIProps {
  value?: string;
  onAddClick?: (value: string) => void;
}

const AddImageContent: FC<AddIProps> = ({ onAddClick, value }) => {
  const { onClose } = useModalContext();
  const [internalValue, updateValue] = React.useState<string>(value || '');

  return (
    <Flex flex={1} flexDir='column' width='100%'>
      <Flex
        flex={1}
        gap='40px'
        marginX={7}
        align='start'
        maxHeight='calc(100% - 61px)'
        paddingBottom={4}
      >
        <AddImageContainer value={internalValue} onChange={updateValue} />
      </Flex>

      <Flex
        gap={5}
        paddingY='10px'
        paddingX={4}
        bgColor='#FCFCFC'
        borderTop='1px solid #EFEFEF'
        borderBottomRadius='8px'
      >
        <Spacer />

        <ActionButton
          fontSize='13px'
          colorScheme='blue'
          close={onClose}
          isDisabled={!internalValue}
          actionFn={async () => {
            await new Promise((resolve) => setTimeout(resolve, 200));
            onAddClick?.(internalValue);
          }}
        >
          Save
        </ActionButton>
      </Flex>
    </Flex>
  );
};

export default AddImageContent;
