import React, { forwardRef, useMemo } from 'react';
import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useControllableState,
} from '@chakra-ui/react';
import { CheckIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { SelectOption } from '../../../../../../../atoms';
import { QuizQuestionType } from '../../../../../../../types';

export const QuizTypeOptions: SelectOption<QuizQuestionType>[] = [
  {
    label: 'Multiple choice',
    value: QuizQuestionType.MULTI_CHOICE,
  },
  {
    label: 'True or False',
    value: QuizQuestionType.TRUE_FALSE,
  },
  {
    label: 'Match the set',
    value: QuizQuestionType.MATCH_SET,
  },
  {
    label: 'Reorder',
    value: QuizQuestionType.SEQUENCE,
  },
  {
    label: 'Fill in the blanks',
    value: QuizQuestionType.FILL_IN_BLANK,
  },
];

interface IProps {
  value?: QuizQuestionType;
  onChange?: (value: QuizQuestionType) => void;
}

const QuizTypeDropdown = forwardRef<never, IProps>(
  ({ value, onChange }, ref) => {
    const [internalValue, updateValue] = useControllableState<QuizQuestionType>(
      {
        value: value,
        onChange: onChange,
      }
    );

    const _value = useMemo(() => {
      return QuizTypeOptions.find((val) => val.value === internalValue);
    }, [internalValue]);

    return (
      <Menu
        matchWidth
        placement='bottom-end'
        closeOnSelect
        boundary='scrollParent'
        autoSelect={false}
      >
        <MenuButton
          ref={ref}
          display='flex'
          fontSize='13px'
          fontWeight={500}
          border='2px solid #EFEFEF'
          borderRadius='12px'
          padding='4px 8px 4px 16px'
          minWidth='180px'
          // isDisabled={disabled}
        >
          <Flex gap={2} align='center' justify='space-between'>
            {_value ? (
              <Box as='span' color='#6F767E' whiteSpace='nowrap'>
                {_value.label}
              </Box>
            ) : (
              <Box as='span' opacity='0.5'>
                Select question type
              </Box>
            )}

            <ChevronDownIcon boxSize='26px' color='#6F767E' />
          </Flex>
        </MenuButton>
        <MenuList
          width='inherit'
          display='flex'
          flexDirection='column'
          padding='16px'
          borderRadius='12px'
          zIndex={100}
          gap={1}
        >
          {QuizTypeOptions.map((item) => {
            const isSelected = item.value === internalValue;
            return (
              <MenuItem
                key={item.value}
                gap={3}
                height='38px'
                alignItems='center'
                paddingInline='12px'
                borderRadius='12px'
                cursor='pointer'
                color='#6F767E'
                aria-selected={isSelected}
                _selected={{ bg: '#2A85FF1A', color: '#2A85FF' }}
                _hover={{ bg: '#F4F4F3' }}
                onClick={() => updateValue?.(item.value)}
              >
                <Box flex={1} fontSize='13px' fontWeight='500' isTruncated>
                  {item.label}
                </Box>
                {isSelected ? <CheckIcon /> : null}
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    );
  }
);

QuizTypeDropdown.displayName = 'QuizTypeDropdown';

export default QuizTypeDropdown;
