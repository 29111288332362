import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { TFormInput } from '../../training-input.types';

export const useAssignmentDisabled = (
  disabled?: boolean
): boolean | undefined => {
  const [isAssignedToUsers, status] = useWatch<
    TFormInput,
    ['isAssignedToUsers', 'status']
  >({ name: ['isAssignedToUsers', 'status'] });

  return useMemo(() => {
    if (disabled === true) {
      return disabled;
    }

    if (status === 'TRAINING_STARTED') {
      return undefined;
    }

    if (status === 'TRAINING_ONE_TIME' || status === 'TRAINING_REPEAT') {
      return isAssignedToUsers;
    }

    return disabled;
  }, [disabled, status, isAssignedToUsers]);
};
