import React, { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LAUNCHER_ADD_LOCATION } from '../../../../appRoutes';
import { AmplitudeEvent, deployEvent } from '../../../../shared';
import { HeaderColors } from '../../../../configs';
import { useOpenLocationConfirm } from '../../../../pages/Locations/OpenLocationConfirm/useOpenLocationConfirm';
import { Button } from '../../../../atoms';
import { AuthRole, Authorize } from '../../../../authorization';
import GenericColorHeader from 'sub-components/GenericColorHeader/GenericColorHeader';

interface IProps {
  haveConfig?: boolean;
}

const DashHeader: FC<IProps> = ({ haveConfig }) => {
  const history = useHistory();
  const { t } = useTranslation(['sidebar', 'team']);

  const openLocationConfirm = useOpenLocationConfirm();

  if (!haveConfig) {
    return (
      <Flex align='center' justify='space-between' mt={2}>
        <Text fontWeight={600} fontSize={'24px'} color={'#111315'}>
          {t('sidebar:locationLauncher')}
        </Text>
      </Flex>
    );
  }

  return (
    <Flex align='center' justify='space-between' mt={2} pb={'24px'}>
      <Text fontWeight={600} fontSize={'24px'} color={'#111315'}>
        {t('sidebar:locationLauncher')}
      </Text>

      <Authorize
        permittedFor={['user']}
        permittedRoles={[AuthRole.SUPER_ADMIN]}
      >
        <Button
          leftIcon={<AddIcon />}
          variant='outline'
          style={{
            color: '#1A1D1F',
            backgroundColor: '#FCFCFC',
            borderRadius: '12px',
            border: '2px #EFEFEF solid',
          }}
          onClick={() => {
            deployEvent(AmplitudeEvent.LAUNCHER_ADD_LOCATION_CLICK, {
              location_launcher_visit_funnel_id: 5,
            });
            openLocationConfirm({
              onOkPress: () => {
                deployEvent(AmplitudeEvent.LAUNCHER_CONTINUE_CHARGEABLE_CLICK, {
                  location_launcher_visit_funnel_id: 4,
                });
                history.push(LAUNCHER_ADD_LOCATION);
              },
            });
          }}
        >
          {t('team:addNewLocation')}
        </Button>
      </Authorize>
    </Flex>
  );
};

export default DashHeader;
