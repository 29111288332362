import React, { FC, useMemo } from 'react';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { ActionMenu, IActionMenuData } from '../../../../../ui-components';
import { truncateString } from '../../../../../utils';
import { AppRoute, useHistory } from '../../../../../routes';
import { useBusinessBoards } from './useBusinessBoards';
import { useAddBoard } from './useAddBoard';

interface IProps {}

const BoardListPopup: FC<IProps> = ({ children }) => {
  const { data } = useBusinessBoards();
  const history = useHistory();
  const addBoard = useAddBoard();

  const menuData = useMemo(() => {
    const _menuData: IActionMenuData[] = data.map((con) => ({
      name: truncateString(con.title, 30),
      value: con.eid,
    }));

    _menuData.push({
      name: 'Create Board',
      value: 'create-board',
      textColor: '#2A85FF',
      icon: faPlus,
      normalIcon: 'fontAwesome',
      iconColor: '#2A85FF',
    });

    return _menuData;
  }, [data]);

  const actionHandler = (_: string, value: string) => {
    if (value === 'create-board') {
      addBoard();
    } else {
      history.replace({
        pathname: AppRoute.LAUNCHER_BOARD_CONFIG,
        params: {
          boardId: value,
        },
      });
    }
  };

  return (
    <ActionMenu
      menuData={menuData}
      clickedItem={actionHandler}
      maxHeight='420px'
    >
      <div>{children}</div>
    </ActionMenu>
  );
};

export default BoardListPopup;
