import { Flex } from '@chakra-ui/react';
import { FC } from 'react';
import { IFinalOverallLocationWiseAnalytics } from 'sub-components/training-v2/shared/types';
import { ViewType } from '../../../../nexus/NexusBase/components/LeftSection/components/LocationLeaderboard/components/ViewAll/types';
import ScoreCard from './ScoreCard';

interface IProps {
  completeData: IFinalOverallLocationWiseAnalytics[];
  type: ViewType;
}

const RenderScoreCards: FC<IProps> = ({ completeData, type }) => {
  const highestPerforming = completeData?.slice(0, 3);
  const topLocation = highestPerforming?.[0];
  const secondLocation = highestPerforming?.[1];
  const thirdLocation = highestPerforming?.[2];

  if (highestPerforming?.length === 3) {
    return (
      <Flex gap={3} alignItems='flex-end' justify='center'>
        <Flex flex={1} justify='center'>
          <ScoreCard
            isOwnLocation={secondLocation?.isOwnLocation}
            score={`${secondLocation?.completionPercent?.toFixed(2)}`}
            label={secondLocation?.name || 'Unknown'}
            locationId={secondLocation?.locationId}
            position={2}
            type={type}
          />
        </Flex>
        <Flex flex={1} justify='center'>
          <ScoreCard
            isOwnLocation={topLocation?.isOwnLocation}
            score={`${topLocation?.completionPercent?.toFixed(2)}`}
            label={topLocation?.name || 'Unknown'}
            locationId={topLocation?.locationId}
            position={1}
            isCrown
            type={type}
          />
        </Flex>
        <Flex flex={1} justify='center'>
          <ScoreCard
            isOwnLocation={thirdLocation?.isOwnLocation}
            score={`${thirdLocation?.completionPercent?.toFixed(2)}`}
            label={thirdLocation?.name || 'Unknown'}
            locationId={thirdLocation?.locationId}
            position={3}
            type={type}
          />
        </Flex>
      </Flex>
    );
  } else if (highestPerforming?.length === 2) {
    return (
      <Flex gap={6} alignItems='baseline' justify='center'>
        <Flex flex={1} justify='center'>
          <ScoreCard
            isOwnLocation={topLocation?.isOwnLocation}
            score={`${topLocation?.completionPercent?.toFixed(2)}`}
            label={topLocation?.name || 'Unknown'}
            locationId={topLocation?.locationId}
            position={1}
            isCrown
            type={type}
          />
        </Flex>
        <Flex flex={1} justify='center'>
          <ScoreCard
            isOwnLocation={secondLocation?.isOwnLocation}
            score={`${secondLocation?.completionPercent?.toFixed(2)}`}
            label={secondLocation?.name || 'Unknown'}
            locationId={secondLocation?.locationId}
            position={2}
            type={type}
          />
        </Flex>
      </Flex>
    );
  } else if (highestPerforming?.length === 1) {
    return (
      <Flex justify='center' width='100%'>
        <Flex flex={1} justify='center'>
          <ScoreCard
            isOwnLocation={topLocation?.isOwnLocation}
            score={`${topLocation?.completionPercent?.toFixed(2)}`}
            label={topLocation?.name || 'Unknown'}
            locationId={topLocation?.locationId}
            position={1}
            isCrown
            type={type}
          />
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex gap={4} alignItems='flex-end' justify='center'>
      <Flex flex={1} justify='center'>
        <ScoreCard
          isOwnLocation={secondLocation?.isOwnLocation}
          score={`${secondLocation?.completionPercent?.toFixed(2)}`}
          label={secondLocation?.name || 'Unknown'}
          locationId={secondLocation?.locationId}
          position={2}
          type={type}
        />
      </Flex>
      <Flex flex={1} justify='center'>
        <ScoreCard
          isOwnLocation={topLocation?.isOwnLocation}
          score={`${topLocation?.completionPercent?.toFixed(2)}`}
          label={topLocation?.name || 'Unknown'}
          locationId={topLocation?.locationId}
          position={1}
          isCrown
          type={type}
        />
      </Flex>
      <Flex flex={1} justify='center'>
        <ScoreCard
          isOwnLocation={thirdLocation?.isOwnLocation}
          score={`${thirdLocation?.completionPercent?.toFixed(2)}`}
          label={thirdLocation?.name || 'Unknown'}
          locationId={thirdLocation?.locationId}
          position={3}
          type={type}
        />
      </Flex>
    </Flex>
  );
};

export default RenderScoreCards;
