import React, { FC, useEffect, useMemo, useState } from 'react';
import { Box, Center, Radio, Checkbox, Stack, Flex } from '@chakra-ui/react';
import { Button } from 'atoms';
import Container from './Container';
import { usePlayContext } from 'sub-components/training-v2/play/store/context';
import {
  getQuestionResponse,
  isQuestionCompleted,
} from 'sub-components/training-v2/play/utils';
import { useService } from '../../../layers/useService';
import {
  cleanResponse,
  isLastUnansweredQuestion,
} from 'sub-components/training-v2/play/utils/quizHelpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSquareCheck,
  faCircle,
  faSquare,
  faCircleDot,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faCircleCheck,
  faCircleXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Image } from 'ui-components';
import QuizQuestionTitle from './QuizQuestionTitle';
import { calculateTotalScore } from '../../../utils';

interface Option {
  uid: string;
  text: string;
  isCorrect: boolean;
}

const QuizMCQ: FC = () => {
  const {
    contents,
    isPreview,
    isRetake,
    selectedIndex,
    selectedSubIndex,
    tpSessionData,
    increment,
    updateTpSessionData,
    setQuizSubmissionResult,
  } = usePlayContext((state) => ({
    selectedIndex: state.selectedIndex,
    selectedSubIndex: state.selectedSubIndex,
    contents: state.tpSessionData?.contents || [],
    isPreview: state.isPreview,
    isRetake: state.isRetake,
    tpSessionData: state.tpSessionData,
    increment: state.increment,
    updateTpSessionData: state.updateTpSessionData,
    setQuizSubmissionResult: state.setQuizSubmissionResult,
  }));

  const service = useService();
  const [dummySubmit, setDummySubmit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const selectedData = useMemo(() => {
    return contents?.[selectedIndex]?.questions?.[selectedSubIndex];
  }, [contents, selectedIndex, selectedSubIndex]);

  const isCompleted = useMemo(() => {
    return isRetake && !dummySubmit
      ? false
      : isQuestionCompleted(
          selectedData?.eid,
          tpSessionData?.userProgress || []
        );
  }, [selectedData, tpSessionData, dummySubmit]);

  const questionResponse = useMemo(() => {
    if (isRetake) return;
    return getQuestionResponse(
      selectedData?.eid,
      tpSessionData?.userProgress || []
    );
  }, [selectedData, tpSessionData]);

  const isMultiSelect = useMemo(() => {
    return selectedData?.hasMultipleCorrect;
  }, [selectedData]);

  useEffect(() => {
    setSelectedOptions([]);
    setDummySubmit(false);
    if (
      questionResponse?.qid === selectedData?.eid &&
      questionResponse?.options?.length &&
      !isRetake
    ) {
      setSelectedOptions(questionResponse.options);
    }
  }, [selectedData?.eid, questionResponse]);

  const calculateScore = (selected: string[]) => {
    if (!selectedData?.options)
      return { score: 0, isPartiallyCorrect: false, isCorrect: false };

    if (!isMultiSelect) {
      const selectedOption = selectedData.options.find(
        (opt) => opt.uid === selected[0]
      );
      return {
        score: selectedOption?.isCorrect ? selectedData.score : 0,
        isPartiallyCorrect: false,
        isCorrect: selectedOption?.isCorrect || false,
      };
    }

    const totalCorrectOptions = selectedData.options.filter(
      (opt) => opt.isCorrect
    ).length;
    const scorePerOption = selectedData.score / totalCorrectOptions;

    let correctSelections = 0;
    let hasIncorrectSelection = false;

    selected.forEach((optionId) => {
      const option = selectedData.options?.find((opt) => opt.uid === optionId);
      if (option?.isCorrect) {
        correctSelections++;
      } else {
        hasIncorrectSelection = true;
      }
    });

    let score = correctSelections * scorePerOption;

    if (hasIncorrectSelection) {
      score = score * 0.5;
    }

    if (correctSelections === 0) {
      return { score: 0, isPartiallyCorrect: false, isCorrect: false };
    }

    if (correctSelections === totalCorrectOptions && !hasIncorrectSelection) {
      return { score, isPartiallyCorrect: false, isCorrect: true };
    }

    return { score, isPartiallyCorrect: true, isCorrect: false };
  };

  const handleSubmit = async () => {
    if (
      !selectedData?.eid ||
      !tpSessionData?.eid ||
      !tpSessionData?.trainingId ||
      isSubmitting
    )
      return;

    const { score, isPartiallyCorrect, isCorrect } =
      calculateScore(selectedOptions);
    const isLastQuizQuestion = isLastUnansweredQuestion(
      contents?.[selectedIndex]?.questions,
      tpSessionData?.userProgress
    );

    if (isRetake) {
      // if (tpSessionData?.userProgress?.[0]) {
      const currentDate = new Date();
      const existingProgress = [
        ...(tpSessionData?.userProgress?.[0]?.progress || []),
      ];
      const currentContentIndex = existingProgress?.findIndex(
        (p) => p?.id === contents?.[selectedIndex]?.eid
      );

      const updatedProgressItem = {
        type: contents?.[selectedIndex]?.type,
        id: contents?.[selectedIndex]?.eid,
        score: score,
        isCompleted: isPreview ? false : true,
        responses:
          currentContentIndex >= 0
            ? [
                ...existingProgress?.[currentContentIndex]?.responses?.map(
                  (r) => ({
                    ...r,
                    isCompleted: true,
                  })
                ),
                {
                  qid: selectedData?.eid,
                  score: score,
                  isCorrect: isCorrect,
                  options: selectedOptions,
                  pairs: [],
                  sequence: [],
                  blanks: [],
                  isCompleted: true,
                  createdAt: currentDate,
                  updatedAt: currentDate,
                },
              ]
            : [
                {
                  qid: selectedData?.eid,
                  score: score,
                  isCorrect: isCorrect,
                  options: selectedOptions,
                  pairs: [],
                  sequence: [],
                  blanks: [],
                  isCompleted: true,
                  createdAt: currentDate,
                  updatedAt: currentDate,
                },
              ],
        updatedAt: currentDate,
      };

      if (currentContentIndex >= 0) {
        existingProgress[currentContentIndex] = updatedProgressItem;
      } else {
        existingProgress?.push(updatedProgressItem);
      }

      const updatedUserProgress = {
        ...tpSessionData?.userProgress?.[0],
        progress: existingProgress,
        isCompleted: isPreview ? false : true,
      };

      setDummySubmit(true);
      updateTpSessionData({
        ...tpSessionData,
        userProgress: [updatedUserProgress],
      });
      // }

      setQuizSubmissionResult({
        showResult: true,
        isCorrect: isCorrect,
        isPartiallyCorrect,
        score,
      });
      return;
    }

    const currentProgress = tpSessionData?.userProgress?.[0]?.progress.find(
      (p) => p.id === contents[selectedIndex].eid
    );

    const existingResponses = (currentProgress?.responses || []).map(
      cleanResponse
    );

    const mergedResponses = [
      ...existingResponses.filter((r: any) => r.qid !== selectedData.eid),
      cleanResponse({
        qid: selectedData.eid,
        options: selectedOptions,
        score,
      }),
    ];
    const totalScore =
      calculateTotalScore(contents[selectedIndex].eid, [
        ...(tpSessionData?.userProgress?.[0]?.progress || []),
      ]) + score;
    setIsSubmitting(true);
    try {
      const result = await service.updateTPProgress.updateTPProgress({
        variables: {
          input: {
            isCompleted: isLastQuizQuestion,
            itemId: contents[selectedIndex].eid,
            sessionId: tpSessionData.eid,
            trainingId: tpSessionData.trainingId,
            responses: mergedResponses,
            score: totalScore,
          },
        },
      });

      if (result?.data?.AddTpProgress) {
        updateTpSessionData({
          ...tpSessionData,
          userProgress: [result.data.AddTpProgress],
        });
        setQuizSubmissionResult({
          showResult: true,
          isCorrect: isCorrect,
          isPartiallyCorrect,
          score: score,
        });
      }
    } catch (error) {
      console.error('Error submitting quiz:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const getOptionStyles = (option: Option) => {
    if (!isCompleted) {
      return {
        bg: 'white',
        border: '1px solid #E2E8F0',
      };
    }

    if (selectedOptions.includes(option.uid)) {
      return {
        bg: option.isCorrect
          ? 'rgba(181, 228, 202, 0.3)'
          : 'rgba(255, 106, 85, 0.2)',
        border: option.isCorrect
          ? '1px solid rgba(131, 191, 110, 1)'
          : '1px solid rgba(255, 106, 85, 1)',
      };
    } else if (option.isCorrect && selectedOptions.length > 0) {
      return {
        bg: 'rgba(181, 228, 202, 0.3)',
        border: '1px solid rgba(131, 191, 110, 1)',
      };
    }

    return {
      bg: 'white',
      border: '1px solid #E2E8F0',
    };
  };

  const renderSelectionIcon = (option: Option) => {
    if (!isCompleted) {
      if (isMultiSelect) {
        return (
          <Checkbox
            isChecked={selectedOptions.includes(option.uid)}
            onChange={() => {}}
            isReadOnly
          />
        );
      } else {
        return (
          <Radio
            isChecked={selectedOptions.includes(option.uid)}
            onChange={() => {}}
            isReadOnly
          />
        );
      }
    }

    if (isMultiSelect) {
      if (selectedOptions.includes(option.uid)) {
        return (
          <FontAwesomeIcon
            icon={faSquareCheck as IconProp}
            fontSize='16px'
            color={
              option.isCorrect
                ? 'rgba(131, 191, 110, 1)'
                : 'rgba(255, 106, 85, 1)'
            }
          />
        );
      }
      return (
        <FontAwesomeIcon
          icon={faSquare as IconProp}
          fontSize='16px'
          color='#E2E8F0'
        />
      );
    } else {
      if (selectedOptions.includes(option.uid)) {
        return (
          <FontAwesomeIcon
            icon={faCircleDot as IconProp}
            fontSize='16px'
            color={
              option.isCorrect
                ? 'rgba(131, 191, 110, 1)'
                : 'rgba(255, 106, 85, 1)'
            }
          />
        );
      }
      return (
        <FontAwesomeIcon
          icon={faCircle as IconProp}
          fontSize='16px'
          color='#E2E8F0'
        />
      );
    }
  };

  return (
    <Container>
      <QuizQuestionTitle />

      <Stack spacing={4}>
        {selectedData?.options?.map((option) => {
          const styles = getOptionStyles(option);
          return (
            <Flex
              key={option.uid}
              bg={styles.bg}
              borderRadius='12px'
              p='16px'
              border={styles.border}
              _hover={
                !isCompleted
                  ? {
                      bg: 'rgba(177, 229, 252, 0.3)',
                      border: '1px solid rgba(42, 133, 255, 1)',
                    }
                  : {}
              }
              onClick={() => {
                if (!isCompleted) {
                  if (isMultiSelect) {
                    if (selectedOptions.includes(option.uid)) {
                      setSelectedOptions(
                        selectedOptions.filter((id) => id !== option.uid)
                      );
                    } else {
                      setSelectedOptions([...selectedOptions, option.uid]);
                    }
                  } else {
                    setSelectedOptions([option.uid]);
                  }
                }
              }}
              cursor={!isCompleted ? 'pointer' : 'default'}
            >
              <Flex w='full' justify='space-between' align='center'>
                <Flex align='flex-start' gap={3}>
                  {renderSelectionIcon(option)}
                  {option?.thumbnail && (
                    <Image
                      src={option?.thumbnail}
                      width={60}
                      height={60}
                      style={{
                        borderRadius: '8px',
                        objectFit: 'contain',
                        border: '1px solid #E2E8F0',
                        backgroundColor: 'white',
                      }}
                    />
                  )}
                  <Box>{option.text}</Box>
                </Flex>
                {isCompleted &&
                  (selectedOptions.includes(option.uid) ||
                    option.isCorrect) && (
                    <FontAwesomeIcon
                      icon={
                        option.isCorrect
                          ? (faCircleCheck as IconProp)
                          : (faCircleXmark as IconProp)
                      }
                      fontSize='20px'
                      color={
                        option.isCorrect
                          ? 'rgba(131, 191, 110, 1)'
                          : 'rgba(255, 106, 85, 1)'
                      }
                    />
                  )}
              </Flex>
            </Flex>
          );
        })}
      </Stack>

      <Button
        size='lg'
        colorScheme='blue'
        onClick={() => (isCompleted ? increment() : handleSubmit())}
        isDisabled={selectedOptions.length === 0 || isSubmitting}
        isLoading={isSubmitting}
      >
        {!isCompleted ? 'Submit' : 'Next'}
      </Button>
    </Container>
  );
};

export default QuizMCQ;
