import React, { FC } from 'react';
import { FormControl, FormErrorMessage } from '@chakra-ui/react';
import { callAll } from '@chakra-ui/utils';
import { Controller, useFormContext } from 'react-hook-form';
import { HeaderColors } from '../../../../../../configs';
import { BoxHeader } from '../../../../../../ui-components';
import { FlexContainer, userAssignDisabled } from '../../common';
import { TFormInput } from '../../training-input.types';
import AssigneeGroupList from './AssigneeGroupList';

import AssigneeTypeSelect from './AssigneeTypeSelect';

interface IProps {}

const TrainingAssignee: FC<IProps> = () => {
  const { getValues, setValue } = useFormContext<TFormInput>();
  const assignDisabled = userAssignDisabled();

  const assigneeUserTypeChange = (value: string) => {
    if (
      value === 'new' &&
      getValues('assignees').some((as) => as.type === 'user')
    ) {
      setValue(
        'assignees',
        getValues('assignees').filter((as) => as.type !== 'user')
      );
    }
    if (getValues('assignmentType') === 'training') {
      setValue('dependantTraining.trainingId', null as never);
      setValue('dependantTraining.hasMinScore', false);
      setValue('dependantTraining.minScorePercent', undefined as never);
    }
  };

  return (
    <FlexContainer flexDir='column' gap={5}>
      <BoxHeader
        title='Who will be undergoing this training?'
        color={HeaderColors.Purple}
      />

      <Controller<TFormInput, 'assigneeUserType'>
        name='assigneeUserType'
        disabled={assignDisabled}
        rules={{
          required: 'Select assignee type',
          deps: ['dependantTraining.trainingId'],
        }}
        render={({ field, fieldState }) => (
          <FormControl mt={2} isInvalid={fieldState.invalid}>
            <AssigneeTypeSelect
              {...field}
              onChange={callAll(field.onChange, assigneeUserTypeChange)}
            />
            <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
          </FormControl>
        )}
      />

      <AssigneeGroupList disabled={assignDisabled} />
    </FlexContainer>
  );
};

export default TrainingAssignee;
