import React, { FC } from 'react';
import { useWatch } from 'react-hook-form';
import { MobilePreviewContainer } from '../common';
import MilestonePreviewContent from './MilestonePreviewContent';

interface IProps {}

const MilestonePreview: FC<IProps> = () => {
  const milestone = useWatch();

  return (
    <MobilePreviewContainer>
      <MilestonePreviewContent
        title={milestone?.title}
        thumbnail={milestone?.thumbnail}
      />
    </MobilePreviewContainer>
  );
};

export default React.memo(MilestonePreview);
