import React, { FC, useEffect, useState } from 'react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Flex,
  Text,
  Button,
  Divider,
  Tooltip,
} from '@chakra-ui/react';
import { ReactComponent as LocationIcon } from 'assets/images/form/Location.svg';
import { ReactComponent as CheveroniconIcon } from 'assets/images/cheveronIcon.svg';
import { ReactComponent as CheckCircleIcon } from 'assets/images/ai-search/check-circle.svg';
import { useUserDataSelector } from 'hooks';
import useCombinedStore from 'zustandStore/store';

interface IProps {
  selectedLocationId: string;
  setSelectedLocationId: React.Dispatch<React.SetStateAction<string>>;
}

const LocationFilter: FC<IProps> = ({
  setSelectedLocationId,
  selectedLocationId,
}) => {
  const [selectedLocationName, setSelectedLocationName] =
    useState<string>('All locations');

  const { loggedInUserLocations } = useUserDataSelector((state) => ({
    loggedInUserLocations: state?.locations,
  }));

  const loggedInUserLocationIds = loggedInUserLocations?.map(
    (item) => item?.eid
  );

  const { updateLocationIds } = useCombinedStore();

  // Updating zustand store with locationId which would be used in Location filter in header section
  useEffect(() => {
    if (selectedLocationId === 'all') {
      updateLocationIds(loggedInUserLocationIds);
    } else {
      updateLocationIds([selectedLocationId]);
    }
  }, [selectedLocationId]);

  const selectedLocation = loggedInUserLocations?.find(
    (item) => item?.eid === selectedLocationId
  );

  return (
    <>
      <Popover>
        <PopoverTrigger>
          <Button
            borderRadius={'12px'}
            fontSize={'14px'}
            leftIcon={<LocationIcon height={'14px'} />}
            rightIcon={<CheveroniconIcon width={'18px'} />}
            backgroundColor={'#FFFFFF'}
          >
            <Text isTruncated width={'100px'}>
              {selectedLocation?.name
                ? selectedLocation?.name
                : 'All locations'}
            </Text>
          </Button>
        </PopoverTrigger>
        <PopoverContent
          maxWidth={'220px'}
          borderRadius={'12px'}
          maxHeight={'300px'}
          overflowY={'auto'}
        >
          <PopoverArrow />
          <PopoverBody>
            <Flex cursor={'pointer'} flexDir={'column'}>
              <Flex
                px={'10px'}
                _hover={{
                  backgroundColor: '#EFEFEF',
                  borderRadius: '12px',
                }}
                justifyContent={'space-between'}
                gap={2}
                py={'8px'}
                onClick={() => {
                  setSelectedLocationId('all');
                  setSelectedLocationName('All locations');
                }}
              >
                <Text>All locations</Text>
                {selectedLocationId === 'all' && (
                  <CheckCircleIcon width={'15px'} />
                )}
              </Flex>
              {loggedInUserLocations?.map((location) => {
                return (
                  <>
                    <Flex
                      onClick={() => {
                        setSelectedLocationId(location?.eid);
                        setSelectedLocationName(location?.name);
                      }}
                      px={'10px'}
                      _hover={{
                        backgroundColor: '#EFEFEF',
                        borderRadius: '12px',
                      }}
                      justifyContent={'space-between'}
                      gap={2}
                      cursor={'pointer'}
                      py={'8px'}
                      key={location?.eid}
                    >
                      <Tooltip label={location?.name}>
                        <Text isTruncated width={'150px'}>
                          {location?.name}
                        </Text>
                      </Tooltip>
                      {selectedLocationId === location?.eid && (
                        <CheckCircleIcon width={'15px'} />
                      )}
                    </Flex>
                  </>
                );
              })}
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  );
};

export default LocationFilter;
