import React, { FC } from 'react';
import { Box, Center, Flex, ScaleFade, Spacer } from '@chakra-ui/react';
import { ChevronDownIcon, DragHandleIcon } from '@chakra-ui/icons';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FlexContainer } from '../../common';
import { IQuizQuestion } from '../../training.graphql';
import { QuizFormInput } from './quiz-resource.graphql';
import { QuizTypeOptions } from './quiz-form/QuizTypeDropdown';
import QuizQuestionForm from './quiz-form/QuizQuestionForm';
import { QuizQFormInput } from './quiz-form/quiz-form.types';
import { emitQuizPreview } from './preview';

import { ReactComponent as SparksIcon } from 'assets/images/sparks.svg';

interface IProps {
  index: number;
  fieldId: string;
  data: IQuizQuestion;
  onUpdate: (index: number, data: IQuizQuestion) => void;
  onRemoveClick?: (index: number) => void;
  onCopyClick: (
    index: number,
    values: QuizQFormInput,
    newValues: QuizQFormInput
  ) => void;
}

const QuizQuestion: FC<IProps> = ({
  data,
  fieldId,
  index,
  onUpdate,
  onCopyClick,
  onRemoveClick,
}) => {
  const { setValue } = useFormContext<QuizFormInput>();
  const [questionIndex, savedIndex] = useWatch<
    QuizFormInput,
    ['questionIndex', 'savedIndex']
  >({
    name: ['questionIndex', 'savedIndex'],
  });
  const _value = QuizTypeOptions.find((option) => option.value === data.type);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    setActivatorNodeRef,
  } = useSortable({ id: fieldId, data: { index: index } });

  if (questionIndex === index) {
    return (
      <Box
        ref={setNodeRef}
        transform={CSS.Translate.toString(transform)}
        transition={transition}
      >
        <QuizQuestionForm
          index={questionIndex}
          initialValue={data}
          onCloseClick={() => setValue('questionIndex', undefined)}
          onRemoveClick={() => {
            setTimeout(setValue, 0, 'questionIndex', undefined);
            return onRemoveClick?.(index);
          }}
          onAddQuestion={(values) => {
            onUpdate?.(index, values);
            setTimeout(setValue, 10, 'questionIndex', undefined);
          }}
          onCopyClick={(values, newValues) => {
            onCopyClick(index, values, newValues);
          }}
        />
      </Box>
    );
  }

  return (
    <>
      <FlexContainer
        ref={setNodeRef}
        flexDir='column'
        bg='#FCFCFC'
        borderColor='#6F767E80'
        p='20px 24px'
        gap={4}
        onClick={() => {
          emitQuizPreview({ questionIndex: index, question: data });
          setValue('questionIndex', index);
        }}
        transform={CSS.Translate.toString(transform)}
        transition={transition}
        zIndex={isDragging ? 3 : undefined}
      >
        <Flex gap={2} align='center'>
          <Center {...attributes} {...listeners} ref={setActivatorNodeRef}>
            <DragHandleIcon boxSize='12px' color='#2A85FF' />
          </Center>
          <Box fontSize='14px' fontWeight='600' color='#272B30'>
            Question {index + 1}
          </Box>

          <Spacer />

          <Flex
            gap={2}
            fontSize='13px'
            fontWeight={500}
            border='2px solid #EFEFEF'
            borderRadius='12px'
            padding='4px 8px 4px 16px'
            minWidth='180px'
            justify='center'
            align='center'
          >
            <Box as='span' color='#6F767E' whiteSpace='nowrap'>
              {data.score ? `${data.score} points` : 'No score'}
            </Box>

            <ChevronDownIcon boxSize='26px' color='#6F767E' />
          </Flex>

          <Flex
            gap={2}
            fontSize='13px'
            fontWeight={500}
            border='2px solid #EFEFEF'
            borderRadius='12px'
            padding='4px 8px 4px 16px'
            minWidth='180px'
            justify='space-between'
            align='center'
          >
            <Box as='span' color='#6F767E' whiteSpace='nowrap'>
              {_value?.label}
            </Box>

            <ChevronDownIcon boxSize='26px' color='#6F767E' />
          </Flex>
        </Flex>

        <Center
          padding='16px 20px'
          borderRadius='12px'
          border='2px solid'
          borderColor='#EFEFEF'
          minHeight='140px'
          textAlign='center'
          style={{
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word',
            overflowWrap: 'break-word',
          }}
        >
          {data.label}
        </Center>
      </FlexContainer>

      {savedIndex === index ? (
        <ScaleFade initialScale={0.8} in>
          <Center marginTop={-2} marginBottom={-1}>
            <Flex
              align='center'
              gap='6px'
              bg='#FFFFFF'
              padding='4px 12px'
              borderRadius='100px'
              boxShadow='0px 4px 20px 0px #7070701A'
              fontSize='14px'
              fontWeight={600}
              color='#83BF6E'
            >
              <SparksIcon width={16} height={16} />
              Question saved
            </Flex>
          </Center>
        </ScaleFade>
      ) : null}
    </>
  );
};

export default QuizQuestion;
