import React, { FC } from 'react';
import {
  Box,
  Checkbox,
  CheckboxGroup,
  Flex,
  IconButton,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { FormCategory } from '../../../../../types';
import { Authorize, AuthRole } from '../../../../../authorization';

interface IProps {
  categoryList: FormCategory[];
  value: string[];
  onChange: (value: string[]) => void;
  onEditClick?: (value: FormCategory) => void;
}

const FormCategoryList: FC<IProps> = ({
  categoryList,
  value,
  onChange,
  onEditClick,
}) => {
  return (
    <Flex
      flexDir='column'
      gap={1}
      minH='200px'
      // maxH='calc(100vh - 400px)'
      // overflowY='auto'
    >
      <CheckboxGroup value={value} onChange={onChange}>
        {categoryList.map((category, index) => (
          <Flex
            padding='4px 6px'
            borderRadius='4px'
            justify='space-between'
            key={index}
            _hover={{
              bg: '#EFEFEF',
              button: {
                visibility: 'visible',
              },
            }}
          >
            <Checkbox
              value={category.eid}
              _checked={{
                fontWeight: '600',
              }}
            >
              <Box fontSize='14px' color='#333B4F'>
                {category.name}
              </Box>
            </Checkbox>
            <Authorize
              permittedFor='user'
              permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
            >
              <IconButton
                visibility='hidden'
                size='xs'
                aria-label='edit-category'
                variant='ghost'
                icon={
                  <FontAwesomeIcon
                    icon={faPen as IconProp}
                    color='#6F767E'
                    size='lg'
                  />
                }
                onClick={() => onEditClick?.(category)}
              />
            </Authorize>
          </Flex>
        ))}
      </CheckboxGroup>
    </Flex>
  );
};

FormCategoryList.displayName =
  'displayName:pages/forms/modules/FormListInternal/form-filter/FormCategoryList';

export default FormCategoryList;
