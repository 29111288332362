import React, { FC, useRef, useState, useEffect, useCallback } from 'react';
import { CloseIcon } from '@chakra-ui/icons';
import {
  FormControl,
  Box,
  Text,
  Flex,
  Button,
  Input,
  Image,
  Stack,
  Progress,
} from '@chakra-ui/react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faGif } from '@fortawesome/pro-light-svg-icons';
import { faAdd } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import moment from 'moment';
import { FILENAME_REGEX } from 'utils/constants';
import { encodeSpaces } from 'utils/encodeSpaces';
import { getUrlFileSize } from 'utils/getUrlFileSize';
import EvidenceUploadSkeleton from './EvidenceUploadSkeleton';
import { ReactComponent as FormIcon } from 'assets/images/sidebar/form.svg';
import { useTranslation } from 'react-i18next';
import { useUserDataSelector, useUploadImage } from 'hooks';
import { ISkillVerifierFormInput } from 'sub-components/training-v2/shared/types';
import { eventBus } from 'shared/eventEmit';

type Props = {
  skillIndex: number;
};

interface UploadState {
  isUploading: boolean;
  progress: number;
  url?: string;
}

const SkillAttachments: FC<Props> = ({ skillIndex }) => {
  const [noteIsVisible, setNoteIsVisible] = useState<Record<number, boolean>>({
    0: false,
  });
  const [evidenceIsVisible, setEvidenceIsVisible] = useState<
    Record<number, boolean>
  >({ 0: false });
  const [addNoteFieldHovered, setAddNoteFieldHovered] =
    useState<boolean>(false);
  const [addEvidenceHovered, setEvidenceHovered] = useState<boolean>(false);

  const [uploadStates, setUploadStates] = useState<Record<number, UploadState>>(
    {}
  );
  const [file, setFile] = useState<File | string | null>(null);
  const [isValidatingUrl, setIsValidatingUrl] = useState<boolean>(false);

  const addNoteRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation(['card', 'training']);
  const uploadImage = useUploadImage();

  const { loggedInUserName } = useUserDataSelector((state) => ({
    loggedInUserName: state.name,
  }));

  const formContext = useFormContext<ISkillVerifierFormInput>();
  if (!formContext) {
    throw new Error('SkillAttachments must be used within a FormProvider');
  }
  const { setValue, control, resetField, getValues } = formContext;

  const {
    fields: files,
    append: addMoreEvidence,
    update: updateEvidence,
    remove: removeEvidence,
  } = useFieldArray({
    control,
    name: `skills.${skillIndex}.files`,
  });
  const { fields: skillFields, remove: removeSkill } = useFieldArray({
    control,
    name: 'skills',
  });

  //handle reEvalute -> reset all the states to being Visibile
  const handleReEvaluate = () => {
    const updatedNotesVisibilityState: Record<number, boolean> = {};
    const updatedEvidenceVisibilityState: Record<number, boolean> = {};

    skillFields.forEach((skill, index) => {
      if (skill?.comment !== '') {
        updatedNotesVisibilityState[index] = true;
      }
      if (skill?.files?.length && skill.files[index]?.url !== '') {
        updatedEvidenceVisibilityState[index] = true;
      }
    });

    if (Object.keys(updatedNotesVisibilityState).length) {
      setNoteIsVisible(updatedNotesVisibilityState);
    }
    if (Object.keys(updatedEvidenceVisibilityState).length) {
      setEvidenceIsVisible(updatedEvidenceVisibilityState);
    }
  };

  //catch the reEvaluate flow event
  useEffect(() => {
    eventBus.on('revalute_assignee', handleReEvaluate);
    return () => {
      eventBus.off('revalute_assignee', handleReEvaluate);
    };
  }, []);
  // Effect to handle URL updates
  useEffect(() => {
    if (file && typeof file === 'string') {
      const updateEvidenceWithUrl = async () => {
        setIsValidatingUrl(true);
        try {
          const fileSize = await getUrlFileSize(file);
          const evidenceIndex = files.findIndex((e) => !e.url);

          if (evidenceIndex !== -1) {
            updateEvidence(evidenceIndex, {
              url: file,
              uploadedBy: loggedInUserName,
              addedOn: moment().toISOString(),
              fileSize,
            });

            setUploadStates((prev) => ({
              ...prev,
              [evidenceIndex]: {
                isUploading: false,
                progress: 100,
                url: file,
              },
            }));
          }
        } catch (error) {
          console.error('Error validating URL:', error);
        } finally {
          setIsValidatingUrl(false);
          setFile(null);
        }
      };

      updateEvidenceWithUrl();
    }
  }, [file, loggedInUserName, files, updateEvidence]);

  // Effect to sync form values when upload states change
  useEffect(() => {
    Object.entries(uploadStates).forEach(([index, state]) => {
      if (!state.isUploading && state.url) {
        const numericIndex = parseInt(index);
        const currentEvidence = files[numericIndex];
        if (currentEvidence && currentEvidence.url !== state.url) {
          updateEvidence(numericIndex, {
            ...currentEvidence,
            url: state.url,
            uploadedBy: loggedInUserName,
            addedOn: moment().toISOString(),
          });
        }
      }
    });
  }, [uploadStates, files, loggedInUserName, updateEvidence]);

  const handleKeyPressOnEnter = (
    event: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (event.key === 'Enter') {
      setValue(`skills.${skillIndex}.comment`, event.currentTarget.value);
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
    }
  };

  const handleUrlBlur = async (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const newValue = e.target.value.trim();
    if (newValue) {
      const parsedNewValue = encodeSpaces(newValue);
      setFile(parsedNewValue);
    }
  };
  const onFileDrop = async (fileLists: File[], evidenceIndex: number) => {
    const newFile = fileLists?.[0];
    if (!newFile) return;

    setUploadStates((prev) => ({
      ...prev,
      [evidenceIndex]: { isUploading: true, progress: 0 },
    }));

    try {
      const fileName = newFile.name?.split(FILENAME_REGEX)?.pop();
      const fileUrl = await uploadImage(newFile, fileName, (progress) => {
        setUploadStates((prev) => ({
          ...prev,
          [evidenceIndex]: { ...prev[evidenceIndex], progress },
        }));
      });

      const parsedFileUrl = encodeSpaces(fileUrl);

      if (parsedFileUrl) {
        setUploadStates((prev) => ({
          ...prev,
          [evidenceIndex]: {
            isUploading: false,
            progress: 100,
            url: parsedFileUrl,
          },
        }));

        setValue(
          `skills.${skillIndex}.files.${evidenceIndex}`,
          {
            url: parsedFileUrl,
            uploadedBy: loggedInUserName,
            addedOn: moment().toISOString(),
            fileSize: newFile.size,
          },
          { shouldDirty: true }
        );
      }
    } catch (error) {
      console.error('Upload failed:', error);
      setUploadStates((prev) => ({
        ...prev,
        [evidenceIndex]: { isUploading: false, progress: 0 },
      }));
    }
  };

  const renderEvidenceItem = (evidence: any, index: number, files: any) => {
    const uploadState = uploadStates[index] || {
      isUploading: false,
      progress: 0,
    };
    const hasUrl =
      evidence.url || (uploadState.url && !uploadState.isUploading);
    if (uploadState.isUploading || isValidatingUrl) {
      return (
        <Stack key={index} gap={2}>
          <EvidenceUploadSkeleton disabled={true} />
          <Flex flexDir={'column'} gap={1}>
            <Text>{t('training:fileUploading')}</Text>
            <Progress
              isAnimated
              value={uploadState.progress}
              size={'sm'}
              borderRadius={4}
            />
          </Flex>
        </Stack>
      );
    }
    if (!hasUrl) {
      return (
        <Controller
          key={`controller-${index}`}
          control={control}
          name={`skills.${skillIndex}.files.${index}.url`}
          rules={{ required: true, maxLength: 5000 }}
          render={({ field }) => (
            <EvidenceUploadSkeleton
              accept={{
                'image/*': ['.jpg', '.jpeg', '.png', '.gif'],
                'video/*': ['.mp4', '.wmv'],
              }}
              onFileDrop={(files) => onFileDrop(files, index)}
              onBlur={(e) => handleUrlBlur(e, index)}
              onChange={field.onChange}
              disabled={isValidatingUrl}
            />
          )}
        />
      );
    }

    const displayUrl = evidence.url || uploadState.url;
    const fileName = displayUrl?.split(FILENAME_REGEX)?.pop();
    const fileExt = fileName?.split('.').pop()?.toLowerCase();

    return (
      <Flex key={`evidence-${index}`} gap={4} align='center' w='100%'>
        <Flex
          justify='space-between'
          align='center'
          gap={3}
          borderRadius='12px'
          bgColor='#FFFFFF'
          p='12px 20px'
          border='2px solid #EFEFEF'
          w='98%'
        >
          <Flex gap={'24px'}>
            <Image
              src={displayUrl}
              boxSize='50px'
              borderRadius='8px'
              fallbackSrc='https://via.placeholder.com/50'
            />
            <Flex gap='10px'>
              {fileExt === 'gif' && (
                <FontAwesomeIcon
                  icon={faGif as IconProp}
                  color='#6F767E'
                  size='2x'
                />
              )}
              <Flex flexDir='column'>
                <Text
                  fontWeight={600}
                  isTruncated
                  maxW='550px'
                  lineHeight='24px'
                >
                  {fileName}
                </Text>
                <Text color='#6F767E' maxW='550px' isTruncated>
                  {displayUrl}
                </Text>
              </Flex>
            </Flex>
          </Flex>
          {/**removes the evidence item */}
          <Button
            variant='unstyled'
            onClick={() => {
              index === 0 &&
                setEvidenceIsVisible((prev) => ({ ...prev, [index]: false })); //reset back add evidence state
              removeEvidence(index);
              setUploadStates((prev) => {
                delete prev[index];
                return { ...prev };
              });
            }}
            borderRadius='lg'
            mt={2}
            _hover={{
              bg: '#EFEFEF',
            }}
            transition='all 0.2s ease-in-out'
            color={'#6F767E'}
            p={'5px'}
          >
            <FontAwesomeIcon
              icon={faClose as IconProp}
              style={{
                height: '24px',
                width: '24px',
                color: '#353839',
                transition: 'inherit',
              }}
            />
          </Button>
        </Flex>

        {files.length === index + 1 && (
          <Button variant={'unstyled'}>
            <FontAwesomeIcon
              icon={faAdd as IconProp}
              cursor='pointer'
              style={{
                height: '12px',
                width: '12px',
                padding: '6px',
                border: '2px solid #EFEFEF',
                borderRadius: '24px',
                color: '#6F767E',
                strokeWidth: '12px',
              }}
              onClick={() =>
                addMoreEvidence({
                  url: '',
                  uploadedBy: '',
                  fileSize: 0,
                  addedOn: moment().toISOString(),
                })
              }
            />
          </Button>
        )}
      </Flex>
    );
  };

  const handleAddEvidence = useCallback(() => {
    setEvidenceIsVisible((prev) => ({ ...prev, [skillIndex]: true }));
    files.length === 0 &&
      addMoreEvidence({
        //only add for the first evidence
        url: '',
        uploadedBy: '',
        fileSize: 0,
        addedOn: moment().toISOString(),
      });
  }, [skillIndex, addMoreEvidence]);
  return (
    <>
      {/* Note section */}
      {noteIsVisible[skillIndex] && (
        <Controller
          control={control}
          name={`skills.${skillIndex}.comment`}
          render={({ field }) => (
            <FormControl>
              <Box
                bg={'inherit'}
                onMouseEnter={() => setAddNoteFieldHovered(true)}
                onMouseLeave={() => setAddNoteFieldHovered(false)}
                _hover={{ bg: '#F4F4F4' }}
                borderRadius='12px'
                p={3}
                mt={4}
              >
                <Flex justifyContent='space-between' alignItems='center' mb={2}>
                  <Text fontSize='sm' fontWeight='600'>
                    Add Note
                  </Text>
                  {addNoteFieldHovered && (
                    <CloseIcon
                      aria-label='Close Note'
                      cursor='pointer'
                      onClick={() => {
                        resetField(`skills.${skillIndex}.comment`);
                        setNoteIsVisible((prev) => ({
                          ...prev,
                          [skillIndex]: false,
                        }));
                      }}
                    />
                  )}
                </Flex>
                <Input
                  height={'48px'}
                  onKeyDown={(e) => handleKeyPressOnEnter(e, skillIndex)}
                  borderRadius={'12px'}
                  bgColor={'#FFFFFF'}
                  border={'2px solid #EFEFEF'}
                  {...field}
                  bg='white'
                  ref={addNoteRef}
                />
              </Box>
            </FormControl>
          )}
        />
      )}
      {/* Evidence section */}
      {evidenceIsVisible[skillIndex] && (
        <FormControl>
          <Box
            bg={'inherit'}
            onMouseEnter={() => setEvidenceHovered(true)}
            onMouseLeave={() => setEvidenceHovered(false)}
            _hover={{ bg: '#F4F4F4' }}
            borderRadius='12px'
            p={3}
            mt={4}
          >
            <Flex justifyContent='space-between' alignItems='center' mb={2}>
              <Text fontSize='sm' fontWeight='600'>
                Add Evidence
              </Text>
              {addEvidenceHovered && (
                <CloseIcon
                  aria-label='Close Evidence'
                  cursor='pointer'
                  onClick={() => {
                    console.log('hoveredEvidence close icon btn clicked');

                    resetField(`skills.${skillIndex}.files`);
                    setEvidenceIsVisible((prev) => {
                      return {
                        ...prev,
                        [skillIndex]: false,
                      };
                    });
                    setUploadStates((prev) => {
                      delete prev[skillIndex];
                      return { ...prev };
                    });
                  }}
                />
              )}
            </Flex>
            <Flex flexDir='column' gap={4}>
              {files.map((evidence, index, files) =>
                renderEvidenceItem(evidence, index, files)
              )}
            </Flex>
          </Box>
        </FormControl>
      )}

      {/* Action buttons */}
      <Flex justify={'flex-end'} align={'center'} gap={2}>
        {!noteIsVisible[skillIndex] && (
          <Button
            leftIcon={<FormIcon />}
            borderRadius='8px'
            color='#111315'
            border='2px solid #EFEFEF'
            bg='#FFFFFF'
            fontWeight={500}
            fontSize='12px'
            onClick={() =>
              setNoteIsVisible((prev) => ({ ...prev, [skillIndex]: true }))
            }
          >
            Add note
          </Button>
        )}
        {!evidenceIsVisible[skillIndex] && (
          <Button
            leftIcon={<FormIcon />}
            borderRadius='8px'
            color='#111315'
            border='2px solid #EFEFEF'
            bg='#FFFFFF'
            fontWeight={500}
            fontSize='12px'
            onClick={handleAddEvidence}
          >
            Add evidence
          </Button>
        )}
      </Flex>
    </>
  );
};

export default SkillAttachments;
