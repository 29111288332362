import { useLazyQuery } from '@apollo/client';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Badge, Box, Center, Flex, Skeleton, Tooltip } from '@chakra-ui/react';
import {
  TRAINING_PATH_V2_DASHBOARD,
  TRAINING_PATH_V2_PLAY,
  TRAINING_PATH_V2_PLAY_RETAKE,
  TRAINING_PATH_V2_PLAY_VIEW,
} from 'appRoutes';
import { Button } from 'atoms';
import { useUserDataSelector } from 'hooks';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'routes';
import { AllClear } from 'sub-components/nexus/NexusBase/components/common';
import { TrainingPathContainerTabs } from 'sub-components/training-v2/dashboard/components/Container';
import { TExcueteCallbackVars } from 'sub-components/training-v2/dashboard/components/GenericFilter/types/filter-types';
import { colorSchemes } from 'sub-components/training-v2/dashboard/components/myTrainings/bottomSection/MyTrainingTable';
import { prepareTrainingPathSessionData } from 'sub-components/training-v2/dashboard/components/myTrainings/helper';
import {
  GET_TRAINING_CATEGORIES,
  USER_TRAINING_PATH_SESSIONS,
} from 'sub-components/training-v2/dashboard/components/myTrainings/query/myTraining.graphql';
import { Image } from 'ui-components';
import { getImage } from 'utils';

interface IProps {
  loading: boolean;
  trainingPathData: any;
}

const CardMode: FC<IProps> = ({ loading, trainingPathData }) => {
  const history = useHistory();
  const onClickHandler = (data: any) => {
    const {
      status,
      hasSkillVerifier,
      isEvaluated,
      evaluationViewed,
      trainingId,
      key,
    } = data;
    const iconType = status?.iconType;

    const navigateTo = (pathname: string) => {
      history.push({
        pathname,
        params: {
          trainingId,
          sessionId: key,
        },
        state: {
          backToTitle: 'Back to Dashboard',
        },
      });
    };

    if (['play', 'resume'].includes(iconType)) {
      return navigateTo(TRAINING_PATH_V2_PLAY);
    }

    if (iconType === 'retake' && hasSkillVerifier) {
      if (isEvaluated && evaluationViewed) {
        return navigateTo(TRAINING_PATH_V2_PLAY_RETAKE);
      } else if (isEvaluated && !evaluationViewed) {
        return navigateTo(TRAINING_PATH_V2_PLAY_VIEW);
      } else if (!isEvaluated && evaluationViewed) {
        return navigateTo(TRAINING_PATH_V2_PLAY);
      } else if (!isEvaluated && !evaluationViewed) {
        return navigateTo(TRAINING_PATH_V2_PLAY_VIEW);
      }
    }

    if (iconType === 'retake') {
      return navigateTo(TRAINING_PATH_V2_PLAY_RETAKE);
    }

    navigateTo(TRAINING_PATH_V2_PLAY);
  };
  const getCellStyles = (record: any) => {
    if (record?.tpTerminatedStatus === 'TRAINING_TERMINATED') {
      return {
        cursor: 'not-allowed',
        opacity: '0.5',
      };
    } else {
      return {};
    }
  };
  const statusBadge = (data: any) => {
    const statusKey = data?.status?.title.toLowerCase().replaceAll(' ', '');

    if (!statusKey) {
      return null;
    }

    return (
      <Flex style={getCellStyles(data)} gap={2} alignItems={'center'}>
        <Badge
          borderRadius={'4px'}
          p={'4px 8px'}
          backgroundColor={colorSchemes[statusKey]?.bg}
          color={colorSchemes[statusKey]?.color}
          fontWeight={700}
          textTransform={'capitalize'}
          fontSize={'12px'}
        >
          {data?.status?.title}
        </Badge>

        {data?.status?.dueDate && data?.status?.dueDate !== '-' ? (
          <Box fontWeight={500}>
            <>&bull; {data?.status?.dueDate}</>
          </Box>
        ) : null}
      </Flex>
    );
  };

  if (loading) {
    return (
      <Flex flexDir='column' gap={4}>
        <Skeleton h='20px' w='100%' />
        <Skeleton h='20px' w='100%' />
        <Skeleton h='20px' w='100%' />
        <Skeleton h='20px' w='100%' />
        <Skeleton h='20px' w='100%' />
        <Skeleton h='20px' w='100%' />
        <Skeleton h='20px' w='100%' />
        <Skeleton h='20px' w='100%' />
        <Skeleton h='20px' w='100%' />
        <Skeleton h='20px' w='100%' />
        <Skeleton h='20px' w='100%' />
      </Flex>
    );
  }

  if (trainingPathData?.length === 0 && !loading) {
    return (
      <Center h='30vh'>
        <AllClear desc='You have no trainings assigned' />
      </Center>
    );
  }

  return (
    <Flex flexDir='column' gap={4}>
      {trainingPathData?.map((data: any) => (
        <Flex
          key={data?.eid}
          gap={2}
          borderRadius='12px'
          bg='#fcfcfc'
          border='1px solid #efefef'
          p='12px'
          cursor='pointer'
          onClick={() => onClickHandler(data)}
          flexDir='column'
        >
          {data?.status?.title && (
            <Flex>
              <Box>{statusBadge(data)}</Box>
            </Flex>
          )}
          <Flex gap={4}>
            <Image
              src={getImage(data?.thumbnail, data?.pathName)}
              width={50}
              height={50}
              style={{
                borderRadius: '12px',
              }}
            />
            <Flex flexDir='column' gap={2} flex='1' minWidth='0'>
              <Tooltip label={data?.pathName} hasArrow>
                <Box
                  as='span'
                  isTruncated
                  maxW='100%'
                  overflow='hidden'
                  textOverflow='ellipsis'
                  whiteSpace='nowrap'
                  width='100%'
                  fontWeight={600}
                >
                  {data?.pathName}
                </Box>
              </Tooltip>
              <Flex>
                <Box color='#6f767e' fontWeight={600}>
                  {data?.progress?.current || 0}
                </Box>
                &nbsp;
                <Box color='#6f767e' fontWeight={600}>
                  /&nbsp;
                  {data?.progress?.total || 0} items left
                </Box>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      ))}
      <Button
        variant='outline'
        size='lg'
        onClick={() =>
          history.push({
            pathname: TRAINING_PATH_V2_DASHBOARD,
            state: {
              tabSelected: 'myTraining' as TrainingPathContainerTabs,
            },
          })
        }
        rightIcon={<ArrowForwardIcon fontSize='20px' />}
      >
        View all
      </Button>
    </Flex>
  );
};

export default CardMode;
