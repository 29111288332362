import React, { FC, useMemo } from 'react';
import {
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  VStack,
  Box,
} from '@chakra-ui/react';
import { FlexCard } from 'atoms';
import { BoxHeader } from 'ui-components';
import { HeaderColors } from 'configs';
import { CustomTab } from 'sub-components/nexus/NexusBase/components/LeftSection/components/LocationLeaderboard/components';
import { LocationList, MemberList } from '.';
import {
  TpOverallLocationsWiseAnalytics,
  TpOverallUserWiseAnalytics,
} from '../../Tracking/tracking-types';

export interface TabConfig {
  label: string;
  minWidth: string;
  hasNewBadge?: boolean;
  value?: string;
}

interface LeaderboardProps {
  tpOverallLocationsWiseAnalytics: TpOverallLocationsWiseAnalytics[];
  tpOverallUserWiseAnalytics: TpOverallUserWiseAnalytics[];
}

const Leaderboard: FC<LeaderboardProps> = ({
  tpOverallLocationsWiseAnalytics,
  tpOverallUserWiseAnalytics,
}) => {
  const tabsConfig = useMemo((): TabConfig[] => {
    return [
      { label: 'Locations', minWidth: '176px', hasNewBadge: false },
      { label: 'Members', minWidth: '176px' },
    ];
  }, []);

  return (
    <FlexCard
      height={'100%'}
      border={'2px solid #EFEFEF'}
      // className='location-leaderboard-container'
      w='100%'
    >
      <VStack spacing={6} align='start' w='full'>
        <Box
          fontWeight='600'
          fontSize={'20px'}
          lineHeight='32px'
          color='#1a1d1f'
        >
          Leaderboard
        </Box>
        <Tabs variant='unstyled' w='full'>
          <TabList flexWrap='wrap' gap='.5rem'>
            {tabsConfig.map((tab, index) => (
              <CustomTab
                key={index}
                label={tab.label}
                minWidth={tab.minWidth}
                hasNewBadge={tab.hasNewBadge}
              />
            ))}
          </TabList>

          <TabPanels>
            {tabsConfig.map((tab, index) => (
              <TabPanel key={index}>
                {tab.label === 'Locations' && (
                  <LocationList
                    tab={tab.label}
                    data={tpOverallLocationsWiseAnalytics}
                  />
                )}
                {tab.label === 'Members' && (
                  <MemberList
                    tab={tab.label}
                    data={tpOverallUserWiseAnalytics}
                  />
                )}
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </VStack>
    </FlexCard>
  );
};

Leaderboard.displayName =
  'displayName:sub-components/training-v2/dashboard/components/TrackPathDashboard/topSection/Leaderboard';

export default Leaderboard;
