import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TrainingContType } from '../../../../../../types';
import chapter from '../../../../../../assets/images/training/knowledge-base.svg';
import quiz from '../../../../../../assets/images/training/quiz.svg';
import video from '../../../../../../assets/images/training/video.svg';
import forms from '../../../../../../assets/images/training/forms.svg';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useContentMenuOptions = (
  onAddClick?: (action: TrainingContType) => void
) => {
  const { t, i18n } = useTranslation(['sidebar', 'training']);

  return useMemo(() => {
    return [
      {
        title: t('sidebar:knowledge_base'),
        description: t('training:addKnowledgeBaesChapters'),
        icon: chapter,
        onClick: () => onAddClick?.(TrainingContType.SOP),
      },
      {
        title: t('training:powerup.quizMenu'),
        description: t('training:quizDesc'),
        icon: quiz,
        onClick: () => onAddClick?.(TrainingContType.QUIZ),
      },
      {
        title: t('training:powerup.videoMenu'),
        description: t('training:videoDesc'),
        icon: video,
        onClick: () => onAddClick?.(TrainingContType.CARD),
      },
      {
        title: t('training:powerup.formMenu'),
        description: t('training:formDesc'),
        icon: forms,
        onClick: () => onAddClick?.(TrainingContType.FORM),
      },
    ];
  }, [i18n.language, onAddClick]);
};
