import { useMemo } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useWatch } from 'react-hook-form';
import { AuthRole } from '../../../../../../authorization';
import { usersEntityObj } from '../../../../../Header';
import { TFormInput } from '../../training-input.types';

const ADMIN_SA = [AuthRole.SUPER_ADMIN, AuthRole.ADMIN];

export const useSupervisorRequired = (): boolean => {
  const entityUsers = useReactiveVar(usersEntityObj);

  const [assignees] = useWatch<TFormInput, ['assignees']>({
    name: ['assignees'],
  });

  return useMemo(() => {
    const haveAssignByRole = assignees.some(
      (assignee) => assignee.type === 'authRole'
    );

    if (
      haveAssignByRole &&
      assignees?.some((as) => as.authRoles?.some((ar) => ADMIN_SA.includes(ar)))
    ) {
      return true;
    }

    const userIds = assignees.flatMap((assignee) => {
      if (assignee.type === 'user') {
        return assignee.userIds;
      }
      return [];
    });

    return entityUsers?.some(
      (user) =>
        userIds?.includes(user.eid) &&
        ADMIN_SA.includes(user.authRole) &&
        user.type === 'user'
    );
  }, [entityUsers, assignees]);
};
