import React, { FC, useEffect } from 'react';
import { DashboardContainer } from 'sub-components';
import { TrainingDashboard } from 'sub-components/training-v2';

const TrainingPathDashboard: FC = () => {
  return (
    <DashboardContainer>
      <TrainingDashboard />
    </DashboardContainer>
  );
};

TrainingPathDashboard.displayName =
  'displayName:pages/TrainingV2/dashboard/TrainingPathDashboard';

export default TrainingPathDashboard;
