import { Any } from '../types';

export const fileToDtaUrl = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = function () {
      resolve(reader.result as string);
    };
    reader.onerror = function () {
      reject(reader.error);
    };

    reader.readAsDataURL(file);
  });
};

export const convertToWebp = async (dataURL: string): Promise<Blob> => {
  // return new Promise((resolve, reject) => {
  //   const img = new Image();
  //   img.src = dataURL;
  //
  //   img.onload = () => {
  //     const canvas = document.createElement('canvas');
  //     const ctx = canvas.getContext('2d');
  //     canvas.width = img.width;
  //     canvas.height = img.height;
  //     ctx!.drawImage(img, 0, 0);
  //
  //     // Convert the canvas content to WebP format
  //     // canvas.toDataURL('image/webp', (webpDataURL) => {
  //     //   resolve(dataURLtoBlob(webpDataURL));
  //     // });
  //
  //     canvas.toBlob((blob) => resolve(blob!), 'image/webp');
  //   };
  //
  //   img.onerror = (error) => reject(error);
  // });

  const canvas = document.createElement('canvas');

  try {
    const result = await new Promise<Blob>((resolve, reject) => {
      const img = new Image();
      img.src = dataURL;

      img.onload = () => {
        const ctx = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;
        ctx!.drawImage(img, 0, 0);

        canvas.toBlob((blob) => resolve(blob!), 'image/webp');
      };

      img.onerror = (error) => reject(error);
    });

    canvas.remove();

    return result;
  } catch (e) {
    canvas.remove();
    return Promise.reject(e);
  }
};

export const getWebpFilename = (fileName: string): string => {
  const _fileName = fileName.split('.');
  _fileName[_fileName.length - 1] = 'webp';

  return _fileName.join('.');
};

export const validateIsImage = (
  imageUrl: string,
  timeout: number = 5000
): Promise<boolean> => {
  const img = new Image();
  return new Promise<boolean>((resolve, reject) => {
    let timer: Any;
    img.onload = () => {
      clearTimeout(timer);
      resolve(true);
    }; // Image loaded successfully
    img.onerror = () => {
      clearTimeout(timer);
      reject(false);
    }; // Image failed to load
    img.src = imageUrl;

    timer = setTimeout(() => {
      clearTimeout(timer);
      reject(); // Reject if the image takes too long to load
    }, timeout);
  });
};

export const validateIsVideo = (
  videoLink: string,
  timeout: number = 5000
): Promise<boolean> => {
  let video = document.createElement('video');

  return new Promise<boolean>((resolve, reject) => {
    // Check if the video can start playing
    video.oncanplay = () => {
      resolve(true); // Video loaded successfully and ready to play
      cleanup();
    };

    // Check if there’s an error loading the video
    video.onerror = () => {
      reject(false); // Video failed to load
      cleanup();
    };

    // Set video source and trigger loading
    video.src = videoLink;

    // Optional: Add a timeout to handle cases where the video hangs
    setTimeout(() => {
      reject(false); // Reject after 5 seconds if loading hangs
      cleanup();
    }, timeout);

    // Cleanup function to destroy the element after validation
    function cleanup() {
      // Stop loading the video and reset it
      video.src = ''; // Stop the video from loading
      video.load(); // Reset the video element

      // Remove video element from the DOM if it was added
      video.remove(); // Clean up from the DOM

      // If the timeout was set, clear it to avoid unnecessary triggers
      clearTimeout(timeout);
    }
  });
};
