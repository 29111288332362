import { gql } from '@apollo/client';

export const USER_TRAINING_PATH_SESSIONS = gql`
  query UserTpSessions(
    $page: Int
    $perPage: Int
    $sort: SortFindManyTPSessionInput
    $filter: FilterFindManyTPSessionInput
    $userProgressFilter2: FilterFindManyTPProgressInput
    $userIds: [String!]
  ) {
    UserTpSessions(
      page: $page
      perPage: $perPage
      sort: $sort
      filter: $filter
    ) {
      count
      items {
        hasSkillVerifier
        title
        updatedAt
        userProgress(filter: $userProgressFilter2) {
          skillVerifierResponse {
            rating
          }
          maxScore
          progress {
            id
            isCompleted
            type
            responses {
              qid
            }
          }
          certificate
          scorePercent
          score
          isCompleted
        }
        startDate
        endDate
        isRepeating
        contents {
          eid
          type
          isRemoved
        }
        trainingId
        eid
        training {
          thumbnail
          category
          status
        }
        assignedUsers(userIds: $userIds) {
          certificateDownloaded
          isEvaluated
          dueDate
          assignedOn
          evaluationViewed
          completedOn
          status
        }
        maxScore
        createdBy
        createdAt
      }
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        itemCount
        pageCount
        perPage
      }
    }
  }
`;

export const GET_TRAINING_CATEGORIES = gql`
  query EntityTpCategories {
    EntityTpCategories {
      eid
      name
    }
  }
`;
