import {
  Modal,
  ModalOverlay,
  ModalContent as NativeModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react';
import ModalContent from './ModalContent';
import React, { FC } from 'react';
import { BoxHeader } from 'ui-components';
import { HeaderColors } from 'configs';
import { TPPathAnalyticsItem } from 'sub-components/training-v2/shared/types';

const FormResponseModal: FC<{
  isOpen: boolean;
  onClose: () => void;
  trainingId: string;
  sessionId: string;
  formId: string;
  selectedRecord: TPPathAnalyticsItem | undefined;
}> = ({ isOpen, onClose, trainingId, sessionId, formId, selectedRecord }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size='6xl'>
      <ModalOverlay />
      <NativeModalContent borderRadius='20px' p='20px' minW='80%'>
        <ModalHeader>
          <BoxHeader
            title='Form responses from within this training path'
            color={HeaderColors.Purple}
          />
        </ModalHeader>
        <ModalCloseButton m='20px' />
        <ModalBody>
          <ModalContent
            trainingSessionId={sessionId}
            formId={formId}
            selectedRecord={selectedRecord}
          />
        </ModalBody>
      </NativeModalContent>
    </Modal>
  );
};

export default FormResponseModal;
