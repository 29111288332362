import React, { FC, useMemo } from 'react';
import { Box, Flex, FlexProps, Image } from '@chakra-ui/react';
import moment from 'moment';

import { ReactComponent as HomeIcon } from 'assets/images/filled-home.svg';
import { ReactComponent as SearchIcon } from 'assets/images/searchIcon.svg';
import { ReactComponent as UserIcon } from 'assets/images/sidebar/member.svg';
import { ReactComponent as MessageIcon } from 'assets/images/sidebar/chat.svg';
import statusIcon from '../../../../../../assets/images/iPhoneStatusIcons.svg';

interface HProps {
  headerBg?: FlexProps['bgColor'];
}

export const MobileHeader: FC<HProps> = ({ headerBg }) => {
  const time = useMemo(() => moment().format('HH:mm'), []);

  return (
    <Flex
      position='absolute'
      top='0'
      width='100%'
      align='center'
      justify='space-between'
      padding='8px 12px'
      zIndex={100}
      bg={headerBg || 'whiteAlpha.600'}
    >
      <Box fontSize='14px' fontWeight='600' pl={1}>
        {time}
      </Box>
      <Image src={statusIcon} height='15px' />
    </Flex>
  );
};

const MobileFooter: FC = () => {
  return (
    <Flex
      height='44px'
      boxShadow='0px -2.45px 2.45px 0px #0000000D'
      justify='space-around'
      align='center'
      userSelect='none'
    >
      <HomeIcon width='15' height='15' />
      <SearchIcon width='15' height='15' />
      <MessageIcon width='15' height='15' />
      <UserIcon width='15' height='15' />
    </Flex>
  );
};

export default MobileFooter;
