import { Flex, Text } from '@chakra-ui/react';
import { HeaderColors } from 'configs';
import { FC } from 'react';
import { Image } from 'ui-components';
import noGraph from '../../../../assets/images/lolav2dashboard/Nograph.svg';

interface IProps {}

const OverallStatusEmpty: FC<IProps> = () => {
  const data = [
    {
      name: 'Overall status',
      key: 'overallStatus',
      subtitle:
        'No locations in the launcher. Add locations to track their launch progress.',
      color: HeaderColors.Blue,
    },
  ];
  return (
    <Flex gap={'20px'}>
      {data?.map((item, index) => {
        return (
          <Flex
            key={index}
            cursor='pointer'
            px={6}
            py={4}
            borderRadius='8px'
            bg='white'
            flexDir='column'
            w='100%'
            gap='16px'
            mb='20px'
            height={'123px'}
          >
            <Flex
              flexDirection={'row'}
              justifyContent={'center'}
              alignItems={'center'}
              width={'100%'}
              height={'182px'}
            >
              <Text
                fontSize={'14px'}
                fontWeight={500}
                color={'#9E9E9E'}
                textAlign={'center'}
              >
                {item?.subtitle}
              </Text>
              <Image src={noGraph} height={100} width={100} />
            </Flex>
          </Flex>
        );
      })}
    </Flex>
  );
};

export default OverallStatusEmpty;
