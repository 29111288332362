import React, { FC } from 'react';
import { HeaderColors } from '../../../../../../configs';
import { BoxHeader } from '../../../../../../ui-components';

import { FlexContainer } from '../../common';
import ScheduleInputs from './ScheduleInputs';

interface IProps {}

const TrainingSchedule: FC<IProps> = () => {
  return (
    <FlexContainer flexDir='column' gap={5}>
      <BoxHeader title='Schedule' color={HeaderColors.Purple} />

      <ScheduleInputs />
    </FlexContainer>
  );
};

export default TrainingSchedule;
