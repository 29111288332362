import React, { FC, useEffect, useMemo, useState } from 'react';
import { useToast } from '@chakra-ui/react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { cloneDeep } from '@apollo/client/utilities';
import { useTranslation } from 'react-i18next';

import { ChatGroupEntity } from '../../../types';
import { SingleUserChatModal } from '../../../ui-components';
import { toArray } from '../../../utils/utils';

import {
  DELETE_CHAT_GROUP,
  GET_CHAT_GROUPS,
} from './service/chat-setting.graphql';
import { IChatGroup } from './service/chat-setting.types';
import ChannelList from './ChannelList';
import { useViewChannelMember } from './create-channel';
import { useChatGroupDelete } from './ChannelList/useChatGroupDelete';
import DashboardContainer from 'sub-components/DashboardContainer';
import { useChannelSetting } from './create-channel/useChannelSetting';
import ChatSettingHeader from './ChatSettingHeader';
import { useCreateChannel } from './create-channel';
import { eventBus } from 'shared/eventEmit';

const ChatSetting: FC = () => {
  const { t } = useTranslation(['setting']);
  const toast = useToast({
    duration: 3000,
    position: 'top-right',
    isClosable: true,
  });

  const [chatUserId, setChatUserId] = useState<string>();

  const chatGroupDelete = useChatGroupDelete();
  const channelMember = useViewChannelMember();

  const [getChatGroups, { data, loading, refetch }] = useLazyQuery<
    IChatGroup,
    never
  >(GET_CHAT_GROUPS, {
    fetchPolicy: 'network-only',
  });

  const [deleteChatGroup] = useMutation(DELETE_CHAT_GROUP, {
    onCompleted: () => {
      toast({
        status: 'success',
        title: 'Chat group deleted successfully',
      });
      refetch && refetch();
    },
    onError: () => {
      toast({
        status: 'error',
        title: 'Chat group could not be deleted',
      });
      refetch && refetch();
    },
  });

  //refetch-if-chat-group-created
  useEffect(() => {
    eventBus.on('group-created-refetch', () => refetch?.());
    return () => eventBus.off('group-created-refetch', () => refetch?.());
  }, []);

  useEffect(() => {
    getChatGroups();
  }, []);

  const createChannel = useCreateChannel();
  const updateSetting = useChannelSetting();

  const onAddNewClick = () => {
    createChannel({
      reFetchData: getChatGroups,
    });
  };

  const deleteChatGroupFn = async (guid: string) => {
    await deleteChatGroup({
      variables: {
        guid: guid,
      },
    });
  };

  const onClickedHandler = (
    action: 'edit' | 'delete' | 'viewMembers' | 'setting',
    data: ChatGroupEntity
  ) => {
    switch (action) {
      case 'delete':
        return chatGroupDelete({
          chatGroup: data,
          onDeletePress: () => {
            return deleteChatGroupFn(data?.guid);
          },
        });
      case 'edit':
        return updateSetting({
          activeTab: 0,
          groupId: data.guid,
          reFetchData: getChatGroups,
          onMessage: (userId: string) => setChatUserId(userId),
        });
      case 'setting':
        return updateSetting({
          activeTab: 2,
          groupId: data.guid,
          reFetchData: getChatGroups,
          onMessage: (userId: string) => setChatUserId(userId),
        });
      case 'viewMembers':
        return channelMember({
          group: data,
          onMessage: (userId: string) => setChatUserId(userId),
        });
    }
  };

  const channels = useMemo(() => {
    const sortedBasedOnIsDefault = toArray(
      cloneDeep(data?.FetchEntityChatGroups)
    ).sort((a, b) => {
      if (a?.isDefault === b?.isDefault) return 0;
      if (a?.isDefault) return -1;
      return 1;
    });
    return sortedBasedOnIsDefault;
  }, [data]);

  return (
    <DashboardContainer>
      <ChatSettingHeader onAddNewClick={onAddNewClick} />

      <ChannelList
        channels={channels}
        loading={loading}
        onClickedHandler={onClickedHandler}
        onAddNewClick={onAddNewClick}
      />

      {chatUserId && (
        <SingleUserChatModal
          selectedUser={chatUserId}
          onClose={() => setChatUserId(undefined)}
        />
      )}
    </DashboardContainer>
  );
};

export default ChatSetting;
