import React, { FC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Box, Flex } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { match } from 'ts-pattern';
import { TFormInput } from '../../training-input.types';
import { useDurationSelect } from './duration';

import { ReactComponent as EditIcon } from '../../../../../../assets/images/edit-dark.svg';

interface IProps {
  disabled?: boolean;
}

const CustomDuration: FC<IProps> = ({ disabled }) => {
  const durationSelect = useDurationSelect();
  const { getValues, setValue, trigger } = useFormContext<TFormInput>();
  const [deadlineType, duration] = useWatch<
    TFormInput,
    ['deadlineType', 'duration']
  >({
    name: ['deadlineType', 'duration'],
  });

  const onClick = () => {
    if (disabled) {
      return;
    }
    durationSelect({
      repeatCycle: getValues('repeatCycle'),
      values: getValues('duration')!,
      onSaveChange: (newValue) => {
        setValue('duration', newValue);
        trigger('deadlineType');
      },
    });
  };

  if (deadlineType !== 'duration') {
    return null;
  }

  const value = match(duration?.type)
    .with('days', () => `${duration?.value} days`)
    .with('weeks', () => `${duration?.value} weeks`)
    .with('months', () => `${duration?.value} months`)
    .with('years', () => `${duration?.value} years`)
    .otherwise(() => 'Select days');

  return (
    <Flex flex={1} gap={5}>
      <Box fontSize='13px' fontWeight='600' lineHeight='45px'>
        of
      </Box>
      <Flex
        height='45px'
        align='center'
        justify='space-between'
        border='2px solid #EFEFEF'
        borderRadius='12px'
        minWidth='150px'
        paddingX='16px'
        fontWeight='600'
        color='#6F767E'
        aria-disabled={disabled}
        _disabled={{
          opacity: 0.4,
          cursor: 'not-allowed',
        }}
      >
        <span>{value}</span>

        {duration?.type ? (
          <EditIcon
            color='#6F767E'
            cursor={disabled ? undefined : 'pointer'}
            onClick={onClick}
          />
        ) : (
          <AddIcon
            boxSize='16px'
            cursor={disabled ? undefined : 'pointer'}
            onClick={onClick}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default React.memo(CustomDuration);
