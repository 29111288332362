import React, { forwardRef } from 'react';
import { Box, Checkbox, Flex } from '@chakra-ui/react';

interface IProps {
  isChecked?: boolean;
  isDisabled?: boolean;
  onChange?: (isChecked?: boolean) => void;
}

const AddSkillVerifierButton = forwardRef<never, IProps>((props, ref) => {
  const onChange = () => {
    props.onChange?.(!props.isChecked);
  };

  return (
    <Flex
      gap={5}
      alignItems='start'
      userSelect='none'
      cursor='pointer'
      aria-disabled={props.isDisabled}
      _disabled={{ cursor: 'not-allowed' }}
      onClick={props.isDisabled ? undefined : onChange}
    >
      <Flex flexDir='column' flex={1} gap='2px'>
        <Box fontSize='15px' fontWeight='600' color='#111315'>
          Add skill verifier
        </Box>
        <Box fontSize='12px' fontWeight='500' color='#6F767E'>
          Allow the supervisor to give feedback on the training path after the
          assignee completes it.
        </Box>
      </Flex>

      <Checkbox
        size='lg'
        mt='2px'
        ref={ref}
        isChecked={props.isChecked}
        isDisabled={props.isDisabled}
        pointerEvents='none'
        onClick={(ev) => ev.stopPropagation()}
      />
    </Flex>
  );
});

AddSkillVerifierButton.displayName = 'AddSkillVerifierButton';

export default AddSkillVerifierButton;
