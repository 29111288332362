import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';
import { Button } from '../../../../atoms';
import { ActionButton } from '../../../../ui-components';
import { AppRoute, useHistory } from '../../../../routes';
import { AmplitudeEvent, deployEvent } from '../../../../shared';
import { useLauncherConfigContext } from '../common';
import { useConfigSaveAction } from '../common/useConfigSaveAction';

const Wrapper = styled(Flex)`
  background: #ffffff;
  padding-block: 20px;
  border: 1px solid #fcfcfc;

  position: sticky;
  bottom: -17px;

  margin-bottom: -17px;
  margin-inline: -38px;
  padding-inline: 38px;

  @media only screen and (max-width: 1199px) {
    margin-inline: -21px;
    padding-inline: 21px;
  }
`;

interface IProps {}

const FooterButton: FC<IProps> = () => {
  const { boardId } = useParams<{ boardId: string }>();

  const {
    isConfigDirty,
    disableAddLocation,
    locationCount,
    initializing,
    boardName,
  } = useLauncherConfigContext((state) => ({
    isConfigDirty: state.isConfigDirty,
    disableAddLocation: state.isConfigDirty || !state?.config?.eid,
    locationCount: state.config?.locations,
    initializing: state.initializing,
    boardName: state.config?.title,
  }));

  const history = useHistory();

  const onSubmitClick = useConfigSaveAction();

  const onAddButtonClick = () => {
    deployEvent(AmplitudeEvent.LAUNCHER_ADD_FIRST_LOCATION_CLICK, {
      board_creation_funnel_id: 5,
      board_creation_method: '',
      board_name: boardName,
      board_id: boardId,
    });
    history.push({
      pathname: AppRoute.LAUNCHER_ADD_LOCATION_FROM_BOARD,
      params: {
        launchId: boardId,
      },
    });
  };

  if (initializing) {
    return null;
  }

  if (locationCount) {
    return (
      <Wrapper gap={4}>
        <ActionButton
          flex={1}
          size='lg'
          colorScheme='blue'
          actionFn={onSubmitClick}
          isDisabled={!isConfigDirty}
        >
          Save and Publish changes
        </ActionButton>
      </Wrapper>
    );
  }

  return (
    <Wrapper gap={4}>
      <ActionButton
        flex={1}
        size='lg'
        variant='outline'
        actionFn={onSubmitClick}
        isDisabled={!isConfigDirty}
      >
        Save and Publish changes
      </ActionButton>

      <Button
        flex={1}
        size='lg'
        colorScheme='blue'
        onClick={onAddButtonClick}
        isDisabled={disableAddLocation}
      >
        Add your first location
      </Button>
    </Wrapper>
  );
};

FooterButton.displayName =
  'sub-components/Launcher/launcher-config/board-view/FooterButton';

export default FooterButton;
