import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import { HeaderColors } from 'configs';
import { useCallback, useState } from 'react';
import { BoxHeader } from 'ui-components';
import MemberActivityTimeline from './MemberActivityTimeline';

interface MemberActivityProps {
  memberName: string;
  userId: string;
  sessionId: string;
  tp: any;
}

const useMemberActivity = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [drawerState, setDrawerState] = useState<MemberActivityProps>({
    userId: '',
    memberName: '',
    sessionId: '',
    tp: {},
  });

  const useMemberActivityDrawer = useCallback(
    (props: MemberActivityProps) => {
      setDrawerState(props);
      onOpen();
    },
    [onOpen]
  );

  const DrawerComponent = (
    <Drawer isOpen={isOpen} onClose={onClose} size='sm'>
      <DrawerOverlay />
      <DrawerContent padding='.5rem' paddingTop='1rem'>
        <DrawerHeader>
          <Flex gap={5}>
            <BoxHeader
              title={`${drawerState.memberName}’s activity`}
              color={HeaderColors.Green}
            />
          </Flex>
          <ModalCloseButton top='8' right='6' fontSize='18px' />
        </DrawerHeader>
        <DrawerBody>
          <MemberActivityTimeline
            sessionId={drawerState.sessionId}
            userId={drawerState.userId}
            userName={drawerState.memberName}
            tp={drawerState.tp}
          />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );

  return { useMemberActivityDrawer, DrawerComponent };
};

useMemberActivity.displayName =
  'displayName:sub-components/training-v2/dashboard/components/memberActivity/useMemberActivity';

export default useMemberActivity;
