import React, { FC } from 'react';
import { Box, Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { Controller, useWatch } from 'react-hook-form';
import { IRepeatInput } from './repeating.types';
import WeekDaySelectInput from './WeekDaySelectInput';

interface IProps {}

const WeekDaySelection: FC<IProps> = () => {
  const type = useWatch<IRepeatInput, 'type'>({
    name: 'type',
  });

  if (type !== 'EVERY_N_WEEKS') {
    return null;
  }

  return (
    <Flex gap={4}>
      <Box
        fontWeight='600'
        color='#6F767E'
        lineHeight='42px'
        whiteSpace='nowrap'
      >
        Repeat on
      </Box>

      <Controller<IRepeatInput, 'dayOfTheWeek'>
        name='dayOfTheWeek'
        rules={{
          required: 'Please select a week day',
        }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid}>
            <WeekDaySelectInput {...field} />
            <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
          </FormControl>
        )}
      />
    </Flex>
  );
};

export default WeekDaySelection;
