import { gql } from '@apollo/client';

export interface TpCategoryEntity {
  eid: string;
  name: string;
}

export interface TpCategoryResponse {
  EntityTpCategories: TpCategoryEntity[];
}

export const TP_CATEGORY = gql`
  query EntityTpCategories {
    EntityTpCategories {
      eid
      name
    }
  }
`;

export interface AddTpCategoryResponse {
  AddTpCategories: TpCategoryEntity;
}

export const ADD_TP_CATEGORY = gql`
  mutation AddTpCategories($category: String!) {
    AddTpCategories(category: $category) {
      eid
      name
    }
  }
`;
