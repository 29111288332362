import { Flex, Text } from '@chakra-ui/react';
import { HeaderColors } from 'configs';
import { FC } from 'react';
import GenericColorHeader from 'sub-components/GenericColorHeader';
import { Image } from 'ui-components';
import noGraph from '../../../../assets/images/lolav2dashboard/Nograph.svg';
interface IProps {}

const Emptystate: FC<IProps> = () => {
  return (
    <Flex
      cursor='pointer'
      px={6}
      py={4}
      borderRadius='8px'
      bg='white'
      flexDir='column'
      w='100%'
      gap='16px'
      height={'294px'}
    >
      <GenericColorHeader title={'Launch time'} color={HeaderColors.Purple} />
      <Flex
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        width={'100%'}
        height={'182px'}
      >
        <Image src={noGraph} height={100} width={100} />
        <Text
          fontSize={'14px'}
          fontWeight={500}
          color={'#9E9E9E'}
          textAlign={'center'}
          width={'388px'}
        >
          No locations have launched yet. Your average launch time will appear
          once at least one location goes live.
        </Text>
      </Flex>
    </Flex>
  );
};

export default Emptystate;
