import { AmplitudeEvent, deployEvent } from '../../../shared';
import { toArray } from '../../../utils';
import { IFormInput } from './add-location.types';

export const deployBasicInfo = (values: IFormInput): void => {
  deployEvent(AmplitudeEvent.LAUNCHER_ADD_BASIC_INFO, {
    location_launcher_visit_funnel_id: 5,
    location_type: values.locationType,
    location_board: values.boards?.find((b) => b.eid === values.selectedBoard)
      ?.title,
    lifecycle_stage: values.locationStatus?.value,
    location_name: values.locationName,
    entity_name: values.entityName,
    entity_type: values.entityType,
    country_of_formation: values.countryOfFormation,
    state_of_formation: values.stateOfFormation,
    tax_payer_id_provided: !!values.taxPayerId,
  });
};

export const deployLocationDetails = (values: IFormInput): void => {
  deployEvent(AmplitudeEvent.LAUNCHER_ADD_LOCATION_DETAILS, {
    location_launcher_visit_funnel_id: 5,
    address: values.address,
    zipcode: values.zipCode,
    country: values.country,
    city: values.city,
    state: values.state,
    timezone: values.timezone?.value,
    email_address: values.locationEmail?.[0]?.email,
    upload_current_license: !!values?.licenceDocument,
  });
};

export const deployProjectDetails = (values: IFormInput): void => {
  const checkListData = toArray(values.selectedBoardData).reduce(
    (acc, value) => {
      if (value.selectedChoice === 'phaseRequired') {
        acc.push(value.category);
      }
      return acc;
    },
    [] as string[]
  );

  deployEvent(AmplitudeEvent.LAUNCHER_ADD_PROJECT_DETAILS, {
    location_launcher_visit_funnel_id: 5,
    phases_to_complete: checkListData,
    estimated_go_live_date: values.startDate,
  });
};

export const deployFinishDetails = (values: IFormInput): void => {
  deployEvent(AmplitudeEvent.LAUNCHER_ADD_INVITE_OWNER, {
    location_launcher_visit_funnel_id: 5,
    owner_1_name: values.locationOwnerOne?.name,
    owner_1_job: values.locationOwnerOne?.job?.value,
    owner_1_email: values.locationOwnerOne?.email,
    owner_2_name: values.locationOwnerTwo?.name,
    owner_2_job: values.locationOwnerTwo?.job?.value,
    owner_2_email: values.locationOwnerTwo?.email,
  });
};
