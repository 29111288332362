import mixpanel from 'mixpanel-browser';
import { useCallback, useEffect } from 'react';
import { useUserEntity } from './useUserDataQuery';

export const useMixPanelSuperProp = () => {
  const businessData = useUserEntity((entity) => ({
    eid: entity?.eid,
    isActiveCustomer: entity?.status?.isActive || false,
    isChurnedCustomer: entity?.status?.isChurned || false,
    isOnHoldCustomer: entity?.status?.isOnHold || false,
    isOnboardingCustomer: entity?.status?.isOnboarding || false,
  }));

  const registerSuperProps = useCallback(() => {
    if (
      sessionStorage?.getItem('mixPanelInit') === '1' &&
      mixpanel &&
      typeof mixpanel.register === 'function' &&
      businessData?.eid
    ) {
      mixpanel.register({
        isActiveCustomer: businessData?.isActiveCustomer,
        isChurnedCustomer: businessData?.isChurnedCustomer,
        isOnHoldCustomer: businessData?.isOnHoldCustomer,
        isOnboardingCustomer: businessData?.isOnboardingCustomer,
      });
    }
  }, [businessData]);

  useEffect(() => {
    const timer = setTimeout(() => {
      registerSuperProps();
    }, 100);

    return () => clearTimeout(timer);
  }, [registerSuperProps]);

  return registerSuperProps;
};
