import React, { FC } from 'react';
import { Text, Button, Image, Center, Flex } from '@chakra-ui/react';
import emptystate from '../../../../../../assets/images/training/crosstrainemptystate.svg';
import { useCrossTrain } from './CrossTrainModal';
import { Authorize, AuthRole } from 'authorization';

interface IProps {
  crossTrainModalData: any[];
  trainingId: string;
  sessionId: string;
  crossTrainAcceess: boolean;
  editPathAccess: boolean;
}

const EmptyState: FC<IProps> = ({
  crossTrainModalData,
  trainingId,
  sessionId,
  editPathAccess,
  crossTrainAcceess,
}) => {
  const openCrossTrainModal = useCrossTrain();
  return (
    <Center height='fit' mt={4}>
      {/* @ts-ignore */}
      <Flex gap={2} textAlign='center' direction={'column'}>
        <Image src={emptystate} alt='Illustration' objectFit='contain' />
        <Text fontSize={'20px'} fontWeight={600} color='#303030'>
          No Assignees Added
        </Text>
        <Text
          fontSize='12px'
          color='#6F767E'
          fontWeight={500}
          maxW='259px'
          mb={5}
          lineHeight={'20px'}
        >
          Spotted talent in a different field? Cross-train them for diverse
          roles and boost their efficiency!
        </Text>
        <Authorize
          permittedFor='user'
          permittedRoles={[AuthRole.LOCATION_OWNER]}
        >
          {crossTrainAcceess && (
            <Button
              colorScheme='blue'
              w='260px'
              borderRadius='12px'
              fontSize={'15px'}
              fontWeight={600}
              p={'8px 16px'}
              onClick={() =>
                openCrossTrainModal(crossTrainModalData, trainingId, sessionId)
              }
            >
              Cross train
            </Button>
          )}
        </Authorize>
      </Flex>
    </Center>
  );
};

export default EmptyState;
