import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { SortableColumnHeaderProps } from './types';
import { faArrowDownShortWide } from '@fortawesome/pro-light-svg-icons';

const SortableColumnHeader: FC<SortableColumnHeaderProps> = ({
  title,
  sortConfig,
  onSort,
}) => (
  <Flex alignItems='center' gap={2}>
    <Text fontSize='12px' fontWeight={500} color='#6F767E'>
      {title}
    </Text>
    <Tooltip label={sortConfig.sortLabel}>
      <Box onClick={onSort} cursor='pointer'>
        <FontAwesomeIcon
          fontSize='12px'
          color={sortConfig.order === 'none' ? '#6F767E' : '#2A85FF'}
          icon={faArrowDownShortWide as IconProp}
        />
      </Box>
    </Tooltip>
  </Flex>
);

export default SortableColumnHeader;
