import React, { FC, useEffect, useState } from 'react';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import PrimaryButton from 'atoms/PrimaryButton';
import SearchInput from 'atoms/SearchInput';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SortingTable from 'sub-components/ChakraTable/SortingTable/SortingTable';
import DashboardContainer from 'sub-components/DashboardContainer';
import ProgressTimeline from 'ui-components/ProgressTimeline';
import SingleUserChatModal from 'ui-components/SingleUserChatModal';
import MilestoneSuccess from '../../../assets/images/milestone_success.png';
import MedalIcon from '../../../assets/images/medal.svg';
import {
  Badge,
  Box,
  Center,
  Flex,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import {
  TRAINING_BY_ID_COMPLETE,
  TRAINING_BY_ID_TINY,
} from '../training.graphql';
// @ts-ignore
import { REASSIGN_TRAINING_COMPLETE } from 'sop-commons/Mutation/Training';
import './TrainingPath.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowUpRightFromSquare,
  faChevronLeft,
  faPlay,
} from '@fortawesome/free-solid-svg-icons';
import TrainingPathFooter from './TrainingPathFooter';
import { AssignedToUsersEntity, TrainingData } from '../training.types';
import SupervisorsSideModal from './SupervisorsSideModal';
import { ReactComponent as AlarmIcon } from '../../../assets/images/alarm.svg';
import { ReactComponent as RetakeIcon } from '../../../assets/images/retake.svg';
import { truncateString } from '../../../utils';
import RoleAndAuthRole from '../../../ui-components/role-authrole/RoleAndAuthRole';
import Image from '../../../ui-components/Image/Image';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import CustomPathCarousel from 'sub-components/AddNewPathModal/CustomPathCarousel';
import Dropdown, { SelectOption } from 'atoms/Dropdown';
import { Authorize, AuthRole } from '../../../authorization';
import NoAssignees from './NoAssignees';
import EmptyState from 'sub-components/EmptyState';
import { useRetakeTraining } from '../../../ui-components/Training';
import { ActionButton } from '../../../ui-components/Confirm';
import {
  filterCard,
  getColumns,
  getCustomTrainingDataHandler,
  searchHandler,
} from './helper';
import Heading from './Heading';
import TrainingAssigneeSearch from './TrainingAssigneeSearch';
import { usersEntityObj } from 'sub-components/Header';

const TrainingPath: FC = () => {
  const { t } = useTranslation(['common', 'training', 'header']);
  const params = useParams<{ id: string; trainingPathName?: string }>();
  const toast = useToast({
    position: 'top-right',
  });
  const entityUsersData = useReactiveVar(usersEntityObj);
  const [progressTimelineModal, setProgressTimelineModal] = useState(false);
  const [showRemindUserChatModal, setShowRemindUserChatModal] = useState(false);
  const [trainingPathData, setTrainingPathData] = useState<any>([]);
  const [assigneesTrainingPathData, setAssigneesTrainingPathData] =
    useState<any>([]);
  const [guestsTrainingPathData, setGuestsTrainingPathData] = useState<any>([]);
  const [copyTrainingPathData, setcopyTrainingPathData] = useState<any>([]);
  const [copyAssigneeTrainingPathData, setcopyAssigneeTrainingPathData] =
    useState<any>([]);
  const [copyGuestTrainingPathData, setcopyGuestTrainingPathData] =
    useState<any>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [progressData, setProgressData] = useState<{
    allMilestoneData: any[];
    showEmptyState: boolean;
    userData: any;
    trainingName?: string;
  }>({ allMilestoneData: [], showEmptyState: false, userData: null });
  const [selectedUser, setSelectedUser] = useState('');
  const [userName, setUserName] = useState('');
  const [showSupervisors, setShowSupervisors] = useState(false);
  const [showChatModal, setShowChatModal] = useState(false);
  const [trainingProgressModal, setTrainingProgressModal] = useState(false);
  const [selectedViewOption, setSelectedViewOption] = useState({
    label: t('common:all'),
    value: 'All',
  });
  const history = useHistory();

  const retakeTraining = useRetakeTraining();

  const [
    trainingByIdData,
    { data: _trainingByIdData, loading: trainingByIdLoading },
  ] = useLazyQuery<{
    trainingById: TrainingData;
  }>(TRAINING_BY_ID_TINY, {
    fetchPolicy: 'network-only',
    onError: (error) => {
      toast({
        title: t('training:fetch_assignees_error_toast'),
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      setTrainingPathData([]);
      setcopyTrainingPathData([]);
      setcopyAssigneeTrainingPathData([]);
      setcopyGuestTrainingPathData([]);
    },
  });

  useEffect(() => {
    if (entityUsersData?.length > 0 && _trainingByIdData?.trainingById?.eid) {
      if (_trainingByIdData?.trainingById?.assignedToUsers?.length) {
        trainingByIdDataHandler(_trainingByIdData?.trainingById);
      } else {
        setTrainingPathData(_trainingByIdData?.trainingById);
        setcopyTrainingPathData(_trainingByIdData?.trainingById);
        setcopyAssigneeTrainingPathData(_trainingByIdData?.trainingById);
        setcopyGuestTrainingPathData(_trainingByIdData?.trainingById);
      }
    }
  }, [entityUsersData, _trainingByIdData]);

  const trainingByIdDataHandler = (data: TrainingData) => {
    const processData = (data: TrainingData) => {
      const result = JSON.parse(JSON.stringify(data));

      const mergedProgress = [
        ...(data.overallProgress?.assignedUserProgress ?? []),
        ...(data.overallProgress?.nonAssignedUserProgress ?? []),
      ];

      const uniqueUsersProgress = Array.from(
        new Map(
          mergedProgress.map((userProgress) => [
            userProgress.userId,
            userProgress,
          ])
        ).values()
      );

      const newAssignedToUsers: AssignedToUsersEntity[] = uniqueUsersProgress
        .map((userProgress) => {
          const existingUser =
            data.assignedToUsers?.find(
              (user) => user.eid === userProgress.userId
            ) ||
            data.externalUsers?.find(
              (ext) => ext?.eid === userProgress?.userId
            );
          if (existingUser) {
            return existingUser;
          } else {
            const userDetails = entityUsersData.find(
              (user) => user.eid === userProgress.userId
            );
            if (userDetails) {
              return userDetails;
            } else {
              return undefined;
            }
            // if (userDetails) {
            //   return {
            //     eid: userDetails.eid,
            //     name: userDetails.name,
            //     userProgress: userProgress,
            //   };
            // }
          }
        })
        .filter((user) => user !== undefined) as AssignedToUsersEntity[];

      result.assignedToUsers = newAssignedToUsers?.map((user) => {
        const userCopy = { ...user };
        const assigned = data?.assignedTo?.find(
          (assign: any) => assign?.eid === user?.eid
        );
        userCopy.assignedDate = assigned?.assignedOn;

        const allProgress = [
          ...(data?.overallProgress?.assignedUserProgress || []),
          ...(data?.overallProgress?.nonAssignedUserProgress || []),
        ];
        const userProgress = allProgress?.filter(
          (progress) => progress?.userId === user?.eid
        );

        if (user?.locations?.length) {
          userCopy.address = user?.locations?.[0]?.name;
        }

        userCopy.userProgress = userProgress;
        userCopy.totalViewedCount =
          userProgress?.[0]?.viewedContents?.filter(filterCard)?.length;
        userCopy.isTrainingCompleted =
          userProgress?.[0]?.viewedContents?.length ===
          data?.trainingContentTiny?.length;
        userCopy.startDate = userProgress?.[0]?.startTime;
        userCopy.isRetakeLoading = false;

        return userCopy;
      });

      return result;
    };

    const tempData = processData(data);

    const assignedTrainingData = tempData?.assignedToUsers?.filter(
      (user: any) =>
        tempData?.overallProgress?.assignedUserProgress?.some(
          (assigned: any) => assigned?.userId === user?.eid
        )
    );

    const guestTrainingData = tempData?.assignedToUsers?.filter((user: any) =>
      tempData?.overallProgress?.nonAssignedUserProgress?.some(
        (assigned: any) => assigned?.userId === user?.eid
      )
    );

    setAssigneesTrainingPathData({
      ...tempData,
      assignedToUsers: assignedTrainingData,
    });
    setcopyAssigneeTrainingPathData({
      ...tempData,
      assignedToUsers: assignedTrainingData,
    });
    setGuestsTrainingPathData({
      ...tempData,
      assignedToUsers: guestTrainingData,
    });
    setcopyGuestTrainingPathData({
      ...tempData,
      assignedToUsers: guestTrainingData,
    });
    setTrainingPathData(tempData);
    setcopyTrainingPathData(tempData);
  };

  const [ReassignTraining] = useMutation(REASSIGN_TRAINING_COMPLETE, {
    onCompleted: (response) => {
      toast({
        title: t('training:training_path_update_success_toast'),
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      // window.location.reload();
      trainingByIdDataHandler(response?.ReassignTraining);
    },
    onError: (error) => {
      toast({
        title: t('training:training_path_update_error_toast'),
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      trainingByIdData();
    },
  });

  useEffect(() => {
    trainingByIdAPIHandler();
  }, []);

  const trainingByIdAPIHandler = () => {
    if (params?.id) {
      trainingByIdData({
        variables: {
          eid: params?.id,
        },
      });
    } else {
      history?.push('/training');
    }
  };

  const progressTimelinehandler = () => {
    setProgressTimelineModal(false);
  };

  const generateRandomUser = () => {
    return 'https://ui-avatars.com/api/?background=random';
  };

  const remindUserChatModal = (_userData: any) => {
    setShowRemindUserChatModal(true);
    setShowChatModal(true);
    setSelectedUser(_userData?.eid);
  };

  const onClose = () => {
    setShowRemindUserChatModal(false);
    setShowChatModal(false);
    setSelectedUser('');
  };

  const colorSetHandler = (index: number) => {
    let colorObj = [
      '#8830b0',
      '#30048c',
      '#60b569',
      '#02ade5',
      '#c7c37b',
      '#cc86a9',
      '#8b78e6',
      '#2578ae',
    ];
    return colorObj[index % 8];
  };

  const completedBadgeBgColor = useColorModeValue('#eafae4', '#eafae4');
  const pendingBadgeBgColor = useColorModeValue(
    'rgba(255, 188, 153, 0.2)',
    'rgba(255, 188, 153, 0.2)'
  );
  const completedBadgeColor = useColorModeValue('#83bf6e', '#83bf6e');
  const pendingBadgeColor = useColorModeValue('#ff6a55', '#ff6a55');

  const getTableData = (isTablet: boolean) => {
    let tableData: any = [];
    let _selectedOptionData =
      selectedViewOption?.value === 'All'
        ? trainingPathData
        : selectedViewOption?.value === 'Assignees'
        ? assigneesTrainingPathData
        : guestsTrainingPathData;

    if (
      _selectedOptionData &&
      _selectedOptionData?.assignedToUsers &&
      _selectedOptionData?.assignedToUsers?.length > 0
    ) {
      const cardCount =
        _selectedOptionData?.trainingContentTiny?.filter(filterCard)?.length;

      tableData = (_selectedOptionData?.assignedToUsers || [])
        ?.filter((user: any) => user?.status === 'active')
        ?.map((item: any, index: any) => {
          // @ts-ignore
          const badgeBgColor =
            item?.totalViewedCount === cardCount
              ? completedBadgeBgColor
              : pendingBadgeBgColor;

          // @ts-ignore
          const badgeColor =
            item?.totalViewedCount === cardCount
              ? completedBadgeColor
              : pendingBadgeColor;

          return {
            userInfo: (
              <Flex>
                {item?.profilePic ? (
                  <Box w={62} h={62} borderRadius={8} mr={10} display='flex'>
                    <Image
                      src={item?.profilePic}
                      height={62}
                      width={62}
                      style={{
                        borderRadius: '8px',
                        objectFit: 'cover',
                      }}
                    />
                  </Box>
                ) : (
                  <Center
                    w={62}
                    h={62}
                    borderRadius={8}
                    mr={10}
                    bg={colorSetHandler(index)}
                  >
                    <Text fontSize='xx-large' fontWeight='600' color='white'>
                      {item?.name?.trim()
                        ? item?.name?.split('')?.[0]?.toUpperCase()
                        : '--'}
                    </Text>
                  </Center>
                )}
                <Box
                  flex={1}
                  flexDirection='column'
                  justifyContent='space-between'
                  overflow='hidden'
                  pr={8}
                >
                  <Text
                    fontSize='14px'
                    fontWeight='700'
                    whiteSpace='nowrap'
                    overflow='hidden'
                    textOverflow='ellipsis'
                    color='#1a1d1f'
                  >
                    {item?.name?.trim() || '--'}
                  </Text>
                  <Text
                    fontSize='12px'
                    fontWeight='500'
                    whiteSpace='nowrap'
                    overflow='hidden'
                    textOverflow='ellipsis'
                    color='#33383F'
                  >
                    {item?.address?.trim() || '--'}
                  </Text>
                  <RoleAndAuthRole
                    authRole={item?.authRole || 'N/A'}
                    role={item?.role}
                    defaultValue='--'
                    roleProps={{ maxW: '50px' }}
                    authRoleProps={{ maxW: '50px' }}
                  />
                </Box>
              </Flex>
            ),
            status: (
              <Box mb='25px'>
                <Badge
                  textTransform='capitalize'
                  fontSize='14px'
                  fontWeight='600'
                  p='5px'
                  bg={badgeBgColor as string}
                  color={badgeColor as string}
                  borderRadius='4px'
                >
                  {t(
                    item.totalViewedCount === cardCount
                      ? 'common:completed'
                      : 'common:pending'
                  )}
                </Badge>
              </Box>
            ),
            assignDate: item?.assignedOn,
            startDate: item?.startDate,
            currentProgress: {
              item: item,
              maxLength: cardCount,
            },
            action: (
              <Box cursor='pointer' mb='25px'>
                {item?.totalViewedCount === cardCount ? (
                  <ActionButton
                    width='full'
                    type='button'
                    size='md'
                    borderRadius='7px'
                    fontSize='15px'
                    leftIcon={<RetakeIcon />}
                    isLoading={item?.isRetakeLoading}
                    disabled={item?.isRetakeLoading}
                    actionFn={() => confirmRetakeModalHandler(item)}
                  >
                    {t('training:training_details_section.retake_button')}
                  </ActionButton>
                ) : (
                  <PrimaryButton
                    type='button'
                    size='md'
                    title={t('training:training_details_section.remind_button')}
                    leftIcon={<AlarmIcon />}
                    onClick={() => {
                      remindUserChatModal(item);
                    }}
                  />
                )}
              </Box>
            ),
          };
        });
    }
    return [...tableData];
  };

  const handleQueryChange = (e: any) => {
    setSearchQuery(e?.target?.value);
    searchHandler(
      e?.target?.value,
      setTrainingPathData,
      copyTrainingPathData,
      setAssigneesTrainingPathData,
      copyAssigneeTrainingPathData,
      setGuestsTrainingPathData,
      copyGuestTrainingPathData,
      selectedViewOption
    );
  };

  const confirmRetakeModalHandler = (userData: any) => {
    setSelectedUser(userData?.eid);
    return new Promise((resolve) => {
      retakeTraining({
        // @ts-ignore
        title: t('training:retake_confirm_user', {
          name: userData?.name,
        }),
        onOk: async () => {
          await ReassignTraining({
            variables: {
              userId: userData?.eid,
              trainingId: trainingPathData?.eid,
            },
          });
          setShowChatModal(true);
          resolve(t('common:success'));
        },
        onCancel: () => resolve(null),
      });
    });
  };

  const openChatModalHandler = (selectedUserId: string) => {
    if (selectedUserId) {
      setShowRemindUserChatModal(true);
      setShowChatModal(true);
      setSelectedUser(selectedUserId);
      setProgressTimelineModal(false);
    }
  };

  const closeModalHandler = () => {
    setTrainingProgressModal(false);
  };

  const viewChangeHandler = (value: SelectOption<any>) => {
    setSelectedViewOption(value);
    setSearchQuery('');
  };

  const onGoToClick = () => {
    history?.push('/training/' + trainingPathData?.eid + '/edit');
  };

  return (
    <DashboardContainer>
      <>
        <div className='training-title-wrapper'>
          <Heading
            trainingPathData={trainingPathData}
            onGoToClick={onGoToClick}
            setTrainingProgressModal={setTrainingProgressModal}
          />
        </div>
        <div className='training-container'>
          <TrainingAssigneeSearch
            searchQuery={searchQuery}
            selectedViewOption={selectedViewOption}
            trainingPathData={trainingPathData}
            handleQueryChange={handleQueryChange}
            viewChangeHandler={viewChangeHandler}
          />
          <div>
            <SortingTable
              colorScheme='blue'
              emptyData={{
                content: !searchQuery ? (
                  <NoAssignees
                    title={trainingPathData?.title}
                    onGoToClick={onGoToClick}
                    createdBy={trainingPathData?.createdBy}
                  />
                ) : (
                  <EmptyState
                    image='Search'
                    title={t('header:result_not_found')}
                    description={t('header:not_found_desc')}
                  />
                ),
              }}
              page={1}
              columns={getColumns(
                false,
                trainingPathData,
                setUserName,
                setProgressData,
                setProgressTimelineModal
              )}
              data={getTableData(false)}
              isLoading={trainingByIdLoading}
              isHidePagination={true}
            />
            <TrainingPathFooter
              createdBy={trainingPathData?.createdBy?.name}
              superVisors={trainingPathData?.supervisorsUsers}
              onSuperVisorClick={() => setShowSupervisors(true)}
            />
          </div>
          {progressTimelineModal && (
            <ProgressTimeline
              isOpen={progressTimelineModal}
              timelineData={progressData?.allMilestoneData}
              onCloseClick={progressTimelinehandler}
              milestoneSuccessImg={MilestoneSuccess}
              // userName={progressData?.[0]?.name || userName}
              userName={progressData?.allMilestoneData?.[0]?.name || userName}
              openChatModal={openChatModalHandler}
              showEmptyState={progressData?.showEmptyState}
              userData={progressData?.userData}
              remindUserChatModalHandler={remindUserChatModal}
              medalIcon={MedalIcon}
            />
          )}
        </div>
      </>
      {showChatModal && (
        <SingleUserChatModal
          onClose={onClose}
          selectedUser={selectedUser}
          prefilledMessage={
            showRemindUserChatModal
              ? ''
              : `To refresh your knowledge and review the content to solidify your understanding, I would like to ask you to retake the training.\n${process.env.REACT_APP_FRONTEND_URL}/view/${params?.id}`
          }
        />
      )}
      <SupervisorsSideModal
        isOpen={showSupervisors}
        superVisors={trainingPathData?.supervisorsUsers}
        onClose={() => setShowSupervisors(false)}
      />
      {trainingProgressModal && (
        <CustomPathCarousel
          enablePreview={true}
          isOpen={trainingProgressModal}
          onClose={closeModalHandler}
          selectedTrainingData={getCustomTrainingDataHandler(trainingPathData)}
        />
      )}
    </DashboardContainer>
  );
};

TrainingPath.displayName =
  'displayName:pages/Training/TrainingPath/TrainingPath';

export default TrainingPath;
