import {
  Avatar,
  Badge,
  Box,
  Center,
  Icon,
  Progress,
  Text,
} from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEllipsis } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnsType, Table } from 'atoms';
import React, { FC } from 'react';
import { TrackPathEntity } from 'sub-components/training-v2/shared/types';
import { ActionMenu } from 'ui-components';
import { useHistory } from 'react-router';

const TrackPathTable: FC = () => {
  const history = useHistory();
  const columns: ColumnsType<TrackPathEntity> = [
    {
      title: 'Path Name',
      dataIndex: 'pathName',
      key: 'pathName',
      sorter: (a, b) => a.pathName.name.localeCompare(b.pathName.name),
      render: (pathName, row) => (
        <Box
          display='flex'
          alignItems='center'
          gap={3}
          onClick={() => {
            history.push(`dashboard/paths/${row.id}`);
          }}
        >
          <Avatar size='sm' src={pathName.profilePic} />
          <Box>
            <Text fontWeight='medium'>{pathName.name}</Text>
            <Text fontSize='sm' color='gray.500'>
              Last updated: {pathName.lastUpdated}
            </Text>
          </Box>
          <Badge colorScheme='blue' ml={2}>
            {pathName.category}
          </Badge>
        </Box>
      ),
    },
    {
      title: 'Created By',
      dataIndex: 'createdBy',
      key: 'createdBy',
      sorter: (a, b) => a.createdBy.localeCompare(b.createdBy),
      render: (createdBy) => <Text>{createdBy}</Text>,
    },
    {
      title: 'Created On',
      dataIndex: 'createdOn',
      key: 'createdOn',
      sorter: (a, b) =>
        new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime(),
      render: (date) => <Text>{new Date(date).toLocaleDateString()}</Text>,
    },
    {
      title: 'Assigned vs Completed',
      dataIndex: 'progress',
      key: 'progress',
      render: (progress) => (
        <Box>
          <Text>
            {progress.completed}/{progress.assigned} Completed
          </Text>
          <Progress
            value={(progress.completed / progress.assigned) * 100}
            size='sm'
            colorScheme='blue'
          />
        </Box>
      ),
    },
    {
      title: 'Overdue',
      dataIndex: 'isOverdue',
      key: 'isOverdue',
      render: (isOverdue) =>
        isOverdue ? <Badge colorScheme='red'>Overdue</Badge> : null,
    },
    {
      title: 'Last Activity',
      dataIndex: 'lastActivity',
      key: 'lastActivity',
      sorter: (a, b) =>
        new Date(a.lastActivity).getTime() - new Date(b.lastActivity).getTime(),
      render: (date) => <Text>{new Date(date).toLocaleDateString()}</Text>,
    },
    {
      title: '',
      key: 'actions',
      render: () => (
        <Center>
          <ActionMenu
            menuData={[
              {
                name: 'Edit',
                value: 'edit',
                icon: '',
              },
              {
                name: 'Track progress',
                value: 'track',
                icon: '',
              },
              {
                name: 'Mark as inactive',
                value: 'inactive',
                icon: '',
              },
              {
                name: 'Delete',
                value: 'delete',
                icon: '',
              },
            ]}
            arrowSize={10}
            closeOnBlur={true}
            offset={[0, 0]}
            clickedItem={(_, clickedItem) => {}}
          >
            <Box cursor='pointer'>
              <FontAwesomeIcon
                icon={faEllipsis as IconProp}
                size='2x'
                color='#33383F'
              />
            </Box>
          </ActionMenu>
        </Center>
      ),
    },
  ];
  const data: TrackPathEntity[] = [
    {
      id: '1',
      pathName: {
        name: 'Development Path',
        profilePic: '/path-pic.jpg',
        lastUpdated: '2024-01-05',
        category: 'Technical',
      },
      createdBy: 'John Doe',
      createdOn: '2024-01-01',
      progress: {
        assigned: 10,
        completed: 7,
      },
      isOverdue: true,
      lastActivity: '2024-01-06',
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={data}
      rowKey='id'
      pagination={{
        pageSize: 10,
      }}
    />
  );
};

TrackPathTable.displayName =
  'displayName:sub-components/training-v2/dashboard/components/TrackPathTable';

export default TrackPathTable;
