import React, { ChangeEvent, FC } from 'react';
import { Box, Flex, Switch } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import { callAll } from '@chakra-ui/utils';
import { QuizQFormInput } from '../quiz-form.types';
import MultiChoiceOptionsList from './MultiChoiceOptionsList';

interface IProps {}

const MultiChoiceInput: FC<IProps> = () => {
  const { getValues, setValue } = useFormContext<QuizQFormInput>();
  const onMultiChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.checked) {
      const options = getValues('options') || [];
      if (options.filter((op) => op.isCorrect).length > 1) {
        setValue(
          'options',
          options.map((op) => {
            op.isCorrect = false;
            return op;
          })
        );
      }
    }
  };

  return (
    <Flex flexDir='column' gap={3}>
      <MultiChoiceOptionsList />

      <Controller<QuizQFormInput, 'hasMultipleCorrect'>
        name='hasMultipleCorrect'
        render={({ field }) => (
          <Flex align='center' gap={2}>
            <Box fontSize='12px' fontWeight='500'>
              Multiple correct answers
            </Box>
            <Switch
              ref={field.ref}
              isChecked={field.value}
              onChange={callAll(field.onChange, onMultiChange)}
              onBlur={field.onBlur}
              sx={{
                '--switch-track-width': '2.5rem',
                '--switch-track-height': '1.25rem',
              }}
            />
          </Flex>
        )}
      />
    </Flex>
  );
};

export default MultiChoiceInput;
