import React, { FC } from 'react';
import { AddIcon } from '@chakra-ui/icons';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../../atoms';
import { AmplitudeEvent, deployEvent } from '../../../../../shared';
import { useAddAssignee } from '../add-assignee';
import { LauncherAssignee } from '../launcher-config.fragment';
import { getBoardViewName } from '../launcher-event.helpers';
import PhaseSettingAssignee from './PhaseSettingAssignee';

interface IProps {
  type: 'task' | 'phase';
}

const addClickEvent = (
  type: IProps['type'],
  taskId: string,
  taskTitle: string,
  view?: string
) => {
  if (type === 'task') {
    deployEvent(AmplitudeEvent.LAUNCHER_ADD_TASK_ASSIGNEE_CLICK, {
      board_creation_funnel_id: 5,
      // @ts-ignore
      task_id: taskId,
      // @ts-ignore
      task_title: taskTitle,
      edit_task_funnel_id: 44,
    });
  } else if (type === 'phase') {
    deployEvent(AmplitudeEvent.LAUNCHER_ADD_PHASE_ASSIGNEE_CLICK, {
      view: view,
    });
  }
};
const addSuccessEvent = (
  type: IProps['type'],
  value: LauncherAssignee,
  preUser: string[],
  values: Record<string, string>
) => {
  const newUser = value?.assignedUser || [];
  if (type === 'task') {
    deployEvent(AmplitudeEvent.LAUNCHER_ADD_TASK_ASSIGNEE, {
      board_creation_funnel_id: 5,
      // @ts-ignore
      task_id: values?.eid,
      // @ts-ignore
      task_title: values?.title,
      assignee_type: value.assignedType,
      total_assignees: value.assignedUser?.length,
      remove_assignee: preUser?.some((u) => !newUser.includes(u)),
      edit_task_funnel_id: 44,
    });
  } else if (type === 'phase') {
    deployEvent(AmplitudeEvent.LAUNCHER_ADD_PHASE_ASSIGNEE, {
      // @ts-ignore
      phase_title: values?.category,
      assignee_type: value.assignedType,
      remove_assignee: preUser?.some((u) => !newUser.includes(u)),
      edit_phase_funnel_id: 44,
    });
  }
};

const PhaseAssignee: FC<IProps> = ({ type }) => {
  const { t } = useTranslation('launcher');
  const { getValues, setValue } = useFormContext<LauncherAssignee>();
  const assignedType = useWatch<LauncherAssignee, 'assignedType'>({
    name: 'assignedType',
  });

  const editAssignee = useAddAssignee();

  const onEditAssignee = () => {
    const viewName = getBoardViewName();
    if (!getValues('assignedType')) {
      // @ts-ignore
      addClickEvent(type, getValues('eid'), getValues('title'), viewName);
    } else if (type === 'phase') {
      deployEvent(AmplitudeEvent.LAUNCHER_EDIT_PHASE_ASSIGNEE, {
        // @ts-ignore
        phase_title: getValues('category'),
        view: viewName,
        edit_phase_funnel_id: 44,
      });
    }

    const preUser = getValues('assignedUser') || [];

    editAssignee({
      value: {
        assignedType: getValues('assignedType'),
        assignedUser: getValues('assignedUser'),
      },
      onChange: async (value) => {
        setValue('assignedType', value.assignedType, {
          shouldDirty: true,
        });
        await new Promise((resolve) => setTimeout(resolve, 10));
        setValue('assignedUser', value.assignedUser, {
          shouldDirty: true,
        });

        addSuccessEvent(type, value, preUser, getValues() as never);
      },
    });
  };

  if (!assignedType) {
    return (
      <Button
        width='max-content'
        leftIcon={<AddIcon />}
        variant='outline'
        fontSize='12px'
        fontWeight='500'
        onClick={onEditAssignee}
      >
        {t('addAssignee', { context: type })}
      </Button>
    );
  }

  return <PhaseSettingAssignee onEditClick={onEditAssignee} />;
};

PhaseAssignee.displayName = 'sc/L/lc/c/pd/PhaseAssignee';

export default PhaseAssignee;
