import { gql } from '@apollo/client';
import {
  QuizQuestionType,
  TAccessPermission,
  TAssignee,
  TAssigneeUserType,
  TDuration,
  TrainingContType,
  TrainingStatus,
  TRepeatCycle,
} from '../../../types';

interface IQuizQuesOption {
  uid: string;
  text: string;
  thumbnail?: string;
  /**
   * This field available only in MULTI_CHOICE question
   */
  isCorrect?: boolean;
}

interface IQuizMatchSetOption {
  uid: string;
  left: string;
  leftThumbnail?: string;
  right: string;
  rightThumbnail?: string;
}

interface IQuizQuesBlank {
  uid: string;
  answer: string;
}

export interface IQuizQuestion {
  type: QuizQuestionType;
  label: string;
  thumbnail?: string;
  score: number;

  /**
   * @enum {QuizQuestionType.MULTI_CHOICE}
   */
  options: IQuizQuesOption[];

  /**
   * @enum {QuizQuestionType.TRUE_FALSE}
   */
  isTrue?: boolean;

  /**
   * @enum {QuizQuestionType.MATCH_SET}
   */
  pairs: IQuizMatchSetOption[];

  /**
   * @enum {QuizQuestionType.MATCH_SET}
   */
  sequence: IQuizQuesOption[];

  /**
   * @enum {QuizQuestionType.FILL_IN_BLANK}
   */
  textWithBlanks: string;
  blanks: IQuizQuesBlank[];

  hasMultipleCorrect?: boolean;
}

export interface ITrainingContent {
  type: TrainingContType;
  eid: string;
  title: string;
  thumbnail: string;
  addedBy: string;

  questions: IQuizQuestion[];
  hasScoring: boolean;
}

interface ISkillVerifier {
  name: string;
  skill: string;
}

interface IDependantTraining {
  hasMinScore: boolean;
  minScorePercent: number;
  trainingId: string;
}

export interface ISchedulePayload {
  isRepeating: boolean;
  repeatCycle: TRepeatCycle | null;
  repeatDetails: JSON;

  deadlineType: 'noDeadline' | 'date' | 'duration';
  deadlineDate: string | null;
  duration: TDuration | null;

  assignmentType: 'now' | 'date' | 'training';
  startDate: string | null;
  dependantTraining: IDependantTraining | null;
}

interface IAssignee {
  assigneeUserType: TAssigneeUserType;

  assignees: TAssignee[];

  supervisors: string[];
}

export interface CreateTrainingInput extends IAssignee, ISchedulePayload {
  eid: string;

  currentEditPage: string;
  title: string;
  description?: string;
  thumbnail: string;
  thumbnailColor: string;
  category: string;
  contents: ITrainingContent[];
  status: TrainingStatus;

  hasScoring: boolean;
  maxScore: number;

  hasSkillVerifier: boolean;
  skillVerifier: ISkillVerifier;

  accessPermission: TAccessPermission;

  createdBy: string;

  assignedUsers: Array<{ name: string }>;
}

export interface TrainingResponse {
  TpById: CreateTrainingInput;
}

export const GET_TRAINING = gql`
  query GetTrainingById($eid: String!) {
    TpById(eid: $eid) {
      eid
      title
      description
      thumbnail
      thumbnailColor
      category
      status

      currentEditPage

      contents {
        type
        eid
        title
        thumbnail
        addedBy
        questions {
          eid
          type
          label
          score
          options {
            uid
            text
            thumbnail
            isCorrect
          }
          isTrue
          pairs {
            uid
            left
            leftThumbnail
            right
            rightThumbnail
          }
          sequence {
            uid
            text
            thumbnail
          }
          thumbnail
          textWithBlanks
          blanks {
            uid
            answer
            thumbnail
          }
          hasMultipleCorrect
        }
        hasScoring
      }

      hasScoring
      maxScore

      assigneeUserType
      assignees {
        type
        roles
        authRoles
        locationIds
        locationType
        userIds
      }

      isRepeating
      repeatCycle {
        type
        dayOfTheWeek
        dayOfTheMonth
        monthOfTheYear
        days
        weeks
        months
        years
      }
      repeatDetails

      deadlineType
      deadlineDate
      duration {
        type
        value
      }

      assignmentType
      startDate
      dependantTraining {
        trainingId
        hasMinScore
        minScorePercent
      }

      hasSkillVerifier
      skillVerifier {
        name
        skill
      }

      supervisors
      accessPermission {
        admin
        locationOwner
        superadmin
      }

      createdBy

      assignedUsers {
        name
      }
    }
  }
`;

export interface UpdateTResponse {
  UpdateTP: {
    succeed: boolean;
  };
}

export const UPDATE_TRAINING = gql`
  mutation UpdateTP($input: UpdateTPInputInput) {
    UpdateTP(input: $input) {
      succeed
    }
  }
`;
