import { ApolloProvider, useApolloClient } from '@apollo/client';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { AlertDialogCloseButton, Box, Flex } from '@chakra-ui/react';
import { useCallback, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { IReAssignInfo } from 'sub-components/training-v2/dashboard/components/TrackPathDashboard/components/PathActions/ReAssignTpForUser';
import { UseConfirm, useConfirm } from 'ui-components';
import { EvaluateContainerContent } from './components/responses/components';

export type TSkillVerifierItem = {
  name: string;
  skill: string;
};
interface IProps {
  quizResponses: any;
  evalAssigneeMeta: {
    name: string;
    tpName: string;
  };
  skillVerifier?: TSkillVerifierItem;
  mode: 'detailed' | 'summarized';
  trainingId: string;
  userId: string;
  sessionId: string;
  maxScore: number;
  score: number;
  userName: string;
  tpScheduleInfo: IReAssignInfo;
  hasSkillVerifier: boolean;
  onEvaluateComplete?: () => void;
  tp?: any;
}

type EvaluateResponses = (props: IProps) => void;

export const useEvaluateResponses = (): EvaluateResponses => {
  const confirm = useConfirm();
  const apolloClient = useApolloClient();
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const { t } = useTranslation(['training']);
  return useCallback(
    ({
      evalAssigneeMeta,
      mode,
      skillVerifier,
      trainingId,
      userId,
      sessionId,
      maxScore,
      score,
      userName,
      tpScheduleInfo,
      hasSkillVerifier,
      onEvaluateComplete,
      tp,
    }) => {
      if (confirmRef.current) {
        confirmRef.current.destroy();
      }
      confirmRef.current = confirm({
        Wrapper: ({ children }: any) => {
          return (
            <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
          );
        },
        size: '4xl',
        title: (
          <Flex
            gap='12px'
            align='center'
            px='40px'
            pt='16px'
            pb='0px'
            py='32px'
          >
            <Box flex={1} flexDir='column'>
              <Box fontWeight={600} fontSize='20px'>
                <Trans
                  t={t}
                  i18nKey='training:evaluate.responses.evalAssignee'
                  values={{
                    name: evalAssigneeMeta?.name,
                  }}
                />
              </Box>
              <Box
                display={'flex'}
                gap={2}
                alignItems={'center'}
                fontWeight={500}
                fontSize={'15px'}
                textColor={'#6F767E'}
                mt={2}
              >
                <Trans
                  t={t}
                  i18nKey='training:evaluate.responses.forTrainingPath'
                  values={{
                    tpName: evalAssigneeMeta.tpName,
                  }}
                  components={[
                    <span
                      key={0}
                      style={{
                        fontWeight: 600,
                        fontSize: '15px',
                        color: '#2A85FF',
                      }}
                    >
                      {evalAssigneeMeta?.tpName}
                    </span>,
                  ]}
                />
                <ExternalLinkIcon
                  height={'24px'}
                  width={'24px'}
                  color={'#2A85FF'}
                  cursor={'pointer'}
                  onClick={() =>
                    window.open(
                      `/training-v2/play/preview/${trainingId}/${sessionId}`
                    )
                  }
                />
              </Box>
            </Box>
            <AlertDialogCloseButton
              pos='relative'
              borderRadius='full'
              top='0'
              right='0'
            />
          </Flex>
        ),
        content: (
          <EvaluateContainerContent
            skill={
              skillVerifier ?? {
                name: 'Default skillname',
                skill: 'Default skill',
              }
            }
            trainingId={trainingId}
            userId={userId}
            sessionId={sessionId}
            maxScore={maxScore}
            score={score}
            userName={userName}
            tpScheduleInfo={tpScheduleInfo}
            hasSkillVerifier={hasSkillVerifier}
            onEvaluateComplete={onEvaluateComplete}
            tp={tp}
          />
        ),
        contentProps: {
          borderRadius: '16px',
          p: '0px',
        },
        headerProps: {
          p: '0px',
        },
        bodyProps: {
          p: '0px',
        },
        isCentered: true,
        // okText: 'Submit',
        // cancelText: "Clear",
        // okButtonProps: {
        //   variant: 'outline',
        //   p: '8px 12px',
        //   borderRadius: '8px',
        //   colorScheme: 'gray'
        // },
        // cancelButtonProps: {
        //   p: '8px 12px',
        //   borderRadius: '8px',
        // }
        footer: null,
        footerProps: {
          w: 'fit-content',
        },
      });
    },
    [confirm]
  );
};
