import { gql } from '@apollo/client';

export const GET_ENTITY_USERS = gql`
  query SHARED_DATA_EntityUser($entityId: String!) {
    EntityUser(entityId: $entityId) {
      eid
      authRole
      createdAt
      email
      name
      type
      role
      status
      isRoot
      locations {
        eid
        name
        locationStatus
      }
      username
      profilePic
      timezone
      locationStatus
    }
  }
`;

export const GET_ENTITY_USERS_V2 = gql`
  query WEB_ENTITIES(
    $entityId: String!
    $status: [userStatusEnum]
    $authRole: [userAuthRoleEnum]
    $type: [userTypeEnum]
    $locations: [String]
  ) {
    EntityUsers(
      entityId: $entityId
      status: $status
      authRole: $authRole
      type: $type
      locations: $locations
    )
  }
`;

export const GET_FOLDER_BY_ID = gql`
  query EntityCategoryById($eid: String!) {
    EntityCategoryById(eid: $eid) {
      children {
        eid
        name
        status
        icon
        color
        createdBy
        createdByUser {
          eid
          name
          profilePic
        }
        visibility
        visibleTo {
          condition
          locations
          roles
          users
        }
      }
      eid
      name
      status
      icon
      color
      parent
      visibility
      visibleTo {
        condition
        locations
        roles
        users
      }
    }
  }
`;

export const TIMEZONES = gql`
  query TIMEZONES {
    TimeZones
  }
`;

export const TIMEZONE_QUERY = gql`
  query TimezoneQuery($lat: String!, $timestamp: String!, $long: String!) {
    GetTimeZoneForLocation(lat: $lat, timestamp: $timestamp, long: $long)
  }
`;

export const GET_LOCATION_BY_ZIP_CODE = gql`
  query GET_LOCATION_BY_ZIPCODE($zipCode: String!) {
    GetLocationsByZipCode(zipCode: $zipCode)
  }
`;
