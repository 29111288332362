import {
  Box,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowDownShortWide } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useMemo, useState } from 'react';
import { SortOptionsItem } from './constants';

type SortOption = {
  eid: string;
  label: string;
  value: 'asc' | 'desc' | '';
};

const sortOptions: SortOption[] = [
  { eid: '1', label: 'No sort', value: '' },
  { eid: '2', label: 'A-Z', value: 'asc' },
  { eid: '3', label: 'Z-A', value: 'desc' },
];

interface IRenderContentProps {
  onSortChange: (value: 'asc' | 'desc' | '') => void;
  selectedSort: string;
  options?: SortOptionsItem[];
}

const RenderContent: FC<IRenderContentProps> = ({
  onSortChange,
  selectedSort,
  options = sortOptions,
}) => {
  return (
    <Flex
      flexDir={'column'}
      gap={4}
      alignItems={'center'}
      justifyContent={'center'}
    >
      {options &&
        options?.map((item) => (
          <Flex
            key={item.eid}
            onClick={() => onSortChange(item.value)}
            cursor={'pointer'}
            borderRadius={'12px'}
            padding={'6px 12px'}
            backgroundColor={item.value === selectedSort ? '#EFEFEF' : 'white'}
            alignItems={'center'}
            justifyContent={'center'}
            shadow={item.value === selectedSort ? 'md' : ''}
          >
            <Text
              fontWeight={600}
              color={item.value === selectedSort ? '' : '#6F767E'}
              minWidth={'100px'}
            >
              {item.label}
            </Text>
          </Flex>
        ))}
    </Flex>
  );
};

interface IProps {
  onSort?: (value: 'asc' | 'desc' | '') => void;
  options?: SortOptionsItem[];
  columnKey?: string;
  activeColumnKey?: string;
}

const SortPopover: FC<IProps> = ({
  onSort,
  options,
  columnKey,
  activeColumnKey,
}) => {
  const [selectedSort, setSelectedSort] = useState<string>('');
  const handleSortChange = (value: 'asc' | 'desc' | '') => {
    setSelectedSort(value);
    onSort?.(value);
  };
  const isSortIconActive = useMemo(() => {
    if (activeColumnKey === columnKey && selectedSort !== '') {
      return true;
    }
    return false;
  }, [activeColumnKey, columnKey, selectedSort]);

  return (
    <Popover placement='right-end' isLazy>
      <PopoverTrigger>
        <Box>
          <FontAwesomeIcon
            color={isSortIconActive ? '#2A85FF' : '#6F767E'}
            cursor={'pointer'}
            icon={faArrowDownShortWide as IconProp}
          />
        </Box>
      </PopoverTrigger>
      <PopoverContent
        width={'170px'}
        minHeight={'150px'}
        p={'10px'}
        borderRadius={'12px'}
      >
        <PopoverArrow />
        <PopoverBody>
          <RenderContent
            onSortChange={handleSortChange}
            selectedSort={selectedSort}
            options={options}
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default SortPopover;
