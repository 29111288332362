import { gql } from '@apollo/client';

export const TASK_BY_ID = gql`
  query TaskById($eid: String!) {
    TaskById(eid: $eid) {
      assignedLocations
      assignedLocationsType
      assignedRoles
      assignedTo
      assignedToLocations {
        eid
        email
        name
        profilePic
      }
      assignedToRoles
      assignedToUsers {
        eid
        email
        role
        authRole
        name
        profilePic
        locations {
          eid
          name
        }
      }
      supervisors
      supervisorsUsers {
        eid
        email
        authRole
        name
        role
        locations {
          eid
          name
        }
        profilePic
      }
      createdBy
      createdByUser {
        eid
        name
        profilePic
      }
      description
      eid
      createdAt
      endCondition {
        endType
        endDate
        repeatCount
        _id
      }
      entityId
      expiresAt
      isRepeating
      lastSession {
        assignees {
          authRole
          completedAt
          completedTasks {
            contentId
            completedAt
            isCompleted
          }
          isCompleted
          locations
          role
          userId
        }
        assigneeUsers {
          authRole
          eid
          locations {
            name
            eid
          }
          name
          profilePic
          role
          type
        }
        assigneeUsersLocations {
          eid
          name
          username
          profilePic
          role
          authRole
        }
        createdAt
        currentUserProgress
        dueDate
        eid
        itemCount
        locationProgress
        name
        progress
        progressRate
        roleProgress
        startDate
        taskId
        taskItems
        title
        updatedAt
      }
      lastUpdated
      lastUpdatedBy
      nextRepeat
      repeatCycle
      repeatDetails
      repeatString
      startDate
      status
      taskItems
      thumbnail
      timezone
      title
      updatedAt
    }
  }
`;

export const GET_FORM_RESPONSE_SESSION = gql`
  query FormResponse(
    $filter: FilterFindManyFormsResponseInput
    $page: Int
    $perPage: Int
  ) {
    FormResponse(filter: $filter, page: $page, perPage: $perPage) {
      items {
        userId
        status
        formId
        sessionId
        response
        initialResponseAt
        completedAt
        createdAt
        updatedAt
        eid
        createdBy {
          eid
          email
          name
          profilePic
          authRole
          role
          locations {
            eid
            name
          }
        }
        form {
          questions
          userList
        }
      }
    }
  }
`;

export const GET_ALL_TASK_SESSIONS = gql`
  query SessionPagination(
    $filter: FilterFindManySessionInput
    $sort: SortFindManySessionInput
  ) {
    SessionPagination(filter: $filter, sort: $sort) {
      count
      items {
        dueDate
        eid
        createdAt
        maxDueDate
        name
        startDate
        taskId
        title
        updatedAt
        progressRate
      }
    }
  }
`;

export const GET_SESSION_BY_ID = gql`
  query SessionById($eid: String!) {
    SessionById(eid: $eid) {
      assignees {
        authRole
        completedAt
        completedTasks {
          completedAt
          contentId
          isCompleted
        }
        isCompleted
        locations
        role
        userId
      }
      assigneeUsers {
        status
        authRole
        eid
        locations {
          eid
          name
        }
        name
        profilePic
        role
      }
      assigneeUsersLocations {
        eid
        name
        profilePic
      }
      createdAt
      currentUserProgress
      dueDate
      eid
      isLastSession
      itemCount
      locationProgress
      name
      progress
      progressRate
      roleProgress
      startDate
      taskId
      taskItems
      title
      updatedAt
      task {
        audit {
          auditId
          description
          flagged
          qid
          sessionId
        }
        auditSession {
          questions
          assignments {
            locationId
            response
          }
        }
      }
    }
  }
`;
// export const GET_SESSION_BY_ID = gql`
//   query SessionById($eid: String!) {
//     SessionById(eid: $eid) {
//       eid
//       createdAt
//       isRepeating
//       startDate
//       taskItems
//       title
//       updatedAt
//     }
//   }
// `;
