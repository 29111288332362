import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Box, Divider, TabIndicator, Tabs } from '@chakra-ui/react';
import DashboardDataContext from 'sub-components/DashboardContainer/DashboardStore/DashboardData/dashboard-data-context';
import FormTabList from './FormTabList';
import FormTabPanelsRoot from './FormTabPanelsRoot';
import { AmplitudeEvent, deployEvent } from '../../../../shared';
import { CommonAmplitudeEvents } from 'shared/amplitudeEvents';
import { useQuery } from '@apollo/client';
import { toArray } from 'utils';
import FormDataContext from 'pages/forms/FormStore/form-data-context';
import { COLORS } from 'configs';
import {
  CategoryResponse,
  getFormCategories,
} from 'sub-components/forms/common/useFormCategories';

interface IProps {}

const FormListInternal: FC<IProps> = () => {
  const dashboardCtx = useContext(DashboardDataContext);
  const formCtx = useContext(FormDataContext);

  const activeTabIndex =
    dashboardCtx?.navigationPersistData?.forms?.selectedTab;
  const [searchValue, setSearchValue] = useState('');
  const [categoryFilter, setCategoryFilter] = useState<string[]>([]);

  const { data, loading: loadingCategory } =
    useQuery<CategoryResponse>(getFormCategories);

  useEffect(() => {
    formCtx?.updateFormCategoriesLoading(loadingCategory);
    let catArr = toArray(data?.EntityFormCategories)?.map((cat, index) => ({
      ...cat,
      color: COLORS?.[index % COLORS?.length] || '#cbecda',
    }));
    formCtx?.updateFormCategories(catArr || []);
  }, [data?.EntityFormCategories, loadingCategory]);

  const handleTabChange = (index: number) => {
    index === 0
      ? deployEvent(CommonAmplitudeEvents.ALL_FORMS_TAB)
      : deployEvent(CommonAmplitudeEvents.FORM_RESPONSES_TAB);

    setSearchValue('');
    setCategoryFilter([]);
    let navigationData = dashboardCtx?.navigationPersistData;
    dashboardCtx?.navigationPersistDataHandler({
      ...navigationData,
      forms: { selectedPage: 1, selectedTab: index },
    });
  };

  const searchHandler = (e: any) => {
    setSearchValue(e?.target?.value);
    dashboardCtx?.navigationPersistFormPageUpdateHandler(1);
  };

  return (
    // @ts-ignore
    <Tabs variant='unstyled' index={activeTabIndex} onChange={handleTabChange}>
      <Box>
        <FormTabList
          searchValue={searchValue}
          searchHandler={searchHandler}
          category={categoryFilter}
          onCategoryChange={setCategoryFilter}
        />
        <Divider maxW={'34%'} id='form-list-internal-divider' />
      </Box>
      <TabIndicator mt='-1.5px' height='2px' bg='#2A85FF' borderRadius='1px' />
      <FormTabPanelsRoot
        activeTabIndex={activeTabIndex}
        searchValue={searchValue}
        categoryFilter={categoryFilter}
      />
    </Tabs>
  );
};

FormListInternal.displayName =
  'displayName:pages/forms/modules/FormListInternal/FormListInternal';

export default FormListInternal;
