import React, { FC } from 'react';
import { Flex, useModalContext } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';

import { ActionButton } from '../../Confirm';

interface IProps {
  onReadClick: () => Promise<void>;
}

const ChapterActionFooter: FC<IProps> = ({ onReadClick }) => {
  const { onClose } = useModalContext();

  return (
    <Flex
      gap='10px'
      align='center'
      w='full'
      justify='flex-end'
      maxWidth='max(875px, 65%)'
    >
      <ActionButton
        fontSize='14px'
        variant='outline'
        leftIcon={
          <FontAwesomeIcon icon={faChevronLeft as IconProp} fontSize='14px' />
        }
        actionFn={onClose}
      >
        Go Back
      </ActionButton>

      <ActionButton
        fontSize='14px'
        variant='solid'
        colorScheme='blue'
        leftIcon={<CheckIcon />}
        actionFn={onReadClick}
        close={onClose}
      >
        I have read this
      </ActionButton>
    </Flex>
  );
};

ChapterActionFooter.displayName =
  'displayName:ui-components/resource-view/chapter/ChapterActionFooter';

export default ChapterActionFooter;
