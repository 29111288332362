import React, { useCallback, useRef } from 'react';
import { AlertDialogCloseButton, Box, Flex } from '@chakra-ui/react';
import { useConfirm, UseConfirm } from '../../../../../../../ui-components';
import SkillVerifierInfoContent from './SkillVerifierInfoContent';

export const useSkillVerifierInfo = () => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();

  return useCallback(() => {
    if (confirmRef.current) {
      confirmRef.current?.destroy();
    }

    confirmRef.current = confirm({
      title: (
        <Flex gap='12px' justify='space-between'>
          <Box
            fontSize='20px'
            fontWeight='600'
            lineHeight='32px'
            color='#1a1d1f'
          >
            Add a skill verifier
          </Box>

          <AlertDialogCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
          />
        </Flex>
      ),

      content: <SkillVerifierInfoContent />,

      isCentered: true,
      headerProps: { paddingX: 7 },
      contentProps: {
        borderRadius: '8px',
        maxWidth: '1024px',
        height: '100%',

        marginTop: 'auto',
        marginBottom: 'auto',

        maxHeight: 'calc(100vh - 6rem)',

        containerProps: {
          // alignItems: 'start',
          paddingTop: '3rem',
          paddingBottom: '3rem',
        },
      },
      bodyProps: {
        paddingX: 0,
        display: 'flex',
        overflow: 'hidden',
        paddingBottom: 0,
      },
      footer: null,
    });
  }, []);
};
