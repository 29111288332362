import React, { FC } from 'react';
import { AspectRatio, Box, Button, Grid } from '@chakra-ui/react';
import { ImageItem } from '../types';
import Image from 'ui-components/Image';

interface IProps {
  images?: ImageItem[];
  colsNo?: number;
}

const GalleryImageList: FC<IProps> = ({ images = [], colsNo = 4 }) => {
  return (
    <Box>
      <Grid templateColumns={`repeat(${colsNo}, 1fr)`} gap={1}>
        {images?.map((item, index) => (
          <Box position='relative'>
            <Image
              key={index}
              src={
                item.source === 'locPhotos'
                  ? `${item.url}&width=500&height=500`
                  : item.url
              }
              width={336}
              height={336}
              style={{
                width: '100%',
                objectFit: 'cover',
                aspectRatio: '1',
              }}
            />

            <Box
              _hover={{
                opacity: 1,
              }}
              className='backdrop'
              background='#00000050'
              position='absolute'
              inset='0px'
              opacity={0}
              transition='opacity 0.3s ease'
              display='flex'
              alignItems='end'
              justifyContent='flex-start'
            >
              <Button
                size='sm'
                margin='14px'
                borderRadius='10px'
                onClick={() => {
                  const googlePlaceId = item.googlePlaceId
                    ?.split('places/')[1]
                    .split('/')[0];

                  if (item.source === 'locPhotos') {
                    window.open(`
                      https://www.google.com/maps/place/?q=place_id:${googlePlaceId}`);
                  } else {
                    window.open(
                      `/audits/details/${item.auditId}/${item.sessionId}`,
                      '_blank'
                    );
                  }
                }}
              >
                {item.source === 'locPhotos'
                  ? 'Open Google places'
                  : 'Open linked Audit'}
              </Button>
            </Box>
          </Box>
        ))}
      </Grid>
    </Box>
  );
};

GalleryImageList.displayName = 'sc/n/G/c/GIL/GalleryImageList';

export default GalleryImageList;
