// @ts-ignore
import React from 'react';
// @ts-ignore
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import { IKContext } from 'imagekitio-react';
import { ConfigProvider } from 'antd';

import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { client } from 'sop-commons/src/client/clientFactory';
import { IntercomProvider } from 'react-use-intercom';
import { INTERCOM_APP_ID } from './env/config';
import { ChakraProvider } from '@chakra-ui/react';

import { theme } from './theme';
import DashboardDataProvider from './sub-components/DashboardContainer/DashboardStore/DashboardData/DashboardDataProvider';
import ChatMessageCountListener from './pages/Chat/ChatMessageCountListener';

import { AppRouter } from './routes';
import { antdThemeConfig } from './antd.config';

import './i18n';
import ChangeLanguage from './ChangeLanguage';
import { NewFeatureAlert } from './sub-components/AddressUpdateCheck';
import './sentry';
import { IMAGEKIT_URL_ENDPOINT } from './utils/constants';
import { UserbackProvider } from '@userback/react';

ReactDOM.render(
  <DashboardDataProvider>
    <UserbackProvider token='A-DFhiH7bVAl4oED8cYofGQdA14'>
      <ChakraProvider theme={theme}>
        <ConfigProvider theme={antdThemeConfig}>
          <IntercomProvider appId={INTERCOM_APP_ID}>
            <ApolloProvider client={client}>
              <IKContext urlEndpoint={IMAGEKIT_URL_ENDPOINT}>
                <React.StrictMode>
                  <AppRouter>
                    <App />
                    <ChangeLanguage />
                    {/*<NewFeatureAlert />*/}
                  </AppRouter>
                </React.StrictMode>
                <ChatMessageCountListener />
              </IKContext>
            </ApolloProvider>
          </IntercomProvider>
        </ConfigProvider>
      </ChakraProvider>
    </UserbackProvider>
  </DashboardDataProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
