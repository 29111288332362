import React, { FC } from 'react';
import {
  Box,
  Flex,
  Checkbox,
  Text,
  Image,
  IconButton,
  useToast,
} from '@chakra-ui/react';
import { HeaderColors } from 'configs';
import { BoxHeader } from 'ui-components';
import { LauncherTaskStep } from 'sub-components/Launcher/launcher-config/common/launcher-config.fragment';
import { useMarkAsComplete } from './useMarkAsComplete';
import { useMarkAsUndone } from './useMarkAsUndone';
import { PhaseTask } from 'sub-components/Launcher/dashboard/Location/LauncherLocation.graphql';
import useCombinedStore from 'zustandStore/store';
import {
  useChapterView,
  useTrainingStartWarning,
  useTrainingView,
} from 'ui-components/resource-view';
import { LAUNCHER_TASK_STEP_TYPE } from 'types';
import ClipboardIcon from 'assets/images/dashboard/clipboard.svg';
import FormIcon from 'assets/images/search/form.svg';
import { useSubmitForm } from 'sub-components/forms';
import { ILocationLaunchTask } from '../../types';
import { TextWithLink } from '../../../launcher-config/common';

interface SubtaskProps {
  isTaskCompleted: boolean;
  title: string;
  isCompleted: boolean;
  type: LAUNCHER_TASK_STEP_TYPE;
  onStatusChange: (completed: boolean) => Promise<void>;
}

const Subtask: FC<SubtaskProps> = ({
  isTaskCompleted,
  title,
  isCompleted,
  type,
  onStatusChange,
}) => {
  const handleCheckboxChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    await onStatusChange(e.target.checked);
  };

  return (
    <Flex
      bgColor={HeaderColors.White}
      padding='12px 16px'
      borderRadius='12px'
      justifyContent='space-between'
      alignItems='center'
      gap={3}
    >
      <Flex gap={3}>
        {!isTaskCompleted && (
          <Checkbox
            size='md'
            borderRadius='6px'
            isChecked={isCompleted}
            onChange={handleCheckboxChange}
          />
        )}
        <TextWithLink
          fontSize='14px'
          fontWeight={500}
          color='#33383F'
          wordBreak='break-word'
          linkText={title}
        />
      </Flex>
      <IconButton
        bg={'rgba(239, 239, 239, 1)'}
        px='4px'
        py='3.5px'
        borderRadius='6px'
        aria-label='action'
        variant='ghost'
        icon={
          <Image
            src={
              type === 'text'
                ? ClipboardIcon
                : type === 'form'
                ? FormIcon
                : ClipboardIcon
            }
          />
        }
      />
    </Flex>
  );
};

interface SubtasksProps {
  isTaskCompleted: boolean;
  launchId?: string;
  taskDetails: PhaseTask;
  refetchData?: (resData?: ILocationLaunchTask | undefined) => void;
}

const Subtasks: FC<SubtasksProps> = ({
  isTaskCompleted,
  launchId,
  taskDetails,
  refetchData,
}) => {
  const {
    selectedInProgressLocation,
    stateDrawer: { updateLoading },
  } = useCombinedStore();
  const steps = taskDetails?.steps;
  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  });
  const { markAsComplete: markStepAsComplete } = useMarkAsComplete();
  const { markAsUndone } = useMarkAsUndone();
  const chapterView = useChapterView();
  const submitForm = useSubmitForm();
  const trainingWarning = useTrainingStartWarning();
  const trainingView = useTrainingView();

  const handleStatusChange = async (
    step: LauncherTaskStep,
    completed: boolean
  ) => {
    try {
      if (completed) {
        switch (step?.type) {
          case 'text': {
            updateLoading(true);
            return markStepAsComplete({
              stepId: step?.stepId,
              launchId: launchId || selectedInProgressLocation?.launchId!,
              taskId: taskDetails?.eid,
              formResponseId: '',
            })
              ?.then((res) => {
                toast({
                  status: 'success',
                  title: 'Successfully marked step as complete',
                });
                refetchData?.(res?.data?.CompleteLauncherTask);
              })
              ?.catch(() => {
                updateLoading(false);
                toast({
                  status: 'error',
                  title: 'Failed to mark step as complete',
                });
                refetchData?.();
              });
          }
          case 'sop':
            return chapterView({
              sopId: step?.sopId!,
              title: '',
              onReadClick: async () => {
                updateLoading(true);
                return markStepAsComplete({
                  stepId: step?.stepId,
                  launchId: launchId || selectedInProgressLocation?.launchId!,
                  taskId: taskDetails?.eid,
                  formResponseId: '',
                })
                  ?.then((res) => {
                    toast({
                      status: 'success',
                      title: 'Successfully marked step as complete',
                    });
                    refetchData?.(res?.data?.CompleteLauncherTask);
                  })
                  ?.catch(() => {
                    updateLoading(false);
                    toast({
                      status: 'error',
                      title: 'Failed to mark step as complete',
                    });
                    refetchData?.();
                  });
              },
            });
          case 'form':
            return submitForm({
              formId: step?.formId!,
              title: step?.title,
              canOverride: true,
              onSubmitted: (returnedFormData) => {
                updateLoading(true);
                return markStepAsComplete({
                  stepId: step?.stepId,
                  launchId: launchId || selectedInProgressLocation?.launchId!,
                  taskId: taskDetails?.eid,
                  formResponseId: returnedFormData?.eid,
                })
                  ?.then((res) => {
                    toast({
                      status: 'success',
                      title: 'Successfully marked step as complete',
                    });
                    refetchData?.(res?.data?.CompleteLauncherTask);
                  })
                  ?.catch(() => {
                    updateLoading(false);
                    toast({
                      status: 'error',
                      title: 'Failed to mark step as complete',
                    });
                    refetchData?.();
                  });
              },
            });
          case 'training':
            trainingWarning(() => {
              trainingView({
                trainingId: step?.stepId,
                title: step?.title,
                formAccessOverride: true,
                inputVariable: {
                  locationLaunchId:
                    launchId || selectedInProgressLocation?.launchId!,
                  taskId: taskDetails?.eid,
                  stepId: step?.stepId,
                },
                onFinish: () => {
                  updateLoading(true);
                  return markStepAsComplete({
                    stepId: step?.stepId,
                    launchId: launchId || selectedInProgressLocation?.launchId!,
                    taskId: taskDetails?.eid,
                    formResponseId: '',
                  })
                    ?.then((res) => {
                      toast({
                        status: 'success',
                        title: 'Successfully marked step as complete',
                      });
                      refetchData?.(res?.data?.CompleteLauncherTask);
                    })
                    ?.catch(() => {
                      updateLoading(false);
                      toast({
                        status: 'error',
                        title: 'Failed to mark step as complete',
                      });
                      refetchData?.();
                    });
                },
              });
            }, step);
            break;
          default:
            return;
        }
      } else {
        updateLoading(true);
        markAsUndone({
          launchId: launchId || selectedInProgressLocation?.launchId!,
          stepId: step?.stepId,
          taskId: taskDetails?.eid,
          formResponseId: '',
        })
          ?.then((res) => {
            toast({
              status: 'success',
              title: 'Successfully marked step as incomplete',
            });
            refetchData?.(res?.data?.MarkLauncherTaskUndone);
          })
          ?.catch(() => {
            updateLoading(false);
            toast({
              status: 'error',
              title: 'Failed to mark step as incomplete',
            });
            refetchData?.();
          });
      }
    } catch (error) {
      console.error(error);
      updateLoading(false);
    }
  };

  return (
    <>
      <BoxHeader
        title='Subtasks'
        color={HeaderColors.Purple}
        margintop='32px'
      />
      <Flex direction='column' gap='12px' mt='12px' overflowY='scroll'>
        {steps?.map((step, index) => (
          <Subtask
            key={index}
            title={step.title}
            type={step?.type}
            isTaskCompleted={isTaskCompleted}
            isCompleted={!!(step?.completedAt || step?.completedBy)}
            onStatusChange={(completed) => handleStatusChange(step, completed)}
          />
        ))}
      </Flex>
    </>
  );
};

Subtask.displayName =
  'displayName:sub-components/Launcher/location-owner/StatesDrawer/Subtasks/Subtask';
Subtasks.displayName =
  'displayName:sub-components/Launcher/location-owner/StatesDrawer/Subtasks/Subtasks';

export default Subtasks;
