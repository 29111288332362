import React, {
  ChangeEvent,
  FC,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Box,
  Checkbox,
  CheckboxGroup,
  Flex,
  HStack,
  List,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import { chakraComponents } from 'chakra-react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';

import { AuthRole } from 'sop-commons/src/client';

import { PrimaryButton, SearchInput } from 'atoms';
import Dropdown, { SelectOption } from 'atoms/Dropdown';
import { useUserDataSelector } from 'hooks';
import { searchRegExp, toArray } from 'utils';

import { useVisibilityMemberData } from '../../../../ui-components/ShareToMember';
import { ReactComponent as ManagerIcon } from '../../../../assets/images/managerBlue.svg';
import MemberItem from './MemberItem';
import {
  FormToUserResponse,
  FormToUserVariable,
  SEND_FORM_QUERY,
} from './send-form.graphql';

import { IFormListEntity } from '../../forms-types';
import SendFormLoader from './SendFormLoader';
import { ActionButton } from '../../../../ui-components';

interface IProps {
  isOpen?: boolean;
  onClose: () => void;
  formData?: IFormListEntity['items'][number];
}

const SendFormModalInternal: FC<IProps> = ({ isOpen, onClose, formData }) => {
  const { t } = useTranslation(['common', 'form', 'header']);
  const submitRef = useRef(false);
  const toast = useToast({
    duration: 3000,
    position: 'top-right',
    isClosable: true,
  });
  const senderId = useUserDataSelector((state) => state.eid);
  const [checked, setChecked] = useState<(string | number)[]>([]);
  const [search, setSearch] = useState('');
  const [selectedFilter, setSelectedFilter] = useState<
    SelectOption | undefined | null
  >(undefined);
  const [allChecked, setAllChecked] = useState(false);

  const [submitFormData] = useMutation<FormToUserResponse, FormToUserVariable>(
    SEND_FORM_QUERY,
    {
      onCompleted: (data) => {
        if (data.sendDirectMessage?.succeed) {
          toast({
            title: t('success'),
            status: 'success',
            description: t('form:form_success_sent'),
          });
        }
        setTimeout(onClose, 100);
      },
    }
  );

  const { members, loading } = useVisibilityMemberData({
    visibility: formData?.visibility!,
    visibleTo: formData?.visibleTo!,
  });

  const filteredMembers = useMemo(() => {
    // Filter members to include only those with an active status
    const activeMembers =
      members?.filter((member) =>
        ['active', 'pending']?.includes(member.status)
      ) || [];

    if (search && selectedFilter?.value) {
      const searchReg = searchRegExp(search, 'gi');
      const filterReg = searchRegExp(selectedFilter?.value, 'gi');
      return (
        activeMembers.filter((value) => {
          return (
            value.name?.match(searchReg) ||
            value.authRole?.match(searchReg) ||
            value.role?.match(searchReg) ||
            value?.locations?.[0]?.name?.match(searchReg) ||
            value?.authRole?.match(filterReg)
          );
        }) || []
      );
    } else if (search || selectedFilter?.value) {
      if (search) {
        const reg = searchRegExp(search, 'gi');
        return (
          activeMembers.filter((value) => {
            return (
              value.name?.match(reg) ||
              value.authRole?.match(reg) ||
              value.role?.match(reg) ||
              value?.locations?.[0]?.name?.match(reg)
            );
          }) || []
        );
      } else {
        const reg = searchRegExp(selectedFilter?.value, 'gi');
        return (
          activeMembers.filter((value) => {
            return value.authRole?.match(reg);
          }) || []
        );
      }
    }

    // Return the filtered active members if no search or filter is applied
    return activeMembers;
  }, [members, search, selectedFilter?.value]);

  const onAllCheckChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAllChecked(event.target.checked);
    const _values = toArray(filteredMembers).map((m) => m.eid);
    if (event.target.checked) {
      setChecked((prevState) => {
        const _newValue = _values.filter((v) => !prevState.includes(v));
        return [...prevState, ..._newValue];
      });
    } else {
      setChecked((prevState) => {
        const _newValue = prevState.filter((v) => !_values.includes(String(v)));
        return [..._newValue];
      });
    }
  };

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onSubmit = useCallback(async () => {
    if (submitRef.current) {
      return;
    }
    submitRef.current = true;

    if (!checked?.length) {
      toast({
        title: t('warning'),
        description: t('form:select_member_warn'),
        status: 'warning',
      });
      submitRef.current = false;
      return;
    }

    try {
      await submitFormData({
        variables: {
          senderId: senderId,
          receiverIds: checked as string[],
          message: `${process.env.REACT_APP_FRONTEND_URL}/form/submit/${formData?.eid}`,
        },
      });
      submitRef.current = false;
    } catch (err) {
      console.log(err);
      submitRef.current = false;
    }
  }, [checked, senderId, formData]);

  const [allItemChecked, isIndeterminate] = useMemo(() => {
    const all = filteredMembers?.every((val) => checked.includes(val.eid));
    const some = filteredMembers?.some((val) => checked.includes(val.eid));
    return [all && !!filteredMembers?.length, some && !all];
  }, [filteredMembers, checked]);

  const onFilterChange = (option: SelectOption) => {
    if (option?.value === selectedFilter?.value) {
      setSelectedFilter(null);
    } else {
      setSelectedFilter(option);
    }
  };

  return (
    <Modal
      isOpen={!!isOpen}
      onClose={onClose}
      size='3xl'
      closeOnOverlayClick={false}
      scrollBehavior='inside'
    >
      <ModalOverlay />
      <ModalContent p={'6'} pb={8} borderRadius='21px'>
        <ModalHeader>
          <Flex align='center'>
            <Box mr={3} cursor='pointer'>
              <FontAwesomeIcon
                icon={faArrowLeft as IconProp}
                fontSize='17px'
                onClick={onClose}
              />
            </Box>
            {t('form:send_form_uppercase')}
          </Flex>
          <Flex mt={4} gap='10px'>
            <Box w='60%'>
              <SearchInput
                size='md'
                width='100%'
                hideShortcuts
                placeholder={t('header:search_member')}
                disabled
                onChange={handleSearchChange}
              />
            </Box>
            <Box w='40%'>
              <Dropdown
                placeholder='Filter by'
                value={selectedFilter}
                onChange={onFilterChange}
                options={[
                  {
                    label: 'Super Admins',
                    value: AuthRole.SUPER_ADMIN,
                  },
                  {
                    label: 'Admins',
                    value: AuthRole.ADMIN,
                  },
                  {
                    label: 'Location Owners',
                    value: AuthRole.LOCATION_OWNER,
                  },
                  {
                    label: 'Workers',
                    value: AuthRole.WORKER,
                  },
                ]}
                selectStyles={{
                  singleValue: {
                    color: '#6f767e',
                    fontWeight: '600',
                  },
                }}
                components={{
                  SingleValue: (valProps) => {
                    return (
                      <chakraComponents.SingleValue {...valProps}>
                        {/* @ts-ignore */}
                        {t('handbook:filterBy', {
                          value: valProps.children,
                        })}
                      </chakraComponents.SingleValue>
                    );
                  },
                }}
              />
            </Box>
          </Flex>
        </ModalHeader>
        <ModalBody marginTop={2}>
          <Box>
            <SendFormLoader
              isEmpty={!filteredMembers?.length}
              isLoading={loading}
            >
              <CheckboxGroup value={checked} onChange={setChecked}>
                <List spacing={3}>
                  {filteredMembers?.length > 0 && (
                    <HStack align='center' justify='space-between'>
                      <Text as='b'>{t('select_all')}</Text>

                      <Checkbox
                        isChecked={allChecked && allItemChecked}
                        isIndeterminate={allChecked && isIndeterminate}
                        onChange={onAllCheckChange}
                      />
                    </HStack>
                  )}
                  {filteredMembers?.map((member) => {
                    return <MemberItem key={member.eid} item={member} />;
                  })}
                </List>
              </CheckboxGroup>
            </SendFormLoader>
          </Box>
        </ModalBody>
        <ModalFooter flexDirection='column'>
          <Flex
            px={5}
            py={4}
            bg='rgba(177, 229, 252, 0.4)'
            mb={3}
            borderRadius='10px'
            w='full'
          >
            <Box mr={3}>
              <ManagerIcon />
            </Box>
            <div>{t('form:email_chat_notification')}</div>
          </Flex>
          <ActionButton
            size='lg'
            w='full'
            isDisabled={!checked?.length}
            colorScheme='blue'
            actionFn={onSubmit}
            fontSize='15px'
            borderRadius='12px'
          >
            {t('send')}
          </ActionButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const SendFormModal: FC<IProps> = (props) => {
  if (!props.isOpen) {
    return null;
  }
  return <SendFormModalInternal {...props} />;
};

SendFormModal.displayName =
  'displayName:pages/forms/modules/SendForm/SendFormModal';

SendFormModalInternal.displayName =
  'displayName:pages/forms/modules/SendForm/SendFormModalInternal';

export default SendFormModal;
