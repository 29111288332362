import React, { FC, useEffect, useState } from 'react';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  IconButton,
} from '@chakra-ui/react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { gql, useLazyQuery } from '@apollo/client';
import { callAll } from '@chakra-ui/utils';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { v4 as generateEid } from 'uuid';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';

import { AuditHelper } from 'configs';
import { Input } from 'atoms';
import { toArray } from '../../../../../utils';
import TitleHeader from '../../../../CardEditor/TitleHeader';

import { IFormInput } from '../../form-types';
import AuditQuestionFooter from './AuditQuestionFooter';
import AuditQuestionTypeSelect from './AuditQuestionTypeSelect';
import { AuditOptionList, IFooterProps } from './audit-options';
import AuditMediaHelper from './AuditMediaHelper';
import AuditQuestionSearchSuggestion from './AuditQuestionSearchSuggestion';

interface IProps extends IFooterProps {
  fieldId: string;
  moveItem: (oldIndex: number, newIndex: number) => void;
  totalQuestions: number;
}

const GET_QUESTION_SUGGESTIONS = gql`
  query Query($query: String!) {
    AuditTemplateSuggestion(query: $query)
  }
`;

const AuditQuestion: FC<IProps> = ({
  questionIndex,
  handleRemove,
  handleDuplicate,
  isReadOnly,
  fieldId,
  moveItem,
  totalQuestions,
}) => {
  const [suggestedQuestions, setSuggestedQuestions] = useState([]);
  const [questionText, setQuestionText] = useState('');
  const [blurToggle, setBlurToggle] = useState(false);
  const { setValue } = useFormContext<IFormInput>();
  const [isAuditQuestion, questionId] = useWatch<
    IFormInput,
    ['isAuditQuestion', `questions.${number}.eid`]
  >({
    name: ['isAuditQuestion', `questions.${questionIndex}.eid`],
  });

  const { setNodeRef, transform, transition } = useSortable({
    id: fieldId,
    data: { index: questionIndex },
  });

  const [getQuestionSuggestions] = useLazyQuery(GET_QUESTION_SUGGESTIONS, {
    onCompleted: (questions) => {
      setSuggestedQuestions(questions?.AuditTemplateSuggestion);
    },
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      getQuestionSuggestions({
        variables: {
          query: questionText,
        },
      });
    }, 300);

    return () => {
      clearTimeout(timer);
    };
  }, [questionText]);

  useEffect(() => {
    const handleClick = (e: any) => {
      if (!e.target.classList.contains('custom-popup')) {
        // Check if suggestedQuestions is already empty
        if (suggestedQuestions?.length > 0) {
          setSuggestedQuestions([]);
        }
      }
    };
    window.addEventListener('click', handleClick);
    return () => window.removeEventListener('click', handleClick);
  }, [blurToggle]);

  const setInputSelectedQuestion = (question: any) => {
    setValue(`questions.${questionIndex}.qType`, question?.qType);
    setValue(`questions.${questionIndex}.label`, question?.label);
    setValue(
      `questions.${questionIndex}.options`,
      toArray(question?.options).map((opt) => ({
        ...opt,
        eid: undefined,
        tempEid: generateEid(),
      }))
    );
    setSuggestedQuestions([]);
  };

  return (
    <Flex
      flex={1}
      border='2px solid #EFEFEF'
      borderRadius='8px'
      bg='#FCFCFC'
      ref={setNodeRef}
      transform={CSS.Translate.toString(transform)}
      transition={transition}
    >
      <Flex width='16px' bg='#2A85FF' borderLeftRadius='5px' />
      <Flex flex={1} flexDir='column' gap={4} padding='23px 23px 23px 16px'>
        <Flex justify='flex-end' marginBottom={-4}>
          <IconButton
            aria-label='up'
            bg='white'
            onClick={() => moveItem?.(questionIndex, questionIndex - 1)}
            isDisabled={questionIndex === 0}
          >
            <ChevronUpIcon boxSize={7} />
          </IconButton>
          <IconButton
            aria-label='down'
            bg='white'
            onClick={() => moveItem?.(questionIndex, questionIndex + 1)}
            isDisabled={totalQuestions === questionIndex + 1}
          >
            <ChevronDownIcon boxSize={7} />
          </IconButton>
        </Flex>

        <Flex flexDir='column'>
          <TitleHeader title='Question' isRequired />

          <Controller<IFormInput, `questions.${number}.label`>
            name={`questions.${questionIndex}.label`}
            render={({ field, fieldState }) => {
              return (
                <FormControl mt={2} isInvalid={!!fieldState.error}>
                  <Input
                    // @ts-ignore
                    onKeyUp={(e) => setQuestionText(e?.target?.value)}
                    size='lg'
                    variant='auditOutline'
                    placeholder='Type your own question or choose from list'
                    {...field}
                    onBlur={() => {
                      AuditHelper.save(field.onBlur);
                      setBlurToggle(!blurToggle);
                    }}
                  />
                  <FormErrorMessage>
                    {fieldState?.error?.message}
                  </FormErrorMessage>
                  {suggestedQuestions?.length > 0 &&
                    questionText.length > 0 && (
                      <AuditQuestionSearchSuggestion
                        field={field}
                        suggestedQuestions={suggestedQuestions}
                        setInputSelectedQuestion={setInputSelectedQuestion}
                      />
                    )}
                </FormControl>
              );
            }}
          />
        </Flex>

        <Flex flexDir='column'>
          <TitleHeader title='Type of response' />

          <Controller<IFormInput, `questions.${number}.qType`>
            name={`questions.${questionIndex}.qType`}
            render={({ field, fieldState }) => (
              <FormControl mt={2} isInvalid={!!fieldState.error}>
                <AuditQuestionTypeSelect
                  {...field}
                  isViewOnly={!!(isAuditQuestion && questionId)}
                  onChange={callAll(field.onChange, AuditHelper.save)}
                />

                <FormErrorMessage>
                  {fieldState?.error?.message}
                </FormErrorMessage>
              </FormControl>
            )}
          />
        </Flex>

        <AuditMediaHelper questionIndex={questionIndex} />
        <AuditOptionList questionIndex={questionIndex} />

        <AuditQuestionFooter
          questionIndex={questionIndex}
          handleRemove={handleRemove}
          handleDuplicate={handleDuplicate}
          isReadOnly={isReadOnly}
        />
      </Flex>
    </Flex>
  );
};

export default AuditQuestion;
