import { cloneDeep } from '@apollo/client/utilities';
import { Box, Center } from '@chakra-ui/react';
import { useUserDataSelector, useUserEntity } from 'hooks';
import React, { FC, useMemo } from 'react';
import { useNexusBoardContext } from 'sub-components/nexus/NexusBase/store/context';
import {
  AuditLocationsAnalytics,
  AuditMonthData,
  EntityAttritionAnalyticsItem,
  MonthData,
  TaskSupervisedLocationWiseTrend,
} from 'sub-components/nexus/NexusBase/types';
import {
  TrainingAnalytics,
  TrainingMonthData,
} from 'sub-components/nexus/NexusBase/types/api-types/training.types';
import { RenderLowest, RenderScoreCards } from '.';
import { ILocationPerformance } from '../types';
import Container from './Container';
import SingleLocationFooter from './SingleLocationFooter';
import ViewAll from './ViewAll';

interface BusinessLocation {
  name: string;
  isRoot?: boolean;
  eid: string;
  launchId?: string;
  locationStatus?: string;
}

// Helper function to calculate the audit score (0 to 100)
export const calculateAuditScore = (
  auditData: AuditMonthData | undefined
): number => {
  if (!auditData || auditData.maxScoreSum === 0) return 0;
  const score = (auditData.totalScoreSum / auditData.maxScoreSum) * 100;
  return Math.max(0, Math.min(score, 100)); // Ensure score is between 0 and 100
};

// Helper function to calculate the task score (0 to 100)
export const calculateTaskScore = (taskData: MonthData | undefined): number => {
  if (!taskData) return 0;
  // console.log({ complete: taskData.complete, inComplete: taskData.inComplete });
  // const incompleteRatio = (taskData.inComplete / totalTasks) * 100;
  const totalComplete = taskData?.complete || 0;
  const totalInComplete = taskData?.inComplete || 0;
  const totalTasks = totalComplete + totalInComplete;

  // if (totalTasks === 0) {
  //   return;
  // }

  const completionRate = (totalComplete / totalTasks) * 100 || 0;
  return Math.max(0, Math.min(completionRate, 100)); // Ensure score is between 0 and 100
};

// Helper function to calculate the task score (0 to 100)
export const calculateTrainingScore = (
  trainingData: TrainingMonthData | undefined
): number => {
  if (!trainingData) return 0;
  let numerator = trainingData?.totalScore + trainingData?.totalRatingsSum;
  let denominator =
    trainingData?.totalMaxScore + trainingData?.totalRatingsMaxSums;

  const completionRate = (numerator / denominator) * 100 || 0;
  return Math.max(0, Math.min(completionRate, 100)); // Ensure score is between 0 and 100
};

// Helper function to calculate the attrition score (0 to 100)
export const calculateAttritionScore = (
  attritionData: EntityAttritionAnalyticsItem[] | undefined,
  locationId: string
): number => {
  if (!attritionData) return 0;
  const locationAttrition = attritionData.find(
    (item) => item.eid === locationId
  );
  if (
    !locationAttrition ||
    typeof locationAttrition.attrition !== 'number' ||
    !locationAttrition?.hasMembers
  ) {
    return 0;
  }
  let score = locationAttrition.attrition;
  // return normalize(score, 0, 100);
  // console.log('SCORE : ', score);
  if (score < 0) score = 0;
  if (score > 100) score = 100;
  return 100 - score;
  // return score !== 0 ? 100 - score : score;
};

const OverallScore: FC = () => {
  const { businessLocations, auditsEnabled } = useUserEntity((entity) => ({
    businessLocations: (entity?.locations ||
      []) as unknown as BusinessLocation[],
    auditsEnabled: entity?.features?.audit,
  }));
  const { authRole: loggedInUserAuthRole, locations: loggedInUserLocations } =
    useUserDataSelector((state) => ({
      authRole: state?.authRole,
      locations: state?.locations,
    }));
  const { attrition, audit, task, training } = useNexusBoardContext(
    (state) => ({
      attrition: state?.attritionData,
      audit: state?.auditData,
      task: state?.taskData,
      training: state?.trainingData,
    })
  );

  // Helper function to get the current month as a string
  const getCurrentMonth = (): string => {
    const date = new Date();
    return (date.getMonth() + 1).toString(); // getMonth() is zero-based
  };

  // Main function to calculate overall scores
  const calculateOverallScores = (
    businessLocations: BusinessLocation[] | undefined,
    attrition: EntityAttritionAnalyticsItem[],
    audits: AuditLocationsAnalytics | undefined,
    tasks: TaskSupervisedLocationWiseTrend | undefined,
    training: TrainingAnalytics
  ): ILocationPerformance[] => {
    const currentMonth = getCurrentMonth();
    const result: ILocationPerformance[] = [];

    if (!businessLocations) return result;

    cloneDeep(businessLocations)
      ?.sort((a, b) => a.name.localeCompare(b.name))
      ?.filter((loc) => !loc?.isRoot)
      ?.forEach((location, index) => {
        const locationId = location.eid;
        const locationName = location.name;

        // Retrieve audit data for the current month
        const locationAuditData = audits
          ? audits[locationId]?.[currentMonth]
          : undefined;
        const auditScore = calculateAuditScore(locationAuditData);

        // Retrieve task data for the current month
        const locationTaskData = tasks
          ? tasks[locationId]?.[currentMonth]
          : undefined;
        const taskScore = calculateTaskScore(locationTaskData);

        // Retrieve training data for the current month
        const locationTrainingData = training
          ? training[locationId]?.[currentMonth]
          : undefined;
        const trainingScore = calculateTrainingScore(locationTrainingData);

        // Retrieve attrition data for the current month
        const attritionScore =
          location?.locationStatus !== 'preLaunch'
            ? calculateAttritionScore(attrition, locationId)
            : 0;

        // Determine if each module is used
        const isTaskUsed =
          (locationTaskData?.complete || 0) +
            (locationTaskData?.inComplete || 0) >
          0;
        const isAuditUsed =
          (locationAuditData?.maxScoreSum || 0) > 0 && auditsEnabled;
        const isTrainingUsed =
          (locationTrainingData?.totalRatingsMaxSums || 0) +
            (locationTrainingData?.totalMaxScore || 0) >
          0;

        // Calculate overall score based on used modules
        const scores = [];
        if (isTaskUsed) scores.push(taskScore);
        if (isAuditUsed) scores.push(auditScore);
        if (isTrainingUsed) scores.push(trainingScore);
        scores.push(attritionScore);

        // Check if any component score is NaN
        const overallScore =
          scores.length > 0
            ? parseFloat(
                (
                  scores.reduce((sum, score) => sum + score, 0) / scores.length
                ).toFixed(2)
              )
            : 0;

        // If overallScore is NaN for some reason, assign 0
        const finalOverallScore = isNaN(overallScore) ? 0 : overallScore;

        result.push({
          locationId,
          locationName,
          rate: finalOverallScore,
          rank: 0,
        });
      });

    return result;
  };

  const overall = useMemo((): {
    overallScore: ILocationPerformance[];
    topRanks: ILocationPerformance[];
    remainingRanks: ILocationPerformance[];
  } => {
    const scores = calculateOverallScores(
      businessLocations,
      attrition,
      audit,
      task,
      training
    );

    const sortedScores = scores.sort((a, b) => b.rate - a.rate);

    const rankedScores = sortedScores.map((overall, index) => ({
      ...overall,
      rank: index + 1,
    }));

    let topRanks = rankedScores?.slice(0, 3) || [];
    let remainingRanks = rankedScores?.slice(3) || [];

    return { overallScore: rankedScores, topRanks, remainingRanks };
  }, [businessLocations, attrition, audit, task, auditsEnabled]);

  return (
    <Container>
      <RenderScoreCards completeData={overall?.topRanks} type='overall' />
      <RenderLowest
        remainingLocations={overall?.remainingRanks}
        type='overall'
      />
      {overall?.overallScore?.length < 3 && <SingleLocationFooter />}
      <ViewAll data={overall?.overallScore} type='overall' />
    </Container>
  );
};

export default OverallScore;
