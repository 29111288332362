import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {
  Box,
  Flex,
  Text,
  VStack,
  Button,
  Divider,
  Badge,
  useTheme,
} from '@chakra-ui/react';
import { PieChart, Pie, Cell } from 'recharts';
import { RepeatIcon } from '@chakra-ui/icons';
import {
  IEvaluationSummaryProps,
  ISkillVerifierFormInput,
} from 'sub-components/training-v2/shared/types';
import { BoxHeader } from 'ui-components';
import ResponseFooter from '../../ResponseFooter';
import { useFormContext, useWatch } from 'react-hook-form';
import { ISkillVerifyInput } from 'sub-components/training-v2/create/components/training-input.types';
import { Progress } from 'antd';
import { useParams } from 'react-router-dom';
import { eventBus } from 'shared/eventEmit';

// Color constants
const AMAZING_COLOR = '#83BF6E';
const NEEDS_IMPROVEMENT_COLOR = '#FF6A55';
const BASE_GRAY = '#E2E8F0';

const EvaluationSummary: React.FC<IEvaluationSummaryProps> = ({
  quizScore,
  skillVerifierScore,
  maxScore,
  onReEvaluate,
  onSubmitHandler,
  isLoading,
  tpScheduleInfo,
  sessionId,
  userId,
  hasSkillVerifier,
  tp,
  onEvaluateComplete,
}) => {
  const { colors } = useTheme();
  const [totalScore, setTotalScore] = useState<number>(0);

  const methods = useFormContext<ISkillVerifierFormInput>();
  const skillsFields = useWatch({
    control: methods.control,
    name: 'skills',
  });

  // useEffect(() => {
  //   setTotalScore(quizScore + skillVerifierScore);
  // }, [quizScore, skillVerifierScore]);

  // const percentage = useMemo(() => {
  //   if(quizScore===0 || maxScore===0) return 0;
  //   if (quizScore !== 0 && maxScore !== 0) {
  //     return Math.min(Math.round((totalScore / maxScore) * 100), 100); //should not exceed 100 percentage
  //   }

  //   return (skillVerifierScore / (skillsFields.length * 10)) * 100;
  // }, [totalScore, maxScore]);

  const renderScoreBadge = () => (
    <Badge
      bg={scoreLabel() === 'Amazing!' ? AMAZING_COLOR : NEEDS_IMPROVEMENT_COLOR}
      color='white'
      px={3}
      py={1}
      borderRadius='6px'
      marginTop='8px'
    >
      {scoreLabel()}
    </Badge>
  );

  const avgSkillVerifierScore = () => {
    const average = skillVerifierScore / skillsFields?.length;
    return Number(average).toFixed(0);
  };

  function scaleTo10(score: number, maxScore: number) {
    return Number(((score / maxScore) * 10).toFixed(1));
  }

  const getTotalScore = () => {
    if (quizScore === 0) return avgSkillVerifierScore();
    if (!hasSkillVerifier) return scaleTo10(Number(quizScore), maxScore);
    // avg of quiz score and skill verifier score
    return Number(
      (scaleTo10(Number(quizScore), maxScore) +
        Number(avgSkillVerifierScore())) /
        2
    ).toFixed(0);
  };
  const totalScorePercentage = () => {
    if (maxScore === 0)
      return Number((skillVerifierScore / (skillsFields.length * 10)) * 100);
    else
      return (
        Number(
          (quizScore + skillVerifierScore) /
            (maxScore + 10 * skillsFields.length)
        ) * 100
      );
  };
  const scoreLabel = useCallback(() => {
    const totalScore = totalScorePercentage();
    if (totalScore >= 50) return 'Amazing!';
    if (totalScore >= 25) return 'Poor';
    return 'Reassign';
  }, [totalScorePercentage]);

  const emitRevaluateEvent = () => {
    setTimeout(() => eventBus.emit('revalute_assignee'), 500);
    onReEvaluate?.();
  };
  return (
    <>
      <Box
        bg='#FCFCFC'
        p={'20px 24px'}
        borderWidth={1}
        borderColor='gray.100'
        borderRadius='xl'
        boxShadow='sm'
      >
        <Text fontSize='18px' fontWeight={500}>
          Evaluation Summary
        </Text>

        <VStack
          spacing={2}
          align='stretch'
          mb={2}
          ml={1}
          mr={2}
          mt={6}
          gap={'12px'}
        >
          {/* Quiz Score Section */}
          {maxScore !== 0 && (
            <>
              <Flex justify='space-between' align='center'>
                <BoxHeader title='Quiz Score' color='#B1E5FC' fontSize='sm' />
                <Flex alignItems='center' fontSize='13px' gap='4px'>
                  {/* {scaleTo10(quizScore, maxScore)}{' '} */}
                  {quizScore}
                  <Box color='#6F767E'>/{maxScore}</Box>
                </Flex>
              </Flex>
              {/**Skill verifier score**/}

              <Flex justify='space-between' align='center'>
                <Flex direction='column'>
                  <BoxHeader
                    title='Skill Verifier Score'
                    color='#CABDFF'
                    fontSize='sm'
                  />
                  <Button
                    leftIcon={<RepeatIcon />}
                    size='sm'
                    variant='ghost'
                    colorScheme='blue'
                    onClick={emitRevaluateEvent}
                    mt={2}
                  >
                    Re-evaluate
                  </Button>
                </Flex>
                <Flex alignItems='center' fontSize='13px' gap='4px'>
                  {skillVerifierScore}
                  <Box color='#6F767E'>/{10 * skillsFields?.length}</Box>
                </Flex>
              </Flex>

              <Divider borderColor='gray.200' />
            </>
          )}

          {/* Total Score Section */}
          <Flex justify='space-between' align='center' pt={4}>
            <Flex direction={'column'}>
              {maxScore === 0 ? (
                <BoxHeader
                  title='Total Training Path Score'
                  color='#B1E5FC'
                  fontSize='sm'
                />
              ) : (
                <Text fontWeight={500} lineHeight={6} color={'#33383F'}>
                  Total Training Path Score
                </Text>
              )}
              {maxScore === 0 && (
                <Button
                  leftIcon={<RepeatIcon />}
                  size='sm'
                  variant='ghost'
                  colorScheme='blue'
                  onClick={emitRevaluateEvent}
                  mt={2}
                >
                  Re-evaluate
                </Button>
              )}
            </Flex>

            <Flex alignItems='center' gap='2px'>
              <Text
                as='span'
                fontSize='20px'
                fontWeight='bold'
                color={colors.blue[500]}
              >
                {quizScore + skillVerifierScore}
              </Text>
              <Box fontSize='13px' fontWeight={500} color='#6F767E'>
                /{maxScore + 10 * skillsFields.length}
              </Box>
            </Flex>
          </Flex>
        </VStack>
      </Box>
      {/* Visualization Section */}
      <Flex direction='column' align='center' mt={6} justify={'center'}>
        <Progress
          type='circle'
          percent={Number(totalScorePercentage()).toFixed(0)}
          format={(percent) => (
            <Text fontSize={'20px'} color={'#33383F'} fontWeight={500}>
              {percent}%
            </Text>
          )}
          size={90}
          strokeColor={totalScorePercentage() >= 50 ? '#83BF6E' : '#FF6A55'}
          trailColor='#EFEFEF'
          strokeWidth={10}
        />
        {renderScoreBadge()}
      </Flex>

      <ResponseFooter
        mode={scoreLabel() === 'Amazing!' ? 'goodScore' : 'badScore'}
        usedAt={'summary'}
        onSubmitHandler={onSubmitHandler}
        isLoading={isLoading}
        tpScheduleInfo={tpScheduleInfo}
        sessionId={sessionId}
        userId={userId}
        tp={tp}
        onEvaluateComplete={onEvaluateComplete}
      />
    </>
  );
};

export default EvaluationSummary;
