import React, { FC, useEffect } from 'react';
import { Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import useCombinedStore from 'zustandStore/store';
import { Container, Heading } from './components';

const TrainingDashboard: FC = () => {
  const { t } = useTranslation(['training']);
  return (
    <Flex flexDir='column' gap={4}>
      <Heading />
      <Container />
    </Flex>
  );
};

TrainingDashboard.displayName =
  'displayName:sub-components/training-v2/dashboard/TrainingDashboard';

export default TrainingDashboard;
