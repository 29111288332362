import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import {
  Flex,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from '@chakra-ui/react';
import { useWatch } from 'react-hook-form';

import { useTranslation } from 'react-i18next';

import { BoxHeader } from '../../../../../ui-components';

import { MemberInput } from '../create-channel.types';

import SelectGroupButton from './SelectGroupButton';
import SelectGroupContent from './SelectGroupContent';
import SelectedTags from './SelectedTags';

interface IProps {}

const SelectGroup: FC<IProps> = () => {
  const { t } = useTranslation(['setting']);
  const [width, setWidth] = useState<string>();
  const initRef = useRef<HTMLButtonElement>(null);
  const autoAddMember = useWatch<MemberInput, 'autoAddMember'>({
    name: 'autoAddMember',
  });

  const resize = useMemo(() => {
    return new ResizeObserver(function (entries) {
      let rect = entries[0]?.borderBoxSize?.[0];

      if (rect?.inlineSize) {
        setWidth(rect.inlineSize + 'px');
      }
    });
  }, []);

  useEffect(() => {
    const elm = document.querySelector('#popover-trigger-select-group');
    if (elm) {
      resize.observe(elm);
    }

    return () => {
      if (elm) {
        resize.unobserve(elm);
      }
    };
  }, [autoAddMember]);

  if (!autoAddMember) {
    return null;
  }

  return (
    <>
      <Popover
        isLazy
        lazyBehavior='unmount'
        id='select-group'
        closeOnBlur={false}
        placement='bottom'
        autoFocus
        modifiers={[
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['bottom'],
            },
          },
        ]}
      >
        {({ onClose }) => (
          <>
            {/*// @ts-ignore  */}
            <PopoverTrigger>
              <SelectGroupButton
                // TODO: don't remove this id, this id is needed for calculating the width of containers
                id='select-group-box-width'
              >
                {t('setting:selectGroups')}
              </SelectGroupButton>
            </PopoverTrigger>
            <PopoverContent width={width} py={3} px={2}>
              <PopoverHeader borderBottom='none'>
                <Flex justify='space-between' align='center'>
                  <BoxHeader
                    title={t('setting:selectMembersGroup')}
                    fontSize='18px'
                    color='#CABDFF'
                  />
                  {/* <PopoverCloseButton pos='relative' top='0' right='0' /> */}
                </Flex>
              </PopoverHeader>
              <PopoverBody>
                <SelectGroupContent onClose={onClose} initRef={initRef} />
              </PopoverBody>
            </PopoverContent>
          </>
        )}
      </Popover>
      <SelectedTags />
    </>
  );
};

export default SelectGroup;
