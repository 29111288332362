import { useMutation } from '@apollo/client';
import { Box, Flex, useToast } from '@chakra-ui/react';
import React, { FC } from 'react';
import { Button } from '../../../../atoms';
import { DELETE_TRAINING_PATH, TERMINATE_TRAINING_PATH } from '../api';
import { MARK_TP_ACTIVE } from '../query/dashboard.graphql';

interface IProps {
  trainingId: string;
  sessionId: string;
  clickedItem?: string;
  type?: string;
  closeModal?: () => void;
  cb?: () => void;
}

const ModalContent: FC<IProps> = ({
  sessionId,
  trainingId,
  clickedItem,
  type,
  closeModal,
  cb,
}) => {
  const toast = useToast({
    duration: 3000,
    isClosable: true,
    position: 'top-right',
  });
  const [deleteTp, { loading: deletingTp }] = useMutation<
    { DeleteTP: { succeed: boolean } },
    { eid: string }
  >(DELETE_TRAINING_PATH);
  const [terminateTp, { loading: terminatingTp }] = useMutation<
    { TerminateTP: { succeed: boolean } },
    { eid: string }
  >(TERMINATE_TRAINING_PATH);
  const [markTPActive, { loading: makingTpActive }] =
    useMutation(MARK_TP_ACTIVE);

  const onDeleteClick = () => {
    deleteTp({
      variables: {
        eid: trainingId,
      },
    })
      ?.then((res) => {
        toast({
          status: 'success',
          title: 'Success',
          description: 'Training path deleted successfully',
        });
      })
      ?.catch((err) => {
        toast({
          status: 'error',
          title: 'Error',
          description: 'Training path could not be deleted',
        });
      })
      ?.finally(() => {
        cb?.();
      });
  };

  const onTerminateClick = () => {
    terminateTp({
      variables: {
        eid: trainingId,
      },
    })
      ?.then((res) => {
        toast({
          status: 'success',
          title: 'Success',
          description: 'Training path terminated successfully',
        });
      })
      ?.catch((err) => {
        toast({
          status: 'error',
          title: 'Error',
          description: 'Training path could not be terminated',
        });
      })
      ?.finally(() => {
        cb?.();
      });
  };

  const onMakeActiveClick = () => {
    markTPActive({
      variables: {
        eid: trainingId,
      },
    })
      ?.then((res) => {
        toast({
          status: 'success',
          title: 'Success',
          description: 'Training path marked active successfully',
        });
      })
      ?.catch((err) => {
        toast({
          status: 'error',
          title: 'Error',
          description: 'Training path could not be marked active',
        });
      })
      ?.finally(() => {
        cb?.();
      });
  };

  if (type === 'TRAINING_TERMINATED' && clickedItem === 'delete') {
    return (
      <Flex flexDir='column' gap={4}>
        <Box
          as='span'
          fontWeight={500}
          fontSize='15px'
          color='rgba(111, 118, 126, 1)'
        >
          Deleting this path will erase all progress made by assignees. Are you
          sure you want to delete this training path permanently ?
        </Box>
        <Flex w='full' align='center' gap={4}>
          <Button
            w='50%'
            size='lg'
            variant='outline'
            bg='white'
            border='2px solid rgba(239, 239, 239, 1)'
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            w='50%'
            size='lg'
            variant='solid'
            colorScheme='red'
            disabled={deletingTp || terminatingTp}
            isLoading={deletingTp}
            onClick={onDeleteClick}
          >
            Delete
          </Button>
        </Flex>
      </Flex>
    );
  }

  if (type === 'TRAINING_TERMINATED' && clickedItem === 'active') {
    return (
      <Flex flexDir='column' gap={4}>
        <Box
          as='span'
          fontWeight={500}
          fontSize='15px'
          color='rgba(111, 118, 126, 1)'
        >
          Are you sure to mark this training path as active ?
        </Box>
        <Flex w='full' align='center' gap={4}>
          <Button
            w='50%'
            size='lg'
            variant='outline'
            bg='white'
            border='2px solid rgba(239, 239, 239, 1)'
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            w='50%'
            size='lg'
            variant='solid'
            colorScheme='blue'
            disabled={makingTpActive}
            isLoading={makingTpActive}
            onClick={onMakeActiveClick}
          >
            Mark as active
          </Button>
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex flexDir='column' gap={4}>
      <Box
        as='span'
        fontWeight={500}
        fontSize='15px'
        color='rgba(111, 118, 126, 1)'
      >
        Deleting this path will erase all progress made by assignees. If you
        want to keep the progress but deactivate the path, consider marking it
        as inactive instead.
      </Box>
      <Flex w='full' align='center' gap={4}>
        <Button
          w='50%'
          size='lg'
          variant='outline'
          bg='white'
          border='2px solid rgba(239, 239, 239, 1)'
          disabled={deletingTp || terminatingTp}
          isLoading={terminatingTp}
          onClick={onTerminateClick}
        >
          Mark as inactive
        </Button>
        <Button
          w='50%'
          size='lg'
          variant='solid'
          colorScheme='red'
          disabled={deletingTp || terminatingTp}
          isLoading={deletingTp}
          onClick={onDeleteClick}
        >
          Delete
        </Button>
      </Flex>
    </Flex>
  );
};

export default ModalContent;
