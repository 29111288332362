import React, { FC } from 'react';
import {
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  Tooltip,
} from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Controller, useWatch } from 'react-hook-form';
import { FlexCard } from '../../../../../../../atoms';
import { TFormInput } from '../../../training-input.types';
import MinScoreSelect from './MinScoreSelect';

interface IProps {
  disabled?: boolean;
  trainingId?: string;
}

const SetMinimumScore: FC<IProps> = ({ disabled, trainingId }) => {
  const trainingName = useWatch<TFormInput, 'title'>({ name: 'title' });

  const hasMinScore = useWatch<TFormInput, 'dependantTraining.hasMinScore'>({
    name: 'dependantTraining.hasMinScore',
  });

  if (!trainingId) {
    return null;
  }

  return (
    <FlexCard
      flexDir='column'
      bg='#F4F4F4'
      border='1px solid #EFEFEF'
      p='12px 20px'
      gap={3}
    >
      <Flex justify='space-between'>
        <Flex
          gap={2}
          fontSize='12px'
          fontWeight='500'
          color='#6F767E'
          align='center'
        >
          <span>Set minimum score</span>
          <Tooltip
            paddingX='8px'
            paddingY='6px'
            borderRadius='4px'
            placement='bottom'
            label={`Require users to achieve a minimum percentage score in the selected path to unlock ‘${trainingName}’`}
          >
            <InfoOutlineIcon />
          </Tooltip>
        </Flex>

        <Controller<TFormInput, 'dependantTraining.hasMinScore'>
          name='dependantTraining.hasMinScore'
          defaultValue={false}
          disabled={disabled}
          render={({ field }) => (
            <Checkbox
              size='lg'
              bg='#FCFCFC'
              isChecked={field.value}
              isDisabled={field.disabled}
              onChange={field.onChange}
              ref={field.ref}
            />
          )}
        />
      </Flex>

      {hasMinScore ? (
        <Controller<TFormInput, 'dependantTraining.minScorePercent'>
          name='dependantTraining.minScorePercent'
          disabled={disabled}
          rules={{
            required: 'Please enter minimum score',
            validate: (value) => {
              if (!value) return 'Please enter minimum score';
            },
          }}
          render={({ field, fieldState }) => (
            <FormControl isInvalid={fieldState.invalid}>
              <MinScoreSelect {...field} />
              <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
            </FormControl>
          )}
        />
      ) : null}
    </FlexCard>
  );
};

export default SetMinimumScore;
