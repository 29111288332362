import {
  Badge,
  Box,
  Button,
  Flex,
  Image,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowDownShortWide,
  faArrowDownToLine,
} from '@fortawesome/pro-light-svg-icons';
import {
  faClock,
  faPlay,
  faRotateRight,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ConfigProvider, Table } from 'antd';
import {
  TRAINING_PATH_V2_PLAY,
  TRAINING_PATH_V2_PLAY_RETAKE,
  TRAINING_PATH_V2_PLAY_VIEW,
} from 'appRoutes';
import React, { FC } from 'react';
import { useHistory } from 'routes';
import Loader from 'sub-components/Loader';
import { useService } from 'sub-components/training-v2/play/layers';
import { PageInfo } from 'types';
import { getImage } from 'utils';
import { useMyTrainingContext } from '../../MyTrainingContext';
import { getCategoriesColorCode } from '../../TrackPathDashboard/bottomSection/helper';
import { CustomProgressBar } from '../../TrackPathDashboard/components';
import EmptyState from '../EmptyState';
import {
  TrainingData,
  UserTrainingPathSessionsType,
} from '../types/myTraining.types';

interface IProps {
  hideSorting?: boolean;
  trainingPathData: TrainingData[] | UserTrainingPathSessionsType[];
  trainingCategory: any;
  loader: boolean;
  pageInfo: PageInfo;
  backToLabel?: string;
  mytarainingMode?: boolean;
}

export const colorSchemes = {
  overdue: {
    bg: '#FFBC9933',
    color: '#FF6A55',
  },
  yettostart: {
    bg: '#2A85FF1A',
    color: '#2A85FF',
  },
  inprogress: {
    bg: '#FFD88D1A',
    color: '#FECE70',
  },
  completed: {
    bg: '#83BF6E33',
    color: '#83BF6E',
  },
};

const MyTrainingTable: FC<IProps> = ({
  hideSorting = false,
  trainingPathData,
  loader,
  trainingCategory,
  pageInfo,
  backToLabel = 'Back to Training',
  mytarainingMode = false,
}) => {
  const {
    searchQuery,
    setSelectedPageIndex,
    selectedPageIndex,
    getTrainingPathSessionData,
    trainingPathFilters,
    sortType,
    setSortType,
  } = useMyTrainingContext();
  const {
    downloadCertificateFlag: { updateDownloadCertificateFlag },
  } = useService();

  const history = useHistory();

  const mapButtonIcons = {
    play: (<FontAwesomeIcon icon={faPlay as IconProp} />) as React.ReactNode,
    resume: (<FontAwesomeIcon icon={faClock as IconProp} />) as React.ReactNode,
    retake: (
      <FontAwesomeIcon icon={faRotateRight as IconProp} />
    ) as React.ReactNode,
  };

  const categoriesColorCode = getCategoriesColorCode(trainingCategory);

  const downloadCertificate = (
    certificateId?: string,
    trainingId?: string,
    sessionId?: string
  ) => {
    if (process.env.REACT_APP_OPS_ENDPOINT) {
      if (trainingId && sessionId && certificateId) {
        updateDownloadCertificateFlag({
          variables: {
            sessionId: sessionId,
          },
        });
      }
      window.open(
        `${process.env.REACT_APP_OPS_ENDPOINT}/tp/certify/${certificateId}`,
        '_blank'
      );
    }
  };

  const actionBtnRender = (record: TrainingData) => {
    const {
      status,
      hasSkillVerifier,
      isEvaluated,
      evaluationViewed,
      trainingId,
      key,
    } = record;
    const iconType = status?.iconType;
    const iconTitle = status?.iconTitle;

    const commonButtonProps = {
      minW: '120px',
      size: 'sm',
      cursor:
        record?.tpTerminatedStatus === 'TRAINING_TERMINATED'
          ? 'not-allowed'
          : 'pointer',
      disabled: record?.tpTerminatedStatus === 'TRAINING_TERMINATED',
    };

    const playButtonProps = {
      ...commonButtonProps,
      leftIcon: <FontAwesomeIcon icon={faPlay as IconProp} />,
      onClick: () => {
        history.push({
          pathname: TRAINING_PATH_V2_PLAY,
          params: {
            trainingId,
            sessionId: key,
          },
          state: {
            backToTitle: backToLabel,
          },
        });
      },
    };

    const resumeButtonProps = {
      ...commonButtonProps,
      leftIcon: <FontAwesomeIcon icon={faClock as IconProp} />,
      onClick: () => {
        history.push({
          pathname: TRAINING_PATH_V2_PLAY,
          params: {
            trainingId,
            sessionId: key,
          },
          state: {
            backToTitle: backToLabel,
          },
        });
      },
    };

    const retakeButtonProps = {
      ...commonButtonProps,
      leftIcon: <FontAwesomeIcon icon={faRotateRight as IconProp} />,
      onClick: () => {
        history.push({
          pathname: TRAINING_PATH_V2_PLAY_RETAKE,
          params: {
            trainingId,
            sessionId: key,
          },
          state: {
            backToTitle: backToLabel,
          },
        });
      },
    };

    const viewResultButtonProps = {
      ...commonButtonProps,
      onClick: () => {
        history.push({
          pathname: TRAINING_PATH_V2_PLAY_VIEW,
          params: {
            trainingId,
            sessionId: key,
          },
          state: {
            backToTitle: backToLabel,
          },
        });
      },
    };

    if (['play']?.includes(iconType)) {
      return <Button {...playButtonProps}>Play</Button>;
    }

    if (['resume']?.includes(iconType)) {
      return <Button {...resumeButtonProps}>Resume</Button>;
    }

    if (iconType === 'retake') {
      if (hasSkillVerifier) {
        if (isEvaluated && evaluationViewed) {
          return <Button {...retakeButtonProps}>Retake</Button>;
        } else if (isEvaluated && !evaluationViewed) {
          return <Button {...viewResultButtonProps}>View result</Button>;
        } else if (!isEvaluated && evaluationViewed) {
          return <Button {...playButtonProps}>Play</Button>;
        } else if (!isEvaluated && !evaluationViewed) {
          return <Button {...viewResultButtonProps}>View result</Button>;
        }
      } else {
        return <Button {...retakeButtonProps}>Retake</Button>;
      }
    }
    return (
      <Button
        {...commonButtonProps}
        leftIcon={mapButtonIcons[iconType]}
        onClick={() => {
          history.push({
            pathname: TRAINING_PATH_V2_PLAY,
            params: {
              trainingId,
              sessionId: key,
            },
            state: {
              backToTitle: backToLabel,
            },
          });
        }}
      >
        {iconTitle}
      </Button>
    );
  };

  const getCellStyles = (record: TrainingData) => {
    if (record?.tpTerminatedStatus === 'TRAINING_TERMINATED') {
      return {
        cursor: 'not-allowed',
        opacity: '0.5',
      };
    } else {
      return {};
    }
  };

  const canDownloadCertificate = (record: TrainingData) => {
    if (record?.status?.title === 'Completed' && !record?.hasSkillVerifier) {
      return true;
    } else if (
      record?.status?.title === 'Completed' &&
      record?.hasSkillVerifier &&
      record?.isEvaluated
    ) {
      return true;
    }
    return false;
  };

  const columns = [
    {
      title: (
        <Flex alignItems={'center'} gap={2}>
          <Text fontWeight={500} color={'#6F767E'}>
            Path Name
          </Text>
          {!hideSorting && (
            <Tooltip label={sortType?.tooltipLabel}>
              <Box
                onClick={() => {
                  setSortType((prevState) => {
                    if (prevState?.sort === 'CREATEDAT_DESC') {
                      return {
                        sort: 'TITLE_ASC',
                        order: 'asc',
                        tooltipLabel: 'sort desc',
                      };
                    } else if (prevState?.sort === 'TITLE_ASC') {
                      return {
                        sort: 'TITLE_DESC',
                        order: 'desc',
                        tooltipLabel: 'no sort',
                      };
                    } else if (prevState?.sort === 'TITLE_DESC') {
                      return {
                        sort: 'CREATEDAT_DESC',
                        order: 'none',
                        tooltipLabel: 'sort asc',
                      };
                    }
                  });
                }}
              >
                <FontAwesomeIcon
                  color={sortType?.order === 'none' ? '#6F767E' : '#2A85FF'}
                  cursor={'pointer'}
                  icon={faArrowDownShortWide as IconProp}
                />
              </Box>
            </Tooltip>
          )}
        </Flex>
      ),
      dataIndex: 'pathName',
      key: 'pathName',
      render: (text: string, record: TrainingData) => {
        return (
          <Flex style={getCellStyles(record)} align='center' gap={3}>
            <Image
              borderRadius='8px'
              height='62px'
              width='62px'
              src={getImage(record?.thumbnail, text)}
            />

            <Flex direction={'column'} gap={0}>
              <Tooltip label={text}>
                <Box
                  as={'span'}
                  isTruncated
                  maxW='150px'
                  fontSize='15px'
                  fontWeight='600'
                >
                  {text}
                </Box>
              </Tooltip>
              <Text fontWeight='500' fontSize='12px' color='#6F767E'>
                Last Updated: {record?.lastUpdated}
              </Text>
              {record?.categoryName && (
                <Tooltip label={record?.categoryName}>
                  <Badge
                    variant='subtle'
                    borderRadius='5px'
                    fontSize='12px'
                    fontWeight={500}
                    textTransform='capitalize'
                    w='fit-content'
                    display='flex'
                    bg={categoriesColorCode?.[record?.categoryId]?.bg}
                    color={categoriesColorCode?.[record?.categoryId]?.color}
                    isTruncated
                    maxW={'150px'}
                  >
                    {record?.categoryName}
                  </Badge>
                </Tooltip>
              )}
            </Flex>
          </Flex>
        );
      },
    },
    {
      title: (
        <Text fontWeight={500} color={'#6F767E'}>
          Progress
        </Text>
      ),
      dataIndex: 'progress',
      key: 'progress',
      render: (_, record) => {
        const isDemoGreator =
          record?.progress?.total >= record?.progress?.current;
        return isDemoGreator ? (
          <Flex
            style={getCellStyles(record)}
            alignItems={'center'}
            gap={2}
            flexDir={mytarainingMode ? 'column' : 'row'}
          >
            <Flex
              minW={'70px'}
              borderRadius={'6px'}
              p={'2px 10px'}
              alignItems={'center'}
              backgroundColor={'#EFEFEF'}
              flexDir={mytarainingMode ? 'column' : 'row'}
            >
              <Flex width={'100%'} justifyContent={'center'}>
                <Text fontWeight={600}>{record?.progress?.current}/</Text>
                <Text>{record?.progress?.total}</Text>
              </Flex>
            </Flex>
            <CustomProgressBar
              width={mytarainingMode ? '65px' : '44px'}
              percent={
                (record?.progress?.current / record?.progress?.total) * 100
              }
            />
          </Flex>
        ) : (
          '-'
        );
      },
    },
    {
      title: (
        <Text fontWeight={500} color={'#6F767E'}>
          Status
        </Text>
      ),
      dataIndex: 'status',
      key: 'status',
      render: (status: { title: string; dueDate: string }, record) => {
        const statusKey = status?.title.toLowerCase().replaceAll(' ', '');

        return (
          <>
            {statusKey ? (
              <Flex
                style={getCellStyles(record)}
                gap={2}
                alignItems={'center'}
                flexDir={mytarainingMode ? 'column' : 'row'}
              >
                <Badge
                  borderRadius={'4px'}
                  p={'4px 8px'}
                  backgroundColor={colorSchemes[statusKey]?.bg}
                  color={colorSchemes[statusKey]?.color}
                  fontWeight={700}
                  textTransform={'capitalize'}
                  fontSize={'12px'}
                >
                  {status?.title}
                </Badge>

                <Box fontWeight={500} fontSize={'12px'}>
                  {status?.dueDate && status?.dueDate !== '-' ? (
                    <>
                      {mytarainingMode ? '' : <>&bull;</>} {status?.dueDate}
                    </>
                  ) : (
                    '-'
                  )}
                </Box>
              </Flex>
            ) : (
              '-'
            )}
          </>
        );
      },
    },
    {
      title: (
        <Text fontWeight={500} color={'#6F767E'}>
          Score
        </Text>
      ),
      dataIndex: 'score',
      key: 'score',
      render: (score: number, record: TrainingData) => {
        const validScore = score !== undefined && score !== null ? score : 0;
        const formattedScore =
          validScore % 1 === 0 ? validScore.toString() : validScore.toFixed(2);
        const isDemoGreater =
          record?.maxScore !== undefined &&
          record?.maxScore >= Number(formattedScore);
        return (
          <>
            {isDemoGreater ? (
              <Flex
                width={'fit-content'}
                borderRadius={'6px'}
                p={'2px 10px'}
                alignItems={'center'}
                backgroundColor={'#EFEFEF'}
                style={getCellStyles(record)}
              >
                <Text fontSize={'14px'} fontWeight={500} color={'#33383F'}>
                  {`${formattedScore}/${record?.maxScore}`}
                </Text>
              </Flex>
            ) : (
              '-'
            )}
          </>
        );
      },
    },
    {
      title: (
        <Text fontWeight={500} color={'#6F767E'}>
          Action
        </Text>
      ),
      key: 'action',
      render: (_: any, record: TrainingData) => {
        return (
          <Flex style={{ cursor: 'not-allowed' }} gap={2}>
            <Flex
              gap={4}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              {actionBtnRender(record)}
              <Tooltip
                label={
                  canDownloadCertificate(record)
                    ? 'Download certificate'
                    : 'You can download your certificate once you complete this training. In case if evaluation is required, please wait until evaluation is done.'
                }
              >
                <Box>
                  <FontAwesomeIcon
                    cursor={'pointer'}
                    icon={faArrowDownToLine as IconProp}
                    style={{
                      color: canDownloadCertificate(record)
                        ? '#2A85FF'
                        : '#6F767E',
                      fontSize: '1.2rem',
                    }}
                    onClick={() =>
                      canDownloadCertificate(record) &&
                      downloadCertificate(
                        record?.certificate,
                        record?.trainingId,
                        record?.key
                      )
                    }
                  />
                </Box>
              </Tooltip>
            </Flex>
          </Flex>
        );
      },
    },
  ];

  return (
    <Flex width={'100%'}>
      <ConfigProvider
        renderEmpty={() => <EmptyState searchQuery={searchQuery} />}
      >
        <Table<TrainingData>
          style={{ width: '100%' }}
          loading={loader && { indicator: <Loader /> }}
          columns={columns}
          dataSource={trainingPathData}
          pagination={{
            total: pageInfo?.itemCount,
            hideOnSinglePage: true,
            defaultPageSize: 10,
            showSizeChanger: false,
            current: pageInfo?.currentPage,
            onChange: (page) => {
              // onPageChange(page);
              setSelectedPageIndex(page);
            },
          }}
        />
      </ConfigProvider>
    </Flex>
  );
};

MyTrainingTable.displayName =
  'displayName:sub-components/training-v2/dashboard/components/myTrainings/bottomSection/MyTrainingTable';

export default MyTrainingTable;
