import { useUserEntity } from 'hooks';
import { useEffect, useMemo } from 'react';
import { useNexusBoardContext } from 'sub-components/nexus/NexusBase/store/context';
import { useGetLocationIds } from 'sub-components/nexus/NexusBase/utils';
import { RatingData } from '../types';
import { useService } from './useService';

export const useControl = () => {
  const updateLocationRating = useNexusBoardContext(
    (state) => state.updateLocationRatingData
  );
  const locationIds = useGetLocationIds(true);
  const locations = useUserEntity((entity) => entity?.locations);
  const {
    locationRating: { locationRating, locationRatingRes },
  } = useService();

  useEffect(() => {
    if (locationIds?.length > 0) {
      locationRating({
        variables: {
          page: 1,
          perPage: locationIds?.length,
          filter: {
            locationIds: locationIds,
          },
        },
      });
    }
  }, [locationIds]);

  useEffect(() => {
    updateLocationRating(
      locationRatingRes?.data?.LocationMetaPagination?.items || []
    );
  }, [locationRatingRes?.data]);

  const locationFind = useMemo(() => {
    return (locId: string) => {
      return locations?.find((loc) => loc?.eid === locId);
    };
  }, [locations]);

  const graphData = useMemo<RatingData[]>(() => {
    const data = locationRatingRes?.data;
    if (!data || data?.LocationMetaPagination?.items?.length === 0) {
      return [];
    }

    let res = data?.LocationMetaPagination?.items?.map((item) => ({
      location: locationFind(item?.locationId)?.name || 'Unknown location',
      googleRating: item?.google?.googleRating,
      googleMapsUri: item?.google?.googleMapsUri,
    }));
    return res?.sort((a, b) => b.googleRating - a.googleRating);
    // return [
    //   { location: "New York", googleRating: 4.5 },
    //   { location: "Los Angeles", googleRating: 4.2 },
    //   { location: "Chicago", googleRating: 3.9 },
    //   { location: "Houston", googleRating: 4.0 },
    //   { location: "Phoenix", googleRating: 4.1 },
    //   { location: "Philadelphia", googleRating: 3.8 },
    //   { location: "San Antonio", googleRating: 4.3 },
    //   { location: "San Diego", googleRating: 4.4 },
    //   { location: "Dallas", googleRating: 4.0 },
    //   { location: "San Jose", googleRating: 4.6 },
    // ]?.sort((a, b) => b.googleRating - a.googleRating);
  }, [locationRatingRes?.data, locationFind]);

  return { graphData };
};
