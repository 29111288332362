import { useLazyQuery } from '@apollo/client';
import { Box, Center, Flex, Skeleton } from '@chakra-ui/react';
import { AuthRole } from 'authorization';
import { useUserDataSelector } from 'hooks';
import React, { FC, useEffect, useMemo } from 'react';
import { ITrackingTPPagination } from 'sub-components/training-v2/dashboard/components/TrackPathDashboard/bottomSection/PathTable';
import { GET_TRACKING_TP_DATA } from 'sub-components/training-v2/shared/query/dashboard.graphql';
import { TPFiltersParams } from 'sub-components/training-v2/shared/types';
import { getImage } from 'utils';
import { Image } from 'ui-components';
import { HeaderColors } from 'configs';
import { AllClear } from 'sub-components/nexus/NexusBase/components/common';
import { Button } from 'atoms';
import { useHistory } from 'routes';
import { TRAINING_PATH_V2_DASHBOARD, TRAINING_PATH_V2_PATHS } from 'appRoutes';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { TrainingPathContainerTabs } from 'sub-components/training-v2/dashboard/components/Container';

const NexusTraining: FC = () => {
  const history = useHistory();
  const { loggedInUserAuthRole } = useUserDataSelector((state) => ({
    loggedInUserAuthRole: state?.authRole,
  }));
  const [
    getTrackingTpPaginationData,
    { loading: trackingTpPaginationLoader, data },
  ] = useLazyQuery<
    { TpPagination: { count: number; items: any[] } },
    {
      page: number;
      perPage: number;
      filter?: {
        query?: string;
        category?: string[];
        createdBy?: string;
        status: string[];
        supervisor?: boolean;
        creator?: boolean;
        locationIds?: string[];
      };
    }
  >(GET_TRACKING_TP_DATA, {
    fetchPolicy: 'network-only',
  });

  const getTrackingTPFilters = ({
    sort = 'CREATEDAT_DESC',
  }: TPFiltersParams) => {
    let filter: {
      query?: string;
      category?: string[];
      createdBy?: string;
      status: string[];
      supervisor?: boolean;
      creator?: boolean;
      locationIds?: string[];
    } = {
      query: '',
      status: [
        'TRAINING_CREATED',
        'TRAINING_STARTED',
        'TRAINING_REPEAT',
        'TRAINING_COMPLETED',
        'TRAINING_ONE_TIME',
      ],
      locationIds: [],
    };

    // if (loggedInUserAuthRole !== AuthRole?.SUPER_ADMIN) {
    //   filter.supervisor = true;
    //   filter.creator = true;
    // } else {
    //   filter.supervisor = false;
    //   filter.creator = false;
    // }
    // filter.supervisor = true;
    // filter.creator = false;

    return {
      filter: filter,
      sort: sort,
      page: 1,
      perPage: 3,
    };
  };

  useEffect(() => {
    getTrackingTpPaginationData({
      variables: getTrackingTPFilters({}),
    });
  }, []);

  const tpData = useMemo(() => {
    console.log('data : ', data);
    return (data?.TpPagination?.items || [])?.map((item) => {
      return {
        trainingId: item?.eid,
        eid: item?.lastSession?.eid,
        icon: getImage(item?.thumbnail, item?.title),
        title: item?.title,
        completed: item?.lastSession?.completedCount,
        total: item?.lastSession?.assignedCount,
      };
    });
  }, [data]);

  if (trackingTpPaginationLoader) {
    return (
      <Flex flexDir='column' gap={4}>
        <Skeleton h='20px' w='100%' />
        <Skeleton h='20px' w='100%' />
        <Skeleton h='20px' w='100%' />
        <Skeleton h='20px' w='100%' />
        <Skeleton h='20px' w='100%' />
        <Skeleton h='20px' w='100%' />
        <Skeleton h='20px' w='100%' />
        <Skeleton h='20px' w='100%' />
        <Skeleton h='20px' w='100%' />
        <Skeleton h='20px' w='100%' />
        <Skeleton h='20px' w='100%' />
      </Flex>
    );
  }

  if (tpData?.length === 0 && !trackingTpPaginationLoader) {
    return (
      <Center h='30vh'>
        <AllClear desc='No trainings to supervise' />
      </Center>
    );
  }

  return (
    <Flex flexDir='column' gap={4}>
      {tpData?.map((data) => (
        <Flex
          key={data?.eid}
          gap={2}
          borderRadius='12px'
          bg='#fcfcfc'
          border='1px solid #efefef'
          p='12px'
          cursor='pointer'
          onClick={() => {
            history.push({
              pathname: TRAINING_PATH_V2_PATHS,
              params: {
                trainingId: data?.trainingId,
                sessionId: data?.eid,
              },
              state: {
                backToTitle: 'Back to Dashboard',
              },
            });
          }}
        >
          <Image
            src={data?.icon}
            width={50}
            height={50}
            style={{
              borderRadius: '12px',
            }}
          />
          <Flex flexDir='column' gap={2}>
            <Flex>{data?.title}</Flex>
            <Flex>
              <Box color={HeaderColors.Red.Mid} fontWeight={600}>
                {data?.total - data?.completed}
              </Box>
              &nbsp;
              <Box color='#6f767e' fontWeight={600}>
                /&nbsp;
                {data?.total} overdue
              </Box>
            </Flex>
          </Flex>
        </Flex>
      ))}
      <Button
        variant='outline'
        size='lg'
        onClick={() =>
          history.push({
            pathname: TRAINING_PATH_V2_DASHBOARD,
            state: {
              tabSelected: 'tracking' as TrainingPathContainerTabs,
            },
          })
        }
        rightIcon={<ArrowForwardIcon fontSize='20px' />}
      >
        View all
      </Button>
    </Flex>
  );
};

export default NexusTraining;
