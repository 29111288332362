import React, { forwardRef } from 'react';
import { Flex, useFormControl } from '@chakra-ui/react';
import moment from 'moment';
import { Button } from '../../../../../../../atoms';

interface IProps {
  value: number;
  onChange: (value: number) => void;
}

const WeekDaySelectInput = forwardRef<never, IProps>(
  ({ value, onChange, children, ...rest }, ref) => {
    const weekdays = moment.weekdaysShort();
    const controlProps = useFormControl(rest);

    return (
      <Flex ref={ref} gap='6px'>
        {weekdays.map((day, index) => {
          const isSelected = index === value;
          return (
            <Button
              boxSize='42px'
              variant='outline'
              borderRadius='12px'
              color='#6F767E'
              fontWeight='500'
              key={day}
              paddingX={0}
              onClick={() => onChange(index)}
              {...controlProps}
              aria-selected={isSelected}
              _selected={{ borderColor: '#2A85FF', color: '#2A85FF' }}
              _invalid={{ borderColor: 'red.300', color: 'red.500' }}
            >
              {day.substring(0, 1)}
            </Button>
          );
        })}
      </Flex>
    );
  }
);

WeekDaySelectInput.displayName = 'WeekDaySelectInput';

export default WeekDaySelectInput;
