import {
  Box,
  Center,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import React, { FC, useMemo, useState } from 'react';
import RenderScoreCard from './RenderScoreCard';
import BaseListRender from './BaseListRender';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowRight, faRocketLaunch } from '@fortawesome/pro-light-svg-icons';
import { Button, Table } from 'atoms';
import { BoxHeader } from 'ui-components';
import { HeaderColors } from 'configs';
import { ColumnsType } from 'antd/es/table';
import {
  TpOverallLocationsWiseAnalytics,
  TpOverallUserWiseAnalytics,
} from '../../Tracking/tracking-types';
import { useReactiveVar } from '@apollo/client';
import { usersEntityObj } from 'sub-components/Header';
import { calculatePercentage } from 'utils';
import { faArrowDownShortWide } from '@fortawesome/pro-solid-svg-icons';
import { LocationBadge } from '../../Tracking/components';
import EmptyState from 'sub-components/EmptyState';

interface IProps {
  tab: string;
  data: TpOverallLocationsWiseAnalytics[];
}

export const useSortedList = (
  data: TpOverallLocationsWiseAnalytics[] | TpOverallUserWiseAnalytics[],
  tab: string
) => {
  const allUsers = useReactiveVar(usersEntityObj);

  const getUserById = (eid: string) => {
    const user = allUsers.find((user) => user.eid === eid);
    return {
      name: user?.name || '',
      role: user?.authRole || '',
      locations: user?.locations || [],
      image: user?.profilePic || '',
      locationStatus: user?.locationStatus || '',
    };
  };

  return useMemo(() => {
    let dataList =
      tab === 'Members'
        ? (data as TpOverallUserWiseAnalytics[])
        : (data as TpOverallLocationsWiseAnalytics[]);

    return (
      dataList
        ?.map((data, idx) => {
          let entityData;
          if (tab === 'Locations') {
            entityData = getUserById(
              (data as TpOverallLocationsWiseAnalytics).locationId
            );
            return {
              ...data,
              locationName: entityData.name,
              completionRate: calculatePercentage(data.completed, data.total),
              status: entityData.locationStatus,
            };
          }

          if (tab === 'Members') {
            entityData = getUserById(
              (data as TpOverallUserWiseAnalytics).userId
            );
            return {
              ...data,
              userName: entityData.name,
              role: entityData.role,
              completionRate: calculatePercentage(data.completed, data.total),
              image: entityData.image,
              locations: entityData.locations,
            };
          }
        })
        .sort((a, b) => {
          const aValue = a?.completionRate ?? -Infinity;
          const bValue = b?.completionRate ?? -Infinity;
          return bValue - aValue;
        })
        .map((data, idx) => {
          return {
            ...data,
            rank: idx + 1,
          };
        }) || []
    );
  }, [data, allUsers, tab]);
};

const LocationList: FC<IProps> = ({ tab, data }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [sortType, setSortType] = useState<'asc' | 'desc'>('desc');

  const sortedList = useSortedList(data, tab);

  const sortedDataList = () => {
    let dataList = [...sortedList];
    return sortType === 'asc' ? dataList.reverse() : dataList;
  };

  const handleSortToggle = () => {
    setSortType((prevState) => {
      if (prevState === 'asc') {
        return 'desc';
      } else {
        return 'asc';
      }
    });
  };

  const columns: ColumnsType<any> = [
    {
      title: (
        <Flex gap={1} align='center' flex={1} justifyContent='center'>
          <Text fontSize='13px' fontWeight={500} textColor={'#6F767E'}>
            Rank
          </Text>
          <FontAwesomeIcon
            color={'#6F767E'}
            cursor={'pointer'}
            icon={faArrowDownShortWide as IconProp}
            onClick={handleSortToggle}
          />
        </Flex>
      ),
      dataIndex: 'rank',
      key: 'rank',
      width: '30%',
      align: 'center',
      render: (data, record) => (
        <Flex as='span' fontWeight={500} justifyContent='center'>
          {data}
        </Flex>
      ),
    },
    {
      title: 'Location',
      dataIndex: 'locationName',
      key: 'locationName',
      width: '50%',
      render: (data, record) => {
        return (
          <Flex color='#33383F' fontWeight={500} gap={2}>
            <LocationBadge
              locations={[
                {
                  locationStatus: record.status,
                },
              ]}
              size='24px'
            />
            {data}
          </Flex>
        );
      },
    },

    {
      title: 'Rate(%)',
      dataIndex: 'completionRate',
      key: 'completionRate',
      width: '20%',
      align: 'center',
      render: (data) => (
        <Flex color='#000000' fontWeight={500} gap={2} justifyContent='center'>
          {data}
        </Flex>
      ),
    },
  ];

  return (
    <Flex
      width={'100%'}
      gap={8}
      flexDir={'column'}
      mt={'35px'}
      alignItems={'center'}
      justify='space-between'
    >
      {data.length ? (
        <>
          <RenderScoreCard completeData={sortedList} />
          <BaseListRender tab={tab} data={(sortedList || [])?.slice(3)} />

          {sortedList?.length > 3 && (
            <Button
              variant='unstyled'
              color='#2A85FF'
              fontWeight={600}
              alignItems='center'
              gap={2}
              display='flex'
              onClick={onOpen}
            >
              View all
              <FontAwesomeIcon
                icon={faArrowRight as IconProp}
                fontSize='15px'
                color='#2A85FF'
              />
            </Button>
          )}

          <Modal isOpen={isOpen} onClose={onClose} isCentered size='2xl'>
            <ModalOverlay />
            <ModalContent padding='12px' borderRadius='12px'>
              <ModalHeader>
                <BoxHeader
                  title='Training completion rate'
                  color={HeaderColors.Yellow}
                />
              </ModalHeader>
              <ModalCloseButton top='1.5rem' right='1.5rem' fontSize='18px' />
              <ModalBody display='flex' flexDirection='column' gap={5}>
                <Text as='span' color='#272B30' fontWeight={600}>
                  All locations at a glance
                </Text>
                <Box overflowY={'scroll'} maxHeight={'505px'}>
                  <Table
                    columns={columns}
                    dataSource={sortedDataList()}
                    rowKey='id'
                    pagination={false}
                    bordered
                  />
                </Box>
              </ModalBody>
            </ModalContent>
          </Modal>
        </>
      ) : (
        <Center height='100%' width='100%'>
          <EmptyState image='Report' title='No data found' imageSize='300px' />
        </Center>
      )}
    </Flex>
  );
};

export default LocationList;
