import { cloneDeep } from '@apollo/client/utilities';
import { TPSession } from 'sub-components/training-v2/shared/types/play';
import { v4 as uuid } from 'uuid';

export const contentCategorize = (sData: TPSession) => {
  let _data = cloneDeep(sData);
  _data.contents.forEach((content) => {
    if (content.type === 'quiz') {
      content.questions = [
        {
          eid: uuid(),
          label: content?.title,
          subTitle: '',
          createdAt: content?.createdAt,
          score: 0,
          type: 'initial',
          updatedAt: content?.updatedAt,
        },
        ...content.questions,
      ];
    }
  });
  return _data;
};
