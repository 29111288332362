import { Center, Flex } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowTrendUp, faUser } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TRAINING_PATH_V2_PLAY_VIEW } from 'appRoutes';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'routes';
import { eventBus } from 'shared/eventEmit';
import { useMyTrainingContext } from '../../MyTrainingContext';
import { IOverallUserDetailsAnalytics } from '../types/myTraining.types';
import { useMyEvaluations } from './my-evaluations';
import PerformanceCards from './PerformanceCards';

interface IProps {
  overallUserDetailsAnalyticsLoader: boolean;
  overallUserDetailsAnalytics: IOverallUserDetailsAnalytics;
}

const PerformanceInfo: FC<IProps> = ({
  overallUserDetailsAnalyticsLoader,
  overallUserDetailsAnalytics,
}) => {
  const history = useHistory();

  const [newEvaluationReceivedCount, setNewEvaluationReceivedCount] =
    useState();
  const completed = overallUserDetailsAnalytics?.completed ?? 0;
  const total = overallUserDetailsAnalytics?.total ?? 1;

  const completionPercent =
    total > 0 ? ((completed / total) * 100).toFixed(2) : '0.00';

  const { trainingCategories, setFilters, filters } = useMyTrainingContext();

  const myEvaluations = useMyEvaluations();

  useEffect(() => {
    const navigationHandler = (data: {
      trainingId: string;
      sessionId: string;
    }) => {
      history?.push({
        pathname: TRAINING_PATH_V2_PLAY_VIEW,
        params: {
          trainingId: data?.trainingId,
          sessionId: data?.sessionId,
        },
      });
    };

    const unsubscribe = eventBus.on(
      'NAVIGATE_TO_TRAINING_RESULT',
      navigationHandler
    );

    return () => {
      unsubscribe();
    };
  }, [history]);

  const trackingPathTable = document.querySelector('#MyTrainingTable');
  const scrollToTable = () => {
    if (trackingPathTable) {
      trackingPathTable?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const applyOverdueFilter = () => {
    scrollToTable();

    if (filters?.selectedStatusesId?.includes('static_1_overdue')) {
      return;
    } else {
      setFilters((prevState) => {
        return {
          ...prevState,
          selectedStatusesId: [
            ...prevState?.selectedStatusesId,
            'static_1_overdue',
          ],
          selectedStatuses: [
            ...prevState?.selectedStatuses,
            {
              eid: 'static_1_overdue',
              name: 'Overdue',
              value: '',
            },
          ],
          mergedFiltersArray: [
            ...prevState?.mergedFiltersArray,
            {
              eid: 'static_1_overdue',
              name: 'Overdue',
              value: '',
              customType: 'statuses',
            },
          ],
          totalFiltersTypes: prevState?.totalFiltersTypes + 1,
        };
      });
    }
  };

  const evaluateHandler = () => {
    return myEvaluations({
      setNewEvaluationReceivedCount: setNewEvaluationReceivedCount,
      newEvaluationReceivedCount: newEvaluationReceivedCount,
      trainingCategories: trainingCategories,
    });
  };

  const cardsData = [
    {
      title: 'New evaluations received',
      count: overallUserDetailsAnalytics?.evaluated?.toString(),
      icon: (
        <Center boxSize='24px' borderRadius='50%' bg='rgba(111, 118, 126, 1)'>
          <FontAwesomeIcon
            icon={faArrowTrendUp as IconProp}
            style={{ color: 'white' }}
          />
        </Center>
      ),
      onClick: evaluateHandler,
    },
    {
      title: 'Paths overdue',
      count: overallUserDetailsAnalytics?.overdue?.toString(),
      icon: (
        <Center boxSize='24px' borderRadius='50%' bg='rgba(111, 118, 126, 1)'>
          <FontAwesomeIcon
            icon={faUser as IconProp}
            style={{ color: 'white' }}
          />
        </Center>
      ),
      onClick: applyOverdueFilter,
    },
    {
      title: 'My Avg completion rate',
      count: `${Number(completionPercent) ? completionPercent : 0}%`,
      icon: (
        <Center boxSize='24px' borderRadius='50%' bg='rgba(111, 118, 126, 1)'>
          <FontAwesomeIcon
            icon={faArrowTrendUp as IconProp}
            style={{ color: 'white' }}
          />
        </Center>
      ),
    },
  ];

  const cards = cardsData;

  return (
    <Flex width={'100%'} gap={'12px'}>
      {cards?.map((card, index) => {
        return (
          <PerformanceCards
            key={index}
            icon={card?.icon}
            title={card?.title}
            count={card?.count}
            overallUserDetailsAnalyticsLoader={
              overallUserDetailsAnalyticsLoader
            }
            onClickHandler={card?.onClick}
          />
        );
      })}
    </Flex>
  );
};

PerformanceInfo.displayName =
  'displayName:sub-components/training-v2/dashboard/components/myTrainings/topSection/PerformanceInfo';

export default PerformanceInfo;
