import React, { FC } from 'react';
import { FormNotAccessible } from '../component';
import { Box, Center, Image } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { HeaderColors } from 'configs';

interface IProps {}

const InactiveFormEmptyState: FC<IProps> = () => {
  return (
    <div>
      <Center
        flexDir='column'
        gap='10px'
        color='#000000'
        minH='calc(50vh - 60px)'
      >
        <FontAwesomeIcon
          icon={faBan as IconProp}
          color={HeaderColors.Red.Mid}
          fontSize='8rem'
        />
        <Center flexDirection='column'>
          <Box fontWeight='600' fontSize='16px' color='#33383F'>
            Form has been marked as inactive
          </Box>
          <Box fontSize='14px' color='#6F767E'>
            Please Contact your manager for further assistance.
          </Box>
        </Center>
      </Center>
    </div>
  );
};

InactiveFormEmptyState.displayName =
  'displayName:sub-components/forms/submit-form/InactiveFormEmptyState.tsx';

export default InactiveFormEmptyState;
