import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Checkbox,
  Flex,
  HStack,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  useModalContext,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faChevronDown,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useDebounce, useUserDataSelector } from 'hooks';
import { AuthRole } from 'sop-commons/src/client';
import Loader from 'sub-components/Loader';
import {
  GetNotificationsResponse,
  GET_USER_NOTIFICATION_CONFIG,
  UPDATE_USER_NOTIFICATION_CONFIG,
} from 'sub-components/NotificationCenter/notiifications.graphql';
import { removeTypeNameFromNestedResponseObj } from 'utils';
import {
  DummyConfigData,
  NotificationChannel,
} from 'sub-components/NotificationCenter/types/types';
import { AllConfigPreferencesDeselectedWarning } from 'sub-components/NotificationCenter/States';
import { useNotificationInfo } from './notification-info';
import {
  categoryTreeWithoutTemplateName,
  dummyConfigData,
} from 'sub-components/NotificationCenter/constants';

interface NotificationSetting {
  key: string;
  title: string;
  description: string;
  subCategories: {
    parentKey: string;
    labels: [];
    selectedChannels: { [key: string]: boolean }[];
    count: number;
    mappedLabels: string[];
    showConfig: boolean[];
  }[];
}

interface NotificationConfigUpdateInput {
  eid: string;
  notificationConfig: Record<string, Record<string, Record<string, boolean>>>;
}

const TableWrapper = styled.div`
  .ant-table {
    .ant-table-container {
      .ant-table-content {
        table {
          thead {
            .ant-table-cell {
              color: #6f767e;
              font-size: 14px;
            }
          }
          tbody {
            tr {
              .ant-table-row {
                border: 1px solid black;
                border-radius: 12px;
                margin-top: 4px;
                margin-bottom: 4px;
              }
              .ant-table-row-expand-icon-cell {
                .ant-table-row-expand-icon {
                  display: none;
                  width: 0;
                  padding: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td,
  .ant-table-tbody > tr.ant-table-expanded-row > td,
  .ant-table-tbody > tr.ant-table-expanded-row > td {
    background: unset !important;
  }
`;
interface IChannelSelectAll {
  selectAll: boolean;
  key: Set<string>;
}

type TChannels = 'pushNotification' | 'sms' | 'email';
const NotificationPreferencesContainer: FC = () => {
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [tableData, setTableData] = useState<
    NotificationSetting[] | DummyConfigData
  >(dummyConfigData);
  const [initialConfigCheckedCount, setInitialConfigCheckedCount] = useState(0);
  const [channelSelectAll, setChannelSelectAll] = useState<
    Record<TChannels, IChannelSelectAll>
  >({
    pushNotification: {
      selectAll: false,
      key: new Set([]),
    },
    sms: {
      selectAll: false,
      key: new Set([]),
    },
    email: {
      selectAll: false,
      key: new Set([]),
    },
  });
  const notificationInfo = useNotificationInfo();

  let latestConfig:
    | Record<string, Record<string, Record<string, boolean>>>
    | undefined;

  const { loggedInUserEid, loggedInUserAuthRole } = useUserDataSelector(
    (state) => ({
      loggedInUserAuthRole: state.authRole,
      loggedInUserEid: state.eid,
    })
  );
  const isWorker = loggedInUserAuthRole === AuthRole.WORKER; //TODO:make dynamic later
  // Array contains the keys which are to be skipped for initial checked items count
  const initialCountArr = [
    'newlocation',
    'compliancereminder',
    'conversationlogadded',
    'delightreeupdates',
    'newfeatures',
    'groupmessage',
  ];
  // Array contains the keys for audit
  const auditCountArr = [
    'auditcreated',
    'auditreminder',
    'auditcompletion',
    'auditupdates',
  ];

  // Array contains the misc keys to be skipped
  const miscArr = [
    'taskcompleted',
    'trainingcompleted',
    'formresponse',
    'llreminder',
    'llassignment',
  ];

  const skipInitialCountArr = isWorker
    ? [...initialCountArr, ...auditCountArr, ...miscArr]
    : initialCountArr;

  const {
    data: notificationConfig,
    loading,
    refetch: refetchConfig,
  } = useQuery<GetNotificationsResponse, { userId: string }>(
    GET_USER_NOTIFICATION_CONFIG,
    {
      variables: {
        userId: loggedInUserEid,
      },
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        // Existing initial count logic
        let count = 0;
        const newData = removeTypeNameFromNestedResponseObj(
          data?.FetchUserMeta?.notificationConfig
        );

        // Initialize channel select all state
        const initialChannelState: Record<TChannels, IChannelSelectAll> = {
          pushNotification: { selectAll: false, key: new Set([]) },
          sms: { selectAll: false, key: new Set([]) },
          email: { selectAll: false, key: new Set([]) },
        };

        // Process each category and channel
        Object.entries(newData).forEach(([categoryKey, categoryValue]) => {
          // Skip processing for systemSettings
          if (
            categoryKey === 'systemSetting' ||
            categoryKey === 'location' ||
            categoryKey === 'other'
          )
            return;
          if (
            (isWorker && categoryKey === 'form') ||
            categoryKey === 'audit' ||
            categoryKey === 'locationLaunch' ||
            categoryKey === 'systemSetting' ||
            categoryKey === 'location' ||
            categoryKey === 'other'
          )
            return;

          const channels = ['pushNotification', 'sms', 'email'] as TChannels[];

          channels.forEach((channel) => {
            // Check if all subcategories have this channel enabled
            const allChannelEnabled = Object.values(categoryValue).every(
              (subCat) => subCat[channel] === true
            );

            if (allChannelEnabled) {
              initialChannelState[channel].key.add(categoryKey);
              initialChannelState[channel].selectAll = true;
            }
          });
        });

        // Update the channelSelectAll state with initial values
        setChannelSelectAll(initialChannelState);

        // Existing count logic
        Object?.values(newData)?.map((value) => {
          Object?.entries(value)?.map((key, value) => {
            if (
              !key?.[0]?.includes('typename') &&
              !skipInitialCountArr?.includes(key?.[0]?.toLowerCase())
            ) {
              Object?.entries(key?.[1])?.map((k, v) => {
                if (!k?.[0]?.includes('typename')) {
                  if (k?.[1] === true) {
                    count += 1;
                  }
                }
              });
            }
          });
        });

        setInitialConfigCheckedCount(count);
      },
    }
  );
  const [UpdateUserMeta, { loading: updateLoader }] = useMutation(
    UPDATE_USER_NOTIFICATION_CONFIG
  );

  const toggleExpand = (key: string) => {
    setExpandedRowKeys((prevKeys) =>
      prevKeys.includes(key)
        ? prevKeys.filter((k) => k !== key)
        : [...prevKeys, key]
    );
  };

  const transformedData = tableData
    .filter((item) => {
      if (
        loggedInUserAuthRole === AuthRole.WORKER &&
        (item.key === 'audit' ||
          item.key === 'form' ||
          item.key === 'locationLaunch')
      ) {
        //since for worker only completion subCat is coming currently in forms so removed it
        return false;
      }
      return item;
    })
    .map((item) => {
      const config = notificationConfig?.FetchUserMeta?.notificationConfig;
      const processedTypeNameKeys: Record<
        string,
        Record<string, NotificationChannel>
      > = removeTypeNameFromNestedResponseObj(config);
      if (!processedTypeNameKeys) return { ...item, subCategories: [] };

      return {
        ...item,
        subCategories: Object.entries(processedTypeNameKeys).map(
          ([configKey, configItem]) => {
            const labels: string[] = Object.keys(configItem);

            const mappedLabels = labels.map((labelKey: string) => {
              let foundLabel = '';
              for (const [_, subCategories] of Object.entries(
                categoryTreeWithoutTemplateName
              )) {
                if (subCategories[labelKey]) {
                  foundLabel = subCategories[labelKey].label;
                  break;
                }
              }
              return foundLabel || labelKey;
            });
            const showConfig: boolean[] = labels.map((labelKey: string) => {
              if (isWorker) {
                if (
                  !(
                    labelKey.toLowerCase().includes('completion') ||
                    labelKey.toLowerCase().includes('completed')
                  )
                ) {
                  return true;
                } else {
                  return false;
                }
              }
              return true;
            });
            const subCatsCount: number =
              loggedInUserAuthRole !== AuthRole.WORKER
                ? Object.keys(configItem).filter(() => configKey === item.key)
                    .length
                : mappedLabels.filter((label) => {
                    if (configKey === item.key) {
                      if (
                        loggedInUserAuthRole === AuthRole.WORKER &&
                        label.split(' ')[1] === 'completed'
                      ) {
                        //works since in worker case removing completion subCats TODO:refac
                        return false;
                      }
                      return label;
                    }
                  }).length;

            return {
              labels,
              selectedChannels: Object.values(configItem),
              count: subCatsCount,
              parentKey: configKey,
              mappedLabels,
              showConfig,
            };
          }
        ),
      };
    });
  const filteredData = transformedData?.map((item) => {
    const filteredSubCats = item.subCategories.filter(
      (cat) => cat.count !== 0 && cat.labels.length > 0
    );
    return {
      ...item,
      subCategories: filteredSubCats,
    };
  });
  const updateUserNotficationsConfig = useCallback(
    (input: NotificationConfigUpdateInput) => {
      UpdateUserMeta({
        variables: {
          input,
        },
      }).then(() => refetchConfig());
    },
    [refetchConfig]
  );

  const isLastSubscriptionInSystem = (
    currentChannel: string,
    categoryKey: string,
    subCatLabel?: string
  ) => {
    const excludedCategories = new Set(['location', 'other', 'systemSetting']);
    let totalEnabledSubscriptions = 0;

    // Count all currently enabled subscriptions across all categories
    filteredData
      .filter((category) => !excludedCategories.has(category.key))
      .forEach((category) => {
        category.subCategories.forEach((subCat) => {
          subCat.selectedChannels.forEach((channel) => {
            ['pushNotification', 'sms', 'email'].forEach((ch) => {
              if (channel[ch]) totalEnabledSubscriptions++;
            });
          });
        });
      });

    // For individual subcategory checkbox
    if (subCatLabel) {
      return (
        totalEnabledSubscriptions === 1 &&
        filteredData
          .find((cat) => cat.key === categoryKey)
          ?.subCategories[0]?.selectedChannels.find(
            (_, index) =>
              filteredData.find((cat) => cat.key === categoryKey)
                ?.subCategories[0]?.labels[index] === subCatLabel
          )?.[currentChannel]
      );
    }

    // For select all checkbox
    return (
      totalEnabledSubscriptions ===
      filteredData
        .find((cat) => cat.key === categoryKey)
        ?.subCategories[0]?.selectedChannels.filter(
          (channel) => channel[currentChannel]
        ).length
    );
  };

  const handleSubCategoriesToggle = (
    isChecked: boolean,
    channelOption: string,
    subCatLabel: string
  ) => {
    const updatedConfig = filteredData.reduce((acc, { key, subCategories }) => {
      const subCategoriesConfig = subCategories.reduce(
        (subAcc, { labels, selectedChannels }) => {
          labels.forEach((label, index) => {
            if (subCatLabel === label) {
              const options = Object.values(selectedChannels)[index];
              if (key === 'chat') {
                options[channelOption] = isChecked;
                const groupMessageSelectedChannels =
                  Object.values(selectedChannels)[1];
                groupMessageSelectedChannels[channelOption] = isChecked;
                subAcc['groupMessage'] = groupMessageSelectedChannels;
              }
              options[channelOption] = isChecked;
              subAcc[label] = options;
            }
            subAcc[label] = selectedChannels[index];
          });
          return subAcc;
        },
        {} as Record<string, Record<string, boolean>>
      );
      acc[key] = subCategoriesConfig;
      return acc;
    }, {} as Record<string, Record<string, Record<string, boolean>>>);

    latestConfig = updatedConfig;

    // Find the category and its current state
    const category = filteredData.find((cat) =>
      cat.subCategories[0]?.labels.includes(subCatLabel)
    );

    // Check if this channel type has any selectAll enabled categories
    const hasChannelSelectAll =
      channelSelectAll[channelOption as TChannels]?.selectAll &&
      channelSelectAll[channelOption as TChannels]?.key.size > 0;

    // Check if any other channel has selectAll enabled
    const hasOtherChannelSelectAll = Object.entries(channelSelectAll).some(
      ([channel, state]) =>
        channel !== channelOption && state.selectAll && state.key.size > 0
    );

    let newCount = isChecked
      ? initialConfigCheckedCount + 1
      : initialConfigCheckedCount - 1;

    // Only show warning if no selectAll is enabled for any channel
    if (newCount === 0 && !hasChannelSelectAll && !hasOtherChannelSelectAll) {
      notificationInfo({
        onOkPress: () => {
          updateUserNotficationsConfig({
            eid: loggedInUserEid,
            notificationConfig: updatedConfig,
          });
          setInitialConfigCheckedCount(newCount);
        },
        onCancelPress: () => {
          newCount += 1;
          setInitialConfigCheckedCount(newCount);
        },
      });
    } else {
      updateUserNotficationsConfig({
        eid: loggedInUserEid,
        notificationConfig: updatedConfig,
      });
      setInitialConfigCheckedCount(newCount);
    }
  };

  const isChannelChecked: (
    record: NotificationSetting,
    channelOption: string
  ) => boolean = (record, channelOption) => {
    if (record?.key === 'systemSetting') return true; // Always true for systemSettings
    const subCategory = record?.subCategories?.[0];
    if (!subCategory?.selectedChannels) return false;

    const { selectedChannels, showConfig } = subCategory;
    if (record?.key === 'chat') {
      return selectedChannels
        ?.filter((_, index) => showConfig?.[index])
        ?.every((channel) => channel?.[channelOption]);
    }
    const filteredChannels = isWorker
      ? selectedChannels?.filter((_, index) => showConfig?.[index]) //remove that specific val for selectedChannel
      : selectedChannels; //if for the channelOption selectAll is true and the key is the same as the record key then return true

    if (
      channelSelectAll[channelOption as TChannels]?.selectAll &&
      channelSelectAll[channelOption as TChannels]?.key?.has(record?.key)
    ) {
      return filteredChannels?.every((channel) => channel?.[channelOption]);
    }
    return filteredChannels?.some((channel) => channel?.[channelOption]);
  };

  const getChannelState = (
    selectedChannels: { [key: string]: boolean }[],
    channelOption: string,
    showConfig: boolean[]
  ) => {
    const authRoleSelectedChannels = isWorker
      ? selectedChannels.filter((_, index) => showConfig?.[index])
      : selectedChannels;
    const allChannelValues = Object.values(authRoleSelectedChannels)?.map(
      (channel) => channel?.[channelOption]
    );
    const allChecked = allChannelValues?.every((value) => value === true);
    const someChecked = allChannelValues?.some((value) => value === true);

    if (allChecked) return 'green';
    if (someChecked) return 'blue';
  };

  const handleSelectAll = (
    e: ChangeEvent<HTMLInputElement>,
    channelType: string,
    record: NotificationSetting
  ) => {
    if (record?.key === 'systemSetting') return;

    const isChecked = e?.target?.checked;

    const updatedConfig = filteredData?.reduce(
      (acc, { key, subCategories }) => {
        const subCategoriesConfig = subCategories?.reduce(
          (subAcc, { labels, selectedChannels }) => {
            labels?.forEach((label, index) => {
              const options = Object.values(selectedChannels)?.[index];
              // Update channel state for matching category
              if (record?.key === key) {
                options[channelType] = isChecked;
                subAcc[label] = options;
              } else {
                subAcc[label] = options;
              }
            });

            return subAcc;
          },
          {} as Record<string, Record<string, boolean>>
        );

        acc[key] = subCategoriesConfig;
        return acc;
      },
      {} as Record<string, Record<string, Record<string, boolean>>>
    );

    // Count currently checked subcategories for this channel
    const checkedSubcatsCount =
      record.subCategories[0]?.selectedChannels.reduce((count, channel) => {
        // Check if all subcategories were previously checked
        const allSubcatsSelected =
          record?.subCategories[0]?.selectedChannels?.every(
            () => !channel[channelType]
          );
        if (!isChecked && allSubcatsSelected) {
          count--;
        }
        return count;
      }, initialConfigCheckedCount) || 0;

    let newCount = initialConfigCheckedCount;
    if (!isChecked) {
      //if deselecting then the above bit takes care of the subtracting previously selected subcats
      newCount -= checkedSubcatsCount;
      setInitialConfigCheckedCount(newCount);
    }

    if (!isChecked && isLastSubscriptionInSystem(channelType, record.key)) {
      notificationInfo({
        onOkPress: () => {
          setChannelSelectAll((prevState) => ({
            ...prevState,
            [channelType]: {
              ...prevState[channelType as TChannels],
              key: new Set(
                [...prevState[channelType as TChannels]?.key]?.filter(
                  (k) => k !== record.key
                )
              ),
            },
          }));
          updateUserNotficationsConfig({
            eid: loggedInUserEid,
            notificationConfig: updatedConfig,
          });
          setInitialConfigCheckedCount(0);
        },
        onCancelPress: () => {
          // Keep current state
        },
      });
    } else {
      setChannelSelectAll((prevState) => ({
        ...prevState,
        [channelType]: {
          ...prevState[channelType as TChannels],
          key: isChecked
            ? new Set([
                ...prevState[channelType as TChannels]?.key,
                record?.key,
              ])
            : new Set(
                [...prevState[channelType as TChannels]?.key]?.filter(
                  (k) => k !== record.key
                )
              ),
        },
      }));
      updateUserNotficationsConfig({
        eid: loggedInUserEid,
        notificationConfig: updatedConfig,
      });
      setInitialConfigCheckedCount(newCount);
    }
  };

  const columns: ColumnsType<NotificationSetting> = [
    {
      title: '',
      dataIndex: 'title',
      key: 'title',
      width: '50%',
      render: (_, record) => {
        return (
          <Stack mt={2} mb={2} marginLeft='-3rem' padding='1rem'>
            <Box as='span' color={'#1A1D1F'} fontWeight={600} fontSize={'20px'}>
              {/**removed the compeled cat */}
              {record?.title} (
              {record?.key === 'chat'
                ? 1
                : record?.key === 'sop'
                ? 2
                : record?.subCategories[0]?.count}
              ) {/**TODO: change later v hacky! */}
            </Box>
            <Text color={'#6F767E'}>{record.description}</Text>
          </Stack>
        );
      },
    },
    {
      title: 'Mobile app',
      dataIndex: 'pushNotification',
      key: 'pushNotification',
      width: '15%',
      align: 'center',
      render: (_, record) => {
        const selectedChannels = record?.subCategories?.[0]?.selectedChannels;
        const colorScheme = selectedChannels
          ? getChannelState(
              selectedChannels,
              'pushNotification',
              record?.subCategories[0]?.showConfig
            )
          : 'blue';
        return (
          <Box>
            <Checkbox
              isDisabled={record?.key === 'systemSetting'}
              isChecked={isChannelChecked(record, 'pushNotification')}
              size={'lg'}
              mr={2}
              colorScheme={colorScheme}
              onChange={(e) => handleSelectAll(e, 'pushNotification', record)}
            />
          </Box>
        );
      },
    },
    {
      title: 'SMS',
      dataIndex: 'sms',
      key: 'sms',
      width: '15%',
      align: 'center',
      render: (_, record) => {
        const selectedChannels = record?.subCategories?.[0]?.selectedChannels;
        const colorScheme = selectedChannels
          ? getChannelState(
              selectedChannels,
              'sms',
              record?.subCategories[0]?.showConfig
            )
          : 'blue';
        return (
          <Box>
            <Checkbox
              isDisabled={record?.key === 'systemSetting'}
              isChecked={isChannelChecked(record, 'sms')}
              size={'lg'}
              colorScheme={colorScheme}
              onChange={(e) => handleSelectAll(e, 'sms', record)}
            />
          </Box>
        );
      },
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
      width: '15%',
      align: 'center',
      render: (_, record) => {
        const selectedChannels = record?.subCategories?.[0]?.selectedChannels;
        const colorScheme = selectedChannels
          ? getChannelState(
              selectedChannels,
              'email',
              record?.subCategories[0]?.showConfig
            )
          : 'blue';
        return (
          <Box>
            <Checkbox
              isDisabled={record?.key === 'systemSetting'}
              isChecked={isChannelChecked(record, 'email')}
              size={'lg'}
              colorScheme={colorScheme}
              onChange={(e) => handleSelectAll(e, 'email', record)}
            />
          </Box>
        );
      },
    },
    {
      key: 'action',
      align: 'center',
      width: '5%',
      render: (_, record) => (
        <Box>
          <FontAwesomeIcon
            icon={
              expandedRowKeys.includes(record?.key)
                ? (faChevronDown as IconProp)
                : (faChevronRight as IconProp)
            }
            style={{ cursor: 'pointer' }}
            onClick={() => toggleExpand(record?.key)}
          />
        </Box>
      ),
    },
  ];
  return (
    <>
      {loading ? (
        <Flex
          justifyContent={'center'}
          minW={'75vw'}
          h={'70vh'}
          alignItems={'center'}
        >
          <Loader />
        </Flex>
      ) : (
        <Box>
          {/* {loading ||
            (updateLoader && (
              <Flex
                top={0}
                left={0}
                right={0}
                bottom={0}
                zIndex={2}
                position={'absolute'}
                style={{ background: 'rgba(0, 0, 0, 0.2)' }}
                justifyContent={'center'}
                minW={'75vw'}
                h={'8=100vh'}
                alignItems={'center'}
              >
                <Loader />
              </Flex>
            ))} */}
          <TableWrapper>
            <Table
              style={{ marginTop: '-30px' }}
              loading={
                loading ||
                (updateLoader && {
                  indicator: <Loader />,
                })
              }
              columns={columns}
              dataSource={filteredData}
              pagination={false}
              expandable={{
                expandedRowKeys,
                onExpand: (expanded, record) => toggleExpand(record?.key),
                expandedRowRender: (record) => (
                  <Flex flexDirection='column' gap={2} ml={2}>
                    {record?.key === 'chat' ? (
                      <Flex key={'chat-row'}>
                        <Text
                          color={'#111315'}
                          lineHeight={'16.94px'}
                          width={`50%`}
                        >
                          Chats received
                        </Text>
                        <Flex
                          width='50%'
                          justifyContent='space-between'
                          alignItems='center'
                        >
                          {['pushNotification', 'sms', 'email'].map(
                            (channelOption, index) => (
                              <Box key={index} width='34%' textAlign='center'>
                                <Checkbox
                                  key={index}
                                  size={'lg'}
                                  borderRadius={'xl'}
                                  value={channelOption}
                                  //  isDisabled={record?.key === 'systemSetting'}
                                  ml={index === 1 ? 4 : 3}
                                  isChecked={
                                    record?.subCategories[0]
                                      ?.selectedChannels[0][channelOption]
                                  }
                                  onChange={(e) => {
                                    handleSubCategoriesToggle(
                                      e.target.checked,
                                      channelOption,
                                      'directMessage'
                                    );
                                  }}
                                />
                              </Box>
                            )
                          )}
                          <Box width='9%' visibility='hidden' />
                        </Flex>
                      </Flex>
                    ) : (
                      record?.subCategories[0]?.showConfig.map(
                        (isShown, labelIndx) => (
                          <Flex key={labelIndx} w='full'>
                            <Text
                              color={'#111315'}
                              lineHeight={'16.94px'}
                              w='50%'
                            >
                              {isShown &&
                                record?.subCategories[0]?.mappedLabels[
                                  labelIndx
                                ]}
                            </Text>
                            <Flex
                              width='50%'
                              justifyContent='space-between'
                              alignItems='center'
                            >
                              {['pushNotification', 'sms', 'email'].map(
                                (channelOption, index) =>
                                  isShown && (
                                    <Box
                                      key={index}
                                      width='34%'
                                      textAlign='center'
                                    >
                                      <Checkbox
                                        key={index}
                                        size={'lg'}
                                        ml={index === 1 ? 4 : 3}
                                        borderRadius={'xl'}
                                        value={channelOption}
                                        isDisabled={
                                          record?.key === 'systemSetting'
                                        }
                                        isChecked={
                                          record?.key === 'systemSetting'
                                            ? true
                                            : record?.subCategories[0]
                                                ?.selectedChannels[labelIndx][
                                                channelOption
                                              ]
                                        }
                                        onChange={(e) => {
                                          handleSubCategoriesToggle(
                                            e.target.checked,
                                            channelOption,
                                            record?.subCategories[0]?.labels[
                                              labelIndx
                                            ]
                                          );
                                        }}
                                      />
                                    </Box>
                                  )
                              )}
                              <Box width='9%' visibility='hidden' />
                            </Flex>
                          </Flex>
                        )
                      )
                    )}
                  </Flex>
                ),
              }}
            />
          </TableWrapper>
        </Box>
      )}
    </>
  );
};
NotificationPreferencesContainer.displayName =
  'sc/NC/S/c/NotiifcationPreferencesContainer.tsx';

export default NotificationPreferencesContainer;
