import React, { ChangeEvent, FC, useMemo } from 'react';
import {
  CheckboxGroup,
  Flex,
  PopoverBody,
  TabList,
  Tabs,
  useControllableState,
  usePopoverContext,
} from '@chakra-ui/react';
import { Button, SearchInput, TabItem } from '../../../../../../../atoms';
import { useSafeState, useUserDataSelector } from '../../../../../../../hooks';
import { TAssLocationType } from '../../../../../../../types';
import { searchRegExp, toArray } from '../../../../../../../utils';
import { AuthRole } from '../../../../../../../authorization';
import { UserEntityData } from '../../../../../../../shared/graphql/shared-types';

import { ListScrollContainer, ListWrapper } from '../common';
import LocationOption from './LocationOption';
import SelectAllButton from './SelectAllButton';

const GetIndex: Record<TAssLocationType, number> = {
  all: 0,
  custom: 0,
  open: 1,
  development: 2,
};

interface IProps {
  locations: UserEntityData[];
  value: string[];
  locationSelectType?: TAssLocationType;
  updateValue: (value: string[], locationType: TAssLocationType) => void;
}

const LocationSelectContent: FC<IProps> = ({
  locations,
  value,
  locationSelectType,
  updateValue,
}) => {
  const authUser = useUserDataSelector((state) => ({
    authRole: state.authRole,
    // locations: cloneDeep(state.locations),
  }));
  const { onClose } = usePopoverContext();
  const [internalValue, internalUpdate] = useControllableState<string[]>({
    defaultValue: () => {
      switch (locationSelectType) {
        case 'all':
          return toArray(locations!).map((l) => l.eid);
        case 'open':
          return toArray(locations!).reduce<string[]>((acc, loc) => {
            if (loc.locationStatus === 'open') {
              acc.push(loc.eid);
            }
            return acc;
          }, []);
        case 'development':
          return toArray(locations!).reduce<string[]>((acc, loc) => {
            if (loc.locationStatus === 'development') {
              acc.push(loc.eid);
            }
            return acc;
          }, []);
        default:
          return value;
      }
    },
  });

  const [internalAllValue, internalAllUpdate] =
    useControllableState<TAssLocationType>({
      defaultValue: locationSelectType || 'custom',
    });

  const [tabValue, updateTabValue] = useControllableState<number>({
    defaultValue: GetIndex[locationSelectType!] || 0,
  });

  const [searchQuery, updateQuery] = useSafeState('');
  const [_allChecked, setAllChecked] = useSafeState(false);

  const { openLocation, launching } = useMemo(() => {
    return locations.reduce(
      (acc, value) => {
        if (value.locationStatus === 'development') {
          acc.launching.push(value);
        } else {
          acc.openLocation.push(value);
        }

        return acc;
      },
      {
        openLocation: [] as typeof locations,
        launching: [] as typeof locations,
      }
    );
  }, [locations]);

  const locationList = useMemo(() => {
    const data =
      tabValue === 2 ? launching : tabValue === 1 ? openLocation : locations;

    if (!searchQuery?.trim()) {
      return data;
    }

    const reg = searchRegExp(searchQuery, 'gi');

    return data.filter((value) => {
      return value.name?.match(reg);
    });
  }, [locations, openLocation, launching, tabValue, searchQuery]);

  const onSelectAll = (
    event: ChangeEvent<HTMLInputElement>,
    locationType: TAssLocationType
  ) => {
    const keys = locationList?.map((val) => val.eid);

    const checkStatus = ['all', 'open', 'development'].includes(locationType);
    if (event.target.checked && checkStatus && !searchQuery) {
      internalUpdate(keys);
    } else {
      internalUpdate((prev = []) => {
        if (event.target.checked) {
          return [...new Set([...prev, ...keys])];
        } else {
          return prev.filter((p) => !keys.includes(p));
        }
      });
    }

    if (searchQuery) {
      setAllChecked(event.target.checked);
    } else {
      setAllChecked(false);
      if (event.target.checked) {
        internalAllUpdate(locationType);
      } else {
        internalAllUpdate('custom');
      }
    }
  };

  const onChangeHandler = (value: string[]) => {
    // switch (internalAllValue) {
    //   case 'all':
    //     if (value.length !== locations?.length) {
    //       internalAllUpdate('custom');
    //     }
    //     break;
    //   case 'open':
    //     if (value.length !== openLocation?.length) {
    //       internalAllUpdate('custom');
    //     }
    //     break;
    //   case 'development':
    //     if (value.length !== launching?.length) {
    //       internalAllUpdate('custom');
    //     }
    //     break;
    // }

    internalAllUpdate('custom');
    internalUpdate(value);
  };

  // const [allItemChecked, isIndeterminate] = useMemo(() => {
  //   const all = locationList?.every((val) => internalValue?.includes(val.eid));
  //   const some = locationList?.some((val) => internalValue?.includes(val.eid));
  //   return [all && !!locationList?.length, some && !all];
  // }, [locationList, internalValue]);

  const creatorLo = authUser?.authRole === AuthRole.LOCATION_OWNER;

  const allChecked = useMemo(() => {
    if (!searchQuery) {
      return false;
    }

    const all = locationList?.every((val) => internalValue?.includes(val.eid));

    return all && !!locationList?.length && _allChecked;
  }, [locationList, _allChecked]);

  const onSelectClick = () => {
    setTimeout(onClose);
    if (!internalAllValue || internalAllValue === 'custom') {
      updateValue?.(internalValue, internalAllValue);
    } else {
      updateValue?.([], internalAllValue);
    }
  };

  return (
    <PopoverBody paddingInline='20px' paddingBlock='16px'>
      <Flex flexDir='column' gap={3}>
        <SearchInput
          size='lg'
          placeholder='Search by location name'
          hideShortcuts
          value={searchQuery}
          onChange={(event) => updateQuery(event.target.value)}
        />

        <Tabs w='full' index={tabValue} onChange={updateTabValue}>
          {creatorLo ? null : (
            <TabList borderBottom='1px solid'>
              <TabItem>All ({locations.length})</TabItem>
              <TabItem>Open ({openLocation.length})</TabItem>
              <TabItem>Launching ({launching.length})</TabItem>
            </TabList>
          )}
        </Tabs>
      </Flex>

      <ListScrollContainer marginTop={creatorLo ? undefined : '20px'}>
        <SelectAllButton
          hidden={tabValue !== 0}
          isSelected={internalAllValue === 'all' || allChecked}
          onSelectAll={(event) => onSelectAll(event, 'all')}
        />
        <SelectAllButton
          hidden={tabValue !== 1}
          isSelected={internalAllValue === 'open' || allChecked}
          onSelectAll={(event) => onSelectAll(event, 'open')}
        />
        <SelectAllButton
          hidden={tabValue !== 2}
          isSelected={internalAllValue === 'development' || allChecked}
          onSelectAll={(event) => onSelectAll(event, 'development')}
        />

        <CheckboxGroup value={internalValue} onChange={onChangeHandler}>
          <ListWrapper
            haveData={locationList?.length > 0}
            hevSearchQuery={!!searchQuery}
          >
            {locationList.map((item) => {
              return <LocationOption key={item.eid} item={item} />;
            })}
          </ListWrapper>
        </CheckboxGroup>
      </ListScrollContainer>

      <Button size='lg' width='full' colorScheme='blue' onClick={onSelectClick}>
        Select
      </Button>
    </PopoverBody>
  );
};

export default LocationSelectContent;
