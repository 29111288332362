import React, { forwardRef, useMemo } from 'react';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  useControllableState,
} from '@chakra-ui/react';
import { AuthRole } from '../../../../../../../authorization';
import AuthRoleSelectContent, { roleOptions } from './AuthRoleSelectContent';
import AuthRoleSelectButton from './AuthRoleSelectButton';

interface IProps {
  value?: AuthRole[];
  onChange?: (value: AuthRole[]) => void;
  disabled?: boolean;
}

const TrainingAuthRoleSelect = forwardRef<never, IProps>(
  ({ value: _value, onChange, disabled }, ref) => {
    const [internalValue, updateValue] = useControllableState<string[]>({
      value: _value,
      onChange: onChange as never,
    });

    const values = useMemo(() => {
      return roleOptions.filter((val) => internalValue?.includes(val.value));
    }, [internalValue]);

    const onRemoveClick = (data: AuthRole) => {
      updateValue(internalValue.filter((val) => val !== data));
    };

    if (disabled) {
      return (
        <AuthRoleSelectButton ref={ref} values={values} disabled={disabled} />
      );
    }

    return (
      <Popover isLazy matchWidth>
        <PopoverTrigger>
          <AuthRoleSelectButton
            ref={ref}
            values={values}
            disabled={disabled}
            onRemoveClick={onRemoveClick}
          />
        </PopoverTrigger>

        <PopoverContent
          width='inherit'
          borderRadius='12px'
          boxShadow='md'
          _focus={{}}
        >
          <AuthRoleSelectContent
            value={internalValue}
            updateValue={updateValue}
          />
        </PopoverContent>
      </Popover>
    );
  }
);

TrainingAuthRoleSelect.displayName = 'TrainingAuthRoleSelect';

export default TrainingAuthRoleSelect;
