import React, { FC } from 'react';
import { TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { FlexCard, TabItem } from 'atoms';
import { useRouteState } from 'routes';
import TrainingPathDetailsSection from './TrainingPathDetailsSection';
import { AddContentSection } from './AddContentSection';
import { AssignPublishSection } from './AssignPublishSection';
import { TFormInput } from './training-input.types';
import { useEditDisabled } from './common';
import TrainingNavigationBlock from './TrainingNavigationBlock';

const TriggerData = {
  '0-1': ['title', 'category', 'thumbnail'],
  '0-2': ['title', 'category', 'thumbnail'],
  '1-2': ['contents'],
};

const Container: FC = () => {
  const { t } = useTranslation(['training']);
  const { getValues, handleSubmit, trigger, clearErrors } =
    useFormContext<TFormInput>();

  const { state, updateState } = useRouteState({ currentEditPage: 0 });

  const tabIndex = state.currentEditPage;

  const editDisabled = useEditDisabled();

  const onTabChange = async (nextIndex: number) => {
    const checkIndex = `${tabIndex}-${nextIndex}` as never;
    const nextTrigger = TriggerData[checkIndex];
    if (nextTrigger) {
      const success = await trigger(nextTrigger, { shouldFocus: true });
      if (success) {
        await handleSubmit(() => null)();
        if (checkIndex === '0-2') {
          updateState({
            currentEditPage: getValues('contents')?.length ? nextIndex : 1,
          });
        } else {
          updateState({ currentEditPage: nextIndex });
        }
        await new Promise((resolve) => setTimeout(resolve, 0));
        clearErrors();
      } else {
        await handleSubmit(() => null)();
      }
    } else {
      updateState({ currentEditPage: nextIndex });
      await new Promise((resolve) => setTimeout(resolve, 0));
      clearErrors();
    }
  };

  return (
    <FlexCard
      aria-selected={tabIndex === 1 && !editDisabled}
      _selected={{ paddingBottom: 0, marginBottom: '-19px' }}
    >
      <Tabs isLazy index={tabIndex} w='full' onChange={onTabChange}>
        <TabList>
          <TabItem>
            <Trans
              t={t}
              i18nKey='training:tabTrainingPathDetails'
              values={{ serialNo: '1' }}
            />
          </TabItem>
          <TabItem>
            <Trans
              t={t}
              i18nKey='training:tabAddContent'
              values={{ serialNo: '2' }}
            />
          </TabItem>
          <TabItem>
            <Trans
              t={t}
              i18nKey='training:tabAssignAndPublish'
              values={{ serialNo: '3' }}
            />
          </TabItem>
        </TabList>

        <TabPanels
          mt={10}
          minHeight='70vh'
          display='flex'
          flexDirection='column'
        >
          <TabPanel padding='0'>
            <TrainingPathDetailsSection disabled={editDisabled} />
          </TabPanel>
          <TabPanel padding='0' flex={1} display='flex' flexDirection='column'>
            <AddContentSection disabled={editDisabled} />
          </TabPanel>
          <TabPanel padding='0'>
            <AssignPublishSection />
          </TabPanel>
        </TabPanels>
      </Tabs>
      <TrainingNavigationBlock />
    </FlexCard>
  );
};

Container.displayName =
  'displayName:sub-components/training-v2/create/components/Container';

export default React.memo(Container);
