import React, { FC } from 'react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Flex,
  Button,
  Text,
} from '@chakra-ui/react';
import { ReactComponent as CheveroniconIcon } from 'assets/images/cheveronIcon.svg';

interface IProps {
  jobList: string[];
  selectedJob: string;
  setSelectedJob: React.Dispatch<React.SetStateAction<string>>;
  jobCounts: any;
}

const JobFilter: FC<IProps> = ({
  jobList,
  setSelectedJob,
  selectedJob,
  jobCounts,
}) => {
  const handleJobSelect = (job: string) => {
    setSelectedJob(job);
  };

  return (
    <Flex>
      <Popover>
        <PopoverTrigger>
          <Button
            color={'#6F767E'}
            fontSize={'12px'}
            borderRadius={'12px'}
            border={'2px solid #EFEFEF'}
            backgroundColor={'white'}
            rightIcon={<CheveroniconIcon />}
          >
            {selectedJob}
          </Button>
        </PopoverTrigger>
        <PopoverContent borderRadius={'12px'} width={'200px'}>
          <PopoverArrow />
          <PopoverBody>
            <RenderJobList
              jobList={jobList}
              handleJobSelect={handleJobSelect}
              selectedJob={selectedJob}
              jobCounts={jobCounts}
            />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Flex>
  );
};

interface IRenderJobList {
  jobList: string[];
  selectedJob: string;
  handleJobSelect?: ((job: string) => void) | undefined;
  jobCounts?: any;
}
export const RenderJobList: FC<IRenderJobList> = ({
  jobList,
  selectedJob,
  handleJobSelect,
  jobCounts,
}) => {
  const getStyles = (job: string) => {
    if (selectedJob === job) {
      return {
        color: '#1A1D1F',
        borderRadius: '12px',
        backgroundColor: '#F4F4F4',
      };
    }
    return {};
  };

  return (
    <Flex fontWeight={600} flexDir={'column'} gap={2}>
      {jobList?.map((item) => {
        return (
          <Flex
            style={getStyles(item)}
            //@ts-ignore
            onClick={() => handleJobSelect(item)}
            color={'#6F767E'}
            p={'10px'}
            cursor={'pointer'}
            key={item}
          >
            <Text>
              {item} &nbsp; ({jobCounts[item]})
            </Text>
          </Flex>
        );
      })}
    </Flex>
  );
};

export default JobFilter;
