import React, { FC } from 'react';
import { Box, Checkbox, Divider, Flex } from '@chakra-ui/react';
import { IconImage, RoleAndAuthRole } from '../../../../../../../ui-components';
import { UserEntityData } from '../../../../../../../shared/graphql/shared-types';

interface IProps {
  item: UserEntityData;
}

const MemberOption: FC<IProps> = ({ item }) => {
  return (
    <React.Fragment key={item.eid}>
      <Flex
        gap={3}
        height='48px'
        minHeight='48px'
        alignItems='center'
        paddingInline='12px'
        borderRadius='12px'
        // cursor='pointer'
        _hover={{ bg: '#F4F4F3' }}
      >
        <IconImage
          name={item.name}
          thumbnail={item.profilePic}
          boxSize={36}
          borderRadius='6px'
        />
        <Flex flex={1} flexDir='column' overflow='hidden'>
          <Box fontWeight='500' isTruncated color='#33383F'>
            {item.name}
          </Box>
          <RoleAndAuthRole
            disableToolTip
            disableAuthRoleSplit
            authRole={item.role}
            role={item.locations?.[0]?.name}
            roleProps={{ color: '#9A9FA5' }}
          />
        </Flex>
        <Checkbox value={item.eid} size='lg' />
      </Flex>
      <Divider />
    </React.Fragment>
  );
};

export default MemberOption;
