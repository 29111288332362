import React, { FC, forwardRef, useMemo } from 'react';
import { chakraComponents, OptionProps } from 'chakra-react-select';
import { Flex, Radio, useControllableState } from '@chakra-ui/react';
import { Dropdown, SelectOption } from '../../../../../../../atoms';
import { toArray } from '../../../../../../../utils';

const Option: FC<OptionProps<SelectOption, false>> = ({
  children,
  isSelected,
  ...props
}) => {
  return (
    <chakraComponents.Option {...props} isSelected={isSelected}>
      <Flex align='center' gap='5px'>
        <Radio size='lg' isChecked={isSelected} />
        <Flex flex={1} noOfLines={1} color='#33383F'>
          {children}
        </Flex>
      </Flex>
    </chakraComponents.Option>
  );
};

interface IProps {
  options?: SelectOption[];
  isLoading?: boolean;
  value?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
}

const TrainingSelect = forwardRef<never, IProps>(
  ({ options, isLoading, value, onChange, disabled }, ref) => {
    const [internalValue, updateValue] = useControllableState<string>({
      value: value,
      onChange: onChange,
    });

    const selectedValue = useMemo(() => {
      if (!internalValue) {
        return null;
      }
      return toArray(options).find((option) => option.value === internalValue);
    }, [options, internalValue]);

    return (
      <Dropdown
        ref={ref}
        size='lg'
        isLoading={isLoading}
        value={selectedValue}
        onChange={(newValue) => updateValue(newValue?.value)}
        options={options}
        disabled={disabled}
        placeholder='Select the prerequisite training path.'
        components={{
          // @ts-ignore
          Option: Option,
        }}
        selectStyles={{
          container: {
            background: 'transparent',
            // width: '240px',
            maxWidth: '100%',
          },
          control: {
            border: '2px solid',
            // maxWidth: '240px',
          },
          menuList: {
            padding: '16px',
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            // maxWidth: '200px',
          },
          option: (__, state) => {
            return {
              fontSize: '15px',
              fontWeight: 600,
              padding: '12px',
              borderRadius: '10px',
              ...(state.isSelected
                ? {
                    background: '#2A85FF1A',
                  }
                : {}),
            };
          },
          singleValue: {
            fontSize: '14px',
            fontWeight: 600,
            color: '#1A1D1F',
          },
        }}
      />
    );
  }
);

TrainingSelect.displayName = 'TrainingSelect';

export default TrainingSelect;
