import React, { FC } from 'react';
import { Checkbox, Flex, Text } from '@chakra-ui/react';
import { HeaderColors } from 'configs';
import { BoxHeader } from 'ui-components';
import { ReactComponent as BackButton } from 'assets/images/back.svg';
import { TAnalyticsFilterClearFilter } from '../../components/DetailsPageFilters/AnalyticsBaseFilter';
import {
  DetailsFiltersType,
  IDetailsFilterTypes,
} from '../../components/AnalyticsContainerHeader';
import { DetailsPageNameType } from '../../components/DetailsMainContainer';

interface IProps {
  setPageName: React.Dispatch<React.SetStateAction<DetailsPageNameType>>;
  handleScoreSelection: (
    e: React.ChangeEvent<HTMLInputElement>,
    score: IDetailsFilterTypes
  ) => void;
  clearFilters: (type?: TAnalyticsFilterClearFilter) => void;
  detailsFilters: DetailsFiltersType;
}

const DetailsScoresFilter: FC<IProps> = ({
  setPageName,
  handleScoreSelection,
  clearFilters,
  detailsFilters,
}) => {
  return (
    <>
      <Flex alignItems={'center'} mt={'30px'} justifyContent={'space-between'}>
        <BoxHeader title='Filters' color={HeaderColors.Purple} />
        <Text
          cursor={'pointer'}
          onClick={() => clearFilters('detailsScores')}
          fontSize={'14px'}
          fontWeight={400}
          color={
            detailsFilters?.selectedScoresId?.length > 0 ? '#2A85FF' : '#6F767E'
          }
        >
          Clear Selection
        </Text>
      </Flex>

      <Flex mt={'20px'} gap={3} alignItems={'center'} marginBottom={'1rem'}>
        <BackButton
          width={'1.2rem'}
          onClick={() => setPageName('base')}
          cursor={'pointer'}
        />
        <Text
          style={{
            fontWeight: 600,
            fontSize: 14,
          }}
        >
          Score
        </Text>
      </Flex>

      <Flex maxHeight={'300px'} flexDir={'column'} overflowY={'auto'}>
        {detailsFilters?.scores.map((score) => {
          return (
            <Flex
              key={score?.eid}
              gap={4}
              alignItems={'center'}
              padding={'0.5rem 0px'}
            >
              <Checkbox
                value={score?.eid}
                onChange={(e) => handleScoreSelection?.(e, score)}
                isChecked={
                  detailsFilters?.selectedScoresId?.includes(score?.eid)
                    ? true
                    : false
                }
              >
                <Flex alignItems={'center'} gap={4}>
                  <Flex justifyContent={'center'} flexDir={'column'}>
                    <Text fontSize={'14px'}>{score.name}</Text>
                  </Flex>
                </Flex>
              </Checkbox>
            </Flex>
          );
        })}
      </Flex>
    </>
  );
};

export default DetailsScoresFilter;
