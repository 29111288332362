import React, { FC } from 'react';
import { Box, Flex, PopoverBody } from '@chakra-ui/react';
import { useController, useWatch } from 'react-hook-form';
import { FlexCard } from '../../../../../../../../atoms';

import { ReactComponent as JobIcon } from '../../../../../../../../assets/images/note.svg';
import { ReactComponent as LocIcon } from '../../../../../../../../assets/images/location.svg';
import ByRoleSelect from './ByRoleSelect';
import ByLocationSelect from './ByLocationSelect';
import { MFilterInput } from './member-filter.types';

interface ISideItem {
  title: string;
  icon: React.ReactNode;
  haveValue?: number;
  isSelected?: boolean;
  onClick?: () => void;
}

const SideItem: FC<ISideItem> = ({
  title,
  haveValue,
  icon,
  isSelected,
  onClick,
}) => {
  return (
    <Flex
      gap={3}
      padding={3}
      align='center'
      cursor='pointer'
      aria-selected={isSelected}
      border='1px solid'
      borderColor='transparent'
      _selected={{
        bg: '#FCFCFC',
        borderBottomColor: '#EFEFEF',
      }}
      onClick={onClick}
    >
      {icon}
      <Box fontSize='15px' fontWeight='600' color='#272B30'>
        {title}
      </Box>
      {haveValue ? (
        <Box boxSize='6px' bg='#2A85FF' borderRadius='full' />
      ) : null}
    </Flex>
  );
};

interface IProps {
  index: number;
}

const MemberFilterContent: FC<IProps> = ({ index }) => {
  const { field } = useController<
    MFilterInput,
    `assignees.${number}.memberFilter.selectedTab`
  >({
    name: `assignees.${index}.memberFilter.selectedTab`,
    defaultValue: 'job',
  });

  const [roleValues, locationValues] = useWatch<
    MFilterInput,
    [
      `assignees.${number}.memberFilter.byRole.values`,
      `assignees.${number}.memberFilter.byLocation.values`
    ]
  >({
    name: [
      `assignees.${index}.memberFilter.byRole.values`,
      `assignees.${index}.memberFilter.byLocation.values`,
    ],
  });

  return (
    <PopoverBody paddingInline={4} paddingBlock={4} minWidth='500px'>
      <Flex gap={4}>
        <FlexCard
          flex={2}
          flexDir='column'
          bg='#F4F4F4'
          padding='16px 12px'
          gap={2}
        >
          <SideItem
            title='By Job'
            haveValue={roleValues?.length}
            icon={<JobIcon width={24} height={24} />}
            isSelected={field.value === 'job'}
            onClick={() => field.onChange('job')}
          />
          <SideItem
            title='By location'
            haveValue={locationValues?.length}
            icon={<LocIcon width={24} height={24} />}
            isSelected={field.value === 'location'}
            onClick={() => field.onChange('location')}
          />
        </FlexCard>
        <Flex flex={3} flexDir='column' height='255px'>
          {field.value === 'job' ? <ByRoleSelect index={index} /> : null}
          {field.value === 'location' ? (
            <ByLocationSelect index={index} />
          ) : null}
        </Flex>
      </Flex>
    </PopoverBody>
  );
};

export default MemberFilterContent;
