import { Box, Center, Flex, Image } from '@chakra-ui/react';
import QuizStart from 'assets/images/quiz-init.png';
import React, { FC } from 'react';

interface IProps {}

const QuizInitScreen: FC<IProps> = () => {
  return (
    <Flex flexDir='column' w='full' h='full'>
      <Center flexDir='column' h='60vh'>
        <Image src={QuizStart} boxSize='185px' />
        <Box as='span' fontWeight={600} fontSize='20px'>
          It's quiz time!
        </Box>
      </Center>
    </Flex>
  );
};

export default QuizInitScreen;
