import React, { FC } from 'react';
import { Box } from '@chakra-ui/react';
import { useQuery } from '@apollo/client';

import { SOP_PREVIEW_QUERY } from './sop-resource.graphql';
import PreviewEmpty from '../form-resource/PreviewEmpty';
import TrainingDesktop from '../../../../../../pages/Training/CreateTrainingPath/Preview/component/TrainingDesktop';

import { ReactComponent as ChapterPreview } from 'assets/images/training/chapter-preview.svg';

interface IProps {
  sopId?: string;
}

const SopPreview: FC<IProps> = ({ sopId }) => {
  const { loading, data } = useQuery(SOP_PREVIEW_QUERY, {
    skip: !sopId,
    variables: {
      eid: sopId,
      override: true,
    },
  });

  const previewData = data?.SopById;

  return (
    <Box
      flex={1}
      overflow='hidden'
      sx={{
        '.training-desktop': {
          height: '100%',
          paddingInline: '12px',
          paddingBottom: '16px',
          '.embed-container': {
            paddingInline: 0,
          },
          '.pdf-container': {
            paddingInline: 0,
            height: 'max-content',
          },
          '.pdf-container > div': {
            padding: 0,
          },
        },
      }}
    >
      {previewData?.eid ? (
        <TrainingDesktop value={{ type: 'sop', ...previewData }} isPreview />
      ) : (
        <PreviewEmpty
          isLoading={loading}
          emptyIcon={ChapterPreview}
          emptyTitle='Click on a chapter to preview.'
        />
      )}
    </Box>
  );
};

export default React.memo(SopPreview);
