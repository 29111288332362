import { createContext, FC, useContext, useState } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import { useToast } from '@chakra-ui/react';

import { FormCategory, StringOrNumber } from '../../../../types';
import {
  bulkFormsDelete,
  bulkResponseExport,
  getCategoryFormCount,
} from './delete.query-helper';
import { DELETE_CATEGORY_QUERY } from './delete.graphql';
import { getFormCategories } from '../../common';

export type Action =
  | 'deleteConfirm'
  // | 'deleteAllForms'
  | 'delete'
  | 'move'
  | 'backToMain'
  | 'exportAndDelete'
  | 'deleteForms'
  | 'formDeletedSuccess'
  | 'deleteCategory'
  | 'exportFormDeleting';

export interface IDataProps {
  category?: FormCategory;
  formCount?: number;
  formIds?: StringOrNumber[];
}

interface IDeleteContext {
  category: FormCategory;
  action?: Action;
  actionHandler?: (action: Action, data?: IDataProps) => Promise<void> | void;
  formCount?: number;
  totalFormCount?: number;
  formIds: StringOrNumber[];
  onCategoryDeleted?: () => Promise<void>;
}

export const DeleteContext = createContext<IDeleteContext>(
  {} as IDeleteContext
);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useDeleteContext = () => useContext(DeleteContext);

interface IProps {
  category: FormCategory;
  formCount?: number;
  onClose?: () => void;
  onCategoryDeleted?: () => Promise<void>;
}

export const DeleteContextProvider: FC<IProps> = ({
  children,
  category,
  formCount: _formCount,
  onClose,
  onCategoryDeleted,
}) => {
  const [_action, setAction] = useState<Action>();
  const [totalFormCount, setAllFormCount] = useState<number | undefined>(
    _formCount
  );
  const [formCount, setFormCount] = useState<number | undefined>();
  const [formIds, setFormIds] = useState<StringOrNumber[]>([]);

  const client = useApolloClient();

  const toast = useToast({
    position: 'top-right',
    isClosable: true,
    duration: 3000,
  });

  const [deleteCategory] = useMutation(DELETE_CATEGORY_QUERY, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: getFormCategories,
        fetchPolicy: 'network-only',
      },
    ],
    onCompleted: () => {
      toast({
        status: 'success',
        title: 'Success',
        description: 'Form category successfully deleted',
      });
    },
    onError: () => {
      toast({
        status: 'error',
        title: 'Error',
        description: 'Form category deleting failed!',
      });
    },
  });

  const actionHandler = async (action: Action, data?: IDataProps) => {
    console.log(action);
    if (action === 'backToMain') {
      setAction(undefined);
      return;
    }

    // if (action === 'deleteAllForms') {
    //   const count = await getCategoryFormCount(client, category.eid);
    //   setFormCount(count);
    //   setAction('deleteConfirm');
    //   return;
    // }

    if (action === 'delete') {
      await deleteCategory({
        variables: {
          categoryId: category.eid,
        },
      });
      await onCategoryDeleted?.();
      setTimeout(() => onClose?.(), 100);
      return;
    }

    if (action === 'deleteConfirm') {
      // if (data && data.hasOwnProperty('formCount')) {
      //   setFormCount(data?.formCount);
      // }
      if (data && data.hasOwnProperty('formIds')) {
        setFormIds(data?.formIds || []);
        setFormCount(data?.formIds?.length);
      } else {
        setFormIds([]);
        setFormCount(totalFormCount);
      }
      setAction(action);
      return;
    }

    if (action === 'exportAndDelete') {
      setAction(action);
      await bulkResponseExport(client, category.eid, formIds);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setAction('exportFormDeleting');
      await bulkFormsDelete(client, category.eid, formIds);
      setAction('formDeletedSuccess');
      const count = await getCategoryFormCount(client, category.eid);
      setAllFormCount(count);
      await new Promise((resolve) => setTimeout(resolve, 2000));
      if (count > 0) {
        setAction('move');
      } else {
        setAction('deleteCategory');
      }
      return;
    }

    if (action === 'deleteForms') {
      setAction(action);
      await bulkFormsDelete(client, category.eid, formIds);
      setAction('formDeletedSuccess');
      const count = await getCategoryFormCount(client, category.eid);
      setAllFormCount(count);
      await new Promise((resolve) => setTimeout(resolve, 2000));
      if (count > 0) {
        setAction('move');
      } else {
        setAction('deleteCategory');
      }
      return;
    }
    setAction(action);
  };

  return (
    <DeleteContext.Provider
      value={{
        category,
        actionHandler,
        action: _action,
        formCount,
        totalFormCount,
        formIds: formIds,
        onCategoryDeleted,
      }}
    >
      {children}
    </DeleteContext.Provider>
  );
};
