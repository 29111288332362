import React, { FC } from 'react';
import { useWatch } from 'react-hook-form';
import { Box, Flex } from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { TFormInput } from '../../training-input.types';

interface IProps {}

const AssignInfoForNewEmployee: FC<IProps> = () => {
  const assigneeUserType = useWatch<TFormInput, 'assigneeUserType'>({
    name: 'assigneeUserType',
  });

  if (!(assigneeUserType === 'new' || assigneeUserType === 'oldAndNew')) {
    return null;
  }

  return (
    <Flex fontWeight='600' color='#2A85FF' gap='10px' align='center'>
      <InfoOutlineIcon boxSize='16px' />
      <Box>
        The training path will be assigned to new employees as soon as they
        join.
      </Box>
    </Flex>
  );
};

export default AssignInfoForNewEmployee;
