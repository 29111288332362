import React, { FC, useMemo } from 'react';
import { AddIcon } from '@chakra-ui/icons';
import { Box, Flex, Spacer, useModalContext } from '@chakra-ui/react';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import { cloneDeep } from '@apollo/client/utilities';
import { eventBus } from 'shared/eventEmit';
import { ActionButton } from '../../../../../../ui-components';
import { TrainingContType } from '../../../../../../types';
import { useIdGenerator, useUserDataSelector } from '../../../../../../hooks';
import { toArray, toNumber } from '../../../../../../utils';
import { IQuizQuestion } from '../../training.graphql';
import { QuizFormInput } from './quiz-resource.graphql';

interface IFormInput {
  type: TrainingContType;
  title: string;
  thumbnail?: string;
  addedBy?: string;
  questions: IQuizQuestion[];
}

interface IProps {
  onAddClick?: (value: IFormInput) => void;
}

const QuizFooter: FC<IProps> = ({ onAddClick }) => {
  const { onClose } = useModalContext();
  const userData = useUserDataSelector((state) => ({
    entityId: state.entityId,
    authUserId: state.eid,
  }));
  const generateId = useIdGenerator();
  const { handleSubmit } = useFormContext<QuizFormInput>();
  const isDirty = useFormState().isDirty;

  const questionList = useWatch<QuizFormInput, 'questions'>({
    name: 'questions',
  });

  const points = useMemo(() => {
    return toArray(questionList).reduce(
      (acc, val) => acc + toNumber(val.score, 0),
      0
    );
  }, [questionList]);

  const onSaveAndExit = async (values: QuizFormInput) => {
    const _values = cloneDeep(values);

    // @ts-ignore
    if (!_values?.eid) {
      // @ts-ignore
      _values.eid = await generateId();
    }

    onAddClick?.({
      ..._values,
      type: TrainingContType.QUIZ,
      addedBy: userData.authUserId,
    });
    await new Promise((resolve) => setTimeout(resolve, 50));
    onClose();
  };

  return (
    <Flex
      gap={5}
      paddingY='10px'
      paddingX={4}
      bgColor='#FCFCFC'
      borderTop='1px solid #EFEFEF'
      borderBottomRadius='8px'
    >
      {questionList?.length ? (
        <Flex align='end' gap='3px' marginLeft={3}>
          <Box
            as='span'
            fontSize='20px'
            fontWeight='600'
            lineHeight='22px'
            color='#111315'
          >
            {questionList?.length}
          </Box>
          <Box
            as='span'
            fontSize='16px'
            fontWeight='500'
            color='#111315'
            lineHeight='20px'
          >
            {questionList?.length === 1 ? 'question' : 'questions'}
          </Box>
          {points ? (
            <Box as='span' fontSize='12px' fontWeight='500' color='#33383F'>
              ({points} {points === 1 ? 'point' : 'points'})
            </Box>
          ) : null}
        </Flex>
      ) : null}
      <Spacer />

      <ActionButton
        variant='outline'
        fontSize='13px'
        leftIcon={<AddIcon />}
        isDisabled={!isDirty}
        actionFn={async () => {
          const res = await new Promise((resolve) => {
            eventBus.emit('ADD_ANOTHER_QUESTION', resolve);
            setTimeout(resolve, 1000, false);
          });
          if (!res) {
            document
              .getElementById('quiz-question-list')
              ?.lastElementChild?.scrollIntoView({ behavior: 'smooth' });
          }
        }}
      >
        Add another question
      </ActionButton>

      <ActionButton
        fontSize='13px'
        colorScheme='blue'
        isDisabled={!isDirty || !questionList?.length}
        actionFn={handleSubmit(onSaveAndExit)}
      >
        Publish quiz
      </ActionButton>
    </Flex>
  );
};

export default QuizFooter;
