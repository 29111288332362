import React, { FC } from 'react';
import { useToast } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useUploadImage } from '../../../../../../hooks';
import { generateVideoThumbnail } from '../../../../../../utils/videoThumbnail';
import {
  EXT_RENAME_REGEX,
  FILENAME_REGEX,
  VIMEO_REGEX,
  YOUTUBE_REGEX,
} from '../../../../../../utils/constants';
import {
  getVideoUrlFileSize,
  getWebpFilename,
  validateIsVideo,
} from '../../../../../../utils';
import { DropzoneInput } from '../common';
import { VideoFormInput } from './video-resource.graphql';
import ThumbnailView from './ThumbnailView';
import { getVimeoDetails, getYoutubeDetails } from './video.helpers';

const FIFTY_MB = 50000000;
const SIX_HUNDRED_MB = 600000000;

interface IProps {
  value?: string;
  onChange?: (value: string) => void;
}

const VideoThumbnail: FC<IProps> = ({ value, onChange }) => {
  const { setValue, setError, trigger } = useFormContext<VideoFormInput>();
  const uploadImage = useUploadImage();
  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  });
  async function getThumbnailFromUrl(videoUrl: string, video?: File | null) {
    try {
      if (YOUTUBE_REGEX.test(videoUrl)) {
        const thumbNail = videoUrl
          .split(videoUrl.includes('youtu.be') ? '/' : 'v=')
          .pop()
          ?.split('?')[0];
        return Promise.resolve(`https://img.youtube.com/vi/${thumbNail}/0.jpg`);
      }

      const blobData = await generateVideoThumbnail(
        video || videoUrl,
        0.5,
        true
      );

      // const videoName = videoUrl?.split(FILENAME_REGEX).pop();

      const thumbnailName = video
        ? video?.name?.replace(EXT_RENAME_REGEX, '.webp')
        : getWebpFilename(videoUrl.split('/').pop() || 'video-thumbnail.jpg');

      return await uploadImage(blobData, thumbnailName);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  function getVideoDetails(videoUrl: string) {
    if (YOUTUBE_REGEX.test(videoUrl)) {
      return getYoutubeDetails(videoUrl);
    }
    if (VIMEO_REGEX.test(videoUrl)) {
      return getVimeoDetails(videoUrl);
    }
  }

  const _onVideoChange = async (videoUrl: string, file?: File) => {
    if (onChange) {
      if (file) {
        toast({ status: 'success', title: 'Video uploaded successfully!' });
        setValue('videoFileSize', file.size);
        setValue('videoFileName', file.name);
        const thumbnail = await getThumbnailFromUrl(videoUrl, file);
        thumbnail && setValue('thumbnail', thumbnail);
      } else {
        const videoDetails = await getVideoDetails(videoUrl);

        if (videoDetails) {
          setValue('thumbnail', videoDetails.thumbnail_url);
          setValue('videoFileName', videoDetails.title);
        } else {
          const thumbnail = await getThumbnailFromUrl(videoUrl, file);
          thumbnail && setValue('thumbnail', thumbnail);
          setValue('videoFileName', videoUrl?.split('/')?.pop());
        }

        const videoFileSize = await getVideoUrlFileSize(videoUrl);
        setValue('videoFileSize', videoFileSize);
      }

      onChange?.(videoUrl);
      trigger('videoUrl');
    }
  };

  const validateLink = async (videoUrl: string) => {
    try {
      if (YOUTUBE_REGEX.test(videoUrl) || VIMEO_REGEX.test(videoUrl)) {
        return Promise.resolve();
      }
      await validateIsVideo(videoUrl);
    } catch (e) {
      setError('videoUrl', {
        type: 'validate',
        message: 'Please enter a valid video URL',
      });
      return Promise.reject(e);
    }
  };

  if (value) {
    return (
      <ThumbnailView
        value={value}
        onRemoveClick={() => {
          onChange?.(null as never);
          setValue('videoFileSize', null as never);
          setValue('videoFileName', null as never);
        }}
      />
    );
  }

  return (
    <DropzoneInput
      maxSize={SIX_HUNDRED_MB}
      caption='MP4, WMV · Max size 600Mb'
      accept={{
        'video/*': ['.mp4', '.mov', '.wmv'],
      }}
      inputPlaceholder='or paste a link from youtube, vimeo etc...'
      onFileChange={_onVideoChange}
      onError={(err) => setError('videoUrl', err)}
      validateLink={validateLink}
    />
  );
};

export default VideoThumbnail;
