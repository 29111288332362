import moment from 'moment';
import { UserEntityData } from 'shared/graphql/shared-types';
import {
  UserTrainingPathSessionsType,
  TrainingData,
  TrainingCategoriesType,
  SkillVerifierType,
} from './types/myTraining.types';

type prepareTrainingPathSessionDataType = (
  data: UserTrainingPathSessionsType[],
  categories: TrainingCategoriesType[],
  entityObjData?: UserEntityData[]
) => TrainingData[];

// function to prepare training data as per we need to show in the table
export const prepareTrainingPathSessionData: prepareTrainingPathSessionDataType =
  (data, categories, entityObjData) => {
    const finalData: TrainingData[] = data?.map((item) => {
      const progressStatus = getProgress(item);
      const scoreData = getScore(item);

      //@ts-ignore
      return {
        key: item?.eid,
        pathName: item?.title ?? '',
        certificate: item?.userProgress?.[0]?.certificate,
        hasSkillVerifier: item?.hasSkillVerifier,
        certificateDownloaded: item?.assignedUsers?.[0]?.certificateDownloaded,
        evaluationViewed: item?.assignedUsers?.[0]?.evaluationViewed,
        isEvaluated: item?.assignedUsers?.[0]?.isEvaluated,
        progress: progressStatus,
        status: getTrainingStatus(
          item?.startDate,
          item?.assignedUsers?.[0]?.dueDate,
          item?.endDate,
          item?.isRepeating,
          item,
          item?.contents?.length,
          item?.updatedAt,
          item?.assignedUsers?.[0]?.completedOn,
          item?.assignedUsers?.[0]?.status
        ),
        score: scoreData?.score,
        maxScore: scoreData?.maxScore,
        lastUpdated: moment(item?.updatedAt).format('D MMM') ?? '-',
        thumbnail: item?.training?.thumbnail,
        eid: item?.eid,
        trainingId: item?.trainingId,
        categoryId: item?.training?.category,
        categoryName:
          getTrainingCategoryName(item?.training?.category, categories) ?? '-',
        tpTerminatedStatus: item?.training?.status,
        createdBy: entityObjData
          ? getTrainingCreatedByName(item?.createdBy, entityObjData)
          : '-',
        createdOn: item?.createdAt,
      } as TrainingData;
    });

    return finalData;
  };

//function to add skill verifier rating and the score
function getScore(item: UserTrainingPathSessionsType) {
  const userProgress = item?.userProgress?.[0];
  const skillVerifierRatings = userProgress?.skillVerifierResponse ?? [];

  // Calculate skill score (sum of ratings)
  const skillScore =
    skillVerifierRatings?.reduce(
      (acc: number, currValue: SkillVerifierType) =>
        acc + (currValue?.rating ?? 0),
      0
    ) ?? 0;

  // Calculate maximum possible skill score
  const skillMaxScore = skillVerifierRatings?.length * 10 ?? 0;

  // Calculate user progress score
  const userScore = userProgress?.score ?? 0;

  // Final score is either user score + skill score or just the skill score if no user score
  const score = userScore + skillScore;

  // Calculate maximum score
  const maxScore = userProgress?.isCompleted
    ? (userProgress?.maxScore ?? 0) + skillMaxScore
    : (item?.maxScore ?? 0) + skillMaxScore;

  return {
    score,
    maxScore,
  };
}

// function to find the category name for a training path from all training categories
function getTrainingCategoryName(
  categoryId: string,
  categories: TrainingCategoriesType[]
) {
  const foundCategory = categories?.find((item) => item?.eid === categoryId);
  return foundCategory?.name;
}

// function to find new status of a task
function getTrainingStatus(
  startDate: string,
  dueDate: string,
  endDate: string,
  isRepeating: boolean,
  item: any,
  totalContentCount: number,
  updatedAt: string,
  completedOn: string,
  apiStatus: string
) {
  const statusObj = {
    title: '',
    dueDate: '',
    iconType: '',
    iconTitle: '',
  };

  const targetDate = dueDate || endDate;

  const today = moment();

  // Check for "Completed" status
  if (apiStatus === 'completed') {
    statusObj.title = 'Completed';
    statusObj.dueDate = completedOn
      ? `On ${moment(completedOn)?.format('Do MMM')}`
      : '-';
    statusObj.iconType = 'retake';
    statusObj.iconTitle = 'Retake';
    return statusObj;
  }

  // Check for "Overdue"
  const isOverdue = targetDate && today.isAfter(moment(targetDate));
  if (isOverdue && (apiStatus === 'assigned' || apiStatus === 'started')) {
    // Calculate the difference in days
    const differenceInDays = today.diff(moment(targetDate), 'minutes');
    let dueDateString =
      differenceInDays > 10080
        ? `since ${moment(targetDate)?.format('Do MMM')}`
        : `by ${convertMinutesToTime(differenceInDays)}`;
    statusObj.dueDate = dueDateString;
    statusObj.title = 'Overdue';
    statusObj.iconType = 'play';
    statusObj.iconTitle = 'Play';
    return statusObj;
  }

  // Check for "In progress" status
  if (apiStatus === 'started') {
    if (targetDate) {
      const daysDifference = moment(targetDate).diff(today, 'minutes');
      let dueDateString =
        daysDifference > 10080
          ? `Due on ${moment(targetDate)?.format('Do MMMM')}`
          : `Due in ${convertMinutesToTime(daysDifference)}`;
      statusObj.dueDate = dueDateString;
    } else {
      statusObj.dueDate = 'No Deadline';
    }
    statusObj.title = 'In progress';
    statusObj.iconType = 'resume';
    statusObj.iconTitle = 'Resume';

    return statusObj;
  }

  // Check for "Yet to start" status
  if (apiStatus === 'assigned') {
    // Calculate the difference in days
    if (targetDate) {
      const differenceInDays = moment(targetDate).diff(today, 'minutes');
      let dueDateString =
        differenceInDays > 10080
          ? `Due on ${moment(targetDate)?.format('Do MMMM')}`
          : `Due in ${convertMinutesToTime(differenceInDays)}`;
      statusObj.dueDate = dueDateString;
    } else {
      statusObj.dueDate = 'No Deadline';
    }
    statusObj.title = 'Yet to start';
    statusObj.iconType = 'play';
    statusObj.iconTitle = 'Play';
    return statusObj;
  }
}

// function to get the progress of the TP
function getProgress(item: UserTrainingPathSessionsType) {
  const completedContents = item?.userProgress?.[0]?.progress?.filter(
    (item) => item?.isCompleted === true && item?.type !== 'milestone'
  );

  const totalContents = item?.contents?.filter(
    (content) => !content?.isRemoved && content?.type !== 'milestone'
  );

  // if completed then show both completed and total as same, because in edit flow more content can be manipulated leading to inconsistency
  if (item?.userProgress?.[0]?.isCompleted) {
    return {
      current: completedContents?.length ?? 0,
      total: completedContents?.length ?? 0,
    };
  }
  return {
    current: completedContents?.length ?? 0,
    total: totalContents?.length ?? 0,
  };
}

export function convertMinutesToTime(minutes: number): string {
  if (minutes === 0) return '0min';

  const units = [
    { name: 'y', value: 60 * 24 * 365 },
    { name: 'm', value: 60 * 24 * 30 },
    { name: 'd', value: 60 * 24 },
    { name: 'h', value: 60 },
    { name: 'min', value: 1 },
  ];

  for (const unit of units) {
    if (unit.name === 'd') {
      const unitValue = Math.ceil(minutes / unit.value);
      if (unitValue > 0) {
        return `${unitValue}${unit.name}`;
      }
    } else {
      const unitValue = Math.floor(minutes / unit.value);
      if (unitValue > 0) {
        return `${unitValue}${unit.name}`;
      }
    }
  }

  return '0min';
}

// function to find the created by name for a training path from all business users
function getTrainingCreatedByName(
  createdById: string,
  users: UserEntityData[]
) {
  const foundCreatedByName = users?.find((item) => item?.eid === createdById);
  return foundCreatedByName?.name;
}
