import { Flex } from '@chakra-ui/react';
import React, { FC } from 'react';
import { ILocationDetails } from '../../utils/location-details.types';
import LocationContactDetails from './Components/LocationContactDetails';
import LocationDetailsComponent from './Components/LocationDetailsComponent';
import EntityDetails from './Components/EntityDetails';
import OtherDetails from './Components/OtherDetails';
import { ApolloQueryResult } from '@apollo/client';

interface IProps {
  details: ILocationDetails | undefined;
  refetch:
    | ((
        variables?:
          | Partial<{
              eid: string;
            }>
          | undefined
      ) => Promise<
        ApolloQueryResult<{
          userById: ILocationDetails;
        }>
      >)
    | undefined;
}

const LocationDetailsCard: FC<IProps> = ({ details, refetch }) => {
  return (
    <Flex flexDir='column' gap={6} w='full'>
      <LocationContactDetails details={details} />
      <LocationDetailsComponent details={details} refetch={refetch} />
      <EntityDetails details={details} />
      <OtherDetails details={details} />
    </Flex>
  );
};

export default LocationDetailsCard;
