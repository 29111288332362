import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import {
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  VStack,
  Box,
  Flex,
  IconButton,
  Tab,
} from '@chakra-ui/react';
import { FlexCard } from 'atoms';
import { CustomTab } from 'sub-components/nexus/NexusBase/components/LeftSection/components/LocationLeaderboard/components';
import LocationList from './LocationList';
import MemberList from './MemberList';
import LocationFilters from './LocationFilters';
import { useUserDataSelector } from 'hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { AuthRole } from 'sop-commons/src/client';
import { GET_OVERALL_USER_WISE_ANALYTICS } from 'sub-components/training-v2/shared/query/dashboard.graphql';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { IFinalOverallUserWiseAnalytics } from 'sub-components/training-v2/shared/types';
import { usersEntityObj } from 'sub-components/Header';
import {
  prepareLeaderboardMemberData,
  sortArray,
  trainingprepareLeaderboardMemberData,
} from './helper';
import { useTrainingContext } from '../TrainingTableContainer';
import useCombinedStore from 'zustandStore/store';
import Loader from 'sub-components/Loader';

interface TabConfig {
  label: string;
  minWidth: string;
  hasNewBadge?: boolean;
}

interface IProps {
  overallAnalyticsLoader: boolean;
}

const Leaderboard: FC<IProps> = ({ overallAnalyticsLoader }) => {
  const [overallUserWiseData, setOverallUserWiseData] = useState<
    IFinalOverallUserWiseAnalytics[]
  >([]);
  const [finalMemberData, setFinalMemberData] = useState({});
  const [renderMemberData, setRenderMemberData] = useState([]);
  const [selectedTab, setSelectedTab] = useState<string>('Members');
  const [selectedLocation, setSelectedLocation] = useState<string>('all');
  const containerRef = useRef<HTMLDivElement | null>(null);
  const tabsConfig = useMemo((): TabConfig[] => {
    return [
      { label: 'Members', minWidth: '176px' },
      { label: 'Locations', minWidth: '176px', hasNewBadge: false },
    ];
  }, []);

  const { locationIdArray } = useCombinedStore();
  const entityObjData = useReactiveVar(usersEntityObj);

  const {
    userAuthRole,
    loggedInUserLocations,
    loggedInUserId,
    businessLocations,
  } = useUserDataSelector((state) => ({
    userAuthRole: state?.authRole,
    loggedInUserLocations: state?.locations,
    loggedInUserId: state?.eid,
    businessLocations: state?.entity?.locations,
  }));

  // Scroll left
  const scrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft -= 200;
    }
  };

  // Scroll right
  const scrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft += 200;
    }
  };

  const [getOverallUserWiseAnalytics, { loading: userWiseLoader }] =
    useLazyQuery(GET_OVERALL_USER_WISE_ANALYTICS, {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setOverallUserWiseData(data?.TpOverallUserWiseAnalytics);
      },
    });

  useEffect(() => {
    const leaderboardMemberData = trainingprepareLeaderboardMemberData(
      overallUserWiseData,
      entityObjData,
      loggedInUserLocations
    );

    setFinalMemberData(leaderboardMemberData);
    setRenderMemberData(leaderboardMemberData?.all);
  }, [entityObjData, overallUserWiseData]);

  useEffect(() => {
    setRenderMemberData(finalMemberData?.[selectedLocation]);
  }, [selectedLocation]);

  useEffect(() => {
    getOverallUserWiseAnalytics();
  }, []);

  // Logic to scroll the location filter chip, to the location which is selected from the header location filter
  useEffect(() => {
    if (containerRef.current && selectedLocation !== 'all') {
      const locationElement = containerRef.current.querySelector(
        `[data-location="${selectedLocation}"]`
      ) as HTMLElement;

      if (locationElement) {
        locationElement.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      }
    }
  }, [locationIdArray]);

  return (
    <FlexCard
      width={'full'}
      height={'100%'}
      border={'2px solid #EFEFEF'}
      className='location-leaderboard-container'
    >
      <VStack align='start' w='full'>
        <Box
          fontWeight='600'
          fontSize={'20px'}
          lineHeight='32px'
          color='#1a1d1f'
        >
          Leaderboard
        </Box>
        <Tabs variant='unstyled' w='full'>
          <TabList display='flex' w='100%' gap='2'>
            {tabsConfig.map((tab, index) => {
              return (
                <Flex
                  key={index}
                  w='50%'
                  justifyContent='center'
                  onClick={() => setSelectedTab(tab?.label)}
                >
                  <CustomTab
                    label={tab.label}
                    minWidth={tab.minWidth}
                    hasNewBadge={tab.hasNewBadge}
                  />
                </Flex>
              );
            })}
          </TabList>

          {selectedTab === 'Members' && (
            <Flex alignItems={'center'} mt={4}>
              <Box cursor={'pointer'} onClick={scrollLeft}>
                <FontAwesomeIcon
                  color='#6F767E'
                  icon={faChevronLeft as IconProp}
                />
              </Box>
              <LocationFilters
                ref={containerRef}
                finalMemberData={finalMemberData}
                loggedInUserLocations={loggedInUserLocations}
                setSelectedLocation={setSelectedLocation}
                selectedLocation={selectedLocation}
              />
              <Box cursor={'pointer'} onClick={scrollRight}>
                <FontAwesomeIcon
                  color='#6F767E'
                  icon={faChevronRight as IconProp}
                />
              </Box>
            </Flex>
          )}

          {overallAnalyticsLoader ? (
            <Flex
              height={'40vh'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <Loader />
            </Flex>
          ) : (
            <TabPanels>
              {tabsConfig.map((tab, index) => (
                <TabPanel key={index}>
                  {tab.label === 'Members' && (
                    <MemberList
                      tab={tab.label}
                      renderMemberData={renderMemberData}
                      selectedLocation={selectedLocation}
                    />
                  )}
                  {tab.label === 'Locations' && (
                    <LocationList
                      tab={tab.label}
                      businessLocations={businessLocations}
                      loggedInUserLocations={loggedInUserLocations}
                    />
                  )}
                </TabPanel>
              ))}
            </TabPanels>
          )}
        </Tabs>
      </VStack>
    </FlexCard>
  );
};

Leaderboard.displayName =
  'packages/sop-web/src/sub-components/training-v2/dashboard/components/locationOwner/Leaderboard';
export default Leaderboard;
