import React, { FC, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Center,
  Flex,
  Stack,
  Text,
  toast,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import {
  ApolloClient,
  DocumentNode,
  useApolloClient,
  useQuery,
  useReactiveVar,
} from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCalendarLines,
  faCalendarXmark,
  faCheck,
  faRepeat,
} from '@fortawesome/pro-light-svg-icons';
import {
  faClockFour,
  faEllipsis,
  faPenToSquare,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AuthRole, userObj } from 'sop-commons/src/client';

import { AmplitudeEvent, deployEvent } from 'shared';

import RoundProgress from '../../Components/sub-components/RoundProgress';
import {
  ISupervisedListDataEntity,
  ISupervisedTasksEntity,
} from '../../tasks.types';
import {
  ActionMenu,
  ActionMenuItem,
  IActionMenuData,
  MenuItemWrapper,
} from '../../../../ui-components/ActionMenu';

import OpenIcon from '../../../../assets/images/open.png';
import CopyIcon from 'assets/images/copy.svg';
import ArchiveIcon from 'assets/images/archive.svg';
import DeletebinIcon from 'assets/images/deleteBin.svg';
import { faHexagonMinus } from '@fortawesome/pro-regular-svg-icons';
import DeleteIcon from 'assets/images/trash-red.svg';
import MoreTaskAssignees from './MoreTaskAssignees';
import {
  ITaskAction,
  RepeatDetails,
} from 'sub-components/tasks/create-task/task.types';
import { taskRepeat } from 'utils/taskRepeat';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { getDateInUserTimezone } from '../../helper';
import moment from 'moment-timezone';
import { showTimeText } from 'pages/TasksDashboard/TaskDetails/components/Details/Details';
import TaskStatusIcon from 'ui-components/TaskStatusIcon';
import { ITaskStatus } from 'ui-components/TaskStatusIcon/types';
import { CommonAmplitudeEvents } from 'shared/amplitudeEvents';
import { IDescriptiveActionMenuData } from 'ui-components/ActionMenu/action-menu.types';
import {
  TaskDeleteContent,
  TaskRestartContent,
  TaskTerminateContent,
  useTaskActionModal,
} from 'sub-components/tasks/create-task/delete';
import { TASKS_CREATE_URL } from 'appRoutes';
import {
  terminateTaskQuery,
  deleteTaskQuery,
  restartTaskQuery,
  ActionVariable,
} from 'sub-components/tasks/create-task/task.graphql';
import { toEditableTask } from 'sub-components/tasks/edit-task/dataConvertToEditable';
import {
  TaskEditableResponse,
  TASK_FOR_EDIT_QUERY,
} from 'sub-components/tasks/edit-task/task.graphql';
import { GET_SUPERVISED_BY_ME_TASKS } from '../services/supervised-tasks.graphql';
import {
  getTaskById,
  getTaskByIdForMyTaskDetails,
} from 'pages/TasksDashboard/MyTasks/services/MyTasks.service';
import { useActivateTask } from 'sub-components/tasks/shared/activate-task-modal';

interface IProps {
  backToTitle?: string;
  task: ISupervisedListDataEntity;
  /** Used to truncate the repeat string. Default will be 'FALSE' */
  truncRepeat?: boolean;
}

const SupervisedTaskItem: FC<IProps> = ({ backToTitle, task, truncRepeat }) => {
  const { t, i18n } = useTranslation(['common', 'task']);
  const history = useHistory();
  const userData = useReactiveVar(userObj);
  const [data, moreAssignees] = useMemo(() => {
    const firstThree = task?.userProgressData?.slice(0, 2) || [];
    const rest = task?.userProgressData?.slice(2) || [];

    return [firstThree, rest];
  }, [task?.userProgressData]);

  const toast = useToast({
    position: 'top-right',
    isClosable: true,
  });
  const client = useApolloClient();

  const actionModal = useTaskActionModal();
  const activateTask = useActivateTask();
  const { execute, data: taskByData } = getTaskById();
  const { execute: excuteTasks, data: tasksDetails } =
    getTaskByIdForMyTaskDetails();
  const redirectHandler = (type: 'open' | 'edit') => {
    if (type === 'open') {
      let url = '';
      if (task?.sessionId) {
        url = `/tasks/supervised/details/${task.eid}/${task?.sessionId}`;
      } else {
        url = `/tasks/supervised/details/${task.eid}`;
      }
      if (backToTitle) {
        deployEvent(CommonAmplitudeEvents.VIEW_SUPERVISED_BY_ME_TASK);
        history.push(url, { backToTitle: backToTitle });
      } else {
        deployEvent(CommonAmplitudeEvents.VIEW_SUPERVISED_BY_ME_TASK);
        history.push(url);
      }
    } else {
      deployEvent(AmplitudeEvent.TASK_EDIT);
      history.push(`/tasks/edit/${task.eid}`, { backToTitle: backToTitle });
    }
  };
  const handleMutation = async (
    docNode: DocumentNode,
    message: string /**success message */,
    redirectTo?: string
  ) => {
    await client.mutate<never, ActionVariable>({
      mutation: docNode,
      variables: {
        eid: task.eid,
      },
      refetchQueries: [GET_SUPERVISED_BY_ME_TASKS, 'GetSupervisedByMeTasks'],
      awaitRefetchQueries: true,
    });
    toast({
      title: t('common:success'),
      description: message,
      status: 'success',
    });
    if (redirectTo) {
      history.replace(redirectTo);
    }
  };
  const handleAction = (type: ITaskAction) => {
    switch (type) {
      case 'terminate':
        deployEvent(AmplitudeEvent.TASK_TERMINATE);
        return actionModal({
          // @ts-ignore
          title: t('task:terminateNameQues', {
            name: task?.taskDetails?.title,
          }),
          content: (
            <TaskTerminateContent
              customDesc={t('task:terminateTaskModalDesc')}
            />
          ),
          buttonTitle: t('task:terminate'),
          isFullWidth: true,
          colorScheme: 'red',
          onClick: () => {
            return handleMutation(
              terminateTaskQuery,
              t('task:terminateSuccess')
            );
          },
        });
      case 'softDelete':
        deployEvent(AmplitudeEvent.TASK_DELETE);
        return actionModal({
          // @ts-ignore
          title: t('common:deleteNameQues', {
            name: task?.taskDetails?.title,
          }),
          content: <TaskDeleteContent />,
          buttonTitle: t('common:delete'),
          colorScheme: 'red',
          cancelText: 'Terminate task',
          okButtonProps: {
            colorScheme: 'red',
            borderRadius: '8px',
            width: '50%',
            padding: '12px 20px',
          },
          cancelButtonProps: {
            variant: 'outline',
            borderRadius: '8px',
            width: '50%',
            padding: '12px 20px',
            _hover: {
              bgColor: '#EFEFEF80',
            },
            border: '2px solid #EFEFEF80',
          },
          onCancel: () => {
            return handleMutation(
              //terminate is the safe option == ok button
              terminateTaskQuery,
              t('task:terminateSuccess')
            );
          },
          onClick: () => {
            return handleMutation(
              deleteTaskQuery,
              t('task:deleteSuccess'),
              '/tasks/supervised'
            );
          },
        });
      case 'restart':
        deployEvent(AmplitudeEvent.TASK_RESTART);
        return activateTask({
          taskId: task?.eid,
          taskTitle: task?.title,
          onRestart: () => {
            return handleMutation(restartTaskQuery, t('task:restartSuccess'));
          },
          onRestartAndEdit: () => {
            return handleMutation(
              restartTaskQuery,
              t('task:restartSuccess'),
              `/tasks/edit/${task?.eid}`
            );
          },
        });
      // return actionModal({
      //   // @ts-ignore
      //   title: t('task:restartNameQues', {
      //     name: task?.taskDetails?.title,
      //   }),
      //   content: <TaskRestartContent />,
      //   buttonTitle: t('task:restart'),
      //   colorScheme: 'blue',
      //   onClick: () => {
      //     return handleMutation(restartTaskQuery, t('task:restartSuccess'));
      //   },
      // });
    }
  };

  const clickedItemsHandler = (_: string, clickedName: string) => {
    switch (clickedName) {
      case 'open':
        return redirectHandler('open');
      case 'edit':
        return redirectHandler('edit');
      case 'terminate':
        return handleAction('terminate');
      case 'delete':
        return handleAction('softDelete');
      case 'restart':
        console.log({ task: task });
        return handleAction('restart');
      default:
        return redirectHandler('open');
    }
  };

  const backgroundHandler = () => {
    let color = '';
    switch (task?.status) {
      case 'overdue':
        color = '#FF6A551A';
        break;
      case 'dueToday':
        color = '#FFC16233';
        break;
      case 'completed':
        color = '#dcedd6';
        break;
      case 'thisWeek':
      case 'scheduled':
        color = '#EFEFEF';
        break;
      case 'upcoming':
        color = '#B1E5FC33';
        break;
      default:
        color = '#FF6A551A';
        break;
    }
    return color;
  };
  const borderHandler = () => {
    let border = '';
    switch (task?.status) {
      case 'overdue':
        border = '1px solid #FF6A55';
        break;
      case 'dueToday':
        border = '1px solid #FF9B00';
        break;
      case 'thisWeek':
      case 'scheduled':
        border = '1px solid #6F767E';
        break;
      case 'completed':
        border = '1px solid #83BF6E';
        break;
      case 'upcoming':
        border = '1px solid #2A85FF';
        break;
      default:
        border = '1px solid #FF6A55';
        break;
    }
    return border;
  };

  const durationReplace = (durationString: string) => {
    let units: any = {
      seconds: 's',
      second: 's',
      minutes: 'm',
      minute: 'm',
      hours: 'h',
      hour: 'hour',
      days: 'd',
      day: 'd',
    };

    let words = durationString.split(' ');

    return words.map((word) => units[word] || word).join(' ');
  };

  const getStatusHandler = () => {
    if (task?.status === 'completed') return;
    switch (task?.status) {
      case 'scheduled':
        return (
          <Flex
            bg='#EFEFEF'
            color='#6F767E'
            gap='5px'
            p='5px 10px'
            borderRadius='8px'
            align='center'
          >
            <FontAwesomeIcon icon={faClockFour as IconProp} />
            Scheduled
          </Flex>
        );
      case 'overdue':
        return (
          <Flex
            bg='#FF6A5533'
            color='#FF6A55'
            gap='5px'
            p='5px 10px'
            borderRadius='8px'
            align='center'
          >
            <FontAwesomeIcon icon={faClockFour as IconProp} />
            Overdue
          </Flex>
        );
      case 'partiallyDone':
        return (
          <></>
          // <Flex
          //   bg='#FFD88D4D'
          //   color='#FF6B00'
          //   gap='5px'
          //   p='5px 10px'
          //   borderRadius='8px'
          //   align='center'
          // >
          //   <FontAwesomeIcon icon={faClockFour as IconProp} />
          //   Partially ended
          // </Flex>
        );
      case 'dueToday':
        return (
          <Flex
            bg='#EFEFEF'
            color='#6F767E'
            gap='5px'
            p='5px 10px'
            borderRadius='8px'
            align='center'
          >
            <FontAwesomeIcon icon={faClockFour as IconProp} />
            <Text m={0}>{durationReplace(task?.duration!)}</Text>
          </Flex>
        );
      case 'upcoming':
        return (
          <Flex
            bg='#EFEFEF'
            color='#6F767E'
            gap='5px'
            p='5px 10px'
            borderRadius='8px'
            align='center'
          >
            <FontAwesomeIcon icon={faClockFour as IconProp} />
            <Text m={0}>{durationReplace(task?.duration!)}</Text>
            {/* <Text m={0}>{task?.dateMoment?.fromNow(true)}</Text> */}
          </Flex>
        );
      case 'terminated':
        return <></>;
      default:
        return (
          <Flex
            bg='#EFEFEF'
            color='#6F767E'
            gap='5px'
            p='5px 10px'
            borderRadius='8px'
            align='center'
          >
            <FontAwesomeIcon icon={faClockFour as IconProp} />
            <Text m={0}>{durationReplace(task?.duration!)}</Text>
          </Flex>
        );
    }
  };

  const showTerminateOption = () => {
    const status = task.status;
    const lastActiveSession = task?.taskDetails?.lastActiveSession;

    // Case 1: If task is not completed, show terminate option
    if (status !== 'completed') {
      return true;
    }

    // Case 2: If task is completed and non-repeating, don't show terminate option
    if (status === 'completed' && !lastActiveSession?.isRepeating) {
      return false;
    }

    // Case 3: If task is completed, repeating, and not the last session, show terminate option
    if (status === 'completed' && lastActiveSession?.isRepeating) {
      return !lastActiveSession?.isLastSession;
    }

    // Default case: don't show terminate option
    return false;
  };

  const canEdit = () => {
    const status = task.status;
    const lastActiveSession = task?.taskDetails?.lastActiveSession;

    // Must be admin or creator
    const hasPermission =
      userData?.authRole === AuthRole.SUPER_ADMIN ||
      userData?.eid === task.createdBy;
    if (!hasPermission) {
      return false;
    }

    // Non-completed tasks can be edited
    if (status !== 'completed') {
      return true;
    }

    // Completed non-repeating tasks can't be edited
    if (status === 'completed' && !lastActiveSession?.isRepeating) {
      return false;
    }

    // Completed repeating tasks can be edited if not the last session
    if (status === 'completed' && lastActiveSession?.isRepeating) {
      return !lastActiveSession?.isLastSession;
    }

    return false;
  };

  const menuData = useMemo(() => {
    const _menuData: IActionMenuData[] = [
      {
        name: t('common:open'),
        value: 'open',
        icon: OpenIcon,
      },
      // {
      //   name: t('task:duplicateTask'),
      //   value: 'duplicate',
      //   icon: CopyIcon,
      // },
      {
        name: 'divider',
        value: 'divider',
        type: 'divider',
      },
      ...(showTerminateOption()
        ? [
            {
              name: t('task:terminate'),
              value: 'terminate',
              icon: faHexagonMinus,
              normalIcon: 'fontAwesome' as 'fontAwesome',
              desc: t('task:terminateTaskDesc'),
              descColor: '#6F767E',
              showChild: true,
            },
          ]
        : []),
      {
        name: t('common:delete'),
        value: 'delete',
        icon: DeleteIcon,
        descColor: '#6F767E',
        showChild: true,
        desc: t('task:deleteTaskDesc'),
      },
    ];
    const _terminatedTasksMenuData: IActionMenuData[] = [
      {
        name: t('task:supervisedByMeActionMenus.terminated.restartTask'),
        value: 'restart',
        icon: ArchiveIcon,
      },
      {
        name: 'divider',
        value: 'divider',
        type: 'divider',
      },
      {
        name: t('task:supervisedByMeActionMenus.terminated.deleteTask'),
        value: 'delete',
        iconColor: '#6F767E',
        showChild: false,
        icon: DeletebinIcon,
      },
    ];
    if (task?.status === 'terminated') {
      return _terminatedTasksMenuData;
    }
    if (canEdit()) {
      _menuData.splice(1, 0, {
        name: t('common:edit'),
        value: 'edit',
        normalIcon: 'fontAwesome',
        icon: faPenToSquare,
      });
    }

    return _menuData;
  }, [t, i18n.language, userData, task.createdBy]);

  const getTaskStatus = useMemo((): ITaskStatus | undefined => {
    switch (task?.status) {
      case 'completed':
        return 'completed';
      case 'currentRunning':
        return 'inProgress';
      case 'dueToday':
        return 'inProgress';
      case 'overdue':
        return 'overdue';
      case 'partiallyDone':
        return 'partiallyCompleted';
      case 'scheduled':
      case 'upcoming':
        return 'scheduled';
      case 'thisWeek':
        return 'inProgress';
      case 'terminated':
        return 'terminated';
      default:
        return undefined;
    }
  }, [task]);
  return (
    <>
      <Flex align='center' w='full' gap='20px'>
        <Flex
          gap='7px'
          w='50%'
          justify='space-between'
          cursor='pointer'
          onClick={() => redirectHandler('open')}
        >
          <Flex gap='10px'>
            {task?.status !== 'completed' ? (
              // <Box
              //   boxSize='18.5px'
              //   mt='4px'
              //   bg={backgroundHandler()}
              //   borderRadius='full'
              //   border={borderHandler()}
              // />
              <TaskStatusIcon
                status={getTaskStatus}
                iconStyles={{ marginTop: '4px' }}
                imgSize='18px'
              />
            ) : (
              <TaskStatusIcon
                status={'completed'}
                iconStyles={{ marginTop: '4px' }}
                imgSize='18px'
              />
              // <Center
              //   boxSize='18.5px'
              //   mt='4px'
              //   borderRadius='full'
              //   border='1px solid #83BF6E'
              //   bg='#83BF6E1A'
              //   p='1px'
              // >
              //   <FontAwesomeIcon
              //     icon={
              //       task?.taskDetails?.status === 'TASK_TERMINATED'
              //         ? (faXmark as IconProp)
              //         : (faCheck as IconProp)
              //     }
              //     color='#83BF6E'
              //     fontSize={'12px'}
              //   />
              // </Center>
            )}
            <Flex flexDirection='column'>
              <Text m={0}>{task?.title}</Text>
              <Flex gap='10px' align='center'>
                <Flex
                  gap='5px'
                  align='center'
                  borderRadius='5px'
                  h='30px'
                  fontSize='12px'
                  // bg={
                  //   task?.status === 'overdue'
                  //     ? '#FF6A551A'
                  //     : task?.status === 'dueToday'
                  //     ? '#33383F12'
                  //     : '#83BF6E1A'
                  // }
                  color={
                    task?.status === 'terminated'
                      ? '#6F767E'
                      : task?.status === 'overdue'
                      ? '#FF6A55'
                      : task?.status === 'dueToday' ||
                        task?.status === 'completed'
                      ? '#83BF6E'
                      : '#2985ff'
                  }
                >
                  <FontAwesomeIcon
                    icon={
                      (task?.taskDetails?.status !== 'TASK_TERMINATED'
                        ? faCalendarLines
                        : faCalendarXmark) as IconProp
                    }
                  />
                  <Text m={0}>
                    {task?.taskDetails?.status !== 'TASK_TERMINATED'
                      ? task?.status === 'completed'
                        ? `Completed on ${task?.date}`
                        : task?.status === 'scheduled'
                        ? `Will publish on ${getDateInUserTimezone(
                            task?.taskDetails?.startDate
                          )?.format('MMM DD')}`
                        : task?.date
                      : `Terminated on ${getDateInUserTimezone(
                          task?.taskDetails?.terminateDetails?.date
                        )?.format('MMM DD')}`}
                  </Text>
                  <>
                    <Text m={0}>&bull;</Text>
                    <FontAwesomeIcon icon={faRepeat as IconProp} />
                    <Text
                      m={0}
                      textTransform='capitalize'
                      isTruncated={truncRepeat}
                      maxW={truncRepeat ? '150px' : 'unset'}
                    >
                      {/* {task?.repeatString} */}
                      {taskRepeat(task?.repeatDetails as RepeatDetails<Date>)}
                    </Text>
                  </>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex w='50%' align='center' justify='flex-end' gap='20px'>
          {getStatusHandler()}
          <Flex align='center' minW='136px' justify='flex-end'>
            {data?.map((user, index) => {
              return (
                <Tooltip
                  key={index}
                  hasArrow
                  label={`${user?.name} - ${user?.progressPercent?.toFixed(
                    0
                  )}%`}
                  placement='bottom-end'
                >
                  <Box>
                    <RoundProgress
                      img={user?.profilePic}
                      value={+user?.progressPercent?.toFixed(0)}
                      thickness={6}
                      overDue={task?.status === 'overdue'}
                    />
                  </Box>
                </Tooltip>
              );
            })}

            <MoreTaskAssignees
              assignees={moreAssignees}
              redirectHandler={redirectHandler}
            />
          </Flex>

          <ActionMenu
            menuData={menuData}
            arrowSize={10}
            closeOnBlur={true}
            offset={[0, 5]}
            clickedItem={clickedItemsHandler}
          >
            <Box cursor='pointer'>
              <FontAwesomeIcon icon={faEllipsis as IconProp} fontSize='20px' />
            </Box>
          </ActionMenu>
        </Flex>
      </Flex>
    </>
  );
};

export default SupervisedTaskItem;
