import React, { FC } from 'react';
import { Box, Center, Checkbox, Divider, Flex } from '@chakra-ui/react';
import { UserEntityData } from '../../../../../../../shared/graphql/shared-types';

import { ReactComponent as RocketIcon } from '../../../../../../../assets/images/rocket.svg';
import { ReactComponent as ZapIcon } from '../../../../../../../assets/images/zap-light.svg';

interface IProps {
  item: UserEntityData;
}

const LocationOption: FC<IProps> = ({ item }) => {
  const isInDev = item.locationStatus === 'development';
  const Icon = isInDev ? RocketIcon : ZapIcon;
  return (
    <React.Fragment key={item.eid}>
      <Flex
        gap={3}
        height='48px'
        minHeight='48px'
        alignItems='center'
        paddingInline='12px'
        borderRadius='12px'
        // cursor='pointer'
        _hover={{ bg: '#F4F4F3' }}
      >
        <Center
          boxSize='24px'
          borderRadius='5px'
          bg={isInDev ? '#8E59FF' : '#83BF6E'}
        >
          <Icon width={12} height={12} />
        </Center>
        <Box
          flex={1}
          fontSize='15px'
          fontWeight='600'
          isTruncated
          color='#33383F'
        >
          {item.name}
        </Box>
        <Checkbox value={item.eid} size='lg' />
      </Flex>
      <Divider />
    </React.Fragment>
  );
};

export default LocationOption;
