import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { ReactComponent as Sort_ascendingIcon } from 'assets/images/nexus/compliance/sort_ascending.svg';
import { ReactComponent as LocationlaunchiconIcon } from 'assets/images/training/LocationLaunchIcon.svg';
import { ReactComponent as LocationopeniconIcon } from 'assets/images/training/LocationOpenIcon.svg';
import { useUserDataSelector } from 'hooks';
import { FC, useState } from 'react';
import { IFinalOverallLocationWiseAnalytics } from 'sub-components/training-v2/shared/types';
import { sortArray } from './helper';
import EmptyLeaderboardState from '../EmptyLeaderboardState';

interface IRenderMemberListProps {
  data: any[];
  selectedLocation?: string;
}

export const RenderMemberList: FC<IRenderMemberListProps> = ({
  data,
  selectedLocation,
}) => {
  return (
    <>
      {data?.map((item) => {
        return (
          <Flex
            key={item?.eid}
            border={'0.5px solid #EFEFEF'}
            p={'12px 24px'}
            borderRadius={'12px'}
            backgroundColor={'white'}
          >
            <Flex
              width={'100%'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Flex alignItems={'center'} gap={4}>
                <Flex
                  justifyContent={'center'}
                  width={'24px'}
                  height={'24px'}
                  borderRadius={'50%'}
                  backgroundColor={'#6F767E'}
                >
                  <Text fontWeight={600} color={'white'}>
                    {selectedLocation === 'all' ? item?.allUserRank : item.rank}
                  </Text>
                </Flex>
                <Flex flexDir={'column'}>
                  <Text fontWeight={600} fontSize={'13px'}>
                    {item?.name}
                  </Text>
                  <Flex
                    gap={1}
                    alignItems={'center'}
                    fontWeight={400}
                    fontSize={'12px'}
                    color={'#6F767E'}
                  >
                    <Text>{item?.locationName}</Text>
                    <Tooltip label={item?.allLocationLabels}>
                      <Text fontWeight={600} color={'black'} p={'2px'}>
                        {item?.tpLocationCount > 0 &&
                          ` +${item?.tpLocationCount} `}
                      </Text>
                    </Tooltip>
                    &bull; <Text>{item?.userRole}</Text>
                  </Flex>
                </Flex>
              </Flex>
              <Flex>
                <Text fontWeight={600} fontSize={'13px'}>
                  {item?.completionPercent?.toFixed(2)}
                </Text>
                <Text color={'#6F767E'}>%</Text>
              </Flex>
            </Flex>
          </Flex>
        );
      })}
    </>
  );
};

export const RenderLocationList = ({ data }) => {
  const renderIcon = (isOwnLocation: boolean) => {
    if (isOwnLocation) {
      return <LocationlaunchiconIcon />;
    } else {
      <LocationopeniconIcon />;
    }
  };

  return (
    <>
      {data?.map((item: IFinalOverallLocationWiseAnalytics) => {
        return (
          <Flex
            key={item?.locationId}
            border={'0.5px solid #EFEFEF'}
            p={'12px 24px'}
            borderRadius={'12px'}
            backgroundColor={'white'}
          >
            <Flex
              width={'100%'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Flex alignItems={'center'} gap={4}>
                <Flex
                  justifyContent={'center'}
                  width={'24px'}
                  height={'24px'}
                  borderRadius={'50%'}
                  backgroundColor={'#6F767E'}
                >
                  <Text fontWeight={600} color={'white'}>
                    {item?.rank}
                  </Text>
                </Flex>
                <Flex gap={2} alignItems={'center'}>
                  {renderIcon(item?.isOwnLocation)}
                  <Tooltip label={item?.name}>
                    <Text
                      isTruncated
                      width={'80px'}
                      fontWeight={600}
                      fontSize={'13px'}
                    >
                      {item?.name}
                    </Text>
                  </Tooltip>
                  {item?.isOwnLocation && (
                    <Text fontWeight={500} fontSize={'12px'} color={'#2A85FF'}>
                      &bull; Your location
                    </Text>
                  )}
                </Flex>
              </Flex>
              <Flex>
                <Text fontWeight={600} fontSize={'13px'}>
                  {item?.completionPercent?.toFixed(2)}
                </Text>
                <Text color={'#6F767E'}>%</Text>
              </Flex>
            </Flex>
          </Flex>
        );
      })}
    </>
  );
};

interface IProps {
  tab: string;
  overallLocationWiseData?: IFinalOverallLocationWiseAnalytics[];
  renderMemberData?: any[];
  selectedLocation?: string;
}

const BaseListRender: FC<IProps> = ({
  tab,
  overallLocationWiseData,
  renderMemberData,
  selectedLocation,
}) => {
  const [sortType, setSortType] = useState<'asc' | 'desc'>('desc');

  const handleSortToggle = () => {
    setSortType((prevState) => {
      if (prevState === 'asc') {
        return 'desc';
      } else {
        return 'asc';
      }
    });
  };

  if (renderMemberData?.length === 0) {
    return (
      <EmptyLeaderboardState
        emptyStateLabel={'Add more members to compare their performance!'}
      />
    );
  }

  if (overallLocationWiseData?.length === 0) {
    return (
      <EmptyLeaderboardState
        emptyStateLabel={'Add more members to compare their performance!'}
      />
    );
  }

  return (
    <Flex
      flexDir={'column'}
      width={'100%'}
      p={'16px 8px'}
      borderRadius={'12px'}
      backgroundColor={'#EFEFEF'}
    >
      <Flex color={'#6F767E'} px={1} justifyContent={'space-between'}>
        <Text>Members</Text>
        <Flex gap={2} alignItems={'center'}>
          <Text>Completion rate</Text>
          <Box cursor={'pointer'} onClick={handleSortToggle}>
            <Sort_ascendingIcon />
          </Box>
        </Flex>
      </Flex>

      <Flex
        maxHeight={'160px'}
        overflowY={'hidden'}
        gap={2}
        flexDir={'column'}
        mt={'10px'}
      >
        {tab === 'Members' ? (
          <RenderMemberList
            data={sortArray(renderMemberData, 'completionPercent', sortType)}
            selectedLocation={selectedLocation}
          />
        ) : (
          <RenderLocationList
            data={sortArray(
              overallLocationWiseData,
              'completionPercent',
              sortType
            )}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default BaseListRender;
