import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Center, Flex, Text, Tooltip, chakra, Box } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';

import { useTranslation } from 'react-i18next';

import { QuestionType } from '../../../../types';
import { useUserDataSelector } from '../../../../hooks';
import { HiddenResponse } from '../../../../configs';
import { IconImage } from '../../../../ui-components';

import Loader from 'sub-components/Loader';
import ListPagination from 'atoms/ListPagination';

import FormDataContext from 'pages/forms/FormStore/form-data-context';

import { QuestionWiseTableWrapper } from './QuestionWiseTable.styles';
import { QUESTION_WISE_RESPONSE } from 'pages/forms/forms-graphql';
import UserDateComponent from '../FormResponseTable/UserDateComponent';
import FileComponent from '../FormResponseTable/FileComponent';
import ImageComponent from '../FormResponseTable/ImageComponent';
import LocationComponent from '../FormResponseTable/LocationComponent';
import LongAnswerComponent from '../FormResponseTable/LongAnswerComponent';
import MemberComponent from '../FormResponseTable/MemberComponent';
import ShortAnswerComponent from '../FormResponseTable/ShortAnswerComponent';
import VideoComponent from '../FormResponseTable/VideoComponent';

import DropDownWithImage from './DropdownWithImage';
import {
  IQuestions,
  IQuestionsListEntity,
  IQuestionsUserEntity,
} from './question-wise.types';

import {
  AcknowledgeCell,
  CheckBoxCell,
  DropDownCell,
} from '../FormResponseTable/ResponseCell';
import { QuestionImage } from '../../../../sub-components/forms/create-form/QuestionTypeSelect/data';
import { IFormResponseItemsEntity, Respondent } from '../../forms-types';
import LocationHeaderComponent from '../FormResponseTable/LocationHeaderComponent';
import LocationCellComponent from '../FormResponseTable/LocationCellComponent';
import { ILocationFilter } from '../FormResponseTable/FormResponseTable';
import { IDateFilterRangeEntity } from 'ui-components/DateFilter/types';
import EmptyState from 'sub-components/EmptyState';

type QuestionEntity = IFormResponseItemsEntity['form']['questions'][number] & {
  image: string;
};

interface IProps {
  userDetails: IQuestionsUserEntity;
}
const QuestionWiseUserDetailsComponent: FC<IProps> = ({ userDetails }) => {
  const userEid = useUserDataSelector((state) => state.eid);

  const userName = userDetails?.eid === userEid ? 'You' : userDetails?.name;

  return (
    <Flex
      align='center'
      columnGap='5px'
      bg='#e0f5fe'
      p='5px 10px'
      borderRadius='7px'
      width='fit-content'
      maxW='100%'
    >
      <IconImage
        name={userDetails?.name}
        thumbnail={userDetails?.profilePic}
        boxSize={25}
        borderRadius='7px'
      />
      <Tooltip
        label={userDetails?.name + ', ' + userDetails?.role}
        placement='bottom'
      >
        <Flex align='center' cursor='pointer' overflow='hidden'>
          <Text as='b' color='#2A85FF' isTruncated>
            {userName}
          </Text>
          <Text>,&nbsp;</Text>
          <Text isTruncated overflow='unset'>
            {userDetails?.role}
          </Text>
        </Flex>
      </Tooltip>
    </Flex>
  );
};

const QuestionWiseTable: FC<{
  dateRangeFilter: IDateFilterRangeEntity | undefined;
  locationsData?: ILocationFilter[];
  locationFilterClickHandler?: (location: ILocationFilter) => void;
}> = ({ dateRangeFilter, locationsData, locationFilterClickHandler }) => {
  const { t } = useTranslation('form');
  const params: { id: string } = useParams();
  const formCtx = useContext(FormDataContext);
  const [page, setPage] = useState(1);
  const [selectedItem, setSelectedItem] = useState<
    QuestionEntity & {
      index: number;
    }
  >();

  const [questionItems, setQuestionItems] = useState<IQuestions>({
    QuestionWiseResponse: {
      count: 0,
      hasNextPage: false,
      hasPrevPage: false,
      limit: 0,
      totalPages: 0,
      items: [],
    },
  });

  const dateFilterRangeSelected =
    dateRangeFilter !== undefined &&
    dateRangeFilter.from.isValid() &&
    (dateRangeFilter.to ? dateRangeFilter.to.isValid() : true);

  const searchFieldHasValue = !!formCtx?.searchString;

  useEffect(() => {
    setPage(1);
    getQuestionWiseResponseHandler();
  }, [formCtx.searchString, dateRangeFilter, selectedItem]);

  const [getQuestionWiseResponse, { loading }] = useLazyQuery(
    QUESTION_WISE_RESPONSE,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data: IQuestions) => {
        // console.log('DATA : ', data);
        setQuestionItems(data);
      },
    }
  );

  const questions = useMemo(() => {
    const _questions = formCtx.responseData?.items?.[0]?.form?.questions || [];
    return _questions.reduce<QuestionEntity[]>((list, item) => {
      if (!HiddenResponse.includes(item?.qType)) {
        // @ts-ignore
        if (item?.qType === 'singleChoice') {
          list.push({
            ...item,
            image: QuestionImage[QuestionType.MULTI_CHOICE].image,
          });
        } else {
          list.push({
            ...item,
            image: QuestionImage[item.qType].image,
          });
        }
      }
      return list;
    }, []);
  }, [formCtx.responseData?.items?.[0]?.form?.questions]);

  useEffect(() => {
    if (questions?.length > 0) {
      setSelectedItem({ ...questions?.[0], index: 0 });
    }
  }, [questions]);

  const getQuestionWiseResponseHandler = (
    page = 1,
    locationIds: string[] = []
  ) => {
    getQuestionWiseResponse({
      variables: {
        formId: params?.id,
        qId: selectedItem?.eid,
        page: page,
        perPage: 10,
        query: formCtx.searchString,
        ...(locationIds?.length > 0 ? { locationIds: locationIds } : {}),
        ...(dateRangeFilter?.from && dateRangeFilter?.to
          ? {
              startDate: dateRangeFilter?.from?.toISOString(),
              endDate: dateRangeFilter?.to?.toISOString(),
            }
          : dateRangeFilter?.from
          ? { startDate: dateRangeFilter?.from?.toISOString() }
          : dateRangeFilter?.to
          ? { endDate: dateRangeFilter?.to?.toISOString() }
          : null),
      },
    });
  };

  const getSelectedCountHandler = () => {
    let selection = locationsData;
    return (selection || []).filter((user) => user.selected === true).length;
  };

  const onFilterCloseHandler = () => {
    let locationIds =
      locationsData
        ?.filter((data) => data?.selected)
        ?.map((data) => data?.eid) || [];
    getQuestionWiseResponseHandler(page, locationIds);
  };

  const getTableHeader = () => {
    return (
      <thead>
        <tr>
          <chakra.td width='150px'>
            <strong>{t('date')}</strong>
          </chakra.td>
          <chakra.td width='300px'>
            <strong>{t('user')}</strong>
          </chakra.td>
          <chakra.td width='300px'>
            <LocationHeaderComponent
              boldText
              locationsData={locationsData}
              showFilters
              getSelectedCountHandler={getSelectedCountHandler}
              locationFilterClickHandler={locationFilterClickHandler}
              onFilterCloseHandler={onFilterCloseHandler}
            />
          </chakra.td>
          <td style={{ width: 'auto' }}>
            <strong>{t('response')}</strong>
          </td>
        </tr>
      </thead>
    );
  };

  const getColumns = () => {
    console.log('GET COLUMNS : : : : : ', formCtx?.responseData?.items);
    // if (formCtx?.responseData?.items?.length > 0) {
    if (questionItems?.QuestionWiseResponse?.items?.length > 0) {
      // console.log('columns : ', questionItems?.QuestionWiseResponse?.items);
      return (
        <tbody>
          {questionItems?.QuestionWiseResponse?.items?.map((item, index) => {
            return <tr key={index}>{columnItemHandler(item, index)}</tr>;
          })}
        </tbody>
      );
    } else {
      console.log('ELSE : : : : : ');
      return (
        <tbody>
          <tr>
            <td colSpan={4} style={{ textAlign: 'center' }}>
              <EmptyState image='Search' title='No Data found' />
            </td>
          </tr>
        </tbody>
      );
    }
  };

  const columnItemHandler = (item: IQuestionsListEntity, rootIndex: number) => {
    let date = (
      <div className='responsive-div'>
        <UserDateComponent date={item?.response?.respondedAt} />
      </div>
    );
    let user = (
      <div className='responsive-div'>
        <QuestionWiseUserDetailsComponent userDetails={item?.user} />
        {/* {<UserDetailsComponent userDetails={item?.user} />} */}
      </div>
    );
    return (
      <>
        <td>{date}</td>
        <td>{user}</td>
        <td>
          <Flex align='center' gap='5px'>
            <LocationCellComponent value={item?.locations?.[0]?.name} />
            {item?.locations?.length > 1 && (
              <Tooltip
                label={item?.locations
                  ?.slice(1)
                  ?.map((loc) => loc?.name)
                  ?.join(', ')}
                hasArrow
                placement='top'
              >
                <Box
                  border='1px solid #6f767e8c'
                  borderRadius='5px'
                  p='2px 4px'
                  cursor='pointer'
                >
                  <Text color='#6F767E'>
                    + {item?.locations?.slice(1)?.length}
                  </Text>
                </Box>
              </Tooltip>
            )}
          </Flex>
        </td>
        {item?.response?.qType === QuestionType.DROPDOWN && (
          <td>
            <DropDownCell response={item.response} question={selectedItem} />
          </td>
        )}
        {item?.response?.qType === QuestionType.FILE_UPLOAD && (
          <td>
            <FileComponent
              files={item?.response?.responseUrl}
              noteText={item?.response?.noteText}
              noteImages={item?.response?.noteImage}
            />
          </td>
        )}
        {item?.response?.qType === QuestionType.IMAGE_UPLOAD && (
          <td>
            <ImageComponent
              images={item?.response?.responseUrl || []}
              noteText={item?.response?.noteText}
            />
          </td>
        )}
        {item?.response?.qType === QuestionType.LOCATION && (
          <td>
            <LocationComponent locations={item?.response?.responseId || []} />
          </td>
        )}
        {item?.response?.qType === QuestionType.LONG_TEXT && (
          <td>
            <LongAnswerComponent
              longAnswer={{
                answer: item?.response?.response!,
                eid: item?.response?.qid!,
              }}
              defaultLength={100}
              noteImages={item?.response?.noteImage}
            />
          </td>
        )}
        {item?.response?.qType === QuestionType.MEMBER && (
          <td>
            <MemberComponent memberIds={item?.response?.responseId || []} />
          </td>
        )}
        {item?.response?.qType === QuestionType.MULTI_CHOICE && (
          <td>
            <CheckBoxCell
              response={item.response}
              question={selectedItem}
              isMultiChoice
              showUnselectedOption
            />
          </td>
        )}
        {/*// @ts-ignore */}
        {item?.response?.qType === 'singleChoice' && (
          <td>
            <CheckBoxCell
              response={item.response}
              question={selectedItem}
              isMultiChoice
              showUnselectedOption
            />
          </td>
        )}

        {item?.response?.qType === QuestionType.CHECKBOX && (
          <td>
            <CheckBoxCell
              response={item.response}
              question={selectedItem}
              showUnselectedOption
            />
          </td>
        )}
        {item?.response?.qType === QuestionType.SHORT_TEXT && (
          <td>
            <ShortAnswerComponent
              shortAnswer={item?.response?.response || ''}
              noteImages={item?.response?.noteImage}
            />
          </td>
        )}
        {item?.response?.qType === QuestionType.VIDEO_UPLOAD && (
          <td>
            <VideoComponent
              videos={item?.response?.responseUrl || []}
              noteText={item?.response?.noteText}
            />
          </td>
        )}
        {item?.response?.qType === QuestionType.ACKNOWLEDGMENT && (
          <td>
            <AcknowledgeCell
              text={item?.response?.response || ''}
              font={item?.response?.font || ''}
              // date={item?.response?.respondedAt}
            />
          </td>
        )}
      </>
    );
  };

  const tableRenderStatus = () => {
    let flag = true; // True => Render Table
    if (
      !loading &&
      questionItems?.QuestionWiseResponse?.items?.length === 0 &&
      !dateFilterRangeSelected &&
      !searchFieldHasValue &&
      getSelectedCountHandler() === 0
    ) {
      flag = false; // => False => Do not Render Table
    }
    return flag;
  };

  const compRender = () => {
    if (loading) {
      return (
        <Center height='100%'>
          <Loader size='lg' />
        </Center>
      );
    } else if (tableRenderStatus()) {
      return (
        <>
          <table>
            {getTableHeader()}
            {getColumns()}
          </table>
          {questionItems?.QuestionWiseResponse?.totalPages > 1 ? (
            <ListPagination
              onPageChange={(page: number) => {
                setPage(page);
                getQuestionWiseResponseHandler(page);
              }}
              data={questionItems?.QuestionWiseResponse?.items}
              totalRegisters={questionItems?.QuestionWiseResponse?.count}
              page={page}
              registersPerPage={10}
            />
          ) : null}
        </>
      );
    } else {
      <EmptyState image='Filter' title='No Response found' />;
    }
  };

  return (
    <Flex flex={1} flexDir='column'>
      {!loading && questionItems?.QuestionWiseResponse?.items?.length > 0 && (
        <DropDownWithImage
          questions={questions}
          selectedData={selectedItem}
          setSelectedItem={setSelectedItem}
        />
      )}
      <QuestionWiseTableWrapper>{compRender()}</QuestionWiseTableWrapper>
    </Flex>
  );
};

export default QuestionWiseTable;
