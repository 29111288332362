import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { HeaderColors } from 'configs';
import React, { FC } from 'react';
import { usePlayContext } from '../store/context';
import { ReactComponent as SparkSolid } from 'assets/images/sparks.svg';

const Footer: FC = () => {
  const {
    increment,
    decrement,
    isPreview,
    tpSessionData,
    selectedIndex,
    selectedSubIndex,
    totalScreens,
    quizSubmissionResult,
    setQuizSubmissionResult,
  } = usePlayContext((state) => ({
    increment: state.increment,
    decrement: state.decrement,
    isPreview: state.isPreview,
    tpSessionData: state.tpSessionData,
    selectedIndex: state.selectedIndex,
    selectedSubIndex: state.selectedSubIndex,
    totalScreens: state.internalTotalScreens,
    quizSubmissionResult: state.quizSubmissionResult,
    setQuizSubmissionResult: state.setQuizSubmissionResult,
  }));

  React.useEffect(() => {
    if (quizSubmissionResult?.showResult) {
      const timer = setTimeout(() => {
        setQuizSubmissionResult(null);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [quizSubmissionResult]);

  const isFirstScreen = selectedIndex === 0 && selectedSubIndex === 0;

  const getContentLength = (content: any) => {
    if (!content) return 0;

    switch (content.type) {
      case 'sop':
        return content.sopContent?.renderContent?.length || 0;
      case 'quiz':
        return content.questions?.length || 0;
      default:
        return 0;
    }
  };

  const isLastScreen = React.useMemo(() => {
    if (!tpSessionData) return false;

    if (selectedIndex >= tpSessionData.contents.length) {
      return true;
    }

    const currentContent = tpSessionData.contents[selectedIndex];
    const contentLength = getContentLength(currentContent);

    const isLastRegularContent =
      selectedIndex === tpSessionData.contents.length - 1;
    const isLastSubItem =
      contentLength === 0 || selectedSubIndex === contentLength - 1;

    if (isPreview && isLastRegularContent && isLastSubItem) {
      return true;
    }

    return (
      isLastRegularContent &&
      isLastSubItem &&
      selectedIndex === totalScreens - 1
    );
  }, [selectedIndex, selectedSubIndex, tpSessionData, totalScreens]);

  const getPositionText = () => {
    if (!tpSessionData) return '';

    if (selectedIndex >= tpSessionData.contents.length) {
      return '';
    }

    return `${selectedIndex + 1}/${tpSessionData.contents.length}`;
  };

  const correctAnswer = (point: number, isPartial: boolean = false) => {
    const roundedPoint = Number(point.toFixed(2));
    return (
      <Flex
        align='center'
        boxShadow='rgba(112, 112, 112, 0.1)'
        bg='white'
        p='4px 12px'
        borderRadius='100px'
        gap='5px'
        color={HeaderColors.GreenDark}
      >
        <SparkSolid />
        <Box fontWeight={600}>
          {isPartial ? 'Partially correct' : 'Correct answer'}{' '}
          {point > 0 && (
            <>
              , +{roundedPoint} point
              {roundedPoint === 1 ? '' : 's'}
            </>
          )}
          !
        </Box>
      </Flex>
    );
  };

  const wrongAnswer = () => {
    return (
      <Flex
        boxShadow='rgba(112, 112, 112, 0.1)'
        align='center'
        bg='white'
        p='4px 12px'
        borderRadius='100px'
        gap='5px'
        color={HeaderColors.Red.Mid}
      >
        <Box fontWeight={600}>Wrong answer</Box>
      </Flex>
    );
  };

  return (
    <Flex
      justify='space-between'
      align='center'
      p={4}
      bg='white'
      borderBottomLeftRadius='16px'
      borderBottomRightRadius='16px'
    >
      <Flex
        color={HeaderColors.DarkBlue}
        align='center'
        cursor='pointer'
        onClick={decrement}
        visibility={isFirstScreen ? 'hidden' : 'visible'}
        gap='5px'
        fontWeight={600}
      >
        <ArrowBackIcon fontSize='20px' />
        <Box as='span'>Prev</Box>
      </Flex>
      <Box as='span'>
        {quizSubmissionResult?.showResult ? (
          <>
            {quizSubmissionResult.isCorrect ||
            quizSubmissionResult?.isPartiallyCorrect
              ? correctAnswer(
                  quizSubmissionResult.score,
                  quizSubmissionResult.isPartiallyCorrect
                )
              : wrongAnswer()}
          </>
        ) : (
          getPositionText()
        )}
      </Box>
      <Flex
        color={HeaderColors.DarkBlue}
        align='center'
        cursor='pointer'
        onClick={increment}
        visibility={isLastScreen ? 'hidden' : 'visible'}
        gap='5px'
        fontWeight={600}
      >
        <ArrowForwardIcon fontSize='20px' />
        <Box as='span'>Next</Box>
      </Flex>
    </Flex>
  );
};

Footer.displayName =
  'displayName:sub-components/training-v2/play/components/Footer';

export default Footer;
