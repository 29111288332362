import { FC } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Text,
  Flex,
  Box,
  ModalFooter,
  useToast,
} from '@chakra-ui/react';
import { Heading } from './Filter';
import { ReactComponent as DeleteIcon } from 'assets/images/nexus/compliance/delete.svg';
import Loader from 'sub-components/Loader';
import { useMutation } from '@apollo/client';
import { DELETE_COMPLIANCE_DOCUMENT } from '../../query/compliance.graphql';
import { Button as AtomButton } from '../../../../../atoms';
import { useAddLauncherTaskFiles } from 'sub-components/Launcher/location-owner/StatesDrawer/StateDrawer/useAddLauncherTaskFiles';

interface IProps {
  eid: string;
  getComplianceFilters: any;
  getComplianceListing: any;
  disabled?: string;
  isTaskFile?: boolean;
  fileData?: any;
  refetchTaskFilesHandler?: () => void;
}

const DeleteDocumentModal: FC<IProps> = ({
  eid,
  getComplianceListing,
  getComplianceFilters,
  disabled,
  isTaskFile,
  fileData,
  refetchTaskFilesHandler,
}) => {
  const toast = useToast({
    duration: 3000,
    isClosable: true,
    position: 'top-right',
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const addLauncherTaskFiles = useAddLauncherTaskFiles();

  const deleteFileFromLauncher = () => {
    let files = fileData?.taskDetails?.taskFiles || [];
    let filteredFiles = files?.filter(
      (_file: any) => _file?.url !== fileData?.docDetails?.url
    );
    onClose();
    addLauncherTaskFiles({
      file: filteredFiles,
      launchId: fileData?.launchId,
      taskId: fileData?.taskDetails?.taskId,
      onSuccess: () => {
        toast({
          status: 'success',
          title: 'File removed successfully',
        });
        refetchTaskFilesHandler?.();
      },
    });
  };

  const [deleteComplianceDocument, { loading: deleteComplianceLoader }] =
    useMutation(DELETE_COMPLIANCE_DOCUMENT, {
      onCompleted: () => {
        onClose();
        getComplianceListing({
          variables: getComplianceFilters(),
        });
      },
      fetchPolicy: 'network-only',
      onError: (err) => {
        console.error('error --- ', err);
      },
    });

  return (
    <>
      <Flex onClick={onOpen} gap={2} alignItems={'center'}>
        <AtomButton
          bg={'transparent'}
          colorScheme='none'
          disabled={disabled}
          size='xs'
          fontSize='12px'
          paddingInline={3}
          height='28px'
        >
          <DeleteIcon cursor={disabled ? 'not-allowed' : 'pointer'} />
        </AtomButton>
        {/* <DeleteIcon cursor={'pointer'} /> */}
      </Flex>
      <Box backgroundColor={'red.100'}>
        <Modal onClose={onClose} isOpen={isOpen} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <Heading color='#B5E4CA' title='Delete document?' />
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Flex>
                <Text color={'#6F767E'}>
                  This will permanently delete the uploaded document
                </Text>
              </Flex>
            </ModalBody>
            <ModalFooter>
              <Flex width={'100%'} gap={2} justifyContent={'center'}>
                <Button onClick={onClose} width={'190px'}>
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    if (isTaskFile) {
                      return deleteFileFromLauncher();
                    }
                    deleteComplianceDocument({
                      variables: {
                        eid: eid,
                      },
                    });
                  }}
                  width={'190px'}
                  colorScheme='blue'
                  mr={3}
                >
                  {deleteComplianceLoader ? <Loader /> : 'Yes'}
                </Button>
              </Flex>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </>
  );
};

DeleteDocumentModal.displayName =
  'displayName:sub-components/nexus/Compliance/Listing/components/DeleteDocumentModal';

export default DeleteDocumentModal;
