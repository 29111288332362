import React, { FC, useEffect, useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useFormContext } from 'react-hook-form';
import { useDebounce } from 'use-debounce';
import { SelectOption } from '../../../../../../atoms';
import { toArray } from '../../../../../../utils';
import { useFormCategories } from '../../../../../forms';
import { BaseDataNotFound, BaseResourceContainer } from '../common';
import FormItem from './FormItem';
import { FORM_RESOURCE_LIST, FormResponse } from './form-resource.graphql';

interface IProps {
  values: string[];
}

const FormResourceContainer: FC<IProps> = ({ values }) => {
  const { setValue, getValues } = useFormContext();

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [category, setCategory] = useState<SelectOption>();

  const { data: formData } = useFormCategories();

  const [fetchData, { data, loading }] = useLazyQuery<FormResponse>(
    FORM_RESOURCE_LIST,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        if (!getValues('preview') && !searchQuery) {
          const firstFormId = toArray(data.FormsPagination?.items).find(
            (item) => !values?.includes(item.eid)
          )?.eid;
          if (firstFormId) {
            setValue('preview', firstFormId);
          }
        }
      },
    }
  );

  const [_searchQuery] = useDebounce(searchQuery, 300);

  useEffect(() => {
    setValue('preview', undefined);
    fetchData({
      variables: {
        perPage: 40 + values.length,
        filter: {
          status: 'active',
          query: _searchQuery,
          categoryId: category?.value ? category?.value : undefined,
        },
        sort: '_ID_DESC',
      },
    });
  }, [_searchQuery, values.length, category]);

  const options = useMemo(() => {
    const _options = toArray(formData?.EntityFormCategories).map((op) => ({
      label: op.name,
      value: op.eid,
    }));

    _options.unshift({ label: 'All categories', value: '' });

    return _options;
  }, [formData?.EntityFormCategories]);

  const formList = useMemo(() => {
    return toArray(data?.FormsPagination?.items).filter((value) => {
      return !values.includes(value.eid);
    });
  }, [data, values]);

  return (
    <BaseResourceContainer
      searchPlaceholder='Browse for forms'
      searchQuery={searchQuery}
      onSearchChange={setSearchQuery}
      // Filter props
      filterOptions={options}
      filterValue={category}
      onFilterChange={(val) => setCategory(val)}
    >
      <BaseDataNotFound
        type='form'
        isLoading={loading}
        category={category?.value ? category?.label : undefined}
        searchQuery={_searchQuery}
        dataLength={formList?.length}
        onBackClick={() => {
          setSearchQuery('');
          setCategory({ label: '', value: '' });
        }}
      >
        {formList.map((item) => (
          <FormItem
            data={item}
            key={item.eid}
            onClick={() => setValue('preview', item.eid)}
          />
        ))}
      </BaseDataNotFound>
    </BaseResourceContainer>
  );
};

export default FormResourceContainer;
