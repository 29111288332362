import { AddIcon } from '@chakra-ui/icons';
import { Flex } from '@chakra-ui/react';
import React, { FC } from 'react';
import { useWatch } from 'react-hook-form';
import { Button } from '../../../../../../../atoms';
import { TAssignByType } from '../../../../../../../types';
import { IAssigneeInput } from '../../../training-input.types';

interface IProps {
  index: number;
  value?: TAssignByType;
  onChange?: (value: TAssignByType) => void;
}

const AddSelectButton: FC<IProps> = ({ index, value, onChange }) => {
  const [roles, authRoles] = useWatch<
    IAssigneeInput,
    [`assignees.${number}.roles`, `assignees.${number}.authRoles`]
  >({
    name: [`assignees.${index}.roles`, `assignees.${index}.authRoles`],
  });

  if (value || authRoles?.length || roles?.length) {
    return null;
  }

  return (
    <Flex>
      <Button
        variant='ghost'
        colorScheme='purple'
        leftIcon={<AddIcon />}
        onClick={() => onChange?.('role')}
      >
        Add Job Condition
      </Button>
      <Button
        variant='ghost'
        colorScheme='purple'
        leftIcon={<AddIcon />}
        onClick={() => onChange?.('authRole')}
      >
        Add Role Condition
      </Button>
    </Flex>
  );
};

export default AddSelectButton;
