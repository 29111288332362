import { useMemo } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useWatch } from 'react-hook-form';
import { useUserDataSelector } from '../../../../../../../hooks';
import { usersEntityObj } from '../../../../../../Header';
import { TFormInput } from '../../../training-input.types';

import { getSuperVisors } from './supervisor.helper';

export const useSupervisors = () => {
  const authUser = useUserDataSelector((user) => ({
    eid: user.eid,
    authRole: user.authRole,
    type: user.type,
    locations: user.locations,
  }));
  const entityUsers = useReactiveVar(usersEntityObj);

  const [assignees] = useWatch<TFormInput, ['assignees']>({
    name: ['assignees'],
  });

  return useMemo(() => {
    return getSuperVisors(authUser, entityUsers, {
      assignees: assignees,
    }).sort((a, b) => a.name!.localeCompare(b.name!));
  }, [authUser, entityUsers, assignees]);
};
