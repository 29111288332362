import { Flex, Box, Text } from '@chakra-ui/react';
import SearchInput from 'atoms/SearchInput';
import React, { FC, useEffect } from 'react';
import { ReactComponent as FilledIcon } from 'assets/images/nexus/compliance/filled.svg';
import { GenericFilter } from '../../GenericFilter';
import { _myTrainingsFilterData } from 'sub-components/training-v2/constants';
import { OperationVariables, QueryLazyOptions } from '@apollo/client';
import { TExcueteCallbackVars } from '../../GenericFilter/types/filter-types';
import { FilterChip, FilterContainer } from '../Filter';
import { ITypes } from '../../TrackPathDashboard/bottomSection/Header';
import { useMyTrainingContext } from '../../MyTrainingContext';

interface IProps {
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  getTrainingPathSessionData: (
    options?: QueryLazyOptions<OperationVariables> | undefined
  ) => void;
  trainingPathFilters: TExcueteCallbackVars;
}

const Header: FC<IProps> = ({
  setSearchQuery,
  getTrainingPathSessionData,
  trainingPathFilters,
}) => {
  const { setFilters, filters, trainingCategories } = useMyTrainingContext();

  // console.log('trainingCategories --- ', trainingCategories);

  // initialize types filters
  const typesFilter: ITypes[] = [
    {
      eid: 'static_1_active1',
      name: 'Active',
    },
    {
      eid: 'static_2_inactive2',
      name: 'Inactive',
    },
  ];

  // initialize status filters
  const statusesFilter = [
    {
      eid: 'static_1_yetToStart',
      name: 'Yet to start',
      value: 'assigned',
    },
    {
      eid: 'static_1_inProgress',
      name: 'In Progress',
      value: 'started',
    },
    {
      eid: 'static_1_completed',
      name: 'Completed',
      value: 'completed',
    },
    {
      eid: 'static_1_overdue',
      name: 'Overdue',
      value: '',
    },
  ];

  //code to handle type filter
  const handleTypeSelection = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: ITypes
  ) => {
    if (e?.target?.checked) {
      setFilters((prevFilterState) => {
        return {
          ...prevFilterState,
          selectedTypes: [...prevFilterState?.selectedTypes, type],
          selectedTypesId: [...prevFilterState?.selectedTypesId, type?.eid],
          totalFiltersTypes: prevFilterState.totalFiltersTypes + 1,
          mergedFiltersArray: [
            ...prevFilterState.mergedFiltersArray,
            { ...type, customType: 'types' },
          ],
        };
      });
    } else {
      setFilters((prevFilterState) => {
        return {
          ...prevFilterState,
          selectedTypes: filters?.selectedTypes?.filter(
            (item) => item?.eid !== type?.eid
          ),
          selectedTypesId: filters?.selectedTypesId?.filter(
            (item) => item !== type?.eid
          ),
          totalFiltersTypes: prevFilterState.totalFiltersTypes - 1,
          mergedFiltersArray: prevFilterState.mergedFiltersArray?.filter(
            (item) => item?.eid !== type?.eid
          ),
        };
      });
    }
  };

  //code to handle status filter
  const handleStatusSelection = (
    e: React.ChangeEvent<HTMLInputElement>,
    status: ITypes
  ) => {
    if (e?.target?.checked) {
      setFilters((prevFilterState) => {
        return {
          ...prevFilterState,
          selectedStatuses: [...prevFilterState?.selectedStatuses, status],
          selectedStatusesId: [
            ...prevFilterState?.selectedStatusesId,
            status?.eid,
          ],
          totalFiltersTypes: prevFilterState.totalFiltersTypes + 1,
          mergedFiltersArray: [
            ...prevFilterState.mergedFiltersArray,
            { ...status, customType: 'statuses' },
          ],
        };
      });
    } else {
      setFilters((prevFilterState) => {
        return {
          ...prevFilterState,
          selectedStatuses: filters?.selectedStatuses?.filter(
            (item) => item?.eid !== status?.eid
          ),
          selectedStatusesId: filters?.selectedStatusesId?.filter(
            (item) => item !== status?.eid
          ),
          totalFiltersTypes: prevFilterState.totalFiltersTypes - 1,
          mergedFiltersArray: prevFilterState.mergedFiltersArray?.filter(
            (item) => item?.eid !== status?.eid
          ),
        };
      });
    }
  };

  //code to handle categories filter
  const handleCategorySelection = (
    e: React.ChangeEvent<HTMLInputElement>,
    category: ITypes
  ) => {
    if (e?.target?.checked) {
      setFilters((prevFilterState) => {
        return {
          ...prevFilterState,
          selectedCategories: [
            ...prevFilterState?.selectedCategories,
            category,
          ],
          selectedCategoriesId: [
            ...prevFilterState?.selectedCategoriesId,
            category?.eid,
          ],
          totalFiltersTypes: prevFilterState.totalFiltersTypes + 1,
          mergedFiltersArray: [
            ...prevFilterState.mergedFiltersArray,
            { ...category, customType: 'categories' },
          ],
        };
      });
    } else {
      setFilters((prevFilterState) => {
        return {
          ...prevFilterState,
          selectedCategories: filters?.selectedCategories?.filter(
            (item) => item?.eid !== category?.eid
          ),
          selectedCategoriesId: filters?.selectedCategoriesId?.filter(
            (item) => item !== category?.eid
          ),
          totalFiltersTypes: prevFilterState.totalFiltersTypes - 1,
          mergedFiltersArray: prevFilterState.mergedFiltersArray?.filter(
            (item) => item?.eid !== category?.eid
          ),
        };
      });
    }
  };

  //code to clear filters
  const clearFilters = (type = 'all') => {
    if (type === 'all') {
      setFilters((prevFilterState) => {
        return {
          ...prevFilterState,
          totalFiltersTypes: 0,
          selectedCategories: [],
          selectedCategoriesId: [],
          selectedTypes: [],
          selectedTypesId: [],
          selectedStatuses: [],
          selectedStatusesId: [],
          mergedFiltersArray: [],
        };
      });
    }
    if (type === 'types') {
      setFilters((prevFilterState) => {
        return {
          ...prevFilterState,
          selectedTypes: [],
          selectedTypesId: [],
          totalFiltersTypes:
            prevFilterState.totalFiltersTypes -
            prevFilterState.selectedTypesId?.length,
          mergedFiltersArray: prevFilterState?.mergedFiltersArray?.filter(
            (item) => item?.customType !== 'types'
          ),
        };
      });
    }
    if (type === 'categories') {
      setFilters((prevFilterState) => {
        return {
          ...prevFilterState,
          selectedCategories: [],
          selectedCategoriesId: [],
          totalFiltersTypes:
            prevFilterState.totalFiltersTypes -
            prevFilterState.selectedCategoriesId?.length,
          mergedFiltersArray: prevFilterState?.mergedFiltersArray?.filter(
            (item) => item?.customType !== 'categories'
          ),
        };
      });
    }
    if (type === 'statuses') {
      setFilters((prevFilterState) => {
        return {
          ...prevFilterState,
          selectedStatuses: [],
          selectedStatusesId: [],
          totalFiltersTypes:
            prevFilterState.totalFiltersTypes -
            prevFilterState.selectedStatusesId?.length,
          mergedFiltersArray: prevFilterState?.mergedFiltersArray?.filter(
            (item) => item?.customType !== 'statuses'
          ),
        };
      });
    }
  };

  useEffect(() => {
    setFilters((prevState) => {
      return {
        ...prevState,
        types: typesFilter,
        statuses: statusesFilter,
        categories: trainingCategories,
      };
    });
  }, [trainingCategories]);

  return (
    <Flex gap={4} width={'100%'} flexDir={'column'}>
      <Flex justifyContent={'space-between'}>
        <Box></Box>
        <Flex width={'400px'} alignItems={'center'} gap={4}>
          <SearchInput
            disabled={true}
            size='md'
            hideShortcuts={true}
            placeholder={'Search training by name'}
            onChange={(e) => setSearchQuery(e?.target?.value)}
          />

          <Box>
            <FilterContainer
              filters={filters}
              setFilters={setFilters}
              handleTypeSelection={handleTypeSelection}
              handleStatusSelection={handleStatusSelection}
              handleCategorySelection={handleCategorySelection}
              clearFilters={clearFilters}
            />
          </Box>
        </Flex>
      </Flex>
      <Flex gap={2} alignItems={'center'}>
        {/* FilterChip */}
        {filters?.totalFiltersTypes > 0 && (
          <>
            <FilterChip filters={filters} setFilters={setFilters} />
          </>
        )}
      </Flex>
    </Flex>
  );
};

export default Header;
