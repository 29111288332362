import React, { FC } from 'react';
import { TaskCreation } from '../../sub-components/tasks';
import DashboardContainer from '../../sub-components/DashboardContainer';

const CreateTask: FC = () => {
  return (
    <DashboardContainer>
      <TaskCreation />
    </DashboardContainer>
  );
};

CreateTask.displayName = 'displayName:pages/Tasks/create-task';

export default CreateTask;
