import {
  Center,
  Portal,
  Box,
  Spinner,
  Text,
  VStack,
  Progress,
} from '@chakra-ui/react';
import React, { FC, useState, useEffect, useRef } from 'react';
import { ISopMedia } from './chapter.types';
import ReactPlayer from 'react-player/lazy';

interface IVideoModalProps {
  onClose: () => void;
  media: ISopMedia[];
}

/**
 * VideoModal Component
 *
 * This component displays a video in a modal overlay using ReactPlayer.
 *
 * @remarks
 * Several Safari-specific accommodations are implemented to ensure cross-browser compatibility:
 *
 * 1. Uses ReactPlayer/lazy instead of ReactPlayer to improve loading performance
 *    and reduce initial bundle size by code-splitting the player.
 *
 * 2. Implements client-side only rendering check (hasWindow state) to prevent
 *    SSR/hydration issues that commonly affect media players.
 *
 * 3. Uses unique key with forced re-render via setTimeout to solve Safari's
 *    issue with initial video loading. Safari sometimes fails to properly
 *    initialize the video player when the component first mounts.
 *
 * 4. Uses playerConfig with specific settings for better Safari compatibility:
 *    - forceVideo: true - Ensures the player treats the source as video content
 *    - forceAudio: false - Prevents audio-only treatment of the content
 *    - hlsOptions settings to handle HLS streams better on Safari
 *
 * 5. Includes fallback to native <video> element if ReactPlayer fails to load
 *
 * 6. Uses stopPropagation on inner Box to prevent click events from closing
 *    the modal when interacting with the video player.
 *
 * 7. Implements position='fixed' (not 'absolute') to ensure proper overlay
 *    regardless of parent container's position context.
 *
 * 8. Implements an adaptive progress loader that shows realistic processing feedback
 *    when videos may take time to prepare.
 *
 * 9. Includes automatic retry mechanism for videos that need processing time
 *    before they're ready to play.
 *
 * 10. Detects unsupported video formats and shows appropriate messages.
 *
 * @param props - The component props
 * @param props.onClose - Function to call when the modal should close
 * @param props.media - Array of media objects containing mediaUrl
 *
 * @returns A modal with video player
 */
const VideoModal: FC<IVideoModalProps> = ({ onClose, media }) => {
  // Track if we're running in browser environment to avoid SSR issues
  const [hasWindow, setHasWindow] = useState(false);
  // Track loading state to show/hide progress indicator
  const [loading, setLoading] = useState(true);
  // Count retry attempts
  const [retryCount, setRetryCount] = useState(0);
  // Unique key to force re-render of ReactPlayer component
  const [key, setKey] = useState(Date.now());
  // Track progress percentage for loading indicator
  const [progressPercent, setProgressPercent] = useState(0);

  // Maximum number of retries before giving up
  const maxRetries = 100;
  // Reference to retry interval timer
  const retryInterval = useRef<NodeJS.Timeout | null>(null);
  // Reference to progress update timer
  const progressTimer = useRef<NodeJS.Timeout | null>(null);
  // Reference to ReactPlayer instance
  const playerRef = useRef(null);

  useEffect(() => {
    // Only render player component on client-side to prevent hydration errors
    if (typeof window !== 'undefined') {
      setHasWindow(true);
    }

    // Start adaptive progress timer for user feedback
    startAdaptiveProgress();

    // Start retry mechanism to handle videos that need processing time
    startRetryMechanism();

    // Cleanup timers on unmount
    return () => {
      if (retryInterval.current) clearInterval(retryInterval.current);
      if (progressTimer.current) clearInterval(progressTimer.current);
    };
  }, []);

  /**
   * Implements a three-phase progress indicator that provides realistic feedback
   * without reaching 100% until the video is actually ready to play.
   */
  const startAdaptiveProgress = () => {
    setProgressPercent(0);

    // Phase 1: Initial fast progress up to 30%
    const initialProgress = () => {
      progressTimer.current = setInterval(() => {
        setProgressPercent((prev) => {
          if (prev >= 30) {
            clearInterval(progressTimer.current!);
            midProgress();
            return 30;
          }
          return prev + 2;
        });
      }, 300);
    };

    // Phase 2: Mid progress up to 70% (slower)
    const midProgress = () => {
      progressTimer.current = setInterval(() => {
        setProgressPercent((prev) => {
          if (prev >= 70) {
            clearInterval(progressTimer.current!);
            finalProgress();
            return 70;
          }
          return prev + 1;
        });
      }, 700);
    };

    // Phase 3: Final progress that approaches but never reaches 100% (very slow)
    const finalProgress = () => {
      progressTimer.current = setInterval(() => {
        setProgressPercent((prev) => {
          const remaining = 99 - prev;
          if (remaining <= 0.1) return prev;

          const increment = Math.max(0.1, remaining * 0.05);
          return Math.min(99, prev + increment);
        });
      }, 1000);
    };

    initialProgress();
  };

  /**
   * Retry mechanism that periodically forces the player to re-attempt loading
   * the video until it succeeds or reaches maximum retry attempts.
   */
  const startRetryMechanism = () => {
    retryInterval.current = setInterval(() => {
      if (retryCount >= maxRetries) {
        if (retryInterval.current) clearInterval(retryInterval.current);
        return;
      }

      // Force re-render of player component with a new key
      setKey(Date.now());
      setRetryCount((prev) => prev + 1);
    }, 3000);
  };

  /**
   * Handler for successful video load event.
   * Cleans up timers and transitions from loading to playing state.
   */
  const handleReady = () => {
    // Stop retry and progress timers
    if (retryInterval.current) clearInterval(retryInterval.current);
    if (progressTimer.current) clearInterval(progressTimer.current);

    // Complete the progress indicator and remove loading overlay
    setProgressPercent(100);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  // Configuration specific to video playback in different browsers
  // These settings help ensure compatibility with Safari
  const playerConfig = {
    file: {
      forceVideo: true, // Always treat as video content
      forceAudio: false, // Don't treat as audio-only
      hlsOptions: {
        enableWorker: false, // Disable web workers for HLS which can cause issues in Safari
        startLevel: -1, // Auto-select quality level
      },
    },
  };

  /**
   * Checks if the provided URL points to a video format that isn't
   * supported by web browsers or ReactPlayer.
   *
   * @param url - The URL to check
   * @returns true if the format is unsupported, false otherwise
   */
  const isUnsupportedFormat = (url: string | undefined): boolean => {
    if (!url) return false;
    const lowercaseUrl = url.toLowerCase();
    return (
      lowercaseUrl.endsWith('.avi') ||
      lowercaseUrl.endsWith('.wmv') ||
      lowercaseUrl.endsWith('.flv')
    );
  };

  const mediaUrl = media?.[0]?.mediaUrl;
  const isFormatSupported = !isUnsupportedFormat(mediaUrl);

  return (
    <Portal>
      <Center
        position='fixed' // Fixed position ensures overlay covers entire viewport
        w='100vw'
        h='100vh'
        top='0'
        left='0'
        zIndex='999'
        bg='rgba(0, 0, 0, 0.8)'
        onClick={onClose} // Close when clicking overlay background
      >
        <Box
          w='85%'
          h='85%'
          onClick={(e) => e.stopPropagation()} // Prevent clicks on video from closing modal
          position='relative'
        >
          {/* Loading overlay with progress indicator */}
          {loading && (
            <Center
              position='absolute'
              w='100%'
              h='100%'
              bg='rgba(0,0,0,0.9)'
              zIndex={2}
              flexDirection='column'
            >
              <VStack spacing={4} w='80%' maxW='500px'>
                <Spinner size='xl' color='white' />
                <Text color='white' fontSize='md'>
                  Preparing video for playback...
                </Text>
                <Progress
                  value={progressPercent}
                  w='100%'
                  colorScheme='blue'
                  hasStripe={progressPercent < 100}
                  isAnimated={progressPercent < 100}
                  borderRadius='md'
                />
                <Text color='white' fontSize='sm'>
                  {progressPercent < 100
                    ? `Processing ${Math.round(progressPercent)}%`
                    : 'Ready to play!'}
                </Text>
                {retryCount > 5 && (
                  <Text color='gray.400' fontSize='xs'>
                    This is taking longer than expected. Please wait...
                  </Text>
                )}
              </VStack>
            </Center>
          )}

          {/* Video player for supported formats */}
          {hasWindow && isFormatSupported && mediaUrl && (
            // @ts-ignore
            <ReactPlayer
              ref={playerRef}
              key={key} // Force component to completely re-mount when key changes
              url={mediaUrl}
              controls={true}
              playing={!loading} // Auto-play when loaded
              height='100%'
              width='100%'
              config={playerConfig}
              playsinline={true} // Essential for iOS Safari
              onReady={handleReady}
              onError={(e) => console.error('Player error:', e)}
              style={{ visibility: loading ? 'hidden' : 'visible' }}
            />
          )}

          {/* Message for unsupported video formats */}
          {hasWindow && !isFormatSupported && (
            <Center flexDirection='column' h='100%' bg='#000' color='white'>
              <Text mb={4}>
                This video format cannot be played in the browser.
              </Text>
              <Text fontSize='sm' mb={4}>
                (.avi files are not supported by web browsers)
              </Text>
            </Center>
          )}
        </Box>
      </Center>
    </Portal>
  );
};

export default VideoModal;
