import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AppRoute, useHistory } from '../../../../routes';
import { AmplitudeEvent, deployEvent } from '../../../../shared';
import { ActionButton } from '../../../../ui-components';
import { useConfigCategoryList } from '../../../../sub-components/Launcher';

import { TemplateContent } from './template.graphql'; // TODO

import { transformTemplate } from './transform-template';
import { ILauncherSetupInput } from './launcher-setup.types';

interface IProps {
  contents: TemplateContent[];
  initialising?: boolean;
  eventName?: AmplitudeEvent;
}

const StartButton: FC<IProps> = ({ initialising, contents, eventName }) => {
  const { t } = useTranslation('common');
  const history = useHistory();
  const { categories } = useConfigCategoryList();

  const {
    formState: { isDirty },
    handleSubmit,
  } = useFormContext<ILauncherSetupInput>();

  // Define an asynchronous function to handle the click event
  const onProceedClick = async (values: ILauncherSetupInput) => {
    if (eventName) {
      deployEvent(eventName, {
        board_creation_funnel_id: 5,
        board_name: values.title,
        // board_id: values.eid,
      });
    }

    const categoryId = categories?.find((cat) => cat.label === 'Others')?.eid;
    const response = transformTemplate(contents, values.title, categoryId);
    // Redirect to the launcher opening task page
    history.push({
      pathname: AppRoute.LAUNCHER_BOARD_CONFIG,
      state: {
        templatedData: response,
      },
    });
  };

  if (initialising) {
    return null;
  }

  const isDisabled = !contents?.length || !isDirty;

  return (
    <Flex position='sticky' bottom='0' width='full'>
      <ActionButton
        w='full'
        fontSize='15px'
        fontWeight='700'
        colorScheme='blue'
        isDisabled={isDisabled}
        actionFn={isDisabled ? undefined : handleSubmit(onProceedClick)}
      >
        {t('continue')}
      </ActionButton>
    </Flex>
  );
};

export default StartButton;
