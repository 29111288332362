import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { FieldArrayWithId, useFormContext } from 'react-hook-form';
import { TFormInput } from '../../training-input.types';
import { getSxScrollStyles } from '../../common';
import DraggableContentItem from './DraggableContentItem';
import ContentListSortable from './ContentListSortable';
import { TrainingContentPreview } from './preview';
import ContentAlertInfo from './ContentAlertInfo';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';

interface IProps {
  disabled?: boolean;
  fields: FieldArrayWithId<TFormInput, 'contents'>[];
  onRemoveClick?: (index: number) => void;
  onEditClick?: (index: number) => void;
  onDragEnd?: (currentIndex: number, nextIndex: number) => void;
}

const ContentList: FC<IProps> = ({
  disabled,
  fields,
  onRemoveClick,
  onEditClick,
  onDragEnd,
}) => {
  const { setValue } = useFormContext<TFormInput>();

  return (
    <Flex
      flex={1}
      borderRadius='12px'
      p={{ base: '24px', md: '28px 32px' }}
      pb={{ base: '16px', md: '16px' }}
      border='1px solid #EFEFEF'
      gap='28px'
      overflow='hidden'
    >
      <Flex
        flex={1}
        gap={2}
        flexDir='column'
        height='70vh'
        minHeight='620px'
        overflow='hidden'
        marginBottom={{ base: '8px', md: '12px' }}
      >
        <Box fontSize='16px' fontWeight='600' color='#111315' mb={3}>
          Items added ({fields.length}) :
        </Box>
        <ContentAlertInfo />
        <Flex
          flex={1}
          flexDir='column'
          gap={2}
          overflow='auto'
          sx={getSxScrollStyles()}
        >
          <ContentListSortable
            disabled={disabled}
            items={fields}
            onDragEnd={onDragEnd}
          >
            {fields.map((field, index) => (
              <DraggableContentItem
                key={index}
                index={index}
                data={field}
                onRemoveClick={
                  disabled ? undefined : () => onRemoveClick?.(index)
                }
                onEditClick={disabled ? undefined : () => onEditClick?.(index)}
                onClick={() => setValue('preview', field)}
              />
            ))}
          </ContentListSortable>
        </Flex>
      </Flex>

      <TrainingContentPreview />
    </Flex>
  );
};

export default React.memo(ContentList);
