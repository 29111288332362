import React, { FC } from 'react';
import { Box, Flex, Image } from '@chakra-ui/react';
import { AuthRole } from '../../../authorization';
import { IconsPlaceholder } from '../../../ui-components';
import { SearchResult } from './ai-search.types';
import { useGenerateLink } from './useGenerateLink';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';

interface IProps {
  source: SearchResult;
  authRole?: AuthRole;
}

const SourceCard: FC<IProps> = ({ source, authRole }) => {
  const resourceLink = useGenerateLink(source?.type, source?.id);

  if (!source || (source.type === 'audit' && authRole === AuthRole.WORKER)) {
    return null;
  }

  return (
    <Flex
      borderRadius='12px'
      bg='#CABDFF80'
      border='1px solid #DDDDDD'
      width='max-content'
      cursor='pointer'
      maxWidth='360px'
      onClick={() => {
        deployEvent(AmplitudeEventNames.SOURCE_CITATION_AI_POWERED_SEARCH, {
          search_funnel_id: 3,
        });
        window.open(resourceLink, '_blank');
      }}
    >
      <Image
        boxSize='48px'
        minWidth='48px'
        src={IconsPlaceholder[source.type]}
        border='1px solid #DDDDDD'
        borderRadius='11px'
      />
      <Flex padding='8px 12px' overflow='hidden'>
        <Box fontWeight='500' color='#1A1D1F' isTruncated>
          {source.title}
        </Box>
      </Flex>
    </Flex>
  );
};

export default SourceCard;
