import { useLazyQuery } from '@apollo/client';
import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Center,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  ModalCloseButton,
  Radio,
  RadioGroup,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { Progress } from 'antd';
import { Button } from 'atoms';
import { HeaderColors } from 'configs';
import { useUserDataSelector } from 'hooks';
import moment from 'moment';
import { FC, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { eventBus } from 'shared/eventEmit';
import Loader from 'sub-components/Loader';
import { TP_BY_ID } from 'sub-components/training-v2/dashboard/components/Tracking/query/tracking.graphql';
import { usePlayContext } from 'sub-components/training-v2/play/store/context';
import { BoxHeader } from 'ui-components';
import { GET_TP_SESSION_BY_ID } from '../../../../dashboard/components/Tracking/query/tracking.graphql';
import { ProgressTimeLine } from '../ProgressTimeLine';
import ViewProgressEvaluationSummary from './ViewProgressEvaluationSummary';

interface IProps {}

const ViewProgress: FC<IProps> = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { updateSelectedIndex, updateSelectedSubIndex } = usePlayContext(
    (state) => ({
      updateSelectedIndex: state.updateSelectedIndex,
      updateSelectedSubIndex: state.updateSelectedSubIndex,
    })
  );
  const [selectedSessionId, setSelectedSessionId] = useState('');
  const [formattedSessions, setFormattedSessions] = useState<
    { name: string; eid: string }[]
  >([]);

  const { trainingId, sessionId } = useParams<{
    trainingId: string;
    sessionId: string;
  }>();

  const { loggedInUserEid } = useUserDataSelector((state) => ({
    loggedInUserEid: state?.eid,
  }));

  useEffect(() => {
    const unsubscribe = eventBus.on('OPEN_PROGRESS_DRAWER', () => {
      onOpen();
    });

    return () => {
      unsubscribe();
    };
  }, [onOpen]);

  const [
    getTPSession,
    {
      loading: tpSessionsLoading,
      data: tpSessions,
      refetch: refetchTPSessions,
    },
  ] = useLazyQuery(TP_BY_ID, {
    fetchPolicy: 'network-only',
  });

  const [
    getUserTpSessionByID,
    { data: userTpProgress, loading: userTpProgressLoading },
  ] = useLazyQuery(GET_TP_SESSION_BY_ID, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (!isOpen) return;

    getTPSession({
      variables: {
        eid: trainingId,
        filter: {
          currentUserSession: true,
        },
      },
    });
  }, [isOpen]);

  useEffect(() => {
    if (tpSessions?.TpById?.sessions?.length) {
      setSelectedSessionId(sessionId);
    }
  }, [tpSessions]);

  useEffect(() => {
    if (!selectedSessionId) return;

    getUserTpSessionByID({
      variables: {
        eid: selectedSessionId,
        filter: {
          userId: loggedInUserEid,
        },
        userIds: [loggedInUserEid],
      },
    });
  }, [selectedSessionId]);

  useEffect(() => {
    if (!tpSessions?.TpById?.sessions?.length) return;

    const sessions = [...tpSessions?.TpById?.sessions].reverse();
    const formattedSessions = [];

    for (let i = 0; i < sessions?.length; i++) {
      if (i === 0) {
        formattedSessions?.push({
          name: 'Current cycle',
          eid: sessions?.[i]?.eid,
        });
      } else if (i === 1) {
        formattedSessions?.push({
          name: `Previous cycle ${
            moment(sessions?.[i]?.startDate)?.isValid()
              ? '(' + moment(sessions?.[i]?.startDate).format('MMM D') + ')'
              : ''
          }`,
          eid: sessions?.[i]?.eid,
        });
      } else {
        formattedSessions?.push({
          name: `${
            moment(sessions?.[i]?.startDate)?.isValid()
              ? moment(sessions?.[i]?.startDate).format('MMM D')
              : ''
          }`,
          eid: sessions?.[i]?.eid,
        });
      }
    }

    setFormattedSessions(formattedSessions);
  }, [tpSessions]);

  const onCloseDrawer = () => {
    onClose();
    setSelectedSessionId('');
  };

  const progress = useMemo(() => {
    if (!userTpProgress) return 0;

    if (userTpProgress?.TpSessionById?.userProgress?.[0]?.isCompleted)
      return 100;

    const filteredContents = userTpProgress?.TpSessionById?.contents?.filter(
      (item) => !item.isRemoved
    );

    const total = filteredContents?.length || 0;

    const completed =
      filteredContents?.filter((content) =>
        userTpProgress?.TpSessionById?.userProgress?.[0]?.progress?.some(
          (progressItem) =>
            progressItem.id === content.eid && progressItem.isCompleted
        )
      )?.length || 0;

    return total > 0 ? Number(((completed / total) * 100).toFixed(0)) : 0;
  }, [userTpProgress]);

  const handleItemClick = (content: any, contentIndex: number) => {
    updateSelectedIndex(contentIndex);
    updateSelectedSubIndex(0);
    onClose();
  };

  return (
    <Box>
      <Button variant='outline' bg='white' onClick={onOpen}>
        View progress
      </Button>
      <Drawer onClose={onCloseDrawer} isOpen={isOpen} size='sm'>
        <DrawerOverlay />
        <DrawerContent padding='.5rem' paddingTop='1rem'>
          <DrawerHeader>
            <Flex gap={5}>
              <BoxHeader title='My progress' color={HeaderColors.Green} />
              {selectedSessionId === formattedSessions[0]?.eid && (
                <Progress
                  type='dashboard'
                  percent={progress}
                  size={45}
                  gapDegree={100}
                  strokeColor={progress >= 100 ? '#83BF6E' : '#6F52ED'}
                  trailColor='#F3F4F6'
                  strokeWidth={8}
                  format={(percent) => (
                    <Box as='span' color='#111315' fontSize='10px'>
                      {percent}%
                    </Box>
                  )}
                />
              )}
            </Flex>

            <ModalCloseButton top='8' right='6' fontSize='18px' />
          </DrawerHeader>
          <DrawerBody>
            {tpSessionsLoading ? (
              <Center height='100%'>
                <Loader />
              </Center>
            ) : (
              <>
                {tpSessions?.TpById?.isRepeating && (
                  <Menu>
                    <MenuButton
                      as={Button}
                      w='full'
                      textAlign='left'
                      bg='white'
                      border='2px solid #EFEFEF'
                      rightIcon={<ChevronDownIcon />}
                      color='#6F767E'
                      colorScheme='white'
                      borderRadius='12px'
                      paddingY='22px'
                    >
                      {formattedSessions?.find(
                        (session) => session.eid === selectedSessionId
                      )?.name || 'Current cycle'}
                    </MenuButton>
                    <MenuList
                      borderRadius='16px'
                      padding='8px'
                      width='24rem'
                      maxHeight='300px'
                      overflowY='auto'
                      paddingBottom='2rem'
                    >
                      <RadioGroup
                        colorScheme='purple'
                        onChange={setSelectedSessionId}
                        value={selectedSessionId}
                      >
                        {formattedSessions?.map((session, index) => {
                          return (
                            <MenuItem
                              borderRadius='8px'
                              justifyContent='space-between'
                              padding='12px'
                              key={index}
                              onClick={() => setSelectedSessionId(session.eid)}
                            >
                              <Text color='#1A1D1F' as='span'>
                                {session?.name}
                              </Text>
                              <Radio
                                value={session?.eid}
                                size='lg'
                                isReadOnly
                              />
                            </MenuItem>
                          );
                        })}
                      </RadioGroup>
                    </MenuList>
                  </Menu>
                )}
              </>
            )}

            {userTpProgressLoading ? (
              <Center height='100%'>
                <Loader />
              </Center>
            ) : selectedSessionId === formattedSessions[0]?.eid ? (
              <Box marginTop='1.5rem'>
                <ProgressTimeLine
                  userProgress={
                    userTpProgress?.TpSessionById?.userProgress?.[0]
                  }
                  contents={userTpProgress?.TpSessionById?.contents}
                  onProgressTimelineItemClick={handleItemClick}
                  showAllContents
                />
              </Box>
            ) : (
              <ViewProgressEvaluationSummary
                maxScore={
                  userTpProgress?.TpSessionById?.userProgress?.[0]?.maxScore ||
                  0
                }
                skillVerifierResponse={
                  userTpProgress?.TpSessionById?.userProgress?.[0]
                    ?.skillVerifierResponse
                }
                hasSkillVerifier={
                  userTpProgress?.TpSessionById?.hasSkillVerifier
                }
                quizScore={
                  userTpProgress?.TpSessionById?.userProgress?.[0]?.score
                }
                certificate={
                  userTpProgress?.TpSessionById?.userProgress?.[0]?.certificate
                }
                isTpSessionCompleted={
                  userTpProgress?.TpSessionById?.userProgress?.[0]?.isCompleted
                }
                sessionId={selectedSessionId}
                isEvaluationCompleted={
                  userTpProgress?.TpSessionById?.userProgress?.[0]?.isEvaluated
                }
                isCertificateDownloaded={
                  userTpProgress?.TpSessionById?.assignedUsers?.[0]
                    ?.certificateDownloaded
                }
              />
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

ViewProgress.displayName =
  'displayName:sub-components/training-v2/play/components/ProgressSection/ViewProgress/ViewProgress';

export default ViewProgress;
