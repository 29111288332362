import { ApolloProvider, useApolloClient } from '@apollo/client';
import { Box, ModalCloseButton } from '@chakra-ui/react';
import { useCallback, useRef } from 'react';
import { UseConfirm, useConfirm } from 'ui-components';
import ReAssignTpForUser, { IReAssignInfo } from './ReAssignTpForUser';

interface IProps {
  sessionId: string;
  userId: string;
  tpScheduleInfo: IReAssignInfo;
  onActionCompleted?: () => void;
}

type UseReassignUser = (props: IProps) => void;

const useReassignUser = (): UseReassignUser => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();
  const apolloClient = useApolloClient();

  return useCallback(
    ({ sessionId, userId, tpScheduleInfo, onActionCompleted }) => {
      if (confirmRef.current) {
        confirmRef.current.destroy();
      }
      confirmRef.current = confirm({
        Wrapper: ({ children }: any) => {
          return (
            <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
          );
        },
        size: '2xl',
        title: (
          <>
            <Box>{`Reassign`}</Box>
            <ModalCloseButton top='1.5rem' right='1.5rem' fontSize='18px' />
          </>
        ),
        content: (
          <ReAssignTpForUser
            sessionId={sessionId}
            userId={userId}
            tpScheduleInfo={tpScheduleInfo}
            refetchTpSession={onActionCompleted}
          />
        ),
        contentProps: {
          borderRadius: '12px',
          p: '1.5rem 2rem 0rem 2rem',
        },
        headerProps: {
          p: '0px',
        },
        bodyProps: {
          p: '0px',
        },
        isCentered: true,
        footer: null,
      });
    },
    [confirm]
  );
};

useReassignUser.displayName =
  'displayName:sub-components/training-v2/dashboard/components/TrackPathDashboard/components/PathActions/useReassignUser.tsx';

export default useReassignUser;
