import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { FormCategory } from '../../../types';

export interface CategoryResponse {
  EntityFormCategories: FormCategory[];
}

export const getFormCategories = gql`
  query EntityFormCategories {
    EntityFormCategories {
      categoryType
      createdBy
      eid
      entityId
      name
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useFormCategories = (options?: QueryHookOptions) => {
  return useQuery<CategoryResponse>(getFormCategories, options);
};
