import { gql } from '@apollo/client';

export interface CertificateItem {
  trainingId: string;
  training: string;
  thumbnail: string;
  certificate: string;
  firstCompletedAt: string;
  certificateUrl: string;
  trainingItems: number;
  viewedContents: number;
}

export interface CertificateResponse {
  userCertificates: CertificateItem[];
}

export interface CertificatesV2Entity {
  sessionId: string;
  trainingId: string;
  assignedOn: string;
  completedOn: string;
  title: string;
  thumbnail: string;
  startDate: string;
  endDate: string;
  certificateDownloaded: boolean;
}

export interface CertificatesV2Response {
  TpCertificates: CertificatesV2Entity[];
}

export const GET_USER_CERTIFICATES = gql`
  query userCertificates($userId: String!) {
    userCertificates(userId: $userId)
  }
`;

export const GET_TP_V2_CERTIFICATES = gql`
  query Query($userId: String) {
    TpCertificates(userId: $userId)
  }
`;
