import React, { useCallback, useRef } from 'react';
import { AlertDialogCloseButton, Flex } from '@chakra-ui/react';
import { HeaderColors } from '../../../../../../../configs';
import {
  BoxHeader,
  useConfirm,
  UseConfirm,
} from '../../../../../../../ui-components';

interface IProps {
  onConfirm?: () => void;
}

type TypeChangeConfirm = (props?: IProps) => void;

export const useTypeChangeConfirm = (): TypeChangeConfirm => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();

  return useCallback((props) => {
    if (confirmRef.current) {
      confirmRef.current?.destroy();
    }

    confirmRef.current = confirm({
      title: (
        <Flex gap='12px' justify='space-between'>
          <BoxHeader title='Unsaved changes' color={HeaderColors.Purple} />

          <AlertDialogCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
          />
        </Flex>
      ),

      content: (
        <Flex fontSize='15px' fontWeight='600' color='#6F767E'>
          The changes you made to this question will be lost if you change the
          question type. Are you sure you want to proceed?
        </Flex>
      ),

      isCentered: true,
      contentProps: {
        borderRadius: '8px',
        maxWidth: '640px',
        paddingY: 2,
      },
      bodyProps: { paddingY: 4 },
      okText: 'Change question type',
      okButtonProps: { flex: 1, size: 'lg' },
      onOK: props?.onConfirm,
      cancelText: 'Keep current question type',
      cancelButtonProps: { flex: 1, size: 'lg', variant: 'outline' },
    });
  }, []);
};
