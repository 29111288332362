import React, { FC, useState } from 'react';
import { Box, Flex, Stack } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircle,
  faSquare,
  faCircleDot,
  faSquareCheck,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faCircleCheck,
  faCircleXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { QuizQFormInput } from '../../quiz-form/quiz-form.types';
import PreviewContainer from '../PreviewContainer';
import { IconImage } from '../../../../../../../../ui-components';

interface Props {
  data: QuizQFormInput;
}

const MultiChoicePreview: FC<Props> = ({ data }) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [showResults, setShowResults] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleOptionClick = (optionId: string) => {
    if (showResults) return;

    if (data.hasMultipleCorrect) {
      setSelectedOptions((prev) =>
        prev.includes(optionId)
          ? prev.filter((id) => id !== optionId)
          : [...prev, optionId]
      );
    } else {
      setSelectedOptions([optionId]);
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    await new Promise((resolve) => setTimeout(resolve, 500));
    setShowResults(true);
    setIsSubmitting(false);
  };

  const getOptionStyles = (option: QuizQFormInput['options'][number]) => {
    if (!showResults) {
      return {
        bg: 'white',
        border: '1px solid #E2E8F0',
      };
    }

    if (selectedOptions.includes(option.uid)) {
      return {
        bg: option.isCorrect
          ? 'rgba(181, 228, 202, 0.3)'
          : 'rgba(255, 106, 85, 0.2)',
        border: option.isCorrect
          ? '1px solid rgba(131, 191, 110, 1)'
          : '1px solid rgba(255, 106, 85, 1)',
      };
    }

    if (option.isCorrect) {
      return {
        bg: 'rgba(181, 228, 202, 0.3)',
        border: '1px solid rgba(131, 191, 110, 1)',
      };
    }

    return {
      bg: 'white',
      border: '1px solid #E2E8F0',
    };
  };

  const renderIcon = (option: QuizQFormInput['options'][number]) => {
    const isSelected = selectedOptions.includes(option.uid);

    if (!showResults) {
      if (data.hasMultipleCorrect) {
        return (
          <FontAwesomeIcon
            icon={
              isSelected ? (faSquareCheck as IconProp) : (faSquare as IconProp)
            }
            fontSize='16px'
            color='#E2E8F0'
          />
        );
      }
      return (
        <FontAwesomeIcon
          icon={isSelected ? (faCircleDot as IconProp) : (faCircle as IconProp)}
          fontSize='16px'
          color='#E2E8F0'
        />
      );
    }

    if (isSelected || option.isCorrect) {
      return (
        <FontAwesomeIcon
          icon={(option.isCorrect ? faCircleCheck : faCircleXmark) as IconProp}
          fontSize='16px'
          color={
            option.isCorrect
              ? 'rgba(131, 191, 110, 1)'
              : 'rgba(255, 106, 85, 1)'
          }
        />
      );
    }

    return null;
  };

  return (
    <PreviewContainer
      title={data.label}
      thumbnail={data.thumbnail}
      onSubmit={handleSubmit}
      showSubmit={!showResults}
      isSubmitting={isSubmitting}
      isDisabled={selectedOptions.length === 0}
    >
      <Stack spacing='6px' fontSize='10px' fontWeight={500}>
        {data.options?.map((option) => {
          const styles = getOptionStyles(option);
          return (
            <Flex
              key={option.uid}
              bg={styles.bg}
              borderRadius='8px'
              p='10px'
              minH='38px'
              gap={2}
              border={styles.border}
              align='start'
              cursor={!showResults ? 'pointer' : 'default'}
              onClick={() => handleOptionClick(option.uid)}
              _hover={
                !showResults
                  ? {
                      bg: 'rgba(177, 229, 252, 0.3)',
                      border: '1px solid rgba(42, 133, 255, 1)',
                    }
                  : {}
              }
            >
              {renderIcon(option)}
              {option?.thumbnail ? (
                <IconImage boxSize={36} name='' thumbnail={option?.thumbnail} />
              ) : null}
              <Box>{option.text}</Box>
            </Flex>
          );
        })}
      </Stack>
    </PreviewContainer>
  );
};

export default MultiChoicePreview;
