import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { ReactComponent as AuditView } from 'assets/images/audit-view-eye.svg';

interface IProps {
  visible?: boolean;
  visibilityCopy: string;
}

const LimitedVisibility: FC<IProps> = ({ visibilityCopy, visible }) => {
  if (!visible) {
    return null;
  }

  return (
    <Flex
      width='100%'
      fontSize='12px'
      color='#33383F'
      bg='#2A85FF1A'
      padding='4px 8px'
      borderRadius='8px'
      gap='8px'
      align='center'
    >
      <AuditView color='#2A85FF' />
      <span>{visibilityCopy}</span>
    </Flex>
  );
};

export default LimitedVisibility;
