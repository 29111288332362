import { gql, useMutation } from '@apollo/client';
import {
  Flex,
  ModalBody,
  ModalFooter,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useModalContext,
  useToast,
} from '@chakra-ui/react';
import { FC, ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';
import { ActionButton } from 'ui-components/Confirm';
import { IFormInput } from './invite.types';
import PhoneEmailTab from './PhoneEmailTab';
import ScanQrCode from './ScanQRCode';
import ShareByLinkTab from './ShareByLinkTab';

const ParentTabWrapper: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Tab
      fontWeight={600}
      fontSize='15px'
      color='#6F767E'
      _selected={{
        bg: '#EFEFEF',
        color: '#1A1D1F',
        fontWeight: 600,
        fontSize: '15px',
        borderRadius: '8px',
      }}
    >
      {children}
    </Tab>
  );
};

interface IProps {
  reInviteUser?: boolean;
}

const INVITE_USER_REQUEST_UPDATE = gql`
  mutation InviteUserRequestUpdate($input: InviteUserUpdateInputInput) {
    InviteUserRequestUpdate(input: $input) {
      succeed
    }
  }
`;

const ShareLinkTabs: FC<IProps> = ({ reInviteUser }) => {
  const { t } = useTranslation(['common', 'invite']);
  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  });
  const { getValues, reset } = useFormContext<IFormInput>();

  const { onClose } = useModalContext();

  const [updateUserSelections] = useMutation(INVITE_USER_REQUEST_UPDATE, {
    onCompleted: () => {
      toast({
        status: 'success',
        title: t('common:success'),
        description: t('invite:inviteDetailSuccess', {
          value: getValues('name'),
        }),
      });
    },
    onError: () => {
      toast({
        status: 'error',
        title: t('common:error'),
        description: t('invite:inviteDetailError'),
      });
    },
  });

  const handleFinish = async (shouldAddAnother: boolean) => {
    const userId = getValues('invitedUserId');
    const input = { userId };

    try {
      await updateUserSelections({
        variables: { input },
      });

      if (shouldAddAnother) {
        deployEvent(AmplitudeEventNames.INVITE_ADD_ANOTHER_MEMBER);
        reset();
      } else {
        onClose();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <ModalBody>
        <Tabs variant='unstyled'>
          <TabList>
            <ParentTabWrapper>{t('invite:phoneEmail')}</ParentTabWrapper>
            <ParentTabWrapper>{t('invite:scanQr')}</ParentTabWrapper>
            <ParentTabWrapper>{t('invite:viaLink')}</ParentTabWrapper>
          </TabList>
          <TabPanels display='flex' flexDir='column' minH='300px'>
            <TabPanel flex={1} p='16px 0'>
              <PhoneEmailTab />
            </TabPanel>
            <TabPanel display='flex' flexDir='column' flex={1} p='16px 0'>
              <ScanQrCode />
            </TabPanel>
            <TabPanel flex={1} p='16px 0'>
              <ShareByLinkTab />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </ModalBody>

      <ModalFooter>
        <Flex width='full' gap='10px' justify='flex-end'>
          {!reInviteUser && (
            <ActionButton
              variant='outline'
              width='auto'
              actionFn={() => handleFinish(true)}
            >
              {t('invite:finishAndInviteAnotherMember')}
            </ActionButton>
          )}
          <ActionButton
            variant='solid'
            colorScheme='blue'
            width={reInviteUser ? 'full' : 'auto'}
            actionFn={() => handleFinish(false)}
          >
            {t('common:finish')}
          </ActionButton>
        </Flex>
      </ModalFooter>
    </>
  );
};

export default ShareLinkTabs;
