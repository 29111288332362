import React, { FC, useMemo } from 'react';
import { Box, Center, Flex, Text, ChakraProps } from '@chakra-ui/react';
import { Image } from 'ui-components';
import { usePlayContext } from 'sub-components/training-v2/play/store/context';
import { FILL_THE_BLANK_REGEX } from 'utils/constants';

const centerStyles: ChakraProps = {
  fontWeight: 600,
  fontSize: '20px',
  bg: 'white',
  borderRadius: '12px',
  p: '40px 24px',
  textAlign: 'center',
  flexDir: 'column',
  gap: 2,
  boxShadow: '0px 4px 20px 0px rgba(112, 112, 112, 0.1)',
};

const QuizQuestionTitle: FC = () => {
  const { contents, selectedIndex, selectedSubIndex } = usePlayContext(
    (state) => ({
      selectedIndex: state.selectedIndex,
      selectedSubIndex: state.selectedSubIndex,
      contents: state.tpSessionData?.contents || [],
    })
  );

  const selectedData = useMemo(
    () => contents?.[selectedIndex]?.questions?.[selectedSubIndex],
    [contents, selectedIndex, selectedSubIndex]
  );

  const isFillInBlanks = selectedData?.type === 'fillInBlanks';

  const [firstPart = '', secondPart = ''] = useMemo(() => {
    const text = selectedData?.textWithBlanks || selectedData?.label || '';
    return text.split(FILL_THE_BLANK_REGEX);
  }, [selectedData?.textWithBlanks, selectedData?.label]);

  return (
    <Center {...centerStyles}>
      {selectedData?.thumbnail && (
        <Image
          src={selectedData.thumbnail}
          width={100}
          height={100}
          style={{ borderRadius: '8px', objectFit: 'contain' }}
        />
      )}
      {isFillInBlanks ? (
        <>
          <Box>Fill in the blank:</Box>
          <Flex w='full'>
            <Text fontWeight={600} fontSize='20px' textAlign='center'>
              {firstPart}{' '}
              <Box
                as='span'
                display='inline-block'
                borderWidth='1px'
                borderColor='blue.200'
                borderRadius='md'
                px={4}
                minW='100px'
              >
                &nbsp;
              </Box>{' '}
              {secondPart}
            </Text>
          </Flex>
        </>
      ) : (
        selectedData?.label
      )}
    </Center>
  );
};

export default QuizQuestionTitle;
