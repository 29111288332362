import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { Flex } from '@chakra-ui/react';
import React, { FC, useEffect, useState } from 'react';
import { usersEntityObj } from 'sub-components/Header';
import {
  GET_TRACKING_TP_DATA,
  GET_TRAINING_CATEGORIES,
} from 'sub-components/training-v2/shared/query/dashboard.graphql';
import {
  ITPCategory,
  TPFiltersParams,
} from 'sub-components/training-v2/shared/types';
import { Header, DraftTable } from './Drafts';
import { prepareTrackingTPTableData } from './TrackPathDashboard/bottomSection/helper';
import { ITrackingTPPagination } from './TrackPathDashboard/bottomSection/PathTable';

interface IProps {}

export interface ISortType {
  key?: string;
  sort?: string;
  order?: 'asc' | 'desc' | 'none';
}

const DraftContainer: FC<IProps> = () => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [trackingTpPaginationData, setTrackingTpPaginationData] = useState<
    ITrackingTPPagination[]
  >([]);
  const [trainingPathItemCount, setTrainingPathItemCount] = useState<number>(0);
  const [trainingCategories, setTrainingCategories] = useState<ITPCategory[]>(
    []
  );
  const [selectedPageIndex, setSelectedPageIndex] = useState<number>(1);
  const [sortType, setSortType] = useState<ISortType>({ order: 'none' });

  const entityObjData = useReactiveVar(usersEntityObj);

  // Main filter handler responsible for returning filter object which is later used to fetch queries from API
  const getTrackingTPFilters = ({
    pageIndex = 1,
    sort = 'CREATEDAT_DESC',
  }: TPFiltersParams) => {
    let filter: {
      query?: string;
      category?: string[];
      createdBy?: string;
      status: string[];
      supervisor?: boolean;
      locationIds?: string[];
      creator?: boolean;
    } = {
      query: '',
      status: ['TRAINING_DRAFT'],
      supervisor: false,
      locationIds: [],
    };

    // // Add filter query to the filter object
    if (searchQuery) {
      filter.query = searchQuery;
    }

    // Only show those drafts which are created by the loggedIn user
    filter.creator = true;

    return {
      filter: filter,
      sort: sort,
      page: selectedPageIndex,
      perPage: 10,
    };
  };

  // API to get tracking pagination data
  const [getTrackingTpPaginationData, { loading: trackingTpPaginationLoader }] =
    useLazyQuery(GET_TRACKING_TP_DATA, {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const formattedData = prepareTrackingTPTableData(
          data?.TpPaginationNew?.items,
          trainingCategories,
          entityObjData
        );

        setTrainingPathItemCount(data?.TpPaginationNew?.count);
        setTrackingTpPaginationData(formattedData);
      },
    });

  // API to get training categories
  const [getTrainingCategories, { loading: trainingCategoriesLoader }] =
    useLazyQuery(GET_TRAINING_CATEGORIES, {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setTrainingCategories(data.EntityTpCategories);
        // Trigger the pagination query after categories are fetched
        getTrackingTpPaginationData({
          variables: getTrackingTPFilters({}),
        });
      },
    });

  useEffect(() => {
    // Fetch initial data when searchQuery is empty
    if (!searchQuery) {
      getTrainingCategories();
      return;
    }

    // Debounce fetch when searchQuery is updated
    const timer = setTimeout(() => {
      getTrackingTpPaginationData({
        variables: getTrackingTPFilters({ pageIndex: selectedPageIndex }),
      });
    }, 500);

    return () => clearTimeout(timer);
  }, [searchQuery]);

  // Handle sorting
  useEffect(() => {
    getTrackingTpPaginationData({
      variables: getTrackingTPFilters({
        pageIndex: selectedPageIndex,
        sort: sortType.sort,
      }),
    });
  }, [sortType, selectedPageIndex]);

  return (
    <Flex
      gap={2}
      flexDir={'column'}
      p={'24px'}
      borderRadius={'8px'}
      border={'2px solid #EFEFEF'}
    >
      <Header setSearchQuery={setSearchQuery} />
      <DraftTable
        searchQuery={searchQuery}
        trackingTpPaginationData={trackingTpPaginationData}
        trackingTpPaginationLoader={trackingTpPaginationLoader}
        trainingCategoriesLoader={trainingCategoriesLoader}
        trainingPathItemCount={trainingPathItemCount}
        trainingCategories={trainingCategories}
        getTrackingTpPaginationData={getTrackingTpPaginationData}
        getTrackingTPFilters={getTrackingTPFilters}
        setSortType={setSortType}
        sortType={sortType}
        setSelectedPageIndex={setSelectedPageIndex}
        selectedPageIndex={selectedPageIndex}
      />
    </Flex>
  );
};

export default DraftContainer;
