import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { AddIcon, CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { Button } from '../../../../../../atoms';

interface IProps {
  isAdded?: boolean;
  onAddClick?: () => void;
  onRemoveClick?: () => void;
}

const ResourceItemAction: FC<IProps> = ({
  isAdded,
  onAddClick,
  onRemoveClick,
}) => {
  if (!isAdded) {
    return (
      <Button
        size='sm'
        width='94px'
        variant='outline'
        leftIcon={<AddIcon />}
        bgColor='#FCFCFC'
        fontSize='12px'
        fontWeight='500'
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          onAddClick?.();
        }}
      >
        Add
      </Button>
    );
  }

  return (
    <Flex flexDir='column' width='94px'>
      <Button
        display='none'
        size='sm'
        variant='ghost'
        leftIcon={<CloseIcon boxSize='10px' />}
        bgColor='#FCFCFC'
        fontSize='12px'
        fontWeight='500'
        className='item-remove'
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          onRemoveClick?.();
        }}
      >
        Remove
      </Button>

      <Flex
        gap={2}
        align='center'
        color='#83BF6E'
        fontSize='12px'
        fontWeight='500'
        paddingX='12px'
        className='item-added'
      >
        <CheckIcon boxSize='12px' />
        Added
      </Flex>
    </Flex>
  );
};

export default ResourceItemAction;
