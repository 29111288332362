import React, { FC } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { Box, Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';
import moment from 'moment';
import { TFormInput } from '../../training-input.types';
import { TrainingDateSelect } from './date-picker';

interface IProps {
  disabled?: boolean;
}

const CustomEndDate: FC<IProps> = ({ disabled }) => {
  const deadlineType = useWatch<TFormInput, 'deadlineType'>({
    name: 'deadlineType',
  });

  if (deadlineType !== 'date') {
    return null;
  }

  return (
    <Flex flex={1} gap={5}>
      <Box fontSize='13px' fontWeight='600' lineHeight='45px'>
        on
      </Box>
      <Controller<TFormInput, 'deadlineDate'>
        name='deadlineDate'
        disabled={disabled}
        rules={{
          required: 'Please select end date',
          validate: (value, formData) => {
            if (formData.assignmentType === 'date') {
              if (moment(value).isSameOrBefore(formData.startDate)) {
                return 'End date value should be after start date';
              }
            }
          },
          deps: ['startDate'],
        }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid}>
            <TrainingDateSelect
              disabled={field.disabled}
              onChange={field.onChange}
              selectedValue={field.value || null}
              placeholderText='Select end date'
              minDate={moment().add(1, 'day').toDate()}
              shouldCloseOnSelect
            />
            <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
          </FormControl>
        )}
      />
    </Flex>
  );
};

export default CustomEndDate;
