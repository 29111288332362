import React, { ChangeEvent, FC, useMemo } from 'react';
import { CheckboxGroup, Flex } from '@chakra-ui/react';
import { useController } from 'react-hook-form';
import { useSafeState } from '../../../../../../../../hooks';
import { searchRegExp } from '../../../../../../../../utils';
import { SearchInput } from '../../../../../../../../atoms';

import { LocationOption, useLocationsList } from '../../location-select';
import { sxScrollStyle } from '../../common.styles';
import { ListWrapper } from '../../common';
import FilterSelectAll from './FilterSelectAll';
import { MFilterInput } from './member-filter.types';

interface IProps {
  index: number;
}

const ByLocationSelect: FC<IProps> = ({ index }) => {
  const [searchQuery, updateQuery] = useSafeState('');

  const { field } = useController<
    MFilterInput,
    `assignees.${number}.memberFilter.byLocation.values`
  >({
    name: `assignees.${index}.memberFilter.byLocation.values`,
    defaultValue: [],
  });

  const locations = useLocationsList();

  const locationList = useMemo(() => {
    if (!searchQuery?.trim()) {
      return locations;
    }

    const reg = searchRegExp(searchQuery, 'gi');

    return locations.filter((value) => {
      return value.name?.match(reg);
    });
  }, [locations, searchQuery]);

  const onSelectAll = (
    event: ChangeEvent<HTMLInputElement>,
    callback: CallableFunction
  ) => {
    if (event.target.checked) {
      field.onChange(locationList.map((j) => j.eid));
    } else {
      field.onChange([]);
    }
    callback(event);
  };

  const [allItemChecked, isIndeterminate] = useMemo(() => {
    const all = locationList?.every((val) => field.value?.includes(val.eid));
    const some = locationList?.some((val) => field.value?.includes(val.eid));
    return [all && !!locationList?.length, some && !all];
  }, [locationList, field.value]);

  return (
    <Flex flexDir='column' gap={1} overflow='auto' sx={sxScrollStyle}>
      <SearchInput
        size='lg'
        placeholder='Search location'
        hideShortcuts
        value={searchQuery}
        onChange={(event) => updateQuery(event.target.value)}
      />

      <FilterSelectAll
        name={`assignees.${index}.memberFilter.byLocation.selectAll`}
        allItemChecked={allItemChecked}
        isIndeterminate={isIndeterminate}
        onChange={onSelectAll}
      />

      <CheckboxGroup value={field.value} onChange={field.onChange}>
        <ListWrapper haveData={locationList?.length > 0}>
          {locationList.map((item) => {
            return <LocationOption key={item.eid} item={item} />;
          })}
        </ListWrapper>
      </CheckboxGroup>
    </Flex>
  );
};

export default ByLocationSelect;
