import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import { useHistory } from 'react-router-dom';
import { gql, useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { userObj } from 'sop-commons/src/client';

import { useUploadImage } from 'hooks';
import { getAvatar } from 'utils';
import { EMAIL_REGEX } from 'utils/constants';
import Image from 'ui-components/Image';

import BrandColorSelection from './BrandColorSelection';
import { SettingFormValues } from './Setting';
import { GET_USER } from '../Login/login.graphql';

import logo from '../../assets/images/logo.webp';
import { roleObj } from 'ui-components/DashboardMenu';

interface UpdateEntityVariable {
  input: {
    address?: string;
    analytics?: boolean;
    brandColor?: string;
    eid: string;
    email?: string;
    enableNotification?: boolean;
    headquarters?: string;
    isDemo?: boolean;
    name?: string;
    onboarded?: boolean;
    phone?: string;
    profilePic?: string;
    supportedLanguages?: string[];
  };
}

const UPDATE_ENTITY_BY_ID = gql`
  mutation UpdateEntityById($input: UpdateEntityInput) {
    updateEntityById(input: $input) {
      eid
    }
  }
`;

interface IProps {}

const CompanySettings: FC<IProps> = () => {
  const history = useHistory();
  const { t } = useTranslation(['common', 'setting']);
  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  });

  const [isUploading, setIsUploading] = useState(false);
  const userObject = useReactiveVar(userObj);
  const uploadImage = useUploadImage();

  const [updateCompanyProfile, { loading }] = useMutation<
    never,
    UpdateEntityVariable
  >(UPDATE_ENTITY_BY_ID, {
    onCompleted: () => {
      toast({
        title: t('setting:companyProfileUpdated'),
        status: 'success',
      });
      getUser();
    },
    onError: () => {
      toast({
        title: t('setting:companyProfileNotUpdated'),
        status: 'error',
      });
    },
  });

  const [updateProfileIcon, { loading: updatingProfile }] = useMutation<
    never,
    UpdateEntityVariable
  >(UPDATE_ENTITY_BY_ID, {
    onCompleted: () => {
      toast({
        title: t('setting:profileIconUpdated'),
        status: 'success',
      });
      getUser();
    },
    onError: () => {
      toast({
        title: t('setting:profileIconNotUpdated'),
        status: 'error',
      });
    },
  });

  const { control, handleSubmit, setValue, getValues } =
    useForm<SettingFormValues>({
      defaultValues: {
        companyName: '',
        headquarters: '',
        email: '',
        phoneNumber: '',
        profilePic: '',
        brandColor: '',
      },
    });

  const [getUser] = useLazyQuery(GET_USER, {
    onCompleted: (data: any) => {
      userObj(data?.user || {});
      const roles = data?.user?.entity?.roles;
      if (roles && roles.length) {
        let roleWithColor: any = {};
        roles.forEach((role: any) => {
          roleWithColor[role.name] = role.color;
        });

        roleObj(roleWithColor);
      }
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    const userEntity = userObject?.entity;

    setValue('companyName', userEntity?.name?.trim(), {
      shouldDirty: false,
    });
    setValue('headquarters', userEntity?.headquarters, {
      shouldDirty: false,
    });
    setValue('email', userEntity?.email, { shouldDirty: false });
    setValue('phoneNumber', userEntity?.phone, { shouldDirty: false });
    setValue('profilePic', userEntity?.profilePic!);
    setValue('brandColor', userEntity?.brandColor!);
  }, [userObject?.entity]);

  const onSubmit = (values: SettingFormValues) => {
    return updateCompanyProfile({
      variables: {
        input: {
          eid: userObject?.entity?.eid,
          email: values.email,
          name: values.companyName,
          phone: values.phoneNumber,
          headquarters: values.headquarters,
          brandColor: values.brandColor,
        },
      },
    });
  };

  const onFileChange = async (files: FileList) => {
    try {
      setIsUploading(true);
      const imageUrl = await uploadImage(files?.[0] || null);
      setValue('profilePic', imageUrl);
      await updateProfileIcon({
        variables: {
          input: {
            eid: userObject?.entity?.eid,
            profilePic: imageUrl,
          },
        },
      });
      setIsUploading(false);
    } catch (e) {
      setIsUploading(false);
    }
  };

  const { getInputProps, open } = useDropzone({
    onDrop: async (acceptedFiles) => {
      await onFileChange(acceptedFiles as unknown as FileList);
    },
    accept: { 'image/*': ['.png', '.jpg', '.jpeg'] },
    multiple: false,
    noClick: true,
    noKeyboard: true,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex gap={6}>
        <Stack flex={1} spacing={6}>
          <Flex flexDirection='column' gap={4}>
            <Controller
              control={control}
              name='companyName'
              rules={{
                required: t('setting:validation.companyNameRequired'),
              }}
              render={({ field, fieldState }) => {
                return (
                  <FormControl isInvalid={!!fieldState.error}>
                    <FormLabel mb={1} fontSize='14px' fontWeight='600'>
                      {t('setting:companyName')}
                    </FormLabel>
                    <Input
                      id='companyName'
                      placeholder={t('setting:placeholder.companyName')}
                      {...field}
                    />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                );
              }}
            />

            <Controller
              control={control}
              name='headquarters'
              rules={{
                required: t('setting:validation.headquarter_required'),
              }}
              render={({ field, fieldState }) => {
                return (
                  <FormControl isInvalid={!!fieldState.error}>
                    <FormLabel mb={1} fontSize='14px' fontWeight='600'>
                      {t('setting:headquarters')}
                    </FormLabel>
                    <Input
                      id='companyName'
                      placeholder={t('setting:placeholder.headquarters')}
                      {...field}
                    />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                );
              }}
            />

            <Controller
              control={control}
              name='email'
              rules={{
                required: t('common:validation.email_required'),
                pattern: {
                  value: EMAIL_REGEX,
                  message: t('common:validation.email_invalid'),
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <FormControl isInvalid={!!fieldState.error}>
                    <FormLabel mb={1} fontSize='14px' fontWeight='600'>
                      {t('setting:email')}
                    </FormLabel>
                    <Input
                      id='companyName'
                      type='email'
                      placeholder={t('setting:placeholder.email')}
                      {...field}
                    />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                );
              }}
            />

            <Controller
              control={control}
              name='phoneNumber'
              // rules={{
              //   required: true,
              // }}
              render={({ field, fieldState }) => {
                return (
                  <FormControl isInvalid={!!fieldState.error}>
                    <FormLabel mb={1} fontSize='14px' fontWeight='600'>
                      {t('setting:phoneNumber')}
                    </FormLabel>
                    <Input
                      id='phoneNumber'
                      placeholder={t('setting:placeholder.phoneNumber')}
                      {...field}
                    />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                );
              }}
            />

            {/* <Controller
              control={control}
              name='brandColor'
              render={({ field, fieldState }) => {
                return (
                  <FormControl isInvalid={!!fieldState.error}>
                    <FormLabel mb={1} fontSize='14px' fontWeight='600'>
                      {t('setting:chooseBrandColor')}
                    </FormLabel>
                    <BrandColorSelection {...field} />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                );
              }}
            /> */}
          </Flex>

          <Flex gap='10px' justify='flex-end' pt={6}>
            <Button
              width='150px'
              variant='outline'
              fontSize='15px'
              fontWeight='600'
              size='lg'
              px={4}
              onClick={() => history.goBack()}
            >
              {t('common:cancel')}
            </Button>

            <Button
              width='150px'
              colorScheme='blue'
              type='submit'
              fontSize='15px'
              fontWeight='600'
              size='lg'
              px={4}
              isLoading={loading || updatingProfile}
              disabled={loading || updatingProfile}
            >
              {t('common:save')}
            </Button>
          </Flex>
        </Stack>

        <Flex
          align='center'
          flexDir='column'
          width='180px'
          mt='26px'
          gap='16px'
          marginRight='100px'
        >
          <Box position='relative'>
            <IconButton
              position='absolute'
              right={0}
              transform='auto'
              translateX='30%'
              translateY='-30%'
              size='sm'
              boxShadow='md'
              borderRadius='full'
              aria-label='edit'
              icon={<FontAwesomeIcon icon={faPen as IconProp} />}
              cursor='pointer'
              isLoading={isUploading}
              onClick={open}
            />
            <input {...getInputProps()} />
            <Controller
              control={control}
              name='profilePic'
              render={({ field }) => {
                return (
                  <Image
                    width={178}
                    height={178}
                    src={getAvatar({
                      name: 'company-avatar',
                      url: field.value,
                      defaultAvatar: logo,
                    })}
                    style={{
                      borderRadius: 10,
                      objectFit: 'cover',
                      maxHeight: '178px',
                      border: '2px solid rgba(154, 159, 165, 0.25)',
                    }}
                  />
                );
              }}
            />
          </Box>
        </Flex>
      </Flex>
    </form>
  );
};

CompanySettings.displayName = 'p/S/CompanySettings';

export default CompanySettings;
