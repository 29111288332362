import {
  Box,
  Button,
  Divider,
  Flex,
  Popover,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import { ReactComponent as FilterIcon } from 'assets/images/nexus/compliance/filled.svg';
import { AuthRole } from 'authorization';
import { useUserDataSelector, useUserEntity } from 'hooks';
import { FC, useMemo, useState } from 'react';
import { FaArrowLeftLong } from 'react-icons/fa6';
import { MdOutlineArrowForwardIos } from 'react-icons/md';
import { BoxHeader } from '../../../../../../ui-components';
import Joblist from './Joblist';
import LocationFilter from './Locationlist';

interface Job {
  name: string;
  eid: string;
}

interface Location {
  eid: string;
  name: string;
  locationStatus: string;
}

interface FilterData {
  jobs: Job[];
  locations: Location[];
  locStatus: string;
}

const createInitialFilter = (
  jobs: Job[] | undefined,
  currUserLocations: Location[] | undefined
): FilterData => ({
  jobs:
    jobs?.map((job) => ({
      name: job.name,
      eid: job.eid,
    })) || [],
  locations:
    currUserLocations?.map((loc) => ({
      eid: loc.eid,
      name: loc.name,
      locStatus: loc.locationStatus,
    })) || [],
});

interface IProps {
  onLocationFilterChange?: (selectedLocationIds: string[]) => void;
  onJobFilterChange?: (selectedJobs: string[]) => void;
  selectedJobIds?: string[];
  selectedLocationIds?: string[];
}

const Filters: FC<IProps> = ({
  onLocationFilterChange,
  onJobFilterChange,
  selectedJobIds = [],
  selectedLocationIds = [],
}) => {
  const [activeView, setActiveView] = useState<'main' | 'job' | 'locationList'>(
    'main'
  );

  const { userAuthRole, userLocations, bussinessLocations } =
    useUserDataSelector((state) => ({
      userAuthRole: state.authRole,
      userLocations: state.locations as Location[],
      bussinessLocations: state.entity?.locations as Location[] | undefined,
    }));
  const { jobs } = useUserEntity((state) => ({
    jobs: state.roles as Job[] | undefined,
  }));

  const currUserLocations = useMemo(() => {
    return userAuthRole === AuthRole.SUPER_ADMIN
      ? bussinessLocations
      : userLocations;
  }, [userAuthRole]);

  const [filter] = useState<FilterData>(() =>
    createInitialFilter(jobs, currUserLocations)
  );

  const totalFilters = selectedJobIds.length + selectedLocationIds.length;

  return (
    // @ts-ignore
    <Flex gap={3} zIndex={100}>
      <Popover placement='bottom-end' isLazy lazyBehavior='unmount'>
        <PopoverTrigger>
          <Button
            variant='ghost'
            size='xs'
            position='relative'
            _hover={{ bg: 'transparent' }}
            _active={{ bg: 'transparent' }}
          >
            <FilterIcon cursor='pointer' />
            {totalFilters > 0 && (
              <Box
                as='span'
                position='absolute'
                top='-5px'
                right='5px'
                bg='#2A85FF'
                color='white'
                borderRadius='full'
                minW='16px'
                h='16px'
                fontSize='10px'
                display='flex'
                alignItems='center'
                justifyContent='center'
              >
                {totalFilters}
              </Box>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent px='15px' py='15px' borderRadius='16px' w='400px'>
          <PopoverHeader borderBottomWidth='0'>
            <Flex justify='space-between' alignItems='center'>
              <BoxHeader color='#CABDFF' title='Filters' fontSize='18px' />
              <PopoverCloseButton
                pos='relative'
                borderRadius='full'
                top='0'
                right='-1'
              />
            </Flex>
          </PopoverHeader>

          {activeView === 'main' ? (
            <Flex
              direction='column'
              gap='16px'
              py='10px'
              fontSize='14px'
              fontWeight={400}
              textColor='#333B4F'
              px='10px'
            >
              <Flex
                justifyContent='space-between'
                alignItems='center'
                cursor='pointer'
                onClick={() => setActiveView('job')}
              >
                <Text fontSize='14px' fontWeight={400}>
                  By job
                </Text>
                <MdOutlineArrowForwardIos size={15} />
              </Flex>
              <Divider />
              <Flex
                justifyContent='space-between'
                alignItems='center'
                cursor='pointer'
                onClick={() => setActiveView('locationList')}
              >
                <Text fontSize='14px' fontWeight={400}>
                  By location
                </Text>
                <MdOutlineArrowForwardIos size={15} />
              </Flex>
            </Flex>
          ) : activeView === 'job' ? (
            <Box py='10px' px='10px'>
              <Flex
                gap={3}
                cursor='pointer'
                onClick={() => setActiveView('main')}
                alignItems='center'
                fontSize='14px'
                fontWeight={600}
                mb='20px'
              >
                <FaArrowLeftLong size={15} color='#6F767E' />
                <Box>By job</Box>
              </Flex>
              <Joblist
                filters={filter}
                value={selectedJobIds}
                onChange={onJobFilterChange}
              />
            </Box>
          ) : (
            <Box py='10px' px='10px'>
              <Flex
                gap={3}
                cursor='pointer'
                onClick={() => setActiveView('main')}
                alignItems='center'
                fontSize='14px'
                fontWeight={600}
                mb='20px'
              >
                <FaArrowLeftLong size={15} color='#6F767E' />
                <Box>By location</Box>
              </Flex>
              <LocationFilter
                filters={filter}
                value={selectedLocationIds}
                onChange={onLocationFilterChange}
              />
            </Box>
          )}
        </PopoverContent>
      </Popover>
    </Flex>
  );
};

export default Filters;
