import React, { FC } from 'react';

import {
  CloseButton,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  useBoolean,
} from '@chakra-ui/react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { AddIcon } from '@chakra-ui/icons';

import { TAssignByType } from '../../../../../../types';
import { Button } from '../../../../../../atoms';
import { toArray } from '../../../../../../utils';

import { IAssigneeInput } from '../../training-input.types';
import GroupLocationSelect from './GroupLocationSelect';

interface IProps {
  assignBy: TAssignByType;
  index: number;
  disabled?: boolean;
}

const AtLocationSelect: FC<IProps> = ({ index, disabled, assignBy }) => {
  const [isSelected, action] = useBoolean(false);

  const { setValue } = useFormContext<IAssigneeInput>();

  const [value, locType] = useWatch<
    IAssigneeInput,
    [`assignees.${number}.locationIds`, `assignees.${number}.locationType`]
  >({
    name: [`assignees.${index}.locationIds`, `assignees.${index}.locationType`],
  });

  if (
    !(isSelected || value?.length || ['open', 'development'].includes(locType!))
  ) {
    return (
      <Flex>
        <Button
          variant='ghost'
          colorScheme='purple'
          leftIcon={<AddIcon />}
          onClick={action.on}
        >
          Add Location Condition
        </Button>
      </Flex>
    );
  }

  return (
    <Controller<IAssigneeInput, `assignees.${number}.locationIds`>
      name={`assignees.${index}.locationIds`}
      disabled={disabled}
      rules={{
        // required: 'Select assignee locations',
        validate: async (value, formData) => {
          await new Promise((resolve) => setTimeout(resolve, 100));
          const _val = toArray(value);
          const locType =
            formData?.assignees?.[index]?.locationType || 'custom';
          if (locType === 'custom' && _val?.length === 0) {
            return 'Select locations for assignee';
          }
        },
      }}
      render={({ field, fieldState }) => (
        <FormControl isInvalid={fieldState.invalid}>
          <Flex justify='space-between' align='center' marginBottom={1}>
            <FormLabel color='#6F767E' marginBottom={0}>
              at location
            </FormLabel>

            {disabled ? null : (
              <CloseButton
                color='blue.500'
                onClick={() => {
                  action.off();
                  field.onChange([]);
                  setValue(`assignees.${index}.locationType`, null as never);
                }}
              />
            )}
          </Flex>

          <GroupLocationSelect index={index} assignBy={assignBy} {...field} />
          <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
        </FormControl>
      )}
    />
  );
};

export default AtLocationSelect;
