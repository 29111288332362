import React, { FC } from 'react';
import { PieChart, Pie, Cell } from 'recharts';
import { IOverallUserDetailsAnalytics } from '../types/myTraining.types';
import { Box, Center, Flex, Text, Tooltip } from '@chakra-ui/react';
import Loader from 'sub-components/Loader';

interface IPieChartIdentifierProps {
  overallUserDetailsAnalytics: IOverallUserDetailsAnalytics;
}

export const PieChartIdentifier: FC<IPieChartIdentifierProps> = ({
  overallUserDetailsAnalytics,
}) => {
  const identifier = [
    {
      color: '#FF6A55',
      count: overallUserDetailsAnalytics?.overdue,
      title: 'Overdue',
    },
    {
      color: '#FFD88D',
      count: overallUserDetailsAnalytics?.inProgress,
      title: 'In Progress',
    },
    {
      color: '#83BF6E',
      count: overallUserDetailsAnalytics?.completed,
      title: 'Completed',
    },
    {
      color: '#FFBC99',
      count: overallUserDetailsAnalytics?.yetToStart,
      title: 'Yet to start',
    },
  ];

  return (
    <Flex gap={3} flexDir='column' ml='-40px'>
      {identifier?.map((item) => (
        <Flex
          alignItems='center'
          borderRadius='8px'
          px='8px'
          backgroundColor='#F1F2FA'
          gap={2}
          key={item?.title}
          width={'143px'}
          height={'32px'}
        >
          <Box
            borderRadius='4px'
            width='12px'
            height='12px'
            backgroundColor={item?.color}
          />
          <Text fontWeight={700} fontSize='14px'>
            {item?.count}
          </Text>
          <Text fontWeight={400} fontSize='14px'>
            {item?.title}
          </Text>
        </Flex>
      ))}
    </Flex>
  );
};

interface IProps {
  overallUserDetailsAnalyticsLoader: boolean;
  overallUserDetailsAnalytics: IOverallUserDetailsAnalytics;
}

const ProgressTrackerGraph: FC<IProps> = ({
  overallUserDetailsAnalytics,
  overallUserDetailsAnalyticsLoader,
}) => {
  const COLORS = {
    InProgress: '#FFD88D',
    Overdue: '#FF6A55',
    YetToStart: '#FFBC99',
    Completed: '#83BF6E',
    NoData: '#F4F4F4',
  };

  // Ensure all values are numbers (default to 0 if undefined/null)
  const inProgress = overallUserDetailsAnalytics?.inProgress ?? 0;
  const overdue = overallUserDetailsAnalytics?.overdue ?? 0;
  const completed = overallUserDetailsAnalytics?.completed ?? 0;
  const yetToStart = overallUserDetailsAnalytics?.yetToStart ?? 0;

  const myTrainingGraphData =
    !overallUserDetailsAnalytics?.inProgress &&
    !overallUserDetailsAnalytics?.overdue &&
    !overallUserDetailsAnalytics?.completed &&
    !overallUserDetailsAnalytics?.yetToStart
      ? [{ name: 'NoData', value: 1 }]
      : [
          { name: 'InProgress', value: inProgress },
          { name: 'Overdue', value: overdue },
          { name: 'Completed', value: completed },
          { name: 'YetToStart', value: yetToStart },
        ];

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    name,
  }) => {
    // Don't show percentage label for the NoData segment
    if (name === 'NoData') return null;

    const RADIAN = Math.PI / 180;
    const offset = 25;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5 + offset;

    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <foreignObject
        x={x - 20}
        y={y - 20}
        width={40}
        height={40}
        style={{ overflow: 'visible' }}
      >
        {percent > 0 && (
          <Tooltip label={`${(percent * 100).toFixed(0)}%`}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#FFFFFF',
                borderRadius: '50%',
                width: '40px',
                height: '40px',
                boxShadow: '2px 2px 5px #B0B0B040',
              }}
            >
              <span
                style={{
                  fontWeight: 500,
                  color: 'black',
                  fontSize: '12px',
                }}
              >
                {`${(percent * 100).toFixed(0)}%`}
              </span>
            </div>
          </Tooltip>
        )}
      </foreignObject>
    );
  };

  const pieChart = () => (
    <PieChart width={400} height={400}>
      <text
        x={200}
        y={165}
        textAnchor='middle'
        dominantBaseline='middle'
        style={{ fontSize: '12px', fontWeight: '400', fill: '#6F767E' }}
      >
        Active paths
      </text>
      <text
        x={200}
        y={190}
        textAnchor='middle'
        dominantBaseline='middle'
        style={{ fontSize: '24px', fontWeight: '700', fill: '#111315' }}
      >
        {overallUserDetailsAnalytics?.activeTp}
      </text>
      <Pie
        data={myTrainingGraphData}
        cx={200}
        cy={180}
        innerRadius={80}
        outerRadius={120}
        fill='#8884d8'
        paddingAngle={0}
        dataKey='value'
        labelLine={false}
        label={renderCustomizedLabel}
      >
        {myTrainingGraphData.map((entry, index) => (
          // <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          <Cell key={entry.name} fill={COLORS[entry.name]} />
        ))}
      </Pie>
    </PieChart>
  );

  return (
    <Flex
      width='100%'
      height='100%'
      flexDir='column'
      borderRadius='12px'
      border='2px solid rgba(239, 239, 239, 1)'
    >
      <Box pl='24px' pt='24px' fontWeight={600} fontSize='20px'>
        Progress Tracker
      </Box>

      <Flex mt='30px' alignItems='center'>
        {overallUserDetailsAnalyticsLoader ? (
          <Center maxBlockSize={'100%'} h={'30vh'} w={'full'}>
            <Loader />
          </Center>
        ) : (
          <>
            {pieChart()}
            <PieChartIdentifier
              overallUserDetailsAnalytics={overallUserDetailsAnalytics}
            />
          </>
        )}
      </Flex>
    </Flex>
  );
};

ProgressTrackerGraph.displayName =
  'packages/sop-web/src/sub-components/training-v2/dashboard/components/myTrainings/topSection/ProgressTrackerGraph';
export default ProgressTrackerGraph;
