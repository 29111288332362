import React, { FC } from 'react';
import {
  Badge,
  Box,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from '@chakra-ui/react';
import { ReactComponent as FilterIcon } from 'assets/images/nexus/compliance/filled.svg';
import MainContainer from './MainContainer';
import { ITypes } from '../Header';
import { TAnalyticsFilterClearFilter } from '../../components/DetailsPageFilters/AnalyticsBaseFilter';
import { DetailsFiltersType } from '../../components/AnalyticsContainerHeader';

export type ClearFilterType =
  | 'all'
  | 'types'
  | 'categories'
  | 'statuses'
  | 'jobs'
  | 'locations';

interface FilterContainerProps {
  filters: any;
  setFilters?: React.Dispatch<React.SetStateAction<any>>;
  handleTypeSelection?: (
    e: React.ChangeEvent<HTMLInputElement>,
    type: ITypes
  ) => void;
  handleStatusSelection?: (
    e: React.ChangeEvent<HTMLInputElement>,
    status: ITypes
  ) => void;
  handleCategorySelection?: (
    e: React.ChangeEvent<HTMLInputElement>,
    category: ITypes
  ) => void;
  handleJobSelection?: (
    e: React.ChangeEvent<HTMLInputElement>,
    jobs: ITypes
  ) => void;
  handleMembersStatusSelection?: (
    e: React.ChangeEvent<HTMLInputElement>,
    status: ITypes
  ) => void;
  handleLocationsSelection?: (
    e: React.ChangeEvent<HTMLInputElement>,
    locations: ITypes
  ) => void;
  clearFilters: (type?: ClearFilterType) => void;
}

const FilterContainer: FC<FilterContainerProps> = ({
  filters,
  setFilters,
  handleTypeSelection,
  handleStatusSelection,
  handleCategorySelection,
  handleJobSelection,
  handleMembersStatusSelection,
  handleLocationsSelection,
  clearFilters,
}) => {
  const filterCount = filters?.totalFiltersTypes;
  return (
    <Popover placement='left-end' isLazy strategy='fixed' size={'lg'}>
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            {filterCount > 0 ? (
              <Box position='relative' display='inline-block'>
                <Badge
                  position='absolute'
                  top='-6px'
                  right='-5px'
                  bgColor='#2A85FF'
                  borderRadius='full'
                  textAlign='center'
                  w='18px'
                  fontSize='12px'
                  fontWeight={600}
                  color='white'
                >
                  {filterCount}
                </Badge>
                <FilterIcon cursor='pointer' />
              </Box>
            ) : (
              <Box>
                <FilterIcon cursor='pointer' />
              </Box>
            )}
          </PopoverTrigger>
          <PopoverContent minHeight={'400px'} p={'10px'} borderRadius={'12px'}>
            <PopoverArrow />
            <PopoverBody>
              <MainContainer
                filters={filters}
                setFilters={setFilters}
                handleTypeSelection={handleTypeSelection}
                handleStatusSelection={handleStatusSelection}
                handleCategorySelection={handleCategorySelection}
                handleJobSelection={handleJobSelection}
                handleMembersStatusSelection={handleMembersStatusSelection}
                handleLocationsSelection={handleLocationsSelection}
                clearFilters={clearFilters}
              />
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};

export default FilterContainer;
