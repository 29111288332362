import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  makeVar,
  split,
  from,
  defaultDataIdFromObject,
} from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { setContext } from "@apollo/client/link/context";
import { WebSocketLink } from "@apollo/client/link/ws";
import { TokenRefreshLink } from "apollo-link-token-refresh";
import jwt_decode from "jwt-decode";
import { UserData } from "./user-data.types";
import { match, P } from "ts-pattern";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import * as Sentry from "@sentry/react";
import { GET_STARTED, INITIALIZE, PUBLIC_AUDIT_REPORT } from "appRoutes";

type RoutePattern = {
  path: string;
  exact?: boolean; // if true, requires exact match
  withParams?: boolean; // if true, allows parameters after the base path
};

// Define public routes with their matching behavior
const PUBLIC_ROUTE_PATTERNS: RoutePattern[] = [
  { path: "/login", exact: true },
  { path: GET_STARTED, exact: true },
  { path: INITIALIZE, exact: true },
  { path: "/forgot-password", exact: true },
  { path: "/invite", withParams: true },
  { path: "/magiclink", exact: true },
  { path: "/audits/public-report", withParams: true },
  { path: "/otp", exact: true },
  { path: "/reset-password", withParams: true },
];

/**
 * Checks if a given pathname matches any of the defined public routes
 * @param pathname - The current pathname to check
 * @returns boolean indicating if the path is public
 */
const isPublicRoute = (pathname: string): boolean => {
  // Normalize the pathname
  const normalizedPath = pathname.replace(/\/+$/, ""); // Remove trailing slashes

  return PUBLIC_ROUTE_PATTERNS.some((pattern) => {
    // Case 1: Exact match required
    if (pattern.exact) {
      return normalizedPath === pattern.path;
    }

    // Case 2: Allow parameters after base path
    if (pattern.withParams) {
      return (
        normalizedPath === pattern.path ||
        normalizedPath.startsWith(pattern.path + "/")
      );
    }

    // Case 3: Default to startsWith for backward compatibility
    return normalizedPath.startsWith(pattern.path);
  });
};

// return PUBLIC_ROUTES.some((route) => pathname.startsWith(route));

export const isJwtValid = (token?: string): boolean => {
  if (token) {
    const decodedToken = jwt_decode<Record<string, never>>(token);
    return decodedToken?.exp >= (new Date().getTime() + 1) / 1000;
  }
  return false;
};

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_ENDPOINT,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("authToken");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      authorization: token ? `Bearer ${token}` : "",
      ...headers,
    },
  };
});

let wsLink = new WebSocketLink({
  uri: `${process.env.REACT_APP_SOCKET_ENDPOINT}`,
  options: {
    reconnect: true,
    connectionParams: () => {
      const token = localStorage.getItem("authToken");
      return {
        Authorization: token ? `Bearer ${token}` : "",
      };
    },
    lazy: true,
  },
});

export const userObj = makeVar<UserData>({} as UserData);
export const chatStatus = makeVar<boolean>(false);
export type { UserData };

export const authTokenVar = makeVar(localStorage.getItem("authToken"));

const isTokenExpired = (token: string): Boolean => {
  try {
    if (token) {
      const decodedToken = jwt_decode<Record<string, never>>(token);
      if (decodedToken?.exp < (new Date().getTime() + 1) / 1000) {
        return true;
      }
    }
  } catch (err) {
    Sentry.captureException(err);
    return true;
  }
  return false;
};

const refreshTokenQuery = `
  query GetToken($refreshToken: String!) {
    getToken(refreshToken: $refreshToken) {
      accessToken
    }
  }
`;

const fetchAccessToken = async () => {
  const refreshToken = localStorage.getItem("refreshAuthToken");

  const graphqlUrl: string = process.env.REACT_APP_API_ENDPOINT!;

  const response = await fetch(graphqlUrl, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query: refreshTokenQuery,
      variables: {
        refreshToken: refreshToken,
      },
    }),
  });
  return await response.json();
};

const retryLink = new RetryLink({
  attempts: {
    max: 1, // Only retry once
    retryIf: (error, operation) => {
      // Only retry if it's a network error with 500x status
      const isNetworkError =
        error?.response?.status >= 500 && error?.response?.status < 600;
      return !!isNetworkError;
    },
  },
});

const refreshLink = new TokenRefreshLink({
  accessTokenField: "accessToken",
  isTokenValidOrUndefined: () => {
    // Safe localStorage access with error handling
    const getLocalStorageItem = (key: string) => {
      try {
        return localStorage.getItem(key);
      } catch (e) {
        console.error("localStorage unavailable:", e);
        return null;
      }
    };

    const isJwtValidWithBuffer = (
      token?: string,
      bufferMinutes = 5
    ): boolean => {
      if (token) {
        try {
          const decodedToken = jwt_decode<Record<string, never>>(token);
          const bufferSeconds = bufferMinutes * 60;
          const currentTimeWithBuffer = Date.now() / 1000 + bufferSeconds;
          return decodedToken?.exp >= currentTimeWithBuffer;
        } catch {
          return false;
        }
      }
      return false;
    };

    const token = getLocalStorageItem("authToken");
    const refreshToken = getLocalStorageItem("refreshAuthToken");
    const currentPath = window.location.pathname;

    // Check if tokens are truly present
    const hasAuthToken = token && token !== "null" && token !== "undefined";
    const hasRefreshToken =
      refreshToken && refreshToken !== "null" && refreshToken !== "undefined";

    // No tokens at all
    if (!hasAuthToken && !hasRefreshToken) {
      if (!isPublicRoute(currentPath)) {
        window.location.href = "/login";
      }
      return true;
    }

    // Only auth token exists
    if (hasAuthToken && !hasRefreshToken) {
      const isAuthValid = isJwtValidWithBuffer(token);
      if (!isAuthValid) {
        localStorage.removeItem("authToken");
        if (!window.location.pathname.includes("/login")) {
          window.location.href = "/login";
        }
      }
      return isAuthValid;
    }

    // Has refresh token - check if it's valid
    if (hasRefreshToken) {
      const isRefreshValid = isJwtValidWithBuffer(refreshToken);

      // Refresh token is expired
      if (!isRefreshValid) {
        localStorage.removeItem("authToken");
        localStorage.removeItem("refreshAuthToken");
        if (!window.location.pathname.includes("/login")) {
          window.location.href = "/login";
        }
        return true;
      }

      // Only refresh token exists and it's valid
      if (!hasAuthToken && isRefreshValid) {
        return false; // Trigger refresh
      }

      // Both tokens exist - check auth token
      return isJwtValidWithBuffer(token!);
    }
    return true;
  },
  fetchAccessToken: fetchAccessToken,
  handleFetch: (accessToken) => {
    authTokenVar(accessToken);
    localStorage.setItem("authToken", accessToken);
  },
  handleResponse: () => (response: any) => {
    if (response?.data?.getToken) {
      return response?.data?.getToken;
    }
  },
  handleError: (err) => {
    // Sentry.captureException(err);
    console.log(err);
    userObj({} as UserData);
    localStorage.removeItem("authToken");
    localStorage.removeItem("refreshAuthToken");
    window.location.href = "/login";
    // showErrorMessage("Session expired. Please log in again.");
  },
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const stringify = (data: object) => JSON.stringify(data, null, 2);

/** Error Interceptor to handle various errors coming from graphql backend */
const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  console.log(
    JSON.stringify({ errors: { GE: graphQLErrors, NE: networkError } })
  );
  if (graphQLErrors)
    graphQLErrors?.map(({ message, locations, path, extensions }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
      Sentry.captureMessage(
        stringify({
          type: "[GraphQL error]",
          message: message,
          locations: locations,
          path: path?.toString(),
          operationName: operation.operationName,
          pathname: window?.location?.toString(),
        })
      );

      // If unauthorized error, clear auth and redirect to login
      if (extensions?.code === "UNAUTHENTICATED") {
        userObj({} as UserData);
        localStorage.removeItem("authToken");
        localStorage.removeItem("refreshAuthToken");
        window.location.href = "/login";
        return;
      }
      // Add your error handling logic here
      // For example, redirect on a specific error
      // if (message.includes('some specific error')) {
      //   window.location.href = '/error-page';
      // }
    });

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    Sentry.captureException(networkError, {
      data: {
        operationName: operation.operationName,
        // query: operation.query?.loc?.source?.body,
        pathname: window?.location?.toString(),
      },
    });
    // Redirect on network errors
    // window.location.href = '/network-error';
  }
});

export const client = new ApolloClient({
  // link: authLink.concat(httpLink),
  /** Uncomment to add `errorLink` to the array */
  // link: from([errorLink, refreshLink, authLink, splitLink]),
  link: from([errorLink, refreshLink, retryLink, authLink, splitLink]),
  cache: new InMemoryCache({
    typePolicies: {
      User: {
        merge: true,
      },
      ChatGroup: {
        merge: true,
      },
    },
    dataIdFromObject: (responseObject, context) => {
      return match(responseObject)
        .with(
          {
            __typename: "User",
            eid: P.string,
          },
          () => {
            return `${responseObject.__typename}:${responseObject.eid}`;
          }
        )
        .with(
          {
            __typename: "ChatGroup",
            guid: P.string,
          },
          () => {
            return `${responseObject.__typename}:${responseObject.guid}`;
          }
        )
        .with(
          {
            __typename: "FormsCategory",
            eid: P.string,
          },
          () => {
            return `${responseObject.__typename}:${responseObject.eid}`;
          }
        )
        .with(
          {
            __typename: "TPProgressProgress",
            updatedAt: P.string,
          },
          () => {
            // TODO this one is temporary solution
            return `${responseObject.__typename}:${responseObject.updatedAt}`;
          }
        )
        .otherwise(() => defaultDataIdFromObject(responseObject, context));
    },
    // dataIdFromObject: (responseObject) => {
    //   switch (responseObject.__typename) {
    //     case "LocationLaunchContentsTask":
    //       if (responseObject.eid && responseObject.launchId) {
    //         return `${responseObject.__typename}:${responseObject.launchId}:${responseObject.eid}`;
    //       }
    //       return defaultDataIdFromObject(responseObject);
    //     case "LauncherLocations":
    //       return `${responseObject.__typename}:${responseObject.launchId}`;
    //   }
    //
    //   if (responseObject.__typename && responseObject.eid) {
    //     return `${responseObject.__typename}:${responseObject.eid}`;
    //   }
    //   return defaultDataIdFromObject(responseObject);
    // },
  }),
});
