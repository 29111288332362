import ProgressBar from './ProgressBar';
import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { ViewProgress } from './ViewProgress';

const ProgressSection: FC = () => {
  return (
    <Flex align='center' gap={4}>
      <ProgressBar />
      <ViewProgress />
    </Flex>
  );
};

ProgressSection.displayName =
  'displayName:sub-components/training-v2/play/components/ProgressSection/ProgressSection';

export default ProgressSection;
