import React, { FC } from 'react';
import {
  forwardRef,
  HStack,
  useNumberInput,
  InputProps,
  UseNumberInputProps,
  IconButton,
  Flex,
} from '@chakra-ui/react';
import { Input } from '../../../../../atoms';

import { ReactComponent as ChevronDownIcon } from 'assets/images/chevronDown.svg';
import { ReactComponent as ChevronUpIcon } from 'assets/images/chevronUp.svg';

interface IProps
  extends UseNumberInputProps,
    Omit<
      InputProps,
      | 'defaultValue'
      | 'max'
      | 'min'
      | 'onChange'
      | 'onInvalid'
      | 'step'
      | 'value'
    > {}

const CustomNumberInput = forwardRef<IProps, 'input'>((props, ref) => {
  const {
    getInputProps,
    getIncrementButtonProps,
    getDecrementButtonProps,
    htmlProps,
  } = useNumberInput({
    step: 1,
    ...props,
  });

  const input = getInputProps({}, ref);

  return (
    <HStack>
      <Input {...htmlProps} {...input} />
      <Flex flexDir='column'>
        <IconButton
          size='xs'
          height={5}
          minWidth={5}
          aria-label='up'
          variant='ghost'
          borderTopRadius='4px'
          borderBottomRadius='0'
          {...getIncrementButtonProps()}
        >
          <ChevronUpIcon />
        </IconButton>
        <IconButton
          size='xs'
          height={5}
          minWidth={5}
          aria-label='down'
          variant='ghost'
          borderTopRadius='0'
          borderBottomRadius='4px'
          {...getDecrementButtonProps()}
        >
          <ChevronDownIcon />
        </IconButton>
      </Flex>
    </HStack>
  );
});

export default CustomNumberInput;
