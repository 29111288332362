import React, { FC } from 'react';
import { Box, Center, chakra, Flex, Spacer } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { Button } from '../../../../../../../atoms';
import { BoxHeader } from '../../../../../../../ui-components';
import { HeaderColors } from '../../../../../../../configs';

interface IProps {}

const SkillVerifierSamplePreview: FC<IProps> = () => {
  return (
    <Flex flex={1} flexDir='column' paddingTop='37px' overflow='hidden'>
      <Flex gap='6px' padding='5px 10px'>
        <Center height='19px' cursor='pointer'>
          <ArrowBackIcon color='#272B30' boxSize='16px' />
        </Center>
        <Flex flexDir='column'>
          <Box fontSize='13px' fontWeight='600'>
            Evaluate ABC
          </Box>
          <Box fontSize='8px' color='#6F767E'>
            For training path: <chakra.span color='#33383F'>xyz</chakra.span>
          </Box>
        </Flex>
      </Flex>

      <Flex
        flex={1}
        flexDir='column'
        gap={2}
        padding='5px 10px'
        bg='#F4F4F4'
        overflow='auto'
        sx={{
          '&::-webkit-scrollbar': {
            width: '5px',
            height: '0px',
          },
          '&::-webkit-scrollbar-track': {
            // background: '#f1f1f1',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#55555555',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#555',
          },
        }}
      >
        <Flex
          flexDir='column'
          gap={2}
          padding='10px'
          borderRadius='8px'
          bg='#FFFFFF'
        >
          <Box fontSize='12px' fontWeight='600' color='#272B30'>
            Quiz score: 5{' '}
            <chakra.span fontSize='10px' color='#6F767E'>
              /10
            </chakra.span>
          </Box>

          <Box
            fontSize='11px'
            fontWeight='600'
            color='#2A85FF'
            padding='5px 10px'
            alignSelf='center'
            cursor='pointer'
          >
            {`View responses ->`}
          </Box>
        </Flex>

        <Flex
          flexDir='column'
          gap='5px'
          padding='10px'
          borderRadius='8px'
          bg='#FFFFFF'
        >
          <Flex paddingBottom='6px'>
            <Box fontSize='13px' fontWeight='600' color='#272B30'>
              Baking test review
            </Box>
          </Flex>

          <BoxHeader
            boxWidth={2.5}
            color={HeaderColors.Purple}
            title='Baking skill'
            fontSize='11px'
          />

          <Flex flexDir='column' gap='12px' padding='5px'>
            <Box fontSize='9px' fontWeight='500' color='#6F767E'>
              How much would you rate their skill? Rate on a scale of 1 to 10
              (where 10 is the best)
            </Box>

            <Flex
              fontSize='8px'
              fontWeight='500'
              color='#6F767E'
              gap='3px'
              justify='space-between'
            >
              {Array.from({ length: 10 }).map((_, i) => (
                <Center
                  key={i}
                  boxSize='16px'
                  minWidth='16px'
                  borderRadius='full'
                  border='1.25px solid #EFEFEF'
                  cursor='pointer'
                  _hover={{ boxShadow: 'md', bg: '#EFEFEF' }}
                >
                  {i + 1}
                </Center>
              ))}
            </Flex>
          </Flex>

          <Flex
            gap='6px'
            fontSize='11px'
            fontWeight='600'
            color='#2A85FF'
            padding='5px 10px'
            alignSelf='center'
            cursor='pointer'
            align='center'
          >
            Add another skill{' '}
            <FontAwesomeIcon icon={faCirclePlus as IconProp} />
          </Flex>
        </Flex>

        <Spacer />

        <Flex gap={3} paddingBottom={1}>
          <Button
            flex={1}
            size='xs'
            fontSize='9px'
            fontWeight='600'
            variant='outline'
          >
            Clear
          </Button>

          <Button
            flex={1}
            size='xs'
            fontSize='9px'
            fontWeight='600'
            colorScheme='blue'
          >
            Submit
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SkillVerifierSamplePreview;
