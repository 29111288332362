import React, { FC } from 'react';
import { Flex, Box } from '@chakra-ui/react';
import Loader from 'sub-components/Loader';

interface IProps {
  icon: React.ReactNode;
  title: string;
  count: string | undefined;
  overallAnalyticsLoader: boolean;
  scrollToTable: () => void;
  onClick: () => void;
}

const PerformanceCard: FC<IProps> = ({
  icon,
  title,
  count,
  overallAnalyticsLoader,
  scrollToTable,
  onClick,
}) => {
  return (
    <Flex
      cursor={'pointer'}
      onClick={() => {
        if (title === 'Total active paths') {
          scrollToTable();
        } else if (title === 'Overdue (members)') {
          scrollToTable();
        } else {
          onClick();
        }
      }}
      width={'full'}
      flexDir={'column'}
      border='2px solid rgba(239, 239, 239, 1)'
      borderRadius='12px'
      p='24px'
    >
      <Box>{icon}</Box>
      <Box
        mt={2}
        color='rgba(111, 118, 126, 1)'
        fontWeight={600}
        fontSize='15px'
        width='125px'
        wordBreak='break-word'
      >
        {title}
      </Box>
      <Box fontWeight={600} fontSize='32px'>
        {overallAnalyticsLoader ? <Loader /> : count}
      </Box>
    </Flex>
  );
};

PerformanceCard.displayName =
  'packages/sop-web/src/sub-components/training-v2/dashboard/components/locationOwner/PerformanceCard';
export default PerformanceCard;
