import { gql } from '@apollo/client';
import { TAssignee, TAssigneeUserType } from '../../../../../../../types';

export interface ITraining {
  eid: string;
  title: string;

  assigneeUserType: TAssigneeUserType;
  assignees: TAssignee[];
  hasScoring?: boolean;
}

export interface TrainingResponse {
  TpPagination: {
    items: ITraining[];
  };
}

export const FETCH_TRAINING = gql`
  query TpPagination(
    $perPage: Int
    $filter: FilterFindManyTPInput
    $sort: SortFindManyTPInput
  ) {
    TpPagination(perPage: $perPage, filter: $filter, sort: $sort) {
      items {
        eid
        title

        assigneeUserType
        assignees {
          type
          authRoles
          roles
          locationIds
          locationType
          userIds
        }
        hasScoring
      }
    }
  }
`;
