import React, { FC } from 'react';
import { Flex, Spacer, Tooltip, useModalContext } from '@chakra-ui/react';
import { useFormContext, useFormState } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import moment from 'moment';
import { ActionButton } from '../../../../../../ui-components';
import { CardType, TrainingContType } from '../../../../../../types';
import { useUserDataSelector } from '../../../../../../hooks';
import { videoContent } from '../../../../../CardEditor/jsonDataFormatter';
import {
  CREATE_VIDEO_RESOURCE,
  VideoFormInput,
  VideoResponse,
  VideoVariable,
} from './video-resource.graphql';

interface IFormInput {
  type: TrainingContType;
  eid: string;
  title: string;
  thumbnail?: string;
  addedBy?: string;
}

interface IProps {
  onAddClick?: (value: IFormInput) => void;
}

const VideoFooter: FC<IProps> = ({ onAddClick }) => {
  const { onClose } = useModalContext();
  const userData = useUserDataSelector((state) => ({
    entityId: state.entityId,
    authUserId: state.eid,
  }));
  const { handleSubmit, reset } = useFormContext<VideoFormInput>();
  const isDirty = useFormState().isDirty;

  const [createVideoCard] = useMutation<VideoResponse, VideoVariable>(
    CREATE_VIDEO_RESOURCE
  );

  const saveCardHandler = async (values: VideoFormInput) => {
    const content = videoContent({
      videoUrl: values?.videoUrl,
      caption: values?.caption,
      videoAddedBy: userData?.authUserId,
      videoDateAdded: moment().toISOString(),
      videoFileSize: values?.videoFileSize,
    });

    const input: VideoVariable['input'] = {
      cardType: CardType.Video,
      entityId: userData?.entityId,
      title: values.title,
      thumbnail: values.thumbnail,
      content: content,
    };

    const res = await createVideoCard({
      variables: {
        input: input,
      },
    });

    if (res.errors) {
      return Promise.reject(res.errors);
    }

    const data = res.data?.createCard?.[0];
    if (data) {
      onAddClick?.({
        type: TrainingContType.CARD,
        eid: data.eid,
        title: data.title,
        thumbnail: data.thumbnail,
        addedBy: userData.authUserId,
      });
    } else {
      return Promise.reject('Something went wrong');
    }
  };

  const onSaveAndAdd = async (values: VideoFormInput) => {
    await saveCardHandler(values);
    await new Promise((resolve) => setTimeout(resolve, 300));
    reset({
      title: '',
      caption: '',
      thumbnail: '',
      videoUrl: '',
      videoFileSize: 0,
    });
    await new Promise((resolve) => setTimeout(resolve, 300));
  };

  const onSaveAndExit = async (values: VideoFormInput) => {
    await saveCardHandler(values);
    await new Promise((resolve) => setTimeout(resolve, 100));
    onClose();
  };

  return (
    <Flex
      gap={5}
      paddingY='10px'
      paddingX={4}
      bgColor='#FCFCFC'
      borderTop='1px solid #EFEFEF'
      borderBottomRadius='8px'
    >
      <Spacer />

      <ActionButton
        variant='outline'
        fontSize='13px'
        isDisabled={!isDirty}
        actionFn={handleSubmit(onSaveAndAdd)}
      >
        Save and add another
      </ActionButton>

      <Tooltip
        hasArrow
        padding='6px 10px'
        placement='top-end'
        bgColor='#000000BD'
        label='Upload a video first'
        shouldWrapChildren={!isDirty}
        isDisabled={isDirty}
      >
        <ActionButton
          fontSize='13px'
          colorScheme='blue'
          isDisabled={!isDirty}
          actionFn={handleSubmit(onSaveAndExit)}
        >
          Save and exit
        </ActionButton>
      </Tooltip>
    </Flex>
  );
};

export default VideoFooter;
