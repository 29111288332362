import React, { FC, MouseEvent } from 'react';
import {
  Center,
  Editable,
  EditableInput,
  EditablePreview,
} from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripDotsVertical } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { AuditHelper } from '../../../../../../configs';
import { IFormInput } from '../../../form-types';
import { ReactComponent as DeleteIcon } from '../../../../../../assets/images/trash.svg';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';

interface IProps {
  questionIndex: number;
  fieldId: string;
  handleRemove: (index: number, event: MouseEvent) => void;
}

const AuditSection: FC<IProps> = ({ questionIndex, fieldId, handleRemove }) => {
  const { getValues } = useFormContext<IFormInput>();
  const {
    setNodeRef,
    transform,
    transition,
    attributes,
    listeners,
    setActivatorNodeRef,
    isDragging,
  } = useSortable({ id: fieldId, data: { index: questionIndex } });

  return (
    <Center
      gap={3}
      height='58px'
      bg='#F4F4F4'
      borderRadius='8px'
      px={6}
      _hover={{
        '.delete-section': {
          cursor: 'pointer',
          opacity: 1,
          visibility: 'visible',
        },
      }}
      ref={setNodeRef}
      transform={CSS.Translate.toString(transform)}
      transition={transition}
      border='1px solid transparent'
      data-grabbed={isDragging ? isDragging : undefined}
      _grabbed={{ zIndex: 2, bgColor: '#F4F4F4', borderColor: '#2A85FF' }}
    >
      <Center
        {...attributes}
        {...listeners}
        ref={setActivatorNodeRef}
        width={6}
        height={8}
        color='#6F767E'
        _hover={{ color: '#2A85FF' }}
        _disabled={{ opacity: '0.4', cursor: 'not-allowed' }}
      >
        <FontAwesomeIcon
          icon={faGripDotsVertical as IconProp}
          fontSize='20px'
        />
      </Center>

      <Controller<IFormInput, `questions.${number}.label`>
        name={`questions.${questionIndex}.label`}
        render={({ field }) => {
          return (
            <Editable
              display='flex'
              {...field}
              onBlur={AuditHelper.save(field.onBlur)}
            >
              <EditablePreview
                minH='27px'
                minW='50px'
                bg='white'
                px={2}
                fontWeight='600'
              />
              <EditableInput
                bg='white'
                paddingX={2}
                fontWeight='500'
                _focus={{
                  border: '2px solid #2A85FF59',
                }}
                // as={Input}
                // variant='auditOutline'
                // borderRadius='12px'
              />
            </Editable>
          );
        }}
      />

      <DeleteIcon
        className='delete-section'
        visibility='hidden'
        opacity='0'
        onClick={(event) => {
          deployEvent(AmplitudeEventNames.DELETE_SECTION_AUDIT, {
            event: {
              audit_id: getValues('eid'),
              audit_funnel_id: 12,
            },
          });
          handleRemove?.(questionIndex, event);
        }}
      />
    </Center>
  );
};

export default AuditSection;
