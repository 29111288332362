import i18n, { ThirdPartyModule } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import ChainedBackend from 'i18next-chained-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import { i18nextPlugin } from 'translation-check';
import LocalforageBackend from 'i18next-localforage-backend';
import moment from 'moment';

import 'intl-pluralrules';
import { localizationEnabled } from './utils/constants';

export const nullPlugin: ThirdPartyModule = {
  type: '3rdParty',

  init() {},
};

const ChainedBack = new ChainedBackend(null, {
  backends: [
    // LocalforageBackend,
    // HttpApi,
    resourcesToBackend((language: string, namespace: string) => {
      language = language !== 'en' ? 'ar' : language;
      return import(`./locales/${language}/${namespace}.ts`);
    }),
  ],
  backendOptions: [
    // {
    //   name: 'trans_cache',
    //   storeName: 'translation',
    //   expirationTime: 2 * 24 * 60 * 60 * 1000, // 2 days
    // },
    // {
    //   loadPath: `${process.env.REACT_APP_API_BASE_ENDPOINT}/locale/{{lng}}/{{ns}}`,
    // },
  ],
});

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(ChainedBack)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // @ts-ignore
  .use(localizationEnabled ? LanguageDetector : nullPlugin)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .use(nullPlugin)
  .use(process?.env?.NODE_ENV === 'development' ? i18nextPlugin : nullPlugin)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // lng: process?.env?.NODE_ENV === 'development' ? 'ar' : 'en',
    fallbackLng: 'en',
    defaultNS: 'common',
    // debug: true,

    ns: [
      'activity',
      'auth',
      'card',
      'category',
      'common',
      'deck',
      'demo',
      'handbook',
      'header',
      'home',
      'invite',
      'location',
      'report',
      'role',
      'setting',
      'sidebar',
      'training',
      'traininginfo',
      'validation',
    ],

    load: 'languageOnly',

    pluralSeparator: '__',

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    react: {
      useSuspense: false,
    },
  })
  .catch((err) => {
    // eslint-disable-next-line no-console
    console.warn('localization setup failed --> ', err?.message);
  });

i18n.on('languageChanged', (lng) => {
  try {
    if (lng !== 'en') {
      import(`moment/locale/${lng}`).catch((err) => {
        // eslint-disable-next-line no-console
        console.log('moment local language import error --> ', err?.message);
      });
    }
  } catch (e: any) {
    // eslint-disable-next-line no-console
    console.log('moment local language import error --> ', e?.message);
  }
  moment.locale(lng);
});

export default i18n;
