import { ChangeEvent, RefObject, useCallback, useRef } from 'react';
import { ErrorOption } from 'react-hook-form';
import { convertToWebp, fileToDtaUrl, getWebpFilename } from '../utils';
import useLatest from './useLatest';
import useSafeState from './useSafeState';
import { useUploadImage } from './useUploadImage';

interface UseOnImageChangeProps {
  onImageChange: (event: ChangeEvent<HTMLInputElement>) => void | Promise<void>;
  thumbnail?: string;
  isUploading: boolean;
  inputRef: RefObject<HTMLInputElement>;
}

interface IProps {
  onChange?: (value: string) => void;
  onError?: (error: ErrorOption) => void;
  /**
   * value must be in bytes
   */
  maxSize?: number;
}

export const useOnImageChange = (props?: IProps): UseOnImageChangeProps => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isUploading, updateIsUploading] = useSafeState(false);
  const [thumbnail, updateThumbnail] = useSafeState<string>();

  const imageUpload = useUploadImage();

  const maxSizeRef = useLatest(props?.maxSize);
  const onErrorRef = useLatest(props?.onError);
  const onChangeRef = useLatest(props?.onChange);

  const onImageChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();

      try {
        updateIsUploading(true);

        const file = event.target.files?.item(0);

        if (!file) return;

        if (maxSizeRef?.current) {
          if (file.size > maxSizeRef?.current) {
            return onErrorRef.current?.({
              type: 'validate',
              message: `This uploaded file exceeds maximum file size limit (${
                maxSizeRef?.current / 1000000
              }mb).`,
            });
          }
        }

        const dataUrl = await fileToDtaUrl(file);
        updateThumbnail(dataUrl);

        const blobData = await convertToWebp(dataUrl);

        const imageUrl = await imageUpload(
          blobData,
          getWebpFilename(file?.name),
          undefined
        );

        onChangeRef.current?.(imageUrl);

        if (inputRef.current) {
          inputRef.current!.files = null;
          inputRef.current!.value = '';
        }
      } catch (e) {
        updateIsUploading(false);
      } finally {
        updateIsUploading(false);
        updateThumbnail(undefined);
      }
    },
    []
  );

  return {
    onImageChange,
    thumbnail,
    isUploading,
    inputRef,
  };
};
