import React, { useCallback, useRef } from 'react';
import { AlertDialogCloseButton, Flex } from '@chakra-ui/react';
import {
  BoxHeader,
  UseConfirm,
  useConfirm,
} from '../../../../../ui-components';
import { HeaderColors } from '../../../../../configs';
import AccessRuleContent, { ARuleProps } from './AccessRuleContent';

interface IProps extends ARuleProps {
  title?: string;
}

type TrainingAccessRule = (props: IProps) => void;

export const useTrainingAccessRule = (): TrainingAccessRule => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();

  const confirm = useConfirm();
  return useCallback(({ title, ...props }) => {
    if (confirmRef.current) {
      confirmRef.current?.destroy();
    }

    confirmRef.current = confirm({
      title: (
        <Flex gap='12px' justify='space-between'>
          <BoxHeader
            title={title || 'Set access rules'}
            color={HeaderColors.Purple}
          />

          <AlertDialogCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
          />
        </Flex>
      ),

      content: <AccessRuleContent {...props} />,

      isCentered: true,
      headerProps: { paddingX: 7, paddingTop: 5 },
      contentProps: {
        borderRadius: '8px',
        maxWidth: '751px',

        marginTop: 'auto',
        marginBottom: 'auto',

        containerProps: {
          // alignItems: 'start',
          paddingTop: '3rem',
          paddingBottom: '3rem',
        },
      },
      bodyProps: {
        paddingX: 0,
        paddingBottom: 0,
      },
      footer: null,
    });
  }, []);
};
