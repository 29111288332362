import { useCallback } from 'react';
import CrossContent from './crossContent';
import { useTrainModal } from './useTrainModal';

export const useCrossTrain = () => {
  const crossTrainModal = useTrainModal();
  return useCallback(
    (crossTrainModalData, trainingId, sessionId) => {
      crossTrainModal({
        title: 'Members',
        subtext:
          'Only members who were not originally assigned to the TP are shown in this list.',
        content: (
          <CrossContent
            crossTrainModalData={crossTrainModalData}
            trainingId={trainingId}
            sessionId={sessionId}
          />
        ),
      });
    },
    [crossTrainModal]
  );
};
