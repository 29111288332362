import React, { FC, useEffect, useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useFormContext } from 'react-hook-form';
import { useDebounce } from 'use-debounce';
import { SelectOption } from '../../../../../../atoms';
import { useUserEntity } from '../../../../../../hooks';
import { toArray } from '../../../../../../utils';
import { BaseDataNotFound, BaseResourceContainer } from '../common';
import SopItem from './SopItem';
import { SOP_RESOURCE_LIST, SopResponse } from './sop-resource.graphql';
import SubCategoryList from './SubCategoryList';

interface IProps {
  values: string[];
}

const SopResourceContainer: FC<IProps> = ({ values }) => {
  const { setValue, getValues } = useFormContext();

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [category, setCategory] = useState<SelectOption>();
  const [subCategory, setSubCategory] = useState<SelectOption>();

  const categories = useUserEntity((ent) => ent?.category);

  const [fetchData, { data, loading }] = useLazyQuery<SopResponse>(
    SOP_RESOURCE_LIST,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        if (!getValues('preview') && !searchQuery) {
          const firstSopId = toArray(data.SopPagination?.items).find(
            (item) => !values?.includes(item.eid)
          )?.eid;
          if (firstSopId) {
            setValue('preview', firstSopId);
          }
        }
      },
    }
  );

  const [_searchQuery] = useDebounce(searchQuery, 300);

  const _category = subCategory || category;
  const _categoryValue = subCategory?.value || category?.value;

  useEffect(() => {
    setValue('preview', undefined);
    fetchData({
      variables: {
        perPage: 40 + values.length,
        filter: {
          status: 'active',
          query: _searchQuery,
          categoryId: _categoryValue ? _categoryValue : undefined,
        },
        sort: '_ID_DESC',
      },
    });
  }, [_searchQuery, values.length, _categoryValue]);

  const options = useMemo(() => {
    const _options = toArray(categories).map((op) => ({
      label: op.name,
      value: op.eid,
    }));

    _options.unshift({ label: 'All chapters', value: '' });

    return _options;
  }, [categories]);

  const chapterList = useMemo(() => {
    return toArray(data?.SopPagination?.items).filter((value) => {
      return !values.includes(value.eid);
    });
  }, [data, values]);

  return (
    <BaseResourceContainer
      searchPlaceholder='Browse for chapter, folder or subfolder'
      searchQuery={searchQuery}
      onSearchChange={setSearchQuery}
      // Filter props
      filterOptions={options}
      filterValue={category}
      onFilterChange={(val) => {
        setCategory(val);
        setSubCategory(undefined);
      }}
    >
      <SubCategoryList
        category={category?.value}
        filterValue={subCategory}
        onFilterChange={(val) => setSubCategory(val)}
      />
      <BaseDataNotFound
        type='sop'
        isLoading={loading}
        category={_category?.value ? _category?.label : undefined}
        searchQuery={_searchQuery}
        dataLength={chapterList?.length}
        onBackClick={() => {
          setSearchQuery('');
          setCategory({ label: '', value: '' });
          setSubCategory(undefined);
        }}
      >
        {chapterList.map((item) => (
          <SopItem
            data={item}
            key={item.eid}
            onClick={() => setValue('preview', item.eid)}
          />
        ))}
      </BaseDataNotFound>
    </BaseResourceContainer>
  );
};

export default SopResourceContainer;
