import React, { FC } from 'react';
import { Box, Checkbox, Divider, Flex } from '@chakra-ui/react';
import { RolesEntity } from 'sop-commons/src/client';

interface IProps {
  item: RolesEntity;
}

const JobOption: FC<IProps> = ({ item }) => {
  return (
    <React.Fragment key={item.eid}>
      <Flex
        gap={3}
        height='48px'
        minHeight='48px'
        alignItems='center'
        paddingInline='12px'
        borderRadius='12px'
        // cursor='pointer'
        _hover={{ bg: '#F4F4F3' }}
      >
        <Box
          flex={1}
          fontSize='15px'
          fontWeight='600'
          isTruncated
          color='#33383F'
        >
          {item.name}
        </Box>
        <Checkbox value={item.name} size='lg' />
      </Flex>
      <Divider />
    </React.Fragment>
  );
};

export default JobOption;
