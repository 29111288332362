import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import Container from './Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripDotsVertical } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { usePlayContext } from 'sub-components/training-v2/play/store/context';
import {
  getQuestionResponse,
  isQuestionCompleted,
} from 'sub-components/training-v2/play/utils';
import { SequenceItem } from 'sub-components/training-v2/shared/types';
import {
  faCircleCheck,
  faCircleXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { Button } from 'atoms';
import { useService } from 'sub-components/training-v2/play/layers';
import {
  cleanResponse,
  isLastUnansweredQuestion,
} from 'sub-components/training-v2/play/utils/quizHelpers';
import { Image } from 'ui-components';
import QuizQuestionTitle from './QuizQuestionTitle';
import { calculateTotalScore } from '../../../utils';

function shuffleArray<T>(array: T[]): T[] {
  const arr = array?.slice();
  for (let i = arr?.length - 1; i > 0; i--) {
    const j = Math?.floor(Math?.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }
  return arr;
}

function removeTypename<T>(item: T): T {
  const { __typename, ...rest } = item as any;
  return rest;
}

const QuizReorder: FC = () => {
  const {
    contents,
    isPreview,
    isRetake,
    selectedIndex,
    selectedSubIndex,
    tpSessionData,
    increment,
    updateTpSessionData,
    setQuizSubmissionResult,
  } = usePlayContext((state) => ({
    isPreview: state?.isPreview,
    isRetake: state?.isRetake,
    selectedIndex: state?.selectedIndex,
    selectedSubIndex: state?.selectedSubIndex,
    contents: state?.tpSessionData?.contents || [],
    tpSessionData: state?.tpSessionData,
    increment: state?.increment,
    updateTpSessionData: state?.updateTpSessionData,
    setQuizSubmissionResult: state?.setQuizSubmissionResult,
  }));

  const service = useService();
  const [sequence, setSequence] = useState<SequenceItem[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dummySubmit, setDummySubmit] = useState(false);
  const rightRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [rightPositions, setRightPositions] = useState<
    { top: number; height: number }[]
  >([]);

  const selectedData = useMemo(() => {
    return contents?.[selectedIndex]?.questions?.[selectedSubIndex];
  }, [contents, selectedIndex, selectedSubIndex]);

  const isCompleted = useMemo(() => {
    return isRetake && !dummySubmit
      ? false
      : isQuestionCompleted(
          selectedData?.eid,
          tpSessionData?.userProgress || []
        );
  }, [selectedData, tpSessionData, isRetake, dummySubmit]);

  const userResponse = useMemo(() => {
    return getQuestionResponse(
      selectedData?.eid,
      tpSessionData?.userProgress || []
    );
  }, [selectedData, tpSessionData]);

  useEffect(() => {
    const updatePositions = () => {
      if (!rightRefs.current) return;

      const positions = rightRefs.current.map((ref) => {
        if (!ref) return { top: 0, height: 0 };

        const rect = ref.getBoundingClientRect();
        const containerRect = ref.offsetParent?.getBoundingClientRect() || {
          top: 0,
        };

        return {
          top: rect.top - containerRect.top,
          height: rect.height,
        };
      });

      setRightPositions(positions);
    };

    updatePositions();

    window.addEventListener('resize', updatePositions);
    return () => {
      window.removeEventListener('resize', updatePositions);
    };
  }, [sequence, selectedData, isSubmitting, dummySubmit]);

  useEffect(() => {
    if (isCompleted && userResponse?.sequence) {
      setSequence(userResponse?.sequence);
    } else {
      const originalSeq: SequenceItem[] = (selectedData?.sequence || []).map(
        removeTypename
      );
      setSequence(shuffleArray(originalSeq));
    }
  }, [selectedData?.eid, isCompleted, userResponse]);

  const handleDragEnd = (result: DropResult) => {
    if (!result?.destination) return;
    const newItems = Array.from(sequence);
    const [reorderedItem] = newItems?.splice(result?.source?.index, 1);
    newItems.splice(result?.destination?.index, 0, reorderedItem);
    setSequence(newItems);
  };

  const calculateScore = () => {
    if (!selectedData?.sequence)
      return { score: 0, isPartiallyCorrect: false, isCorrect: false };

    let correctCount = 0;
    const totalItems = selectedData?.sequence?.length;

    sequence?.forEach((item, index) => {
      if (item?.uid === (selectedData?.sequence || [])?.[index]?.uid) {
        correctCount++;
      }
    });

    const scorePerItem = selectedData?.score / totalItems;
    const score = correctCount * scorePerItem;
    const isFullyCorrect = correctCount === totalItems;
    const isPartiallyCorrect = score > 0 && !isFullyCorrect;

    return {
      score,
      isPartiallyCorrect,
      isCorrect: isFullyCorrect,
    };
  };

  const checkSequence = (
    item: SequenceItem,
    index: number
  ): {
    status: 'correct' | 'incorrect' | undefined;
    // correctOption: SequenceItem | undefined;
    correctOption: number | undefined;
  } => {
    if (!selectedData || !selectedData?.sequence || !isCompleted)
      return {
        correctOption: undefined,
        status: undefined,
      };
    const correctOption = selectedData?.sequence?.[index];
    if (item?.uid === correctOption?.uid) {
      return {
        status: 'correct',
        correctOption: undefined,
      };
    } else {
      const correctIndex = selectedData?.sequence?.findIndex(
        (seq) => seq.uid === item.uid
      );
      return {
        status: 'incorrect',
        correctOption: correctIndex + 1,
      };
    }
  };

  const handleSubmit = async () => {
    if (
      !selectedData ||
      !selectedData?.eid ||
      !tpSessionData?.eid ||
      !tpSessionData?.trainingId ||
      isSubmitting
    )
      return;

    const { score, isPartiallyCorrect, isCorrect } = calculateScore();
    if (isRetake) {
      // if (tpSessionData?.userProgress?.[0]) {
      const currentDate = new Date();
      const existingProgress = [
        ...(tpSessionData?.userProgress?.[0]?.progress || []),
      ];
      const currentContentIndex = existingProgress?.findIndex(
        (p) => p?.id === contents?.[selectedIndex]?.eid
      );

      // Find existing responses that aren't for this question
      const otherResponses =
        currentContentIndex >= 0
          ? existingProgress?.[currentContentIndex]?.responses?.filter(
              (r) => r?.qid !== selectedData?.eid
            )
          : [];

      const updatedProgressItem = {
        type: contents?.[selectedIndex]?.type,
        id: contents?.[selectedIndex]?.eid,
        score: score,
        isCompleted: isPreview ? false : true,
        responses: [
          ...otherResponses,
          {
            qid: selectedData?.eid,
            score: score,
            isCorrect: isCorrect,
            options: [],
            pairs: [],
            sequence: sequence, // Keep user's sequence
            blanks: [],
            isCompleted: true,
            createdAt: currentDate,
            updatedAt: currentDate,
          },
        ],
        updatedAt: currentDate,
      };

      if (currentContentIndex >= 0) {
        existingProgress[currentContentIndex] = updatedProgressItem;
      } else {
        existingProgress?.push(updatedProgressItem);
      }

      const updatedUserProgress = {
        ...tpSessionData?.userProgress?.[0],
        progress: existingProgress,
        isCompleted: isPreview ? false : true,
      };

      // First set dummySubmit to true to prevent useEffect from resetting sequence
      setDummySubmit(true);
      // Then update tpSessionData with user's sequence preserved
      updateTpSessionData({
        ...tpSessionData,
        userProgress: [updatedUserProgress],
      });
      // }

      setQuizSubmissionResult({
        showResult: true,
        isCorrect,
        isPartiallyCorrect,
        score,
      });
      return;
    }
    const isLastQuizQuestion = isLastUnansweredQuestion(
      contents?.[selectedIndex]?.questions,
      tpSessionData?.userProgress
    );

    const currentProgress = tpSessionData?.userProgress?.[0]?.progress.find(
      (p) => p?.id === contents?.[selectedIndex]?.eid
    );

    const existingResponses = (currentProgress?.responses || [])?.map(
      cleanResponse
    );

    const mergedResponses = [
      ...existingResponses?.filter((r: any) => r?.qid !== selectedData?.eid),
      cleanResponse({
        qid: selectedData?.eid,
        sequence: sequence,
        score,
      }),
    ];

    const totalScore =
      calculateTotalScore(contents[selectedIndex].eid, [
        ...(tpSessionData?.userProgress?.[0]?.progress || []),
      ]) + score;

    setIsSubmitting(true);

    try {
      const result = await service?.updateTPProgress?.updateTPProgress({
        variables: {
          input: {
            isCompleted: isLastQuizQuestion,
            itemId: contents?.[selectedIndex]?.eid,
            sessionId: tpSessionData?.eid,
            trainingId: tpSessionData?.trainingId,
            responses: mergedResponses,
            score: totalScore,
          },
        },
      });

      if (result?.data?.AddTpProgress) {
        updateTpSessionData({
          ...tpSessionData,
          userProgress: [result?.data?.AddTpProgress],
        });
        setQuizSubmissionResult({
          showResult: true,
          isCorrect: isCorrect,
          isPartiallyCorrect,
          score,
        });
      }
    } catch (error) {
      console.error('Error submitting sequence quiz:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container>
      <QuizQuestionTitle />

      <Flex position='relative'>
        <Box position='absolute' left='0' top='0' width='56px' zIndex='1'>
          {sequence.map((_, index) => (
            <Flex
              key={`indicator-${index}`}
              width='56px'
              height='64px'
              position='absolute'
              top={`${rightPositions[index]?.top || 0}px`}
              borderTopLeftRadius='0px'
              borderBottomLeftRadius='0px'
              borderTopRightRadius='12px'
              borderBottomRightRadius='12px'
              borderLeft={`2px solid ${
                isCompleted
                  ? checkSequence(sequence?.[index], index)?.status ===
                    'correct'
                    ? 'rgba(131, 191, 110, 1)'
                    : 'rgba(255, 106, 85, 1)'
                  : 'rgba(42, 133, 255, 1)'
              }`}
              bg='white'
              align='center'
              justify='center'
            >
              {index + 1}
            </Flex>
          ))}
        </Box>

        <Box ml='70px' width='calc(100% - 70px)'>
          {/* @ts-ignore */}
          <DragDropContext onDragEnd={handleDragEnd}>
            {/* @ts-ignore */}
            <Droppable droppableId='droppable' isDropDisabled={isCompleted}>
              {(droppableProvided) => (
                <Box
                  {...droppableProvided?.droppableProps}
                  ref={droppableProvided?.innerRef}
                  position='relative'
                >
                  {sequence?.map((item, index) => (
                    // @ts-ignore
                    <Draggable
                      key={item?.uid}
                      draggableId={item?.uid}
                      index={index}
                      isDragDisabled={isCompleted}
                    >
                      {(draggableProvided) => (
                        <Flex
                          ref={(el) => {
                            rightRefs.current[index] = el;
                            draggableProvided?.innerRef(el);
                          }}
                          {...draggableProvided?.draggableProps}
                          {...draggableProvided?.dragHandleProps}
                          p='16px'
                          minH='64px'
                          mb={2}
                          borderRadius='12px'
                          alignItems='center'
                          w='full'
                          _hover={
                            !isCompleted
                              ? {
                                  bg: 'rgba(177, 229, 252, 0.3)',
                                  border: '1px solid rgba(42, 133, 255, 1)',
                                }
                              : {}
                          }
                          bg={
                            isCompleted
                              ? checkSequence(item, index)?.status === 'correct'
                                ? 'rgba(181, 228, 202, 0.3)'
                                : 'rgba(255, 106, 85, 0.2)'
                              : 'white'
                          }
                          border={
                            isCompleted
                              ? checkSequence(item, index)?.status === 'correct'
                                ? '1px solid rgba(131, 191, 110, 1)'
                                : '1px solid rgba(255, 106, 85, 1)'
                              : '1px solid #E2E8F0'
                          }
                        >
                          <Flex flexDir='column' w='full'>
                            <Flex
                              align='center'
                              justify='space-between'
                              w='full'
                            >
                              <Flex align='center' gap={4}>
                                {item?.thumbnail && (
                                  <Image
                                    src={item?.thumbnail}
                                    height={40}
                                    width={40}
                                    style={{
                                      borderRadius: '8px',
                                      objectFit: 'contain',
                                      border: '1px solid #E2E8F0',
                                      backgroundColor: 'white',
                                    }}
                                  />
                                )}
                                <Text
                                  fontWeight={!isCompleted ? 600 : 500}
                                  fontSize='15px'
                                >
                                  {item?.text}
                                </Text>
                              </Flex>
                              {!isCompleted ? (
                                <FontAwesomeIcon
                                  icon={faGripDotsVertical as IconProp}
                                  color='#6F767E'
                                />
                              ) : checkSequence(item, index)?.status ===
                                'correct' ? (
                                <FontAwesomeIcon
                                  icon={faCircleCheck as IconProp}
                                  fontSize='20px'
                                  color='rgba(131, 191, 110, 1)'
                                />
                              ) : (
                                <FontAwesomeIcon
                                  icon={faCircleXmark as IconProp}
                                  fontSize='20px'
                                  color='rgba(255, 106, 85, 1)'
                                />
                              )}
                            </Flex>
                            {isCompleted &&
                              checkSequence(item, index)?.status ===
                                'incorrect' && (
                                <Flex
                                  fontSize='14px'
                                  mt={2}
                                  justify='flex-start'
                                  flexDir='column'
                                  gap={1}
                                >
                                  <Box
                                    bg='rgba(255, 105, 85, 0.20)'
                                    w='full'
                                    h='1px'
                                  />
                                  <Box>
                                    <Box as='span'>Correct order:&nbsp;</Box>
                                    <Box
                                      as='span'
                                      fontWeight={600}
                                      fontSize='15px'
                                    >
                                      {' '}
                                      {checkSequence(item, index)
                                        ?.correctOption || ''}
                                    </Box>
                                  </Box>
                                </Flex>
                              )}
                          </Flex>
                        </Flex>
                      )}
                    </Draggable>
                  ))}
                  {droppableProvided?.placeholder}
                </Box>
              )}
            </Droppable>
          </DragDropContext>
        </Box>
      </Flex>

      <Button
        size='lg'
        colorScheme='blue'
        onClick={() => (isCompleted ? increment() : handleSubmit())}
        isLoading={isSubmitting}
        isDisabled={isSubmitting}
      >
        {isCompleted ? 'Next' : 'Submit'}
      </Button>
    </Container>
  );
};

export default QuizReorder;
