import React, { FC } from 'react';
import { Box, Center } from '@chakra-ui/react';
import { IconImage } from '../../../../../../ui-components';

import { ReactComponent as MilestoneIcon } from 'assets/images/training/milestone.svg';

interface IProps {
  title?: string;
  thumbnail?: string;
}

const MilestonePreviewContent: FC<IProps> = ({ title, thumbnail }) => {
  return (
    <Box flex={1} overflow='hidden'>
      <Center
        flexDir='column'
        gap='32px'
        height='full'
        borderRadius='16px'
        paddingX={2}
      >
        <Box
          fontSize='15px'
          fontWeight='500'
          color='#111315'
          textAlign='center'
          wordBreak='break-word'
          paddingX={1}
        >
          {title?.trim() || `Hurray you’ve reached the milestone!`}
        </Box>

        {thumbnail ? (
          <IconImage
            name=''
            thumbnail={thumbnail}
            boxSize={165}
            borderRadius='0px'
          />
        ) : (
          <MilestoneIcon />
        )}
      </Center>
    </Box>
  );
};

export default MilestonePreviewContent;
