import { InfoIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Divider,
  Flex,
  FormErrorMessage,
  Stack,
  Text,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import React, { FC, useEffect, useRef, useState } from 'react';
import { BoxHeader } from 'ui-components';
import { SkillAttachments, Verifier } from './index';

import { useMutation } from '@apollo/client';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCheck,
  faCirclePlus,
  faClose,
  faPencil,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormInput } from 'atoms';
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ISkillVerifierFormInput } from 'sub-components/training-v2/shared/types';
import { TSkillVerifierItem } from '../../../useEvaluateResponses';
import ResponseFooter from '../../ResponseFooter';
import { EvaluationSummary, ScoreContainer } from './index';
import {
  AddTpSkillVerifierResponse,
  AddTpSkillVerifierResponseItem,
  AddTpSkillVerifierResponseVariables,
  ATTACH_TP_SKILL_VERIFIER_RESPONSE,
  TP_EVALUATE,
  TpEvaluateVariables,
} from './query/evaluate.graphql';
import { eventBus } from 'shared/eventEmit';
import { ReactComponent as SkillInfoIcon } from 'assets/images/training/skillInfoIcon.svg';
import { IReAssignInfo } from 'sub-components/training-v2/dashboard/components/TrackPathDashboard/components/PathActions/ReAssignTpForUser';

type Props = {};

const SkillVerifier: FC<{
  skill: TSkillVerifierItem;
  trainingId: string;
  userId: string;
  sessionId: string;
  maxScore: number;
  score: number;
  userName: string;
  tpScheduleInfo: IReAssignInfo;
  hasSkillVerifier: boolean;
  onEvaluateComplete?: () => void;
  tp: any;
}> = ({
  skill,
  trainingId,
  userId,
  sessionId,
  maxScore,
  score,
  userName,
  tpScheduleInfo,
  hasSkillVerifier,
  onEvaluateComplete,
  tp,
}) => {
  const [tpSkillResponse, setTpSkillResponse] =
    useState<AddTpSkillVerifierResponseItem>();
  const [isRatingSubmitted, setIsRatingSubmitted] = useState<boolean>(false);
  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  });
  const [
    AddTpSkillVerifierResponse,
    { loading: isAddTpSkillVerifierResponseLoading },
  ] = useMutation<
    AddTpSkillVerifierResponse,
    AddTpSkillVerifierResponseVariables
  >(ATTACH_TP_SKILL_VERIFIER_RESPONSE, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setTpSkillResponse(data?.AddTpSkillVerifierResponse);
    },
  });
  const [TpEvaluate, { loading: isTpEvaluateLoading }] = useMutation<
    unknown,
    TpEvaluateVariables
  >(TP_EVALUATE, {
    fetchPolicy: 'network-only',
    onCompleted: async (data) => {
      toast({
        title: 'Training path evaluated successfully!',
        status: 'success',
      });
    },
    onError: () => {
      toast({
        title: 'Training path cannot be evaluated',
        status: 'error',
      });
    },
  });
  const addAnotherRef = useRef<HTMLDivElement>(null);
  const [addAnotherSkillVisible, setaddAnotherSkillVisible] = useState<
    Record<number, boolean>
  >({
    0: true,
  });
  const [isEditing, setIsEditing] = useState<Record<number, boolean>>({
    0: true, //always true
    1: false,
  });
  const methods = useForm<ISkillVerifierFormInput>({
    defaultValues: {
      skills: [
        {
          name: skill.name,
          rating: skill.rating,
          comment: '',
          files: [],
        },
      ],
    },
    mode: 'onBlur',
  });
  const {
    control,
    handleSubmit,
    watch,
    formState: { dirtyFields },
    setValue,
    getValues,
  } = methods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'skills',
  });
  const onSubmit = async (
    data: ISkillVerifierFormInput,
    e?: React.BaseSyntheticEvent
  ) => {
    e?.preventDefault();
    try {
      await AddTpSkillVerifierResponse({
        variables: {
          input: {
            sessionId,
            trainingId,
            userId,
            skillVerifier: data.skills.map((skill) => {
              return {
                ...skill,
                files:
                  skill?.files && skill?.files?.length > 0
                    ? skill.files?.map((file) => file.url)
                    : [],
              };
            }),
          },
        },
      });
      await TpEvaluate({
        variables: {
          sessionId,
          userId,
        },
      });

      onEvaluateComplete?.();
    } catch (error) {
      console.log(error, 'error while adding skill verifier response');
    }
  };
  const { t } = useTranslation(['training']);
  const skills = useWatch<ISkillVerifierFormInput>({
    control,
    name: 'skills',
  });
  const addSkillInput = (skillIndex: number, name: string) => {
    setValue(`skills.${skillIndex}.name`, name, { shouldDirty: true });
    setIsEditing((prev) => ({
      ...prev,
      [skillIndex]: false, //set inputVisiblity for current skill once skill has been added to form
    }));
  };
  const addAnotherSkillHandler = (skillIndex: number) => {
    append({ name: '', rating: 0, files: [] });
    setIsEditing((prev) => ({
      ...prev,
      [skillIndex + 1]: true, //since 0th field will be default, control is out of the idx>0 check
    }));
    setaddAnotherSkillVisible((prev) => {
      const nextIndex = Object.keys(prev).length;
      return {
        ...prev,
        [skillIndex]: false, //hide current button
        [nextIndex]: true, //show for new skill
      };
    });
  };
  const removeHandler = (skillIndex: number) => {
    // setValue(`skills.${skillIndex}.files`, []); //remove
    remove(skillIndex); //remove skill from fields array
    setaddAnotherSkillVisible((prev) => {
      const updatedVisibility = { ...prev };
      delete updatedVisibility[skillIndex]; //remove the btnvisiblity for the current skill
      updatedVisibility[skillIndex - 1] = true; //set true for the previous skill
      return updatedVisibility;
    });

    setIsEditing((prev) => {
      const updatedVisibility = { ...prev };
      delete updatedVisibility[skillIndex]; //remove the inputVisiiblity for the current skill
      return updatedVisibility;
    });
  };
  useEffect(() => {
    eventBus.on('Rating_submitted', () => {
      setIsRatingSubmitted(true);
    });
    // eventBus.on('rating_for_current_skill', () => { });

    return () => {
      eventBus.off('Rating_submitted', () => {
        setIsRatingSubmitted(false);
      });
    };
  }, []);

  return (
    <FormProvider {...methods}>
      {isRatingSubmitted ? (
        <EvaluationSummary
          quizScore={Math.round(score)}
          skillVerifierScore={getValues().skills.reduce(
            (acc, field) => acc + (field?.rating ?? 0),
            0
          )}
          maxScore={maxScore}
          onReEvaluate={() => {
            //compelete the evaluation
            setIsRatingSubmitted(false);
          }}
          onSubmitHandler={handleSubmit(onSubmit)}
          isLoading={isAddTpSkillVerifierResponseLoading || isTpEvaluateLoading}
          tpScheduleInfo={tpScheduleInfo}
          sessionId={sessionId}
          userId={userId}
          hasSkillVerifier={hasSkillVerifier}
          tp={tp}
          onEvaluateComplete={onEvaluateComplete}
        />
      ) : (
        <>
          <ScoreContainer
            score={Math.round(score)}
            totalQuestions={maxScore}
            sessionId={sessionId}
            userName={userName}
            userId={userId}
          />
          <Flex
            p={'20px 24px'}
            bg='#FCFCFC'
            borderRadius='lg'
            boxShadow='sm'
            border='1px'
            borderColor='gray.100'
            justifyContent={'center'}
            alignItems={'space-between'}
            flexDirection={'column'}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack gap={6}>
                <Flex justify={'space-between'} alignItems={'center'}>
                  <Text fontWeight={600} fontSize={'18px'} lineHeight={'32px'}>
                    {skill.skill}
                  </Text>
                  <Tooltip
                    offset={[-120, 10]}
                    fontWeight={600}
                    borderRadius={'4px'}
                    p={'2px 8px'}
                    label={
                      'This is a skill verifier. The score you assign will contribute to the assignee’s overall training path score.'
                    }
                  >
                    <SkillInfoIcon />
                  </Tooltip>
                </Flex>

                {fields.map((field, index) => {
                  return (
                    <Stack gap={4} key={field.id}>
                      {index > 0 ? (
                        <Flex>
                          <Controller
                            name={`skills.${index}.name` as const}
                            control={control}
                            render={({ field, fieldState: { error } }) => {
                              //
                              return (
                                <Flex
                                  w={'100%'}
                                  justify={'space-between'}
                                  align={'center'}
                                >
                                  {isEditing[index] ? (
                                    <Flex w={'92%'}>
                                      <Box
                                        mr={4}
                                        w={4}
                                        borderRadius={4}
                                        bg={'#CABDFF'}
                                        style={{ aspectRatio: '1/2' }}
                                      />
                                      <FormInput
                                        {...field}
                                        ref={addAnotherRef}
                                        h={'40px'}
                                        gap={10}
                                        onChange={field.onChange}
                                        rightIcon={
                                          <FontAwesomeIcon
                                            icon={faCheck as IconProp}
                                            size={'2x'}
                                            height={'14px'}
                                            cursor={'pointer'}
                                            color={'#6F767E'}
                                            onClick={() => {
                                              addSkillInput(index, field.value);
                                            }}
                                          />
                                        }
                                        rightElementProps={{
                                          marginRight: '12px',
                                        }}
                                        color={'#6F767E'}
                                        placeholder={t(
                                          'training:skillNameInputPlaceholder'
                                        )}
                                      />
                                      {error && (
                                        <FormErrorMessage color='red.500'>
                                          {error.message}
                                        </FormErrorMessage>
                                      )}
                                    </Flex>
                                  ) : (
                                    <>
                                      <BoxHeader
                                        title={field?.value ?? '-'}
                                        color={'#CABDFF'}
                                        fontSize={'15px'}
                                      />
                                      <Flex
                                        gap={4}
                                        align={'center'}
                                        cursor={'pointer'}
                                      >
                                        <FontAwesomeIcon
                                          icon={faPencil as IconProp}
                                          size={'lg'}
                                          color={'#6F767E'}
                                          onClick={() =>
                                            setIsEditing((prev) => ({
                                              ...prev,
                                              [index]: true,
                                            }))
                                          }
                                        />
                                        <FontAwesomeIcon
                                          icon={faClose as IconProp}
                                          size={'lg'}
                                          color={'#6F767E'}
                                          onClick={() => {
                                            removeHandler(index);
                                          }}
                                        />
                                      </Flex>
                                    </>
                                  )}
                                </Flex>
                              );
                            }}
                          />
                        </Flex>
                      ) : (
                        <BoxHeader
                          title={field?.name ?? '-'}
                          color={'#CABDFF'}
                          fontSize={'15px'}
                        />
                      )}
                      <Flex flexDirection={'column'} p={2}>
                        <Verifier skillIndex={index} />
                        <Flex justify={'flex-end'} mt={4}>
                          <Flex gap={2} flexDir={'column'} w={'100%'}>
                            <SkillAttachments
                              skillIndex={index}
                              key={field.id}
                            />
                          </Flex>
                        </Flex>
                      </Flex>
                      <Divider mt={6} color={'#EFEFEF'} size={'sm'} />

                      {addAnotherSkillVisible[index] && (
                        <Flex mt={6}>
                          <Button
                            onClick={() => addAnotherSkillHandler(index)}
                            rightIcon={
                              <FontAwesomeIcon
                                icon={faCirclePlus as IconProp}
                                size={'lg'}
                              />
                            }
                            gap={2}
                            bg={'#FFFFFF'}
                            borderRadius={'8px'}
                            border={'2px solid #EFEFEF'}
                            fontWeight={700}
                            fontSize={'14px'}
                            color={'#1A1D1F'}
                          >
                            {t('training:addAnotherSkill')}
                          </Button>
                        </Flex>
                      )}
                    </Stack>
                  );
                })}
              </Stack>
            </form>
          </Flex>
          <ResponseFooter usedAt={'submission'} />
        </>
      )}
    </FormProvider>
  );
};

export default SkillVerifier;
