import { Box, Center, Flex, Image } from '@chakra-ui/react';
import React, { FC, useMemo, useState } from 'react';
import { usePlayContext } from 'sub-components/training-v2/play/store/context';
import EvaluatePending from 'assets/images/evaluate-pending.svg';
import { Button } from 'atoms';
import EvaluateDetailScreen from './EvaluateDetailScreen';
import { useSendReminder } from './useSendReminder';
import { CheckIcon } from '@chakra-ui/icons';
import { eventBus } from 'shared/eventEmit';

const SkillVerifier: FC = () => {
  const { tpSessionData } = usePlayContext((state) => ({
    tpSessionData: state.tpSessionData,
  }));

  const [reminderSent, setReminderSent] = useState(false);
  const sendReminder = useSendReminder();

  const updateSendRemindStyle = () => {
    setReminderSent(true);
  };

  const handleViewResponses = () => {
    eventBus.emit('OPEN_PROGRESS_DRAWER');
  };

  const supervisorsList = useMemo(() => {
    const supervisors = tpSessionData?.supervisors || [];
    const createdBy = tpSessionData?.createdBy;

    if (!createdBy) return supervisors;

    return supervisors.includes(createdBy)
      ? supervisors
      : [...supervisors, createdBy];
  }, [tpSessionData?.supervisors, tpSessionData?.createdBy]);

  if (!tpSessionData?.userProgress?.[0]?.isEvaluated) {
    return (
      <Center
        flexDir='column'
        gap={4}
        h='full'
        justifyContent='space-between'
        w='60%'
        p='50px 0px 24px 0px'
      >
        <Box fontWeight={600} fontSize='32px' textAlign='center'>
          Your training has been submitted for evaluation.
        </Box>
        <Image src={EvaluatePending} />
        <Box
          textAlign='center'
          fontSize='15px'
          fontWeight={500}
          color='rgba(111, 118, 126, 1)'
        >
          Haven’t received feedback yet? You can send a reminder to your
          supervisor.
        </Box>
        <Flex w='full' align='center' gap={4}>
          <Button
            variant='outline'
            size='lg'
            w='50%'
            onClick={handleViewResponses}
          >
            View responses
          </Button>
          <Button
            variant='solid'
            colorScheme={reminderSent ? 'green' : 'blue'}
            w='50%'
            size='lg'
            isDisabled={reminderSent}
            rightIcon={reminderSent ? <CheckIcon /> : undefined}
            onClick={() => {
              sendReminder({
                sessionId: tpSessionData?.eid,
                supervisors: supervisorsList,
                trainingId: tpSessionData?.trainingId,
                cb: () => updateSendRemindStyle(),
              });
            }}
          >
            {reminderSent ? 'Reminder sent' : 'Send reminder'}
          </Button>
        </Flex>
      </Center>
    );
  }

  return <EvaluateDetailScreen />;
};

export default SkillVerifier;
