import { FC } from 'react';
import { Button, Flex, Text } from '@chakra-ui/react';
import { ActionButton } from 'ui-components';

interface IProps {
  onClose: () => void;
  onContinueClick?: () => Promise<void>;
}

const ReassignModal: FC<IProps> = ({ onClose, onContinueClick }) => {
  return (
    <Flex flexDir='column' gap={8}>
      <Text alignItems='center' fontSize='15px'>
        You are about to reassign this training path to all the assignees. All
        the progress of all assignees will be removed. You want to continue?
      </Text>

      <Flex justifyContent='flex-end' gap={3}>
        <Button variant='outline' fontSize='15px' onClick={onClose}>
          Cancel
        </Button>
        <ActionButton colorScheme='blue' actionFn={onContinueClick}>
          Continue
        </ActionButton>
      </Flex>
    </Flex>
  );
};

export default ReassignModal;
