import React, { FC, forwardRef, useCallback, useMemo } from 'react';
import {
  Box,
  Button,
  CSSObject,
  Flex,
  IconButton,
  useStyles,
} from '@chakra-ui/react';
import {
  chakraComponents,
  MenuListProps,
  OptionProps,
  SizeProps,
  Size,
} from 'chakra-react-select';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { faPen } from '@fortawesome/pro-regular-svg-icons';

import { toArray } from '../../../utils';
import { FormCategory } from '../../../types';
import Dropdown, { DropDownProps, SelectOption } from '../../../atoms/Dropdown';
import { Authorize, AuthRole } from '../../../authorization';
import { useFormCategories } from '../common';

import {
  useCreateFormCategory,
  useDeleteFormCategory,
  useEditFormCategory,
} from '../category';

interface CustomOptionProps
  extends Omit<OptionProps<FormCategory, false>, 'children'> {
  onEditClick?: (data: FormCategory) => void;
}

const CustomOption: FC<CustomOptionProps> = (props) => {
  const {
    onEditClick,
    children,
    isFocused,
    isDisabled,
    isSelected,
    selectProps: { size, selectedOptionStyle, chakraStyles },
  } = props;

  const itemStyles = useStyles().item as Record<string, CSSObject>;

  const paddings: SizeProps = {
    // sm: '0.3rem 0.6rem',
    // md: '0.4rem 0.8rem',
    // lg: '0.5rem 1rem',
    sm: '0.75rem',
    md: '1rem',
    lg: '1.25rem',
  };

  const paddingBlock: SizeProps = {
    sm: '0.3rem',
    md: '0.4rem',
    lg: '0.5rem',
  };

  const shouldHighlight: boolean =
    selectedOptionStyle === 'color' && isSelected;

  const selectedBg = '#B1E5FC47';

  const initialStyles: CSSObject = {
    ...itemStyles,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    textAlign: 'start',
    fontSize: size,
    paddingInline: paddings[size as Size],
    paddingBlock: 0,
    color: '#1A1D1F',
    bg: isFocused ? itemStyles._focus?.bg : 'transparent',
    ...(shouldHighlight && {
      bg: selectedBg,
      _active: { bg: selectedBg },
    }),
    ...(isDisabled && itemStyles._disabled),
    ...(isDisabled && { _active: {} }),
    _hover: {
      button: {
        visibility: 'visible',
      },
    },
  };

  const sx: CSSObject = chakraStyles?.option
    ? // @ts-ignore
      chakraStyles.option(initialStyles, props)
    : initialStyles;

  return (
    <chakraComponents.Option
      {...props}
      // @ts-ignore
      innerProps={{ ...props.innerProps, sx }}
    >
      <Flex
        flex={1}
        align='center'
        justify='space-between'
        borderTop='1px solid #EAEBED'
        paddingBlock={paddingBlock[size as Size]}
        gap={4}
      >
        {children}

        <Authorize
          permittedFor='user'
          permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
        >
          <IconButton
            visibility='hidden'
            size='xs'
            aria-label='edit-category'
            variant='ghost'
            icon={
              <FontAwesomeIcon
                icon={faPen as IconProp}
                color='#6F767E'
                size='lg'
              />
            }
            onClick={() => onEditClick?.(props.data)}
          />
        </Authorize>
      </Flex>
    </chakraComponents.Option>
  );
};

type IProps = DropDownProps<SelectOption<never>> & {
  onCategoryDeleted?: () => Promise<void>;
  onCategoryUpdated?: (data: FormCategory) => void;
};

const FormCategorySelect = forwardRef<never, IProps>(
  (
    { onCategoryDeleted, onCategoryUpdated, inputStyle, onChange, ...rest },
    ref
  ) => {
    const { data, loading: loadingCategory } = useFormCategories({
      fetchPolicy: 'network-only',
    });

    const createFormCategory = useCreateFormCategory();
    const editFormCategory = useEditFormCategory();
    const deleteFormCategory = useDeleteFormCategory();

    const categoryList = useMemo(() => {
      return toArray(data?.EntityFormCategories);
    }, [data?.EntityFormCategories]);

    const onCreateClick = () => {
      createFormCategory({
        onCategoryCreated: (data) => {
          // @ts-ignore
          onChange?.(data);
        },
      });
    };

    const onDeleteClick = async (category: FormCategory) => {
      await deleteFormCategory({
        category: category,
        onCategoryDeleted: onCategoryDeleted,
      });
    };

    const onEditClick = (category: FormCategory) => {
      editFormCategory({
        category: category,
        onCategoryUpdated: onCategoryUpdated,
        onDeleteClick: () => onDeleteClick?.(category),
      });
    };

    const MenuList = useCallback((menuProps: MenuListProps) => {
      return (
        <chakraComponents.MenuList {...menuProps}>
          <Authorize
            permittedFor='user'
            permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
          >
            <Button
              w='full'
              size='md'
              variant='ghost'
              colorScheme='blue'
              fontSize='15px'
              borderRadius='0'
              p='8px 20px'
              height='44px'
              justifyContent='start'
              leftIcon={
                <FontAwesomeIcon icon={faPlus as IconProp} fontSize='14px' />
              }
              onClick={onCreateClick}
            >
              <Box>Create new form category</Box>
            </Button>
          </Authorize>

          {menuProps.children}
        </chakraComponents.MenuList>
      );
    }, []);

    return (
      <Dropdown
        ref={ref}
        isLoading={loadingCategory}
        options={categoryList as never}
        getOptionValue={(option) => option?.eid}
        getOptionLabel={(option) => (option?.eid ? option?.name : undefined)}
        size='lg'
        className='dropdown'
        placeholder=''
        inputStyle={{
          border: 'none',
          borderColor: 'transparent',
          borderRadius: '12px',
          fontSize: '15px',
          outline: 'none',
          ...inputStyle,
        }}
        components={{
          MenuList: MenuList as never,
          // Option: CustomOption as never,
          Option: (props) => (
            // @ts-ignore
            <CustomOption {...props} onEditClick={onEditClick} />
          ),
        }}
        // @ts-ignore
        onChange={onChange}
        {...rest}
      />
    );
  }
);

FormCategorySelect.displayName = 'FormCategorySelect';

export default FormCategorySelect;
