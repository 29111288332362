import React, { FC, useMemo } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useWatch } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { toArray } from '../../../../../../utils';
import { IAssigneeInput, TFormInput } from '../../training-input.types';
import { useTrainingCreator } from '../../common';
import { useUserRecordRef } from '../Assignee/assign-info.helpers';

import { ReactComponent as ManagerIcon } from 'assets/images/managerBlue.svg';
import { getSupervisorTransObject } from './supervisor-info.helpers';

interface IProps {}

const SupervisorInfo: FC<IProps> = () => {
  const { t } = useTranslation('traininginfo', { keyPrefix: 'supervisorInfo' });
  const userRef = useUserRecordRef();

  const [assigneeUserType] = useWatch<IAssigneeInput, ['assigneeUserType']>({
    name: ['assigneeUserType'],
  });

  const creator = useTrainingCreator();

  const [assignees, hasSkillVerifier, supervisors] = useWatch<
    TFormInput,
    ['assignees', 'hasSkillVerifier', 'supervisors']
  >({
    name: ['assignees', 'hasSkillVerifier', 'supervisors'],
  });

  const transObject = useMemo(() => {
    return getSupervisorTransObject(
      {
        assignees: assignees,
        assigneeUserType: assigneeUserType,
        supervisors: supervisors,
        hasSkillVerifier: hasSkillVerifier,
      },
      userRef,
      creator
    );
  }, [assigneeUserType, hasSkillVerifier, supervisors, creator]);

  const _transObject = toArray(transObject);

  if (_transObject?.length < 2) {
    return (
      <Flex
        flexDir='column'
        bg='#B1E5FC66'
        p='16px 24px'
        borderRadius='10px'
        gap={2}
      >
        <ManagerIcon width={16} />
        <Box color='#33383F'>
          <Trans t={t} i18nKey='placeholder' />
        </Box>
      </Flex>
    );
  }

  return (
    <Flex
      flexDir='column'
      bg='#B1E5FC66'
      p='16px 24px'
      borderRadius='10px'
      gap={2}
    >
      <ManagerIcon width={16} />
      {_transObject.map((item, index) => (
        <Box color='#33383F' key={index} _notLast={{ paddingBottom: 2 }}>
          <Trans
            t={t}
            {...item}
            // @ts-ignore
            components={{ ...item?.components }}
          />
        </Box>
      ))}
    </Flex>
  );
};

export default SupervisorInfo;
