import React, { FC } from 'react';
import { Box, Center, Flex, Image } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLoader } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Button } from '../../../../../../atoms';
import Loader from '../../../../../Loader';

import SearchEmpty from '../../../../../../assets/images/Illustrations.svg';
import DataEmpty from '../../../../../../assets/images/empty-state/empty-compliance-state.svg';

interface NProps {
  title: string;
  buttonText: string;
  onAddClick: () => void;
}

const NoDataView: FC<NProps> = ({ title, buttonText, onAddClick }) => {
  return (
    <Center flexDir='column' flex={1} gap={6} pt={5}>
      <Image src={DataEmpty} width='280px' />

      <Flex flexDir='column' gap='4px' textAlign='center'>
        <Box fontSize='15px' fontWeight='600' color='#33383F'>
          {title}
        </Box>

        <Box fontSize='12px' fontWeight='500' color='#6F767E'>
          Create a new one to get started.
        </Box>
      </Flex>

      <Button colorScheme='blue' borderRadius='12px' onClick={onAddClick}>
        {buttonText}
      </Button>
    </Center>
  );
};

interface IProps {
  isLoading?: boolean;
  category?: string;
  searchQuery?: string;
  dataLength?: number;
  emptyImage?: string; // TODO
  onBackClick?: () => void;
  type: 'sop' | 'form';
}

const BaseDataNotFound: FC<IProps> = ({
  isLoading,
  category,
  searchQuery,
  dataLength = 0,
  emptyImage,
  onBackClick,
  children,
  type,
}) => {
  if (!isLoading && dataLength) {
    return <>{children}</>;
  }

  const _search = searchQuery || category;

  if (isLoading && _search) {
    return (
      <Center flexDir='column' paddingTop={10} gap={1}>
        <Box fontSize='14px' fontWeight='600' color='#33383F'>
          Searching for the best match...
        </Box>
        <Box fontSize='12px' fontWeight='500' color='#6F767E' mb={4}>
          Almost there!
        </Box>
        <FontAwesomeIcon
          icon={faLoader as IconProp}
          size='2x'
          color='#2A85FF'
          spin
        />
      </Center>
    );
  }

  if (isLoading) {
    return (
      <Center boxSize='full' borderRadius='10px' pt={16}>
        <Loader size='lg' />
      </Center>
    );
  }

  if (!_search) {
    if (type === 'form') {
      return (
        <NoDataView
          title='No forms available'
          buttonText='Create new form'
          onAddClick={() => window.open('/forms/create', '_blank')}
        />
      );
    }
    if (type === 'sop') {
      return (
        <NoDataView
          title='No chapter available'
          buttonText='Create new chapter'
          onAddClick={() => window.open('/create-chapter', '_blank')}
        />
      );
    }
  }

  return (
    <Center flexDir='column' flex={1} gap={6} pt={4}>
      <Button
        colorScheme='blue'
        variant='ghost'
        fontSize='12px'
        fontWeight='500'
        leftIcon={<ArrowBackIcon />}
        onClick={onBackClick}
      >
        Go back to previous page
      </Button>

      <Flex flexDir='column' gap='4px' textAlign='center'>
        <Box fontSize='15px' fontWeight='600' color='#33383F'>
          {_search
            ? 'No matches found for your search'.concat(
                category ? ` in ${category}.` : '.'
              )
            : ''}
        </Box>

        <Box fontSize='12px' fontWeight='500' color='#6F767E'>
          {_search ? "But don't worry, there's more to explore!" : ''}
        </Box>
      </Flex>

      <Image
        src={_search ? SearchEmpty : emptyImage}
        boxSize={_search ? '200px' : '180px'}
      />
    </Center>
  );
};

export default BaseDataNotFound;
