import React, { useCallback } from 'react';
import { useResourceModal } from '../../../../common';
import AddImageContent, { AddIProps } from './AddImageContent';

type UseAddImage = (props: AddIProps) => void;

export const useAddImage = (): UseAddImage => {
  const resourceModal = useResourceModal();

  return useCallback((props) => {
    resourceModal({
      title: 'Add image',
      content: <AddImageContent {...props} />,
    });
  }, []);
};
