import React, { FC, useCallback, useContext, useMemo, useRef } from 'react';
import { Box, Center, Flex } from '@chakra-ui/react';
import { TrainingPlayContext } from './context';

import TrainingDesktop from '../CreateTrainingPath/Preview/component/TrainingDesktop';
import FinishTraining from './FinishTraining';
import Loader from '../../../sub-components/Loader';
import {
  UserDetailsComponent,
  UserDetailsRef,
} from '../../../sub-components/UserLocationDetails';
import { useSubmitForm } from '../../../sub-components/forms';

interface IProps {}

const PlayComponent: FC<IProps> = () => {
  const useDetailRef = useRef<UserDetailsRef>(null);
  const submitForm = useSubmitForm();
  const { trainingContents, selectedIndex, increment, loading } =
    useContext(TrainingPlayContext);

  const rawContent = useMemo(() => {
    return trainingContents?.[selectedIndex];
  }, [trainingContents, selectedIndex]);

  const goToNextCard = () => increment();

  const onItemClick = useCallback((event: MouseEvent, node: any) => {
    switch (node?.type?.name) {
      case 'member':
        useDetailRef.current?.openDetail('user', node.attrs);
        break;
      case 'location':
        useDetailRef.current?.openDetail('branch', node.attrs);
        break;
      case 'form':
        submitForm({
          formId: node.attrs?.eid,
          title: node.attrs?.label,
        });
        break;
      case 'chapter':
        window.open(`/chapters/view/${node.attrs?.eid}`, '_blank');
        break;
    }
  }, []);

  if (loading) {
    return (
      <Center
        width='100%'
        maxW='962px'
        borderRadius='8px'
        p='32px'
        pt='28px'
        bg='#FFFFFF'
        overflow='auto'
        minH='50vh'
      >
        <Loader size='xl' />
      </Center>
    );
  }

  if (rawContent?.type === 'finish') {
    return (
      <Box maxW='962px' overflow='auto'>
        <FinishTraining />
      </Box>
    );
  }

  return (
    <Flex flex={1} justify='center' maxW='962px' overflow='auto'>
      <TrainingDesktop
        value={rawContent}
        goToNextCard={goToNextCard}
        onItemClick={onItemClick}
      />
      <UserDetailsComponent ref={useDetailRef} />
    </Flex>
  );
};

PlayComponent.displayName =
  'displayName:pages/Training/TrainingPlay/PlayComponent';

export default PlayComponent;
