import { Box, Flex } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import React, { FC, useEffect } from 'react';

import LocationOwnerGraphContainer from './LocationOwnerGraphContainer';

import { Authorize, AuthRole } from '../../authorization';
import PrimaryButton from '../../atoms/PrimaryButton';
import { AmplitudeEventNames, deployEvent } from '../../shared/amplitudeEvents';
import AdminSAGraphContainer from './AdminSAGraphs/AdminSAGraphContainer';
import { TASKS_CREATE_URL } from '../../appRoutes';
import { EventPropertyType } from 'shared/amplitudeEvents/amplitude-events-types';

const TasksDashboard: FC = () => {
  const { t } = useTranslation(['sidebar', 'task']);
  const history = useHistory();

  useEffect(() => {
    deployEvent(AmplitudeEventNames.TASKS_PAGE_VISIT, {
      from_where: 'Navigation Panel',
    });
  }, []);

  return (
    <Flex flexDirection='column' gap='20px'>
      <Flex align='center'>
        <Box fontSize='28px' fontWeight='700' flex='1'>
          {t('sidebar:tasks')}
        </Box>
        <Authorize
          permittedRoles={[
            AuthRole.SUPER_ADMIN,
            AuthRole.ADMIN,
            AuthRole.LOCATION_OWNER,
          ]}
          permittedFor='user'
        >
          <PrimaryButton
            leftIcon={<AddIcon />}
            title={t('task:addNewTask')}
            width='fit-content'
            variant='solid'
            style={{ backgroundColor: 'white', color: '#2A85FF' }}
            onClick={() => {
              deployEvent(AmplitudeEventNames.TASK_CREATE);
              deployEvent(AmplitudeEventNames.CREATE_TASK_CLICK, {
                from_where: 'Tasks Dashboard',

                create_task_funnel_id: 4,
              });
              history.push(TASKS_CREATE_URL);
            }}
          />
        </Authorize>
      </Flex>
      <Authorize
        permittedFor={['user']}
        permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
      >
        <Box>
          <AdminSAGraphContainer />
        </Box>
      </Authorize>
      <Authorize
        permittedFor={['user']}
        permittedRoles={[AuthRole.LOCATION_OWNER]}
      >
        <Box>
          <LocationOwnerGraphContainer />
        </Box>
      </Authorize>
    </Flex>
  );
};

TasksDashboard.displayName = 'displayName:pages/TasksDashboard/TasksDashboard';

export default TasksDashboard;
