import React, { ChangeEvent, forwardRef, useImperativeHandle } from 'react';
import { Box, Center, Image } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import _uniqueId from 'lodash/uniqueId';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCirclePlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { useSafeState, useUploadImage } from '../../../../../hooks';
import {
  convertToWebp,
  fileToDtaUrl,
  getWebpFilename,
} from '../../../../../utils';
import Loader from '../../../../Loader';
import ThumbnailView from './ThumbnailView';
import { TFormInput } from '../training-input.types';

const TEN_MB = 10000000;

interface IProps {
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
}

const TrainingThumbnail = forwardRef<never, IProps>(
  ({ value, onChange, disabled }, ref) => {
    const { t } = useTranslation(['category', 'training']);
    const { setError } = useFormContext<TFormInput>();
    const [inputId] = React.useState(_uniqueId('addFile-'));
    const inputRef = React.useRef<HTMLInputElement>(null);

    const [, updateIsUploading] = useSafeState(false);
    const [thumbnail, updateThumbnail] = useSafeState<string>();

    const imageUpload = useUploadImage();

    useImperativeHandle(ref, () => null, []);

    const onImageChange = async (event: ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();

      try {
        updateIsUploading(true);

        const file = event.target.files?.item(0);

        if (!file) return;

        // TODO write file size validation here

        if (file.size > TEN_MB) {
          return setError('thumbnail', {
            message:
              'This uploaded file exceeds maximum file size limit (10mb).',
            type: 'validate',
          });
        }

        const dataUrl = await fileToDtaUrl(file);
        updateThumbnail(dataUrl);

        const blobData = await convertToWebp(dataUrl);

        const imageUrl = await imageUpload(
          blobData,
          getWebpFilename(file?.name),
          undefined
        );

        onChange?.(imageUrl);

        inputRef.current!.files = null;
        inputRef.current!.value = '';
      } catch (e) {
        updateIsUploading(false);
      } finally {
        updateIsUploading(false);
        updateThumbnail(undefined);
      }
    };

    if (thumbnail) {
      return (
        <Box minW='214px' h='270px' position='relative' borderRadius='12px'>
          <Image
            src={thumbnail}
            boxSize='full'
            borderRadius='12px'
            objectFit='cover'
            opacity={0.75}
          />

          <Center
            top={0}
            position='absolute'
            boxSize='full'
            bg='blackAlpha.200'
            borderRadius='12px'
            cursor='not-allowed'
          >
            <Loader size='lg' thickness='3px' />
          </Center>
        </Box>
      );
    }

    if (value) {
      return (
        <ThumbnailView
          value={value}
          ref={inputRef as never}
          onImageChange={onImageChange}
          onRemoveClick={() => onChange?.('')}
          disabled={disabled}
        />
      );
    }

    return (
      <Center
        as='label'
        minW='214px'
        h='270px'
        borderRadius='12px'
        border='1px dashed #6F767E'
        bg='#F4F4F4'
        gap={2}
        flexDir='column'
        cursor='pointer'
        userSelect='none'
        htmlFor={inputId}
        aria-disabled={disabled}
        _disabled={{
          opacity: 0.4,
          cursor: 'not-allowed',
        }}
      >
        <FontAwesomeIcon icon={faCirclePlus as IconProp} />
        <Box fontWeight={600} fontSize='15px'>
          {t('training:addImage')}
        </Box>

        <input
          ref={inputRef}
          type='file'
          onChange={onImageChange}
          id={inputId}
          style={{ display: 'none' }}
          accept='image/*'
          disabled={disabled}
        />
      </Center>
    );
  }
);

TrainingThumbnail.displayName = 'TrainingThumbnail';

export default TrainingThumbnail;
