import { Button, Flex } from '@chakra-ui/react';
import { useOpenLocationConfirm } from 'pages/Locations/OpenLocationConfirm/useOpenLocationConfirm';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AmplitudeEvent, deployEvent } from 'shared';
import { LAUNCHER_ADD_LOCATION } from '../../../../appRoutes';
import { FlexCard } from '../../../../atoms';
import EmptyState from '../../../EmptyState';

interface IProps {
  haveConfig?: boolean;
}

const EmptyLauncherTasks: FC<IProps> = ({ haveConfig }) => {
  const history = useHistory();
  const { t } = useTranslation('launcher');
  const openLocationConfirm = useOpenLocationConfirm();

  if (!haveConfig) {
    return (
      <FlexCard alignItems='center' justifyContent='center' p='90px'>
        <EmptyState
          image='SingleLocation'
          title={t('noLocationProgress')}
          description={t('noLocationProgressDesc')}
        />
      </FlexCard>
    );
  }

  return (
    <Flex
      mt={'12px'}
      alignItems={'center'}
      justifyContent={'center'}
      h={'full'}
    >
      <EmptyState
        image='SingleLocation'
        title={t('noLocationProgress')}
        description={t('noLocationProgressDesc')}
        showButton
        buttonProps={
          <Button
            mt={4}
            variant='solid'
            colorScheme='blue'
            size='md'
            borderRadius={'12px'}
            isFullWidth
            onClick={() => {
              deployEvent(AmplitudeEvent.LAUNCHER_ADD_LOCATION_CLICK, {
                location_launcher_visit_funnel_id: 5,
              });
              openLocationConfirm({
                onOkPress: () => {
                  deployEvent(
                    AmplitudeEvent.LAUNCHER_CONTINUE_CHARGEABLE_CLICK,
                    {
                      location_launcher_visit_funnel_id: 4,
                    }
                  );
                  history.push(LAUNCHER_ADD_LOCATION);
                },
              });
            }}
          >
            {t('addLocations')}
          </Button>
        }
      />
    </Flex>
  );
};

export default EmptyLauncherTasks;
