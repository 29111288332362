import React, { FC, useEffect, useState } from 'react';
import { Box, Center, Flex } from '@chakra-ui/react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripDotsVertical } from '@fortawesome/pro-solid-svg-icons';
import {
  faCircleCheck,
  faCircleXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { QuizQFormInput } from '../../quiz-form/quiz-form.types';
import PreviewContainer from '../PreviewContainer';

interface Props {
  data: QuizQFormInput;
}

const SequencePreview: FC<Props> = ({ data }) => {
  const [sequence, setSequence] = useState(() => {
    if (!data.sequence) return [];
    return [...data.sequence].sort(() => Math.random() - 0.5);
  });
  const [showResults, setShowResults] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setSequence([...data.sequence].sort(() => Math.random() - 0.5));
  }, [data.sequence]);

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination || showResults) return;

    const items = Array.from(sequence);
    const [removed] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, removed);
    setSequence(items);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    await new Promise((resolve) => setTimeout(resolve, 500));
    setShowResults(true);
    setIsSubmitting(false);
  };

  const isItemCorrect = (index: number): boolean => {
    if (!showResults) return false;
    return sequence[index]?.uid === data.sequence?.[index]?.uid;
  };

  const getCorrectItem = (index: number): string => {
    return data.sequence?.[index]?.text || '';
  };

  return (
    <PreviewContainer
      title={data.label}
      thumbnail={data.thumbnail}
      onSubmit={handleSubmit}
      showSubmit={!showResults}
      isSubmitting={isSubmitting}
      isDisabled={false}
    >
      <Flex>
        <Flex flexDirection='column'>
          {sequence.map((_, index) => (
            <Center
              key={index}
              padding='10px'
              minH='36px'
              borderRightRadius='12px'
              style={{ aspectRatio: '1' }}
              borderLeft={`2px solid ${
                showResults
                  ? isItemCorrect(index)
                    ? 'rgba(131, 191, 110, 1)'
                    : 'rgba(255, 106, 85, 1)'
                  : 'rgba(42, 133, 255, 1)'
              }`}
              bg='white'
              fontSize='9px'
              fontWeight={!showResults ? 600 : 500}
              mb='6px'
            >
              {index + 1}
            </Center>
          ))}
        </Flex>

        <Box pl='6px' flex={1} overflow='hidden'>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable
              droppableId='reorder-droppable'
              isDropDisabled={showResults}
            >
              {(provided) => (
                <Box ref={provided.innerRef} {...provided.droppableProps}>
                  {sequence.map((item, index) => {
                    return (
                      // @ts-ignore
                      <Draggable
                        key={item.uid}
                        draggableId={item.uid}
                        index={index}
                        isDragDisabled={showResults}
                      >
                        {(providedDrag) => (
                          <Flex
                            ref={providedDrag.innerRef}
                            {...providedDrag.draggableProps}
                            {...providedDrag.dragHandleProps}
                            p='10px'
                            mb='6px'
                            borderRadius='12px'
                            alignItems='center'
                            w='full'
                            bg={
                              showResults
                                ? isItemCorrect(index)
                                  ? 'rgba(181, 228, 202, 0.3)'
                                  : 'rgba(255, 106, 85, 0.2)'
                                : 'white'
                            }
                            border={
                              showResults
                                ? isItemCorrect(index)
                                  ? '1px solid rgba(131, 191, 110, 1)'
                                  : '1px solid rgba(255, 106, 85, 1)'
                                : '1px solid #E2E8F0'
                            }
                            _hover={
                              !showResults
                                ? {
                                    bg: 'rgba(177, 229, 252, 0.3)',
                                    border: '1px solid rgba(42, 133, 255, 1)',
                                  }
                                : {}
                            }
                          >
                            <Flex flexDir='column' w='full'>
                              <Flex
                                align='center'
                                justify='space-between'
                                w='full'
                                gap={2}
                              >
                                <Box
                                  fontWeight={!showResults ? 600 : 500}
                                  fontSize='9px'
                                  isTruncated
                                >
                                  {item.text}
                                </Box>
                                {!showResults ? (
                                  <FontAwesomeIcon
                                    icon={faGripDotsVertical as IconProp}
                                    color='#6F767E'
                                  />
                                ) : isItemCorrect(index) ? (
                                  <FontAwesomeIcon
                                    icon={faCircleCheck as IconProp}
                                    fontSize='12px'
                                    color='rgba(131, 191, 110, 1)'
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    icon={faCircleXmark as IconProp}
                                    fontSize='12px'
                                    color='rgba(255, 106, 85, 1)'
                                  />
                                )}
                              </Flex>
                              {/*{showResults && !isItemCorrect(index) && (*/}
                              {/*  <Flex*/}
                              {/*    fontSize='14px'*/}
                              {/*    mt={2}*/}
                              {/*    align='center'*/}
                              {/*    gap='5px'*/}
                              {/*  >*/}
                              {/*    <Box as='span'>Correct order:</Box>*/}
                              {/*    <Box*/}
                              {/*      as='span'*/}
                              {/*      fontWeight={600}*/}
                              {/*      fontSize='15px'*/}
                              {/*    >*/}
                              {/*      {getCorrectItem(index)}*/}
                              {/*    </Box>*/}
                              {/*  </Flex>*/}
                              {/*)}*/}
                            </Flex>
                          </Flex>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </DragDropContext>
        </Box>
      </Flex>
    </PreviewContainer>
  );
};

export default SequencePreview;
