import React, { FC, useState } from 'react';
import { Button, Checkbox, Flex } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormInput } from '../../../../atoms';
import { updateObject } from '../../../../utils/objectHelper';

import { IFormInput } from '../task.types';

interface IProps {
  data: IFormInput['contents'][number];
  close?: () => void;
}

const EditSteps: FC<IProps> = ({ data, close }) => {
  const { t } = useTranslation(['common', 'task']);

  const { getValues, setValue } = useFormContext<IFormInput>();

  const [value, setStateValue] = useState(data.title);

  const onUpdateClick = () => {
    const contents = getValues('contents');
    const index = contents.findIndex((con) => con.tempEid === data.tempEid);

    if (index > -1) {
      updateObject(contents, [index, 'title'], value);
      setValue('contents', contents, { shouldValidate: true });
    }

    close?.();
  };

  return (
    <Flex flex={1} gap={3}>
      <Checkbox isReadOnly />
      <FormInput
        placeholder={t('task:placeholder.addATODOItem')}
        variant='outline'
        value={value}
        onChange={(event) => setStateValue(event.target.value)}
        rightElementProps={{
          width: '5rem',
          justifyContent: 'flex-end',
        }}
        paddingRight={value ? 20 : 4}
        rightIcon={
          value ? (
            <Button
              fontSize='14px'
              onClick={onUpdateClick}
              colorScheme='blue'
              variant='ghost'
              paddingX='16px'
              borderRightRadius='12px'
            >
              {t('common:update')}
            </Button>
          ) : undefined
        }
      />
    </Flex>
  );
};

export default EditSteps;
