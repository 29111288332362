import { TFuncKey } from 'i18next';
import { cloneDeep } from '@apollo/client/utilities';
import { convertToPlainArray, TFormInput } from '../../create';
import { TrainingContType } from '../../../../types';
import { removeKeyData } from '../mapToFormData';

type Content = TFormInput['contents'][number];

const checkMilestoneDiff = (left: Content, right: Content) => {
  return (
    left?.title === right?.title &&
    left?.thumbnail === right?.thumbnail &&
    left?.type === right?.type
  );
};

const checkQuizDiff = (left: Content, right: Content) => {
  const baseCheck =
    left?.title === right?.title &&
    left?.thumbnail === right?.thumbnail &&
    left?.hasScoring === right?.hasScoring &&
    left?.type === right?.type;

  return (
    baseCheck &&
    JSON.stringify(left.questions) ===
      JSON.stringify(removeKeyData(right.questions, 'id'))
  );
};

interface ChangeLog {
  key: TFuncKey<'traininginfo', 'changeLog'>;
}

export const contentDiffHelpers = (
  originalData?: TFormInput['originalData'],
  values?: TFormInput
): ChangeLog[] => {
  const dataAltered = [] as ChangeLog[];

  // Training path details
  if (!originalData?.thumbnail && values?.thumbnail) {
    dataAltered.push({ key: 'thumbnail_added' });
  }

  if (originalData?.thumbnail && !values?.thumbnail) {
    dataAltered.push({ key: 'thumbnail_removed' });
  }

  if (
    originalData?.thumbnail &&
    values?.thumbnail &&
    originalData?.thumbnail !== values?.thumbnail
  ) {
    dataAltered.push({ key: 'thumbnail_altered' });
  }

  if (originalData?.title !== values?.title) {
    dataAltered.push({ key: 'title_altered' });
  }

  if (originalData?.description !== values?.description) {
    dataAltered.push({ key: 'description_altered' });
  }

  if (originalData?.category !== values?.category) {
    dataAltered.push({ key: 'category_altered' });
  }

  // Training content

  const _contents = (values?.contents || []).map((con) => con.tempEid);
  if (
    originalData?.contents?.every((con, index) => {
      const indexMatched = _contents[index] === con.tempEid;

      if (!indexMatched) {
        return false;
      }

      if (con.type === TrainingContType.QUIZ) {
        return checkQuizDiff(con, values?.contents[index]!);
      } else if (con.type === TrainingContType.MILESTONE) {
        return checkMilestoneDiff(con, values?.contents[index]!);
      }

      return indexMatched;
    })
  ) {
    if (_contents?.length > originalData?.contents?.length) {
      dataAltered.push({ key: 'content_altered' });
    }
  } else {
    dataAltered.push({ key: 'content_altered' });
  }

  // Training Assign and publish
  if (originalData?.assigneeUserType !== values?.assigneeUserType) {
    dataAltered.push({ key: 'assign_policy_altered' });
  }

  const origAssignee = convertToPlainArray(
    cloneDeep(originalData?.assignees || [])
  );
  const currAssignee = convertToPlainArray(cloneDeep(values?.assignees || []));

  if (!origAssignee.every((assignee) => currAssignee.includes(assignee))) {
    dataAltered.push({ key: 'assignee_altered' });
  } else {
    if (currAssignee.length > origAssignee.length) {
      dataAltered.push({ key: 'assignee_added' });
    }
  }

  // Training Schedule

  if (originalData?.frequency === values?.frequency) {
    if (
      values?.frequency === 'repeat' &&
      JSON.stringify(originalData?.repeatCycle) !==
        JSON.stringify(values?.repeatCycle)
    ) {
      dataAltered.push({ key: 'frequency_altered' });
    }
  } else {
    dataAltered.push({ key: 'frequency_altered' });
  }

  if (originalData?.deadlineType === values?.deadlineType) {
    switch (values?.deadlineType) {
      case 'date':
        if (
          originalData?.deadlineDate?.toString() !==
          values?.deadlineDate?.toString()
        ) {
          dataAltered.push({ key: 'deadline_altered' });
        }
        break;
      case 'duration':
        if (
          JSON.stringify(originalData?.duration) !==
          JSON.stringify(values?.duration)
        ) {
          dataAltered.push({ key: 'deadline_altered' });
        }
        break;
    }
  } else {
    dataAltered.push({ key: 'deadline_altered' });
  }

  if (originalData?.assignmentType === values?.assignmentType) {
    switch (values?.assignmentType) {
      case 'date':
        if (
          originalData?.startDate?.toString() !== values?.startDate?.toString()
        ) {
          dataAltered.push({ key: 'assignment_altered' });
        }
        break;
      case 'training':
        if (
          originalData?.dependantTraining?.trainingId !==
          values?.dependantTraining?.trainingId
        ) {
          dataAltered.push({ key: 'assignment_altered' });
        }
        if (
          originalData?.dependantTraining?.hasMinScore !==
          values?.dependantTraining?.hasMinScore
        ) {
          dataAltered.push({ key: 'assignment_altered' });
        } else if (
          values?.dependantTraining?.hasMinScore &&
          originalData?.dependantTraining?.minScorePercent !==
            values?.dependantTraining?.minScorePercent
        ) {
          dataAltered.push({ key: 'assignment_altered' });
        }

        break;
    }
  } else {
    dataAltered.push({ key: 'assignment_altered' });
  }

  // Training Skill verifier
  if (!originalData?.hasSkillVerifier && values?.hasSkillVerifier) {
    dataAltered.push({ key: 'skill_verifier_added' });
  }
  if (originalData?.hasSkillVerifier && !values?.hasSkillVerifier) {
    dataAltered.push({ key: 'skill_verifier_removed' });
  }
  if (originalData?.hasSkillVerifier && values?.hasSkillVerifier) {
    if (
      originalData?.skillVerifier?.name !== values?.skillVerifier?.name ||
      originalData?.skillVerifier?.skill !== values?.skillVerifier?.skill
    ) {
      dataAltered.push({ key: 'skill_verifier_altered' });
    }

    const prevSupervisors = originalData?.supervisors || [];
    const supervisors = values?.supervisors || [];

    if (
      !prevSupervisors?.every((supervisor) => supervisors.includes(supervisor))
    ) {
      dataAltered.push({ key: 'supervisor_altered' });
    } else {
      if (supervisors.length > prevSupervisors.length) {
        dataAltered.push({ key: 'supervisor_added' });
      }
    }
  }

  if (
    JSON.stringify(originalData?.accessPermission) !==
    JSON.stringify(values?.accessPermission)
  ) {
    dataAltered.push({ key: 'access_permission_altered' });
  }

  return dataAltered;
};
