import { gql } from '@apollo/client';

export const QUERIES = {
  GET_MY_EVALUATIONS_COUNT: gql`
    query GET_MY_EVALUATIONS_COUNT(
      $userIds: [String!]
      $filter: FilterFindManyTPSessionInput
      $sort: SortFindManyTPSessionInput
    ) {
      UserTpSessions(filter: $filter, sort: $sort) {
        count
        items {
          eid
          assignedUsers(userIds: $userIds) {
            userId
          }
        }
      }
    }
  `,

  // GET_MY_EVALUATIONS_COUNT: gql`
  //   query Query {
  //     TpNewEvaluations
  //   }
  // `,

  MY_EVALUATIONS: gql`
    query GET_MY_EVALUATIONS(
      $userIds: [String!]
      $page: Int
      $perPage: Int
      $filter: FilterFindManyTPSessionInput
      $sort: SortFindManyTPSessionInput
    ) {
      UserTpSessions(
        filter: $filter
        sort: $sort
        page: $page
        perPage: $perPage
      ) {
        items {
          title
          eid
          trainingId
          training {
            thumbnail
            category
          }
          userProgress {
            certificate
          }
          assignedUsers(userIds: $userIds) {
            userId
            status
            certificateDownloaded
            score
            evaluatedOn
            evaluationViewed
            isEvaluated
          }
        }
      }
    }
  `,
};
