import React, { FC } from 'react';
import { Flex, FlexProps } from '@chakra-ui/react';
import { BoxHeader } from '../../../../../../ui-components';
import { HeaderColors } from '../../../../../../configs';
import MobileFooter, { MobileHeader } from './MobileFooter';

interface IProps {
  headerBg?: FlexProps['bgColor'];
}

const MobilePreviewContainer: FC<IProps> = ({ headerBg, children }) => {
  return (
    <Flex flexDir='column' width='260px' pt={1} gap={5} paddingBottom={4}>
      <BoxHeader color={HeaderColors.Blue} title='Preview' fontSize='16px' />
      <Flex
        position='relative'
        flex={1}
        flexDir='column'
        overflow='hidden'
        border='5px solid #EFEFEF'
        borderRadius='32px'
        userSelect='none'
        maxHeight='550px'
      >
        <MobileHeader headerBg={headerBg} />
        {children}
        <MobileFooter />
      </Flex>
    </Flex>
  );
};

export default MobilePreviewContainer;
