import React, { forwardRef, useMemo } from 'react';
import {
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  useControllableState,
} from '@chakra-ui/react';
import { Button } from '../../../../../../../atoms';
// import { useUserDataSelector } from 'hooks';
import SupervisorSelectContent from './SupervisorSelectContent';
// import { useSupervisorMembers } from './useSupervisorMembers';
import { useSupervisors } from './useSupervisors';
import SupervisorSelectButton from './SupervisorSelectButton';
import { useHaveOnlyWorkers } from './useHaveOnlyWorkers';

interface IProps {
  value?: string[];
  onChange?: (value: string[]) => void;
  disabled?: boolean;
}

const TrainingSupervisorSelect = forwardRef<never, IProps>(
  ({ value: _value, onChange, disabled }, ref) => {
    // const { loggedInUserEid } = useUserDataSelector((state) => ({
    //   loggedInUserEid: state?.eid,
    // }));

    // const supervisors = useSupervisorMembers(loggedInUserEid);
    const supervisors = useSupervisors();

    const haveOnlyWorker = useHaveOnlyWorkers();

    const [internalValue, updateValue] = useControllableState<string[]>({
      value: _value as never,
      onChange: onChange as never,
    });

    const values = useMemo(() => {
      return supervisors.filter((val) => internalValue?.includes(val.eid));
    }, [supervisors, internalValue]);

    const onRemoveClick = (data: string) => {
      updateValue(internalValue.filter((val) => val !== data));
    };

    if (disabled) {
      return (
        <SupervisorSelectButton ref={ref} disabled={disabled} values={values} />
      );
    }

    return (
      <Flex flexDir='column' gap={2}>
        <Popover isLazy matchWidth>
          <PopoverTrigger>
            <SupervisorSelectButton
              ref={ref}
              disabled={disabled}
              values={values}
              onRemove={onRemoveClick}
              haveOnlyWorker={haveOnlyWorker}
            />
          </PopoverTrigger>

          <PopoverContent
            width='inherit'
            borderRadius='12px'
            boxShadow='md'
            _focus={{}}
          >
            <SupervisorSelectContent
              members={supervisors}
              value={internalValue}
              updateValue={updateValue}
            />
          </PopoverContent>
        </Popover>
        {values?.length > 10 ? (
          <Button
            alignSelf='start'
            size='sm'
            variant='ghost'
            colorScheme='blue'
            fontSize='12px'
            onClick={() => updateValue([])}
          >
            Clear all
          </Button>
        ) : null}
      </Flex>
    );
  }
);

TrainingSupervisorSelect.displayName = 'TrainingSupervisorSelect';

export default TrainingSupervisorSelect;
