// Create new file: ./component/MyTaskListing/TerminatedTasksComponent/TerminatedTasksComponent.tsx
import { Flex, List, ListItem } from '@chakra-ui/react';
import { HeaderColors } from 'configs';
import { useUserDataSelector } from 'hooks';
import MyTaskItem from 'pages/TasksDashboard/Components/MyTaskItem';
import { terminatedTasksHandler } from 'pages/TasksDashboard/MyTasks/functions/completedTasksHandler';
import {
  IMyTask,
  ITaskListData,
  ITaskOverview,
} from 'pages/TasksDashboard/tasks.types';
import React, { FC } from 'react';
import GenericColorHeader from 'sub-components/GenericColorHeader';

const TerminatedTasksComponent: FC<{
  data: IMyTask | undefined;
  taskOverviewData: ITaskOverview[];
  taskData: ITaskListData[];
}> = ({ data, taskOverviewData, taskData }) => {
  const timezone = useUserDataSelector((state) => state?.timezone);
  return terminatedTasksHandler(data, timezone)?.length > 0 ? (
    <Flex flexDirection='column' gap='10px'>
      <GenericColorHeader
        title='Terminated tasks'
        color={HeaderColors.Orange}
      />
      <Flex flexDir='column'>
        <List spacing={2}>
          {terminatedTasksHandler(data, timezone)?.map((task, index) => {
            return (
              <ListItem
                key={index}
                bg='white'
                p={2}
                borderRadius='8px'
                _hover={{ bg: '#F9F9F9' }}
              >
                <MyTaskItem task={task} />
              </ListItem>
            );
          })}
        </List>
      </Flex>
    </Flex>
  ) : null;
};

export default TerminatedTasksComponent;
