import React, { FC, useState } from 'react';
import { ITypes } from '../Header';
import BaseFilter from './BaseFilter';
import { ClearFilterType } from './FilterContainer';
import TypeFilter from './TypeFilter';
import StatusFilter from './StatusFilter';
import CategoryFilter from './CategoryFilter';
import JobFilter from './JobFilter';
import LocationFilter from './LocationFilter';
import useCombinedStore from 'zustandStore/store';
import { AnalyticsBaseFilter } from '../../components';
import { TAnalyticsFilterClearFilter } from '../../components/DetailsPageFilters/AnalyticsBaseFilter';
import DetailsStatusFilter from './DetailsStatusFilter';
import { DetailsFiltersType } from '../../components/AnalyticsContainerHeader';
export type PageNameType =
  | 'base'
  | 'statuses'
  | 'types'
  | 'categories'
  | 'job'
  | 'locations'
  | 'detailsStatus'
  | 'detailsScores';

interface IProps {
  filters: any;
  setFilters: any;
  handleTypeSelection?: (
    e: React.ChangeEvent<HTMLInputElement>,
    type: ITypes
  ) => void;
  handleStatusSelection?: (
    e: React.ChangeEvent<HTMLInputElement>,
    status: ITypes
  ) => void;
  handleCategorySelection?: (
    e: React.ChangeEvent<HTMLInputElement>,
    category: ITypes
  ) => void;
  handleJobSelection?: (
    e: React.ChangeEvent<HTMLInputElement>,
    jobs: ITypes
  ) => void;
  handleMembersStatusSelection?: (
    e: React.ChangeEvent<HTMLInputElement>,
    status: ITypes
  ) => void;
  handleLocationsSelection?: (
    e: React.ChangeEvent<HTMLInputElement>,
    locations: ITypes
  ) => void;
  clearFilters: (type?: ClearFilterType | TAnalyticsFilterClearFilter) => void;
}

const MainContainer: FC<IProps> = ({
  setFilters,
  filters,
  handleTypeSelection,
  handleStatusSelection,
  handleCategorySelection,
  handleJobSelection,
  handleMembersStatusSelection,
  handleLocationsSelection,
  clearFilters,
}) => {
  const { tableTabIndex } = useCombinedStore();
  const [pageName, setPageName] = useState<PageNameType>('base');
  if (pageName === 'types') {
    return (
      <TypeFilter
        clearFilters={clearFilters}
        filters={filters}
        setPageName={setPageName}
        handleTypeSelection={handleTypeSelection}
      />
    );
  }

  if (pageName === 'statuses') {
    return (
      <StatusFilter
        clearFilters={clearFilters}
        handleStatusSelection={
          tableTabIndex === 0
            ? handleStatusSelection
            : handleMembersStatusSelection
        }
        filters={filters}
        setPageName={setPageName}
      />
    );
  }

  if (pageName === 'categories') {
    return (
      <CategoryFilter
        clearFilters={clearFilters}
        handleCategorySelection={handleCategorySelection}
        filters={filters}
        setPageName={setPageName}
      />
    );
  }

  if (pageName === 'job') {
    return (
      <JobFilter
        clearFilters={clearFilters}
        handleJobSelection={handleJobSelection}
        filters={filters}
        setPageName={setPageName}
      />
    );
  }

  if (pageName === 'locations') {
    return (
      <LocationFilter
        clearFilters={clearFilters}
        handleLocationsSelection={handleLocationsSelection}
        filters={filters}
        setPageName={setPageName}
      />
    );
  }

  return (
    <>
      {
        <BaseFilter
          clearFilters={clearFilters}
          filters={filters}
          setPageName={setPageName}
        />
      }
    </>
  );
};

export default MainContainer;
