import React, { FC } from 'react';
import { useWatch } from 'react-hook-form';
import { TFormInput } from '../../training-input.types';
import SupervisorInfo from './SupervisorInfo';

interface IProps {}

const SupervisorInfoContainer: FC<IProps> = () => {
  const [hasSkillVerifier] = useWatch<TFormInput, ['hasSkillVerifier']>({
    name: ['hasSkillVerifier'],
  });

  if (!hasSkillVerifier) {
    return null;
  }

  return <SupervisorInfo />;
};

export default React.memo(SupervisorInfoContainer);
