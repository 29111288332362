import {
  Question,
  TPUserProgressEntity,
} from 'sub-components/training-v2/shared/types';

export const isQuestionCompleted = (
  questionId: string,
  userProgress: TPUserProgressEntity[]
) => {
  if (!userProgress?.length) return false;

  const progress = userProgress[0]?.progress;
  if (progress?.length === 0) return false;

  return progress.some((p) =>
    p.responses?.some((r) => r.qid === questionId && r.isCorrect !== undefined)
  );
};

export const getQuestionResponse = (
  questionId: string,
  userProgress: TPUserProgressEntity[]
) => {
  if (!userProgress?.length) return null;

  const progress = userProgress[0]?.progress;
  if (!progress?.length) return null;

  const questionProgress = progress.find((p) =>
    p.responses?.some((r) => r.qid === questionId)
  );

  return questionProgress?.responses?.find((r) => r.qid === questionId);
};

export const isLastUnansweredQuestion = (
  questions: Question[],
  userProgress: TPUserProgressEntity[]
) => {
  const quizQuestions = questions?.filter((q) => q.type !== 'initial') || [];

  const completedCount = quizQuestions.filter((q) =>
    isQuestionCompleted(q.eid, userProgress || [])
  ).length;

  const isLastQuizQuestion = completedCount === quizQuestions.length - 1;
  return isLastQuizQuestion;
};

export const cleanResponse = (response: any) => {
  const { __typename, createdAt, updatedAt, ...cleanedResponse } = response;

  // Clean options array if it contains objects
  const cleanedOptions = response?.options?.map((option: any) => {
    if (typeof option === 'string') return option;
    const { __typename, ...cleanedOption } = option;
    return cleanedOption;
  });

  // Clean pairs array
  const cleanedPairs = response?.pairs?.map((pair: any) => {
    const { __typename, ...cleanedPair } = pair;
    return cleanedPair;
  });

  // Clean sequence array
  const cleanedSequence = response?.sequence?.map((seq: any) => {
    const { __typename, ...cleanedSeq } = seq;
    return cleanedSeq;
  });

  return {
    ...cleanedResponse,
    options: cleanedOptions,
    pairs: cleanedPairs,
    sequence: cleanedSequence,
  };
};

export const calculateTotalScore = (
  contentId: string,
  existingProgress: TPUserProgressEntity['progress']
): number => {
  const contentProgress = existingProgress?.find((p) => p.id === contentId);
  if (!contentProgress || !contentProgress.responses) return 0;

  return contentProgress.responses.reduce((total, response) => {
    return total + (response.score || 0);
  }, 0);
};
