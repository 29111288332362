import { useToast } from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import { useFormContext } from 'react-hook-form';
import { useSaveHandler } from './useSaveHandler';
import { PUBLISH_TRAINING, TPublishResponse } from './training.graphql';
import { TFormInput } from './training-input.types';

type UsePublishHandler = (
  values: TFormInput['accessPermission']
) => Promise<void>;

export const usePublishHandler = (): UsePublishHandler => {
  const toast = useToast({
    position: 'top-right',
    isClosable: true,
    duration: 3000,
  });
  const { getValues, setValue } = useFormContext<TFormInput>();

  const [publishTraining] = useMutation<TPublishResponse>(PUBLISH_TRAINING, {
    onCompleted: () => {
      toast({
        status: 'success',
        title: 'Success',
        description: 'Training path created successfully!',
      });
    },
    onError: (err) => {
      // eslint-disable-next-line no-console
      console.log(err?.message);
      toast({
        status: 'error',
        title: 'Error',
        description: 'Training path creation failed!',
      });
    },
  });

  const saveHandler = useSaveHandler();

  return async (access: TFormInput['accessPermission']) => {
    setValue('accessPermission', access);
    await saveHandler(getValues());
    const res = await publishTraining({ variables: { eid: getValues('eid') } });

    if (res.errors) {
      return Promise.reject(res.errors);
    }
  };
};
