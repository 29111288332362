import React, { useCallback, useRef } from 'react';
import ModalContent from './ModalContent';
import { ApolloProvider, useApolloClient } from '@apollo/client';
import { useConfirm, UseConfirm } from 'ui-components';
import { TPPathAnalyticsItem } from 'sub-components/training-v2/shared/types';

interface IProps {
  trainingId: string;
  sessionId: string;
  quizId: string;
  record: TPPathAnalyticsItem;
  cb?: () => void;
}

type QuizResponseModal = (props: IProps) => void;

export const useQuizResponse = (): QuizResponseModal => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();
  const apolloClient = useApolloClient();

  return useCallback(({ trainingId, sessionId, quizId, cb, record }) => {
    if (confirmRef.current) {
      confirmRef.current.destroy();
    }

    const cbHandler = () => {
      confirmRef?.current?.destroy();
      cb?.();
    };

    confirmRef.current = confirm({
      Wrapper: ({ children }: any) => {
        return (
          <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
        );
      },
      content: (
        <ModalContent
          trainingId={trainingId}
          sessionId={sessionId}
          quizId={quizId}
          record={record}
        />
      ),
      size: '6xl',
      isCentered: true,
      contentProps: {
        py: '12px',
        px: '8px',
        borderRadius: '16px',
      },
      okText: null,
      cancelText: null,
      footer: null,
    });
  }, []);
};
