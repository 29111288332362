import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { useReactiveVar } from '@apollo/client';
import { AuthRole } from '../../../../../../../authorization';
import { usersEntityObj } from '../../../../../../Header';
import { UserEntityData } from '../../../../../../../shared/graphql/shared-types';
import { TFormInput } from '../../../training-input.types';

export const haveOnlyWorkers = (
  entityUsers: UserEntityData[] = [],
  assignees: TFormInput['assignees']
): boolean => {
  if (assignees) {
    return assignees.every((assignee) => {
      if (assignee.type === 'user') {
        return !entityUsers?.some(
          (user) =>
            assignee.userIds?.includes(user.eid) &&
            user.type === 'user' &&
            user.authRole !== AuthRole.WORKER
        );
      }

      if (
        assignee.type === 'authRole' &&
        assignee.authRoles?.every((ar) => AuthRole.WORKER === ar)
      ) {
        return true;
      }
      return false;
    });
  }

  return false;
};

export const useHaveOnlyWorkers = (): boolean => {
  const entityUsers = useReactiveVar(usersEntityObj);

  const assignees = useWatch<TFormInput, 'assignees'>({
    name: 'assignees',
  });

  return useMemo(() => {
    return haveOnlyWorkers(entityUsers, assignees);
  }, [entityUsers, assignees]);
};
