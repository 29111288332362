import { Flex } from '@chakra-ui/react';
import React, { FC } from 'react';
import ScoreCard from './ScoreCard';
import { IFinalOverallLocationWiseAnalytics } from 'sub-components/training-v2/shared/types';
import { useUserDataSelector } from 'hooks';
import { AuthRole } from 'sop-commons/src/client';

interface IProps {
  completeData: IFinalOverallLocationWiseAnalytics[];
}

const RenderScoreCards: FC<IProps> = ({ completeData }) => {
  const highestPerforming = completeData?.slice(0, 3);
  const topMember = highestPerforming?.[0];
  const secondMember = highestPerforming?.[1];
  const thirdMember = highestPerforming?.[2];

  const { userAuthRole, loggedInUserId } = useUserDataSelector((state) => ({
    userAuthRole: state?.authRole,
    loggedInUserId: state?.eid,
  }));

  const getLabel = (data: IFinalOverallLocationWiseAnalytics) => {
    if (userAuthRole === AuthRole?.LOCATION_OWNER) {
      if (loggedInUserId === data?.userId) {
        return 'You' || 'Unknown';
      } else {
        return data?.name || 'Unknown';
      }
    } else {
      return data?.name || 'Unknown';
    }
  };

  return (
    <Flex justify='center' align='flex-end' gap={4}>
      {highestPerforming?.length === 3 && (
        <>
          <ScoreCard
            isOwnLocation={secondMember?.isOwnLocation}
            score={`${secondMember?.completionPercent?.toFixed(2)}`}
            label={getLabel(secondMember)}
            locationId={secondMember?.locationId}
            position={2}
          />
          <ScoreCard
            isOwnLocation={topMember?.isOwnLocation}
            score={`${topMember?.completionPercent?.toFixed(2)}`}
            label={getLabel(topMember)}
            locationId={topMember?.locationId}
            position={1}
            isCrown
          />
          <ScoreCard
            isOwnLocation={thirdMember?.isOwnLocation}
            score={`${thirdMember?.completionPercent?.toFixed(2)}`}
            label={getLabel(thirdMember)}
            locationId={thirdMember?.locationId}
            position={3}
          />
        </>
      )}

      {highestPerforming?.length === 2 && (
        <>
          <ScoreCard
            isOwnLocation={topMember?.isOwnLocation}
            score={`${topMember?.completionPercent?.toFixed(2)}`}
            label={getLabel(topMember)}
            locationId={topMember?.locationId}
            position={1}
            isCrown
          />
          <ScoreCard
            isOwnLocation={secondMember?.isOwnLocation}
            score={`${secondMember?.completionPercent?.toFixed(2)}`}
            label={getLabel(secondMember)}
            locationId={secondMember?.locationId}
            position={2}
          />
        </>
      )}

      {highestPerforming?.length === 1 && (
        <Flex justify='center' width='100%'>
          <ScoreCard
            isOwnLocation={topMember?.isOwnLocation}
            score={`${topMember?.completionPercent?.toFixed(2)}`}
            label={getLabel(topMember)}
            locationId={topMember?.locationId}
            hideRank
            position={1}
          />
        </Flex>
      )}
    </Flex>
  );
};

export default RenderScoreCards;
