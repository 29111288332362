import React, { FC, useEffect, useState } from 'react';
import { Box, Center, Flex } from '@chakra-ui/react';
import { Image } from 'ui-components';
import ImgBgToggleLight from 'assets/images/img-toggle-bg-light.png';
import ImgBgToggleDark from 'assets/images/img-toggle-bg-dark.png';
import { Button } from 'atoms';
import { HeaderColors } from 'configs';

const STORAGE_KEY = 'imgBgToggleViewed';

interface ModalContentProps {
  onClose: () => void;
}

const ModalContent: FC<ModalContentProps> = ({ onClose }) => {
  const [toggleImgView, setToggleImgView] = useState(false);

  useEffect(() => {
    const isViewed = localStorage.getItem(STORAGE_KEY);
    if (!isViewed) {
      localStorage.setItem(STORAGE_KEY, 'true');
    } else {
      onClose();
    }
  }, [onClose]);

  const handleToggle = () => setToggleImgView((prev) => !prev);

  return (
    <Center flexDir='column' gap={6} w='full' p='32px'>
      <Box fontSize='20px' fontWeight={600}>
        New Feature! Switch Viewing Modes
      </Box>

      <Image
        width={280}
        height={200}
        src={toggleImgView ? ImgBgToggleDark : ImgBgToggleLight}
        style={{ transition: 'opacity 0.5s ease' }}
      />

      <Flex
        align='center'
        gap={1}
        transition='all 0.3s ease'
        _hover={{ opacity: 0.8 }}
      >
        <Box
          bg='rgba(111, 118, 126, 1)'
          opacity={toggleImgView ? 0.4 : 0.6}
          width={toggleImgView ? '8px' : '17px'}
          height={toggleImgView ? '8px' : '8px'}
          borderRadius={toggleImgView ? 'full' : '100px'}
          transition='all 0.3s ease'
        />
        <Box
          bg='rgba(111, 118, 126, 1)'
          opacity={toggleImgView ? 0.6 : 0.4}
          width={toggleImgView ? '17px' : '8px'}
          height='8px'
          borderRadius={toggleImgView ? '100px' : 'full'}
          transition='all 0.3s ease'
        />
      </Flex>
      <Box textAlign='center' fontWeight={500} color='rgba(111, 118, 126, 1)'>
        Having trouble seeing the image clearly? Click on it to toggle its
        background between light and dark modes for better visibility
      </Box>
      <Button
        onClick={() => (toggleImgView ? onClose() : handleToggle())}
        variant='solid'
        colorScheme='blue'
        size='lg'
        w='full'
      >
        {toggleImgView ? 'Finish' : 'Next'}
      </Button>
      <Box
        textAlign='center'
        w='full'
        textDecor='underline'
        color={HeaderColors.DarkBlue}
        cursor='pointer'
        fontSize='15px'
        fontWeight={600}
        onClick={onClose}
      >
        Skip to chapter
      </Box>
    </Center>
  );
};

export default ModalContent;
