import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { AlertBox } from '../../../../../../../../atoms';
import SequenceOptionsList from './SequenceOptionsList';

interface IProps {}

const SequenceInput: FC<IProps> = () => {
  return (
    <Flex flexDir='column' gap={3}>
      <SequenceOptionsList />

      <AlertBox
        icon={faCircleInfo}
        childProps={{ color: '#33383F', fontSize: '12px' }}
      >
        Assignees will see shuffled answer options
      </AlertBox>
    </Flex>
  );
};

export default SequenceInput;
