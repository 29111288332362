import { useUserEntity } from 'hooks';
import moment from 'moment';
import { useEffect, useRef } from 'react';
import { useNexusBoardContext } from '../store/context';
import { useGetDates, useGetLocationIds } from '../utils';
import { useService } from './useService';

export const useControl = () => {
  const {
    setError,
    updateAttritionData,
    updateAuditData,
    updateLocationDetails,
    updateTaskData,
    updateTrainingData,
  } = useNexusBoardContext((state) => ({
    setError: state.setError,
    updateAttritionData: state.udpateAttritionData,
    updateAuditData: state.updateAuditData,
    updateTaskData: state.updateTaskData,
    updateLocationDetails: state.updateLocationDetails,
    updateTrainingData: state.updateTrainingData,
  }));
  const exportRef = useRef<HTMLDivElement>(null);
  const { loggedInUserEntityId, businessLocations } = useUserEntity(
    (entity) => ({
      loggedInUserEntityId: entity?.eid,
      businessLocations: entity?.locations,
    })
  );
  const {
    attritionService,
    auditService,
    taskService,
    leaderboardService,
    locationDetailsService,
  } = useService({});
  // const { attritionData, attritionRes } = attritionService;
  // const { auditData, auditRes } = auditService;
  // const { taskData, taskRes } = taskService;
  const { leaderboardData, leaderboardRes } = leaderboardService;
  const { locationDetailsData, locationDetailsRes } = locationDetailsService;

  const locationIds = useGetLocationIds();
  // const { startDate, endDate } = useGetDates(undefined, { useUTC: true });
  const startDate = moment().utc().startOf('month').startOf('day');
  const endDate = moment().utc().endOf('month').endOf('day');

  // const getAttritionData = () => {
  //   attritionData({
  //     variables: {
  //       startDate: startDate?.toISOString(),
  //       endDate: endDate?.toISOString(),
  //     },
  //   });
  // };

  // const getAutiData = () => {
  //   auditData({
  //     variables: {
  //       locationIds: locationIds,
  //       startDate: startDate?.toISOString(),
  //       endDate: endDate?.toISOString(),
  //     },
  //   });
  // };

  // const getTaskData = () => {
  //   taskData({
  //     variables: {
  //       locationIds: locationIds,
  //       type: 'monthly',
  //     },
  //   });
  // };

  const getLeaderboardData = () => {
    leaderboardData({
      variables: {
        locationIds: locationIds?.filter((loc) =>
          businessLocations?.some((bus) => loc === bus?.eid && !bus?.isRoot)
        ),
        startDate: startDate?.toISOString(),
        endDate: endDate?.toISOString(),
      },
    });
  };

  const getLocationDetailsData = () => {
    locationDetailsData({
      variables: {
        entityId: loggedInUserEntityId,
      },
    });
  };

  useEffect(() => {
    if (locationIds?.length > 0) {
      // getAttritionData();
      // getAutiData();
      // getTaskData();
      getLeaderboardData();
    }
    if (loggedInUserEntityId) {
      getLocationDetailsData();
    }
  }, [locationIds, loggedInUserEntityId]);

  useEffect(() => {
    if (locationDetailsRes?.data?.EntityById) {
      updateLocationDetails(locationDetailsRes?.data?.EntityById);
    }
    // if (attritionRes?.error || auditRes?.error || taskRes?.error) {
    //   const errorMessages = [
    //     attritionRes?.error?.message,
    //     auditRes?.error?.message,
    //     taskRes?.error?.message,
    //   ]
    //     .filter(Boolean)
    //     .join('; ');
    //   setError(`Failed to fetch data: ${errorMessages}`);
    // }
    if (leaderboardRes?.error) {
      setError(`Failed to fetch data: ${leaderboardRes?.error?.message}`);
    } else {
      // if (attritionRes?.data && auditRes?.data && taskRes?.data) {
      //   updateAttritionData(attritionRes?.data?.EntityAttritionAnalytics);
      //   updateAuditData(auditRes?.data?.AuditLocationsAnalytics);
      //   updateTaskData(taskRes?.data?.TaskSupervisedLocationWiseTrend);
      // }
      if (leaderboardRes?.data) {
        updateAttritionData(leaderboardRes?.data?.NexusDashboard?.attrition);
        updateAuditData(leaderboardRes?.data?.NexusDashboard?.audit);
        updateTaskData(leaderboardRes?.data?.NexusDashboard?.task);
        updateTrainingData(leaderboardRes?.data?.NexusDashboard?.tp);
      }
    }
  }, [
    // attritionRes,
    // auditRes,
    // taskRes,
    leaderboardRes,
    locationDetailsRes,
    updateAttritionData,
    updateAuditData,
    updateTaskData,
    updateLocationDetails,
    setError,
  ]);

  return {
    leaderboardLoading: leaderboardRes?.loading,
    exportRef,
    locationDetailsLoading: locationDetailsRes?.loading,
  };
};
