import React, { FC, useCallback, useEffect } from 'react';
import { Flex } from '@chakra-ui/react';
import { SelectOption } from '../../../../../../atoms';
import { useSafeState } from '../../../../../../hooks';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import NavigateButton from './NavigateButton';

type Direction = 'left' | 'right';

const TRACK_SIZE = 360;

interface IProps {
  options: SelectOption[];
  filterValue?: SelectOption;
  onFilterChange?: (value: SelectOption) => void;
}

const SubcategoryListContent: FC<IProps> = ({
  options,
  filterValue,
  onFilterChange,
}) => {
  const [isScrollable, setScrollable] = useSafeState<boolean>(false);
  const [status, setStatus] = useSafeState<Direction>();

  function handleResize(ref: HTMLElement) {
    const { scrollLeft, clientWidth, scrollWidth } = ref;
    if (Math.round(scrollLeft) === Math.round(scrollWidth - clientWidth)) {
      setStatus('right');
    } else if (scrollLeft === 0) {
      setStatus('left');
    } else {
      setStatus(undefined);
    }
  }

  const handleThumbPosition = useCallback((event) => {
    handleResize(event.currentTarget);
  }, []);

  useEffect(() => {
    const element = document.getElementById('sub-category-lists');

    if (element) {
      setScrollable(element?.scrollWidth > element?.clientWidth * 1.05);
    }

    handleResize(element!);

    const observer = new ResizeObserver(() => {
      handleResize(element!);
    });

    element && observer.observe(element);

    element?.addEventListener('scroll', handleThumbPosition);
    return () => {
      element && observer.unobserve(element);
      element?.removeEventListener('scroll', handleThumbPosition);
    };
  }, [options]);

  const getScrollAmount = (
    direction: Direction,
    element: HTMLElement
  ): number => {
    const newLeft = element.clientWidth - (element.clientWidth % TRACK_SIZE);

    return direction === 'right' ? newLeft : -newLeft;
  };

  const handleScrollButton = (direction: Direction) => {
    const element = document.getElementById('sub-category-lists');

    if (element) {
      const scrollAmount = getScrollAmount(direction, element);
      element.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  return (
    <Flex position='relative'>
      <NavigateButton
        isScrollable={isScrollable}
        direction='left'
        onClick={() => handleScrollButton('left')}
        containerProps={{
          left: 0,
          justify: 'start',
          hidden: status === 'left',
        }}
        isDisabled={status === 'left'}
      />

      <Flex
        overflowX='auto'
        flexGrow={1}
        gap={2}
        userSelect='none'
        fontSize='15px'
        fontWeight='500'
        id='sub-category-lists'
      >
        {options.map((item) => {
          const isSelected = item.value === (filterValue?.value || '');
          return (
            <Flex
              key={item.value}
              whiteSpace='nowrap'
              border='1px solid'
              borderColor='#CAC4D0'
              borderRadius='8px'
              height='36px'
              align='center'
              paddingX='14px'
              cursor='pointer'
              aria-selected={isSelected}
              _hover={{ bg: '#F4F4F3' }}
              _selected={{ bg: '#E8DEF8', borderColor: '#E8DEF8' }}
              onClick={(event) => {
                onFilterChange?.(item);
                event.currentTarget?.scrollIntoView({
                  inline: 'center',
                  block: 'center',
                });
              }}
              gap='10px'
            >
              {isSelected ? (
                <FontAwesomeIcon icon={faCheck as IconProp} color='#4A4459' />
              ) : null}
              <span>{item.label}</span>
            </Flex>
          );
        })}
      </Flex>

      <NavigateButton
        isScrollable={isScrollable}
        direction='right'
        onClick={() => handleScrollButton('right')}
        containerProps={{
          right: 0,
          justify: 'end',
          hidden: status === 'right',
        }}
        isDisabled={status === 'right'}
      />
    </Flex>
  );
};

export default SubcategoryListContent;
