import axios from 'axios';

interface VideoUrlDetails {
  title: string;
  thumbnail_url: string;
}

export const getYoutubeDetails = async (
  videoUrl: string
): Promise<VideoUrlDetails> => {
  const response = await axios.get<VideoUrlDetails>(
    `https://youtube.com/oembed?url=${videoUrl}&format=json`
  );
  return response.data;
};

export const getVimeoDetails = async (
  videoUrl: string
): Promise<VideoUrlDetails> => {
  const response = await axios.get<VideoUrlDetails>(
    `https://vimeo.com/api/oembed.json?url=${videoUrl}`
  );
  return response.data;
};
