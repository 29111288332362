import React, { FC } from 'react';
import { Box, Center } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLoader } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { ReactComponent as CardPreviewEmpty } from 'sop-commons/src/card-components/assets/cardPreview.svg';

interface IProps {
  isLoading?: boolean;
  emptyTitle: string;
  emptyCaption?: string;
  emptyIcon?: React.ComponentType;
}

const PreviewEmpty: FC<IProps> = ({
  emptyTitle,
  emptyCaption,
  isLoading,
  emptyIcon,
}) => {
  if (isLoading) {
    return (
      <Center height='full'>
        <FontAwesomeIcon
          icon={faLoader as IconProp}
          size='2x'
          color='#2A85FF'
          spin
        />
      </Center>
    );
  }

  const EmptyIcon = emptyIcon || CardPreviewEmpty;

  return (
    <Center
      flexDir='column'
      gap='8px'
      height='full'
      borderRadius='16px'
      paddingX='32px'
    >
      <EmptyIcon />
      <Box
        marginTop={4}
        fontSize='15px'
        fontWeight='600'
        color='#6F767E'
        textAlign='center'
        whiteSpace='pre-line'
      >
        {emptyTitle}
      </Box>
      {emptyCaption ? (
        <Box
          maxW='200px'
          textAlign='center'
          fontSize='12px'
          fontWeight='400'
          color='#9E9E9E'
        >
          {emptyCaption}
        </Box>
      ) : null}
    </Center>
  );
};

export default PreviewEmpty;
