import React, { FC } from 'react';
import { CSSObject, Flex } from '@chakra-ui/react';
import { useFormContext, useFormState } from 'react-hook-form';

import { ReactComponent as DeleteIcon } from '../../../../../assets/images/trash.svg';
import { ReactComponent as CopyIcon } from '../../../../../assets/images/copy.svg';
import { IFormInput } from '../../form-types';
import { IFooterProps } from './audit-options';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';

export const notLastStyle: CSSObject = {
  position: 'relative',
  ':after': {
    content: "''",
    position: 'absolute',
    right: '-16px',
    top: '50%',
    width: '1.5px',
    height: '20px',
    background: '#BEBEBE',
    transform: 'translateY(-10px)',
  },
};

interface IProps extends IFooterProps {}

const BaseAuditQuesFooter: FC<IProps> = ({
  questionIndex,
  isReadOnly,
  handleDuplicate,
  handleRemove,
  children,
}) => {
  const isDisabled = useFormState<IFormInput>({
    name: 'title',
  }).disabled;
  const { getValues } = useFormContext<IFormInput>();

  const isInteractive = isDisabled || isReadOnly;

  const removeHandler = (
    questionIndex: number,
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => {
    deployEvent(AmplitudeEventNames.DELETE_QUESTION_AUDIT, {
      event: {
        audit_id: getValues('eid'),
        audit_funnel_id: 12,
      },
    });
    handleRemove?.(questionIndex, event);
  };

  return (
    <Flex justify='flex-end' align='center' gap={8}>
      <Flex gap='16px' _notLast={notLastStyle}>
        <CopyIcon
          cursor={!isInteractive ? 'pointer' : 'no-drop'}
          opacity={isDisabled ? 0.5 : 1}
          onClick={(event) =>
            !isInteractive && handleDuplicate(questionIndex, event)
          }
        />
        <DeleteIcon
          cursor={!isInteractive ? 'pointer' : 'no-drop'}
          opacity={isDisabled ? 0.5 : 1}
          onClick={(event) =>
            !isInteractive && removeHandler(questionIndex, event)
          }
        />
      </Flex>

      {children}
    </Flex>
  );
};

export default BaseAuditQuesFooter;
