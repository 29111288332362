import React, { FC, useState } from 'react';
import DetailsScoresFilter from '../../bottomSection/Filter/DetailsScoresFilter';
import DetailsStatusFilter from '../../bottomSection/Filter/DetailsStatusFilter';
import AnalyticsBaseFilter, {
  TAnalyticsFilterClearFilter,
} from './AnalyticsBaseFilter';
import { DetailsFiltersType, IDetailsFilterTypes } from './AnalyticsContainer';

export type DetailsPageNameType = 'base' | 'detailsStatus' | 'detailsScores';

interface IProps {
  clearFilters: (type?: TAnalyticsFilterClearFilter) => void;
  detailsFilters: DetailsFiltersType;
  setDetailsFilters: React.Dispatch<React.SetStateAction<DetailsFiltersType>>;
  handleStatusSelection: (
    e: React.ChangeEvent<HTMLInputElement>,
    status: IDetailsFilterTypes
  ) => void;
  handleScoreSelection: (
    e: React.ChangeEvent<HTMLInputElement>,
    score: IDetailsFilterTypes
  ) => void;
}

const DetailsMainContainer: FC<IProps> = ({
  clearFilters,
  detailsFilters,
  setDetailsFilters,
  handleStatusSelection,
  handleScoreSelection,
}) => {
  const [pageName, setPageName] = useState<DetailsPageNameType>('base');

  if (pageName === 'detailsStatus') {
    return (
      <DetailsStatusFilter
        clearFilters={clearFilters}
        detailsFilters={detailsFilters}
        setDetailsFilters={setDetailsFilters}
        setPageName={setPageName}
        handleStatusSelection={handleStatusSelection}
      />
    );
  }
  if (pageName === 'detailsScores') {
    return (
      <DetailsScoresFilter
        clearFilters={clearFilters}
        detailsFilters={detailsFilters}
        setPageName={setPageName}
        handleScoreSelection={handleScoreSelection}
      />
    );
  }

  return (
    <>
      {
        <AnalyticsBaseFilter
          clearFilters={clearFilters}
          detailsFilters={detailsFilters}
          setPageName={setPageName}
        />
      }
    </>
  );
};

export default DetailsMainContainer;
