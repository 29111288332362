import React, { FC } from 'react';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  Radio,
  Text,
} from '@chakra-ui/react';
import { Controller } from 'react-hook-form';

import DateInput, {
  DateInputButton,
} from '../../../../../../../pages/Locations/Components/DateInput';
import { IDocumentInput } from '../compliance.input';

import { ReactComponent as CalendarIcon } from 'assets/images/calendar.svg';
import DatePickerCustom from 'ui-components/DatePicker/DatePicker';

interface IProps {}

const RemindOnDate: FC<IProps> = () => {
  return (
    <Flex align='center' gap={3}>
      <Radio size='lg' value='on' id='remidn-on' />
      <Controller<IDocumentInput, 'reminder.remindDate'>
        name='reminder.remindDate'
        rules={{
          validate: (value, values) => {
            if (values?.reminder?.reminderType === 'on') {
              return value ? undefined : 'Select reminder date';
            }
          },
        }}
        render={({ field, fieldState }) => (
          <FormControl
            display='flex'
            alignItems='center'
            gap={3}
            isInvalid={fieldState.invalid}
          >
            <Text fontSize='12px' color='#6F767E'>
              On
            </Text>
            <DatePickerCustom
              placeholder={''}
              minDate={new Date()}
              {...field}
              onChange={(date) => {
                field.onChange(date);
              }}
              disableLeftIcon
              rightIcon={<CalendarIcon />}
              colorScheme='gray'
              format='DD-MM-yyyy'
              width='190px'
            />

            <FormErrorMessage mt='unset'>
              {fieldState.error?.message}
            </FormErrorMessage>
          </FormControl>
        )}
      />
    </Flex>
  );
};

RemindOnDate.displayName =
  'displayName:sub-components/nexus/Compliance/Create/components/common/document-reminder/RemindOnDate';

export default RemindOnDate;
