import { Box, Center, VStack } from '@chakra-ui/react';
import { ReactComponent as Crown } from 'assets/images/nexus/crown.svg';
import React from 'react';

interface ScoreCardProps {
  score: number;
  label: string;
  locationId: string;
  position: number;
  hideRank?: boolean;
  isCrown?: boolean;
}

const ScoreCard: React.FC<ScoreCardProps> = ({
  score,
  label,
  locationId,
  position,
  hideRank,
  isCrown,
}) => {
  return (
    <VStack
      borderWidth='2px'
      borderRadius='12px'
      // p='24px'
      py='20px'
      w={isCrown ? '11vw' : '7vw'}
      border={`2px solid ${isCrown ? '#8E59FF' : '#2A85FF'}`}
      bg={isCrown ? 'rgba(142, 89, 255, 0.2)' : 'rgba(42, 133, 255, 0.1)'}
      position='relative'
    >
      {isCrown && <Crown style={{ position: 'absolute', top: '-3.1rem' }} />}
      <Box
        as='span'
        px='24px'
        color={isCrown ? '#8E59FF' : '#2A85FF'}
        fontSize={isCrown ? '32px' : '20px'}
        fontWeight={isCrown ? 600 : 500}
      >
        {score}
      </Box>
      <Box as='span' fontSize='14px' fontWeight={600} isTruncated maxW='100px'>
        {label}
      </Box>
      {!hideRank && (
        <Center
          pos='absolute'
          bottom='-1rem'
          boxSize='32px'
          color='white'
          borderRadius='50%'
          fontWeight={500}
          border='3px solid #F4F4F4'
          bg={isCrown ? '#8E59FF' : '#2A85FF'}
        >
          {position}
        </Center>
      )}
    </VStack>
  );
};
export default ScoreCard;
