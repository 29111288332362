import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, Flex, IconButton, Input } from '@chakra-ui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { DisabledNote } from 'configs';
import { QuestionType } from 'types';

import { Question as QuestionData } from '../../create-form/form.types';
import { useQuestionNotes } from '../../submit-form';
import Options from './Options';
import DropDownChoice from './DropDownChoice';
import MemberChoice from './MemberChoice';
import LocationChoice from './LocationChoice';
import UploadField from './UploadField';
import { FormPreviewInput } from '../form-preview.types';
import FormTextInput from './FormTextInput';
import FormTextAreaInput from './FormTextAreaInput';
import ImageNotesInput from './ImageNotesInput';

interface IProps {
  question: QuestionData;
  currentIndex: number;
  isPreview?: boolean;
  isReadOnly?: boolean;
  isDisabled?: boolean;
}

const Question: FC<IProps> = ({
  question,
  currentIndex,
  isPreview,
  isReadOnly,
  isDisabled,
}) => {
  const { control, getValues, setValue } = useFormContext<FormPreviewInput>();

  const questionNotes = useQuestionNotes();

  const onAddNotes = () => {
    questionNotes({
      note: getValues(`response.${currentIndex}.noteText`),
      onNoteAdd: (newNote) => {
        setValue(`response.${currentIndex}.noteText`, newNote);
      },
    });
  };

  if (question.isArchieved) {
    return null;
  }

  const renderInput = () => {
    switch (question.qType) {
      case QuestionType.LOCATION:
        return (
          <LocationChoice
            isRequired={question.isRequired}
            currentIndex={currentIndex}
            isDisabled={isDisabled}
          />
        );
      case QuestionType.MEMBER:
        return (
          <MemberChoice
            isRequired={question.isRequired}
            currentIndex={currentIndex}
            isDisabled={isDisabled}
          />
        );
      case QuestionType.DROPDOWN:
        return (
          <DropDownChoice
            question={question}
            currentIndex={currentIndex}
            isDisabled={isDisabled}
          />
        );
      case QuestionType.MULTI_CHOICE:
      case QuestionType.CHECKBOX:
        return (
          <Options
            question={question}
            currentIndex={currentIndex}
            isDisabled={isDisabled}
            isReadOnly={isReadOnly}
            isPreview={isPreview}
          />
        );
      case QuestionType.LONG_TEXT:
        return (
          <FormTextAreaInput
            question={question}
            currentIndex={currentIndex}
            isDisabled={isDisabled}
          />
        );
      case QuestionType.VIDEO_UPLOAD:
      case QuestionType.IMAGE_UPLOAD:
      case QuestionType.FILE_UPLOAD:
        return (
          <UploadField
            question={question}
            currentIndex={currentIndex}
            isPreview={isPreview}
            isDisabled={isDisabled}
          />
        );
      default:
        return (
          <FormTextInput
            question={question}
            currentIndex={currentIndex}
            isReadOnly={isReadOnly}
            isDisabled={isDisabled}
          />
        );
    }
  };

  return (
    <div className='custom-form-preview-question-con'>
      <Controller
        name={`response.${currentIndex}.qid`}
        control={control}
        defaultValue={question.eid}
        render={({ field }) => {
          return <Input size='lg' {...field} hidden />;
        }}
      />

      <div className='custom-form-preview-question-label-container'>
        <Box flex={1}>
          <div className='custom-form-preview-question-label'>
            {question.label}
            {question.isRequired ? (
              <span style={{ color: 'red' }}> *</span>
            ) : null}
          </div>
          <div className='custom-form-preview-question-sub-title'>
            {question.subTitle}
          </div>
        </Box>

        <Controller
          name={`response.${currentIndex}.noteText`}
          control={control}
          defaultValue=''
          render={({ field }) => {
            if (
              !field.value ||
              DisabledNote.includes(question.qType) ||
              isDisabled ||
              isPreview ||
              isReadOnly
            ) {
              return <React.Fragment />;
            }
            return (
              <IconButton
                size='sm'
                variant='ghost'
                aria-label='note'
                icon={
                  <FontAwesomeIcon
                    icon={faPenToSquare as IconProp}
                    color='#6F767E'
                  />
                }
                onClick={onAddNotes}
              />
            );
          }}
        />
      </div>

      <div className={`custom-form-${question.qType}`}>{renderInput()}</div>
      <Controller
        name={`response.${currentIndex}.noteText`}
        control={control}
        defaultValue=''
        render={({ field }) => {
          if (
            !field.value ||
            DisabledNote.includes(question.qType) ||
            isDisabled ||
            isPreview ||
            isReadOnly
          ) {
            return <React.Fragment />;
          }
          return (
            <Flex
              mt={4}
              gap={2}
              borderRadius='10px'
              border='1px solid #EFEFEF'
              p='10px 2px 10px 10px'
              bg='#F4F4F4'
            >
              <Box
                flex={1}
                color='#6F767E'
                fontSize='13px'
                whiteSpace='pre-line'
                lineHeight='unset !important'
              >
                {field.value}
              </Box>
              <IconButton
                size='sm'
                aria-label='note'
                mt='-8px'
                icon={
                  <FontAwesomeIcon
                    icon={faPenToSquare as IconProp}
                    color='#6F767E'
                  />
                }
                onClick={onAddNotes}
              />
            </Flex>
          );
        }}
      />
      <ImageNotesInput question={question} />
    </div>
  );
};

export default Question;
