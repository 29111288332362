import React, { FC } from 'react';
import { Box, Center, Checkbox, Flex } from '@chakra-ui/react';
import { UserEntityData } from '../../../../../../../shared/graphql/shared-types';

import { ReactComponent as RocketIcon } from 'assets/images/rocket.svg';
import { ReactComponent as ZapIcon } from 'assets/images/zap-light.svg';

interface IProps {
  item: UserEntityData;
}

const SupervisorItem: FC<IProps> = ({ item }) => {
  const location = item?.locations?.[0];
  const isInDev = location?.locationStatus === 'development';
  const Icon = isInDev ? RocketIcon : ZapIcon;

  return (
    <Flex
      gap={3}
      height='48px'
      minHeight='48px'
      alignItems='center'
      paddingInline='12px'
      borderRadius='12px'
      userSelect='none'
      // cursor='pointer'
      _hover={{ bg: '#F4F4F3' }}
    >
      <Box
        flex={3}
        fontSize='15px'
        fontWeight='600'
        isTruncated
        color='#33383F'
      >
        {item.name}
      </Box>
      <Box
        flex={2}
        fontSize='13px'
        fontWeight='500'
        isTruncated
        color='#6F767E'
        textTransform='capitalize'
      >
        {item.authRole?.split(/(?=[A-Z])/).join(' ')}
      </Box>
      <Flex flex={3} gap={2} overflow='hidden'>
        <Center
          boxSize='20px'
          borderRadius='5px'
          bg={isInDev ? '#8E59FF' : '#83BF6E'}
        >
          <Icon width={12} height={12} />
        </Center>
        <Box
          flex={1}
          fontSize='13px'
          fontWeight='600'
          isTruncated
          color='#33383F'
        >
          {location?.name || '--'}
        </Box>
      </Flex>
      <Checkbox
        ml={4}
        // @ts-ignore
        isReadOnly={item.isSelected}
        value={item.eid}
        size='lg'
      />
    </Flex>
  );
};

export default SupervisorItem;
