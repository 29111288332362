import React, { forwardRef, useMemo } from 'react';
import { useControllableState } from '@chakra-ui/react';
import { Dropdown } from '../../../../../../atoms';

const options = [
  {
    label: 'Existing members only',
    value: 'old',
  },
  {
    label: 'New members only',
    value: 'new',
  },
  {
    label: 'Everyone (Existing and new)',
    value: 'oldAndNew',
  },
];

interface IProps {
  value?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
}

const AssigneeTypeSelect = forwardRef<never, IProps>(
  ({ value, onChange, disabled }, ref) => {
    const [internalValue, updateValue] = useControllableState<string>({
      value: value,
      onChange: onChange,
    });

    const selectedValue = useMemo(() => {
      return options.find((op) => op.value === internalValue);
    }, [internalValue]);

    return (
      <Dropdown
        ref={ref}
        size='lg'
        value={selectedValue}
        onChange={(newValue) => updateValue(newValue?.value)}
        options={options}
        disabled={disabled}
        selectStyles={{
          container: {
            background: 'transparent',
          },
          control: {
            border: '2px solid',
          },
          menuList: {
            padding: '16px',
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
          },
          option: {
            fontSize: '15px',
            fontWeight: 600,
            padding: '12px',
            borderRadius: '10px',
          },
          singleValue: {
            fontSize: '15px',
            fontWeight: 600,
            color: '#6F767E',
          },
        }}
      />
    );
  }
);

AssigneeTypeSelect.displayName = 'AssigneeTypeSelect';

export default AssigneeTypeSelect;
