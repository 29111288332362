import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { ErrorOption } from 'react-hook-form';
import _uniqueId from 'lodash/uniqueId';
import { useOnImageChange } from '../../../../../../../../hooks';
import ThumbnailLoading from './ThumbnailLoading';
import ThumbnailView from './ThumbnailView';
import ThumbnailSelect from './ThumbnailSelect';

interface IProps {
  boxSize: number;
  value?: string;
  onChange?: (value: string) => void;
  onError?: (error: ErrorOption) => void;
}

const ThumbnailInput = forwardRef<never, IProps>(
  ({ boxSize, value, onError, onChange }, ref) => {
    const [inputId] = useState(_uniqueId('quiz-thumbnail-'));

    useImperativeHandle(ref, () => null, []);

    const { onImageChange, thumbnail, inputRef } = useOnImageChange({
      onError: onError,
      onChange: onChange,
    });

    if (thumbnail) {
      return <ThumbnailLoading thumbnail={thumbnail} boxSize={boxSize} />;
    }

    if (value) {
      return (
        <ThumbnailView
          ref={inputRef}
          value={value}
          boxSize={boxSize}
          inputId={inputId}
          onImageChange={onImageChange}
        />
      );
    }

    return (
      <ThumbnailSelect
        ref={inputRef}
        inputId={inputId}
        onImageChange={onImageChange}
      />
    );
  }
);

ThumbnailInput.displayName = 'ThumbnailInput';

export default ThumbnailInput;
