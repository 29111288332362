import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { ReactComponent as Sort_ascendingIcon } from 'assets/images/nexus/compliance/sort_ascending.svg';
import { FC, useState } from 'react';
import EmptyLeaderboardState from '../../EmptyLeaderboardState';
import {
  TpOverallLocationsWiseAnalytics,
  TpOverallUserWiseAnalytics,
} from '../../Tracking/tracking-types';
import { calcAuthRole } from '../helpers';
import locationlaunchicon from '../../../../../../assets/images/training/LocationLaunchIcon.svg';
import locationopenicon from '../../../../../../assets/images/training/LocationOpenIcon.svg';
import { Image } from 'ui-components';

export const RenderMemberList = ({
  tpOverallUserWiseAnalytics,
}: {
  tpOverallUserWiseAnalytics: TpOverallUserWiseAnalytics[];
}) => {
  return (
    <>
      {tpOverallUserWiseAnalytics?.map((item, index) => {
        return (
          // @ts-ignore
          <Flex
            key={index}
            border={'0.5px solid #EFEFEF'}
            p={'12px 24px'}
            borderRadius={'12px'}
            backgroundColor={'white'}
          >
            <Flex
              width={'100%'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Flex alignItems={'center'} gap={4}>
                <Flex
                  justifyContent={'center'}
                  width={'24px'}
                  height={'24px'}
                  borderRadius={'50%'}
                  backgroundColor={'#6F767E'}
                >
                  <Text fontWeight={600} color={'white'}>
                    {item?.rank}
                  </Text>
                </Flex>
                <Flex flexDir={'column'}>
                  <Text fontWeight={600} fontSize={'13px'}>
                    {item.userName}
                  </Text>
                  {item.locations.length > 0 && (
                    <Flex
                      gap={1}
                      alignItems={'center'}
                      fontWeight={400}
                      fontSize={'12px'}
                      color={'#6F767E'}
                    >
                      <Flex alignItems='center' gap={1}>
                        {item.locations[0]?.locationStatus === 'open' ? (
                          <Image
                            src={locationopenicon}
                            alt='Open Location'
                            width={16}
                            height={16}
                          />
                        ) : (
                          <Image
                            src={locationlaunchicon}
                            alt='Launch Location'
                            width={16}
                            height={16}
                          />
                        )}
                        <Text>{item.locations[0]?.name}</Text>
                      </Flex>

                      {/* If there are more than one location, show the rest in a tooltip */}
                      {item.locations.length > 1 && (
                        <Tooltip
                          label={
                            <Box>
                              {item.locations.slice(1).map((loc, index) => (
                                <Flex key={index} alignItems='center' gap={1}>
                                  {loc?.locationStatus === 'open' ? (
                                    <Image
                                      src={locationopenicon}
                                      alt='Open Location'
                                      width={16}
                                      height={16}
                                    />
                                  ) : (
                                    <Image
                                      src={locationlaunchicon}
                                      alt='Launch Location'
                                      width={16}
                                      height={16}
                                    />
                                  )}
                                  <Text>{loc?.name}</Text>
                                </Flex>
                              ))}
                            </Box>
                          }
                        >
                          <Text fontWeight={600} color={'black'} p={'2px'}>
                            +{item.locations.length - 1}
                          </Text>
                        </Tooltip>
                      )}
                      <Text
                        fontWeight={400}
                        fontSize={'12px'}
                        color={'#6F767E'}
                        marginTop='-3px'
                        textTransform='capitalize'
                      >
                        • {calcAuthRole(item.role)}
                      </Text>
                    </Flex>
                  )}
                </Flex>
              </Flex>
              <Flex>
                <Text fontWeight={600} fontSize={'13px'}>
                  {`${item.completionRate}`}
                </Text>
                <Text color={'#6F767E'}>%</Text>
              </Flex>
            </Flex>
          </Flex>
        );
      })}
    </>
  );
};

export const RenderLocationList = ({
  tpOverallLocationsWiseAnalytics,
}: {
  tpOverallLocationsWiseAnalytics: TpOverallLocationsWiseAnalytics[];
}) => {
  return (
    <>
      {tpOverallLocationsWiseAnalytics?.map((item, index) => {
        return (
          // @ts-ignore
          <Flex
            key={index}
            border={'0.5px solid #EFEFEF'}
            p={'12px 24px'}
            borderRadius={'12px'}
            backgroundColor={'white'}
          >
            <Flex
              width={'100%'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Flex alignItems={'center'} gap={4}>
                <Flex
                  justifyContent={'center'}
                  width={'24px'}
                  height={'24px'}
                  borderRadius={'50%'}
                  backgroundColor={'#6F767E'}
                >
                  <Text fontWeight={600} color={'white'}>
                    {item?.rank}
                  </Text>
                </Flex>
                <Flex alignItems='center' gap={1}>
                  {item?.status === 'open' ? (
                    <Image
                      src={locationopenicon}
                      alt='Open Location'
                      width={16}
                      height={16}
                    />
                  ) : (
                    <Image
                      src={locationlaunchicon}
                      alt='Launch Location'
                      width={16}
                      height={16}
                    />
                  )}
                  <Text fontSize={'13px'} fontWeight={600}>
                    {item.locationName}
                  </Text>
                </Flex>
              </Flex>
              <Text fontSize={'13px'} fontWeight={600} display={'flex'}>
                {`${item.completionRate}`}
                <span
                  style={{
                    fontSize: '12px',
                    fontWeight: 400,
                    color: '#6F767E',
                  }}
                >
                  %
                </span>
              </Text>
            </Flex>
          </Flex>
        );
      })}
    </>
  );
};

interface IProps {
  tab: string;
  data: TpOverallLocationsWiseAnalytics[] | TpOverallUserWiseAnalytics[];
}

const BaseListRender: FC<IProps> = ({ tab, data }) => {
  const [sortType, setSortType] = useState<'asc' | 'desc'>('desc');

  const sortedDataList = () => {
    let dataList = [...data];
    return sortType === 'asc' ? dataList.reverse() : dataList;
  };

  const handleSortToggle = () => {
    setSortType((prevState) => {
      if (prevState === 'asc') {
        return 'desc';
      } else {
        return 'asc';
      }
    });
  };

  if (data?.length === 0) {
    return (
      <EmptyLeaderboardState
        emptyStateLabel={
          tab === 'Locations'
            ? 'Add more locations to compare their performance!'
            : 'Add more members to compare their performance!'
        }
      />
    );
  }

  return (
    <Flex
      flexDir={'column'}
      width={'100%'}
      p={'16px 8px'}
      borderRadius={'12px'}
      backgroundColor={'#EFEFEF'}
      overflow={'clip'}
    >
      <Flex color={'#6F767E'} px={1} justifyContent={'space-between'}>
        <Text>{tab}</Text>
        <Flex gap={2} alignItems={'center'}>
          <Text>Completion rate</Text>
          <Box cursor={'pointer'} onClick={handleSortToggle}>
            <Sort_ascendingIcon />
          </Box>
        </Flex>
      </Flex>

      <Flex
        maxHeight={'300px'}
        overflowY={'clip'}
        gap={2}
        flexDir={'column'}
        mt={'10px'}
      >
        {tab === 'Locations' && (
          <RenderLocationList
            tpOverallLocationsWiseAnalytics={
              sortedDataList() as TpOverallLocationsWiseAnalytics[]
            }
          />
        )}
        {tab === 'Members' && (
          <RenderMemberList
            tpOverallUserWiseAnalytics={
              sortedDataList() as TpOverallUserWiseAnalytics[]
            }
          />
        )}
      </Flex>
    </Flex>
  );
};

export default BaseListRender;
