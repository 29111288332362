import React, { FC } from 'react';
import { Center, Flex } from '@chakra-ui/react';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripDotsVertical } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import ContentItem, { ContentItemProps } from './ContentItem';

interface IProps extends ContentItemProps {}

const DraggableContentItem: FC<IProps> = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef,
  } = useSortable({ id: props.data.id, data: { index: props.index } });

  return (
    <Flex
      gap={4}
      _hover={{
        zIndex: 2,
        '.content-item': {
          borderColor: '#EFEFEF',
          backgroundColor: '#FCFCFC',
          button: { display: 'inline-flex' },
        },
        '>div[role="button"]': {
          visibility: 'visible',
          opacity: 1,
        },
      }}
      ref={setNodeRef}
      transform={CSS.Translate.toString(transform)}
      // transition={transition}
    >
      <Center
        alignSelf='center'
        visibility='hidden'
        opacity={0}
        {...attributes}
        {...listeners}
        ref={setActivatorNodeRef}
        boxSize={6}
        _disabled={{
          opacity: '0.4 !important',
          cursor: 'not-allowed',
        }}
      >
        <FontAwesomeIcon
          icon={faGripDotsVertical as IconProp}
          fontSize='20px'
          color='#6F767E'
        />
      </Center>

      <ContentItem {...props} />
    </Flex>
  );
};

export default DraggableContentItem;
