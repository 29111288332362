import { ConfigProvider, Table } from 'antd';
import EmptyState from './EmptyState';
import React, { FC, SetStateAction, useState } from 'react';
import {
  ITPCategory,
  ITPFilter,
  ITrainingDraftData,
  TPFiltersParams,
} from 'sub-components/training-v2/shared/types';
import Loader from 'sub-components/Loader';
import { Flex, Text, Box, Image, Button, Tooltip } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowDownShortWide,
  faArrowDownToLine,
  faEllipsis,
} from '@fortawesome/pro-light-svg-icons';
import { getImage } from 'utils';
import { ITrackingTPPagination } from '../TrackPathDashboard/bottomSection/PathTable';
import { ActionMenu } from 'ui-components';
import { ReactComponent as EditiconIcon } from 'assets/images/training/edit.svg';
import { ReactComponent as TrashIcon } from 'assets/images/trash.svg';
import { getCategoriesColorCode } from '../TrackPathDashboard/bottomSection/helper';
import moment from 'moment';
import { OperationVariables, QueryLazyOptions } from '@apollo/client';
import { ISortType } from '../DraftContainer';
import { useDeleteDraftTP } from './useDeleteDraftTP';
import { useHistory } from 'routes';
import { TRAINING_PATH_V2_EDIT } from '../../../../../appRoutes';

interface IProps {
  searchQuery: string;
  trackingTpPaginationData: ITrackingTPPagination[];
  trackingTpPaginationLoader: boolean;
  trainingCategoriesLoader: boolean;
  trainingPathItemCount: number;
  trainingCategories: ITPCategory[];
  getTrackingTpPaginationData: (
    options?: QueryLazyOptions<OperationVariables> | undefined
  ) => void;
  getTrackingTPFilters:
    | (({ pageIndex, sort }: TPFiltersParams) => ITPFilter | undefined)
    | undefined;
  setSortType: React.Dispatch<SetStateAction<ISortType>>;
  sortType: ISortType;
  setSelectedPageIndex: React.Dispatch<SetStateAction<number>>;
  selectedPageIndex: number;
}

const DraftTable: FC<IProps> = ({
  searchQuery,
  trackingTpPaginationData,
  trackingTpPaginationLoader,
  trainingCategoriesLoader,
  trainingPathItemCount,
  trainingCategories,
  getTrackingTpPaginationData,
  getTrackingTPFilters,
  setSortType,
  sortType,
  setSelectedPageIndex,
  selectedPageIndex,
}) => {
  const history = useHistory();
  const deleteDraftTp = useDeleteDraftTP();
  const categoriesColorCode = getCategoriesColorCode(trainingCategories);

  const columns = [
    {
      title: (
        <Flex alignItems={'center'} gap={2}>
          <Text fontWeight={500} color={'#6F767E'}>
            Path Name
          </Text>
          <Box
            onClick={() =>
              setSortType(
                (prevState: { key: string; sort: string; order: string }) => {
                  const sortObj = {
                    key: 'pathName',
                    sort: '',
                    order: '',
                  };
                  if (prevState?.order === 'asc') {
                    sortObj.order = 'desc';
                    sortObj.sort = 'TITLE_DESC';
                  } else if (prevState?.order === 'desc') {
                    sortObj.order = 'none';
                    sortObj.sort = 'CREATEDAT_DESC';
                  } else if (prevState?.order === 'none') {
                    sortObj.order = 'asc';
                    sortObj.sort = 'TITLE_ASC';
                  }
                  return sortObj;
                }
              )
            }
          >
            <FontAwesomeIcon
              color={
                sortType.key === 'pathName' && sortType.order !== 'none'
                  ? '#2A85FF'
                  : '#6F767E'
              }
              cursor={'pointer'}
              icon={faArrowDownShortWide as IconProp}
            />
          </Box>
        </Flex>
      ),
      dataIndex: 'pathName',
      key: 'pathName',
      render: (text: string, record: any) => {
        return (
          <Flex align='center' gap={3}>
            <Image
              borderRadius='8px'
              height='62px'
              width='62px'
              src={getImage(record?.thumbnail, text)}
            />

            <Flex direction={'column'} gap={0}>
              <Tooltip label={text}>
                <Box
                  as={'span'}
                  isTruncated
                  maxW='150px'
                  fontSize='15px'
                  fontWeight='600'
                >
                  {text}
                </Box>
              </Tooltip>
            </Flex>
          </Flex>
        );
      },
    },
    {
      title: (
        <Flex alignItems={'center'} gap={2}>
          <Text fontWeight={500} color={'#6F767E'}>
            Category
          </Text>
          {/* <Box>
            <FontAwesomeIcon
              color={'#6F767E'}
              cursor={'pointer'}
              icon={faArrowDownShortWide as IconProp}
            />
          </Box> */}
        </Flex>
      ),
      dataIndex: 'category',
      key: 'category',
      render: (_, record) => (
        <Flex alignItems={'center'} gap={2}>
          <Flex
            minW={'70px'}
            borderRadius={'6px'}
            p={'2px 10px'}
            alignItems={'center'}
            backgroundColor={categoriesColorCode?.[record?.category?.eid]?.bg}
          >
            <Tooltip label={record?.category?.name}>
              <Text
                isTruncated
                maxW={'150px'}
                color={categoriesColorCode?.[record?.category?.eid]?.color}
              >
                {record?.category?.name}
              </Text>
            </Tooltip>
          </Flex>
        </Flex>
      ),
    },
    {
      title: (
        <Flex alignItems={'center'} gap={2}>
          <Text fontWeight={500} color={'#6F767E'}>
            Created on
          </Text>
          <Box
            onClick={() =>
              setSortType((prevState) => {
                const sortObj = {
                  key: 'createdOn',
                  sort: '',
                  order: '',
                };
                if (prevState?.order === 'asc') {
                  sortObj.order = 'desc';
                  sortObj.sort = 'CREATEDAT_DESC';
                } else if (prevState?.order === 'desc') {
                  sortObj.order = 'none';
                  sortObj.sort = 'CREATEDAT_DESC';
                } else if (prevState?.order === 'none') {
                  sortObj.order = 'asc';
                  sortObj.sort = 'CREATEDAT_ASC';
                }
                return sortObj;
              })
            }
          >
            <FontAwesomeIcon
              color={
                sortType.key === 'createdOn' && sortType.order !== 'none'
                  ? '#2A85FF'
                  : '#6F767E'
              }
              cursor={'pointer'}
              icon={faArrowDownShortWide as IconProp}
            />
          </Box>
        </Flex>
      ),
      dataIndex: 'createdOn',
      key: 'createdOn',
      render: (_, record) => (
        <Text fontSize={'14px'} fontWeight={500} color={'#272B30'}>
          {moment(record.createdOn).format('DD MMM YYYY')}
        </Text>
      ),
    },
    {
      title: (
        <Flex alignItems={'center'} gap={2}>
          <Text fontWeight={500} color={'#6F767E'}>
            Last activity
          </Text>
          <Box
            onClick={() =>
              setSortType((prevState) => {
                const sortObj = {
                  key: 'lastActivity',
                  sort: '',
                  order: '',
                };
                if (prevState?.order === 'asc') {
                  sortObj.order = 'desc';
                  sortObj.sort = 'UPDATEDAT_DESC';
                } else if (prevState?.order === 'desc') {
                  sortObj.order = 'none';
                  sortObj.sort = 'CREATEDAT_DESC';
                } else if (prevState?.order === 'none') {
                  sortObj.order = 'asc';
                  sortObj.sort = 'UPDATEDAT_ASC';
                }
                return sortObj;
              })
            }
          >
            <FontAwesomeIcon
              color={
                sortType.key === 'lastActivity' && sortType.order !== 'none'
                  ? '#2A85FF'
                  : '#6F767E'
              }
              cursor={'pointer'}
              icon={faArrowDownShortWide as IconProp}
            />
          </Box>
        </Flex>
      ),
      dataIndex: 'lastUpdated',
      key: 'lastUpdated',
      render: (_, record) => (
        <Flex gap={4}>
          <Text fontSize={'14px'} fontWeight={500} color={'#272B30'}>
            {moment(record.lastUpdated).format('DD MMM YYYY')}
          </Text>
          <Text fontSize={'12px'} fontWeight={500} color={'#6F767E'}>
            {moment(record.lastUpdated).format('hh:mm A')}
          </Text>
        </Flex>
      ),
    },
    {
      title: <Text fontWeight={500} color={'#6F767E'}></Text>,
      key: 'action',
      width: 50,
      render: (_: any, record: any) => {
        return (
          <Box minWidth='50px' textAlign='center'>
            <ActionMenu
              strategy='fixed'
              menuData={[
                {
                  name: 'Edit',
                  value: 'edit',
                  icon: <EditiconIcon />,
                  normalIcon: 'chakraUIIcon',
                  textColor: '#6F767E',
                },
                {
                  name: 'Delete',
                  value: 'delete',
                  icon: <TrashIcon />,
                  normalIcon: 'chakraUIIcon',
                  textColor: 'red',
                },
              ]}
              arrowSize={10}
              closeOnBlur={true}
              offset={[0, 0]}
              clickedItem={(_, clickedItem) => {
                if (clickedItem === 'edit') {
                  return history.push({
                    pathname: TRAINING_PATH_V2_EDIT,
                    params: {
                      trainingId: record?.key,
                    },
                  });
                }
                if (clickedItem === 'delete') {
                  return deleteDraftTp({
                    trainingId: record?.key,
                    cb: () =>
                      getTrackingTpPaginationData({
                        variables: getTrackingTPFilters?.({
                          pageIndex: selectedPageIndex,
                        }),
                      }),
                  });
                }
              }}
            >
              <Box cursor='pointer'>
                <FontAwesomeIcon
                  icon={faEllipsis as IconProp}
                  size='2x'
                  color='#33383F'
                />
              </Box>
            </ActionMenu>
          </Box>
        );
      },
    },
  ];

  return (
    <Flex>
      <ConfigProvider
        renderEmpty={() => <EmptyState searchQuery={searchQuery} />}
      >
        <Table<ITrainingDraftData>
          style={{ width: '100%' }}
          loading={
            (trackingTpPaginationLoader || trainingCategoriesLoader) && {
              indicator: <Loader />,
            }
          }
          columns={columns}
          dataSource={trackingTpPaginationData}
          pagination={{
            total: trainingPathItemCount,
            pageSize: 10,
            hideOnSinglePage: true,
            onChange: (page) => {
              setSelectedPageIndex(page);
            },
          }}
        />
      </ConfigProvider>
    </Flex>
  );
};

export default DraftTable;
