import React, { FC } from 'react';
import { Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { useFieldArray, useFormContext, useFormState } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { ListSortable } from 'ui-components';
import { LauncherTaskStep } from '../../launcher-config.fragment';
import { ITaskInput } from '../task-form.types';
import AddStepForm from './AddStepForm';
import StepItem from './StepItem';
import {
  ResourceAction,
  useChapterResource,
  useFormResource,
  useTrainingResource,
} from './resource';

const mapId = (data: LauncherTaskStep) => {
  return data.stepId;
};

interface IProps {}

const StepDetailsForm: FC<IProps> = () => {
  const openFormModal = useFormResource();
  const openChapterModal = useChapterResource();
  const openTrainingModal = useTrainingResource();

  const { getValues } = useFormContext<ITaskInput>();

  const { fields, append, remove, update, move } = useFieldArray<
    ITaskInput,
    'steps'
  >({
    name: 'steps',
    // rules: {
    //   required: t('task:validation.taskStepRequired'),
    // },
  });

  const contentError = useFormState<ITaskInput>({
    // control: control,
    name: 'steps',
    exact: true,
  }).errors?.steps;

  const onFormAddClick = () => {
    openFormModal({
      values: getValues('steps').map(mapId),
      onAddClick: (values) => {
        append(
          values.map((value) => ({
            type: 'form',
            formId: value.eid,
            title: value.title,
            stepId: value.eid,
            trainingId: null,
            sopId: null,
          }))
        );
      },
    });
  };

  const onChapterAddClick = () => {
    openChapterModal({
      values: getValues('steps').map(mapId),
      onAddClick: (values) => {
        append(
          values.map((value) => ({
            type: 'sop',
            sopId: value.eid,
            title: value.title,
            stepId: value.eid,
            trainingId: null,
            formId: null,
          }))
        );
      },
    });
  };

  const onTrainingAddClick = () => {
    openTrainingModal({
      values: getValues('steps').map(mapId),
      onAddClick: (values) => {
        append(
          values.map((value) => ({
            type: 'training',
            trainingId: value.eid,
            title: value.title,
            stepId: value.eid,

            formId: null,
            sopId: null,
          }))
        );
      },
    });
  };

  const resourceHandler = (action: ResourceAction) => {
    switch (action) {
      case ResourceAction.ADD_FORM:
        return onFormAddClick();
      case ResourceAction.ADD_CHAPTER:
        return onChapterAddClick();
      case ResourceAction.ADD_TRAINING:
        return onTrainingAddClick();
    }
  };

  const onUpdateClick = (value: LauncherTaskStep, index: number) => {
    update(index, value);
  };

  const onRemoveClick = (_: unknown, index: number) => {
    remove(index);
  };

  return (
    <div>
      <AddStepForm
        onAddClick={(newValue) => {
          append({
            type: 'text',
            stepId: uuidv4(),
            title: newValue,
          } as never);
        }}
        resourceHandler={resourceHandler}
      />

      <FormControl isInvalid={!!contentError}>
        <Flex flexDir='column' gap='6px' pt={fields?.length ? 2 : 0}>
          <ListSortable
            items={fields}
            onDragEnd={(oldIndex, newIndex) => move(oldIndex, newIndex)}
          >
            {fields?.map((value, index) => (
              <StepItem
                key={value.id}
                fieldId={value.id}
                index={index}
                data={value}
                onDelete={onRemoveClick}
                onUpdate={onUpdateClick}
              />
            ))}
          </ListSortable>
        </Flex>

        <FormErrorMessage>
          {contentError?.root?.message || contentError?.message}
        </FormErrorMessage>
      </FormControl>
    </div>
  );
};

StepDetailsForm.displayName =
  'sub-components/Launcher/launcher-config/common/config-task-form/task-steps/StepDetailsForm';

export default StepDetailsForm;
