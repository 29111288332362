import React, { FC } from 'react';
import { Box, Flex, Tooltip } from '@chakra-ui/react';
import { BaseResourceItem } from '../common';
import { SopResourceItem } from './sop-resource.graphql';
import { useSopFolderMap } from './useSopFolderMap';

interface IProps {
  data: SopResourceItem;
  onClick?: () => void;
}

const SopItem: FC<IProps> = ({ data, onClick }) => {
  const categories = useSopFolderMap(data.categoryId);

  return (
    <BaseResourceItem
      data={data}
      onClick={onClick}
      visibilityCopy='This chapter has limited visibility'
    >
      <Flex
        flex={1}
        flexDir='column'
        gap={1}
        overflow='hidden'
        userSelect='none'
      >
        <Flex
          gap={1}
          fontSize='12px'
          color='#6F767E'
          align='center'
          maxWidth='max-content'
          isTruncated
        >
          {categories.map((category, index) => (
            <React.Fragment key={category.eid}>
              {index === 0 ? null : (
                <Box
                  as='span'
                  boxSize='2px'
                  minWidth='2px'
                  bgColor='#1A1D1F'
                  borderRadius='full'
                />
              )}

              {category.name}
            </React.Fragment>
          ))}
        </Flex>

        <Tooltip
          hasArrow
          padding='6px 10px'
          bgColor='#000000BD'
          label={data.title}
          isDisabled={data.title?.length < 40}
        >
          <Box
            fontSize='15px'
            fontWeight='600'
            color='#33383F'
            lineHeight='24px'
            maxWidth='max-content'
            isTruncated
          >
            {data.title}
          </Box>
        </Tooltip>
      </Flex>
    </BaseResourceItem>
  );
};

export default SopItem;
