import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { SelectOption } from '../../../../../../atoms';
import { TFormInput } from '../../training-input.types';

export const useDeadlineOptions = (): SelectOption[] => {
  const [assigneeType, frequency] = useWatch<
    TFormInput,
    ['assigneeUserType', 'frequency']
  >({
    name: ['assigneeUserType', 'frequency'],
  });

  return useMemo(() => {
    if (frequency === 'once' && assigneeType === 'old') {
      return [
        {
          label: 'No deadline',
          value: 'noDeadline',
        },
        {
          label: 'Custom end date',
          value: 'date',
        },
        {
          label: 'Custom duration',
          value: 'duration',
        },
      ];
    }

    return [
      {
        label: 'No deadline',
        value: 'noDeadline',
      },
      {
        label: 'Custom duration',
        value: 'duration',
      },
    ];
  }, [frequency, assigneeType]);
};

export const useAssignOptions = (): SelectOption[] => {
  const [assigneeType, frequency, deadlineType] = useWatch<
    TFormInput,
    ['assigneeUserType', 'frequency', 'deadlineType']
  >({
    name: ['assigneeUserType', 'frequency', 'deadlineType'],
  });

  return useMemo(() => {
    // Return different options based on the combination of assigneeType, frequency, and deadlineType
    if (assigneeType === 'old') {
      if (frequency === 'once') {
        if (deadlineType === 'noDeadline') {
          return [
            { label: 'Now', value: 'now' },
            { label: 'Custom start date', value: 'date' },
            { label: 'Completion of another path', value: 'training' },
          ];
        } else if (deadlineType === 'date') {
          return [
            { label: 'Now', value: 'now' },
            { label: 'Custom start date', value: 'date' },
          ];
        } else if (deadlineType === 'duration') {
          return [
            { label: 'Now', value: 'now' },
            { label: 'Completion of another path', value: 'training' },
          ];
        }
      } else if (frequency === 'repeat') {
        if (deadlineType === 'noDeadline') {
          return [
            { label: 'Now', value: 'now' },
            { label: 'Completion of another path', value: 'training' },
          ];
        } else if (deadlineType === 'date') {
          return [];
        } else if (deadlineType === 'duration') {
          return [
            { label: 'Now', value: 'now' },
            { label: 'Completion of another path', value: 'training' },
          ];
        }
      }
    } else if (assigneeType === 'new') {
      if (frequency === 'once') {
        if (deadlineType === 'noDeadline') {
          return [
            { label: 'Now', value: 'now' },
            { label: 'Completion of another path', value: 'training' },
          ];
        } else if (deadlineType === 'date') {
          return [];
        } else if (deadlineType === 'duration') {
          return [
            { label: 'Now', value: 'now' },
            { label: 'Completion of another path', value: 'training' },
          ];
        }
      } else if (frequency === 'repeat') {
        if (deadlineType === 'noDeadline') {
          return [
            { label: 'Now', value: 'now' },
            { label: 'Completion of another path', value: 'training' },
          ];
        } else if (deadlineType === 'date') {
          return [];
        } else if (deadlineType === 'duration') {
          return [
            { label: 'Now', value: 'now' },
            { label: 'Completion of another path', value: 'training' },
          ];
        }
      }
    } else if (assigneeType === 'oldAndNew') {
      if (frequency === 'once') {
        if (deadlineType === 'noDeadline') {
          return [
            { label: 'Now', value: 'now' },
            { label: 'Completion of another path', value: 'training' },
          ];
        } else if (deadlineType === 'date') {
          return [];
        } else if (deadlineType === 'duration') {
          return [
            { label: 'Now', value: 'now' },
            { label: 'Completion of another path', value: 'training' },
          ];
        }
      } else if (frequency === 'repeat') {
        if (deadlineType === 'noDeadline') {
          return [
            { label: 'Now', value: 'now' },
            { label: 'Completion of another path', value: 'training' },
          ];
        } else if (deadlineType === 'date') {
          return [];
        } else if (deadlineType === 'duration') {
          return [
            { label: 'Now', value: 'now' },
            { label: 'Completion of another path', value: 'training' },
          ];
        }
      }
    }

    // Default case, if no conditions match (though should not occur due to tests)
    return [];
  }, [assigneeType, frequency, deadlineType]);
};
