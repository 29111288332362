import React, { FC, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  CloseButton,
  FormControl,
  FormErrorMessage,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { SmallCloseIcon } from '@chakra-ui/icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { Input } from '../../../../../../atoms';
import { ActionButton } from '../../../../../../ui-components';
import { useTrainingCategories } from './useTrainingCategories';

interface IProps {
  onAddClick?: (value: string) => void;
  duplicateCheck?: (value: string) => boolean;
  onCloseClick?: () => void;
}

const AddCategoryInput: FC<IProps> = ({ onAddClick, onCloseClick }) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const methods = useForm<{ category: string }>({
    defaultValues: { category: '' },
  });
  const { categories } = useTrainingCategories();

  const duplicateCheck = (value: string) => {
    return categories.find((i) => i.name?.trim() === value);
  };

  return (
    <Controller
      control={methods.control}
      name='category'
      rules={{
        required: 'Please enter category name',
        validate: (value) => {
          if (!value?.trim()) {
            return 'Enter valid category name';
          }
          if (value?.length > 32) {
            return 'Category name cannot exceed 32 characters.';
          }
          if (duplicateCheck?.(value?.trim())) {
            return 'Category name already exists';
          }
        },
      }}
      render={({ field, fieldState }) => (
        <FormControl p='2px' pt='10px' pr='12px' isInvalid={fieldState.invalid}>
          <InputGroup position='relative' size='lg'>
            <Input
              variant='auditOutline'
              {...field}
              placeholder='Type new category here'
              onKeyDown={(event) => {
                if (event.code === 'Enter') {
                  event.preventDefault();
                  event.stopPropagation();
                  buttonRef.current?.click();
                }
              }}
            />

            <InputRightElement width={12}>
              <ActionButton
                ref={buttonRef}
                padding={0}
                variant='ghost'
                size='sm'
                actionFn={methods.handleSubmit((values) =>
                  onAddClick?.(values?.category?.trim())
                )}
              >
                <FontAwesomeIcon
                  icon={faArrowRight as IconProp}
                  fontSize='20px'
                  color='#6F767E'
                />
              </ActionButton>
            </InputRightElement>

            <CloseButton
              boxSize='20px'
              right='-7px'
              top='-7px'
              borderRadius='full'
              position='absolute'
              zIndex='10'
              bgColor='#EFEFEF'
              onClick={onCloseClick}
            >
              <SmallCloseIcon width='1em' height='1em' color='#6F767E' />
            </CloseButton>
          </InputGroup>
          <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
        </FormControl>
      )}
    />
  );
};

export default AddCategoryInput;
