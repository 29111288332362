import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { useSafeState } from '../../../../../../../hooks';
import { ContentItemInput } from '../../../training-input.types';
import { QuizPreview, QuizPreviewHeader } from '../../../resources';

interface IProps {
  quizData: ContentItemInput;
}

const TrainingQuizPreview: FC<IProps> = ({ quizData }) => {
  const [selectedIndex, updateIndex] = useSafeState(0);
  const selectedQuestion = quizData?.questions?.[selectedIndex];

  return (
    <Flex flex={1} flexDir='column' paddingTop='37px' overflow='hidden'>
      <QuizPreviewHeader
        title={quizData.title}
        questionLength={quizData?.questions?.length}
        selectedIndex={selectedIndex}
        onClick={(nextIndex) => updateIndex(nextIndex)}
      />
      <QuizPreview previewData={selectedQuestion} />
    </Flex>
  );
};

export default TrainingQuizPreview;
