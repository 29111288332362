import React from 'react';
import { SortConfig } from './types';

export const useSortableData = (initialSort: SortConfig) => {
  const [sortConfig, setSortConfig] = React.useState<SortConfig>(initialSort);

  const getNextSortState = (): SortConfig => {
    const sortStates = {
      none: { order: 'asc', value: 'TITLE_ASC', sortLabel: 'sort desc' },
      asc: { order: 'desc', value: 'TITLE_DESC', sortLabel: 'no sort' },
      desc: { order: 'none', value: 'CREATEDAT_DESC', sortLabel: 'sort asc' },
    } as const;
    return sortStates[sortConfig.order];
  };

  const updateSort = () => {
    const newSortConfig = getNextSortState();
    setSortConfig(newSortConfig);
    return newSortConfig;
  };

  return { sortConfig, updateSort };
};
