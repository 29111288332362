import React, { useEffect, useState } from 'react';
import { Box, Text, Input, Flex, Center } from '@chakra-ui/react';
import Container from './Container';
import { usePlayContext } from 'sub-components/training-v2/play/store/context';
import { useService } from 'sub-components/training-v2/play/layers';
import {
  cleanResponse,
  getQuestionResponse,
  isLastUnansweredQuestion,
  isQuestionCompleted,
} from 'sub-components/training-v2/play/utils/quizHelpers';
import { Button } from 'atoms';
import { HeaderColors } from 'configs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck,
  faCircleXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import QuizQuestionTitle from './QuizQuestionTitle';
import { calculateTotalScore } from '../../../utils';

interface QuizFillBlankProps {}

const QuizFillBlank: React.FC<QuizFillBlankProps> = () => {
  const {
    contents,
    isPreview,
    isRetake,
    selectedIndex,
    selectedSubIndex,
    tpSessionData,
    increment,
    updateTpSessionData,
    setQuizSubmissionResult,
  } = usePlayContext((state) => ({
    isPreview: state.isPreview,
    isRetake: state.isRetake,
    selectedIndex: state.selectedIndex,
    selectedSubIndex: state.selectedSubIndex,
    contents: state.tpSessionData?.contents || [],
    tpSessionData: state.tpSessionData,
    increment: state.increment,
    updateTpSessionData: state.updateTpSessionData,
    setQuizSubmissionResult: state.setQuizSubmissionResult,
  }));

  const service = useService();
  const [userAnswer, setUserAnswer] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dummySubmit, setDummySubmit] = useState(false);

  const selectedData = React.useMemo(() => {
    return contents?.[selectedIndex]?.questions?.[selectedSubIndex];
  }, [contents, selectedIndex, selectedSubIndex]);

  const isCompleted = React.useMemo(() => {
    return isRetake && !dummySubmit
      ? false
      : isQuestionCompleted(
          selectedData?.eid,
          tpSessionData?.userProgress || []
        );
  }, [selectedData, tpSessionData, isRetake, dummySubmit]);

  const questionResponse = React.useMemo(() => {
    return getQuestionResponse(
      selectedData?.eid,
      tpSessionData?.userProgress || []
    );
  }, [selectedData, tpSessionData]);

  useEffect(() => {
    if (!selectedData) return;

    if (isCompleted && questionResponse?.blanks?.length && !dummySubmit) {
      setUserAnswer(questionResponse.blanks[0]);
    } else if (!isCompleted && !dummySubmit) {
      setUserAnswer('');
    }
    // Keep current answer if we just submitted in retake mode (dummySubmit true)
  }, [selectedData?.eid, isCompleted, questionResponse, dummySubmit]);

  const handleAnswerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserAnswer(e.target.value);
  };

  const normalizeAnswer = (answer: string) => {
    return answer?.trim()?.toLowerCase()?.replace(/\s+/g, '');
  };

  const isCorrect = selectedData?.blanks
    ?.map((blank) => normalizeAnswer(blank?.answer))
    ?.includes(normalizeAnswer(userAnswer));

  const handleSubmit = async () => {
    if (
      !selectedData?.eid ||
      !tpSessionData?.eid ||
      !tpSessionData?.trainingId ||
      isSubmitting
    )
      return;

    const score = isCorrect ? selectedData.score : 0;

    // Handle retake submissions
    if (isRetake) {
      // if (tpSessionData?.userProgress?.[0]) {
      const currentDate = new Date();
      const existingProgress = [
        ...(tpSessionData?.userProgress?.[0]?.progress || []),
      ];
      const currentContentIndex = existingProgress?.findIndex(
        (p) => p?.id === contents?.[selectedIndex]?.eid
      );

      // Find existing responses that aren't for this question
      const otherResponses =
        currentContentIndex >= 0
          ? existingProgress?.[currentContentIndex]?.responses?.filter(
              (r) => r?.qid !== selectedData?.eid
            )
          : [];

      const updatedProgressItem = {
        type: contents?.[selectedIndex]?.type,
        id: contents?.[selectedIndex]?.eid,
        score: score,
        isCompleted: isPreview ? false : true,
        responses: [
          ...otherResponses,
          {
            qid: selectedData?.eid,
            score: score,
            isCorrect: !!isCorrect,
            options: [],
            pairs: [],
            sequence: [],
            blanks: [userAnswer?.trim()],
            isCompleted: true,
            createdAt: currentDate,
            updatedAt: currentDate,
          },
        ],
        updatedAt: currentDate,
      };

      if (currentContentIndex >= 0) {
        existingProgress[currentContentIndex] = updatedProgressItem;
      } else {
        existingProgress?.push(updatedProgressItem);
      }

      const updatedUserProgress = {
        ...tpSessionData?.userProgress?.[0],
        progress: existingProgress,
        isCompleted: isPreview ? false : true,
      };

      setDummySubmit(true);
      updateTpSessionData({
        ...tpSessionData,
        userProgress: [updatedUserProgress],
      });
      // }

      setQuizSubmissionResult({
        showResult: true,
        isCorrect: !!isCorrect,
        isPartiallyCorrect: false,
        score,
      });
      return;
    }

    // Normal submission flow
    const isLastQuizQuestion = isLastUnansweredQuestion(
      contents?.[selectedIndex]?.questions,
      tpSessionData?.userProgress
    );

    const currentProgress = tpSessionData?.userProgress?.[0]?.progress.find(
      (p) => p.id === contents[selectedIndex].eid
    );

    const existingResponses = (currentProgress?.responses || []).map(
      cleanResponse
    );

    const mergedResponses = [
      ...existingResponses.filter((r: any) => r.qid !== selectedData.eid),
      cleanResponse({
        qid: selectedData.eid,
        blanks: [userAnswer?.trim()],
        score,
      }),
    ];

    const totalScore =
      calculateTotalScore(contents[selectedIndex].eid, [
        ...(tpSessionData?.userProgress?.[0]?.progress || []),
      ]) + score;

    setIsSubmitting(true);

    try {
      const result = await service.updateTPProgress.updateTPProgress({
        variables: {
          input: {
            isCompleted: isLastQuizQuestion,
            itemId: contents[selectedIndex].eid,
            sessionId: tpSessionData.eid,
            trainingId: tpSessionData.trainingId,
            responses: mergedResponses,
            score: totalScore,
          },
        },
      });

      if (result?.data?.AddTpProgress) {
        updateTpSessionData({
          ...tpSessionData,
          userProgress: [result.data.AddTpProgress],
        });
        setQuizSubmissionResult({
          showResult: true,
          isCorrect: !!isCorrect,
          isPartiallyCorrect: false,
          score: score,
        });
      }
    } catch (error) {
      console.error('Error submitting fill-in-the-blank quiz:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const fieldRender = () => {
    if (!isCompleted) {
      return (
        <Input
          placeholder='Type answer here'
          value={userAnswer}
          onChange={handleAnswerChange}
          w='full'
          size='lg'
          border='none'
          bg='white'
        />
      );
    } else if (isCompleted) {
      if (isCorrect) {
        return (
          <Flex
            bg='rgba(181, 228, 202, 0.3)'
            border={`1px solid ${HeaderColors.GreenDark}`}
            borderRadius='12px'
            p='16px'
            align='center'
            justify='space-between'
          >
            {userAnswer}
            <FontAwesomeIcon
              icon={faCircleCheck as IconProp}
              color={HeaderColors.GreenDark}
              fontSize='20px'
            />
          </Flex>
        );
      } else {
        return (
          <Flex
            bg='rgba(255, 106, 85, 0.2)'
            border={`1px solid ${HeaderColors.Red.Dark}`}
            borderRadius='12px'
            p='16px'
            flexDir='column'
            gap={4}
          >
            <Flex align='center' justify='space-between'>
              {userAnswer}
              <FontAwesomeIcon
                icon={faCircleXmark as IconProp}
                color={HeaderColors.Red.Dark}
                fontSize='20px'
              />
            </Flex>
            <Box bg='rgba(255, 105, 85, 0.20)' w='full' h='1px' />
            <Flex align='center' gap='5px'>
              <Flex>Correct answer:</Flex>
              <Flex fontWeight={600} fontSize='15px'>
                {selectedData?.blanks
                  ?.map((blank) => blank?.answer)
                  ?.join(', or ')}
              </Flex>
            </Flex>
          </Flex>
        );
      }
    }
  };

  return (
    <Container minH='40vh' justify='space-between'>
      <Flex flexDir='column' gap={4}>
        <QuizQuestionTitle />

        {fieldRender()}
      </Flex>

      <Button
        size='lg'
        colorScheme='blue'
        onClick={() => (isCompleted ? increment() : handleSubmit())}
        isDisabled={!userAnswer.trim() || isSubmitting}
        isLoading={isSubmitting}
      >
        {isCompleted ? 'Next' : 'Submit'}
      </Button>
    </Container>
  );
};

export default QuizFillBlank;
