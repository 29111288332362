import { match } from 'ts-pattern';
import { IRepeatInput } from './repeating/repeating.types';
import { TDuration } from '../../../../../../types';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const convertRepeatCycle = (repeatCycle: IRepeatInput | null) => {
  return match(repeatCycle)
    .with({ type: 'EVERY_N_DAYS' }, () => ({
      dayValue: repeatCycle?.repeatValue!,
      value: repeatCycle?.repeatValue!,
      type: 'days',
    }))
    .with({ type: 'EVERY_N_WEEKS' }, () => ({
      dayValue: repeatCycle?.repeatValue! * 7,
      value: repeatCycle?.repeatValue!,
      type: 'weeks',
    }))
    .with({ type: 'EVERY_N_MONTHS' }, () => ({
      dayValue: repeatCycle?.repeatValue! * 30,
      value: repeatCycle?.repeatValue!,
      type: 'months',
    }))
    .with({ type: 'EVERY_N_YEARS' }, () => ({
      dayValue: repeatCycle?.repeatValue! * 365,
      value: repeatCycle?.repeatValue!,
      type: 'years',
    }))
    .otherwise(() => null);
};

export const convertDurationToDays = (duration: TDuration): number => {
  switch (duration?.type) {
    case 'days':
      return duration.value;
    case 'weeks':
      return duration.value * 7;
    case 'months':
      return duration.value * 30;
    case 'years':
      return duration.value * 365;
    default:
      return 0;
  }
};
