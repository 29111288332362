import { useIntercom } from 'react-use-intercom';
import { CometChat } from '@cometchat-pro/chat';
import {
  authTokenVar,
  chatStatus,
  client,
  UserData,
  userObj,
} from 'sop-commons/src/client/clientFactory';
import { bundleObj, roleObj } from '../ui-components/DashboardMenu';
import { GET_USER } from '../pages/Login/login.graphql';
import { clearAvatarCache } from '../utils/avatar';
import { checkFirstTimeFlag } from 'sub-components/NotificationCenter/helper';

type useLogoutT = () => () => Promise<void>;

const useLogout: useLogoutT = () => {
  const { shutdown } = useIntercom();

  return async () => {
    try {
      if (CometChat.isInitialized()) {
        const loggedInUser = await CometChat.getLoggedinUser();
        if (loggedInUser) {
          await CometChat.logout().then(
            () => {
              console.log('CometChat logout completed successfully');
            },
            (error) => {
              console.log('CometChat logout failed with exception: ', error);
            }
          );
        } else {
          console.log('User already logged out from CometChat');
        }
      }
    } catch (e) {
      console.log('CometChat logout error ', e);
    }

    // Add a delay of 500ms after Comet chat operation
    await new Promise((resolve) => setTimeout(resolve, 500));

    const delightreeEmail = localStorage.getItem('delightree-email');
    const archiveValueForNotificationIntroFlag = checkFirstTimeFlag();
    localStorage.clear();
    localStorage.setItem(
      //maintaining the firstTimeStateForNotificationsIntoModal!
      // Edge case-> in the same browser, if different user logins again then the flag should also be cleared,
      //  but i canot store username in localStorage right?
      'firstTimeFeatureIntro',
      archiveValueForNotificationIntroFlag
    );
    if (delightreeEmail) {
      setTimeout(() => {
        localStorage.setItem('delightree-email', delightreeEmail);
      });
    }

    authTokenVar('');
    userObj({} as UserData);
    chatStatus(false);
    bundleObj({});
    roleObj({});
    shutdown();
    clearAvatarCache();
    // userflow.reset();
    // client.writeQuery({
    //   query: GET_USER,
    //   data: {
    //     user: {},
    //   },
    // });
    await client.clearStore();
    // try {
    //   if (CometChat.isInitialized() && CometChat.) {
    //     await CometChat.logout().then(
    //       () => console.log('Logout completed successfully'),
    //       (error) => console.log('Logout failed with exception: ', error)
    //     );
    //   }
    // } catch (e) {
    //   console.log('cometchat logout error ', e);
    // }
  };
};

export default useLogout;
