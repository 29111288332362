import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import AddContentButton from './components/AddContentButton';
import { Button } from '../../../../../atoms';
import { TrainingContType } from '../../../../../types';
import { useEditDisabled } from '../common';

import { ReactComponent as FlagFilledIcon } from 'assets/images/audits/FlagFilled.svg';

interface IProps {
  onClick?: (action: TrainingContType) => void;
}

const FooterButton: FC<IProps> = ({ onClick }) => {
  const editDisabled = useEditDisabled();

  if (editDisabled) {
    return null;
  }

  return (
    <Flex
      position='sticky'
      bottom='-17px'
      bg='#FCFCFC'
      borderTop='1px solid #EFEFEF'
      marginX={-6}
      paddingX={6}
      paddingY={3}
      gap={4}
      zIndex={3}
    >
      <AddContentButton onClick={onClick} />
      <Button
        variant='outline'
        onClick={() => onClick?.(TrainingContType.MILESTONE)}
        leftIcon={<FlagFilledIcon color='#83BF6E' />}
      >
        Add milestone
      </Button>
    </Flex>
  );
};

export default FooterButton;
