import React, { FC, useEffect, useMemo, useState } from 'react';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CardFormInput } from '../card-editor.types';
import TitleHeader from '../TitleHeader';
import FormSelect from './FormSelect';
import FormPreview from './FormPreview';
import SearchInput from 'atoms/SearchInput';
import {
  Box,
  VStack,
  HStack,
  Image,
  Text,
  Radio,
  RadioGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Spinner,
  Flex,
} from '@chakra-ui/react';
import { useLazyQuery } from '@apollo/client';
import {
  formSearch,
  FormSearchResponse,
} from 'sub-components/forms/form.graphql';
import debounce from 'lodash.debounce';
import EmptyState from 'sub-components/EmptyState';
import { getImage } from '../../../utils';
const colors = ['#B5E4CAB2', '#FFD88D99', '#B1E5FC', '#CABDFFB2'];

const FormType: React.FC<{ type: string; index: number }> = ({
  type,
  index,
}) => {
  const backgroundColor = colors[index % colors.length];
  return (
    <Box
      bg={backgroundColor}
      px={2}
      py={1}
      borderRadius='md'
      width='fit-content'
    >
      <Text as='b'>{type}</Text>
    </Box>
  );
};

interface IProps {}

const CustomFormEditor: FC<IProps> = () => {
  const { t } = useTranslation('card');
  const [searchQuery, setSearchQuery] = useState('');
  const { control, setValue } = useFormContext<CardFormInput>();

  const [fetchData, { data, loading: loadingCategory }] =
    useLazyQuery<FormSearchResponse>(formSearch, {
      fetchPolicy: 'network-only',
      variables: {
        perPage: 500,
      },
    });

  const debouncedFetchData = debounce(async (query) => {
    const title = query.trim();

    const filter: { title?: string; category?: string; query?: string } = {};

    if (title) {
      filter.query = title;
    }

    fetchData({
      variables: {
        perPage: 500,
        filter: filter,
      },
    });
  }, 500);

  useEffect(() => {
    setValue('shouldShowFooter', false);
  }, []);

  useEffect(() => {
    setValue('shouldShowFooter', false);
    debouncedFetchData(searchQuery);
  }, [searchQuery]);

  const items = data?.FormsPagination?.items || [];

  useEffect(() => {
    if (items.length > 0) {
      setValue('shouldShowFooter', true);
    }
  }, [items]);

  const categoryList = useMemo(() => {
    if (Array.isArray(items)) {
      return items
        ?.filter((val) => val.status === 'active')
        ?.map((val) => ({
          label: val.title,
          value: val.eid,
          ...val,
        }));
    } else {
      return [];
    }
  }, [items]);

  const renderHandler = () => {
    if (loadingCategory) {
      return (
        <Box
          h='20vh'
          display='flex'
          justifyContent='space-between'
          alignItems='center'
        >
          <Spinner />
        </Box>
      );
    }

    if (!loadingCategory && searchQuery === '') {
      return (
        <EmptyState
          image='Form'
          title='No form created yet'
          description='Click on Create New Form to create a new form'
        />
      );
    }

    if (!loadingCategory && searchQuery !== '') {
      return (
        <EmptyState image='Search' title='Form entered could not be found' />
      );
    }
  };

  console.log(categoryList);

  return (
    // <div>
    //   <TitleHeader
    //     title={t('select_form')}
    //     desc={t('select_form_desc')}
    //     titleStyle={{ marginTop: '30px' }}
    //     isRequired
    //   />

    //   <Controller
    //     name={'form'}
    //     control={control}
    //     rules={{
    //       required: t('validation.form_required'),
    //     }}
    //     render={({ field, fieldState }) => {
    //       return (
    //         <FormControl mt={3} isInvalid={!!fieldState.error}>
    //           <FormSelect
    //             value={field.value}
    //             onChange={(newValue) => {
    //               console.log('newValue', newValue)
    //               console.log('field value', field)
    //               field.onChange(newValue)
    //             }}
    //             className='member-role-dropdown-container'
    //           />
    //           <FormErrorMessage>
    //             <span>{fieldState?.error?.message}</span>
    //           </FormErrorMessage>
    //         </FormControl>
    //       );
    //     }}
    //   />

    //   <FormPreview />
    // </div>
    <>
      <SearchInput
        placeholder='Search in forms'
        hideShortcuts
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <RadioGroup mt={8}>
        <VStack width='100%' alignItems='center' spacing={8}>
          {categoryList?.length > 0
            ? categoryList.map((form, index) => (
                <FormControl key={form.eid}>
                  <FormLabel>
                    <HStack spacing={4} justifyContent={'space-between'}>
                      <Controller
                        name='form'
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => {
                          return (
                            <Radio
                              size='lg'
                              value={form.eid}
                              isChecked={value?.eid === form.eid}
                              onChange={() => {
                                onChange(form);
                              }}
                              onBlur={onBlur}
                            />
                          );
                        }}
                      />
                      <Flex flex={1} gap={2} alignItems='center'>
                        <Image
                          boxSize='50px'
                          src={getImage(form.thumbnail)}
                          alt={form.title}
                          borderRadius='4px'
                        />
                        <VStack align='start' spacing={1}>
                          <Text fontWeight='bold'>{form.title}</Text>
                        </VStack>
                      </Flex>
                      <Box>
                        <FormType type={form.category} index={index} />
                      </Box>
                    </HStack>
                  </FormLabel>
                </FormControl>
              ))
            : renderHandler()}
        </VStack>
      </RadioGroup>
    </>
  );
};

export default CustomFormEditor;
