import { useQuery, useReactiveVar } from '@apollo/client';
import { Box, Center, Flex, Text, Tooltip } from '@chakra-ui/react';
import { Button } from 'atoms';
import React, {
  FC,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Loader } from 'sub-components';
import { usersEntityObj } from 'sub-components/Header';
import { TaskCreationRef } from 'sub-components/tasks/create-task';
import { useAssigneesInfo } from 'sub-components/tasks/create-task/Assignees/AssigneeHint';
import { useSchedulerInfo } from 'sub-components/tasks/create-task/Scheduler/useSchedulerInfo';
import { useSupervisorNames } from 'sub-components/tasks/create-task/Supervisors/SupervisorHint';
import { useSpiltArray } from 'sub-components/tasks/create-task/TaskSteps/TaskStepperDescription';
import { toEditableTask } from 'sub-components/tasks/edit-task/dataConvertToEditable';
import {
  TaskEditableResponse,
  TASK_FOR_EDIT_QUERY,
} from 'sub-components/tasks/edit-task/task.graphql';
import { toArray } from 'utils';
import { IFormInput } from '../../../../sub-components/tasks/create-task/task.types';

interface IProps {
  taskId: string;
  onClose: () => void;
  onError: () => void;
  onRestartHandler: () => Promise<void>;
  onRestartAndEditHandler: () => Promise<void>;
}

interface SchedulerContentProps {
  onClose: () => void;
  onRestartHandler: () => Promise<void>;
  onRestartAndEditHandler: () => Promise<void>;
}

const Item: FC<{
  transKey: 'training:chapters' | 'form:forms' | 'task:todo';
  names: string[];
  moreItems: string[];
}> = ({ transKey, names, moreItems = [] }) => {
  const { t } = useTranslation(['training', 'form', 'task']);

  if (names.length === 0) {
    return null;
  }

  return (
    <Flex flex={1} align='center'>
      <Text color='#33383f' fontWeight='600'>
        {t(transKey)}:&nbsp;
      </Text>

      <Tooltip label={names} hasArrow>
        <Text noOfLines={1} cursor='pointer'>
          {names?.join(', ')}
        </Text>
      </Tooltip>

      {moreItems?.length > 0 && (
        <Tooltip label={moreItems?.join(', ')} hasArrow>
          <Text whiteSpace='nowrap'>
            <Trans
              t={t}
              i18nKey='training:count_more'
              values={{ value: moreItems?.length }}
            />
          </Text>
        </Tooltip>
      )}
    </Flex>
  );
};

const SchedulerContent: FC<SchedulerContentProps> = ({
  onClose,
  onRestartHandler,
  onRestartAndEditHandler,
}) => {
  const { t } = useTranslation('task');

  const transObject = useSchedulerInfo();
  console.log({ transObject });
  const contents = useWatch<IFormInput, 'contents'>({
    name: 'contents',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingEdit, setIsLoadingEdit] = useState(false);

  const entityUsersData = useReactiveVar(usersEntityObj);
  const members = useMemo(() => {
    return entityUsersData
      ?.filter(
        (user) =>
          user?.type === 'user' && ['active', 'pending']?.includes(user?.status)
      )
      ?.map((user) => ({
        label: user?.name,
        value: user?.eid,
        authRole: user?.authRole,
        locations: user?.locations || [],
        role: user?.role,
        url: user?.profilePic,
        status: user?.status,
      }));
  }, [entityUsersData]);
  const data = useAssigneesInfo(members);
  const [selected, haveMore] = useSupervisorNames(members);

  const { sop, form, todo } = useMemo(() => {
    return toArray(contents).reduce(
      (previousValue, currentValue) => {
        switch (currentValue.type) {
          case 'sop':
            previousValue['sop'].push(currentValue.title);
            break;
          case 'form':
            previousValue['form'].push(currentValue.title);
            break;
          case 'todo':
            previousValue['todo'].push(currentValue.title);
            break;
        }

        return previousValue;
      },
      { sop: [], form: [], todo: [] } as Record<
        'sop' | 'form' | 'todo',
        string[]
      >
    );
  }, [contents]);

  const [sopNames, sopMore] = useSpiltArray(sop, 5);
  const [formNames, formMore] = useSpiltArray(form, 5);
  const [todoNames, todoMore] = useSpiltArray(todo, 5);

  const handleRestart = async () => {
    setIsLoading(true);
    try {
      await onRestartHandler();
      onClose();
    } finally {
      setIsLoading(false);
    }
  };

  const handleRestartAndEdit = async () => {
    setIsLoadingEdit(true);
    try {
      await onRestartAndEditHandler();
      onClose();
    } finally {
      setIsLoadingEdit(false);
    }
  };

  return (
    <Flex flexDir='column' gap={4}>
      <Flex
        flexDir='column'
        gap={4}
        bg='rgba(177, 229, 252, 0.4)'
        borderRadius='10px'
        p='16px 24px'
      >
        <Box fontWeight={600} fontSize='14px'>
          Are you sure you want to restart this task with the following
          settings?
        </Box>
        <Flex flexDir='column' gap={4} bg='white' borderRadius='12px' p='12px'>
          <Flex flexDir='column' gap={1}>
            <Box
              color='rgba(111, 118, 126, 1)'
              fontWeight={600}
              fontSize='15px'
            >
              Task schedule{' '}
            </Box>
            <Box gap={2}>
              <Trans t={t} {...transObject} />
            </Box>
          </Flex>

          <Flex flexDir='column' gap={1}>
            <Box
              color='rgba(111, 118, 126, 1)'
              fontWeight={600}
              fontSize='15px'
            >
              Steps{' '}
            </Box>
            <Flex gap={2} flexDir='column'>
              <Item
                transKey='training:chapters'
                names={sopNames}
                moreItems={sopMore}
              />

              <Item
                transKey='form:forms'
                names={formNames}
                moreItems={formMore}
              />

              <Item
                transKey='task:todo'
                names={todoNames}
                moreItems={todoMore}
              />
            </Flex>
          </Flex>

          <Flex flexDir='column' gap={1}>
            <Box
              color='rgba(111, 118, 126, 1)'
              fontWeight={600}
              fontSize='15px'
            >
              Assignees{' '}
            </Box>
            <Flex flexDir='column'>
              {data?.map((value) => {
                return (
                  <Box gap={2}>
                    <Trans t={t} {...value} />
                  </Box>
                );
              })}
            </Flex>
          </Flex>

          <Flex flexDir='column' gap={1}>
            <Box
              color='rgba(111, 118, 126, 1)'
              fontWeight={600}
              fontSize='15px'
            >
              Supervisors{' '}
            </Box>
            <Flex>
              <Box gap={2}>
                <Trans
                  t={t}
                  i18nKey='supervisorNames'
                  count={haveMore}
                  values={{
                    names: haveMore > 1 ? selected.join(', ') : selected,
                  }}
                />
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex flexDir='column' gap={2}>
        <Button
          size='lg'
          colorScheme='blue'
          variant='solid'
          isLoading={isLoading}
          disabled={isLoading || isLoadingEdit}
          onClick={handleRestart}
        >
          Restart
        </Button>
        <Button
          size='lg'
          variant='outline'
          onClick={handleRestartAndEdit}
          isLoading={isLoadingEdit}
          disabled={isLoading || isLoadingEdit}
        >
          Restart and edit details
        </Button>
      </Flex>
    </Flex>
  );
};

const ContentInternal = forwardRef<
  TaskCreationRef,
  {
    onClose: () => void;
    onRestartHandler: () => Promise<void>;
    onRestartAndEditHandler: () => Promise<void>;
  }
>((props, ref) => {
  const initialRef = useRef<IFormInput>();

  const methods = useForm<IFormInput>({
    defaultValues: {
      completed: {
        details: false,
      },

      currentStep: 'details',
      taskRunFor: 'once',

      assignToType: [],

      contents: [],
    },
  });

  useImperativeHandle(
    ref,
    () => {
      return {
        initEditTask: (values) => {
          const timeOffsetDiff = methods.getValues('timeOffsetDiff');
          methods.reset({ ...values, timeOffsetDiff });
          initialRef.current = values;
        },
        setError: methods.setError,
        setValue: methods.setValue,
      };
    },
    [methods.reset, methods.setValue, methods.setError]
  );
  return (
    <FormProvider {...methods}>
      <SchedulerContent
        onClose={props.onClose}
        onRestartHandler={props.onRestartHandler}
        onRestartAndEditHandler={props.onRestartAndEditHandler}
      />
    </FormProvider>
  );
});
ContentInternal.displayName =
  'displayName:sub-components/tasks/shared/activate-task-modal/ContentInternal';

const Content: FC<IProps> = ({
  taskId,
  onClose,
  onError,
  onRestartHandler,
  onRestartAndEditHandler,
}) => {
  const taskFormRef = useRef<TaskCreationRef>(null);
  const { loading } = useQuery<TaskEditableResponse>(TASK_FOR_EDIT_QUERY, {
    fetchPolicy: 'network-only',
    variables: { eid: taskId },
    onCompleted: (response) => {
      if (response.TaskById && response.TaskById?.status !== 'TASK_DELETED') {
        const editableTask = toEditableTask(response.TaskById);
        taskFormRef.current?.initEditTask(editableTask);
      } else {
        onError();
      }
    },
  });
  if (loading) {
    return (
      <Center h='20vh'>
        <Loader />
      </Center>
    );
  }
  return (
    <ContentInternal
      ref={taskFormRef}
      onClose={onClose}
      onRestartHandler={onRestartHandler}
      onRestartAndEditHandler={onRestartAndEditHandler}
    />
  );
};

export default Content;
