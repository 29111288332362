import React, { FC } from 'react';
import { Box, Flex, keyframes, Text } from '@chakra-ui/react';
import { useLocation, useParams } from 'react-router-dom';
import { useFormContext, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from 'atoms';
import { AppRoute, useHistory } from 'routes';
import { Authorize, AuthRole } from '../../../authorization';
import { headerConfig } from '../common';

import { ReactComponent as BackIcon } from '../../../assets/images/chevronBackward.svg';
import { ReactComponent as SyncIcon } from '../../../assets/images/sync-sharp.svg';
import { AuditQuestion, IFormInput } from './form-types';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';
import { QuestionType } from 'types';

const spin = keyframes({
  '0%': {
    transform: 'rotate(0deg)',
  },
  '100%': {
    transform: 'rotate(360deg)',
  },
});

interface LocationState {
  isCreation: boolean;
}

interface Params {
  templateId: string;
}

interface IProps {
  onFinishHandler: (values: IFormInput) => Promise<void>;
}

const CreateAuditHeader: FC<IProps> = ({ onFinishHandler }) => {
  const { t } = useTranslation(['audit']);
  const history = useHistory();
  const params = useParams<Params>();
  const isCreation = useLocation<LocationState>()?.state?.isCreation;

  const { handleSubmit } = useFormContext<IFormInput>();
  const { isSubmitting, isDirty } = useFormState<IFormInput>();

  const countQuestionsByType = (
    questions: AuditQuestion[]
  ): Record<string, number> => {
    return questions.reduce((counts, question) => {
      const questionType = question.qType;
      counts[questionType] = (counts[questionType] || 0) + 1;
      return counts;
    }, {} as Record<string, number>);
  };

  const baseEventObj = (values: IFormInput) => {
    return {
      audit_id: values?.eid,
      audit_funnel_id: 12,
      template_type: values?.category,
      audit_title: values?.title,
      total_questions:
        values?.questions?.filter((que) => que?.qType !== QuestionType.SECTION)
          ?.length || 0,
      total_sections:
        values?.questions?.filter((que) => que?.qType === QuestionType.SECTION)
          ?.length || 0,
      question_details: values?.questions,
      cateogry_details: countQuestionsByType(values?.questions),
      template_name: values?.title,
      edit_audit_funnel_id: 12,
    };
  };

  const _saveProxy = async (values: IFormInput) => {
    deployEvent(AmplitudeEventNames.SAVE_AS_DRAFT_AUDIT, {
      event: baseEventObj(values),
    });
    await onFinishHandler?.(values);
    sessionStorage.setItem('auditRootTab', '1');
    history.replace({
      pathname: AppRoute.AUDIT_LIST,
    });
  };

  const _submitProxy = async (values: IFormInput) => {
    deployEvent(AmplitudeEventNames.SAVE_AND_SCHEDULE_AUDIT, {
      event: {
        ...baseEventObj(values),
        from_where: isCreation ? 'Create Page' : 'Edit Page',
      },
    });
    if (isDirty) {
      await onFinishHandler?.(values);
    }
    history.push({
      pathname: AppRoute.SCHEDULE_AUDIT,
      params: {
        templateId: params.templateId,
      },
    });
  };

  // @ts-ignore
  const backToTitle: string = history.location.state?.backToTitle;
  // @ts-ignore
  const pageTitle: string = history.location.state?.pageTitle;

  return (
    <Flex flexDir='column' gap={2} {...headerConfig}>
      <Button
        fontSize='14px'
        fontWeight={500}
        width='max-content'
        variant='link'
        leftIcon={<BackIcon width='16' height='16' />}
        _hover={{
          textDecoration: 'none',
        }}
        onClick={history.goBack}
      >
        {backToTitle || 'Back to Templates'}
      </Button>

      <Flex align='center' justify='space-between'>
        <Flex align='center' gap={3} minH={10}>
          <Text fontWeight={600} fontSize='28px'>
            {isCreation
              ? pageTitle || t('audit:createATemplate')
              : t('audit:editTemplate')}
          </Text>
          <Flex
            hidden={!isSubmitting}
            align='center'
            gap='6px'
            fontSize='12px'
            color='#6F767E'
          >
            <Box animation={`${spin} 1s linear infinite`} boxSize={4}>
              <SyncIcon />
            </Box>
            Saving
          </Flex>
        </Flex>

        <Authorize permittedRoles={AuthRole.SUPER_ADMIN}>
          <Flex gap={3}>
            <Button
              variant='outline'
              colorScheme='gray'
              onClick={handleSubmit(_saveProxy)}
              isDisabled={isSubmitting}
            >
              Save as Draft
            </Button>
            <Button
              variant='solid'
              colorScheme='blue'
              onClick={handleSubmit(_submitProxy)}
              isDisabled={isSubmitting}
            >
              Save and Schedule
            </Button>
          </Flex>
        </Authorize>
      </Flex>
    </Flex>
  );
};

export default CreateAuditHeader;
