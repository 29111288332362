import React, {
  FC,
  useMemo,
  useState,
  createContext,
  useContext,
  useEffect,
} from 'react';
import {
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import { CustomTab } from 'sub-components/nexus/NexusBase/components/LeftSection/components/LocationLeaderboard/components';
import {
  ITPCategory,
  ITPFilter,
  ITrainingContextData,
  TPFiltersParams,
  TPItems,
} from 'sub-components/training-v2/shared/types';
import { Header } from './TrackPathDashboard/bottomSection';
import { TrackMembersTable } from './Tracking/components';
import { TabConfig } from './TrackPathDashboard/topSection/Leaderboard';
import TrackPathTableContainer from './TrackPathTableContainer';
import {
  OperationVariables,
  QueryLazyOptions,
  useLazyQuery,
  useReactiveVar,
} from '@apollo/client';
import {
  GET_TRACKING_TP_DATA,
  GET_TRAINING_CATEGORIES,
} from 'sub-components/training-v2/shared/query/dashboard.graphql';
import { prepareTrackingTPTableData } from './TrackPathDashboard/bottomSection/helper';
import { usersEntityObj } from 'sub-components/Header';
import { ITrackingTPPagination } from './TrackPathDashboard/bottomSection/PathTable';
import { ITypes } from './TrackPathDashboard/bottomSection/Header';
import { FilterChip } from './TrackPathDashboard/bottomSection/Filter';
import { useUserDataSelector } from 'hooks';
import { AuthRole } from 'sop-commons/src/client';
import useCombinedStore from 'zustandStore/store';

type TrainingContextPropsType = {
  trainingContextData: ITrainingContextData;
  setTrainingContextData: React.Dispatch<
    React.SetStateAction<ITrainingContextData>
  >;
  trackingTpPaginationData: ITrackingTPPagination[];
  trainingCategories: ITPCategory[];
  getTrackingTpPaginationData: (
    options?: QueryLazyOptions<OperationVariables> | undefined
  ) => void;
  getTrainingCategories: (
    options?: QueryLazyOptions<OperationVariables> | undefined
  ) => void;
  getTrackingTPFilters?: ({
    pageIndex,
    sort,
  }: TPFiltersParams) => ITPFilter | undefined;
  setFilters: React.Dispatch<React.SetStateAction<FiltersType>>;
  filters: FiltersType;
  trackingTpPaginationLoader: boolean;
  trainingCategoriesLoader: boolean;
  selectedSort: ISortType;
  setSelectedSort: React.Dispatch<React.SetStateAction<ISortType>>;
  trainingPathItemCount: number;
  selectedPageIndex: number;
  setSelectedPageIndex: React.Dispatch<React.SetStateAction<number>>;
  trackingTpPaginationError: boolean;
};

interface ISortType {
  value: string;
  sortType: string;
}

export type FiltersType = {
  totalFiltersTypes: number;
  types: any[];
  selectedTypes: any[];
  selectedTypesId: any[];
  categories: any[];
  selectedCategories: any[];
  selectedCategoriesId: any[];
  statuses: any[];
  selectedStatuses: any[];
  selectedJobs: { name: string; eid: string }[];
  selectedJobsId: string[];
  selectedLocations: { name: string; locStatus?: string; eid: string }[];
  selectedLocationsId: string[];
  selectedStatusesId: any[];
  mergedFiltersArray: any[];
  locations?: {
    name: string;
    locStatus?: 'open' | 'development' | 'preLaunch';
    eid: string;
  }[];
  jobs?: { name: string; eid: string }[];
};

// Create context
const TrainingContext = createContext<TrainingContextPropsType | undefined>(
  undefined
);

// Create a custom hook to use the context
const useTrainingContext = (): TrainingContextPropsType => {
  const context = useContext(TrainingContext);
  if (!context) {
    throw Error('something went wrong');
  }
  return context;
};

const TrainingTableContainer: FC = () => {
  // State for other tp data
  const [trainingContextData, setTrainingContextData] =
    useState<ITrainingContextData>({
      trackingTpSearchQuery: '',
    });
  const [trackingTpPaginationData, setTrackingTpPaginationData] = useState<
    ITrackingTPPagination[]
  >([]);
  const [trainingPathItemCount, setTrainingPathItemCount] = useState<number>(0);
  const [trainingCategories, setTrainingCategories] = useState<ITPCategory[]>(
    []
  );
  const [selectedSort, setSelectedSort] = useState<ISortType>({});
  const [selectedPageIndex, setSelectedPageIndex] = useState<number>(1);
  const [filters, setFilters] = useState<FiltersType>({
    totalFiltersTypes: 0,
    types: [],
    selectedTypes: [],
    selectedTypesId: [],
    categories: [],
    selectedCategories: [],
    selectedCategoriesId: [],
    statuses: [],
    selectedStatuses: [],
    selectedStatusesId: [],
    mergedFiltersArray: [],
    selectedJobsId: [],
    jobs: [],
    locations: [],
    selectedJobs: [],
    selectedLocations: [],
    selectedLocationsId: [],
  });
  const { locationIdArray, tableTabIndex, updateTableTabIndex } =
    useCombinedStore();

  const { userAuthRole } = useUserDataSelector((state) => ({
    userAuthRole: state?.authRole,
  }));

  const entityObjData = useReactiveVar(usersEntityObj);

  const tabsConfig = useMemo((): TabConfig[] => {
    return [
      { label: 'Paths', minWidth: '80px', hasNewBadge: false },
      { label: 'Members', minWidth: '80px' },
    ];
  }, []);

  // Main filter handler responsible for returning filter object which is later used to fetch queries from API
  const getTrackingTPFilters = ({
    pageIndex = 1,
    sort = 'CREATEDAT_DESC',
  }: TPFiltersParams) => {
    let filter: {
      query?: string;
      category?: string[];
      createdBy?: string;
      status: string[];
      supervisor?: boolean;
      creator?: boolean;
      locationIds?: string[];
    } = {
      query: '',
      status: [
        'TRAINING_STARTED',
        'TRAINING_ONE_TIME',
        'TRAINING_REPEAT',
        'TRAINING_COMPLETED',
        'TRAINING_TERMINATED',
      ],
      locationIds: [],
    };

    // Don't apply supervisor and creator filter if the user role is Super Admin
    if (userAuthRole === AuthRole?.LOCATION_OWNER) {
      filter.supervisor = true;
      filter.creator = true;
    }

    // Add filter query to the filter object
    if (trainingContextData?.trackingTpSearchQuery) {
      filter.query = trainingContextData?.trackingTpSearchQuery;
    }

    // Add filter for categories
    if (filters?.selectedCategoriesId?.length > 0) {
      filter.category = filters?.selectedCategoriesId;
    }

    // Add filter for status
    // Only show the active training paths
    if (filters?.selectedStatusesId?.length > 0) {
      if (
        filters?.selectedStatusesId?.length === 1 &&
        filters?.selectedStatusesId?.includes('static_1_active1')
      ) {
        filter.status = [
          'TRAINING_STARTED',
          'TRAINING_ONE_TIME',
          'TRAINING_REPEAT',
          'TRAINING_COMPLETED',
        ];
      }

      // Only show the inactive training path
      if (
        filters?.selectedStatusesId?.length === 1 &&
        filters?.selectedStatusesId?.includes('static_2_inactive2')
      ) {
        filter.status = ['TRAINING_TERMINATED'];
      }
    }

    // Add filter for type

    // If location owner then send both flags
    if (userAuthRole === AuthRole?.LOCATION_OWNER) {
      if (
        filters?.selectedTypesId?.length === 1 &&
        filters?.selectedTypesId?.includes('static_supervised_by_me1')
      ) {
        filter.supervisor = true;
        filter.creator = false;
      }

      if (
        filters?.selectedTypesId?.length === 1 &&
        filters?.selectedTypesId?.includes('static_created_by_me')
      ) {
        filter.creator = true;
        filter.supervisor = false;
      }
    }
    // If Superadmin or Admin then don't send the creator and supervisor flag by default
    else {
      if (
        filters?.selectedTypesId?.length === 1 &&
        filters?.selectedTypesId?.includes('static_supervised_by_me1')
      ) {
        filter.supervisor = true;
      }

      if (
        filters?.selectedTypesId?.length === 1 &&
        filters?.selectedTypesId?.includes('static_created_by_me')
      ) {
        filter.creator = true;
      }
    }

    if (filters?.selectedTypesId?.length === 2) {
      filter.creator = true;
      filter.supervisor = true;
    }

    // Add location filter, to show path table data only for the selected location
    if (locationIdArray?.length > 1) {
      filter.locationIds = [];
    } else {
      filter.locationIds = locationIdArray;
    }

    return {
      filter: filter,
      sort: sort,
      page: pageIndex,
      perPage: 10,
    };
  };

  // API to get tracking pagination data
  const [
    getTrackingTpPaginationData,
    { loading: trackingTpPaginationLoader, error: trackingTpPaginationError },
  ] = useLazyQuery(GET_TRACKING_TP_DATA, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const formattedData = prepareTrackingTPTableData(
        data?.TpPaginationNew?.items,
        trainingCategories,
        entityObjData
      );

      // setTrainingPathItemCount(data?.TpPagination?.count);
      setTrainingPathItemCount(data?.TpPaginationNew?.count);
      setTrackingTpPaginationData(formattedData);
    },
  });

  // API to get training categories
  const [getTrainingCategories, { loading: trainingCategoriesLoader }] =
    useLazyQuery(GET_TRAINING_CATEGORIES, {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setTrainingCategories(data.EntityTpCategories);
        // Trigger the pagination query after categories are fetched
        getTrackingTpPaginationData({
          variables: getTrackingTPFilters({
            pageIndex: selectedPageIndex,
            sort: selectedSort?.value,
          }),
        });
      },
    });

  useEffect(() => {
    // Fetch initial data when searchQuery is empty
    if (
      !trainingContextData?.trackingTpSearchQuery &&
      !filters?.totalFiltersTypes &&
      tableTabIndex === 0
    ) {
      getTrainingCategories();
      return;
    }

    // Debounce fetch when searchQuery is updated
    let timer: NodeJS.Timeout;
    if (tableTabIndex === 0) {
      timer = setTimeout(() => {
        getTrackingTpPaginationData({
          variables: getTrackingTPFilters({
            pageIndex: selectedPageIndex,
            sort: selectedSort?.value,
          }),
        });
      }, 500);
    }

    return () => clearTimeout(timer);
  }, [
    trainingContextData?.trackingTpSearchQuery,
    tableTabIndex === 0 && filters,
  ]);

  // Call only TP pagination API on location change from dropdown (could be handled in the above useEffect, but this'll cause extra call for categories API)
  useEffect(() => {
    if (trainingCategories?.length > 0) {
      getTrackingTpPaginationData({
        variables: getTrackingTPFilters({
          pageIndex: selectedPageIndex,
          sort: selectedSort?.value,
        }),
      });
    }
  }, [locationIdArray, selectedPageIndex]);

  return (
    // Wrap the children with the provider
    <TrainingContext.Provider
      value={{
        trainingContextData: trainingContextData,
        setTrainingContextData: setTrainingContextData,
        getTrackingTpPaginationData: getTrackingTpPaginationData,
        getTrainingCategories: getTrainingCategories,
        trackingTpPaginationData: trackingTpPaginationData,
        trainingCategories: trainingCategories,
        getTrackingTPFilters: getTrackingTPFilters,
        filters: filters,
        setFilters: setFilters,
        trackingTpPaginationLoader: trackingTpPaginationLoader,
        trainingCategoriesLoader: trainingCategoriesLoader,
        setSelectedSort: setSelectedSort,
        selectedSort: selectedSort,
        trainingPathItemCount: trainingPathItemCount,
        setSelectedPageIndex: setSelectedPageIndex,
        selectedPageIndex: selectedPageIndex,
        trackingTpPaginationError: trackingTpPaginationError,
      }}
    >
      <Flex
        id='trainingPathTable'
        flexDir='column'
        border='2px solid rgba(239, 239, 239, 1)'
        borderRadius='12px'
        p='24px'
      >
        <Tabs
          onChange={(index) => updateTableTabIndex(index)}
          index={tableTabIndex}
          isLazy
          variant='unstyled'
          w='full'
        >
          <TabList flexWrap='wrap' gap='.5rem'>
            <Flex
              width={'100%'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Flex gap={2}>
                {tabsConfig.map((tab, index) => (
                  <CustomTab
                    key={index}
                    label={tab.label}
                    minWidth={tab.minWidth}
                    hasNewBadge={tab.hasNewBadge}
                  />
                ))}
              </Flex>
              <Flex>
                <Header />
              </Flex>
            </Flex>

            {/* FilterChip */}
            {filters?.totalFiltersTypes > 0 && (
              <FilterChip filters={filters} setFilters={setFilters} />
            )}
          </TabList>

          <TabPanels>
            {tabsConfig.map((tab, index) => (
              <TabPanel key={index} px={0}>
                {tab.label === 'Paths' && <TrackPathTableContainer />}
                {tab.label === 'Members' && <TrackMembersTable />}
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Flex>
    </TrainingContext.Provider>
  );
};

TrainingTableContainer.displayName =
  'displayName:sub-components/training-v2/dashboard/components/TrainingTableContainer';

export default TrainingTableContainer;
export { useTrainingContext }; // Export the hook for use in child components
