// hooks/useDownloadReport.ts
import { useState, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { GET_AUDIT_REPORT } from '../../../../pages/Audits/Detail/graphql/common-audit-details.graphql';
import {
  AuditReportResponse,
  AuditReportVariable,
} from '../../../../pages/Audits/Detail/graphql/common-audit-details.types';

interface UseDownloadReportParams {
  locationId: string;
  sessionId: string;
  reportsUrl?: string;
  callback?: () => void;
}

interface UseDownloadReportReturn {
  handleDownload: () => void;
  loading: boolean;
  error?: string;
}

export const useDownloadReport = ({
  locationId,
  sessionId,
  reportsUrl,
  callback,
}: UseDownloadReportParams): UseDownloadReportReturn => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const [getAuditReport] = useMutation<
    AuditReportResponse,
    AuditReportVariable
  >(GET_AUDIT_REPORT, {
    onCompleted: (data) => {
      setLoading(false);
      const url = data?.AuditSessionReportsPdf;
      if (url) {
        const opened = window.open(url, '_blank');
        if (!opened) {
          setError('Failed to open the report in a new tab.');
        }
      } else {
        setError('Report URL not found.');
      }
      callback?.();
    },
    onError: (err) => {
      setLoading(false);
      setError(err?.message);
      console.error('Error downloading report:', err);
    },
  });

  const handleDownload = useCallback(() => {
    if (loading) return; // Prevent multiple clicks

    if (reportsUrl) {
      const opened = window.open(reportsUrl, '_blank');
      if (!opened) {
        setError('Failed to open the report in a new tab.');
      } else {
        callback?.();
      }
    } else {
      setLoading(true);
      getAuditReport({ variables: { locationId, sessionId } });
    }
  }, [reportsUrl, callback, getAuditReport, locationId, sessionId, loading]);

  return { handleDownload, loading, error };
};
