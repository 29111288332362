import { useQuery } from '@apollo/client';
import { TAssigneeUserType } from '../../../../../../../types';
import { FETCH_TRAINING, TrainingResponse } from './dependent.graphql';

export const useTrainingList = (userType?: TAssigneeUserType) => {
  return useQuery<TrainingResponse>(FETCH_TRAINING, {
    fetchPolicy: 'network-only',
    variables: {
      perPage: 100,
      sort: '_ID_DESC',
      filter: {
        status: ['TRAINING_STARTED', 'TRAINING_ONE_TIME', 'TRAINING_REPEAT'],
        assigneeUserType: userType,
      },
    },
  });
};
