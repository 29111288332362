import React, { forwardRef, useMemo } from 'react';
import { CheckIcon, ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useControllableState,
} from '@chakra-ui/react';

const options = Array.from({ length: 9 }).map((_, i) => (i + 1) * 10);

interface IProps {
  value?: number;
  onChange?: (value: number) => void;
  disabled?: boolean;
}

const MinScoreSelect = forwardRef<never, IProps>(
  ({ value, onChange, disabled }, ref) => {
    const [internalValue, updateValue] = useControllableState<number>({
      value: value,
      onChange: onChange,
    });

    const _value = useMemo(() => {
      return options.find((val) => val === internalValue);
    }, [internalValue]);

    return (
      <Menu
        matchWidth
        placement='bottom-end'
        closeOnSelect
        boundary='scrollParent'
        autoSelect={false}
      >
        <MenuButton
          ref={ref}
          display='flex'
          fontSize='13px'
          fontWeight={600}
          bgColor='#FFFFFF'
          border='2px solid #EFEFEF'
          borderRadius='12px'
          padding='4px 8px 4px 16px'
          width='190px'
          maxWidth='190px'
          minHeight='46px'
          disabled={disabled}
          _disabled={{
            opacity: 0.4,
            cursor: 'not-allowed',
          }}
        >
          <Flex gap={2} align='center' justify='space-between'>
            {_value ? (
              <Box as='span' color='#6F767E' whiteSpace='nowrap'>
                {_value}%
              </Box>
            ) : (
              <Box as='span' opacity='0.5'>
                Enter minimum %
              </Box>
            )}

            <ChevronDownIcon boxSize='26px' color='#6F767E' />
          </Flex>
        </MenuButton>
        <MenuList
          width='inherit'
          minWidth='0'
          display='flex'
          flexDirection='column'
          padding='16px'
          borderRadius='12px'
          zIndex={100}
          maxHeight='320px'
          overflow='hidden auto'
          gap={1}
          sx={{
            '&::-webkit-scrollbar': {
              width: '5px',
              height: '0px',
            },
            '&::-webkit-scrollbar-track': {
              // background: '#f1f1f1',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#EFEFEF',
              borderRadius: '4px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: '#555',
              cursor: 'pointer',
            },
          }}
        >
          {options.map((item) => {
            const isSelected = item === internalValue;
            return (
              <MenuItem
                key={item}
                gap={3}
                height='38px'
                alignItems='center'
                paddingInline='12px'
                borderRadius='12px'
                cursor='pointer'
                color='#6F767E'
                aria-selected={isSelected}
                _selected={{ bg: '#2A85FF1A', color: '#2A85FF' }}
                _hover={{ bg: '#F4F4F3' }}
                onClick={() => updateValue?.(item)}
              >
                <Box flex={1} fontSize='13px' fontWeight='500' isTruncated>
                  {item}%
                </Box>
                {isSelected ? <CheckIcon /> : null}
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    );
  }
);

MinScoreSelect.displayName = 'MinScoreSelect';

export default MinScoreSelect;
