import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import TrackingContainer from './Tracking/TrackingContainer';
import MyTrainingsContainer from './MyTrainingsContainer';
import DraftContainer from './DraftContainer';
import { FlexCard } from 'atoms';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserDataSelector } from 'hooks';
import { AuthRole } from 'sop-commons/src/client';
import { useRouteState } from 'routes';
import { useLocation } from 'react-router-dom';
import useCombinedStore from 'zustandStore/store';

export type TrainingPathContainerTabs = 'tracking' | 'myTraining' | 'draft';

interface ILocationState {
  tabSelected: TrainingPathContainerTabs;
}

const Container: FC = () => {
  const { t } = useTranslation(['training']);
  const loggedInUserAuthRole = useUserDataSelector((state) => state?.authRole);
  const locState = useLocation<ILocationState>()?.state;
  const { updateTrainingCategoryIndex } = useCombinedStore();

  const { state, updateState, resetChildState } = useRouteState({
    tabIndex: 0,
  });

  // useEffect(() => {
  //   const trainingTab = sessionStorage.getItem('trainingRootTab');
  //   if (trainingTab && !isNaN(+trainingTab)) {
  //     updateState({
  //       tabIndex: +trainingTab,
  //     });
  //     sessionStorage.removeItem('trainingRootTab');
  //   }
  // }, []);

  useEffect(() => {
    const isWorker = loggedInUserAuthRole === AuthRole?.WORKER;
    let tabIndex = 0;

    const trainingTab = sessionStorage.getItem('trainingRootTab');
    if (trainingTab && !isNaN(+trainingTab)) {
      tabIndex = +trainingTab;
    }

    if (locState?.tabSelected) {
      switch (locState?.tabSelected) {
        case 'tracking':
          tabIndex = isWorker ? 0 : 0;
          break;
        case 'myTraining':
          tabIndex = isWorker ? 0 : 1;
          break;
        case 'draft':
          tabIndex = isWorker ? 0 : 2;
          break;
      }
    }

    if (isWorker) {
      tabIndex = 0;
    }

    updateState({ tabIndex });
  }, [locState, loggedInUserAuthRole, updateState]);

  const onChange = (index: number) => {
    resetChildState('training');
    updateState({
      tabIndex: index,
    });
    updateTrainingCategoryIndex(index);
    sessionStorage.setItem('trainingRootTab', index.toString());
  };

  return (
    <FlexCard>
      <Tabs isLazy w='full' index={state.tabIndex} onChange={onChange}>
        <TabList>
          {loggedInUserAuthRole !== AuthRole?.WORKER && (
            <Tab
              _selected={{
                borderBottom: '3px solid #2A85FF',
                color: 'inherit',
              }}
              textColor='#6F767E'
              fontSize='15px'
              fontWeight={600}
            >
              {t('training:tracking')}
            </Tab>
          )}
          <Tab
            _selected={{
              borderBottom: '3px solid #2A85FF',
              color: 'inherit',
            }}
            textColor='#6F767E'
            fontSize='15px'
            fontWeight={600}
          >
            {t('training:myTrainings')}
          </Tab>
          {loggedInUserAuthRole !== AuthRole?.WORKER && (
            <Tab
              _selected={{
                borderBottom: '3px solid #2A85FF',
                color: 'inherit',
              }}
              textColor='#6F767E'
              fontSize='15px'
              fontWeight={600}
            >
              {t('training:drafts')}
            </Tab>
          )}
        </TabList>
        <TabPanels>
          {loggedInUserAuthRole !== AuthRole?.WORKER && (
            <TabPanel px={0} pt={'40px'}>
              <TrackingContainer />
            </TabPanel>
          )}
          <TabPanel px={0} py={'40px'}>
            <MyTrainingsContainer />
          </TabPanel>
          {loggedInUserAuthRole !== AuthRole?.WORKER && (
            <TabPanel px={0} py={'40px'}>
              <DraftContainer />
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </FlexCard>
  );
};

Container.displayName =
  'displayName:sub-components/training-v2/dashboard/components/Container';

export default Container;
