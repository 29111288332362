import React, { FC, useEffect, useMemo, useState } from 'react';
import { Box, Radio, Stack, Flex } from '@chakra-ui/react';
import { Button } from 'atoms';
import Container from './Container';
import { usePlayContext } from 'sub-components/training-v2/play/store/context';
import {
  isQuestionCompleted,
  getQuestionResponse,
} from 'sub-components/training-v2/play/utils';
import { useService } from 'sub-components/training-v2/play/layers';
import {
  cleanResponse,
  isLastUnansweredQuestion,
} from 'sub-components/training-v2/play/utils/quizHelpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faCircleDot } from '@fortawesome/pro-solid-svg-icons';
import {
  faCircleCheck,
  faCircleXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import QuizQuestionTitle from './QuizQuestionTitle';
import { calculateTotalScore } from '../../../utils';

const QuizTrueFalse: FC = () => {
  const {
    contents,
    isPreview,
    isRetake,
    selectedIndex,
    selectedSubIndex,
    tpSessionData,
    increment,
    updateTpSessionData,
    setQuizSubmissionResult,
  } = usePlayContext((state) => ({
    isPreview: state.isPreview,
    isRetake: state.isRetake,
    selectedIndex: state.selectedIndex,
    selectedSubIndex: state.selectedSubIndex,
    contents: state.tpSessionData?.contents || [],
    tpSessionData: state.tpSessionData,
    increment: state.increment,
    updateTpSessionData: state.updateTpSessionData,
    setQuizSubmissionResult: state.setQuizSubmissionResult,
  }));

  const service = useService();
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dummySubmit, setDummySubmit] = useState(false);

  const selectedData = useMemo(() => {
    return contents?.[selectedIndex]?.questions?.[selectedSubIndex];
  }, [contents, selectedIndex, selectedSubIndex]);

  const isCompleted = useMemo(() => {
    return isRetake && !dummySubmit
      ? false
      : isQuestionCompleted(
          selectedData?.eid,
          tpSessionData?.userProgress || []
        );
  }, [selectedData, tpSessionData, dummySubmit]);

  const questionResponse = useMemo(() => {
    if (isRetake) return;
    return getQuestionResponse(
      selectedData?.eid,
      tpSessionData?.userProgress || []
    );
  }, [selectedData, tpSessionData]);

  useEffect(() => {
    if (isRetake) return;
    if (questionResponse) {
      const isAnswerTrue = questionResponse.isCorrect === selectedData?.isTrue;
      setSelectedOption(isAnswerTrue ? 'True' : 'False');
    }
  }, [selectedData?.eid, questionResponse]);

  const handleSubmit = async () => {
    if (
      !selectedData?.eid ||
      !tpSessionData?.eid ||
      !tpSessionData?.trainingId ||
      isSubmitting
    )
      return;

    // Determine if user selected correctly based on the question's isTrue value
    const isCorrect =
      (selectedData.isTrue && selectedOption === 'True') ||
      (!selectedData.isTrue && selectedOption === 'False');

    const score = isCorrect ? selectedData.score : 0;

    if (isRetake) {
      // if (tpSessionData?.userProgress?.[0]) {
      const currentDate = new Date();
      const existingProgress = [
        ...(tpSessionData?.userProgress?.[0]?.progress || []),
      ];
      const currentContentIndex = existingProgress?.findIndex(
        (p) => p?.id === contents?.[selectedIndex]?.eid
      );
      const updatedProgressItem = {
        type: contents?.[selectedIndex]?.type,
        id: contents?.[selectedIndex]?.eid,
        score: score,
        isCompleted: isPreview ? false : true,
        responses:
          currentContentIndex >= 0
            ? [
                ...existingProgress?.[currentContentIndex]?.responses?.map(
                  (r) => ({
                    ...r,
                    isCompleted: true,
                  })
                ),
                {
                  qid: selectedData?.eid,
                  score: score,
                  isCorrect: isCorrect,
                  options: [],
                  pairs: [],
                  sequence: [],
                  blanks: [],
                  isCompleted: true,
                  createdAt: currentDate,
                  updatedAt: currentDate,
                },
              ]
            : [
                {
                  qid: selectedData?.eid,
                  score: score,
                  isCorrect: isCorrect,
                  options: [],
                  pairs: [],
                  sequence: [],
                  blanks: [],
                  isCompleted: true,
                  createdAt: currentDate,
                  updatedAt: currentDate,
                },
              ],
        updatedAt: currentDate,
      };

      if (currentContentIndex >= 0) {
        existingProgress[currentContentIndex] = updatedProgressItem;
      } else {
        existingProgress?.push(updatedProgressItem);
      }

      const updatedUserProgress = {
        ...tpSessionData?.userProgress?.[0],
        progress: existingProgress,
        isCompleted: isPreview ? false : true,
      };
      setDummySubmit(true);
      updateTpSessionData({
        ...tpSessionData,
        userProgress: [updatedUserProgress],
      });
      // }

      setQuizSubmissionResult({
        showResult: true,
        isPartiallyCorrect: false,
        isCorrect,
        score,
      });
      return;
    }

    const isLastQuizQuestion = isLastUnansweredQuestion(
      contents?.[selectedIndex]?.questions,
      tpSessionData?.userProgress
    );

    const currentProgress = tpSessionData?.userProgress?.[0]?.progress.find(
      (p) => p.id === contents[selectedIndex].eid
    );

    const existingResponses = (currentProgress?.responses || []).map(
      cleanResponse
    );

    const mergedResponses = [
      ...existingResponses.filter((r) => r.qid !== selectedData.eid),
      {
        qid: selectedData.eid,
        isCorrect,
        score,
      },
    ];

    const totalScore =
      calculateTotalScore(contents[selectedIndex].eid, [
        ...(tpSessionData?.userProgress?.[0]?.progress || []),
      ]) + score;

    setIsSubmitting(true);

    try {
      const result = await service.updateTPProgress.updateTPProgress({
        variables: {
          input: {
            isCompleted: isLastQuizQuestion,
            itemId: contents[selectedIndex].eid,
            sessionId: tpSessionData.eid,
            trainingId: tpSessionData.trainingId,
            responses: mergedResponses,
            score: totalScore,
          },
        },
      });

      if (result?.data?.AddTpProgress) {
        updateTpSessionData({
          ...tpSessionData,
          userProgress: [result.data.AddTpProgress],
        });
        setQuizSubmissionResult({
          showResult: true,
          isPartiallyCorrect: false,
          isCorrect,
          score,
        });
      }
    } catch (error) {
      console.error('Error submitting true/false quiz:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const getOptionStyles = (option: string) => {
    if (!isCompleted) {
      return {
        bg: 'white',
        border: '1px solid #E2E8F0',
      };
    }

    // For retake mode, we need to check against selectedData.isTrue directly
    const isThisOptionCorrect =
      (option === 'True' && selectedData?.isTrue) ||
      (option === 'False' && !selectedData?.isTrue);

    const isUserSelectionCorrect =
      (selectedOption === 'True' && selectedData?.isTrue) ||
      (selectedOption === 'False' && !selectedData?.isTrue);

    if (selectedOption === option) {
      return {
        bg: isUserSelectionCorrect
          ? 'rgba(181, 228, 202, 0.3)'
          : 'rgba(255, 106, 85, 0.2)',
        border: isUserSelectionCorrect
          ? '1px solid rgba(131, 191, 110, 1)'
          : '1px solid rgba(255, 106, 85, 1)',
      };
    } else if (isThisOptionCorrect && !isUserSelectionCorrect) {
      return {
        bg: 'rgba(181, 228, 202, 0.3)',
        border: '1px solid rgba(131, 191, 110, 1)',
      };
    }

    return {
      bg: 'white',
      border: '1px solid #E2E8F0',
    };
  };

  const renderSelectionIcon = (option: string) => {
    if (!isCompleted) {
      return (
        <Radio
          value={option}
          isChecked={selectedOption === option}
          onChange={() => {}}
          isReadOnly
        />
      );
    }

    const isUserSelectionCorrect =
      (selectedOption === 'True' && selectedData?.isTrue) ||
      (selectedOption === 'False' && !selectedData?.isTrue);

    if (selectedOption === option) {
      return (
        <FontAwesomeIcon
          icon={faCircleDot as IconProp}
          fontSize='16px'
          color={
            isUserSelectionCorrect
              ? 'rgba(131, 191, 110, 1)'
              : 'rgba(255, 106, 85, 1)'
          }
        />
      );
    }

    return (
      <FontAwesomeIcon
        icon={faCircle as IconProp}
        fontSize='16px'
        color='#E2E8F0'
      />
    );
  };

  return (
    <Container>
      <QuizQuestionTitle />

      <Stack spacing={4}>
        {['True', 'False'].map((option) => {
          const styles = getOptionStyles(option);
          const isThisOptionCorrect =
            (option === 'True' && selectedData?.isTrue) ||
            (option === 'False' && !selectedData?.isTrue);

          return (
            <Flex
              key={option}
              bg={styles.bg}
              borderRadius='12px'
              p='16px'
              border={styles.border}
              _hover={
                !isCompleted
                  ? {
                      bg: 'rgba(177, 229, 252, 0.3)',
                      border: '1px solid rgba(42, 133, 255, 1)',
                    }
                  : {}
              }
              onClick={() => {
                if (!isCompleted) {
                  setSelectedOption(option);
                }
              }}
              cursor={!isCompleted ? 'pointer' : 'default'}
            >
              <Flex w='full' justify='space-between' align='center'>
                <Flex align='center' gap={3}>
                  {renderSelectionIcon(option)}
                  <Box>{option}</Box>
                </Flex>
                {isCompleted &&
                  (selectedOption === option || isThisOptionCorrect) && (
                    <FontAwesomeIcon
                      icon={
                        (selectedOption === option &&
                          questionResponse?.isCorrect) ||
                        (isThisOptionCorrect && !questionResponse?.isCorrect)
                          ? (faCircleCheck as IconProp)
                          : (faCircleXmark as IconProp)
                      }
                      fontSize='20px'
                      color={
                        (selectedOption === option &&
                          questionResponse?.isCorrect) ||
                        (isThisOptionCorrect && !questionResponse?.isCorrect)
                          ? 'rgba(131, 191, 110, 1)'
                          : 'rgba(255, 106, 85, 1)'
                      }
                    />
                  )}
              </Flex>
            </Flex>
          );
        })}
      </Stack>

      <Button
        size='lg'
        colorScheme='blue'
        onClick={() => (isCompleted ? increment() : handleSubmit())}
        isDisabled={!selectedOption || isSubmitting}
        isLoading={isSubmitting}
      >
        {isCompleted ? 'Next' : 'Submit'}
      </Button>
    </Container>
  );
};

export default QuizTrueFalse;
