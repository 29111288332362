import React, { FC, useMemo, useState } from 'react';
import { Box, Center, Divider, Flex, List } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';

import { SelectOption } from '../../../../../../atoms';
import { ChatGroupEntity, GroupMemberEntity } from '../../../../../../types';
import { useUserDataSelector } from '../../../../../../hooks';

import DataNotFound from '../../channel-members/DataNotFound';
import SearchAndFilter from '../../channel-members/SearchAndFilter';
import { useMemberFilter } from '../../channel-members/useMemberData';
import { useJobRoleLocationList } from '../../select-group';
import { BaseGroupResponse } from '../../create-channel.graphql';

import MemberItem from './MemberItem';
import { useAddMember } from './useAddMember';
import ChatMembersAutoAddSettingsInfo from './ChatMembersAutoAddSettingsInfo';

interface IProps {
  group: ChatGroupEntity;
  members: GroupMemberEntity[];
  loading?: boolean;
  onActionClick: (action: string, data: GroupMemberEntity) => void;
  onMemberUpdated: (data: BaseGroupResponse) => void;
}

const ChannelMemberList: FC<IProps> = ({
  group,
  members,
  loading,
  onActionClick,
  onMemberUpdated,
}) => {
  const [filterBy, setFilterBy] = useState<SelectOption | null>(null);
  const [searchQuery, setSearchQuery] = useState('');

  const authUserId = useUserDataSelector((state) => state.eid);

  const _options = useJobRoleLocationList();
  const options = useMemo(() => {
    const details = group?.details;

    const check = [
      ...(details?.authRoles || []),
      ...(details?.roles || []),
      ...(details?.locationIds || []),
    ];

    if (check.length === 0) {
      return _options;
    }

    return _options.filter((op) => check.includes(op.value));
  }, [_options, group?.details]);

  const filteredMembers = useMemberFilter(members, filterBy, searchQuery);
  const showAutoAddInfo = useMemo(() => {
    if (!group?.details) return false;
    const { authRoles = [], roles = [], locations = [] } = group?.details;
    return authRoles?.length > 0 || roles?.length > 0 || locations?.length > 0;
  }, [group.details]);
  return (
    <div style={{ position: 'relative' }}>
      <Box
        position='sticky'
        top={0}
        bg='rgba(255, 255, 255, 0.6)'
        backdropFilter='blur(10px)'
        boxShadow='0 2px 6px rgba(0, 0, 0, 0.1)'
        pt={'16px'}
      >
        <SearchAndFilter
          searchQuery={searchQuery}
          onSearch={setSearchQuery}
          options={options}
          filterBy={filterBy}
          onFilterChange={setFilterBy}
        />
        {filteredMembers?.length > 0 && (
          <>
            {showAutoAddInfo && (
              <ChatMembersAutoAddSettingsInfo groups={group} />
            )}
            <Divider my={4} />
          </>
        )}
      </Box>
      <Box overflow={filteredMembers?.length > 0 ? 'auto' : 'hidden'} h='100%'>
        <DataNotFound
          isLoading={loading}
          searchQuery={searchQuery || filterBy?.value}
          dataLength={filteredMembers.length}
        >
          <List overflow='auto' spacing={5} minH='40vh' pb={2}>
            {filteredMembers?.map((member) => (
              <MemberItem
                key={member.uid}
                item={member}
                onActionClick={onActionClick}
                isGroupOwner={member.uid === group?.owner?.eid}
                isMyself={authUserId === member.uid}
              />
            ))}
          </List>
        </DataNotFound>
      </Box>
    </div>
  );
};

export default ChannelMemberList;
