import React, { FC } from 'react';
import { Box, Center, Flex } from '@chakra-ui/react';
import { ArrowBackIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import { toNumber } from '../../../../../../../utils';

interface IProps {
  title: string;
  questionLength?: number;
  selectedIndex?: number;
  onClick?: (nextIndex: number) => void;
}

const QuizPreviewHeader: FC<IProps> = ({
  title,
  questionLength,
  selectedIndex,
  onClick,
}) => {
  const array = Array.from({ length: toNumber(questionLength, 0) }).map(
    (_, i) => i
  );

  return (
    <Flex flexDir='column' gap='6px' padding='5px 10px'>
      <Flex gap='6px'>
        <Center height='19px' cursor='pointer'>
          <ArrowBackIcon color='#272B30' boxSize='16px' />
        </Center>
        <Box flex={1} fontSize='13px' fontWeight='600' isTruncated>
          {title}
        </Box>
        <Center height='19px' cursor='pointer'>
          <InfoOutlineIcon color='#272B30' boxSize='13px' />
        </Center>
      </Flex>
      <Flex gap='6px' paddingBottom='4px' align='center'>
        <Box fontSize='9px' fontWeight='500'>
          {toNumber(selectedIndex, 0) + 1}/{questionLength}
        </Box>
        <Flex flex={1} align='center' gap='4px'>
          {array.map((index) => (
            <Box
              key={index}
              flex={1}
              height='5px'
              borderRadius='5px'
              bg='#EFEFEF'
              cursor={onClick ? 'pointer' : undefined}
              aria-selected={index === selectedIndex}
              _selected={{ bg: '#2A85FF' }}
              onClick={() => onClick?.(index)}
            />
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default QuizPreviewHeader;
