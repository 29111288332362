import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  useControllableState,
} from '@chakra-ui/react';
import { forwardRef, useMemo } from 'react';
import BoardSelectButton from './BoardSelectButton';
import BoardSelectContent from './BoardSelectContent';

interface IProps {
  value?: string;
  onChange?: (value: string) => void;
  options: { name: string; eid: string }[];
}

const BoardDropDown = forwardRef<never, IProps>(
  ({ value, onChange, options }, ref) => {
    const [internalValue, updateValue] = useControllableState<string>({
      value: value,
      onChange: onChange,
    });

    const selected = useMemo(() => {
      return options.find((val) => internalValue === val.eid);
    }, [options, internalValue]);

    return (
      <Popover isLazy matchWidth>
        <PopoverTrigger>
          <BoardSelectButton ref={ref} board={selected?.name} />
        </PopoverTrigger>
        <PopoverContent
          width='inherit'
          borderRadius='12px'
          border='1px solid #EFEFEF'
        >
          <BoardSelectContent
            boards={options}
            value={internalValue}
            updateValue={updateValue}
          />
        </PopoverContent>
      </Popover>
    );
  }
);

BoardDropDown.displayName = 'BoardDropDown';

export default BoardDropDown;
