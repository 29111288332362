import { Box, Flex, Image } from '@chakra-ui/react';
import { HeaderColors } from 'configs';
import CertificateEarn from 'assets/images/certificate-earn.svg';
import React, { FC, useEffect, useMemo } from 'react';
import { usePlayContext } from 'sub-components/training-v2/play/store/context';
import { Button } from 'atoms';
import { useDownloadCertificate } from 'sub-components/training-v2/shared/download-certificate';
import ViewProgressEvaluationSummary from '../../ProgressSection/ViewProgress/ViewProgressEvaluationSummary';
import { useService } from 'sub-components/training-v2/play/layers';

const EvaluateDetailScreen: FC = () => {
  const { tpSessionData, retake } = usePlayContext((state) => ({
    tpSessionData: state.tpSessionData,
    retake: state.retake,
  }));
  const {
    updateViewEvaluation: { updateViewEvaluation, viewEvaluationData },
  } = useService();

  const downloadCertificate = useDownloadCertificate();

  const viewedEvaluationScreen = useMemo(() => {
    return !!tpSessionData?.assignedUsers?.[0]?.evaluationViewed;
  }, [tpSessionData]);

  const isEvaluated = useMemo(() => {
    return !!tpSessionData?.assignedUsers?.[0]?.isEvaluated;
  }, [tpSessionData]);

  const hasQuiz = tpSessionData?.contents?.some(
    (content) => content.type === 'quiz'
  );

  useEffect(() => {
    if (
      !viewedEvaluationScreen &&
      tpSessionData?.eid &&
      tpSessionData?.hasSkillVerifier &&
      isEvaluated
    ) {
      updateViewEvaluation({
        variables: {
          sessionId: tpSessionData?.eid,
        },
      });
    }
  }, [viewedEvaluationScreen, tpSessionData?.eid]);

  return (
    <Flex
      flexDir='column'
      w='60%'
      justify='space-between'
      h='full'
      p='40px 0px 0px 0px'
    >
      <Flex flexDir='column' gap={4}>
        <Flex
          bg='rgba(234, 243, 255, 1)'
          p='4px 16px'
          borderRadius='12px'
          border={`2px solid ${HeaderColors.DarkBlue}`}
          align='center'
          justify='space-between'
        >
          <Box as='span'>You have earned a new certificate!</Box>
          <Image src={CertificateEarn} />
        </Flex>
        <ViewProgressEvaluationSummary
          maxScore={tpSessionData?.maxScore || 0}
          skillVerifierResponse={
            tpSessionData?.userProgress?.[0]?.skillVerifierResponse || []
          }
          hasSkillVerifier={!!tpSessionData?.hasSkillVerifier}
          quizScore={tpSessionData?.userProgress?.[0]?.score || 0}
          certificate={tpSessionData?.userProgress?.[0]?.certificate || ''}
          isTpSessionCompleted={!!tpSessionData?.userProgress?.[0]?.isCompleted}
          sessionId={tpSessionData?.eid || ''}
          isEvaluationCompleted={
            !!tpSessionData?.userProgress?.[0]?.isEvaluated
          }
          isCertificateDownloaded={
            !!tpSessionData?.assignedUsers?.[0]?.certificateDownloaded
          }
          hideCertificateInfo
          hideProgressCircular
          showFeedback
          hideQuiz={!hasQuiz}
          evaluationSummaryFontSize='24px'
        />
      </Flex>
      <Flex align='center' gap={4} py={4}>
        <Button variant='outline' w='50%' size='lg' onClick={retake}>
          Retake path
        </Button>
        <Button
          variant='solid'
          colorScheme='blue'
          w='50%'
          size='lg'
          onClick={() => {
            downloadCertificate({
              certificateId: tpSessionData?.userProgress?.[0]?.certificate,
              tpSessionId: tpSessionData?.eid,
            });
          }}
        >
          Download certificate
        </Button>
      </Flex>
    </Flex>
  );
};

export default EvaluateDetailScreen;
