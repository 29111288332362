import React, { FC, useContext } from 'react';
import { Center } from '@chakra-ui/react';
import EditorPreview, { ICON_TYPE, JSONContent } from 'delightree-editor';
import { useTrainingPreview } from '../useTrainingPreview';
import { ActionType, QuizType } from 'sop-commons/src/card-components';
import Milestone from 'sop-commons/src/card-components/layouts/newLayouts/Milestone';
import { CardType, SopType } from '../../../../../../types';
import CustomFormType from '../../../../../../sub-components/forms/from-preview/CustomFormType';
import { PreviewContext } from '../../context';
import { FormNotAccessible } from 'sub-components/forms';
import ReactPlayer from 'react-player';
import ChapterFilePreview from '../ChapterFilePreview';

export interface CommonWrapperProps {
  value?: JSONContent;
  isPreview?: boolean;
  goToNextCard?: () => void;
  onItemClick?: (event: MouseEvent, node: any) => void;
  isNotDesktop?: boolean;
}

const CommonWrapper: FC<CommonWrapperProps> = ({
  value,
  isPreview,
  goToNextCard,
  onItemClick,
  isNotDesktop,
}) => {
  const content = useTrainingPreview(value);
  const previewCtx = useContext(PreviewContext);

  if (value?.sopType && value?.sopType !== 'text') {
    switch (value.sopType as SopType) {
      case 'video':
        return (
          <ReactPlayer
            url={value?.media?.[0]?.mediaUrl}
            controls
            playing
            height='100%'
            width='100%'
          />
        );
      case 'document':
        return (
          <ChapterFilePreview
            isNotDesktop={isNotDesktop}
            sopData={value as never}
          />
        );
    }
  }

  switch (content?.type) {
    case 'action':
      return (
        <ActionType
          content={content?.content}
          // @ts-ignore
          backgroundColor={content?.backgroundColor}
          // @ts-ignore
          cardId={content?.eid}
          // @ts-ignore
          subTitle={content.subTitle}
          isPreview={isPreview}
          shouldMoveToNextCard={goToNextCard}
        />
      );
    case 'quiz':
      return (
        <QuizType
          content={content?.content}
          title={content?.title}
          // @ts-ignore
          layout={content?.layout}
          // @ts-ignore
          cardId={content?.eid}
          isPreview={isPreview}
          shouldMoveToNextCard={goToNextCard}
        />
      );
    case 'milestone':
      return (
        <Milestone
          content={content?.title}
          milestoneUrl={content?.content?.[0]?.url}
        />
      );
    case CardType.CustomForm:
      if (!content?.isAccessible) {
        return (
          <Center width='full'>
            <FormNotAccessible type='UNAUTHORISED' />
          </Center>
        );
      }
      return (
        <CustomFormType
          content={content?.content}
          title={content?.title}
          isPreview={isPreview}
          shouldMoveToNextCard={goToNextCard}
        />
      );
    default:
      // @ts-ignore
      return (
        <EditorPreview
          readonly
          value={content?.content?.[0]?.tiptap || content?.content || content}
          chapterIcon={previewCtx?.chapterIcon}
          onItemClick={onItemClick}
          iconType={previewCtx?.iconType === ICON_TYPE ? 'icon' : 'emoji'}
        />
      );
  }
};

CommonWrapper.displayName =
  'displayName:pages/Training/CreateTrainingPath/Preview/component/common/CommonWrapper';

export default CommonWrapper;
