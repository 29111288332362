import { TooltipProps } from 'recharts-new';
import {
  NameType,
  ValueType,
} from 'recharts-new/types/component/DefaultTooltipContent';
import React, { FC, useState, useEffect, useCallback } from 'react';
import { Box, Button, Flex, VStack } from '@chakra-ui/react';
import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-light-svg-icons';

interface CustomTooltipProps extends TooltipProps<ValueType, NameType> {}

const CustomTooltip: FC<CustomTooltipProps> = ({ active, payload, label }) => {
  const [isLocalHovered, setIsLocalHovered] = useState(false);
  const [frozenPayload, setFrozenPayload] = useState(payload);

  useEffect(() => {
    if (active && !isLocalHovered) {
      setFrozenPayload(payload);
    }
  }, [active, isLocalHovered]);

  const isTooltipVisible = active || isLocalHovered;

  const handleMouseEnter = useCallback(() => {
    setIsLocalHovered(true);
  }, [payload]);

  const handleMouseLeave = useCallback(() => {
    setIsLocalHovered(false);
  }, [payload]);

  if (!isTooltipVisible || !frozenPayload?.length) return null;

  const dataEntry = frozenPayload[0];

  return (
    <VStack
      border='.5px solid rgba(239, 239, 239, 1)'
      bg='rgba(252, 252, 252, 1)'
      w='fit-content'
      align='flex-start'
      p='12px 16px'
      borderRadius='8px'
      boxShadow='md'
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        pointerEvents: 'auto',
      }}
    >
      <VStack gap={2} alignItems='flex-start'>
        <Box color='rgba(111, 118, 126, 1)' fontSize='13px' fontWeight={500}>
          {dataEntry.payload.location}: Google rating
        </Box>
        <Flex align='center' gap='5px'>
          <Box fontSize='20px' fontWeight={600}>
            {dataEntry.value}
          </Box>
          <FontAwesomeIcon
            icon={faStar as IconProp}
            color='rgba(244, 228, 105, 1)'
          />
        </Flex>
      </VStack>
      <Button
        cursor='pointer'
        variant='unstyled'
        bg='#F4F4F4'
        p='2px 4px'
        borderRadius='4px'
        color='#2A85FF'
        fontWeight={500}
        alignItems='center'
        lineHeight='120%'
        display='flex'
        mt={4}
        rightIcon={
          <FontAwesomeIcon icon={faArrowUpRightFromSquare as IconProp} />
        }
        onClick={(e) => {
          e.stopPropagation();
          window.open(dataEntry.payload.googleMapsUri, '_blank');
        }}
      >
        View google places
      </Button>
    </VStack>
  );
};

export default CustomTooltip;
