import React, { FC, useMemo } from 'react';
import { Box } from '@chakra-ui/react';
import { useWatch } from 'react-hook-form';
import { toArray } from '../../../../../../../utils';
import { TFormInput } from '../../../training-input.types';
import { isSubsetOfAssignees } from './training-assignee-check';
import { ITraining } from './dependent.graphql';

interface IProps {
  trainingId?: string;
  training?: ITraining;
}

const DependentTrainingAssigneeInfo: FC<IProps> = ({ training }) => {
  const [assigneeUserType, assignees] = useWatch<
    TFormInput,
    ['assigneeUserType', 'assignees']
  >({
    name: ['assigneeUserType', 'assignees'],
  });

  const isValid = useMemo(() => {
    if (!training?.eid) {
      return true;
    }

    const trainingAssignees = toArray(training?.assignees);
    const currentAssignees = toArray(assignees);

    return isSubsetOfAssignees(trainingAssignees, currentAssignees);
  }, [training, assigneeUserType, assignees]);

  if (isValid) {
    return null;
  }

  return (
    <Box fontSize='12px' fontWeight='500' color='red.500' mt='6px'>
      The selected training path has a different group of assignees than the one
      you’re currently creating.
    </Box>
  );
};

export default DependentTrainingAssigneeInfo;
