import React, { FC, useEffect } from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  IconButton,
  Spacer,
} from '@chakra-ui/react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { CloseIcon } from '@chakra-ui/icons';
import { QuizQuestionType } from '../../../../../../../types';
import { FILL_THE_BLANK_REGEX } from '../../../../../../../utils/constants';
import { FlexContainer } from '../../../common';
import { QuizQFormInput } from './quiz-form.types';
import QuizTypeDropdown from './QuizTypeDropdown';
import QuestionTitleInput from './QuestionTitleInput';
import QuizQuestionDataInput from './QuizQuestionDataInput';
import { useTypeChangeConfirm } from './useTypeChangeConfirm';
import { getDefaultValue } from './quiz-form.default';
import QuizQuestionFooter from './QuizQuestionFooter';
import { emitQuizPreview } from '../preview';
import ScoreSelect from './ScoreSelect';

interface IProps {
  index: number;
  initialValue: QuizQFormInput;
  onCloseClick?: () => void;
  onRemoveClick?: () => void;
  onAddQuestion: (values: QuizQFormInput) => void;
  onCopyClick: (values: QuizQFormInput, newValues: QuizQFormInput) => void;
}

const QuizQuestionForm: FC<IProps> = ({
  index,
  onCloseClick,
  initialValue,
  onAddQuestion,
  onRemoveClick,
  onCopyClick,
}) => {
  const methods = useForm<QuizQFormInput>({
    defaultValues: initialValue,
    mode: 'onChange',
  });

  const changeConfirm = useTypeChangeConfirm();

  useEffect(() => {
    const subscription = methods.watch((newFormData) => {
      emitQuizPreview({ questionIndex: index, question: newFormData as never });
    });

    return () => subscription.unsubscribe();
  }, []);

  const onTypeChange = async (value: QuizQFormInput['type']) => {
    await new Promise((resolve) => setTimeout(resolve, 50));
    changeConfirm({
      onConfirm: () => {
        methods.reset(getDefaultValue({ type: value }, methods.getValues()));
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <FlexContainer
        flexDir='column'
        bg='#FCFCFC'
        borderColor='#6F767E80'
        p='20px 24px'
        gap={4}
        position='relative'
      >
        {onCloseClick ? (
          <IconButton
            position='absolute'
            top={-2}
            right={-2}
            aria-label='cancel'
            colorScheme='blackAlpha'
            size='xs'
            bg='#6F767E'
            borderRadius='full'
            onClick={onCloseClick}
          >
            <CloseIcon width='0.85em' height='0.85em' />
          </IconButton>
        ) : null}

        <Flex gap={2} align='center'>
          <Box fontSize='14px' fontWeight='600' color='#272B30'>
            Question {index + 1}
          </Box>

          <Spacer />

          <Controller<QuizQFormInput, 'score'>
            name='score'
            render={({ field }) => (
              <Flex width='150px'>
                <ScoreSelect {...field} />
              </Flex>
            )}
          />

          <Controller<QuizQFormInput, 'type'>
            name='type'
            render={({ field }) => (
              <QuizTypeDropdown {...field} onChange={onTypeChange} />
            )}
          />
        </Flex>

        <Controller<QuizQFormInput, 'label'>
          name='label'
          rules={{
            required: 'Question title is required',
            validate: (value, formData) => {
              if (
                formData.type === QuizQuestionType.FILL_IN_BLANK &&
                !value?.match(FILL_THE_BLANK_REGEX)
              ) {
                return 'Missing required parameters, please enter continuous underscores ___';
              }

              if (!value?.trim()) {
                return 'Enter valid question title';
              }
            },
          }}
          render={({ field, fieldState }) => (
            <FormControl isInvalid={fieldState.invalid}>
              <QuestionTitleInput {...field} />
              <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
            </FormControl>
          )}
        />

        <QuizQuestionDataInput />

        <QuizQuestionFooter
          onAddQuestion={onAddQuestion}
          onRemoveClick={onRemoveClick}
          onCopyClick={onCopyClick}
        />
      </FlexContainer>
    </FormProvider>
  );
};

export default QuizQuestionForm;
