import { AddIcon } from '@chakra-ui/icons';
import { Box, Flex } from '@chakra-ui/react';
import { Button } from 'atoms';
import React, { FC, useState } from 'react';
import { Preview } from '../../preview';
import { IQuizModalProps, QuizQuestion } from '../../types';
import ActionFooter from './ActionFooter';
import ChooseQuestion from './ChooseQuestion';

const ModalContent: FC<IQuizModalProps> = ({ mode }) => {
  const [quizQuestions, setQuizQuestions] = useState<QuizQuestion[]>([]);
  const [previewOption, setPreviewOption] = useState<QuizQuestion | undefined>(
    undefined
  );
  return (
    <Flex flexDir='column' h='83vh'>
      <Flex gap={2}>
        <Flex px='24px' h='70vh' flexDir='column'>
          <Box color='rgba(111, 118, 126, 1)' fontWeight={600} fontSize='18px'>
            Add questions
          </Box>
          <ChooseQuestion setPreviewOption={setPreviewOption} />
        </Flex>
        <Flex>
          <Preview data={previewOption} />
        </Flex>
      </Flex>
      <ActionFooter />
    </Flex>
  );
};

ModalContent.displayName =
  'displayName:sub-components/training-v2/shared/quiz/components/ModalContent';

export default ModalContent;
