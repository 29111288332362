import React, { FC } from 'react';
import { Box, Flex, IconButton, Spacer } from '@chakra-ui/react';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { getI18n } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const CustomHeader: FC<ReactDatePickerCustomHeaderProps> = ({
  monthDate,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => {
  return (
    <Flex gap={5} align='center' borderBottom='1px solid #E4E5E7' pb='16px'>
      <IconButton
        size='sm'
        aria-label='prev'
        borderRadius='full'
        variant='ghost'
        isDisabled={prevMonthButtonDisabled}
        onClick={prevMonthButtonDisabled ? undefined : decreaseMonth}
      >
        <FontAwesomeIcon icon={faChevronLeft as IconProp} color='#848A95' />
      </IconButton>

      <Spacer />

      <Box color='#0F2552' fontSize='15px' fontWeight='600'>
        {monthDate?.toLocaleString(getI18n().language, {
          month: 'long',
          year: 'numeric',
        })}
      </Box>

      <Spacer />

      <IconButton
        size='sm'
        aria-label='next'
        borderRadius='full'
        variant='ghost'
        isDisabled={nextMonthButtonDisabled}
        onClick={nextMonthButtonDisabled ? undefined : increaseMonth}
      >
        <FontAwesomeIcon icon={faChevronRight as IconProp} />
      </IconButton>
    </Flex>
  );
};

export default CustomHeader;
