import React, {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Box } from '@chakra-ui/react';
import { useLazyQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { match } from 'ts-pattern';
import { useTranslation } from 'react-i18next';

import { SelectOption } from 'atoms/Dropdown';
import { toArray } from 'utils/utils';

import MemberList from './MemberList';
import {
  MEMBER_LIST_QUERY,
  MemberListResponse,
  MemberListVariable,
  MemberSort,
  EntityUsersResponse,
  EntityUsersVariable,
  EntityUserSort,
  ENTITY_USERS_LIST,
} from './member.graphql';
import { TeamItem } from './team.types';
import SingleUserChatModal from '../../../ui-components/SingleUserChatModal';
import { SearchFilterHeader } from './component';
import eventBus from '../locations/AddLocationModal/eventBus';
import InviteUserModal from 'ui-components/InviteUserNew/InviteUserModal';
import DashboardDataContext from 'sub-components/DashboardContainer/DashboardStore/DashboardData/dashboard-data-context';
import { ReInviteUserModal } from '../../../ui-components/InviteUserNew';
import { useUpdateInvitedMember } from '../InvitedMember';
import { InvitedUser } from '../InvitedMember/update-invite.graphql';
import { AuthRole } from '../../../authorization';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';
import { useUserDataSelector } from 'hooks';

interface IProps {}

const MemberListContainer: FC<IProps> = () => {
  const history = useHistory();
  const { t } = useTranslation(['common']);

  const [filterBy, setFilterBy] = useState<SelectOption | null>();
  const [authRoleFilterBy, setAuthRoleFilterBy] = useState<SelectOption | null>(
    () => {
      return {
        label: t('common:all'),
        value: 'all',
      };
    }
  );
  const [sortBy, setSortBy] = useState<EntityUserSort>();
  const [searchQuery, setSearchQuery] = useState('');
  // const [selectedPage, setSelectedPage] = useState(1);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('');
  const dashboardCtx = useContext(DashboardDataContext);
  const selectedPage =
    dashboardCtx?.navigationPersistData?.members?.selectedPage;

  const [chatUserId, setChatUserId] = useState<string>();
  const [reInviteUser, setReInviteUser] = useState<InvitedUser>();

  const updateInvitedMember = useUpdateInvitedMember();

  useEffect(() => {
    eventBus.on('openAddMemberModal', locationModalHandler);
    return () => {
      eventBus.removeListener('openAddMemberModal', locationModalHandler);
    };
  }, []);

  const locationModalHandler = () => {
    setShowInviteModal(true);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const onPageChangeHandler = (page: number) => {
    // setSelectedPage(page);
    let navigationData = dashboardCtx?.navigationPersistData;
    let membersData = dashboardCtx?.navigationPersistData?.members;
    dashboardCtx?.navigationPersistDataHandler({
      ...navigationData,
      members: { ...membersData, selectedPage: page },
    });
  };

  // const [fetchMemberList, { loading, data, refetch }] = useLazyQuery<
  //   MemberListResponse,
  //   MemberListVariable
  // >(MEMBER_LIST_QUERY, {
  //   fetchPolicy: 'network-only',
  // });
  const loggedInUserDetails = useUserDataSelector((state) => ({
    locationEids: state.locations?.map((key) => key.eid),
    authRole: state.authRole,
    rootLocation: state?.entity?.locations?.find((loc) => loc?.isRoot)?.eid,
  }));

  const [fetchMemberList, { loading, data, refetch }] = useLazyQuery<
    EntityUsersResponse,
    EntityUsersVariable
  >(ENTITY_USERS_LIST, {
    fetchPolicy: 'network-only',
  });

  const getVariables = useCallback((): EntityUsersVariable => {
    const filters: Partial<EntityUsersVariable['filter']> = {
      query: debouncedSearchQuery,
      statuses: ['active', 'pending'],
      authRole:
        authRoleFilterBy?.value === 'all'
          ? undefined
          : [authRoleFilterBy?.value as AuthRole],
    };

    if (loggedInUserDetails.authRole === AuthRole.LOCATION_OWNER) {
      filters.locationId = loggedInUserDetails.rootLocation
        ? [
            ...(loggedInUserDetails.locationEids || []),
            loggedInUserDetails.rootLocation,
          ]
        : loggedInUserDetails.locationEids;
    }
    return {
      page: selectedPage,
      perPage: 10, // Add appropriate page size
      filter: filters,
      sort:
        sortBy ||
        match<string, EntityUserSort>(filterBy?.value as string)
          .with('Alphabetically', () => 'NAME_ASC')
          .with('Last Created', () => 'CREATEDAT_DESC')
          .otherwise(() => 'CREATEDAT_DESC'),
    };
  }, [
    debouncedSearchQuery,
    selectedPage,
    filterBy,
    sortBy,
    authRoleFilterBy,
    loggedInUserDetails.authRole,
  ]);

  useEffect(() => {
    fetchMemberList({
      variables: getVariables(),
    });
  }, [getVariables]);

  const clickedHandler = async (clickedItem: string, member: TeamItem) => {
    switch (clickedItem) {
      case 'message':
        deployEvent(AmplitudeEventNames.MEMBER_CHAT_BUTTON);
        return setChatUserId(member.eid);
      case 'reInvite':
        deployEvent(AmplitudeEventNames.MEMBER_REINVITE_BUTTON);
        return updateInvitedMember({
          data: member,
          onResendClick: setReInviteUser,
          onDataRefetch: () => {
            fetchMemberList({
              variables: getVariables(),
            });
          },
          onError: () => {
            fetchMemberList({
              variables: getVariables(),
            });
          },
        });
      case 'open':
        deployEvent(AmplitudeEventNames.MEMBER_OPEN);
        return history.push(`/teams/members/${member.eid}`, {
          name: member.name,
        });
      case 'edit':
        deployEvent(AmplitudeEventNames.MEMBER_EDIT);
        return history.push(`/teams/members/${member.eid}`, {
          name: member.name,
        });
      default:
        // eslint-disable-next-line no-console
        console.log(clickedItem);
    }
  };

  const items = data?.EntityUserPagination?.items || [];

  const pageInfo = data?.EntityUserPagination;

  const dataList = useMemo(() => {
    return toArray(items);
  }, [items]);

  const onActionCompleted = () => {
    setTimeout(() => {
      refetch && refetch(getVariables());
    }, 4000);
  };

  const shouldRefetch = () => {
    setTimeout(() => {
      refetch && refetch(getVariables());
    }, 4000);
  };

  return (
    <Box flex={1}>
      <SearchFilterHeader
        sortBy={filterBy}
        onSortChange={(value) => {
          setSortBy(undefined);
          setFilterBy(value);
        }}
        filterBy={authRoleFilterBy}
        onFilterChange={setAuthRoleFilterBy}
        searchQuery={searchQuery}
        onSearch={onSearchChange}
      />
      <MemberList
        dataList={dataList}
        loading={loading}
        currentPage={selectedPage}
        itemCount={pageInfo?.count}
        isSearchQuery={!!searchQuery}
        onPageChange={onPageChangeHandler}
        onClickedHandler={clickedHandler}
        shouldRefetch={shouldRefetch}
        onSortByChange={(_value) => {
          setFilterBy(null);
          setSortBy(_value);
        }}
      />
      {chatUserId && (
        <SingleUserChatModal
          selectedUser={chatUserId}
          onClose={() => setChatUserId(undefined)}
        />
      )}
      {showInviteModal && (
        <InviteUserModal
          isOpen={showInviteModal}
          onClose={() => setShowInviteModal(false)}
          onActionCompleted={onActionCompleted}
          shouldRefetch={shouldRefetch}
        />
      )}

      {reInviteUser?.eid && (
        <ReInviteUserModal
          isOpen={!!reInviteUser?.eid}
          data={reInviteUser}
          onClose={() => setReInviteUser(undefined)}
        />
      )}
    </Box>
  );
};

MemberListContainer.displayName =
  'displayName:pages/Teams/members/MemberListContainer';

export default MemberListContainer;
