import React, { FC, useCallback, useMemo } from 'react';
import { usePlayContext } from 'sub-components/training-v2/play/store/context';
import {
  HeadingContainer,
  QuizFillBlank,
  QuizInitScreen,
  QuizMatch,
  QuizMCQ,
  QuizReorder,
  QuizTrueFalse,
} from '.';

const QuizContent: FC = () => {
  const { contents, selectedIndex, selectedSubIndex } = usePlayContext(
    (state) => ({
      selectedIndex: state.selectedIndex,
      selectedSubIndex: state.selectedSubIndex,
      contents: state.tpSessionData?.contents || [],
    })
  );

  const selectedData = useMemo(() => {
    return contents?.[selectedIndex]?.questions?.[selectedSubIndex];
  }, [contents, selectedIndex, selectedSubIndex]);

  const wrapper = (children: React.ReactNode) => {
    return (
      <HeadingContainer title={contents?.[selectedIndex]?.title}>
        {children}
      </HeadingContainer>
    );
  };

  const render = useCallback(() => {
    switch (selectedData?.type) {
      case 'initial':
        return wrapper(<QuizInitScreen key={selectedData.eid} />);
      case 'multiChoice':
        return wrapper(<QuizMCQ key={selectedData.eid} />);
      case 'trueFalse':
        return wrapper(<QuizTrueFalse key={selectedData.eid} />);
      case 'matchSet':
        return wrapper(<QuizMatch key={selectedData.eid} />);
      case 'fillInBlanks':
        return wrapper(<QuizFillBlank key={selectedData.eid} />);
      case 'sequence':
        return wrapper(<QuizReorder key={selectedData.eid} />);
      default:
        return null;
    }
  }, [selectedData]);

  return render();
};

export default QuizContent;
