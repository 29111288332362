import { ApolloProvider, useApolloClient } from '@apollo/client';
import { Box, Button, CloseButton, Flex, Text } from '@chakra-ui/react';
import { ReactNode, useCallback, useRef } from 'react';
import { Any } from '../../../../../../types';
import { useConfirm, UseConfirm } from '../../../../../../ui-components';
import useCrosstrainConfirm from './useCrosstrainConfirm';
import { eventBus } from 'shared/eventEmit';

export interface RModalProps {
  title: string;
  content?: ReactNode;
  subtext?: string;
  refetchCallback?: () => void;
}

type TrainModal = (props: RModalProps) => void;

export const useTrainModal = (): TrainModal => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();
  const apolloClient = useApolloClient();

  const clear = useCrosstrainConfirm(() => {
    confirmRef.current?.destroy();
  });

  return useCallback((props: RModalProps) => {
    if (confirmRef.current) {
      confirmRef.current?.destroy();
    }

    confirmRef.current = confirm({
      Wrapper: ({ children }: Any) => (
        <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
      ),
      title: (
        <Flex justify='space-between' align='center' w='full'>
          <Box>
            <Text fontSize='20px' fontWeight='600' color='#111315'>
              {props.title}
            </Text>
            {props.subtext && (
              <Text fontSize='15px' fontWeight={500} color='#6F767E' mt='8px'>
                {props.subtext}
              </Text>
            )}
          </Box>
          <Button
            variant='ghost'
            _hover={{ bg: 'white' }}
            position='absolute'
            top={12}
            right={8}
            size='30px'
            onClick={() => {
              eventBus.emit('modalCloseRequested');
            }}
          >
            <CloseButton width={'18px'} height={'18px'} />
          </Button>
        </Flex>
      ),
      content: (
        <Box width='full' overflow='hidden'>
          {props.content}
        </Box>
      ),

      isCentered: true,
      headerProps: { paddingX: 10, paddingTop: 10, paddingBottom: 3 },
      contentProps: {
        borderRadius: '12px',
        maxWidth: '746px',
        height: 'auto',
        maxHeight: 'calc(100vh - 6rem)',
        overflow: 'hidden',
        bg: 'white',
        containerProps: {
          display: 'flex',
          flexDirection: 'column',
          paddingTop: '3rem',
          paddingBottom: '3rem',
        },
      },
      bodyProps: {
        paddingX: 0,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      },
      footer: null,
    });
  }, []);
};
