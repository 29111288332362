import { TaskStatus } from '../../../types';

export type Stepper =
  | 'details'
  | 'scheduler'
  | 'taskSteps'
  | 'assignees'
  | 'supervisors';

export const Weekdays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
] as const;

export const AssignToData = ['member', 'location', 'job'] as const;

export type AssignToType = typeof AssignToData[number];

export type IWeekDays = typeof Weekdays[number];

export type ITaskAction = 'restart' | 'terminate' | 'softDelete';

export const TaskDueData = [
  'nextWeek',
  'endOfMonth',
  'endOfYear',
  'date',
] as const;

export const EndTypeData = ['never', 'repeat', 'date'] as const;

export interface EndCondition<T extends unknown> {
  endType: typeof EndTypeData[number];
  endDate?: T;
  repeatCount?: number;
}

export const RepeatCycleData = [
  'daily',
  'weekly',
  'yearly',
  'monthly',
  'days',
] as const;

export type RepeatCycle = typeof RepeatCycleData[number];

export type AssignedLocationsType =
  | 'everyone'
  | 'locationOwner'
  | 'worker'
  | 'locationUser';

export type ContentType =
  | 'sop'
  | 'card'
  | 'deck'
  | 'milestone'
  | 'form'
  | 'text'
  | 'todo'
  | 'training';

export interface RepeatDetails<T extends unknown> {
  taskRunFor: 'once' | 'repeat';

  // Task Due Date
  taskDue: typeof TaskDueData[number];
  taskDueDate?: T;

  dueTime?: T;

  // Repeat
  repeatCycle?: RepeatCycle;
  weekDays?: IWeekDays[];
  repeatMonthly?: T | 'lastDay';
  repeatYearly?: T;
  noOfDays?: number;

  // Publish Date
  startDateType: 'now' | 'date';
  startDate?: T;
  startTime?: T;

  // Extra
  assignToType?: AssignToType[];
}

export interface IFormInput extends RepeatDetails<Date> {
  // formStep: Record<Stepper, StepperStatus>;
  currentStep?: Stepper;
  completed: Record<Stepper, boolean>;
  filled?: Record<Stepper, boolean>;

  title: string;
  description?: string;
  lastSession: {
    isLastSession: boolean;
  };

  // =========> START Schedule

  endCondition: EndCondition<Date>;

  // <=========== END Schedule

  contents: Array<{
    eid: string;
    tempEid: string; // Copy of eid or temporary id for identification
    title: string;
    thumbnail?: string;
    isMandatory?: boolean;
    backgroundColor?: string;
    type: ContentType;
    dateAdded: string;
    formData?: any;
    sopData?: any;
    trainingData?: any;
  }>;

  // Assignees

  assignToType?: AssignToType[];

  assignedLocationsType: AssignedLocationsType;
  assignedToLocation?: string[];

  assignedToUsers?: string[];

  assignedToRole?: string[];

  // Supervisor

  supervisorType:
    | 'all'
    | 'superAdmins'
    | 'admins'
    | 'superAdminsAndAdmins'
    | 'locationOwners';

  supervisors?: string[];

  // TODO for edit task
  status?: TaskStatus;
  repeatString?: string;
  isEditing?: boolean;

  // TODO Time offset
  timeOffsetDiff?: number;
}

export interface variable {
  assignedToLocation: null;
  assignedToRole: null;
  assignedToUsers: null;
  contents: Array<{
    backgroundColor: null;
    eid: null;
    isMandatory: null;
    title: null;
    type: null;
  }>;
  description: null;
  endCondition: {
    endDate: null;
    endType: null;
    repeatCount: null;
  };
  isRepeating: null;
  repeatCycle: null;
  repeatDetails: null;
  repeatString: null;
  startDate: null;
  supervisors: null;
  thumbnail: null;
  title: null;
}
