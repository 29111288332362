import React, { FC } from 'react';

import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers';
import {
  SortableContext,
  SortableContextProps,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

interface IProps {
  disabled?: boolean;
  onDragEnd?: (oldIndex: number, newIndex: number) => void;
  items: SortableContextProps['items'];
}

const ContentListSortable: FC<IProps> = ({
  items = [],
  onDragEnd,
  disabled,
  children,
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={(event) => {
        const oldIndex = event?.active.data.current?.index;
        const newIndex = event?.over?.data.current?.index;

        if (
          typeof oldIndex === 'number' &&
          typeof newIndex === 'number' &&
          oldIndex !== newIndex &&
          onDragEnd
        ) {
          onDragEnd(oldIndex, newIndex);
        }
      }}
      modifiers={[restrictToVerticalAxis, restrictToParentElement]}
    >
      <SortableContext
        disabled={items.length < 2 || disabled}
        items={items}
        strategy={verticalListSortingStrategy}
      >
        {children}
      </SortableContext>
    </DndContext>
  );
};

export default ContentListSortable;
