import React, { FC } from 'react';
import { Center, Flex } from '@chakra-ui/react';
import { Image } from '../../../../../../../../ui-components';
import Loader from '../../../../../../../Loader';

interface IProps {
  thumbnail: string;
  boxSize: number;
}

const ThumbnailLoading: FC<IProps> = ({ thumbnail, boxSize }) => {
  return (
    <Flex position='relative' borderRadius='8px' userSelect='none'>
      <Image
        src={thumbnail}
        height={boxSize}
        width={boxSize}
        style={{
          minWidth: boxSize,
          objectFit: 'cover',
          borderRadius: '8px',
        }}
      />

      <Center
        position='absolute'
        boxSize='full'
        bg='blackAlpha.200'
        borderRadius='8px'
        cursor='not-allowed'
      >
        <Loader size='lg' thickness='3px' />
      </Center>
    </Flex>
  );
};

export default ThumbnailLoading;
