import { FC, useMemo } from 'react';

import { Box, Center, Flex, Image, Text } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faBookOpen, faBullhorn } from '@fortawesome/pro-duotone-svg-icons';
import {
  faBallot,
  faChevronRight,
  faFlag,
  faVideo,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import lockedTaskIcon from 'assets/images/tasks/lock.svg';

//TODO: types
interface IProps {
  userProgress: any;
  contents: any;
  onProgressTimelineItemClick?: (item: any, index: number) => void;
  showAllContents?: boolean;
}

const TimelineItem = ({
  type,
  title,
  completed = false,
  score,
  maxScore,
}: {
  type: string;
  title: string;
  completed?: boolean;
  score: boolean;
  maxScore: boolean;
}) => {
  const color = completed ? '#83BF6E' : '#6F767E';

  const getIconByTrainingStep = () => {
    if (type === 'sop') return faBookOpen;
    else if (type === 'quiz') return faBullhorn;
    else if (type === 'card') return faVideo;
    else if (type === 'form') return faBallot;
    else return faVideo;
  };
  if (type === 'milestone')
    return (
      <Center
        background='#8E59FF33'
        color='#8E59FF'
        width='100%'
        gap={3}
        borderRadius='12px'
        marginY='4px'
        padding='12px 16px'
      >
        <FontAwesomeIcon icon={faFlag as IconProp} color='#8E59FF' />
        {title}
      </Center>
    );

  return (
    <Box>
      <Flex gap={4} alignItems='center'>
        <Center
          width='32px'
          height='32px'
          background={color}
          borderRadius='100%'
        >
          <FontAwesomeIcon
            icon={getIconByTrainingStep() as IconProp}
            color='#FFFFFF'
            size='1x'
          />
        </Center>
        <Flex alignItems='center' justifyContent='space-between' flex={1}>
          <Flex flexDirection='column'>
            <Flex alignItems='center' gap={2}>
              <Text
                as='span'
                fontSize='12px'
                color='#6F767E'
                textTransform='capitalize'
              >
                {type === 'sop' ? 'Chapter' : type === 'card' ? 'Video' : type}
              </Text>

              {type === 'quiz' && (
                <Flex alignItems='center' gap={2}>
                  <Box
                    width='4px'
                    height='4px'
                    borderRadius='100%'
                    background='#33383F'
                  ></Box>
                  <Box fontSize='12px' color='#6F767E'>
                    <Text as='span'>Score: </Text>
                    <Text as='span' color='#33383F' fontWeight={500}>
                      {score
                        ? Number(score) % 1 === 0
                          ? Number(score)
                          : Number(score).toFixed(1)
                        : 0}
                    </Text>
                    <Text as='span'>/{maxScore}</Text>
                  </Box>
                </Flex>
              )}
            </Flex>

            <Text as='span' fontSize='15px' color='#33383F' fontWeight={600}>
              {title}
            </Text>
          </Flex>
          {completed ? (
            <>
              {type === 'form' || type === 'quiz' ? (
                <FontAwesomeIcon icon={faChevronRight as IconProp} />
              ) : null}
            </>
          ) : (
            <Image src={lockedTaskIcon} />
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

const TimelineSeparator = ({ completed = false }: { completed?: boolean }) => {
  const color = completed ? '#83BF6E' : '#6F767E';

  return (
    <Box marginLeft='15px' width='3px' background={color} height='34px'></Box>
  );
};

const ProgressTimeLine: FC<IProps> = ({
  userProgress,
  contents,
  onProgressTimelineItemClick,
  showAllContents,
}) => {
  const isCompleted = userProgress?.isCompleted;

  return (
    <Box w='full'>
      {isCompleted && !showAllContents ? (
        <>
          {userProgress?.progress?.map((item, idx) => {
            const content = userProgress?.contents?.find(
              (content) => content.eid === item.id
            );

            if (!content) return null;

            const isStepCompleted = item.isCompleted;
            const score = item.score || 0;

            const quizMaxScore = content?.questions
              ?.map((question) => Number(question?.score) || 0)
              .reduce((a, b) => a + b, 0);

            return (
              <div key={content.eid}>
                <Box
                  cursor={
                    content.type === 'quiz' || content.type === 'form'
                      ? 'pointer'
                      : 'default'
                  }
                  onClick={() => {
                    if (content.type === 'quiz' || content.type === 'form') {
                      onProgressTimelineItemClick?.(content, idx);
                    }
                  }}
                >
                  <TimelineItem
                    type={content.type}
                    title={content.title}
                    completed={isStepCompleted}
                    score={score}
                    maxScore={quizMaxScore}
                  />
                </Box>
                {idx !== contents?.length - 1 && (
                  <TimelineSeparator completed={isStepCompleted} />
                )}
              </div>
            );
          })}
        </>
      ) : (
        <>
          {contents
            ?.filter((item) => !item.isRemoved)
            .map((content, idx) => {
              const isStepCompleted =
                isCompleted ||
                userProgress?.progress?.find((item) => item.id === content.eid)
                  ?.isCompleted ||
                false;

              const score =
                userProgress?.progress?.find((item) => item.id === content.eid)
                  ?.score || 0;

              let userContent;

              if (isCompleted) {
                userContent = userProgress?.contents?.find(
                  (userContent) => userContent?.eid === content.eid
                );

                if (!userContent) {
                  userContent = content;
                }
              } else {
                userContent = content;
              }

              const quizMaxScore = userContent?.questions
                ?.map((question) => Number(question?.score) || 0)
                .reduce((sum, score) => sum + score, 0);

              return (
                <div key={content.eid}>
                  <Box
                    cursor={
                      content.type === 'quiz' || content.type === 'form'
                        ? 'pointer'
                        : 'default'
                    }
                    onClick={() => {
                      if (content.type === 'quiz' || content.type === 'form') {
                        onProgressTimelineItemClick?.(content, idx);
                      }
                    }}
                  >
                    <TimelineItem
                      type={content.type}
                      title={content.title}
                      completed={isStepCompleted}
                      score={score}
                      maxScore={quizMaxScore}
                    />
                  </Box>
                  {idx !== contents.length - 1 && (
                    <TimelineSeparator completed={isStepCompleted} />
                  )}
                </div>
              );
            })}
        </>
      )}
    </Box>
  );
};

ProgressTimeLine.displayName =
  'displayName:sub-components/training-v2/play/components/ProgressSection/ProgressTimeLine/ProgressTimeLine';

export default ProgressTimeLine;
