import { Flex, Text, Box } from '@chakra-ui/react';
import React, { FC, useEffect, useRef, useState } from 'react';
import { LocationsEntity } from 'sop-commons/src/client';
import useCombinedStore from 'zustandStore/store';

interface LocationChipsProps {
  title: string;
  handleLocationChipClick: (title: string) => void;
  selectedLocation: string;
  locationId: string;
  count: number;
}

export const LocationChips: FC<LocationChipsProps> = ({
  title,
  handleLocationChipClick,
  selectedLocation,
  locationId,
  count,
}) => {
  return (
    <Flex
      data-location={locationId} // Adding the data-location attribute helpful for finding the element to scroll
      onClick={() => handleLocationChipClick(locationId)}
      userSelect={'none'}
      cursor={'pointer'}
      alignItems={'center'}
      gap={1}
      justifyContent={'center'}
      borderRadius={'8px'}
      p={'4px 6px'}
      color={locationId === selectedLocation ? '#1D192B' : '#49454F'}
      border={
        locationId === selectedLocation
          ? '1px solid #E8DEF8'
          : '1px solid #49454F'
      }
      backgroundColor={locationId === selectedLocation ? '#E8DEF8' : ''}
    >
      <Text fontWeight={500} whiteSpace={'nowrap'}>
        {title}
      </Text>
      <Text fontWeight={500}>({count})</Text>
    </Flex>
  );
};

interface IProps {
  ref: any;
  loggedInUserLocations: LocationsEntity[] | undefined;
  selectedLocation: string;
  setSelectedLocation: React.Dispatch<React.SetStateAction<string>>;
  finalMemberData?: any;
}

const LocationFilters: FC<IProps> = React.forwardRef(
  (
    {
      loggedInUserLocations,
      selectedLocation,
      setSelectedLocation,
      finalMemberData,
    },
    ref
  ) => {
    const { locationIdArray } = useCombinedStore();

    const handleLocationChipClick = (locationId: string) => {
      setSelectedLocation((prevLocation) =>
        prevLocation === locationId ? 'all' : locationId
      );
    };

    // Scroll to selected location whenever selectedLocation changes
    useEffect(() => {
      if (ref.current) {
        const locationElement = ref.current.querySelector(
          `[data-location="${selectedLocation}"]`
        ) as HTMLElement;

        if (locationElement) {
          locationElement.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
          });
        }
      }
    }, [selectedLocation, ref]);

    useEffect(() => {
      if (locationIdArray?.length > 1) {
        handleLocationChipClick('all');
      } else if (locationIdArray?.length === 1) {
        handleLocationChipClick(locationIdArray?.[0]);
      }
    }, [locationIdArray]);

    return (
      <Flex position='relative' w='100%' overflow='hidden'>
        <Flex
          scrollBehavior={'smooth'}
          ref={ref}
          overflowX='auto'
          px={'10px'}
          gap='8px'
          alignItems='center'
        >
          <LocationChips
            selectedLocation={selectedLocation}
            handleLocationChipClick={handleLocationChipClick}
            title='All'
            locationId='all'
            count={finalMemberData?.['all']?.length}
          />
          {loggedInUserLocations?.map((location) => (
            <LocationChips
              selectedLocation={selectedLocation}
              handleLocationChipClick={handleLocationChipClick}
              key={location?.eid}
              title={location?.name}
              locationId={location?.eid}
              count={finalMemberData?.[location?.eid]?.length}
            />
          ))}
        </Flex>

        <Box
          position='absolute'
          top='0'
          right='0'
          h='100%'
          w='100px'
          pointerEvents='none'
          bgGradient='linear(to-l, white, rgba(255, 255, 255, 0))'
        />
      </Flex>
    );
  }
);

LocationFilters.displayName =
  'packages/sop-web/src/sub-components/training-v2/dashboard/components/locationOwner/LocationFilters';
export default LocationFilters;
