import { useMutation, useQuery } from '@apollo/client';
import { toArray } from '../../../../../../utils';
import {
  TpCategoryResponse,
  TP_CATEGORY,
  ADD_TP_CATEGORY,
  AddTpCategoryResponse,
} from './tp-category.graphql';

export const useTrainingCategories = () => {
  const { data, loading } = useQuery<TpCategoryResponse>(TP_CATEGORY);

  return {
    loading,
    categories: toArray(data?.EntityTpCategories),
  };
};

export const useAddTpCategory = () => {
  const [addCategory] = useMutation<AddTpCategoryResponse>(ADD_TP_CATEGORY, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: TP_CATEGORY,
      },
    ],
  });

  return {
    addCategory,
  };
};
