import {
  Content,
  TPUserProgressEntity,
} from 'sub-components/training-v2/shared/types';

export const isItemCompleted = (
  itemId: string,
  userProgress: TPUserProgressEntity[]
) => {
  return userProgress?.[0]?.progress?.some(
    (item) => item.id === itemId && item.isCompleted
  );
};

export const findFirstIncompleteContent = (
  contents: Content[],
  userProgress: TPUserProgressEntity[]
): number => {
  for (let i = 0; i < contents.length; i++) {
    const content = contents?.[i];
    const isContentCompleted = userProgress?.[0]?.progress?.some(
      (progress) => progress?.id === content?.eid && progress?.isCompleted
    );

    if (!isContentCompleted) {
      return i;
    }
  }
  return 0;
};
