import React, { forwardRef, useMemo } from 'react';
import moment from 'moment';
import { Dropdown } from '../../../../../../../atoms';

interface IProps {
  value: number;
  onChange: (value: number) => void;
}

const MonthSelect = forwardRef<never, IProps>(({ value, onChange }, ref) => {
  const monthOptions = useMemo(() => {
    return moment.months().map((month, index) => ({
      label: month,
      value: index,
    }));
  }, []);

  const selected = useMemo(() => {
    return monthOptions.find((op) => op.value === value);
  }, [monthOptions, value]);

  return (
    <Dropdown
      ref={ref}
      size='lg'
      value={selected}
      onChange={(nextValue) => onChange(nextValue.value)}
      options={monthOptions}
      selectStyles={{
        container: {
          background: 'transparent',
        },
        control: {
          border: '2px solid',
        },
        menuList: {
          padding: '16px',
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          minWidth: 0,
        },
        option: {
          fontSize: '15px',
          fontWeight: 600,
          padding: '12px',
          borderRadius: '10px',
        },
        singleValue: {
          fontSize: '15px',
          fontWeight: 600,
          color: '#6F767E',
        },
      }}
    />
  );
});

MonthSelect.displayName = 'MonthSelect';

export default MonthSelect;
