import React, { FC } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Flex, IconButton } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { v4 as uuidv4 } from 'uuid';
import { OptionsSortable } from '../common';
import { QuizQFormInput } from '../quiz-form.types';
import SequenceOption from './SequenceOption';

interface IProps {}

const SequenceOptionsList: FC<IProps> = () => {
  const { getValues } = useFormContext<QuizQFormInput>();
  const { fields, append, remove, move } = useFieldArray<
    QuizQFormInput,
    'sequence'
  >({
    name: 'sequence',
  });

  const onRemoveClick = (index: number) => {
    if (getValues('sequence').length > 2) {
      remove(index);
    }
  };

  return (
    <Flex gap={3} flexWrap='wrap'>
      <OptionsSortable
        items={fields}
        onDragEnd={(oldIndex, newIndex) => move(oldIndex, newIndex)}
      >
        {fields.map((field, index, arr) => (
          <SequenceOption
            key={field.id}
            optionId={field.id}
            optionIndex={index}
            totalLength={arr.length}
            onRemoveClick={() => onRemoveClick(index)}
          />
        ))}
      </OptionsSortable>

      <IconButton
        size='xs'
        aria-label='add-image'
        variant='outline'
        borderRadius='full'
        borderWidth='1px'
        alignSelf='center'
        icon={<AddIcon />}
        onClick={() =>
          append({
            uid: uuidv4(),
            text: '',
          })
        }
      />
    </Flex>
  );
};

export default SequenceOptionsList;
