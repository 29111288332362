import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/react';
import { Input } from '../../../../../../atoms';
import MilestoneThumbnail from './MilestoneThumbnail';

interface IProps {}

const MilestoneResourceContainer: FC<IProps> = () => {
  const { trigger } = useFormContext();
  return (
    <Flex
      flex={1}
      gap={6}
      flexDir='column'
      maxHeight='full'
      maxWidth='calc(100% - 260px - 40px)'
      overflow='hidden auto'
    >
      <Box fontSize='18px' fontWeight='600' color='#6F767E'>
        A milestone on a training path is a specific point that users must reach
        to complete all concepts in the preceding skills. It serves as a
        checkpoint for their progress.
      </Box>

      <Flex flexDir='column' gap={5} paddingBottom={4} paddingInline={1}>
        <Controller
          name='title'
          rules={{
            required: 'Milestone name is required',
            validate: (value) => {
              if (!value?.trim()) {
                return 'Enter valid milestone name';
              }
              if (value?.length > 120) {
                return 'Milestone name cannot exceed 120 characters.';
              }
            },
          }}
          render={({ field, fieldState }) => (
            <FormControl isInvalid={fieldState.invalid}>
              <FormLabel fontSize='14px'>Enter Milestone name</FormLabel>
              <Input
                size='lg'
                variant='auditOutline'
                placeholder='Hurray you’ve reached the milestone!'
                {...field}
              />
              <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
            </FormControl>
          )}
        />

        <Controller
          name='thumbnail'
          render={({ field, fieldState }) => (
            <FormControl isInvalid={fieldState.invalid}>
              <FormLabel fontSize='14px'>
                Add custom image/GIF (Optional)
              </FormLabel>
              <MilestoneThumbnail
                value={field.value}
                onChange={(newValue) => {
                  field.onChange(newValue);
                  trigger('thumbnail');
                }}
              />
              <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
            </FormControl>
          )}
        />
      </Flex>
    </Flex>
  );
};

export default MilestoneResourceContainer;
