import { useMemo } from 'react';
import { useReactiveVar } from '@apollo/client';
import { cloneDeep } from '@apollo/client/utilities';
import { userObj } from 'sop-commons/src';
import { useUserDataSelector } from '../../../../../../../hooks';
import { usersEntityObj } from '../../../../../../Header';
import { AuthRole } from '../../../../../../../authorization';
import { toArray } from '../../../../../../../utils';
import { UserEntityData } from '../../../../../../../shared/graphql/shared-types';

function filterLocation<T extends any>(user: T) {
  // @ts-ignore
  if (user.type === 'user') {
    return false;
  }
  // @ts-ignore
  return ['open', 'development'].includes(user.locationStatus!);
}

const mapLocation = (locations: UserEntityData[]) => {
  return toArray(locations)
    .filter(filterLocation)
    .sort((a, b) => a.name!.localeCompare(b.name!));
};

export const getLocationList = (): UserEntityData[] => {
  if (userObj().authRole === AuthRole.LOCATION_OWNER) {
    return mapLocation(userObj()?.locations as never as UserEntityData[]);
  }
  return mapLocation(usersEntityObj());
};

export const useLocationsList = (): UserEntityData[] => {
  const authUser = useUserDataSelector((state) => ({
    authRole: state.authRole,
    locations: cloneDeep(state.locations),
  }));
  const entityUsers = useReactiveVar(usersEntityObj);

  return useMemo(() => {
    if (authUser?.authRole === AuthRole.LOCATION_OWNER) {
      return mapLocation(authUser?.locations as never as UserEntityData[]);
    }

    return mapLocation(entityUsers);
  }, [entityUsers, authUser]);
};
