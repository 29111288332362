import React, { forwardRef, useMemo } from 'react';
import { useControllableState } from '@chakra-ui/react';
import { Dropdown } from '../../../../../../../atoms';
import { useSafeState } from '../../../../../../../hooks';

function getNumbersContaining(digit: string) {
  const digitStr = digit.toString();
  return Array.from({ length: 101 }, (_, i) => i).filter((num) =>
    num.toString().includes(digitStr)
  );
}

const toPoints = (value: number) => {
  if (value === 0) {
    return 'No score';
  }
  return value === 1 ? `${value} point` : `${value} points`;
};

function mapOption(value: number) {
  return {
    value: value as never,
    label: toPoints(value),
  };
}

interface IProps {
  value?: number;
  onChange?: (value: number) => void;
  disabled?: boolean;
}

const ScoreSelect = forwardRef<never, IProps>(
  ({ value, onChange, disabled }, ref) => {
    const [query, updateQuery] = useSafeState('');

    const [internalValue, updateValue] = useControllableState<number>({
      value: value,
      onChange: onChange,
    });

    const _options = useMemo(() => {
      if (query) {
        return getNumbersContaining(query);
      }

      return [0, 1, 2, 3, 4, 5, 10, 20, 50];
    }, [query]);

    const options = useMemo(() => {
      if (internalValue) {
        return [...new Set([..._options, internalValue])]
          .sort((a, b) => a - b)
          .map(mapOption);
      }
      return _options.map(mapOption);
    }, [_options, internalValue]);

    const selectedValue = useMemo(() => {
      if (internalValue >= 0) {
        return {
          value: internalValue,
          label: toPoints(internalValue),
        };
      }
      return null;
    }, [internalValue]);

    return (
      <Dropdown
        ref={ref}
        value={selectedValue}
        onChange={(newValue) => updateValue(newValue?.value)}
        options={options}
        disabled={disabled}
        onInputChange={updateQuery}
        menuPlacement='auto'
        placeholder='Select score'
        selectStyles={{
          container: {
            background: 'transparent',
            flex: 1,
            maxWidth: '100%',
          },
          control: {
            border: '2px solid',
            minHeight: '38px',
          },
          menuList: {
            minWidth: 0,
            padding: '12px 10px',
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '8px',
            gap: '8px',
          },
          option: (_, props) => ({
            fontSize: '12px',
            fontWeight: 500,
            padding: '8px 12px',
            borderRadius: '10px',
            // eslint-disable-next-line react/prop-types
            bg: props.isSelected ? '#2A85FF1A' : _.bg,
            // eslint-disable-next-line react/prop-types
            color: props.isSelected ? '#2A85FF' : _.color,
          }),
          singleValue: {
            fontSize: '12px',
            fontWeight: 600,
            color: '#6F767E',
          },
        }}
      />
    );
  }
);

ScoreSelect.displayName = 'ScoreSelect';

export default ScoreSelect;
