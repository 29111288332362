import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { useController } from 'react-hook-form';
import { VisibilityStatus } from '../../../../../../types';
import { IconImage } from '../../../../../../ui-components';
import ResourceItemAction from './ResourceItemAction';
import LimitedVisibility from './LimitedVisibility';

export interface BaseRItem {
  eid: string;
  title: string;
  thumbnail?: string;
  visibility?: VisibilityStatus;
}

interface IProps {
  data: BaseRItem;
  onClick?: () => void;
  visibilityCopy: string;
}

const BaseResourceItem: FC<IProps> = ({
  data,
  onClick,
  visibilityCopy,
  children,
}) => {
  const isPrivate = data?.visibility === 'private';

  const { field } = useController({ name: `trainingContent.${data.eid}` });

  const isAdded = data.eid === field.value?.eid;

  return (
    <Flex
      flexDir='column'
      gap={4}
      padding='8px 12px'
      border='1px solid'
      borderColor='transparent'
      borderRadius='12px'
      _hover={{
        borderColor: '#EFEFEF',
        bgColor: '#FCFCFC',
        button: {
          color: '#2A85FF',
          bgColor: '#FFFFFF',
        },
        '.item-remove': {
          display: 'inline-flex',
        },
        '.item-added': {
          display: 'none',
        },
      }}
      onClick={onClick}
    >
      <Flex flex={1} maxWidth='100%' gap={4} align='center'>
        <IconImage
          // @ts-ignore
          icon={data.icon}
          // @ts-ignore
          files={data.files}
          name={data.title}
          thumbnail={data.thumbnail}
          boxSize={60}
        />

        {children}

        <ResourceItemAction
          isAdded={isAdded}
          onAddClick={() => field.onChange(data)}
          onRemoveClick={() => field.onChange(null)}
        />
      </Flex>

      <LimitedVisibility
        visibilityCopy={visibilityCopy}
        visible={isPrivate && isAdded}
      />
    </Flex>
  );
};

export default BaseResourceItem;
