import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Flex, useToast } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { cloneDeep } from '@apollo/client/utilities';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Authorize, AuthRole } from 'authorization';
import { useUserDataSelector } from 'hooks';
import { ActionButton } from 'ui-components';

import {
  Address,
  BasicDetails,
  EntityDetails,
  LoginDetails,
  OtherDetails,
} from '../Components';
import UpdateLoginDetails from './UpdateLoginDetails';

import { IFormInput } from '../AddLocation/add-location.types';
import { addLocationFormat } from './formatSubmitData';
import {
  UPDATE_LOCATION_QUERY,
  UpdateLocationVariable,
} from './update-location.graphql';
import { getDefaultFormData } from '../AddLocation/default-form-data';
import AddLocationHeader from '../AddLocation/AddLocationHeader';
import DeleteLocation from './DeleteLocation';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';
import { getTimezones } from 'shared/graphql/SharedGraphql';
import {
  LocationLiveResp,
  MAKE_LOCATION_LIVE,
} from 'sub-components/LocationLaunchDashboard/LiveModal/live-modal.graphql';
import { LocationInputs } from '../AddLocation/add-location.graphql';
import { ATTACH_LOCATION_CREDENTIALS } from 'sub-components/LocationLaunchDashboard/LocationLoginDetails/location-login-details.graphql';
import { LocationStatusOption } from '../AddLocation/static-data';

export interface EditLocationRef {
  initForm: (values: IFormInput) => void;
}

interface IProps {
  isEdit?: boolean;
  locationId?: string;
  onMoveSuccess?: () => void;
  loading?: boolean;
}

const EditLocationForm = forwardRef<EditLocationRef, IProps>(
  ({ isEdit, locationId, onMoveSuccess, loading }, ref) => {
    const { t } = useTranslation(['common', 'location']);
    const [markedAsLive, setMarkedAsLive] = useState<boolean>(false);
    const toast = useToast({
      position: 'top-right',
      isClosable: true,
      duration: 3000,
    });
    const history = useHistory();
    const partialDisabled = useUserDataSelector(
      (state) => state.authRole === AuthRole.LOCATION_OWNER
    );

    const methods = useForm<IFormInput>({
      defaultValues: getDefaultFormData(),
    });
    const initialUserNameRef = useRef('');
    initialUserNameRef.current = methods?.getValues('username');

    const locationEid = methods?.watch('eid');

    useImperativeHandle(
      ref,
      () => {
        return {
          initForm: (values) => {
            methods.reset(values);
          },
        };
      },
      [methods.reset]
    );

    const { execute: getTimezonesData } = getTimezones((timezones) => {
      methods.setValue('timezonesData', timezones);
    });

    useEffect(() => {
      if (locationEid) {
        getTimezonesData();
      }
    }, [locationEid]);

    const [updateLocation, { loading: updateLocationLoading }] = useMutation<
      never,
      UpdateLocationVariable
    >(UPDATE_LOCATION_QUERY, {
      onCompleted: () => {
        if (!markedAsLive) {
          toast({
            status: 'success',
            title: t('common:success'),
            description: t('location:location_update_success'),
          });
        }
        history.goBack();
      },
      onError: (error) => {
        if (error?.message?.includes('Username')) {
          methods.setError(
            'username',
            {
              type: 'custom',
              message: error.message,
            },
            {
              shouldFocus: true,
            }
          );
        } else {
          toast({
            status: 'error',
            title: t('common:error'),
            description: t('location:locationUpdateError'),
          });
        }
      },
    });

    const [makeLive, { loading: makeLiveLoading }] =
      useMutation<LocationLiveResp>(MAKE_LOCATION_LIVE, {
        onCompleted: (data) => {
          toast({
            status: 'success',
            title: 'Success',
            description: 'Location was made live',
          });
        },
        onError: () => {
          toast({
            status: 'error',
            title: 'Error',
            description: 'Location could not be made live',
          });
        },
      });

    const [
      attachLocationCredentials,
      { loading: attachLocationCredentialsLoading },
    ] = useMutation(ATTACH_LOCATION_CREDENTIALS);

    const onSubmit = async (data: IFormInput) => {
      deployEvent(AmplitudeEventNames.LOCATION_ADD_BUTTON, {
        add_location_funnel_id: 5,
        location_id: locationId,
        location_type: data.locationType,
        location_name: data.locationName ?? 'Miami',
        status: data.locationStatus.value,
        email_address: data.locationEmail ?? 'xyz@gmail.com',
        owner: data.locationOwners ?? ['Joe', 'Ryan'],
        location_user_name: data.username ?? 'abctest',
        job_edit_funnel_id: 5,
        Address: data.address ?? 'Abc, Floor 1',
        State: data.state ?? 'Haryana',
        City: data.city ?? 'Gurugram',
        Zipcode: data.zipCode ?? '123412',
        entity_name: data.entityName ?? 'Foodapanda',
        entity_type: data.entityType ?? 'LLC',
        country_of_formation: data.countryOfFormation ?? 'USA',
        state_of_formation: data.stateOfFormation ?? 'Alaska',
        location_Type: data.locationType ?? 'Affiliate',
        location_edit_funnel_id: 5,
        source: data.locationStatus.label ?? 'Pre- launch location',
      });

      const inputData = addLocationFormat(cloneDeep(data), locationId!);

      const isCredsAttached = !!initialUserNameRef.current;

      if (markedAsLive && !isCredsAttached) {
        attachLocationCredentials({
          variables: {
            locationId: locationId,
            username: methods.getValues('username'),
            password: methods.getValues('password') || '',
          },
        })
          .then(() => {
            makeLive({
              variables: {
                locationId: locationId,
              },
            });
            updateLocation({
              variables: {
                input: inputData,
              },
            });
          })
          .catch((error) => {});
      }

      if (markedAsLive && isCredsAttached) {
        await makeLive({
          variables: {
            locationId: locationId,
          },
        });

        await updateLocation({
          variables: {
            input: inputData,
          },
        });
      }

      if (!markedAsLive) {
        await updateLocation({
          variables: {
            input: inputData,
          },
        });
      }
    };

    useEffect(() => {
      if (markedAsLive) {
        methods.setValue('locationStatus', { label: 'Open', value: 'open' });
      }
    }, [markedAsLive]);

    return (
      <FormProvider {...methods}>
        <Flex flexDir='column' gap={4} pb={5}>
          <AddLocationHeader
            title={t('location:edit_location')}
            setMarkedAsLive={setMarkedAsLive}
            markedAsLive={markedAsLive}
            isDataLoading={loading}
          />

          <BasicDetails isEdit={isEdit} partialDisabled={partialDisabled} />

          {!methods.getValues('username') && markedAsLive ? (
            <LoginDetails />
          ) : (
            <UpdateLoginDetails
              locationId={locationId}
              markedAsLive={markedAsLive}
            />
          )}

          <Address />

          <EntityDetails />

          <OtherDetails isDisabled={partialDisabled} />

          <Flex justify='flex-end' gap='12px'>
            <Authorize
              permittedFor='user'
              permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
            >
              <DeleteLocation onMoveSuccess={onMoveSuccess} />
            </Authorize>

            <ActionButton
              size='lg'
              type='submit'
              colorScheme='blue'
              minW='170px'
              fontSize='15px'
              fontWeight='600'
              borderRadius='7px'
              isLoading={
                makeLiveLoading ||
                attachLocationCredentialsLoading ||
                updateLocationLoading
              }
              isDisabled={
                makeLiveLoading ||
                attachLocationCredentialsLoading ||
                updateLocationLoading
              }
              actionFn={methods.handleSubmit(onSubmit)}
            >
              {markedAsLive ? 'Make live' : t('common:update')}
            </ActionButton>
          </Flex>
        </Flex>
      </FormProvider>
    );
  }
);

EditLocationForm.displayName = 'Edit Location Form';

export default EditLocationForm;
