import {
  ApolloClient,
  makeVar,
  useLazyQuery,
  useReactiveVar,
} from '@apollo/client';
import { AuthRole } from 'authorization';
import {
  chaptersListVar,
  foldersListVar,
} from 'pages/Chapters/ChapterContainer/ChapterContainer';
import {
  ChapterListResponse,
  ChapterListVariable,
  GET_CHAPTERS,
  GET_FOLDERS_LIST,
} from 'pages/Chapters/chapters.graphql';
import { GET_USER } from 'pages/Login/login.graphql';
import { CategoryEntity, userObj } from 'sop-commons/src/client';
import { usersEntityObj } from 'sub-components/Header';
import { roleObj } from 'ui-components/DashboardMenu';
import { UserDataResponse } from '../../hooks/useUserDataQuery';
import {
  GET_ENTITY_USERS,
  GET_ENTITY_USERS_V2,
  TIMEZONES,
  TIMEZONE_QUERY,
  GET_FOLDER_BY_ID,
  GET_LOCATION_BY_ZIP_CODE,
} from './queries';

import {
  LocationFields,
  LocationResponse,
  TimezoneResponse,
  UserEntityData,
} from './shared-types';
import { useMixPanelSuperProp } from 'hooks';

export const getLoggedInUserDataHandler = (
  onSuccess?: (data: UserDataResponse) => void,
  onErrorFn?: () => void
) => {
  const mixPanelSuperProps = useMixPanelSuperProp();
  const [execute, { loading, error, data, refetch }] =
    useLazyQuery<UserDataResponse>(GET_USER, {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onCompleted: (loggedInUserData) => {
        userObj(loggedInUserData?.user);
        const roles = data?.user?.entity?.roles;
        if (roles && roles.length) {
          let roleWithColor: any = {};
          roles.forEach((role: any) => {
            roleWithColor[role.name] = role.color;
          });

          roleObj(roleWithColor);
        }
        mixPanelSuperProps();
        onSuccess?.(loggedInUserData);
      },
      onError: () => {
        onErrorFn?.();
      },
    });
  return { execute, loading, error, data, refetch };
};

//use this to fetch user entity data for complete project
export const getUsersEntityHandler = (
  onSuccess?: (data: UserEntityData[]) => void
) => {
  const [execute, { loading, error, data, refetch }] = useLazyQuery<
    {
      EntityUsers: UserEntityData[];
    },
    {
      entityId: string;
      status: 'active' | 'inactive' | 'pending' | 'revoked';
      authRole: AuthRole;
      type: 'user' | 'branch';
    }
  >(GET_ENTITY_USERS_V2, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const filteredUsers = data?.EntityUsers?.filter(
        (user) =>
          user?.type === 'branch' ||
          (user?.type === 'user' &&
            ['active', 'pending']?.includes(user?.status))
      );

      usersEntityObj(filteredUsers);
      onSuccess?.(filteredUsers);
    },
  });
  return { execute, loading, error, data, refetch };
};

export const getFoldersList = (
  onSuccess?: (data: CategoryEntity[]) => void
) => {
  const [execute, { loading, error, data, refetch }] = useLazyQuery<
    { EntityCategories: CategoryEntity[] },
    never
  >(GET_FOLDERS_LIST, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      // updateFoldersList(data?.EntityCategories || []);
      foldersListVar(data?.EntityCategories || []);
      onSuccess?.(data?.EntityCategories || []);
    },
  });
  return { execute, loading, error, data, refetch };
};

export const getChaptersList = (
  onSuccess?: (data: ChapterListResponse) => void
) => {
  const [execute, { loading, error, data, refetch }] = useLazyQuery<
    ChapterListResponse,
    ChapterListVariable
  >(GET_CHAPTERS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      chaptersListVar(data);
      onSuccess?.(data);
    },
  });
  return { execute, loading, error, data, refetch };
};

export const getFolderById = (onSuccess?: (data: CategoryEntity) => void) => {
  const [execute, { loading, error, data, refetch }] = useLazyQuery<
    { EntityCategoryById: CategoryEntity },
    { eid: string }
  >(GET_FOLDER_BY_ID, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      onSuccess?.(data?.EntityCategoryById);
    },
  });
  return { execute, loading, error, data, refetch };
};

export const getTimezones = (
  onSuccess?: (timezones: string[]) => void,
  onError?: () => void
) => {
  const [execute, { loading, error, data, refetch }] = useLazyQuery<
    { TimeZones: string[] },
    never
  >(TIMEZONES, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      onSuccess?.(data?.TimeZones || []);
    },
  });
  return { execute, loading, error, data, refetch };
};

export const getLocationByZipCode = async (
  client: ApolloClient<any>,
  zipCode: string
): Promise<LocationFields | null> => {
  try {
    const { data } = await client.query<LocationResponse>({
      query: GET_LOCATION_BY_ZIP_CODE,
      variables: { zipCode },
    });

    const location = data?.GetLocationsByZipCode?.[0];
    if (!location) return null;

    const formattedData: LocationFields = {
      coordinates: {
        lat: location?.geometry?.location?.lat?.toString(),
        lng: location?.geometry?.location?.lng?.toString(),
      },
    };

    location?.address_components?.forEach((component) => {
      if (component?.types?.includes('country')) {
        formattedData.country = component?.long_name;
      }
      if (component?.types?.includes('locality')) {
        formattedData.city = component?.long_name;
      }
      if (component.types?.includes('administrative_area_level_1')) {
        formattedData.state = component?.long_name;
      }
    });

    return formattedData;
  } catch (error) {
    console.error('Error fetching location:', error);
    return null;
  }
};

export const getTimezoneForLocation = async (
  client: ApolloClient<any>,
  coordinates: { lat: string; lng: string }
): Promise<{ label: string; value: string } | null> => {
  try {
    const { data } = await client.query<TimezoneResponse>({
      query: TIMEZONE_QUERY,
      variables: {
        lat: coordinates?.lat,
        long: coordinates?.lng,
        timestamp: Math.floor(Date.now() / 1000).toString(),
      },
    });

    const timezone = data?.GetTimeZoneForLocation;
    if (!timezone?.timeZoneId) return null;

    return {
      label: timezone?.timeZoneId,
      value: timezone?.timeZoneId,
    };
  } catch (error) {
    console.error('Error fetching timezone:', error);
    return null;
  }
};
