import React, { FC, forwardRef } from 'react';
import DatePicker, { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { Wrapper } from './wrapper.styles';
// import CustomHeader from 'sub-components/tasks/create-task/Scheduler/CustomDatePicker/CustomHeader';
import PickerButton from './PickerButton';
import CustomHeader from './CustomHeader';

interface IProps {
  readOnly?: boolean;
  selectedValue: Date | null;
  onChange: (date: Date | null) => void;
  onSelect?: (date: Date | null) => void;
  onCalendarOpen?: () => void;
  onCalendarClose?: () => void;
  placeholderText: string;
  dateFormat?: string;
  minDate?: Date | null;
  maxDate?: Date | null;
  customInput?: React.ReactElement;
  disabled?: boolean;
  renderCustomHeader?: (
    params: ReactDatePickerCustomHeaderProps
  ) => React.ReactNode;
  hideDays?: boolean;
  shouldCloseOnSelect?: boolean;
  open?: boolean;
  children?: React.ReactNode;
}

const TrainingDateSelect = forwardRef<never, IProps>(
  (
    {
      readOnly,
      selectedValue,
      onChange,
      onSelect,
      onCalendarOpen,
      onCalendarClose,
      children,
      placeholderText,
      dateFormat,
      minDate,
      maxDate,
      customInput,
      disabled,
      renderCustomHeader = CustomHeader,
      hideDays,
      shouldCloseOnSelect = false,
    },
    ref
  ) => {
    const effectiveCustomInput = customInput ?? <PickerButton ref={ref} />;

    return (
      <Wrapper hideDays={hideDays}>
        <DatePicker
          disabled={disabled}
          readOnly={readOnly}
          selected={selectedValue}
          onCalendarOpen={onCalendarOpen}
          onCalendarClose={onCalendarClose}
          onChange={onChange}
          onSelect={onSelect}
          customInput={effectiveCustomInput}
          renderCustomHeader={renderCustomHeader}
          formatWeekDay={(day) => day.substring(0, 3)}
          calendarStartDay={1}
          placeholderText={placeholderText}
          dateFormat={dateFormat || 'd MMM yyyy'}
          minDate={minDate}
          maxDate={maxDate}
          shouldCloseOnSelect={shouldCloseOnSelect}
        >
          {children}
        </DatePicker>
      </Wrapper>
    );
  }
);

TrainingDateSelect.displayName = 'TrainingDateSelect';

export default TrainingDateSelect;
