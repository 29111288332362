import { QuizQuestionType } from '../../../../../../../types';
import { QuizQFormInput } from '../quiz-form/quiz-form.types';

export const quizExamples: Record<QuizQuestionType, QuizQFormInput> = {
  [QuizQuestionType.MULTI_CHOICE]: {
    type: QuizQuestionType.MULTI_CHOICE,
    label:
      'Which of the following is the most important step to ensure your cake rises properly during baking?',
    score: 0,
    options: [
      {
        uid: '1',
        text: 'Using cold butter',
        isCorrect: false,
      },
      {
        uid: '2',
        text: 'Overmixing the batter',
        isCorrect: false,
      },
      {
        uid: '3',
        text: 'Preheating the oven to the correct temperature',
        isCorrect: true,
      },
      {
        uid: '4',
        text: 'Adding extra sugar to the batter',
        isCorrect: false,
      },
    ],
    isTrue: undefined,
    pairs: [],
    sequence: [],
    textWithBlanks: '',
    blanks: [],
    // @ts-ignore
    isExample: true,
  },
  [QuizQuestionType.TRUE_FALSE]: {
    type: QuizQuestionType.TRUE_FALSE,
    label: 'Adding salt to bread dough helps control the fermentation process.',
    score: 0,
    options: [],
    isTrue: undefined,
    pairs: [],
    sequence: [],
    textWithBlanks: '',
    blanks: [],
    // @ts-ignore
    isExample: true,
  },
  [QuizQuestionType.MATCH_SET]: {
    type: QuizQuestionType.MATCH_SET,
    label: 'Match the following baking terms with their correct descriptions',
    score: 0,
    options: [],
    isTrue: undefined,
    pairs: [
      {
        uid: '1',
        left: 'Creaming',
        right: 'Gently incorporating ingredients to retain air',
      },
      {
        uid: '2',
        left: 'Proofing',
        right: 'Mixing butter and sugar until light and fluffy',
      },
      {
        uid: '3',
        left: 'Folding',
        right: 'Allowing dough to rise before baking',
      },
      {
        uid: '4',
        left: 'Sifting',
        right: 'Passing dry ingredients through a fine mesh to remove lumps',
      },
    ],
    sequence: [],
    textWithBlanks: '',
    blanks: [],
    // @ts-ignore
    isExample: true,
  },
  [QuizQuestionType.SEQUENCE]: {
    type: QuizQuestionType.SEQUENCE,
    label: 'Put the following steps of baking a cake in the correct order:',
    score: 0,
    options: [],
    isTrue: undefined,
    pairs: [],
    sequence: [
      {
        uid: '1',
        text: 'Mix wet and dry ingredients',
      },
      {
        uid: '2',
        text: 'Preheat the oven',
      },
      {
        uid: '3',
        text: 'Pour the batter into the pans',
      },
      {
        uid: '4',
        text: 'Grease and flour the cake pans',
      },
      {
        uid: '5',
        text: 'Cream butter and sugar',
      },
    ],
    textWithBlanks: '',
    blanks: [],
    // @ts-ignore
    isExample: true,
  },
  [QuizQuestionType.FILL_IN_BLANK]: {
    type: QuizQuestionType.FILL_IN_BLANK,
    label: '',
    score: 0,
    options: [],
    isTrue: undefined,
    pairs: [],
    sequence: [],
    textWithBlanks:
      'To ensure a cake rises properly, it’s important to preheat the oven to the correct temperature, usually around ____ degrees Fahrenheit for most cakes.',
    blanks: [],
    // @ts-ignore
    isExample: true,
  },
};
