import React, { FC } from 'react';
import { useFieldArray, useFormContext, useFormState } from 'react-hook-form';
import { Box, Flex, IconButton } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { v4 as uuidv4 } from 'uuid';
import { QuizQFormInput } from '../quiz-form.types';
import MultiChoiceOption from './MultiChoiceOption';

const validateOption = (options: QuizQFormInput['options']) => {
  return (
    options?.every((op) => op.text) && !options?.some((op) => op.isCorrect)
  );
};

interface IProps {}

const MultiChoiceOptionsList: FC<IProps> = () => {
  const { getValues, trigger } = useFormContext<QuizQFormInput>();
  const { fields, append, remove, update } = useFieldArray<
    QuizQFormInput,
    'options'
  >({
    name: 'options',
    rules: {
      validate: (value) => {
        if (validateOption(value)) {
          return 'Select right answer option';
        }
      },
    },
  });

  const optionError = useFormState<QuizQFormInput>({
    name: 'options',
    exact: true,
  }).errors?.options;

  const errorMessage = optionError?.root?.message || optionError?.message;

  const onRemoveClick = (index: number) => {
    if (getValues('options').length > 2) {
      remove(index);
    }
  };

  const onCheckClick = (index: number) => {
    const hasMultipleCorrect = getValues('hasMultipleCorrect');
    const options = getValues('options');

    const option = options[index];

    if (hasMultipleCorrect) {
      update(index, { ...option, isCorrect: !option.isCorrect });
    } else {
      const oldIndex = options.findIndex((op) => op.isCorrect);
      if (oldIndex !== -1 && oldIndex !== index) {
        update(oldIndex, { ...options[oldIndex], isCorrect: false });
      }
      update(index, { ...option, isCorrect: !option.isCorrect });
    }
    trigger('options');
  };

  return (
    <Flex flexDir='column'>
      <Flex gap={3} flexWrap='wrap'>
        {fields.map((field, index, arr) => (
          <MultiChoiceOption
            key={field.id}
            isCorrect={field.isCorrect}
            optionIndex={index}
            totalLength={arr.length}
            onRemoveClick={() => onRemoveClick(index)}
            onCheckClick={() => onCheckClick(index)}
          />
        ))}

        <IconButton
          size='xs'
          aria-label='add-image'
          variant='outline'
          borderRadius='full'
          borderWidth='1px'
          alignSelf='center'
          icon={<AddIcon />}
          onClick={() => append({ uid: uuidv4(), text: '', isCorrect: false })}
        />
      </Flex>
      {errorMessage && (
        <Box color='red.500' mt={2}>
          {errorMessage}
        </Box>
      )}
    </Flex>
  );
};

export default MultiChoiceOptionsList;
