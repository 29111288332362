import React, { forwardRef, useMemo } from 'react';
import { useControllableState } from '@chakra-ui/react';
import { Dropdown, SelectOption } from '../../../../../../atoms';
import { toArray } from '../../../../../../utils';

interface IProps {
  options: SelectOption[];
  value?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
}

const SelectInput = forwardRef<never, IProps>(
  ({ options, value, onChange, disabled }, ref) => {
    const [internalValue, updateValue] = useControllableState<string>({
      value: value,
      onChange: onChange,
    });

    const selectedValue = useMemo(() => {
      return toArray(options).find((option) => option.value === internalValue);
    }, [options, internalValue]);

    return (
      <Dropdown
        ref={ref}
        size='lg'
        value={selectedValue}
        onChange={(newValue) => updateValue(newValue?.value)}
        options={options}
        disabled={disabled}
        selectStyles={{
          container: {
            background: 'transparent',
            flex: 1,
            // width: '240px',
            maxWidth: '100%',
          },
          control: {
            border: '2px solid',
            // maxWidth: '240px',
          },
          menuList: {
            padding: '16px',
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            // maxWidth: '200px',
          },
          option: {
            fontSize: '15px',
            fontWeight: 600,
            padding: '12px',
            borderRadius: '10px',
          },
          singleValue: {
            fontSize: '15px',
            fontWeight: 600,
            color: '#6F767E',
          },
        }}
      />
    );
  }
);

SelectInput.displayName = 'SelectInput';

export default SelectInput;
