import { FC } from 'react';
import { ConfigProvider, Table } from 'antd';
import { Box } from '@chakra-ui/react';
import Loader from 'sub-components/Loader';
import { TableDataType } from 'sub-components/nexus/Compliance/types/compliance.types';

interface IProps {
  tableData: TableDataType[];
  totalRowsCount: number;
  columns: any;
  getComplianceListing: any;
  getComplianceFilters: any;
  complianceLoader: any;
  onPageChange: (page: number) => void;
}

const LauncherTable: FC<IProps> = ({
  tableData,
  columns,
  totalRowsCount,
  getComplianceListing,
  getComplianceFilters,
  complianceLoader,
  onPageChange,
}) => {
  return (
    <Box>
      <Table
        bordered={true}
        loading={complianceLoader && { indicator: <Loader /> }}
        columns={columns}
        dataSource={tableData}
        pagination={{
          total: totalRowsCount,
          defaultPageSize: 10,
          onChange: onPageChange,
        }}
      />
    </Box>
  );
};

LauncherTable.displayName =
  'sub-components/Launcher/document-listing/components/LauncherTable';
export default LauncherTable;
