import moment from 'moment';
import { UserEntityData } from 'shared/graphql/shared-types';
import { ITPCategory, TPItems } from 'sub-components/training-v2/shared/types';
import { ITrackingTPPagination } from './PathTable';

export type prepareTrackingTPTableDataType = (
  trainingData: TPItems[],
  categoryData: ITPCategory[],
  users: UserEntityData[]
) => ITrackingTPPagination[];

export const prepareTrackingTPTableData: prepareTrackingTPTableDataType = (
  trainingPathData,
  categoryData,
  users
) => {
  const formattedTrackingTPTableData = trainingPathData?.map(
    (item: TPItems) => {
      return {
        key: item?.eid,
        sessionId: item?.lastSession?.eid,
        pathName: item?.title ?? '',
        thumbnail: item?.thumbnail,
        progress: {
          current: item?.lastSession?.completedCount ?? 0,
          total: item?.lastSession?.assignedCount ?? 0,
        },
        overdue: item?.lastSession?.overdueCount,
        lastUpdated: item?.updatedAt ?? '-',
        lastActivity: item?.lastSession?.updatedAt ?? item?.updatedAt,
        createdBy: getTrainingCreatedByName(item?.createdBy, users) ?? '-',
        createdById: item?.createdBy,
        createdOn: item?.createdAt ?? '-',
        category: getTrainingCategoryName(item?.category, categoryData) ?? '-',
        status: item?.status,
        accessPermission: item?.accessPermission,
      } as ITrackingTPPagination;
    }
  );

  return formattedTrackingTPTableData;
};

// function to find the category name for a training path from all training categories
function getTrainingCategoryName(
  categoryId: string,
  categories: ITPCategory[]
) {
  const foundCategory = categories?.find((item) => item?.eid === categoryId);
  return foundCategory;
}

// function to find the created by name for a training path from all business users
function getTrainingCreatedByName(
  createdById: string,
  users: UserEntityData[]
) {
  const foundCreatedByName = users?.find((item) => item?.eid === createdById);
  return foundCreatedByName?.name;
}

//randomly assign colorCodes for training categories
export function getCategoriesColorCode(categories?: ITPCategory[]) {
  const colorCodes = [
    { bg: '#8E59FF33', color: '#8E59FF' },
    { bg: '#2A85FF33', color: '#2A85FF' },
    { bg: '#FFD88D33', color: '#ffab0f' },
    { bg: '#FF6A5533', color: '#FF6A55' },
  ];

  const linkedColorsWithCategories = {};

  categories?.forEach((item, index) => {
    linkedColorsWithCategories[`${item?.eid}`] =
      colorCodes[index % colorCodes?.length];
  });

  return linkedColorsWithCategories;
}
