import React, { useState, useEffect } from 'react';
import {
  Box,
  Avatar,
  Text,
  Flex,
  HStack,
  Button,
  Badge,
  Tooltip,
} from '@chakra-ui/react';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import 'antd/dist/reset.css';
import { LocationBadge, SortPopover } from '../../Tracking/components';

import { EmptyState } from '.';
import { Image } from 'ui-components';
import { getImage } from 'utils';
import { TrainingPathAction } from '../components';
import {
  getInActiveStyles,
  IOnViewActivityClick,
} from '../components/MemberAnalytics';
import { useMemberActivity } from '../../memberActivity';
import { useParams } from 'react-router-dom';

export interface CrossTrainData {
  id: string;
  name: string;
  image?: string;
  locations?: string[];
  job: string;
  status: 'Completed' | 'In Progress';
  completedProgress: number;
  total: number;
  assignedOn: string;
  completedOn: string;
  score?: number;
  assignee?: string;
  action?: string;
  crossTrained?: boolean;
}

interface CrossTrainingProps {
  crossTrainData: CrossTrainData[];
  crossTrainModalData: any[];
  trainingId: string;
  tp: any;
  sessionId: string;
  refetchTpSession?: () => void;
  crossTrainAcceess: boolean;
  editPathAccess: boolean;
}

const CrossTraining: React.FC<CrossTrainingProps> = ({
  crossTrainData,
  crossTrainModalData,
  trainingId,
  tp,
  sessionId,
  refetchTpSession,
  editPathAccess,
  crossTrainAcceess,
}) => {
  const [data, setData] = useState<CrossTrainData[]>(crossTrainData);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc' | ''>('');
  const { DrawerComponent, useMemberActivityDrawer } = useMemberActivity();

  useEffect(() => {
    setData(crossTrainData);
  }, [crossTrainData]);

  const onViewActivityClick = ({
    name,
    userId,
    sessionId,
    tp,
  }: IOnViewActivityClick) => {
    useMemberActivityDrawer({
      memberName: name,
      userId: userId,
      sessionId: sessionId,
      tp: tp,
    });
  };

  const handleSort = (order: 'asc' | 'desc' | '') => {
    setSortOrder(order);

    const sortedData = [...data].sort((a, b) => {
      if (order === 'asc') return a?.name?.localeCompare(b?.name);
      if (order === 'desc') return b?.name?.localeCompare(a?.name);
      return 0;
    });

    setData(sortedData);
  };

  if (!data?.length) {
    return (
      <EmptyState
        trainingId={trainingId}
        crossTrainModalData={crossTrainModalData}
        sessionId={sessionId}
        editPathAccess={editPathAccess}
        crossTrainAcceess={crossTrainAcceess}
      />
    );
  }

  const columns: ColumnsType<CrossTrainData> = [
    {
      title: (
        // @ts-ignore
        <Flex gap={1} align='center'>
          <Text fontSize='12px' fontWeight={500} textColor={'#6F767E'}>
            Members
          </Text>
          <SortPopover onSort={handleSort} sortOrder={sortOrder} />
        </Flex>
      ),
      dataIndex: 'name',
      key: 'name',
      width: 200,
      render: (_, record) => {
        return (
          <Flex
            gap={2}
            align='center'
            style={getInActiveStyles(record)}
            whiteSpace={'nowrap'}
          >
            <div
              style={{
                width: '64px',
                height: '64px',
                borderRadius: '6px',
                overflow: 'hidden',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Image
                src={getImage(record?.profilePic, record?.name)}
                height={64}
                width={64}
                style={{
                  objectFit: 'cover',
                  width: '100%',
                  height: '100%',
                }}
              />
            </div>
            <Flex direction={'column'} gap={'0.1px'}>
              <Box>
                <Tooltip label={record?.name}>
                  <Text
                    fontWeight={600}
                    fontSize={'13px'}
                    color='#111315'
                    maxWidth={'110px'}
                    isTruncated
                  >
                    {record?.name}
                  </Text>
                </Tooltip>
                <LocationBadge
                  locations={
                    Array.isArray(record?.locations) ? record?.locations : []
                  }
                />
              </Box>
              <Box ml={1}>
                <Tooltip label={record?.job}>
                  <Text
                    color='#8E59FF'
                    fontSize={'10px'}
                    fontWeight={500}
                    isTruncated
                    maxWidth={'50px'}
                  >
                    {record?.job}
                  </Text>
                </Tooltip>
              </Box>
            </Flex>
          </Flex>
        );
      },
    },
    {
      title: (
        <Text fontSize='12px' fontWeight={500} textColor={'#6F767E'}>
          Status
        </Text>
      ),
      dataIndex: 'status',
      key: 'status',

      render: (status: string, record: any) => (
        <Badge
          bg={status === 'Completed' ? '#83BF6E33' : '#FFBC9933'}
          textColor={status === 'Completed' ? '#83BF6E' : '#FF6A55'}
          fontWeight={700}
          fontSize={'12px'}
          textTransform='capitalize'
          style={getInActiveStyles(record)}
        >
          {status}
        </Badge>
      ),
    },
    {
      title: (
        <Text fontSize='12px' fontWeight={500} textColor={'#6F767E'}>
          Progress
        </Text>
      ),
      dataIndex: 'progress',
      key: 'progress',
      align: 'center',
      render: (_, record: any) => (
        <Badge
          color='gray.600'
          fontSize='12px'
          fontWeight={500}
          bg='#EFEFEF'
          px={4}
          borderRadius='6px'
          style={getInActiveStyles(record)}
        >
          <HStack spacing={0.1}>
            <Text color='#1A1D1F' fontSize={'14px'}>
              {record?.completedProgress}
            </Text>
            <Text color='#1A1D1F' fontSize={'14px'}>
              /
            </Text>
            <Text color='#6F767E'>{record?.total}</Text>
          </HStack>
        </Badge>
      ),
    },
    {
      title: (
        <Text fontSize='12px' fontWeight={500} textColor={'#6F767E'}>
          Assign Date
        </Text>
      ),
      dataIndex: 'assignedOn',
      key: 'assignedOn',
      align: 'center',
      render: (assignedOn: string, record: any) => (
        <Text
          color={'#33383F'}
          fontSize={'14px'}
          fontWeight={500}
          style={getInActiveStyles(record)}
        >
          {assignedOn}
        </Text>
      ),
    },
    {
      title: (
        <Text fontSize='12px' fontWeight={500} textColor={'#6F767E'}>
          Completion Date
        </Text>
      ),
      dataIndex: 'completedOn',
      key: 'completedOn',
      align: 'center',
      render: (completedOn: string, record: any) => (
        <Text
          color={'#33383F'}
          fontSize={'14px'}
          fontWeight={500}
          style={getInActiveStyles(record)}
        >
          {completedOn === 'Invalid date' ? '-' : completedOn}
        </Text>
      ),
    },
    {
      title: (
        <Text fontSize='12px' fontWeight={500} textColor={'#6F767E'}>
          Score
        </Text>
      ),
      dataIndex: 'score',
      key: 'score',
      render: (score: number | undefined, record: any) => (
        <Flex align='center' gap={2} style={getInActiveStyles(record)}>
          <Text color={'#33383F'} fontSize={'14px'} fontWeight={500}>
            {score ?? '-'}
          </Text>
          {record.assignee && (
            <Avatar size='sm' name={record?.assignee} bg='gray.200' />
          )}
        </Flex>
      ),
    },
    {
      title: (
        <Text fontSize='12px' fontWeight={500} textColor={'#6F767E'}>
          Action
        </Text>
      ),
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (action: string | undefined, record: any) => {
        const assignedUser = {
          isEvaluated: record?.isEvaluated,
          completedOn: record?.completedOn,
          dueDate: record?.dueDate,
          userId: record?.userId,
          score: record?.score,
          scorePercent: record?.scorePercent,
        };

        return (
          <Flex style={getInActiveStyles(record)}>
            <TrainingPathAction
              onViewActivityClick={onViewActivityClick}
              userName={record.name}
              sessionId={sessionId}
              tp={{
                training: tp?.TpSessionById?.training,
                endDate: tp?.TpSessionById?.endDate,
                assignedUser: assignedUser,
                hasSkillVerifier: tp?.TpSessionById?.hasSkillVerifier,
                isRepeating: tp?.TpSessionById?.isRepeating,
                accessPermission: tp?.TpSessionById?.training?.accessPermission,
                supervisors: tp?.TpSessionById?.supervisors,
                createdBy: tp?.TpSessionById?.createdBy,
              }}
              onActionCompleted={refetchTpSession}
            />
          </Flex>
        );
      },
    },
  ];

  return (
    <Box mt={6} bg='white' borderRadius='lg' shadow='sm'>
      <Table
        columns={columns}
        dataSource={data}
        rowKey='id'
        pagination={{ pageSize: 100 }}
      />
      {DrawerComponent}
    </Box>
  );
};

export default CrossTraining;
