import { TPSession } from 'sub-components/training-v2/shared/types/play';
import { createStore } from 'zustandStore';
import { immer } from 'zustandStore/middleware';
import { useService } from '../layers';

type QuizSubmissionResult = {
  showResult: boolean;
  isCorrect: boolean;
  isPartiallyCorrect?: boolean;
  score: number;
} | null;

export interface PlayState {
  dataLoading: boolean;
  tpSessionData: TPSession | undefined;
  selectedIndex: number;
  selectedSubIndex: number;
  isShowingLeaderboard: boolean;
  isShowingEvaluation: boolean;
  internalTotalScreens: number;
  isCompleted: boolean;
  hasScoring: boolean;
  tpMarkedAsComplete: boolean;
  quizSubmissionResult: QuizSubmissionResult;
  isRetake: boolean;
  isPreview: boolean;
}

export interface PlayAction extends PlayState {
  updateDataLoading: (value: boolean) => void;
  updateTpSessionData: (data: TPSession | undefined) => void;
  updateContentByIndex: (
    mainIndex: number,
    data: TPSession['contents'][number]
  ) => void;
  increment: () => void;
  decrement: () => void;
  setQuizSubmissionResult: (result: PlayState['quizSubmissionResult']) => void;
  initializeRetake: (shouldRetake: boolean) => void;
  retake: () => void;
  showLastScreen: () => void;
  updateSelectedIndex: (index: number) => void;
  updateSelectedSubIndex: (index: number) => void;
  updateIsPreview: (value: boolean) => void;
}

export const usePlayStore = createStore<PlayAction>();

export const createPlayStore = (service: ReturnType<typeof useService>) => {
  return createStore<PlayAction>()(
    immer((set, get) => {
      // Helper functions
      const getContentLength = (content: any) => {
        if (!content) return 0;
        switch (content.type) {
          case 'sop':
            return content.sopContent?.renderContent?.length || 0;
          case 'quiz':
            return content.questions?.length || 0;
          default:
            return 0;
        }
      };

      const calculateTotalScreens = (tpData: TPSession | undefined) => {
        if (!tpData) return 0;

        const regularScreens = tpData.contents.length;
        const isCompleted = tpData.contents.every((content) =>
          tpData.userProgress?.[0]?.progress?.some(
            (progress) => progress.id === content.eid && progress.isCompleted
          )
        );

        if (!isCompleted) return regularScreens;

        // return regularScreens + (tpData.hasScoring ? 2 : 1);
        return regularScreens + 2;
      };

      const checkAndCompleteTP = async (
        tpData: TPSession | undefined,
        isRetake: boolean
      ) => {
        if (!tpData || isRetake) return;
        if (get().tpMarkedAsComplete || tpData?.userProgress?.[0]?.isCompleted)
          return;

        if (!tpData.contents || tpData.contents.length === 0) return;
        if (!tpData.userProgress) return;

        const allCompleted = tpData.contents.every((content) =>
          tpData.userProgress?.[0]?.progress?.some(
            (progress) =>
              progress.id === content.eid && progress.isCompleted === true
          )
        );

        if (allCompleted) {
          try {
            await service.completeTp.completeTP({
              variables: { sessionId: tpData.eid },
            });
            set((state) => {
              state.tpMarkedAsComplete = true;
            });
          } catch (error) {
            console.error('Error completing training path:', error);
          }
        }
      };

      const calculateInternalTotalScreens = (tpData: TPSession | undefined) => {
        if (!tpData) return 0;

        const regularScreens = tpData.contents.length;
        const isCompleted = tpData.contents.every((content) =>
          tpData.userProgress?.[0]?.progress?.some(
            (progress) => progress.id === content.eid && progress.isCompleted
          )
        );

        if (!isCompleted) return regularScreens;
        return regularScreens + 2;
      };

      return {
        dataLoading: false as boolean,
        tpSessionData: undefined as TPSession | undefined,
        selectedIndex: 0 as number,
        selectedSubIndex: 0 as number,
        sopContent: [] as any[],
        internalTotalScreens: 0,
        isShowingLeaderboard: false as boolean,
        isShowingEvaluation: false as boolean,
        hasScoring: false as boolean,
        isCompleted: false as boolean,
        tpMarkedAsComplete: false as boolean,
        quizSubmissionResult: null as QuizSubmissionResult,
        isRetake: false as boolean,
        isPreview: false as boolean,

        initializeRetake: (shouldRetake: boolean) => {
          set((state) => {
            state.isRetake = shouldRetake;
            state.selectedIndex = 0;
            state.selectedSubIndex = 0;
            if (state.tpSessionData) {
              state.tpSessionData.userProgress = [];
            }
          });
        },

        updateTpSessionData: (newData: TPSession | undefined) => {
          set((state) => {
            if (!newData) return;
            const tpMarkedAsComplete =
              newData.userProgress?.[0]?.isCompleted === true;
            const isCompleted = newData.contents.every((content) =>
              newData.userProgress?.[0]?.progress?.some(
                (progress) =>
                  progress.id === content.eid && progress.isCompleted
              )
            );
            state.internalTotalScreens = calculateInternalTotalScreens(newData);
            state.isCompleted = isCompleted;
            state.tpMarkedAsComplete = tpMarkedAsComplete;
            state.hasScoring = newData.hasScoring;

            if (state.tpSessionData) {
              const mergedContents = newData.contents.map((newContent) => {
                const existing = state.tpSessionData!.contents.find(
                  (c) => c.eid === newContent.eid
                );
                if (
                  newContent.type === 'sop' &&
                  existing &&
                  existing.sopContent !== undefined
                ) {
                  return { ...newContent, sopContent: existing.sopContent };
                }
                return newContent;
              });
              state.tpSessionData = {
                ...newData,
                contents: mergedContents,
              };
            } else {
              state.tpSessionData = newData;
            }
          });
          const currentState = get();
          checkAndCompleteTP(currentState.tpSessionData, currentState.isRetake);
        },

        updateDataLoading: (value: boolean) => {
          set((state) => {
            state.dataLoading = value;
          });
        },

        updateContentByIndex: (
          mainIndex: number,
          data: TPSession['contents'][number]
        ) => {
          set((state) => {
            if (!state.tpSessionData) return;
            state.tpSessionData.contents[mainIndex] = data;
          });
          const currentState = get();
          checkAndCompleteTP(currentState.tpSessionData, currentState.isRetake);
        },

        setQuizSubmissionResult: (result) => {
          set((state) => {
            state.quizSubmissionResult = result;
          });
        },

        updateSelectedIndex: (index: number) => {
          set((state) => {
            state.selectedIndex = index;
          });
        },

        updateSelectedSubIndex: (index: number) => {
          set((state) => {
            state.selectedSubIndex = index;
          });
        },

        updateIsPreview: (value: boolean) => {
          set((state) => {
            state.isPreview = value;
          });
        },

        increment: () => {
          set((state) => {
            if (!state.tpSessionData?.contents?.length) return;

            const contents = state.tpSessionData.contents;
            if (state.selectedIndex >= contents.length) {
              if (state.selectedIndex < state.internalTotalScreens - 1) {
                state.selectedIndex++;
              }
              return;
            }
            const currentContent = contents[state.selectedIndex];
            const contentLength = getContentLength(currentContent);

            if (state.selectedIndex >= contents.length) {
              if (state.selectedIndex < state.internalTotalScreens - 1) {
                state.selectedIndex++;
              }
              return;
            }

            const isLastRegularContent =
              state.selectedIndex === contents.length - 1;
            const isLastSubItem =
              state.selectedSubIndex === contentLength - 1 ||
              contentLength === 0;

            if (isLastRegularContent && isLastSubItem && state.isCompleted) {
              state.selectedIndex++;
              state.selectedSubIndex = 0;
              return;
            }

            if (
              contentLength > 0 &&
              state.selectedSubIndex < contentLength - 1
            ) {
              state.selectedSubIndex++;
            } else if (state.selectedIndex < contents.length - 1) {
              state.selectedIndex++;
              state.selectedSubIndex = 0;
            }
          });
          const currentState = get();
          checkAndCompleteTP(currentState.tpSessionData, currentState.isRetake);
        },

        decrement: () => {
          set((state) => {
            if (!state.tpSessionData?.contents?.length) return;
            if (state.selectedIndex === 0 && state.selectedSubIndex === 0)
              return;
            const contents = state.tpSessionData.contents;

            if (state.selectedSubIndex > 0) {
              state.selectedSubIndex--;
              return;
            }

            if (state.selectedIndex > 0) {
              state.selectedIndex--;
              const previousContent = contents[state.selectedIndex];
              const prevContentLength = getContentLength(previousContent);
              state.selectedSubIndex = Math.max(0, prevContentLength - 1);
            }
          });
          const currentState = get();
          checkAndCompleteTP(currentState.tpSessionData, currentState.isRetake);
        },

        retake: () => {
          set((state) => {
            state.selectedIndex = 0;
            state.selectedSubIndex = 0;
            state.isRetake = true;
          });
        },

        showLastScreen: () => {
          set((state) => {
            // For view-result, need show the certificate screen which is the last screen
            // So that has to be contents.length + 1 (leaderboard) + 1 (evaluation/certificate)
            if (state.tpSessionData?.contents) {
              // Set to the last screen (certificate)
              state.selectedIndex = state.internalTotalScreens - 1;
              state.selectedSubIndex = 0;
            }
          });
        },
      };
    })
  );
};
