import { gql } from '@apollo/client';
import { SearchData } from './search-modal.types';

export interface SearchResponse {
  Search: Array<SearchData>;
}

export const GET_SEARCH_RESULTS = gql`
  query Search($input: SearchQueryInput) {
    Search(input: $input) {
      path
      meta
      type
      value
      eid
    }
  }
`;

export const ADD_RECENT_SEARCH = gql`
  mutation RecentSearchAdd($input: AddSearchInput) {
    RecentSearchAdd(input: $input) {
      searchQuery
      viewedResult
      path
      eid
    }
  }
`;

interface TrainingData {
  eid: string;
  createdBy: string;
  allSupervisors: string[];
  assignedUsers: Array<{ eid: string }>;
  lastSession?: {
    eid: string;
  };
}

export interface TrainingResponse {
  TpById: TrainingData;
}

export const GET_TRAINING_DETAILS = gql`
  query TpById($eid: String!) {
    TpById(eid: $eid) {
      eid
      allSupervisors
      createdBy
      assignedUsers {
        eid
      }
      lastSession {
        eid
      }
    }
  }
`;
