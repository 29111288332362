import React, { FC } from 'react';
import { Flex, Spacer, useModalContext } from '@chakra-ui/react';
import { useFormContext, useFormState } from 'react-hook-form';
import { cloneDeep } from '@apollo/client/utilities';
import { ActionButton } from '../../../../../../ui-components';
import { TrainingContType } from '../../../../../../types';
import { useUserDataSelector } from '../../../../../../hooks';

interface IFormInput {
  type: TrainingContType;
  title: string;
  thumbnail?: string;
  addedBy?: string;
}

interface IProps {
  onAddClick?: (value: IFormInput) => void;
}

const MilestoneFooter: FC<IProps> = ({ onAddClick }) => {
  const addedByEid = useUserDataSelector((state) => state.eid);
  const { onClose } = useModalContext();
  const { handleSubmit, reset } = useFormContext<IFormInput>();
  const isDirty = useFormState().isDirty;

  const saveHandler = (values: IFormInput) => {
    const _values = cloneDeep(values);
    // @ts-ignore
    if (values?.eid) {
      onAddClick?.({
        ..._values,
        type: TrainingContType.MILESTONE,
        title: _values.title,
        thumbnail: _values.thumbnail,
      });
    } else {
      onAddClick?.({
        type: TrainingContType.MILESTONE,
        title: _values.title,
        thumbnail: _values.thumbnail,
        addedBy: addedByEid,
      });
    }
  };

  const onSaveAndAdd = async (values: IFormInput) => {
    saveHandler(values);
    await new Promise((resolve) => setTimeout(resolve, 300));
    reset({ title: '', thumbnail: undefined });
    await new Promise((resolve) => setTimeout(resolve, 300));
  };

  const onSaveAndExit = async (values: IFormInput) => {
    saveHandler(values);
    await new Promise((resolve) => setTimeout(resolve, 100));
    onClose();
  };

  return (
    <Flex
      gap={5}
      paddingY='10px'
      paddingX={4}
      bgColor='#FCFCFC'
      borderTop='1px solid #EFEFEF'
      borderBottomRadius='8px'
    >
      <Spacer />

      <ActionButton
        variant='outline'
        fontSize='13px'
        isDisabled={!isDirty}
        actionFn={handleSubmit(onSaveAndAdd)}
      >
        Save and add another
      </ActionButton>

      <ActionButton
        fontSize='13px'
        colorScheme='blue'
        isDisabled={!isDirty}
        actionFn={handleSubmit(onSaveAndExit)}
      >
        Save and exit
      </ActionButton>
    </Flex>
  );
};

export default MilestoneFooter;
