import styled from '@emotion/styled';

export const DatePickerCustomStyleWrapper = styled.div<{ readOnly?: boolean }>`
  position: relative;
  .react-date-picker__clear-button {
    display: none;
  }

  .react-date-picker__calendar-button {
    display: none;
  }

  .react-date-picker__inputGroup {
    display: none;
  }

  .react-date-picker__calendar.react-date-picker__calendar--closed {
    display: none;
  }

  .react-date-picker__calendar.react-date-picker__calendar--open {
    position: static !important;
    width: 100% !important;
    height: 100% !important;
  }

  .react-calendar__viewContainer {
    border-bottom: 1px solid #efefef;
    padding-bottom: 12px;
  }

  .react-date-picker__calendar.react-date-picker__calendar--open,
  .react-date-picker__calendar.react-date-picker__calendar--closed {
    .react-calendar {
      border-radius: 16px;
      padding: 12px;
      border: none;
      width: 340px;
      background: #fcfcfc;
    }

    .react-calendar__month-view__weekdays {
      display: grid !important;
      grid-template-columns: repeat(7, 1fr);
      gap: 14px;
      padding: 8px;

      .react-calendar__month-view__weekdays__weekday {
        text-align: center;
        width: 32px;
        padding: 0px;

        abbr {
          text-decoration: none;
          font-size: 12px;
          font-weight: 700;
          text-transform: capitalize;
          color: #9a9fa5;
        }
      }
    }

    .react-calendar__tile--rangeBothEnds,
    .react-calendar__month-view__days__day--neighboringMonth {
      color: #cdcdcd !important;
    }

    .react-calendar__month-view__days {
      display: grid !important;
      grid-template-columns: repeat(7, 1fr);
      gap: 8px 4px;
      text-align: center;
      margin-top: 0.5rem;

      .react-calendar__month-view__days__day--weekend {
        color: #1a1d1f;

        &:disabled {
          color: #ababab;
        }
      }

      .react-calendar__month-view__days__day {
        height: 32px;
        width: 32px;
        padding: 0;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: unset !important;
        font-weight: 500;
        border-radius: 6px;
        background: white;

        &.react-calendar__tile--active,
        &.react-calendar__tile--hasActive {
          background: #2a85ff;
          color: white !important;
        }

        abbr {
          text-decoration: none;
          font-size: 14px;
        }
      }
    }

    .react-calendar__tile--now {
      background: #5ea0f9 !important;
      color: white !important;

      abbr {
        color: white !important;
      }
    }

    .react-calendar__year-view {
      .react-calendar__year-view__months {
        display: grid !important;
        grid-template-columns: repeat(3, 1fr);
        gap: 8px 12px;
        text-align: center;
        margin-top: 0.5rem;
      }

      .react-calendar__year-view__months__month {
        height: 45px;
        padding: 0px;
        background: #ffffff;
        border-radius: 8px;

        abbr {
          color: #1b1b1b;
          font-size: 14px;
          font-weight: 500;
        }
      }

      .react-calendar__tile--hasActive {
        background: #2a85ff;
        color: white;
        abbr {
          color: white;
        }
      }
    }

    .react-calendar__decade-view,
    .react-calendar__century-view {
      .react-calendar__decade-view__years,
      .react-calendar__century-view__decades {
        display: grid !important;
        grid-template-columns: repeat(3, 1fr);
        gap: 8px 12px;
        text-align: center;
        margin-top: 0.5rem;
      }

      .react-calendar__decade-view__years__year,
      .react-calendar__century-view__decades__decade {
        height: 45px;
        padding: 0;
        background: #ffffff;
        border-radius: 8px;
        font-size: 14px;

        abbr {
          color: #1b1b1b;
          font-size: 14px;
          font-weight: 500;
        }
      }

      .react-calendar__tile--hasActive {
        background: #2a85ff;
        color: white;
        abbr {
          color: white;
        }
      }
    }

    .react-calendar__navigation {
      align-items: center;
      border-bottom: 1px solid #efefef;
      padding-bottom: 16px;

      .react-calendar__navigation__prev2-button,
      .react-calendar__navigation__next2-button {
        display: none;
      }

      .react-calendar__navigation__prev-button,
      .react-calendar__navigation__next-button {
        background: #efefef;
        border-radius: 8px;
        height: 36px;
        min-width: 36px !important;
        font-size: 24px;
      }

      .react-calendar__navigation__label {
        font-size: 15px;
        font-weight: 600;
        color: #1a1d1f;
      }

      button:disabled {
        background: none !important;
      }

      .react-calendar__navigation__label:hover,
      .react-calendar__navigation__label:focus {
        background: none;
      }
    }
  }

  .react-calendar--year-view ~ div > div:last-child,
  .react-calendar--decade-view ~ div > div:last-child,
  .react-calendar--century-view ~ div > div:last-child {
    display: none !important;
  }

  .react-calendar--monty-view ~ div > div:last-child {
    display: flex !important;
  }
`;
