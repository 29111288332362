import React, { FC, useCallback, useRef, useState } from 'react';
import { Flex, Progress } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import {
  UserDetailsComponent,
  UserDetailsRef,
} from 'sub-components/UserLocationDetails';
import { useSubmitForm } from 'sub-components/forms';

import {
  ChapterContent,
  ChapterFileView,
  ChapterFooter,
  ChapterViewContainer,
} from 'pages/Chapters/ChapterView';
import Footer from './Footer';
import ChapterHeader from './ChapterHeader';

interface IProps {
  data: any;
  taskId: string;
  sessionId: string;
  updatingProgress: boolean;
  goBack: () => void;
  updateProgressHandler: () => void;
}

const ChapterRender: FC<IProps> = ({
  data,
  taskId,
  sessionId,
  updatingProgress,
  goBack,
  updateProgressHandler,
}) => {
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useState('');
  const useDetailRef = useRef<UserDetailsRef>(null);
  const [progress, setProgress] = useState(0);
  const submitForm = useSubmitForm();
  const onItemClick = useCallback((event: MouseEvent, node: any) => {
    switch (node?.type?.name) {
      case 'member':
        useDetailRef.current?.openDetail('user', node.attrs);
        break;
      case 'location':
        useDetailRef.current?.openDetail('branch', node.attrs);
        break;
      case 'form':
        submitForm({
          formId: node.attrs?.eid,
          title: node.attrs?.label,
        });
        break;
      case 'chapter':
        window.open(`/chapters/view/${node.attrs?.eid}`, '_blank');
        break;
    }
  }, []);
  const onScrollHandler = (e: React.UIEvent<HTMLElement>) => {
    const target = e.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    const scrolled = scrollTop / (scrollHeight - clientHeight);
    setProgress(scrolled * 100);
  };

  const sopUser = data?.lastUpdatedByUser || data?.createdByUser;

  return (
    <Flex flexDir='column' flex={1} justify='center' gap={4}>
      <ChapterViewContainer>
        <Progress
          pos='absolute'
          top={0}
          width='full'
          marginLeft='-16px'
          size='xs'
          borderTopRadius='5px'
          bg='transparent'
          value={progress}
        />

        <ChapterHeader
          searchQuery={searchQuery}
          onSearch={(e) => setSearchQuery(e?.target?.value)}
          onCloseClick={goBack}
        />

        {data?.sopType !== 'text' ? (
          <ChapterFileView sopData={data} />
        ) : (
          <ChapterContent
            data={data}
            searchQuery={searchQuery}
            onItemClick={onItemClick}
            onScroll={onScrollHandler}
          />
        )}

        <ChapterFooter name={sopUser?.name} lastUpdated={data?.lastUpdated} />
      </ChapterViewContainer>

      <UserDetailsComponent ref={useDetailRef} />

      <Footer
        goBack={goBack}
        taskId={taskId}
        updatingProgress={updatingProgress}
        updateProgressHandler={updateProgressHandler}
      />
    </Flex>
  );
};

export default ChapterRender;
