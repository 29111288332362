import React, { FC } from 'react';
import { QuizQuestionType } from '../../../../../../../types';
import { QuizQFormInput } from '../quiz-form/quiz-form.types';
import SelectQuizToPreview from './SelectQuizToPreview';
import {
  FillBlankPreview,
  MatchSetPreview,
  MultiChoicePreview,
  SequencePreview,
  TrueFalsePreview,
} from './question-types';

interface IProps {
  previewData?: QuizQFormInput;
}

const QuizPreview: FC<IProps> = ({ previewData }) => {
  if (!previewData) {
    return <SelectQuizToPreview />;
  }

  switch (previewData.type) {
    case QuizQuestionType.MULTI_CHOICE:
      return <MultiChoicePreview data={previewData} />;
    case QuizQuestionType.TRUE_FALSE:
      return <TrueFalsePreview data={previewData} />;
    case QuizQuestionType.MATCH_SET:
      return <MatchSetPreview data={previewData} />;
    case QuizQuestionType.SEQUENCE:
      return <SequencePreview data={previewData} />;
    case QuizQuestionType.FILL_IN_BLANK:
      return <FillBlankPreview data={previewData} />;
    default:
      return <SelectQuizToPreview />;
  }
};

export default QuizPreview;
