import { useFormContext } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { cloneDeep } from '@apollo/client/utilities';
import {
  CREATE_TRAINING,
  CreateVariable,
  TrainingResponse,
  UPDATE_TRAINING,
} from './training.graphql';
import { TFormInput } from './training-input.types';
import { toPayloadInput } from './data-mapper.helper';

type UseSaveHandler = (values: TFormInput) => Promise<void>;

export const useSaveHandler = (): UseSaveHandler => {
  const { getValues, setValue, control } = useFormContext<TFormInput>();

  const [createTraining] = useMutation<TrainingResponse, CreateVariable>(
    CREATE_TRAINING
  );

  const [updateTraining] = useMutation<TrainingResponse, CreateVariable>(
    UPDATE_TRAINING
  );

  return async (values: TFormInput) => {
    try {
      control._disableForm(true);
      console.log('Raw input --> ', getValues());
      const input = toPayloadInput(cloneDeep(values));
      console.log('Mapped payload --> ', input);

      if (values?.eid) {
        input.eid = values.eid;
      }

      if (input.eid) {
        const res = await updateTraining({
          variables: {
            input: input,
          },
        });

        if (res.errors) {
          return Promise.reject(res.errors);
        }
      } else {
        const res = await createTraining({
          variables: {
            input: input,
          },
        });

        if (res.errors) {
          return Promise.reject(res.errors);
        }
        setValue('eid', res.data?.CreateTP?.eid);
      }
    } catch (err) {
      return Promise.reject(err);
    } finally {
      control._disableForm(false);
    }
  };
};
