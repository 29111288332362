import { gql } from '@apollo/client';

export const TP_SESSION_BY_ID_WEB = gql`
  query TP_SESSION_BY_ID_WEB(
    $eid: String!
    $filter: FilterFindManyTPProgressInput
    $assignedUserIds: [String!]
  ) {
    TpSessionById(eid: $eid) {
      name
      title
      entityId
      createdBy
      assignedUsers(userIds: $assignedUserIds) {
        userId
        locations
        status
        startedOn
        assignedOn
        completedOn
        evaluatedOn
        dueDate
        certificateDownloaded
        crossTrained
        isEvaluated
        evaluationViewed
        score
        scorePercent
      }
      trainingId
      hasScoring
      maxScore
      hasSkillVerifier
      supervisors
      skillVerifier {
        name
        skill
      }
      isRepeating
      startDate
      endDate
      contents {
        type
        isMandatory
        isRemoved
        hasScoring
        title
        questions {
          thumbnail
          hasMultipleCorrect
          label
          subTitle
          type
          score
          options {
            uid
            thumbnail
            text
            isCorrect
          }
          pairs {
            uid
            left
            leftThumbnail
            right
            rightThumbnail
          }
          isTrue
          sequence {
            uid
            text
            thumbnail
          }
          textWithBlanks
          blanks {
            uid
            answer
          }
          createdAt
          updatedAt
          eid
        }
        description
        thumbnail
        addedBy
        dateAdded
        eid
      }
      createdAt
      updatedAt
      eid
      userProgress(filter: $filter) {
        certificate
        userId
        trainingId
        sessionId
        maxScore
        score
        scorePercent
        isCompleted
        completedAt
        isEvaluated
        evaluatedAt
        evaluatedBy
        progress {
          type
          id
          score
          isCompleted
          responses {
            qid
            score
            options
            pairs {
              uid
              left
              right
              leftThumbnail
              rightThumbnail
            }
            isCorrect
            sequence {
              uid
              text
              thumbnail
            }
            blanks
            createdAt
            updatedAt
          }
          updatedAt
        }
        skillVerifierResponse {
          name
          rating
          comment
          files
        }
        createdAt
        updatedAt
        eid
      }
      training {
        title
      }
    }
  }
`;

export const GET_SOP_BY_ID = gql`
  query GET_SOP_BY_ID($eid: String!) {
    SopById(eid: $eid) {
      eid
      icon
      content
      title
      isMultiPage
      createdAt
      updatedAt
      sopType
      lastUpdated
      visibility
      files {
        name
        url
        type
        mimetype
        fileSize
        createdBy {
          eid
          name
        }
        createdAt
        updatedAt
      }
      media {
        playbackId
        assetId
        type
        rawUrl
        mediaUrl
        thumbnail
        status
        meta
        _id
      }
      categoryId
      category
      thumbnail
      status
      smartPageEnabled
      translations {
        languageCode
        status
        title
        raw_content
        content
        lastUpdatedOn
        lastUpdateRequestedOn
        lastUpdatedBy
        lastRequestedBy
      }
      cards {
        cardType
        title
        thumbnail
        backgroundColor
        layout
        subTitle
        category
        categoryId
        isSmart
        smartPageEnabled
        trainingOnly
        createdBy
        entityId
        content
        raw_content
        formId
        status
        views
        lastViewed
        lastUpdated
        files {
          name
          url
          type
          mimetype
          fileSize
          createdBy {
            eid
            name
          }
          createdAt
          updatedAt
        }
        media {
          playbackId
          assetId
          type
          rawUrl
          mediaUrl
          thumbnail
          status
          meta
          _id
        }
        handbookView
        translations
        defaultLanguage
        createdAt
        updatedAt
        eid
      }
      cardView {
        cardType
        title
        thumbnail
        backgroundColor
        layout
        subTitle
        category
        categoryId
        isSmart
        smartPageEnabled
        trainingOnly
        createdBy
        entityId
        content
        raw_content
        formId
        status
        views
        lastViewed
        lastUpdated
        files {
          name
          url
          type
          mimetype
          fileSize
          createdBy {
            eid
            name
          }
          createdAt
          updatedAt
        }
        media {
          playbackId
          assetId
          type
          rawUrl
          mediaUrl
          thumbnail
          status
          meta
          _id
        }
        createdAt
        updatedAt
        eid
      }
    }
  }
`;

export const UPDATE_TP_PROGRESS = gql`
  mutation UPDATE_TP_PROGRESS($input: AddTPProgressInputInput) {
    AddTpProgress(input: $input) {
      eid
      userId
      trainingId
      sessionId
      score
      scorePercent
      certificate
      isCompleted
      crossTrained
      completedAt
      hasSkillVerifier
      isEvaluated
      evaluatedAt
      evaluatedBy
      progress {
        type
        id
        score
        isCompleted
        responses {
          qid
          score
          options
          pairs {
            uid
            left
            right
            leftThumbnail
            rightThumbnail
          }
          isCorrect
          sequence {
            uid
            thumbnail
            text
          }
          blanks
          createdAt
          updatedAt
        }
        updatedAt
      }
      skillVerifierResponse {
        name
        rating
        comment
        files
      }
      createdAt
      updatedAt
      eid
    }
  }
`;

export const COMPLETE_TP = gql`
  mutation TpCompleted($sessionId: String!) {
    TpCompleted(sessionId: $sessionId) {
      succeed
    }
  }
`;

export const GET_FORM_BY_ID = gql`
  query FormById($eid: String!) {
    FormById(eid: $eid) {
      title
      description
      category
      categoryId
      createdBy {
        eid
        name
      }
      contributors
      entityId
      thumbnail
      status
      questions
      isTemplate
      lastUpdated
      lastUpdatedBy {
        name
        eid
      }
      responseVisibility
      notifyVia
      visibleTo {
        users
        locations
        roles
        condition
        _id
      }
      visibility
      createdAt
      updatedAt
      eid
      contributorUsers {
        eid
        name
      }
      userList
      latestResponse {
        userId
        locations
        status
        formId
        sessionId
        response
        initialResponseAt
        completedAt
        createdAt
        updatedAt
        eid
        createdBy {
          eid
          name
        }
      }
      optionsMapping
      responseCount
    }
  }
`;

export const GET_CARD_BY_ID = gql`
  query FindCardById($eid: String!) {
    findCardById(eid: $eid) {
      cardType
      title
      thumbnail
      backgroundColor
      layout
      subTitle
      category
      categoryId
      isSmart
      smartPageEnabled
      trainingOnly
      createdBy
      entityId
      content
      raw_content
      formId
      status
      views
      lastViewed
      lastUpdated
      files {
        name
        url
        type
        mimetype
        fileSize
        createdBy {
          eid
          name
        }
        createdAt
        updatedAt
      }
      media {
        playbackId
        assetId
        type
        rawUrl
        mediaUrl
        thumbnail
        status
        meta
        _id
      }
      handbookView
      translations
      defaultLanguage
      createdAt
      updatedAt
      eid
    }
  }
`;

export const REASSIGN_TP_USER = gql`
  mutation ReAssignTpUser(
    $reAssignTpUserSessionId2: String!
    $userId: String!
  ) {
    ReAssignTpUser(sessionId: $reAssignTpUserSessionId2, userId: $userId) {
      succeed
    }
  }
`;

export const GET_TP_USER_FORM_RESPONSE = gql`
  query TpFormResponse(
    $userId: String!
    $trainingSessionId: String!
    $formId: String!
  ) {
    TpFormResponse(
      userId: $userId
      trainingSessionId: $trainingSessionId
      formId: $formId
    ) {
      userId
      locations
      status
      formId
      sessionId
      trainingSessionId
      response
      initialResponseAt
      completedAt
      createdAt
      updatedAt
      eid
      createdBy {
        eid
        name
      }
      form {
        title
        description
        category
        categoryId
        createdBy {
          eid
          name
        }
        contributors
        entityId
        thumbnail
        status
        questions
        isTemplate
        lastUpdated
        lastUpdatedBy {
          name
          eid
        }
        responseVisibility
        notifyVia
        visibleTo {
          condition
          locations
          roles
          users
        }
        visibility
        createdAt
        updatedAt
        eid
      }
    }
  }
`;

export const GET_TP_OVERALL_USER_WISE_ANALYTICS = gql`
  query GET_TP_OVERALL_USER_WISE_ANALYTICS {
    TpOverallUserWiseAnalytics
  }
`;

export const VIEW_EVALUATION_UPDATE = gql`
  mutation ViewTpEvaluation($sessionId: String!) {
    ViewTpEvaluation(sessionId: $sessionId) {
      succeed
    }
  }
`;
