import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { userObj } from 'sop-commons/src';
import { Container, Heading } from './components';
import { TFormInput } from './components/training-input.types';
import { defaultTrainingInput } from './training.default';

const CreateTraining: FC = () => {
  const methods = useForm<TFormInput>({
    defaultValues: defaultTrainingInput(userObj().authRole),
  });

  return (
    <FormProvider {...methods}>
      <Flex flexDir='column' gap={4}>
        <Heading />
        <Container />
      </Flex>
    </FormProvider>
  );
};

CreateTraining.displayName =
  'displayName:sub-components/training-v2/create/CreateTraining';

export default CreateTraining;
