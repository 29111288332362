import React, { createContext, useContext, ReactNode, useState } from 'react';
import { OperationVariables, QueryLazyOptions } from '@apollo/client';
import { TExcueteCallbackVars } from 'sub-components/training-v2/dashboard/components/GenericFilter/types/filter-types';

export interface MyTrainingContextType {
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  selectedPageIndex: number;
  setSelectedPageIndex: React.Dispatch<React.SetStateAction<number>>;
  getTrainingPathSessionData: (
    options?: QueryLazyOptions<OperationVariables>
  ) => void;
  trainingPathFilters: TExcueteCallbackVars;
  sortType: {
    sort: string;
    order: string;
    tooltipLabel: string;
  };
  setSortType: React.Dispatch<React.SetStateAction<any>>;
  filters: any;
  setFilters: React.Dispatch<React.SetStateAction<any>>;
  trainingCategories: any[];
}

const MyTrainingContext = createContext<MyTrainingContextType | undefined>(
  undefined
);

interface MyTrainingProviderProps {
  children: ReactNode;
  value: MyTrainingContextType;
}

export const MyTrainingProvider: React.FC<MyTrainingProviderProps> = ({
  children,
  value,
}) => {
  return (
    <MyTrainingContext.Provider value={value}>
      {children}
    </MyTrainingContext.Provider>
  );
};

export const useMyTrainingContext = (): MyTrainingContextType => {
  const context = useContext(MyTrainingContext);
  if (context === undefined) {
    throw new Error(
      'useMyTrainingContext must be used within a MyTrainingProvider'
    );
  }
  return context;
};
