import { Flex, Text } from '@chakra-ui/react';
import React, { FC } from 'react';
import { ReactComponent as IllustrationsIcon } from 'assets/images/Illustrations.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useTrainingContext } from '../../TrainingTableContainer';
import { ReactComponent as TrainingPathEmptyStateIcon } from 'assets/images/empty-state/training-path-empty-state.svg';

interface IProps {
  filterCount?: number;
  trackingTpSearchQuery?: string;
  dataLength?: number;
}

const EmptyState: FC<IProps> = ({ filterCount, trackingTpSearchQuery }) => {
  const { setTrainingContextData, setFilters } = useTrainingContext();

  const renderIcon = () => {
    if (!trackingTpSearchQuery && !filterCount) {
      return <TrainingPathEmptyStateIcon />;
    } else {
      return <IllustrationsIcon />;
    }
  };

  let title = 'Nothing to learn as of now!';
  let description = `Your training will be visible once your manager assign them to you.`;

  if (trackingTpSearchQuery) {
    title = `No matches found for your search ${trackingTpSearchQuery}.`;
    description = `Please check the spelling, if it's correct.`;
  }

  if (filterCount) {
    title = `No matches found with these filters, please adjust your filters!`;
    description = `But don't worry, there's more to explore!`;
  }

  return (
    <Flex
      height={'60vh'}
      justifyContent={'center'}
      alignItems={'center'}
      flexDir={'column'}
    >
      {(trackingTpSearchQuery || !!filterCount) && (
        <Flex
          onClick={() => {
            setTrainingContextData((prevState) => {
              return { ...prevState, trackingTpSearchQuery: '' };
            });

            setFilters((prevFilterState) => {
              return {
                ...prevFilterState,
                totalFiltersTypes: 0,
                selectedCategories: [],
                selectedCategoriesId: [],
                selectedTypes: [],
                selectedTypesId: [],
                selectedStatuses: [],
                selectedStatusesId: [],
                mergedFiltersArray: [],
              };
            });
          }}
          cursor={'pointer'}
          gap={2}
          alignItems={'center'}
          color={'#2A85FF'}
        >
          <FontAwesomeIcon icon={faArrowLeft as IconProp} />
          <Text fontWeight={500} fontSize={'12px'}>
            Go back to previous page
          </Text>
        </Flex>
      )}

      {renderIcon()}

      <Text mt={'20px'} color={'#33383F'} fontSize={'15px'} fontWeight={600}>
        {title}
      </Text>
      <Text fontSize={'12px'} fontWeight={500} color={'#6F767E'}>
        {description}
      </Text>
    </Flex>
  );
};

export default EmptyState;
