import React, { FC, useCallback, useMemo } from 'react';
import { Box, Center } from '@chakra-ui/react';
import { ActionType } from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { AuthRole } from '../../../../../../authorization';
import { useUserDataSelector } from '../../../../../../hooks';
import { toArray } from '../../../../../../utils';
import { TSortBy } from '../../../../../../types';
import { ActionMenu } from '../../../../../../ui-components';
import {
  Column,
  SortingTable,
} from '../../../../../../sub-components/ChakraTable/SortingTable';

import {
  CategoryCell,
  CreatedDateCell,
  OccurredDateCell,
  SubjectCell,
  VisibleToLocationOwner,
} from './components';
import { ConversationLogItem } from './conversation-log.graphql';

import OpenIcon from '../../../../../../assets/images/open.svg';
import PinIcon from '../../../../../../assets/images/pin.svg';
import UnPinIcon from '../../../../../../assets/images/unpin.svg';
import EditIcon from '../../../../../../assets/images/edit-black.svg';
import DeleteIcon from '../../../../../../assets/images/trashRed.svg';
import PinnedIcon from '../../../../../../assets/images/pinned.svg';
import { NullShorting } from '../../../../../../utils/sorting';

interface IProps {
  logsList?: ConversationLogItem[];
  actionHandler: (
    actionName: string,
    data: ConversationLogItem
  ) => Promise<void | null>;
  updateSortBy: (sortBy: TSortBy[]) => void;
  sortBy?: TSortBy[];
}

const ConversationLogLists: FC<IProps> = ({
  logsList = [],
  actionHandler,
  updateSortBy,
  sortBy = [],
}) => {
  const canEditLog = useUserDataSelector(
    (state) =>
      state.type === 'user' &&
      [AuthRole.SUPER_ADMIN, AuthRole.ADMIN].includes(state.authRole)
  );

  const authUser = useUserDataSelector((state) => ({
    eid: state.eid,
    authRole: state.authRole,
    type: state.type,
  }));

  const stateReducer = <T extends unknown>(newState: T, action: ActionType) => {
    switch (action?.type) {
      case 'toggleSortBy':
        // @ts-ignore
        updateSortBy?.(toArray<TSortBy>(newState?.sortBy));
        break;
    }

    return newState;
  };

  const getMenuData = useCallback(
    (logItem: ConversationLogItem) => {
      if (!canEditLog) {
        return [
          {
            name: 'Open',
            value: 'open',
            icon: OpenIcon,
          },
        ];
      }

      const isPinned = logItem?.isPinned;

      const canDelete =
        authUser?.authRole === AuthRole.SUPER_ADMIN ||
        authUser?.eid === logItem?.createdBy;

      return [
        {
          name: 'Open',
          value: 'open',
          icon: OpenIcon,
        },
        {
          ...(isPinned
            ? {
                name: 'Unpin this conversation',
                value: 'unpin',
                icon: UnPinIcon,
              }
            : {
                name: 'Pin this conversation',
                value: 'pin',
                icon: PinIcon,
              }),
        },
        ...(canDelete
          ? [
              {
                name: 'Edit',
                value: 'edit',
                icon: EditIcon,
              },
              {
                name: 'Delete',
                value: 'delete',
                icon: DeleteIcon,
                textColor: '#FF6A55',
              },
            ]
          : []),
      ];
    },
    [canEditLog, authUser]
  );

  const columns = useMemo((): Column<ConversationLogItem>[] => {
    return [
      {
        Header: '',
        accessor: 'showLO',
        width: '50px',
        disableSortBy: true,
        Cell: ({ cell: { value, row } }) => {
          return <VisibleToLocationOwner value={value} />;
        },
        sortType: NullShorting,
      },
      {
        Header: 'Subject',
        accessor: 'subject',
        width: 'calc(62% - 180px)',
        Cell: ({ cell: { value, row } }) => {
          return (
            <SubjectCell
              value={value}
              onClick={() => actionHandler?.('open', row.original)}
            />
          );
        },
        sortType: NullShorting,
      },
      {
        Header: 'Occurred on',
        accessor: 'occurrenceDate',
        width: '20%',
        Cell: ({ cell: { value } }) => {
          return <OccurredDateCell value={value} />;
        },
        sortType: NullShorting,
      },
      {
        Header: 'Category',
        accessor: 'category',
        width: '18%',
        disableSortBy: true,
        Cell: ({ cell: { value } }) => {
          return <CategoryCell value={value} />;
        },
      },
      {
        Header: 'Created on',
        accessor: 'createdAt',
        width: '130px',
        Cell: ({ cell: { value, row } }) => {
          return (
            <CreatedDateCell
              value={value}
              createdBy={row.original.createdByUser}
            />
          );
        },
        sortType: NullShorting,
      },
      {
        accessor: 'eid',
        width: '50px',
        disableSortBy: true,
        Cell: ({ cell: { row } }) => {
          const isPinned = row.original.isPinned;
          return (
            <Center>
              <ActionMenu
                menuData={getMenuData(row.original)}
                arrowSize={10}
                closeOnBlur={true}
                offset={[0, 0]}
                clickedItem={(_, clickedItem) =>
                  actionHandler?.(clickedItem, row.original)
                }
              >
                <Box cursor='pointer'>
                  <FontAwesomeIcon
                    icon={faEllipsis as IconProp}
                    fontSize='22px'
                    color='#6F767E'
                  />
                </Box>
              </ActionMenu>

              {isPinned && (
                <Box
                  position='relative'
                  _after={{
                    content: "''",
                    backgroundImage: PinnedIcon,
                    position: 'absolute',
                    top: '-12px',
                    right: '-26px',
                    boxSize: '18px',
                  }}
                />
              )}
            </Center>
          );
        },
      },
    ];
  }, [actionHandler, getMenuData]);

  return (
    <SortingTable
      page={1}
      columns={columns}
      data={logsList}
      stateReducer={stateReducer}
      isHidePagination
      options={{
        autoResetSortBy: false,
        initialState: {
          sortBy: sortBy,
        },
      }}
    />
  );
};

export default ConversationLogLists;
