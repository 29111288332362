import { gql } from '@apollo/client';
import { JSONContent } from 'delightree-editor';
import { ConversationLogItem } from '../conversation-log.graphql';

interface BaseInput {
  category: string;
  content: JSONContent;
  locationId: string;
  occurrenceDate: string;
  raw_content: string;
  subject: string;

  createdBy?: string;
}

export interface AddInputVariable {
  input: BaseInput;
}

export const CREATE_LOGS_QUERY = gql`
  mutation AddConversationLogs($input: ConversationLogsAddInputInput) {
    AddConversationLogs(input: $input) {
      eid
    }
  }
`;

export interface UpdateInputVariable {
  input: Partial<BaseInput> & {
    eid: string;
    isPinned?: boolean;
  };
}

export const UPDATE_LOGS_QUERY = gql`
  mutation UpdateConversationLogs($input: ConversationLogsUpdateInputInput) {
    UpdateConversationLogs(input: $input) {
      eid
    }
  }
`;

export interface LogEditResponse {
  conversationLogsFindById: ConversationLogItem & {
    content: JSONContent;
  };
}

export const CONVERSATION_LOGS_FIND = gql`
  query ConversationLogsFindById($eid: String!) {
    conversationLogsFindById(eid: $eid) {
      eid
      showLO
      category
      occurrenceDate
      createdAt
      subject
      content
      createdByUser {
        name
        profilePic
        role
        eid
      }
    }
  }
`;
