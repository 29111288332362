import { Box, Flex } from '@chakra-ui/react';
import { FC } from 'react';
import { CustomTooltip } from 'ui-components/Tooltip';

interface IProps {
  item: {
    name: string;
    eid: string;
  };
  onClick?: () => void;
}

const BoardItem: FC<IProps> = ({ item, onClick, children }) => {
  return (
    <Flex
      gap={3}
      height='48px'
      minHeight='48px'
      alignItems='center'
      p={'16px'}
      borderRadius='16px'
      // cursor='pointer'
      _hover={{ bg: '#F4F4F3' }}
      color='#FFFFFF'
      onClick={onClick}
    >
      <Box
        flex={1}
        fontSize={'14px'}
        fontWeight={500}
        isTruncated
        textColor={'#1A1D1F'}
        zIndex={1}
      >
        <CustomTooltip>{item.name}</CustomTooltip>
      </Box>

      {children}
    </Flex>
  );
};

export default BoardItem;
