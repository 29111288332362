import { TFormInput } from '../../training-input.types';

export const validateDeadlineType = (
  value: TFormInput['deadlineType'],
  formData: Partial<TFormInput>
): string | undefined => {
  const canHaveDate =
    formData.frequency === 'once' && formData.assigneeUserType === 'old';
  if (value === 'date' && !canHaveDate) {
    return 'Please select valid completion deadline';
  }

  if (formData.frequency === 'repeat' && value === 'date') {
    return 'Please select valid completion deadline';
  }
};

export const validateAssignType = (
  value: TFormInput['assignmentType'],
  formData: Partial<TFormInput>
): string | undefined => {
  if (formData.deadlineType === 'date') {
    const canHaveDate =
      formData.assigneeUserType === 'old' && formData.frequency === 'once';

    if (canHaveDate && value !== 'training') {
      return undefined;
    }

    return 'Please select valid training start date';
  }

  if (formData.deadlineType === 'duration' && value === 'date') {
    return 'Please select valid training start date';
  }

  if (formData.deadlineType === 'noDeadline' && value === 'date') {
    if (formData.assigneeUserType === 'old' && formData.frequency === 'once') {
      return undefined;
    }
    return 'Please select valid training start date';
  }
};
