import { Flex, Box } from '@chakra-ui/react';
import { FC } from 'react';
import {
  TpOverallLocationsWiseAnalytics,
  TpOverallUserWiseAnalytics,
} from '../../Tracking/tracking-types';
import ScoreCard from './ScoreCard';

interface IProps {
  completeData:
    | TpOverallLocationsWiseAnalytics[]
    | TpOverallUserWiseAnalytics[];
}

const RenderScoreCards: FC<IProps> = ({ completeData }) => {
  const highestPerforming = completeData?.slice(0, 3);
  const [topLocation, secondLocation, thirdLocation] = highestPerforming || [];

  return (
    <Flex
      id='t-c-flex-container'
      justify='center'
      align='flex-end'
      gap={4}
      wrap='nowrap'
    >
      {secondLocation && (
        <Box id='t-c-flex-item' alignSelf='flex-end'>
          <ScoreCard
            score={secondLocation.completionRate as number}
            label={
              (secondLocation as TpOverallLocationsWiseAnalytics)
                .locationName ||
              (secondLocation as TpOverallUserWiseAnalytics).userName
            }
            locationId={
              (secondLocation as TpOverallLocationsWiseAnalytics).locationId
            }
            position={2}
          />
        </Box>
      )}

      {topLocation && (
        <Box id='t-c-flex-item' alignSelf='flex-start'>
          <ScoreCard
            score={topLocation.completionRate as number}
            label={
              (topLocation as TpOverallLocationsWiseAnalytics).locationName ||
              (topLocation as TpOverallUserWiseAnalytics).userName
            }
            locationId={
              (topLocation as TpOverallLocationsWiseAnalytics).locationId
            }
            position={1}
            isCrown
          />
        </Box>
      )}

      {thirdLocation && (
        <Box id='t-c-flex-item' alignSelf='flex-end'>
          <ScoreCard
            score={thirdLocation.completionRate as number}
            label={
              (thirdLocation as TpOverallLocationsWiseAnalytics).locationName ||
              (thirdLocation as TpOverallUserWiseAnalytics).userName
            }
            locationId={
              (thirdLocation as TpOverallLocationsWiseAnalytics).locationId
            }
            position={3}
          />
        </Box>
      )}
    </Flex>
  );
};

export default RenderScoreCards;
