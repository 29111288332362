import { ChevronLeftIcon } from '@chakra-ui/icons';
import { Box, Flex } from '@chakra-ui/react';
import { TRAINING_PATH_V2 } from 'appRoutes';
import React, { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { usePlayContext } from '../store/context';

interface ILocationState {
  backToTitle: string;
}

const Heading: FC = () => {
  const { sessionData } = usePlayContext((state) => ({
    sessionData: state?.tpSessionData,
  }));
  const locState = useLocation<ILocationState>()?.state;
  const history = useHistory();

  const redirectHandler = () => {
    if (locState?.backToTitle) {
      history?.goBack();
    } else {
      history.push(TRAINING_PATH_V2);
    }
  };
  return (
    <Flex flexDir='column' gap={2}>
      <Box fontWeight={600} fontSize='24px'>
        {sessionData?.training?.title || 'No title'}
      </Box>
      <Flex
        color='rgba(111, 118, 126, 1)'
        w='fit-content'
        cursor='pointer'
        align='center'
        gap='5px'
        onClick={redirectHandler}
      >
        <ChevronLeftIcon fontSize='20px' />
        <Box as='span' fontWeight={500} fontSize='15px'>
          {locState?.backToTitle || 'Back to training'}
        </Box>
      </Flex>
    </Flex>
  );
};

Heading.displayName =
  'displayName:sub-components/training-v2/play/components/Heading';

export default Heading;
