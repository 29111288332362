import {
  OperationVariables,
  QueryLazyOptions,
  useApolloClient,
  useLazyQuery,
} from '@apollo/client';
import { Center, Flex, Text } from '@chakra-ui/react';
import { useUserDataSelector } from 'hooks';
import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
} from 'react';
import Loader from 'sub-components/Loader';
import {
  ITPCategory,
  ITPFilter,
  TPFiltersParams,
} from 'sub-components/training-v2/shared/types';
import { PageInfo } from 'types';
import { TExcueteCallbackVars } from './GenericFilter/types/filter-types';
import { TopSection, BottomSection, EmptyState } from './myTrainings';
import { prepareTrainingPathSessionData } from './myTrainings/helper';
import {
  USER_TRAINING_PATH_SESSIONS,
  GET_TRAINING_CATEGORIES,
} from './myTrainings/query/myTraining.graphql';
import {
  UserTrainingPathSessionsType,
  TrainingData,
  TrainingCategoriesType,
} from './myTrainings/types/myTraining.types';
import { MyTrainingContextType, MyTrainingProvider } from './MyTrainingContext';
import { ReactComponent as DateCenterEmptyStateIcon } from 'assets/images/empty-state/date-center-empty-state.svg';

interface ISortType {
  sort: string;
  order: string;
  tooltipLabel: string;
}

export type FiltersType = {
  totalFiltersTypes: number;
  types: any[];
  selectedTypes: any[];
  selectedTypesId: any[];
  categories: any[];
  selectedCategories: any[];
  selectedCategoriesId: any[];
  statuses: any[];
  selectedStatuses: any[];
  selectedStatusesId: any[];
  mergedFiltersArray: any[];
};

type MyTrainingType = {
  trainingPathFilters?: any;
  getTrainingPathSessionData: (
    options?: QueryLazyOptions<OperationVariables> | undefined
  ) => void;
  searchQuery?: string;
  setSelectedPageIndex: React.Dispatch<React.SetStateAction<number>>;
  selectedPageIndex: number;
  setSortType: React.Dispatch<React.SetStateAction<ISortType>>;
  sortType: ISortType;
  filters: FiltersType;
  setFilters: React.Dispatch<React.SetStateAction<FiltersType>>;
  trainingCategories: ITPCategory[];
};

const MyTrainingsContainer: FC = () => {
  const [trainingPathData, setTrainingPathData] = useState<
    TrainingData[] | UserTrainingPathSessionsType[]
  >([]);
  const [trainingCategories, setTrainingCategories] = useState<ITPCategory[]>(
    []
  );
  const [sortType, setSortType] = useState<ISortType>({
    sort: 'CREATEDAT_DESC',
    order: 'none',
    tooltipLabel: 'sort asc',
  });
  const [selectedPageIndex, setSelectedPageIndex] = useState(1);
  const [pageInfo, setPageInfo] = useState<PageInfo>({
    currentPage: 1,
    itemCount: 10,
    pageCount: 1,
    perPage: 10,
    hasNextPage: false,
    hasPreviousPage: false,
  });
  const [searchQuery, setSearchQuery] = useState<string>('');
  const loggedInUserId = useUserDataSelector((state) => state?.eid);
  const [filters, setFilters] = useState<FiltersType>({
    totalFiltersTypes: 0,
    types: [],
    selectedTypes: [],
    selectedTypesId: [],
    categories: [],
    selectedCategories: [],
    selectedCategoriesId: [],
    statuses: [],
    selectedStatuses: [],
    selectedStatusesId: [],
    mergedFiltersArray: [],
  });

  const trainingPathFilters: TExcueteCallbackVars = (
    page = 1,
    sort = 'CREATEDAT_DESC'
  ) => {
    let filter: {
      query?: string;
      category?: string[];
      status?: string[];
      assigneeStatus?: string[];
      overdue?: boolean;
    } = {
      query: '',
      status: [
        'TRAINING_CREATED',
        'TRAINING_STARTED',
        'TRAINING_REPEAT',
        'TRAINING_COMPLETED',
        'TRAINING_TERMINATED',
        'TRAINING_ONE_TIME',
      ],
      assigneeStatus: [],
    };
    if (searchQuery) {
      filter.query = searchQuery;
    }

    // Add filter for categories
    if (filters?.selectedCategoriesId?.length > 0) {
      filter.category = filters?.selectedCategoriesId;
    }

    // Add filter for status

    // Overdue
    if (filters?.selectedStatusesId?.includes('static_1_overdue')) {
      filter.overdue = true;
    }

    // Yet to start
    if (filters?.selectedStatusesId?.includes('static_1_yetToStart')) {
      filter.assigneeStatus?.push('assigned');
    }

    // In Progress
    if (filters?.selectedStatusesId?.includes('static_1_inProgress')) {
      filter.assigneeStatus?.push('started');
    }

    // Completed
    if (filters?.selectedStatusesId?.includes('static_1_completed')) {
      filter.assigneeStatus?.push('completed');
    }

    // Add filter for Types
    if (filters?.selectedTypesId?.length > 0) {
      if (
        filters?.selectedTypesId?.length === 1 &&
        filters?.selectedTypesId?.includes('static_1_active1')
      ) {
        filter.status = [
          'TRAINING_CREATED',
          'TRAINING_STARTED',
          'TRAINING_REPEAT',
          'TRAINING_COMPLETED',
          'TRAINING_ONE_TIME',
        ];
      }

      if (
        filters?.selectedTypesId?.length === 1 &&
        filters?.selectedTypesId?.includes('static_2_inactive2')
      ) {
        filter.status = ['TRAINING_TERMINATED'];
      }
    }

    return {
      page: selectedPageIndex,
      perPage: 10,
      sort: sort,
      filter: filter,
      userProgressFilter2: {
        userId: loggedInUserId,
      },
      userIds: [loggedInUserId],
    };
  };

  // API to get tracking pagination data
  const [
    getTrainingPathSessionData,
    { loading: trackingTpPaginationLoader, error: tpUserSessionError },
  ] = useLazyQuery(USER_TRAINING_PATH_SESSIONS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const formattedData = prepareTrainingPathSessionData(
        data?.UserTpSessions?.items,
        trainingCategories
      );
      setPageInfo(data?.UserTpSessions?.pageInfo);
      setTrainingPathData(formattedData);
    },
  });

  // API to get training categories
  const [
    getTrainingCategories,
    { loading: trainingCategoriesLoader, error: tpCategoriesError },
  ] = useLazyQuery(GET_TRAINING_CATEGORIES, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setTrainingCategories(data.EntityTpCategories);
      // Trigger the pagination query after categories are fetched
      getTrainingPathSessionData({
        variables: trainingPathFilters(selectedPageIndex, sortType?.sort),
      });
    },
  });

  useEffect(() => {
    // Fetch data when page changed
    if (selectedPageIndex !== 1) {
      getTrainingPathSessionData({
        variables: trainingPathFilters(selectedPageIndex, sortType?.sort),
      });
      return;
    }

    // Fetch initial data when searchQuery is empty
    if (
      !searchQuery &&
      sortType?.order === 'none' &&
      !filters?.totalFiltersTypes
    ) {
      getTrainingCategories();
      return;
    }

    // Debounce fetch when searchQuery is updated
    const timer = setTimeout(() => {
      getTrainingPathSessionData({
        variables: trainingPathFilters(selectedPageIndex, sortType?.sort),
      });
    }, 500);

    return () => clearTimeout(timer);
  }, [searchQuery, selectedPageIndex, sortType, filters]);

  const contextValue: MyTrainingContextType = {
    searchQuery,
    setSearchQuery,
    selectedPageIndex,
    setSelectedPageIndex,
    getTrainingPathSessionData,
    trainingPathFilters,
    sortType,
    setSortType,
    filters,
    setFilters,
    trainingCategories,
  };

  return (
    <MyTrainingProvider value={contextValue}>
      <Flex gap={4} flexDir={'column'}>
        <Flex>
          <TopSection />
        </Flex>
        <Flex p={'24px'} borderRadius={'8px'} border={'2px solid #EFEFEF'}>
          {tpUserSessionError || tpCategoriesError ? (
            <Flex
              py={'1rem'}
              flexDir={'column'}
              alignItems={'center'}
              justifyContent={'center'}
              height={'100%'}
              width={'100%'}
            >
              <DateCenterEmptyStateIcon />
              <Center flexDir='column' height={'full'}>
                <Text as='b' fontWeight={700} fontSize='15px'>
                  Error while loading data, please refresh the page!
                </Text>
              </Center>
            </Flex>
          ) : (
            <BottomSection
              trainingCategory={trainingCategories}
              trainingPathData={trainingPathData}
              setSearchQuery={setSearchQuery}
              tableLoader={trackingTpPaginationLoader}
              trainingCategoriesLoader={trainingCategoriesLoader}
              getTrainingPathSessionData={getTrainingPathSessionData}
              trainingPathFilters={trainingPathFilters}
              pageInfo={pageInfo}
            />
          )}
        </Flex>
      </Flex>
    </MyTrainingProvider>
  );
};

MyTrainingsContainer.displayName =
  'displayName:sub-components/training-v2/dashboard/components/MyTrainingsContainer';

export default MyTrainingsContainer;
