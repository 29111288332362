import { useMemo } from 'react';
import { CategoryEntity } from 'sop-commons/src/client';
import { useUserEntity } from '../../../../../../hooks';
import { toArray } from '../../../../../../utils';

// Calculated data cached to reduce data mapping
const cache: Record<string, CategoryEntity[]> = {};

export const useSopFolderMap = (categoryId: string): CategoryEntity[] => {
  const categories = useUserEntity((entity) => entity?.category || []);

  return useMemo(() => {
    const values = [] as typeof categories;

    if (cache[categoryId]) {
      return cache[categoryId];
    }

    function findCategory(_categories: CategoryEntity[]) {
      for (const category of _categories) {
        values.push(category);

        // Check if this category is the one we are looking for
        if (category.eid === categoryId) {
          return true;
        }

        // Recursively search through children if they exist
        if (toArray(category.children).length > 0) {
          if (findCategory(category.children)) {
            return true;
          }
        }

        // If we didn't find it, pop the current category from the values
        values.pop();
      }
    }

    findCategory(categories);

    cache[categoryId] = values;

    return values;
  }, [categories, categoryId]);
};
