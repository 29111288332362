import React, { ChangeEvent, FC, useMemo, useState } from 'react';
import {
  Box,
  Checkbox,
  CheckboxGroup,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Input, SelectOption } from 'atoms';
import { searchRegExp } from 'utils';

interface IProps {
  options?: SelectOption[];
  value: string[];
  onChange: (value: string[]) => void;
  disabled?: boolean;
}

const LocationList: FC<IProps> = ({ onChange, value, options = [] }) => {
  const [searchQuery, setSearchQuery] = useState('');

  const filteredOptions = useMemo(() => {
    if (searchQuery) {
      const reg = searchRegExp(searchQuery, 'gi');
      return options.filter((option) => {
        return option.label?.match(reg);
      });
    }

    return options;
  }, [options, searchQuery]);

  const onAllSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const keys: string[] = filteredOptions?.map((val) => val.value) || [];
    if (event.target.checked) {
      onChange?.([...new Set([...value, ...keys])]);
    } else {
      onChange?.([...value.filter((v) => !keys.includes(v))]);
    }
  };

  const isSelectAll = useMemo(() => {
    return (
      !filteredOptions.some((it) => !value.includes(it.value)) &&
      options?.length > 0
    );
  }, [filteredOptions, value]);

  return (
    <>
      <Input
        minH='42px'
        size='lg'
        variant='filled'
        placeholder='Type location name'
        onChange={(event) => setSearchQuery(event.target.value)}
      />
      <Flex
        flex={1}
        flexDir='column'
        overflow='auto'
        gap={3}
        sx={{
          '.chakra-checkbox__control': {
            borderRadius: '4px',
          },
          '.chakra-checkbox__label': {
            fontSize: '14px',
            fontWeight: 600,
          },
        }}
        css={{
          '&::-webkit-scrollbar': {
            height: '8px',
          },
          '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#c1c1c1',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#a8a8a8',
          },
        }}
      >
        {!filteredOptions.length ? (
          <Box
            fontSize='12px'
            fontWeight='500'
            color='#9A9FA5'
            fontStyle='italic'
            paddingX={2}
          >
            No location with that name
          </Box>
        ) : null}

        {filteredOptions.length > 1 ? (
          <Checkbox size='lg' isChecked={isSelectAll} onChange={onAllSelect}>
            Select all
          </Checkbox>
        ) : null}
        <CheckboxGroup value={value} onChange={onChange}>
          {filteredOptions.map((option) => (
            <Checkbox size='lg' key={option.value} value={option.value}>
              {option.label}
            </Checkbox>
          ))}
        </CheckboxGroup>
      </Flex>
    </>
  );
};

const LocationSelect: FC<IProps> = ({
  options = [],
  value,
  onChange,
  disabled,
}) => {
  const _copy = useMemo(() => {
    if (value?.length) {
      if (value.length > 1) {
        return `${value.length} Locations`;
      }
      return `${value.length} Location`;
    }

    return 'Choose Location';
  }, [value]);

  return (
    <Flex justify='end' align='center' height={12}>
      <Popover placement='bottom-end' matchWidth>
        {/*// @ts-ignore */}
        <PopoverTrigger>
          <Flex
            align='center'
            justify={'space-between'}
            fontWeight={600}
            py={'12px'}
            px={'20px'}
            fontSize={'14px'}
            cursor='pointer'
            minWidth={'200px'}
            aria-disabled={disabled}
            _disabled={{
              opacity: 0.4,
              cursor: 'not-allowed',
            }}
            onClick={(event) => {
              if (disabled) {
                event.preventDefault();
              }
            }}
            border='2px solid #EFEFEF'
            borderRadius='12px'
          >
            <Box as='span'>{_copy}</Box>
            <ChevronDownIcon boxSize={5} />
          </Flex>
        </PopoverTrigger>
        <PopoverContent width='220px' borderRadius='12px'>
          <PopoverArrow />
          <PopoverBody
            display='flex'
            flexDir='column'
            maxHeight='380px'
            overflow='hidden'
            gap='12px'
            padding='16px 14px'
          >
            <LocationList value={value} onChange={onChange} options={options} />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Flex>
  );
};

export default LocationSelect;
