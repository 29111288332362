import {
  Box,
  Flex,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { Authorize, AuthRole } from 'authorization';
import { FC, useEffect, useMemo, useState } from 'react';
import { CustomTab } from 'sub-components/nexus/NexusBase/components/LeftSection/components/LocationLeaderboard/components';
import {
  IAnalyticsContainerProps,
  TMemberAnalyticsItem,
} from 'sub-components/training-v2/shared/types';
import { MemberAnalytics } from '..';
import { CrossTraining } from '../../crossTrain';
import { CrossTrainData } from '../../crossTrain/CrossTraining';
import { TabConfig } from '../../topSection/Leaderboard';
import PathAnalytics from '../PathAnalytics';
import AnalyticsContainerHeader from './AnalyticsContainerHeader';
import DetailsFilterChips from './DetailsFilterChips';

export type IDetailsFilterTypes = {
  eid: string;
  name: string;
};

const statusesFilter: IDetailsFilterTypes[] = [
  { eid: 'static_1_pending1', name: 'Pending' },
  { eid: 'static_2_completed2', name: 'Completed' },
];

const scoreFilters: IDetailsFilterTypes[] = [
  { eid: 'static_1_above60_1', name: 'Above 60%' },
  { eid: 'static_2_above60_2', name: 'Below 60%' },
];

export type DetailsFiltersType = {
  totalFiltersTypes: number;
  memberStatuses: IDetailsFilterTypes[];
  selectedStatuses: IDetailsFilterTypes['name'][];
  selectedStatusesId: IDetailsFilterTypes['eid'][];
  scores: IDetailsFilterTypes[];
  selectedScores: IDetailsFilterTypes['name'][];
  selectedScoresId: IDetailsFilterTypes['eid'][];
  mergedFiltersArray: any[];
};

const AnalyticsContainer: FC<IAnalyticsContainerProps> = ({
  memberAnalytics,
  trainingId,
  crossTrainModalData,
  crossTrainData,
  pathAnalytics,
  tpName,
  tp,
  sessionId,
  refetchTpSession,
  crossTrainAcceess,
  editPathAcccess,
}) => {
  const tabsConfig = useMemo((): TabConfig[] => {
    return [
      {
        label: 'Path analytics',
        minWidth: '80px',
        hasNewBadge: false,
        value: 'pathAnalytics',
      },
      { label: 'Member analytics', minWidth: '80px', value: 'memberAnalytics' },
    ];
  }, []);

  //  original unfiltered member analytics data
  const [originalTrackMembersData, setOriginalTrackMembersData] = useState<
    TMemberAnalyticsItem[]
  >([]);
  const [trackMembersData, setTrackMembersData] = useState<
    TMemberAnalyticsItem[]
  >([]);

  //  original unfiltered cross training data
  const [originalCrossTrainMembersData, setOriginalCrossTrainMembersData] =
    useState<CrossTrainData[] | TMemberAnalyticsItem[]>([]);
  const [crossTrainMembersData, setCrossTrainMembersData] = useState<
    CrossTrainData[] | TMemberAnalyticsItem[]
  >([]);

  const [tabIndex, setTabIndex] = useState<number>(0);
  const [detailsFilters, setDetailsFilters] = useState<DetailsFiltersType>({
    totalFiltersTypes: 0,
    memberStatuses: statusesFilter,
    selectedStatuses: [],
    selectedStatusesId: [],
    scores: scoreFilters,
    selectedScores: [],
    selectedScoresId: [],
    mergedFiltersArray: [],
  });

  useEffect(() => {
    //  original and the filtered data to the full dataset
    setOriginalTrackMembersData(memberAnalytics);
    setTrackMembersData(memberAnalytics);

    setOriginalCrossTrainMembersData(crossTrainData);
    setCrossTrainMembersData(crossTrainData);
  }, [memberAnalytics]);

  return (
    <Flex
      flexDir='column'
      border='2px solid rgba(239, 239, 239, 1)'
      borderRadius='12px'
      p='24px'
    >
      <Tabs
        variant='unstyled'
        w='full'
        onChange={(index) => setTabIndex(index)}
      >
        <TabList gap='.5rem' flexWrap='wrap'>
          <Flex
            width={'100%'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Flex gap={2}>
              {tabsConfig.map((tab, index) => (
                <CustomTab
                  key={index}
                  label={tab.label}
                  minWidth={tab.minWidth}
                  hasNewBadge={tab.hasNewBadge}
                />
              ))}
              <Authorize
                permittedFor='user'
                permittedRoles={[AuthRole.LOCATION_OWNER]}
              >
                <CustomTab
                  label='Cross Training'
                  minWidth='80px'
                  hasNewBadge={false}
                />
              </Authorize>
            </Flex>
            {tabIndex !== 0 && (
              <Flex>
                <AnalyticsContainerHeader
                  trackMembersData={trackMembersData}
                  setTrackMembersData={setTrackMembersData}
                  crossTrainMembersData={
                    crossTrainMembersData as TMemberAnalyticsItem[]
                  }
                  setCrossTrainMembersData={setCrossTrainMembersData}
                  originalTrackMembersData={originalTrackMembersData}
                  originalCrossTrainMembersData={originalCrossTrainMembersData}
                  detailsFilters={detailsFilters}
                  setDetailsFilters={setDetailsFilters}
                  tabIndex={tabIndex}
                />
              </Flex>
            )}
          </Flex>
          <Box mt={2}>
            {/* FilterChip */}
            {detailsFilters?.totalFiltersTypes > 0 && (
              <DetailsFilterChips
                filters={detailsFilters}
                setFilters={setDetailsFilters}
              />
            )}
          </Box>
        </TabList>

        <TabPanels>
          {tabsConfig.map((tab, index) => (
            <TabPanel key={index} p={0} ml={'-15px'} mt={4}>
              {tab.value === 'pathAnalytics' && (
                <PathAnalytics
                  pathAnalytics={pathAnalytics}
                  trainingId={trainingId}
                  sessionId={sessionId}
                  tp={tp}
                />
              )}
              {tab.value === 'memberAnalytics' && (
                <MemberAnalytics
                  memberAnalytics={trackMembersData}
                  tpName={tpName}
                  trainingId={trainingId}
                  tp={tp}
                  refetchTpSession={refetchTpSession}
                />
              )}
            </TabPanel>
          ))}
          <TabPanel>
            <CrossTraining
              crossTrainData={crossTrainMembersData as CrossTrainData[]}
              crossTrainModalData={crossTrainModalData}
              trainingId={trainingId}
              tp={tp}
              refetchTpSession={refetchTpSession}
              sessionId={sessionId}
              editPathAccess={editPathAcccess}
              crossTrainAcceess={crossTrainAcceess}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
};

export default AnalyticsContainer;
