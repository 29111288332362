import React, { FC } from 'react';
import { Box, Flex, Tooltip } from '@chakra-ui/react';
import moment from 'moment';
import { faCalendar } from '@fortawesome/pro-light-svg-icons';
import { MdOutlineVisibilityOff } from 'react-icons/md';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconImage } from '../../../../../../ui-components';

import { ConversationLogItem } from './conversation-log.graphql';

interface SubjectProps {
  value: string;
  onClick?: () => void;
}

export const SubjectCell: FC<SubjectProps> = ({ value, onClick }) => {
  if (!value) {
    return <>- -</>;
  }

  return (
    <Flex
      width='fit-content'
      fontSize='13px'
      fontWeight='500'
      color='#2A85FF'
      paddingRight='10px'
      noOfLines={2}
      cursor='pointer'
      onClick={onClick}
    >
      {value}
    </Flex>
  );
};

export const CategoryCell: FC<SubjectProps> = ({ value }) => {
  if (!value) {
    return <>- -</>;
  }

  return (
    <Flex fontSize='13px' paddingRight='10px'>
      {value}
    </Flex>
  );
};

interface DateProps {
  value: string;
  dateFormat?: string;
}

export const OccurredDateCell: FC<DateProps> = ({
  value,
  dateFormat = 'DD MMMM',
}) => {
  if (!value) {
    return <>- -</>;
  }

  return (
    <Flex
      width='max-content'
      align='center'
      fontSize='13px'
      fontWeight='500'
      color='#CCA250'
      bg='#FFD88D4D'
      padding='6px 10px'
      borderRadius='6px'
      gap='6px'
    >
      <FontAwesomeIcon icon={faCalendar as IconProp} />
      {moment(value).format(dateFormat)}
    </Flex>
  );
};

interface CreateProps {
  value: string;
  createdBy: ConversationLogItem['createdByUser'];
}

export const CreatedDateCell: FC<CreateProps> = ({ value, createdBy }) => {
  if (!value) {
    return <>- -</>;
  }

  return (
    <Flex flexDir='column' gap={1}>
      <Flex gap='6px' align='center'>
        <IconImage
          name={createdBy.name}
          thumbnail={createdBy.profilePic}
          boxSize={18}
          borderRadius='50%'
        />
        <Box noOfLines={1} fontSize='12px' fontWeight='500' color='black'>
          {createdBy.name}
        </Box>
      </Flex>
      <Box color='#6F767E' fontSize='12px'>
        {moment(value).format('on DD MMMM')}
      </Box>
    </Flex>
  );
};

interface VisibleToLocationOwnerProps {
  value: boolean;
}

export const VisibleToLocationOwner: FC<VisibleToLocationOwnerProps> = ({
  value,
}) => {
  if (!value) {
    return (
      <Tooltip label={'Not visible to Location Owners'}>
        <Flex
          width='fit-content'
          fontSize='18px'
          fontWeight='500'
          color='#6F767E'
          paddingRight='10px'
          noOfLines={2}
          cursor='pointer'
        >
          <MdOutlineVisibilityOff />
        </Flex>
      </Tooltip>
    );
  }

  return <></>;
};
