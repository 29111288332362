import React, { FC, useEffect, useState } from 'react';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { Box, Center, Flex, IconButton, Tooltip } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { gql } from '@apollo/client';
import ListPagination from 'atoms/ListPagination';
import Loader from 'sub-components/Loader';
import { AuthRole, userObj } from 'sop-commons/src/client';
import { AmplitudeEventNames } from 'shared/amplitudeEvents/amplitude-events-types';
import { deployEvent } from 'shared/amplitudeEvents/AmplitudeEvents';
import { FormTable } from 'pages/forms/FormResponse/FormResponseTable/FormResponseTableComponent';
import EmptyFormResponse from 'pages/forms/FormResponse/FormResponseTable/EmptyFormResponse';
import FormReportExportFooter from 'pages/forms/FormResponse/FormResponseTable/FormResponseExportFooter';
import { FormResponseTableWrapper } from 'pages/forms/FormResponse/FormResponseTable/FormResponseTable.styles';
import { HiddenResponse } from 'configs';
import { QuestionEntity, ResponseEntity } from 'types';
import { ADD_LOG_FORM_RESPONSE_EXPORT } from 'pages/forms/FormResponse/FormResponseTable/form-response-graphql';
import { GenerateFormResponseSheet } from 'pages/forms/FormResponse/FormResponseTable/GenerateFormResponseSheetHandler/GenerateFormResponseSheet';
import {
  getTableData,
  getTableHeader,
} from 'pages/forms/FormResponse/FormResponseTable/form-response-helper';
import { IDateFilterRangeEntity } from 'ui-components/DateFilter/types';
import { TPPathAnalyticsItem } from 'sub-components/training-v2/shared/types';
import { Image } from 'ui-components';
import { getImage } from 'utils';
import moment from 'moment';
import { Button, SearchInput } from 'atoms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { useUserDataSelector } from 'hooks';
import { usersEntityObj } from 'sub-components/Header';

export const TP_FORM_RESPONSES = gql`
  query TpFormResponses($trainingSessionId: String!, $formId: String!) {
    TpFormResponses(trainingSessionId: $trainingSessionId, formId: $formId) {
      userId
      locations
      status
      formId
      sessionId
      trainingSessionId
      response
      initialResponseAt
      completedAt
      createdAt
      updatedAt
      eid
      createdBy {
        eid
        name
        authRole
        email
        role
        profilePic
        locations {
          name
          eid
        }
      }
      form {
        questions
        userList
      }
    }
  }
`;

export interface Respondent {
  eid: string;
  name: string;
  selected: boolean;
}

export interface ILocationFilter {
  name: string;
  eid: string;
  selected: boolean;
}

interface ITrainingFormResponseItem {
  userId: string;
  locations: string[];
  status: 'draft' | 'complete';
  formId: string;
  sessionId?: string;
  trainingSessionId: string;
  response: any[];
  initialResponseAt: string;
  completedAt: string;
  createdAt: string;
  updatedAt: string;
  eid: string;
  createdBy: {
    eid: string;
    name: string;
    authRole?: string;
    email?: string;
    role?: string;
    profilePic?: string;
    locations?: { name: string; eid?: string }[];
  };
  form: {
    questions: QuestionEntity[];
    userList: any[];
  };
}

export interface FormExportEntity {
  formId: string;
  response: ResponseEntity[];
  completedAt: string;
  createdBy: {
    name: string;
    eid: string;
    locations: Array<{
      eid: string;
      name: string;
    }>;
    profilePic: string;
  };
}

interface IFormResponseData {
  count: number;
  pageInfo: {
    currentPage: number;
    perPage: number;
    pageCount: number;
    itemCount: number;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
  };
  items: ITrainingFormResponseItem[];
}

interface TrainingFormResponseTableProps {
  formId: string;
  trainingSessionId: string;
  dateRangeFilter?: IDateFilterRangeEntity;
  showLocation?: boolean;
  showFilters?: boolean;
  selectedRecord: TPPathAnalyticsItem | undefined;
  setIsAnyTableFilterSelected?: React.Dispatch<React.SetStateAction<boolean>>;
}

const TrainingFormResponseTable: FC<TrainingFormResponseTableProps> = ({
  formId,
  trainingSessionId,
  dateRangeFilter,
  showLocation = true,
  showFilters = true,
  selectedRecord,
  setIsAnyTableFilterSelected,
}) => {
  const { t } = useTranslation(['common', 'form']);
  const history = useHistory();
  const [questions, setQuestions] = useState<QuestionEntity[]>([]);
  const [page, setPage] = useState(1);
  const [responseData, setResponseData] = useState<IFormResponseData>({
    count: 0,
    pageInfo: {
      currentPage: 1,
      perPage: 10,
      pageCount: 0,
      itemCount: 0,
      hasNextPage: false,
      hasPreviousPage: false,
    },
    items: [],
  });
  const [allResponses, setAllResponses] = useState<ITrainingFormResponseItem[]>(
    []
  );
  const [filteredResponses, setFilteredResponses] = useState<
    ITrainingFormResponseItem[]
  >([]);
  const userData = useReactiveVar(userObj);
  const allUsers = useReactiveVar(usersEntityObj);

  const [usersData, setUsersData] = useState<Respondent[]>([]);
  const [locationsData, setLocationsData] = useState<ILocationFilter[]>([]);
  const [searchString, setSearchString] = useState('');
  const [hasInitialData, setHasInitialData] = useState(false);

  const { loggedInUserRole, loggedInUserLocations } = useUserDataSelector(
    (state) => ({
      loggedInUserRole: state?.authRole,
      loggedInUserLocations: state?.locations,
    })
  );

  const [formResponseExportEvent] = useMutation(ADD_LOG_FORM_RESPONSE_EXPORT, {
    onCompleted: () => {
      deployEvent(AmplitudeEventNames.FORM_RESPONSE_EXPORT);
    },
  });

  const [getTrainingFormResponses, { loading: loading }] = useLazyQuery<{
    TpFormResponses: ITrainingFormResponseItem[];
  }>(TP_FORM_RESPONSES, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const responses = JSON.parse(JSON.stringify(data?.TpFormResponses || []));

      // Set flag if we have received data
      setHasInitialData(responses.length > 0);

      // Process the responses
      responses.forEach((item: ITrainingFormResponseItem) => {
        if (item?.form?.questions) {
          item.form.questions.forEach((question) => {
            if (question?.options) {
              question.options.forEach((option) => {
                option.selected = false;
              });
            }
          });
        }
        if (item?.response) {
          item.response.forEach((res) => {
            res.isExpanded = false;
          });
        }
      });

      setAllResponses(responses);
      setFilteredResponses(responses);

      // Extract questions from the first response if available
      if (responses.length > 0 && responses[0]?.form?.questions?.length > 0) {
        const incomingQuestions = responses[0].form.questions.filter(
          (question) => !HiddenResponse.includes(question?.qType)
        );
        setQuestions(incomingQuestions);
      }

      // Extract unique users and locations for filters
      extractFiltersFromResponses(responses);

      updatePaginatedData(responses, 1);
    },
  });

  // Extract users and locations from responses for filtering
  const extractFiltersFromResponses = (
    responses: ITrainingFormResponseItem[]
  ) => {
    // Extract unique users
    const uniqueUsers = new Map<string, Respondent>();

    // Extract unique locations
    const uniqueLocations = new Map<string, ILocationFilter>();

    responses.forEach((item) => {
      // Add user
      if (item.createdBy?.eid && item.createdBy?.name) {
        if (!uniqueUsers.has(item.createdBy.eid)) {
          uniqueUsers.set(item.createdBy.eid, {
            eid: item.createdBy.eid,
            name: item.createdBy.name,
            selected: false,
          });
        }
      }

      // Add locations
      if (item.locations && Array.isArray(item.locations)) {
        item.locations.forEach((locationId) => {
          // Try to find location name from createdBy.locations
          const locationObj = item.createdBy?.locations?.find(
            (loc) => loc.eid === locationId
          );
          const locationName = locationObj?.name || locationId;

          if (!uniqueLocations.has(locationId)) {
            uniqueLocations.set(locationId, {
              eid: locationId,
              name: locationName,
              selected: false,
            });
          }
        });
      }

      // Also check locations from createdBy
      if (
        item.createdBy?.locations &&
        Array.isArray(item.createdBy.locations)
      ) {
        item.createdBy.locations.forEach((loc) => {
          if (loc.eid && !uniqueLocations.has(loc.eid)) {
            uniqueLocations.set(loc.eid, {
              eid: loc.eid,
              name: loc.name,
              selected: false,
            });
          }
        });
      }
    });

    setUsersData(Array.from(uniqueUsers.values()));
    setLocationsData(Array.from(uniqueLocations.values()));
  };

  // Fetch data on component mount or when dependencies change
  useEffect(() => {
    if (!formId || !trainingSessionId) return;

    getTrainingFormResponses({
      variables: {
        formId: formId,
        trainingSessionId: trainingSessionId,
      },
    });
  }, [formId, trainingSessionId]);

  // Apply filters and update pagination
  useEffect(() => {
    applyFilters();
  }, [
    allResponses,
    usersData,
    locationsData,
    searchString,
    dateRangeFilter,
    questions,
  ]);

  // Handle pagination changes
  useEffect(() => {
    updatePaginatedData(filteredResponses, page);
  }, [page, filteredResponses]);

  // Apply all filters (user, location, date, search, question options)
  const applyFilters = () => {
    if (!allResponses.length) return;

    let results = [...allResponses];

    // Apply user filter
    const selectedUserIds = usersData
      .filter((user) => user.selected)
      .map((user) => user.eid);

    if (selectedUserIds.length > 0) {
      results = results.filter((item) =>
        selectedUserIds.includes(item.createdBy?.eid)
      );
    }

    // Apply location filter
    const selectedLocationIds = locationsData
      .filter((location) => location.selected)
      .map((location) => location.eid);

    if (selectedLocationIds.length > 0) {
      results = results.filter((item) => {
        // Check user's assigned locations
        const userLocations =
          item.createdBy?.locations?.map((loc) => loc.eid) || [];
        // Check response locations
        const responseLocations = item.locations || [];
        // Find any match between selected locations and user/response locations
        return selectedLocationIds.some(
          (locId) =>
            userLocations.includes(locId) || responseLocations.includes(locId)
        );
      });
    }

    // Apply question option filters
    const selectedOptionIds = questions
      .flatMap((q) => q.options || [])
      .filter((option) => option.selected)
      .map((option) => option.eid);

    if (selectedOptionIds.length > 0) {
      results = results.filter((item) => {
        if (!item.response || !Array.isArray(item.response)) return false;

        // Check if any response contains selected options
        return item.response.some((resp) => {
          if (!resp.responseId || !Array.isArray(resp.responseId)) return false;
          return resp.responseId.some((id) => selectedOptionIds.includes(id));
        });
      });
    }

    // Apply search filter - enhanced to search by name, role, and location
    if (searchString) {
      const searchLower = searchString.toLowerCase();
      results = results.filter((item) => {
        // Search in user name
        if (item.createdBy?.name?.toLowerCase().includes(searchLower))
          return true;

        // Search in user role
        if (item.createdBy?.role?.toLowerCase().includes(searchLower))
          return true;

        // Search in user locations
        if (
          item.createdBy?.locations &&
          Array.isArray(item.createdBy.locations)
        ) {
          if (
            item.createdBy.locations.some((loc) =>
              loc.name?.toLowerCase().includes(searchLower)
            )
          ) {
            return true;
          }
        }

        // Search in response locations (using location data map)
        if (item.locations && Array.isArray(item.locations)) {
          const locationMatches = locationsData.some(
            (loc) =>
              item.locations.includes(loc.eid) &&
              loc.name.toLowerCase().includes(searchLower)
          );
          if (locationMatches) return true;
        }

        return false;
      });
    }

    // Apply date filter
    if (dateRangeFilter) {
      if (dateRangeFilter.from || dateRangeFilter.to) {
        results = results.filter((item) => {
          const responseDate = new Date(
            item.completedAt || item.updatedAt || item.createdAt
          );

          if (dateRangeFilter.from && dateRangeFilter.to) {
            return (
              responseDate >= dateRangeFilter.from &&
              responseDate <= dateRangeFilter.to
            );
          } else if (dateRangeFilter.from) {
            return responseDate >= dateRangeFilter.from;
          } else if (dateRangeFilter.to) {
            return responseDate <= dateRangeFilter.to;
          }

          return true;
        });
      }
    }

    // Update if filters are selected
    if (setIsAnyTableFilterSelected) {
      const isAnyFilterSelected =
        selectedUserIds.length > 0 ||
        selectedLocationIds.length > 0 ||
        selectedOptionIds.length > 0 ||
        !!searchString ||
        !!(dateRangeFilter && (dateRangeFilter.from || dateRangeFilter.to));

      setIsAnyTableFilterSelected(isAnyFilterSelected);
    }

    setFilteredResponses(results);
    updatePaginatedData(results, 1); // Reset to first page when filters change
  };

  // Update pagination data
  const updatePaginatedData = (
    data: ITrainingFormResponseItem[],
    currentPage: number
  ) => {
    // filter for location owner
    let responses;
    if (loggedInUserRole === AuthRole.LOCATION_OWNER) {
      responses = data.filter((item) =>
        item.locations.some((location) =>
          loggedInUserLocations?.some(
            (userLocation) => userLocation.eid === location
          )
        )
      );
    } else {
      responses = data;
    }

    const totalCount = responses.length;

    const totalPages = Math.ceil(totalCount / 10);
    const startIndex = (currentPage - 1) * 10;
    const endIndex = startIndex + 10;
    const paginatedItems = responses.slice(startIndex, endIndex);

    const _formResponse: IFormResponseData = {
      count: totalCount,
      pageInfo: {
        currentPage: currentPage,
        perPage: 10,
        pageCount: totalPages,
        itemCount: totalCount,
        hasNextPage: currentPage < totalPages,
        hasPreviousPage: currentPage > 1,
      },
      items: paginatedItems,
    };

    setResponseData(_formResponse);
    if (currentPage !== page) {
      setPage(currentPage);
    }
  };

  // User filter click handler
  const userFilterClickHandler = (user: Respondent) => {
    const updatedUsers = usersData.map((u) =>
      u.eid === user.eid ? { ...u, selected: !u.selected } : u
    );
    setUsersData(updatedUsers);
  };

  // Location filter click handler
  const locationFilterClickHandler = (location: ILocationFilter) => {
    const updatedLocations = locationsData.map((loc) =>
      loc.eid === location.eid ? { ...loc, selected: !loc.selected } : loc
    );
    setLocationsData(updatedLocations);
  };

  // Question filter change handler
  const onQuestionFilterChange = (ids?: string[]) => {
    if (!ids || !questions.length) return;

    // Update questions to mark selected options
    const updatedQuestions = questions.map((question) => {
      if (!question.options) return question;

      const updatedOptions = question.options.map((option) => ({
        ...option,
        selected: ids.includes(option.eid),
      }));

      return {
        ...question,
        options: updatedOptions,
      };
    });

    setQuestions(updatedQuestions);
  };

  // Filter reset handler
  const onFilterCloseHandler = () => {};

  // Get count of selected filters
  const getSelectedCountHandler = (type: 'user' | 'location') => {
    const selection = type === 'user' ? usersData : locationsData;
    return selection.filter((item) => item.selected).length;
  };

  // Export handler
  const generateUserWiseReportHandler = () => {
    formResponseExportEvent({
      variables: {
        input: {
          entityId: userData?.entityId,
          eventName: 'FormResponseExport',
          eventType: 'Query',
          payload: { formId: formId, trainingSessionId: trainingSessionId },
          source: 'Client',
          userId: userData?.eid,
        },
      },
    });

    // Generate export from filtered data
    const exportData: FormExportEntity[] = filteredResponses.map((item) => ({
      formId: item.formId,
      response: item.response as unknown as ResponseEntity[],
      completedAt: item.completedAt,
      createdBy: {
        name: item.createdBy?.name || '',
        eid: item.createdBy?.eid || '',
        locations:
          item.createdBy?.locations?.map((loc) => ({
            name: loc.name,
            eid: loc.eid || '',
          })) || [],
        profilePic: item.createdBy?.profilePic || '',
      },
    }));

    GenerateFormResponseSheet(
      questions,
      exportData,
      null // No formResponseByIdData for training responses
    );
  };

  // Handle search
  const handleSearch = (query: string) => {
    setSearchString(query);
  };

  // Render functions
  const renderLoader = () => {
    return (
      <Center w='100%' h='50vh'>
        <Loader size='lg' />
      </Center>
    );
  };

  const renderTable = () => {
    return (
      <Flex flexDir='column' gap={4}>
        <Flex justify='space-between' align='center'>
          <Flex align='center' gap={2}>
            <Image
              src={getImage(selectedRecord?.thumbnail, selectedRecord?.title)}
              width={48}
              height={48}
              style={{ borderRadius: '6px' }}
            />
            <Flex flexDir='column'>
              <Box>{selectedRecord?.title}</Box>
              <Box
                color='rgba(111, 118, 126, 1)'
                fontSize='12px'
                fontWeight={400}
              >
                Added on{' '}
                {moment(responseData?.items?.[0]?.createdAt)?.format('DD YYYY')}
              </Box>
            </Flex>
          </Flex>
          <Flex gap={4}>
            <Box w='300px'>
              <SearchInput
                placeholder='Search by user, job or location'
                hideShortcuts
                onChange={(e) => handleSearch(e.target.value)}
                value={searchString}
              />
            </Box>
            <Box>
              <Tooltip label='Export responses' hasArrow>
                <IconButton
                  aria-label='Export responses'
                  variant='outline'
                  icon={<FontAwesomeIcon icon={faFileExport as IconProp} />}
                  onClick={generateUserWiseReportHandler}
                />
              </Tooltip>
            </Box>
            <Box>
              <Button
                variant='outline'
                leftIcon={
                  <FontAwesomeIcon
                    icon={faArrowUpRightFromSquare as IconProp}
                    color='rgba(111, 118, 126, 1)'
                  />
                }
                onClick={() => history.push(`/forms/response/${formId}`)}
              >
                View all responses
              </Button>
            </Box>
          </Flex>
        </Flex>
        <FormTable
          data={getTableData(questions, responseData?.items)}
          columns={getTableHeader(
            t,
            showFilters,
            usersData,
            getSelectedCountHandler,
            onFilterCloseHandler,
            userFilterClickHandler,
            formId,
            showLocation,
            locationsData,
            locationFilterClickHandler,
            questions,
            setQuestions,
            onQuestionFilterChange,
            allUsers
          )}
        />
        {((responseData?.pageInfo?.currentPage === 1 &&
          responseData?.pageInfo?.hasNextPage) ||
          responseData?.pageInfo?.currentPage > 1) && (
          <Box marginBottom={'4rem'}>
            <ListPagination
              onPageChange={(page: number) =>
                updatePaginatedData(filteredResponses, page)
              }
              data={responseData?.items}
              totalRegisters={responseData.count}
              page={page}
              registersPerPage={10}
            />
          </Box>
        )}
      </Flex>
    );
  };

  const renderEmptyState = () => {
    return <EmptyFormResponse />;
  };

  // Determines if any filter is currently applied
  const isAnyFilterApplied = () => {
    return (
      searchString.trim() !== '' ||
      usersData.some((user) => user.selected) ||
      locationsData.some((location) => location.selected) ||
      questions.some((question) =>
        question.options?.some((option) => option.selected)
      ) ||
      !!(dateRangeFilter?.from || dateRangeFilter?.to)
    );
  };

  const compRender = () => {
    if (loading) {
      return renderLoader();
    } else if (!hasInitialData && !isAnyFilterApplied()) {
      // Show empty state only when we never received data and no filters are applied
      return renderEmptyState();
    } else {
      // Always show table when we have data or filters are applied
      return renderTable();
    }
  };

  return <FormResponseTableWrapper>{compRender()}</FormResponseTableWrapper>;
};

export default TrainingFormResponseTable;
