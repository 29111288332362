import React, { FC } from 'react';
import { Box, Flex, IconButton } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faGif } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FILENAME_REGEX } from '../../../../../../utils/constants';
import { validateIsImage } from '../../../../../../utils';
import { DropzoneInput } from '../common';

import { ReactComponent as DeleteIcon } from 'assets/images/trash-red.svg';

const FIVE_MB = 5000000;

interface IProps {
  value?: string;
  onChange?: (value: string) => void;
}

const MilestoneThumbnail: FC<IProps> = ({ value, onChange }) => {
  const { setError } = useFormContext();

  const validateLink = async (imageUrl: string) => {
    try {
      await validateIsImage(imageUrl, 5000);
    } catch (e) {
      setError('thumbnail', {
        type: 'validate',
        message: 'Please enter a valid image URL',
      });
      return Promise.reject(e);
    }
  };

  if (value) {
    return (
      <Flex gap={4} align='center' py={2}>
        <FontAwesomeIcon
          icon={faGif as IconProp}
          color='#6F767E'
          fontSize='2.2em'
        />
        <Flex flex={1} flexDir='column' overflow='hidden' gap='2px'>
          <Box fontSize='13px' fontWeight='600' color='#111315' isTruncated>
            {value.split(FILENAME_REGEX).pop()}
          </Box>
          <Box fontSize='12px' color='#6F767E' isTruncated>
            {value}
          </Box>
        </Flex>
        <IconButton
          size='sm'
          aria-label='delete'
          variant='ghost'
          icon={<DeleteIcon width='20' height='20' />}
          onClick={() => onChange?.(null as never)}
        />
      </Flex>
    );
  }

  return (
    <DropzoneInput
      maxSize={FIVE_MB}
      caption='JPG, PNG, GIF · Max size 5Mb'
      accept={{
        'image/*': ['.jpg', '.jpeg', '.png', '.gif'],
      }}
      inputPlaceholder='or paste a link from giphy, pixaby etc...'
      onFileChange={onChange}
      onError={(err) => setError('thumbnail', err)}
      validateLink={validateLink}
    />
  );
};

export default MilestoneThumbnail;
