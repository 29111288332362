import React, { FC } from 'react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { Box, Flex } from '@chakra-ui/react';
import { TRAINING_PATH_V2_DASHBOARD } from 'appRoutes';
import { useHistory } from 'routes';
import TrainingActions from './TrainingActions';

const Heading: FC = () => {
  const history = useHistory();
  return (
    <Flex align='center' justify='space-between'>
      <Flex flexDir='column'>
        <Box as='span' fontWeight={600} fontSize='32px'>
          Create training path
        </Box>
        <Box
          color='rgba(111, 118, 126, 1)'
          cursor='pointer'
          fontSize='15px'
          fontWeight={500}
          w='fit-content'
          onClick={() => history.push({ pathname: TRAINING_PATH_V2_DASHBOARD })}
        >
          <ChevronLeftIcon />
          <Box as='span'>Back to Training</Box>
        </Box>
      </Flex>
      <TrainingActions />
    </Flex>
  );
};

Heading.displayName =
  'displayName:sub-components/training-v2/create/components/Heading';

export default Heading;
