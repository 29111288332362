import { gql } from '@apollo/client';
import { VisibilityStatus } from '../../../../../../types';

export interface SopResourceItem {
  eid: string;
  title: string;
  categoryId: string;
  thumbnail: string;
  icon?: JSON;
  visibility: VisibilityStatus;
}

export interface SopResponse {
  SopPagination: {
    items: SopResourceItem[];
  };
}

export const SOP_RESOURCE_LIST = gql`
  query SOP_RESOURCE_LIST(
    $filter: FilterFindManySopInput
    $sort: SortFindManySopInput
    $perPage: Int
  ) {
    SopPagination(filter: $filter, sort: $sort, perPage: $perPage) {
      items {
        eid
        title
        categoryId
        thumbnail
        icon
        files {
          mimetype
        }
        visibility
      }
    }
  }
`;

export const SOP_PREVIEW_QUERY = gql`
  query SopById($eid: String!, $override: Boolean) {
    SopById(eid: $eid, override: $override) {
      eid
      title
      thumbnail
      category
      icon
      sopType

      lastUpdated

      smartPageEnabled
      isMultiPage

      files {
        mimetype
        url
        name
      }
      media {
        mediaUrl
      }

      content

      cards {
        eid
        title
        cardType
        content
        thumbnail
      }
      cardView {
        eid
        title
        cardType
        content
        thumbnail
      }

      visibility
    }
  }
`;
