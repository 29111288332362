import React, { FC, useMemo, useState } from 'react';
import { Checkbox, Flex, Text, Tooltip } from '@chakra-ui/react';
import { BoxHeader } from 'ui-components';
import SearchInput from 'atoms/SearchInput';
import { ReactComponent as BackButton } from 'assets/images/back.svg';
import { PageNameType } from './MainContainer';
import { ClearFilterType } from './FilterContainer';
import { HeaderColors } from 'configs';
import EmptyState from './EmptyState';
import { ITypes } from '../Header';
import { useTrainingContext } from '../../../TrainingTableContainer';

interface IProps {
  filters: any;
  setPageName: React.Dispatch<React.SetStateAction<PageNameType>>;
  handleTypeSelection?: (
    e: React.ChangeEvent<HTMLInputElement>,
    type: ITypes
  ) => void;
  clearFilters: (type?: ClearFilterType) => void;
}

const TypeFilter: FC<IProps> = ({
  filters,
  setPageName,
  handleTypeSelection,
  clearFilters,
}) => {
  const { types, selectedTypesId } = filters;
  const [typeSearchText, setTypeSearchText] = useState('');

  const { trackingTpPaginationLoader, trainingCategoriesLoader } =
    useTrainingContext();

  // UseMemo for efficient filtering
  const filteredTypes = useMemo(
    () =>
      types?.filter((type: any) =>
        type?.name?.toLowerCase().includes(typeSearchText.toLowerCase())
      ),
    [typeSearchText, types]
  );

  const typesCount = types.length;
  const filteredCount = filteredTypes.length;

  return (
    <>
      {/* Header */}
      <Flex alignItems='center' mt='30px' justifyContent='space-between'>
        <BoxHeader title='Filters' color={HeaderColors.Purple} />
        <Text
          cursor='pointer'
          onClick={() => clearFilters('types')}
          fontSize='14px'
          fontWeight={400}
          color={filters?.selectedTypesId?.length > 0 ? '#2A85FF' : '#6F767E'}
        >
          Clear Selection
        </Text>
      </Flex>

      {/* Back Button and Location Count */}
      <Flex mt='20px' gap={3} alignItems='center' mb='1rem'>
        <BackButton
          width='1.2rem'
          onClick={() => setPageName('base')}
          cursor='pointer'
        />
        <Text fontWeight={600} fontSize='14px'>
          Type
        </Text>
      </Flex>

      {/* Search Input */}
      {/* <SearchInput
        className='location-search-field'
        value={typeSearchText}
        onChange={(e) => setTypeSearchText(e.target.value)}
        placeholder='Search by type name'
        hideShortcuts
      /> */}

      {/* Filtered Locations */}
      {filteredCount > 0 ? (
        <Flex maxHeight='300px' flexDirection='column' overflowY='auto'>
          {filteredTypes.map((type) => (
            <Flex key={type.eid} gap={4} alignItems='center' py='0.5rem'>
              <Checkbox
                disabled={
                  trackingTpPaginationLoader || trainingCategoriesLoader
                }
                value={type.eid}
                onChange={(e) => handleTypeSelection?.(e, type)}
                isChecked={selectedTypesId.includes(type.eid)}
              >
                {/* <Tooltip label={type.name}> */}
                <Text
                  noOfLines={1}
                  fontWeight={400}
                  lineHeight='16px'
                  fontSize='14px'
                  color='#333B4F'
                >
                  {type.name}
                </Text>
                {/* </Tooltip> */}
              </Checkbox>
            </Flex>
          ))}
        </Flex>
      ) : (
        <EmptyState view='types' />
      )}
    </>
  );
};

export default TypeFilter;
