import { Flex } from '@chakra-ui/react';
import React, { FC } from 'react';
import Leaderboard from './Leaderboard';

const RightSection: FC = () => {
  return (
    <Flex width={'100%'}>
      <Leaderboard />
    </Flex>
  );
};

RightSection.displayName =
  'displayName:sub-components/training-v2/dashboard/components/myTrainings/topSection/RightSection';

export default RightSection;
