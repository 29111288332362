import { Center, Flex } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowTrendUp,
  faCircleCheck,
  faUser,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { eventBus } from 'shared/eventEmit';
import { IOverallAnalytics } from 'sub-components/training-v2/shared/types';
import useCombinedStore from 'zustandStore/store';
import { usePendingEvaluation } from '../myTrainings/pendingEvaluation';
import { useTrainingContext } from '../TrainingTableContainer';
import PerformanceCard from './PerformanceCard';

interface IProps {
  overallAnalyticsData: IOverallAnalytics;
  overallAnalyticsLoader: boolean;
}

const PerformanceInfo: FC<IProps> = ({
  overallAnalyticsData,
  overallAnalyticsLoader,
}) => {
  const trackingPathTable = document.querySelector('#trainingPathTable');
  const { updateTableTabIndex } = useCombinedStore();
  const usePendingEvaluationModal = usePendingEvaluation();

  const scrollToTable = (key: string) => {
    if (key === 'overdue') {
      updateTableTabIndex(1);
      setTimeout(() => eventBus.emit('overdue_member_filter_applied'), 0);
    } else if (key === 'totalActivePath') {
      updateTableTabIndex(0);
    }

    if (trackingPathTable) {
      trackingPathTable?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const cardData = [
    {
      title: 'Pending Evaluation',
      count: overallAnalyticsData?.needsEvaluationAssignee?.toString(),
      icon: (
        <Center boxSize='24px' borderRadius='50%' bg='rgba(111, 118, 126, 1)'>
          <FontAwesomeIcon
            icon={faArrowTrendUp as IconProp}
            style={{ color: 'white' }}
          />
        </Center>
      ),
      onClick: () => {
        usePendingEvaluationModal({
          pendingEvaluationCount:
            overallAnalyticsData?.needsEvaluationAssignee || 0,
        });
      },
      key: 'pendingEvaluation',
    },
    {
      title: 'Overdue (members)',
      count: overallAnalyticsData?.totalOverdueAssignee?.toString(),
      icon: (
        <Center boxSize='24px' borderRadius='50%' bg='rgba(111, 118, 126, 1)'>
          <FontAwesomeIcon
            icon={faUser as IconProp}
            style={{ color: 'white' }}
          />
        </Center>
      ),
      key: 'overdue',
    },
    {
      title: 'Total active paths',
      count: overallAnalyticsData?.totalActiveTp?.toString(),
      icon: (
        <Center boxSize='24px' borderRadius='50%' bg='rgba(111, 118, 126, 1)'>
          <FontAwesomeIcon
            icon={faArrowTrendUp as IconProp}
            style={{ color: 'white' }}
          />
        </Center>
      ),
      key: 'totalActivePath',
    },
  ];
  return (
    <Flex width={'100%'} gap={'12px'}>
      {cardData?.map((card, index) => {
        return (
          <PerformanceCard
            key={index}
            icon={card?.icon}
            title={card?.title}
            count={card?.count}
            overallAnalyticsLoader={overallAnalyticsLoader}
            scrollToTable={() => {
              scrollToTable(card?.key);
            }}
            onClick={card?.onClick}
          />
        );
      })}
    </Flex>
  );
};

PerformanceInfo.displayName =
  'packages/sop-web/src/sub-components/training-v2/dashboard/components/locationOwner/PerformanceInfo';
export default PerformanceInfo;
