import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { TrainingContType } from '../../../../../types';
import { toArray } from '../../../../../utils';
import {
  useFormResource,
  useMilestoneResource,
  useQuizResource,
  useSopResource,
  useVideoResource,
} from '../resources';
import { TFormInput } from '../training-input.types';
import { InitialScreen } from './components';
import ContentList from './components/ContentList';
import FooterButton from './FooterButton';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';

const mapId = (data: TFormInput['contents'][number]) => {
  return data.tempEid;
};

interface IProps {
  disabled?: boolean;
}

const AddContentSection: FC<IProps> = ({ disabled }) => {
  const { getValues } = useFormContext<TFormInput>();
  const { fields, append, move, remove, update } = useFieldArray<
    TFormInput,
    'contents'
  >({
    name: 'contents',
    rules: { required: 'Please add content for the training path' },
  });

  const quizResource = useQuizResource();
  const formResource = useFormResource();
  const sopResource = useSopResource();
  const videoResource = useVideoResource();
  const milestoneResource = useMilestoneResource();

  const addContentHandler = (type: TrainingContType) => {
    switch (type) {
      case TrainingContType.QUIZ:
        return quizResource({
          onAddClick: (values) => {
            append(values as never);
            deployEvent(AmplitudeEventNames.TRAINING_PATH_CONTENT_ADD, {
              event: {
                add_new_path_funnel_id: 4,
                content_type: 'quiz',
                content_name: values.map((item) => item.name),
              },
            });
          },
        });
      case TrainingContType.SOP:
        return sopResource({
          values: getValues('contents').map(mapId),
          onAddClick: (values) => {
            append(values as never);
            console.log(
              values.map((item) => item.type),
              values.map((item) => item.name)
            );

            deployEvent(AmplitudeEventNames.TRAINING_PATH_CONTENT_ADD, {
              event: {
                add_new_path_funnel_id: 4,
                content_type: 'sop',
                content_name: values.map((item) => item.name),
              },
            });
          },
        });
      case TrainingContType.FORM:
        return formResource({
          values: getValues('contents').map(mapId),
          onAddClick: (values) => {
            append(values as never);
            deployEvent(AmplitudeEventNames.TRAINING_PATH_CONTENT_ADD, {
              event: {
                add_new_path_funnel_id: 4,
                content_type: 'form',
                content_name: values.map((item) => item.name),
              },
            });
          },
        });
      case TrainingContType.CARD:
        return videoResource({
          onAddClick: (values) => {
            append(values as never);
            deployEvent(AmplitudeEventNames.TRAINING_PATH_CONTENT_ADD, {
              event: {
                add_new_path_funnel_id: 4,
                content_type: 'card',
                content_name: values.map((item) => item.name),
              },
            });
          },
        });
      case TrainingContType.MILESTONE:
        return milestoneResource({
          onAddClick: (values) => {
            append(values as never);
            deployEvent(AmplitudeEventNames.TRAINING_PATH_CONTENT_MILESTONE, {
              event: {
                add_new_path_funnel_id: 4,
                milestone_name: values.title,
              },
            });
          },
        });
      default:
        break;
    }
  };

  const onEditClick = (index: number) => {
    const values = getValues(`contents.${index}`);

    switch (values?.type) {
      case TrainingContType.QUIZ:
        return quizResource({
          initialValues: values,
          onAddClick: (newValues) => {
            const _newValues = toArray(newValues)[0] as never;
            if (_newValues) {
              update(index, _newValues);
            }
          },
        });
      case TrainingContType.MILESTONE:
        return milestoneResource({
          initialValues: values,
          onAddClick: (newValues) => {
            const _newValues = toArray(newValues)[0] as never;
            if (_newValues) {
              update(index, _newValues);
            }
          },
        });
      default:
        break;
    }
  };

  if (!fields?.length) {
    return <InitialScreen disabled={disabled} onAddClick={addContentHandler} />;
  }

  return (
    <Flex flex={1} flexDir='column' gap={6}>
      <ContentList
        fields={fields}
        disabled={disabled}
        onRemoveClick={(index) => remove(index)}
        onEditClick={onEditClick}
        onDragEnd={(currentIndex, nextIndex) => {
          move(currentIndex, nextIndex);
        }}
      />

      <FooterButton onClick={addContentHandler} />
    </Flex>
  );
};

AddContentSection.displayName =
  'displayName:sub-components/training-v2/create/components/AddContentSection/AddContentSection';

export default AddContentSection;
