import { useLazyQuery, useMutation } from '@apollo/client';
import {
  COMPLETE_TP,
  GET_TP_OVERALL_USER_WISE_ANALYTICS,
  TP_SESSION_BY_ID_WEB,
  UPDATE_DOWNLOAD_CERTIFICATE_FLAG,
  UPDATE_TP_PROGRESS,
  VIEW_EVALUATION_UPDATE,
} from 'sub-components/training-v2/shared/api';
import {
  TpOverallUserWiseAnalytics,
  TPSession,
  TPUserProgressEntity,
  UpdateTPProgressVariables,
} from 'sub-components/training-v2/shared/types/play';

export const useService = () => {
  const [getTPBySession, tpBySessionData] = useLazyQuery<
    { TpSessionById: TPSession },
    { eid: string; filter?: { userId: string[] }; assignedUserIds: string[] }
  >(TP_SESSION_BY_ID_WEB, {
    fetchPolicy: 'network-only',
  });

  const [updateTPProgress, tpProgressData] = useMutation<
    { AddTpProgress: TPUserProgressEntity },
    { input: UpdateTPProgressVariables }
  >(UPDATE_TP_PROGRESS);

  const [completeTP, completeTPData] = useMutation<
    { TpCompleted: { succeed: boolean } },
    { sessionId: string }
  >(COMPLETE_TP);

  const [getOverllUserAnalytics, overallUserAnalyticsData] = useLazyQuery<
    { TpOverallUserWiseAnalytics: TpOverallUserWiseAnalytics },
    never
  >(GET_TP_OVERALL_USER_WISE_ANALYTICS, {
    fetchPolicy: 'network-only',
  });

  const [updateDownloadCertificateFlag, downloadCertificateFlagData] =
    useMutation<
      { DownloadTpCertificates: { succeed: boolean } },
      { sessionId: string }
    >(UPDATE_DOWNLOAD_CERTIFICATE_FLAG);

  const [updateViewEvaluation, viewEvaluationData] = useMutation<
    { ViewTpEvaluation: { succeed: boolean } },
    { sessionId: string }
  >(VIEW_EVALUATION_UPDATE);

  return {
    tpSession: {
      getTPBySession,
      tpBySessionData,
    },
    updateTPProgress: {
      updateTPProgress,
      tpProgressData,
    },
    completeTp: {
      completeTP,
      completeTPData,
    },
    overallUserAnalytics: {
      getOverllUserAnalytics,
      overallUserAnalyticsData,
    },
    downloadCertificateFlag: {
      updateDownloadCertificateFlag,
      downloadCertificateFlagData,
    },
    updateViewEvaluation: {
      updateViewEvaluation,
      viewEvaluationData,
    },
  };
};
