import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
} from '@chakra-ui/react';
import { Input, Textarea } from '../../../../../../atoms';
import { getSxScrollStyles } from '../../common';
import VideoThumbnail from './VideoThumbnail';

interface IProps {}

const VideoResourceContainer: FC<IProps> = () => {
  return (
    <Flex
      flex={1}
      gap={6}
      flexDir='column'
      maxHeight='full'
      maxWidth='calc(100% - 260px - 40px)'
    >
      <Flex
        flexDir='column'
        gap={5}
        overflow='hidden auto'
        paddingTop={1}
        paddingBottom={4}
        paddingInline={1}
        sx={getSxScrollStyles()}
      >
        <Controller
          name='title'
          rules={{
            required: 'Vide title is required',
            validate: (value) => {
              if (!value?.trim()) {
                return 'Enter valid video title';
              }
            },
          }}
          render={({ field, fieldState }) => (
            <FormControl isInvalid={fieldState.invalid}>
              <FormLabel fontSize='14px' mb={1}>
                Enter video title
              </FormLabel>
              <FormHelperText fontSize='12px' mt={0} mb={2}>
                This title helps you and your team quickly find the video when
                searching!
              </FormHelperText>
              <Input
                size='lg'
                variant='auditOutline'
                placeholder='Eg: stock shelves with bread, change price on POS, etc'
                {...field}
              />
              <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
            </FormControl>
          )}
        />

        <Controller
          name='caption'
          render={({ field, fieldState }) => (
            <FormControl isInvalid={fieldState.invalid}>
              <FormLabel fontSize='14px' mb={1}>
                Caption (Optional)
              </FormLabel>
              <FormHelperText fontSize='12px' mt={0} mb={2}>
                A short caption makes the video easier to find with AI search.
              </FormHelperText>

              <Textarea
                size='lg'
                variant='auditOutline'
                bg='white'
                fontSize='14px'
                fontWeight='600'
                color='#6F767E'
                sx={{
                  '&::-webkit-scrollbar': {
                    width: '4px',
                    height: '0px',
                    marginRight: '4px',
                  },
                  '&::-webkit-scrollbar-track': {
                    marginTop: '6px',
                    marginBottom: '4px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: '#EFEFEF',
                    borderRadius: '4px',
                    backgroundClip: 'padding-box',
                  },
                  '&::-webkit-scrollbar-thumb:hover': {
                    background: '#555',
                    cursor: 'pointer',
                  },
                }}
                {...field}
              />
              <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
            </FormControl>
          )}
        />

        <Controller
          name='videoUrl'
          rules={{
            required: 'Video is required',
          }}
          render={({ field, fieldState }) => (
            <FormControl isInvalid={fieldState.invalid}>
              <FormLabel fontSize='14px'>Add video</FormLabel>
              <VideoThumbnail value={field.value} onChange={field.onChange} />
              <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
            </FormControl>
          )}
        />
      </Flex>
    </Flex>
  );
};

export default VideoResourceContainer;
