import { ApolloProvider, useApolloClient } from '@apollo/client';
import { Flex, Box, AlertDialogCloseButton } from '@chakra-ui/react';
import React, { useCallback, useRef } from 'react';
import { HeaderColors } from 'shared/headerColors/header-colors';
import { BoxHeader, useConfirm, UseConfirm } from 'ui-components';
import Content from './Content';

interface IProps {
  taskId: string;
  taskTitle: string;
  onRestart: () => Promise<void>;
  onRestartAndEdit: () => Promise<void>;
}

type ActivateTask = (props: IProps) => void;

export const useActivateTask = (): ActivateTask => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();
  const apolloClient = useApolloClient();

  return useCallback(({ taskId, taskTitle, onRestart, onRestartAndEdit }) => {
    if (confirmRef.current) {
      confirmRef.current.destroy();
    }

    const onClose = () => {
      confirmRef?.current?.destroy();
    };

    const onRestartHandler = async () => {
      await onRestart();
    };

    const onRestartAndEditHandler = async () => {
      await onRestartAndEdit();
    };

    const onErrorHandler = () => {
      confirmRef?.current?.destroy();
    };

    confirmRef.current = confirm({
      Wrapper: ({ children }: any) => {
        return (
          <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
        );
      },
      title: (
        <Flex gap='12px'>
          <Box flex={1}>
            <BoxHeader
              title={`Restart "${taskTitle}" ?`}
              color={HeaderColors.Purple}
            />
          </Box>
          <AlertDialogCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
          />
        </Flex>
      ),
      content: (
        <Content
          taskId={taskId}
          onRestartHandler={onRestartHandler}
          onRestartAndEditHandler={onRestartAndEditHandler}
          onError={onErrorHandler}
          onClose={onClose}
        />
      ),
      size: '2xl',
      contentProps: {
        minW: '864px',
        p: '16px',
        borderRadius: '16px',
      },
      footer: null,
      okText: null,
      onOK: undefined,
      cancelText: null,
    });
  }, []);
};
