import { Box, Flex, FlexProps, Text } from '@chakra-ui/react';
import React, { FC } from 'react';
import { usePlayContext } from 'sub-components/training-v2/play/store/context';
import { isQuestionCompleted } from 'sub-components/training-v2/play/utils';

interface IProps extends FlexProps {
  children: React.ReactNode;
}

const QuizProgressBar: FC = () => {
  const { tpSessionData, selectedIndex, selectedSubIndex, contents } =
    usePlayContext((state) => ({
      tpSessionData: state?.tpSessionData,
      selectedIndex: state.selectedIndex,
      selectedSubIndex: state.selectedSubIndex,
      contents: state.tpSessionData?.contents || [],
    }));

  const selectedData = React.useMemo(() => {
    return contents?.[selectedIndex];
  }, [contents, selectedIndex, selectedSubIndex]);

  const activeQuestions = selectedData?.questions?.filter(
    (q) => q?.type !== 'initial'
  );
  const totalQuestions = activeQuestions?.length || 0;
  const completedQuestions =
    activeQuestions?.filter((q) => {
      let isCompleted = isQuestionCompleted(
        q.eid,
        tpSessionData?.userProgress || []
      );
      return isCompleted;
    }).length || 0;

  return (
    <Flex flexDir='column' gap={2}>
      <Flex justify='space-between' align='center' gap={4}>
        <Box as='span' fontSize='sm' fontWeight='medium'>
          {completedQuestions}/{totalQuestions}
        </Box>
        <Flex w='full' gap={1}>
          {activeQuestions?.map((question, index) => {
            const isCompleted = isQuestionCompleted(
              question.eid,
              tpSessionData?.userProgress || []
            );

            return (
              <Box
                key={question.eid}
                flex={1}
                h='8px'
                bg={isCompleted ? 'blue.500' : 'gray.200'}
                borderRadius='6px'
              />
            );
          })}
        </Flex>
      </Flex>
    </Flex>
  );
};

const Container: FC<IProps> = ({ children, ...props }) => {
  return (
    <Flex flexDir='column' gap={4}>
      <QuizProgressBar />
      <Flex
        bg='rgba(42, 133, 255, 0.05)'
        borderRadius='12px'
        p='16px'
        w='full'
        flexDir='column'
        gap={8}
        {...props}
      >
        {children}
      </Flex>
    </Flex>
  );
};

export default Container;
