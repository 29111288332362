import { useEffect, useState } from 'react';

export function useWidthInPixels(vw: number) {
  const [width, setWidth] = useState(() => calculateWidthFromVW(vw));

  useEffect(() => {
    function handleResize() {
      setWidth(calculateWidthFromVW(vw));
    }

    window.addEventListener('resize', handleResize);
    // Cleanup on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, [vw]);

  return width;
}

function calculateWidthFromVW(vw: number) {
  return (window.innerWidth * vw) / 100;
}
