export const SORT_VALUES = {
  _ID_ASC: '_ID_ASC',
  _ID_DESC: '_ID_DESC',
  TITLE_ASC: 'TITLE_ASC',
  TITLE_DESC: 'TITLE_DESC',
  CREATEDAT_ASC: 'CREATEDAT_ASC',
  CREATEDAT_DESC: 'CREATEDAT_DESC',
  UPDATEDAT_ASC: 'UPDATEDAT_ASC',
  UPDATEDAT_DESC: 'UPDATEDAT_DESC',
  CREATEDBY_ASC: 'CREATEDBY_ASC',
  CREATEDBY_DESC: 'CREATEDBY_DESC',
  LAST_SESSION_ASC: 'LAST_SESSION_ASC',
  LAST_SESSION_DESC: 'LAST_SESSION_DESC',
};

export const _pathName = [
  {
    eid: '1',
    label: 'No sort',
    value: SORT_VALUES.CREATEDAT_DESC,
  },
  {
    eid: '2',
    label: 'A-Z',
    value: SORT_VALUES.TITLE_ASC,
  },
  {
    eid: '3',
    label: 'Z-A',
    value: SORT_VALUES.TITLE_DESC,
  },
];

export const _createdBy = [
  {
    eid: '1',
    label: 'No sort',
    value: SORT_VALUES.CREATEDAT_DESC,
  },
  {
    eid: '2',
    label: 'A-Z',
    value: SORT_VALUES.CREATEDBY_ASC,
  },
  {
    eid: '3',
    label: 'Z-A',
    value: SORT_VALUES.CREATEDBY_DESC,
  },
];

export const _createdOn = [
  {
    eid: '1',
    label: 'No sort',
    value: SORT_VALUES.CREATEDAT_DESC,
  },
  {
    eid: '2',
    label: 'Earliest first',
    value: SORT_VALUES.CREATEDAT_ASC,
  },
  {
    eid: '3',
    label: 'Latest first',
    value: SORT_VALUES.CREATEDAT_DESC,
  },
];

export const _lastActivity = [
  {
    eid: '1',
    label: 'No sort',
    value: SORT_VALUES.CREATEDAT_DESC,
  },
  {
    eid: '2',
    label: 'Earliest first',
    value: SORT_VALUES.LAST_SESSION_ASC,
  },
  {
    eid: '3',
    label: 'Latest first',
    value: SORT_VALUES.LAST_SESSION_DESC,
  },
];
