import { ApolloProvider, useApolloClient } from '@apollo/client';
import { ModalCloseButton } from '@chakra-ui/react';
import { HeaderColors } from 'configs';
import { useCallback, useRef } from 'react';
import { BoxHeader, UseConfirm, useConfirm } from 'ui-components';
import ViewResponseContainer from './ViewResponseContainer';

interface IProps {}

const useViewResponse = () => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();
  const apolloClient = useApolloClient();

  return useCallback(
    ({
      sessionId,
      userId,
      userName,
    }: {
      sessionId: string;
      userId: string;
      userName: string;
    }) => {
      if (confirmRef.current) {
        confirmRef.current.destroy();
      }
      confirmRef.current = confirm({
        Wrapper: ({ children }: any) => {
          return (
            <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
          );
        },
        size: '6xl',
        title: (
          <>
            <BoxHeader
              color={HeaderColors.Green}
              title={`${userName}’s activity`}
            />
            <ModalCloseButton top='1.5rem' right='1.5rem' fontSize='18px' />
          </>
        ),
        content: (
          <ViewResponseContainer sessionId={sessionId} userId={userId} />
        ),
        contentProps: {
          borderRadius: '12px',
          p: '2rem 2.5rem',
        },
        headerProps: {
          p: '0px',
        },
        bodyProps: {
          p: '0px',
        },
        isCentered: true,
        footer: null,
      });
    },
    [confirm]
  );
};

useViewResponse.displayName =
  'displayName:sub-components/training-v2/dashboard/components/myTrainings/viewResponse/useViewResponse.tsx';

export default useViewResponse;
