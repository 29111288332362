import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Button, FlexCard } from '../../../../../../atoms';
import { TFormInput } from '../../training-input.types';
import { useAddSkillVerifier } from './useAddSkillVerifier';
import { useSkillVerifierInfo } from './skill-verifier-info';
import SkillVerifierView from './SkillVerifierView';
import AddSkillVerifierButton from './AddSkillVerifierButton';

interface IProps {
  disabled?: boolean;
}

const AddSkillVerifier: FC<IProps> = ({ disabled }) => {
  const { getValues, setValue } = useFormContext<TFormInput>();
  const skillName = useWatch<TFormInput, 'skillVerifier.name'>({
    name: 'skillVerifier.name',
  });
  const addSkillVerifier = useAddSkillVerifier();
  const skillVerifierInfo = useSkillVerifierInfo();

  return (
    <FlexCard flexDir='column' bg='#F4F4F466' p='28px 32px' gap={2}>
      <Controller<TFormInput, 'hasSkillVerifier'>
        name='hasSkillVerifier'
        disabled={disabled}
        render={({ field }) => (
          <AddSkillVerifierButton
            ref={field.ref}
            isChecked={field.value}
            isDisabled={field.disabled}
            onChange={(isChecked) => {
              if (disabled) {
                return;
              }
              if (isChecked) {
                addSkillVerifier({
                  initialValues: getValues('skillVerifier'),
                  onAddClick: (values) => {
                    setValue('skillVerifier', values);
                    field.onChange(true);
                  },
                });
              } else {
                field.onChange(false);
                setValue('skillVerifier', undefined);
              }
            }}
          />
        )}
      />

      <Flex flexDir='column' gap='2px'>
        <SkillVerifierView
          skillName={skillName}
          disabled={disabled}
          onEditClick={() => {
            addSkillVerifier({
              initialValues: getValues('skillVerifier'),
              onAddClick: (values) => {
                setValue('skillVerifier', values);
              },
            });
          }}
        />

        <Button
          mt={6}
          width='max-content'
          size='sm'
          variant='outline'
          fontSize='12px'
          bg='#FCFCFC'
          onClick={() => skillVerifierInfo()}
        >
          Know more
        </Button>
      </Flex>
    </FlexCard>
  );
};

export default AddSkillVerifier;
