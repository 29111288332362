import React, { FC } from 'react';
import {
  Box,
  IconButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import { useWatch } from 'react-hook-form';
import MemberFilterContent from './MemberFilterContent';

import { ReactComponent as FilterIcon } from '../../../../../../../../assets/images/filterIcon.svg';
import { MFilterInput } from './member-filter.types';

interface IProps {
  index: number;
}

const MemberFilter: FC<IProps> = ({ index }) => {
  const memberFilter = useWatch<
    MFilterInput,
    `assignees.${number}.memberFilter`
  >({
    name: `assignees.${index}.memberFilter`,
  });

  const roleValue = memberFilter?.byRole?.values || [];
  const locValues = memberFilter?.byLocation?.values || [];

  const haveFilter = !!(roleValue.length || locValues.length);

  return (
    <Popover isLazy placement='bottom-end'>
      <PopoverTrigger>
        <IconButton
          position='relative'
          aria-label='filter'
          size='lg'
          borderRadius='12px'
          paddingInline='12px'
        >
          <>
            <FilterIcon />
            {haveFilter ? (
              <Box
                position='absolute'
                boxSize='9px'
                bg='#2A85FF'
                top='10px'
                right='10px'
                borderRadius='full'
              />
            ) : null}
          </>
        </IconButton>
      </PopoverTrigger>

      <PopoverContent
        width='inherit'
        borderRadius='12px'
        boxShadow='md'
        _focus={{}}
      >
        <MemberFilterContent index={index} />
      </PopoverContent>
    </Popover>
  );
};

export default MemberFilter;
