import React, { FC } from 'react';
import { Box, Flex, Grid, GridItem, Image } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useFormState } from 'react-hook-form';
import { TrainingContType } from '../../../../../../types';
import { TFormInput } from '../../training-input.types';
import { useContentMenuOptions } from './useContentMenuOptions';

interface ContentCardProps {
  title: string;
  description: string;
  icon: string;
  onClick: () => void;
  disabled?: boolean;
}

const ContentCard: FC<ContentCardProps> = ({
  title,
  description,
  icon,
  onClick,
  disabled,
}) => (
  <GridItem
    border='1px solid #EFEFEF'
    borderRadius='12px'
    p='14px 20px'
    cursor='pointer'
    onClick={disabled ? undefined : onClick}
    display='flex'
    alignItems='center'
    gap={5}
    aria-disabled={disabled}
    _hover={{
      bgColor: '#F4F4F4',
    }}
    _disabled={{
      opacity: 0.4,
      cursor: 'not-allowed',
    }}
  >
    <Image boxSize='72px' src={icon} />
    <Flex flexDir='column'>
      <Box fontWeight={600} fontSize='18px'>
        {title}
      </Box>
      <Box color='#6F767E' fontWeight={500} fontSize='15px'>
        {description}
      </Box>
    </Flex>
  </GridItem>
);

interface IProps {
  onAddClick?: (type: TrainingContType) => void;
  disabled?: boolean;
}

const InitialScreen: FC<IProps> = ({ onAddClick, disabled }) => {
  const { t } = useTranslation(['sidebar', 'training']);

  const contentCards = useContentMenuOptions(onAddClick);

  const contentError = useFormState<TFormInput>({
    name: 'contents',
    exact: true,
  }).errors?.contents;

  const errorMessage = contentError?.root?.message || contentError?.message;

  return (
    <Flex
      flexDir='column'
      borderRadius='12px'
      p={{ base: '24px', md: '28px 32px' }}
      border='1px solid #EFEFEF'
      gap='28px'
    >
      <Box fontWeight={600} fontSize='20px'>
        {t('training:addContentInitialChoose')}
      </Box>

      <Grid
        templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }}
        gap={4}
        aria-invalid={!!errorMessage}
        _invalid={{
          '> div': {
            borderColor: 'red.300',
            boxShadow: '0 0 0 1px #ff6a55',
          },
        }}
      >
        {contentCards.map((card, index) => (
          <ContentCard
            key={index}
            title={card.title}
            description={card.description}
            icon={card.icon}
            onClick={card.onClick}
            disabled={disabled}
          />
        ))}
      </Grid>

      {errorMessage && (
        <Box color='red.500' mt={-3}>
          {errorMessage}
        </Box>
      )}
    </Flex>
  );
};

InitialScreen.displayName =
  'displayName:sub-components/training-v2/create/components/AddContentSection/components/InitialScreen';

export default React.memo(InitialScreen);
