import { Center, Divider, Flex } from '@chakra-ui/react';
import { Heading, Footer, MainSection, ProgressSection } from './components';
import React, { FC } from 'react';
import { useControl } from './layers';
import Loader from 'sub-components/Loader';
import { PlayProvider } from './store/context';

const TrainingPathPlayInternal: FC = () => {
  const { loading } = useControl();

  if (loading) {
    return (
      <Center h='90vh'>
        <Loader />
      </Center>
    );
  }
  return (
    <Flex flexDir='column' gap={4}>
      <Heading />
      <ProgressSection />
      <Flex flexDir='column' h='calc(100vh - 170px)'>
        <MainSection />
        <Flex w='full' align='center' justify='center' bg='white'>
          <Divider w='98%' />
        </Flex>
        <Footer />
      </Flex>
    </Flex>
  );
};

const TrainingPathPlayContainer: FC = () => {
  return (
    <PlayProvider>
      <TrainingPathPlayInternal />
    </PlayProvider>
  );
};

TrainingPathPlayContainer.displayName =
  'displayName:sub-components/training-v2/play/TrainingPathPlayContainer';

export default TrainingPathPlayContainer;
