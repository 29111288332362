import { useLazyQuery } from '@apollo/client';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Badge, Box, Center, Flex, Skeleton, Tooltip } from '@chakra-ui/react';
import {
  TRAINING_PATH_V2_DASHBOARD,
  TRAINING_PATH_V2_PLAY,
  TRAINING_PATH_V2_PLAY_RETAKE,
  TRAINING_PATH_V2_PLAY_VIEW,
} from 'appRoutes';
import { Button } from 'atoms';
import { useUserDataSelector } from 'hooks';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'routes';
import { AllClear } from 'sub-components/nexus/NexusBase/components/common';
import { TrainingPathContainerTabs } from 'sub-components/training-v2/dashboard/components/Container';
import { TExcueteCallbackVars } from 'sub-components/training-v2/dashboard/components/GenericFilter/types/filter-types';
import { colorSchemes } from 'sub-components/training-v2/dashboard/components/myTrainings/bottomSection/MyTrainingTable';
import { prepareTrainingPathSessionData } from 'sub-components/training-v2/dashboard/components/myTrainings/helper';
import {
  GET_TRAINING_CATEGORIES,
  USER_TRAINING_PATH_SESSIONS,
} from 'sub-components/training-v2/dashboard/components/myTrainings/query/myTraining.graphql';
import { Image } from 'ui-components';
import { getImage } from 'utils';
import CardMode from './CardMode';
import TableMode from './TableMode';

interface IProps {
  mode?: 'card' | 'table';
}

const NexusMyTraining: FC<IProps> = ({ mode = 'card' }) => {
  const { loggedInUserEid } = useUserDataSelector((state) => ({
    loggedInUserEid: state?.eid,
  }));
  const [trainingCategories, setTrainingCategories] = useState<any[]>([]);
  const [trainingPathData, setTrainingPathData] = useState<any[]>([]);

  const trainingPathFilters: TExcueteCallbackVars = (
    page = 1,
    sort = 'CREATEDAT_DESC'
  ) => {
    let filter: {
      query?: string;
      category?: string[];
      status?: string[];
      assigneeStatus?: string[];
      overdue?: boolean;
    } = {
      query: '',
      status: [
        'TRAINING_CREATED',
        'TRAINING_STARTED',
        'TRAINING_REPEAT',
        'TRAINING_COMPLETED',
        'TRAINING_TERMINATED',
        'TRAINING_ONE_TIME',
      ],
      assigneeStatus: [],
    };

    filter.overdue = true;
    filter.assigneeStatus?.push('started');
    filter.assigneeStatus?.push('assigned');
    filter.assigneeStatus?.push('completed');

    return {
      page: 1,
      perPage: 3,
      sort: sort,
      filter: filter,
      userProgressFilter2: {
        userId: loggedInUserEid,
      },
      userIds: [loggedInUserEid],
    };
  };

  const [getTrainingPathSessionData, { loading: trackingTpPaginationLoader }] =
    useLazyQuery(USER_TRAINING_PATH_SESSIONS, {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const formattedData = prepareTrainingPathSessionData(
          data?.UserTpSessions?.items,
          trainingCategories
        );
        setTrainingPathData(formattedData);
      },
    });
  const [getTrainingCategories, { loading: trainingCategoriesLoader }] =
    useLazyQuery(GET_TRAINING_CATEGORIES, {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setTrainingCategories(data.EntityTpCategories);
        getTrainingPathSessionData({
          variables: trainingPathFilters(1),
        });
      },
    });

  useEffect(() => {
    getTrainingCategories();
  }, []);

  return mode === 'card' ? (
    <CardMode
      loading={trackingTpPaginationLoader || trainingCategoriesLoader}
      trainingPathData={trainingPathData}
    />
  ) : (
    <TableMode
      loading={trackingTpPaginationLoader || trainingCategoriesLoader}
      trainingCategories={trainingCategories}
      trainingPathData={trainingPathData}
    />
  );
};

export default NexusMyTraining;
