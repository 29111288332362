import React, { ChangeEvent, FC } from 'react';
import { Box, Checkbox, Flex } from '@chakra-ui/react';

interface IProps {
  hidden?: boolean;
  onSelectAll?: (event: ChangeEvent<HTMLInputElement>) => void;
  isSelected?: boolean;
}

const SelectAllButton: FC<IProps> = ({ hidden, onSelectAll, isSelected }) => {
  return (
    <Flex paddingInline='12px' paddingY={4} hidden={hidden}>
      <Box
        flex={1}
        fontSize='13px'
        fontWeight='600'
        color='#6F767E'
        textTransform='uppercase'
      >
        Select all
      </Box>
      <Checkbox
        value='all'
        size='lg'
        isChecked={isSelected}
        // isChecked={isSelected && allItemChecked}
        // isIndeterminate={isSelected && isIndeterminate}
        onChange={onSelectAll}
      />
    </Flex>
  );
};

export default SelectAllButton;
