import { Badge } from '@chakra-ui/react';
import FormDataContext from 'pages/forms/FormStore/form-data-context';
import React, { FC, useContext, useMemo } from 'react';

interface IProps {
  text: string;
  index: number;
}

const FormType: FC<IProps> = ({ text, index }) => {
  const formCtx = useContext(FormDataContext);

  const color = useMemo(() => {
    return (
      formCtx?.formCategories
        ?.filter((category) => category?.name === text)
        ?.map((cat) => cat?.color)?.[0] || '#cbecda'
    );
  }, [formCtx?.formCategories, text]);

  return (
    <Badge
      whiteSpace={'unset'}
      style={{
        blockSize: 'fit-content',
        textTransform: 'capitalize',
        backgroundColor: color,
        padding: '4px 6px',
        borderRadius: '4px',
        fontWeight: '600',
      }}
    >
      {text}
    </Badge>
  );
};

export default FormType;
