import {
  Box,
  Center,
  Circle,
  Flex,
  HStack,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { FC } from 'react';
import { Cell, Pie, PieChart } from 'recharts';
import { IProgressTrackerProps } from 'sub-components/training-v2/shared/types';
import { BoxHeader } from 'ui-components';

const COLORS = ['#CABDFF', '#FFD88D', '#B5E4CA', '#FFBC99'];

const ProgressTracker: FC<IProgressTrackerProps> = ({ progressData }) => {
  const { progress, totalMembers } = progressData;
  const filteredProgress = progress?.filter((item) => item?.count !== -1); //filtering out the overdue metric for noDeadline tp
  const chartData = filteredProgress
    ?.filter((item) => item?.count !== -1)
    ?.map((item) => ({
      value: item.count,
    }));
  const allZero = filteredProgress?.every((item) => item?.count === 0);
  return (
    <Box
      bg='#FFFFFF'
      p={'20px'}
      borderRadius='12px'
      w={{ base: '100%', md: '30%' }}
      border={'2px solid #EFEFEF'}
    >
      <Stack gap={'20px'} w='full'>
        <BoxHeader
          fontSize='18px'
          fontWeight={600}
          title={'Progress tracker'}
          color={'#B5E4CA'}
        />

        {allZero ? (
          <Center maxBlockSize={'full'} flexDir='column' h='20vh' gap={4}>
            <Box as='span' fontWeight={600} fontSize='16px'>
              Yet to be generated
            </Box>
            <Box
              as='span'
              fontWeight={500}
              color='rgba(111, 118, 126, 1)'
              textAlign='center'
            >
              Analytics will be updated once the data is availaible.
            </Box>
          </Center>
        ) : (
          <Stack gap={0}>
            <Text
              color={'#272B30'}
              fontWeight={600}
              fontSize={'14px'}
              mb={'16px'}
            >
              Total members allotted: {totalMembers}
            </Text>
            <Flex gap={8} align={'center'} px={'16px'}>
              <Box
                position='relative'
                width='99px'
                height='99px'
                borderRadius='full'
              >
                <PieChart width={99} height={99}>
                  <Pie
                    data={chartData}
                    cx='50%'
                    cy='50%'
                    outerRadius={49}
                    dataKey='value'
                  >
                    {chartData?.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS?.length]}
                      />
                    ))}
                  </Pie>
                </PieChart>
              </Box>

              <VStack align='stretch' spacing={3}>
                {filteredProgress?.map((item, index) => (
                  <HStack key={index} spacing={'8px'}>
                    <Circle size='8px' bg={COLORS[index % COLORS?.length]} />
                    <Flex gap={2}>
                      <Text
                        fontWeight={600}
                        color={'#272B30'}
                        fontSize={'15px'}
                      >
                        {item?.count}
                      </Text>
                      <Text
                        color={'#6F767E'}
                        fontSize={'12px'}
                        fontWeight={500}
                        isTruncated
                        maxW={'120px'}
                      >
                        {item?.status}
                      </Text>
                    </Flex>
                  </HStack>
                ))}
              </VStack>
            </Flex>
          </Stack>
        )}
      </Stack>
    </Box>
  );
};

export default ProgressTracker;
