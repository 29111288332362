import { gql } from '@apollo/client';

export const AUDIT_SESSION_DETAILS = gql`
  query AuditSessionById($eid: String!) {
    AuditSessionById(eid: $eid) {
      assignments {
        auditors
        locationId
        completedBy
        startedBy
        totalScore
        completedPercent
        isCompleted
        comments
        response
        completedAt
      }
      auditId
      auditType
      auditors {
        eid
        name
      }
      createdAt
      description
      dueDate
      isCompleted
      eid
      entityId
      isLastSession
      name
      questions
      repeatCycle
      startDate
      title
      updatedAt

      audit {
        status
        statusLogs {
          status
          statusDate
          createdByUser {
            name
          }
        }
      }
    }
  }
`;

export const SUBMIT_AUDIT_RESPONSE = gql`
  mutation SubmitAuditResponse($input: AuditResponseAddInput) {
    SubmitAuditResponse(input: $input) {
      succeed
    }
  }
`;

export const COMPLETE_AUDIT_SESSION = gql`
  mutation CompleteAuditSession($input: AuditSessionCompleteInput) {
    CompleteAuditSession(input: $input) {
      succeed
    }
  }
`;
