import React, { FC } from 'react';
import { Box, Checkbox, Flex } from '@chakra-ui/react';

interface IProps {
  value: string;
  label: string;
}

const CheckItem: FC<IProps> = ({ value, label }) => {
  return (
    <Flex
      gap={3}
      height='48px'
      minHeight='48px'
      alignItems='center'
      paddingInline='12px'
      borderRadius='12px'
      // cursor='pointer'
      _hover={{ bg: '#F4F4F3' }}
    >
      <Box
        flex={1}
        fontSize='15px'
        fontWeight='600'
        isTruncated
        color='#33383F'
      >
        {label}
      </Box>
      <Checkbox value={value} size='lg' />
    </Flex>
  );
};

export default CheckItem;
