import { DeepPartial } from 'react-hook-form';
import { toArray } from 'utils/utils';
import { QuestionType } from 'types';

import { TemplateItem } from './FormTemplate/form-template.graphql';
import { FormStatus, IFormInput, Question } from './create-form/form.types';
import { FormEditResponse } from './form.graphql';
import { FormPreviewInput } from './from-preview/form-preview.types';
import { HiddenResponse } from '../../configs';

const deprecatedQuestionType = (qType: QuestionType) => {
  // @ts-ignore
  if (qType === 'singleChoice') {
    return QuestionType.MULTI_CHOICE;
  }
  return qType;
};

export const templateToForm = (template: TemplateItem): IFormInput => {
  const questions = template?.questions?.map((question: Question) => {
    const options = toArray(question?.options).map((option) => {
      return {
        label: option?.label,
        isCorrect: option?.isCorrect,
        isDisabled: option?.isDisabled,
        subTitle: option?.subTitle,
        isOther: option?.isOther,
      };
    });

    return {
      label: question?.label,
      subTitle: question?.subTitle,
      qType: deprecatedQuestionType(question?.qType),
      isRequired: question?.isRequired,
      hasAnalytics: question?.hasAnalytics,
      isDisabled: question?.isDisabled,
      hasOtherOption: question?.hasOtherOption,
      options: options,
    } as Question;
  });

  return {
    title: template?.title,
    thumbnail: template?.thumbnail,
    description: template?.description,
    contributors: [],
    formCategory: {
      eid: template?.categoryId!,
      name: template?.category,
    },
    questions: questions || [],
  };
};

export const toEditableForm = (
  data: FormEditResponse['FormById']
): IFormInput => {
  const questions = toArray(data?.questions).map((question: Question) => {
    const options = toArray(question?.options).map((option) => {
      return {
        eid: option?.eid,
        label: option?.label,
        isCorrect: option?.isCorrect,
        isDisabled: option?.isDisabled,
        subTitle: option?.subTitle,
        isArchieved: option?.isArchieved,
        isOther: option?.isOther,
      };
    });

    return {
      eid: question?.eid,
      label: question?.label,
      subTitle: question?.subTitle,
      qType: deprecatedQuestionType(question?.qType),
      isRequired: question?.isRequired,
      hasAnalytics: question?.hasAnalytics,
      isDisabled: question?.isDisabled,
      isArchieved: question?.isArchieved,
      hasOtherOption: question?.hasOtherOption,
      hasImageNote: question?.hasImageNote,
      options: options,
    } as Question;
  });

  return {
    eid: data?.eid,
    title: data?.title,
    thumbnail: data?.thumbnail,
    description: data?.description,
    contributors: data?.contributors,
    status: data?.status as FormStatus,
    formCategory: {
      eid: data?.categoryId,
      name: data?.category,
    },
    questions: questions,
    originalQues: questions.slice(),
    notifyVia: data?.notifyVia || [],
    visibleTo: data.visibleTo,
    visibility: data.visibility,
    responseVisibility: data.responseVisibility,
  };
};

export const toMobilePreview = (data: FormEditResponse['FormById']) => {
  const questions = data?.questions?.map(
    (question: FormPreviewInput['response'][number]) => {
      return {
        qid: question?.eid,
        qType: deprecatedQuestionType(question?.qType),

        otherResponse: question.otherResponse,
        responseId: question?.responseId || [],
        response: question?.response || '',
        responseUrl: question?.responseUrl || [],
        isCorrect: question?.isCorrect === true,

        eid: question?.eid,
        label: question?.label,
        subTitle: question?.subTitle,
        isRequired: question?.isRequired,
        hasAnalytics: question?.hasAnalytics,
        isDisabled: question?.isDisabled,
        isArchieved: question?.isArchieved,
        hasOtherOption: question?.hasOtherOption,
        hasImageNote: question?.hasImageNote,
        options: question?.options?.map((option) => {
          return {
            eid: option?.eid,
            label: option?.label,
            isCorrect: option?.isCorrect,
            isDisabled: option?.isDisabled,
            subTitle: option?.subTitle,
            isArchieved: option?.isArchieved,
            isOther: option?.isOther,
          };
        }),
      } as Question;
    }
  );

  return {
    formId: data?.eid,
    description: data?.description,
    response: questions,
  };
};

const getResultId = (response: any) => {
  const data = toArray(response);

  return data.map((value) => {
    if (typeof value === 'object') {
      return value?.eid || value?.value;
    } else {
      return value;
    }
  });
};

export const toFormResponse = (
  data: FormPreviewInput
): DeepPartial<FormPreviewInput> => {
  const questions = data?.response
    ?.map((question) => {
      const resp: Partial<typeof question> = {
        qid: question?.eid,
        qType: question?.qType,
        responseId: getResultId(question?.responseId),
        response: question?.response || '',
        responseUrl: toArray(question?.responseUrl),
        otherResponse: question?.otherResponse,
        noteText: question?.noteText,
        // isCorrect: question.isCorrect,
      };

      if (question.qType === QuestionType.ACKNOWLEDGMENT) {
        resp.response = question.signature?.text || '';
        resp.font = question.signature?.font || '';
      }

      return resp;
    })
    .filter((value) => !HiddenResponse.includes(value?.qType!));

  return {
    status: 'complete',
    formId: data?.formId,
    response: toArray(questions),
  };
};
