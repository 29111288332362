import React, { FC, ReactNode, useCallback, useRef } from 'react';
import {
  AlertDialogCloseButton,
  Box,
  ButtonProps,
  Flex,
} from '@chakra-ui/react';
import { BoxHeader, UseConfirm, useConfirm } from 'ui-components';
import ModalContent from './ModalContent';
import { ApolloProvider, useApolloClient } from '@apollo/client';
import { ITPCategory } from 'sub-components/training-v2/shared/types';
import { eventBus } from 'shared/eventEmit';

interface IProps {
  newEvaluationReceivedCount?: number;
  setNewEvaluationReceivedCount?: React.Dispatch<React.SetStateAction<number>>;
  trainingCategories?: ITPCategory[];
}

type MyEvaluationsModal = (props: IProps) => void;

export const useMyEvaluations = (): MyEvaluationsModal => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();
  const apolloClient = useApolloClient();

  return useCallback(
    ({
      newEvaluationReceivedCount,
      setNewEvaluationReceivedCount,
      trainingCategories,
    }) => {
      if (confirmRef.current) {
        confirmRef.current.destroy();
      }

      const cbHandler = () => {
        confirmRef?.current?.destroy();
      };

      const onNavigateHandler = (trainingId: string, sessionId: string) => {
        eventBus.emit('NAVIGATE_TO_TRAINING_RESULT', {
          trainingId: trainingId,
          sessionId: sessionId,
        });
        confirmRef?.current?.destroy?.();
      };

      confirmRef.current = confirm({
        Wrapper: ({ children }: any) => {
          return (
            <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
          );
        },
        title: (
          <Flex gap='12px'>
            <Box flex={1}>
              <BoxHeader title={`New evaluations received`} color='#CABDFF' />
            </Box>
            <AlertDialogCloseButton
              pos='relative'
              borderRadius='full'
              top='0'
              right='0'
            />
          </Flex>
        ),
        content: (
          <ModalContent
            trainingCategories={trainingCategories || []}
            onNavigateHandler={onNavigateHandler}
            setNewEvaluationReceivedCount={setNewEvaluationReceivedCount}
          />
        ),
        size: '3xl',
        isCentered: true,
        contentProps: {
          py: '12px',
          px: '8px',
          borderRadius: '16px',
        },
        okText: null,
        cancelText: null,
        footer: null,
      });
    },
    []
  );
};
