import React, { FC, useEffect } from 'react';
import { Box, Flex, Image, useModalContext } from '@chakra-ui/react';

import { ActionButton } from '../../../ui-components';

import aiPowerIntro from '../../../assets/images/ai-search/AiPowerIntro.svg';
import checkCircle from '../../../assets/images/ai-search/check-circle.svg';
import closeCircle from '../../../assets/images/ai-search/close-circle.svg';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';

interface ItemProps {
  title: string;
  type?: 'success' | 'error';
}

const CardItem: FC<ItemProps> = ({ title, type }) => {
  const src = type === 'success' ? checkCircle : closeCircle;
  const color = type === 'success' ? '#83BF6E' : '#FF6A55';

  return (
    <Flex
      gap={2}
      color={color}
      bg='#FFFFFF'
      padding={3}
      borderRadius='12px'
      fontSize='12px'
      fontWeight={500}
      align='center'
    >
      <Image boxSize='20px' src={src} />
      {title}
    </Flex>
  );
};

interface IProps {
  onContinue?: (value: boolean) => void;
}

const AiSearchIntro: FC<IProps> = ({ onContinue }) => {
  const { onClose } = useModalContext();

  useEffect(() => {
    deployEvent(AmplitudeEventNames.INTRODUCTION_AI_POWERED_SEARCH, {
      event: {
        search_funnel_id: 3,
      },
    });
  }, []);

  return (
    <Flex flexDir='column' gap={6}>
      <Box fontSize='20px' fontWeight='600' color='#33383F' textAlign='center'>
        Introducing AI powered search
      </Box>

      <Image src={aiPowerIntro} alt='aiPowerIntro' />

      <Box
        fontWeight='500'
        color='#6F767E'
        textAlign='center'
        userSelect='none'
      >
        Our AI-powered search has the capability to read documents, forms, and
        training paths and other uploaded resources
      </Box>

      <Flex
        flexDir='column'
        bg='#F4F4F4'
        borderRadius='12px'
        padding={4}
        gap={4}
      >
        <Box fontWeight='600' color='#33383F' textAlign='center'>
          Do’s and Don’ts
        </Box>

        <Flex gap={2} justify='center'>
          <CardItem type='success' title='How to bake a cake?' />
          <CardItem
            type='success'
            title='What are different kinds of coffees?'
          />
        </Flex>

        <Flex gap={2} justify='center'>
          <CardItem type='error' title='How to create a training path?' />
          <CardItem type='error' title='What was my revenue?' />
        </Flex>
      </Flex>

      <Flex flexDir='column' gap={2}>
        <ActionButton
          size='lg'
          colorScheme='blue'
          actionFn={() => {
            deployEvent(AmplitudeEventNames.TRY_NOW_AI_POWERED_SEARCH, {
              event: {
                search_funnel_id: 3,
              },
            });
            onContinue?.(true);
          }}
          close={onClose}
        >
          Try now
        </ActionButton>

        <Flex height='48px'>
          <ActionButton
            width='full'
            size='lg'
            variant='link'
            colorScheme='blue'
            actionFn={() => {
              deployEvent(
                AmplitudeEventNames.CONTINUE_WITH_STANDARD_AI_POWERED_SEARCH,
                {
                  event: {
                    search_funnel_id: 3,
                  },
                }
              );
              onContinue?.(false);
            }}
            close={onClose}
          >
            Continue with Standard
          </ActionButton>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default AiSearchIntro;
