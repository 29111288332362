import React, { FC, useState } from 'react';
import {
  Box,
  Center,
  Checkbox,
  Flex,
  IconButton,
  Spacer,
} from '@chakra-ui/react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faClose, faGripDotsVertical } from '@fortawesome/pro-solid-svg-icons';
import { IconImage, IconsPlaceholder } from '../../../../../../ui-components';
import { LauncherTaskStep } from '../../launcher-config.fragment';
import { TextWithLink } from '../../TextWithLink';

import EditStepForm from './EditStepForm';

interface IProps {
  index: number;
  data: LauncherTaskStep;
  isDisabled?: boolean;
  fieldId: string;
  onDelete?: (value: LauncherTaskStep, index: number) => void;
  onUpdate?: (value: LauncherTaskStep, index: number) => void;
}

const StepItem: FC<IProps> = ({
  data,
  index,
  isDisabled,
  fieldId,
  onDelete,
  onUpdate,
}) => {
  const [editing, setEditing] = useState(false);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    setActivatorNodeRef,
    isDragging,
  } = useSortable({ id: fieldId, data: { index: index } });

  if (editing) {
    return (
      <Flex
        gap={4}
        ref={setNodeRef}
        transform={CSS.Translate.toString(transform)}
        border='1px solid transparent'
        borderRadius='4px'
        paddingLeft={2}
        data-grabbed={isDragging ? isDragging : undefined}
        _grabbed={{ zIndex: 2, bgColor: '#F4F4F4', borderColor: '#2A85FF' }}
      >
        <Center
          {...attributes}
          {...listeners}
          ref={setActivatorNodeRef}
          width={6}
          height={12}
          color='#6F767E'
          zIndex={1}
          _hover={{ color: '#2A85FF' }}
          _disabled={{ opacity: '0.4', cursor: 'not-allowed' }}
        >
          <FontAwesomeIcon
            icon={faGripDotsVertical as IconProp}
            fontSize='20px'
          />
        </Center>

        <EditStepForm
          data={data}
          onUpdateClick={(newValue) => {
            onUpdate?.(newValue, index);
            setEditing(false);
          }}
        />
      </Flex>
    );
  }

  // @ts-ignore
  return (
    <Flex
      pos='relative'
      gap={4}
      align='center'
      _hover={{
        '.step-action': {
          display: 'flex',
          opacity: 1,
        },
      }}
      ref={setNodeRef}
      transform={CSS.Translate.toString(transform)}
      border='1px solid transparent'
      borderRadius='4px'
      paddingLeft={2}
      data-grabbed={isDragging ? isDragging : undefined}
      _grabbed={{ zIndex: 2, bgColor: '#F4F4F4', borderColor: '#2A85FF' }}
    >
      <Center
        {...attributes}
        {...listeners}
        ref={setActivatorNodeRef}
        width={6}
        minW={6}
        height={12}
        color='#6F767E'
        zIndex={1}
        _hover={{ color: '#2A85FF' }}
        _disabled={{ opacity: '0.4', cursor: 'not-allowed' }}
      >
        <FontAwesomeIcon
          icon={faGripDotsVertical as IconProp}
          fontSize='20px'
        />
      </Center>

      <Checkbox isReadOnly />

      <TextWithLink
        fontSize='15px'
        fontWeight='400'
        color='#272B30'
        overflow='hidden'
        linkText={data?.title}
      />

      <Spacer />

      {data?.type === 'text' ? (
        <Box boxSize='30px' minW='30px' />
      ) : (
        <IconImage
          thumbnail={IconsPlaceholder[data.type]}
          name={data.title}
          boxSize={30}
          borderRadius='4px'
        />
      )}

      {!isDisabled && (
        <Flex
          display='none'
          opacity='0'
          className='step-action'
          gap={4}
          pos='absolute'
          top='0'
          right='0'
          boxSize='full'
          justify='flex-end'
          bg='linear-gradient(270deg, #FFFFFF 16%, rgba(255, 255, 255, 0) 86%)'
          transition='opacity 200ms, display 200ms'
          borderRightRadius='3px'
          paddingRight={2}
        >
          {data?.type === 'text' && (
            <IconButton
              aria-label='edit'
              borderRadius='full'
              bg='#BBBBBB'
              size='xs'
              alignSelf='center'
              icon={<FontAwesomeIcon icon={faPen as IconProp} color='white' />}
              onClick={() => setEditing(true)}
            />
          )}

          <IconButton
            aria-label='action'
            borderRadius='full'
            bg='#BBBBBB'
            size='xs'
            alignSelf='center'
            icon={
              <FontAwesomeIcon
                size='lg'
                icon={faClose as IconProp}
                color='white'
              />
            }
            onClick={() => onDelete?.(data, index)}
          />
        </Flex>
      )}
    </Flex>
  );
};

StepItem.displayName =
  'sub-components/Launcher/launcher-config/common/config-task-form/task-steps/StepItem';

export default StepItem;
