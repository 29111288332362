import React, { useCallback } from 'react';
import { AlertDialogCloseButton, Box, Flex } from '@chakra-ui/react';
import { useConfirm } from '../../../../../../../ui-components';
import RepeatForm, { RepeatFormProps } from './RepeatForm';

type RepeatingSelect = (props: RepeatFormProps) => void;

export const useRepeatingSelect = (): RepeatingSelect => {
  const confirm = useConfirm();

  return useCallback((props) => {
    confirm({
      title: (
        <Flex justify='space-between'>
          <Box
            fontWeight='600'
            fontSize='20px'
            lineHeight='32px'
            color='#1a1d1f'
          >
            Select repeating schedule
          </Box>

          <AlertDialogCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
          />
        </Flex>
      ),
      isCentered: true,
      content: <RepeatForm {...props} />,

      headerProps: {
        paddingY: 7,
      },

      contentProps: {
        minWidth: '500px',
        // borderRadius: '12px',
        // paddingY: '12px',
        padding: 0,
      },

      bodyProps: {
        padding: 0,
      },

      footer: null,
    });
  }, []);
};
