import React, { forwardRef, useMemo } from 'react';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  useControllableState,
} from '@chakra-ui/react';
import CategorySelectContent from './CategorySelectContent';
import { useTrainingCategories } from './useTrainingCategories';
import CategorySelectButton from './CategorySelectButton';

interface IProps {
  value?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
}

const TrainingCategorySelect = forwardRef<never, IProps>(
  ({ value, onChange, disabled }, ref) => {
    const { categories } = useTrainingCategories();

    const [internalValue, updateValue] = useControllableState<string>({
      value: value,
      onChange: onChange,
    });

    const selected = useMemo(() => {
      return categories.find((val) => internalValue === val.eid);
    }, [categories, internalValue]);

    if (disabled) {
      return (
        <CategorySelectButton ref={ref} category={selected?.name} disabled />
      );
    }

    return (
      <Popover isLazy matchWidth>
        <PopoverTrigger>
          <CategorySelectButton ref={ref} category={selected?.name} />
        </PopoverTrigger>

        <PopoverContent
          width='inherit'
          borderRadius='12px'
          boxShadow='md'
          _focus={{}}
        >
          <CategorySelectContent
            categories={categories}
            value={internalValue}
            updateValue={updateValue}
          />
        </PopoverContent>
      </Popover>
    );
  }
);

TrainingCategorySelect.displayName = 'TrainingCategorySelect';

export default TrainingCategorySelect;
