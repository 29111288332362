import { ApolloQueryResult, useMutation } from '@apollo/client';
import { Box, Button, Center, Flex, Text } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { UPDATE_LOCATION_GO_LIVE_DATE } from 'pages/LocationDetails/service/location-details.graphql';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DatePickerCustom from 'ui-components/DatePicker/DatePicker';
import { faCalendar } from '@fortawesome/pro-light-svg-icons';
import { ILocationDetails } from 'pages/LocationDetails/utils/location-details.types';

interface IProps {
  goLiveDate: Date | undefined;
  locationId: string | undefined;
  refetch:
    | ((
        variables?:
          | Partial<{
              eid: string;
            }>
          | undefined
      ) => Promise<
        ApolloQueryResult<{
          userById: ILocationDetails;
        }>
      >)
    | undefined;
}

const SetGoLiveDate: FC<IProps> = ({ goLiveDate, locationId, refetch }) => {
  const { t } = useTranslation(['common', 'location', 'setting']);
  const [updatedGoLive, setUpdatedGoLiveDate] = useState(goLiveDate);
  const [open, setOpen] = useState(false);

  const [updateLocationGoLive, { loading }] = useMutation(
    UPDATE_LOCATION_GO_LIVE_DATE
  );

  const updateGoLiveDate = async () => {
    if (!updatedGoLive || !locationId) return;

    try {
      await updateLocationGoLive({
        variables: {
          input: {
            eid: locationId,
            goLiveDate: updatedGoLive,
          },
        },
      });

      refetch?.({
        eid: locationId,
      });
      setOpen(false);
    } catch (e) {}
  };

  return (
    <div>
      <Flex flexDir='column'>
        {!open && (
          <>
            <Text fontWeight={600}>{t('location:goLiveDate')}:</Text>
            {goLiveDate ? (
              <Flex alignItems='center' gap='6px'>
                <Text color='#33383F' fontSize='14px'>
                  On {moment(goLiveDate).format('DD MMM YYYY')}
                </Text>
                <FontAwesomeIcon
                  icon={faPencil as IconProp}
                  cursor='pointer'
                  color={'#6F767E'}
                  onClick={() => {
                    setOpen(true);
                  }}
                />
              </Flex>
            ) : (
              <Flex
                gap='.5rem'
                alignItems='center'
                onClick={() => setOpen(true)}
                width='fit-content'
                cursor='pointer'
              >
                <Text>Add date</Text>
                <Center
                  border='1.33px solid #EFEFEF'
                  borderRadius='100%'
                  padding='4px'
                  width='20px'
                  height='20px'
                >
                  <FontAwesomeIcon
                    icon={faPlus as IconProp}
                    color={'#6F767E'}
                  />
                </Center>
              </Flex>
            )}
          </>
        )}
        {open && (
          <Flex
            flexDirection='column'
            background='#F4F4F4'
            padding='12px 16px'
            borderRadius='12px'
            gap='10px'
            width='290px'
          >
            <Text fontWeight={600}>{t('location:goLiveDate')}:</Text>
            <DatePickerCustom
              placeholder='Add a date'
              value={updatedGoLive}
              maxDate={new Date()}
              onChange={(value) => {
                setUpdatedGoLiveDate(value);
              }}
              variant='solid'
              colorScheme='whiteAlpha'
              color='#6F767E'
              format='DD MMM yyyy'
            />
            <Flex gap='12px' justifyContent='flex-end' fontSize='12px'>
              <Button
                background='white'
                color='#1A1D1F'
                size='xs'
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button>
              <Button
                colorScheme='blue'
                size='xs'
                disabled={!updatedGoLive || loading}
                isLoading={loading}
                onClick={updateGoLiveDate}
              >
                Save
              </Button>
            </Flex>
          </Flex>
        )}
      </Flex>
    </div>
  );
};

export default SetGoLiveDate;
