import React, { ChangeEvent, forwardRef, useState } from 'react';
import { Box, Center, Image } from '@chakra-ui/react';
import _uniqueId from 'lodash/uniqueId';
import { Button } from '../../../../../atoms';

import { ReactComponent as DeleteIcon } from 'assets/images/deleteBin.svg';
import { ReactComponent as EditDarkIcon } from 'assets/images/edit-dark.svg';

interface IProps {
  value: string;
  onImageChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onRemoveClick?: () => void;
  disabled?: boolean;
}

const ThumbnailView = forwardRef<never, IProps>(
  ({ value, onImageChange, onRemoveClick, disabled }, inputRef) => {
    const [inputId] = useState(_uniqueId('addFile-'));

    if (disabled) {
      return (
        <Box
          minW='214px'
          h='270px'
          borderRadius='12px'
          opacity={0.4}
          cursor='not-allowed'
        >
          <Image
            src={value}
            boxSize='full'
            borderRadius='12px'
            objectFit='cover'
          />
        </Box>
      );
    }

    return (
      <Box
        minW='214px'
        h='270px'
        position='relative'
        borderRadius='12px'
        _hover={{
          div: {
            display: 'flex',
          },
        }}
      >
        <Center
          display='none'
          position='absolute'
          boxSize='full'
          bg='blackAlpha.600'
          borderRadius='12px'
          flexDirection='column'
          gap='16px'
        >
          <Button
            as='label'
            size='sm'
            fontSize='12px'
            fontWeight='500'
            leftIcon={<EditDarkIcon width='16' />}
            htmlFor={inputId}
            cursor='pointer'
          >
            Change image
          </Button>

          <Button
            size='sm'
            fontSize='12px'
            fontWeight='500'
            colorScheme='blackAlpha'
            bgColor='#111315'
            leftIcon={<DeleteIcon color='#EFEFEF' width='16' />}
            onClick={onRemoveClick}
          >
            Remove image
          </Button>
        </Center>

        <Image
          src={value}
          boxSize='full'
          borderRadius='12px'
          objectFit='cover'
        />

        <input
          ref={inputRef}
          type='file'
          onChange={onImageChange}
          id={inputId}
          style={{ display: 'none' }}
          accept='image/*'
        />
      </Box>
    );
  }
);

ThumbnailView.displayName = 'ThumbnailView';

export default ThumbnailView;
