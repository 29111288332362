import { AddIcon } from '@chakra-ui/icons';
import { Flex } from '@chakra-ui/react';
import React, { FC } from 'react';
import { Button } from '../../../../../atoms';

const ActionFooter: FC = () => {
  return (
    <Flex
      justify='flex-end'
      p='12px 16px'
      gap={4}
      w='full'
      position='absolute'
      bottom='0px'
      bg='rgba(252, 252, 252, 1)'
      borderTop='1px solid rgba(239, 239, 239, 1)'
      borderBottomLeftRadius='12px'
      borderBottomRightRadius='12px'
    >
      <Button variant='outline' leftIcon={<AddIcon />}>
        Add another question
      </Button>
      <Button variant='solid' colorScheme='blue'>
        Publish quiz
      </Button>
    </Flex>
  );
};

ActionFooter.displayName =
  'displayName:sub-components/training-v2/shared/quiz/components/ActionFooter';

export default ActionFooter;
