import React from 'react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Box, Flex, forwardRef } from '@chakra-ui/react';
import { RolesEntity } from 'sop-commons/src/client';
import { Button, ButtonProps } from '../../../../../../../atoms';
import SelectedJobs from './SelectedJobs';

interface IProps extends ButtonProps {
  values?: RolesEntity[];
  onRemoveClick?: (value: string) => void;
}

const JobSelectButton = forwardRef<IProps, 'button'>(
  ({ values, disabled, onRemoveClick, ...props }, ref) => {
    return (
      <Button
        ref={ref}
        as='div'
        size='lg'
        borderRadius='12px'
        rightIcon={<ChevronDownIcon boxSize='28px' color='#6F767E' />}
        justifyContent='space-between'
        alignItems='initial'
        paddingX='12px'
        paddingBlock='6px'
        variant='outline'
        bg='white'
        width='full'
        height='initial'
        minHeight={12}
        disabled={disabled}
        {...props}
      >
        <Flex gap={2} align='center' flexWrap='wrap'>
          {values?.length ? (
            <SelectedJobs values={values} onRemoveClick={onRemoveClick} />
          ) : (
            <Box fontSize='14px' fontWeight='600' opacity='0.5'>
              Select job
            </Box>
          )}
        </Flex>
      </Button>
    );
  }
);

export default JobSelectButton;
