import React, { FC, useState } from 'react';
import { Button, Stack, Text, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import {
  ISkillVerifierFormInput,
  IVerifierProps,
} from 'sub-components/training-v2/shared/types';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { eventBus } from 'shared/eventEmit';

const Verifier: FC<IVerifierProps> = ({ skillIndex }) => {
  const { control, setValue, watch } =
    useFormContext<ISkillVerifierFormInput>();
  const fieldName = `skills.${skillIndex}.rating` as const;
  const currentSkillRating = useWatch<
    ISkillVerifierFormInput,
    `skills.${number}.rating`
  >({
    control,
    name: fieldName,
  });
  const addRating = (rating: number) => {
    setValue(fieldName, rating, { shouldDirty: true }); //making sure that the ratings are compared with the defuault vals for IsDirty State
  };

  const { t } = useTranslation(['training']);

  return (
    <Stack gap={3} p={4} align={'flex-start'} justify={'center'}>
      <Text color={'#6F767E'} fontSize={'14px'}>
        {t('training:evaluate.responses.skillVerifyDesc')}
      </Text>
      <Flex gap={4}>
        {Array.from({ length: 10 }).map((_, index) => {
          return (
            <Controller
              control={control}
              name={fieldName}
              key={index}
              render={({ field, fieldState: { error } }) => {
                const isSelected = currentSkillRating === index + 1;
                return (
                  <Button
                    border={`2px solid ${isSelected ? '#2A85FF' : '#EFEFEF'}`}
                    borderRadius={'12px'}
                    color={isSelected ? '#2A85FF' : '#6F767E'}
                    key={index}
                    h={'60px'}
                    w={'60px'}
                    bg={'#FFFFFF'}
                    onClick={() => addRating(index + 1)}
                    onChange={field.onChange}
                  >
                    <Text>{index + 1}</Text>
                  </Button>
                );
              }}
            />
          );
        })}
      </Flex>
    </Stack>
  );
};

export default Verifier;
