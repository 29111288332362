import { Box, Center, Flex, HStack, VStack } from '@chakra-ui/react';
import React, { FC, useState } from 'react';
import {
  Bar,
  BarChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts-new';
import { PaginationComponent } from 'sub-components/nexus/NexusBase/components/LeftSection/components/CustomerFeedback/components';
import { TpOverallLocationsWiseAnalytics } from './Tracking/tracking-types';
import { useReactiveVar } from '@apollo/client';
import { usersEntityObj } from 'sub-components/Header';
import moment from 'moment';
import EmptyState from 'sub-components/EmptyState';

const CustomizedAxisTick: React.FC<any> = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y + 25})`}>
      <text
        x={0}
        y={0}
        dy={4}
        textAnchor='middle'
        transform='rotate(-90)'
        fontSize='12px'
        fontWeight='500'
        fill='#33383F'
      >
        {payload?.value?.length > 9
          ? `${payload?.value?.substring(0, 5)}...`
          : payload?.value}
      </text>
    </g>
  );
};

const LegendComponent: FC = () => {
  const legends = [
    { color: 'rgba(142, 89, 255, 1)', label: 'Avg. completion rate' },
  ];

  return (
    <Flex mt={5}>
      {legends.map((legend) => (
        <HStack key={legend.label} alignItems='flex-start' pos='relative'>
          <Flex gap='10px' alignItems='center'>
            <Box height='6px' width='22px' bg={legend.color} />
            <Box
              as='span'
              m={0}
              p={0}
              textColor='#6F767E'
              fontSize='12px'
              fontWeight={500}
            >
              {legend.label}
            </Box>
          </Flex>
        </HStack>
      ))}
    </Flex>
  );
};

const ITEMS_PER_PAGE = 15;

interface LocationCompletionGraphProps {
  tpOverallLocationsWiseAnalytics: TpOverallLocationsWiseAnalytics[];
}

const LocationCompletionGraph = ({
  tpOverallLocationsWiseAnalytics,
}: LocationCompletionGraphProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const allUsers = useReactiveVar(usersEntityObj);

  const getLocationName = (eid: string) => {
    return allUsers.find((user) => user.eid === eid)?.name || '';
  };

  // Filter out locations where the corresponding user isRoot
  const filteredLocations = tpOverallLocationsWiseAnalytics?.filter(
    (location) => {
      const user = allUsers.find((user) => user.eid === location.locationId);
      return user ? !user.isRoot : true; // Keep if no user found or not isRoot
    }
  );

  const totalPages = Math.ceil(filteredLocations?.length / ITEMS_PER_PAGE);

  const handlePrev = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNext = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const paginatedData =
    filteredLocations?.length > ITEMS_PER_PAGE
      ? filteredLocations?.slice(
          (currentPage - 1) * ITEMS_PER_PAGE,
          currentPage * ITEMS_PER_PAGE
        )
      : filteredLocations;

  const updatedPaginatedData = paginatedData?.map((item) => ({
    ...item,
    completeRate:
      item.total === 0 ? 0 : ((item.completed / item.total) * 100).toFixed(2),
    locationName: getLocationName(item.locationId),
  }));

  const graphContainer = () => {
    const showReferenceLinesYAxis = [25, 50, 75, 100];
    return (
      <ResponsiveContainer width='95%' height={400}>
        <BarChart
          data={updatedPaginatedData}
          margin={{ top: 20, right: 0, left: 10, bottom: 40 }}
        >
          <XAxis
            dataKey='locationName'
            tick={<CustomizedAxisTick />}
            interval={0}
          />
          <YAxis domain={[0, 100]} ticks={[0, 25, 50, 75, 100]} />
          <Tooltip wrapperStyle={{}} />
          <Bar
            dataKey='completeRate'
            name='Avg. completion rate'
            fill='rgba(142, 89, 255, 1)'
            barSize={12}
            radius={[3, 3, 0, 0]}
          />
          {showReferenceLinesYAxis?.map((axisPoint, index) => (
            <ReferenceLine
              key={index}
              y={axisPoint}
              stroke='rgba(239, 239, 239, 1)'
              strokeDasharray='5 5'
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    );
  };

  return (
    <Flex gap={4} height='100%' width='100%'>
      <VStack
        align='flex-start'
        w='full'
        border='2px solid rgba(239, 239, 239, 1)'
        p='24px'
        borderRadius='12px'
      >
        <Box fontWeight={600} fontSize='20px'>
          Location wise completion
        </Box>
        <Box color='rgba(111, 118, 126, 1)' fontWeight={500} fontSize='12px'>
          Date as of {moment().format('DD MMM YYYY')}
        </Box>

        {filteredLocations?.length ? (
          <>
            {graphContainer()}
            <Flex
              justifyContent='space-between'
              alignItems='center'
              mt={4}
              pl={4}
              w='full'
            >
              <LegendComponent />
              <PaginationComponent
                currentPage={currentPage}
                totalPages={totalPages}
                handlePrev={handlePrev}
                handleNext={handleNext}
              />
            </Flex>
          </>
        ) : (
          <Center height='100%' width='100%'>
            <EmptyState
              image='DataCenter'
              title='No data found'
              imageSize='300px'
            />
          </Center>
        )}
      </VStack>
    </Flex>
  );
};

LocationCompletionGraph.displayName =
  'displayName:sub-components/training-v2/dashboard/components/LocationCompletionGraph';

export default LocationCompletionGraph;
