import React, { FC } from 'react';
import { Box, Center, Flex } from '@chakra-ui/react';

const data = [
  'The assignee completes the training path.',
  'The supervisor is notified of the completion.',
  'The supervisor reviews their performance.',
  `The supervisor fills out the Skill Verifier form, providing feedback and rating the assignee’s skills.`,
  'The assignee receives the feedback to help them improve and grow.',
];

interface IProps {}

const SkillVerifierInfoContainer: FC<IProps> = () => {
  return (
    <Flex
      flex={1}
      gap={6}
      flexDir='column'
      maxHeight='full'
      maxWidth='calc(100% - 260px - 40px)'
      overflow='hidden auto'
    >
      <Flex flexDir='column' gap={4}>
        <Box fontSize='14px' fontWeight='500' color='#272B30'>
          What is a skill verifier?
        </Box>
        <Box fontSize='12px' fontWeight='500' color='#6F767E'>
          A Skill Verifier is a feedback form filled out by the supervisor to
          assess the assignee’s performance after completing a training path.
        </Box>
      </Flex>

      <Flex flexDir='column' gap={3}>
        <Box fontSize='14px' fontWeight='500' color='#272B30' mb={1}>
          How does it work?
        </Box>

        {data.map((item, index) => (
          <Flex
            key={index}
            fontSize='12px'
            fontWeight='500'
            align='center'
            gap={3}
          >
            <Center
              boxSize={6}
              borderRadius='full'
              color='#33383F'
              bg='#B1E5FC'
            >
              {index + 1}
            </Center>
            <Box color='#6F767E'>{item}</Box>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export default SkillVerifierInfoContainer;
