import { gql } from '@apollo/client';
import { UserEntityData } from 'shared/graphql/shared-types';
import { MembersTrainingPath } from 'sub-components/training-v2/shared/types';

export interface TrackingAnalyticsResponse {
  TpOverallAnalytics: {};
  TpOverallLocationsWiseAnalytics: {};
  TpOverallUserWiseAnalytics: {};
}
export const GET_TRACKING_ANALYTICS = gql`
  query GetTrackingAnalytics {
    TpOverallAnalytics
    TpOverallLocationsWiseAnalytics
    TpOverallUserWiseAnalytics
  }
`;

export interface IEntityUsersListResponse {
  EntityUsers: UserEntityData[];
}
export const GET_ENTITY_USERS = gql`
  query Query($entityId: String!) {
    EntityUsers(entityId: $entityId)
  }
`;

export interface TrackMembersAnalyticsResponse {
  TpOverallUserWiseAnalytics: MembersTrainingPath[];
}

export const GET_TRACKING_MEMBERS_ANALYTICS = gql`
  query GetTrackingAnalytics {
    TpOverallUserWiseAnalytics
  }
`;

export interface GetMemberAverageCompletionScoreResponse {
  Tppagination: {
    items: {
      lastSession: {
        trainingId: string;
        userProgress: {
          id: string;
          isCompleted: boolean;
        };
        score: number;
        contents: {
          eid: string;
        };
        maxscore: number;
      };
    };
  };
}

export const GET_MEMBER_AVERAGE_COMPLETION_SCORE = gql`
  query TpPagination {
    TpPagination {
      items {
        lastSession {
          trainingId
          userProgress {
            progress {
              id
              isCompleted
            }
            score
          }
          contents {
            eid
          }
          maxScore
        }
      }
    }
  }
`;

export const GET_PENDING_EVALUATION = gql`
  query GetPendingEvaluation {
    TpSessionsPendingEvaluation
  }
`;

export const GET_TP_SESSION_LOG = gql`
  query GetTpSessionLog($sessionId: String!, $userId: String!) {
    TpSessionLogById(sessionId: $sessionId, userId: $userId) {
      userId
      trainingId
      sessionId
      activities {
        action
        timestamp
        metadata
      }
      eid
    }
  }
`;

export const GET_TP_SESSION_BY_ID = gql`
  query TpSessionById(
    $eid: String!
    $filter: FilterFindManyTPProgressInput
    $userIds: [String!]
  ) {
    TpSessionById(eid: $eid) {
      name
      title
      trainingId
      maxScore
      hasSkillVerifier
      skillVerifier {
        name
        skill
      }
      training {
        assignmentType
        startDate
        deadlineType
        deadlineDate
        duration {
          type
          value
        }
        accessPermission {
          admin
          locationOwner
          superadmin
        }
      }
      assignedUsers(userIds: $userIds) {
        certificateDownloaded
        userId
        scorePercent
        score
        assignedOn
        dueDate
      }
      contents {
        title
        type
        eid
        isRemoved
        questions {
          label
          type
          eid
          score
          thumbnail
          options {
            uid
            text
            thumbnail
            isCorrect
          }
          hasMultipleCorrect
          pairs {
            uid
            left
            right
            leftThumbnail
            rightThumbnail
          }
          isTrue
          sequence {
            uid
            thumbnail
            text
          }
          textWithBlanks
          blanks {
            uid
            thumbnail
            answer
          }
          type
        }
      }
      userProgress(filter: $filter) {
        score
        certificate
        isCompleted
        isEvaluated
        maxScore
        skillVerifierResponse {
          rating
        }
        contents {
          title
          type
          eid
          isRemoved
          questions {
            label
            type
            eid
            score
            thumbnail
            options {
              uid
              text
              thumbnail
              isCorrect
            }
            hasMultipleCorrect
            pairs {
              uid
              left
              right
              leftThumbnail
              rightThumbnail
            }
            isTrue
            sequence {
              uid
              thumbnail
              text
            }
            textWithBlanks
            blanks {
              uid
              thumbnail
              answer
            }
            type
          }
        }
        progress {
          type
          id
          score
          isCompleted
          responses {
            qid
            score
            options
            isCorrect
            sequence {
              uid
              thumbnail
              text
            }
            blanks
            createdAt
            updatedAt
            pairs {
              uid
              left
              right
              leftThumbnail
              rightThumbnail
            }
          }
        }
        score
      }
    }
  }
`;

export const TP_BY_ID = gql`
  query TpById($eid: String!, $filter: FilterFindManyTPSessionInput) {
    TpById(eid: $eid) {
      isRepeating
      sessions(filter: $filter) {
        name
        title
        eid
        startDate
      }
    }
  }
`;

export const TpFormResponse = gql`
  query TpFormResponse(
    $userId: String!
    $trainingSessionId: String!
    $formId: String!
  ) {
    TpFormResponse(
      userId: $userId
      trainingSessionId: $trainingSessionId
      formId: $formId
    ) {
      status
      form {
        title
        description
        category
        questions
      }
      response
    }
  }
`;

export const REMIND_ASSIGNEE = gql`
  mutation TpRemindAssignee($sessionId: String!, $userId: String!) {
    TpRemindAssignee(sessionId: $sessionId, userId: $userId) {
      succeed
    }
  }
`;

export const REASSIGN_TP_USER = gql`
  mutation ReAssignTp($input: ReAssignTpInputInput) {
    ReAssignTp(input: $input) {
      succeed
    }
  }
`;
