import React, { FC } from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  useModalContext,
} from '@chakra-ui/react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Button } from '../../../../../../../atoms';
import { ActionButton } from '../../../../../../../ui-components';
import { CustomNumberInput } from '../../../common';
import WeekDaySelection from './WeekDaySelection';
import YearMonthSelect from './YearMonthSelect';
import MonthDateSelect from './MonthDateSelect';
import { IRepeatInput } from './repeating.types';
import RepeatTypeSelect from './RepeatTypeSelect';

export interface RepeatFormProps {
  values: Partial<IRepeatInput>;
  onSaveChange?: (values: IRepeatInput) => void;
}

const RepeatForm: FC<RepeatFormProps> = ({
  values: defaultValues,
  onSaveChange,
}) => {
  const { onClose } = useModalContext();
  const methods = useForm<IRepeatInput>({
    defaultValues: defaultValues,
  });

  const onSubmit = async (values: IRepeatInput) => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    onSaveChange?.(values);
    setTimeout(onClose, 100);
  };

  return (
    <FormProvider {...methods}>
      <Flex flexDir='column' minHeight='300px' justify='space-between' gap={8}>
        <Flex flexDir='column' padding='12px 24px' gap={3}>
          <Flex gap={5}>
            <Box
              fontWeight='600'
              color='#6F767E'
              whiteSpace='nowrap'
              lineHeight='48px'
            >
              Repeat every
            </Box>

            <Controller<IRepeatInput, 'repeatValue'>
              name='repeatValue'
              defaultValue={1}
              rules={{
                required: 'Required',
              }}
              render={({ field, fieldState }) => (
                <FormControl maxWidth='100px' isInvalid={fieldState.invalid}>
                  <CustomNumberInput
                    size='lg'
                    variant='auditOutline'
                    textAlign='center'
                    min={1}
                    max={999}
                    {...field}
                    onChange={(_, newValue) => field.onChange(newValue)}
                  />
                </FormControl>
              )}
            />

            <Controller<IRepeatInput, 'type'>
              name='type'
              rules={{
                required: 'Select repeat type',
              }}
              render={({ field, fieldState }) => (
                <FormControl maxWidth='150px' isInvalid={fieldState.invalid}>
                  <RepeatTypeSelect {...field} />
                  <FormErrorMessage>
                    {fieldState?.error?.message}
                  </FormErrorMessage>
                </FormControl>
              )}
            />
          </Flex>

          <WeekDaySelection />

          <YearMonthSelect />

          <MonthDateSelect />
        </Flex>
        <Flex
          gap={5}
          justify='flex-end'
          padding='12px 20px'
          bgColor='#FCFCFC'
          borderTop='1px solid #EFEFEF'
          borderBottomRadius='md'
        >
          <Button variant='outline' onClick={onClose}>
            Cancel
          </Button>
          <ActionButton
            colorScheme='blue'
            actionFn={methods.handleSubmit(onSubmit)}
          >
            Save
          </ActionButton>
        </Flex>
      </Flex>
    </FormProvider>
  );
};

export default RepeatForm;
