import React, { FC } from 'react';
import { DashboardContainer } from 'sub-components';
import { EditTraining } from 'sub-components/training-v2';

const EditTrainingPath: FC = () => {
  return (
    <DashboardContainer>
      <EditTraining />
    </DashboardContainer>
  );
};

export default EditTrainingPath;
