import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Flex, useToast } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import debounce from 'lodash.debounce';
import { match } from 'ts-pattern';
import { useTranslation } from 'react-i18next';

import { Button, SearchInput } from '../../../../../../atoms';
import { AmplitudeEvent, deployEvent } from '../../../../../../shared';
import { useRect, useSearchParam } from '../../../../../../hooks';
import { Authorize, AuthRole } from '../../../../../../authorization';
import { TSortBy } from '../../../../../../types';

import { useCreateLogs } from './create-logs/useCreateLogs';
import ConversationLoader from './ConversationLoader';
import ConversationLogLists from './ConversationLogLists';
import {
  ConversationLogItem,
  ConversationLogsQuery,
  ConversationLogsResponse,
  ConvSortSort,
  DELETE_LOGS_QUERY,
  LogsQueryVariable,
} from './conversation-log.graphql';
import { useLogDeleteConfirm } from './useLogDeleteConfirm';
import { UPDATE_LOGS_QUERY } from './create-logs/create-log.graphql';
import { useViewConversationLog } from './view-log';
import { LogPagination } from './log-pagination';
import { eventBus } from 'shared/eventEmit';

const getSortBy = (values: TSortBy[] | undefined = []): ConvSortSort => {
  return match<TSortBy, ConvSortSort>(values?.[0])
    .with({ id: 'occurrenceDate' }, (sel) =>
      sel.desc ? 'OCCURRENCEDATE_ASC' : 'OCCURRENCEDATE_DESC'
    )
    .with({ id: 'createdAt' }, (sel) =>
      sel.desc ? 'CREATEDAT_ASC' : 'CREATEDAT_DESC'
    )
    .otherwise(() => 'ISPINNED_DESC');
};

interface SearchParam {
  logid?: string;
}

interface IProps {
  locationId: string;
}

const ConversationLogsContainer: FC<IProps> = ({ locationId }) => {
  const { t } = useTranslation(['common']);
  const ref = useRef<HTMLDivElement>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [sortBy, updateSortBy] = useState<TSortBy[]>();
  const rect = useRect(ref);
  const mounted = useRef(false);
  const toast = useToast({
    position: 'top-right',
    isClosable: true,
    duration: 3000,
  });

  const searchLogId = useSearchParam<SearchParam>()?.logid;

  const [data, setData] =
    useState<ConversationLogsResponse['conversationLogsPagination']>();

  const createNotes = useCreateLogs();
  const deleteConfirm = useLogDeleteConfirm();
  const viewConversationLog = useViewConversationLog();

  const [fetchData, { loading, refetch, client }] = useLazyQuery<
    ConversationLogsResponse,
    LogsQueryVariable
  >(ConversationLogsQuery, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      setData(response.conversationLogsPagination);
    },
    onError: () => {
      setData(undefined);
    },
  });

  const [deleteLog] = useMutation(DELETE_LOGS_QUERY, {
    onCompleted: () => {
      toast({
        status: 'success',
        title: t('common:success'),
        description: 'Conversation log successfully deleted.',
      });
    },
    onError: () => {
      toast({
        status: 'error',
        title: t('common:error'),
        description: 'Conversation log deletion failed.',
      });
    },
  });

  const inputVariables = useMemo(() => {
    const inputs: LogsQueryVariable = {
      page: currentPage,
      perPage: 10,
      sort: getSortBy(sortBy),
      filter: {
        locationId: [locationId],
      },
    };

    if (searchQuery?.trim()) {
      inputs['filter']['query'] = searchQuery.trim();
    }

    return inputs;
  }, [searchQuery, currentPage, sortBy]);

  const debouncedFetch = debounce(async (variables) => {
    fetchData({
      variables: variables,
    });
  }, 300);

  useEffect(() => {
    fetchData({
      variables: {
        page: 1,
        perPage: 10,
        sort: getSortBy(sortBy),
        filter: {
          locationId: [locationId],
        },
      },
    });

    setTimeout(() => {
      mounted.current = true;
    });
  }, [locationId]);

  useEffect(() => {
    const unsubscribe = eventBus.on('callApi', () => {
      fetchData({
        variables: inputVariables,
      });
    });

    return () => unsubscribe?.();
  }, []);

  useEffect(() => {
    if (!mounted.current) {
      return undefined;
    }

    debouncedFetch(inputVariables);

    return () => debouncedFetch.cancel();
  }, [inputVariables]);

  const onCreateClick = () => {
    createNotes({
      locationId: locationId,
      onSuccess: () => {
        deployEvent(AmplitudeEvent.LOCATION_CONV_CREATED);
        fetchData({
          variables: inputVariables,
        });
      },
    });
  };

  const actionHandler = async (action: string, value: ConversationLogItem) => {
    switch (action) {
      case 'open':
        deployEvent(AmplitudeEvent.LOCATION_CONV_VIEW);
        return viewConversationLog({
          noteId: value.eid,
        });
      case 'edit':
        deployEvent(AmplitudeEvent.LOCATION_CONV_EDIT_BUTTON);
        return createNotes({
          locationId: locationId,
          noteId: value.eid,
          onSuccess: () => {
            deployEvent(AmplitudeEvent.LOCATION_CONV_EDITED);
            fetchData({
              variables: inputVariables,
            });
          },
        });
      case 'delete':
        deployEvent(AmplitudeEvent.LOCATION_CONV_DELETE_BUTTON);
        return deleteConfirm({
          onDeleteClick: async () => {
            const delResponse = await deleteLog({
              variables: {
                eid: value.eid,
              },
            });

            if (delResponse.errors) {
              return Promise.reject(delResponse.errors);
            }
            deployEvent(AmplitudeEvent.LOCATION_CONV_DELETED);
            const response = await refetch?.(inputVariables);
            if (response?.data) {
              setData(response?.data.conversationLogsPagination);
            }
          },
        });
      case 'pin':
      case 'unpin': {
        deployEvent(AmplitudeEvent.LOCATION_CONV_PIN_BUTTON);
        await client?.mutate({
          mutation: UPDATE_LOGS_QUERY,
          variables: {
            input: {
              eid: value.eid,
              isPinned: action === 'pin',
            },
          },
        });
        const response = await refetch?.(inputVariables);
        if (response?.data) {
          setData(response?.data.conversationLogsPagination);
        }
        return null;
      }
      default:
        console.log(action, value);
    }
  };

  useEffect(() => {
    if (searchLogId) {
      actionHandler('open', {
        eid: searchLogId,
      } as ConversationLogItem);
    }
  }, [searchLogId]);

  return (
    <Flex flexDir='column' minH='calc(100vh - 234px)' ref={ref} gap='20px'>
      <Flex justify='space-between'>
        <SearchInput
          width='320px'
          hideShortcuts
          placeholder='Search by subject & content'
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <Authorize
          permittedFor='user'
          permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
        >
          <Button
            event={AmplitudeEvent.LOCATION_CONV_LOG_ADD_BUTTON}
            leftIcon={<AddIcon />}
            colorScheme='blue'
            onClick={onCreateClick}
          >
            Add
          </Button>
        </Authorize>
      </Flex>

      <ConversationLoader
        isLoading={loading}
        isEmpty={!data?.items?.length}
        haveQuery={!!searchQuery}
      >
        <ConversationLogLists
          logsList={data?.items}
          actionHandler={actionHandler}
          sortBy={sortBy}
          updateSortBy={updateSortBy}
        />
      </ConversationLoader>

      <LogPagination
        position='fixed'
        bottom={0}
        left={rect?.left && `${rect.left - 24}px`}
        width={rect?.width && `${rect.width + 48}px`}
        totalRegisters={data?.pageInfo?.itemCount}
        registersPerPage={data?.pageInfo?.perPage}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
        itemCount={data?.items?.length}
      />
    </Flex>
  );
};

export default ConversationLogsContainer;
