import { Box, Center, Flex, Tooltip } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import React, { FC } from 'react';
import { useHistory } from 'routes';
import { TRAINING_PATH_V2_DASHBOARD } from 'appRoutes';

interface IProps {
  children: React.ReactNode;
  title: string;
}

const HeadingContainer: FC<IProps> = ({ children, title }) => {
  const history = useHistory();
  return (
    <Flex flexDir='column' gap={4} w='full'>
      <Flex align='center' justify='space-between'>
        <Flex align='center' gap='10px'>
          <Box as='span' fontWeight={600} fontSize='32px'>
            {title}
          </Box>
          <Tooltip
            label='Only the first quiz attempt score will be recorded as final.'
            hasArrow
          >
            <Box w='fit-content'>
              <FontAwesomeIcon
                icon={faCircleExclamation as IconProp}
                fontSize='20px'
                cursor='pointer'
                color='rgba(111, 118, 126, 1)'
              />
            </Box>
          </Tooltip>
        </Flex>
        <Center
          bg='rgba(244, 244, 244, 1)'
          borderRadius='50%'
          boxSize='44px'
          p='4px'
          cursor='pointer'
          onClick={() =>
            history.push({
              pathname: TRAINING_PATH_V2_DASHBOARD,
            })
          }
        >
          <FontAwesomeIcon
            icon={faXmark as IconProp}
            fontSize='20px'
            color='rgba(91, 90, 100, 1)'
          />
        </Center>
      </Flex>
      {children}
    </Flex>
  );
};

export default HeadingContainer;
