import React, { FC, useContext } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { canAccessAudit } from 'configs';
import {
  CHAPTER_URL,
  FORMS_URL,
  TRAINING_URL,
  AUDIT_LIST,
  TRAINING_PATH_V2,
  TRAINING_PATH_V2_DASHBOARD,
} from 'appRoutes';
import { Authorize } from 'authorization';

import SidebarItemHandbook from 'sub-components/Sidebar/category/SidebarItemHandbook';
import SidebarItem from 'sub-components/Sidebar/SidebarItem';
import SidebarSection from 'sub-components/Sidebar/SidebarSection';
import SidebarAudit from './SidebarAudit';

import { ReactComponent as FormIcon } from '../../../../assets/images/sidebar/form.svg';
import { ReactComponent as TrainingIcon } from '../../../../assets/images/sidebar/training.svg';
import { useRenderFlag } from '../helper';

const ResourcesSection: FC = () => {
  const { t } = useTranslation('sidebar');
  const match = useRouteMatch();
  // const flag = useRenderFlag();
  const pathname = useLocation().pathname;

  return (
    <SidebarSection title={t('resourcesSection')}>
      <SidebarItemHandbook
        isSelected={match?.path === CHAPTER_URL}
        isExpand={match?.path === CHAPTER_URL}
      />
      <Authorize>
        <SidebarItem
          icon={FormIcon}
          title={t('formCheckList')}
          link={FORMS_URL}
          isSelected={pathname === FORMS_URL}
        />
      </Authorize>
      {/* {flag && (
        <Authorize>
          <SidebarItem
            icon={FormIcon}
            title={t('formCheckList')}
            link={FORMS_URL}
            isSelected={pathname === FORMS_URL}
          />
        </Authorize>
      )} */}

      {/* <Authorize canAccess={(s) => canAccessAudit(s) && flag}>
        <SidebarAudit isSelected={pathname === AUDIT_LIST} />
      </Authorize> */}
      <Authorize canAccess={(s) => canAccessAudit(s)}>
        <SidebarAudit isSelected={pathname === AUDIT_LIST} />
      </Authorize>

      {/* <Authorize>
        <SidebarItem
          icon={TrainingIcon}
          title={t('training')}
          type='training'
          link={TRAINING_URL}
          isSelected={pathname === TRAINING_URL}
        />
      </Authorize> */}
      {/* {flag && (
        <Authorize>
          <SidebarItem
            icon={TrainingIcon}
            title={t('training')}
            type='training'
            link={TRAINING_URL}
            // isMenu={
            //   (userObject?.authRole === SUPER_ADMIN ||
            //     userObject?.authRole === ADMIN) &&
            //   userObject?.type === 'user'
            // }
            isSelected={pathname === TRAINING_URL}
            // hasList={
            //   (userObject?.authRole === SUPER_ADMIN ||
            //     userObject?.authRole === ADMIN) &&
            //   userObject?.type === 'user'
            // }
            // isExpand={
            //   pathname === TRAINING_URL || pathname === TRAINING_DRAFT_URL
            // }
            // list={
            //   userObject?.authRole === SUPER_ADMIN ||
            //   userObject?.authRole === ADMIN
            //     ? [
            //         {
            //           name: t('active_path'),
            //           value: 'active_path',
            //           status: 'valid',
            //           icon: '',
            //         },
            //         {
            //           name: t('drafts'),
            //           value: 'drafts',
            //           status: 'valid',
            //           icon: '',
            //         },
            //       ]
            //     : []
            // }
          />
        </Authorize>
      )} */}
      <SidebarItem
        icon={TrainingIcon}
        title={t('training')}
        type='training'
        link={TRAINING_PATH_V2_DASHBOARD}
        isSelected={pathname?.includes(TRAINING_PATH_V2)}
      />
    </SidebarSection>
  );
};

ResourcesSection.displayName =
  'sub-components/Sidebar/sections/resources/ResourcesSection';

export default ResourcesSection;
