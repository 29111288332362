import React, { FC, useRef } from 'react';
import { Flex, IconButton } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { cloneDeep } from '@apollo/client/utilities';
import { v4 as uuidv4 } from 'uuid';
import { QuizQuestionType } from '../../../../../../../types';
import { ActionButton } from '../../../../../../../ui-components';
import { eventBus } from '../../../../../../../shared/eventEmit';
import { QuizQFormInput } from './quiz-form.types';
import { useMultiChoiceConfirm } from './useMultiChoiceConfirm';

import { ReactComponent as DeleteIcon } from 'assets/images/deleteBin.svg';
import { ReactComponent as CopyIcon } from 'assets/images/copy.svg';

interface IProps {
  onRemoveClick?: () => void;
  onAddQuestion: (values: QuizQFormInput) => void;
  onCopyClick: (values: QuizQFormInput, newValues: QuizQFormInput) => void;
}

function updateDataId<T>(obj: T): T {
  // Return early if the input is not an object or array
  if (obj === null || typeof obj !== 'object') return obj;

  // If the input is an array, map over the items and update uids
  if (Array.isArray(obj)) {
    return obj.map((item) => updateDataId(item)) as unknown as T;
  }

  // If the input is an object, iterate over its keys and update 'uid'
  const newObj = {} as T;
  for (const key in obj) {
    // If the key is 'uid', update it
    if (key === 'uid') {
      newObj[key] = uuidv4() as never;
    } else {
      newObj[key] = updateDataId(obj[key]);
    }
  }

  return newObj;
}

const QuizQuestionFooter: FC<IProps> = ({
  onAddQuestion,
  onRemoveClick,
  onCopyClick,
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { handleSubmit, reset } = useFormContext<QuizQFormInput>();
  const multiChoiceConfirm = useMultiChoiceConfirm();

  const validateHandler = (
    values: QuizQFormInput,
    callback: CallableFunction = () => null
  ) => {
    if (
      values?.type === QuizQuestionType.MULTI_CHOICE &&
      values?.hasMultipleCorrect
    ) {
      if (values?.options?.filter((op) => op.isCorrect)?.length < 2) {
        multiChoiceConfirm({
          onConfirm: () => callback(values),
        });
      } else {
        callback(values);
      }
    } else {
      callback(values);
    }
  };

  const submitHandler = async (values: QuizQFormInput) => {
    validateHandler(values, onAddQuestion);
    // if (
    //   values?.type === QuizQuestionType.MULTI_CHOICE &&
    //   values?.hasMultipleCorrect
    // ) {
    //   if (values?.options?.filter((op) => op.isCorrect)?.length < 2) {
    //     multiChoiceConfirm({
    //       onConfirm: () => onAddQuestion(values),
    //     });
    //   } else {
    //     onAddQuestion(values);
    //   }
    // } else {
    //   onAddQuestion(values);
    // }
  };

  const copyHandler = async (values: QuizQFormInput) => {
    validateHandler(
      updateDataId(cloneDeep(values)),
      (newValues: QuizQFormInput) => {
        onCopyClick(values, newValues);
        reset(newValues);
      }
    );
    await new Promise((resolve) => setTimeout(resolve, 500));
  };

  React.useEffect(() => {
    const unsubscribe = eventBus.on('ADD_ANOTHER_QUESTION', (callback) => {
      callback?.(true);
      buttonRef.current?.click();
    });

    return () => unsubscribe?.();
  }, []);

  return (
    <Flex justify='end' gap={3}>
      <Flex gap={1}>
        <ActionButton
          aria-label='copy-question'
          variant='ghost'
          padding={0}
          actionFn={handleSubmit(copyHandler)}
        >
          <CopyIcon width={24} height={24} color='#6F767E' />
        </ActionButton>

        <IconButton
          aria-label='delete-question'
          variant='ghost'
          icon={<DeleteIcon width={24} height={24} color='#6F767E' />}
          onClick={onRemoveClick}
        />
      </Flex>

      <ActionButton
        ref={buttonRef}
        colorScheme='blue'
        actionFn={handleSubmit(submitHandler)}
      >
        Save
      </ActionButton>
    </Flex>
  );
};

export default QuizQuestionFooter;
