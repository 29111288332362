import React, { FC, useMemo } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useWatch } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { toArray } from '../../../../../../utils';
import { IAssigneeInput } from '../../training-input.types';
import { getTransObject, useUserRecordRef } from './assign-info.helpers';

/**
 * 1. Training will be assigned to all current members who are in barista jobs in all open and launching locations.
 * 2. Training will be assigned to all current members who are in Store manager, Barista, or Product director jobs in (Open) Bangalore, Hyderabad, and (Launching) Jaipur locations.
 * 3. Training will be assigned to all current members who are in Chef job in all locations and Store manager, Barista, or Product director roles in (Open) Bangalore, Hyderabad, and (Launching) Jaipur locations.
 * 4. Training will be assigned to all current members who are in all open and launching locations.
 * 5. ✅ Training will be assigned to all current members who are in (Open) Bangalore, Hyderabad, and (Launching) Jaipur locations.
 * 6. ✅ Training will be assigned to Satya Pichai, Mayank Jha, Loony, +5 more.
 * 7. Training will be assigned to Admin and Superadmins in all open locations
 * 7->1. Training will be assigned to all current members who are in Admin and Superadmins job in all open locations
 */

interface IProps {}

const AssigneeInfo: FC<IProps> = () => {
  const { t } = useTranslation('traininginfo', { keyPrefix: 'assigneeInfo' });
  const userRef = useUserRecordRef();

  const [assigneeUserType] = useWatch<IAssigneeInput, ['assigneeUserType']>({
    name: ['assigneeUserType'],
  });

  const [assignees] = useWatch<IAssigneeInput, ['assignees']>({
    name: ['assignees'],
  });

  const transObject = useMemo(() => {
    return getTransObject(
      {
        assignees: assignees,
        assigneeUserType: assigneeUserType,
      },
      userRef
    );
  }, [assigneeUserType, assignees]);

  if (!transObject) {
    return null;
  }

  return (
    <Flex
      flexDir='column'
      bg='#B1E5FC66'
      p='16px 24px'
      borderRadius='10px'
      gap={2}
    >
      <FontAwesomeIcon
        icon={faCircleExclamation as IconProp}
        color='#2A85FF'
        fontSize='16px'
        style={{ alignSelf: 'flex-start' }}
      />
      {toArray(transObject)?.map((item, index) => (
        <Box color='#33383F' key={index} _notLast={{ paddingBottom: 2 }}>
          <Trans
            t={t}
            {...item}
            // @ts-ignore
            components={{ ...item?.components }}
          />
        </Box>
      ))}
    </Flex>
  );
};

export default AssigneeInfo;
