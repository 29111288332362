import React, { FC } from 'react';
import { Box, Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { callAll } from '@chakra-ui/utils';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { TAssignByType } from '../../../../../../types';
import { AuthRole } from '../../../../../../authorization';
import { usersEntityObj } from '../../../../../Header';
import { UserEntityData } from '../../../../../../shared/graphql/shared-types';
import { IAssigneeInput } from '../../training-input.types';
import { TrainingJobSelect } from './job-select';
import { TrainingAuthRoleSelect } from './authRole-select';
import { isAdminOnly } from './GroupLocationSelect';
import { TrainingMemberSelect } from './member-select';
import { LocationGroupSelect } from './LocationGroup';
import AtLocationSelect from './AtLocationSelect';

interface IProps {
  assignBy: TAssignByType;
  index: number;
  disabled?: boolean;
}

const reduceRoot = (acc: string[], value: UserEntityData) => {
  if (value.isRoot) {
    acc.push(value.eid);
  }
  return acc;
};

const AssigneeInput: FC<IProps> = ({ assignBy, index, disabled }) => {
  const { setValue } = useFormContext<IAssigneeInput>();

  const assigneeUserType = useWatch<IAssigneeInput, 'assigneeUserType'>({
    name: 'assigneeUserType',
  });

  const onAuthRoleChange = (values: AuthRole[]) => {
    if (isAdminOnly(values)) {
      setValue(
        `assignees.${index}.locationIds`,
        usersEntityObj().reduce(reduceRoot, [])
      );
      setValue(`assignees.${index}.locationType`, 'custom');
    }
  };

  if (!assignBy) {
    return <Box minH={2} />;
  }

  if (assignBy === 'user') {
    return (
      <>
        <Controller<IAssigneeInput, `assignees.${number}.userIds`>
          name={`assignees.${index}.userIds`}
          disabled={disabled}
          rules={{
            required: 'Select members for assignee',
            deps: ['supervisors'],
          }}
          render={({ field, fieldState }) => (
            <FormControl isInvalid={fieldState.invalid}>
              {/*<FormLabel color='#6F767E'>Select members:</FormLabel>*/}
              <TrainingMemberSelect index={index} {...field} />
              <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
            </FormControl>
          )}
        />

        {assigneeUserType === 'oldAndNew' && (
          <Flex
            color='#33383F'
            fontSize='12px'
            fontWeight='500'
            align='center'
            gap='10px'
          >
            <FontAwesomeIcon
              icon={faCircleExclamation as IconProp}
              color='#8E59FF'
              fontSize='20px'
              rotation={180}
            />
            You are assigning to existing members only
          </Flex>
        )}
      </>
    );
  }

  if (assignBy === 'location') {
    return (
      <LocationGroupSelect
        index={index}
        assignBy={assignBy}
        disabled={disabled}
      />
    );
  }

  return (
    <React.Fragment>
      {assignBy === 'role' && (
        <Controller<IAssigneeInput, `assignees.${number}.roles`>
          name={`assignees.${index}.roles`}
          disabled={disabled}
          rules={{
            required: 'Select assignee jobs',
          }}
          render={({ field, fieldState }) => (
            <FormControl isInvalid={fieldState.invalid}>
              <TrainingJobSelect {...field} />
              <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
            </FormControl>
          )}
        />
      )}

      {assignBy === 'authRole' && (
        <Controller<IAssigneeInput, `assignees.${number}.authRoles`>
          name={`assignees.${index}.authRoles`}
          disabled={disabled}
          rules={{
            required: 'Select assignee roles',
            deps: [`assignees.${index}.locationIds`],
          }}
          render={({ field, fieldState }) => (
            <FormControl isInvalid={fieldState.invalid}>
              <TrainingAuthRoleSelect
                {...field}
                onChange={callAll(field.onChange, onAuthRoleChange)}
              />
              <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
            </FormControl>
          )}
        />
      )}

      <AtLocationSelect index={index} assignBy={assignBy} disabled={disabled} />
    </React.Fragment>
  );
};

export default AssigneeInput;
