import React, { useMemo } from 'react';
import { Box, Flex, FlexProps, forwardRef } from '@chakra-ui/react';
import { useFormState } from 'react-hook-form';
import { QuizQFormInput } from '../quiz-form.types';

interface IProps {
  optionIndex: number;
  baseKey: 'sequence' | 'options' | 'pairs';
  fieldKeys: string[];
  height?: FlexProps['height'];
  transform?: FlexProps['transform'];
  transition?: FlexProps['transform'];
}

const BaseOptionContainer = forwardRef<IProps, 'div'>(
  (
    {
      children,
      baseKey,
      fieldKeys = [],
      optionIndex,
      height,
      transform,
      transition,
    },
    ref
  ) => {
    const error = useFormState<QuizQFormInput>().errors;
    const optionError = error?.[baseKey]?.[optionIndex];

    const depCheck = Object.keys(optionError || {});

    const errorMessage = useMemo(() => {
      for (const fieldKey of fieldKeys) {
        // @ts-ignore
        if (optionError?.[fieldKey]) {
          // @ts-ignore
          return optionError[fieldKey]?.message;
        }
      }

      return undefined;
    }, [depCheck, fieldKeys]);

    return (
      <Flex
        ref={ref}
        flex={1}
        flexDir='column'
        width='268px'
        minWidth='174px'
        transform={transform}
        transition={transition}
      >
        <Flex
          flexDir='column'
          borderRadius='12px'
          border='2px solid'
          borderColor='#EFEFEF'
          height={height}
          aria-invalid={!!errorMessage}
          _invalid={{
            borderColor: 'red.300',
            boxShadow: '0 0 0 1px #FF6A55',
          }}
        >
          {children}
        </Flex>

        {errorMessage && (
          <Box color='red.500' mt='6px'>
            {errorMessage}
          </Box>
        )}
      </Flex>
    );
  }
);

export default BaseOptionContainer;
