import { FC, PropsWithChildren, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Box } from '@chakra-ui/react';

import Header from '../Header';
import Sidebar from '../Sidebar';

import './DashboardContainer.scss';
import DashboardDataContext from './DashboardStore/DashboardData/dashboard-data-context';
import { ILanguageSetting } from 'ui-components/LanguageSetting/language-setting.types';
import { GET_SUPPORTED_LOCALES } from 'ui-components/LanguageSetting/language-setting.graphql';
import { useAnalyticsSetup } from './useAnalyticsSetup';
import DashboardModal from './DashboardModal';

interface IProps {
  refetchUsers?: () => void;
  refetchLocations?: () => void;
  className?: string;
}

const DashboardContainer: FC<IProps> = ({
  refetchUsers,
  children,
  refetchLocations,
  className = '',
}: PropsWithChildren<IProps>) => {
  useAnalyticsSetup();

  const pathname = useLocation().pathname;
  const dashboardCtx = useContext(DashboardDataContext);

  const checkBotFlag = () => {
    return pathname === '/create-sop' || pathname === '/search-sop';
  };

  useQuery<ILanguageSetting>(GET_SUPPORTED_LOCALES, {
    // fetchPolicy: 'network-only',
    onCompleted: (data) => {
      dashboardCtx?.preferredLanguagesHandler(data?.SupportedLocales);
    },
  });

  return (
    <>
      <div className={'dashboard-container ' + className}>
        <Sidebar />

        <div className='desktop-content-with-header-container'>
          {!checkBotFlag() ? (
            <Header
              refetchUsers={refetchUsers}
              refetchLocations={refetchLocations}
            />
          ) : (
            <Box width='100%' height='96px' bg='#fcfcfc' display='flex' />
          )}
          <div id='dashboard-content' className='desktop-content-container'>
            {children}
          </div>
        </div>
      </div>
      {dashboardCtx.showErrorModal && !checkBotFlag() && <DashboardModal />}
    </>
  );
};

export default DashboardContainer;
