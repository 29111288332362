import { Box, Flex, Image, Stack } from '@chakra-ui/react';
import type { TableProps } from 'antd';
import { Table } from 'antd';
import { IQuizResponsesTable } from 'sub-components/training-v2/shared/types';
import mcqImage from 'assets/images/training-path-v2/quiz/mcq.svg';
import reorderImage from 'assets/images/training-path-v2/quiz/reorder.svg';
import matchSetImage from 'assets/images/training-path-v2/quiz/matchSet.svg';
import tfImage from 'assets/images/training-path-v2/quiz/tf.svg';

type Props = {
  responseData: any;
  maxScore: number;
};

const getQuizImageByType = (type) => {
  switch (type) {
    case 'multiChoice':
      return mcqImage;

    case 'trueFalse':
      return tfImage;

    case 'matchSet':
      return matchSetImage;

    case 'sequence':
      return reorderImage;

    default:
      return mcqImage;
  }
};

const ResponsesTable = ({ responseData, maxScore }: Props) => {
  const score = (responseData ?? []).reduce(
    (sum, item) => sum + (item?.score ?? 0),
    0
  );

  const columns: TableProps<IQuizResponsesTable>['columns'] = [
    {
      title: 'Question',
      dataIndex: 'question',
      key: 'question',
      render: (_, { question }) => {
        return (
          <Flex gap={2} alignItems='center'>
            <Image
              src={getQuizImageByType(question.type)}
              width='16px'
              marginRight='5px'
            />
            {question.thumbnail && (
              <Image
                src={question.thumbnail}
                alt={`Thumbnail for ${question.label}`}
                boxSize='35px'
                objectFit='cover'
                borderRadius='md'
              />
            )}
            {question.label}
          </Flex>
        );
      },
    },
    {
      title: 'Response',
      dataIndex: 'response',
      key: 'response',
      render: (_, { response }) => {
        return <Stack>{response}</Stack>;
      },
    },
    {
      title: 'Result',
      dataIndex: 'result',
      key: 'result',
      render: (_, { result }) => (
        <Stack>
          {result.map((res) => (
            <Box
              key={res.eid}
              style={{
                color: res.result !== 'correct' ? '#FF4D4F' : '#52C41A',
                backgroundColor:
                  res.result !== 'correct' ? '#FFF1F0' : '#F6FFED',
                padding: '4px 8px',
                borderRadius: '4px',
                width: 'fit-content',
              }}
              textTransform='capitalize'
            >
              {res.result}
            </Box>
          ))}
        </Stack>
      ),
    },
    {
      title: 'Points',
      dataIndex: 'points',
      key: 'points',
      width: '100px',
    },
  ];

  return (
    <Box>
      <Flex
        border='1px solid #DDDDDD'
        borderRadius='8px'
        p='12px'
        borderBottom='0'
        borderBottomRadius='0'
        marginBottom='-12px'
        fontSize='18px'
        color='#272B30'
        fontWeight={600}
        gap={2}
      >
        Quiz score :
        <Flex
          fontSize='13px'
          color='#6F767E'
          alignItems='center'
          fontWeight={500}
        >
          <Box
            color='#2A85FF'
            fontSize='20px'
            fontWeight={600}
            marginRight='2px'
          >
            {score % 1 === 0 ? score : Number(score).toFixed(2)}
          </Box>
          /{maxScore}
        </Flex>
      </Flex>
      <Table<IQuizResponsesTable>
        columns={columns}
        dataSource={responseData}
        pagination={false}
        style={{
          overflow: 'hidden',
          backgroundColor: '#FCFCFC',
          border: '1px solid #DDDDDD',
          borderRadius: '8px',
          borderTopLeftRadius: '0',
          borderTopRightRadius: '0',
          marginTop: '12px',
          marginBottom: '12px',
        }}
        sticky
      />
    </Box>
  );
};

export default ResponsesTable;
