import React, { FC } from 'react';
import { useWatch } from 'react-hook-form';
import { QuizQuestionType } from '../../../../../../../types';
import { QuizQFormInput } from './quiz-form.types';

// Quiz question type inputs
import { MultiChoiceInput } from './multi-choice';
import { TrueFalseInput } from './true-false';
import { MatchSetInput } from './match-set';
import { SequenceInput } from './sequence';
import { FillBlanksInput } from './fill-blanks';

interface IProps {}

const QuizQuestionDataInput: FC<IProps> = () => {
  const type = useWatch<QuizQFormInput, 'type'>({
    name: 'type',
  });

  switch (type) {
    case QuizQuestionType.MULTI_CHOICE:
      return <MultiChoiceInput />;
    case QuizQuestionType.TRUE_FALSE:
      return <TrueFalseInput />;
    case QuizQuestionType.MATCH_SET:
      return <MatchSetInput />;
    case QuizQuestionType.SEQUENCE:
      return <SequenceInput />;
    case QuizQuestionType.FILL_IN_BLANK:
      return <FillBlanksInput />;
    default:
      return null;
  }
};

export default QuizQuestionDataInput;
