import React, { FC, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  useModalContext,
  useToast,
} from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import FormInput from '../../../../atoms/FormInput';
import { FormCategory } from '../../../../types';
import { ActionButton } from '../../../../ui-components';
import { toArray } from '../../../../utils';
import { getFormCategories, useFormCategories } from '../../common';
import {
  UpdateCategoryResponse,
  FORM_CATEGORY_UPDATE,
} from './category-edit.graphql';

interface IProps {
  category: FormCategory;
  onCategoryUpdated?: (category: FormCategory) => void;
  onDeleteClick?: () => void;
}

const EditFormCategoryForm: FC<IProps> = ({
  category,
  onCategoryUpdated,
  onDeleteClick,
}) => {
  const { onClose } = useModalContext();
  const { t } = useTranslation(['common', 'form']);
  const { data } = useFormCategories();
  const { control, formState, handleSubmit } = useForm<FormCategory>({
    defaultValues: () => Promise.resolve(category),
  });

  const toast = useToast({
    position: 'top-right',
    isClosable: true,
    duration: 3000,
  });

  const isDirty = formState.isDirty;

  const [updateCategory, { loading }] = useMutation<UpdateCategoryResponse>(
    FORM_CATEGORY_UPDATE,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: getFormCategories,
          fetchPolicy: 'network-only',
        },
      ],
    }
  );

  const onSubmit = async (values: FormCategory) => {
    try {
      const response = await updateCategory({
        variables: {
          categoryId: values.eid,
          category: values.name,
        },
      });

      if (response.errors) {
        await Promise.reject(response.errors);
      } else {
        toast({
          title: t('success'),
          status: 'success',
          description: 'Form category successfully added',
        });
        onCategoryUpdated?.(response.data!.UpdateFormCategory);
        onClose();
      }
    } catch (e) {
      toast({
        title: t('error'),
        status: 'error',
        description: 'Form category creation failed',
      });
      throw e;
    }
  };

  const categoryList = useMemo(() => {
    return toArray(data?.EntityFormCategories);
  }, [data?.EntityFormCategories]);

  return (
    <Flex flexDirection='column' gap={4}>
      <Controller
        control={control}
        name='name'
        defaultValue=''
        rules={{
          required: t('form:validation.formCategoryRequired'),
          validate: (value) => {
            if (
              categoryList.find(
                (category) => category.name.trim() === value.trim()
              )
            ) {
              return '* Form category with same name exists';
            }
          },
        }}
        render={({ field, fieldState }) => {
          return (
            <FormControl isInvalid={!!fieldState.error}>
              <FormInput
                id='role'
                size='lg'
                placeholder={t('form:formCategory')}
                {...field}
              />
              <FormErrorMessage>
                <span>{fieldState.error?.message}</span>
              </FormErrorMessage>
            </FormControl>
          );
        }}
      />

      <Flex justifyContent='flex-end' pb={2} gap={3}>
        <ActionButton
          size='lg'
          minW='180px'
          fontSize='15px'
          colorScheme='red'
          isDisabled={loading}
          actionFn={onDeleteClick}
          close={onClose}
        >
          {t('common:delete')}
        </ActionButton>
        <ActionButton
          size='lg'
          minW='180px'
          fontSize='15px'
          colorScheme='blue'
          isDisabled={!isDirty}
          actionFn={handleSubmit(onSubmit)}
        >
          {t('common:update')}
        </ActionButton>
      </Flex>
    </Flex>
  );
};

export default EditFormCategoryForm;
