import React, { forwardRef, useMemo } from 'react';
import { Flex, IconButton } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import { match, P } from 'ts-pattern';
import moment from 'moment';
import { Any } from '../../../../../../types';
import { toNumber } from '../../../../../../utils';
import { TFormInput } from '../../training-input.types';
import SelectInput from './SelectInput';

import { ReactComponent as EditIcon } from '../../../../../../assets/images/edit-dark.svg';

interface IProps {
  value?: TFormInput['frequency'];
  onChange?: (value: TFormInput['frequency']) => void;
  disabled?: boolean;
}

const month = moment().month(0);

export const getNthDate = (value: number) => {
  return month.date(value).format('Do');
};

const FrequencySelect = forwardRef<never, IProps>((field, ref) => {
  const [repeatCycle, status] = useWatch<TFormInput, ['repeatCycle', 'status']>(
    { name: ['repeatCycle', 'status'] }
  );
  const { getValues } = useFormContext<TFormInput>();

  const frequencyOptions = useMemo(() => {
    return [
      {
        label: 'Does not repeat',
        value: 'once',
      },
      {
        label: match(repeatCycle)
          .with({ type: 'EVERY_N_DAYS', repeatValue: P.number }, () => {
            if (toNumber(repeatCycle?.repeatValue, 0) > 1) {
              return `Repeats every ${repeatCycle?.repeatValue} days`;
            }
            return 'Repeats every days';
          })
          .with({ type: 'EVERY_N_WEEKS' }, () => {
            const ON = moment.weekdays(repeatCycle?.dayOfTheWeek!);
            if (toNumber(repeatCycle?.repeatValue, 0) > 1) {
              return `Repeats every ${repeatCycle?.repeatValue} weeks on ${ON}`;
            }
            return `Repeats weekly on ${ON}`;
          })
          .with({ type: 'EVERY_N_MONTHS', dayOfTheMonth: P.number }, () => {
            const ON = getNthDate(repeatCycle?.dayOfTheMonth!);
            if (toNumber(repeatCycle?.repeatValue, 0) > 1) {
              return `Repeats every ${repeatCycle?.repeatValue} months on ${ON}`;
            }
            return `Repeats monthly on the ${ON}`;
          })
          .with({ type: 'EVERY_N_YEARS', dayOfTheMonth: P.number }, () => {
            const ON = getNthDate(repeatCycle?.dayOfTheMonth!);
            const month = moment.months(repeatCycle?.monthOfTheYear!);
            if (toNumber(repeatCycle?.repeatValue, 0) > 1) {
              return `Repeats every ${repeatCycle?.repeatValue} years in ${month} on ${ON}`;
            }
            return `Repeats Yearly in ${month} on ${ON}`;
          })
          .otherwise(() => 'Repeats multiple times'),
        value: 'repeat',
      },
    ];
  }, [field.value, repeatCycle]);

  const isDisabled = useMemo(() => {
    if (!status || status === 'TRAINING_DRAFT') {
      return false;
    }
    return getValues('originalData.frequency') === 'repeat';
  }, [status, field.value]);

  return (
    <Flex gap={4}>
      <SelectInput
        ref={ref}
        options={frequencyOptions}
        {...field}
        disabled={field.disabled || isDisabled}
        value={field.value as string}
        onChange={field.onChange as Any}
      />

      {field.value === 'repeat' && (
        <IconButton
          size='lg'
          aria-label='edit'
          variant='ghost'
          isDisabled={field?.disabled}
          onClick={
            field?.disabled ? undefined : () => field.onChange?.('repeat')
          }
        >
          <EditIcon color='#6F767E' />
        </IconButton>
      )}
    </Flex>
  );
});

FrequencySelect.displayName = 'FrequencySelect';

export default FrequencySelect;
