import { VStack } from '@chakra-ui/react';
import React, { FC } from 'react';
import Loader from 'sub-components/Loader';
import { usePlayContext } from '../../store/context';
import {
  SopContent,
  QuizContent,
  MilestoneContent,
  FormContent,
  CardContent,
  LeaderboardScreen,
  EvaluationScreen,
} from './components';
import CertificateScreen from './components/CertificateScreen';

const MainSection: FC = () => {
  const { tpSessionData, selectedIndex, dataLoading, isRetake } =
    usePlayContext((state) => ({
      tpSessionData: state.tpSessionData,
      selectedIndex: state.selectedIndex,
      dataLoading: state.dataLoading,
      isRetake: state.isRetake,
    }));

  if (dataLoading) return <Loader />;
  if (!tpSessionData || !tpSessionData.contents.length) return null;

  const renderContent = () => {
    if (!tpSessionData) return null;

    // If we're past the regular content screens
    if (selectedIndex >= tpSessionData.contents.length) {
      // First additional screen is always LeaderboardScreen
      if (selectedIndex === tpSessionData.contents.length) {
        return <LeaderboardScreen />;
      }

      // Second screen - either evaluation or certificate
      if (selectedIndex === tpSessionData?.contents?.length + 1) {
        const hasQuiz = tpSessionData?.contents?.some(
          (content) => content?.type === 'quiz' && content?.hasScoring
        );

        if (hasQuiz || tpSessionData.hasSkillVerifier) {
          // If there's either a quiz with score or skill verifier, show evaluation
          return <EvaluationScreen />;
        } else {
          // If no quiz and no skill verifier, show certificate directly
          return <CertificateScreen />;
        }
      }

      return null;
    }

    const currentContent = tpSessionData.contents[selectedIndex];
    if (!currentContent) return null;

    switch (currentContent?.type) {
      case 'milestone':
        return <MilestoneContent />;
      case 'sop':
        return <SopContent content={currentContent} />;
      case 'quiz':
        return <QuizContent />;
      case 'form':
        return <FormContent />;
      case 'card':
        return <CardContent />;
      default:
        return null;
    }
  };

  return (
    <VStack
      bg='white'
      minH='calc(100vh - 199px)'
      maxH='calc(100vh - 199px)'
      borderTopLeftRadius='16px'
      borderTopRightRadius='16px'
      px='10%'
      py={4}
      overflowY='scroll'
      flex={1}
      position='relative'
    >
      {renderContent()}
    </VStack>
  );
};

MainSection.displayName =
  'displayName:sub-components/training-v2/play/components/MainSection/MainSection';

export default MainSection;
