import { useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  useModalContext,
  useToast,
} from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleExclamation } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HeaderColors } from 'configs';
import { FC } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { eventBus } from 'shared/eventEmit';
import CustomDuration from 'sub-components/training-v2/create/components/AssignPublishSection/Schedule/CustomDuration';
import { useDurationSelect } from 'sub-components/training-v2/create/components/AssignPublishSection/Schedule/duration';
import CustomEndDate from 'sub-components/training-v2/create/components/AssignPublishSection/Schedule/CustomEndDate';
import CustomStartDate from 'sub-components/training-v2/create/components/AssignPublishSection/Schedule/CustomStartDate';
import SelectInput from 'sub-components/training-v2/create/components/AssignPublishSection/Schedule/SelectInput';
import { IScheduleInput } from 'sub-components/training-v2/create';
import { ISchedulePayload } from 'sub-components/training-v2/edit';
import { BoxHeader } from 'ui-components';
import { REASSIGN_TP_USER } from '../../../Tracking/query/tracking.graphql';

export type IReAssignInfo = Pick<
  ISchedulePayload,
  'assignmentType' | 'deadlineDate' | 'startDate' | 'deadlineType' | 'duration'
>;

interface IProps {
  sessionId: string;
  userId: string;
  tpScheduleInfo: IReAssignInfo;
  refetchTpSession?: () => void;
}

export type IReAssignFormInput = Pick<
  IScheduleInput,
  | 'assignmentType'
  | 'deadlineDate'
  | 'startDate'
  | 'deadlineType'
  | 'duration'
  | 'repeatCycle'
>;

const deadlineOptions = [
  {
    label: 'No deadline',
    value: 'noDeadline',
  },
  {
    label: 'Custom end date',
    value: 'date',
  },
  {
    label: 'Custom duration',
    value: 'duration',
  },
];

const assignTypeOptions = [
  { label: 'Now', value: 'now' },
  { label: 'Custom start date', value: 'date' },
];

const mapDetailsPayload = (value: IReAssignFormInput): IReAssignInfo => {
  return {
    deadlineType: value.deadlineType,
    deadlineDate:
      value.deadlineType === 'date' ? value.deadlineDate!?.toString() : null,
    duration: value.deadlineType === 'duration' ? value.duration : null,

    assignmentType: value.assignmentType,
    startDate:
      value.assignmentType === 'date' ? value.startDate!?.toString() : null,
  };
};

const ReAssignTpForUser: FC<IProps> = ({
  userId,
  sessionId,
  tpScheduleInfo,
  refetchTpSession,
}) => {
  const methods = useForm<IReAssignFormInput>({
    defaultValues: {
      assignmentType: 'now',
      deadlineType: 'noDeadline',
    },
  });
  const { onClose } = useModalContext();
  const toast = useToast({
    duration: 2000,
    position: 'top-right',
    isClosable: true,
  });

  const [reAssignTpUser, { loading }] = useMutation(REASSIGN_TP_USER, {
    onCompleted: () => {
      toast({
        title: 'Training path re-assigned successfully',
        status: 'success',
      });
      refetchTpSession?.();
      eventBus.emit('Reassign_for_user_submmitted_from_evaluate_modal');
      setTimeout(onClose);
    },
  });

  const { handleSubmit, setValue, getValues } = methods;

  const durationSelect = useDurationSelect();

  const onSubmit = (data: IReAssignFormInput) => {
    //trigger the reassign event for loading reassign loading state
    eventBus.emit('Reassign_loader', { isReAssignTpLoading: loading });
    reAssignTpUser({
      variables: {
        input: {
          sessionId: sessionId,
          userId: userId,
          details: mapDetailsPayload(data),
        },
      },
    });
  };

  const onDeadlineChange = (onChange: CallableFunction) => {
    return function (value: string) {
      if (value === 'duration') {
        durationSelect({
          // repeatCycle: getValues('repeatCycle'),
          values: getValues('duration')!,
          onSaveChange: (newValue) => {
            setValue('duration', newValue);
            onChange(value);
          },
        });
      } else {
        setValue('duration', null);
        onChange(value);
      }
      setValue('deadlineDate', null);
    };
  };

  const onAssignedTypeChange = (data: any) => {
    setValue('assignmentType', data);
    setValue('startDate', null);
  };

  return (
    <Flex
      flexDirection='column'
      borderTop='1px solid #EFEFEF'
      marginTop={4}
      paddingY='1rem'
      height='600px'
    >
      <Flex flexDirection='column' gap={4}>
        {tpScheduleInfo.deadlineType === 'date' && (
          <Box background='#B1E5FC66' padding='16px 24px' borderRadius='10px'>
            <FontAwesomeIcon
              icon={faCircleExclamation as IconProp}
              fontSize='18px'
              color='#2A85FF'
            />

            <Box marginTop={1}>
              This path previously had a
              <Box as='span' fontWeight={700} color='#33383F' mx={1}>
                custom end date.
              </Box>
              Please select how you'd like to assign this path.
            </Box>
          </Box>
        )}

        {tpScheduleInfo.assignmentType === 'date' && (
          <Box background='#B1E5FC66' padding='16px 24px' borderRadius='10px'>
            <FontAwesomeIcon
              icon={faCircleExclamation as IconProp}
              fontSize='18px'
              color='#2A85FF'
            />

            <Box marginTop={1}>
              This path previously had a
              <Box as='span' fontWeight={700} color='#33383F' mx={1}>
                custom start date.
              </Box>
              Please select how you'd like to assign this path.
            </Box>
          </Box>
        )}
      </Flex>

      <BoxHeader title='Schedule' color={HeaderColors.Purple} margintop={5} />

      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ display: 'flex', height: '100%' }}
        >
          <Flex
            flexDirection='column'
            marginTop={8}
            width='100%'
            justifyContent='space-between'
          >
            <Flex flexDirection='column' gap={4}>
              <Flex align='center' gap={4}>
                <Box
                  alignSelf='start'
                  width='160px'
                  fontWeight='600'
                  color='#33383F'
                  lineHeight='48px'
                >
                  Completion deadline
                </Box>

                <Flex flex={3} gap={5}>
                  <Controller<IReAssignFormInput, 'deadlineType'>
                    name='deadlineType'
                    defaultValue='noDeadline'
                    rules={{
                      required: 'Completion deadline field is required',
                      deps: ['duration'],
                    }}
                    render={({ field, fieldState }) => (
                      <FormControl isInvalid={fieldState.invalid}>
                        <SelectInput
                          {...field}
                          options={deadlineOptions}
                          value={field.value as string}
                          onChange={onDeadlineChange(field.onChange)}
                        />
                        <FormErrorMessage>
                          {fieldState?.error?.message}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  />

                  <CustomDuration />
                  <CustomEndDate />
                </Flex>
              </Flex>

              <Flex align='center' gap={4}>
                <Box width='160px' fontWeight='600' color='#33383F'>
                  Assign training
                </Box>

                <Flex flex={3} gap={5}>
                  <Controller<IReAssignFormInput, 'assignmentType'>
                    name='assignmentType'
                    defaultValue='now'
                    rules={{
                      deps: ['deadlineType', 'deadlineDate'],
                    }}
                    render={({ field, fieldState }) => (
                      <FormControl isInvalid={fieldState.invalid}>
                        <SelectInput
                          options={assignTypeOptions}
                          {...field}
                          onChange={onAssignedTypeChange}
                        />
                        <FormErrorMessage>
                          {fieldState?.error?.message}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  />
                  <CustomStartDate />
                </Flex>
              </Flex>
            </Flex>

            <Box
              borderTop='1px solid #EFEFEF'
              justifyContent='flex-end'
              width='100%'
              display='flex'
              gap={4}
              paddingTop={4}
            >
              <Button onClick={onClose} variant='outline'>
                Cancel
              </Button>
              <Button
                type='submit'
                colorScheme='blue'
                width='fit-content'
                isLoading={loading}
                disabled={loading}
              >
                Save and assign
              </Button>
            </Box>
          </Flex>
        </form>
      </FormProvider>
    </Flex>
  );
};

ReAssignTpForUser.displayName =
  'displayName:sub-components/training-v2/dashboard/components/TrackPathDashboard/components/PathActions/ReAssignTpForUser.tsx';

export default ReAssignTpForUser;
