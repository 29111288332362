import { SearchInput } from 'atoms';
import React, { FC } from 'react';

interface IProps {
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
}

const Header: FC<IProps> = ({ setSearchQuery }) => {
  return (
    <div>
      <SearchInput
        disabled={true}
        size='md'
        hideShortcuts={true}
        placeholder={'Search training path by name'}
        onChange={(e) => setSearchQuery(e?.target?.value)}
      />
    </div>
  );
};

export default Header;
