import React, { forwardRef, ChangeEvent, useMemo } from 'react';
import {
  Box,
  Center,
  Checkbox,
  Divider,
  Flex,
  TabList,
  Tabs,
  Text,
  useControllableState,
} from '@chakra-ui/react';
import { HeaderColors } from 'configs';
import { BoxHeader } from 'ui-components';
import SearchInput from 'atoms/SearchInput';
import { ReactComponent as BackButton } from 'assets/images/back.svg';
import { PageNameType } from './MainContainer';
import { ClearFilterType } from './FilterContainer';
import EmptyState from './EmptyState';
import {
  FiltersType,
  useTrainingContext,
} from '../../../TrainingTableContainer';
import { ITypes } from '../Header';
import { TabItem } from 'atoms';
import { searchRegExp } from 'utils';
import { useSafeState } from 'hooks';
import { ReactComponent as RocketIcon } from '../../../../../../../assets/images/rocket.svg';
import { ReactComponent as ZapIcon } from '../../../../../../../assets/images/zap-light.svg';

interface IProps {
  filters: FiltersType;
  setPageName: React.Dispatch<React.SetStateAction<PageNameType>>;
  handleLocationsSelection?: (
    e: React.ChangeEvent<HTMLInputElement>,
    status: ITypes
  ) => void;
  value?: string[];
  onChange?: (value: string[]) => void;
  clearFilters: (type?: ClearFilterType) => void;
}

const LocationFilter = forwardRef<HTMLElement, IProps>(
  ({ setPageName, filters, handleLocationsSelection, clearFilters }, ref) => {
    const [tabValue, updateTabValue] = useControllableState<number>({
      defaultValue: 0,
    });
    const [locationsSearchText, setLocationsSearchText] = useSafeState('');
    const { selectedLocationsId, locations } = filters;
    const { setFilters, trainingContextData, setTrainingContextData } =
      useTrainingContext();

    // Prepare open and launching locations
    const { openLocation, launching } = useMemo(() => {
      return locations?.reduce(
        (acc, value) => {
          if (value.locStatus === 'development') {
            acc.launching.push(value);
          } else {
            acc.openLocation.push(value);
          }
          return acc;
        },
        {
          openLocation: [] as typeof locations,
          launching: [] as typeof locations,
        }
      );
    }, [locations]);

    // Compute location list based on active tab and search text
    const locationList = useMemo(() => {
      const data =
        tabValue === 2 ? launching : tabValue === 1 ? openLocation : locations;

      if (!locationsSearchText?.trim()) {
        return data;
      }
      const reg = searchRegExp(locationsSearchText, 'gi');
      return data.filter((value) => value.name?.match(reg));
    }, [locations, openLocation, launching, tabValue, locationsSearchText]);

    // "Select All" functionality synced with context
    const onSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
      const keys = locationList.map((val) => val.eid) || [];
      if (event?.target?.checked) {
        // Only push the location EIDs into the state (avoiding duplicates)
        setFilters((prev) => ({
          ...prev,
          selectedLocationsId: [
            ...new Set([...prev.selectedLocationsId, ...keys]),
          ],
          mergedFiltersArray: (prev?.locations ?? []).map((item) => ({
            ...item,
            customType: 'locations',
          })),
          totalFiltersTypes: prev.totalFiltersTypes + prev?.locations?.length,
        }));
        // Update the training context filtered data to include all track members

        setTrainingContextData((prevState) => ({
          ...prevState,
          filteredTrackMembersData: prevState.trackMembersData,
        }));
      } else {
        // Remove the location EIDs from the selected state if unchecking "Select All"
        setFilters((prev) => ({
          ...prev,
          selectedLocationsId: prev.selectedLocationsId.filter(
            (id) => !keys.includes(id)
          ),
          mergedFiltersArray: [],
          totalFiltersTypes: 0,
        }));
      }
    };

    // Determine if "Select All" should be checked or indeterminate
    const [allItemChecked, isIndeterminate] = useMemo(() => {
      const all = locationList.every((val) =>
        selectedLocationsId.includes(val.eid)
      );
      const some = locationList.some((val) =>
        selectedLocationsId.includes(val.eid)
      );
      return [all && locationList.length > 0, some && !all];
    }, [locationList, selectedLocationsId]);

    return (
      <>
        <Flex alignItems='center' mt='30px' justifyContent='space-between'>
          <BoxHeader title='Filters' color={HeaderColors.Purple} />
          <Text
            cursor='pointer'
            onClick={() => clearFilters('locations')}
            fontSize='14px'
            fontWeight={400}
            color={selectedLocationsId.length > 0 ? '#2A85FF' : '#6F767E'}
          >
            Clear Selection
          </Text>
        </Flex>

        <Flex mt='20px' gap={3} alignItems='center' marginBottom='1rem'>
          <BackButton
            width='1.2rem'
            onClick={() => setPageName('base')}
            cursor='pointer'
          />
          <Text style={{ fontWeight: 600, fontSize: 14 }}>By location</Text>
        </Flex>

        <SearchInput
          className='uploaded-by-search-field'
          value={locationsSearchText}
          onChange={(e) => setLocationsSearchText(e.target.value)}
          placeholder='Search by location name'
          hideShortcuts
        />
        <Divider mt='12px' mb='12px' />
        <Tabs w='30%' index={tabValue} onChange={updateTabValue} isLazy>
          <TabList
            borderBottom='1px solid'
            position='sticky'
            top='0'
            zIndex='1'
            bg='white'
          >
            <TabItem fontSize={'12px'} color={'#6F767E'} fontWeight={600}>
              All ({locations?.length})
            </TabItem>
            <TabItem fontSize={'12px'} color={'#6F767E'} fontWeight={600}>
              Open ({openLocation.length})
            </TabItem>
            <TabItem fontSize={'12px'} color={'#6F767E'} fontWeight={600}>
              Launching ({launching.length})
            </TabItem>
          </TabList>
        </Tabs>
        <Box minH='200px' maxH='400px' overflowY='auto'>
          <Flex paddingInline='12px' paddingY={4}>
            <Box
              flex={1}
              fontSize='13px'
              fontWeight='600'
              color='#6F767E'
              textTransform='uppercase'
            >
              Select all
            </Box>
            <Checkbox
              value='all'
              size='lg'
              isChecked={allItemChecked}
              isIndeterminate={isIndeterminate}
              onChange={onSelectAll}
            />
          </Flex>
          {locationList.length > 0 ? (
            locationList.map((item) => {
              const isInDev = item.locStatus === 'development';
              const Icon = isInDev ? RocketIcon : ZapIcon;
              return (
                <React.Fragment key={item.eid}>
                  <Flex
                    gap={3}
                    height='48px'
                    minHeight='48px'
                    alignItems='center'
                    paddingInline='12px'
                    borderRadius='12px'
                    _hover={{ bg: '#F4F4F3' }}
                  >
                    <Center
                      boxSize='24px'
                      borderRadius='5px'
                      bg={isInDev ? '#8E59FF' : '#83BF6E'}
                    >
                      <Icon width={12} height={12} />
                    </Center>
                    <Box
                      flex={1}
                      fontSize='14px'
                      fontWeight='400'
                      lineHeight={'21px'}
                      isTruncated
                      color='#1A1D1F'
                    >
                      {item.name}
                    </Box>
                    <Checkbox
                      value={item.eid}
                      isChecked={selectedLocationsId.includes(item.eid)}
                      size='lg'
                      onChange={(e) => handleLocationsSelection?.(e, item)}
                    />
                  </Flex>
                  <Divider />
                </React.Fragment>
              );
            })
          ) : (
            <EmptyState view='statuses' />
          )}
        </Box>
      </>
    );
  }
);
LocationFilter.displayName =
  'sub-components/training-v2/dashboard/components/TrackPathDashboard/bottomSection/Filter/LocationFilter.tsx';
export default LocationFilter;
