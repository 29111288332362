import { useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Flex,
  Text,
  useModalContext,
  useToast,
} from '@chakra-ui/react';
import { FC } from 'react';
import {
  LocationPrelaunchResp,
  MOVE_LOCATION_TO_PRELAUNCH,
} from 'sub-components/LocationLaunchDashboard/LiveModal/live-modal.graphql';

interface IProps {
  locationId: string;
  onPreLaunchModalClose: () => void;
  locationName: string;
}

const MoveToPrelaunchModal: FC<IProps> = ({
  locationId,
  onPreLaunchModalClose,
  locationName,
}) => {
  const { onClose } = useModalContext();
  const toast = useToast({
    isClosable: true,
    duration: 3000,
    position: 'top-right',
  });

  const [moveToPrelaunch, { loading }] = useMutation<LocationPrelaunchResp>(
    MOVE_LOCATION_TO_PRELAUNCH,
    {
      onCompleted: (data) => {
        toast({
          status: 'success',
          title: 'Success',
          description: 'Location moved to Pre-launch',
        });
        onPreLaunchModalClose();
        onClose();
      },
      onError: () => {
        toast({
          status: 'error',
          title: 'Error',
          description: 'Error in moving location to Pre-launch',
        });
        onClose();
      },
    }
  );

  return (
    <Flex flexDir='column' gap={3} marginTop='2rem'>
      <Text fontWeight={500} fontSize='14px' color='#00000080'>
        <Box
          as='span'
          fontWeight='extrabold'
          color='#000000'
        >{`${locationName} `}</Box>
        will lose all its progress on the launcher tasks and all members will be
        deactivated. You can activate them again when you’re ready to launch the
        location.
      </Text>
      <Flex gap={4} marginTop='1rem'>
        <Button
          background='white'
          color='#1A1D1F'
          borderRadius='10px'
          variant='outline'
          padding='12px 20px'
          flex={1}
          height='fit-content'
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          colorScheme='blue'
          borderRadius='10px'
          padding='12px 20px'
          height='fit-content'
          flex={1}
          isLoading={loading}
          disabled={loading}
          onClick={() =>
            moveToPrelaunch({
              variables: {
                locationId: locationId,
              },
            })
          }
        >
          Move location to pre-launch
        </Button>
      </Flex>
    </Flex>
  );
};

MoveToPrelaunchModal.displayName =
  'displayName:pages/Locations/AddLocation/MoveToPrelaunchModal.tsx';

export default MoveToPrelaunchModal;
