import React, { FC, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { Flex, Text, Tooltip } from '@chakra-ui/react';

import { Trans, useTranslation } from 'react-i18next';

import { IFormInput } from '../task.types';
import { toArray } from '../../../../utils/utils';

const Item: FC<{
  transKey: 'training:chapters' | 'form:forms';
  names: string[];
  length: number;
}> = ({ transKey, names, length = 0 }) => {
  const { t } = useTranslation(['training', 'form']);

  if (names.length === 0) {
    return null;
  }

  return (
    <Flex flex={1} align='center' gap={1}>
      <Text color='#33383f' fontWeight='600'>
        {t(transKey)}:
      </Text>

      <Tooltip label={names} hasArrow>
        <Text noOfLines={1} cursor='pointer'>
          {names}
        </Text>
      </Tooltip>

      {length > 0 && (
        <Text whiteSpace='nowrap'>
          <Trans
            t={t}
            i18nKey='training:count_more'
            values={{ value: length }}
          />
        </Text>
      )}
    </Flex>
  );
};

export const useSpiltArray = <T extends string[]>(data: T, count = 1) =>
  useMemo(() => {
    const _temp = toArray(data).slice();

    const tName = _temp.splice(0, count);

    return [tName, _temp];
  }, [data]);

interface IProps {}

const TaskStepperDescription: FC<IProps> = () => {
  const contents = useWatch<IFormInput, 'contents'>({
    name: 'contents',
  });

  const { sop, form } = useMemo(() => {
    return toArray(contents).reduce(
      (previousValue, currentValue) => {
        switch (currentValue.type) {
          case 'sop':
            previousValue['sop'].push(currentValue.title);
            break;
          case 'form':
            previousValue['form'].push(currentValue.title);
            break;
        }

        return previousValue;
      },
      { sop: [], form: [] } as Record<'sop' | 'form', string[]>
    );
  }, [contents]);

  const [sopNames, sopMore] = useSpiltArray(sop);
  const [formNames, formMore] = useSpiltArray(form);

  return (
    <Flex gap={2}>
      <Item
        transKey='training:chapters'
        names={sopNames}
        length={sopMore.length}
      />

      <Item transKey='form:forms' names={formNames} length={formMore.length} />
    </Flex>
  );
};

export default TaskStepperDescription;
