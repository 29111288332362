import React, { FC } from 'react';
import { Center, Flex } from '@chakra-ui/react';
import { useApolloClient } from '@apollo/client';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { cloneDeep } from '@apollo/client/utilities';
import { useSafeState, useSearchParam } from '../../../hooks';
import { useRouteState } from '../../../routes';
import Loader from '../../Loader';
import { TFormInput } from '../create';
import { Container } from '../create/components';
import EditHeading from './EditHeading';
import { GET_TRAINING, TrainingResponse } from './edit-training.graphql';
import { mapToFormData } from './mapToFormData';

const EditTraining: FC = () => {
  const client = useApolloClient();
  const history = useHistory();
  const [isLoading, updateLoadingState] = useSafeState(true);

  const params = useParams<{ trainingId: string }>();

  const searchParam = useSearchParam<{ action?: 'reassign' }>();
  const { updateState } = useRouteState({ currentEditPage: 0 });

  const methods = useForm<TFormInput>({
    defaultValues: async () => {
      const response = await client.query<TrainingResponse>({
        query: GET_TRAINING,
        fetchPolicy: 'network-only',
        variables: { eid: params?.trainingId },
      });

      if (response?.error) {
        history.goBack();
        return Promise.resolve<TFormInput>({} as TFormInput);
      }

      // TODO if training is deleted show alert
      // TODO if training is terminated show alert

      setTimeout(updateLoadingState, 100, false);
      const mappedData = mapToFormData(
        response?.data?.TpById,
        searchParam?.action
      );
      updateState({ currentEditPage: mappedData.currentEditPage });
      return {
        ...mappedData,
        originalData: cloneDeep(mappedData),
      };
    },
    reValidateMode: 'onChange',
    mode: 'all',
  });

  if (isLoading) {
    return (
      <Center boxSize='full'>
        <Loader size='xl' />
      </Center>
    );
  }

  return (
    <FormProvider {...methods}>
      <Flex flexDir='column' gap={4}>
        <EditHeading />
        <Container />
      </Flex>
    </FormProvider>
  );
};

EditTraining.displayName =
  'displayName:sub-components/training-v2/edit/EditTraining';

export default React.memo(EditTraining);
