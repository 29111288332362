import { OperationVariables, QueryLazyOptions } from '@apollo/client';
import { Flex } from '@chakra-ui/react';
import React, { FC } from 'react';
import Loader from 'sub-components/Loader';
import { PageInfo } from 'types';
import { TExcueteCallbackVars } from '../GenericFilter/types/filter-types';
import { Header, MyTrainingTable } from './bottomSection/';
import EmptyState from './EmptyState';
import {
  UserTrainingPathSessionsType,
  TrainingData,
} from './types/myTraining.types';

interface IProps {
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  trainingPathData: TrainingData[] | UserTrainingPathSessionsType[];
  getTrainingPathSessionData: (
    options?: QueryLazyOptions<OperationVariables> | undefined
  ) => void;
  trainingPathFilters: TExcueteCallbackVars;
  tableLoader: boolean;
  trainingCategory: any;
  trainingCategoriesLoader: boolean;
  onPageChange?: (page: number) => void;
  pageInfo: PageInfo;
}

const BottomSection: FC<IProps> = ({
  setSearchQuery,
  trainingPathData,
  getTrainingPathSessionData,
  trainingPathFilters,
  tableLoader,
  trainingCategory,
  trainingCategoriesLoader,
  // onPageChange,
  pageInfo,
}) => {
  return (
    <Flex id='MyTrainingTable' gap={4} flexDir={'column'} width={'100%'}>
      <Flex justifyContent={'flex-end'} width={'100%'}>
        <Header
          setSearchQuery={setSearchQuery}
          getTrainingPathSessionData={getTrainingPathSessionData}
          trainingPathFilters={trainingPathFilters}
        />
      </Flex>
      <Flex width={'100%'}>
        {/* {tableLoader || trainingCategoriesLoader ? (
          <Flex
            width={'100%'}
            height={'50vh'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Loader />
          </Flex>
        ) : trainingPathData?.length > 0 ? (
          <MyTrainingTable
            getTrainingPathSessionData={getTrainingPathSessionData}
            trainingPathFilters={trainingPathFilters}
            trainingPathData={trainingPathData}
            trainingCategory={trainingCategory}
            loader={tableLoader}
            onPageChange={onPageChange}
            pageInfo={pageInfo}
          />
        ) : (
          <EmptyState setSearchQuery={setSearchQuery} />
        )} */}

        <MyTrainingTable
          trainingPathData={trainingPathData}
          trainingCategory={trainingCategory}
          loader={tableLoader}
          // onPageChange={onPageChange}
          pageInfo={pageInfo}
        />

        {/* {trainingPathData?.length > 0 ? (
          <MyTrainingTable
            trainingPathData={trainingPathData}
            trainingCategory={trainingCategory}
            loader={tableLoader}
          />
        ) : (
          <Flex
            width={'100%'}
            height={'50vh'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Loader />
          </Flex>
        )} */}
      </Flex>
    </Flex>
  );
};

BottomSection.displayName =
  'displayName:sub-components/training-v2/dashboard/components/myTrainings/BottomSection';

export default BottomSection;
