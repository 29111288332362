import React, { createContext, FC, useContext, useRef } from 'react';

import { createPlayStore, PlayAction } from './play.store';
import { useStore } from '../../../../zustandStore';
import { useService } from '../layers';

type PlayStore = ReturnType<typeof createPlayStore>;

const PlayContext = createContext<PlayStore | null>(null);

const PlayProvider: FC = ({ children }) => {
  const service = useService();
  const storeRef = useRef<PlayStore>();
  if (!storeRef.current) {
    storeRef.current = createPlayStore(service);
  }

  return (
    <PlayContext.Provider value={storeRef.current}>
      {children}
    </PlayContext.Provider>
  );
};

function usePlayContext<T>(selector: (state: PlayAction) => T): T {
  const store = useContext(PlayContext);
  if (!store) throw new Error('Missing PlayContext.Provider in the tree');
  return useStore(store, selector);
}

export { PlayProvider, usePlayContext };
