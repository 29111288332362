import React, { FC, useMemo } from 'react';
import {
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  VStack,
  Box,
  Text,
  Flex,
} from '@chakra-ui/react';
import { FlexCard } from 'atoms';
import { BoxHeader } from 'ui-components';
import { HeaderColors } from 'configs';
import {
  Attrition,
  AuditScore,
  CustomTab,
  OverallScore,
  RevenueAnnumPanel,
  RoyaltyPanel,
  TaskComplete,
  TrainingCompletionPanel,
} from './components';
import { useUserEntity } from 'hooks';
import moment from 'moment';

interface TabConfig {
  label: string;
  minWidth: string;
  hasNewBadge?: boolean;
  infoText?: string;
}

const LocationLeaderboard: FC = () => {
  const auditsEnabled = useUserEntity((entity) => entity?.features?.audit);
  const tabsConfig = useMemo((): TabConfig[] => {
    return [
      {
        label: 'Overall Score',
        minWidth: '176px',
        hasNewBadge: false,
        infoText:
          'The average of all scores for a location, reflecting overall performance.',
      },
      {
        label: 'Task Completion',
        minWidth: '176px',

        infoText:
          'The percentage of tasks completed by the location out of the total assigned.',
      },
      // {
      //   label: 'Revenue/annum',
      //   minWidth: '176px',
      //   infoText: 'The total revenue generated by the location in a year.',
      // },
      {
        label: 'Attrition',
        minWidth: '176px',
        infoText:
          'The percentage of employees leaving the franchise per location.',
      },
      // {
      //   label: 'Royalty',
      //   minWidth: '85px',
      //   infoText: 'The total royalty amount contributed by the location.',
      // },
      {
        label: 'Training Completion',
        minWidth: '174px',
        infoText:
          'The percentage of training sessions completed by the location out of the total assigned.',
      },
      ...(auditsEnabled
        ? [
            {
              label: 'Audits Score',
              minWidth: '124px',
              infoText:
                'The average score of all audits conducted during the month.',
            },
          ]
        : []),
    ];
  }, [auditsEnabled]);
  return (
    <FlexCard className='location-leaderboard-container' w='full'>
      <VStack spacing={6} align='start' w='full'>
        <Flex flexDir='column' gap={1}>
          <BoxHeader title='Location Leaderboard' color={HeaderColors.Yellow} />
          <Box>
            <Text
              color={'#6F767E'}
              ml={'32px'}
              fontWeight={500}
              lineHeight={'20px'}
            >{`Data as of ${moment().format('DD MMM YYYY')}`}</Text>
          </Box>
        </Flex>
        <Tabs variant='unstyled' w='full'>
          <TabList flexWrap='wrap' gap='.5rem'>
            {tabsConfig.map((tab, index) => (
              <CustomTab
                key={index}
                label={tab.label}
                minWidth={tab.minWidth}
                hasNewBadge={tab.hasNewBadge}
                infoText={tab.infoText}
              />
            ))}
          </TabList>

          <TabPanels>
            {tabsConfig.map((tab, index) => (
              <TabPanel key={index}>
                {tab.label === 'Overall Score' && <OverallScore />}
                {tab.label === 'Task Completion' && <TaskComplete />}
                {tab.label === 'Revenue/annum' && <RevenueAnnumPanel />}
                {tab.label === 'Attrition' && <Attrition />}
                {tab.label === 'Royalty' && <RoyaltyPanel />}
                {tab.label === 'Training Completion' && (
                  <TrainingCompletionPanel />
                )}
                {tab.label === 'Audits Score' && <AuditScore />}
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </VStack>
    </FlexCard>
  );
};

export default LocationLeaderboard;
