import React, { forwardRef, useMemo } from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import {
  Flex,
  Radio,
  RadioGroup,
  useFormControlContext,
} from '@chakra-ui/react';
import { LocationFieldEntity } from 'sop-commons/src/client';

import { FormInput } from 'atoms';
import Dropdown, { SelectOption } from 'atoms/Dropdown';

import { IFormInput } from '../AddLocation/add-location.types';
import { UploadDocumentInput } from './UploadDocument';
import DateInput from './DateInput';
import DatePickerCustom from 'ui-components/DatePicker/DatePicker';

interface IProps extends ControllerRenderProps<IFormInput, any> {
  fieldType: LocationFieldEntity['fieldType'];
  options?: string[];
}

const OtherInputField = forwardRef<never, IProps>(
  ({ fieldType, options, ...field }, ref) => {
    const selectOption = useMemo((): SelectOption[] => {
      if (options?.length) {
        return options.map((opt) => ({
          value: opt,
          label: opt,
        }));
      }

      return [];
    }, [options]);

    const context = useFormControlContext();

    switch (fieldType) {
      case 'date':
        return (
          <DatePickerCustom
            disabled={context.isDisabled}
            minDate={new Date(1971, 0, 1)}
            disableLeftIcon
            colorScheme='gray'
            {...field}
          />
        );
      case 'document':
        return (
          <UploadDocumentInput
            isDisabled={context.isDisabled}
            {...field}
            ref={ref}
          />
        );
      case 'singleChoice':
        return (
          <RadioGroup {...field} ref={ref}>
            <Flex wrap='wrap' gap='8px 12px'>
              {selectOption.map((it, index) => (
                <Radio key={index} value={it.value}>
                  {it.label}
                </Radio>
              ))}
            </Flex>
          </RadioGroup>
        );
      case 'dropDown':
        return (
          <Dropdown
            size='lg'
            options={selectOption}
            value={field.value}
            onChange={field.onChange}
            ref={ref}
          />
        );
      case 'text':
        return <FormInput size='lg' {...field} ref={ref} />;
      default:
        return <FormInput size='lg' {...field} ref={ref} />;
    }
  }
);

OtherInputField.displayName = 'Other Input Field';
export default OtherInputField;
