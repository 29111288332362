import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { useWatch } from 'react-hook-form';
import { QuizPreview } from './preview';
import { useQuizPreviewData } from './preview/useQuizPreviewData';
import QuizPreviewHeader from './preview/QuizPreviewHeader';
import SelectQuizToPreview from './preview/SelectQuizToPreview';

const QuizPreviewContainer: FC = () => {
  const previewData = useQuizPreviewData();
  const [title, questionList] = useWatch({ name: ['title', 'questions'] });
  const selectedQuestion = previewData?.question;

  if (selectedQuestion) {
    // @ts-ignore
    const questLength = selectedQuestion?.isExample ? 1 : questionList?.length;
    const selIndex = previewData?.questionIndex;

    const isEqual = questLength === selIndex;

    return (
      <Flex flex={1} flexDir='column' paddingTop='37px' overflow='hidden'>
        <QuizPreviewHeader
          title={title || 'Quiz name'}
          questionLength={isEqual ? 1 : questLength}
          selectedIndex={isEqual ? 0 : selIndex}
        />
        <QuizPreview previewData={selectedQuestion} />
      </Flex>
    );
  }

  return <SelectQuizToPreview />;
};

export default QuizPreviewContainer;
