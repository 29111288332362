import React, { ChangeEvent, FC, useContext, useState } from 'react';
import {
  Flex,
  FormControl,
  FormLabel,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Switch,
} from '@chakra-ui/react';

import { BoxHeader } from '../../../../../ui-components';
import CategoryListContainer from './CategoryListContainer';
import TriggerButton from './TriggerButton';
import FormDataContext from 'pages/forms/FormStore/form-data-context';
import { useLazyQuery } from '@apollo/client';
import { FORMS_PAGINATION } from '../form-internal.graphql';
import DashboardDataContext from 'sub-components/DashboardContainer/DashboardStore/DashboardData/dashboard-data-context';
import { eventBus } from 'shared/eventEmit';

interface IProps {
  category: string[];
  onCategoryChange: (category: string[]) => void;
}

const FormCategoryFilter: FC<IProps> = ({ category, onCategoryChange }) => {
  const formCtx = useContext(FormDataContext);
  const dashboardCtx = useContext(DashboardDataContext);
  const [isActiveFilter, setIsActiveFilter] = useState(false);

  const [getFormsListHandler] = useLazyQuery(FORMS_PAGINATION, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      formCtx.updateFormsListDataHandler(data?.FormsPagination);
    },
  });

  const deleteNavigateHandler = () => {
    let pageInfo = formCtx?.formsListData?.pageInfo;
    if (pageInfo.itemCount % 10 === 1 && pageInfo.itemCount > 10) {
      if (
        pageInfo.currentPage > 1 &&
        pageInfo.currentPage === pageInfo.pageCount
      ) {
        return pageInfo.currentPage - 1;
      }
    }
    return pageInfo.currentPage;
  };

  const refetchFormList = () => {
    let newPage = deleteNavigateHandler();
    let navigationPersistData = dashboardCtx?.navigationPersistData;
    let formData = navigationPersistData?.forms;
    dashboardCtx?.navigationPersistDataHandler({
      ...navigationPersistData,
      forms: { ...formData, selectedPage: newPage },
    });

    const filter: Record<string, unknown> = {
      status: !isActiveFilter ? 'active' : undefined,
    };

    if (category && category.length > 0) {
      filter.categoryId = category;
    }

    getFormsListHandler({
      variables: {
        page: newPage,
        perPage: 10,
        sort: 'CREATEDAT_DESC',
        filter: filter,
      },
    });
  };
  const filterStatus = () => {
    eventBus.emit('active_filter_form', isActiveFilter);
  };

  const handleSwitchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setIsActiveFilter(checked);
    refetchFormList();
    filterStatus();
  };

  return (
    <Popover placement='bottom-end' isLazy lazyBehavior='unmount'>
      <PopoverTrigger>
        <TriggerButton
          category={category}
          onCategoryChange={onCategoryChange}
        />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverHeader borderBottomWidth='0'>
          <Flex justify='space-between' alignItems='center'>
            <BoxHeader color='#CABDFF' title='Filters' fontSize='18px' />
            <PopoverCloseButton
              pos='relative'
              borderRadius='full'
              top='0'
              right='0'
            />
          </Flex>
        </PopoverHeader>
        <PopoverBody>
          <FormControl
            display='flex'
            alignItems='center'
            gap={'8px'}
            mb={'20px'}
          >
            <Switch
              id='active-forms'
              isChecked={isActiveFilter}
              onChange={handleSwitchChange}
            />
            <FormLabel
              htmlFor='active-forms'
              mb='0'
              fontWeight={600}
              fontSize={'14px'}
              color={'#6F767E'}
            >
              Don’t show inactive forms
            </FormLabel>
          </FormControl>

          <CategoryListContainer
            category={category}
            onCategoryChange={onCategoryChange}
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

FormCategoryFilter.displayName =
  'displayName:pages/forms/modules/FormListInternal/form-filter/FormCategoryFilter';

export default FormCategoryFilter;
