import React, { FC } from 'react';
import { DashboardContainer } from 'sub-components';
import { CreateTraining } from 'sub-components/training-v2';

const CreateTrainingPath: FC = () => {
  return (
    <DashboardContainer>
      <CreateTraining />
    </DashboardContainer>
  );
};

export default CreateTrainingPath;
