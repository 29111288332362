import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { TrainingContType } from '../../../../../../types';
import { toArray } from '../../../../../../utils';
import { ContentItemInput } from '../../training-input.types';
import { MobilePreviewContainer } from '../common';
import QuizResourceContainer from './QuizResourceContainer';
import QuizFooter from './QuizFooter';
import { QuizFormInput } from './quiz-resource.graphql';
import QuizPreviewContainer from './QuizPreviewContainer';

const defaultValues = {
  type: TrainingContType.QUIZ,
  title: '',
  questions: [],
} as unknown as QuizFormInput;

export interface QResourceProps {
  initialValues?: ContentItemInput;
  onAddClick?: (values: unknown[]) => void;
}

const QuizResourceContent: FC<QResourceProps> = ({
  onAddClick,
  initialValues,
}) => {
  const methods = useForm<QuizFormInput>({
    defaultValues: initialValues || Object.assign({}, defaultValues),
  });

  const _onAddClick = (values: unknown) => {
    return onAddClick?.(toArray(values));
  };

  return (
    <FormProvider {...methods}>
      <Flex flex={1} flexDir='column' width='100%'>
        <Flex
          flex={1}
          gap='30px'
          marginX={7}
          // align='start'
          maxHeight='calc(100% - 61px)'
        >
          <QuizResourceContainer />

          <MobilePreviewContainer>
            <QuizPreviewContainer />
          </MobilePreviewContainer>
        </Flex>

        <QuizFooter onAddClick={_onAddClick} />
      </Flex>
    </FormProvider>
  );
};

export default QuizResourceContent;
