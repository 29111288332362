import React, { FC } from 'react';
import { Image, Flex, IconButton } from '@chakra-ui/react';

import { ReactComponent as DeleteIcon } from 'assets/images/deleteBin.svg';

interface IProps {
  value: string;
  onRemove?: () => void;
}

const AddImageView: FC<IProps> = ({ value, children, onRemove }) => {
  if (!value) return <React.Fragment>{children}</React.Fragment>;

  return (
    <Flex
      boxSize='full'
      flexDir='column'
      gap='2px'
      borderRadius='12px'
      bgColor='#EFEFEF'
      align='center'
      position='relative'
      maxHeight='calc(100% - 56px)'
    >
      <Image src={value} height='100%' objectFit='contain' />

      <IconButton
        position='absolute'
        right='10px'
        top='10px'
        aria-label='delete-image'
        icon={<DeleteIcon width={24} height={24} color='#272B30' />}
        bgColor='#FCFCFC'
        onClick={onRemove}
      />
    </Flex>
  );
};

export default AddImageView;
