import { Checkbox, Flex, Text } from '@chakra-ui/react';
import React, { ChangeEvent, FC, forwardRef } from 'react';
import { ITypes } from '../bottomSection/Header';
import EmptyState from './EmptyState';

interface IProps {
  filters: any;
  handleJobSelection?: (
    e: React.ChangeEvent<HTMLInputElement>,
    status: ITypes
  ) => void;
  value?: string[]; // Selected job names
  onChange?: (value: string[]) => void;
  clearFilters?: () => void;
}

const Joblist = forwardRef<HTMLElement, IProps>(
  (
    { filters, handleJobSelection, value = [], onChange, clearFilters },
    ref
  ) => {
    const { jobs } = filters || {};

    const filteredCount = jobs?.length ?? 0;

    const handleCheckboxChange = (
      e: ChangeEvent<HTMLInputElement>,
      job: any
    ) => {
      const newValue = e.target.checked
        ? [...value, job.name]
        : value.filter((id) => id !== job.name);
      onChange?.(newValue);
      handleJobSelection?.(e, job);
    };

    return (
      <>
        {filteredCount > 0 ? (
          <Flex maxHeight='300px' flexDir='column' overflowY='auto'>
            {jobs?.map((job: any) => (
              <Flex
                key={job?.eid}
                gap={4}
                alignItems='center'
                padding='0.5rem 0px'
              >
                <Checkbox
                  value={job?.name}
                  isChecked={value.includes(job?.name)}
                  onChange={(e) => handleCheckboxChange(e, job)}
                >
                  <Flex alignItems='center' gap={4}>
                    <Text fontSize='14px' fontWeight={400}>
                      {job?.name}
                    </Text>
                  </Flex>
                </Checkbox>
              </Flex>
            ))}
          </Flex>
        ) : (
          <EmptyState view='statuses' />
        )}
      </>
    );
  }
);

export default Joblist;
