import { FC, useEffect, useState } from 'react';
import { Flex } from '@chakra-ui/react';

import {
  PerformanceInfo,
  ProgressTrackerGraph,
  Leaderboard,
} from './locationOwner';
import { useLazyQuery } from '@apollo/client';
import { GET_TP_OVERALL_ANALYTICS } from 'sub-components/training-v2/shared/query/dashboard.graphql';
import { IOverallAnalytics } from 'sub-components/training-v2/shared/types';
import useCombinedStore from 'zustandStore/store';

const LocationOwnerContainer: FC = () => {
  const { locationIdArray } = useCombinedStore();

  const [overallAnalyticsData, setOverallAnalyticsData] =
    useState<IOverallAnalytics>({});
  const [getOverallAnalytics, { loading: overallAnalyticsLoader }] =
    useLazyQuery(GET_TP_OVERALL_ANALYTICS, {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setOverallAnalyticsData(data?.TpOverallAnalytics);
      },
    });

  useEffect(() => {
    getOverallAnalytics({
      variables: {
        locations: locationIdArray,
      },
    });
  }, [locationIdArray]);

  return (
    <Flex height={'100%'} width={'100%'} gap={2}>
      <Flex gap={2} flexDir={'column'} width={'50%'}>
        <PerformanceInfo
          overallAnalyticsLoader={overallAnalyticsLoader}
          overallAnalyticsData={overallAnalyticsData}
        />
        <ProgressTrackerGraph overallAnalyticsData={overallAnalyticsData} />
      </Flex>
      <Flex width={'50%'}>
        <Leaderboard overallAnalyticsLoader={overallAnalyticsLoader} />
      </Flex>
    </Flex>
  );
};

LocationOwnerContainer.displayName =
  'packages/sop-web/src/sub-components/training-v2/dashboard/components/LocationOwnerContainer';
export default LocationOwnerContainer;
