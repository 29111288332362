import { useMutation } from '@apollo/client';
import { AmplitudeEvent, deployEvent } from '../../../../../shared';
import { MARK_LAUNCHER_TASK_UNDONE } from './state-drawer.graphql';
import {
  MarkTaskUndoneResponse,
  MarkTaskUndoneVariables,
} from './state-drawer.types';
import {
  getDueDate,
  getTaskDuration,
  TaskToComplete,
} from './useMarkAsComplete';

interface IProps {
  stepId: string;
  taskId: string;
  launchId: string;
  formResponseId?: string;
  task?: TaskToComplete;
}

export const useMarkAsUndone = () => {
  const [markAsUndone, { loading: markAsUndoneLoading }] = useMutation<
    MarkTaskUndoneResponse,
    MarkTaskUndoneVariables
  >(MARK_LAUNCHER_TASK_UNDONE);

  const handleMarkIncomplete = async ({
    launchId,
    stepId,
    taskId,
    task,
  }: IProps) => {
    const res = await markAsUndone({
      variables: {
        launchId: launchId,
        taskId: taskId,
        ...(stepId ? { stepId: stepId } : {}),
      },
    });

    if (res.data?.MarkLauncherTaskUndone && task) {
      deployEvent(AmplitudeEvent.LAUNCHER_MARK_TASK_UNFINISHED, {
        task_id: taskId,
        task_title: task?.title,
        phase_title: task?.phaseName,
        task_duration: getTaskDuration(task),
        due_date: getDueDate(task?.dueDate),
      });
    }

    return res;
  };

  return { markAsUndone: handleMarkIncomplete, markAsUndoneLoading };
};
