import { MutableRefObject } from 'react';
import { ApolloClient } from '@apollo/client';
import { toArray } from '../../../../utils';

import {
  LAUNCHER_TASK_CATEGORY,
  TaskCategoryResponse,
} from './launcher-config.graphql';
import {
  LauncherConfigStore,
  LauncherContentInput,
} from './launcher-config.store';
import { LauncherConfig } from './launcher-config.fragment';
import { getAssignedUser } from '../../../../pages/launcher/config-editor';

export const getLauncherConfig = (config: LauncherConfig) => {
  const locations = toArray(config?.locationLaunches).filter((l) => !l.isLive);
  return {
    eid: config.eid,
    title: config.title,
    contents: toArray(config.contents).map((content) => ({
      ...content,
      tasks: toArray(content.tasks).map((task) => {
        if (content.assignedType && !task.assignedType) {
          return {
            ...task,
            assignedType: content.assignedType,
            assignedUser: getAssignedUser(content),
          };
        }

        return task;
      }),
    })),
    locations: locations.length,
    locationLaunches: locations,
  };
};

export function fetchTaskCategories(
  client: ApolloClient<object>,
  storeRef: MutableRefObject<LauncherConfigStore | undefined>
): void {
  client
    .query<TaskCategoryResponse>({
      query: LAUNCHER_TASK_CATEGORY,
      fetchPolicy: 'network-only',
    })
    .then((response) => {
      if (response.data) {
        storeRef.current?.setState((state) => {
          state.taskCategory = toArray(
            response.data.EntityLauncherTaskCategories
          ).reduce((acc, value) => {
            acc[value.eid] = value.name;
            return acc;
          }, {} as Record<string, string>);
        });
      }
      if (response.error) {
        // eslint-disable-next-line no-console
        console.log(response.error);
        // TODO Add error handler
      }
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.log(err);
      // TODO Add error handler
    });
}

export const mapConfigToObject = (contents?: LauncherContentInput[]) => {
  return toArray(contents).reduce((acc, phase) => {
    acc[phase.eid] = phase;

    const tasks = toArray(phase.tasks);

    for (const task of tasks) {
      acc[task.eid] = task;
    }

    return acc;
  }, {} as Record<string, any>);
};
