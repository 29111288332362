import { FormControl, FormErrorMessage } from '@chakra-ui/react';
import moment from 'moment';
import { FC, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import DatePickerCustom from 'ui-components/DatePicker/DatePicker';
import { IFormInput } from '../../../audit-schedule.types';

const Yearly: FC = () => {
  const { minDate, maxDate } = useMemo(() => {
    return {
      minDate: moment().startOf('year').toDate(),
      maxDate: moment().endOf('year').toDate(),
    };
  }, []);

  const { getValues } = useFormContext<IFormInput>();

  return (
    <>
      <Controller
        name='repeatDetails.yearly'
        rules={{
          required: 'Please select yearly repeat period',
        }}
        render={({ field, fieldState }) => {
          return (
            <FormControl display='flex' gap={4} isInvalid={!!fieldState.error}>
              <DatePickerCustom
                placeholder=''
                onChange={(date) => {
                  field.onChange(date);
                }}
                value={getValues('repeatDetails.yearly')}
                minDate={minDate}
                maxDate={maxDate}
                format='DD MMMM'
              />
              <FormErrorMessage mt='0'>
                {fieldState.error?.message}
              </FormErrorMessage>
            </FormControl>
          );
        }}
      />
      {/* <Controller<IFormInput, 'repeatDetails.yearly'>
        name='repeatDetails.yearly'
        rules={{
          required: 'Please select yearly repeat period',
        }}
        render={({ field, fieldState }) => {
          return (
            <FormControl display='flex' gap={4} isInvalid={!!fieldState.error}>
              <SimpleDatePicker
                disabled={field.disabled}
                isSelected={!!field.value}
                onChange={field.onChange}
                selectedValue={field.value || null}
                placeholderText=''
                minDate={minDate}
                maxDate={maxDate}
                dateFormat='d MMMM'
              />
              <FormErrorMessage mt='0'>
                {fieldState.error?.message}
              </FormErrorMessage>
            </FormControl>
          );
        }}
      /> */}
    </>
  );
};

export default Yearly;
