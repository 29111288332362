import { FC, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useService } from '../../sub-components/training-v2/play/layers';

const NavigateCompletedAssignee: FC = () => {
  const { certificateId, sessionId, trainingId } = useParams<{
    certificateId: string;
    sessionId: string;
    trainingId: string;
  }>();
  const {
    downloadCertificateFlag: { updateDownloadCertificateFlag },
  } = useService();
  const history = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    let isCancelled: boolean = false;
    const downloadCertificate = async (
      certificateId: string,
      sessionId: string
    ) => {
      if (process.env.REACT_APP_OPS_ENDPOINT) {
        if (sessionId && certificateId) {
          window.open(
            `${process.env.REACT_APP_OPS_ENDPOINT}/tp/certify/${certificateId}`,
            '_parent'
          );
          try {
            await updateDownloadCertificateFlag({
              variables: {
                sessionId: sessionId,
              },
            });
          } catch (error) {
            if (!isCancelled) {
              console.error('Error updating certificate flag:', error);
            }
          }
        } else {
          history.replace('/training-v2/dashboard');
        }
      }
    };
    //if the assignee has not been evaluated yet but has completed the TP --> navigate to the 'SUBMITTED TO THE EVAL screen'
    if (pathname.includes('evp') && !certificateId) {
      history.replace(
        `/training-v2/play/view-result/${trainingId}/${sessionId}`
      );
    } else {
      downloadCertificate(certificateId, sessionId);
    }
    return () => {
      isCancelled = true;
    };
  }, [sessionId, certificateId]);
  return null;
};
export default NavigateCompletedAssignee;
