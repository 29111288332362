import {
  Badge,
  Box,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import { ReactComponent as FilterIcon } from 'assets/images/nexus/compliance/filled.svg';
import React, { FC } from 'react';

import { TAnalyticsFilterClearFilter } from './AnalyticsBaseFilter';
import {
  DetailsFiltersType,
  IDetailsFilterTypes,
} from './AnalyticsContainerHeader';
import DetailsMainContainer from './DetailsMainContainer';

export type ClearFilterType =
  | 'all'
  | 'types'
  | 'categories'
  | 'statuses'
  | 'jobs'
  | 'locations';

interface DetailsFilterContainerProps {
  detailFilters: DetailsFiltersType;
  setDetailsFilters: React.Dispatch<React.SetStateAction<DetailsFiltersType>>;
  clearFilters: (type?: TAnalyticsFilterClearFilter) => void;
  handleStatusSelection: (
    e: React.ChangeEvent<HTMLInputElement>,
    status: IDetailsFilterTypes
  ) => void;
  handleScoreSelection: (
    e: React.ChangeEvent<HTMLInputElement>,
    score: IDetailsFilterTypes
  ) => void;
}

const DetailsFilterContainer: FC<DetailsFilterContainerProps> = ({
  clearFilters,
  setDetailsFilters,
  detailFilters,
  handleStatusSelection,
  handleScoreSelection,
}) => {
  const filterCount = detailFilters?.totalFiltersTypes;
  return (
    <Popover placement='left-end' isLazy strategy='fixed' size={'lg'}>
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            {filterCount > 0 ? (
              <Box position='relative' display='inline-block'>
                <Badge
                  position='absolute'
                  top='-6px'
                  right='-5px'
                  bgColor='#2A85FF'
                  borderRadius='full'
                  textAlign='center'
                  w='18px'
                  fontSize='12px'
                  fontWeight={600}
                  color='white'
                >
                  {filterCount}
                </Badge>
                <FilterIcon cursor='pointer' />
              </Box>
            ) : (
              <Box>
                <FilterIcon cursor='pointer' />
              </Box>
            )}
          </PopoverTrigger>
          <PopoverContent minHeight={'400px'} p={'10px'} borderRadius={'12px'}>
            <PopoverArrow />
            <PopoverBody>
              <DetailsMainContainer
                detailsFilters={detailFilters}
                setDetailsFilters={setDetailsFilters}
                clearFilters={clearFilters}
                handleStatusSelection={handleStatusSelection}
                handleScoreSelection={handleScoreSelection}
              />
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};

export default DetailsFilterContainer;
