import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { Card } from 'antd';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faAngleLeft } from '@fortawesome/pro-solid-svg-icons';

import { AuthRole, useAuthorization } from 'authorization';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import FormResponseHeader from './FormResponseHeader';
import FormResponseTable from './FormResponseTable';
import FormResponseTabsContainer from './FormResponseTabs';
import { FormResponseContainerWrapper } from './FormResponseContainer.styles';
import FormDataContext from 'pages/forms/FormStore/form-data-context';
import QuestionWiseTable from './QuestionWiseTable';
import FormSummary from './FormSummary';
import { Respondent } from '../forms-types';
import {
  IDateFilterOptions,
  IDateFilterRangeEntity,
} from 'ui-components/DateFilter/types';

interface LocationState {
  backToTitle?: string;
}

const FormResponseContainer: FC = () => {
  const { t } = useTranslation('form');
  const [dateRangeFilter, setDateFilterRange] = useState<
    IDateFilterRangeEntity | undefined
  >(undefined);
  const [usersList, setUsersList] = useState<Respondent[]>([]);
  const [isAnyTableFilterSelected, setIsAnyTableFilterSelected] =
    useState(false);
  const [selectedDateFilter, setSelectedDateFilter] = useState<
    IDateFilterOptions | undefined
  >(undefined);
  const [locationsList, setLocationsList] = useState<Respondent[]>([]);
  const history = useHistory();
  const params: { id: string } = useParams();
  const formCtx = useContext(FormDataContext);
  const locState = useLocation<LocationState>().state;
  const authorization = useAuthorization({
    permittedFor: 'user',
    permittedRoles: [AuthRole.SUPER_ADMIN],
  });

  const formId = formCtx?.formResponseByIdData?.eid;

  // useEffect(() => {
  //   const formData = formCtx.formResponseByIdData;

  //   const contributors = formData?.contributors || [];

  //   if (formData?.eid) {
  //     authorization(
  //       {
  //         canAccess: ({ eid }) => {
  //           return (
  //             eid === formData?.createdBy?.eid || contributors.includes(eid)
  //           );
  //         },
  //       },
  //       null,
  //       () => history.push('/forms')
  //     );
  //   }
  // }, [formId]);

  useEffect(() => {
    let _usersList = formCtx?.formRespondents?.FormRespondents?.users;
    let _locationsList = formCtx?.formRespondents?.FormRespondents?.locations;

    let incomingUsersList = _usersList;
    let _tempUsersList = incomingUsersList?.map((incomingUser) => {
      const existingUser = usersList?.find(
        (_user) => _user?.eid === incomingUser?.eid
      );
      return existingUser ? existingUser : incomingUser;
    });

    let incomingLocationsList = _locationsList;
    let _tempLocationsList = incomingLocationsList?.map((incomingLocation) => {
      const existingLocation = locationsList?.find(
        (_user) => _user?.eid === incomingLocation?.eid
      );
      return existingLocation ? existingLocation : incomingLocation;
    });

    setUsersList(_tempUsersList);
    setLocationsList(_tempLocationsList);
  }, [formCtx?.formRespondents]);

  const userFilterClickHandler = (user: Respondent) => {
    let _usersData: Respondent[] = JSON.parse(JSON.stringify(usersList));
    _usersData?.map((data) => {
      if (user?.eid === data?.eid) {
        data.selected = !data.selected;
      }
    });
    setUsersList(_usersData);
  };

  const locationFilterClickHandler = (location: Respondent) => {
    let _locationsData: Respondent[] = JSON.parse(
      JSON.stringify(locationsList)
    );
    _locationsData?.map((data) => {
      if (location?.eid === data?.eid) {
        data.selected = !data.selected;
      }
    });
    setLocationsList(_locationsData);
  };

  const onDateFilterRangeSelectionHandler = (
    range: IDateFilterRangeEntity | undefined
  ) => {
    setDateFilterRange(range);
  };

  return (
    <>
      <FormResponseContainerWrapper>
        <div className='forms-title-container'>
          <div className='forms-path-title'>{t('response')}</div>
          <Box className='forms-title-breadcrumb'>
            <FontAwesomeIcon icon={faAngleLeft as IconProp} />
            <span
              onClick={() =>
                locState?.backToTitle
                  ? history.goBack()
                  : history.push('/forms')
              }
              style={{ cursor: 'pointer' }}
            >
              &nbsp;{locState?.backToTitle ?? t('back_to_forms')}
            </span>
          </Box>
        </div>
      </FormResponseContainerWrapper>
      <Card
        style={{ borderRadius: '12px', flex: 1 }}
        bodyStyle={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Flex flex={1} flexDir='column' rowGap='1rem'>
          <FormResponseHeader />
          <FormResponseTabsContainer
            dateRangeFilter={dateRangeFilter}
            isAnyTableFilterSelected={isAnyTableFilterSelected}
            selectedDateFilter={selectedDateFilter}
            setSelectedDateFilter={setSelectedDateFilter}
            onDateFilterRangeSelectionHandler={
              onDateFilterRangeSelectionHandler
            }
          />
          {formCtx.selectedTabIndex === 0 && (
            <FormResponseTable
              dateRangeFilter={dateRangeFilter}
              formId={formId}
              usersData={usersList}
              locationsData={locationsList}
              showFilters
              showLocation
              userFilterClickHandler={userFilterClickHandler}
              locationFilterClickHandler={locationFilterClickHandler}
              setIsAnyTableFilterSelected={setIsAnyTableFilterSelected}
            />
          )}
          {formCtx.selectedTabIndex === 1 && (
            <QuestionWiseTable
              dateRangeFilter={dateRangeFilter}
              locationsData={locationsList}
              locationFilterClickHandler={locationFilterClickHandler}
            />
          )}
          {formCtx.selectedTabIndex === 2 && (
            <FormSummary locationsData={locationsList} />
          )}
        </Flex>
      </Card>
    </>
  );
};

export default FormResponseContainer;
