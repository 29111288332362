export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const phaseColors = [
  '#FFD88D',
  '#FFBC99',
  '#B5E4CA',
  '#B1E5FC',
  '#CABDFF',
];

export const getPhaseColor = (index: number) =>
  phaseColors[index % phaseColors.length];

export const daysToMonthsDays = (days: number) => {
  const months = Math.floor(days / 30);
  console.log(months, days);
  const remainingDays = Math.round(days % 30);
  if (months === 0) return `${remainingDays} days`;
  return `${months} months ${remainingDays} days`;
};
