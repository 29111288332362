import { MutableRefObject } from 'react';
import { P } from 'ts-pattern';
import { toArray } from '../../../../../utils';
import { UserEntityData } from '../../../../../shared/graphql/shared-types';
import { IAssigneeInput } from '../training-input.types';

type TAssignees = IAssigneeInput['assignees'];

export type UserRecordRef = MutableRefObject<Record<string, UserEntityData>>;

export const haveValue = P.when((value) => toArray(value).length);

export const filterJob = (p: TAssignees[number]) => {
  if (!p.roles?.length) {
    return false;
  }

  if (!p.locationType || p.locationType === 'custom') {
    return toArray(p?.locationIds).length > 0;
  }
  return true;
};

export const haveJobAssignee = P.when<
  TAssignees,
  (value: TAssignees) => boolean
>((value) => toArray(value).some(filterJob));

export const filterRole = (p: TAssignees[number]) => {
  if (!p.authRoles?.length) {
    return false;
  }

  if (!p.locationType || p.locationType === 'custom') {
    return toArray(p?.locationIds).length > 0;
  }
  return true;
};

export const haveRoleAssignee = P.when<
  TAssignees,
  (value: TAssignees) => boolean
>((value) => toArray(value).some(filterRole));

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getLocations = (
  locationIds: string[] = [],
  userRef: UserRecordRef
) => {
  const { open, launching } = locationIds.reduce(
    (acc, loc) => {
      const _loc = userRef.current[loc];

      if (!_loc) return acc; // Early exit if _loc is not found

      const { locationStatus, name } = _loc; // Destructuring for clarity

      if (locationStatus === 'open') {
        acc.open.push(name);
      } else if (locationStatus === 'development') {
        acc.launching.push(name);
      }

      return acc;
    },
    {
      open: [] as string[],
      launching: [] as string[],
    }
  );

  let context: string | undefined;

  if (open.length && launching.length) {
    context = 'both';
  } else if (open.length) {
    context = 'open';
  } else if (launching.length) {
    context = 'launching';
  }

  return {
    open: open,
    launching: launching,
    context: context,
  };
};
