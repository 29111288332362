import { Flex, IconButton, Text } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-solid-svg-icons';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  handlePrev: () => void;
  handleNext: () => void;
}

const PaginationComponent: FC<PaginationProps> = ({
  currentPage,
  totalPages,
  handlePrev,
  handleNext,
}) => {
  return (
    <Flex alignItems='center' mt={3}>
      <IconButton
        icon={<FontAwesomeIcon icon={faChevronLeft as IconProp} />}
        onClick={handlePrev}
        isDisabled={currentPage === 1}
        aria-label='Previous Page'
        mr={2}
        variant='outline'
      />
      <Text>
        <Text as='span' fontSize='14px' fontWeight='500'>
          {currentPage}
        </Text>
        {'/'}
        <Text as='span' fontSize='12px' fontWeight='400' color={'#6F767E'}>
          {totalPages}
        </Text>
      </Text>

      <IconButton
        icon={<FontAwesomeIcon icon={faChevronRight as IconProp} />}
        onClick={handleNext}
        isDisabled={currentPage === totalPages}
        aria-label='Next Page'
        ml={2}
        variant='outline'
      />
    </Flex>
  );
};

export default PaginationComponent;
