import React, { forwardRef } from 'react';
import { As, useFormControl } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';

import { Button, ButtonProps } from '../../../../../atoms';

export const buttonStyles: ButtonProps = {
  _invalid: {
    bg: 'rgba(255, 188, 153, 0.3)',
    borderColor: 'red.300',
  },
};

interface IProps extends ButtonProps {
  children: React.ReactNode;
  as?: As;
}

const BaseSelectButton = forwardRef<never, IProps>(
  ({ children, as, ...rest }, ref) => {
    const controlProps = useFormControl(rest);

    return (
      <Button
        ref={ref}
        as={as}
        size='lg'
        borderRadius='12px'
        rightIcon={<ChevronDownIcon boxSize='28px' color='#6F767E' />}
        paddingX='12px'
        variant='outline'
        bg='white'
        {...controlProps}
      >
        {children}
      </Button>
    );
  }
);

BaseSelectButton.displayName = 'BaseSelectButton';

export default BaseSelectButton;
