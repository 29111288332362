import React, { FC } from 'react';

interface IProps {}

const useEvaluatePendingEvaluation = () => {
  return (
    <div>
      <h1>hii</h1>
    </div>
  );
};

useEvaluatePendingEvaluation.displayName =
  'displayName:sub-components/training-v2/dashboard/components/myTrainings/pendingEvaluation/useEvaluatePendingEvaluation.tsx';

export default useEvaluatePendingEvaluation;
