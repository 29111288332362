import moment from 'moment-timezone';
import {
  IItems,
  IMyTask,
  ITaskOverviewEntity,
} from 'pages/TasksDashboard/tasks.types';
import { groupedTaskSessions, ModifiedItems } from './processTaskData';

const completeTaskHandler = (item: IItems) => {
  let flag = false;
  if (item?.task?.status === 'TASK_TERMINATED') {
    return false;
  }
  if (item?.isRepeating) {
    /**
     * REPEATING TASK : If lastSession is true and task is repeating
     */
    if (item?.isLastSession) {
      flag = true;
    }
  } else {
    /**
     * ONE TIME TASK : If task is not repeating and completed tasks' length equals total item count
     */
    if (
      !item?.isRepeating &&
      item?.currentUserProgress?.completedTasks?.length === item?.itemCount
    ) {
      flag = true;
    }
  }
  return flag;
};

export const completedTasksHandler = (
  taskData: IMyTask | undefined,
  userTimezone: string
) => {
  const completedTasks: any[] = [];
  let _itemsArray: IItems[] = JSON.parse(
    JSON.stringify(taskData?.MyTaskSession || [])
  );
  let itemsArray = groupedTaskSessions(_itemsArray as ModifiedItems[]);
  itemsArray?.map((item) => {
    let _now = moment()?.tz(userTimezone);
    let now = moment.utc(
      _now?.format('DD-MMM-YYYY hh:mm a'),
      'DD-MMM-YYYY hh:mm a'
    );
    let taskDueDate = moment.utc(item?.dueDate);
    if (completeTaskHandler(item)) {
      completedTasks?.push({
        eid: item?.taskId,
        sessionId: item?.eid,
        status: 'completed' as ITaskOverviewEntity,
        title: item?.title,
        taskDetails: item,
        date: moment(
          item?.currentUserProgress?.completedAt || item?.dueDate
        )?.isValid()
          ? moment(
              item?.currentUserProgress?.completedAt || item?.dueDate
            )?.format('MMM DD')
          : '-',
        detailedAnalysis: {
          backendTaskStartDate: item?.startDate,
          backendTaskDueDate: item?.dueDate,
          dueDateISO: moment.utc(item?.dueDate),
          myLocalUTC: moment().toISOString(),
          myLocal: moment().format('DD-MMM-YYYY hh:mm a'),
          timezone: userTimezone,
          isLastSession: item?.isLastSession,
          isRepeating: !!item?.task?.repeatCycle,
        },
        timeDuration: null,
        countData: {
          completedCount: item?.currentUserProgress?.completedTasks?.length,
          totalCount: item?.itemCount,
        },
        repeatString: item?.task?.repeatCycle,
      });
    }
  });
  let _completedTasks = completedTasks.sort((a, b) => {
    const dateA = moment(
      a?.detailedAnalysis?.dueDateISO,
      'DD-MMM-YYYY hh:mm a'
    );
    const dateB = moment(
      b?.detailedAnalysis?.dueDateISO,
      'DD-MMM-YYYY hh:mm a'
    );
    return dateA.isAfter(dateB) ? 1 : -1;
  });
  return _completedTasks;
};

export const terminatedTasksHandler = (
  taskData: IMyTask | undefined,
  userTimezone: string
) => {
  const terminatedTasks: any[] = [];
  let _itemsArray: IItems[] = JSON.parse(
    JSON.stringify(taskData?.MyTaskSession || [])
  );
  let itemsArray = groupedTaskSessions(_itemsArray as ModifiedItems[]);
  itemsArray?.map((item) => {
    let _now = moment()?.tz(userTimezone);
    let now = moment.utc(
      _now?.format('DD-MMM-YYYY hh:mm a'),
      'DD-MMM-YYYY hh:mm a'
    );
    let taskDueDate = moment.utc(item?.dueDate);
    if (item?.task?.status === 'TASK_TERMINATED') {
      terminatedTasks?.push({
        eid: item?.taskId,
        sessionId: item?.eid,
        status: 'terminated' as ITaskOverviewEntity,
        title: item?.title,
        taskDetails: item,
        date: moment(item?.dueDate)?.format('MMM DD'),
        detailedAnalysis: {
          backendTaskStartDate: item?.startDate,
          backendTaskDueDate: item?.dueDate,
          dueDateISO: moment.utc(item?.dueDate),
          myLocalUTC: moment().toISOString(),
          myLocal: moment().format('DD-MMM-YYYY hh:mm a'),
          timezone: userTimezone,
          isLastSession: item?.isLastSession,
          isRepeating: !!item?.task?.repeatCycle,
        },
        timeDuration: null,
        countData: {
          completedCount: item?.currentUserProgress?.completedTasks?.length,
          totalCount: item?.itemCount,
        },
        repeatString: item?.task?.repeatCycle,
      });
    }
  });
  let _terminatedTasks = terminatedTasks.sort((a, b) => {
    const dateA = moment(
      a?.detailedAnalysis?.dueDateISO,
      'DD-MMM-YYYY hh:mm a'
    );
    const dateB = moment(
      b?.detailedAnalysis?.dueDateISO,
      'DD-MMM-YYYY hh:mm a'
    );
    return dateA.isAfter(dateB) ? 1 : -1;
  });
  return _terminatedTasks;
};
