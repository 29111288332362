import React, { forwardRef, useMemo } from 'react';
import { Dropdown } from '../../../../../../../atoms';
import { TDuration } from '../../../../../../../types';

const options = [
  {
    label: 'Day(s)',
    value: 'days',
  },
  {
    label: 'Week(s)',
    value: 'weeks',
  },
  {
    label: 'Month(s)',
    value: 'months',
  },
  {
    label: 'Year(s)',
    value: 'years',
  },
];

interface IProps {
  value?: TDuration['type'];
  onChange: (value: TDuration['type']) => void;
}

const RepeatOnSelect = forwardRef<never, IProps>(({ value, onChange }, ref) => {
  const selected = useMemo(() => {
    return options.find((op) => op.value === value);
  }, [value]);

  return (
    <Dropdown
      ref={ref}
      size='lg'
      value={selected}
      onChange={(nextValue) => onChange(nextValue.value)}
      options={options}
      selectStyles={{
        container: {
          background: 'transparent',
        },
        control: {
          border: '2px solid',
        },
        menuList: {
          padding: '16px',
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          minWidth: 0,
        },
        option: {
          fontSize: '15px',
          fontWeight: 600,
          padding: '12px',
          borderRadius: '10px',
        },
        singleValue: {
          fontSize: '15px',
          fontWeight: 600,
          color: '#6F767E',
        },
      }}
    />
  );
});

RepeatOnSelect.displayName = 'RepeatOnSelect';

export default RepeatOnSelect;
