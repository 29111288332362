import { gql } from '@apollo/client';
import {
  TrainingContType,
  TrainingStatusOld,
  VisibilityStatus,
} from '../../../types';
import { UserProgressEntity } from '../training.types';

export interface TrainingResponse {
  trainingById: {
    eid: string;
    title: string;
    entityId: string;
    status: string;
    userProgress: UserProgressEntity[];
    trainingItems: any[];
    visibility: VisibilityStatus;
  };
  trainingForms?: Array<{
    formId: string;
    isAccessible: boolean;
  }>;
}

export const GET_TRAINING_PLAY = gql`
  query TrainingPlay($eid: String!) {
    trainingById(eid: $eid) {
      eid
      title
      entityId
      status
      userProgress
      trainingItems
      visibility
    }
    trainingForms(eid: $eid) {
      formId
      isAccessible
    }
  }
`;

export interface InputVariable {
  locationLaunchId: string;
  stepId: string;
  taskId: string;
}

export interface LauncherTrainingInput extends InputVariable {
  contentId: string;
  trainingId: string;
  type: TrainingContType;
}

export interface TrainingInput {
  contentId: string;
  eid: string;
  type: string;
  userId?: string;
}

export interface TrainingProgressVariable {
  input: TrainingInput | LauncherTrainingInput;
}

interface TrainingProgress {
  eid: string;
  title: string;
  entityId: string;
  status: TrainingStatusOld;
  userProgress: UserProgressEntity[];
}

export interface TrainingProgressResponse {
  UpdateUserTrainingProgress: TrainingProgress;
  UpdateUserTrainingProgressLauncher: TrainingProgress;
}

export const UPDATE_TRAINING_COMPLETE = gql`
  mutation UpdateUserTrainingProgress($input: UserTrainingProgressInput) {
    UpdateUserTrainingProgress(input: $input) {
      eid
      title
      entityId
      status
      userProgress
    }
  }
`;

export const LAUNCHER_TRAINING_COMPLETE = gql`
  mutation UpdateUserTrainingProgressLauncher(
    $input: UserTrainingProgressLauncherInput
  ) {
    UpdateUserTrainingProgressLauncher(input: $input) {
      eid
      title
      entityId
      status
      userProgress
    }
  }
`;

export interface ReassignVariable {
  userId: string;
  trainingId: string;
}

export const REASSIGN_TRAINING = gql`
  mutation ReassignTraining($userId: String!, $trainingId: String!) {
    ReassignTraining(userId: $userId, trainingId: $trainingId) {
      eid
    }
  }
`;
