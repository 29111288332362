import { useEffect, useRef } from 'react';
import { AmplitudeEvent, deployEvent } from '../../../shared';
import { TaskFilterType } from './types';

export const useLauncherTasksEventTrack = (
  countData: Record<TaskFilterType['value'], number>
): void => {
  const mountRef = useRef<boolean>();

  useEffect(() => {
    if (!mountRef?.current && countData) {
      deployEvent(AmplitudeEvent.LAUNCHER_TASK_PAGE_VISIT, {
        // from_where: '',
        due_today: countData?.dueToday,
        overdue: countData?.overdue,
        this_week: countData?.thisWeek,
        total_tasks: countData?.totalTasks,
      });

      mountRef.current = true;
    }
  }, [countData]);
};
