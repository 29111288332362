import React, { FC, useMemo } from 'react';
import {
  Box,
  Flex,
  ListItem,
  Spacer,
  UnorderedList,
  useModalContext,
} from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import {
  faTriangleExclamation,
  faUserTie,
} from '@fortawesome/free-solid-svg-icons';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';

import { AlertBox } from '../../../../atoms';
import { ActionButton } from '../../../../ui-components';
import { toArray } from '../../../../utils';
import { getTransObject, TFormInput, useUserRecordRef } from '../../create';
import { contentDiffHelpers } from './content-diff.helpers';

export interface ARuleProps {
  values?: TFormInput;
  onPublishClick?: () => void | Promise<void>;
}

const BeforePublishContent: FC<ARuleProps> = ({ values, onPublishClick }) => {
  const { t } = useTranslation('traininginfo', { keyPrefix: 'changeLog' });
  const { t: tAssignee } = useTranslation('traininginfo', {
    keyPrefix:
      values?.frequency === 'repeat'
        ? 'assigneeChangeInfoRepeat'
        : 'assigneeChangeInfo',
  });
  const { onClose } = useModalContext();
  const userRef = useUserRecordRef();
  const diffs = useMemo(() => {
    return contentDiffHelpers(values?.originalData, values);
  }, [values]);

  const transObject = useMemo(() => {
    return getTransObject(
      {
        assignBy: values?.assignBy,
        assignees: values?.assignees,
        userIds: values?.userIds,
        locationIds: values?.locationIds,
        locationType: values?.locationType,
        // intentionally set value as old, so that info will show who will get to see the new changes
        assigneeUserType: 'old',
      },
      userRef
    );
  }, [values]);

  const _onPublishClick = async () => {
    if (onPublishClick) {
      await onPublishClick?.();
    }
    setTimeout(onClose);
  };

  const assigneeCopy = useMemo(() => {
    if (values?.frequency === 'repeat') {
      return 'Members will be able to see the new changes in content only in future cycles.';
    }
    return 'Only those members who have not attempted the training path will be able to see the new changes in content.';
  }, []);

  return (
    <Flex flexDir='column' gap={5}>
      <Flex flexDir='column' gap={7} paddingX={7} paddingBottom={7}>
        <Flex flexDir='column' gap={4}>
          <Box fontSize='15px' fontWeight={600} lineHeight='24px'>
            New edits
          </Box>

          <AlertBox
            hidden={!!diffs?.length}
            childProps={{ fontWeight: 500, color: 'red.500' }}
            icon={faTriangleExclamation}
            bg='red.100'
            iconProps={{ color: '#ff6a55' }}
          >
            Please change some training content or assignee
          </AlertBox>

          <AlertBox
            hidden={!diffs?.length}
            childProps={{ fontWeight: 500 }}
            icon={faCircleExclamation}
          >
            <UnorderedList>
              {diffs.map((diff, index) => (
                <ListItem key={index}>
                  <Trans t={t} i18nKey={diff.key} />
                </ListItem>
              ))}
            </UnorderedList>
          </AlertBox>

          <Flex flexDir='column'>
            <Box as='span' fontSize='15px' fontWeight={600} lineHeight='24px'>
              Who will see the changes?
            </Box>
            <Box as='span' fontSize='12px' fontWeight={600} color='#6F767E'>
              {assigneeCopy}
            </Box>
          </Flex>

          <AlertBox childProps={{ fontWeight: 500 }} icon={faUserTie}>
            {toArray(transObject)?.map((item, index) => (
              <Box
                key={index}
                _notLast={{
                  borderBottom: '2px dashed #CBD5E0',
                  paddingBottom: 1,
                }}
                _notFirst={{ paddingTop: 1 }}
              >
                <Trans
                  t={tAssignee as TFunction<'traininginfo', 'assigneeInfo'>}
                  {...item}
                  // @ts-ignore
                  components={{ ...item?.components }}
                />
              </Box>
            ))}
          </AlertBox>
        </Flex>
      </Flex>

      <Flex
        gap={5}
        paddingY='12px'
        paddingX={4}
        bgColor='#FCFCFC'
        borderTop='1px solid #EFEFEF'
        borderBottomRadius='8px'
      >
        <Spacer />

        <ActionButton variant='outline' fontSize='13px' actionFn={onClose}>
          Cancel
        </ActionButton>

        <ActionButton
          fontSize='13px'
          colorScheme='blue'
          actionFn={_onPublishClick}
          isDisabled={!diffs?.length}
        >
          Publish
        </ActionButton>
      </Flex>
    </Flex>
  );
};

export default BeforePublishContent;
