import {
  Button,
  Center,
  Checkbox,
  Flex,
  IconButton,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react';
import TrainingiconIcon from 'assets/images/TrainingIcon.png';
import moment from 'moment';
import React, { FC } from 'react';
import { LAUNCHER_TASK_STEP_TYPE } from 'types';
import ClipboardIcon from 'assets/images/dashboard/clipboard.svg';
import FormIcon from 'assets/images/search/form.svg';
import { PhaseTask } from 'sub-components/Launcher/dashboard/Location/LauncherLocation.graphql';
import Loader from 'sub-components/Loader';
import { TextWithLink } from '../../../../launcher-config/common';

type TaskStepStatusBtnProps = {
  status: string;
};

const TaskStepStatusBtn: FC<TaskStepStatusBtnProps> = ({ status }) => {
  return (
    <Button
      colorScheme={status === 'completed' ? 'green' : 'gray'}
      borderRadius={'12px'}
      variant={'solid'}
      size={'sm'}
      fontSize={'14px'}
      lineHeight={'14.52px'}
      fontWeight={600}
      textTransform='capitalize'
    >
      {status}
    </Button>
  );
};
type TaskStepProps = {
  title: string;
  addedOn: Date;
  createdBy: string;
  taskImage: string;
  type: LAUNCHER_TASK_STEP_TYPE;
  completed: boolean;
  handleStatusChange: (value: boolean) => void;
  isLoading: boolean;
  disabled: boolean;
  taskCompleted: boolean;
};
const TaskStep: FC<TaskStepProps> = ({
  title,
  addedOn,
  completed,
  createdBy,
  taskImage,
  type,
  isLoading,
  disabled,
  taskCompleted,
  handleStatusChange,
}) => {
  const imgRender = () => {
    switch (type) {
      case 'form':
        return <Image src={FormIcon} borderRadius='6px' boxSize='50px' />;
      default:
        return (
          <Center
            boxSize='50px'
            minWidth='50px'
            bg='rgba(239, 239, 239, 1)'
            p='1px'
            borderRadius='6px'
          >
            <Image src={ClipboardIcon} boxSize='20px' />
          </Center>
        );
    }
  };
  return (
    <Flex gap='36px' justifyContent='space-between' alignItems='center'>
      <Flex gap={'19px'} alignItems={'flex-start'}>
        {/* <Image src={taskImage} /> */}
        {/* <IconButton
          bg={'rgba(239, 239, 239, 1)'}
          px='4px'
          py='3.5px'
          borderRadius='6px'
          aria-label='action'
          variant='ghost'
          icon={
            <Image
              src={
                type === 'text'
                  ? ClipboardIcon
                  : type === 'form'
                  ? FormIcon
                  : ClipboardIcon
              }
            />
          }
        /> */}
        {imgRender()}
        <Stack spacing={1}>
          <TextWithLink
            fontWeight={500}
            fontSize='14px'
            lineHeight='25px'
            wordBreak='break-word'
            linkText={title}
          />
          <Flex
            gap={1}
            color={'#6F767E'}
            fontWeight={400}
            fontSize={'12px'}
            letterSpacing={'0.1px'}
            lineHeight={'18px'}
            alignItems={'center'}
            wrap={'nowrap'}
          >
            <Text>Added on {moment(addedOn).format('D MMM YYYY')}</Text> &bull;{' '}
            <Text>Created by {createdBy}</Text>
          </Flex>
        </Stack>
      </Flex>
      {isLoading ? (
        <Loader
          fontSize='16px'
          thickness='3px'
          minWidth='var(--spinner-size)'
        />
      ) : !taskCompleted ? (
        <Checkbox
          isChecked={completed}
          onChange={(e) => handleStatusChange(e?.target?.checked)}
          isDisabled={disabled}
        />
      ) : null}
      {/* <TaskStepStatusBtn status={completed ? 'completed' : 'not started'} /> */}
    </Flex>
  );
};

TaskStep.displayName =
  'sub-components/Launcher/tasks/components/task-details/components/TaskStep';

export default TaskStep;
