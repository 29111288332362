import { Flex, Text } from '@chakra-ui/react';
import React, { FC } from 'react';
import { ReactComponent as IllustrationsIcon } from 'assets/images/Illustrations.svg';
import { ReactComponent as TrainingPathEmptyStateIcon } from 'assets/images/empty-state/training-path-empty-state.svg';

interface IProps {
  searchQuery: string;
}

const EmptyState: FC<IProps> = ({ searchQuery }) => {
  let title = 'Nothing to learn as of now!';
  let description =
    'Your training will be visible once your manager assign them to you';

  const renderIcon = () => {
    if (searchQuery) {
      return <IllustrationsIcon />;
    } else {
      return <TrainingPathEmptyStateIcon />;
    }
  };

  if (searchQuery) {
    title = `No results found for ${searchQuery} !`;
    description = 'Please check your spelling or search something else!';
  }

  return (
    <Flex
      width={'100%'}
      height={'60vh'}
      justifyContent={'center'}
      alignItems={'center'}
      flexDir={'column'}
    >
      {renderIcon()}
      <Text fontWeight={700}>{title}</Text>
      <Text fontSize={'12px'} fontWeight={500} color={'#9E9E9E'}>
        {description}
      </Text>
    </Flex>
  );
};

export default EmptyState;
