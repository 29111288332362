import { CSSProperties, forwardRef, type MutableRefObject } from 'react';
import {
  components as Components,
  Select,
  Props,
  chakraComponents,
  GroupBase,
  SelectInstance,
} from 'chakra-react-select';
import { Any } from '../../types';
import { getChakraSelectStyles, SelectStyles } from './dropdown.styles';

export interface SelectOption<T extends string = string> {
  label: string;
  value: T;
  [key: string]: Any;
}

export interface DropDownProps<
  Option = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
> extends Pick<
    Props<Option, IsMulti, Group>,
    | 'menuIsOpen'
    | 'onMenuOpen'
    | 'onMenuClose'
    | 'menuPlacement'
    | 'closeMenuOnSelect'
    | 'isSearchable'
    | 'onBlur'
  > {
  className?: string;
  size?: 'sm' | 'md' | 'lg';
  inputStyle?: CSSProperties;
  disabled?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  placeholder?: string;
  value?: Option | null;
  onChange?: (newValue: Option, actionMeta: Any) => void;
  onInputChange?: (newValue: string) => void;
  options?: Array<Option>;
  menuShouldScrollIntoView?: boolean;
  components?: Partial<typeof Components>;
  selectStyles?: SelectStyles<Option, IsMulti, Group>;
  isReadOnly?: boolean;
  getOptionValue?: (option: Any) => string;
  getOptionLabel?: (option: Any) => string;
  isOptionSelected?: (option: Option, selectedValue: Any) => boolean;
  formatOptionLabel?: (option: Any, meta: Any) => Any;
  id?: string;
}

type RefInstance<SInstance> =
  | ((instance: SInstance) => void)
  | MutableRefObject<SInstance>
  | null;

const Dropdown = <
  Option extends SelectOption<Any>,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
>(
  {
    inputStyle,
    selectStyles = {},
    onChange,
    options,
    disabled,
    isDisabled,
    ...props
  }: DropDownProps<Option, IsMulti, Group>,
  ref: RefInstance<SelectInstance<Option, IsMulti, Group> | null>
) => {
  const mergedStyles: SelectStyles<Option, IsMulti, Group> = {
    ...selectStyles,
    control: {
      ...inputStyle,
      ...selectStyles?.control,
    },
  };

  return (
    <Select<Option, IsMulti, Group>
      ref={ref}
      onChange={onChange as Any}
      options={options}
      // @ts-ignore
      value={props.value || {}}
      chakraStyles={getChakraSelectStyles(mergedStyles)}
      isDisabled={isDisabled || disabled}
      {...props}
    />
  );
};

Dropdown.displayName = 'a/Dropdown';

const Option = chakraComponents.Option;

export { Option };

export default forwardRef(Dropdown);
