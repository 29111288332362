import { useQuery } from '@apollo/client';
import { Center, Flex } from '@chakra-ui/react';
import { AuthRole } from 'authorization';
import { useUserDataSelector } from 'hooks';
import { FC, useState } from 'react';
import Loader from 'sub-components/Loader';
import {
  IFinalOverallLocationWiseAnalytics,
  IOverallLocationWiseAnalytics,
} from 'sub-components/training-v2/shared/types';
import LocationCompletionGraph from '../LocationCompletionGraph';
import LocationOwnerContainer from '../LocationOwnerContainer';
import PerformanceSection from '../PerformanceSection';
import { Leaderboard } from '../TrackPathDashboard/topSection';
import TrainingTableContainer from '../TrainingTableContainer';
import { GET_TRACKING_ANALYTICS } from './query/tracking.graphql';

const TrackingContainer: FC = () => {
  const [overallLocationWiseData, setOverallLocationWiseData] = useState<
    IFinalOverallLocationWiseAnalytics[]
  >([]);
  const { userAuthRole, loggedInUserLocations, businessLocations } =
    useUserDataSelector((state) => ({
      userAuthRole: state?.authRole,
      loggedInUserLocations: state?.locations,
      loggedInUserId: state?.eid,
      businessLocations: state?.entity?.locations,
    }));

  const {
    data: trackingAnalytics,
    loading,
    refetch: refetchTrackingAnalytics,
  } = useQuery(GET_TRACKING_ANALYTICS, {
    fetchPolicy: 'cache-first',
    onCompleted: (data) => {
      const locationWiseData =
        businessLocations
          ?.map((location) => {
            if (!location?.isRoot) {
              const analytics = data?.TpOverallLocationsWiseAnalytics?.find(
                (item: IOverallLocationWiseAnalytics) =>
                  item?.locationId === location?.eid
              );
              const isOwnLocation = !!loggedInUserLocations?.find(
                (loc) => loc?.eid === location?.eid
              );
              const completed = analytics?.completed || 0;
              const total = analytics?.total || 0;
              const completionPercent = total ? (completed / total) * 100 : 0;

              return {
                locationId: location?.eid,
                name: location?.name,
                completed,
                total,
                completionPercent,
                isOwnLocation,
                status: location.locationStatus,
              };
            }
          })
          ?.filter((item) => item) || [];

      setOverallLocationWiseData(locationWiseData);
    },
  });

  if (loading)
    return (
      <Center maxBlockSize={'100%'} height={'100vh'}>
        <Loader />
      </Center>
    );
  return (
    <Flex flexDir='column' gap={4}>
      {userAuthRole === AuthRole?.LOCATION_OWNER ? (
        <LocationOwnerContainer />
      ) : (
        <>
          <Flex gap={2} width={'100%'}>
            <Flex width={'50%'} gap={2} flexDir={'column'}>
              <PerformanceSection
                tpOverallAnalytics={trackingAnalytics?.TpOverallAnalytics}
                refetchTrackingAnalytics={refetchTrackingAnalytics}
              />
              <LocationCompletionGraph
                tpOverallLocationsWiseAnalytics={
                  trackingAnalytics?.TpOverallLocationsWiseAnalytics
                }
              />
            </Flex>
            <Flex width={'50%'}>
              <Leaderboard
                tpOverallLocationsWiseAnalytics={overallLocationWiseData}
                tpOverallUserWiseAnalytics={
                  trackingAnalytics?.TpOverallUserWiseAnalytics
                }
              />
            </Flex>
          </Flex>
        </>
      )}
      <TrainingTableContainer />
    </Flex>
  );
};

TrackingContainer.displayName =
  'displayName:sub-components/training-v2/dashboard/components/Tracking/TrackingContainer';

export default TrackingContainer;
