import React, { FC, useEffect, useMemo } from 'react';
import {
  Box,
  Center,
  Flex,
  Grid,
  GridItem,
  Skeleton,
  VStack,
} from '@chakra-ui/react';
import { useService } from 'sub-components/training-v2/play/layers';
import { useReactiveVar } from '@apollo/client';
import { usersEntityObj } from 'sub-components/Header';
import { HeaderColors } from 'configs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ScoreCard } from 'sub-components/nexus/NexusBase/components/LeftSection/components/LocationLeaderboard/components';
import confettiCelebrate from 'assets/lotties/confettieLottie.json';
import { Button } from 'atoms';
import Lottie from 'react-lottie';
import { useUserDataSelector } from 'hooks';
import { usePlayContext } from 'sub-components/training-v2/play/store/context';
import { useHistory } from 'routes';
import { TRAINING_PATH_V2_DASHBOARD } from 'appRoutes';
import { TrainingPathContainerTabs } from 'sub-components/training-v2/dashboard/components/Container';

interface ProcessedUserData {
  userId: string;
  name: string;
  completed: number;
  total: number;
  progress: number;
  rank: number;
}

const LeaderboardScreen: FC = () => {
  const { isRetake, increment } = usePlayContext((state) => ({
    isRetake: state.isRetake,
    increment: state.increment,
  }));
  const history = useHistory();
  const entityData = useReactiveVar(usersEntityObj);
  const loggedInUserData = useUserDataSelector((state) => state);
  const {
    overallUserAnalytics: { getOverllUserAnalytics, overallUserAnalyticsData },
  } = useService();

  const defaultUploadLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: confettiCelebrate,
  };

  const processedData = useMemo(() => {
    const analytics =
      overallUserAnalyticsData?.data?.TpOverallUserWiseAnalytics || [];
    const users = entityData?.filter((entity) => entity?.type === 'user') || [];

    const usersWithProgress = analytics
      .map((user) => {
        const userInfo = users.find((u) => u.eid === user.userId);
        if (!userInfo) return null;

        return {
          userId: user.userId,
          name: loggedInUserData?.eid === user?.userId ? 'You' : userInfo.name,
          completed: user.completed,
          total: user.total,
          progress: user.total > 0 ? (user.completed / user.total) * 100 : 0,
        };
      })
      .filter(Boolean) as Omit<ProcessedUserData, 'rank'>[];

    usersWithProgress.sort((a, b) => b.progress - a.progress);

    return usersWithProgress.map((user, index) => ({
      ...user,
      rank: index + 1,
    }));
  }, [overallUserAnalyticsData?.data?.TpOverallUserWiseAnalytics, entityData]);

  const topPerformers = useMemo(
    () => processedData.slice(0, 3),
    [processedData]
  );
  const remainingPerformers = useMemo(
    () => processedData.slice(3),
    [processedData]
  );

  const yourDetails = useMemo(() => {
    let foundUser = processedData?.find(
      (p) => p?.userId === loggedInUserData?.eid
    );
    return {
      ...foundUser,
      otherDetails: loggedInUserData,
    };
  }, [processedData]);

  useEffect(() => {
    getOverllUserAnalytics();
  }, []);

  const renderTopPerformers = () => {
    if (topPerformers.length === 3) {
      return (
        <Grid templateColumns='repeat(3, 1fr)' gap={6} alignItems='end'>
          <GridItem>
            <ScoreCard
              score={`${topPerformers[1].progress.toFixed(2)}`}
              label={topPerformers[1].name}
              locationId={topPerformers[1].userId}
              position={2}
              type='overall'
            />
          </GridItem>
          <GridItem>
            <ScoreCard
              score={`${topPerformers[0].progress.toFixed(2)}`}
              label={topPerformers[0].name}
              locationId={topPerformers[0].userId}
              position={1}
              isCrown
              type='overall'
            />
          </GridItem>
          <GridItem>
            <ScoreCard
              score={`${topPerformers[2].progress.toFixed(2)}`}
              label={topPerformers[2].name}
              locationId={topPerformers[2].userId}
              position={3}
              type='overall'
            />
          </GridItem>
        </Grid>
      );
    } else if (topPerformers.length === 2) {
      return (
        <Grid templateColumns='repeat(2, 1fr)' gap={6} alignItems='baseline'>
          <GridItem>
            <ScoreCard
              score={`${topPerformers[0].progress.toFixed(2)}`}
              label={topPerformers[0].name}
              locationId={topPerformers[0].userId}
              position={1}
              isCrown
              type='overall'
            />
          </GridItem>
          <GridItem>
            <ScoreCard
              score={`${topPerformers[1].progress.toFixed(2)}`}
              label={topPerformers[1].name}
              locationId={topPerformers[1].userId}
              position={2}
              type='overall'
            />
          </GridItem>
        </Grid>
      );
    } else if (topPerformers.length === 1) {
      return (
        <Grid templateColumns='repeat(2, 1fr)' gap={6} alignItems='baseline'>
          <GridItem colSpan={2}>
            <ScoreCard
              score={`${topPerformers[0].progress.toFixed(2)}`}
              label={topPerformers[0].name}
              locationId={topPerformers[0].userId}
              hideRank
              position={1}
              type='overall'
            />
          </GridItem>
        </Grid>
      );
    }
    return null;
  };

  const renderRemainingPerformers = () => {
    if (!yourDetails) return null;

    return (
      <Flex flexDir='column' gap={4} w='full'>
        <VStack
          align='stretch'
          spacing={3}
          bg='#efefef'
          w='full'
          borderRadius='12px'
          p='16px 8px'
        >
          <Flex align='center' justify='space-between'>
            <Box color='#6f767e' fontSize='12px' fontWeight={500}>
              Your position
            </Box>
            <Box color='#6f767e' fontSize='12px' fontWeight={500}>
              Completion rate
            </Box>
          </Flex>
          <Flex
            justify='space-between'
            align='center'
            bg='white'
            p='8px 12px'
            borderRadius='8px'
          >
            <Flex align='center' gap='5px'>
              <Center
                fontSize='10px'
                fontWeight={600}
                borderRadius='50%'
                bg='rgba(111, 118, 126, 1)'
                color='white'
                p='4px 8px'
                mr={1}
                boxSize='20px'
              >
                {yourDetails?.rank}
              </Center>
              <Box fontSize='12px' fontWeight={600}>
                {yourDetails?.name}
              </Box>
              <Box fontSize='12px' fontWeight={600}>
                &bull;
              </Box>
              <Box
                fontSize='12px'
                fontWeight={400}
                color='rgba(111, 118, 126, 1)'
              >
                {loggedInUserData?.role}
              </Box>
            </Flex>
            <Box fontSize='12px' fontWeight={600}>
              {(yourDetails?.progress || 0).toFixed(2)}%
            </Box>
          </Flex>
        </VStack>
        <Box
          fontSize='13px'
          fontWeight={500}
          color='rgba(111, 118, 126, 1)'
          textAlign='center'
        >
          You are ranked {yourDetails?.rank}
          {getOrdinalSuffix(yourDetails?.rank || 0)} on the leaderboard. Keep up
          the spirit!
        </Box>
      </Flex>
    );
  };

  // Helper function for ordinal suffixes
  const getOrdinalSuffix = (num: number): string => {
    const j = num % 10;
    const k = num % 100;
    if (j === 1 && k !== 11) return 'st';
    if (j === 2 && k !== 12) return 'nd';
    if (j === 3 && k !== 13) return 'rd';
    return 'th';
  };

  if (overallUserAnalyticsData?.loading) {
    return (
      <Flex w='full' flexDir='column' gap={4}>
        <Skeleton h='40px' w='200px' />
        <Skeleton h='20px' w='full' />
        <Skeleton h='20px' w='full' />
        <Skeleton h='20px' w='full' />
        <Skeleton h='20px' w='full' />
        <Skeleton h='20px' w='full' />
        <Skeleton h='20px' w='full' />
        <Skeleton h='20px' w='full' />
        <Skeleton h='20px' w='full' />
      </Flex>
    );
  }

  if (processedData.length === 0) {
    return (
      <Flex justify='center' align='center' h='200px'>
        <Box
          p={4}
          bg='rgba(239, 239, 239, 1)'
          borderRadius='8px'
          display='flex'
          alignItems='center'
          gap={2}
        >
          <FontAwesomeIcon
            icon={faCircleExclamation as IconProp}
            fontSize='20px'
            color='rgba(111, 118, 126, 1)'
          />
          <Box fontSize='12px' fontWeight={500}>
            No training progress data available
          </Box>
        </Box>
      </Flex>
    );
  }

  return (
    <Flex
      id='leaderboard-flex-one'
      p={4}
      gap={4}
      flexDir='column'
      h='full'
      justify='space-between'
      align='center'
      w='60%'
    >
      {/* @ts-ignore */}
      <Lottie
        ariaRole='div'
        options={defaultUploadLottieOptions}
        isClickToPauseDisabled
        height='100%'
        width='100%'
        style={{ position: 'absolute', top: '-70px' }}
      />
      <Flex
        w='full'
        alignItems='center'
        flexDir='column'
        h='full'
        justify='space-evenly'
      >
        <Box fontWeight={600} fontSize='32px' textAlign='center'>
          Congratulations on completing the training path!
        </Box>
        <Flex flexDir='column' gap={8} align='center' mt='50px'>
          {renderTopPerformers()}
          {renderRemainingPerformers()}
        </Flex>
        <Flex w='full'>
          <Button
            size='lg'
            variant='solid'
            colorScheme='blue'
            w='full'
            onClick={() =>
              isRetake
                ? history.push({
                    pathname: TRAINING_PATH_V2_DASHBOARD,
                    state: {
                      tabSelected: 'myTraining' as TrainingPathContainerTabs,
                    },
                  })
                : increment()
            }
          >
            Continue
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default LeaderboardScreen;
