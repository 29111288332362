import React, { FC } from 'react';
import { Box, Center, Flex, Skeleton, Spacer, Tooltip } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ActionButton } from '../../../../ui-components';
import { AppRoute, useHistory as useCustomHistory } from '../../../../routes';
import { AmplitudeEvent, deployEvent } from '../../../../shared';
import { BoardNameButton, useLauncherConfigContext } from '../common';
import { BoardActionButton, useBoardActionHandler } from './board-action';

interface IProps {}

const BoardViewHeader: FC<IProps> = () => {
  const { t } = useTranslation(['launcher']);
  const history = useHistory();
  const customHistory = useCustomHistory();

  const { configTitle, initializing, updateTitle } = useLauncherConfigContext(
    (state) => ({
      configTitle: state.config?.title,
      initializing: state.initializing,
      updateTitle: state.updateConfigTitle,
    })
  );

  const data = useLauncherConfigContext((state) => ({
    eid: state.config?.eid,
    title: state.config?.title,
    // @ts-ignore
    locationLaunches: state.config?.locationLaunches,
  }));

  const actionHandler = useBoardActionHandler({
    onRename: (newTitle) => updateTitle(newTitle),
    onDeleted: () => {
      customHistory.replace({ pathname: AppRoute.LAUNCHER_BOARDS });
    },
    onDuplicated: (newConfigEid) => {
      customHistory.replace({
        pathname: AppRoute.LAUNCHER_BOARD_CONFIG,
        search: history.location.search,
        params: {
          boardId: newConfigEid,
        },
      });
    },
  });

  return (
    <Flex align='center' mb={5} gap={3}>
      <Center
        height='36px'
        alignSelf='start'
        onClick={history.goBack}
        cursor='pointer'
      >
        <ArrowBackIcon color='#000000' boxSize='25px' />
      </Center>
      <Box overflowX='hidden'>
        {initializing ? (
          <Skeleton height='28px' marginY='4px' width='200px' />
        ) : (
          <Box
            fontSize='24px'
            fontWeight='700'
            color='#272B30'
            whiteSpace='nowrap'
            isTruncated
          >
            {configTitle}
          </Box>
        )}

        <Box fontSize='15px' fontWeight='400' color='#6F767E'>
          Back to My boards
        </Box>
      </Box>

      {data?.eid ? (
        <Flex alignSelf='start'>
          <BoardActionButton
            size='sm'
            iconSize='xl'
            iconColor='#6F767E'
            onActionClick={(action) => actionHandler(action, data as never)}
          />
        </Flex>
      ) : null}

      <Spacer />

      <BoardNameButton />

      <Tooltip
        hasArrow
        paddingX='8px'
        paddingY='6px'
        borderRadius='4px'
        placement='bottom-end'
        label='Open in editor mode to edit Phases and Timelines'
      >
        <ActionButton
          size='lg'
          variant='outline'
          fontWeight='700'
          bg='white'
          minW='min-content'
          actionFn={() => {
            deployEvent(AmplitudeEvent.LAUNCHER_SWITCH_VIEW, {
              view_name: 'Board View',
            });
            history.replace('?viewMode=editor');
          }}
        >
          {t('launcher:openInEditorMode')}
        </ActionButton>
      </Tooltip>
    </Flex>
  );
};

export default BoardViewHeader;
