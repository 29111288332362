import React, { useCallback, useRef } from 'react';
import { UseConfirm, useConfirm } from '../../../../ui-components';
import ModalContent from './ModalContent';

interface IProps {}

type ImageBgToggleModal = (props: IProps) => void;

export const useImageBgToggle = (): ImageBgToggleModal => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();

  return useCallback(() => {
    if (confirmRef.current) {
      confirmRef.current.destroy();
    }

    confirmRef.current = confirm({
      content: <ModalContent onClose={() => confirmRef?.current?.destroy()} />,
      size: 'lg',
      isCentered: true,
      bodyProps: {
        p: '0px',
      },
      contentProps: {
        p: '0px',
        borderRadius: '9px',
        minW: '580px',
      },
      okText: null,
      cancelText: null,
      footer: null,
    });
  }, []);
};
