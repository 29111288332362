import { Flex, PopoverBody, usePopoverContext } from '@chakra-ui/react';
import { FC } from 'react';
import BoardItem from './BoardItem';

interface IProps {
  boards: { name: string; eid: string }[];
  value: string;
  updateValue: (value: string) => void;
}

const BoardSelectContent: FC<IProps> = ({ boards, value, updateValue }) => {
  const { onClose } = usePopoverContext();

  return (
    <PopoverBody>
      <Flex
        flexDir='column'
        gap={1}
        maxHeight='380px'
        bg='#FFFFFF'
        overflow='auto'
      >
        {boards?.map((item) => (
          <BoardItem
            key={item.eid}
            item={item}
            onClick={() => {
              updateValue?.(item.eid);
              setTimeout(onClose, 100);
            }}
          />
        ))}
      </Flex>
    </PopoverBody>
  );
};

export default BoardSelectContent;
