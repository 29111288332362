import React, { FC, useMemo } from 'react';
import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faBellSlash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as SopIcon } from 'assets/images/notifications/sop.svg';
import { ReactComponent as FormIcon } from 'assets/images/notifications/form.svg';
import { ReactComponent as TrainingIcon } from 'assets/images/notifications/training.svg';
import { ReactComponent as TaskIcon } from 'assets/images/notifications/task.svg';
import { ReactComponent as AuditsIcon } from 'assets/images/notifications/audit.svg';
import { ReactComponent as SystemUpdates } from 'assets/images/notifications/systemUpdates.svg';
import { ReactComponent as ChatUpdates } from 'assets/images/notifications/chatUpdates.svg';
import { ReactComponent as UnReadIndicatorIcon } from 'assets/images/notifications/unreadNotificationStatusIndicator.svg';
import { ReactComponent as MarkUnreadIcon } from 'assets/images/notifications/markUnread.svg';
import { ReactComponent as MarkReadIcon } from 'assets/images/notifications/markRead.svg';
import { ReactComponent as LauncherIcon } from 'assets/images/notifications/launcher.svg';

import { t } from 'i18next';
import { INotificationItem } from '../types/service-types';
import {
  getNotificationTime,
  removedDomainUrl,
  splitTpParams,
} from '../helper';
import { useUserDataSelector } from 'hooks';
import { AuthRole } from 'authorization';
import { useHistory, useParams } from 'react-router';
import useCombinedStore from 'zustandStore/store';
import { useService } from 'sub-components/training-v2/play/layers';
interface IProps {
  notificationData: INotificationItem;
  handleUpdateNotificationReadStatus: (eid: string, status: boolean) => void;
  isCompactView?: boolean;
}

const NotificationCard: FC<IProps> = ({
  notificationData,
  handleUpdateNotificationReadStatus,
  isCompactView = true,
}) => {
  const {
    subject,
    action,
    predicate,
    isSeen,
    resourceId,
    channels,
    eid,
    category,
    subCategory,
    metaData,
  } = notificationData;

  const [isHovered, setIsHovered] = React.useState(false);
  const history = useHistory();

  const {
    downloadCertificateFlag: { updateDownloadCertificateFlag },
  } = useService();

  const downloadCertificate = async (
    certificateId: string,
    sessionId: string
  ) => {
    if (process.env.REACT_APP_OPS_ENDPOINT) {
      if (sessionId && certificateId) {
        try {
          await updateDownloadCertificateFlag({
            variables: {
              sessionId: sessionId,
            },
          });
        } catch (error) {
          console.error('Error updating certificate flag:', error);
        }
      } else {
        history.replace('/training-v2/dashboard');
      }
    }
  };

  const renderIcon = (): React.ReactElement | null => {
    if (category.includes('location')) return <LauncherIcon />;
    if (category === 'chat') return <ChatUpdates />;
    if (category === 'task') return <TaskIcon />;
    if (category === 'form') return <FormIcon />;
    if (category === 'sop') return <SopIcon />;
    if (category === 'systemSetting') return <SystemUpdates />;
    if (category === 'audit') return <AuditsIcon />;
    if (category === 'training') return <TrainingIcon />;
    return <></>;
  };
  const loggedInUserAuthRole = useUserDataSelector((state) => state.authRole);

  const { selectedInProgressLocation, updateSelectedInProgressLocation } =
    useCombinedStore();
  const { assignedLocationsToUser } = useUserDataSelector((state) => ({
    assignedLocationsToUser: state.locations,
  }));
  const inProgressLocations = useMemo(() => {
    return assignedLocationsToUser?.filter((loc) => !loc?.onboarded) || [];
  }, [assignedLocationsToUser]);

  const { trainingId, sessionId, certificateId } = splitTpParams(
    metaData?.trainingLink
  );
  const dynamicUrlSubCats = () => {
    let url;
    switch (subCategory) {
      case 'sopUpdates': //content edited chapter
        url = `/chapters/view/${resourceId}/`;
        break;
      case 'sopAdded': //new chapter
        url = `/chapters/view/${resourceId}/`;
        break;
      case 'newFolderAdded': //no notifications triggering
        url = `/folders/${resourceId}/`;
        break;
      case 'trainingCreated':
        url = `/training/${metaData?.trainingId}/`;
        break;
      case 'trainingCompleted':
        url = `/training/${resourceId}/`;
        break;
      case 'trainingAssignment':
        //for if the subject contains supervise keyword -> assignees listing
        url = subject.includes('supervise')
          ? `/training/${resourceId}/`
          : `/training-play/${metaData?.trainingId}`;
        break;
      case 'auditReminder':
        url = removedDomainUrl(metaData?.webUrl);
        break;
      // case 'auditUpdates': //tab switching based on the url is not possible, still on details page
      //   url = removedDomainUrl(metaData?.webUrl);
      //   break;
      // case 'auditCompletion':
      //   url = removedDomainUrl(metaData?.webUrl);
      //   break;
      // case 'auditCreated':
      //   url = removedDomainUrl(metaData?.webUrl);
      //   break;
      // case 'taskCreated':
      //   url = removedDomainUrl(metaData?.taskUrl);
      //   break;
      // case 'taskAssigned':
      //   url = removedDomainUrl(metaData?.taskUrl);
      //   break;
      // case 'taskCompleted':
      //   url = removedDomainUrl(metaData?.taskUrl);
      //   break;
      // case 'taskReminder':
      //   url = removedDomainUrl(metaData?.taskUrl);
      //   break;
      case 'llReminder':
        url = removedDomainUrl(
          metaData?.taskUrl ? metaData?.taskUrl : metaData?.loginUrl
        );
        break;
      case 'llAssignment':
        url = removedDomainUrl(metaData?.launcherUrl);
        break;
      default:
        return '/';
    }
    return url;
  };
  const navigateToResource = () => {
    let url = '';
    switch (category) {
      case 'task':
        url = removedDomainUrl(metaData?.taskUrl);
        break;
      case 'training':
        if (metaData?.trainingLink?.includes('evp') && !certificateId) {
          url = `/training-v2/play/view-result/${trainingId}/${sessionId}`;
        } else if (metaData?.trainingLink?.includes('wsv') && certificateId) {
          downloadCertificate(certificateId, sessionId).then(() => {
            window.open(
              `${process.env.REACT_APP_OPS_ENDPOINT}/tp/certify/${certificateId}`,
              '_blank'
            );
          });
        } else if (
          subject.includes('assigned') &&
          metaData?.trainingLink?.includes('preview')
        ) {
          const parts = metaData?.trainingLink
            ?.split('/preview/')?.[1]
            .split('/');
          url = `/training-v2/play/${parts[0]}/${parts[1]}`;
        } else {
          url = removedDomainUrl(metaData?.trainingLink);
        }
        break;
      case 'form':
        url = `/forms/response/${resourceId}/`;
        break;
      case 'audit':
        url = `/audits`;
        break;
      case 'sop':
        url = dynamicUrlSubCats();
        break;
      case 'locationLauncher':
        if (loggedInUserAuthRole === AuthRole.LOCATION_OWNER) {
          const currentSelectedLocation = inProgressLocations.find(
            (location) => location.eid === resourceId
          );
          updateSelectedInProgressLocation(currentSelectedLocation);
          url = dynamicUrlSubCats();
        }
        url = dynamicUrlSubCats();
        break;
      default:
        url = '/';
        break;
    }
    history.push(url);
    //also onClick it should be read
  };

  const navigateAndMarkAsRead = () => {
    navigateToResource();
    if (!isSeen) {
      handleUpdateNotificationReadStatus(eid, true);
    }
  };

  return (
    <>
      <Flex
        alignItems={'center'}
        bgColor={
          !notificationData?.isSeen //isMuted state
            ? '#EDF7FC'
            : 'white'
        }
        borderRadius={'12px'}
        mt={2}
        // mb={1}
        justifyContent={'space-between'}
        p={'12px'}
        boxShadow={isCompactView ? 'sm' : 'none'}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Flex gap={3} cursor={'pointer'} onClick={navigateAndMarkAsRead}>
          {/* renderIcon */}
          {renderIcon() &&
            React.cloneElement(renderIcon() as React.ReactElement, {
              // @ts-ignore
              marginTop: '4px',
              'aria-hidden': true,
              focusable: false,
            })}
          <Flex align={'flex-start'} flexDir={'column'}>
            <Text
              fontWeight={500}
              fontSize={'15px'}
              color={'#111315'}
              noOfLines={1}
            >
              {subject}
            </Text>
            <Box as='span' fontWeight={500} fontSize={'15px'} color={'#6F767E'}>
              {action?.trim()}
              <Box
                as='span'
                fontWeight={500}
                fontSize={'15px'}
                color={'#111315'}
              >
                {` ${predicate}`}
              </Box>
            </Box>
          </Flex>
        </Flex>

        <Flex gap={'16px'} align={'center'}>
          {channels?.length === 1 && (
            <FontAwesomeIcon icon={faBellSlash as IconProp} />
          )}
          <Text
            fontWeight={600}
            fontSize={'13px'}
            lineHeight={'16px'}
            color={'#9A9FA5'}
          >
            {getNotificationTime(notificationData?.createdAt)}
          </Text>
          {!notificationData?.isSeen &&
            React.cloneElement(<UnReadIndicatorIcon />)}
          {notificationData?.isSeen && isHovered && (
            <Tooltip label={t('notifications:utils.markAsUnread')} hasArrow>
              <MarkUnreadIcon
                cursor={'pointer'}
                onClick={() => {
                  handleUpdateNotificationReadStatus(eid, false);
                }}
              />
            </Tooltip>
          )}

          {!notificationData?.isSeen && isHovered && (
            <Tooltip label={t('notifications:utils.markRead')}>
              <MarkReadIcon
                cursor={'pointer'}
                onClick={() => {
                  handleUpdateNotificationReadStatus(eid, true);
                }}
              />
            </Tooltip>
          )}
        </Flex>
      </Flex>
    </>
  );
};
NotificationCard.displayName = 'sc/NC/S/c/NotificationCard.tsx';
export default React.memo(NotificationCard);
