import { Box, Flex, ThemeTypings } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCalendar } from '@fortawesome/pro-light-svg-icons';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'atoms';
import { FC, ReactElement, useEffect, useRef, useState } from 'react';
import DatePicker, { DatePickerProps } from 'react-date-picker';
import { DatePickerCustomStyleWrapper } from './DatePickerStyles';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useOutsideClick } from '@chakra-ui/react';

interface IProps extends DatePickerProps {
  placeholder?: string;
  disableLeftIcon?: boolean;
  colorScheme?: ThemeTypings['colorSchemes'];
  transKey?: string; // i.e if needed to show label like Custom - 12 dec 2025 then pass transKey = "Custom -"
  format?: string;
  rightIcon?: ReactElement;
  width?: string;
  customInput?: ReactElement;
  enableAction?: boolean;
  customActions?: ReactElement; // pass custom actions to datepicker
  variant?: string;
  color?: string;
}

const DatePickerCustom: FC<IProps> = ({
  disableLeftIcon,
  placeholder,
  colorScheme,
  transKey,
  format,
  rightIcon,
  width,
  customInput,
  enableAction,
  customActions,
  onChange,
  value,
  variant,
  color,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(value);
  const [datePickerKey, setDatePickerKey] = useState(Date.now());
  const [position, setPosition] = useState<
    | 'top-left'
    | 'top-center'
    | 'top-right'
    | 'bottom-left'
    | 'bottom-center'
    | 'bottom-right'
  >('bottom-center');

  const containerRef = useRef<HTMLDivElement>(null);
  const pickerContainerRef = useRef<HTMLDivElement>(null);
  const pickerRef = useRef<HTMLDivElement>(null);
  const [boxHeight, setBoxHeight] = useState<string | number>('auto');

  useOutsideClick({
    ref: containerRef,
    handler: () => setOpen(false),
  });

  const { t } = useTranslation(['task']);

  useEffect(() => {
    if (open && containerRef.current && pickerRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const pickerHeight = pickerRef.current.offsetHeight;
      const pickerWidth = pickerRef.current.offsetWidth;
      const viewportHeight = window.innerHeight;
      const viewportWidth = window.innerWidth;

      const spaceBelow = viewportHeight - containerRect.bottom;
      const spaceAbove = containerRect.top;
      const spaceLeft = containerRect.left;
      const spaceRight = viewportWidth - containerRect.right;

      const boxHeight = pickerHeight;
      const boxWidth = pickerWidth;

      let verticalPosition: 'top' | 'bottom' = 'bottom';
      let horizontalPosition: 'left' | 'center' | 'right' = 'center';

      if (spaceBelow < boxHeight && spaceAbove > spaceBelow) {
        verticalPosition = 'top';
      } else {
        verticalPosition = 'bottom';
      }

      if (spaceLeft >= boxWidth) {
        horizontalPosition = 'left';
      } else if (spaceRight >= boxWidth) {
        horizontalPosition = 'right';
      } else {
        horizontalPosition = 'center';
      }

      setPosition(`${verticalPosition}-${horizontalPosition}`);
    }
  }, [open]);

  useEffect(() => {
    if (open && pickerRef.current && pickerContainerRef.current) {
      const pickerHeight = pickerRef.current.scrollHeight; // Total height of the date picker
      const actionHeight = enableAction
        ? pickerContainerRef.current.scrollHeight // Height of actions container
        : 0;

      setBoxHeight(pickerHeight + actionHeight); // Combine heights
    }
  }, [open, enableAction]);

  useEffect(() => {
    if (open && selectedDate !== value) {
      setSelectedDate(value);
    }
  }, [open]);

  const handleOpen = () => {
    setOpen(true);

    requestAnimationFrame(() => {
      if (containerRef.current && pickerContainerRef.current) {
        const containerRect = containerRef.current.getBoundingClientRect();
        const pickerHeight = pickerContainerRef.current.offsetHeight;
        const pickerWidth = pickerContainerRef.current.offsetWidth;
        const viewportHeight = window.innerHeight;
        const viewportWidth = window.innerWidth;

        const spaceBelow = viewportHeight - containerRect.bottom;
        const spaceAbove = containerRect.top;
        const spaceRight = viewportWidth - containerRect.right;
        const spaceLeft = containerRect.left;

        if (spaceBelow >= pickerHeight) {
          setPosition('bottom');
        } else if (spaceAbove >= pickerHeight) {
          setPosition('top');
        } else if (spaceRight >= pickerWidth) {
          setPosition('right');
        } else if (spaceLeft >= pickerWidth) {
          setPosition('left');
        } else {
          setPosition('bottom');
        }
      }
    });
  };

  const onCalendarClose = () => {
    setOpen(false);
  };

  const onCalendarSave = () => {
    setOpen(false);
    onChange?.(selectedDate);
  };

  const datePickerDisplayText = () => {
    if (!value) {
      return placeholder || 'Select a date';
    }

    if (transKey) {
      return ` ${t(transKey, {
        value: moment(value as Date).format(format ? format : 'DD MMMM YYYY'),
      })}`;
    }

    return `${moment(value as Date).format(format ? format : 'DD MMMM YYYY')}`;
  };

  const getValue = () => {
    if (customActions) {
      return value;
    }
    return selectedDate;
  };

  return (
    <DatePickerCustomStyleWrapper ref={containerRef}>
      {customInput ? (
        <Box width='fit-content' onClick={() => setOpen(!open)}>
          {customInput}
        </Box>
      ) : (
        <Button
          variant={variant ? variant : value ? 'solid' : 'outline'}
          onClick={() => {
            setOpen(!open);
          }}
          borderRadius='12px'
          padding='24px 16px'
          fontWeight={500}
          fontSize='14px'
          width={width ? width : '100%'}
          justifyContent='space-between'
          colorScheme={colorScheme ? colorScheme : value ? 'blue' : 'gray'}
          leftIcon={
            disableLeftIcon ? null : (
              <FontAwesomeIcon icon={faCalendar as IconProp} />
            )
          }
          rightIcon={
            rightIcon ? (
              rightIcon
            ) : (
              <FontAwesomeIcon icon={faCaretDown as IconProp} size='lg' />
            )
          }
          disabled={props.disabled}
          color={color}
        >
          {datePickerDisplayText()}
        </Button>
      )}

      {open && (
        <Box
          ref={pickerRef}
          background='#FCFCFC'
          width='340px'
          height={boxHeight}
          boxShadow='rgba(149, 157, 165, 0.2) 0px 8px 24px'
          borderRadius='16px'
          position='absolute'
          zIndex={1}
          id='customPortal'
          {...(position.includes('top')
            ? { bottom: '100%', marginBottom: '8px' }
            : { top: '100%', marginTop: '8px' })}
          {...(position.includes('left')
            ? { right: 0 }
            : position.includes('right')
            ? { left: 0 }
            : { left: '50%', transform: 'translateX(-50%)' })}
        >
          <DatePicker
            key={Date.now()}
            isOpen={open}
            onCalendarClose={onCalendarClose}
            shouldCloseCalendar={(reason) => {
              if (
                reason.reason === 'select' ||
                reason.reason === 'outsideAction'
              ) {
                return false;
              }
              return true;
            }}
            onChange={(date) => {
              if (customActions) {
                onChange?.(date);
              } else {
                setSelectedDate(date);
              }
            }}
            value={getValue()}
            {...props}
          />

          <Flex padding='16px' paddingTop='4px' justifyContent='space-between'>
            {customActions ? (
              customActions
            ) : (
              <>
                <Button
                  variant='outline'
                  onClick={() => {
                    setSelectedDate(new Date());
                    setDatePickerKey(new Date());
                  }}
                >
                  Go to today
                </Button>
                <Flex gap='6px'>
                  <Button variant='outline' onClick={() => setOpen(false)}>
                    Cancel
                  </Button>
                  <Button colorScheme='blue' onClick={onCalendarSave}>
                    Save
                  </Button>
                </Flex>
              </>
            )}
          </Flex>
        </Box>
      )}
    </DatePickerCustomStyleWrapper>
  );
};

export default DatePickerCustom;
