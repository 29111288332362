import React, { FC } from 'react';
import { Accordion, Flex } from '@chakra-ui/react';
import { HeaderColors } from '../../../../configs';
import { AmplitudeEvent } from '../../../../shared';
import { BoxHeader } from '../../../../ui-components';

import TemplatePhase from './TemplatePhase';
import StartButton from './StartButton';

import { TemplateContent } from './template.graphql'; // TODO
import AccordionSkeleton from './AccordionSkeleton';

interface IProps {
  title: string;
  contents: TemplateContent[];
  initialising?: boolean;
  eventName?: AmplitudeEvent;
}

const TemplateView: FC<IProps> = ({
  title,
  contents,
  initialising,
  eventName,
}) => {
  return (
    <Flex flexDir='column' gap='16px'>
      <BoxHeader fontSize='18px' title={title} color={HeaderColors.Purple} />
      <AccordionSkeleton isLoaded={!initialising}>
        <Accordion defaultIndex={[]} allowMultiple>
          {contents.map((content) => {
            return <TemplatePhase key={content.eid} content={content} />;
          })}
        </Accordion>
      </AccordionSkeleton>
      <StartButton
        initialising={initialising}
        contents={contents}
        eventName={eventName}
      />
    </Flex>
  );
};

export default TemplateView;
