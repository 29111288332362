import { useEffect, useRef } from 'react';
import { AmplitudeEvent, deployEvent } from '../../../shared';

export interface IAnalyticData {
  key:
    | 'total'
    | 'completion'
    | 'ontime'
    | 'lagging'
    | 'overallStatus'
    | 'launchRate';
  color?: string;
  count?: string;
  name?: string;
  greenValue?: string;
  orangeValue?: string;
  majorValue?: string;
}

export const useLauncherDashboardEventTrack = (
  locations: IAnalyticData[],
  locationLoading: boolean
): void => {
  const mountRef = useRef<boolean>();

  useEffect(() => {
    if (!locationLoading && !mountRef?.current) {
      const data = locations?.reduce<
        Record<IAnalyticData['key'], IAnalyticData>
      >((acc, val) => {
        acc[val.key] = val;
        return acc;
      }, {} as never);

      const superEvent = {
        total_locations: data?.total?.count,
        avg_completion_rate: data?.completion?.count,
        location_on_time: data?.ontime?.count,
        locations_lagging: data?.lagging?.count,
        // total_tasks_done: '',
        // percent_completion: '',
      };

      deployEvent(AmplitudeEvent.LAUNCHER_DASHBOARD_VISIT, {
        event: {
          // from_where: '',
          location_launcher_visit_funnel_id: '4',
          ...superEvent,
        },
        super: superEvent,
        profile: superEvent,
      });

      mountRef.current = true;
    }
  }, [locationLoading, locations]);
};
