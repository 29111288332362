import React, { FC } from 'react';
import { Box, Center, Flex } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faGlobe } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IProps {
  title: string;
  caption: string;
}

const TrainingAccessRuleSection: FC<IProps> = ({
  title,
  caption,
  children,
}) => {
  return (
    <Flex justify='space-between' align='center'>
      <Flex gap='10px'>
        <Center boxSize='30px'>
          <FontAwesomeIcon icon={faGlobe as IconProp} fontSize='22px' />
        </Center>
        <Flex flexDir='column'>
          <Box as='span' fontWeight={600} lineHeight='24px'>
            {title}
          </Box>
          <Box as='span' fontSize='13px' fontWeight={500} color='#6F767E'>
            {caption}
          </Box>
        </Flex>
      </Flex>
      {children}
    </Flex>
  );
};

export default TrainingAccessRuleSection;
