import React from 'react';
import { forwardRef, IconButton, IconButtonProps } from '@chakra-ui/react';

export const QuizIconButton = forwardRef<IconButtonProps, 'button'>(
  (props, ref) => {
    return <IconButton ref={ref} minWidth={4} height={4} {...props} />;
  }
);

export default QuizIconButton;
