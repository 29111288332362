import React, { FC } from 'react';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Spacer,
  useModalContext,
} from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import { Input } from '../../../../../../atoms';
import { ActionButton } from '../../../../../../ui-components';
import { ISkillVerifyInput } from '../../training-input.types';

import { ReactComponent as SkillVerifierIcon } from '../../../../../../assets/images/training/skill-verifier.svg';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';

export interface SVProps {
  initialValues?: ISkillVerifyInput;
  onAddClick?: (values: ISkillVerifyInput) => void;
}

const AddSkillVerifierContent: FC<SVProps> = ({
  initialValues,
  onAddClick,
}) => {
  const { onClose } = useModalContext();

  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm<ISkillVerifyInput>({
    defaultValues: initialValues || {
      skill: '',
      name: '',
    },
  });

  const onDoneClick = (values: ISkillVerifyInput) => {
    onAddClick?.({
      skill: values.skill?.trim(),
      name: values.name?.trim(),
    });
    deployEvent(AmplitudeEventNames.TRAINING_PATH_SKILL_VERIFIER, {
      event: { skill_verifier_name: values?.name, skill_assess: values?.skill },
    });
    setTimeout(onClose);
  };

  return (
    <Flex flexDir='column' gap={5}>
      <Flex flexDir='column' gap={7} paddingX={7}>
        <Controller
          name='name'
          control={control}
          rules={{
            required: 'Skill verifier name is required',
            validate: (value) => {
              if (!value?.trim()) {
                return 'Enter valid skill verifier name';
              }
            },
          }}
          render={({ field, fieldState }) => (
            <FormControl isInvalid={fieldState.invalid}>
              <FormLabel fontSize='14px'>
                Enter skill verifier name
                <span style={{ color: 'red' }}> *</span>
              </FormLabel>
              <Input size='lg' {...field} />
              <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
            </FormControl>
          )}
        />

        <Controller
          name='skill'
          control={control}
          rules={{
            required: 'Skill title is required',
            validate: (value) => {
              if (!value?.trim()) {
                return 'Enter valid skill title';
              }
            },
          }}
          render={({ field, fieldState }) => (
            <FormControl isInvalid={fieldState.invalid}>
              <FormLabel fontSize='14px'>
                Which skill is being assessed?
                <span style={{ color: 'red' }}> *</span>
              </FormLabel>
              <Input size='lg' {...field} />
              <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
            </FormControl>
          )}
        />

        <Flex justify='center'>
          <SkillVerifierIcon />
        </Flex>
      </Flex>

      <Flex
        gap={5}
        paddingY='12px'
        paddingX={4}
        bgColor='#FCFCFC'
        borderTop='1px solid #EFEFEF'
        borderBottomRadius='8px'
      >
        <Spacer />

        <ActionButton
          fontSize='13px'
          colorScheme='blue'
          isDisabled={!isDirty}
          actionFn={handleSubmit(onDoneClick)}
        >
          Done
        </ActionButton>
      </Flex>
    </Flex>
  );
};

export default AddSkillVerifierContent;
