import { Divider, Flex, Text } from '@chakra-ui/react';
import { HeaderColors } from 'configs';
import React, { FC } from 'react';
import { BoxHeader } from 'ui-components';
import { ReactComponent as ChevronforwardIcon } from 'assets/images/chevronForward.svg';
import { PageNameType } from './MainContainer';
import { ClearFilterType } from './FilterContainer';
import useCombinedStore from 'zustandStore/store';

interface IProps {
  filters: any;
  setPageName: React.Dispatch<React.SetStateAction<PageNameType>>;
  clearFilters: (type?: ClearFilterType) => void;
}

const BaseFilter: FC<IProps> = ({ setPageName, filters, clearFilters }) => {
  const { tableTabIndex } = useCombinedStore();
  return (
    <Flex gap={4} flexDir='column'>
      <Flex alignItems={'center'} mt={'10px'} justifyContent={'space-between'}>
        <BoxHeader title='Filters' color={HeaderColors.Purple} />
        <Text
          cursor={'pointer'}
          onClick={() => clearFilters()}
          fontSize={'14px'}
          fontWeight={400}
          color={filters?.totalFiltersTypes > 0 ? '#2A85FF' : '#6F767E'}
        >
          Clear All
        </Text>
      </Flex>
      <Flex
        mt={'20px'}
        onClick={() => {
          if (tableTabIndex > 0) {
            setPageName('job');
          } else {
            setPageName('types');
          }
        }}
        cursor={'pointer'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Flex gap={4}>
          <Text
            color={'#333B4F'}
            fontWeight={400}
            lineHeight='16px'
            fontSize='14px'
          >
            {tableTabIndex > 0 ? 'By job' : 'Type'}
          </Text>
          <Text
            color={'#6F767E'}
            fontWeight={400}
            lineHeight='16px'
            fontSize='14px'
          >
            {filters.selectedJobs.length > 0
              ? `${
                  filters?.selectedJobs?.length
                    ? '(' + filters?.selectedJobs?.length + ' selected)'
                    : ''
                }`
              : `${
                  filters?.selectedTypes?.length
                    ? '(' + filters?.selectedTypes?.length + ' selected)'
                    : ''
                }`}
          </Text>
        </Flex>
        <ChevronforwardIcon />
      </Flex>
      <Divider />
      <Flex
        onClick={() => {
          if (tableTabIndex > 0) {
            setPageName('locations');
          } else {
            setPageName('categories');
          }
        }}
        cursor={'pointer'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Flex gap={4}>
          <Text
            color={'#333B4F'}
            fontWeight={400}
            lineHeight='16px'
            fontSize='14px'
          >
            {tableTabIndex > 0 ? 'By location' : 'Category'}
          </Text>
          <Text
            color={'#6F767E'}
            fontWeight={400}
            lineHeight='16px'
            fontSize='14px'
          >
            {filters?.selectedLocations.length > 0
              ? `${
                  filters?.selectedLocations?.length
                    ? '(' + filters?.selectedLocations?.length + ' selected)'
                    : ''
                }`
              : `${
                  filters?.selectedCategories?.length
                    ? '(' + filters?.selectedCategories?.length + ' selected)'
                    : ''
                }`}
          </Text>
        </Flex>
        <ChevronforwardIcon />
      </Flex>
      <Divider />
      <Flex
        onClick={() => setPageName('statuses')}
        cursor={'pointer'}
        mb={'30px'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Flex gap={4}>
          <Text
            color={'#333B4F'}
            fontWeight={400}
            lineHeight='16px'
            fontSize='14px'
          >
            {tableTabIndex > 0 ? 'By status' : 'Status'}
          </Text>
          <Text
            color={'#6F767E'}
            fontWeight={400}
            lineHeight='16px'
            fontSize='14px'
          >
            {`${
              filters?.selectedStatuses?.length
                ? '(' + filters?.selectedStatuses?.length + ' selected)'
                : ''
            }`}
          </Text>
        </Flex>
        <ChevronforwardIcon />
      </Flex>
    </Flex>
  );
};

export default BaseFilter;
