import React, { FC, useEffect, useRef } from 'react';
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Box, Button, Center, Flex, Spacer, useToast } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';

import { AccessibilityType, QuestionType } from '../../../types';
import { AmplitudeEvent, deployEvent } from '../../../shared';

import { IFormInput } from './submit-form.types';
import Section from './Section';
import { FormResponse, getFormById } from '../form.graphql';
import { toFormResponse, toMobilePreview } from '../dataConvert';
import QuestionContainer from './QuestionContainer';
import {
  addFormResponse,
  FormSubmitResponse,
} from '../from-preview/form-preview.graphql';
import Loader from '../../Loader';

import TextSection from './TextSection';
import Acknowledge from './Acknowledge';
import InactiveFormEmptyState from './InactiveFormEmptyState';
import { useUserDataSelector } from 'hooks';
import { AuthRole } from 'authorization';

export type UnauthorisedType = 'NOT_FOUND' | 'UNAUTHORISED';

interface IProps {
  formId: string;
  onDataLoad?: (data: FormResponse['FormById']) => void;
  onSubmitted?: (
    returnedFormData?: FormSubmitResponse['addFormResponse']
  ) => void;
  onUnauthorised?: (type: AccessibilityType) => void;
  canOverride?: boolean;
}

const SubmitForm: FC<IProps> = ({
  formId,
  onDataLoad,
  onSubmitted,
  onUnauthorised,
  canOverride,
}) => {
  const { t } = useTranslation(['common', 'form']);
  const submitRef = useRef(false);
  const methods = useForm<IFormInput>();
  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  });

  const { loggedInUserAuthRole, loggedInUserId } = useUserDataSelector(
    (state) => {
      return {
        loggedInUserId: state?.eid,
        loggedInUserAuthRole: state?.authRole,
      };
    }
  );

  const { isSubmitSuccessful } = methods.formState;

  const [fetchForm, { loading: isFetching, data: formData }] =
    useLazyQuery<FormResponse>(getFormById, {
      fetchPolicy: 'network-only',
      onCompleted: (response) => {
        if (response.FormById?.eid) {
          const newValues = toMobilePreview(response.FormById as any);
          methods.reset(newValues);
        }
        onDataLoad?.(response.FormById);
      },
      onError: (error) => {
        for (let err of error.graphQLErrors) {
          if (err.extensions.code === 'FORBIDDEN') {
            onUnauthorised?.('UNAUTHORISED');
            break;
          }
          if (err.extensions.code === 'BAD_USER_INPUT') {
            onUnauthorised?.('NOT_FOUND');
            break;
          }
        }
      },
    });

  const [submitForm, { loading }] = useMutation<FormSubmitResponse>(
    addFormResponse,
    {
      onCompleted: (data) => {
        deployEvent(AmplitudeEvent.FORMS_RESPONSE_SUBMITTED);
        submitRef.current = false;
        onSubmitted?.(data?.addFormResponse);
      },
      onError: () => {
        submitRef.current = false;
      },
    }
  );

  useEffect(() => {
    fetchForm({
      variables: {
        eid: formId,
        override: canOverride,
      },
    });
  }, [formId, canOverride]);

  const { fields } = useFieldArray<IFormInput, 'response'>({
    control: methods.control,
    name: 'response',
  });

  const onFinish = async (values: IFormInput) => {
    if (submitRef.current) {
      return null;
    }
    submitRef.current = true;
    const finalValues = toFormResponse(values);
    const response = await submitForm({
      variables: {
        input: finalValues,
      },
    });

    if (response.errors) {
      toast({
        status: 'error',
        title: t('error'),
        description: t('form:responseNotSubmitted'),
      });
      throw 'Form submission failed';
    }
  };

  if (isFetching) {
    return (
      <Flex maxW='320px' mt='60px' mx='auto'>
        <Loader size='lg' mx='auto' />
      </Flex>
    );
  }

  if (formData?.FormById?.status === 'inactive') {
    return (
      <Center width='800px'>
        <InactiveFormEmptyState />
      </Center>
    );
  }

  if (!fields.length) {
    return null;
  }

  return (
    <Flex flex={1}>
      <FormProvider {...methods}>
        <Flex
          as='form'
          flex={1}
          direction='column'
          onSubmit={methods.handleSubmit(onFinish)}
        >
          <Controller
            name='description'
            control={methods.control}
            shouldUnregister
            render={({ field }) => {
              if (!field.value) {
                return <React.Fragment />;
              }
              return (
                <Box color='#272b30' mb={3} whiteSpace='pre-line'>
                  {field.value}
                </Box>
              );
            }}
          />

          {fields?.map((question, index) => {
            if (question.qType === QuestionType.SECTION) {
              return <Section key={index} title={question.label} />;
            }
            if (question.qType === QuestionType.TEXT_SECTION) {
              return <TextSection key={index} question={question} />;
            }
            if (question.qType === QuestionType.ACKNOWLEDGMENT) {
              return (
                <Acknowledge
                  key={index}
                  currentIndex={index}
                  question={question}
                />
              );
            }
            return (
              <QuestionContainer
                key={index}
                currentIndex={index}
                question={question}
                isDisabled={isSubmitSuccessful}
              />
            );
          })}
          <Spacer display='flex' bg='white' mt='20px' mb='10px'>
            {isSubmitSuccessful ? (
              <Button
                mt='auto'
                width='full'
                bg='#83bf6e'
                color='#fcfcfc'
                _hover={{
                  bg: '#83bf6ecc',
                  color: '#fcfcfc',
                }}
                _active={{
                  bg: '#83bf6ecc',
                }}
                leftIcon={<CheckIcon />}
              >
                {t('submitted')}
              </Button>
            ) : (
              <Button
                mt='auto'
                width='full'
                colorScheme='blue'
                type='submit'
                isLoading={loading}
              >
                {t('submit')}
              </Button>
            )}
          </Spacer>
        </Flex>
      </FormProvider>
    </Flex>
  );
};

export default SubmitForm;
