import React, {
  BaseSyntheticEvent,
  Dispatch,
  ReactNode,
  SetStateAction,
} from 'react';
import { DropzoneOptions } from 'react-dropzone';
import { UserEntityData } from 'shared/graphql/shared-types';
import { LocationStatus } from 'sop-commons/src/client';
import { Location } from 'sub-components/training-v2/dashboard/components/TrackPathDashboard/crossTrain/LocationBadge';
import { IGetTpSessionResponse } from 'sub-components/training-v2/dashboard/components/TrackPathDashboard/query/track-dashboard-graphql';
import { TSkillVerifierItem } from '../quiz/useEvaluateResponses';
import { ApolloQueryResult } from '@apollo/client';
import { IReAssignInfo } from 'sub-components/training-v2/dashboard/components/TrackPathDashboard/components/PathActions/ReAssignTpForUser';
import { DetailsFiltersType } from 'sub-components/training-v2/dashboard/components/TrackPathDashboard/components/DetailsPageFilters/AnalyticsContainer';

export interface TrackPathEntity {
  id: string;
  pathName: {
    name: string;
    profilePic: string;
    lastUpdated: string;
    category: string;
  };
  createdBy: string;
  createdOn: string;
  progress: {
    assigned: number;
    completed: number;
  };
  isOverdue: boolean;
  lastActivity: string;
}

export interface IQuizResponsesTable {
  key: string;
  question: any;
  questionType: 'mcq' | 'true/false' | 'match' | 'reorder' | 'blanks';
  response: string[];
  result: { eid: string; result: 'correct' | 'wrong' }[];
  points: number | string;
}
type ReviewSkills = {
  rating?: number;
  comment?: string;
  files?: {
    url: string;
    uploadedBy?: string;
    addedOn?: string;
    fileSize?: number;
  }[];
  name: string;
};
export interface ISkillVerifierFormInput {
  skills: ReviewSkills[];
}
export interface IEvidenceUploadSkeletonProps {
  accept?: DropzoneOptions['accept'];
  descType?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFileDrop?: DropzoneOptions['onDrop'];
  disabled?: boolean;
}

export interface IAddAnotherSkillProps {
  skillIndex: number;
  handler: React.Dispatch<React.SetStateAction<Record<number, boolean>>>;
  visiblilityState: Record<number, boolean>;
}

export type IVerifierProps = Pick<IAddAnotherSkillProps, 'skillIndex'>;
export interface IEvaluationSummaryProps {
  quizScore: number;
  skillVerifierScore: number;
  onReEvaluate?: () => void;
  maxScore: number;
  onSubmitHandler: (
    e?: BaseSyntheticEvent<object, any, any> | undefined
  ) => Promise<void>;
  isLoading: boolean;
  tpScheduleInfo: IReAssignInfo;
  sessionId: string;
  userId: string;
  hasSkillVerifier: boolean;
  tp: any;
  onEvaluateComplete: (() => void) | undefined;
}
export type TCyclesSession = {
  endDate?: Date;
  startDate?: Date;
  eid: string;
};
export interface ITrackPathHeaderProps {
  tpName: string;
  publishedStatus: string;
  crossTrainModalData: any[];
  trainingId: string;
  sessionId: string;
  crossTrainAcceess: boolean;
  editPathAccess: boolean;
  adminTpEditAccess: Boolean;
  tp: any;
  isTpRepeating: Boolean;
  repeatingCycles: { endDate: Date; startDate: Date; eid: string }[];
  setCycleSessionId: Dispatch<SetStateAction<TCyclesSession>>;
  cyclesSessionId: TCyclesSession;
  refetchTpSessionData?: () => void;
}
export interface SummaryDataItem {
  createdOn: string;
  createdBy: string;
  category: string;
  supervisedBy: {
    name: string;
    avatar: string;
  }[];
  frequency: string;
  status: string;
}
export interface ITrackPathSummarProps {
  summaryData: TTPSummary;
}

interface ProgressItem {
  count: number;
  status: string;
}

export interface IProgressTrackerProps {
  progressData: { totalMembers: number; progress: ProgressItem[] };
}
export interface IAverageTpDetailsProps {
  averageTPData: {
    averageScore: string;
    meta: { value: string; label: string; icon: ReactNode }[];
  };
}
export interface ITrackMembersFilterItem {
  // label: 'By job' | 'By location' | 'By status';
  // value: 'job' | 'location' | 'status';
  // options:
  //   | string[]
  //   | {
  //       eid: string;
  //       name: string;
  //       locationStatus?: LocationStatus;
  //     };
}
export interface ITrainingContextData {
  trackingTpSearchQuery: string;
  trackMembersData?: Array<
    Partial<MembersTrainingPath> & Partial<UserEntityData>
  >;
  filteredTrackMembersData?: Array<
    Partial<MembersTrainingPath> & Partial<UserEntityData>
  >;
}

export type TPSession = {
  eid: string;
  completedCount: number;
  assignedCount: number;
  updatedAt: string;
  overdueCount: number;
};

export type AccessPermissionType =
  | 'VIEW'
  | 'EDIT'
  | 'ASSIGN'
  | 'CAN_CROSSTRAIN'
  | 'CANNOT_CROSSTRAIN';

export type RoleAccessPermission = {
  admin?: AccessPermissionType;
  locationOwner?: AccessPermissionType;
  superadmin?: AccessPermissionType;
};

export type TPItems = {
  eid: string;
  title: string;
  thumbnail: string;
  category: string;
  updatedAt: string;
  createdBy: string;
  createdAt: string;
  lastSession: TPSession;
  status?: string;
  accessPermission: RoleAccessPermission;
};

export interface ITPCategory {
  eid: string;
  name: string;
}

export interface ITPFilter {
  filter?: {
    query?: string;
  };
  sort: string;
  page: number;
  perPage: number;
}

export type TPFiltersParams = {
  pageIndex?: number;
  sort?: string;
};

export type MembersTrainingPath = {
  userId: string;
  completed: number;
  totalScore: number;
  scoringSession: number;
  avgScore: number;
  total: number;
  overdue: number;
};

export interface IOverallLocationWiseAnalytics {
  locationId: string;
  completed: number;
  total: number;
}

export interface IOverallUserWiseAnalytics {
  locationId: string;
  completed: number;
  total: number;
}

export interface IFinalOverallLocationWiseAnalytics {
  userId: string;
  locationId: string;
  completed: number;
  total: number;
  name: string;
  completionPercent: number;
  isOwnLocation: boolean;
  rank?: number;
}

export interface IFinalOverallUserWiseAnalytics {
  userId: string;
  locationId: string;
  userName: string;
  locationName: string;
  completed: number;
  total: number;
  completionPercent: number;
  rank?: number;
}

export interface IOverallAnalytics {
  totalActiveTp?: number;
  totalAssignee?: number;
  inProgressAssignee?: number;
  notStartedAssignee?: number;
  completedAssignee?: number;
  totalOverdueAssignee?: number;
  needsEvaluationAssignee?: number;
  uniqueAssignees?: number;
  uniqueOverdueAssignee?: number;
}

export type EnumTPSessionAssignedUsersStatus =
  | 'assigned'
  | 'started'
  | 'completed';

export type TMemberAnalyticsItem = {
  dueDate: string;
  completedOn: Date;
  assignedOn: Date;
  startedOn: Date;
  score: number;
  userId: string;
  crossTrained: boolean;
  isEvaluated: boolean;
  evaluatedOn: Date;
  name: string;
  job: string;
  locations: {
    eid: string;
    name: string;
    locationStatus?: LocationStatus | undefined;
    locationIcon: ReactNode;
  };
  profilePic: string;
  trainingPathEndDate: string;
  completedProgress: number;
  total: number;
  status: 'completed' | 'pending';
  averageTimeSpent: string;
  actions: string;
  skillVerifier: TSkillVerifierItem;
  maxScore: number;
  isRepeating: boolean;
  hasScore: number;
  scorePercent: number;
  isRemoved: boolean;
  ratings: number[];
  evaluatedBy: string | undefined;
  userMaxScore: number;
};
export interface IAnalyticsContainerProps {
  memberAnalytics: TMemberAnalyticsItem[];
  trainingId: string;
  crossTrainAcceess: boolean;
  editPathAcccess: boolean;
  crossTrainModalData: string[];
  pathAnalytics: TPPathAnalyticsItem[];
  tpName: string;
  tp: IGetTpSessionResponse | undefined;
  sessionId: string;
  crossTrainData: string[];
  refetchTpSession: (
    variables?:
      | Partial<{
          eid: string;
          filter?: Record<'userId', string[]>;
        }>
      | undefined
  ) => Promise<ApolloQueryResult<IGetTpSessionResponse>>;
}
export type TMemberAnalyticsProps = Pick<
  IAnalyticsContainerProps,
  'memberAnalytics' | 'trainingId' | 'tpName' | 'tp' | 'refetchTpSession'
>;

export type TTPDetails = {
  averageScore: {
    totalAverageScore: number;
    maxScore: number;
    averagePercent: number;
  };
  averageCompletionRate: number;
  averageTimeSpentOnTP: number;
};

export type TTPProgress = {
  totalMembers: number;
  membersYetToStart: number;
  onGoingMembers: number;
  completedMembers: number;
  overDueMembers: number;
};
export type TTPSummary = {
  tpSessionSummaryCategory: string;
  tpSessionCreatedOn: string;
  tpSessionCreatedBy: string;
  tpSessionSupervisedBy: {
    name: string;
    avatar: string;
  }[];
  tpSessionRepeatFrequency: string;
  tpSessionStatus: string;
};

export interface ITrackPathSummaryContainer {
  tpDetails: TTPDetails;
  summaryData: TTPSummary;
  progressData: TTPProgress;
}
export const EnumTPContentsType = {
  sop: 'Chapter',
  card: 'Card',
  quiz: 'Quiz',
  form: 'Form',
  milestone: 'Milestone',
};

export type TPPathAnalyticsItem = {
  eid: string;
  thumbnail: string;
  type: keyof typeof EnumTPContentsType;
  title: string;
  progress: {
    onTime: number;
    overDue: number;
  };
  createdOn?: Date;
  createdBy?: string;
};
export interface IPathAnalytics {
  pathAnalyticsData?: TPPathAnalyticsItem[];
}

export interface ITrainingDraftData {}

export interface MyEvaluationsCount {
  TpNewEvaluations: number;
}
export interface IAnalyticsContainerHeaderProps {
  trackMembersData: TMemberAnalyticsItem[];
  crossTrainMembersData: TMemberAnalyticsItem[];
  setTrackMembersData: Dispatch<SetStateAction<TMemberAnalyticsItem[]>>;
  setCrossTrainMembersData: Dispatch<SetStateAction<TMemberAnalyticsItem[]>>;
  detailsFilters: DetailsFiltersType;
  setDetailsFilters: React.Dispatch<SetStateAction<DetailsFiltersType>>;
  tabIndex: number;
  originalTrackMembersData: TMemberAnalyticsItem[];
  originalCrossTrainMembersData: TMemberAnalyticsItem[];
}
