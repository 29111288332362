import { useLazyQuery } from '@apollo/client';
import { Center, Flex } from '@chakra-ui/react';
import { useUserDataSelector } from 'hooks';
import React, { FC, useEffect, useState, useCallback } from 'react';
import {
  GET_TP_OVERALL_USER_DETAILS_ANALYTICS,
  MY_EVALUATIONS_COUNT,
} from 'sub-components/training-v2/shared/query/dashboard.graphql';
import { MyEvaluationsCount } from 'sub-components/training-v2/shared/types';
import { IOverallUserDetailsAnalytics } from '../types/myTraining.types';
import PerformanceInfo from './PerformanceInfo';
import ProgressTrackerGraph from './ProgressTrackerGraph';
import EmptyState from 'sub-components/EmptyState';

const LeftSection: FC = () => {
  const [overallUserDetailsAnalytics, setOverallUserDetailsAnalytics] =
    useState<IOverallUserDetailsAnalytics>({});
  const userId = useUserDataSelector((state) => state?.eid);

  const [
    getOverallUserDetailsAnalytics,
    { loading: overallUserDetailsAnalyticsLoader, error: overallError },
  ] = useLazyQuery<
    { TpOverallUserDetailsAnalytics: IOverallUserDetailsAnalytics },
    { userId: string }
  >(GET_TP_OVERALL_USER_DETAILS_ANALYTICS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const { evaluated, ...rest } = data?.TpOverallUserDetailsAnalytics || {};
      setOverallUserDetailsAnalytics((prev) => ({
        ...prev,
        ...rest,
      }));
    },
    onError: (error) => {
      console.error('Error fetching overall user details analytics:', error);
    },
  });

  const [
    getMyEvaluationCount,
    { loading: gettingMyEvaluationCount, error: evaluationError },
  ] = useLazyQuery<MyEvaluationsCount>(MY_EVALUATIONS_COUNT, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setOverallUserDetailsAnalytics((prev) => ({
        ...prev,
        evaluated: data?.TpNewEvaluations,
      }));
    },
    onError: (error) => {
      console.error('Error fetching evaluation count:', error);
    },
  });

  const fetchData = useCallback(() => {
    if (userId) {
      getOverallUserDetailsAnalytics({ variables: { userId } });
      getMyEvaluationCount();
    }
  }, [userId, getOverallUserDetailsAnalytics, getMyEvaluationCount]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (overallError || evaluationError) {
    return (
      <Flex
        borderRadius={'8px'}
        border={'2px solid #EFEFEF'}
        alignItems={'center'}
        justifyContent={'center'}
        height={'100%'}
        width={'100%'}
      >
        <EmptyState
          image='DataCenter'
          title='Error while loading data, please refresh the page!'
          imageSize='300px'
        />
      </Flex>
    );
  }

  return (
    <Flex width='100%' flexDir='column' gap={2}>
      <Flex width='100%'>
        <PerformanceInfo
          overallUserDetailsAnalytics={overallUserDetailsAnalytics}
          overallUserDetailsAnalyticsLoader={
            overallUserDetailsAnalyticsLoader || gettingMyEvaluationCount
          }
        />
      </Flex>
      <Flex height={'100%'} width='100%'>
        <ProgressTrackerGraph
          overallUserDetailsAnalytics={overallUserDetailsAnalytics}
          overallUserDetailsAnalyticsLoader={overallUserDetailsAnalyticsLoader}
        />
      </Flex>
    </Flex>
  );
};

LeftSection.displayName =
  'displayName:sub-components/training-v2/dashboard/components/myTrainings/topSection/LeftSection';

export default LeftSection;
