import React, { FC, useEffect, useState } from 'react';
import { Box, Center, Flex } from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripDotsVertical } from '@fortawesome/pro-solid-svg-icons';
import {
  faCircleCheck,
  faCircleXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { QuizQFormInput } from '../../quiz-form/quiz-form.types';
import PreviewContainer from '../PreviewContainer';

interface Props {
  data: QuizQFormInput;
}

const MatchSetPreview: FC<Props> = ({ data }) => {
  const [shuffledPairs, setShuffledPairs] = useState(() => {
    if (!data.pairs) return [];
    return [...data.pairs].sort(() => Math.random() - 0.5);
  });
  const [showResults, setShowResults] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setShuffledPairs([...data.pairs].sort(() => Math.random() - 0.5));
  }, [data.pairs]);

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination || showResults) return;

    const items = Array.from(shuffledPairs);
    const [removed] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, removed);
    setShuffledPairs(items);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    await new Promise((resolve) => setTimeout(resolve, 500));
    setShowResults(true);
    setIsSubmitting(false);
  };

  const isMatchCorrect = (index: number): boolean => {
    if (!showResults) return false;
    return shuffledPairs[index]?.uid === data.pairs?.[index]?.uid;
  };

  const getCorrectAnswer = (index: number): string => {
    return data.pairs?.[index]?.right || '';
  };

  return (
    <PreviewContainer
      title={data.label}
      thumbnail={data.thumbnail}
      onSubmit={handleSubmit}
      showSubmit={!showResults}
      isSubmitting={isSubmitting}
      isDisabled={false}
    >
      <Box maxHeight='calc(100vh - 300px)' overflow='auto'>
        <Flex direction='row' width='full'>
          <Flex direction='column' gap='6px' width='55%'>
            {data.pairs?.map((pair, index) => (
              <Flex
                key={pair.uid}
                align='center'
                width='full'
                gap='6px'
                pr='6px'
              >
                <Box
                  flex={1}
                  p='10px'
                  borderLeft={`2px solid ${
                    showResults
                      ? isMatchCorrect(index)
                        ? 'rgba(131, 191, 110, 1)'
                        : 'rgba(255, 106, 85, 1)'
                      : 'rgba(42, 133, 255, 1)'
                  }`}
                  borderRightRadius='8px'
                  bg='white'
                  minH='62.5px'
                >
                  <Box fontSize='9px' fontWeight='medium'>
                    {pair.left}
                  </Box>
                </Box>
                <Center>
                  <ArrowForwardIcon boxSize={5} color='gray.500' />
                </Center>
              </Flex>
            ))}
          </Flex>

          <Box width='45%'>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable
                droppableId='match-set-droppable'
                isDropDisabled={showResults}
              >
                {(provided) => (
                  <Box ref={provided.innerRef} {...provided.droppableProps}>
                    {shuffledPairs.map((pair, index) => {
                      return (
                        // @ts-ignore
                        <Draggable
                          key={pair.uid}
                          draggableId={pair.uid}
                          index={index}
                          isDragDisabled={showResults}
                        >
                          {(providedDrag, snapshotDrag) => (
                            <Box
                              ref={providedDrag.innerRef}
                              {...providedDrag.draggableProps}
                              mb='6px'
                            >
                              <Flex
                                p='10px'
                                borderRadius='8px'
                                bg={
                                  showResults
                                    ? isMatchCorrect(index)
                                      ? 'rgba(181, 228, 202, 0.3)'
                                      : 'rgba(255, 106, 85, 0.2)'
                                    : snapshotDrag.isDragging
                                    ? 'rgba(177, 229, 252, 0.3)'
                                    : 'white'
                                }
                                border={
                                  showResults
                                    ? isMatchCorrect(index)
                                      ? '1px solid rgba(131, 191, 110, 1)'
                                      : '1px solid rgba(255, 106, 85, 1)'
                                    : '1px solid #E2E8F0'
                                }
                                _hover={
                                  !showResults
                                    ? {
                                        bg: 'rgba(177, 229, 252, 0.3)',
                                        border:
                                          '1px solid rgba(42, 133, 255, 1)',
                                      }
                                    : {}
                                }
                                align='center'
                                justify='space-between'
                                gap='6px'
                                minH='62.5px'
                                {...providedDrag.dragHandleProps}
                              >
                                <Box
                                  fontWeight={!showResults ? 600 : 500}
                                  fontSize='9px'
                                  noOfLines={3}
                                >
                                  {pair.right}
                                </Box>
                                <Box>
                                  {!showResults ? (
                                    <FontAwesomeIcon
                                      icon={faGripDotsVertical as IconProp}
                                      color='#6F767E'
                                    />
                                  ) : isMatchCorrect(index) ? (
                                    <FontAwesomeIcon
                                      icon={faCircleCheck as IconProp}
                                      fontSize='12px'
                                      color='rgba(131, 191, 110, 1)'
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      icon={faCircleXmark as IconProp}
                                      fontSize='12px'
                                      color='rgba(255, 106, 85, 1)'
                                    />
                                  )}
                                </Box>
                              </Flex>
                              {/*{showResults && !isMatchCorrect(index) && (*/}
                              {/*  <Box fontSize='14px' color='#6F767E' mt={2}>*/}
                              {/*    Correct answer: {getCorrectAnswer(index)}*/}
                              {/*  </Box>*/}
                              {/*)}*/}
                            </Box>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
        </Flex>
      </Box>
    </PreviewContainer>
  );
};

export default MatchSetPreview;
