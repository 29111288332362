import React, { FC } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  IconButton,
  Input,
  Switch,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripDotsVertical } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { QuestionType } from 'types';

import FormInput from '../../../../atoms/FormInput';
import { IFormInput } from '../form.types';
import AnswerSelection from './AnswerSelection';

interface IProps {
  currentIndex: number;
  questionIndex: number;
  isReadOnly?: boolean;
  onRemove?: () => void;
  fieldId: string;
}

const OptionInput: FC<IProps> = ({
  questionIndex,
  currentIndex,
  isReadOnly,
  onRemove,
  fieldId,
}) => {
  const { t } = useTranslation('form');
  const { control } = useFormContext<IFormInput>();

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    setActivatorNodeRef,
    isDragging,
  } = useSortable({ id: fieldId, data: { index: currentIndex } });

  const questionType: QuestionType = useWatch({
    control: control,
    name: `questions.${questionIndex}.qType`,
  });

  const isArchived: boolean = useWatch({
    name: `questions.${questionIndex}.options.${currentIndex}.isArchieved`,
  });

  const isOther: boolean = useWatch({
    name: `questions.${questionIndex}.options.${currentIndex}.isOther`,
  });

  if (isArchived) {
    return null;
  }

  return (
    <Flex
      mt={5}
      ref={setNodeRef}
      transform={CSS.Translate.toString(transform)}
      position='relative'
      data-grabbed={isDragging ? isDragging : undefined}
      _grabbed={{ zIndex: 2 }}
    >
      <Center
        {...attributes}
        {...listeners}
        ref={setActivatorNodeRef}
        width={6}
        height={12}
        mr={3}
        color='#6F767E'
        _hover={{ color: '#2A85FF' }}
        _disabled={{ opacity: '0.4', cursor: 'not-allowed' }}
        data-grabbed={isDragging ? isDragging : undefined}
        _grabbed={{ color: '#2A85FF' }}
      >
        <FontAwesomeIcon
          icon={faGripDotsVertical as IconProp}
          fontSize='20px'
        />
      </Center>

      <AnswerSelection
        questionType={questionType}
        currentIndex={currentIndex}
        questionIndex={questionIndex}
        isReadOnly={isReadOnly}
      />

      <Controller
        name={`questions.${questionIndex}.options.${currentIndex}.subTitle`}
        control={control}
        render={({ field }) => {
          return <Input size='lg' {...field} hidden isReadOnly={isReadOnly} />;
        }}
      />

      <Controller
        name={`questions.${questionIndex}.options.${currentIndex}.isDisabled`}
        render={({ field }) => {
          return (
            <Switch
              isChecked={field.value}
              onChange={field.onChange}
              hidden
              isReadOnly={isReadOnly}
            />
          );
        }}
      />

      <Controller
        name={`questions.${questionIndex}.options.${currentIndex}.label`}
        control={control}
        rules={{
          required: {
            value: !isOther,
            message: t('validation.option_required'),
          },
          validate: (value) => {
            if (value?.trim()?.length === 0 && !isOther) {
              return t('validation.option_enter');
            }
          },
          deps: [`questions.${questionIndex}.label`],
        }}
        defaultValue=''
        render={({ field, fieldState }) => {
          return (
            <FormControl
              ml={4}
              isInvalid={!!fieldState.error}
              isReadOnly={isReadOnly || isOther}
            >
              <FormInput
                size='lg'
                variant='auditOutline'
                {...field}
                placeholder={isOther ? t('other') : undefined}
                data-grabbed={isDragging ? isDragging : undefined}
                _grabbed={{ borderColor: '#2A85FF' }}
              />
              <FormErrorMessage>
                <span>{fieldState.error?.message}</span>
              </FormErrorMessage>
            </FormControl>
          );
        }}
      />
      <Flex h={12} align='center' pl={2} mr={-3}>
        <IconButton
          aria-label={'remove'}
          size='xs'
          icon={<CloseIcon />}
          onClick={onRemove}
        />
      </Flex>
    </Flex>
  );
};

export default OptionInput;
