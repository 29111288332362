import { cloneDeep } from '@apollo/client/utilities';
import { Flex, useToast } from '@chakra-ui/react';
import moment from 'moment';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';
import { toNumber } from 'utils';
import { useHistory, useRouteState } from '../../../../routes';
import { ActionButton } from '../../../../ui-components';
import { useTrainingAccessRule } from './access-rules';
import { getNthDate } from './AssignPublishSection/Schedule/FrequencySelect';
import { useTrainingCreator } from './common';
import { TFormInput } from './training-input.types';
import { useErrorHandler } from './useErrorHandler';
import { usePublishHandler } from './usePublishHandler';
import { useSaveHandler } from './useSaveHandler';
import { useLocationsList } from './AssignPublishSection/Assignee/location-select';
import { useReactiveVar } from '@apollo/client';
import { usersEntityObj } from 'sub-components/Header';

export const TriggerData = {
  '0-1': ['title', 'category', 'thumbnail'],
  '0-2': ['title', 'category', 'thumbnail'],
  '1-2': ['contents'],
};

export const TrainingActionsname = (index: number) => {
  if (index === 0) {
    return 'Training path details';
  } else if (index === 1) {
    return 'Add content';
  } else if (index === 2) {
    return 'Assign and publish';
  }
};

export const getRepeatCycleText = (repeatCycle: any) => {
  const { type, repeatValue, dayOfTheWeek, dayOfTheMonth, monthOfTheYear } =
    repeatCycle || {};

  const repeatVal = toNumber(repeatValue, 0);

  switch (type) {
    case 'EVERY_N_DAYS':
      return repeatVal > 1
        ? `Repeats every ${repeatVal} days`
        : 'Repeats every days';

    case 'EVERY_N_WEEKS': {
      const weekday = moment.weekdays(dayOfTheWeek);
      return repeatVal > 1
        ? `Repeats every ${repeatVal} weeks on ${weekday}`
        : `Repeats weekly on ${weekday}`;
    }

    case 'EVERY_N_MONTHS': {
      const nthDate = getNthDate(dayOfTheMonth);
      return repeatVal > 1
        ? `Repeats every ${repeatVal} months on ${nthDate}`
        : `Repeats monthly on the ${nthDate}`;
    }

    case 'EVERY_N_YEARS': {
      const nthDate = getNthDate(dayOfTheMonth);
      const month = moment.months(monthOfTheYear);
      return repeatVal > 1
        ? `Repeats every ${repeatVal} years in ${month} on ${nthDate}`
        : `Repeats Yearly in ${month} on ${nthDate}`;
    }

    default:
      return 'Repeats multiple times';
  }
};

interface IProps {}

const TrainingActions: FC<IProps> = () => {
  const history = useHistory();
  const {
    trigger,
    getValues,
    setValue,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useFormContext<TFormInput>();
  const toast = useToast({
    position: 'top-right',
    isClosable: true,
    duration: 3000,
  });
  const locations = useLocationsList();
  const entityUsers = useReactiveVar(usersEntityObj);
  const users = entityUsers?.filter(
    (item) => item.type === 'user' && item.status === 'active'
  );

  const { state, updateState } = useRouteState({ currentEditPage: 0 });

  const tabIndex = state.currentEditPage;

  const creator = useTrainingCreator();
  const accessRule = useTrainingAccessRule();

  const saveHandler = useSaveHandler();
  const publishHandler = usePublishHandler();

  const handleDeployEvent = () => {
    const trainingDetails = getValues();
    if (tabIndex === 0) {
      deployEvent(AmplitudeEventNames.TRAINING_PATH_DETAILS_STEP_1, {
        event: {
          add_new_path_funnel_id: 4,
          course_title: trainingDetails.title,
          category: trainingDetails.category,
        },
      });
    } else if (tabIndex === 1) {
      deployEvent(AmplitudeEventNames.TRAINING_PATH_DETAILS_STEP_2, {
        event: {
          add_new_path_funnel_id: 4,
        },
      });
    } else if (tabIndex === 2) {
      deployEvent(AmplitudeEventNames.TRAINING_PATH_DETAILS_STEP_3, {
        event: {
          add_new_path_funnel_id: 4,
          who_undergo_training: trainingDetails?.assigneeUserType,
          number_of_groups: trainingDetails?.assignees?.length,
          training_frequency:
            trainingDetails?.isRepeating === true
              ? getRepeatCycleText(trainingDetails?.repeatCycle)
              : 'once',
          completion_deadline: trainingDetails?.deadlineDate || '',
          assign_training: trainingDetails?.assignmentType,
          skill_verifier_added: trainingDetails?.hasSkillVerifier,
          skill_assess: trainingDetails?.skillVerifier?.skill,
        },
      });
    }
  };

  const actionHandler = async () => {
    const nextIndex = tabIndex + 1;
    const nextTrigger = TriggerData[`${tabIndex}-${nextIndex}` as never];

    if (tabIndex === 2 && (await trigger())) {
      setValue('currentEditPage', state.currentEditPage);
      const values = cloneDeep(getValues());
      const combinedRoles = [
        ...new Set(values?.assignees?.flatMap((item) => item.roles)),
      ];
      const combinedLocationIds = [
        ...new Set(values?.assignees?.flatMap((item) => item.locationIds)),
      ];

      const combinedLocationNames = [
        combinedLocationIds
          .map((id) => locations.find((loc) => loc.eid === id)?.name)
          .filter(Boolean),
      ];

      const combinedUserIds = [
        ...new Set(values?.assignees?.flatMap((item) => item.userIds)),
      ];

      const combinedUsernames = [
        combinedUserIds
          .map((id) => users.find((user) => user.eid === id)?.name)
          .filter(Boolean),
      ];
      await saveHandler(values);
      accessRule({
        initialValue: values.accessPermission,
        hasSkillVerifier: values.hasSkillVerifier,
        creator: creator,
        details: {
          assigneeUserType: values.assigneeUserType,
          assignees: values.assignees,
          supervisors: values.supervisors,
        },

        onPublishClick: async (permission) => {
          setValue('accessPermission', permission);
          await publishHandler(permission);
          deployEvent(AmplitudeEventNames.TRAINING_PATH_PUBLISH, {
            event: {
              assign_to_roles: combinedRoles,
              assign_to_locations: combinedLocationNames,
              assign_to_members: combinedUsernames,
              supervisor: values?.supervisors,
              add_new_path_funnel_id: 4,
            },
          });
          history.goBack();
        },
      });
    }

    if (nextTrigger && tabIndex < 2) {
      const success = await trigger(nextTrigger, { shouldFocus: true });
      if (success) {
        const values = getValues();
        values.currentEditPage = nextIndex;
        await saveHandler(values);
        reset(getValues(), { keepErrors: true });
        updateState({ currentEditPage: nextIndex });
        toast({
          status: 'success',
          title: 'Training path saved as draft',
        });
      }
    }

    handleDeployEvent();
  };

  const handleDraftSave = async () => {
    const success = await trigger(['title', 'thumbnail'], {
      shouldFocus: true,
    });
    if (success) {
      setValue('currentEditPage', state.currentEditPage);
      const values = getValues();
      await saveHandler(values);
      reset(undefined, { keepValues: true });
      await new Promise((resolve) => setTimeout(resolve, 100));
      toast({
        status: 'success',
        title: 'Training path saved as draft',
      });
      deployEvent(AmplitudeEventNames.TRAINING_PATH_SAVE_AS_DRAFT, {
        event: {
          add_new_path_funnel_id: 4,
          step_saved_as_draft: TrainingActionsname(state.currentEditPage),
        },
      });
      history.goBack();
    }
  };

  const errorHandler = useErrorHandler();

  return (
    <Flex align='center' gap={4}>
      <ActionButton
        variant='outline'
        bg='white'
        actionFn={handleDraftSave}
        isDisabled={!isDirty}
      >
        Save as draft
      </ActionButton>

      <ActionButton
        variant='solid'
        colorScheme='blue'
        actionFn={handleSubmit(actionHandler, errorHandler)}
      >
        Save and next
      </ActionButton>
    </Flex>
  );
};

export default TrainingActions;
