import { useMutation } from '@apollo/client';
import moment from 'moment';
import { AmplitudeEvent, deployEvent } from '../../../../../shared';

import { COMPLETE_LAUNCHER_TASK } from './state-drawer.graphql';
import {
  CompleteLauncherTaskResponse,
  CompleteLauncherTaskVariables,
} from './state-drawer.types';

export interface TaskToComplete {
  title: string;
  phaseName: string;
  status: string;
  dueDate?: string | Date;
  triggerDetails?: {
    deadlineOffset: number;
  };
}

interface IProps {
  stepId: string;
  taskId: string;
  launchId: string;
  formResponseId?: string;
  task?: TaskToComplete;
}

export const getTaskDuration = (task?: TaskToComplete) => {
  return task?.triggerDetails?.deadlineOffset
    ? moment.duration(task.triggerDetails.deadlineOffset).asDays()
    : 0;
};

export const getDueDate = (dueDate?: string | Date) => {
  return dueDate ? moment(dueDate).format('DD/MM/YYYY') : undefined;
};

export const useMarkAsComplete = () => {
  const [markAsComplete, { loading: markAsCompleteLoading }] = useMutation<
    CompleteLauncherTaskResponse,
    CompleteLauncherTaskVariables
  >(COMPLETE_LAUNCHER_TASK);

  const handleMarkComplete = async ({
    launchId,
    stepId,
    taskId,
    formResponseId,
    task,
  }: IProps) => {
    const res = await markAsComplete({
      variables: {
        input: {
          launchId: launchId,
          taskId: taskId,
          ...(stepId ? { stepId: stepId } : {}),
          ...(formResponseId ? { formResponseId: formResponseId } : {}),
        },
      },
    });

    if (res.data?.CompleteLauncherTask && task) {
      deployEvent(AmplitudeEvent.LAUNCHER_MARK_TASK_COMPLETED, {
        task_id: taskId,
        task_title: task?.title,
        phase_title: task?.phaseName,
        task_duration: getTaskDuration(task),
        status: task?.status,
        due_date: getDueDate(task?.dueDate),
        attachment: '',
      });
    }

    return res;
  };

  return { markAsComplete: handleMarkComplete, markAsCompleteLoading };
};
