import React, { FC } from 'react';
import { Box } from '@chakra-ui/react';

interface IProps {
  haveData?: boolean;
  hevSearchQuery?: boolean;
  children: React.ReactNode;
}

const ListWrapper: FC<IProps> = ({ haveData, children }) => {
  if (haveData) {
    return <>{children}</>;
  }

  return (
    <Box
      textAlign='center'
      fontSize='15px'
      fontWeight='500'
      color='#9A9FA5'
      marginBottom='1rem'
      userSelect='none'
    >
      No options
    </Box>
  );
};

export default React.memo(ListWrapper);
