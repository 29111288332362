import { Box, Flex } from '@chakra-ui/react';
import React, { FC } from 'react';

interface IProps {
  title: string;
  value: string;
  icon: React.ReactNode;
  onClick?: () => void;
  scrollToTable?: (key: string) => void;
  key?: string;
}

const PerformanceCard: FC<IProps> = ({
  title,
  value,
  icon,
  onClick,
  scrollToTable,
  key,
}) => {
  return (
    <Flex
      flexDir='column'
      border='2px solid rgba(239, 239, 239, 1)'
      borderRadius='12px'
      p='20px'
      cursor={onClick ? 'pointer' : 'default'}
      // onClick={onClick ? () => scrollToTable(key) : () => {}}
      onClick={() => {
        if (title === 'Total active paths') {
          scrollToTable?.();
        } else {
          onClick?.();
        }
      }}
      width={'full'}
      height={'156px'}
      fontSize={'15px'}
      fontWeight={600}
      justifyContent='space-between'
    >
      <Box>{icon}</Box>
      <Box
        mt={'8px'}
        color='rgba(111, 118, 126, 1)'
        fontWeight={600}
        fontSize='15px'
        width='125px'
        wordBreak='break-word'
      >
        {title}
      </Box>
      <Box fontWeight={600} fontSize='32px'>
        {value}
      </Box>
    </Flex>
  );
};

export default PerformanceCard;
