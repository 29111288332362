import FormDataContext from 'pages/forms/FormStore/form-data-context';
import React, { FC, useContext, useMemo } from 'react';
import { ResponsiveDivWrapper } from '../FormResponseTable.styles';
import { MemberComponentWrapper } from './MemberComponent.styles';

import { getImage } from 'utils';
import { UserListEntity } from '../../../../../types';
import { Box } from '@chakra-ui/react';

interface IProps {
  memberIds: string[];
  userList?: UserListEntity[];
}

const MemberComponent: FC<IProps> = ({ memberIds, userList: _userList }) => {
  const responseByIdData = useContext(FormDataContext)?.formResponseByIdData;

  const userList = responseByIdData?.eid
    ? responseByIdData?.userList
    : _userList;

  const memberData = useMemo(() => {
    return memberIds.map((memberId) => {
      return userList?.find((user) => user.eid === memberId);
    });
  }, [userList, memberIds]);

  return (
    <ResponsiveDivWrapper>
      <MemberComponentWrapper>
        {memberData?.length > 0 ? (
          memberData.map((member, index) => (
            <div className='member-div' key={index}>
              <div>
                <img
                  className='member-img'
                  src={getImage(member?.profilePic, member?.name)}
                  alt={member?.name || 'Member Image'}
                />
              </div>
              <span className='member-span'>{member?.name}</span>
            </div>
          ))
        ) : (
          <div className='no-members'>No members available</div>
        )}
      </MemberComponentWrapper>
    </ResponsiveDivWrapper>
  );
};

export default MemberComponent;
