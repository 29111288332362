import React, { FC } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Flex } from '@chakra-ui/react';
import { v4 as uuidv4 } from 'uuid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Button } from '../../../../../../../../atoms';
import { QuizQFormInput } from '../quiz-form.types';
import FillBlankOption from './FillBlankOption';

interface IProps {}

const FillBlankOptionsList: FC<IProps> = () => {
  const { getValues } = useFormContext<QuizQFormInput>();
  const { fields, append, remove } = useFieldArray<QuizQFormInput, 'blanks'>({
    name: 'blanks',
  });

  const onRemoveClick = (index: number) => {
    if (getValues('blanks').length > 1) {
      remove(index);
    }
  };

  return (
    <Flex gap={3} flexWrap='wrap'>
      {fields.map((field, index, arr) => (
        <FillBlankOption
          key={field.id}
          optionIndex={index}
          totalLength={arr.length}
          onRemoveClick={() => onRemoveClick(index)}
        />
      ))}

      <Button
        variant='outline'
        fontSize='13px'
        leftIcon={
          <FontAwesomeIcon icon={faCirclePlus as IconProp} color='#6F767E' />
        }
        onClick={() => append({ uid: uuidv4(), answer: '' })}
      >
        Add alternate answer variation
      </Button>
    </Flex>
  );
};

export default FillBlankOptionsList;
