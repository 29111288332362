import React, { useState } from 'react';
import {
  Box,
  VStack,
  Checkbox,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useDisclosure,
  CheckboxGroup,
  Flex,
  PopoverBody,
  PopoverHeader,
  Badge,
  HStack,
  IconButton,
  Center,
} from '@chakra-ui/react';
import { BoxHeader } from 'ui-components';
import { SearchInput } from 'atoms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFilter } from '@fortawesome/pro-solid-svg-icons';
import { faLocationDot, faX } from '@fortawesome/pro-light-svg-icons';
import { Loader } from 'sub-components';
import CategoryListLoader from 'pages/forms/modules/FormListInternal/form-filter/CategoryListLoader';

interface Location {
  value: string;
  label: string;
}

interface LocationFilterProps {
  locations: Location[];
  selectedLocations: string[];
  onLocationChange: (locations: string[]) => void;
  loading: Boolean;
}

const LocationFilter: React.FC<LocationFilterProps> = ({
  locations,
  selectedLocations,
  onLocationChange,
  loading,
}) => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [searchQuery, setSearchQuery] = useState('');

  const filteredLocations = locations.filter((location) =>
    location.label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleCheckboxChange = (locationValue: string) => {
    const newSelectedLocations = selectedLocations.includes(locationValue)
      ? selectedLocations.filter((loc) => loc !== locationValue)
      : [...selectedLocations, locationValue];
    onLocationChange(newSelectedLocations);
  };

  const clearAll = () => {
    onLocationChange([]);
    setSearchQuery('');
    onClose();
  };

  return (
    <Box position='relative'>
      <Popover isOpen={isOpen} onClose={onClose} placement='left-start'>
        <PopoverTrigger>
          {/* <Box
            onClick={onToggle}
            border='1px'
            borderColor='gray.200'
            borderRadius='md'
            p={2}
            cursor='pointer'
          >
            <HStack justify='space-between'>
              {selectedLocations.length > 0 && (
                <HStack>
                  <Text fontSize='sm' color='purple.500'>
                    {selectedLocations.length}
                  </Text>
                  <IconButton
                    aria-label='Clear all'
                    icon={<SmallCloseIcon />}
                    size='xs'
                    variant='ghost'
                    onClick={(e) => {
                      e.stopPropagation();
                      clearAll();
                    }}
                  />
                </HStack>
              )}
            </HStack>
          </Box> */}
          <Box position='absolute' right='0' top='-10'>
            {selectedLocations.length > 0 && (
              <Badge
                position='absolute'
                top='-8px'
                right='-8px'
                borderRadius='full'
                bg='#2A85FF'
                color='#FFFFFF'
                fontSize='9px'
                minW='20px'
                height='20px'
                display='flex'
                alignItems='center'
                justifyContent='center'
                zIndex='1'
                fontWeight={600}
              >
                {selectedLocations.length}
              </Badge>
            )}
            <FontAwesomeIcon
              icon={faFilter as IconProp}
              color='#6F767E'
              cursor='pointer'
              onClick={onToggle}
              size='2x'
            />
          </Box>
        </PopoverTrigger>
        <PopoverContent p={'12px'}>
          <PopoverHeader borderBottomWidth='0'>
            <Flex justify='space-between' alignItems='center'>
              <BoxHeader
                color='#CABDFF'
                title='Filter by location'
                fontSize='18px'
              />
              <Text
                pos='relative'
                fontWeight={500}
                fontSize={'14px'}
                textColor={selectedLocations.length > 0 ? '#2A85FF' : '#6F767E'}
                top='0'
                right='0'
                onClick={(e) => {
                  e.stopPropagation();
                  clearAll();
                }}
                cursor={'pointer'}
              >
                Clear All
              </Text>
            </Flex>
          </PopoverHeader>
          <PopoverBody overflowY={'scroll'} maxH='calc(100vh - 400px)'>
            {loading ? (
              <Center boxSize={'100%'}>
                <Loader />
              </Center>
            ) : (
              <VStack align='stretch' spacing={4}>
                <SearchInput
                  placeholder='Search by location name'
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  hideShortcuts
                />
                <Center boxSize={'100%'}>
                  <CategoryListLoader
                    isLoading={loading}
                    isEmpty={filteredLocations.length === 0}
                    searchQuery={searchQuery}
                  />
                </Center>
                <Flex flexDir='column' gap={1} minH='200px'>
                  <CheckboxGroup value={selectedLocations}>
                    {filteredLocations.map((location, index) => (
                      <Flex
                        padding='4px 6px'
                        borderRadius='4px'
                        justify='space-between'
                        key={index}
                        _hover={{
                          bg: '#EFEFEF',
                          button: {
                            visibility: 'visible',
                          },
                        }}
                      >
                        <Checkbox
                          value={location.value}
                          isChecked={selectedLocations.includes(location.value)}
                          onChange={() => handleCheckboxChange(location.value)}
                          _checked={{
                            fontWeight: '600',
                          }}
                        >
                          <Box fontSize='14px' color='#333B4F'>
                            {location.label}
                          </Box>
                        </Checkbox>
                      </Flex>
                    ))}
                  </CheckboxGroup>
                </Flex>
              </VStack>
            )}
          </PopoverBody>
        </PopoverContent>
      </Popover>

      <HStack
        display={'flex'}
        alignItems={'center'}
        mt={2}
        overflowY={'scroll'}
        ml={1}
      >
        {selectedLocations.length > 0 && (
          <Text fontSize={'12px'} fontWeight={500} width={'93px'}>
            Filters applied:
          </Text>
        )}
        {selectedLocations.map((locationValue) => {
          const location = locations.find((loc) => loc.value === locationValue);
          return (
            location && (
              <Badge
                key={locationValue}
                bg='#E8DEF8'
                borderRadius='8px'
                px={'2px'}
                py={'3px'}
                variant='solid'
                textColor={'#000000'}
                MaxWidth={'151px'}
                height={'32px'}
                display={'flex'}
                gap={1}
                alignItems={'center'}
              >
                <IconButton
                  aria-label={`Remove ${location.label}`}
                  icon={
                    <FontAwesomeIcon
                      icon={faLocationDot as IconProp}
                      color='#6F767E'
                      cursor='pointer'
                    />
                  }
                  size='xs'
                  _hover={{
                    bg: 'transparent',
                  }}
                  bg={'transparent'}
                />
                <Text
                  fontWeight={500}
                  fontSize={'12px'}
                  isTruncated
                  maxW={'150px'}
                >
                  {location.label}
                </Text>
                <IconButton
                  aria-label={`Remove ${location.label}`}
                  icon={
                    <FontAwesomeIcon
                      icon={faX as IconProp}
                      color='#6F767E'
                      cursor='pointer'
                    />
                  }
                  size='xs'
                  variant='solid'
                  _hover={{
                    bg: 'transparent',
                  }}
                  bg={'transparent'}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCheckboxChange(locationValue);
                  }}
                />
              </Badge>
            )
          );
        })}
      </HStack>
    </Box>
  );
};

export default LocationFilter;
