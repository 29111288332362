import { useQuery } from '@apollo/client';
import { Box, Center } from '@chakra-ui/react';
import CertificatesList from './v2/CertificateComponent';
import { FC } from 'react';
import {
  GET_TP_V2_CERTIFICATES,
  CertificatesV2Response,
} from './certificate.graphql';
import { EmptyState, Loader } from 'sub-components';
import { useTranslation } from 'react-i18next';

interface ICertificate {
  userCertificates: {
    certificate: string;
    certificateUrl: string;
    firstCompletedAt: string;
    thumbnail: string;
    training: string;
    trainingId: string;
  }[];
}

interface IProps {
  userId: string;
}

const CertificateComponent: FC<IProps> = ({ userId }) => {
  const { t } = useTranslation(['training']);

  const { data, loading } = useQuery<CertificatesV2Response>(
    GET_TP_V2_CERTIFICATES,
    {
      fetchPolicy: 'network-only',
      variables: {
        userId: userId,
      },
    }
  );

  const dataList = data?.TpCertificates || [];

  if (loading) {
    return (
      <Center h='70vh'>
        <Loader />
      </Center>
    );
  }

  if (!loading && dataList?.length === 0) {
    return (
      <Center h='70vh'>
        <EmptyState
          image='Certificate'
          title={t('training:noCertificateAwarded')}
        />
      </Center>
    );
  }

  return (
    <Box id='my-training-list-parent-profile' bg='white' borderRadius='7px'>
      <CertificatesList data={dataList} />
    </Box>
  );
};

export default CertificateComponent;
