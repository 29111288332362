import { Badge, Flex, Text, Tooltip } from '@chakra-ui/react';
import React, { FC } from 'react';
import { TrainingItemCellProps } from './types';
import { Image } from 'ui-components';
import { getImage } from 'utils';

const TrainingItemCell: FC<TrainingItemCellProps> = ({
  record,
  text,
  categoriesColorCode,
  onNavigate,
}) => (
  <Flex
    cursor='pointer'
    onClick={() => {
      if (record?.trainingId && record?.eid && onNavigate) {
        onNavigate(record.trainingId, record.eid);
      }
    }}
    gap={2}
  >
    <Image
      width={62}
      height={62}
      src={getImage(record?.profilePic, text)}
      style={{ borderRadius: '8px' }}
    />
    <Flex justifyContent='center' flexDir='column'>
      <Tooltip label={text}>
        <Text
          isTruncated
          maxWidth='130px'
          width='fit-content'
          fontSize='15px'
          fontWeight={600}
        >
          {text}
        </Text>
      </Tooltip>
      <Tooltip label={record?.category?.[0]?.name}>
        <Badge
          isTruncated
          fontSize='10px'
          maxWidth='100px'
          width='fit-content'
          borderRadius='6px'
          bg={categoriesColorCode?.[record?.category?.[0]?.eid]?.bg}
          color={categoriesColorCode?.[record?.category?.[0]?.eid]?.color}
        >
          {record?.category?.[0]?.name}
        </Badge>
      </Tooltip>
    </Flex>
  </Flex>
);

export default TrainingItemCell;
