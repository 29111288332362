import React, { useMemo } from 'react';
import { chakra, forwardRef, HTMLChakraProps } from '@chakra-ui/system';

export interface TextWithLinkProps extends HTMLChakraProps<'div'> {
  linkText?: string;
}

const LINK_REGEX =
  /(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*))/g;

const EMAIL_REGEX = /\s?[^@\s]+@[^@\s]+\.[^\s]+/g;

export const TextWithLink = forwardRef<TextWithLinkProps, 'div'>(
  (props, ref) => {
    const { linkText, ...rest } = props;

    const extraProps = useMemo((): HTMLChakraProps<'div'> | undefined => {
      if (linkText) {
        return {
          dangerouslySetInnerHTML: {
            __html: linkText
              .replace(
                LINK_REGEX,
                (x) =>
                  `<a href="${x.trim()}" target="_blank" rel="noopener noreferrer">${x}</a>`
              )
              .replace(
                EMAIL_REGEX,
                (x) =>
                  `<a href="mailto:${x.trim()}" target="_blank" rel="noopener noreferrer">${x}</a>`
              ),
          },
          sx: {
            a: {
              color: 'blue.500',
              _hover: { textDecoration: 'underline' },
            },
          },
        };
      }
      return undefined;
    }, [linkText]);

    return (
      <chakra.div
        ref={ref}
        {...extraProps}
        {...rest}
        sx={{ ...extraProps?.sx, ...rest?.sx }}
      />
    );
  }
);
