import moment from 'moment-timezone';
import {
  IMyTask,
  ITaskOverviewEntity,
  IItems,
} from 'pages/TasksDashboard/tasks.types';
import { HeaderColors } from 'shared/headerColors/header-colors';
import { UserData } from 'sop-commons/src/client';

const generateItem = (
  item: any,
  status: ITaskOverviewEntity,
  taskDueDate: moment.Moment,
  taskStartDate: moment.Moment,
  now: moment.Moment,
  userTimezone: string,
  duration: moment.Duration,
  hours: number,
  minutes: number
) => ({
  eid: item?.taskId,
  taskDetails: item,
  sessionId: item?.eid,
  status: status,
  title: item?.title,
  date: taskDueDate.format('MMM DD'),
  repeatDetails: item?.task?.repeatDetails,
  detailedAnalysis: {
    backendTaskStartDate: item?.startDate,
    backendTaskDueDate: item?.dueDate,
    dueDateISO: moment.utc(item?.dueDate),
    taskStartDate: taskStartDate.format('DD-MMM-YYYY hh:mm a'),
    taskDueDate: taskDueDate.format('DD-MMM-YYYY hh:mm a'),
    now: now.format('DD-MMM-YYYY hh:mm a'),
    nowUTC: now.toISOString(),
    myLocalUTC: moment().toISOString(),
    myLocal: moment().format('DD-MMM-YYYY hh:mm a'),
    timezone: userTimezone,
    isLastSession: item?.isLastSession,
    isRepeating: item?.isRepeating,
  },
  timeDuration: null,
  countData: {
    completedCount: item?.currentUserProgress?.completedTasks?.length,
    totalCount: item?.itemCount,
  },
  repeatString: item?.task?.repeatCycle,
  duration,
  hours,
  minutes,
  isMissed: item?.isMissed,
});

export const getUniqueSessionTask = (
  taskData: IItems[] | undefined,
  userTimezone: string
) => {
  let grouped = (taskData || [])?.reduce((acc: any, current) => {
    if (!acc[current?.taskId]) {
      acc[current?.taskId] = [];
    }
    acc[current?.taskId].push(current);
    return acc;
  }, {});
  let arrayOfGroups = Object.values(grouped);
  let itemsArray: IItems[] = [];
  arrayOfGroups?.map((group: any) => {
    let _arr: any[] = [];
    if (Array.isArray(group) && group?.length === 1) {
      itemsArray?.push(group?.[0]);
      return;
    }
    group?.map((_group: any) => {
      let taskStartDate = moment.utc(_group?.startDate);
      let taskDueDate = moment.utc(_group?.dueDate);
      let _now = moment()?.tz(userTimezone);
      let now = moment.utc(
        _now?.format('DD-MMM-YYYY hh:mm a'),
        'DD-MMM-YYYY hh:mm a'
      );
      _arr.push({
        startDate: taskStartDate?.format('DD-MMM-YYYY, hh:mm A'),
        data: _group,
      });
    });
    let latest = _arr.reduce(
      (max, obj) => (moment(obj.startDate).isAfter(max.startDate) ? obj : max),
      _arr[0]
    )?.data;
    itemsArray?.push(latest);
  });
  return itemsArray;
};

const overdueTaskHandler = (
  taskDueDate: moment.Moment,
  now: moment.Moment,
  item: IItems
) => {
  let flag = false;

  if (item.isRepeating) {
    /**
     * REPEATING TASK : Will always be returned as false
     */
    flag = false;
  } else {
    /**
     * ONE TIME TASK :
     */
    if (
      taskDueDate.isBefore(now) &&
      item.currentUserProgress.completedTasks.length !== item.itemCount
    ) {
      flag = true;
    }
  }
  return flag;
};

const dueTodayTaskHandler = (
  taskDueDate: moment.Moment,
  now: moment.Moment,
  item: IItems
) => {
  let flag = false;
  if (!item.isRepeating) {
    /**
     * ONE TIME TASK : Don't consider the last session, only check if task due date matches today and items count does not match total count
     */
    if (
      taskDueDate.isSame(now, 'day') &&
      item.currentUserProgress.completedTasks.length !== item.itemCount
    ) {
      flag = true;
    }
  } else {
    /**
     * REPEATING TASK : consider the last session. if it is last session, it will be false. if it is not last session, then logic as above
     */
    if (item.isLastSession) {
      flag = false;
    } else if (
      taskDueDate.isSame(now, 'day') &&
      item.currentUserProgress.completedTasks.length !== item.itemCount
    ) {
      flag = true;
    }
  }
  return flag;
};

const thisWeekUpcomingTaskHandler = (
  taskDueDate: moment.Moment,
  now: moment.Moment,
  item: IItems,
  endOfWeek: moment.Moment
) => {
  let flag = false;
  if (!item.isRepeating) {
    /**
     * ONE TIME TASK : Don't consider the last session, only check if task due date is after today and before this week and items count does not match total count
     */
    if (
      taskDueDate.isAfter(now) &&
      taskDueDate.isBefore(endOfWeek) &&
      item.currentUserProgress.completedTasks.length !== item.itemCount
    ) {
      flag = true;
    }
  } else {
    /**
     * REPEATING TASK : consider the last session. if it is last session, it will be false. if it is not last session, then logic as above
     */
    if (item.isLastSession) {
      flag = false;
    } else if (
      taskDueDate.isAfter(now) &&
      taskDueDate.isBefore(endOfWeek) &&
      !item?.isLastSession &&
      item.currentUserProgress.completedTasks.length !== item.itemCount
    ) {
      flag = true;
    }
  }
  return flag;
};

const upcomingTaskHandler = (
  taskDueDate: moment.Moment,
  now: moment.Moment,
  item: IItems
) => {
  let flag = false;
  if (!item.isRepeating) {
    /**
     * ONE TIME TASK : Don't consider the last session, only check if task due date is after today and items count does not match total count
     */
    if (
      taskDueDate.isAfter(now) &&
      item.currentUserProgress.completedTasks.length !== item.itemCount
    ) {
      flag = true;
    }
  } else {
    /**
     * REPEATING TASK : consider the last session. if it is last session, it will be false. if it is not last session, then logic as above
     */
    if (item.isLastSession) {
      flag = false;
    } else if (
      taskDueDate.isAfter(now) &&
      !item?.isLastSession &&
      item.currentUserProgress.completedTasks.length !== item.itemCount
    ) {
      flag = true;
    }
  }
  return flag;
};

export type GroupedTasks = {
  [taskId: string]: ModifiedItems[];
};

export type ModifiedItems = IItems & { isMissed: string };

export const groupedTaskSessions = (itemsArray: ModifiedItems[]) => {
  const groupedTasks: GroupedTasks = itemsArray.reduce((acc, item) => {
    if (!acc[item.taskId]) {
      acc[item.taskId] = [];
    }
    acc[item.taskId].push(item);
    return acc;
  }, {} as GroupedTasks);

  const resultArray: ModifiedItems[] = Object.values(groupedTasks).map(
    (group) => {
      const latestSession = { ...group[0] }; // Create a copy to prevent direct mutation

      // Check if there is a previous session and set 'isMissed' accordingly
      if (group[1] && !group[1].currentUserProgress.isCompleted) {
        latestSession.isMissed = moment.utc(group[1].dueDate)?.format('MMM DD');
      } else {
        latestSession.isMissed = '';
      }

      return latestSession;
    }
  );
  return resultArray;
};

export const processTaskData = (
  taskData: IMyTask | undefined,
  userData: Pick<UserData, 'timezone'>
) => {
  const overdue: any[] = [];
  const dueToday: any[] = [];
  const thisWeek: any[] = [];
  const upcoming: any[] = [];
  const totalTasks: any[] = [];
  let userTimezone = userData?.timezone;
  let _now = moment()?.tz(userTimezone);
  let now = moment.utc(
    _now?.format('DD-MMM-YYYY hh:mm a'),
    'DD-MMM-YYYY hh:mm a'
  );
  let _itemsArray: ModifiedItems[] = JSON.parse(
    JSON.stringify(taskData?.MyTaskSession || [])
  );
  let itemsArray = groupedTaskSessions(_itemsArray);
  itemsArray?.map((item) => {
    let endOfWeek = moment()?.tz(userTimezone)?.endOf('week');
    let taskDueDate = moment.utc(item?.dueDate);
    /**
     * Task Start Date will be converted to user's timezone
     */
    let _taskStartDate = moment(
      moment.utc(item?.startDate),
      'DD-MMM-YYYY hh:mm a'
    )?.tz(userTimezone);
    let taskStartDate = moment.utc(
      _taskStartDate?.format('DD-MMM-YYYY hh:mm a'),
      'DD-MMM-YYYY hh:mm a'
    );
    let diffInMilliseconds = taskDueDate?.diff(now);
    let duration = moment?.duration(diffInMilliseconds);
    let hours = Math?.floor(duration?.asHours());
    let minutes = Math?.floor(duration?.asMinutes()) - hours * 60;

    let _item = generateItem(
      item,
      'totalTasks',
      taskDueDate,
      taskStartDate,
      now,
      userTimezone,
      duration,
      hours,
      minutes
    );
    totalTasks.push(_item);
    if (item?.task?.status === 'TASK_TERMINATED') return;
    if (overdueTaskHandler(taskDueDate, now, item)) {
      let _item = generateItem(
        item,
        'overdue',
        taskDueDate,
        taskStartDate,
        now,
        userTimezone,
        duration,
        hours,
        minutes
      );
      overdue.push(_item);
    } else if (dueTodayTaskHandler(taskDueDate, now, item)) {
      let _item = generateItem(
        item,
        'dueToday',
        taskDueDate,
        taskStartDate,
        now,
        userTimezone,
        duration,
        hours,
        minutes
      );
      dueToday.push(_item);
    } else if (thisWeekUpcomingTaskHandler(taskDueDate, now, item, endOfWeek)) {
      let _item = generateItem(
        item,
        'thisWeek',
        taskDueDate,
        taskStartDate,
        now,
        userTimezone,
        duration,
        hours,
        minutes
      );
      thisWeek.push(_item);
      let _upcomingItem = generateItem(
        item,
        'upcoming',
        taskDueDate,
        taskStartDate,
        now,
        userTimezone,
        duration,
        hours,
        minutes
      );
      upcoming.push(_upcomingItem);
    } else if (upcomingTaskHandler(taskDueDate, now, item)) {
      let _item = generateItem(
        item,
        'upcoming',
        taskDueDate,
        taskStartDate,
        now,
        userTimezone,
        duration,
        hours,
        minutes
      );
      upcoming.push(_item);
    }
  });
  let _overdueSorted = overdue.sort((a, b) => {
    const dateA = moment(
      a?.detailedAnalysis?.dueDateISO,
      'DD-MMM-YYYY hh:mm a'
    );
    const dateB = moment(
      b?.detailedAnalysis?.dueDateISO,
      'DD-MMM-YYYY hh:mm a'
    );
    return dateA.isAfter(dateB) ? 1 : -1;
  });

  let _dueTodaySorted = dueToday.sort((a, b) => {
    const dateA = moment(
      a?.detailedAnalysis?.dueDateISO,
      'DD-MMM-YYYY hh:mm a'
    );
    const dateB = moment(
      b?.detailedAnalysis?.dueDateISO,
      'DD-MMM-YYYY hh:mm a'
    );
    return dateA.isAfter(dateB) ? 1 : -1;
  });

  let _thisWeekSorted = thisWeek.sort((a, b) => {
    const dateA = moment(
      a?.detailedAnalysis?.dueDateISO,
      'DD-MMM-YYYY hh:mm a'
    );
    const dateB = moment(
      b?.detailedAnalysis?.dueDateISO,
      'DD-MMM-YYYY hh:mm a'
    );
    return dateA.isAfter(dateB) ? 1 : -1;
  });

  let _upcomingSorted = upcoming.sort((a, b) => {
    const dateA = moment(
      a?.detailedAnalysis?.dueDateISO,
      'DD-MMM-YYYY hh:mm a'
    );
    const dateB = moment(
      b?.detailedAnalysis?.dueDateISO,
      'DD-MMM-YYYY hh:mm a'
    );
    return dateA.isAfter(dateB) ? 1 : -1;
  });

  let _totalTasksSorted = totalTasks.sort((a, b) => {
    const dateA = moment(
      a?.detailedAnalysis?.dueDateISO,
      'DD-MMM-YYYY hh:mm a'
    );
    const dateB = moment(
      b?.detailedAnalysis?.dueDateISO,
      'DD-MMM-YYYY hh:mm a'
    );
    return dateA.isAfter(dateB) ? 1 : -1;
  });

  let taskDetailsObj = [
    { type: 'overdue' as ITaskOverviewEntity, data: _overdueSorted },
    { type: 'dueToday' as ITaskOverviewEntity, data: _dueTodaySorted },
    { type: 'thisWeek' as ITaskOverviewEntity, data: _thisWeekSorted },
    { type: 'upcoming' as ITaskOverviewEntity, data: _upcomingSorted },
    { type: 'totalTasks' as ITaskOverviewEntity, data: _totalTasksSorted },
  ];
  let taskOverviewObj = [
    {
      id: 'overdue' as ITaskOverviewEntity,
      name: 'Overdue tasks',
      count: overdue?.length || 0,
      selected: false,
      color: HeaderColors.Red.Light,
    },
    {
      id: 'dueToday' as ITaskOverviewEntity,
      name: 'Due today',
      count: dueToday?.length || 0,
      selected: false,
      color: HeaderColors.Purple,
    },
    {
      id: 'thisWeek' as ITaskOverviewEntity,
      name: "This week's tasks",
      count: thisWeek?.length || 0,
      selected: false,
      color: HeaderColors.Blue,
    },
    {
      id: 'upcoming' as ITaskOverviewEntity,
      name: "This week's tasks",
      count: upcoming?.length || 0,
      selected: false,
      color: HeaderColors.Blue,
    },
    {
      id: 'totalTasks' as ITaskOverviewEntity,
      name: 'Total tasks',
      count: totalTasks?.length || 0,
      selected: false,
      color: HeaderColors.Yellow,
    },
  ];
  return { taskDetails: taskDetailsObj, taskOverview: taskOverviewObj };
};
