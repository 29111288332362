import React, { FC, useEffect, useState } from 'react';
import { Center, Flex, Text } from '@chakra-ui/react';
import { useLazyQuery, useReactiveVar } from '@apollo/client';

import EmptyState from '../../../../sub-components/EmptyState';

import { Loader } from 'sub-components';
import { ConfigProvider, Table } from 'antd';
import { PageInfo } from 'types';
import {
  GET_TRAINING_CATEGORIES,
  USER_TRAINING_PATH_SESSIONS,
} from 'sub-components/training-v2/dashboard/components/myTrainings/query/myTraining.graphql';
import { prepareTrainingPathSessionData } from 'sub-components/training-v2/dashboard/components/myTrainings/helper';
import { useUserDataSelector } from 'hooks';
import { ITPCategory } from 'sub-components/training-v2/shared/types';
import {
  TrainingData,
  UserTrainingPathSessionsType,
} from 'sub-components/training-v2/dashboard/components/myTrainings/types/myTraining.types';
import {
  PathName,
  PathNameTitle,
  Progress,
  Status,
  CreatedBy,
  CreatedOn,
} from 'pages/Training/Training/component/Components';
import { getCategoriesColorCode } from 'sub-components/training-v2/dashboard/components/TrackPathDashboard/bottomSection/helper';
import { usersEntityObj } from 'sub-components/Header';
import { useParams } from 'react-router-dom';

interface IProps {
  userId: string;
  username?: string;
}

interface ISortType {
  sort: string;
  order: string;
  tooltipLabel: string;
}

const TrainingComponent: FC<IProps> = ({ userId, username }) => {
  const { memberId } = useParams<{ memberId: string }>();

  const [trainingPathData, setTrainingPathData] = useState<
    TrainingData[] | UserTrainingPathSessionsType[]
  >([]);
  const [trainingCategories, setTrainingCategories] = useState<ITPCategory[]>(
    []
  );
  const [sortType, setSortType] = useState<ISortType>({
    sort: 'CREATEDAT_DESC',
    order: 'none',
    tooltipLabel: 'Sort A-Z',
  });
  const [selectedPageIndex, setSelectedPageIndex] = useState(1);
  const [pageInfo, setPageInfo] = useState<PageInfo>({
    currentPage: 1,
    itemCount: 10,
    pageCount: 1,
    perPage: 10,
    hasNextPage: false,
    hasPreviousPage: false,
  });
  const [searchQuery, setSearchQuery] = useState<string>('');
  const loggedInUserId = useUserDataSelector((state) => state?.eid);
  const [filters, setFilters] = useState<any>({
    totalFiltersTypes: 0,
    types: [],
    selectedTypes: [],
    selectedTypesId: [],
    categories: [],
    selectedCategories: [],
    selectedCategoriesId: [],
    statuses: [],
    selectedStatuses: [],
    selectedStatusesId: [],
    mergedFiltersArray: [],
  });

  const entityObjData = useReactiveVar(usersEntityObj);

  const categoriesColorCode = getCategoriesColorCode(trainingCategories);

  const trainingPathFilters = (page = 1, sort = 'CREATEDAT_DESC') => {
    let filter: {
      query?: string;
      category?: string[];
      status?: string[];
      assigneeStatus?: string[];
      overdue?: boolean;
      userId?: string;
    } = {
      query: '',
      status: [
        'TRAINING_CREATED',
        'TRAINING_STARTED',
        'TRAINING_REPEAT',
        'TRAINING_COMPLETED',
        'TRAINING_TERMINATED',
        'TRAINING_ONE_TIME',
      ],
      assigneeStatus: [],
    };
    if (searchQuery) {
      filter.query = searchQuery;
    }

    filter.userId = memberId;

    // Add filter for categories
    if (filters?.selectedCategoriesId?.length > 0) {
      filter.category = filters?.selectedCategoriesId;
    }

    // Add filter for status

    // Overdue
    if (filters?.selectedStatusesId?.includes('static_1_overdue')) {
      filter.overdue = true;
    }

    // Yet to start
    if (filters?.selectedStatusesId?.includes('static_1_yetToStart')) {
      filter.assigneeStatus?.push('assigned');
    }

    // In Progress
    if (filters?.selectedStatusesId?.includes('static_1_inProgress')) {
      filter.assigneeStatus?.push('started');
    }

    // Completed
    if (filters?.selectedStatusesId?.includes('static_1_completed')) {
      filter.assigneeStatus?.push('completed');
    }

    // Add filter for Types
    if (filters?.selectedTypesId?.length > 0) {
      if (
        filters?.selectedTypesId?.length === 1 &&
        filters?.selectedTypesId?.includes('static_1_active1')
      ) {
        filter.status = [
          'TRAINING_CREATED',
          'TRAINING_STARTED',
          'TRAINING_REPEAT',
          'TRAINING_COMPLETED',
          'TRAINING_ONE_TIME',
        ];
      }

      if (
        filters?.selectedTypesId?.length === 1 &&
        filters?.selectedTypesId?.includes('static_2_inactive2')
      ) {
        filter.status = ['TRAINING_TERMINATED'];
      }
    }

    return {
      page: selectedPageIndex,
      perPage: 10,
      sort: sort,
      filter: filter,
      userProgressFilter2: {
        userId: memberId,
      },
      userIds: [memberId],
    };
  };

  // API to get tracking pagination data
  const [
    getTrainingPathSessionData,
    { loading: trackingTpPaginationLoader, error: tpUserSessionError },
  ] = useLazyQuery(USER_TRAINING_PATH_SESSIONS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const formattedData = prepareTrainingPathSessionData(
        data?.UserTpSessions?.items,
        trainingCategories,
        entityObjData
      );

      setPageInfo(data?.UserTpSessions?.pageInfo);
      setTrainingPathData(formattedData);
    },
  });

  // API to get training categories
  const [
    getTrainingCategories,
    { loading: trainingCategoriesLoader, error: tpCategoriesError },
  ] = useLazyQuery(GET_TRAINING_CATEGORIES, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setTrainingCategories(data.EntityTpCategories);
      // Trigger the pagination query after categories are fetched
      getTrainingPathSessionData({
        variables: trainingPathFilters(selectedPageIndex, sortType?.sort),
      });
    },
  });

  useEffect(() => {
    // Fetch data when page changed
    if (selectedPageIndex !== 1) {
      getTrainingPathSessionData({
        variables: trainingPathFilters(selectedPageIndex, sortType?.sort),
      });
      return;
    }

    // Fetch initial data when searchQuery is empty
    if (
      !searchQuery &&
      sortType?.order === 'none' &&
      !filters?.totalFiltersTypes
    ) {
      getTrainingCategories();
      return;
    }

    // Debounce fetch when searchQuery is updated
    const timer = setTimeout(() => {
      getTrainingPathSessionData({
        variables: trainingPathFilters(selectedPageIndex, sortType?.sort),
      });
    }, 500);

    return () => clearTimeout(timer);
  }, [searchQuery, selectedPageIndex, sortType, filters]);

  const columns = [
    {
      title: <PathNameTitle setSortType={setSortType} sortType={sortType} />,
      dataIndex: 'pathName',
      key: 'pathName',
      render: (value, row: TrainingData) => {
        return (
          <PathName
            value={value}
            row={row}
            categoriesColorCode={categoriesColorCode}
          />
        );
      },
    },
    {
      title: (
        <Text fontWeight={500} color={'#6F767E'}>
          Status
        </Text>
      ),
      dataIndex: 'status',
      key: 'status',
      render: (status: { title: string; dueDate: string }, row) => {
        return <Status value={status} row={row} mytarainingMode={false} />;
      },
    },
    {
      title: (
        <Text fontWeight={500} color={'#6F767E'}>
          Progress
        </Text>
      ),
      dataIndex: 'progress',
      key: 'progress',
      render: (value, row: TrainingData) => {
        return <Progress value={value} row={row} />;
      },
    },
    {
      title: (
        <Flex alignItems={'center'} gap={2}>
          <Text fontWeight={500} color={'#6F767E'}>
            Created by
          </Text>
        </Flex>
      ),
      dataIndex: 'createdBy',
      key: 'createdBy',
      render: (value, row) => {
        return <CreatedBy value={value} row={row} />;
      },
    },
    {
      title: (
        <Flex alignItems={'center'} gap={2}>
          <Text fontWeight={500} color={'#6F767E'}>
            Created on
          </Text>
        </Flex>
      ),
      dataIndex: 'createdOn',
      key: 'createdOn',
      render: (value, row) => {
        return <CreatedOn value={value} row={row} />;
      },
    },
  ];

  return (
    <>
      {trainingPathData?.length > 0 ? (
        <Flex width={'100%'}>
          <ConfigProvider
            renderEmpty={() => (
              <EmptyState
                title={'No training available'}
                description={'Trainings assigned to you, would appear here.'}
                image={'MyTraining'}
              />
            )}
          >
            <Table<TrainingData>
              style={{ width: '100%' }}
              loading={trackingTpPaginationLoader && { indicator: <Loader /> }}
              columns={columns}
              dataSource={trainingPathData}
              pagination={{
                total: pageInfo?.itemCount,
                hideOnSinglePage: true,
                defaultPageSize: 10,
                showSizeChanger: false,
                current: pageInfo?.currentPage,
                onChange: (page) => {
                  setSelectedPageIndex(page);
                },
              }}
            />
          </ConfigProvider>
        </Flex>
      ) : (
        <>
          {trackingTpPaginationLoader || trainingCategoriesLoader ? (
            <Center maxBlockSize={'100%'} height={'40vh'}>
              <Loader />
            </Center>
          ) : (
            <Flex
              height={'70vh'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <EmptyState
                title={'No training available'}
                description={'Trainings assigned to you, would appear here.'}
                image={'MyTraining'}
              />
            </Flex>
          )}
        </>
      )}
    </>
  );
};

export default TrainingComponent;
