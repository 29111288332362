import { Box, Flex, Tooltip } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFlag } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useMemo } from 'react';
import { usePlayContext } from '../../store/context';

const ProgressBar: FC = () => {
  const { isPreview, tpSessionData, updateSelectedIndex } = usePlayContext(
    (state) => ({
      tpSessionData: state?.tpSessionData,
      isPreview: state?.isPreview,
      updateSelectedIndex: state?.updateSelectedIndex,
    })
  );

  const contentItems = useMemo(() => {
    if (!tpSessionData?.contents) return [];

    const userProgress = tpSessionData?.userProgress?.[0]?.progress;

    return tpSessionData?.contents?.map((content, index) => {
      const isContentCompleted =
        userProgress?.some(
          (progress) => progress?.id === content?.eid && progress?.isCompleted
        ) || false;

      return {
        type: content?.type,
        eid: content?.eid,
        title: content?.title,
        isCompleted: isPreview ? false : isContentCompleted,
        index,
      };
    });
  }, [tpSessionData, isPreview]);

  const regularItemCount = useMemo(() => {
    return contentItems?.filter((item) => item?.type !== 'milestone')?.length;
  }, [contentItems]);

  const handleItemClick = (contentIndex: number) => {
    updateSelectedIndex(contentIndex);
  };

  if (contentItems?.length === 0) return null;

  return (
    <Flex w='100%' h='8px' position='relative' align='center' mr={4} gap='2px'>
      {contentItems?.map((item, index) => {
        const prevIsMilestone =
          index > 0 && contentItems?.[index - 1]?.type === 'milestone';
        const nextIsMilestone =
          index < contentItems?.length - 1 &&
          contentItems?.[index + 1]?.type === 'milestone';
        const isOnlyRegularItem =
          regularItemCount === 1 && item?.type !== 'milestone';

        if (item?.type === 'milestone') {
          return (
            <Tooltip
              key={item?.eid}
              label={item?.title || 'Milestone'}
              placement='top'
              hasArrow
            >
              <Flex
                w='28px'
                h='28px'
                shrink={0}
                borderRadius='50%'
                bg={item?.isCompleted ? 'white' : 'gray.200'}
                justify='center'
                align='center'
                cursor='pointer'
                onClick={() => handleItemClick(item?.index)}
                _hover={{
                  opacity: 0.8,
                  boxShadow: '0 0 5px rgba(0,0,0,0.2)',
                }}
              >
                <FontAwesomeIcon
                  icon={faFlag as IconProp}
                  fontSize='14px'
                  color={
                    item.isCompleted
                      ? 'rgba(142, 89, 255, 1)'
                      : 'rgba(111, 118, 126, 1)'
                  }
                />
              </Flex>
            </Tooltip>
          );
        }

        let borderRadius;

        if (isOnlyRegularItem) {
          borderRadius = '6px';
        } else if (prevIsMilestone && nextIsMilestone) {
          borderRadius = '6px';
        } else if (prevIsMilestone) {
          borderRadius = '6px 0 0 6px';
        } else if (nextIsMilestone) {
          borderRadius = '0 6px 6px 0';
        } else {
          if (index === 0) {
            borderRadius = '6px 0 0 6px';
          } else if (index === contentItems?.length - 1) {
            borderRadius = '0 6px 6px 0';
          } else if (
            index < contentItems?.length - 1 &&
            contentItems?.[index + 1]?.type === 'milestone'
          ) {
            borderRadius = '0 6px 6px 0';
          } else {
            borderRadius = '0';
          }
        }

        return (
          <Tooltip
            key={item?.eid}
            label={item?.title || 'Content item'}
            placement='top'
            hasArrow
          >
            <Box
              flex={1}
              h='8px'
              bg={item?.isCompleted ? 'green.500' : 'gray.200'}
              borderRadius={borderRadius}
              cursor='pointer'
              onClick={() => handleItemClick(item?.index)}
              _hover={{
                opacity: 0.8,
                transform: 'scaleY(1.2)',
                transition: 'all 0.2s ease-in-out',
              }}
              transition='all 0.2s ease-in-out'
            />
          </Tooltip>
        );
      })}
    </Flex>
  );
};

export default ProgressBar;
