import React, { forwardRef, useRef, useState } from 'react';
import {
  Box,
  BoxProps,
  Center,
  useControllableState,
  useMergeRefs,
} from '@chakra-ui/react';
import _uniqueId from 'lodash/uniqueId';

interface IProps {
  id?: string;
  value?: string;
  onChange?: (value: string) => void;
  readOnly?: boolean;
  placeholder?: string;
  isSelected?: boolean;
  fontSize?: BoxProps['fontSize'];
  fontWeight?: BoxProps['fontWeight'];
}

const QuizTextInput = forwardRef<never, IProps>(
  (
    {
      id,
      value,
      onChange,
      readOnly,
      placeholder,
      isSelected,
      fontSize = '12px',
      fontWeight = '500',
    },
    ref
  ) => {
    const inputRef = useRef<HTMLDivElement>(null);
    const [inputId] = useState(id || _uniqueId('text-input-'));

    const [, updateValue] = useControllableState({
      value: value,
      onChange: onChange,
    });

    React.useEffect(() => {
      if (inputRef.current) {
        inputRef.current.textContent = value || '';
      }
    }, []);

    return (
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        width='full'
        position='relative'
        fontSize={fontSize}
        fontWeight={fontWeight}
        aria-selected={isSelected}
        _selected={{
          color: '#83BF6E',
          fontWeight: '600',
        }}
      >
        <Center
          ref={useMergeRefs(inputRef, ref)}
          flexDir='column'
          overflowY='auto'
          justifyContent='start'
          sx={{
            '&::-webkit-scrollbar': {
              width: '5px',
              height: '0px',
            },
            '&::-webkit-scrollbar-track': {
              // background: '#f1f1f1',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#EFEFEF',
              borderRadius: '4px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: '#555',
              cursor: 'pointer',
            },
          }}
          id={inputId}
          contentEditable={!readOnly}
          onInput={(event) => {
            const elem = event.currentTarget;
            let messageInput = elem.textContent?.trim();

            // Reset the input value
            if (!messageInput?.length) {
              event.currentTarget.textContent = messageInput!;
            }

            updateValue(event.currentTarget?.innerText || '');
          }}
          data-placeholder={placeholder || 'Type here...'}
          outline='none'
          padding='8px 12px'
          textAlign='center'
          style={{
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word',
            overflowWrap: 'break-word',
          }}
          _empty={{
            _before: {
              color: '#6F767E',
              fontWeight: '500',
              content: 'attr(data-placeholder)',
              pointerEvents: 'none',
              position: 'absolute',
            },
          }}
        />
      </Box>
    );
  }
);

QuizTextInput.displayName = 'QuizTextInput';

export default React.memo(QuizTextInput);
