import { match, P } from 'ts-pattern';
import { toArray, toNumber } from '../../../../utils';
import { ACCESS_SEPARATOR } from '../../../../configs';
import {
  QuizQuestionType,
  TAssignee,
  TAssLocationType,
  TrainingAccessEnum,
  TrainingContType,
} from '../../../../types';
import { ContentItemInput, TFormInput } from './training-input.types';
import {
  CreateTrainingInput,
  IQuizQuestion,
  ISchedulePayload,
  ITrainingContent,
} from './training.graphql';

const cleanLocationIds = (locationType?: TAssLocationType, ids?: string[]) => {
  if (!locationType || locationType === 'custom') {
    return ids || [];
  }
  return [];
};

export const getLocationType = (
  locationType: TAssLocationType = 'custom',
  ids: string[] = []
): TAssignee['locationType'] => {
  if (locationType === 'open' || locationType === 'development') {
    return locationType;
  }

  if (!locationType || locationType === 'custom') {
    return ids?.length ? 'custom' : 'all';
  }

  return 'all';
};

export const mapAssigneePayload = (
  values: Pick<TFormInput, 'assignees'>
): CreateTrainingInput['assignees'] => {
  return toArray(values?.assignees)
    .map((value) => {
      const locationIds = cleanLocationIds(
        value.locationType,
        value.locationIds
      );

      const locationType = getLocationType(value.locationType, locationIds);

      return match<TAssignee['type'], TAssignee | undefined>(value.type)
        .with('role', () => ({
          type: value.type,
          roles: value.roles || [],
          authRoles: [],
          locationIds: locationIds,
          locationType: locationType,
          userIds: [],
        }))
        .with('location', () => ({
          type: value.type,
          roles: value.roles || [],
          authRoles: value.authRoles || [],
          locationIds: locationIds,
          locationType: locationType,
          userIds: [],
        }))
        .with('user', () => ({
          type: value.type,
          roles: [],
          authRoles: [],
          locationIds: [],
          userIds: value.userIds || [],
        }))
        .with('authRole', () => ({
          type: value.type,
          roles: [],
          authRoles: value.authRoles || [],
          locationIds: locationIds,
          locationType: locationType,
          userIds: [],
        }))
        .otherwise(() => undefined);
    })
    .filter(Boolean) as TAssignee[];
};

const mapDependTraining = (
  assignmentType: TFormInput['assignmentType'],
  values: TFormInput['dependantTraining']
): ISchedulePayload['dependantTraining'] => {
  if (assignmentType === 'training') {
    const hasMinScore = values?.trainingId ? values.hasMinScore : false;
    return {
      trainingId: values.trainingId,
      hasMinScore: hasMinScore || false,
      minScorePercent: hasMinScore ? values.minScorePercent : 0,
    };
  }

  return null;
};

const mapSchedulePayload = (value: TFormInput): ISchedulePayload => {
  const rCycle = value.repeatCycle!;
  return {
    isRepeating: value.frequency === 'repeat',
    repeatCycle: match([value.frequency, rCycle?.type])
      .with(['once', P._], () => null)
      .with(['repeat', 'EVERY_N_DAYS'], () => ({
        type: rCycle.type,
        days: toNumber(rCycle.repeatValue),
      }))
      .with(['repeat', 'EVERY_N_WEEKS'], () => ({
        type: rCycle.type,
        weeks: toNumber(rCycle.repeatValue),
        dayOfTheWeek: toNumber(rCycle.dayOfTheWeek),
      }))
      .with(['repeat', 'EVERY_N_MONTHS'], () => ({
        type: rCycle.type,
        months: toNumber(rCycle.repeatValue),
        dayOfTheMonth: toNumber(rCycle.dayOfTheMonth),
      }))
      .with(['repeat', 'EVERY_N_YEARS'], () => ({
        type: rCycle.type,
        years: toNumber(rCycle.repeatValue),
        monthOfTheYear: toNumber(rCycle.monthOfTheYear),
        dayOfTheMonth: toNumber(rCycle.dayOfTheMonth),
      }))
      .otherwise(() => null),

    repeatDetails: {} as JSON,

    deadlineType: value.deadlineType,
    deadlineDate: value.deadlineType === 'date' ? value.deadlineDate : null, // TODO transform this date if required
    duration: value.deadlineType === 'duration' ? value.duration : null,

    assignmentType: value.assignmentType,
    startDate: value.assignmentType === 'date' ? value.startDate : null, // TODO transform this date if required
    dependantTraining: mapDependTraining(
      value.assignmentType,
      value.dependantTraining
    ),
  };
};

const mapQuestions = (
  value: ContentItemInput
): ITrainingContent['questions'] => {
  if (value?.type === TrainingContType.QUIZ) {
    return toArray(value?.questions)
      .map((ques) =>
        match<QuizQuestionType, IQuizQuestion>(ques.type!)
          .with(QuizQuestionType.MULTI_CHOICE, () => ({
            eid: ques.eid,
            type: QuizQuestionType.MULTI_CHOICE,
            label: ques?.label || '',
            score: toNumber(ques.score, 0),
            options: ques.options || [],
            isTrue: undefined,
            pairs: [],
            sequence: [],
            thumbnail: ques.thumbnail || '',
            textWithBlanks: '',
            blanks: [],
            hasMultipleCorrect: ques.hasMultipleCorrect,
          }))
          .with(QuizQuestionType.TRUE_FALSE, () => ({
            eid: ques.eid,
            type: QuizQuestionType.TRUE_FALSE,
            label: ques?.label || '',
            score: toNumber(ques.score, 0),
            options: [],
            isTrue: ques.isTrue,
            pairs: [],
            sequence: [],
            thumbnail: ques.thumbnail || '',
            textWithBlanks: '',
            blanks: [],
            hasMultipleCorrect: false,
          }))
          .with(QuizQuestionType.MATCH_SET, () => ({
            eid: ques.eid,
            type: QuizQuestionType.MATCH_SET,
            label: ques?.label || '',
            score: toNumber(ques.score, 0),
            options: [],
            isTrue: undefined,
            pairs: ques.pairs || [],
            sequence: [],
            thumbnail: ques.thumbnail || '',
            textWithBlanks: '',
            blanks: [],
            hasMultipleCorrect: false,
          }))
          .with(QuizQuestionType.SEQUENCE, () => ({
            eid: ques.eid,
            type: QuizQuestionType.SEQUENCE,
            label: ques?.label || '',
            score: toNumber(ques.score, 0),
            options: [],
            isTrue: undefined,
            pairs: [],
            sequence: ques.sequence || [],
            thumbnail: ques.thumbnail || '',
            textWithBlanks: '',
            blanks: [],
            hasMultipleCorrect: false,
          }))
          .with(QuizQuestionType.FILL_IN_BLANK, () => ({
            eid: ques.eid,
            type: QuizQuestionType.FILL_IN_BLANK,
            label: ques?.label || '',
            score: toNumber(ques.score, 0),
            options: [],
            isTrue: undefined,
            pairs: [],
            sequence: [],
            thumbnail: ques.thumbnail || '',
            textWithBlanks: ques.textWithBlanks || '',
            blanks: toArray(ques.blanks).map((bl) => ({
              uid: bl.uid,
              answer: bl.answer,
            })),
            hasMultipleCorrect: false,
          }))
          .otherwise(() => null as never)
      )
      .filter(Boolean);
  }
  return [];
};

const mapContentPayload = (
  contents: TFormInput['contents']
): CreateTrainingInput['contents'] => {
  return toArray(contents).map((cont) => ({
    type: cont.type,
    eid: cont.eid,
    title: cont.title,
    thumbnail: cont.thumbnail!,
    addedBy: cont.addedBy,
    questions: mapQuestions(cont),
    hasScoring: toArray(cont?.questions).some((q) => q.score),
  }));
};

const toAccessData = (value: string = '') => {
  return value.split(ACCESS_SEPARATOR).filter(Boolean) as TrainingAccessEnum[];
};

export const mapAccessPermission = (
  values: TFormInput['accessPermission']
): CreateTrainingInput['accessPermission'] => {
  if (values) {
    return {
      admin: toAccessData(values.admin),
      locationOwner: toAccessData(values.locationOwner),
      superadmin: toAccessData(values.superadmin),
    };
  }

  return undefined as never;
};

const mapTrainingScore = (contents: CreateTrainingInput['contents']) => {
  const _content = contents.filter((q) => q.hasScoring);
  if (_content.length) {
    return {
      hasScoring: true,
      maxScore: _content.reduce((acc, content) => {
        if (content.questions?.length) {
          return content.questions.reduce(
            (a, b) => a + toNumber(b.score, 0),
            acc
          );
        }
        return acc;
      }, 0),
    };
  }
  return {
    hasScoring: false,
    maxScore: 0,
  };
};

export const toPayloadInput = (value: TFormInput): CreateTrainingInput => {
  const contents = mapContentPayload(value.contents);
  return {
    currentEditPage: value.currentEditPage?.toString(),

    title: value.title,
    description: value.description,
    thumbnail: value.thumbnail,
    thumbnailColor: value.thumbnailColor,
    category: value.category,

    contents: contents,

    ...mapTrainingScore(contents),

    assigneeUserType: value.assigneeUserType,
    assignees: mapAssigneePayload(value),

    ...mapSchedulePayload(value),

    hasSkillVerifier: value.hasSkillVerifier,
    skillVerifier: value.skillVerifier!,

    supervisors: value.hasSkillVerifier ? value.supervisors : [],

    accessPermission: mapAccessPermission(value.accessPermission),
  };
};

// export const toFormInput = () => {}
