import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useEffect,
  useRef,
} from 'react';
import { Box, Flex } from '@chakra-ui/react';
import axios, { CancelTokenSource } from 'axios';
import { useSafeState } from '../../../hooks';
import { Any } from '../../../types';
import { AI_SEARCH_ENDPOINT } from '../../../utils/constants';

import FetchingResult from './FetchingResult';
import NoResultFound from './NoResultFound';
import SearchResultView from './SearchResultView';
import { RecentAISearch } from './recent-search';
import { AiResponse } from './ai-search.types';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';

interface IProps {
  searchQuery?: string;
  updateSearchQuery?: (value: string) => void;
}

export interface AiSearchRef {
  start: (query: string) => Promise<Any>;
}

const AiSearchContent = forwardRef<AiSearchRef, IProps>(
  ({ searchQuery: query, updateSearchQuery }, ref) => {
    const cancelRef = useRef<CancelTokenSource>();

    const [searchQuery, setSearchQuery] = useSafeState<string>();

    const [isLoading, setLoading] = useSafeState(false);

    const [data, setData] = useSafeState<AiResponse>();

    const fetData = useCallback((query?: string) => {
      setSearchQuery(query);
      if (!query) {
        return;
      }
      cancelRef.current = axios.CancelToken.source();
      setLoading(true);
      deployEvent(AmplitudeEventNames.AI_POWERED_SEARCH, {
        event: {
          search_funnel_id: 3,
          search_keyword: query,
        },
      });
      return axios
        .post(
          AI_SEARCH_ENDPOINT,
          {
            query: query,
          },
          {
            cancelToken: cancelRef.current.token,
            headers: {
              Authorization: `Bearer ${localStorage.getItem('authToken')}`,
            },
          }
        )
        .then((response) => {
          setData(response.data as never);
          setLoading(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setLoading(false);
            console.warn(err);
          }
        });
    }, []);

    // useEffect(() => {
    //   cancelRef.current?.cancel();
    //
    //   const x = setTimeout(() => {
    //     fetData(searchQuery);
    //   });
    //
    //   return () => {
    //     clearTimeout(x);
    //     cancelRef.current?.cancel();
    //   };
    // }, [searchQuery]);

    useEffect(() => {
      if (query?.trim()?.length) {
        fetData(query);
      }
      return () => {
        cancelRef.current?.cancel();
      };
    }, []);

    useImperativeHandle(
      ref,
      () => {
        return {
          start: async (query: string) => {
            cancelRef.current?.cancel();
            await fetData(query);
          },
        };
      },
      []
    );

    return (
      <Flex flexDir='column' px='32px' gap={3} mt={4}>
        {searchQuery ? (
          <>
            <Box
              fontSize='15px'
              fontWeight='600'
              color='#6F767E'
              hidden={!isLoading && !data?.success}
            >
              Search response:
            </Box>

            {isLoading ? (
              <FetchingResult />
            ) : data?.success ? (
              <SearchResultView
                data={data}
                onRegenerate={() => fetData(searchQuery)}
              />
            ) : (
              <NoResultFound
                data={data}
                onRegenerate={() => fetData(searchQuery)}
              />
            )}
          </>
        ) : (
          <RecentAISearch onClick={updateSearchQuery} />
        )}

        <Box fontSize='12px' color='#6F767E' textAlign='center'>
          Search quality and accuracy may vary. Recheck important information
        </Box>
      </Flex>
    );
  }
);

AiSearchContent.displayName = 'AiSearchContent';

export default React.memo(AiSearchContent);
