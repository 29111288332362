export const generateVideoThumbnail = <B extends boolean = false>(
  file: File | string,
  quality?: number,
  blob?: B
): Promise<(B extends true ? Blob : string) | null> => {
  return new Promise((resolve) => {
    // Fallback image
    const createFallbackThumbnail = () => {
      const canvas = document.createElement('canvas');
      canvas.width = 320;
      canvas.height = 180;
      const ctx = canvas.getContext('2d');

      if (ctx) {
        // Gray background
        ctx.fillStyle = '#f0f0f0';
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        // Draw a play button
        ctx.fillStyle = '#666666';
        ctx.beginPath();
        ctx.moveTo(canvas.width / 2 - 30, canvas.height / 2 - 30);
        ctx.lineTo(canvas.width / 2 + 30, canvas.height / 2);
        ctx.lineTo(canvas.width / 2 - 30, canvas.height / 2 + 30);
        ctx.closePath();
        ctx.fill();

        if (blob) {
          canvas.toBlob(
            (blobData) => resolve(blobData as B extends true ? Blob : string),
            'image/png',
            quality
          );
        } else {
          const url = canvas.toDataURL('image/png', quality);
          return resolve(url as B extends true ? Blob : string);
        }
      } else {
        resolve(null);
      }
    };

    const canvas = document.createElement('canvas');
    const video = document.createElement('video');

    // Timeout to prevent hanging
    const timeout = setTimeout(() => {
      console.log('Video thumbnail generation timed out');
      video.removeAttribute('src');
      video.load();
      if (typeof file !== 'string') {
        URL.revokeObjectURL(video.src);
      }
      createFallbackThumbnail();
    }, 5000); // 5 second timeout

    // Error handler
    video.onerror = (e) => {
      console.error('Error loading video for thumbnail generation:', e);
      clearTimeout(timeout);
      if (typeof file !== 'string') {
        URL.revokeObjectURL(video.src);
      }
      createFallbackThumbnail();
    };

    video.autoplay = false;
    video.muted = true;
    video.volume = 0;
    video.crossOrigin = 'anonymous';
    video.preload = 'metadata';

    // Using onloadedmetadata first to ensure dimensions are there
    video.onloadedmetadata = () => {
      console.log('Video metadata loaded');
      if (video.videoWidth === 0 || video.videoHeight === 0) {
        console.error('Invalid video dimensions');
        clearTimeout(timeout);
        createFallbackThumbnail();
        return;
      }

      // Seeking to the time that is required to be captured
      try {
        video.currentTime = 0.1; // Seek to a very early frame
      } catch (e) {
        console.error('Error seeking video:', e);
        clearTimeout(timeout);
        createFallbackThumbnail();
      }
    };

    // Use seeked event
    video.onseeked = () => {
      let ctx = canvas.getContext('2d');

      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      try {
        ctx?.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

        // Clean up
        video.pause();
        clearTimeout(timeout);
        if (typeof file !== 'string') {
          URL.revokeObjectURL(video.src);
        }

        if (blob) {
          canvas.toBlob(
            (blobData) => resolve(blobData as B extends true ? Blob : string),
            'image/png', // Changed to png for better compatibility
            quality
          );
        } else {
          const url = canvas.toDataURL('image/png', quality);
          return resolve(url as B extends true ? Blob : string);
        }
      } catch (e) {
        console.error('Error drawing video to canvas:', e);
        clearTimeout(timeout);
        if (typeof file !== 'string') {
          URL.revokeObjectURL(video.src);
        }
        createFallbackThumbnail();
      }
    };

    // Set the source after setting up all event handlers
    try {
      if (typeof file === 'string') {
        video.src = file;
      } else {
        video.src = URL.createObjectURL(file);
      }
      video.load();
    } catch (e) {
      console.error('Error setting video source:', e);
      createFallbackThumbnail();
    }
  });
};
