import React, { FC, useState } from 'react';
import { ITypes } from '../Header';
import BaseFilter from './BaseFilter';
import { ClearFilterType } from './FilterContainer';
import TypeFilter from './TypeFilter';
import StatusFilter from './StatusFilter';
import CategoryFilter from './CategoryFilter';

export type PageNameType = 'base' | 'statuses' | 'types' | 'categories';

interface IProps {
  filters: any;
  setFilters: any;
  handleTypeSelection: (
    e: React.ChangeEvent<HTMLInputElement>,
    type: ITypes
  ) => void;
  handleStatusSelection: (
    e: React.ChangeEvent<HTMLInputElement>,
    status: ITypes
  ) => void;
  handleCategorySelection: (
    e: React.ChangeEvent<HTMLInputElement>,
    category: ITypes
  ) => void;
  clearFilters: (type?: ClearFilterType) => void;
}

const MainContainer: FC<IProps> = ({
  setFilters,
  filters,
  handleTypeSelection,
  handleStatusSelection,
  handleCategorySelection,
  clearFilters,
}) => {
  const [pageName, setPageName] = useState<PageNameType>('base');

  if (pageName === 'types') {
    return (
      <TypeFilter
        clearFilters={clearFilters}
        filters={filters}
        setPageName={setPageName}
        handleTypeSelection={handleTypeSelection}
      />
    );
  }

  if (pageName === 'statuses') {
    return (
      <StatusFilter
        clearFilters={clearFilters}
        handleStatusSelection={handleStatusSelection}
        filters={filters}
        setPageName={setPageName}
      />
    );
  }

  if (pageName === 'categories') {
    return (
      <CategoryFilter
        clearFilters={clearFilters}
        handleCategorySelection={handleCategorySelection}
        filters={filters}
        setPageName={setPageName}
      />
    );
  }

  return (
    <BaseFilter
      clearFilters={clearFilters}
      filters={filters}
      setPageName={setPageName}
    />
  );
};

export default MainContainer;
