import React, { forwardRef, useMemo } from 'react';
import { Dropdown, SelectOption } from '../../../../../../../atoms';
import { IRepeatInput } from './repeating.types';

const options: SelectOption<IRepeatInput['type']>[] = [
  {
    label: 'Day(s)',
    value: 'EVERY_N_DAYS',
  },
  {
    label: 'Week(s)',
    value: 'EVERY_N_WEEKS',
  },
  {
    label: 'Month(s)',
    value: 'EVERY_N_MONTHS',
  },
  {
    label: 'Year(s)',
    value: 'EVERY_N_YEARS',
  },
];

interface IProps {
  value: IRepeatInput['type'];
  onChange: (value: IRepeatInput['type']) => void;
}

const RepeatTypeSelect = forwardRef<never, IProps>(
  ({ value, onChange }, ref) => {
    const selected = useMemo(() => {
      return options.find((op) => op.value === value);
    }, [value]);

    return (
      <Dropdown
        ref={ref}
        size='lg'
        value={selected}
        onChange={(nextValue) => onChange(nextValue.value)}
        options={options}
        selectStyles={{
          container: {
            background: 'transparent',
          },
          control: {
            border: '2px solid',
          },
          menuList: {
            padding: '16px',
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            minWidth: 0,
          },
          option: {
            fontSize: '15px',
            fontWeight: 600,
            padding: '12px',
            borderRadius: '10px',
          },
          singleValue: {
            fontSize: '15px',
            fontWeight: 600,
            color: '#6F767E',
          },
        }}
      />
    );
  }
);

RepeatTypeSelect.displayName = 'RepeatTypeSelect';

export default RepeatTypeSelect;
