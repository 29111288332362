import React, { FC, useContext, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FORM_ANALYTICS } from 'pages/forms/forms-graphql';
import FormDataContext from 'pages/forms/FormStore/form-data-context';
import { colorsArray } from './colorsArray';
import { ISummary, ISummaryData } from './form-summary.types';
import { formSummaryFunctions } from './form-summary-functions';
import { statusChecker } from './status-checker';
import { ILocationFilter } from '../FormResponseTable/FormResponseTable';
import LocationFilter from './LocationFilter';

interface IProps {
  locationsData: ILocationFilter[] | undefined;
}

const FormSummary: FC<IProps> = ({ locationsData }) => {
  const { t } = useTranslation(['form']);
  const params: { id: string } = useParams();
  const formCtx = useContext(FormDataContext);

  const [summaryData, setSummaryData] = useState<ISummaryData>({
    checkListData: [],
    singleChoice: [],
    multipleChoice: [],
    dropdown: [],
    locationUser: [],
    member: [],
  });

  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);

  const ticks = [0, 0.25, 0.5, 0.75, 1];
  const domain = [0, 1];

  const [getFormAnalytics, { loading }] = useLazyQuery(FORM_ANALYTICS, {
    fetchPolicy: 'network-only',
    onCompleted: (data: ISummary) => {
      let {
        _dropDownData,
        _multiChoiceData,
        _locationUserData,
        _memberData,
        _singleChoiceData,
        _checkBoxData,
      } = formSummaryFunctions(data, colorsArray, formCtx);

      setSummaryData({
        ...summaryData,
        dropdown: _dropDownData,
        multipleChoice: _multiChoiceData,
        locationUser: _locationUserData,
        member: _memberData,
        singleChoice: _singleChoiceData,
        checkListData: _checkBoxData,
      });
    },
  });

  const handleLocationChange = (newSelectedLocations: string[]) => {
    setSelectedLocations(newSelectedLocations);
    const locationIds =
      newSelectedLocations.length > 0 ? newSelectedLocations : undefined;
    getFormAnalytics({
      variables: {
        eid: params?.id,
        locationIds: locationIds,
      },
    });
  };

  useEffect(() => {
    getFormAnalytics({
      variables: {
        eid: params?.id,
        locationIds:
          selectedLocations.length > 0 ? selectedLocations : undefined,
      },
    });
  }, []);

  const locationOptions =
    locationsData?.map((location) => ({
      value: location.eid,
      label: location.name,
    })) || [];

  console.log(summaryData, 'sd');

  return (
    <>
      <LocationFilter
        selectedLocations={selectedLocations}
        onLocationChange={handleLocationChange}
        locations={locationOptions}
        loading={loading}
      />
      {statusChecker(
        loading,
        summaryData,
        domain,
        colorsArray,
        ticks,
        t,
        locationsData
      )}
    </>
  );
};

export default FormSummary;
