import { TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import React, { FC } from 'react';
import { CustomTab } from 'sub-components/nexus/NexusBase/components/LeftSection/components/LocationLeaderboard/components';
import NexusTraining from './NexusTraining';
import TrackMembers from 'sub-components/TrainingsBoard/TrackMembers';
import { OverdueTasks } from '.';

const SupervisedTabs: FC = () => {
  return (
    <Tabs variant='unstyled' w='full' isLazy>
      <TabList w='full' gap={4}>
        <CustomTab
          key='overdueTasks'
          label='Overdue tasks'
          minWidth='fit-content'
        />
        <CustomTab key='trainings' label='Trainings' minWidth='fit-content' />
      </TabList>
      <TabPanels>
        <TabPanel key={'overdueTasks'} px={0} py={4}>
          <OverdueTasks />
        </TabPanel>
        <TabPanel key={'trainings'} px={0} py={4}>
          {/* <TrackMembers layout='card' setShowViewAllBtn={() => {}} /> */}
          <NexusTraining />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default SupervisedTabs;
