import { HeaderColors } from 'configs';
import { FC } from 'react';
import GenericColorHeader from 'sub-components/GenericColorHeader/GenericColorHeader';
import CommonContainer from 'ui-components/CommonContainer/CommonContainer';
import { LocationFilter } from './components';

export interface LocationAttentionContainerProps {
  locMakeLiveHandler?: (locationId: string) => void;
  cardsData: Array<{
    id: string;
    name: string;
    status: string;
    completionRate: number;
  }>;
}

const LocationAttentionContainer: FC<LocationAttentionContainerProps> = ({
  locMakeLiveHandler,
  cardsData,
}) => {
  // Check if we should hide the container
  const shouldHideContainer =
    (cardsData.length === 1 && !cardsData[0]?.status) ||
    cardsData.every((item) => !item.status);

  if (shouldHideContainer) {
    return null;
  }

  return (
    <CommonContainer
      display='flex'
      flexDir='column'
      gap={'8px'}
      bg='white'
      borderRadius='6px'
      p='20px'
    >
      <GenericColorHeader
        title='Locations requiring attention'
        color={'#B5E4CA'}
      />
      <LocationFilter
        locMakeLiveHandler={locMakeLiveHandler}
        locationData={cardsData}
      />
    </CommonContainer>
  );
};

export default LocationAttentionContainer;
