import React, { FC } from 'react';
import { Popover, PopoverContent, PopoverTrigger } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { Button } from '../../../../../../atoms';
import { TrainingContType } from '../../../../../../types';
import AddContentBody from './AddContentBody';

interface IProps {
  onClick?: (action: TrainingContType) => void;
}

const AddContentButton: FC<IProps> = ({ onClick }) => {
  return (
    <Popover isLazy placement='top-start'>
      <PopoverTrigger>
        <Button variant='outline' maxWidth='max-content' leftIcon={<AddIcon />}>
          Add content
        </Button>
      </PopoverTrigger>

      <PopoverContent
        width='448px'
        borderRadius='12px'
        boxShadow='md'
        _focus={{}}
      >
        <AddContentBody onClick={onClick} />
      </PopoverContent>
    </Popover>
  );
};

export default AddContentButton;
