import React, { FC } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { Box, Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { TFormInput } from '../../training-input.types';
import { TrainingDateSelect } from './date-picker';

interface IProps {
  disabled?: boolean;
}

const CustomStartDate: FC<IProps> = ({ disabled }) => {
  const assignmentType = useWatch<TFormInput, 'assignmentType'>({
    name: 'assignmentType',
  });

  if (assignmentType !== 'date') {
    return null;
  }

  return (
    <Flex flex={1} gap={5}>
      <Box fontSize='13px' fontWeight='600' lineHeight='45px'>
        on
      </Box>
      <Controller<TFormInput, 'startDate'>
        name='startDate'
        disabled={disabled}
        rules={{
          required: 'Please select start date',
          deps: ['deadlineDate'],
        }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid}>
            <TrainingDateSelect
              disabled={field.disabled}
              onChange={field.onChange}
              selectedValue={field.value || null}
              placeholderText='Select start date'
              minDate={new Date()}
              shouldCloseOnSelect
            />
            <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
          </FormControl>
        )}
      />
    </Flex>
  );
};

export default CustomStartDate;
