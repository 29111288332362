import React, { FC } from 'react';
import {
  CloseButton,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/react';
import { Controller, useWatch } from 'react-hook-form';
import { TAssignByType } from '../../../../../../../types';
import { IAssigneeInput } from '../../../training-input.types';
import { TrainingJobSelect } from '../job-select';

interface IProps {
  type?: TAssignByType;
  index: number;
  disabled?: boolean;
  removeType?: () => void;
}

const LocationRoleSelect: FC<IProps> = ({
  type,
  index,
  disabled,
  removeType,
}) => {
  const value = useWatch<IAssigneeInput, `assignees.${number}.roles`>({
    name: `assignees.${index}.roles`,
    defaultValue: [],
  });

  if (type === 'role' || value?.length) {
    return (
      <div>
        <Controller<IAssigneeInput, `assignees.${number}.roles`>
          name={`assignees.${index}.roles`}
          disabled={disabled}
          rules={{
            required: 'Select assignee jobs',
          }}
          render={({ field, fieldState }) => (
            <FormControl isInvalid={fieldState.invalid}>
              <Flex justify='space-between' align='center' marginBottom={1}>
                <FormLabel color='#6F767E' marginBottom={0}>
                  with job
                </FormLabel>

                {disabled ? null : (
                  <CloseButton
                    color='blue.500'
                    onClick={() => {
                      removeType?.();
                      field.onChange([]);
                    }}
                  />
                )}
              </Flex>

              <TrainingJobSelect {...field} />
              <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
            </FormControl>
          )}
        />
      </div>
    );
  }

  return null;
};

export default LocationRoleSelect;
