import {
  Avatar,
  Box,
  Center,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import React, { FC, useState } from 'react';
import RenderScoreCard from './RenderScoreCard';
import BaseListRender from './BaseListRender';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { Button, Table } from 'atoms';
import { BoxHeader } from 'ui-components';
import { HeaderColors } from 'configs';
import { ColumnsType } from 'antd/es/table';
import { TpOverallUserWiseAnalytics } from '../../Tracking/tracking-types';
import { useSortedList } from './LocationList';
import { faArrowDownShortWide } from '@fortawesome/pro-solid-svg-icons';
import { getImage } from 'utils';
import EmptyState from 'sub-components/EmptyState';

interface IProps {
  tab: string;
  data: TpOverallUserWiseAnalytics[];
}

const MemberList: FC<IProps> = ({ tab, data }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const sortedList = useSortedList(data, tab);
  const [sortType, setSortType] = useState<'asc' | 'desc'>('desc');

  const sortedDataList = () => {
    let dataList = [...sortedList];
    return sortType === 'asc' ? dataList.reverse() : dataList;
  };

  const handleSortToggle = () => {
    setSortType((prevState) => {
      if (prevState === 'asc') {
        return 'desc';
      } else {
        return 'asc';
      }
    });
  };

  const columns: ColumnsType<any> = [
    {
      title: (
        <Flex gap={1} align='center' flex={1} justifyContent='center'>
          <Text fontSize='13px' fontWeight={500} textColor={'#6F767E'}>
            Rank
          </Text>
          <FontAwesomeIcon
            color={'#6F767E'}
            cursor={'pointer'}
            icon={faArrowDownShortWide as IconProp}
            onClick={handleSortToggle}
          />
        </Flex>
      ),
      dataIndex: 'rank',
      key: 'rank',
      width: '30%',
      align: 'center',
      render: (data) => (
        <Flex as='span' fontWeight={500} justifyContent='center'>
          {data}
        </Flex>
      ),
    },
    {
      title: 'Members',
      dataIndex: 'userName',
      key: 'userName',
      width: '50%',
      render: (data, record) => {
        return (
          <Flex gap={2} alignItems='center'>
            <Image
              src={getImage(record.image, data)}
              width='6'
              height='6'
              borderRadius='5px'
            />
            <Text as='span' color='#111315' fontWeight={600} fontSize='14px'>
              {data}
            </Text>
          </Flex>
        );
      },
    },

    {
      title: 'Rate(%)',
      dataIndex: 'completionRate',
      key: 'completionRate',
      width: '20%',
      align: 'center',
      render: (data) => (
        <Flex color='#000000' fontWeight={500} gap={2} justifyContent='center'>
          {data}
        </Flex>
      ),
    },
  ];

  return (
    <Flex
      width={'100%'}
      gap={8}
      flexDir={'column'}
      mt={'35px'}
      alignItems={'center'}
    >
      {data?.length ? (
        <>
          <RenderScoreCard type='overall' completeData={sortedList} />
          <BaseListRender tab={tab} data={(sortedList || [])?.slice(3)} />
          {sortedList?.length > 3 && (
            <Button
              variant='unstyled'
              color='#2A85FF'
              fontWeight={600}
              alignItems='center'
              gap={2}
              display='flex'
              onClick={onOpen}
            >
              View all
              <FontAwesomeIcon
                icon={faArrowRight as IconProp}
                fontSize='15px'
                color='#2A85FF'
              />
            </Button>
          )}

          <Modal isOpen={isOpen} onClose={onClose} isCentered size='2xl'>
            <ModalOverlay />
            <ModalContent padding='12px' borderRadius='12px'>
              <ModalHeader>
                <BoxHeader
                  title='Training completion rate'
                  color={HeaderColors.Yellow}
                />
              </ModalHeader>
              <ModalCloseButton top='1.5rem' right='1.5rem' fontSize='18px' />
              <ModalBody display='flex' flexDirection='column' gap={5}>
                <Text as='span' color='#272B30' fontWeight={600}>
                  All your members at a glance
                </Text>
                <Box overflowY={'scroll'} maxHeight={'505px'}>
                  <Table
                    columns={columns}
                    dataSource={sortedDataList()}
                    rowKey='id'
                    pagination={false}
                    bordered
                  />
                </Box>
              </ModalBody>
            </ModalContent>
          </Modal>
        </>
      ) : (
        <Center height='100%' width='100%'>
          <EmptyState image='Report' title='No data found' imageSize='300px' />
        </Center>
      )}
    </Flex>
  );
};

export default MemberList;
