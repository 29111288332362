import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import { cloneDeep } from '@apollo/client/utilities';
import { ActionButton } from '../../../../../ui-components';
import { useIdGenerator, useLatest } from '../../../../../hooks';
import { AmplitudeEvent, deployEvent } from '../../../../../shared';
import { ITaskInput } from './task-form.types';
import { isDependencyError } from '../useTaskBeforeSave';
import { useLauncherAlert } from '../useLauncherAlert';
import { getBoardViewName } from '../launcher-event.helpers';
import { useTaskTriggerSetting } from './task-trigger-setting';

interface IProps {
  sectionIndex: number;
  taskIndex: number;
  defaultEid?: string;
  beforeSave?: (task: ITaskInput) => Promise<void>;
  onSubmit: (values: ITaskInput) => Promise<void>;
  onDiscardClick?: () => void;
}

const TaskSaveButton: FC<IProps> = ({
  defaultEid,
  beforeSave,
  onSubmit,
  sectionIndex,
  taskIndex,
  onDiscardClick,
}) => {
  const { isDirty } = useFormState();
  const { handleSubmit, getValues, setValue } = useFormContext<ITaskInput>();
  const title = useWatch({ name: 'title' });
  const generateId = useIdGenerator();
  const launcherAlert = useLauncherAlert();
  const addTrigger = useTaskTriggerSetting();

  const taskName = `Phase ${sectionIndex + 1} Task ${taskIndex + 1}: ${title}`;

  const triggerRef = useLatest(() => {
    addTrigger({
      taskName: taskName,
      defaultValue: {
        eid: getValues('eid'),
        trigger: getValues('trigger'),
      },
      onSaveChanges: (values) => {
        setValue('trigger', values.trigger, {
          shouldDirty: true,
        });
      },
    });
  });

  const submitProxy = async (values: ITaskInput) => {
    const input = cloneDeep(values);

    if (!input.eid) {
      input.eid = await generateId();
    }

    try {
      await beforeSave?.(input);
    } catch (err) {
      if (isDependencyError(err as Error)) {
        launcherAlert({
          title: 'Please note',
          description:
            'This configuration creates a circular dependency, which could lead to process deadlocks. Please revise your selection to proceed.',
          buttonText: 'Edit trigger settings',
          cancelText: null,
          onConfirm: triggerRef.current,
        });
      }
      return Promise.reject(err);
    }
    await onSubmit?.(input);
    if (!values?.eid) {
      deployEvent(AmplitudeEvent.LAUNCHER_ADD_TASK, {
        board_creation_funnel_id: 5,
        task_id: input.eid,
        task_title: input.title,
        task_category: input?.category,
        phase_title: values?.phase?.category,
        document_mandatory: input?.docRequired,
        total_steps: input?.steps?.length,
        task_assignee: input?.assignedType,
        dynamic_activation_trigger_set:
          input?.trigger?.triggerChoice === 'activation',
        dynamic_deadline_trigger_set:
          input?.trigger?.triggerChoice === 'deadline',
        view: getBoardViewName(),
        // board_creation_method: ''
      });
    }
  };

  return (
    <Flex gap={8}>
      <ActionButton
        flex={1}
        size='lg'
        variant='outline'
        isDisabled={!isDirty}
        actionFn={onDiscardClick}
      >
        Discard changes
      </ActionButton>

      <ActionButton
        flex={1}
        size='lg'
        colorScheme='blue'
        isDisabled={!isDirty}
        actionFn={handleSubmit(submitProxy)}
      >
        {defaultEid ? 'Save changes' : 'Add'}
      </ActionButton>
    </Flex>
  );
};

TaskSaveButton.displayName =
  'sub-components/Launcher/launcher-config/common/config-task-form/TaskSaveButton';

export default TaskSaveButton;
