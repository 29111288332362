import { TAssignee } from '../../../../../../../types';
import { getLocationType } from '../../../data-mapper.helper';

// Helper function to generate strings for roles, authRoles, and locations
const generateString = (
  prefix: string,
  ids: string[] = [],
  locations: string[] = [],
  locationType?: TAssignee['locationType']
) => {
  if (!locationType || locationType === 'custom') {
    return ids.flatMap((id) =>
      locations.map((location) => `${prefix}-${id}-${location}`)
    );
  }

  return ids.map((id) => `${prefix}-${id}-${locationType}`);
};

export const convertToPlainArray = (assignees: TAssignee[]): string[] => {
  // Return an empty array immediately if input is not an array or is empty
  if (!Array.isArray(assignees) || assignees.length === 0) {
    return [];
  }

  const plainArray: string[] = [];

  // Iterate through assignees and process them based on type
  assignees.forEach(
    ({ type, roles, authRoles, locationIds, locationType, userIds }) => {
      const _locationType = getLocationType(locationType, locationIds);
      switch (type) {
        case 'role':
          plainArray.push(
            ...generateString('role', roles, locationIds, _locationType)
          );
          break;

        case 'authRole':
          plainArray.push(
            ...generateString('authRole', authRoles, locationIds, _locationType)
          );
          break;

        case 'user':
          userIds.forEach((userId) => plainArray.push(`user-${userId}`));
          break;

        case 'location':
          if (roles?.length) {
            plainArray.push(
              ...generateString('role', roles, locationIds, _locationType)
            );
          } else if (authRoles?.length) {
            plainArray.push(
              ...generateString(
                'authRole',
                authRoles,
                locationIds,
                _locationType
              )
            );
          } else {
            if (!locationType || locationType === 'custom') {
              locationIds.forEach((location) =>
                plainArray.push(`location-${location}`)
              );
            } else {
              plainArray.push(`location-${locationType}`);
            }
          }
          break;
      }
    }
  );

  return plainArray;
};

export function isSubsetOfAssignees(
  trainingAssignees: TAssignee[],
  currentAssignees: TAssignee[]
): boolean {
  // Check if either of the arrays is not provided or invalid
  if (!Array.isArray(trainingAssignees) || !Array.isArray(currentAssignees)) {
    return false;
  }

  // If the currentAssignees array is empty, it's always a subset
  if (currentAssignees.length === 0) {
    return true;
  }

  const trainAssignee = convertToPlainArray(trainingAssignees);
  const currAssignee = convertToPlainArray(currentAssignees);

  return currAssignee.every((assignee) => trainAssignee.includes(assignee));
}
