import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Box, Center, Flex, Image } from '@chakra-ui/react';
import { ColumnsType, Table } from 'atoms';
import React, { FC } from 'react';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';
import { RatingData } from 'sub-components/nexus/NexusBase/components/LeftSection/components/CustomerFeedback/types';
import { LocationRating } from '../types';
import { useCarrouselModal } from './useCarouselModal';

interface IProps {
  graphData: LocationRating[];
}

const RatingSection: FC<IProps> = ({ graphData }) => {
  const carrouselModal = useCarrouselModal();
  const columns: ColumnsType<LocationRating> = [
    {
      title: '',
      key: 'location',
      dataIndex: 'location',
      width: '30%',
    },
    {
      title: 'Rating',
      key: 'rating',
      dataIndex: 'googleRating',
      width: '30%',
      render(value) {
        const displayValue = value ?? '-';
        const color =
          value === null || value === undefined
            ? 'black'
            : value < 3
            ? 'rgba(255, 106, 85, 1)'
            : 'rgba(131, 191, 110, 1)';

        return (
          <Box fontWeight={600} color={color}>
            {displayValue}
          </Box>
        );
      },
    },
    {
      title: 'Images',
      key: 'images',
      dataIndex: 'images',
      width: '30%',
      render(_, record) {
        const totalImages = record?.galleryData?.length || 0;
        const displayedImages = record?.galleryData?.slice(0, 6) || [];
        const remainingCount = totalImages > 6 ? totalImages - 6 : 0;
        if (totalImages === 0) {
          return (
            <Flex align='center' fontWeight={600} boxSize='30px' color='black'>
              -
            </Flex>
          );
        }
        return (
          <Flex gap={1} align='center'>
            {displayedImages?.slice(0, 6)?.map((gallery) => (
              <Image
                src={`${gallery?.photoUrl}&width=100&height=100`}
                minW='30px'
                maxW='30px'
                minH='30px'
                maxH='30px'
                borderRadius='4px'
              />
            ))}
            {remainingCount > 0 && (
              <Center
                color='rgba(111, 118, 126, 1)'
                fontSize='12px'
                minW='30px'
                maxW='30px'
                minH='30px'
                maxH='30px'
              >
                +{remainingCount}
              </Center>
            )}
            <ArrowForwardIcon
              bg='rgba(244, 244, 244, 1)'
              borderRadius='50%'
              fontSize='32px'
              p='7px'
              cursor='pointer'
              onClick={() => {
                deployEvent(
                  AmplitudeEventNames.VIEW_MORE_IMAGES_CUSTOMER_FEEDBACK,
                  {
                    event: {
                      location_name: record?.location,
                      rating: record?.googleRating,
                      total_images: record?.galleryData?.length || 0,
                    },
                  }
                );
                carrouselModal({
                  galleryData: record?.galleryData,
                  locationName: record?.location,
                });
              }}
            />
          </Flex>
        );
      },
    },
  ];

  return (
    <Table
      dataSource={graphData}
      columns={columns}
      pagination={{ hideOnSinglePage: true }}
    />
  );
};

export default RatingSection;
