import { useSafeState, useUserEntity } from 'hooks';
import { useEffect, useMemo } from 'react';
import { ImageItem } from 'sub-components/nexus/Gallery/types';
import { useGetLocationIds } from 'sub-components/nexus/NexusBase/utils';
import { ViewType } from '../types';
import { useService } from './useService';

export const useControl = () => {
  const {
    internalPhotos: { internalPhotos, internalPhotosRes },
    locPhotos: { locPhotos, locPhotosRes },
  } = useService();
  const locationIds = useGetLocationIds(true);
  const isAuditEnabled = useUserEntity((entity) => entity?.features?.audit);

  const [viewType, setViewType] = useSafeState<ViewType>(
    isAuditEnabled ? 'customer' : 'customer'
  );

  const handleViewType = (type: ViewType) => {
    setViewType(type);
  };

  useEffect(() => {
    if (locationIds?.length > 0) {
      if (viewType === 'customer') {
        locPhotos({
          variables: {
            locationIds: locationIds,
          },
        });
      } else if (viewType === 'internal') {
        internalPhotos({
          variables: {
            locationIds: locationIds,
          },
        });
      }
    }
  }, [locationIds, viewType]);

  const galleryImages: ImageItem[] = useMemo(() => {
    if (viewType === 'customer') {
      const data = locPhotosRes?.data?.LocationGooglePhotos;
      if (!data || data?.length === 0) {
        return [];
      }
      return (
        data?.map((item) => ({
          title: item?.name,
          description: item?.name,
          url: `${item?.photoUrl}`,
          source: 'locPhotos' as 'locPhotos',
          googlePlaceId: item.name,
        })) || []
      );
    } else {
      const data = internalPhotosRes?.data?.LocationInternalPhotos;
      if (!data || data?.length === 0) {
        return [];
      }
      return (
        data?.map((item) => ({
          title: item?.name,
          description: item?.name,
          url: item?.url,
          source: 'internal' as 'internal',
          auditId: item?.meta?.auditId,
          sessionId: item?.meta?.sessionId,
        })) || []
      );
    }
  }, [
    viewType,
    locPhotosRes?.data?.LocationGooglePhotos,
    internalPhotosRes?.data?.LocationInternalPhotos,
  ]);

  return {
    loading:
      viewType === 'customer'
        ? locPhotosRes?.loading
        : internalPhotosRes?.loading,
    isAuditEnabled,
    galleryImages,
    viewType,
    handleViewType,
  };
};
