import { match } from 'ts-pattern';
import { v4 as uuidv4 } from 'uuid';
import { QuizQFormInput } from './quiz-form.types';
import { QuizQuestionType } from '../../../../../../../types';

export const getDefaultValue = (
  values: Partial<QuizQFormInput>,
  nextValue?: Partial<QuizQFormInput>
): QuizQFormInput => {
  return match<QuizQuestionType, QuizQFormInput>(values.type!)
    .with(QuizQuestionType.MULTI_CHOICE, () => ({
      type: QuizQuestionType.MULTI_CHOICE,
      label: nextValue?.label || '',
      score: 0,
      options: [
        { uid: uuidv4(), text: '', isCorrect: false },
        { uid: uuidv4(), text: '', isCorrect: false },
      ],
      isTrue: undefined,
      hasMultipleCorrect: false,
      pairs: [],
      sequence: [],
      thumbnail: '',
      textWithBlanks: '',
      blanks: [],
    }))
    .with(QuizQuestionType.TRUE_FALSE, () => ({
      type: QuizQuestionType.TRUE_FALSE,
      label: nextValue?.label || '',
      score: 0,
      options: [],
      isTrue: undefined,
      hasMultipleCorrect: false,
      pairs: [],
      sequence: [],
      thumbnail: '',
      textWithBlanks: '',
      blanks: [],
    }))
    .with(QuizQuestionType.MATCH_SET, () => ({
      type: QuizQuestionType.MATCH_SET,
      label: nextValue?.label || '',
      score: 0,
      options: [],
      isTrue: undefined,
      hasMultipleCorrect: false,
      pairs: [
        { uid: uuidv4(), left: '', right: '' },
        { uid: uuidv4(), left: '', right: '' },
      ],
      sequence: [],
      thumbnail: '',
      textWithBlanks: '',
      blanks: [],
    }))
    .with(QuizQuestionType.SEQUENCE, () => ({
      type: QuizQuestionType.SEQUENCE,
      label: nextValue?.label || '',
      score: 0,
      options: [],
      isTrue: undefined,
      hasMultipleCorrect: false,
      pairs: [],
      sequence: [
        { uid: uuidv4(), text: '' },
        { uid: uuidv4(), text: '' },
      ],
      thumbnail: '',
      textWithBlanks: '',
      blanks: [],
    }))
    .with(QuizQuestionType.FILL_IN_BLANK, () => ({
      type: QuizQuestionType.FILL_IN_BLANK,
      label: nextValue?.label || '',
      score: 0,
      options: [],
      isTrue: undefined,
      hasMultipleCorrect: false,
      pairs: [],
      sequence: [],
      thumbnail: '',
      textWithBlanks: nextValue?.label || '',
      blanks: [{ uid: uuidv4(), answer: '' }],
    }))
    .otherwise(() => ({
      type: QuizQuestionType.MULTI_CHOICE,
      label: nextValue?.label || '',
      score: 0,
      options: [],
      isTrue: undefined,
      hasMultipleCorrect: false,
      pairs: [],
      sequence: [],
      thumbnail: '',
      textWithBlanks: '',
      blanks: [],
    }));
};
