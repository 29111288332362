import { useEffect } from 'react';
import { useSafeState } from '../../../../../../../hooks';
import { QuizQuestionType } from '../../../../../../../types';
import { eventBus } from '../../../../../../../shared/eventEmit';
import { QuizQFormInput } from '../quiz-form/quiz-form.types';
import { quizExamples } from './quiz.example';

export const QUIZ_PREVIEW_EVENT = 'QUIZ_PREVIEW_EVENT';

type PreviewData = {
  question: QuizQFormInput;
  questionIndex: number;
};

export const emitQuizPreview = (data: PreviewData): void => {
  eventBus.emit(QUIZ_PREVIEW_EVENT, data);
};

export const previewExample = (quizType: QuizQuestionType): void => {
  eventBus.emit(QUIZ_PREVIEW_EVENT, {
    question: quizExamples[quizType],
    questionIndex: 0,
  });
};

export const useQuizPreviewData = (): PreviewData | undefined => {
  const [state, updateState] = useSafeState<PreviewData>();

  useEffect(() => {
    const unsubscribe = eventBus.on(QUIZ_PREVIEW_EVENT, (data) => {
      updateState(data);
    });

    return () => unsubscribe();
  }, []);

  return state;
};
