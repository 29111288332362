import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { TrainingContType } from '../../../../../../types';
import { useUserDataSelector } from '../../../../../../hooks';
import { toArray } from '../../../../../../utils';
import { BaseResourceFooter, MobilePreviewContainer } from '../common';
import SopResourceContainer from './SopResourceContainer';
import { SopResourceItem } from './sop-resource.graphql';
import SopPreview from './SopPreview';

export interface SResourceProps {
  values?: string[];
  onAddClick?: (values: unknown[]) => void;
  onCreateClick?: () => void;
}

const SopResourceContent: FC<SResourceProps> = ({
  values = [],
  onAddClick,
  onCreateClick,
}) => {
  const addedByEid = useUserDataSelector((state) => state.eid);
  const methods = useForm();

  const _onAddClick = () => {
    const contents: Record<string, SopResourceItem> =
      methods.getValues('trainingContent');

    const values = Object.values(contents).reduce((acc, value) => {
      if (value) {
        acc.push({
          type: TrainingContType.SOP,
          eid: value.eid,
          tempEid: value.eid,
          title: value.title,
          // description: value.description,
          thumbnail: value.thumbnail,
          addedBy: addedByEid,
          // dateAdded: moment().format('D MMMM'),
          formData: value,
        });
      }
      return acc;
    }, [] as unknown[]);

    return onAddClick?.(values);
  };

  return (
    <FormProvider {...methods}>
      <Flex flex={1} flexDir='column' width='100%'>
        <Flex
          flex={1}
          gap='40px'
          marginX={7}
          // align='start'
          maxHeight='calc(100% - 61px)'
        >
          <SopResourceContainer values={toArray(values)} />

          <MobilePreviewContainer headerBg='blackAlpha.200'>
            <Controller
              name='preview'
              render={({ field }) => <SopPreview sopId={field.value} />}
            />
          </MobilePreviewContainer>
        </Flex>

        <BaseResourceFooter
          onAddClick={_onAddClick}
          onCreateClick={onCreateClick}
          selectedTitle='training:chapterSelected'
          selectLabel='Add a chapter from the list'
          createTitle='Create new chapter'
          createLabel='Click to create a new chapter in a separate tab. Once published, it will appear in the search results here.'
        />
      </Flex>
    </FormProvider>
  );
};

export default SopResourceContent;
