import React, { FC } from 'react';
import { Box, Center, Flex } from '@chakra-ui/react';
import { BaseLayoutWrapper, TaskCompletionRate } from 'sub-components';
import { useUserDataSelector } from 'hooks';
import { AuthRole } from 'sop-commons/src/client';
import NumericStats from './components/NumericStats';
import TasksForToday from './components/TasksForToday';
import UnreadMessages from './components/UnreadMessages';
import { gql, useQuery } from '@apollo/client';
import Loader from 'ui-components/Loader';
import { ITasksForToday } from 'pages/Home/Home';
import styled from '@emotion/styled';

export interface UserAnalyticsOverviewType {
  userAnalyticsOverview: {
    locationCount: number;
    userCount: number;
    tp?: {
      total: number;
      activeTp: number;
      inActiveTp: number;
      completed: number;
      overdue: number;
      yetToStart: number;
      evaluated: number;
      inProgress: number;
      needsEvaluation: number;
    };
    tasks: {
      complete: number;
      incomplete: number;
      rate: number;
    };
    trainings: {
      complete: number;
      incomplete: number;
    };
    formsFilled: number;
    taskCompanyAvg: {
      complete: number;
      incomplete: number;
      rate: number;
    };
    bestLocationTaskPerformance: {
      complete: number;
      inComplete: number;
      rate: number;
      locationId: string;
    };
  };
}

const RightSideWrapper = styled.div`
  width: 25%;
  table {
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    height: 100%;
  }
  th,
  td {
    border: 1px solid #f5f5f5;
    text-align: left;
    padding: 15px;
  }
  table > tbody > tr {
    height: 158px;
  }
  table > tbody > tr > td {
    width: 250px;
    vertical-align: middle;
  }
`;

const MidWrapper = styled.div`
  width: 25%;
  table {
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    height: 100%;
  }
  th,
  td {
    border: 1px solid #f5f5f5;
    text-align: left;
    padding: 15px;
  }
  table > tbody > tr {
    height: 158px;
  }
  table > tbody > tr > td {
    width: 250px;
    vertical-align: baseline;
  }
`;

interface IProps {
  analyticsData: UserAnalyticsOverviewType | undefined;
  loading: boolean;
  tasksForTodayData: ITasksForToday;
}

const AnalyticsBoard: FC<IProps> = ({
  analyticsData,
  loading,
  tasksForTodayData,
}) => {
  const userData = useUserDataSelector((state) => ({
    authRole: state.authRole,
    type: state.type,
  }));

  return loading ? (
    <BaseLayoutWrapper w='full' h='40vh'>
      <Center w='full'>
        <Loader />
      </Center>
    </BaseLayoutWrapper>
  ) : userData?.authRole !== AuthRole.WORKER && userData?.type !== 'branch' ? (
    <BaseLayoutWrapper id='analytics-board-b-l-w-1' w='100%'>
      <NumericStats analyticsData={analyticsData?.userAnalyticsOverview} />
      <MidWrapper>
        <table>
          <tbody>
            <tr>
              <td>
                <TaskCompletionRate
                  companyAvg={
                    analyticsData?.userAnalyticsOverview?.taskCompanyAvg
                      ?.rate || 0
                  }
                  myLocations={
                    analyticsData?.userAnalyticsOverview
                      ?.bestLocationTaskPerformance?.rate || 0
                  }
                />
              </td>
            </tr>
          </tbody>
        </table>
      </MidWrapper>
      {/* <Flex
        id='analytics-board-flex-1'
        w='25%'
        flexDir='column'
        p={4}
        h='full'
      >
        <Flex
          id='analytics-board-flex-2'
          justifyContent='center'
          h='full'
          alignItems='normal'
        >
          <TaskCompletionRate
            companyAvg={data?.userAnalyticsOverview?.taskCompanyAvg?.rate || 0}
            myLocations={
              data?.userAnalyticsOverview?.bestLocationTaskPerformance?.rate ||
              0
            }
          />
        </Flex>
      </Flex> */}
      <RightSideWrapper>
        <table>
          <tbody>
            <tr>
              <td>
                <TasksForToday tasksForTodayData={tasksForTodayData} />
              </td>
            </tr>
            <tr>
              <td>
                <UnreadMessages />
              </td>
            </tr>
          </tbody>
        </table>
      </RightSideWrapper>
      {/* <Flex flexDirection='column' w='30%' justify='space-between' h='full'>
        <Flex p={6}>
          <TasksForToday tasksForTodayData={tasksForTodayData} />
        </Flex>
        <Flex border='1px solid #F5F5F5' />
        <Flex p={6}>
          <UnreadMessages />
        </Flex>
      </Flex> */}
    </BaseLayoutWrapper>
  ) : (
    <Flex flexDir='column' gap={4} w='30%'>
      <BaseLayoutWrapper flexDir='column'>
        <Box borderBottom='1px solid #F5F5F5' p={4}>
          <TasksForToday tasksForTodayData={tasksForTodayData} />
        </Box>
        <Box p={4}>
          <UnreadMessages />
        </Box>
      </BaseLayoutWrapper>
      <BaseLayoutWrapper padding={4} id='analytics-board-flex-3'>
        <TaskCompletionRate
          companyAvg={
            analyticsData?.userAnalyticsOverview?.taskCompanyAvg?.rate || 0
          }
          myLocations={
            analyticsData?.userAnalyticsOverview?.bestLocationTaskPerformance
              ?.rate || 0
          }
        />
      </BaseLayoutWrapper>
      <BaseLayoutWrapper>
        <NumericStats analyticsData={analyticsData?.userAnalyticsOverview} />
      </BaseLayoutWrapper>
    </Flex>
  );
};

export default AnalyticsBoard;
