import { ApolloProvider, useApolloClient } from '@apollo/client';
import { Box, ModalCloseButton, Text } from '@chakra-ui/react';
import { HeaderColors } from 'configs';
import { useCallback, useRef } from 'react';
import { BoxHeader, UseConfirm, useConfirm } from 'ui-components';

const useCrosstrainConfirm = (onConfirm?: () => void) => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();
  const apolloClient = useApolloClient();

  return useCallback(() => {
    if (confirmRef.current) {
      confirmRef.current.destroy();
    }
    confirmRef.current = confirm({
      Wrapper: ({ children }: any) => (
        <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
      ),
      size: 'xl',
      title: (
        <>
          <BoxHeader
            color={HeaderColors.Purple}
            title='Discard unsaved changes?'
          />
          <ModalCloseButton top='1.5rem' right='1.5rem' fontSize='18px' />
        </>
      ),
      content: (
        <Box mt={6} mb={8}>
          <Text color={'#6F767E'} fontWeight={600}>
            The changes you made here won’t be saved. You will need to reselect
            the assignee names next time.
          </Text>
        </Box>
      ),
      contentProps: {
        borderRadius: '12px',
        p: '24px',
      },
      headerProps: { p: '0px' },
      bodyProps: { p: '0px' },
      isCentered: true,
      okText: 'Discard',
      onOK: () => {
        confirmRef.current?.destroy();
        if (onConfirm) onConfirm(); // Call the provided onClose
      },
      footerProps: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '16px',
      },
      cancelButtonProps: {
        variant: 'solid',
        size: 'lg',
        isFullWidth: true,
        borderRadius: '12px',
        fontWeight: 700,
      },
      okButtonProps: {
        variant: 'solid',
        isFullWidth: true,
        size: 'lg',
        colorScheme: 'blue',
        borderRadius: '12px',
        fontWeight: 700,
      },
    });
  }, [confirm]);
};

export default useCrosstrainConfirm;
