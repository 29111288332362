import React, { FC, useMemo, useState } from 'react';
import { Checkbox, Flex, Text, Tooltip } from '@chakra-ui/react';
import { BoxHeader } from 'ui-components';
import { ReactComponent as BackButton } from 'assets/images/back.svg';
import { PageNameType } from './MainContainer';
import { ClearFilterType } from './FilterContainer';
import { HeaderColors } from 'configs';
import EmptyState from './EmptyState';
import { ITypes } from '../../TrackPathDashboard/bottomSection/Header';
// import { ITypes } from '../Header';
// import { useTrainingContext } from '../../../TrainingTableContainer';

interface IProps {
  filters: any;
  setPageName: React.Dispatch<React.SetStateAction<PageNameType>>;
  handleCategorySelection: (
    e: React.ChangeEvent<HTMLInputElement>,
    category: ITypes
  ) => void;
  clearFilters: (type?: ClearFilterType) => void;
}

const CategoryFilter: FC<IProps> = ({
  filters,
  setPageName,
  handleCategorySelection,
  clearFilters,
}) => {
  const { categories, selectedCategoriesId } = filters;
  const [categoriesSearchText, setCategoriesSearchText] = useState('');

  // const { trackingTpPaginationLoader, trainingCategoriesLoader } =
  //   useTrainingContext();

  // UseMemo for efficient filtering
  const filteredCategories = useMemo(
    () =>
      categories?.filter((category: any) =>
        category?.name
          ?.toLowerCase()
          .includes(categoriesSearchText.toLowerCase())
      ),
    [categoriesSearchText, categories]
  );

  const categoryCount = categories.length;
  const filteredCount = filteredCategories.length;

  return (
    <>
      {/* Header */}
      <Flex alignItems='center' mt='30px' justifyContent='space-between'>
        <BoxHeader title='Filters' color={HeaderColors.Purple} />
        <Text
          cursor='pointer'
          onClick={() => clearFilters('categories')}
          fontSize='14px'
          fontWeight={400}
          color={
            filters?.selectedCategoriesId?.length > 0 ? '#2A85FF' : '#6F767E'
          }
        >
          Clear Selection
        </Text>
      </Flex>

      {/* Back Button and Location Count */}
      <Flex mt='20px' gap={3} alignItems='center' mb='1rem'>
        <BackButton
          width='1.2rem'
          onClick={() => setPageName('base')}
          cursor='pointer'
        />
        <Text fontWeight={600} fontSize='14px'>
          Category
        </Text>
      </Flex>

      {/* Search Input */}
      {/* <SearchInput
        className='location-search-field'
        value={typeSearchText}
        onChange={(e) => setTypeSearchText(e.target.value)}
        placeholder='Search by type name'
        hideShortcuts
      /> */}

      {/* Filtered Locations */}
      {filteredCount > 0 ? (
        <Flex maxHeight='300px' flexDirection='column' overflowY='auto'>
          {filteredCategories.map((category) => (
            <Flex key={category.eid} gap={4} alignItems='center' py='0.5rem'>
              <Checkbox
                // disabled={
                //   trackingTpPaginationLoader || trainingCategoriesLoader
                // }
                value={category.eid}
                onChange={(e) => handleCategorySelection(e, category)}
                isChecked={selectedCategoriesId.includes(category.eid)}
              >
                <Tooltip label={category.name}>
                  <Text
                    noOfLines={1}
                    fontWeight={400}
                    lineHeight='16px'
                    fontSize='14px'
                    color='#333B4F'
                  >
                    {category.name}
                  </Text>
                </Tooltip>
              </Checkbox>
            </Flex>
          ))}
        </Flex>
      ) : (
        <EmptyState view='categories' />
      )}
    </>
  );
};

export default CategoryFilter;
