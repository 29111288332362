import { useQuery, useReactiveVar } from '@apollo/client';
import { Badge, Box, Center, Flex, Text, Tooltip } from '@chakra-ui/react';
import { Table } from 'antd';
import { ColumnsType } from 'atoms';
import { useUserDataSelector, useUserEntity } from 'hooks';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { eventBus } from 'shared/eventEmit';
import { AuthRole } from 'sop-commons/src/client';
import EmptyState from 'sub-components/EmptyState';
import { usersEntityObj } from 'sub-components/Header';
import Loader from 'sub-components/Loader';
import { UserEntityData } from 'sub-components/nexus/NexusBase/components/LeftSection/components/Heading/components/CustomReportComponent/types';
import { MembersTrainingPath } from 'sub-components/training-v2/shared/types';
import { Image } from 'ui-components';
import { getImage } from 'utils';
import { useMemberActivity } from '../../memberActivity';
import { CustomProgressBar } from '../../TrackPathDashboard/components';
import { IOnViewActivityClick } from '../../TrackPathDashboard/components/MemberAnalytics';
import usePathsByMemberId from '../../TrackPathDashboard/components/PathsByMemberId.tsx/usePathsByMemberId';
import { useTrainingContext } from '../../TrainingTableContainer';
import {
  GET_TRACKING_MEMBERS_ANALYTICS,
  TrackMembersAnalyticsResponse,
} from '../query/tracking.graphql';
import { MemberTableType } from '../tracking-types';
import { membersAvgRateOptions } from './constants';
import LocationBadge from './LocationBadge';
import RoleFormatter from './RoleFormatter';
import SortPopover from './SortPopover';

const TrackMembersTable: FC = () => {
  const entityUserList = useReactiveVar(usersEntityObj);

  const {
    data: trackingAnalytics,
    loading: analyticsLoading,
    error: analyticsError,
  } = useQuery<TrackMembersAnalyticsResponse>(GET_TRACKING_MEMBERS_ANALYTICS, {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'network-only',
  });
  const PathsByMemberId = usePathsByMemberId();
  const { DrawerComponent, useMemberActivityDrawer } = useMemberActivity();

  const { setTrainingContextData, trainingContextData, filters, setFilters } =
    useTrainingContext();

  const membersData = useMemo(() => {
    if (!trackingAnalytics?.TpOverallUserWiseAnalytics || !entityUserList)
      return [];
    const data: Array<Partial<MembersTrainingPath> & Partial<UserEntityData>> =
      trackingAnalytics?.TpOverallUserWiseAnalytics?.map((member) => {
        const entityUser =
          entityUserList?.find((user) => user?.eid === member?.userId) ?? {};
        return { ...member, ...entityUser };
      });
    return data
      ?.filter((user) => user?.status && user?.name)
      ?.sort((a, b) => (a?.name || '').localeCompare(b?.name || '')); //sort in asc && remove empty inactive/deleted users
  }, [trackingAnalytics, entityUserList]);

  const [currColumnKey, setCurrColumnKey] = useState<string>('');
  const { userAuthRole, userLocations, bussinessLocations } =
    useUserDataSelector((state) => ({
      userAuthRole: state?.authRole,
      userLocations: state?.locations,
      bussinessLocations: state?.entity?.locations,
    }));
  const { jobs } = useUserEntity((state) => ({
    jobs: state?.roles,
  }));

  //filter for LO's
  const currUserLocations = useMemo(() => {
    return userAuthRole === AuthRole.SUPER_ADMIN
      ? bussinessLocations
      : userLocations;
  }, [userAuthRole]);

  const prepareFilterData = useCallback(() => {
    setFilters((prevState) => ({
      ...prevState,
      jobs: jobs?.map((job) => {
        return {
          name: job?.name,
          eid: job?.eid,
        };
      }),
      locations: currUserLocations?.map((loc) => {
        return {
          eid: loc?.eid,
          name: loc?.name,
          locStatus: loc?.locationStatus,
        };
      }),
    }));
  }, [currUserLocations, jobs]);

  const toggleOverdueFilterHandler = useCallback(() => {
    setFilters((prevState) => {
      // If the overdue filter is already applied, remove it.
      if (prevState.selectedStatusesId.includes('static_1_overdue1')) {
        const updatedSelectedStatuses = prevState?.selectedStatuses?.filter(
          (status) => status?.eid !== 'static_1_overdue1'
        );
        const updatedSelectedStatusesId = prevState?.selectedStatusesId?.filter(
          (id) => id !== 'static_1_overdue1'
        );

        // Update training context data to original data if no filters remain
        setTrainingContextData((prevTrainingState) => {
          const newFilteredData =
            updatedSelectedStatuses?.length === 0
              ? prevTrainingState?.trackMembersData
              : updatedSelectedStatuses?.reduce((filtered, status) => {
                  if (status.name === 'Overdue') {
                    return filtered?.filter((member) => member?.overdue > 0);
                  } else if (status?.name === 'Evaluation required') {
                    return filtered?.filter((member) => member?.avgScore < 50);
                  }
                  return filtered;
                }, prevTrainingState?.trackMembersData);

          return {
            ...prevTrainingState,
            filteredTrackMembersData: newFilteredData,
          };
        });

        return {
          ...prevState,
          selectedStatuses: updatedSelectedStatuses,
          selectedStatusesId: updatedSelectedStatusesId,
          mergedFiltersArray: prevState?.mergedFiltersArray?.filter(
            (filter) => filter?.eid !== 'static_1_overdue1'
          ),
          totalFiltersTypes: prevState?.totalFiltersTypes - 1,
        };
      } else {
        // else add the overdue filter
        const updatedSelectedStatuses = [
          ...prevState?.selectedStatuses,
          { eid: 'static_1_overdue1', name: 'Overdue' },
        ];

        setTrainingContextData((prevTrainingState) => {
          let filteredMembers = prevTrainingState?.trackMembersData || [];
          updatedSelectedStatuses?.forEach((selectedStatus) => {
            if (selectedStatus?.name === 'Overdue') {
              filteredMembers = filteredMembers?.filter(
                (member) => member?.overdue > 0
              );
            } else if (selectedStatus?.name === 'Evaluation required') {
              filteredMembers = filteredMembers?.filter(
                (member) => member?.avgScore < 50
              );
            }
          });
          return {
            ...prevTrainingState,
            filteredTrackMembersData: filteredMembers,
          };
        });

        return {
          ...prevState,
          selectedStatusesId: [
            ...prevState?.selectedStatusesId,
            'static_1_overdue1',
          ],
          selectedStatuses: updatedSelectedStatuses,
          mergedFiltersArray: [
            ...prevState?.mergedFiltersArray,
            {
              eid: 'static_1_overdue1',
              name: 'Overdue',
              customType: 'statuses',
            },
          ],
          totalFiltersTypes: prevState?.totalFiltersTypes + 1,
        };
      }
    });
  }, [setFilters, setTrainingContextData]);

  // Sync sortedMembersData with fresh membersData when it updates
  useEffect(() => {
    prepareFilterData();
    setTrainingContextData((prev) => ({
      ...prev,
      trackMembersData: membersData,
      filteredTrackMembersData: membersData,
    }));
  }, [membersData]);

  //subscribe for overdue filter applied form performace card info
  useEffect(() => {
    eventBus.on('overdue_member_filter_applied', toggleOverdueFilterHandler);
    return () => {
      eventBus.off('overdue_member_filter_applied', toggleOverdueFilterHandler);
    };
  }, []);

  const handleSort = (
    columnKey: 'members' | 'completion' | 'score',
    value: 'asc' | 'desc' | ''
  ) => {
    setCurrColumnKey(columnKey); //track the active sort column

    if (value === '') {
      //no sort
      return setTrainingContextData((prev) => ({
        ...prev,
        filteredTrackMembersData: prev?.trackMembersData, // Reset to original data
      }));
    }
    const sortedData = [...membersData]?.sort((a, b) => {
      if (columnKey === 'members' && a.name && b.name) {
        return value === 'asc'
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      } else if (columnKey === 'completion') {
        const completionA = a?.total ? (a?.completed || 0 / a?.total) * 100 : 0;
        const completionB = b?.total ? (b?.completed || 0 / b?.total) * 100 : 0;
        return value === 'asc'
          ? completionA - completionB
          : completionB - completionA;
      } else if (columnKey === 'score') {
        const avgScoreA = a.avgScore ?? 0;
        const avgSCoreB = b.avgScore ?? 0;
        return value === 'asc' ? avgScoreA - avgSCoreB : avgSCoreB - avgScoreA;
      }
      return 0; //return no sort
    });
    setTrainingContextData((prev) => ({
      ...prev,
      filteredTrackMembersData: sortedData,
    }));
  };
  const onViewActivityClick = ({
    name,
    userId,
    sessionId,
    tp,
  }: IOnViewActivityClick) => {
    useMemberActivityDrawer({
      memberName: name,
      userId: userId,
      sessionId: sessionId,
      tp: tp,
    });
  };

  const memberColumns: ColumnsType<MemberTableType> = [
    {
      title: (
        <Flex alignItems={'center'} gap={2}>
          <Text fontWeight={500} color={'#6F767E'}>
            Members
          </Text>
          <SortPopover
            onSort={(sortValue) => handleSort('members', sortValue)}
            columnKey={'members'}
            activeColumnKey={currColumnKey}
          />
        </Flex>
      ),
      dataIndex: 'member',
      key: 'member',
      width: 230,
      render: (_, record) => {
        return (
          <Flex
            align='center'
            gap={3}
            cursor='pointer'
            onClick={() => {
              PathsByMemberId({
                userId: record?.userId,
                userName: record?.name,
                onViewActivityClick: onViewActivityClick,
              });
            }}
            whiteSpace={'nowrap'}
          >
            <Image
              style={{
                borderRadius: '6.82px',
              }}
              height={44}
              width={44}
              src={getImage(record?.profilePic, record?.name)}
            />
            <Box>
              <Tooltip label={record?.name}>
                <Text
                  fontWeight={600}
                  color='#1A1D1F'
                  fontSize={'15px'}
                  isTruncated
                  w={'100px'}
                >
                  {record?.name}
                </Text>
              </Tooltip>
              <LocationBadge locations={record?.locations} />
            </Box>
          </Flex>
        );
      },
    },
    {
      title: (
        <Text fontWeight={500} color={'#6F767E'}>
          Role
        </Text>
      ),
      dataIndex: 'role',
      key: 'role',
      render: (_, record) => (
        <Box>
          <Text color='#33383F' fontSize={'14px'} fontWeight={500}>
            {record.role}
          </Text>
          <Text
            fontSize='12px'
            color='#6F767E'
            textTransform={'capitalize'}
            fontWeight={500}
          >
            <RoleFormatter authRole={record?.authRole} />
          </Text>
        </Box>
      ),
    },
    {
      title: (
        <Text fontWeight={500} color={'#6F767E'}>
          Completed vs Assigned
        </Text>
      ),
      dataIndex: 'total',
      key: 'total',
      render: (_, record) => {
        const isDemoGreator = record?.total >= record?.completed;
        return isDemoGreator ? (
          <Flex alignItems={'center'} gap={2}>
            <Flex
              minW={'70px'}
              borderRadius={'6px'}
              p={'2px 10px'}
              alignItems={'center'}
              backgroundColor={'#EFEFEF'}
            >
              <Flex width={'100%'} justifyContent={'center'}>
                <Text fontWeight={600}>{record?.completed}/</Text>
                <Text>{record?.total}</Text>
              </Flex>
            </Flex>
            <CustomProgressBar
              width='44px'
              percent={
                record?.total > 0
                  ? (record?.completed / record?.total) * 100
                  : 0
              }
            />
          </Flex>
        ) : (
          '-'
        );
      },
    },
    {
      title: (
        <Text fontWeight={500} color={'#6F767E'}>
          Overdue paths
        </Text>
      ),
      dataIndex: 'overdue',
      key: 'overdue',
      render: (_, record) => (
        <>
          {record?.overdue > 0 ? (
            <Badge
              variant='subtle'
              borderRadius='5px'
              fontSize='12px'
              fontWeight={500}
              textTransform='capitalize'
              w='fit-content'
              display='flex'
              bg={'#FFBC9933'}
              color={'#FF6A55'}
            >
              {record?.overdue} overdue
            </Badge>
          ) : (
            <Text>-</Text>
          )}
        </>
      ),
    },
    {
      title: () => (
        <Flex alignItems={'center'} gap={2}>
          <Text fontWeight={500} color={'#6F767E'}>
            Avg completion rate
          </Text>
          <SortPopover
            activeColumnKey={currColumnKey}
            columnKey='completion'
            options={membersAvgRateOptions}
            onSort={(sortValue) => handleSort('completion', sortValue)}
          />
        </Flex>
      ),
      dataIndex: 'avgCompletion',
      key: 'avgCompletion',
      render: (_, record) => (
        <Text color={'#33383F'} fontWeight={500}>
          {((record?.completed / record?.total) * 100).toFixed(0)}%
        </Text>
      ),
    },
    {
      title: () => (
        <Flex alignItems={'center'} gap={2}>
          <Text fontWeight={500} color={'#6F767E'}>
            Avg score
          </Text>
          <SortPopover
            activeColumnKey={currColumnKey}
            columnKey='score'
            options={membersAvgRateOptions}
            onSort={(sortValue) => handleSort('score', sortValue)}
          />
        </Flex>
      ),
      dataIndex: 'avgScore',
      key: 'avgScore',
      render: (_, record) => (
        <Text color={'#33383F'} fontWeight={500}>
          {record?.avgScore?.toFixed(0)}%
        </Text>
      ),
    },
  ];

  if (analyticsLoading) {
    return (
      <Center maxBlockSize={'100%'} height={'40vh'}>
        <Loader />
      </Center>
    );
  }
  if (analyticsError) {
    return (
      <EmptyState
        image='Report'
        title='Error while loading the data, please refresh the page!'
        imageSize='300px'
      />
    );
  }
  return trainingContextData?.filteredTrackMembersData &&
    trainingContextData?.filteredTrackMembersData?.length > 0 ? (
    <>
      <Table<MemberTableType>
        columns={memberColumns}
        dataSource={trainingContextData?.filteredTrackMembersData}
        isLoading={analyticsLoading}
        pagination={{
          showSizeChanger: false,
        }}
      />
      {DrawerComponent}
    </>
  ) : (
    <Center height='100%' width='100%'>
      <EmptyState image='Report' title='No data found' imageSize='300px' />
    </Center>
  );
};

TrackMembersTable.displayName =
  'displayName:sub-components/training-v2/dashboard/components/TrackMembersTable';

export default TrackMembersTable;
