import { ApolloProvider, useApolloClient } from '@apollo/client';
import { Box, ModalCloseButton, Text } from '@chakra-ui/react';
import { HeaderColors } from 'configs';
import { useCallback, useRef } from 'react';
import { BoxHeader, UseConfirm, useConfirm } from 'ui-components';

interface IProps {}

const useClearEvalute = () => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();
  const apolloClient = useApolloClient();
  return useCallback(
    ({ onOKHandler }: { onOKHandler }) => {
      if (confirmRef.current) {
        confirmRef.current.destroy();
      }
      confirmRef.current = confirm({
        Wrapper: ({ children }: any) => {
          return (
            <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
          );
        },
        size: '2xl',
        title: (
          <>
            <BoxHeader color={HeaderColors.Purple} title={`Unsaved changes`} />
            <ModalCloseButton top='1.5rem' right='1.5rem' fontSize='18px' />
          </>
        ),
        content: (
          <Box mt={6} mb={8}>
            <Text color={'#6F767E'} fontWeight={600}>
              Any changes made to this evaluation form will be lost if you clear
              it. Are you sure you want to continue?
            </Text>
          </Box>
        ),
        contentProps: {
          borderRadius: '12px',
          p: '24px',
        },
        headerProps: {
          p: '0px',
        },
        bodyProps: {
          p: '0px',
        },
        isCentered: true,
        okText: 'Clear form',
        onOK: onOKHandler,
        footerProps: {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '16px',
        },
        cancelButtonProps: {
          variant: 'solid',
          size: 'lg',
          isFullWidth: true,
          borderRadius: '12px',
          fontWeight: 700,
        },
        okButtonProps: {
          variant: 'solid',
          isFullWidth: true,
          size: 'lg',
          colorScheme: 'blue',
          borderRadius: '12px',
          fontWeight: 700,
        },
      });
    },
    [confirm]
  );
};

export default useClearEvalute;
