import { gql } from '@apollo/client';
import {
  EnumTPContentsType,
  EnumTPSessionAssignedUsersStatus,
} from 'sub-components/training-v2/shared/types';
import { TAssignee, TAssigneeUserType, TrainingAccessEnum } from 'types';

export interface RepeatCycle {
  type: 'EVERY_N_DAYS' | 'EVERY_N_WEEKS' | 'EVERY_N_MONTHS' | 'EVERY_N_YEARS';
  days: number | null;
  weeks: number | null;
  dayOfTheWeek: string | null; // e.g., "Monday"
  months: number | null;
  dayOfTheMonth: number | null; // e.g., 1-31
  years: number | null;
  monthOfTheYear: string | null; // e.g., "January"
}
export type TEnumTPStatus =
  | 'TRAINING_DRAFT'
  | 'TRAINING_CREATED'
  | 'TRAINING_STARTED'
  | 'TRAINING_ONE_TIME'
  | 'TRAINING_REPEAT'
  | 'TRAINING_COMPLETED'
  | 'TRAINING_TERMINATED'
  | 'TRAINING_DELETED';

export type TUserProgressItem = {
  isCompleted: boolean;
  score: number;
  evaluatedAt: Date;
  evaluatedBy: string;
  isEvaluated: boolean;
  maxScore: number;
  progress: {
    id: string;
    isCompleted: boolean;
    updatedAt: string;
    type: keyof typeof EnumTPContentsType;
  }[];
  userId: string;
};
export type TContentItem = {
  eid: string;
  thumbnail: string;
  title: string;
  type: keyof typeof EnumTPContentsType;
  isRemoved: boolean;
};
export type TAssignedUserItem = {
  status: EnumTPSessionAssignedUsersStatus;
  dueDate: Date;
  completedOn: Date;
  assignedOn: Date;
  startedOn: Date;
  score: number;
  userId: string;
  crossTrained: boolean;
  isEvaluated: boolean;
  evaluatedOn: Date;
  scorePercent: number;
  isRemoved: boolean;
  ratings: number[];
};
export interface TPRepeatCycle {
  type: 'EVERY_N_DAYS' | 'EVERY_N_WEEKS' | 'EVERY_N_MONTHS' | 'EVERY_N_YEARS';
  days: number | null;
  weeks: number | null;
  dayOfTheWeek: string | null; // e.g., "Monday"
  months: number | null;
  dayOfTheMonth: number | null; // e.g., 1-31
  years: number | null;
  monthOfTheYear: string | null; // e.g., "January"
}
export interface TPSession {
  assignedUsers: TAssignedUserItem[];
  userProgress: TUserProgressItem[];
  contents: TContentItem[];
  endDate: Date;
  maxScore: number;
  createdAt: Date;
  createdBy: string;
  trainingId: string;
  training?: {
    category: string;
    status: TEnumTPStatus;
    createdBy: string;
    eid: string;
    title: string;
    createdAt: Date;
    accessPermission: {
      admin: TrainingAccessEnum;
      locationOwner: TrainingAccessEnum;
      superadmin: TrainingAccessEnum;
    };
    isRepeating: boolean;
    assigneeUserType: TAssigneeUserType;
    assignees: TAssignee[];
    deadlineType: 'noDeadline' | 'date' | 'duration';
    repeatCycle: TPRepeatCycle;
  };
  supervisors: string[];
  hasSkillVerifier: boolean;
  skillVerifier: {
    name: string;
    skill: string;
  };
  hasScoring: boolean;
  isRepeating: boolean;
  crossTrain: boolean;
}
export interface IGetTpSessionResponse {
  TpSessionById: TPSession;
}

export interface GetAssignedUserItem {
  userId: string;
  isRemoved: boolean;
}
export interface IGetAssignedUsersForTpSession {
  TpSessionById: {
    assignedUsers: GetAssignedUserItem[];
  };
}
export const GET_ASSIGNED_USERS_TP_SESSION = gql`
  query TpSessionAssignedUsers($eid: String!) {
    TpSessionById(eid: $eid) {
      assignedUsers {
        userId
        isRemoved
      }
    }
  }
`;
export const GET_TP_SESSION_DATA = gql`
  query TpSessionById($eid: String!, $filter: FilterFindManyTPProgressInput) {
    TpSessionById(eid: $eid) {
      assignedUsers {
        status
        dueDate
        completedOn
        assignedOn
        startedOn
        score
        userId
        crossTrained
        isEvaluated
        evaluatedOn
        locations
        scorePercent
        isRemoved
        ratings
      }
      endDate
      userProgress(filter: $filter) {
        isCompleted
        isEvaluated
        evaluatedBy
        maxScore
        progress {
          id
          isCompleted
          type
          updatedAt
        }
        userId
        score
        crossTrained
      }
      contents {
        eid
        thumbnail
        title
        type
        isRemoved
      }
      maxScore
      createdAt
      createdBy
      trainingId
      isRepeating
      hasScoring
      training {
        category
        status
        createdBy
        isRepeating
        accessPermission {
          admin
          locationOwner
          superadmin
        }
        repeatCycle {
          type
          days
          weeks
          dayOfTheWeek
          months
          dayOfTheMonth
          years
          monthOfTheYear
        }
        createdAt
        eid
        title
        thumbnail
        accessPermission {
          locationOwner
          admin
        }
        startDate
        assigneeUserType
        assignmentType
        deadlineType
        deadlineDate
        duration {
          type
          value
        }
        assignees {
          type
          roles
          authRoles
          userIds
          locationType
          locationIds
        }
      }
      supervisors
      hasSkillVerifier
      skillVerifier {
        name
        skill
      }
    }
  }
`;

export const ENITITY_USERS_LIST = gql`
  query EntityUserList($entityId: String!) {
    EntityUsers(entityId: $entityId)
  }
`;

export const ADD_CROSS_TRAINING_USERS = gql`
  mutation AddCrossTrainingUsers($trainingId: String!, $users: [String!]) {
    AddCrossTrainingUsers(trainingId: $trainingId, users: $users) {
      title
      entityId
      crossTrainingUsers
    }
  }
`;

export const GET_TP_OVERALL_USERWISE_ANALYTICS = gql`
  query TpOverallUserDetailsAnalytics(
    $userId: String!
    $filter: FilterFindManyTPProgressInput
  ) {
    TpOverallUserDetailsAnalytics(userId: $userId) {
      sessions {
        title
        eid
        endDate
        hasSkillVerifier
        isRepeating
        createdBy
        supervisors
        assignedUsers {
          crossTrained
          status
          dueDate
          isEvaluated
          userId
          completedOn
          score
          assignedOn
        }
        training {
          thumbnail
          thumbnailColor
          category
          title
          status
          accessPermission {
            admin
            locationOwner
            superadmin
          }
          startDate
          assignmentType
          deadlineType
          deadlineDate
          duration {
            type
            value
          }
        }
        updatedAt
        contents {
          eid
        }
        userProgress(filter: $filter) {
          scorePercent
          evaluatedAt
          evaluatedBy
          progress {
            isCompleted
            id
          }
        }
      }
    }
  }
`;

export const GET_TP_ALLOWED_CROSS_TRAINEES = gql`
  query TpAllowedCrossTrainees($trainingId: String!) {
    TpAllowedCrossTrainees(trainingId: $trainingId) {
      name
      locations {
        eid
        name
        locationStatus
      }
      eid
      authRole
      role
      profilePic
    }
  }
`;

export const GET_REPEATING_TP_CYCLES = gql`
  query RepeatingTpCycles($eid: String!) {
    TpById(eid: $eid) {
      sessions {
        endDate
        startDate
        eid
      }
    }
  }
`;
