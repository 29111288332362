import { chakra, FlexProps, forwardRef } from '@chakra-ui/react';

export const FlexContainer = forwardRef<FlexProps, 'div'>((props, ref) => {
  const { direction, align, justify, wrap, basis, grow, shrink, ...rest } =
    props;

  const styles = {
    display: 'flex',
    flexDirection: direction,
    alignItems: align,
    justifyContent: justify,
    flexWrap: wrap,
    flexBasis: basis,
    flexGrow: grow,
    flexShrink: shrink,
  };

  return (
    <chakra.div
      ref={ref}
      __css={styles}
      border='1px solid #EFEFEF'
      borderRadius='12px'
      p='28px 32px'
      {...rest}
    />
  );
});

export default FlexContainer;
