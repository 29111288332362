import React, { FC } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { Box, Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { IRepeatInput } from './repeating.types';
import MonthSelect from './MonthSelect';

interface IProps {}

const YearMonthSelect: FC<IProps> = () => {
  const type = useWatch<IRepeatInput, 'type'>({
    name: 'type',
  });

  if (type !== 'EVERY_N_YEARS') {
    return null;
  }

  return (
    <Flex gap={5} align='center'>
      <Box
        alignSelf='start'
        fontWeight='600'
        color='#6F767E'
        whiteSpace='nowrap'
        lineHeight='48px'
      >
        in
      </Box>

      <Controller<IRepeatInput, 'monthOfTheYear'>
        name='monthOfTheYear'
        rules={{
          required: 'Please select a month',
        }}
        render={({ field, fieldState }) => (
          <FormControl maxWidth='180px' isInvalid={fieldState.invalid}>
            <MonthSelect {...field} />
            <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
          </FormControl>
        )}
      />
    </Flex>
  );
};

export default YearMonthSelect;
