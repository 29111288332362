import React, { FC } from 'react';
import { useWatch } from 'react-hook-form';
import { TFormInput } from '../../../training-input.types';
import DependantTrainingSelect from './DependantTrainingSelect';

interface IProps {
  disabled?: boolean;
}

const DependantTraining: FC<IProps> = ({ disabled }) => {
  const assignmentType = useWatch<TFormInput, 'assignmentType'>({
    name: 'assignmentType',
  });

  if (assignmentType !== 'training') {
    return null;
  }

  return <DependantTrainingSelect disabled={disabled} />;
};

export default DependantTraining;
