import { useQuery } from '@apollo/client';
import { Center, CloseButton, Flex, Skeleton } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { TRAINING_PATH_V2 } from 'appRoutes';
import { useUserDataSelector } from 'hooks';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormNotAccessible } from 'sub-components/forms';
import { FormResponse } from 'sub-components/forms/form.graphql';
import CustomFormType from 'sub-components/forms/from-preview/CustomFormType';
import { InactiveFormEmptyState } from 'sub-components/forms/submit-form';
import { useService } from 'sub-components/training-v2/play/layers';
import { usePlayContext } from 'sub-components/training-v2/play/store/context';
import { isItemCompleted } from 'sub-components/training-v2/play/utils';
import {
  GET_FORM_BY_ID,
  GET_TP_USER_FORM_RESPONSE,
} from 'sub-components/training-v2/shared/api';
import { AccessibilityType } from 'types';
import { NotAccessibleContainer } from 'ui-components';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  .custom-form-preview {
    width: 50%;
  }
`;

const FormContent: FC = () => {
  const history = useHistory();
  const {
    contents,
    isRetake,
    selectedIndex,
    selectedSubIndex,
    tpSessionData,
    updateContentByIndex,
    updateTpSessionData,
  } = usePlayContext((state) => ({
    isRetake: state.isRetake,
    selectedIndex: state.selectedIndex,
    selectedSubIndex: state.selectedSubIndex,
    contents: state.tpSessionData?.contents || [],
    tpSessionData: state.tpSessionData,
    updateContentByIndex: state.updateContentByIndex,
    updateTpSessionData: state.updateTpSessionData,
  }));

  const [unAuthorised, setUnauthorised] = useState<
    AccessibilityType | undefined
  >(undefined);
  const [localFormResponse, setLocalFormResponse] = useState<any>(null);

  const { loggedInUserEid } = useUserDataSelector((state) => ({
    loggedInUserEid: state?.eid,
  }));

  const service = useService();

  const selectedData = useMemo(() => {
    return contents?.[selectedIndex];
  }, [contents, selectedIndex, selectedSubIndex]);

  const isCompleted = useMemo(() => {
    setLocalFormResponse([]);
    return isItemCompleted(
      selectedData?.eid,
      tpSessionData?.userProgress || []
    );
  }, [selectedData, tpSessionData]);

  useEffect(() => {
    setUnauthorised(undefined);
  }, [selectedIndex]);

  const { loading, data: formData } = useQuery<FormResponse, { eid: string }>(
    GET_FORM_BY_ID,
    {
      variables: { eid: contents[selectedIndex].eid },
      onCompleted: (data) => {
        setUnauthorised(undefined);
        updateContentByIndex(selectedIndex, {
          ...contents[selectedIndex],
          formContent: {
            ...data?.FormById,
          },
        });
      },
      onError: (error) => {
        for (let err of error.graphQLErrors) {
          if (err.extensions.code === 'FORBIDDEN') {
            setUnauthorised?.('UNAUTHORISED');
            break;
          }
          if (err.extensions.code === 'BAD_USER_INPUT') {
            setUnauthorised?.('NOT_FOUND');
            break;
          }
        }
      },
    }
  );

  const { loading: responseLoading, data: userResponseData } = useQuery(
    GET_TP_USER_FORM_RESPONSE,
    {
      skip:
        !tpSessionData?.eid ||
        !contents[selectedIndex].eid ||
        !isCompleted ||
        isRetake,
      variables: {
        userId: loggedInUserEid,
        trainingSessionId: tpSessionData?.eid,
        formId: contents[selectedIndex].eid,
      },
    }
  );

  useEffect(() => {
    if (userResponseData?.TpFormResponse) {
      setLocalFormResponse(userResponseData.TpFormResponse);
    }
  }, [userResponseData]);

  const handleCompletion = async (
    itemId: string,
    sessionId: string,
    trainingId: string
  ) => {
    if (unAuthorised || formData?.FormById?.status === 'inactive') return;
    const userProgress = tpSessionData?.userProgress;

    if (
      userProgress?.[0]?.progress?.some(
        (item) => item.id === itemId && item.isCompleted
      )
    ) {
      return;
    }
    await service.updateTPProgress
      .updateTPProgress({
        variables: {
          input: {
            isCompleted: true,
            itemId: itemId,
            sessionId: sessionId,
            trainingId: trainingId,
            responses: [],
          },
        },
      })
      ?.then((res) => {
        if (res?.data?.AddTpProgress?.eid && tpSessionData) {
          updateTpSessionData({
            ...tpSessionData,
            userProgress: res?.data?.AddTpProgress
              ? [res?.data?.AddTpProgress]
              : [],
          });
        }
      });
  };

  const onSubmitSuccess = () => {
    const currentContent = contents?.[selectedIndex];
    if (tpSessionData?.eid && tpSessionData?.trainingId) {
      handleCompletion(
        currentContent.eid,
        tpSessionData.eid,
        tpSessionData.trainingId
      );
    }
  };

  const formContentWithResponses = useMemo(() => {
    if (!selectedData?.formContent || !userResponseData?.TpFormResponse) {
      return [selectedData?.formContent];
    }

    const userResponses =
      localFormResponse?.response || userResponseData?.TpFormResponse?.response;

    return [
      {
        ...selectedData.formContent,
        questions: selectedData.formContent.questions?.map((question) => {
          const userResponse = userResponses?.find(
            (response: any) => response.qid === question.eid
          );

          if (!userResponse) return question;

          return {
            ...question,
            response: userResponse.response,
            otherResponse: userResponse.otherResponse,
            responseUrl: userResponse.responseUrl,
            responseId: userResponse.responseId,
          };
        }),
      },
    ];
  }, [selectedData, userResponseData]);

  if (unAuthorised) {
    return (
      <NotAccessibleContainer>
        <CloseButton ml='auto' onClick={() => history.push(TRAINING_PATH_V2)} />
        <FormNotAccessible type={unAuthorised} />
      </NotAccessibleContainer>
    );
  }

  if (loading) {
    return (
      <Flex w='full' flexDir='column' gap={4}>
        <Skeleton h='40px' w='200px' />
        <Skeleton h='20px' w='full' />
        <Skeleton h='20px' w='full' />
        <Skeleton h='20px' w='full' />
        <Skeleton h='20px' w='full' />
        <Skeleton h='20px' w='full' />
        <Skeleton h='20px' w='full' />
        <Skeleton h='20px' w='full' />
        <Skeleton h='20px' w='full' />
      </Flex>
    );
  }

  if (formData?.FormById?.status === 'inactive')
    return (
      <Center height='60vh'>
        <InactiveFormEmptyState />
      </Center>
    );

  return (
    <Wrapper>
      <CustomFormType
        showTitleOutside
        content={formContentWithResponses || []}
        title={selectedData?.title}
        isPreview={isRetake}
        isDisabled={isCompleted && !isRetake}
        isReadOnly={isCompleted && !isRetake}
        trainingSessionId={tpSessionData?.eid}
        onSubmitSuccess={onSubmitSuccess}
        sessionId={userResponseData?.TpFormResponse?.sessionId}
      />
    </Wrapper>
  );
};

export default FormContent;
