import { useCallback } from 'react';
import { FieldErrors } from 'react-hook-form';
import { useRouteState } from '../../../../routes';
import { TFormInput } from './training-input.types';

export const useErrorHandler = () => {
  const { updateState } = useRouteState({ currentEditPage: 0 });

  return useCallback(
    (errors: FieldErrors<TFormInput>) => {
      Object.keys(errors).some((key) => {
        if (['title', 'thumbnail', 'category', 'description'].includes(key)) {
          updateState({ currentEditPage: 0 });
          return true;
        }
        if (key === 'contents') {
          updateState({ currentEditPage: 1 });
          return true;
        }
        updateState({ currentEditPage: 2 });
        return true;
      });
    },
    [updateState]
  );
};
