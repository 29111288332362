import React, { FC, useState } from 'react';
import { Box, Flex, Stack } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faCircleDot } from '@fortawesome/pro-solid-svg-icons';
import {
  faCircleCheck,
  faCircleXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { QuizQFormInput } from '../../quiz-form/quiz-form.types';
import PreviewContainer from '../PreviewContainer';

interface Props {
  data: QuizQFormInput;
}

const TrueFalsePreview: FC<Props> = ({ data }) => {
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [showResults, setShowResults] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    await new Promise((resolve) => setTimeout(resolve, 500));
    setShowResults(true);
    setIsSubmitting(false);
  };

  const getOptionStyles = (option: string) => {
    if (!showResults) {
      return {
        bg: 'white',
        border: '1px solid #E2E8F0',
      };
    }

    const isThisOptionCorrect =
      (option === 'True' && data.isTrue) ||
      (option === 'False' && !data.isTrue);

    if (selectedOption === option) {
      return {
        bg: isThisOptionCorrect
          ? 'rgba(181, 228, 202, 0.3)'
          : 'rgba(255, 106, 85, 0.2)',
        border: isThisOptionCorrect
          ? '1px solid rgba(131, 191, 110, 1)'
          : '1px solid rgba(255, 106, 85, 1)',
      };
    }

    if (isThisOptionCorrect) {
      return {
        bg: 'rgba(181, 228, 202, 0.3)',
        border: '1px solid rgba(131, 191, 110, 1)',
      };
    }

    return {
      bg: 'white',
      border: '1px solid #E2E8F0',
    };
  };

  const renderSelectionIcon = (option: string) => {
    const isSelected = selectedOption === option;
    const isThisOptionCorrect =
      (option === 'True' && data.isTrue) ||
      (option === 'False' && !data.isTrue);

    if (!showResults) {
      return (
        <FontAwesomeIcon
          icon={isSelected ? (faCircleDot as IconProp) : (faCircle as IconProp)}
          fontSize='16px'
          color='#E2E8F0'
        />
      );
    }

    if (isSelected || isThisOptionCorrect) {
      return (
        <FontAwesomeIcon
          icon={
            (isThisOptionCorrect ? faCircleCheck : faCircleXmark) as IconProp
          }
          fontSize='16px'
          color={
            isThisOptionCorrect
              ? 'rgba(131, 191, 110, 1)'
              : 'rgba(255, 106, 85, 1)'
          }
        />
      );
    }

    return null;
  };

  return (
    <PreviewContainer
      title={data.label}
      thumbnail={data.thumbnail}
      onSubmit={handleSubmit}
      showSubmit={!showResults}
      isSubmitting={isSubmitting}
      isDisabled={!selectedOption || typeof data.isTrue !== 'boolean'}
    >
      <Stack spacing='6px' fontSize='10px' fontWeight={500}>
        {['True', 'False'].map((option) => {
          const styles = getOptionStyles(option);
          return (
            <Flex
              key={option}
              bg={styles.bg}
              borderRadius='8px'
              p='10px'
              gap={2}
              minH='38px'
              border={styles.border}
              align='center'
              cursor={!showResults ? 'pointer' : 'default'}
              onClick={() => {
                if (!showResults) {
                  setSelectedOption(option);
                }
              }}
              _hover={
                !showResults
                  ? {
                      bg: 'rgba(177, 229, 252, 0.3)',
                      border: '1px solid rgba(42, 133, 255, 1)',
                    }
                  : {}
              }
            >
              {renderSelectionIcon(option)}
              <Box>{option}</Box>
            </Flex>
          );
        })}
      </Stack>
    </PreviewContainer>
  );
};

export default TrueFalsePreview;
