import { cloneDeep } from '@apollo/client/utilities';
import { useUserDataSelector } from 'hooks';
import React from 'react';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { TPSession } from 'sub-components/training-v2/shared/types/play';
import { usePlayContext } from '../store/context';
import { contentCategorize } from '../utils';
import { findFirstIncompleteContent } from '../utils/helper';
import { useService } from './useService';

export const useControl = () => {
  const { loggedInUserId } = useUserDataSelector((state) => ({
    loggedInUserId: state?.eid,
  }));

  const {
    loading,
    updateDataLoading,
    updateSessionData,
    initializeRetake,
    showLastScreen,
    updateIsPreview,
    updateSelectedIndex,
  } = usePlayContext((state) => ({
    loading: state?.dataLoading,
    updateDataLoading: state?.updateDataLoading,
    updateSessionData: state?.updateTpSessionData,
    initializeRetake: state?.initializeRetake,
    showLastScreen: state?.showLastScreen,
    updateIsPreview: state?.updateIsPreview,
    updateSelectedIndex: state?.updateSelectedIndex,
  }));

  const {
    tpSession: { getTPBySession, tpBySessionData },
  } = useService();

  const { sessionId, trainingId } = useParams<{
    trainingId: string;
    sessionId: string;
  }>();

  const initialLoadRef = React.useRef(false);

  const location = useLocation();
  const isRetake = location.pathname.includes('/play/retake/');
  const isPreview = location.pathname.includes('/play/preview/');
  const isViewResult = location.pathname.includes('/play/view-result/');

  useEffect(() => {
    if (!initialLoadRef.current) {
      initialLoadRef.current = true;
      getTPBySession({
        variables: {
          eid: sessionId,
          filter: {
            userId: [loggedInUserId],
          },
          assignedUserIds: [loggedInUserId],
        },
      });
    }
  }, [sessionId]);

  useEffect(() => {
    if (tpBySessionData?.data?.TpSessionById) {
      let _tpBySessionData = cloneDeep(tpBySessionData?.data?.TpSessionById);
      // Filter contents to remove `isRemoved=true`
      const session: TPSession = {
        ..._tpBySessionData,
        contents: _tpBySessionData?.contents?.filter(
          (content) => !content?.isRemoved
        ),
      };
      // // For completed sessions, only show contents that are in the progress array
      // if (session.userProgress?.[0]?.isCompleted) {
      //   const progressContentIds = session?.userProgress?.[0]?.progress?.map(
      //     (item) => item?.id
      //   );
      //   session.contents = session?.contents?.filter((content) =>
      //     progressContentIds?.includes(content?.eid)
      //   );
      // }
      let _session = contentCategorize(session);
      const wasCompleted = session.userProgress?.[0]?.completedAt;

      // If the session was previously completed but has new content items
      if (wasCompleted && _session?.userProgress?.[0]) {
        const progressContentIds =
          _session?.userProgress?.[0]?.progress?.map((item) => item?.id) || [];

        // Find content items that don't have corresponding progress entries
        const newContentItems = _session?.contents?.filter(
          (content) => !progressContentIds?.includes(content?.eid)
        );

        // For completed sessions with new content, add progress entries for new items
        if (newContentItems?.length > 0 && _session?.userProgress?.[0]) {
          const currentDate = new Date();

          // Create progress entries for new content items
          const newProgressEntries = newContentItems?.map((content) => ({
            type: content?.type,
            id: content?.eid,
            score: 0,
            isCompleted: true, // Mark as completed
            responses: [],
            updatedAt: currentDate,
            createdAt: currentDate,
          }));

          // Add new progress entries to existing progress
          _session.userProgress[0].progress = [
            ..._session?.userProgress?.[0]?.progress,
            ...newProgressEntries,
          ];
        }
      }
      if ((wasCompleted && isRetake) || isPreview || isViewResult) {
        initializeRetake(true);
      }

      const isFullyCompleted = _session?.userProgress?.[0]?.isCompleted;

      if (!isFullyCompleted) {
        const firstIncompleteIndex = findFirstIncompleteContent(
          _session?.contents,
          _session?.userProgress
        );
        updateSelectedIndex(firstIncompleteIndex);
      }

      updateSessionData(_session);

      if (isViewResult) {
        showLastScreen();
      }
    }
  }, [tpBySessionData?.data?.TpSessionById, isRetake, isViewResult, isPreview]);

  useEffect(() => {
    updateDataLoading(tpBySessionData?.loading);
  }, [tpBySessionData?.loading]);

  useEffect(() => {
    updateIsPreview(!!isPreview);
  }, [isPreview]);

  return {
    loading,
  };
};
