import { AddIcon } from '@chakra-ui/icons';
import { Box, Center, Flex, Image } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSquareCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'atoms';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QuizQuestion } from '../../types';
import multiplechoice from '../../../../../assets/images/training-path-v2/multiplechoice.png';
import truefalse from '../../../../../assets/images/training-path-v2/truefalse.png';
import fillinblank from '../../../../../assets/images/training-path-v2/fillinblank.png';
import reorder from '../../../../../assets/images/training-path-v2/reorder.png';
import match from '../../../../../assets/images/training-path-v2/match.png';

interface IProps {
  setPreviewOption: React.Dispatch<
    React.SetStateAction<QuizQuestion | undefined>
  >;
}

const ChooseQuestion: FC<IProps> = ({ setPreviewOption }) => {
  const { t } = useTranslation(['form', 'training']);
  const [hoveredIndex, setHoveredIndex] = useState<number>(0);

  const questionTypes = [
    {
      id: 'mcq',
      icon: multiplechoice,
      title: 'Multiple choice',
      description:
        'Check for retention by asking participants to pick one or more correct answers.',
    },
    {
      id: 'trueFalse',
      icon: truefalse,
      title: 'True or False',
      description:
        "Test participants' quick comprehension with straightforward true-or-false statements.",
    },
    {
      id: 'match',
      icon: match,
      title: 'Match the set',
      description:
        'Encourage critical thinking by matching related items in two lists.',
    },
    {
      id: 'reorder',
      icon: reorder,
      title: 'Reorder',
      description: 'Challenge users to arrange items in the correct sequence.',
    },
    {
      id: 'fill',
      icon: fillinblank,
      title: 'Fill in the blanks',
      description:
        'Let users recall and type the correct word or phrase, perfect for testing detailed knowledge or key terms.',
    },
  ];

  useEffect(() => {
    if (questionTypes?.[hoveredIndex]?.['id'] === 'mcq') {
      setPreviewOption({
        questionPoint: 1,
        multipleCorrectAnswers: false,
        options: [
          {
            isCorrect: false,
            optionTitle: 'Using cold butter',
          },
          {
            isCorrect: false,
            optionTitle: 'Overmixing the batter',
          },
        ],
        questionTitle:
          'Which of the following is the most important step to ensure your cake rises properly during baking?',
        questionType: 'mcq',
      });
    } else if (questionTypes?.[hoveredIndex]?.['id'] === 'trueFalse') {
      setPreviewOption({
        questionPoint: 1,
        options: [
          {
            optionTitle: 'True',
            isCorrect: true,
          },
          {
            isCorrect: false,
            optionTitle: 'False',
          },
        ],
        questionTitle:
          'Which of the following is the most important step to ensure your cake rises properly during baking?',
        questionType: 'trueFalse',
      });
    } else if (questionTypes?.[hoveredIndex]?.['id'] === 'match') {
      setPreviewOption({
        questionType: 'match',
        options: [
          { question: 'Creaming', response: 'A' },
          { question: 'Proofing', response: 'B' },
          { question: 'Folding', response: 'C' },
          { question: 'Sifting', response: 'D' },
        ],
        questionPoint: 1,
        questionTitle:
          'Match the following baking terms with their correct descriptions',
      });
    }
  }, [hoveredIndex]);

  return (
    <Flex
      bg='rgba(252, 252, 252, 1)'
      border='1px solid rgba(111, 118, 126, 0.5)'
      borderTopLeftRadius='12px'
      borderTopRightRadius='12px'
      mt={'20px'}
      p='20px 24px'
      flexDir='column'
      gap={4}
      width={'632px'}
    >
      <Flex flexDir='column' gap={'4px'}>
        {/* @ts-ignore */}
        <Box fontWeight={600} fontSize='14px'>
          {t('training:quiz.queSerial', { queNo: 1 })}
        </Box>
        {/* @ts-ignore */}
        <Box fontWeight={400} fontSize='12px'>
          {t('training:quiz.queDesc')}
        </Box>
      </Flex>
      <Flex flexDir='column' gap={'12px'}>
        {questionTypes?.map((que, index) => (
          <Flex
            key={index}
            align='baseline'
            justify='space-between'
            p={'12px 16px'}
            borderRadius='8px'
            bg={hoveredIndex === index ? 'gray.100' : 'transparent'}
            transition='all 0.2s'
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(-1)}
          >
            <Flex gap={4}>
              <Center
                boxSize='40px'
                borderRadius='8px'
                p='6px 7px'
                bg={
                  hoveredIndex === index ? 'blue.500' : 'rgba(111, 118, 126, 1)'
                }
                color='white'
                transition='all 0.2s'
              >
                <Image src={que?.icon} h={'16px'} w={'16px'} />
              </Center>
              <Flex flexDir='column' gap={1}>
                <Box fontWeight={600} fontSize='15px'>
                  {que?.title}
                </Box>
                <Box
                  fontWeight={500}
                  fontSize='12px'
                  color='rgba(111, 118, 126, 1)'
                  opacity={hoveredIndex === index ? 1 : 0}
                  transition='all 0.2s'
                  width={'378px'}
                >
                  {que?.description}
                </Box>
              </Flex>
            </Flex>
            <Button
              size='sm'
              textColor={hoveredIndex === index ? '#2A85FF' : '#1A1D1F'}
              variant='solid'
              leftIcon={<AddIcon size={2} />}
            >
              Add
            </Button>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

ChooseQuestion.displayName =
  'displayName:sub-components/training-v2/shared/quiz/components/ChooseQuestion';

export default ChooseQuestion;
