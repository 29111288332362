import React, { FC, useMemo, useState } from 'react';
import { Checkbox, Flex, Text } from '@chakra-ui/react';
import { HeaderColors } from 'configs';
import { BoxHeader } from 'ui-components';
import SearchInput from 'atoms/SearchInput';
import { ReactComponent as BackButton } from 'assets/images/back.svg';
import { PageNameType } from './MainContainer';
import { ClearFilterType } from './FilterContainer';
import EmptyState from './EmptyState';
import {
  FiltersType,
  useTrainingContext,
} from '../../../TrainingTableContainer';
import { ITypes } from '../Header';

interface IProps {
  filters: FiltersType;
  setPageName: React.Dispatch<React.SetStateAction<PageNameType>>;
  handleJobSelection?: (
    e: React.ChangeEvent<HTMLInputElement>,
    status: ITypes
  ) => void;
  clearFilters: (type?: ClearFilterType) => void;
}

const JobFilter: FC<IProps> = ({
  setPageName,
  filters,
  handleJobSelection,
  clearFilters,
}) => {
  const { jobs } = filters;
  const [jobsSearchText, setJobsSearchText] = useState('');
  const { trackingTpPaginationLoader, trainingCategoriesLoader } =
    useTrainingContext();

  // UseMemo for efficient filtering
  const filteredJobs = useMemo(
    () =>
      jobs?.filter((status) =>
        status?.name?.toLowerCase().includes(jobsSearchText.toLowerCase())
      ),
    [jobs, jobsSearchText]
  );

  const statusCount = jobs?.length;
  const filteredCount = filteredJobs?.length ?? 0;

  return (
    <>
      <Flex alignItems={'center'} mt={'30px'} justifyContent={'space-between'}>
        <BoxHeader title='Filters' color={HeaderColors.Purple} />
        <Text
          cursor={'pointer'}
          onClick={() => clearFilters('jobs')}
          fontSize={'14px'}
          fontWeight={400}
          color={filters?.selectedJobsId?.length > 0 ? '#2A85FF' : '#6F767E'}
        >
          Clear Selection
        </Text>
      </Flex>

      <Flex mt={'20px'} gap={3} alignItems={'center'} marginBottom={'1rem'}>
        <BackButton
          width={'1.2rem'}
          onClick={() => setPageName('base')}
          cursor={'pointer'}
        />
        <Text
          style={{
            fontWeight: 600,
            fontSize: 14,
          }}
        >
          By job
        </Text>
      </Flex>
      {/* <SearchInput
        className='uploaded-by-search-field'
        value={setStatusSearchText}
        onChange={(e) => setStatusSearchText(e?.target?.value)}
        placeholder='Search by name'
        hideShortcuts
      /> */}

      {filteredCount > 0 ? (
        <Flex maxHeight={'300px'} flexDir={'column'} overflowY={'auto'}>
          {filteredJobs?.map((job) => {
            return (
              <Flex
                key={job?.eid}
                gap={4}
                alignItems={'center'}
                padding={'0.5rem 0px'}
              >
                <Checkbox
                  disabled={
                    trackingTpPaginationLoader || trainingCategoriesLoader
                  }
                  value={job?.eid}
                  onChange={(e) => handleJobSelection?.(e, job)}
                  isChecked={
                    filters?.selectedJobsId?.includes(job?.eid) ? true : false
                  }
                >
                  <Flex alignItems={'center'} gap={4}>
                    <Flex justifyContent={'center'} flexDir={'column'}>
                      <Text fontSize={'14px'}>{job?.name}</Text>
                    </Flex>
                  </Flex>
                </Checkbox>
              </Flex>
            );
          })}
        </Flex>
      ) : (
        <EmptyState view='statuses' />
      )}
    </>
  );
};

export default JobFilter;
