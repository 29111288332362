import { Box, Flex, Skeleton, VStack, Text } from '@chakra-ui/react';
import { FlexCard } from 'atoms';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
// import MemberList from './MemberList';
// import LocationFilters from './LocationFilters';
import { useUserDataSelector } from 'hooks';
// import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { usersEntityObj } from 'sub-components/Header';
import { GET_OVERALL_USER_WISE_ANALYTICS } from 'sub-components/training-v2/shared/query/dashboard.graphql';
import { IFinalOverallUserWiseAnalytics } from 'sub-components/training-v2/shared/types';
import { getMyTrainingLeaderboardMembers } from '../../locationOwner/helper';
import MemberList from './MemberList';
import JobFilter from './JobFilter';
import useCombinedStore from 'zustandStore/store';
import { AuthRole } from 'authorization';

interface TabConfig {
  label: string;
  minWidth: string;
  hasNewBadge?: boolean;
}

const Leaderboard: FC = () => {
  const [overallUserWiseData, setOverallUserWiseData] = useState<
    IFinalOverallUserWiseAnalytics[]
  >([]);
  const [finalMemberData, setFinalMemberData] = useState([]);
  const [jobList, setJobList] = useState<string[]>([]);
  const [selectedJob, setSelectedJob] = useState<string>('All jobs');
  const [jobCounts, setJobCounts] = useState({});

  const { userEntityLoading } = useCombinedStore((state) => ({
    userEntityLoading: state.usersEntityLoading,
  }));

  const entityObjData = useReactiveVar(usersEntityObj);

  const {
    userAuthRole,
    loggedInUserLocations,
    loggedInUserId,
    businessLocations,
    loggedInUserRole,
  } = useUserDataSelector((state) => ({
    userAuthRole: state?.authRole,
    loggedInUserLocations: state?.locations,
    loggedInUserId: state?.eid,
    businessLocations: state?.entity?.locations,
    loggedInUserRole: state?.role,
  }));

  const [getOverallUserWiseAnalytics, { loading: userWiseLoader }] =
    useLazyQuery(GET_OVERALL_USER_WISE_ANALYTICS, {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setOverallUserWiseData(data?.TpOverallUserWiseAnalytics);
      },
    });

  useEffect(() => {
    const leaderboardMemberData = getMyTrainingLeaderboardMembers(
      overallUserWiseData,
      entityObjData,
      loggedInUserLocations
    );

    // Prepare job list
    const jobList = ['All jobs', loggedInUserRole];

    // Create a map to hold the job counts
    const countData: Record<string, number> = {
      'All jobs': leaderboardMemberData?.all?.length,
    };

    // Calculate counts for each job role dynamically
    leaderboardMemberData?.all?.forEach((item) => {
      if (item?.userRole) {
        countData[item.userRole] = (countData[item.userRole] || 0) + 1;
      }
    });

    // Set the job counts in state
    setJobList(jobList);
    setJobCounts(countData);

    if (selectedJob === 'All jobs') {
      setFinalMemberData(leaderboardMemberData?.all);
    } else {
      // Get users having similar job role of logged in user
      const filteredJobRoleUsers = leaderboardMemberData?.all?.filter(
        (item) => item?.userRole === selectedJob
      );
      setFinalMemberData(filteredJobRoleUsers);
    }
  }, [entityObjData, overallUserWiseData, selectedJob]);

  useEffect(() => {
    getOverallUserWiseAnalytics();
  }, []);

  const getEmptySkeleton = () => {
    return (
      <Flex flexDir='column' w='full' gap={8}>
        <Skeleton w='40%' h='20px' />
        <Skeleton w='full' h='20px' />
        <Skeleton w='full' h='20px' />
        <Skeleton w='full' h='20px' />
        <Skeleton w='full' h='20px' />
        <Skeleton w='full' h='20px' />
        <Skeleton w='full' h='20px' />
        <Skeleton w='full' h='20px' />
      </Flex>
    );
  };

  return (
    <FlexCard width={'100%'} height={'100%'} border={'2px solid #EFEFEF'}>
      {userWiseLoader || userEntityLoading ? (
        getEmptySkeleton()
      ) : (
        <VStack align='start' w='full'>
          <Flex
            alignItems={'center'}
            width={'100%'}
            justifyContent={'space-between'}
            gap={2}
            mb={'50px'}
          >
            <Text
              fontWeight='600'
              fontSize={'20px'}
              lineHeight='32px'
              color='#1a1d1f'
            >
              Leaderboard
            </Text>
            {userAuthRole === AuthRole?.WORKER && (
              <JobFilter
                jobList={jobList}
                setSelectedJob={setSelectedJob}
                selectedJob={selectedJob}
                jobCounts={jobCounts}
              />
            )}
          </Flex>
          <MemberList finalMemberData={finalMemberData} />
        </VStack>
      )}
    </FlexCard>
  );
};

Leaderboard.displayName =
  'displayName:sub-components/training-v2/dashboard/components/myTrainings/topSection/Leaderboard';

export default Leaderboard;
