import React, { FC } from 'react';
import { Box, Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { useFieldArray, useFormContext, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ListSortable } from 'ui-components';
import { IFormInput } from '../task.types';
import TaskItem from './TaskItem';
import TaskStepFormNew from './TaskStepFormNew';

const TaskStepForm: FC = () => {
  const { t } = useTranslation(['task']);
  const { control } = useFormContext<IFormInput>();

  const { fields, append, remove, move } = useFieldArray<
    IFormInput,
    'contents'
  >({
    name: 'contents',
    rules: {
      required: t('task:validation.taskStepRequired'),
    },
  });

  const contentError = useFormState({
    control: control,
    name: 'contents',
    exact: true,
  }).errors?.contents;

  const onRemoveClick = (_: unknown, index: number) => {
    remove(index);
  };

  return (
    <>
      <Flex flexDir='column' gap={4} pt='20px'>
        <FormControl isInvalid={!!contentError}>
          <Flex flexDir='column' gap='14px'>
            {!fields?.length && (
              <Box fontWeight='600'>{t('task:addSteps')}</Box>
            )}

            <ListSortable
              items={fields}
              onDragEnd={(oldIndex, newIndex) => move(oldIndex, newIndex)}
            >
              {fields?.map((value, index) => (
                <TaskItem
                  key={value.tempEid}
                  fieldId={value.id}
                  index={index}
                  data={value}
                  onDelete={onRemoveClick}
                />
              ))}
            </ListSortable>
          </Flex>

          <FormErrorMessage>
            {contentError?.root?.message || contentError?.message}
          </FormErrorMessage>
        </FormControl>
      </Flex>

      <TaskStepFormNew append={append} />
    </>
  );
};

export default TaskStepForm;
