import { useReactiveVar } from '@apollo/client';
import React from 'react';
import { usersEntityObj } from '../sub-components/Header';

export const useLocationOps = () => {
  const businessEntityData = useReactiveVar(usersEntityObj);
  const getBusinessLocations = () => {
    return businessEntityData?.filter((bus) => bus?.type === 'branch');
  };
  const getLocationDetailsById = (locationId: string) => {
    return businessEntityData?.find((bus) => bus?.eid === locationId);
  };
  return { getLocationDetailsById, getBusinessLocations };
};
