import React, { FC, Fragment, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { isCheckList, toArray } from 'utils/utils';
import { FormCategory, QuestionType } from 'types';

import TitleHeader from '../../CardEditor/TitleHeader';
import { IFormInput } from '../create-form/form.types';
import { useFormCategories } from '../common';
import FormCategorySelect from './FormCategorySelect';
import { fromAvailabilityCheck } from './from-availability-check';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';

interface IProps {
  isReadOnly?: boolean;
}

const FormCategoryComponent: FC<IProps> = ({ isReadOnly }) => {
  const { t } = useTranslation('form');
  const history = useHistory();
  const { control, setValue, getValues } = useFormContext<IFormInput>();

  const { data, client } = useFormCategories();

  function onTypeChange(val: IFormInput['formCategory'], callback: Function) {
    const prevVal = getValues('formCategory.eid');
    callback(val);
    if (isCheckList(val.name)) {
      if (val.eid === prevVal) {
        return;
      }
      setValue('questions', [
        {
          qType: QuestionType.CHECKBOX,
          options: [
            {
              label: '',
              isCorrect: false,
              isDisabled: false,
              subTitle: '',
            },
            {
              label: '',
              isCorrect: false,
              isDisabled: false,
              subTitle: '',
            },
          ],
          label: '',
          isRequired: true,
          hasAnalytics: false,
        },
      ]);
    }
  }

  const categoryList = useMemo(() => {
    return toArray(data?.EntityFormCategories).map((cat) => cat.eid);
  }, [data?.EntityFormCategories]);

  const onCategoryDeleted = async () => {
    if (getValues('eid')) {
      try {
        const res = await fromAvailabilityCheck(client, getValues('eid'));
        setValue('formCategory', {
          eid: res.categoryId,
          name: res.category,
        });
      } catch (err) {
        console.log(err);
        setTimeout(history.goBack, 100);
      }
    }
  };

  const onCategoryUpdated = async (_category: FormCategory) => {
    if (getValues('formCategory.eid') === _category?.eid) {
      setValue('formCategory', _category);
    }
  };

  return (
    <Fragment>
      <TitleHeader
        title={t('formCategory')}
        desc={t('formCategoryDesc')}
        isRequired
      />

      <Controller
        name='formCategory'
        control={control}
        rules={{
          required: t('validation.formCategoryRequired'),
          validate: (value) => {
            if (!value?.eid) {
              return t('validation.formCategoryRequired');
            }
            if (!categoryList.includes(value.eid)) {
              return t('validation.formTypeInvalid');
            }
          },
        }}
        render={({ field, fieldState }) => {
          return (
            <FormControl
              mt={3}
              isInvalid={!!fieldState.error}
              isReadOnly={isReadOnly}
            >
              <FormCategorySelect
                {...field}
                value={field.value as never}
                onChange={(newValue) => {
                  deployEvent(AmplitudeEventNames?.FORM_CATEGORY_SELECT, {
                    form_id: '',
                    create_form_funnel_id: '',
                    category: newValue?.name,
                    edit_form_funnel_id: 3,
                  });

                  onTypeChange(newValue as never, field.onChange);
                }}
                isDisabled={isReadOnly}
                onCategoryDeleted={onCategoryDeleted}
                onCategoryUpdated={onCategoryUpdated}
              />
              <FormErrorMessage>
                <span>{fieldState?.error?.message}</span>
              </FormErrorMessage>
            </FormControl>
          );
        }}
      />

      <Box h='30px' />
    </Fragment>
  );
};

export default FormCategoryComponent;
