import React, { forwardRef, useMemo } from 'react';
import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useControllableState,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { SelectOption } from '../../../../../atoms';

interface IProps {
  value?: string;
  onChange?: (value: string) => void;
  options: SelectOption[];
}

const PopupSelect = forwardRef<never, IProps>(
  ({ value, onChange, options = [] }, ref) => {
    const [internalValue, updateValue] = useControllableState<string>({
      value: value,
      onChange: onChange,
    });

    const _value = useMemo(() => {
      return options.find((val) => val.value === internalValue);
    }, [internalValue, options]);

    return (
      <Menu
        placement='bottom-end'
        closeOnSelect
        boundary='scrollParent'
        autoSelect={false}
      >
        <MenuButton
          ref={ref}
          display='flex'
          fontSize='13px'
          fontWeight={500}
          // isDisabled={disabled}
        >
          <Flex gap={2} align='center'>
            {_value ? (
              <Box as='span' color='#33383F' whiteSpace='nowrap'>
                {_value.label}
              </Box>
            ) : (
              <Box as='span' opacity='0.5'>
                Select ...
              </Box>
            )}

            <ChevronDownIcon boxSize='28px' color='#33383F' />
          </Flex>
        </MenuButton>
        <MenuList
          width='280px'
          display='flex'
          flexDirection='column'
          padding='16px'
          borderRadius='12px'
          zIndex={100}
          gap={1}
        >
          {options.map((item) => {
            const isSelected = item.value === internalValue;
            return (
              <MenuItem
                key={item.value}
                gap={3}
                height='48px'
                alignItems='center'
                paddingInline='12px'
                borderRadius='12px'
                cursor='pointer'
                color='#6F767E'
                aria-selected={isSelected}
                _selected={{ bg: '#2A85FF1A', color: '#2A85FF' }}
                _hover={{ bg: '#F4F4F3' }}
                onClick={() => updateValue?.(item.value)}
              >
                <Box flex={1} fontSize='15px' fontWeight='600' isTruncated>
                  {item.label}
                </Box>
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    );
  }
);

PopupSelect.displayName = 'PopupSelect';

export default PopupSelect;
