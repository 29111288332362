import {
  Badge,
  Box,
  Center,
  Flex,
  HStack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { Table } from 'antd';
import 'antd/dist/reset.css';
import type { ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import { LocationBadge, SortPopover } from '../../Tracking/components';

import { useParams } from 'react-router-dom';
import {
  TMemberAnalyticsItem,
  TMemberAnalyticsProps,
} from 'sub-components/training-v2/shared/types';
import { useMemberActivity } from '../../memberActivity';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as EvalautedbyCheckIcon } from 'assets/images/training/evalautedbyCheck.svg';
import { getImage } from 'utils';
import TrainingPathAction from './TrainingPathAction';
import EmptyState from 'sub-components/EmptyState';
import moment from 'moment';
import { Image } from 'ui-components';

export const SA_MEMBER_ACTIONS = [
  'evaluate',
  'reassign',
  'view_activity',
  'remind',
];

export interface IOnViewActivityClick {
  name: string;
  userId: string;
  sessionId: string;
  tp: any;
}
const renderTrainingPathInfo = (record: {
  trainingPathEndDate: string;
  dueDate: string;
  status: string;
}) => {
  const now = moment().utc().startOf('day');
  const userDueDate = record?.dueDate;
  const tpDueDate = record?.trainingPathEndDate;
  const userDueDateMoment = moment(userDueDate);
  const tpDueDateMoment = moment(tpDueDate);

  const isOverDue =
    record?.status !== 'Completed' &&
    ((userDueDateMoment.isValid() && now.isAfter(userDueDateMoment)) ||
      (tpDueDateMoment.isValid() && now.isAfter(tpDueDateMoment)));

  if (record?.trainingPathEndDate !== '-' && record?.dueDate !== '-') {
    return (
      <>
        <Flex align='center' gap={2}>
          <Text
            as='span'
            fontSize='12px'
            textColor={isOverDue ? '#FF6A55' : '#6F767E'}
          >
            {userDueDate}
          </Text>
          <Tooltip
            placement='bottom'
            label="The assignee's personal deadline, based on path duration, is shown here. The actual path deadline is displayed below."
          >
            <Box>
              <FontAwesomeIcon
                icon={faCircleInfo as IconProp}
                cursor='pointer'
              />
            </Box>
          </Tooltip>
        </Flex>
        <Box as='span' color='#6F767E' fontSize='12px' fontWeight={500}>
          {tpDueDate}
        </Box>
      </>
    );
  }
  if (record?.dueDate !== '-' && record?.trainingPathEndDate === '-') {
    return (
      <Box
        as='span'
        color={isOverDue ? '#FF6A55' : '#6F767E'}
        fontSize='12px'
        fontWeight={500}
      >
        {userDueDate}
      </Box>
    );
  }

  return (
    <Box
      as='span'
      color={isOverDue ? '#FF6A55' : '#6F767E'}
      fontSize='12px'
      fontWeight={500}
    >
      {tpDueDate}
    </Box>
  );
};

export const getInActiveStyles = (
  record: TMemberAnalyticsItem
): Record<string, number | string> => {
  return record?.isRemoved
    ? {
        opacity: 0.5,
        cursor: 'not-allowed',
      }
    : {};
};

const MembersTable: React.FC<TMemberAnalyticsProps> = ({
  memberAnalytics,
  tp,
  refetchTpSession,
}) => {
  const { DrawerComponent, useMemberActivityDrawer } = useMemberActivity();
  const [data, setData] = useState<TMemberAnalyticsItem[]>([]);

  const { sessionId } = useParams<{
    sessionId: string;
  }>();
  useEffect(() => {
    setData(memberAnalytics);
  }, [memberAnalytics]);

  const [sortOrder, setSortOrder] = useState<'asc' | 'desc' | ''>('');

  const handleSort = (order: 'asc' | 'desc' | '') => {
    setSortOrder(order);

    const sortedData = [...memberAnalytics].sort((a, b) => {
      if (order === 'asc') return a?.name.localeCompare(b?.name);
      if (order === 'desc') return b?.name.localeCompare(a?.name);
      return 0;
    });

    setData(sortedData);
  };

  const onViewActivityClick = ({
    name,
    userId,
    sessionId,
    tp,
  }: IOnViewActivityClick) => {
    useMemberActivityDrawer({
      memberName: name,
      userId: userId,
      sessionId: sessionId,
      tp: tp,
    });
  };

  const columns: ColumnsType<TMemberAnalyticsItem> = [
    {
      title: (
        // @ts-ignore
        <Flex gap={1} align='center'>
          <Text fontSize='12px' fontWeight={500} textColor={'#6F767E'}>
            Members
          </Text>
          <SortPopover onSort={handleSort} />
        </Flex>
      ),
      dataIndex: 'name',
      key: 'name',
      width: 200,
      render: (_, record) => {
        return (
          <Flex
            gap={2}
            align='center'
            style={getInActiveStyles(record)}
            whiteSpace={'nowrap'}
          >
            <div
              style={{
                width: '64px',
                height: '64px',
                borderRadius: '6px',
                overflow: 'hidden',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Image
                src={getImage(record?.profilePic, record?.name)}
                height={64}
                width={64}
                style={{
                  objectFit: 'cover',
                  width: '100%',
                  height: '100%',
                }}
              />
            </div>
            <Flex direction={'column'} gap={'0.1px'}>
              <Box>
                <Tooltip label={record?.name}>
                  <Text
                    fontWeight={600}
                    fontSize={'13px'}
                    color='#111315'
                    maxWidth={'110px'}
                    isTruncated
                  >
                    {record?.name}
                  </Text>
                </Tooltip>
                <LocationBadge
                  locations={
                    Array.isArray(record?.locations) ? record?.locations : []
                  }
                />
              </Box>
              <Box ml={1}>
                <Tooltip label={record?.job}>
                  <Text
                    color='#8E59FF'
                    fontSize={'10px'}
                    fontWeight={500}
                    isTruncated
                    maxWidth={'50px'}
                  >
                    {record?.job}
                  </Text>
                </Tooltip>
              </Box>
            </Flex>
          </Flex>
        );
      },
    },
    {
      title: (
        <Text fontSize='12px' fontWeight={500} textColor={'#6F767E'}>
          Status
        </Text>
      ),
      dataIndex: 'status',
      key: 'status',
      render: (status: string, record) => {
        return (
          <Badge
            bg={
              status === 'Completed'
                ? '#83BF6E33'
                : status === 'Pending'
                ? '#FF6A5533'
                : '#FFD70033'
            }
            textColor={
              status === 'Completed'
                ? '#83BF6E'
                : status === 'Pending'
                ? '#FF6A55'
                : '#FFD700'
            }
            fontWeight={700}
            fontSize='12px'
            textTransform='capitalize'
            borderRadius='4px'
            style={getInActiveStyles(record)}
          >
            {status}
          </Badge>
        );
      },
    },
    {
      title: (
        <Text fontSize='12px' fontWeight={500} textColor={'#6F767E'}>
          Progress
        </Text>
      ),
      dataIndex: 'progress',
      key: 'progress',
      align: 'center',
      render: (_, record) => {
        const isDemoGreator = record?.total >= record?.completedProgress;
        return isDemoGreator ? (
          <Badge
            color='gray.600'
            fontSize='12px'
            fontWeight={500}
            bg='#EFEFEF'
            px={4}
            borderRadius='6px'
            style={getInActiveStyles(record)}
          >
            <HStack spacing={0.1}>
              <Text color='#1A1D1F' fontSize={'14px'}>
                {record?.completedProgress}
              </Text>
              <Text color='#1A1D1F' fontSize={'14px'}>
                /
              </Text>
              <Text color='#6F767E'>{record?.total}</Text>
            </HStack>
          </Badge>
        ) : (
          '-'
        );
      },
    },
    {
      title: (
        <Text fontSize='12px' fontWeight={500} textColor={'#6F767E'}>
          Assign Date
        </Text>
      ),
      dataIndex: 'assignedOn',
      key: 'assignedOn',
      align: 'center',
      render: (assignedOn: string, record) => (
        <Text
          color={'#33383F'}
          fontSize={'12px'}
          fontWeight={500}
          style={getInActiveStyles(record)}
        >
          {assignedOn}
        </Text>
      ),
    },
    {
      title: (
        <Text fontSize='12px' fontWeight={500} textColor={'#6F767E'}>
          Due Date
        </Text>
      ),
      dataIndex: 'dueDate',
      key: 'dueDate',
      render: (_, record) => (
        <Flex flexDirection='column' style={getInActiveStyles(record)}>
          {renderTrainingPathInfo(record)}
        </Flex>
      ),
    },
    {
      title: (
        <Text fontSize='12px' fontWeight={500} textColor={'#6F767E'}>
          Comp. Date
        </Text>
      ),
      dataIndex: 'completedOn',
      key: 'completedOn',
      align: 'center',
      render: (completedOn: string, record) => (
        <Text
          color={'#33383F'}
          fontSize={'12px'}
          fontWeight={500}
          style={getInActiveStyles(record)}
        >
          {completedOn === 'Invalid date' ? '-' : completedOn}
        </Text>
      ),
    },
    {
      title: (
        <Text fontSize='12px' fontWeight={500} textColor={'#6F767E'}>
          Score
        </Text>
      ),
      dataIndex: 'score',
      key: 'score',
      render: (score: number | undefined, record) => {
        const recievedEvaluatedScore = Number(
          record?.score +
            (Array.isArray(record?.ratings) && record?.ratings?.length > 0
              ? record?.ratings?.reduce((acc, curr) => acc + curr, 0)
              : 0)
        ).toFixed(0);

        const total = record?.maxScore + record?.ratings?.length * 10; //could be undefined if the user has not completed the training

        //explicit check for denominator to be always greator
        const isDemoGreator = total >= Number(recievedEvaluatedScore);
        return (
          <>
            {total && total !== 0 && isDemoGreator ? (
              <Flex
                flexDir={'column'}
                gap={1}
                align={'center'}
                justify={'center'}
                mt={2}
              >
                <Flex
                  width={'fit-content'}
                  borderRadius={'6px'}
                  p={'2px 8px'}
                  alignItems={'center'}
                  backgroundColor={'#EFEFEF'}
                  style={getInActiveStyles(record)}
                >
                  <Text fontSize={'14px'} fontWeight={500} color={'#33383F'}>
                    {`${recievedEvaluatedScore}/${total}`}
                  </Text>
                </Flex>
                {record?.evaluatedBy && (
                  <Flex gap={1} alignItems={'center'} justifyContent={'center'}>
                    <EvalautedbyCheckIcon height={'18px'} width={'18px'} />
                    <Text
                      color={'#6F767E'}
                      fontSize={'10px'}
                      fontWeight={400}
                      isTruncated
                      maxW={'65px'}
                    >
                      {record?.evaluatedBy}
                    </Text>
                  </Flex>
                )}
              </Flex>
            ) : (
              '-'
            )}
          </>
        );
      },
    },
    {
      title: (
        <Text
          fontSize='12px'
          fontWeight={500}
          textColor={'#6F767E'}
          isTruncated
          maxW={'120px'}
        >
          Avg. Time Spent
        </Text>
      ),
      dataIndex: 'averageTimeSpent',
      key: 'averageTimeSpent',
      render: (averageTimeSpent: string, record) => {
        return (
          <Text
            color={'#33383F'}
            fontSize={'12px'}
            fontWeight={500}
            style={getInActiveStyles(record)}
          >
            {averageTimeSpent}
          </Text>
        );
      },
    },
    {
      title: (
        <Text fontSize='12px' fontWeight={500} textColor={'#6F767E'}>
          Action
        </Text>
      ),
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (action: string | undefined, record) => {
        const assignedUser = {
          isEvaluated: record?.isEvaluated,
          completedOn: record?.completedOn,
          dueDate: record?.dueDate,
          userId: record?.userId,
          score: record?.score,
          scorePercent: record?.scorePercent,
          startDate: record?.assignedOn,
          maxScore: record?.maxScore,
        };

        return (
          <Flex style={getInActiveStyles(record)}>
            <TrainingPathAction
              onViewActivityClick={onViewActivityClick}
              userName={record?.name}
              sessionId={sessionId}
              tp={{
                training: tp?.TpSessionById?.training,
                endDate: tp?.TpSessionById?.endDate,
                assignedUser: assignedUser,
                hasSkillVerifier: tp?.TpSessionById?.hasSkillVerifier,
                isRepeating: tp?.TpSessionById?.isRepeating,
                accessPermission: tp?.TpSessionById.training?.accessPermission,
                supervisors: tp?.TpSessionById?.supervisors,
                createdBy: tp?.TpSessionById?.createdBy,
                status: tp?.TpSessionById?.training?.status,
              }}
              onActionCompleted={refetchTpSession}
              isRemoved={record?.isRemoved}
            />
          </Flex>
        );
      },
    },
  ];

  return (
    <Box mt={6} bg='white' borderRadius='lg' shadow='sm'>
      {data && data?.length > 0 ? (
        <Table
          columns={columns}
          dataSource={data}
          rowKey='id'
          pagination={{
            pageSize: 5,
            showSizeChanger: false,
          }}
          // scroll={{ x: '' }}
        />
      ) : (
        <Center height='100%' width='100%'>
          <EmptyState image='Report' title='No data found' imageSize='300px' />
        </Center>
      )}
      {DrawerComponent}
    </Box>
  );
};
MembersTable.displayName =
  'displayName:sub-components/training-v2/dashboard/components/TrackPathDashboard/components/MemberAnalytics.tsx';
export default MembersTable;
