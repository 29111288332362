import React, { FC, useMemo } from 'react';
import { chakra, Tooltip } from '@chakra-ui/react';
import { useReactiveVar } from '@apollo/client';
import { match } from 'ts-pattern';
import { TFuncKey } from 'i18next';
import { useLatest } from '../../../../../../hooks';
import { capitalizeFirstLetter, toArray } from '../../../../../../utils';
import { TAssignee, TAssLocationType } from '../../../../../../types';
import { usersEntityObj } from '../../../../../Header';
import { UserEntityData } from '../../../../../../shared/graphql/shared-types';
import { IAssigneeInput } from '../../training-input.types';
import { getLocations, haveValue } from '../../common/info.helpers';
import { getLocationType } from '../../data-mapper.helper';

type UserRecordRef = React.MutableRefObject<Record<string, UserEntityData>>;

export const useUserRecordRef = (): UserRecordRef => {
  const entityUsers = useReactiveVar(usersEntityObj);

  const _usersObject = useMemo(() => {
    return toArray(entityUsers).reduce((acc, value) => {
      acc[value.eid] = value;
      return acc;
    }, {} as Record<string, UserEntityData>);
  }, [entityUsers]);

  return useLatest(_usersObject);
};

interface TransObj {
  i18nKey?: TFuncKey<'traininginfo', 'assigneeInfo' | 'assigneeChangeInfo'>;
  values?: object;
  context?: string;
}

interface CTooltipProps {
  label: string;
}

const CustomTooltip: FC<CTooltipProps> = ({ label, children }) => {
  return (
    <Tooltip
      hasArrow
      placement='bottom-start'
      borderRadius='4px'
      padding='4px 8px'
      maxWidth='200px'
      label={label}
    >
      <chakra.span whiteSpace='nowrap' textDecoration='underline'>
        {children}
      </chakra.span>
    </Tooltip>
  );
};

export const ALLCheckType: TAssLocationType[] = ['all', 'open', 'development'];

const getRolesData = (
  userType: IAssigneeInput['assigneeUserType'],
  prefix: string,
  assignee: TAssignee,
  userRef: UserRecordRef
) => {
  const { locationIds, roles } = assignee || [];

  const locationType = getLocationType(assignee.locationType, locationIds);

  const data: TransObj[] = [];

  if (locationType && ALLCheckType.includes(locationType)) {
    data.push({
      i18nKey: 'job',
      values: { jobs: roles },
      context: `${prefix}${locationType}`,
    });
  } else {
    const { open, launching, context } = getLocations(locationIds, userRef);

    data.push({
      i18nKey: 'job',
      values: {
        jobs: roles,
        openLocations: open,
        launchingLocations: launching,
      },
      context: prefix.concat('custom_', context!),
    });
  }

  if (userType === 'oldAndNew') {
    data.push({ i18nKey: 'job', context: userType });
  }

  return data;
};

const getAuthRolesData = (
  userType: IAssigneeInput['assigneeUserType'],
  prefix: string,
  assignee: TAssignee,
  userRef: UserRecordRef
) => {
  const { locationIds, authRoles } = assignee || [];

  const locationType = getLocationType(assignee.locationType, locationIds);

  const data: TransObj[] = [];

  if (locationType && ALLCheckType.includes(locationType)) {
    data.push({
      i18nKey: 'role',
      values: {
        roles: authRoles.map((r) =>
          capitalizeFirstLetter(r)
            .split(/(?=[A-Z])/)
            .join(' ')
        ),
      },
      context: `${prefix}${locationType}`,
    });
  } else {
    const { open, launching, context } = getLocations(locationIds, userRef);

    data.push({
      i18nKey: 'role',
      values: {
        roles: authRoles.map((r) =>
          capitalizeFirstLetter(r)
            .split(/(?=[A-Z])/)
            .join(' ')
        ),
        openLocations: open,
        launchingLocations: launching,
      },
      context: prefix.concat('custom_', context!),
    });
  }

  if (userType === 'oldAndNew') {
    data.push({ i18nKey: 'role', context: userType });
  }

  return data;
};

export const getTransObject = (
  values: Partial<IAssigneeInput>,
  userRef: UserRecordRef
): TransObj | TransObj[] | undefined => {
  const userType = values?.assigneeUserType;

  if (!userType) {
    return {
      i18nKey: 'placeholder',
      context: values?.assigneeUserType,
    };
  }

  const prefix = userType === 'new' ? 'new_' : '';

  const transObjects = values?.assignees
    ?.flatMap((assignee) => {
      return match<TAssignee, TransObj | TransObj[] | undefined>(assignee)
        .with({ type: 'user', userIds: haveValue }, () => {
          const _users = toArray(assignee.userIds).map(
            (userId) => userRef.current[userId]?.name
          );
          const _more = _users.slice(3);
          const haveMore = _more?.length > 1;
          return {
            i18nKey: 'user',
            values: {
              users: haveMore
                ? _users.slice(0, 3).join(', ')
                : _users.slice(0, 4),
              more: _more.length,
            },
            context: haveMore ? 'more' : undefined,
            components: {
              more: <CustomTooltip label={_more.join(', ')} />,
            },
          };
        })
        .with(
          { type: 'location' },
          (_value) => {
            if (!_value.locationType || _value.locationType === 'custom') {
              return toArray(_value?.locationIds).length > 0;
            }
            return true;
          },
          () => {
            if (assignee?.roles?.length) {
              return getRolesData(userType, prefix, assignee, userRef);
            }

            if (assignee?.authRoles?.length) {
              return getAuthRolesData(userType, prefix, assignee, userRef);
            }

            const data: TransObj[] = [];

            if (
              assignee?.locationType &&
              ALLCheckType.includes(assignee?.locationType)
            ) {
              data.push({
                i18nKey: 'location',
                context: `${prefix}${assignee?.locationType}`,
              });
            } else {
              const { open, launching, context } = getLocations(
                assignee?.locationIds,
                userRef
              );

              data.push({
                i18nKey: 'location',
                values: {
                  openLocations: open,
                  launchingLocations: launching,
                },
                context: prefix.concat('custom_', context!),
              });
            }

            if (userType === 'oldAndNew') {
              data.push({
                i18nKey: 'location',
                context: userType,
              });
            }

            return data;
          }
        )
        .with(
          { type: 'role' },
          (_value) => _value.roles?.length,
          () => {
            return getRolesData(userType, prefix, assignee, userRef);
          }
        )
        .with(
          { type: 'authRole' },
          (_value) => _value.authRoles?.length,
          () => {
            return getAuthRolesData(userType, prefix, assignee, userRef);
          }
        )
        .otherwise(() => undefined);
    })
    .filter(Boolean) as TransObj[];

  if (transObjects?.length) {
    return transObjects;
  }

  return {
    i18nKey: 'placeholder',
    context: values?.assigneeUserType,
  };
};
