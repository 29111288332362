import React, { ChangeEvent, FC } from 'react';
import { Box, Checkbox, Flex } from '@chakra-ui/react';
import { Controller } from 'react-hook-form';
import { MFilterInput } from './member-filter.types';

interface IProps {
  name:
    | `assignees.${number}.memberFilter.byRole.selectAll`
    | `assignees.${number}.memberFilter.byLocation.selectAll`;
  allItemChecked?: boolean;
  isIndeterminate?: boolean;
  onChange?: (
    event: ChangeEvent<HTMLInputElement>,
    callback: CallableFunction
  ) => void;
}

const FilterSelectAll: FC<IProps> = ({
  name,
  allItemChecked,
  isIndeterminate,
  onChange,
}) => {
  return (
    <Flex paddingInline='12px' height='48px' minHeight='48px' align='center'>
      <Box
        flex={1}
        fontSize='13px'
        fontWeight='600'
        color='#6F767E'
        textTransform='uppercase'
      >
        Select all
      </Box>
      <Controller<
        MFilterInput,
        | `assignees.${number}.memberFilter.byRole.selectAll`
        | `assignees.${number}.memberFilter.byLocation.selectAll`
      >
        name={name}
        defaultValue={false}
        render={({ field }) => (
          <Checkbox
            value='all'
            size='lg'
            isChecked={field.value && allItemChecked}
            isIndeterminate={field.value && isIndeterminate}
            onChange={(event) => onChange?.(event, field.onChange)}
          />
        )}
      />
    </Flex>
  );
};

export default FilterSelectAll;
