import { ApolloProvider, useApolloClient } from '@apollo/client';
import { ModalCloseButton } from '@chakra-ui/react';
import { HeaderColors } from 'configs';
import { useCallback, useRef } from 'react';
import { AppRouter } from 'routes';
import { BoxHeader, UseConfirm, useConfirm } from 'ui-components';
import MakeLiveModal from './MakeLiveModal';
import MoveToPrelaunchModal from './MoveToPrelaunchModal';
interface IProps {}

const useMoveToPrelaunch = () => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();
  const apolloClient = useApolloClient();

  return useCallback(
    ({
      locationId,
      onPreLaunchModalClose,
      locationName,
    }: {
      locationId: string;
      onPreLaunchModalClose: () => void;
      locationName: string;
    }) => {
      if (confirmRef.current) {
        confirmRef.current.destroy();
      }
      confirmRef.current = confirm({
        Wrapper: ({ children }: any) => {
          return (
            <ApolloProvider client={apolloClient}>
              <AppRouter>{children}</AppRouter>
            </ApolloProvider>
          );
        },
        size: '2xl',
        title: (
          <>
            <BoxHeader
              color={HeaderColors.Purple}
              title='Confirm moving location to pre-launch?'
            />
            <ModalCloseButton
              top='1.5rem'
              right='1.5rem'
              fontSize='14px'
              color='#6F767E'
              padding='.8rem'
              borderRadius='50%'
            />
          </>
        ),
        content: (
          <MoveToPrelaunchModal
            locationId={locationId}
            onPreLaunchModalClose={onPreLaunchModalClose}
            locationName={locationName}
          />
        ),
        contentProps: {
          borderRadius: '12px',
          p: '2rem 2.5rem',
        },
        headerProps: {
          p: '0px',
        },
        bodyProps: {
          p: '0px',
        },
        isCentered: true,
        footer: null,
      });
    },
    [confirm, apolloClient]
  );
};

useMoveToPrelaunch.displayName =
  'displayName:pages/Locations/AddLocation/useMoveToPrelaunch.tsx';

export default useMoveToPrelaunch;
