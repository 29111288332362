import { useQuery, useReactiveVar } from '@apollo/client';
import {
  Box,
  Center,
  Checkbox,
  CheckboxGroup,
  Flex,
  Image,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  useModalContext,
} from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import { faFilter } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TRAINING_PATH_V2 } from 'appRoutes';
import { Button, SearchInput } from 'atoms';
import { HeaderColors } from 'configs';
import { useUserDataSelector } from 'hooks';
import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import EmptyState from 'sub-components/EmptyState';
import { usersEntityObj } from 'sub-components/Header';
import Loader from 'sub-components/Loader';
import { useTrainingCategories } from 'sub-components/training-v2/create/components/TrainingPathDetailsSection/training-category/useTrainingCategories';
import { BoxHeader } from 'ui-components';
import { getImage } from 'utils';
import { GET_PENDING_EVALUATION } from '../../Tracking/query/tracking.graphql';
import PendingUserList from './PendingUserList';

interface IProps {
  refetchTrackingAnalytics?: () => void;
  setPendingEvalCount: (count: number) => void;
}

const PendingEvaluation: FC<IProps> = ({
  refetchTrackingAnalytics,
  setPendingEvalCount,
}) => {
  const { onClose } = useModalContext();

  const {
    data: pendingEvaluation,
    loading,
    refetch: refetchPendingEvaluation,
  } = useQuery(GET_PENDING_EVALUATION, {
    onCompleted(data) {
      const pendingEvalCount =
        data?.TpSessionsPendingEvaluation?.map(
          (session) => session.userIds
        ).flat().length || 0;

      setPendingEvalCount(pendingEvalCount);
    },
  });
  const categories = useTrainingCategories();
  const usersEntityData = useReactiveVar(usersEntityObj);
  const [formattedSessions, setFormattedSessions] = useState([]);
  const [filteredSessions, setFilteredSessions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [createdBy, setCreatedBy] = useState<'Me' | 'Others' | 'Both'>('Both');
  const { loggedInUserEid } = useUserDataSelector((state) => ({
    loggedInUserEid: state?.eid,
  }));
  const history = useHistory();

  const formattedPendingEvaluation = () => {
    const formattedData = pendingEvaluation?.TpSessionsPendingEvaluation?.map(
      (session) => {
        const users = usersEntityData.filter((user) =>
          session.userIds.includes(user.eid)
        );
        return {
          ...session,
          users,
        };
      }
    );

    setFormattedSessions(formattedData);
  };

  const getTpCategoryName = (categoryId: string) => {
    const category = categories?.categories.find(
      (category) => category.eid === categoryId
    );
    return category?.name;
  };

  useEffect(() => {
    if (!searchTerm && createdBy === 'Both') {
      setFilteredSessions(formattedSessions);
      return;
    }

    const lowerTerm = searchTerm.toLowerCase();

    const filteredData = formattedSessions
      ?.filter((session) => {
        if (
          (createdBy === 'Me' && session.createdBy !== loggedInUserEid) ||
          (createdBy === 'Others' && session.createdBy === loggedInUserEid)
        ) {
          return false;
        }
        return true;
      })
      .map((session) => {
        const matchedUsers = session.users.filter((user) =>
          user.name.toLowerCase().includes(lowerTerm)
        );

        const matchesTraining = session.name.toLowerCase().includes(lowerTerm);

        if (matchesTraining) {
          return session;
        } else if (matchedUsers.length > 0) {
          return { ...session, users: matchedUsers };
        }

        return null;
      })
      .filter(Boolean);

    setFilteredSessions(filteredData);
  }, [searchTerm, formattedSessions, createdBy, loggedInUserEid]);

  useEffect(() => {
    if (!pendingEvaluation?.TpSessionsPendingEvaluation) return;

    formattedPendingEvaluation();
  }, [pendingEvaluation]);

  const handleChange = (value: string) => {
    setCreatedBy((prev) =>
      prev === value ? 'Both' : (value as 'Me' | 'Others')
    );
  };

  const onEvaluateComplete = () => {
    refetchTrackingAnalytics?.();
    refetchPendingEvaluation();
    onClose();
  };

  return (
    <Box marginTop='1rem' maxHeight='60vh' overflowY='scroll'>
      <Flex gap={1} position='sticky' top='0' zIndex={100}>
        <SearchInput
          placeholder='Search members, training path'
          hideShortcuts
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Popover placement='bottom-end'>
          <PopoverTrigger>
            <Button variant='unstyled' background='white' position='relative'>
              <FontAwesomeIcon icon={faFilter as IconProp} color='#6F767E' />
              {createdBy === 'Me' || createdBy === 'Others' ? (
                <Center
                  width='14px'
                  height='14px'
                  background='#2A85FF'
                  borderRadius='100%'
                  fontSize='9px'
                  color='white'
                  padding='4px'
                  position='absolute'
                  top='5px'
                  right='5px'
                >
                  1
                </Center>
              ) : null}
            </Button>
          </PopoverTrigger>
          <PopoverContent
            boxShadow='md'
            padding={5}
            borderRadius='12px'
            width='20rem'
          >
            <Flex justifyContent='space-between'>
              <BoxHeader color={HeaderColors.Purple} title='Filter' />
              <Button
                color='#2A85FF'
                variant='unstyled'
                onClick={() => setCreatedBy('Both')}
              >
                Clear All
              </Button>
            </Flex>
            <CheckboxGroup value={createdBy !== 'Both' ? [createdBy] : []}>
              <Flex
                flexDirection='column'
                color='#1A1D1F'
                fontSize='14px'
                marginTop='.4rem'
                gap='2px'
              >
                <Checkbox
                  value='Me'
                  padding='10px 12px'
                  borderRadius='8px'
                  width='100%'
                  isChecked={createdBy === 'Me'}
                  onChange={() => handleChange('Me')}
                >
                  Created by me
                </Checkbox>
                <Checkbox
                  value='Others'
                  padding='10px 12px'
                  borderRadius='8px'
                  width='100%'
                  isChecked={createdBy === 'Others'}
                  onChange={() => handleChange('Others')}
                >
                  Created by others
                </Checkbox>
              </Flex>
            </CheckboxGroup>
          </PopoverContent>
        </Popover>
      </Flex>

      <Flex marginTop={6} flexDirection='column' gap={6}>
        {filteredSessions.length > 0 ? (
          filteredSessions.map((session) => (
            <Flex flexDirection='column' flex={1} gap={3}>
              <Flex
                background='#8E59FF26'
                padding='8px 12px'
                borderRadius='8px'
                gap={3}
                alignItems='center'
                flex={1}
                paddingRight='16px'
              >
                <Flex gap={3} flex={1}>
                  <Image
                    src={getImage(session.thumbnail, session.name)}
                    width='12'
                    height='12'
                    borderRadius='8px'
                  />
                  <Flex flexDirection='column' gap={1}>
                    <Text as='span' color='#111315' fontWeight={600}>
                      {`Path: ${session.name}`}
                    </Text>
                    <Box
                      background='#8E59FF33'
                      color='#8E59FF'
                      padding='4px 8px'
                      width='fit-content'
                      borderRadius='4px'
                      fontWeight={500}
                      fontSize='12px'
                    >
                      {getTpCategoryName(session.category)}
                    </Box>
                  </Flex>
                </Flex>

                <Box
                  cursor='pointer'
                  onClick={() => {
                    window.open(
                      `${TRAINING_PATH_V2}/play/preview/${session?.trainingId}/${session?.sessionId}`
                    );
                  }}
                >
                  <FontAwesomeIcon
                    icon={faArrowUpRightFromSquare as IconProp}
                    fontSize='20px'
                    color='#2A85FF'
                  />
                </Box>
              </Flex>
              <PendingUserList
                users={session.users}
                sessionId={session.sessionId}
                onEvaluateComplete={onEvaluateComplete}
              />
            </Flex>
          ))
        ) : (
          <>
            {loading ? (
              <Center width='100%' height='40vh'>
                <Loader />
              </Center>
            ) : (
              <EmptyState title='No data found' image='Search' />
            )}
          </>
        )}
      </Flex>
    </Box>
  );
};

PendingEvaluation.displayName =
  'displayName:sub-components/training-v2/dashboard/components/myTrainings/pendingEvaluation/PendingEvaluation.tsx';

export default PendingEvaluation;
