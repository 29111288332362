import React, { FC, useMemo, useState } from 'react';
import { Box, Button, Flex, ModalCloseButton } from '@chakra-ui/react';
import { useQuery, useReactiveVar } from '@apollo/client';
import moment from 'moment';
import Editor from 'delightree-editor';
import { MdOutlineVisibility } from 'react-icons/md';
import { MdOutlineVisibilityOff } from 'react-icons/md';
import { ReactComponent as EditDarkIcon } from 'assets/images/edit-dark.svg';

import { IconImage } from '../../../../../../../ui-components';

import {
  CONVERSATION_LOGS_FIND,
  LogEditResponse,
} from '../create-logs/create-log.graphql';
import { OccurredDateCell } from '../components';
import LogViewSkeleton from './LogViewSkeleton';
import { usersEntityObj } from 'sub-components/Header';
import { useCreateLogs } from '../create-logs/useCreateLogs';
import { AmplitudeEvent, deployEvent } from 'shared';
import { AuthRole } from 'sop-commons/src/client';
import { eventBus } from 'shared/eventEmit';

interface IProps {
  noteId: string;
  locationId?: string;
  userAuthRole?: string;
  loggedInUserId?: string;
}

const ConversationLogView: FC<IProps> = ({
  noteId,
  locationId,
  userAuthRole,
  loggedInUserId,
}) => {
  const [wasDirty, setWasDirty] = useState<boolean>(false);
  const createNotes = useCreateLogs();
  const entityList = useReactiveVar(usersEntityObj);

  const locationName = useMemo(() => {
    const name = entityList?.find((l) => l.eid === locationId)?.name;
    return name ? name : null;
  }, []);

  const {
    data,
    loading,
    refetch: refetchNoteData,
  } = useQuery<LogEditResponse>(CONVERSATION_LOGS_FIND, {
    fetchPolicy: 'network-only',
    variables: {
      eid: noteId,
    },
  });

  const noteData = data?.conversationLogsFindById;

  if (loading || !noteData) {
    return <LogViewSkeleton />;
  }

  const getEditAccess = (createdById?: string) => {
    if (userAuthRole === AuthRole?.SUPER_ADMIN) {
      return true;
    } else if (loggedInUserId === createdById) {
      return true;
    } else {
      return false;
    }
  };

  const canEdit = noteData
    ? getEditAccess(noteData?.createdByUser?.eid)
    : false;

  const visibleToLocationOwner = noteData && noteData?.showLO;

  const handleModalClose = (wasDirty: boolean) => {
    if (wasDirty) {
      eventBus.emit('callApi');
    }
  };

  return (
    <Flex flexDir='column' minH='calc(100vh - 12rem)' gap='12px'>
      <ModalCloseButton
        onClick={() => {
          handleModalClose(wasDirty);
        }}
        top={8}
        right={8}
      />

      {canEdit && (
        <Button
          leftIcon={<EditDarkIcon style={{ height: '16px' }} />}
          color={'#1A1D1F'}
          border={'2px solid #EFEFEF'}
          backgroundColor={'#FCFCFC'}
          fontSize={'12px'}
          maxH={'26px'}
          right={20}
          top={9}
          position={'absolute'}
          iconSpacing={'0px'}
          pl={'5px'}
          onClick={() => {
            createNotes({
              locationId: locationId,
              noteId: noteData?.eid,
              refetchNoteData: refetchNoteData,
              onModalClose: (wasDirty) => {
                setWasDirty?.(wasDirty);
              },
              onSuccess: () => {
                deployEvent(AmplitudeEvent.LOCATION_CONV_EDITED);
              },
            });
          }}
        >
          Edit
        </Button>
      )}

      <Flex gap={3}>
        <OccurredDateCell
          value={noteData.occurrenceDate}
          dateFormat='DD MMM, hh:mm A'
        />

        {locationName && (
          <Flex
            width='max-content'
            align='center'
            fontSize='13px'
            fontWeight='500'
            color='#8E59FF'
            bg='#CABDFF66'
            padding='6px 10px'
            borderRadius='6px'
            gap='6px'
          >
            {visibleToLocationOwner ? (
              <MdOutlineVisibility fontSize={'16px'} />
            ) : (
              <MdOutlineVisibilityOff fontSize={'16px'} />
            )}
            Location Owners {`(${locationName})`}
          </Flex>
        )}
      </Flex>

      <Box fontSize='24px' fontWeight={700} color='#272B30'>
        {noteData.subject}
      </Box>
      <Flex flexDir='column' gap='10px' paddingBlock={3}>
        <Flex align='center' gap={2} fontSize='14px' fontWeight={500}>
          <Box color='#979EA6'>Type of note:</Box>
          <Box color='#1A1D1F'>{noteData.category}</Box>
        </Flex>
        <Flex align='center' gap={2} fontSize='14px' fontWeight={500}>
          <Box color='#979EA6'>Created by:</Box>
          <Flex
            align='center'
            color='#1A1D1F'
            gap={2}
            bg='#B1E5FC80'
            padding='4px 10px 4px 6px'
            borderRadius='5px'
          >
            <IconImage
              name={noteData.createdByUser.name}
              thumbnail={noteData.createdByUser.profilePic}
              boxSize={18}
              borderRadius='5px'
            />
            <span>
              {noteData.createdByUser?.name}, {noteData.createdByUser?.role}
            </span>
          </Flex>
          <Box color='#979EA6'>on</Box>
          <Box color='#1A1D1F'>
            {moment(noteData.createdAt).format('DD MMMM')}
          </Box>
        </Flex>
      </Flex>
      <Editor readonly value={noteData.content} />
    </Flex>
  );
};

export default ConversationLogView;
