import React, { FC } from 'react';
import { Center, Flex, Spacer } from '@chakra-ui/react';
import { DragHandleIcon } from '@chakra-ui/icons';
import { Controller, useController } from 'react-hook-form';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { BaseOptionContainer, QuizIconButton, QuizTextInput } from '../common';
import { ReactComponent as DeleteIcon } from 'assets/images/deleteBin.svg';
import { ReactComponent as TaskIcon } from 'assets/images/sidebar/task.svg';
import { QuizQFormInput } from '../quiz-form.types';
import { ThumbnailAddButton, ThumbnailView } from '../thumbnail-input';

interface IProps {
  optionIndex: number;
  totalLength: number;
  optionId: string;
  onRemoveClick?: () => void;
}

const SequenceOption: FC<IProps> = ({
  optionIndex,
  totalLength,
  optionId,
  onRemoveClick,
}) => {
  const { field } = useController<
    QuizQFormInput,
    `sequence.${number}.thumbnail`
  >({
    name: `sequence.${optionIndex}.thumbnail`,
  });

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef,
  } = useSortable({ id: optionId, data: { index: optionIndex } });

  return (
    <BaseOptionContainer
      optionIndex={optionIndex}
      baseKey='sequence'
      fieldKeys={['text']}
      ref={setNodeRef}
      transform={CSS.Translate.toString(transform)}
      transition={transition}
    >
      <Flex
        flexDir='column'
        borderTopRadius='10px'
        borderBottom='2px solid #EFEFEF'
        height='122px'
      >
        <Flex
          justify='space-between'
          padding='6px'
          gap='6px'
          borderTopRadius='10px'
          align='center'
        >
          {field.value ? null : <ThumbnailAddButton {...field} />}

          <QuizIconButton
            aria-label='delete-image'
            borderRadius='4px'
            borderWidth='1px'
            icon={<DeleteIcon width={12} height={12} />}
            isDisabled={totalLength <= 2}
            onClick={onRemoveClick}
          />

          <Spacer />

          <Center {...attributes} {...listeners} ref={setActivatorNodeRef}>
            <DragHandleIcon boxSize='12px' color='#2A85FF' />
          </Center>
        </Flex>
        <Flex flex={1} padding='12px 16px' overflow='hidden' gap={1}>
          {field.value ? (
            <ThumbnailView
              boxSize={60}
              alignSelf='center'
              {...field}
              value={field.value}
            />
          ) : null}

          <Controller<QuizQFormInput, `sequence.${number}.text`>
            name={`sequence.${optionIndex}.text`}
            rules={{
              required: 'Option title is required',
              validate: (value) => {
                if (!value?.trim()) {
                  return 'Enter valid option title';
                }
              },
            }}
            render={({ field }) => {
              return (
                <QuizTextInput
                  placeholder={`Type ${optionIndex + 1}ˢᵗ item here`}
                  {...field}
                />
              );
            }}
          />
        </Flex>
      </Flex>
      <Center
        borderBottomRadius='10px'
        borderTop='2px solid #EFEFEF'
        height='32px'
        fontSize='12px'
        fontWeight='500'
        color='#6F767E'
      >
        {optionIndex + 1}
      </Center>
    </BaseOptionContainer>
  );
};

export default SequenceOption;
