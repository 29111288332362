import React, { FC, useEffect, useMemo } from 'react';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { cloneDeep } from '@apollo/client/utilities';
import debounce from 'lodash.debounce';
import { useUserDataSelector } from '../../hooks';

import { BaseSearchModal, SearchDataList } from '../../modal';
import { foldersListVar } from '../../pages/Chapters/ChapterContainer/ChapterContainer';
import { CustomCategoryEntity } from '../../pages/Chapters/ChapterHeader/components/BulkMoveContainer';
import SearchList from '../SearchList';
import RecentSearchList from '../RecentSearchList';
import { GET_SEARCH_RESULTS, SearchResponse } from './search-modal.graphql';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';

interface IProps {
  searchQuery?: string;
  onSelectedItem?: (item: BaseSearchModal, isAddRecentSearch: boolean) => void;
}

const SearchListContainer: FC<IProps> = ({ searchQuery, onSelectedItem }) => {
  const categoriesList = useReactiveVar(foldersListVar);
  const userId = useUserDataSelector((state) => state?.eid);

  const [getSearchResults, { loading, data: rawData }] =
    useLazyQuery<SearchResponse>(GET_SEARCH_RESULTS, {
      fetchPolicy: 'network-only',
    });

  const debouncedFetchData = debounce((query) => {
    deployEvent(AmplitudeEventNames.STANDARD_SEARCH, {
      event: {
        search_funnel_id: 3,
        search_keyword: query?.trim(),
      },
    });
    getSearchResults({
      variables: {
        input: {
          query: query.trim(),
        },
      },
    });
  }, 500);

  useEffect(() => {
    if (searchQuery) {
      debouncedFetchData(searchQuery);
    }
  }, [searchQuery]);

  const searchList = useMemo(() => {
    let _searchItems = cloneDeep(rawData?.Search) || [];
    _searchItems.forEach((item) => {
      if (item?.type === 'category' && !item?.meta?.categoryId) {
        item.meta.categoryId = item.eid;
      }
    });
    if (
      categoriesList &&
      categoriesList?.length > 0 &&
      _searchItems?.length > 0
    ) {
      // const updatedSearchData = mapSearchDataToFolderList(
      //   _searchItems,
      //   categoriesList
      // );
      let allFoldersList: CustomCategoryEntity[] = [];
      categoriesList?.map((list) => {
        if (list?.children?.length > 0) {
          list?.children?.map((child) => {
            allFoldersList?.push({
              ...child,
              parentFolderId: list?.eid,
              parentFolderName: list?.name,
              type: 'subFolder',
            });
          });
          allFoldersList?.push({
            ...list,
            parentFolderId: undefined,
            parentFolderName: undefined,
            type: 'folder',
          });
        } else {
          allFoldersList?.push({
            ...list,
            parentFolderId: undefined,
            parentFolderName: undefined,
            type: 'folder',
          });
        }
      });
      _searchItems?.map((item) => {
        let flag = allFoldersList?.filter(
          (folder) => folder?.eid === item?.meta?.categoryId
        )?.[0];
        if (flag) {
          item.meta.parentId = flag?.parentFolderId;
          item.meta.parentName = flag?.parentFolderName;
        }
      });
      return new SearchDataList(
        cloneDeep(_searchItems?.filter((dt) => dt.eid !== userId))
      )?.data;
    }
    return [];
    // return rawData?.Search.filter((dt: any) => dt.eid !== user.eid) || [];
  }, [rawData?.Search, userId]);

  return (
    <React.Fragment>
      {searchQuery && (
        <SearchList
          searchList={searchList}
          loading={loading}
          onSelectedItem={(item) => {
            deployEvent(AmplitudeEventNames.STANDARD_SEARCH_RESPONSE_CLICK, {
              event: {
                search_funnel_id: 3,
              },
            });
            onSelectedItem?.(item, true);
          }}
        />
      )}
      {!searchQuery && (
        <RecentSearchList
          onItemClick={(item) => {
            deployEvent(AmplitudeEventNames.STANDARD_SEARCH_RESPONSE_CLICK, {
              event: {
                search_funnel_id: 3,
              },
            });
            onSelectedItem?.(item, false);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default SearchListContainer;
