import React, { FC, useMemo } from 'react';
interface RoleProps {
  authRole: string;
}
const RoleFormatter: FC<RoleProps> = ({ authRole }) => {
  const calcAuthRole = useMemo(() => {
    return authRole ? authRole.split(/(?=[A-Z])/).join(' ') : '';
  }, [authRole]);

  return <span>{calcAuthRole}</span>;
};

export default RoleFormatter;
