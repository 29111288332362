import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import amplitude from 'amplitude-js';
import mixpanel from 'mixpanel-browser';
import { LocationsEntity } from 'sop-commons/src/client';
import { useUserDataSelector, useUserEntity } from '../../hooks';
import { toArray } from '../../utils';

interface LocationMapping {
  eid: string | undefined;
  name: string | undefined;
}

interface UserObject {
  eid: string;
  name: string;
  email: string;
  role: string;
  authRole: string;
  type: string;
  locations: LocationMapping[];
}

interface BusinessEntity {
  eid: string;
  analyticsEnabled?: boolean;
  name?: string;
  isDemo?: boolean;
  isActiveCustomer?: boolean;
  isChurnedCustomer?: boolean;
  isOnHoldCustomer?: boolean;
  isOnboardingCustomer?: boolean;
}

type StatusProperties = {
  [key: string]: boolean | undefined;
};

// Common fields and utilities
const STATUS_FIELDS = [
  'isActiveCustomer',
  'isChurnedCustomer',
  'isOnHoldCustomer',
  'isOnboardingCustomer',
] as const;

const ANALYTICS_SERVICES = {
  AMPLITUDE: 'amplitude',
  MIXPANEL: 'mixpanel',
  USERBACK: 'userback',
} as const;

const mapLocation = (loc: LocationsEntity): LocationMapping => {
  return { eid: loc?.eid, name: loc?.name };
};

const addStatusProperties = (
  business: BusinessEntity,
  prefix: string = ''
): StatusProperties => {
  const statusProperties: StatusProperties = {};

  STATUS_FIELDS.forEach((field) => {
    const value = business?.[field];
    if (value !== undefined && value !== null && typeof value === 'boolean') {
      statusProperties[`${prefix}${field}`] = value;
    }
  });

  return statusProperties;
};

const createUserProperties = (
  userObject: UserObject,
  business: BusinessEntity,
  analyticsEnabled: boolean | undefined,
  service: string
): Record<string, any> => {
  const prefix = service === ANALYTICS_SERVICES.MIXPANEL ? '$' : '';
  const demoKey =
    service === ANALYTICS_SERVICES.MIXPANEL ? '$AUTODEMO' : 'AUTODEMO';

  return {
    [`${prefix}name`]: userObject?.name,
    [`${prefix}email`]: userObject?.email,
    [`${prefix}role`]: userObject?.role,
    [`${prefix}authRole`]: userObject?.authRole,
    [`${prefix}companyName`]: business?.name,
    [`${prefix}isTestUser`]: !analyticsEnabled,
    [`${prefix}userType`]: userObject?.type,
    [`${prefix}locations`]: userObject?.locations,
    ...addStatusProperties(business, prefix),
    ...(business?.isDemo ? { [demoKey]: true } : {}),
  };
};

const initializeAnalyticsService = (
  service: string,
  key: string,
  userId: string,
  business: BusinessEntity,
  setInitialized: (value: boolean) => void
): void => {
  if (service === ANALYTICS_SERVICES.AMPLITUDE) {
    amplitude.getInstance().init(key, userId);
    setInitialized(true);
    sessionStorage.setItem('amplitudeInit', '1');
  } else if (service === ANALYTICS_SERVICES.MIXPANEL && userId) {
    mixpanel.init(key, {
      debug: false,
      track_pageview: true,
      persistence: 'localStorage',
    });
    mixpanel.identify(userId);
    if (business?.name && business?.eid) {
      mixpanel.set_group('company_id', business.eid);

      const groupObject = mixpanel.get_group('company_id', business?.eid);
      groupObject.set({
        businessName: business?.name,
        isDemo: business?.isDemo,
        analyticsEnabled: business?.analyticsEnabled,
        isActiveCustomer: business?.isActiveCustomer,
        isChurnedCustomer: business?.isChurnedCustomer,
        isOnHoldCustomer: business?.isOnHoldCustomer,
        isOnboardingCustomer: business?.isOnboardingCustomer,
      });
    }
    setInitialized(true);
    sessionStorage.setItem('mixPanelInit', '1');
  }
};

export const useAnalyticsSetup = (): void => {
  const pathname = useLocation().pathname;

  const userObject = useUserDataSelector((state) => ({
    eid: state.eid,
    name: state.name,
    email: state.email,
    role: state.role,
    authRole: state.authRole,
    type: state.type,
    locations: toArray(state?.locations).map(mapLocation),
  }));

  const business: BusinessEntity = useUserEntity((entity) => ({
    analyticsEnabled: entity?.analytics,
    name: entity?.name,
    eid: entity?.eid,
    isDemo: entity?.isDemo,
    isActiveCustomer: entity?.status?.isActive || false,
    isChurnedCustomer: entity?.status?.isActive || false,
    isOnHoldCustomer: entity?.status?.isActive || false,
    isOnboardingCustomer: entity?.status?.isActive || false,
  }));

  const analyticsEnabled = useUserEntity((entity) => entity?.analytics);

  const amplitudeKey = process.env?.REACT_APP_AMPLITUDE_KEY;
  const mixpanelKey = process.env?.REACT_APP_MIXPANEL_KEY;
  const isProduction = process?.env?.REACT_APP_DEPLOYMENT_TYPE === 'production';

  const [isAmplitudeInitialized, setIsAmplitudeInitialized] =
    useState<boolean>(false);
  const [isMixpanelInitialized, setIsMixpanelInitialized] =
    useState<boolean>(false);

  const checkBotFlag = (): boolean => {
    return pathname === '/create-sop' || pathname === '/search-sop';
  };

  useEffect(() => {
    Sentry.setUser({
      email: userObject?.email,
      fullName: userObject?.name,
      username: userObject?.name,
      id: userObject?.eid,
    });

    if (
      userObject?.eid &&
      window?.Userback &&
      typeof window?.Userback.identify === 'function'
    ) {
      const userbackProperties = createUserProperties(
        userObject,
        business,
        analyticsEnabled,
        ANALYTICS_SERVICES.USERBACK
      );

      window.Userback.identify(userObject.eid, userbackProperties);
    }
  }, [userObject, business, analyticsEnabled]);

  useEffect(() => {
    const shouldInitialize =
      isProduction &&
      analyticsEnabled &&
      mixpanelKey &&
      amplitudeKey &&
      !checkBotFlag();

    if (shouldInitialize) {
      if (userObject?.eid) {
        initializeAnalyticsService(
          ANALYTICS_SERVICES.AMPLITUDE,
          amplitudeKey,
          userObject.eid,
          business,
          setIsAmplitudeInitialized
        );

        initializeAnalyticsService(
          ANALYTICS_SERVICES.MIXPANEL,
          mixpanelKey,
          userObject.eid,
          business,
          setIsMixpanelInitialized
        );
      } else {
        amplitude.getInstance().init(amplitudeKey);
        setIsAmplitudeInitialized(true);
        sessionStorage.setItem('amplitudeInit', '1');
        console.warn(
          '[Mixpanel] User ID is missing. Mixpanel will not be initialized.'
        );
      }

      if (isAmplitudeInitialized) {
        const identify = new amplitude.Identify();
        const amplitudeProperties = createUserProperties(
          userObject,
          business,
          analyticsEnabled,
          ANALYTICS_SERVICES.AMPLITUDE
        );

        // Set amplitude properties
        Object.entries(amplitudeProperties).forEach(([key, value]) => {
          if (value !== null) {
            identify.set(key, value);
          }
        });

        amplitude.getInstance().identify(identify);
      }

      // Set up Mixpanel identify
      if (isMixpanelInitialized) {
        const mixpanelProperties = createUserProperties(
          userObject,
          business,
          analyticsEnabled,
          ANALYTICS_SERVICES.MIXPANEL
        );

        mixpanel?.people?.set(mixpanelProperties);
      }
    } else {
      // Reset services if they shouldn't be initialized
      if (isMixpanelInitialized) {
        mixpanel.reset();
      }
      setIsMixpanelInitialized(false);
      setIsAmplitudeInitialized(false);
      sessionStorage.setItem('amplitudeInit', '0');
      sessionStorage.setItem('mixPanelInit', '0');
    }
  }, [
    userObject,
    business,
    analyticsEnabled,
    amplitudeKey,
    mixpanelKey,
    isProduction,
  ]);

  // Track route changes
  useEffect(() => {
    const shouldTrack =
      isProduction &&
      isAmplitudeInitialized &&
      isMixpanelInitialized &&
      !checkBotFlag();

    if (shouldTrack) {
      if (isAmplitudeInitialized) {
        amplitude.getInstance().logEvent('Route Changed', { url: pathname });
      }
      if (isMixpanelInitialized) {
        mixpanel.track('Route Changed', { url: pathname });
      }
    }
  }, [pathname, isAmplitudeInitialized, isMixpanelInitialized, isProduction]);
};
