import React, { FC } from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Tooltip,
} from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Controller, useWatch } from 'react-hook-form';
import { BoxHeader } from '../../../../../../ui-components';
import { HeaderColors } from '../../../../../../configs';
import { FlexContainer } from '../../common';
import { IAssigneeInput, TFormInput } from '../../training-input.types';
import { TrainingSupervisorSelect } from './supervisor-select';
import { useSupervisorRequired } from './useSupervisorRequired';

interface IProps {
  disabled?: boolean;
}

const TrainingSupervisor: FC<IProps> = ({ disabled }) => {
  const hasSkillVerifier = useWatch<TFormInput, 'hasSkillVerifier'>({
    name: 'hasSkillVerifier',
  });

  const isRequired = useSupervisorRequired();

  if (!hasSkillVerifier) {
    return null;
  }

  return (
    <FlexContainer flexDir='column' gap={5}>
      <Flex align='center' gap={1}>
        <BoxHeader
          title='Who will be supervising this training?'
          color={HeaderColors.Purple}
        />
        <Box
          fontSize='13px'
          fontWeight={600}
          color='#6F767E'
          marginTop='6px'
          hidden={isRequired}
        >
          (Optional)
        </Box>
      </Flex>

      <Controller<IAssigneeInput, 'supervisors'>
        name='supervisors'
        disabled={disabled}
        rules={{
          required: {
            value: isRequired,
            message: 'Select members for supervisors',
          },
        }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid}>
            <FormLabel color='#6F767E'>
              Select supervisor:{' '}
              <Tooltip
                maxWidth='320px'
                paddingX='8px'
                paddingY='6px'
                borderRadius='4px'
                placement='top-start'
                label='Both the default and selected supervisors will work together to track and evaluate the assignees.'
              >
                <InfoOutlineIcon fontSize='13px' />
              </Tooltip>
            </FormLabel>
            <TrainingSupervisorSelect {...field} />
            <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
          </FormControl>
        )}
      />
    </FlexContainer>
  );
};

export default TrainingSupervisor;
