import { FC } from 'react';
import { PathTable } from './TrackPathDashboard/bottomSection';
import { Box, Flex } from '@chakra-ui/react';
import { useTrainingContext } from './TrainingTableContainer';
import EmptyState from 'sub-components/EmptyState';

const TrackPathTableContainer: FC = () => {
  const { trackingTpPaginationError } = useTrainingContext();

  if (trackingTpPaginationError) {
    return (
      <EmptyState
        image='Report'
        title={`Error while loading data, please refresh the page!`}
        imageSize='300px'
      />
    );
  }

  return (
    <Flex direction='column' width='100%'>
      <Box width='100%'>
        <PathTable />
      </Box>
    </Flex>
  );
};

TrackPathTableContainer.displayName =
  'displayName:sub-components/training-v2/dashboard/components/TrackPathTableContainer';

export default TrackPathTableContainer;
