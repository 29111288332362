import React, { FC, useMemo, useState } from 'react';
import {
  Flex,
  useBoolean,
  useCheckboxGroupContext,
  useToast,
} from '@chakra-ui/react';
import { useMutation } from '@apollo/client';

import { ActionButton } from '../../../../../ui-components';
import { FormCategory } from '../../../../../types';
import { toArray } from '../../../../../utils';
import { useFormCategories } from '../../../common';

import { useDeleteContext } from '../context';
import FormCategorySelect from './FormCategorySelect';
import { MOVE_FORM_CATEGORY, moveFormListGraphQL } from './move-form.graphql';

interface IProps {
  onMoveSuccess: () => void;
}

const MoveAction: FC<IProps> = ({ onMoveSuccess }) => {
  const { value } = useCheckboxGroupContext();
  const { actionHandler, totalFormCount, category } = useDeleteContext();
  const [newCategory, setCategory] = useState<FormCategory>();
  const [formMoving, action] = useBoolean(false);

  const toast = useToast({
    position: 'top-right',
    isClosable: true,
    duration: 3000,
  });

  const { data, loading: loadingCategory } = useFormCategories({
    fetchPolicy: 'network-only',
  });

  const noValue = !value?.length;

  const [moveForms] = useMutation(MOVE_FORM_CATEGORY, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: moveFormListGraphQL,
        variables: {
          page: 1,
          perPage: totalFormCount,
          filter: {
            categoryId: category?.eid,
          },
        },
      },
    ],
    onCompleted: () => {
      toast({
        status: 'success',
        title: 'Success',
        description: `Form successfully moved to ${newCategory?.name}.`,
      });
    },
    onError: () => {
      action.off();
      toast({
        status: 'error',
        title: 'Error',
        description: 'Failed to move the form to the new category',
      });
    },
  });

  const onMoveClick = async () => {
    action.on();
    await moveForms({
      variables: {
        categoryId: newCategory?.eid,
        formIds: value,
      },
    });
    action.off();
    onMoveSuccess?.();
  };

  const categoryList = useMemo(() => {
    return toArray(data?.EntityFormCategories).filter(
      (c) => c.eid !== category?.eid
    );
  }, [data?.EntityFormCategories, category]);

  return (
    <Flex
      bg='white'
      position='sticky'
      bottom={0}
      pb={2}
      gap={3}
      opacity={noValue ? 0.6 : 1}
    >
      <FormCategorySelect
        categories={categoryList}
        isLoading={loadingCategory}
        isDisabled={noValue}
        value={newCategory as never}
        onChange={(newValue) => setCategory(newValue as never)}
        onMoveClick={onMoveClick}
        formMoving={formMoving}
      />

      <ActionButton
        flex={1}
        size='lg'
        fontSize='15px'
        colorScheme='red'
        isDisabled={noValue}
        actionFn={() =>
          actionHandler?.('deleteConfirm', {
            formIds: value,
          })
        }
      >
        Delete forms
      </ActionButton>
    </Flex>
  );
};

export default MoveAction;
