import React, { FC } from 'react';
import { Center, Fade, Flex } from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Button, FlexCard } from '../../../../../../../atoms';
import { BoxHeader } from '../../../../../../../ui-components';
import { HeaderColors } from '../../../../../../../configs';
import { useSafeState } from '../../../../../../../hooks';
import { AppRoute, useHistory } from '../../../../../../../routes';
import LinkGoogleImages from './LinkGoogleImages';
import {
  GalleryImageList,
  GalleryViewType,
} from '../../../../../Gallery/components';
import { useControl } from './layers';
import { Loader } from 'sub-components';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';

const ImageGallery: FC = () => {
  const { galleryImages, loading, viewType, handleViewType, isAuditEnabled } =
    useControl();
  const history = useHistory();

  const render = () => {
    if (loading) {
      return (
        <Center h='50vh'>
          <Loader />
        </Center>
      );
    }

    return (
      <>
        {isAuditEnabled && (
          <GalleryViewType value={viewType} onChange={handleViewType} />
        )}
        {galleryImages?.length === 0 ? (
          <LinkGoogleImages hideBtn />
        ) : (
          <Fade in={true}>
            <GalleryImageList colsNo={3} images={galleryImages?.slice(0, 6)} />
          </Fade>
        )}
      </>
    );
  };

  return (
    <FlexCard flexDir='column' gap={4}>
      <Flex justify='space-between'>
        <BoxHeader title='Image gallery' color={HeaderColors.Blue} />
        {galleryImages?.length > 0 && (
          <Button
            colorScheme='blue'
            variant='link'
            rightIcon={<ArrowForwardIcon />}
            onClick={() => {
              const tabName =
                viewType === 'customer' ? 'Customer Upload' : 'Internal Images';
              deployEvent(
                AmplitudeEventNames.VIEW_MORE_IMAGE_GALLERY_DASHBOARD,
                {
                  event: {
                    tab_name: tabName,
                  },
                }
              );
              history.push({
                pathname: AppRoute.GALLERY,
              });
            }}
          >
            View more
          </Button>
        )}
      </Flex>

      {render()}
    </FlexCard>
  );
};

ImageGallery.displayName = 'sc/n/NB/c/LC/c/IG/ImageGallery';

export default ImageGallery;
