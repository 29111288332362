import React, { ChangeEvent, FC, useMemo } from 'react';
import { Box, Checkbox, CheckboxGroup, Divider, Flex } from '@chakra-ui/react';
import { useController } from 'react-hook-form';
import { useUserEntity } from '../../../../../../../../hooks';
import { sxScrollStyle } from '../../common.styles';
import { MFilterInput } from './member-filter.types';
import FilterSelectAll from './FilterSelectAll';

interface IProps {
  index: number;
}

const ByRoleSelect: FC<IProps> = ({ index }) => {
  const jobs = useUserEntity((entity) => entity?.roles || []);

  const { field } = useController<
    MFilterInput,
    `assignees.${number}.memberFilter.byRole.values`
  >({
    name: `assignees.${index}.memberFilter.byRole.values`,
    defaultValue: [],
  });

  const onSelectAll = (
    event: ChangeEvent<HTMLInputElement>,
    callback: CallableFunction
  ) => {
    if (event.target.checked) {
      field.onChange(jobs.map((j) => j.name));
    } else {
      field.onChange([]);
    }
    callback(event);
  };

  const [allItemChecked, isIndeterminate] = useMemo(() => {
    const all = jobs?.length === field.value?.length;
    const some = !!field.value?.length && field.value?.length < jobs?.length;
    return [all && !!jobs?.length, some && !all];
  }, [jobs, field.value]);

  return (
    <Flex flexDir='column' gap={1} overflow='auto' sx={sxScrollStyle}>
      <FilterSelectAll
        name={`assignees.${index}.memberFilter.byRole.selectAll`}
        allItemChecked={allItemChecked}
        isIndeterminate={isIndeterminate}
        onChange={onSelectAll}
      />

      <CheckboxGroup value={field.value} onChange={field.onChange}>
        {jobs.map((item) => {
          return (
            <React.Fragment key={item.eid}>
              <Flex
                gap={3}
                height='48px'
                minHeight='48px'
                alignItems='center'
                paddingInline='12px'
                borderRadius='12px'
                // cursor='pointer'
                _hover={{ bg: '#F4F4F3' }}
              >
                <Box
                  flex={1}
                  fontSize='15px'
                  fontWeight='600'
                  isTruncated
                  color='#33383F'
                >
                  {item.name}
                </Box>
                <Checkbox value={item.name} size='lg' />
              </Flex>
              <Divider />
            </React.Fragment>
          );
        })}
      </CheckboxGroup>
    </Flex>
  );
};

export default ByRoleSelect;
