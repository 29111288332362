import React, { forwardRef, useMemo } from 'react';
import {
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  useControllableState,
} from '@chakra-ui/react';

import { Button } from '../../../../../../../atoms';
import { TAssLocationType } from '../../../../../../../types';
import LocationSelectContent from './LocationSelectContent';
import { useLocationsList } from './useLocationsList';
import LocationSelectButton from './LocationSelectButton';

export interface LocSelectProps {
  value?: string[];
  onChange?: (value: string[]) => void;
  disabled?: boolean;
  locationType?: TAssLocationType;
  onTypeChange?: (value: TAssLocationType) => void;
}

const TrainingLocationSelect = forwardRef<never, LocSelectProps>(
  ({ value: _value, onChange, disabled, locationType, onTypeChange }, ref) => {
    const locations = useLocationsList();

    const [internalValue, updateValue] = useControllableState<string[]>({
      value: _value,
      onChange: onChange,
    });

    const [intLocType, updateLocType] = useControllableState<TAssLocationType>({
      value: locationType,
      onChange: onTypeChange,
    });

    const values = useMemo(() => {
      return locations.filter((val) => internalValue?.includes(val.eid));
    }, [locations, internalValue]);

    const onRemoveClick = (data?: string, locType?: TAssLocationType) => {
      if (data) {
        updateValue(internalValue.filter((val) => val !== data));
        if (intLocType !== 'custom') {
          updateLocType('custom');
        }
      } else if (locType) {
        updateLocType('custom');
      }
    };

    if (disabled) {
      return (
        <LocationSelectButton
          ref={ref}
          values={values}
          locationType={intLocType}
          disabled={disabled}
        />
      );
    }

    return (
      <Flex flexDir='column' gap={2}>
        <Popover isLazy matchWidth>
          <PopoverTrigger>
            <LocationSelectButton
              ref={ref}
              values={values}
              locationType={intLocType}
              onRemoveClick={onRemoveClick}
            />
          </PopoverTrigger>

          <PopoverContent
            width='inherit'
            borderRadius='12px'
            boxShadow='md'
            _focus={{}}
          >
            <LocationSelectContent
              locations={locations}
              value={internalValue}
              updateValue={(newLocations, newLocType) => {
                updateValue(newLocations);
                updateLocType(newLocType);
              }}
              locationSelectType={intLocType}
            />
          </PopoverContent>
        </Popover>
        {values?.length > 10 ? (
          <Button
            alignSelf='start'
            size='sm'
            variant='ghost'
            colorScheme='blue'
            fontSize='12px'
            onClick={() => updateValue([])}
          >
            Clear all
          </Button>
        ) : null}
      </Flex>
    );
  }
);

TrainingLocationSelect.displayName = 'TrainingLocationSelect';

export default TrainingLocationSelect;
