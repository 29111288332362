import { Flex } from '@chakra-ui/react';
import React, { FC } from 'react';
import { LeftSection, RightSection } from './topSection/';

interface IProp {}

const TopSection: FC<IProp> = () => {
  return (
    <Flex gap={2} width={'100%'}>
      <Flex width={'50%'}>
        <LeftSection />
      </Flex>
      <Flex w={'50%'}>
        <RightSection />
      </Flex>
    </Flex>
  );
};

TopSection.displayName =
  'displayName:sub-components/training-v2/dashboard/components/myTrainings/TopSection';

export default TopSection;
