import { gql } from '@apollo/client';
import { PageInfo } from '../../../../../../types';

export type ConvSortSort =
  | '_ID_ASC'
  | '_ID_DESC'
  | 'ISPINNED_ASC'
  | 'ISPINNED_DESC'
  | 'SUBJECT_ASC'
  | 'SUBJECT_DESC'
  | 'OCCURRENCEDATE_ASC'
  | 'OCCURRENCEDATE_DESC'
  | 'CREATEDAT_ASC'
  | 'CREATEDAT_DESC';

export interface LogsQueryVariable {
  page: number;
  perPage?: number;
  sort?: ConvSortSort;
  filter: {
    locationId: string[];
    query?: string;
  };
}

export interface ConversationLogItem {
  eid: string;
  subject: string;
  occurrenceDate: string;
  category: string;
  createdAt: string;
  isPinned: boolean;
  createdBy: string;
  showLO: boolean;
  createdByUser: {
    eid?: string;
    name: string;
    profilePic: string;
    role?: string;
  };
}

export interface ConversationLogsResponse {
  conversationLogsPagination: {
    items: ConversationLogItem[];
    pageInfo: PageInfo;
  };
}

export const ConversationLogsQuery = gql`
  query ConversationLogs(
    $page: Int
    $perPage: Int
    $sort: SortFindManyConversationLogInput
    $filter: FilterFindManyConversationLogInput
  ) {
    conversationLogsPagination(
      page: $page
      perPage: $perPage
      sort: $sort
      filter: $filter
    ) {
      items {
        eid
        subject
        occurrenceDate
        category
        createdAt
        isPinned
        createdBy
        showLO
        createdByUser {
          name
          profilePic
        }
      }
      pageInfo {
        currentPage
        itemCount
        perPage
      }
    }
  }
`;

export const DELETE_LOGS_QUERY = gql`
  mutation DeleteConversationLog($eid: String!) {
    DeleteConversationLog(eid: $eid) {
      succeed
    }
  }
`;
