import React, { forwardRef, useMemo } from 'react';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  useControllableState,
} from '@chakra-ui/react';
import { useUserEntity } from '../../../../../../../hooks';
import JobSelectContent from './JobSelectContent';
import JobSelectButton from './JobSelectButton';

interface IProps {
  value?: string[];
  onChange?: (value: string[]) => void;
  disabled?: boolean;
}

const TrainingJobSelect = forwardRef<never, IProps>(
  ({ value: _value, onChange, disabled }, ref) => {
    const roles = useUserEntity((entity) => entity?.roles || []);

    const [internalValue, updateValue] = useControllableState<string[]>({
      value: _value,
      onChange: onChange,
    });

    const values = useMemo(() => {
      return roles.filter((val) => internalValue?.includes(val.name));
    }, [roles, internalValue]);

    const onRemoveClick = (data: string) => {
      updateValue(internalValue.filter((val) => val !== data));
    };

    if (disabled) {
      return (
        <JobSelectButton
          ref={ref}
          values={values}
          disabled={disabled}
          onRemoveClick={onRemoveClick}
        />
      );
    }

    return (
      <Popover isLazy matchWidth>
        <PopoverTrigger>
          <JobSelectButton
            ref={ref}
            values={values}
            disabled={disabled}
            onRemoveClick={onRemoveClick}
          />
        </PopoverTrigger>

        <PopoverContent
          width='inherit'
          borderRadius='12px'
          boxShadow='md'
          _focus={{}}
        >
          <JobSelectContent
            jobs={roles}
            value={internalValue}
            updateValue={updateValue}
          />
        </PopoverContent>
      </Popover>
    );
  }
);

TrainingJobSelect.displayName = 'TrainingJobSelect';

export default TrainingJobSelect;
