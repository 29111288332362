import { ApolloProvider, useApolloClient } from '@apollo/client';
import { ModalCloseButton } from '@chakra-ui/react';
import { HeaderColors } from 'configs';
import { useCallback, useRef } from 'react';
import { AppRouter } from 'routes';
import { BoxHeader, UseConfirm, useConfirm } from 'ui-components';
import MakeLiveModal from './MakeLiveModal';
import { SelectOption } from 'atoms';
import { LocationStatus } from 'types';

interface IProps {}

const useMakeLiveModal = () => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();
  const apolloClient = useApolloClient();

  return useCallback(
    ({
      onMarkedAsLiveModalClose,
      locationName,
      locationStatus,
    }: {
      onMarkedAsLiveModalClose: () => void;
      locationName: string;
      locationStatus: LocationStatus;
    }) => {
      if (confirmRef.current) {
        confirmRef.current.destroy();
      }
      confirmRef.current = confirm({
        Wrapper: ({ children }: any) => {
          return (
            <ApolloProvider client={apolloClient}>
              <AppRouter>{children}</AppRouter>
            </ApolloProvider>
          );
        },
        size: '2xl',
        title: (
          <>
            <BoxHeader color={HeaderColors.Purple} title='Please note' />
            <ModalCloseButton
              top='1.5rem'
              right='1.5rem'
              fontSize='14px'
              color='#6F767E'
              padding='.8rem'
              borderRadius='50%'
            />
          </>
        ),
        content: (
          <MakeLiveModal
            onMarkedAsLiveModalClose={onMarkedAsLiveModalClose}
            locationName={locationName}
            locationStatus={locationStatus}
          />
        ),
        contentProps: {
          borderRadius: '12px',
          p: '2rem 2.5rem',
        },
        headerProps: {
          p: '0px',
        },
        bodyProps: {
          p: '0px',
        },
        isCentered: true,
        footer: null,
      });
    },
    [confirm, apolloClient]
  );
};

useMakeLiveModal.displayName =
  'displayName:pages/Locations/AddLocation/useMakeLiveModal.tsx';

export default useMakeLiveModal;
