import React, { FC, UIEventHandler, useEffect } from 'react';
import styled from '@emotion/styled';
import Editor, { ICON_TYPE } from 'delightree-editor';
import { SopDetailResponse } from './chapter.graphql';
import { useImageBgToggle } from './[info]ImageBgToggle';

const Wrapper = styled.div`
  flex: 1;
  overflow: auto;
  padding-left: 20px;
  padding-right: 20px;
`;

interface IProps {
  data?: SopDetailResponse['SopById'];
  searchQuery?: string;
  onItemClick?: (event: MouseEvent, node: any) => void;
  onScroll?: UIEventHandler<HTMLDivElement>;
}

const ChapterContent = React.forwardRef<HTMLDivElement, IProps>(
  ({ data, searchQuery, onItemClick, onScroll }, ref) => {
    const imageBgToggleInfo = useImageBgToggle();
    const content = data?.content?.[0]?.tiptap;

    const marginTop = '20px';
    const marginRight = '10px';
    const marginBottom = '20px';
    const marginLeft = '10px';

    useEffect(() => {
      imageBgToggleInfo({});
    }, []);

    const getPageMargins = () => {
      return `@page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }`;
    };

    return (
      <Wrapper onScroll={onScroll} id='chapter-content-to-export' ref={ref}>
        <style>{getPageMargins()}</style>
        <Editor
          readonly
          value={content}
          searchQuery={searchQuery}
          onItemClick={onItemClick}
          chapterIcon={data?.icon?.native}
          iconType={data?.icon?.id === ICON_TYPE ? 'icon' : 'emoji'}
        />
      </Wrapper>
    );
  }
);

ChapterContent.displayName = 'Chapters/ChapterView/ChapterContent';

export default ChapterContent;
