import { useMutation } from '@apollo/client';
import { cloneDeep } from '@apollo/client/utilities';
import { TFormInput } from '../create';
import { UPDATE_TRAINING, UpdateTResponse } from './edit-training.graphql';
import { CreateTrainingInput } from '../create/components/training.graphql';
import { mapAccessPermission } from '../create/components/data-mapper.helper';

type InputData = Pick<TFormInput, 'eid' | 'accessPermission'>;
type InputPayload = Pick<CreateTrainingInput, 'eid' | 'accessPermission'>;

const toPermissionPayload = (values: InputData): InputPayload => {
  return {
    eid: values.eid,
    accessPermission: mapAccessPermission(values.accessPermission),
  };
};

export const useAccessPermissionSave = () => {
  const [updateTraining] = useMutation<
    UpdateTResponse,
    { input: InputPayload }
  >(UPDATE_TRAINING);

  return async (values: InputData) => {
    try {
      const input = toPermissionPayload(cloneDeep(values));

      const res = await updateTraining({
        variables: {
          input: input,
        },
      });

      if (res.errors) {
        return Promise.reject(res.errors);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  };
};
