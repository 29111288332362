import React, { FC } from 'react';
import { Box, Flex, IconButton } from '@chakra-ui/react';
import { useWatch } from 'react-hook-form';
import { Image } from '../../../../../../ui-components';
import { VideoFormInput } from './video-resource.graphql';

import { ReactComponent as DeleteIcon } from 'assets/images/trash-red.svg';

interface IProps {
  value: string;
  onRemoveClick?: () => void;
}

const ThumbnailView: FC<IProps> = ({ value, onRemoveClick }) => {
  const thumbnail = useWatch<VideoFormInput, 'thumbnail'>({
    name: 'thumbnail',
  });
  const videoFileName = useWatch<VideoFormInput, 'videoFileName'>({
    name: 'videoFileName',
  });

  return (
    <Flex gap={4} align='center' py={2}>
      <Image
        src={thumbnail}
        width={142}
        height={78}
        style={{ borderRadius: '12px' }}
      />
      <Flex flex={1} flexDir='column' overflow='hidden' gap='2px'>
        <Box fontSize='13px' fontWeight='600' color='#111315' isTruncated>
          {videoFileName}
        </Box>
        <Box fontSize='12px' color='#6F767E' isTruncated>
          {value}
        </Box>
      </Flex>
      <IconButton
        size='sm'
        aria-label='delete'
        variant='ghost'
        icon={<DeleteIcon width='20' height='20' />}
        onClick={onRemoveClick}
      />
    </Flex>
  );
};

export default ThumbnailView;
