import React, { FC } from 'react';
import { Box, Flex, Spacer, useToast } from '@chakra-ui/react';
import { gql, useMutation } from '@apollo/client';

import { Button, Input } from '../../../atoms';
import { useSafeState } from '../../../hooks';
import { ActionButton } from '../../../ui-components';

import { ReactComponent as SyncIcon } from 'assets/images/sync-sharp.svg';

import { ReactComponent as ThumbUpIcon } from 'assets/images/ai-search/thumbs-up.svg';
import { ReactComponent as ThumbUpFilledIcon } from 'assets/images/ai-search/thumbs-up-filled.svg';
import { ReactComponent as ThumbDownIcon } from 'assets/images/ai-search/thumbs-down.svg';
import { ReactComponent as ThumbDownFilledIcon } from 'assets/images/ai-search/thumbs-down-filled.svg';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';

const FEEDBACK = gql`
  mutation AiSearchUpdate($eid: String!, $like: Boolean!, $comments: String) {
    aiSearchUpdate(eid: $eid, like: $like, comments: $comments) {
      eid
    }
  }
`;

interface IProps {
  searchId?: string;
  isLoading?: boolean;
  onRegenerate?: () => void;
}

const ResponseFooter: FC<IProps> = ({ searchId, isLoading, onRegenerate }) => {
  const toast = useToast({
    position: 'top-right',
    isClosable: true,
  });
  const [status, updateStatus] = useSafeState<string>();
  const [comment, setComment] = useSafeState<string>();
  const [updating, setUpdating] = useSafeState<boolean>();

  const [addFeedback] = useMutation(FEEDBACK, {
    onCompleted: () => {
      toast({
        status: 'success',
        title: 'Success',
        description: 'Thanks for sharing your feedback!',
      });
    },
    onError: () => {
      toast({
        status: 'error',
        title: 'Error',
        description: 'Oops! Something went wrong. Please try again.',
      });
    },
  });

  const submitFeedBack = async (like: boolean, comments?: string) => {
    setUpdating(true);
    await addFeedback({
      variables: {
        eid: searchId,
        like: like,
        comments: comments,
      },
    });
    setUpdating(false);
  };

  return (
    <Flex flexDir='column' hidden={isLoading} gap={3}>
      <Flex align='center'>
        <Button
          fontSize='14px'
          variant='link'
          colorScheme='blue'
          rightIcon={<SyncIcon width='14' height='14' />}
          onClick={() => {
            deployEvent(
              AmplitudeEventNames.AI_POWERED_SEARCH_REGENERATE_CLICK,
              {
                search_funnel_id: 3,
              }
            );
            onRegenerate?.();
          }}
        >
          Regenerate
        </Button>

        <Spacer />

        <Flex gap={2} alignItems='center'>
          <Box fontSize='12px' color='#6F767E'>
            Was this what you were looking for?
          </Box>

          <ActionButton
            size='xs'
            borderRadius='full'
            aria-label='like'
            bg='whiteAlpha.900'
            padding={0}
            actionFn={() => {
              updateStatus('like');
              deployEvent(AmplitudeEventNames.AI_POWERED_SEARCH_LIKE_CLICK, {
                event: {
                  search_funnel_id: 3,
                  like: true,
                },
              });
              return submitFeedBack(true);
            }}
            isDisabled={updating}
          >
            {status === 'like' ? <ThumbUpFilledIcon /> : <ThumbUpIcon />}
          </ActionButton>

          <ActionButton
            size='xs'
            borderRadius='full'
            aria-label='dislike'
            bg='whiteAlpha.900'
            padding={0}
            actionFn={() => {
              deployEvent(AmplitudeEventNames.AI_POWERED_SEARCH_LIKE_CLICK, {
                event: {
                  search_funnel_id: 3,
                  like: false,
                },
              });
              updateStatus('dislike');
            }}
            isDisabled={updating}
          >
            {status === 'dislike' ? <ThumbDownFilledIcon /> : <ThumbDownIcon />}
          </ActionButton>
        </Flex>
      </Flex>

      {status === 'dislike' ? (
        <Flex gap={3}>
          <Input
            flex={1}
            bg='white'
            autoFocus
            placeholder='Help us improve by telling us what went wrong!'
            _placeholder={{
              fontStyle: 'italic',
              fontWeight: '200',
            }}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            isDisabled={updating}
          />

          <ActionButton
            colorScheme='blue'
            fontSize='12px'
            actionFn={() => submitFeedBack(false, comment)}
            isDisabled={updating}
          >
            Submit
          </ActionButton>
        </Flex>
      ) : null}
    </Flex>
  );
};

export default ResponseFooter;
