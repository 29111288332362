import React, { FC } from 'react';
import { Flex, PopoverBody, usePopoverContext } from '@chakra-ui/react';
import { useSafeState } from '../../../../../../hooks';
import AddTpCategory from './AddTpCategory';
import CategoryItem from './CategoryItem';
import { TpCategoryEntity } from './tp-category.graphql';

interface IProps {
  categories: TpCategoryEntity[];
  value: string;
  updateValue: (value: string) => void;
}

const CategorySelectContent: FC<IProps> = ({
  categories,
  value,
  updateValue,
}) => {
  const [newCat, updateNewCat] = useSafeState<TpCategoryEntity>();
  const { onClose } = usePopoverContext();

  return (
    <PopoverBody paddingInline='20px' paddingBlock='16px'>
      <Flex
        flexDir='column'
        gap={1}
        maxHeight='380px'
        // minHeight='300px'
        overflow='auto'
        sx={{
          '&::-webkit-scrollbar': {
            width: '5px',
            height: '0px',
          },
          '&::-webkit-scrollbar-track': {
            // background: '#f1f1f1',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#EFEFEF',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#555',
          },
        }}
      >
        <AddTpCategory
          onNewCategory={updateNewCat}
          isSelected={newCat?.eid === value}
          onClick={() => {
            updateValue?.(newCat?.eid!);
            setTimeout(onClose, 100);
          }}
        />

        {categories.map((item) => {
          if (item.eid === newCat?.eid) {
            return null;
          }
          return (
            <CategoryItem
              key={item.eid}
              item={item}
              isSelected={item.eid === value}
              onClick={() => {
                updateValue?.(item.eid);
                setTimeout(onClose, 100);
              }}
            />
          );
        })}
      </Flex>
    </PopoverBody>
  );
};

export default CategorySelectContent;
