import React, { FC } from 'react';
import { AddIcon, CheckIcon } from '@chakra-ui/icons';
import { Flex } from '@chakra-ui/react';
import { Button } from '../../../../../../atoms';
import { useSafeState } from '../../../../../../hooks';
import { useAddTpCategory } from './useTrainingCategories';
import { TpCategoryEntity } from './tp-category.graphql';
import CategoryItem from './CategoryItem';
import AddCategoryInput from './AddCategoryInput';

interface IProps {
  isSelected?: boolean;
  onClick?: () => void;
  onNewCategory?: (cate: TpCategoryEntity) => void;
}

const AddTpCategory: FC<IProps> = ({ onNewCategory, isSelected, onClick }) => {
  const [open, updateOpen] = useSafeState(false);
  const [category, updateCategory] = useSafeState<TpCategoryEntity>();

  const { addCategory } = useAddTpCategory();

  const onAddClick = async (value: string = '') => {
    const res = await addCategory({
      variables: {
        category: value.trim(),
      },
    });

    if (res.errors) {
      return Promise.reject(res.errors);
    }

    if (res.data?.AddTpCategories) {
      updateCategory(res.data?.AddTpCategories);
      onNewCategory?.(res.data?.AddTpCategories);
    }
  };

  if (category) {
    return (
      <CategoryItem item={category} isSelected={isSelected} onClick={onClick}>
        <Flex
          gap={2}
          align='center'
          color='#83BF6E'
          fontSize='13px'
          fontWeight='500'
          paddingX='12px'
          className='item-added'
        >
          <CheckIcon boxSize='14px' />
          New category added
        </Flex>
      </CategoryItem>
    );
  }

  if (open) {
    return (
      <AddCategoryInput
        onAddClick={onAddClick}
        onCloseClick={() => updateOpen(false)}
      />
    );
  }

  return (
    <Button
      minHeight='48px'
      variant='ghost'
      leftIcon={<AddIcon />}
      color='#8E59FF'
      justifyContent='start'
      onClick={() => updateOpen(true)}
    >
      Add new
    </Button>
  );
};

export default AddTpCategory;
