import { match } from 'ts-pattern';
import moment from 'moment';
import { userObj } from 'sop-commons/src';
import { toArray, toNumber } from '../../../utils';
import { ACCESS_SEPARATOR } from '../../../configs';
import { TAssignee, TrainingAccessEnum } from '../../../types';
import { AuthRole } from '../../../authorization';
import { TFormInput } from '../create';
import { TrainingResponse } from './edit-training.graphql';
import { usersEntityObj } from '../../Header';
import { defaultTrainingInput } from '../create/training.default';

type Training = TrainingResponse['TpById'];

type Assignee = Pick<TFormInput, 'assignees'>;

const assigneeMap = (ass: TAssignee): TAssignee => {
  switch (ass?.type) {
    case 'role':
      return {
        type: 'role',
        roles: ass.roles || [],
        locationIds: ass.locationIds || [],
        locationType: ass.locationType || 'custom',
        authRoles: [],
        userIds: [],
      };
    case 'authRole':
      return {
        type: 'authRole',
        authRoles: ass.authRoles || [],
        locationIds: ass.locationIds || [],
        locationType: ass.locationType || 'custom',
        roles: [],
        userIds: [],
      };
    case 'user':
      return {
        type: 'user',
        userIds: ass.userIds || [],
        authRoles: [],
        locationIds: [],
        roles: [],
      };
    case 'location':
      return {
        type: 'location',
        locationIds: ass.locationIds || [],
        authRoles: ass.authRoles || [],
        userIds: [],
        roles: ass.roles || [],
        locationType: ass.locationType || 'custom',
      };
  }
};

export const fromAssigneePayload = (
  assignees: Training['assignees']
): Assignee => {
  let result: Assignee = {
    assignees: [],
  };

  const _assignee = toArray(assignees);

  if (_assignee.length === 0) {
    result.assignees = [
      {
        type: undefined as never,
        authRoles: [],
        roles: [],
        userIds: [],
        locationIds: [],
        locationType: undefined,
      },
    ];
    return result;
  }

  result.assignees = _assignee.map(assigneeMap);

  return result;
};

export function removeKeyData<T>(obj: T, removeKey: string): T {
  // Return early if the input is not an object or array
  if (obj === null || typeof obj !== 'object') return obj;

  // If the input is an array, map over the items and remove uids
  if (Array.isArray(obj)) {
    return obj.map((item) => removeKeyData(item, removeKey)) as unknown as T;
  }

  // If the input is an object, iterate over its keys and omit 'uid'
  const newObj = {} as T;
  for (const key in obj) {
    // Skip the key if it is 'uid'
    if (key === removeKey) {
      continue;
    }
    newObj[key] = removeKeyData(obj[key], removeKey);
  }

  return newObj;
}

export const mapContentPayload = (
  contents: Training['contents']
): TFormInput['contents'] => {
  return toArray(contents).map((value) => {
    return {
      ...value,
      tempEid: value.eid,
      questions: removeKeyData(toArray(value.questions), '__typename'),
    };
  });
};

const mapRCyclePayload = (rCycle: Training['repeatCycle']) => {
  if (!rCycle) {
    return null;
  }

  return match([rCycle?.type])
    .with(['EVERY_N_DAYS'], () => ({
      type: rCycle.type,
      repeatValue: rCycle.days,
    }))
    .with(['EVERY_N_WEEKS'], () => ({
      type: rCycle.type,
      repeatValue: rCycle.weeks,
      dayOfTheWeek: rCycle.dayOfTheWeek,
    }))
    .with(['EVERY_N_MONTHS'], () => ({
      type: rCycle.type,
      repeatValue: rCycle.months,
      dayOfTheMonth: rCycle.dayOfTheMonth,
    }))
    .with(['EVERY_N_YEARS'], () => ({
      type: rCycle.type,
      repeatValue: rCycle.years,
      monthOfTheYear: rCycle.monthOfTheYear,
      dayOfTheMonth: rCycle.dayOfTheMonth,
    }))
    .otherwise(() => null);
};

const fromAccessData = (value: TrainingAccessEnum[] = []) => {
  return value.join(ACCESS_SEPARATOR).toUpperCase();
};

export const transformAccessRules = (
  permission: Training['accessPermission'],
  createdBy?: string
): TFormInput['accessPermission'] => {
  const creatorRole = usersEntityObj().find(
    (u) => u.eid === createdBy
  )?.authRole;

  if (permission) {
    const defaultRule = defaultTrainingInput(creatorRole)?.accessPermission!;

    const rules = {
      admin: fromAccessData(permission.admin),
      locationOwner: fromAccessData(permission.locationOwner),
      superadmin: fromAccessData(permission.superadmin),
    };

    switch (creatorRole) {
      case AuthRole.LOCATION_OWNER:
        rules.superadmin = defaultRule?.superadmin || rules.superadmin;
        rules.admin = defaultRule?.admin || rules.admin;
        break;
      default:
        rules.superadmin = defaultRule?.superadmin || rules.superadmin;
        break;
    }

    return rules;
  }

  return undefined;
};

const getCurrentEditTab = (
  values: TrainingResponse['TpById'],
  action?: 'reassign' | 'assignee' | 'content'
) => {
  if (values?.status === 'TRAINING_DRAFT') {
    return toNumber(values?.currentEditPage, 0);
  }

  switch (action) {
    case 'reassign':
    case 'assignee':
      return 2;
    case 'content':
      return 1;
  }

  if (values?.createdBy === userObj().eid) {
    return 0;
  }

  const authRole = userObj().authRole as Exclude<
    AuthRole,
    AuthRole.WORKER | AuthRole.LOCATION_USER
  >;

  const permission = values.accessPermission?.[authRole];

  if (permission?.includes(TrainingAccessEnum.ASSIGN)) {
    return 2;
  }

  return 0;
};

const convertToDate = (check: string, value: string | null) => {
  if (check === 'date' && value) {
    return moment(value).toDate();
  }
  return null;
};

export const mapToFormData = (
  values: TrainingResponse['TpById'],
  action?: 'reassign'
): TFormInput => {
  return {
    ...values,

    currentEditPage: getCurrentEditTab(values, action),
    isReassigning: action === 'reassign',

    contents: mapContentPayload(values?.contents),

    ...fromAssigneePayload(values?.assignees),

    frequency: values?.isRepeating ? 'repeat' : 'once',

    // @ts-ignore
    repeatCycle: values?.isRepeating
      ? mapRCyclePayload(values?.repeatCycle)
      : null,

    deadlineDate: convertToDate(values?.deadlineType, values?.deadlineDate), // TODO transform to date
    duration: values?.duration
      ? {
          type: values?.duration?.type!,
          value: values?.duration?.value!,
        }
      : null,

    startDate: convertToDate(values?.assignmentType, values?.startDate), // TODO transform to date

    skillVerifier: values?.hasSkillVerifier
      ? {
          name: values?.skillVerifier?.name,
          skill: values?.skillVerifier?.skill,
        }
      : undefined,

    accessPermission: transformAccessRules(
      values?.accessPermission,
      values?.createdBy
    ),

    isAssignedToUsers: toArray(values?.assignedUsers).length > 0,
  };
};
