import React, { FC } from 'react';
import { Button } from '../../../../../../atoms';

import { ReactComponent as EditIcon } from '../../../../../../assets/images/edit-dark.svg';

interface IProps {
  disabled?: boolean;
  skillName?: string;
  onEditClick?: () => void;
}

const SkillVerifierView: FC<IProps> = ({
  skillName,
  onEditClick,
  disabled,
}) => {
  if (!skillName) {
    return null;
  }

  if (disabled) {
    return (
      <Button
        mt={4}
        width='max-content'
        size='sm'
        variant='outline'
        fontSize='12px'
        bg='#FCFCFC'
      >
        Name: {skillName}
      </Button>
    );
  }

  return (
    <Button
      mt={4}
      width='max-content'
      size='sm'
      variant='outline'
      fontSize='12px'
      bg='#FCFCFC'
      rightIcon={<EditIcon width='20' height='20' />}
      onClick={onEditClick}
    >
      Name: {skillName}
    </Button>
  );
};

export default SkillVerifierView;
