import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Box, Flex } from '@chakra-ui/react';
import { HeaderColors } from 'configs';
import React, { FC, useMemo } from 'react';
import { BoxHeader } from 'ui-components';
import { QuizQuestion } from '../types';

interface IProps {
  data: QuizQuestion | undefined;
}

const Preview: FC<IProps> = ({ data }) => {
  const optionsList = useMemo(() => {
    switch (data?.questionType) {
      case 'mcq':
      case 'trueFalse':
        return (
          <Flex flexDir='column'>
            {data?.options?.map((option) => (
              <Flex>{option?.optionTitle}</Flex>
            ))}
          </Flex>
        );
      case 'match':
        return (
          <Flex flexDir='column' gap={4}>
            {data?.options?.map((option) => (
              <Flex align='center' gap='5px'>
                <Flex
                  bg='rgba(244, 244, 244, 1)'
                  h='50px'
                  w='93px'
                  p='12px 10px'
                  borderRadius='8px'
                  border='.3px solid rgba(111, 118, 126, 1)'
                  align='center'
                >
                  {option?.question}
                </Flex>
                <ArrowForwardIcon />
                <Flex
                  bg='white'
                  h='50px'
                  w='93px'
                  p='12px 10px'
                  borderRadius='8px'
                  border='.3px solid rgba(111, 118, 126, 1)'
                  align='center'
                >
                  {option?.response}
                </Flex>
              </Flex>
            ))}
          </Flex>
        );
      default:
        return null;
    }
  }, [data]);

  return (
    <Flex flexDir='column'>
      <BoxHeader color={HeaderColors.Blue} title='Preview' />
      <Flex flexDir='column'>
        <Flex bg={HeaderColors.DarkBlue}>
          <Box>{data?.questionTitle}</Box>
        </Flex>
        <Flex>{optionsList}</Flex>
      </Flex>
    </Flex>
  );
};

Preview.displayName =
  'displayName:sub-components/training-v2/shared/preview/Preview';

export default Preview;
