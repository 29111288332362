import React, { FC } from 'react';
import DashboardContainer from '../../sub-components/DashboardContainer';
import { TaskUpdate } from '../../sub-components/tasks';

interface IProps {}

const EditTask: FC<IProps> = () => {
  return (
    <DashboardContainer>
      <TaskUpdate />
    </DashboardContainer>
  );
};

EditTask.displayName = 'displayName:pages/Tasks/edit-task';

export default EditTask;
