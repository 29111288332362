import React, { FC } from 'react';
import { Box } from '@chakra-ui/react';
import { ACCESS_SEPARATOR } from '../../../../../configs';

interface IProps {
  value?: string;
}

const AccessViewOnly: FC<IProps> = ({ value }) => {
  return (
    <Box
      fontSize='13px'
      fontWeight={500}
      color='#33383F'
      whiteSpace='nowrap'
      textTransform='capitalize'
    >
      {value?.toLowerCase()?.replaceAll(ACCESS_SEPARATOR, ', ')}
    </Box>
  );
};

export default React.memo(AccessViewOnly);
