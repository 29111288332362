import React from 'react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Box, Flex, forwardRef } from '@chakra-ui/react';
import { Button, ButtonProps, SelectOption } from '../../../../../../../atoms';
import { AuthRole } from '../../../../../../../authorization';
import SelectedAuthRoles from './SelectedAuthRoles';

interface IProps extends ButtonProps {
  values?: SelectOption<AuthRole>[];
  onRemoveClick?: (value: AuthRole) => void;
}

const AuthRoleSelectButton = forwardRef<IProps, 'button'>(
  ({ values, disabled, onRemoveClick, ...props }, ref) => {
    return (
      <Button
        ref={ref}
        as='div'
        size='lg'
        borderRadius='12px'
        rightIcon={<ChevronDownIcon boxSize='28px' color='#6F767E' />}
        justifyContent='space-between'
        alignItems='initial'
        paddingX='12px'
        paddingBlock='6px'
        variant='outline'
        bg='white'
        width='full'
        // _hover={{}}
        disabled={disabled}
        {...props}
      >
        <Flex gap={2} align='center'>
          {values?.length ? (
            <SelectedAuthRoles values={values} onRemoveClick={onRemoveClick} />
          ) : (
            <Box fontSize='14px' fontWeight='600' opacity='0.5'>
              Select role
            </Box>
          )}
        </Flex>
      </Button>
    );
  }
);

export default AuthRoleSelectButton;
