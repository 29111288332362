import React, { FC } from 'react';
import { Box, Button, Center, Flex } from '@chakra-ui/react';
import { IconImage } from '../../../../../../../ui-components';

interface PreviewContainerProps {
  title: string;
  thumbnail?: string;
  hideTitle?: boolean;
  children: React.ReactNode;
  onSubmit?: () => void;
  showSubmit?: boolean;
  isSubmitting?: boolean;
  isDisabled?: boolean;
}

const PreviewContainer: FC<PreviewContainerProps> = ({
  title,
  thumbnail,
  hideTitle,
  children,
  onSubmit,
  showSubmit = true,
  isSubmitting = false,
  isDisabled = false,
}) => {
  return (
    <Flex flex={1} flexDir='column' overflow='hidden'>
      <Flex
        flexDir='column'
        gap={4}
        height='full'
        padding='16px 8px'
        bg='#EFEFEF'
        overflow='auto'
      >
        {hideTitle ? null : (
          <Center
            flexDir='column'
            fontSize='10px'
            fontWeight={600}
            bg='white'
            borderRadius='12px'
            p='20px 16px'
            textAlign='center'
            wordBreak='break-word'
            gap='6px'
          >
            {thumbnail ? (
              <IconImage boxSize={64} name='' thumbnail={thumbnail} />
            ) : null}
            <Box>{title}</Box>
          </Center>
        )}

        <Box flex={1} overflow='auto'>
          {children}
        </Box>

        {showSubmit && (
          <Button
            size='sm'
            fontSize='12px'
            colorScheme='blue'
            onClick={onSubmit}
            isLoading={isSubmitting}
            isDisabled={isDisabled || isSubmitting}
          >
            Submit
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

export default PreviewContainer;
