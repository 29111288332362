import { useMutation } from '@apollo/client';
import { useFormContext } from 'react-hook-form';
import { cloneDeep } from '@apollo/client/utilities';

import { TFormInput } from '../create';
import { CreateVariable } from '../create/components/training.graphql';
import { toPayloadInput } from '../create/components/data-mapper.helper';
import { UPDATE_TRAINING, UpdateTResponse } from './edit-training.graphql';

type UseUpdateHandler = (values: TFormInput) => Promise<void>;

export const useUpdateHandler = (): UseUpdateHandler => {
  const { getValues, control, reset } = useFormContext<TFormInput>();

  const [updateTraining] = useMutation<UpdateTResponse, CreateVariable>(
    UPDATE_TRAINING,
    { onCompleted: () => reset(undefined, { keepValues: true }) }
  );

  return async (values: TFormInput) => {
    try {
      control._disableForm(true);
      console.log('Raw input --> ', getValues());
      const input = toPayloadInput(cloneDeep(values));
      console.log('Mapped payload --> ', input);

      if (values?.eid) {
        input.eid = values.eid;
      }

      const res = await updateTraining({
        variables: {
          input: input,
        },
      });

      if (res.errors) {
        return Promise.reject(res.errors);
      }
    } catch (err) {
      return Promise.reject(err);
    } finally {
      control._disableForm(false);
    }
  };
};
