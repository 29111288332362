import React, { FC } from 'react';
import { Box, Center } from '@chakra-ui/react';
import { gql, useQuery } from '@apollo/client';
import { JSONContent } from 'delightree-editor';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLoader } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import VideoPreviewContent from '../../../resources/video-resource/VideoPreviewContent';

interface Response {
  findCardById: {
    eid: string;
    title: string;
    thumbnail: string;
    content: JSONContent;
  };
}

const GET_CARD_DATA = gql`
  query FindCardById($eid: String!) {
    findCardById(eid: $eid) {
      eid
      title
      thumbnail
      content
    }
  }
`;

interface IProps {
  cardId: string;
}

const VideoPreview: FC<IProps> = ({ cardId }) => {
  const { data, loading } = useQuery<Response>(GET_CARD_DATA, {
    skip: !cardId,
    variables: {
      eid: cardId,
    },
  });

  if (loading) {
    return (
      <Center flex={1}>
        <FontAwesomeIcon
          icon={faLoader as IconProp}
          size='2x'
          color='#2A85FF'
          spin
        />
      </Center>
    );
  }

  const cardData = data?.findCardById;

  return (
    <Box flex={1} overflow='hidden' paddingTop='32px'>
      <VideoPreviewContent
        title={cardData?.title}
        caption={cardData?.content?.[1]?.text}
        videoUrl={cardData?.content?.[0]?.video}
      />
    </Box>
  );
};

export default VideoPreview;
