import React, { ChangeEvent, FC, useMemo } from 'react';
import {
  Box,
  Checkbox,
  CheckboxGroup,
  Divider,
  Flex,
  PopoverBody,
  useControllableState,
  usePopoverContext,
} from '@chakra-ui/react';
import { Button, SearchInput } from '../../../../../../../atoms';
import { useSafeState } from '../../../../../../../hooks';
import { searchRegExp } from '../../../../../../../utils';
import { UserEntityData } from '../../../../../../../shared/graphql/shared-types';

import { getSxScrollStyles } from '../../../common';
import { ListWrapper } from '../../Assignee/common';
import SupervisorItem from './SupervisorItem';

interface IProps {
  members: UserEntityData[];
  value: string[];
  updateValue: (value: string[]) => void;
}

const SupervisorSelectContent: FC<IProps> = ({
  members,
  value,
  updateValue,
}) => {
  const { onClose } = usePopoverContext();
  const [internalValue, internalUpdate] = useControllableState<string[]>({
    defaultValue: value,
  });

  const [searchQuery, updateQuery] = useSafeState('');
  const [allChecked, setAllChecked] = useSafeState(false);

  const locationList = useMemo(() => {
    if (!searchQuery?.trim()) {
      return members;
    }

    const reg = searchRegExp(searchQuery, 'gi');

    return members.filter((value) => {
      return value.name?.match(reg);
    });
  }, [members, searchQuery]);

  const onSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
    const keys = locationList?.map((val) => val.eid);

    internalUpdate((prev = []) => {
      if (event.target.checked) {
        return [...new Set([...prev, ...keys])];
      } else {
        return prev.filter((p) => !keys.includes(p));
      }
    });
    setAllChecked(event.target.checked);
  };

  const [allItemChecked, isIndeterminate] = useMemo(() => {
    const all = locationList?.every((val) => internalValue?.includes(val.eid));
    const some = locationList?.some((val) => internalValue?.includes(val.eid));
    return [all && !!locationList?.length, some && !all];
  }, [locationList, internalValue]);

  return (
    <PopoverBody paddingInline='20px' paddingBlock='16px'>
      <SearchInput
        size='lg'
        placeholder='Search member'
        hideShortcuts
        value={searchQuery}
        onChange={(event) => updateQuery(event.target.value)}
      />

      <Flex
        flexDir='column'
        gap={1}
        marginTop='20px'
        maxHeight='400px'
        overflow='auto'
        sx={getSxScrollStyles()}
      >
        <Flex paddingInline='12px' paddingY={4}>
          <Box
            flex={1}
            fontSize='13px'
            fontWeight='600'
            color='#6F767E'
            textTransform='uppercase'
          >
            Select all
          </Box>
          <Checkbox
            value='all'
            size='lg'
            isChecked={allChecked && allItemChecked}
            isIndeterminate={allChecked && isIndeterminate}
            onChange={onSelectAll}
          />
        </Flex>

        <CheckboxGroup value={internalValue} onChange={internalUpdate as never}>
          <ListWrapper haveData={locationList?.length > 0}>
            {locationList.map((item) => {
              return (
                <React.Fragment key={item.eid}>
                  <SupervisorItem item={item} />
                  <Divider />
                </React.Fragment>
              );
            })}
          </ListWrapper>
        </CheckboxGroup>
      </Flex>

      <Button
        size='lg'
        width='full'
        colorScheme='blue'
        onClick={() => {
          setTimeout(onClose);
          updateValue?.(internalValue as string[]);
        }}
      >
        Select
      </Button>
    </PopoverBody>
  );
};

export default SupervisorSelectContent;
