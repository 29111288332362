import React, { FC, useMemo } from 'react';
import { Box, Flex, Spacer, Tooltip, useModalContext } from '@chakra-ui/react';
import { useWatch } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from '../../../../../../atoms';
import { ActionButton } from '../../../../../../ui-components';

interface IProps {
  onAddClick?: () => void;
  selectedTitle: 'training:formSelected' | 'training:chapterSelected';
  selectLabel: string;

  createTitle: string;
  createLabel: string;
  onCreateClick?: () => void;
}

const BaseResourceFooter: FC<IProps> = ({
  onAddClick,
  selectedTitle,
  selectLabel,
  createTitle,
  createLabel,
  onCreateClick,
}) => {
  const { t } = useTranslation(['training']);
  const { onClose } = useModalContext();
  const trainingContent = useWatch({
    name: 'trainingContent',
  });

  const selected = useMemo(() => {
    return Object.values(trainingContent || {})?.filter(Boolean)?.length;
  }, [trainingContent]);

  return (
    <Flex
      gap={5}
      paddingY='10px'
      paddingX={4}
      bgColor='#FCFCFC'
      borderTop='1px solid #EFEFEF'
      borderBottomRadius='8px'
    >
      {selected ? (
        <Flex align='end' gap='3px' marginLeft={3}>
          <Box
            as='span'
            fontSize='20px'
            fontWeight='600'
            lineHeight='22px'
            color='#111315'
          >
            {selected}
          </Box>
          <Box as='span' fontSize='13px' fontWeight='500' color='#33383F'>
            <Trans t={t} i18nKey={selectedTitle} count={selected} />
          </Box>
        </Flex>
      ) : null}

      <Spacer />

      <Tooltip
        hasArrow
        padding='6px 10px'
        placement='top-start'
        bgColor='#000000BD'
        label={createLabel}
      >
        <Button variant='outline' fontSize='13px' onClick={onCreateClick}>
          {createTitle}
        </Button>
      </Tooltip>

      <Tooltip
        hasArrow
        padding='6px 10px'
        placement='top-end'
        bgColor='#000000BD'
        label={selectLabel}
        shouldWrapChildren={!selected}
        isDisabled={!!selected}
      >
        <ActionButton
          fontSize='13px'
          colorScheme='blue'
          close={onClose}
          isDisabled={!selected}
          actionFn={onAddClick}
        >
          Confirm selection ({selected})
        </ActionButton>
      </Tooltip>
    </Flex>
  );
};

export default BaseResourceFooter;
