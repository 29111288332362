import React, { useCallback, useRef } from 'react';
import { AlertDialogCloseButton, Flex } from '@chakra-ui/react';
import { BoxHeader, UseConfirm, useConfirm } from '../../../../ui-components';
import { HeaderColors } from '../../../../configs';
import BeforePublishContent, { ARuleProps } from './BeforePublishContent';

type BeforePublish = (props: ARuleProps) => void;

export const useBeforePublish = (): BeforePublish => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();

  const confirm = useConfirm();
  return useCallback((props) => {
    if (confirmRef.current) {
      confirmRef.current?.destroy();
    }

    confirmRef.current = confirm({
      title: (
        <Flex gap='12px' justify='space-between'>
          <BoxHeader title='Edit' color={HeaderColors.Purple} />

          <AlertDialogCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
          />
        </Flex>
      ),

      content: <BeforePublishContent {...props} />,

      isCentered: true,
      headerProps: { paddingX: 7, paddingTop: 5 },
      contentProps: {
        borderRadius: '8px',
        maxWidth: '751px',

        marginTop: 'auto',
        marginBottom: 'auto',

        containerProps: {
          // alignItems: 'start',
          paddingTop: '3rem',
          paddingBottom: '3rem',
        },
      },
      bodyProps: {
        paddingX: 0,
        paddingBottom: 0,
      },
      footer: null,
    });
  }, []);
};
