import {
  Box,
  Button,
  Center,
  Flex,
  Text,
  useModalContext,
} from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { LocationStatus } from 'types';

interface IProps {
  onMarkedAsLiveModalClose: () => void;
  locationName: string;
  locationStatus: LocationStatus;
}

const MakeLiveModal: FC<IProps> = ({
  onMarkedAsLiveModalClose,
  locationName,
  locationStatus,
}) => {
  const { onClose } = useModalContext();

  return (
    <Flex flexDir='column' gap={3} marginTop='3rem'>
      <Text color='#272B30' fontSize='24px' fontWeight={400} textAlign='center'>
        You are making
        <Box as='span' color='#2A85FF' fontWeight={800} px={2}>
          {locationName}
        </Box>
        live
      </Text>

      <Flex flexDir='column' gap={4}>
        <Text
          fontWeight={400}
          fontSize='14px'
          color='#00000080'
          textAlign='center'
        >
          Once you make your location live, you will gain access to a range of
          powerful features. You will be able to add more members to your team,
          assign tasks to them, track their progress, assign trainings to
          enhance their skills, and much more.
        </Text>
        {locationStatus !== 'preLaunch' && (
          <Flex w='full' justify='center'>
            <Center
              gap='10px'
              bg='rgba(252, 241, 229, 1)'
              borderRadius='7px'
              p='11px 12px'
              border='1px solid rgba(255, 174, 82, 1)'
            >
              <FontAwesomeIcon
                icon={faCircleExclamation as IconProp}
                color='rgba(255, 164, 61, 1)'
              />
              <Box as='span' color='rgba(255, 164, 61, 1)'>
                All progress on the launcher tasks will be lost.
              </Box>
            </Center>
          </Flex>
        )}
      </Flex>

      <Flex gap={4} marginTop='1.5rem'>
        <Button
          background='white'
          color='#1A1D1F'
          borderRadius='10px'
          variant='outline'
          padding='12px 20px'
          flex={1}
          height='fit-content'
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          colorScheme='blue'
          borderRadius='10px'
          padding='12px 20px'
          height='fit-content'
          flex={1}
          onClick={() => {
            onMarkedAsLiveModalClose();
            onClose();
          }}
        >
          Confirm & make live
        </Button>
      </Flex>
    </Flex>
  );
};

MakeLiveModal.displayName =
  'displayName:pages/Locations/AddLocation/MakeLiveModal.tsx';

export default MakeLiveModal;
