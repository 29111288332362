import { AddIcon } from '@chakra-ui/icons';
import { Flex, Text, useModalContext } from '@chakra-ui/react';
import React, { BaseSyntheticEvent, FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../../atoms';
import { ISkillVerifierFormInput } from '../../types';
import { eventBus } from 'shared/eventEmit/eventBus';
import { useClearEvaluate } from '..';
import { useReassignUser } from 'sub-components/training-v2/dashboard/components/TrackPathDashboard/components';
import { IReAssignInfo } from 'sub-components/training-v2/dashboard/components/TrackPathDashboard/components/PathActions/ReAssignTpForUser';
import { AuthRole } from 'sop-commons/src/client';
import { TrainingAccessEnum } from 'types';
import { useUserDataSelector } from 'hooks';
import { useQuery } from '@apollo/client';

interface ResponseFooterProps {
  onSubmitHandler?: (
    e?: BaseSyntheticEvent<object, any, any> | undefined
  ) => Promise<void>;
  mode?: 'goodScore' | 'badScore';
  usedAt: 'submission' | 'summary';
  isLoading?: boolean;
  sessionId?: string;
  userId?: string;
  tpScheduleInfo?: IReAssignInfo;
  tp?: any;
  onEvaluateComplete: (() => void) | undefined;
}

const ResponseFooter: FC<ResponseFooterProps> = ({
  onSubmitHandler,
  mode,
  usedAt,
  isLoading,
  tpScheduleInfo,
  userId,
  sessionId,
  tp,
  onEvaluateComplete,
}) => {
  const { t } = useTranslation(['common']);
  const {
    control,
    formState: { dirtyFields },
    reset,
  } = useFormContext<ISkillVerifierFormInput>();
  const { authRole, loggedInUserEid } = useUserDataSelector((state) => ({
    authRole: state?.authRole,
    loggedInUserEid: state?.eid,
  }));
  const { onClose } = useModalContext();
  const reassignUser = useReassignUser();
  const onClear = useClearEvaluate();
  const [isReassignLoading, setIsReassignLoading] = useState<boolean>(false);

  // Check if the first ratings field is dirty
  const ratings = dirtyFields.skills?.map((skill) => skill.rating) || [];
  const isRatingDirty =
    ratings.length > 0 && ratings.every((skill) => skill === true);

  /**Can current user reassign the assignee */
  const canCurrentUserReassign =
    (authRole === AuthRole.SUPER_ADMIN &&
      tp?.accessPermission?.superadmin?.includes(TrainingAccessEnum.ASSIGN)) ||
    (authRole === AuthRole.ADMIN &&
      tp?.accessPermission?.admin?.includes(TrainingAccessEnum.ASSIGN) &&
      tp?.status !== 'TRAINING_TERMINATED');
  //lo reassing whn he is the creator

  /**
   * Returns the CTA label for a given type based on 'usedAt' and 'mode'
   */
  const getCTA = (type: 'primary' | 'secondary'): string => {
    if (usedAt === 'summary') {
      if (mode === 'goodScore') {
        return type === 'secondary'
          ? canCurrentUserReassign
            ? 'Reassign path'
            : 'Clear'
          : 'Mark as completed';
      }
      return type === 'secondary'
        ? 'Mark as completed'
        : canCurrentUserReassign
        ? 'Reassign path'
        : 'Clear';
    }
    // Default CTA when not in summary mode
    return type === 'secondary' ? 'Clear' : t('common:submit');
  };

  /**
   * Handles the action based on the CTA label.
   * When the CTA is "Mark as completed", it will call onSubmitHandler.
   * If the CTA is "Reassign path", it calls reassignUser.
   * If the CTA is "Clear", it calls onClear.
   */
  const handleAction = async (buttonType: 'primary' | 'secondary') => {
    const cta = getCTA(buttonType);

    if (cta === 'Mark as completed') {
      if (onSubmitHandler) {
        await onSubmitHandler();
        //emitting the completed action for refetching
        eventBus.emit('evalute_flow_completed_refetch_for_training_actions');
        setTimeout(onClose);
      }
    } else if (cta === 'Reassign path') {
      reassignUser({
        sessionId: sessionId!,
        userId: userId!,
        tpScheduleInfo: tpScheduleInfo!,
        onActionCompleted: onEvaluateComplete,
      });
    } else if (cta === 'Clear') {
      onClear({ onOKHandler: reset });
    }
  };

  //catching the Reassign_loader
  useEffect(() => {
    eventBus.on('Reassign_loader', (isReAssignTpLoading) => {
      setIsReassignLoading(isReAssignTpLoading);
    });
    return () => {
      eventBus.off('Reassign_loader', (isReAssignTpLoading) => {
        setIsReassignLoading(isReAssignTpLoading);
      });
    };
  }, [eventBus]);

  return (
    <Flex
      justify='flex-end'
      p='12px 8px'
      gap={4}
      w='full'
      position='absolute'
      bottom='0px'
      right={0}
      bg='rgba(252, 252, 252, 1)'
      borderTop='1px solid rgba(239, 239, 239, 1)'
      borderBottomLeftRadius='12px'
      borderBottomRightRadius='12px'
    >
      <Button
        variant={'solid'}
        colorScheme={
          getCTA('secondary') === 'Mark as completed' && !canCurrentUserReassign
            ? 'blue'
            : 'gray'
        }
        isLoading={
          getCTA('secondary') === 'Reassign path'
            ? isReassignLoading
            : isLoading
        }
        onClick={
          usedAt !== 'summary'
            ? () => onClear({ onOKHandler: reset })
            : () => handleAction('secondary')
        }
        hidden={
          usedAt === 'summary' &&
          !canCurrentUserReassign &&
          getCTA('secondary') === 'Clear'
        }
      >
        {getCTA('secondary')}
      </Button>
      <Button
        variant={'solid'}
        colorScheme={'blue'}
        onClick={() => {
          if (isRatingDirty) {
            eventBus.emit('Rating_submitted');
            handleAction('primary');
          }
        }}
        disabled={!isRatingDirty}
        isLoading={
          getCTA('primary') === 'Reassign path' ? isReassignLoading : isLoading
        }
        hidden={
          usedAt === 'summary' &&
          !canCurrentUserReassign &&
          getCTA('primary') === 'Clear'
        }
      >
        {getCTA('primary')}
      </Button>
    </Flex>
  );
};

ResponseFooter.displayName =
  'displayName:sub-components/training-v2/shared/quiz/components/ResponseFooter.tsx';

export default ResponseFooter;
