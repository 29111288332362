import { gql } from '@apollo/client';
import { JSONContent } from 'delightree-editor';
import { CardType } from '../../../../../../types';

export interface VideoFormInput {
  title: string;
  caption: string;
  videoUrl: string;
  thumbnail: string;
  videoFileSize?: number;
  videoFileName?: string;
}

interface CardAddInputInput {
  cardType: CardType;
  entityId: string;
  title: string;
  thumbnail: string;
  content: JSONContent[];
}

export interface VideoVariable {
  input: CardAddInputInput;
}

export interface VideoResponse {
  createCard: Array<{
    eid: string;
    title: string;
    category: string;
    thumbnail: string;
    cardType: CardType;
  }>;
}

export const CREATE_VIDEO_RESOURCE = gql`
  mutation CreateCard($input: [CardAddInputInput]) {
    createCard(input: $input) {
      eid
      title
      category
      thumbnail
      cardType
    }
  }
`;
