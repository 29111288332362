import { Box, Flex, Text } from '@chakra-ui/react';
import { ReactComponent as Sort_ascendingIcon } from 'assets/images/nexus/compliance/sort_ascending.svg';
import { useUserDataSelector } from 'hooks';
import { FC, useState } from 'react';
import {
  IFinalOverallLocationWiseAnalytics,
  IFinalOverallUserWiseAnalytics,
} from 'sub-components/training-v2/shared/types';
import { sortArray } from '../../locationOwner/helper';
import EmptyLeaderboardState from 'sub-components/training-v2/dashboard/components/EmptyLeaderboardState';

interface IRenderMemberListProps {
  data: any[];
  user: string;
}

export const RenderMemberList: FC<IRenderMemberListProps> = ({
  data,
  user,
}) => {
  return (
    <>
      {data?.length > 0 &&
        data?.map((item, index) => {
          // console.log('item --- ', item);

          const isCurrentUser = item?.userId === user;

          return (
            // @ts-ignore
            <Flex
              key={item?.userId}
              border={'0.5px solid #EFEFEF'}
              p={'12px 24px'}
              borderRadius={'12px'}
              backgroundColor={'white'}
            >
              <Flex
                width={'100%'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <Flex alignItems={'center'} gap={4}>
                  <Flex
                    justifyContent={'center'}
                    width={'24px'}
                    height={'24px'}
                    borderRadius={'50%'}
                    backgroundColor={isCurrentUser ? '#8E59FF' : '#6F767E'}
                  >
                    <Text fontWeight={600} color={'white'}>
                      {/* {index + 4} */}
                      {item?.allUserRank}
                    </Text>
                  </Flex>
                  <Flex gap={2}>
                    <Text
                      fontWeight={600}
                      fontSize={'13px'}
                      color={isCurrentUser ? '#8E59FF' : 'black'}
                    >
                      {isCurrentUser ? 'You' : item?.name}{' '}
                    </Text>
                    {item?.userRole && (
                      <>
                        &bull;
                        <Text fontSize={'13px'} color={'#6F767E'}>
                          {item?.userRole}
                        </Text>
                      </>
                    )}
                  </Flex>
                </Flex>
                <Flex>
                  <Text fontWeight={600} fontSize={'13px'}>
                    {Number(item?.completionPercent)?.toFixed(2)}
                  </Text>
                  <Text color={'#6F767E'}>%</Text>
                </Flex>
              </Flex>
            </Flex>
          );
        })}
    </>
  );
};

interface IProps {
  tab: string;
  overallLocationWiseData?: IFinalOverallLocationWiseAnalytics[] | undefined;
  overallUserWiseData?: IFinalOverallUserWiseAnalytics[] | undefined;
  emptyStateLabel: string;
}

const BaseListRender: FC<IProps> = ({
  tab,
  overallUserWiseData = [],
  emptyStateLabel,
}) => {
  const [sortType, setSortType] = useState<'asc' | 'desc'>('asc');

  const user = useUserDataSelector((state) => state.eid);

  if (overallUserWiseData?.length >= 3) {
    overallUserWiseData = overallUserWiseData?.slice(3);
  }

  const handleSortToggle = () => {
    setSortType((prevState) => {
      if (prevState === 'asc') {
        return 'desc';
      } else {
        return 'asc';
      }
    });
  };

  if ((overallUserWiseData || [])?.length <= 3) {
    return <EmptyLeaderboardState emptyStateLabel={emptyStateLabel} />;
  }

  return (
    <Flex
      flexDir={'column'}
      width={'100%'}
      p={'16px 8px'}
      borderRadius={'12px'}
      backgroundColor={'#EFEFEF'}
    >
      <Flex color={'#6F767E'} px={1} justifyContent={'space-between'}>
        <Text>{tab}</Text>
        <Flex gap={2} alignItems={'center'}>
          <Text>Completion rate</Text>
          <Box cursor={'pointer'} onClick={handleSortToggle}>
            <Sort_ascendingIcon />
          </Box>
        </Flex>
      </Flex>

      <Flex
        maxHeight={'265px'}
        overflowY={'hidden'}
        gap={2}
        flexDir={'column'}
        mt={'10px'}
      >
        <RenderMemberList
          data={sortArray(
            [...(overallUserWiseData || [])],
            'allUserRank',
            sortType
          )}
          user={user}
        />
      </Flex>
    </Flex>
  );
};

export default BaseListRender;
