import React, { useCallback } from 'react';
import { useResourceModal } from '../common';
import SopResourceContent, { SResourceProps } from './SopResourceContent';

type FormResource = (props: SResourceProps) => void;

export const useSopResource = (): FormResource => {
  const resourceModal = useResourceModal();

  return useCallback((props) => {
    resourceModal({
      title: 'Knowledge base',
      content: (
        <SopResourceContent
          {...props}
          onCreateClick={() => window.open('/create-chapter', '_blank')}
        />
      ),
    });
  }, []);
};
