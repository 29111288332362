import React, { FC } from 'react';
import { useFieldArray } from 'react-hook-form';
import { Button, Flex } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { IAssigneeInput } from '../../training-input.types';
import AssigneeGroup from './AssigneeGroup';

interface IProps {
  disabled?: boolean;
}

const AssigneeGroupList: FC<IProps> = ({ disabled }) => {
  const { fields, append, remove } = useFieldArray<IAssigneeInput, 'assignees'>(
    { name: 'assignees' }
  );

  const totalLength = fields.length;

  return (
    <Flex flexDir='column' gap={4} paddingTop={2}>
      {fields.map((field, index) => (
        <AssigneeGroup
          key={field.id}
          index={index}
          totalLength={totalLength}
          disabled={disabled}
          onDeleteClick={disabled ? undefined : () => remove(index)}
        />
      ))}

      {disabled ? null : (
        <Button
          width='max-content'
          variant='outline'
          leftIcon={<AddIcon />}
          onClick={() => {
            append({
              type: null as never,
              roles: [],
              authRoles: [],
              locationIds: [],
              userIds: [],
            });
          }}
        >
          Add another group
        </Button>
      )}
    </Flex>
  );
};

export default AssigneeGroupList;
