import React, { useCallback, useRef } from 'react';
import { useMutation } from '@apollo/client';
import { useLatest } from 'hooks';
import { AppRoute, useHistory } from 'routes';
import { BoxHeader, UseConfirm, useConfirm } from 'ui-components';
import { REASSIGN_TP_USER } from '../../Tracking/query/tracking.graphql';
import ReassignModal from './ReassignModal';

interface IProps {
  trainingId: string;
  sessionId: string;
  details: Record<string, unknown>;
}

type ReassignModalProps = (props: IProps) => void;

export const useReassignModal = (): ReassignModalProps => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();
  const history = useHistory();

  const [reassignRequest] = useMutation(REASSIGN_TP_USER);

  const reassignTp = useCallback(
    async (input: unknown) => {
      const resp = await reassignRequest({
        variables: { input },
      });

      if (resp.errors) {
        return Promise.reject(resp.errors);
      }
    },
    [reassignRequest]
  );

  const assignRef = useLatest(reassignTp);

  return useCallback(({ trainingId, sessionId, details }) => {
    if (confirmRef.current) {
      confirmRef.current.destroy();
    }

    const onContinueClick = async () => {
      await assignRef.current({
        sessionId: sessionId,
        details: details,
        userId: null, // This one is required field
      });
      confirmRef.current?.destroy();
      history.push({
        pathname: AppRoute.TRAINING_PATH_V2_EDIT,
        params: {
          trainingId: trainingId,
        },
        search: '?action=reassign',
      });
    };

    const onClose = () => {
      if (confirmRef.current) {
        confirmRef.current.destroy();
      }
    };

    confirmRef.current = confirm({
      title: <BoxHeader title='Reassign All' color={'#CABDFF'} />,
      content: (
        <ReassignModal onClose={onClose} onContinueClick={onContinueClick} />
      ),
      size: '2xl',
      isCentered: true,
      contentProps: {
        py: '12px',
        px: '8px',
        borderRadius: '16px',
      },
      footer: null,
    });
  }, []);
};
