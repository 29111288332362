import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { Control, Controller, useWatch } from 'react-hook-form';
import { AccessPermissionInput } from '../training-input.types';
import TrainingAccessRuleSection from './TrainingAccessRuleSection';
import PopupSelect from './PopupSelect';
import { AuthRole } from '../../../../../authorization';
import { TrainingAccessEnum } from '../../../../../types';
import { ACCESS_SEPARATOR } from '../../../../../configs';
import AccessViewOnly from './AccessViewOnly';

const AdminOptions = [
  {
    label: 'View, Edit, Assign',
    value: [
      TrainingAccessEnum.VIEW,
      TrainingAccessEnum.EDIT,
      TrainingAccessEnum.ASSIGN,
    ].join(ACCESS_SEPARATOR),
  },
  {
    label: 'View',
    value: [TrainingAccessEnum.VIEW].join(ACCESS_SEPARATOR),
  },
  {
    label: 'Edit',
    value: [TrainingAccessEnum.EDIT].join(ACCESS_SEPARATOR),
  },
  {
    label: 'Assign',
    value: [TrainingAccessEnum.ASSIGN].join(ACCESS_SEPARATOR),
  },
];

const LocOptions = [
  {
    label: 'View, Can cross train',
    value: [TrainingAccessEnum.CAN_CROSSTRAIN].join(ACCESS_SEPARATOR),
  },
  {
    label: 'View, Cannot cross train',
    value: [TrainingAccessEnum.CANNOT_CROSSTRAIN].join(ACCESS_SEPARATOR),
  },
];

interface IProps {
  control: Control<AccessPermissionInput>;
  creatorRole?: AuthRole;
}

const GeneralAccess: FC<IProps> = ({ control, creatorRole }) => {
  const superAdmin = useWatch({
    control: control,
    name: 'superadmin',
  });

  return (
    <Flex flexDir='column' gap={4}>
      <Flex flexDir='column'>
        <Box as='span' fontSize='15px' fontWeight={600} lineHeight='24px'>
          General access
        </Box>
        <Box as='span' fontSize='12px' fontWeight={600} color='#6F767E'>
          For members who are not default supervisors (as listed in the box
          above)
        </Box>
      </Flex>

      <TrainingAccessRuleSection
        title='Superadmins'
        caption='All superadmins can create, edit, assign and view training path'
      >
        <AccessViewOnly value={superAdmin} />
      </TrainingAccessRuleSection>

      <TrainingAccessRuleSection
        title='Admins'
        caption='All admins can create, edit, assign and view training path based on granted access'
      >
        <Controller
          name='admin'
          control={control}
          render={({ field }) => {
            if (!creatorRole || creatorRole === AuthRole.LOCATION_OWNER) {
              return <AccessViewOnly value={field?.value} />;
            }

            return <PopupSelect {...field} options={AdminOptions} />;
          }}
        />
      </TrainingAccessRuleSection>

      <TrainingAccessRuleSection
        title='Location Owners'
        caption='(Only those who have view access or are supervisors)'
      >
        <Controller
          name='locationOwner'
          control={control}
          render={({ field }) => (
            <PopupSelect {...field} options={LocOptions} />
          )}
        />
      </TrainingAccessRuleSection>
    </Flex>
  );
};

export default GeneralAccess;
