import React, { FC, useState } from 'react';
import {
  Badge,
  Box,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowDownShortWide } from '@fortawesome/pro-light-svg-icons';
import { _pathName, _createdBy, _createdOn, _lastActivity } from './constant';
import { useTrainingContext } from '../../../TrainingTableContainer';

function decideDataToRender(columnKey: string) {
  if (columnKey === 'pathName') {
    return _pathName;
  } else if (columnKey === 'createdBy') {
    return _createdBy;
  } else if (columnKey === 'createdOn') {
    return _createdOn;
  } else if (columnKey === 'lastActivity') {
    return _lastActivity;
  }
}

type DataType = {
  eid: string;
  label: string;
  value: string;
};

interface IRenderContentProps {
  data: DataType[];
  columnKey: 'pathName' | 'createdBy' | 'createdOn' | 'lastActivity';
}
export const RenderContent: FC<IRenderContentProps> = ({ data, columnKey }) => {
  const {
    getTrackingTpPaginationData,
    getTrackingTPFilters,
    setSelectedSort,
    selectedSort,
  } = useTrainingContext();

  return (
    <Flex
      flexDir={'column'}
      gap={4}
      alignItems={'center'}
      justifyContent={'center'}
    >
      {data?.map((item) => {
        return (
          <Flex
            onClick={() => {
              const tempObj = {
                value: item?.label === 'No sort' ? '' : item?.value,
                sortType: item?.label === 'No sort' ? '' : columnKey,
              };

              setSelectedSort(tempObj);
              getTrackingTpPaginationData({
                variables: getTrackingTPFilters({ sort: item?.value }),
              });
            }}
            cursor={'pointer'}
            borderRadius={'12px'}
            padding={'6px 12px'}
            backgroundColor={
              item?.label === 'No sort'
                ? ''
                : item?.value === selectedSort?.value
                ? '#EFEFEF'
                : 'white'
            }
            alignItems={'center'}
            justifyContent={'center'}
            key={item?.eid}
            shadow={
              item?.label === 'No sort'
                ? ''
                : item?.value === selectedSort?.value
                ? 'md'
                : ''
            }
          >
            <Text
              fontWeight={600}
              color={
                item?.label === 'No sort'
                  ? '#6F767E'
                  : item?.value === selectedSort?.value
                  ? ''
                  : '#6F767E'
              }
              minWidth={'100px'}
            >
              {item?.label}
            </Text>
          </Flex>
        );
      })}
    </Flex>
  );
};

interface IProps {
  columnKey: 'pathName' | 'createdBy' | 'createdOn' | 'lastActivity';
}

const SortUI: FC<IProps> = ({ columnKey }) => {
  const { selectedSort } = useTrainingContext();
  const data = decideDataToRender(columnKey);

  return (
    <Popover placement='right-end' isLazy>
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <Box>
              <FontAwesomeIcon
                color={
                  selectedSort?.sortType === columnKey ? '#2A85FF' : '#6F767E'
                }
                cursor={'pointer'}
                icon={faArrowDownShortWide as IconProp}
              />
            </Box>
          </PopoverTrigger>
          <PopoverContent
            width={'170px'}
            minHeight={'150px'}
            p={'10px'}
            borderRadius={'12px'}
          >
            <PopoverArrow />
            <PopoverBody>
              <RenderContent data={data} columnKey={columnKey} />
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};

export default SortUI;
