import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { AlertBox } from '../../../../../../../../atoms';
import FillBlankOptionsList from './FillBlankOptionsList';

interface IProps {}

const FillBlanksInput: FC<IProps> = () => {
  return (
    <Flex flexDir='column' gap={3}>
      <AlertBox icon={faCircleExclamation}>
        <strong>Notes:-</strong> Please enter continuous multiple underscores{' '}
        <strong>(___)</strong> in place where the answer should be filled.
      </AlertBox>

      <FillBlankOptionsList />
    </Flex>
  );
};

export default FillBlanksInput;
