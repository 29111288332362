import { Flex, useModalContext } from '@chakra-ui/react';
import { FC, useEffect } from 'react';
import { eventBus } from 'shared/eventEmit';
import { IReAssignInfo } from 'sub-components/training-v2/dashboard/components/TrackPathDashboard/components/PathActions/ReAssignTpForUser';
import { TSkillVerifierItem } from '../../../useEvaluateResponses';

import { SkillVerifier } from './index';

const EvaluateContainerContent: FC<{
  skill: TSkillVerifierItem;
  trainingId: string;
  userId: string;
  sessionId: string;
  maxScore: number;
  score: number;
  userName: string;
  tpScheduleInfo: IReAssignInfo;
  hasSkillVerifier: boolean;
  onEvaluateComplete?: () => void;
  tp?: any;
}> = ({
  skill,
  userId,
  trainingId,
  sessionId,
  maxScore,
  score,
  userName,
  tpScheduleInfo,
  hasSkillVerifier,
  onEvaluateComplete,
  tp,
}) => {
  const { onClose: closeEvaluateModal } = useModalContext();
  useEffect(() => {
    eventBus.on('Reassign_for_user_submmitted_from_evaluate_modal', () => {
      setTimeout(closeEvaluateModal);
    });

    return () => {
      eventBus.off('Reassign_for_user_submmitted_from_evaluate_modal', () => {
        setTimeout(closeEvaluateModal);
      });
    };
  }, [eventBus]);

  return (
    //ScoreContainer
    //SkillVerifierContainer
    <>
      <Flex
        flexDir={'column'}
        gap={4}
        p={'40px 24px'}
        h='80vh'
        overflow={'scroll'}
      >
        <SkillVerifier
          skill={skill}
          userId={userId}
          trainingId={trainingId}
          sessionId={sessionId}
          maxScore={maxScore}
          score={score}
          userName={userName}
          tpScheduleInfo={tpScheduleInfo}
          hasSkillVerifier={hasSkillVerifier}
          onEvaluateComplete={onEvaluateComplete}
          tp={tp}
        />
      </Flex>
    </>
  );
};

export default EvaluateContainerContent;
