import { Flex } from '@chakra-ui/react';
import { TRAINING_PATH_V2_CREATE } from 'appRoutes';
import { Button } from 'atoms';
import { Authorize, AuthRole } from 'authorization';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'routes';
import useCombinedStore from 'zustandStore/store';
import LocationFilter from './locationFilter/LocationFilter';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';

const Heading: FC = () => {
  const [selectedLocationId, setSelectedLocationId] = useState<string>('all');
  const { trainingCategoryIndex } = useCombinedStore();
  const { t } = useTranslation(['training']);
  const history = useHistory();

  return (
    <Flex width='full' justify='space-between'>
      <div style={{ width: '50%', fontSize: '28px', fontWeight: '700' }}>
        {t('training')}
      </div>

      <Flex alignItems={'center'} gap={4}>
        {trainingCategoryIndex === 0 && (
          <Authorize
            permittedFor={'user'}
            permittedRoles={[AuthRole.LOCATION_OWNER]}
          >
            <LocationFilter
              setSelectedLocationId={setSelectedLocationId}
              selectedLocationId={selectedLocationId}
            />
          </Authorize>
        )}

        <Authorize
          permittedFor={'user'}
          permittedRoles={[
            AuthRole.SUPER_ADMIN,
            AuthRole.ADMIN,
            AuthRole.LOCATION_OWNER,
          ]}
        >
          <Button
            variant='outline'
            colorScheme='blue'
            onClick={() => {
              history.push({ pathname: TRAINING_PATH_V2_CREATE });
              deployEvent(AmplitudeEventNames.ADD_NEW_TRAINING_PATH, {
                event: {
                  add_new_path_funnel_id: 4,
                },
              });
            }}
          >
            Add new path
          </Button>
        </Authorize>
      </Flex>
    </Flex>
  );
};

Heading.displayName =
  'displayName:sub-components/training-v2/dashboard/components/Heading';

export default Heading;
