import React, { FC } from 'react';
import { Box } from '@chakra-ui/react';
import { useQuery } from '@apollo/client';
import { CardType } from '../../../../../../types';
import TrainingCard from '../../../../../TrainingCard';

import { FORM_PREVIEW_QUERY } from './form-resource.graphql';
import PreviewEmpty from './PreviewEmpty';

interface IProps {
  formId?: string;
}

const FormPreview: FC<IProps> = ({ formId }) => {
  const { loading, data } = useQuery(FORM_PREVIEW_QUERY, {
    skip: !formId,
    variables: {
      eid: formId,
    },
  });

  const previewData = data?.FormById;

  return (
    <Box flex={1} overflow='hidden'>
      {previewData?.eid ? (
        <TrainingCard
          content={{
            cardType: CardType.CustomForm,
            content: [{ ...previewData }],
          }}
          isPreview
        />
      ) : (
        <PreviewEmpty
          isLoading={loading}
          emptyTitle={`Select a form to \n preview`}
        />
      )}
    </Box>
  );
};

export default React.memo(FormPreview);
