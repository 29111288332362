import React, { FC } from 'react';
import FormResponseTab from './FormResponseTab';

interface IProps {
  activeTabIndex: number;
  searchValue: string;
  categoryFilter: string[];
}

const FormResponseTabPanel: FC<IProps> = ({
  activeTabIndex,
  searchValue,
  categoryFilter,
}) => {
  return (
    <FormResponseTab
      activeTabIndex={activeTabIndex}
      searchValue={searchValue}
      categoryFilter={categoryFilter}
    />
  );
};

FormResponseTabPanel.displayName =
  'displayName:pages/forms/modules/FormListInternal/FormResponseTabPanel';

export default FormResponseTabPanel;
