import React, { FC, useCallback } from 'react';
import { usePlayContext } from 'sub-components/training-v2/play/store/context';
import SkillVerifier from './SkillVerifier';
import EvaluateDetailScreen from './EvaluateDetailScreen';

interface EvaluationScreenProps {}

const EvaluationScreen: FC<EvaluationScreenProps> = () => {
  const { tpSessionData } = usePlayContext((state) => ({
    tpSessionData: state.tpSessionData,
  }));

  const render = useCallback(() => {
    if (tpSessionData?.hasSkillVerifier) {
      return <SkillVerifier />;
    }
    return <EvaluateDetailScreen />;
  }, [tpSessionData]);

  return render();
};

export default EvaluationScreen;
