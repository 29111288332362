import React, { ChangeEvent, forwardRef } from 'react';
import { Flex, IconButton } from '@chakra-ui/react';

import { ReactComponent as ImageIcon } from '../../../../../../../../assets/images/image.svg';

interface IProps {
  inputId: string;
  onImageChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const ThumbnailSelect = forwardRef<HTMLInputElement, IProps>(
  ({ inputId, onImageChange }, inputRef) => {
    return (
      <Flex>
        <IconButton
          as='label'
          size='xs'
          aria-label='add-image'
          variant='outline'
          borderRadius='4px'
          borderWidth='1px'
          icon={<ImageIcon width={16} height={16} />}
          htmlFor={inputId}
          cursor='pointer'
        />
        <input
          ref={inputRef}
          type='file'
          onChange={onImageChange}
          id={inputId}
          style={{ display: 'none' }}
          accept='image/*'
        />
      </Flex>
    );
  }
);

ThumbnailSelect.displayName = 'ThumbnailSelect';

export default ThumbnailSelect;
