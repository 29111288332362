import { Box, Center, Flex, Image } from '@chakra-ui/react';
import CertificateView from 'assets/images/certificate-view.svg';
import React, { FC } from 'react';
import { usePlayContext } from 'sub-components/training-v2/play/store/context';
import { Button } from 'atoms';
import { useDownloadCertificate } from 'sub-components/training-v2/shared/download-certificate';

const CertificateScreen: FC = () => {
  const { tpSessionData, retake } = usePlayContext((state) => ({
    tpSessionData: state.tpSessionData,
    retake: state.retake,
  }));
  const downloadCertificate = useDownloadCertificate();

  return (
    <Flex flexDir='column' w='60%' justify='space-between' h='full' p={4}>
      <Flex flexDir='column' gap={4}>
        <Center flexDir='column' fontWeight={600} fontSize='32px'>
          <Box as='span' textAlign='center'>
            You have earned a new certificate!
          </Box>
          <Image src={CertificateView} h='300px' />
        </Center>
      </Flex>
      <Flex align='center' gap={4}>
        <Button variant='outline' w='50%' size='lg' onClick={retake}>
          Retake path
        </Button>
        <Button
          variant='solid'
          colorScheme='blue'
          w='50%'
          size='lg'
          onClick={() => {
            downloadCertificate({
              certificateId: tpSessionData?.userProgress?.[0]?.certificate,
              tpSessionId: tpSessionData?.eid,
            });
          }}
        >
          Download certificate
        </Button>
      </Flex>
    </Flex>
  );
};

export default CertificateScreen;
