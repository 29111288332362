import { useCallback, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { userObj } from 'sop-commons/src';
import { AuthRole } from '../../../../../authorization';
import { TrainingAccessEnum } from '../../../../../types';
import { TFormInput } from '../training-input.types';

const useAccessCheck = () => {
  const { getValues } = useFormContext<TFormInput>();
  const [createdBy, isReassigning, status] = useWatch<
    TFormInput,
    ['createdBy', 'isReassigning', 'status']
  >({ name: ['createdBy', 'isReassigning', 'status'] });

  return useCallback(
    (accessType: TrainingAccessEnum) => {
      if (!status || status === 'TRAINING_DRAFT' || !createdBy) {
        return undefined;
      }
      if (createdBy === userObj().eid) {
        return true;
      }

      const authRole = userObj().authRole as Exclude<
        AuthRole,
        AuthRole.WORKER | AuthRole.LOCATION_USER
      >;

      const accessData = getValues(`accessPermission.${authRole}`);

      // If training is reassigning then only assignee part will be editable
      if (isReassigning && accessData?.length) {
        return accessData.includes(TrainingAccessEnum.ASSIGN);
      }

      if (accessData?.length) {
        return accessData.includes(accessType);
      }
      return false;
    },
    [status, createdBy, isReassigning]
  );
};

// export const userCanEdit = (): boolean | undefined => {
//   const accessCheck = useAccessCheck();
//
//   return useMemo(() => {
//     return accessCheck(TrainingAccessEnum.EDIT);
//   }, [accessCheck]);
// };

export const useEditDisabled = (): boolean | undefined => {
  const accessCheck = useAccessCheck();

  return useMemo(() => {
    const access = accessCheck(TrainingAccessEnum.EDIT);
    if (access === undefined) {
      return undefined;
    }
    return !access;
  }, [accessCheck]);
};

// export const userCanAssign = (): boolean | undefined => {
//   const accessCheck = useAccessCheck();
//
//   return useMemo(() => {
//     return accessCheck(TrainingAccessEnum.ASSIGN);
//   }, [accessCheck]);
// };

export const userAssignDisabled = (): boolean | undefined => {
  const accessCheck = useAccessCheck();

  return useMemo(() => {
    const access = accessCheck(TrainingAccessEnum.ASSIGN);
    if (access === undefined) {
      return undefined;
    }
    return !access;
  }, [accessCheck]);
};
