import React, { FC, useState } from 'react';
import { FormControl, FormErrorMessage } from '@chakra-ui/react';
import { Controller } from 'react-hook-form';
import { TAssignByType } from '../../../../../../../types';
import { toArray } from '../../../../../../../utils';
import { IAssigneeInput } from '../../../training-input.types';
import GroupLocationSelect from '../GroupLocationSelect';
import LocationRoleSelect from './LocationRoleSelect';
import LocationAuthRoleSelect from './LocationAuthRoleSelect';
import AddSelectButton from './AddSelectButton';

interface IProps {
  assignBy: TAssignByType;
  index: number;
  disabled?: boolean;
}

const LocationGroupSelect: FC<IProps> = ({ index, disabled, assignBy }) => {
  const [type, setType] = useState<TAssignByType>();

  return (
    <>
      <Controller<IAssigneeInput, `assignees.${number}.locationIds`>
        name={`assignees.${index}.locationIds`}
        disabled={disabled}
        rules={{
          // required: 'Select assignee locations',
          validate: async (value, formData) => {
            await new Promise((resolve) => setTimeout(resolve, 100));
            const _val = toArray(value);
            const locType =
              formData?.assignees?.[index]?.locationType || 'custom';
            if (locType === 'custom' && _val?.length === 0) {
              return 'Select locations for assignee';
            }
          },
        }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid}>
            <GroupLocationSelect index={index} assignBy={assignBy} {...field} />
            <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
          </FormControl>
        )}
      />

      <AddSelectButton index={index} value={type} onChange={setType} />

      <LocationRoleSelect
        type={type}
        removeType={() => setType(undefined)}
        index={index}
        disabled={disabled}
      />

      <LocationAuthRoleSelect
        type={type}
        removeType={() => setType(undefined)}
        index={index}
        disabled={disabled}
      />
    </>
  );
};

export default LocationGroupSelect;
