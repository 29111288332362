import { TranslatedData } from './base-card.types';
import { IEmojiStructure } from 'delightree-editor';
import { AuthRole } from '../authorization';

export type TrainingStatusOld = 'active' | 'inactive' | 'archieved' | 'draft';

export type TAssignByType = 'role' | 'authRole' | 'location' | 'user';
export type TAssigneeUserType = 'old' | 'new' | 'oldAndNew';

export type TrainingStatus =
  | 'TRAINING_DRAFT'
  | 'TRAINING_CREATED'
  | 'TRAINING_STARTED'
  | 'TRAINING_ONE_TIME'
  | 'TRAINING_REPEAT'
  | 'TRAINING_COMPLETED'
  | 'TRAINING_TERMINATED'
  | 'TRAINING_DELETED';

export type TRepeatType =
  | 'EVERY_N_DAYS'
  | 'EVERY_N_WEEKS'
  | 'EVERY_N_MONTHS'
  | 'EVERY_N_YEARS';

export enum QuizQuestionType {
  MULTI_CHOICE = 'multiChoice',
  TRUE_FALSE = 'trueFalse',
  MATCH_SET = 'matchSet',
  /**
   *
   * @type {QuizQuestionType.SEQUENCE}
   * @alias REORDER
   */
  SEQUENCE = 'sequence',
  FILL_IN_BLANK = 'fillInBlanks',
}

export interface TDuration {
  value: number;
  type: 'days' | 'weeks' | 'months' | 'years';
}

export enum TrainingAccessEnum {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
  ASSIGN = 'ASSIGN',
  CAN_CROSSTRAIN = 'CAN_CROSSTRAIN',
  CANNOT_CROSSTRAIN = 'CANNOT_CROSSTRAIN',
}

export interface TAccessPermission {
  admin: TrainingAccessEnum[];
  locationOwner: TrainingAccessEnum[];
  superadmin: TrainingAccessEnum[];
}

export type TAssLocationType = 'all' | 'open' | 'development' | 'custom';

export interface TAssignee {
  type: TAssignByType;
  authRoles: AuthRole[];
  locationIds: string[];
  roles: string[];
  userIds: string[];
  locationType?: TAssLocationType;
}

export interface TRepeatCycle {
  type: TRepeatType;
  days?: number;
  weeks?: number;
  months?: number;
  years?: number;

  dayOfTheWeek?: number;
  dayOfTheMonth?: number;
  monthOfTheYear?: number;
}

export enum TrainingContType {
  SOP = 'sop',
  CARD = 'card',
  QUIZ = 'quiz',
  FORM = 'form',
  /**
   * @deprecated
   * @type {TrainingContType.SOP}
   */
  DECK = 'deck',
  MILESTONE = 'milestone',
}

export interface TrainingItemEntity {
  type: string;
  eid: string;
  uid: string;
  content?: null[];
  icon: IEmojiStructure;
  title: string;
  isMultiPage: boolean;
  createdAt: string;
  updatedAt: string;
  sopType: string;
  lastUpdated: string;
  visibility: string;
  files?: null[];
  media?: null[];
  categoryId: string;
  category: string;
  thumbnail: string;
  status: string;
  smartPageEnabled: boolean;
  translations: Record<string, TranslatedData>;
  cards?: CardsEntity[];
  cardView?: CardViewEntity[];
  dateAdded: string;
}

// TODO types
export interface CardsEntity {
  type: string;
  eid: string;
  uid: string;
  title: string;
  subTitle: string;
  content?: null[];
  createdAt: string;
  updatedAt: string;
  lastUpdated: string;
  formId: string;
  visibility: string;
  createdBy: string;
  category: string;
  thumbnail: string;
  status: string;
  layout: string;
  cardType: string;
  files?: null[] | null;
  media?: null[] | null;
  defaultLanguage: string;
  backgroundColor: string;
  form: string;
  translations: Record<string, TranslatedData>;
}

// TODO types
export interface CardViewEntity {
  type: string;
  eid: string;
  uid: string;
  title: string;
  subTitle: string;
  content?: null[] | null;
  createdAt: string;
  updatedAt: string;
  lastUpdated: string;
  formId: string;
  visibility: string;
  createdBy: string;
  category: string;
  thumbnail: string;
  status: string;
  layout: string;
  cardType: string;
  files?: null[];
  media?: null[];
  defaultLanguage: string;
  backgroundColor: string;
  form: string;
  translations: Record<string, TranslatedData>;
}
