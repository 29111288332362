import React, { FC, useCallback, useMemo } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';

import { SelectOption } from '../../../../../atoms';

import { MemberInput } from '../create-channel.types';

import { useJobRoleLocationList } from './useJobLocationData';

export const Colors: Record<string, string> = {
  job: '#EC811D',
  location: '#83BF6E',
  role: '#8E59FF',
};

export const BG: Record<string, string> = {
  job: '#FFD88D4D',
  location: '#B5E4CA4D',
  role: '#CABDFF4D',
};

interface IProps {}

const SelectedTags: FC<IProps> = () => {
  const _options = useJobRoleLocationList();

  const { getValues, setValue } = useFormContext<MemberInput>();

  const addToChannel = useWatch<MemberInput, 'addToChannel'>({
    name: 'addToChannel',
  });

  const allData = useMemo(() => {
    return _options.filter((item) => addToChannel?.includes(item.value));
  }, [_options, addToChannel]);

  const handleRemove = useCallback((item: SelectOption) => {
    setValue(
      'addToChannel',
      [...getValues('addToChannel')].filter((val) => val !== item.value),
      {
        shouldValidate: true,
        shouldDirty: true,
      }
    );
  }, []);

  if (!allData?.length) {
    return null;
  }

  return (
    <Flex align='center' gap='10px' wrap='wrap'>
      {allData.map((item) => (
        <Flex
          key={item?.value}
          align='center'
          bg={BG[item.type!]}
          p='3px 10px'
          borderRadius='4px'
          gap='5px'
        >
          <Text
            as='span'
            fontWeight='600'
            pr='3px'
            noOfLines={1}
            fontSize='12px'
            color={Colors[item.type!]}
            textTransform='capitalize'
          >
            {item?.label}
          </Text>
          <Box
            cursor='pointer'
            fontSize='18px'
            fontWeight='500'
            lineHeight='24px'
            onClick={() => handleRemove(item)}
            px='2px'
          >
            &times;
          </Box>
        </Flex>
      ))}
    </Flex>
  );
};

export default SelectedTags;
