import React, { forwardRef } from 'react';
import { Button, ButtonProps, useFormControl } from '@chakra-ui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { useButtonProps } from '../../../../../../tasks/create-task/Scheduler/useButtonProps';

export interface PickerButtonProps extends ButtonProps {
  isSelected?: boolean;
  readOnly?: boolean;
}

const PickerButton = forwardRef<never, PickerButtonProps>(
  ({ isSelected, value, onClick, placeholder, ...rest }, ref) => {
    const controlProps = useFormControl(rest);

    const otherProps = useButtonProps({
      isReadOnly: controlProps.readOnly,
      isSelected: isSelected,
    });

    return (
      <Button
        size='lg'
        fontSize='14px'
        fontWeight='500'
        minWidth='180px'
        justifyContent='space-between'
        borderRadius='12px'
        rightIcon={<FontAwesomeIcon icon={faCalendar as IconProp} />}
        {...otherProps}
        {...controlProps}
        onClick={onClick}
        ref={ref}
        _invalid={{
          borderColor: 'red.300',
          boxShadow: '0 0 0 0.5px #FF6A55',
          color: 'red.500',
        }}
      >
        {value ? value : placeholder}
      </Button>
    );
  }
);

PickerButton.displayName = 'Picker Button';

export default PickerButton;
