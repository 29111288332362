import { Button, Center, Flex, Text } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowDownToLine } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import React, { FC, useEffect, useMemo } from 'react';
import { getCategoriesColorCode } from '../../../TrackPathDashboard/bottomSection/helper';
import { useDownloadCertificate } from 'sub-components/training-v2/shared/download-certificate';
import Loader from 'sub-components/Loader';
import { useUserDataSelector } from 'hooks';
import TrainingItemCell from './TrainingItemCell';
import { IProps, TableItem } from './types';
import { useSortableData } from './useSortableData';
import SortableColumnHeader from './SortableColumnHeader';
import { useEvaluationsData } from './useEvaluationsData';

const ModalContent: FC<IProps> = ({
  trainingCategories = [],
  onNavigateHandler,
  setNewEvaluationReceivedCount,
}) => {
  const { loggedInUserEid } = useUserDataSelector((state) => ({
    loggedInUserEid: state?.eid,
  }));
  const downloadCertificate = useDownloadCertificate();
  const categoriesColorCode = getCategoriesColorCode(trainingCategories);

  const { sortConfig, updateSort } = useSortableData({
    order: 'none',
    value: 'CREATEDAT_DESC',
    sortLabel: 'sort asc',
  });

  const { data, loading, error, fetchData } = useEvaluationsData(
    loggedInUserEid,
    trainingCategories,
    sortConfig
  );

  useEffect(() => {
    fetchData();
  }, []);

  const handleSort = () => {
    updateSort();
  };

  const columns = useMemo<ColumnsType<TableItem>>(
    () => [
      {
        title: (
          <SortableColumnHeader
            title='Path Name'
            sortConfig={sortConfig}
            onSort={handleSort}
          />
        ),
        dataIndex: 'title',
        key: 'title',
        width: '33%',
        render: (text, record) => (
          <TrainingItemCell
            record={record}
            text={text}
            categoriesColorCode={categoriesColorCode}
            onNavigate={onNavigateHandler}
          />
        ),
      },
      {
        title: (
          <Text fontSize='12px' fontWeight={500} color='#6F767E'>
            Score
          </Text>
        ),
        dataIndex: 'score',
        key: 'score',
        width: '33%',
        render: (score) => (
          <Text fontSize='15px' fontWeight={500}>
            {(score || 0).toFixed(2)}%
          </Text>
        ),
      },
      {
        title: (
          <Text fontSize='12px' fontWeight={500} color='#6F767E'>
            Action
          </Text>
        ),
        key: 'action',
        width: '33%',
        render: (_, record) => (
          <Button
            variant='outline'
            bg='white'
            onClick={() => {
              if (record?.certificateId && record?.eid) {
                downloadCertificate({
                  certificateId: record.certificateId,
                  tpSessionId: record.eid,
                  isCertificateDownloaded: record.certificateDownloaded,
                });
              }
            }}
            rightIcon={<FontAwesomeIcon icon={faArrowDownToLine as IconProp} />}
          >
            Download certificate
          </Button>
        ),
      },
    ],
    [sortConfig, categoriesColorCode, downloadCertificate]
  );

  if (error) {
    return (
      <Center h='60vh'>
        <Text color='red.500'>Error loading data. Please try again.</Text>
      </Center>
    );
  }

  return (
    <Flex p={4} direction='column' gap={4} maxH='50vh' overflowY='auto'>
      <Table
        loading={loading && { indicator: <Loader /> }}
        columns={columns}
        dataSource={data}
        rowKey='eid'
        pagination={false}
        locale={{
          emptyText: (
            <Text color='gray.500' p={4}>
              No evaluations pending certificate download
            </Text>
          ),
        }}
      />
    </Flex>
  );
};

export default ModalContent;
