import React, { FC } from 'react';
import { Accordion, Flex } from '@chakra-ui/react';
import { useWatch } from 'react-hook-form';
import { AmplitudeEvent, deployEvent } from '../../../../../shared';
import { ITaskInput } from './task-form.types';

const tabNames = [
  'Task Details',
  'Steps and Assignee Details',
  'Activation Details',
];

const getTabName = (index: number): string => tabNames[index] || '';

interface IProps {}

const TaskFormContainer: FC<IProps> = ({ children }) => {
  const taskEid = useWatch<ITaskInput, 'eid'>({
    name: 'eid',
  });
  const taskTitle = useWatch<ITaskInput, 'title'>({
    name: 'title',
  });

  return (
    <Accordion
      as={Flex}
      flexDir='column'
      gap={6}
      defaultIndex={0}
      reduceMotion
      allowToggle
      onChange={(selected) => {
        if (selected !== -1) {
          deployEvent(AmplitudeEvent.LAUNCHER_ADD_TASK_SECTION_CLICK, {
            board_creation_funnel_id: 5,
            task_id: taskEid,
            task_title: taskTitle,
            tab_name: getTabName(selected as number),
          });
        }
      }}
    >
      {children}
    </Accordion>
  );
};

TaskFormContainer.displayName =
  'sub-components/Launcher/launcher-config/common/config-task-form/TaskFormContainer';

export default TaskFormContainer;
