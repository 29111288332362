import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  Controller,
  FormProvider,
  useForm,
  useFormState,
} from 'react-hook-form';
import {
  Box,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  useToast,
} from '@chakra-ui/react';
import { CometChat } from '@cometchat-pro/chat';
import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';

import TitleHeader from '../../../../../../sub-components/CardEditor/TitleHeader';
import { CustomSelectComponent } from '../../../../../../sub-components/CustomDropdowns';
import { SelectGroup, useJobRoleLocationList } from '../../select-group';
import { ActionButton } from '../../../../../../ui-components';
import { MemberInput } from '../../create-channel.types';
import { ChatGroupEntity } from '../../../../../../types';
import { schema } from '../../validatior';
import {
  UPDATE_GROUP_QUERY,
  UpdateGroupResponse,
  UpdateGroupVariable,
} from '../../create-channel.graphql';
import { useTranslation } from 'react-i18next';
import { SelectOption } from '../../../../../../sub-components/tasks/create-task/common';
import { useMembersData } from '../../../../../../sub-components/tasks/create-task/Supervisors';
import { useUserDataSelector } from '../../../../../../hooks';
import { toArray } from '../../../../../../utils/utils';

import { mapAddToChannel } from '../../format-data';
import { getGroupMembers } from '../../useChatGroupData';
import { useGroupMemberPermission } from '../../useSubmitChannel';
import { useMemberData } from '../../channel-members/useMemberData';

interface IProps {
  initialValue: ChatGroupEntity;
  groupId: string;
  closeModal?: () => void;
  onMemberUpdated?: (
    data?: UpdateGroupResponse['updateChatGroupByGuid']
  ) => void;
}

const AddMemberContent: FC<IProps> = ({
  initialValue,
  groupId,
  onMemberUpdated,
}) => {
  const { t } = useTranslation(['common', 'setting', 'invite', 'task']);
  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  });

  const getGroupPermission = useGroupMemberPermission();

  const addToChannel = useMemo(() => {
    const details = initialValue?.details;

    return [
      ...(details?.authRoles || []),
      ...(details?.roles || []),
      ...(details?.locationIds || []),
    ];
  }, [initialValue?.details]);

  const methods = useForm<
    MemberInput & {
      initialValue: ChatGroupEntity;
      groupName: string;
    }
  >({
    resolver: yupResolver(schema as never),
    defaultValues: {
      groupName: initialValue.name,
      initialValue: initialValue,
      addToChannel: addToChannel,
      autoAddMember: !!addToChannel.length,
    },
  });

  const entityId = useUserDataSelector((state) => state?.entityId);

  const [fetching, setFetching] = useState(false);
  const [members, setMembers] = useState<SelectOption[]>([]);

  const { getUsersData, loading } = useMembersData(setMembers);

  const groupOptions = useJobRoleLocationList();

  const { reFetchData } = useMemberData(groupId);

  useEffect(() => {
    getUsersData({
      variables: {
        entityId: entityId,
        status: ['active'],
        type: ['user'],
      },
    });
  }, [entityId]);

  useEffect(() => {
    if (initialValue) {
      setFetching(true);
      getGroupMembers(initialValue.guid, initialValue.membersCount + 5)
        .then((value) => {
          const directMembers = initialValue?.details?.directMembers || [];
          const s = value.reduce<string[]>((previousValue, currentValue) => {
            // @ts-ignore
            if (directMembers.includes(currentValue.uid)) {
              // @ts-ignore
              previousValue.push(currentValue.uid);
            }
            return previousValue;
          }, []);
          methods.setValue('assignedToUser', s);
          setFetching(false);
        })
        .catch((error) => {
          console.log('getGroupMembers  ==> ', error);
          setFetching(false);
        });
    }
  }, [initialValue]);

  const { isDirty } = useFormState({
    control: methods.control,
  });

  const [updateMember] = useMutation<UpdateGroupResponse, UpdateGroupVariable>(
    UPDATE_GROUP_QUERY,
    {
      onCompleted: (response) => {
        const data = response?.updateChatGroupByGuid;
        if (data) {
          onMemberUpdated?.(data);
          const values = methods.getValues('initialValue');
          methods.setValue('initialValue', {
            ...values,
            details: {
              ...values.details,
              directMembers: data.directMembers,
              locationIds: data.locationIds,
              roles: data.roles,
              authRoles: data.authRoles,
            },
          });
          reFetchData();
        }
      },
    }
  );

  const onSubmit = async (values: MemberInput) => {
    try {
      const directMembers =
        methods.getValues('initialValue.details.directMembers') || [];

      const newMember = toArray(values.assignedToUser).filter(
        (d) => !directMembers.includes(d)
      );

      if (newMember?.length) {
        const inviteConfig = methods.getValues(
          'initialValue.metadata.inviteUser'
        );
        const members = newMember.map(
          (memberId) =>
            new CometChat.GroupMember(
              memberId,
              getGroupPermission(memberId, inviteConfig)
            )
        );

        await CometChat.addMembersToGroup(groupId, members, []);
      }

      const kickMembers = directMembers.reduce<Promise<boolean>[]>(
        (acc, memberId) => {
          if (
            !values.assignedToUser.includes(memberId) &&
            memberId !== initialValue.owner.eid
          ) {
            acc.push(CometChat.kickGroupMember(groupId, memberId));
          }
          return acc;
        },
        []
      );
      if (kickMembers?.length) {
        await Promise.all(kickMembers);
      }

      const channelData = mapAddToChannel(
        groupOptions,
        values.addToChannel,
        values.autoAddMember
      );

      await updateMember({
        variables: {
          guid: groupId,
          directMembers: values.assignedToUser || [],
          ...channelData,
        },
      });

      toast({
        status: 'success',
        title: t('common:success'),
        description: t('setting:memberUpdateSuccess'),
      });
    } catch (e) {
      toast({
        status: 'error',
        title: t('common:error'),
        description: t('setting:memberUpdateError'),
      });
      return Promise.reject(e);
    }
  };

  return (
    <FormProvider {...methods}>
      <Flex flexDir='column' gap={4}>
        <Box>
          <TitleHeader title={t('setting:addMembers')} />

          <Controller
            control={methods.control}
            name='assignedToUser'
            render={({ field, fieldState }) => {
              return (
                <FormControl mt={2} isInvalid={!!fieldState?.error}>
                  <CustomSelectComponent
                    placeholder={t('task:placeholder.selectMembers')}
                    placeholderColor='#1A1D1F'
                    headerTitle={t('task:placeholder.selectMembers')}
                    bottomOutsideBtn={{
                      show: true,
                      title: t('task:placeholder.addSelectMembers'),
                    }}
                    headerBarColor='#CABDFF'
                    searchFieldPlaceholder={t('setting:searchByNameJobOrLoc')}
                    options={members}
                    showFilter
                    value={field.value}
                    showDivider
                    showSelectAll
                    showSearchField
                    showHeader
                    showHeaderCloseIcon
                    onChange={field.onChange}
                    loading={loading || fetching}
                  />
                  <FormErrorMessage>
                    {fieldState?.error?.message}
                  </FormErrorMessage>
                </FormControl>
              );
            }}
          />
        </Box>

        <Flex
          flexDir='column'
          gap={4}
          padding='16px 20px'
          bg='#F7F7F7'
          borderRadius='10px'
        >
          <Controller
            control={methods.control}
            name='autoAddMember'
            render={({ field }) => {
              return (
                <Checkbox
                  color='#33383F'
                  maxW='fit-content'
                  isChecked={field.value}
                  onChange={field.onChange}
                  ref={field.ref}
                >
                  <Box fontSize='14px'>
                    {t('setting:autoAddNewMemberToChannel')}
                  </Box>
                </Checkbox>
              );
            }}
          />

          <SelectGroup />
        </Flex>

        <Flex pb={2} gap={3}>
          <ActionButton
            flex={1}
            fontSize='15px'
            colorScheme='blue'
            isDisabled={!isDirty}
            actionFn={methods.handleSubmit(onSubmit)}
          >
            {t('setting:updateMembers')}
          </ActionButton>
        </Flex>
      </Flex>
    </FormProvider>
  );
};

export default AddMemberContent;
