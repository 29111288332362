import React, { FC } from 'react';
import { Stack, Text, Flex, Box } from '@chakra-ui/react';
import { FlexCard } from 'atoms';
import { IAverageTpDetailsProps } from 'sub-components/training-v2/shared/types';

const AverageTpDetails: FC<IAverageTpDetailsProps> = ({
  averageTPData: { averageScore, meta },
}) => {
  return (
    <>
      {averageScore ? (
        /* RENDER THIS IF averageScore IS DEFINED */
        <Flex flexDir='column' gap={6} w='40%'>
          {/* Average Score Card */}
          <FlexCard
            fontWeight={600}
            border='2px solid #EFEFEF'
            justifyContent='space-between'
            alignItems='center'
            p='28px 24px'
            w='full'
          >
            <Text fontSize='15px' color='#6F767E'>
              Average Score
            </Text>
            <Text color='#8E59FF' fontSize='20px'>
              {averageScore}%
            </Text>
          </FlexCard>

          {/* Additional cards based on meta */}
          <Flex gap={2} w='full'>
            {meta.map((item, index) => (
              <FlexCard
                key={index}
                minH='240px'
                flexDir='column'
                fontWeight={600}
                border='2px solid #EFEFEF'
                p='24px 32px'
                color='#6F767E'
                w='full'
              >
                <Stack mt={2}>
                  <Box ml='-10px'>{item.icon}</Box>
                  <Text fontWeight={600} fontSize='15px' color='#111315'>
                    {item.value}
                  </Text>
                  <Text flexWrap='wrap' fontSize='15px' fontWeight={600}>
                    {item.label}
                  </Text>
                </Stack>
              </FlexCard>
            ))}
          </Flex>
        </Flex>
      ) : (
        <Flex flexDir='column' gap={8} w='30%' flexGrow={1}>
          <Flex gap={6} flexDirection='column'>
            {meta.map((item, index) => (
              <FlexCard
                key={index}
                minH='200px'
                flexDir='column'
                fontWeight={600}
                border='2px solid #EFEFEF'
                p='24px 32px'
                color='#6F767E'
                w='full'
              >
                <Stack mt={2} gap={1}>
                  <Box ml='-10px'>{item.icon}</Box>
                  <Text
                    fontWeight={600}
                    fontSize='20px'
                    color='#111315'
                    lineHeight={'32px'}
                  >
                    {item.value}
                  </Text>
                  <Text
                    flexWrap='wrap'
                    fontSize='15px'
                    fontWeight={600}
                    color={'#6F767E'}
                  >
                    {item.label}
                  </Text>
                </Stack>
              </FlexCard>
            ))}
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default AverageTpDetails;
