import React, { FC, ReactNode, useCallback, useRef } from 'react';
import {
  AlertDialogCloseButton,
  Box,
  ButtonProps,
  Flex,
} from '@chakra-ui/react';
import { BoxHeader, UseConfirm, useConfirm } from '../../../../ui-components';
import ModalContent from './ModalContent';
import { ApolloProvider, useApolloClient } from '@apollo/client';

interface IProps {
  trainingId: string;
  sessionId: string;
  clickedItem?: string;
  type?: string;
  cb?: () => void;
}

type DeleteTPModal = (props: IProps) => void;

export const useDeleteTP = (): DeleteTPModal => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();
  const apolloClient = useApolloClient();

  return useCallback(({ trainingId, sessionId, clickedItem, type, cb }) => {
    let modalTitle = 'Delete training path';

    if (clickedItem === 'active') {
      modalTitle = 'Mark training path active';
    }

    if (confirmRef.current) {
      confirmRef.current.destroy();
    }

    const cbHandler = () => {
      confirmRef?.current?.destroy();
      cb?.();
    };

    confirmRef.current = confirm({
      Wrapper: ({ children }: any) => {
        return (
          <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
        );
      },
      title: (
        <Flex gap='12px'>
          <Box flex={1}>
            <BoxHeader title={modalTitle} color='#CABDFF' />
          </Box>
          <AlertDialogCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
          />
        </Flex>
      ),
      content: (
        <ModalContent
          trainingId={trainingId}
          sessionId={sessionId}
          clickedItem={clickedItem}
          type={type}
          closeModal={() => confirmRef.current?.destroy()}
          cb={cbHandler}
        />
      ),
      size: 'lg',
      isCentered: true,
      contentProps: {
        py: '12px',
        px: '8px',
        borderRadius: '16px',
        minW: '548px',
      },
      okText: null,
      cancelText: null,
      footer: null,
    });
  }, []);
};
