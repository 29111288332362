import { Box, Flex, Text } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useMemo } from 'react';
import {
  GroupedTasksData,
  TaskFilterType,
} from 'sub-components/Launcher/tasks/types';
import { toArray } from '../../../../../../utils';
import { ILocationLaunchTask } from '../../../../location-owner/types';
import { useLauncherTasksEventTrack } from '../../../useLauncherTasksEventTrack';

interface TaskNavbarProps {
  tasks: GroupedTasksData[];
  taskFilters: TaskFilterType[];
  setTaskFilters: React.Dispatch<React.SetStateAction<TaskFilterType[]>>;
}

const thisWeek = ['dueToday', 'duesoon', 'inProgress'];

const TaskNavbar: FC<TaskNavbarProps> = ({
  tasks,
  taskFilters,
  setTaskFilters,
}) => {
  const updateSelection = (value: TaskFilterType['value']) => {
    let _taskFilters = [...taskFilters];
    _taskFilters?.map((filter) => {
      if (filter.value === value) {
        filter.selected = !filter.selected;
        filter.showSelection = true;
      } else {
        filter.selected = false;
        filter.showSelection = true;
      }
    });
    setTaskFilters(_taskFilters);
  };

  const getCount = (
    allTasks: ILocationLaunchTask[],
    status: TaskFilterType['value']
  ) => {
    if (status === 'totalTasks') return allTasks.length;
    if (status === 'thisWeek')
      return allTasks.filter((task) => thisWeek.includes(task?.status)).length;
    return allTasks.filter((task) => task?.status === status).length;
  };

  const mappedCount = useMemo(() => {
    const allTasks = toArray(tasks).flatMap((task) =>
      toArray(task.groupedPhases).flatMap((group) => group?.tasks || [])
    );

    return taskFilters?.reduce((acc, filter) => {
      acc[filter.value] = getCount(allTasks, filter.value);
      return acc;
    }, {} as Record<TaskFilterType['value'], number>);
  }, [tasks]);

  useLauncherTasksEventTrack(mappedCount);

  return (
    <Flex gap={'20px'} align='center'>
      {taskFilters?.map((taskFilter, index) => (
        <Flex
          key={index}
          cursor='pointer'
          p={6}
          borderRadius='8px'
          border={taskFilter.selected ? '1px solid #2b85ff' : 'none'}
          bg='white'
          flexDir='column'
          w='266px'
          gap='10px'
          mb='10px'
          onClick={() => updateSelection(taskFilter?.value)}
        >
          <Flex align='center' justify='space-between'>
            <Flex gap='10px' align='center'>
              <Box
                h='20px'
                w='12px'
                bg={taskFilter.bannerColor}
                borderRadius='2px'
              />
              <Text>{taskFilter.label}</Text>
            </Flex>
            {taskFilter.selected && (
              <FontAwesomeIcon
                icon={faCircleCheck as IconProp}
                color='#2b85ff'
              />
            )}
          </Flex>
          <Text fontWeight={700} fontSize='20px'>
            {mappedCount[taskFilter?.value]} Tasks
          </Text>
        </Flex>
      ))}
    </Flex>
  );
};

TaskNavbar.displayName =
  'displayName:sub-components/Launcher/tasks/components/my-tasks/TaskNavbar/TaskNavbar';

export default TaskNavbar;
