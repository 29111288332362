import React, { FC, useEffect } from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Radio,
  RadioGroup,
  SkeletonCircle,
  SkeletonText,
  Stack,
} from '@chakra-ui/react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormInput } from 'atoms';
import Dropdown from 'atoms/Dropdown';
import { BoxHeader } from 'ui-components';
import TitleHeader from 'sub-components/CardEditor/TitleHeader';

import { IFormInput } from '../../AddLocation/add-location.types';
import { LocationStatusOption } from '../../AddLocation/static-data';
import { UploadDocumentInput } from '../UploadDocument';
import { useParams } from 'react-router-dom';

interface IProps {
  partialDisabled?: boolean;
}

const SkeletonBoardLoading: FC = () => {
  return (
    <Flex align='center' gap={4} mt={2}>
      <Flex align='center' gap='5px'>
        <SkeletonCircle size='4' />
        <SkeletonText w='100px' noOfLines={1} />
      </Flex>
      <Flex align='center' gap='5px'>
        <SkeletonCircle size='4' />
        <SkeletonText w='100px' noOfLines={1} />
      </Flex>
      <Flex align='center' gap='5px'>
        <SkeletonCircle size='4' />
        <SkeletonText w='100px' noOfLines={1} />
      </Flex>
    </Flex>
  );
};

const BasicDetails: FC<IProps> = ({ partialDisabled }) => {
  const { launchId } = useParams<{ launchId: string }>();
  const { t } = useTranslation(['common', 'location']);
  const { control, getValues, setValue } = useFormContext<IFormInput>();

  const locTypeWatch = useWatch<IFormInput, 'locationType'>({
    control,
    name: 'locationType',
  });

  const loadingBoardsWatch = useWatch<IFormInput, 'loadingBoards'>({
    control,
    name: 'loadingBoards',
  });

  const selectedBoard = useWatch<IFormInput, 'selectedBoard'>({
    control,
    name: 'selectedBoard',
  });

  const allBoards = useWatch<IFormInput, 'boards'>({
    control,
    name: 'boards',
  });

  useEffect(() => {
    // If there is already selected board, don't override it then
    if (selectedBoard) return;
    // Find the board whose id is coming in adress bar
    let foundBoard = allBoards?.find((board) => board?.eid === launchId);
    // If board not found, return
    if (!foundBoard) return;
    // Board found, set the value
    setValue('selectedBoard', foundBoard?.eid);
  }, [launchId, selectedBoard, allBoards]);

  return (
    <Flex bg='white' borderRadius='8px' p={8} gap='20px' flexDir='column'>
      <BoxHeader
        fontSize='16px'
        color='#CABDFF'
        title={t('location:basicDetails')}
      />

      <Flex flexDir='column' gap='20px'>
        <Flex align='flex-start' justify='flex-start'>
          <Controller
            control={control}
            name='locationType'
            rules={{
              required: t('location:validation.locationTypeRequired'),
            }}
            render={({ field, fieldState }) => {
              return (
                <FormControl
                  isDisabled={partialDisabled}
                  isInvalid={!!fieldState.error}
                  w='330px'
                >
                  <TitleHeader title={t('location:locationType')} isRequired />
                  <RadioGroup
                    mt={2}
                    value={field.value}
                    onChange={field.onChange}
                  >
                    <Stack
                      direction='row'
                      spacing={6}
                      sx={{
                        '.chakra-radio__label': {
                          color: '#6F767E',
                          fontSize: 14,
                          fontWeight: 500,
                          _checked: {
                            color: '#33383F',
                          },
                        },
                        '.chakra-radio__control': {
                          _checked: {
                            borderColor: 'blue.500',
                            background: 'white',
                            color: 'blue.500',
                            _hover: {
                              background: 'white',
                            },
                          },
                        },
                      }}
                    >
                      <Radio value='franchise'>{t('location:franchise')}</Radio>
                      <Radio value='corporate'>{t('location:corporate')}</Radio>
                    </Stack>
                  </RadioGroup>
                  <FormErrorMessage>
                    <span>{fieldState.error?.message}</span>
                  </FormErrorMessage>
                </FormControl>
              );
            }}
          />
          <Controller
            control={control}
            name='selectedBoard'
            rules={{
              required: t('location:validation.locationTypeRequired'),
            }}
            render={({ field, fieldState }) => {
              return (
                <FormControl
                  isDisabled={partialDisabled}
                  isInvalid={!!fieldState.error}
                  w='fit-content'
                >
                  <TitleHeader title={'Select Board'} isRequired />
                  {loadingBoardsWatch ? (
                    <SkeletonBoardLoading />
                  ) : (
                    <RadioGroup
                      mt={2}
                      value={field?.value}
                      onChange={field.onChange}
                    >
                      <Flex
                        w='full'
                        rowGap={2}
                        columnGap={4}
                        flexWrap='wrap'
                        sx={{
                          '.chakra-radio__label': {
                            color: '#6F767E',
                            fontSize: 14,
                            fontWeight: 500,
                            _checked: {
                              color: '#33383F',
                            },
                          },
                          '.chakra-radio__control': {
                            _checked: {
                              borderColor: 'blue.500',
                              background: 'white',
                              color: 'blue.500',
                              _hover: {
                                background: 'white',
                              },
                            },
                          },
                        }}
                      >
                        {getValues('boards')?.map((board) => (
                          <Radio key={board?.eid} value={board?.eid}>
                            {board?.title || '-'}
                          </Radio>
                        ))}
                      </Flex>
                    </RadioGroup>
                  )}
                  <FormErrorMessage>
                    <span>{fieldState.error?.message}</span>
                  </FormErrorMessage>
                </FormControl>
              );
            }}
          />
        </Flex>

        <Flex gap='20px'>
          <Box flex={4}>
            <TitleHeader title={t('location:lifecycleStage')} isRequired />
            <Controller
              control={control}
              name='locationStatus'
              render={({ field, fieldState }) => {
                return (
                  <FormControl mt={2} isInvalid={!!fieldState.error}>
                    <Dropdown
                      // isDisabled={partialDisabled}
                      isDisabled
                      size='lg'
                      placeholder={t('location:placeholder.locationStatus')}
                      options={LocationStatusOption}
                      {...field}
                      selectStyles={{
                        container: {
                          background: 'transparent',
                        },
                      }}
                    />
                    <FormErrorMessage>
                      <span>{fieldState.error?.message}</span>
                    </FormErrorMessage>
                  </FormControl>
                );
              }}
            />
          </Box>

          {/* <Box flex={9}>
            <TitleHeader title={'Upload current license document'} />

            <Controller
              control={control}
              name='licenceDocument'
              // rules={{
              //   required: {
              //     value: true,
              //     message: `Licence document is required`,
              //   },
              // }}
              render={({ field, fieldState }) => {
                return (
                  <FormControl
                    // isDisabled={isDisabled}
                    mt={2}
                    isInvalid={!!fieldState.error}
                  >
                    <UploadDocumentInput
                      // isDisabled={context.isDisabled}
                      {...field}
                    />
                    <FormErrorMessage>
                      <span>{fieldState.error?.message}</span>
                    </FormErrorMessage>
                  </FormControl>
                );
              }}
            />
          </Box> */}
          <Box flex={9}>
            <Controller
              control={control}
              name='locationName'
              rules={{
                required: locTypeWatch
                  ? `What will this franchise be called?`
                  : t('location:validation.locationNameRequired'),
                // pattern: {
                //   value: /^[a-zA-Z0-9()\- ]+$/,
                //   message:
                //     'Location name can only include letters, numbers, hyphens, and brackets.',
                // },
                validate: (value) =>
                  value.trim().length !== 0 ||
                  t('common:validation.name_invalid'),
              }}
              render={({ field, fieldState }) => {
                return (
                  <FormControl
                    isDisabled={partialDisabled}
                    isInvalid={!!fieldState.error}
                  >
                    <TitleHeader
                      title={t('location:locationName')}
                      isRequired
                    />
                    <FormInput
                      mt={2}
                      size='lg'
                      variant='outline'
                      placeholder={t('location:placeholder.name')}
                      {...field}
                    />
                    <FormErrorMessage>
                      <span>{fieldState.error?.message}</span>
                    </FormErrorMessage>
                  </FormControl>
                );
              }}
            />
          </Box>
          <Box flex={6} />
        </Flex>
      </Flex>
    </Flex>
  );
};

BasicDetails.displayName = 'p/LN/C/BD/BasicDetails';

export default BasicDetails;
