import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useToast,
} from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faChevronDown,
  faCircleCheck,
} from '@fortawesome/pro-regular-svg-icons';
import { faBarChart, faRepeat } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import alarmClock from 'assets/images/training/alarm.svg';
import { Button } from 'atoms';
import { useUserDataSelector } from 'hooks';
import moment from 'moment';
import { FC, useState } from 'react';
import { AuthRole } from 'sop-commons/src/client';
import { useEvaluateResponses } from 'sub-components/training-v2/shared/quiz';
import { TrainingAccessEnum } from 'types';
import {
  GET_TP_SESSION_BY_ID,
  REMIND_ASSIGNEE,
} from '../../Tracking/query/tracking.graphql';
import { IOnViewActivityClick } from './MemberAnalytics';
import useReassignUser from './PathActions/useReassignUser';
import { IReAssignInfo } from './PathActions/ReAssignTpForUser';

interface IProps {
  onViewActivityClick: (data: IOnViewActivityClick) => void;
  userName: string;
  sessionId: string;
  tp: any;
  onClose?: () => void;
  onActionCompleted?: any;
  reassignButton?: Boolean;
  isRemoved?: boolean;
}

const TrainingPathAction: FC<IProps> = ({
  onViewActivityClick,
  userName,
  sessionId,
  tp,
  onClose,
  onActionCompleted,
  reassignButton = true,
  isRemoved,
}) => {
  const isEvaluated = tp.assignedUser?.isEvaluated;
  const evaluate = useEvaluateResponses();
  const reassignUser = useReassignUser();

  const { loggedInUserEid, authRole } = useUserDataSelector((state) => ({
    loggedInUserEid: state?.eid,
    authRole: state?.authRole,
  }));

  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  });

  const [getTpSessionById, { loading }] = useLazyQuery(GET_TP_SESSION_BY_ID, {
    onCompleted(data) {
      const tpScheduleInfo = {
        startDate: tp?.training?.startDate,
        assignmentType: tp?.training?.assignmentType,
        deadlineType: tp?.training?.deadlineType,
        deadlineDate: tp?.training?.deadlineDate,
        duration: tp?.training?.duration,
        assignedUserDueDate: tp?.assignedUser?.dueDate,
        assignedUserStartDate: tp?.assignedUser?.startDate,
      };
      evaluate({
        evalAssigneeMeta: {
          name: userName,
          tpName: data?.TpSessionById?.title,
        },
        quizResponses: [],
        mode: 'summarized',
        skillVerifier: data?.TpSessionById?.skillVerifier,
        trainingId: data?.TpSessionById?.trainingId,
        userId: tp.assignedUser.userId,
        sessionId: sessionId,
        maxScore: tp?.assignedUser?.maxScore,
        score: tp?.assignedUser?.score, //check with BE
        userName: userName,
        tpScheduleInfo,
        hasSkillVerifier: tp?.hasSkillVerifier,
        onEvaluateComplete: onActionCompleted,
        tp: tp,
      });
    },
    fetchPolicy: 'no-cache',
  });

  const [remindAssignee, { loading: remindAssigneeLoading, data }] =
    useMutation(REMIND_ASSIGNEE, {
      onCompleted: (data) => {
        if (data?.TpRemindAssignee?.succeed) {
          toast({
            status: 'success',
            title: 'Reminder sent successfully',
          });
        }
      },
      onError: () => {
        toast({
          status: 'error',
          title: 'Reminder could not be sent',
        });
      },
    });

  const getPathStatus = () => {
    if (tp?.status === 'TRAINING_TERMINATED') return 'viewActivity';

    const canCurrentUserEvaluate =
      (tp?.supervisors?.some((supervisor) => supervisor === loggedInUserEid) ||
        tp?.createdBy === loggedInUserEid) &&
      tp?.assignedUser?.userId !== loggedInUserEid; //can only eval if the currUser is not an assignee and part of supervising team

    // if the current user is superadmin then it can or if admin then if it has permssion
    const canCurrentUserReassign =
      (authRole === AuthRole.SUPER_ADMIN &&
        tp?.accessPermission?.superadmin?.includes(
          TrainingAccessEnum.ASSIGN
        )) ||
      (authRole === AuthRole.ADMIN &&
        tp?.accessPermission?.admin?.includes(TrainingAccessEnum.ASSIGN) &&
        tp?.status !== 'TRAINING_TERMINATED') ||
      tp?.createdBy === loggedInUserEid;

    const isReassignAndEvaluate =
      tp?.hasSkillVerifier &&
      !isEvaluated &&
      !tp?.isRepeating &&
      tp?.assignedUser?.completedOn &&
      tp?.assignedUser?.completedOn !== '-' &&
      canCurrentUserEvaluate &&
      canCurrentUserReassign;
    if (isReassignAndEvaluate) return 'reassignAndEvaluate';

    if (
      tp.hasSkillVerifier &&
      !isEvaluated &&
      tp?.assignedUser?.completedOn &&
      tp?.assignedUser?.completedOn !== '-' &&
      canCurrentUserEvaluate
    )
      return 'Evaluate';

    if (tp.assignedUser?.dueDate && tp.assignedUser?.completedOn === '-') {
      const dueDate = moment(tp.assignedUser?.dueDate);
      const today = moment();
      if (dueDate.isBefore(today, 'hours')) {
        return 'overdue';
      }
    }

    // workaround till the logic on training table container fixed
    if (
      tp.assignedUser?.completedOn &&
      tp.assignedUser?.completedOn !== '-' &&
      !tp.isRepeating &&
      canCurrentUserReassign
    )
      return 'reassign';

    return 'viewActivity';
  };

  const onReassignClick = () => {
    const tpScheduleInfo: IReAssignInfo = {
      startDate: tp?.training?.startDate,
      assignmentType: tp?.training?.assignmentType,
      deadlineType: tp?.training?.deadlineType,
      deadlineDate: tp?.training?.deadlineDate,
      duration: tp?.training?.duration,
    };
    reassignUser({
      sessionId: sessionId,
      userId: tp.assignedUser?.userId,
      tpScheduleInfo: tpScheduleInfo,
      onActionCompleted: onActionCompleted,
    });
  };

  return (
    <div>
      {getPathStatus() === 'Evaluate' && (
        <Button
          variant='unstyled'
          gap={1}
          textColor={'#2A85FF'}
          fontWeight={700}
          fontSize={'15px'}
          align={'center'}
          display='flex'
          onClick={() => {
            getTpSessionById({
              variables: {
                eid: sessionId,
              },
            });
          }}
          isLoading={loading}
          disabled={loading || isRemoved}
        >
          <FontAwesomeIcon icon={faCircleCheck as IconProp} />
          <Text fontSize={'15px'} fontWeight={700}>
            Evaluate
          </Text>
        </Button>
      )}

      {getPathStatus() === 'overdue' && (
        <Button
          gap={1}
          textColor={'#2A85FF'}
          fontWeight={700}
          fontSize={'15px'}
          variant='unstyled'
          display='flex'
          isLoading={remindAssigneeLoading}
          disabled={remindAssigneeLoading || isRemoved}
          onClick={() => {
            remindAssignee({
              variables: {
                sessionId: sessionId,
                userId: tp.assignedUser.userId,
              },
            });
          }}
        >
          <Image src={alarmClock} />
          <Text>Remind</Text>
        </Button>
      )}

      {getPathStatus() === 'viewActivity' && (
        <Flex
          gap={2}
          textColor='#2A85FF'
          fontWeight={700}
          fontSize='15px'
          align='center'
          cursor='pointer'
          disabled={loading || isRemoved}
          onClick={() => {
            onClose?.();
            onViewActivityClick({
              name: userName,
              userId: tp.assignedUser.userId,
              sessionId: sessionId,
              tp: tp,
            });
          }}
        >
          <FontAwesomeIcon
            icon={faBarChart as IconProp}
            transform={{
              rotate: -90,
              flipX: true,
            }}
          />
          <Text>View Activity</Text>
        </Flex>
      )}

      {getPathStatus() === 'reassign' && reassignButton && (
        <Button
          display='flex'
          variant='unstyled'
          gap={2}
          textColor={'#2A85FF'}
          fontWeight={700}
          fontSize={'15px'}
          alignItems={'center'}
          onClick={onReassignClick}
          disabled={loading || isRemoved}
        >
          <FontAwesomeIcon icon={faRepeat as IconProp} />
          <Text>Reassign</Text>
        </Button>
      )}

      {getPathStatus() === 'reassignAndEvaluate' && (
        <Flex
          justifyContent={'space-between'}
          minW={'120px'}
          alignItems={'center'}
        >
          <Button
            variant='unstyled'
            gap={2}
            textColor={'#2A85FF'}
            fontWeight={700}
            fontSize={'15px'}
            alignItems={'center'}
            display='flex'
            onClick={() => {
              getTpSessionById({
                variables: {
                  eid: sessionId,
                },
              });
            }}
            isLoading={loading}
            disabled={loading || isRemoved}
          >
            <FontAwesomeIcon icon={faCircleCheck as IconProp} />
            <Text fontSize={'15px'} fontWeight={700}>
              Evaluate
            </Text>
          </Button>
          <Menu>
            <MenuButton
              ml={1}
              disabled={isRemoved}
              cursor={isRemoved ? 'not-allowed' : 'pointer'}
            >
              <FontAwesomeIcon
                icon={faChevronDown as IconProp}
                color='#2A85FF'
              />
            </MenuButton>
            <MenuList
              boxShadow='lg'
              borderRadius='16px'
              p={2}
              border='none'
              width='fit-content'
            >
              <MenuItem
                gap={3}
                borderRadius='12px'
                onClick={() => {
                  getTpSessionById({
                    variables: {
                      eid: sessionId,
                    },
                  });
                }}
              >
                <FontAwesomeIcon
                  icon={faCircleCheck as IconProp}
                  color='#6F767E'
                />
                <Text fontSize={'15px'} color='#1A1D1F'>
                  Evaluate
                </Text>
              </MenuItem>
              <MenuItem gap={3} borderRadius='12px' onClick={onReassignClick}>
                <FontAwesomeIcon icon={faRepeat as IconProp} color='#6F767E' />
                <Text color='#1A1D1F'>Reassign</Text>
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      )}
    </div>
  );
};

TrainingPathAction.displayName =
  'sub-components/training-v2/dashboard/components/TrackPathDashboard/components/TrainingPathAction.tsx';

export default TrainingPathAction;
