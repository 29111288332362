import React, { FC } from 'react';
import { Box, Flex, Spacer, useModalContext } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { faUserTie } from '@fortawesome/free-solid-svg-icons';
import { AlertBox } from '../../../../../atoms';
import { ActionButton } from '../../../../../ui-components';
import { AuthRole } from '../../../../../authorization';
import { AccessPermissionInput, IAssigneeInput } from '../training-input.types';
import GeneralAccess from './GeneralAccess';

interface Details extends IAssigneeInput {}

interface TCreator {
  eid: string;
  name: string;
  authRole: AuthRole;
}

export interface ARuleProps {
  initialValue?: AccessPermissionInput;
  creator?: TCreator;
  hasSkillVerifier?: boolean;
  details?: Details;
  onPublishClick?: (values: AccessPermissionInput) => void | Promise<void>;
  buttonText?: string;
}

const AccessRuleContent: FC<ARuleProps> = ({
  initialValue,
  onPublishClick,
  hasSkillVerifier,
  buttonText,
  creator,
}) => {
  const { onClose } = useModalContext();
  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm<AccessPermissionInput>({
    defaultValues: initialValue,
  });

  const _onPublishClick = async (values: AccessPermissionInput) => {
    if (onPublishClick) {
      await onPublishClick?.({
        admin: values.admin,
        locationOwner: values.locationOwner,
        superadmin: values.superadmin,
      });
    }
    setTimeout(onClose);
  };

  return (
    <Flex flexDir='column' gap={5}>
      <Flex flexDir='column' gap={7} paddingX={7} paddingBottom={7}>
        <Flex flexDir='column' gap={4}>
          {hasSkillVerifier ? (
            <>
              <Box fontSize='15px' fontWeight={600} lineHeight='24px'>
                Default access
              </Box>

              <AlertBox childProps={{ fontWeight: 500 }} icon={faUserTie}>
                All superadmins are the supervisors for this path. They by
                default have access to track assignees.
              </AlertBox>
            </>
          ) : null}

          <GeneralAccess creatorRole={creator?.authRole} control={control} />
        </Flex>
      </Flex>

      <Flex
        gap={5}
        paddingY='12px'
        paddingX={4}
        bgColor='#FCFCFC'
        borderTop='1px solid #EFEFEF'
        borderBottomRadius='8px'
      >
        <Spacer />

        <ActionButton
          variant='outline'
          fontSize='13px'
          isDisabled={!isDirty}
          actionFn={() => reset()}
        >
          Clear
        </ActionButton>

        <ActionButton
          fontSize='13px'
          colorScheme='blue'
          actionFn={handleSubmit(_onPublishClick)}
        >
          {buttonText || 'Publish'}
        </ActionButton>
      </Flex>
    </Flex>
  );
};

export default AccessRuleContent;
