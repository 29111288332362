import React, { FC } from 'react';
import { Box, Center } from '@chakra-ui/react';

import { ReactComponent as QuizIcon } from 'assets/images/training/empty-quiz.svg';

interface IProps {}

const SelectQuizToPreview: FC<IProps> = () => {
  return (
    <Box flex={1} overflow='hidden' paddingTop='32px'>
      <Center flexDir='column' gap='32px' height='full'>
        <QuizIcon />

        <Box
          fontSize='15px'
          fontWeight='500'
          color='#6F767E'
          textAlign='center'
          maxWidth='148px'
        >
          Click on a question type to preview
        </Box>
      </Center>
    </Box>
  );
};

export default SelectQuizToPreview;
