import React, { FC, useCallback, useContext, useEffect } from 'react';
import {
  Button,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useControllableState,
} from '@chakra-ui/react';
import { CometChat } from '@cometchat-pro/chat';

import { GroupMemberEntity } from '../../../../../types';

import AboutChannel from './AboutChannel';
import ChannelMemberListContainer from './members/ChannelMemberListContainer';
import ChannelSetting from './ChannelSetting';
import { useUpdateChannel } from '../useUpdateChannel';
import AboutChannelSkeleton from './AboutChannelSkeleton';
import { UpdateChannelContext } from './UpdateChannelProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useAddMember } from './members/useAddMember';
import { eventBus } from 'shared/eventEmit';

interface ITabProps {
  isDisabled?: boolean;
}

const ParentTabWrapper: FC<ITabProps> = ({ children, isDisabled }) => {
  return (
    <Tab
      fontWeight={500}
      fontSize='15px'
      color='#6F767E'
      isDisabled={isDisabled}
      _selected={{
        color: '#272B30',
        borderColor: '#2A85FF',
        borderBottomWidth: '3px',
        fontWeight: 600,
      }}
    >
      {children}
    </Tab>
  );
};

interface IProps {
  activeTab?: number;
  onGroupDeleted?: (...args: any) => void;
  onGroupUpdated?: (group: CometChat.Group) => void;
  onMemberUpdated?: () => void;
  onSendMessage: (data: GroupMemberEntity) => void;
}

const UpdateChannelContent: FC<IProps> = ({
  activeTab,
  onGroupDeleted,
  onGroupUpdated,
  onMemberUpdated: _onMemberUpdated,
  onSendMessage,
}) => {
  const { channel, refetch, groupId, loading } =
    useContext(UpdateChannelContext);

  const updateChannel = useUpdateChannel();

  const onEditClick = () => {
    return updateChannel({
      initialState: channel,
      onGroupUpdated: (group) => {
        refetch?.();
        onGroupUpdated?.(group);
      },
    });
  };
  const [updateContentTab, setUpdateContentTab] = useControllableState({
    defaultValue: activeTab,
  });
  const onMemberUpdated = useCallback(() => {
    refetch?.();
    _onMemberUpdated?.();
  }, [refetch, _onMemberUpdated]);

  const isDefault =
    channel?.details?.isEditable === false || !channel?.hasJoined;

  const editMember = useAddMember();

  const onEditMemberClick = () => {
    editMember({
      initialState: channel,
      onMemberUpdated: onMemberUpdated,
      updateContentTab,
    });
  };

  return (
    <Tabs
      defaultIndex={activeTab}
      isLazy
      onChange={(tabIndex) => {
        setUpdateContentTab(tabIndex);
      }}
    >
      <TabList>
        <ParentTabWrapper>About</ParentTabWrapper>
        <ParentTabWrapper isDisabled={loading || isDefault}>
          Members
        </ParentTabWrapper>
        <ParentTabWrapper>Settings</ParentTabWrapper>
        <Spacer />
        {updateContentTab === 1 && (
          <Button
            variant='unstyled'
            display={'flex'}
            alignItems={'center'}
            leftIcon={<FontAwesomeIcon icon={faPen as IconProp} height={19} />}
            gap={'2px'}
            color={'#2A85FF'}
            fontWeight={600}
            onClick={onEditMemberClick}
          >
            Edit members
          </Button>
        )}
      </TabList>

      <TabPanels>
        <TabPanel px={0} height={'400px'} overflowY={'auto'}>
          <AboutChannelSkeleton isLoaded={!loading && !!channel}>
            <AboutChannel
              initialValue={channel!}
              onEditButtonClick={onEditClick}
            />
          </AboutChannelSkeleton>
        </TabPanel>
        <TabPanel px={0} height={'400px'} overflowY={'auto'} pt={-4}>
          <ChannelMemberListContainer
            groupId={groupId}
            isLoading={loading}
            group={channel!}
            onMemberUpdated={onMemberUpdated}
            onSendMessage={onSendMessage}
          />
        </TabPanel>
        <TabPanel px={0} height={'400px'} overflowY={'auto'}>
          <ChannelSetting
            groupId={groupId}
            group={channel!}
            onGroupDeleted={onGroupDeleted}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default UpdateChannelContent;
