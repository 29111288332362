import React, { FC, useMemo, useState } from 'react';
import { Button, Flex, Text, Checkbox, Box, useToast } from '@chakra-ui/react';
import { ReminSupervisorsEntity } from './useSendReminder';
import { getImage } from 'utils';
import { gql, useMutation, useReactiveVar } from '@apollo/client';
import { usersEntityObj } from 'sub-components/Header';
import { AuthRole } from 'authorization';
import { ColumnsType, Table } from 'atoms/Table';
import { Image } from 'ui-components';
import { useUserDataSelector } from 'hooks';
import { UserEntityData } from 'shared/graphql/shared-types';
import EmptyState from 'sub-components/EmptyState';

interface IProps {
  sessionId?: string;
  supervisors: string[];
  onCancel: () => void;
  onSuccess: () => void;
}

const TP_REMIND_EVALUATION_WEB = gql`
  mutation TpRemindEvaluation(
    $sessionId: String!
    $userId: String!
    $supervisors: [String!]!
  ) {
    TpRemindEvaluation(
      sessionId: $sessionId
      userId: $userId
      supervisors: $supervisors
    ) {
      succeed
    }
  }
`;

const ModalContent: FC<IProps> = ({
  sessionId,
  supervisors,
  onCancel,
  onSuccess,
}) => {
  const toast = useToast({
    position: 'top-right',
    isClosable: true,
    duration: 3000,
  });
  const { loggedInUserEid, loggedInUserAuthRole, loggedInUserLocations } =
    useUserDataSelector((state) => ({
      loggedInUserEid: state?.eid,
      loggedInUserAuthRole: state?.authRole,
      loggedInUserLocations: state?.locations,
    }));
  const businessEntityData = useReactiveVar(usersEntityObj);
  const [selectedSupervisors, setSelectedSupervisors] = useState<string[]>([]);

  const [remindSupervisors, { loading }] = useMutation<
    { TpRemindEvaluation: { succeed: boolean } },
    { sessionId: string; userId: string; supervisors: string[] }
  >(TP_REMIND_EVALUATION_WEB, {
    onCompleted: () => {
      toast({
        status: 'success',
        description: 'Supervisors reminded to evaluate',
      });
      onSuccess();
    },
    onError: () => {
      toast({
        status: 'error',
        description: 'Supervisors could not be reminded at the moment',
      });
      onCancel();
    },
  });

  const calcAuthRole = (authRole: AuthRole) => {
    return authRole?.split(/(?=[A-Z])/).join(' ');
  };

  const _supervisors = useMemo(() => {
    const loggedInUserLocationEids = (loggedInUserLocations || []).map(
      (loc) => loc?.eid
    );

    const data = (businessEntityData || []).reduce(
      (
        acc: Pick<UserEntityData, 'eid' | 'name' | 'profilePic' | 'authRole'>[],
        entity
      ) => {
        const isUser = entity.type === 'user';
        const isActiveOrPending = ['active', 'pending'].includes(entity.status);
        const isInUserLocations = entity?.locations?.some((loc) =>
          loggedInUserLocationEids.includes(loc?.eid)
        );

        const shouldInclude =
          isUser &&
          isActiveOrPending &&
          ([AuthRole.SUPER_ADMIN, AuthRole.ADMIN].includes(entity.authRole) ||
            ![AuthRole.LOCATION_OWNER, AuthRole.WORKER].includes(
              loggedInUserAuthRole
            ) ||
            isInUserLocations);

        if (shouldInclude) {
          acc.push({
            eid: entity.eid,
            name: entity.name,
            profilePic: entity.profilePic,
            authRole: calcAuthRole(entity.authRole) as AuthRole,
          });
        }
        return acc;
      },
      []
    );

    return data.filter((d) => (supervisors || []).includes(d.eid));
  }, [
    businessEntityData,
    supervisors,
    loggedInUserAuthRole,
    loggedInUserLocations,
  ]);

  const handleSelect = (supervisorId: string) => {
    setSelectedSupervisors((prev) =>
      prev.includes(supervisorId)
        ? prev.filter((id) => id !== supervisorId)
        : [...prev, supervisorId]
    );
  };

  const columns: ColumnsType<ReminSupervisorsEntity> = [
    {
      title: <Box pl={4}>Supervisor</Box>,
      dataIndex: 'name',
      key: 'name',
      width: '50%',
      render: (text, record) => (
        <Flex align='center' pl={4}>
          <Image
            src={getImage(record?.profilePic, record?.name)}
            width={25}
            height={25}
            style={{ borderRadius: '6px' }}
          />
          <Text ml={3} fontWeight='600'>
            {text}
          </Text>
        </Flex>
      ),
    },
    {
      title: 'Role',
      dataIndex: 'authRole',
      key: 'authRole',
      width: '33%',
      render: (text) => (
        <Box as='span' fontWeight={600} textTransform='capitalize'>
          {text}
        </Box>
      ),
    },
    {
      title: '',
      key: 'action',
      width: '33%',
      align: 'center' as const,
      render: (_, record) => (
        <Box display='flex' justifyContent='center'>
          <Checkbox
            colorScheme='blue'
            checked={selectedSupervisors.includes(record.eid)}
            onChange={() => handleSelect(record.eid)}
          />
        </Box>
      ),
    },
  ];

  const onRemindHandler = () => {
    if (sessionId && loggedInUserEid && selectedSupervisors?.length > 0)
      remindSupervisors({
        variables: {
          sessionId: sessionId,
          userId: loggedInUserEid,
          supervisors: selectedSupervisors,
        },
      });
  };

  return (
    <Flex w='full' gap={6} flexDir='column'>
      <Box
        borderRadius='12px'
        borderWidth='1px'
        borderColor='rgba(239, 239, 239, 1)'
        p={0}
        minH='37vh'
        maxH='37vh'
        overflowY='auto'
        w='full'
        mt={4}
      >
        <Table
          columns={columns}
          dataSource={_supervisors}
          pagination={false}
          emptyState={
            <EmptyState image='NoLocationMember' title='No supervisor found' />
          }
          style={{
            paddingTop: '24px',
            paddingBottom: '24px',
          }}
          size='middle'
        />
      </Box>
      <Flex w='full' gap={4}>
        <Button
          variant='outline'
          size='lg'
          bg='white'
          w='50%'
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          variant='solid'
          colorScheme='blue'
          size='lg'
          w='50%'
          disabled={!selectedSupervisors?.length || loading}
          isLoading={loading}
          onClick={onRemindHandler}
        >
          Send reminder
        </Button>
      </Flex>
    </Flex>
  );
};

export default ModalContent;
