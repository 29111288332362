import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { toArray } from '../../../../../../utils';
import { ContentItemInput } from '../../training-input.types';
import MilestoneResourceContainer from './MilestoneResourceContainer';
import MilestoneFooter from './MilestoneFooter';
import MilestonePreview from './MilestonePreview';

export interface SResourceProps {
  initialValues?: ContentItemInput;
  onAddClick?: (values: unknown[]) => void;
}

const MilestoneResourceContent: FC<SResourceProps> = ({
  onAddClick,
  initialValues,
}) => {
  const methods = useForm({
    defaultValues: initialValues || { title: '' },
  });

  const _onAddClick = (values: unknown) => {
    return onAddClick?.(toArray(values));
  };

  return (
    <FormProvider {...methods}>
      <Flex flex={1} flexDir='column' width='100%'>
        <Flex
          flex={1}
          gap='40px'
          marginX={7}
          // align='start'
          maxHeight='calc(100% - 61px)'
        >
          <MilestoneResourceContainer />

          <MilestonePreview />
        </Flex>

        <MilestoneFooter onAddClick={_onAddClick} />
      </Flex>
    </FormProvider>
  );
};

export default MilestoneResourceContent;
