import React, { FC } from 'react';
import { Flex, Box } from '@chakra-ui/react';
import Loader from 'sub-components/Loader';

interface IProps {
  icon: React.ReactNode;
  title: string;
  count: string;
  overallUserDetailsAnalyticsLoader: boolean;
  onClickHandler?: () => void;
}

const PerformanceCard: FC<IProps> = ({
  icon,
  title,
  count,
  overallUserDetailsAnalyticsLoader,
  onClickHandler,
}) => {
  return (
    <Flex
      width={'full'}
      height={'180px'}
      flexDir={'column'}
      border='2px solid rgba(239, 239, 239, 1)'
      borderRadius='12px'
      p='24px'
      cursor='pointer'
      onClick={onClickHandler}
    >
      <Box>{icon}</Box>
      <Box
        mt={2}
        color='rgba(111, 118, 126, 1)'
        fontWeight={600}
        fontSize='15px'
        height={'48px'}
      >
        {title}
      </Box>
      <Box fontWeight={600} fontSize='32px'>
        {overallUserDetailsAnalyticsLoader ? <Loader /> : count}
      </Box>
    </Flex>
  );
};

PerformanceCard.displayName =
  'displayName:sub-components/training-v2/dashboard/components/myTrainings/topSection/PerformanceCards';

export default PerformanceCard;
