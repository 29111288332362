import { AuthRole } from '../../../authorization';
import { TrainingAccessEnum } from '../../../types';
import { ACCESS_SEPARATOR } from '../../../configs';
import { TFormInput } from './components/training-input.types';

export const defaultTrainingInput = (authRole?: AuthRole) => {
  const defaultTrainingInput: Partial<TFormInput> = {
    currentEditPage: 0,
    title: '',
    description: '',

    contents: [],

    assigneeUserType: 'old',
    assignees: [
      {
        type: undefined as never,
        authRoles: [],
        roles: [],
        userIds: [],
        locationIds: [],
        locationType: undefined,
      },
    ],

    frequency: 'once',
    deadlineType: 'noDeadline',
    assignmentType: 'now',

    hasSkillVerifier: false,

    supervisors: [],

    accessPermission: {
      superadmin: [
        TrainingAccessEnum.VIEW,
        TrainingAccessEnum.EDIT,
        TrainingAccessEnum.ASSIGN,
      ].join(ACCESS_SEPARATOR),
      admin: [TrainingAccessEnum.EDIT].join(ACCESS_SEPARATOR),
      locationOwner: [TrainingAccessEnum.CAN_CROSSTRAIN].join(ACCESS_SEPARATOR),
    },
  };

  if (authRole === AuthRole.LOCATION_OWNER) {
    defaultTrainingInput.accessPermission = {
      superadmin: [TrainingAccessEnum.VIEW, TrainingAccessEnum.EDIT].join(
        ACCESS_SEPARATOR
      ),
      admin: [TrainingAccessEnum.VIEW, TrainingAccessEnum.EDIT].join(
        ACCESS_SEPARATOR
      ),
      locationOwner: [TrainingAccessEnum.CAN_CROSSTRAIN].join(ACCESS_SEPARATOR),
    };
  }

  return defaultTrainingInput;
};
