import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { useReactiveVar } from '@apollo/client';
import { useUserDataSelector } from '../../../../../hooks';
import { AuthRole } from '../../../../../authorization';
import { usersEntityObj } from '../../../../Header';
import { TFormInput } from '../training-input.types';

interface TCreator {
  eid: string;
  name: string;
  authRole: AuthRole;
}

export const useTrainingCreator = (): TCreator | undefined => {
  const createdBy = useWatch<TFormInput, 'createdBy'>({ name: 'createdBy' });
  const authUser = useUserDataSelector((state) => ({
    eid: state.eid,
    name: state.name,
    authRole: state.authRole,
  }));
  const entityUsers = useReactiveVar(usersEntityObj);

  return useMemo(() => {
    if (createdBy && createdBy !== authUser?.eid) {
      return entityUsers?.find((user) => user.eid === createdBy);
    }

    return authUser;
  }, [entityUsers, authUser, createdBy]);
};

export const getTrainingCreator = (
  _createdBy: string
): TCreator | undefined => {
  const createdBy = _createdBy;
  const authUser = useUserDataSelector((state) => ({
    eid: state.eid,
    name: state.name,
    authRole: state.authRole,
  }));
  const entityUsers = useReactiveVar(usersEntityObj);
  return useMemo(() => {
    if (createdBy && createdBy !== authUser?.eid) {
      return entityUsers?.find((user) => user.eid === createdBy);
    }

    return authUser;
  }, [entityUsers, authUser, createdBy]);
};
