import React, { FC, useMemo } from 'react';
import { SelectOption } from '../../../../../../atoms';
import { useUserEntity } from '../../../../../../hooks';
import { toArray } from '../../../../../../utils';
import SubcategoryListContent from './SubcategoryListContent';

interface IProps {
  category?: string;
  filterValue?: SelectOption;
  onFilterChange?: (value: SelectOption) => void;
}

const SubCategoryList: FC<IProps> = ({
  category,
  filterValue,
  onFilterChange,
}) => {
  const categories = useUserEntity((ent) =>
    ent?.category?.find((cat) => cat.eid === category)
  );

  const options = useMemo(() => {
    const _options = toArray(categories?.children).map((op) => ({
      label: op.name,
      value: op.eid,
    }));

    _options.unshift({ label: 'All', value: '' });

    return _options;
  }, [categories]);

  if (options?.length < 2) {
    return null;
  }

  return (
    <SubcategoryListContent
      options={options.slice(0, 200)}
      filterValue={filterValue}
      onFilterChange={onFilterChange}
    />
  );
};

export default SubCategoryList;
