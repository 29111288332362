import React, { FC } from 'react';
import {
  CheckboxGroup,
  PopoverBody,
  useControllableState,
  usePopoverContext,
} from '@chakra-ui/react';
import { RolesEntity } from 'sop-commons/src/client';
import { Button } from '../../../../../../../atoms';
import { StringOrNumber } from '../../../../../../../types';
import { ListScrollContainer } from '../common';
import JobOption from './JobOption';

interface IProps {
  jobs: RolesEntity[];
  value: string[];
  updateValue: (value: string[]) => void;
}

const JobSelectContent: FC<IProps> = ({ jobs, value, updateValue }) => {
  const { onClose } = usePopoverContext();
  const [internalValue, internalUpdate] = useControllableState<
    StringOrNumber[]
  >({
    defaultValue: value,
  });

  return (
    <PopoverBody paddingInline='20px' paddingBlock='16px'>
      <ListScrollContainer marginTop={undefined}>
        <CheckboxGroup value={internalValue} onChange={internalUpdate}>
          {jobs.map((item) => {
            return <JobOption key={item.eid} item={item} />;
          })}
        </CheckboxGroup>
      </ListScrollContainer>

      <Button
        size='lg'
        width='full'
        colorScheme='blue'
        onClick={() => {
          setTimeout(onClose);
          updateValue?.(internalValue as string[]);
        }}
      >
        Select
      </Button>
    </PopoverBody>
  );
};

export default JobSelectContent;
