import { Divider, Flex, Text } from '@chakra-ui/react';
import { HeaderColors } from 'configs';
import React, { FC } from 'react';
import { BoxHeader } from 'ui-components';
import { ReactComponent as ChevronforwardIcon } from 'assets/images/chevronForward.svg';
import { PageNameType } from './MainContainer';
import { ClearFilterType } from './FilterContainer';

interface IProps {
  filters: any;
  setPageName: React.Dispatch<React.SetStateAction<PageNameType>>;
  clearFilters: (type?: ClearFilterType) => void;
}

const BaseFilter: FC<IProps> = ({ setPageName, filters, clearFilters }) => {
  return (
    <Flex gap={4} flexDir='column'>
      <Flex alignItems={'center'} mt={'10px'} justifyContent={'space-between'}>
        <BoxHeader title='Filters' color={HeaderColors.Purple} />
        <Text
          cursor={'pointer'}
          onClick={() => clearFilters()}
          fontSize={'14px'}
          fontWeight={400}
          color={filters?.totalFiltersTypes > 0 ? '#2A85FF' : '#6F767E'}
        >
          Clear All
        </Text>
      </Flex>

      <Flex
        onClick={() => setPageName('statuses')}
        cursor={'pointer'}
        mt={'20px'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Flex gap={4}>
          <Text
            color={'#333B4F'}
            fontWeight={400}
            lineHeight='16px'
            fontSize='14px'
          >
            Status
          </Text>
          <Text
            color={'#6F767E'}
            fontWeight={400}
            lineHeight='16px'
            fontSize='14px'
          >
            {`${
              filters?.selectedStatuses?.length
                ? '(' + filters?.selectedStatuses?.length + ' selected)'
                : ''
            }`}
          </Text>
        </Flex>
        <ChevronforwardIcon />
      </Flex>

      <Divider />
      <Flex
        onClick={() => setPageName('categories')}
        cursor={'pointer'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Flex gap={4}>
          <Text
            color={'#333B4F'}
            fontWeight={400}
            lineHeight='16px'
            fontSize='14px'
          >
            Category
          </Text>
          <Text
            color={'#6F767E'}
            fontWeight={400}
            lineHeight='16px'
            fontSize='14px'
          >
            {`${
              filters?.selectedCategories?.length
                ? '(' + filters?.selectedCategories?.length + ' selected)'
                : ''
            }`}
          </Text>
        </Flex>
        <ChevronforwardIcon />
      </Flex>
      <Divider />
      <Flex
        mb={'30px'}
        onClick={() => setPageName('types')}
        cursor={'pointer'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Flex gap={4}>
          <Text
            color={'#333B4F'}
            fontWeight={400}
            lineHeight='16px'
            fontSize='14px'
          >
            Type
          </Text>
          <Text
            color={'#6F767E'}
            fontWeight={400}
            lineHeight='16px'
            fontSize='14px'
          >
            {`${
              filters?.selectedTypes?.length
                ? '(' + filters?.selectedTypes?.length + ' selected)'
                : ''
            }`}
          </Text>
        </Flex>
        <ChevronforwardIcon />
      </Flex>
    </Flex>
  );
};

export default BaseFilter;
