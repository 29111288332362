import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Center, Flex, Text } from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import { Button } from 'atoms';
import Container from './Container';
import { usePlayContext } from 'sub-components/training-v2/play/store/context';
import { useService } from 'sub-components/training-v2/play/layers';
import { MatchPair } from 'sub-components/training-v2/shared/types';
import {
  getQuestionResponse,
  isQuestionCompleted,
} from 'sub-components/training-v2/play/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripDotsVertical } from '@fortawesome/pro-solid-svg-icons';
import {
  faCircleCheck,
  faCircleXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  calculateTotalScore,
  cleanResponse,
  isLastUnansweredQuestion,
} from 'sub-components/training-v2/play/utils/quizHelpers';
import { Image } from 'ui-components';
import QuizQuestionTitle from './QuizQuestionTitle';

function shuffleArray<T>(array: T[]): T[] {
  const arr = array.slice();
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }
  return arr;
}

function removeTypename<T>(item: T): T {
  const { __typename, ...rest } = item as any;
  return rest;
}

const QuizMatch: FC = () => {
  const {
    contents,
    isPreview,
    isRetake,
    selectedIndex,
    selectedSubIndex,
    tpSessionData,
    increment,
    updateTpSessionData,
    setQuizSubmissionResult,
  } = usePlayContext((state) => ({
    isPreview: state.isPreview,
    isRetake: state.isRetake,
    selectedIndex: state.selectedIndex,
    selectedSubIndex: state.selectedSubIndex,
    contents: state.tpSessionData?.contents || [],
    tpSessionData: state.tpSessionData,
    increment: state.increment,
    updateTpSessionData: state.updateTpSessionData,
    setQuizSubmissionResult: state.setQuizSubmissionResult,
  }));

  const service = useService();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [pairHeights, setPairHeights] = useState<number[]>([]);
  const leftRefs = useRef<(HTMLDivElement | null)[]>([]);
  const rightRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [dummySubmit, setDummySubmit] = useState(false);

  const currentQuestion = useMemo(() => {
    return contents?.[selectedIndex]?.questions?.[selectedSubIndex];
  }, [contents, selectedIndex, selectedSubIndex]);

  const originalPairs: MatchPair[] = useMemo(() => {
    if (!currentQuestion?.pairs) return [];
    return currentQuestion.pairs.map(removeTypename);
  }, [currentQuestion]);

  const isCompleted = useMemo(() => {
    return isRetake && !dummySubmit
      ? false
      : isQuestionCompleted(
          currentQuestion?.eid,
          tpSessionData?.userProgress || []
        );
  }, [currentQuestion, tpSessionData]);

  const savedResponse = useMemo(() => {
    return getQuestionResponse(
      currentQuestion?.eid,
      tpSessionData?.userProgress || []
    );
  }, [currentQuestion, tpSessionData]);

  const [shuffledRight, setShuffledRight] = useState<MatchPair[]>([]);

  useEffect(() => {
    const updateHeights = () => {
      const newHeights = originalPairs.map((_, index) => {
        const leftHeight = leftRefs.current[index]?.scrollHeight || 0;
        const rightHeight = rightRefs.current[index]?.scrollHeight || 0;
        return Math.max(leftHeight, rightHeight);
      });
      setPairHeights(newHeights);
    };

    updateHeights();

    window.addEventListener('resize', updateHeights);
    return () => window.removeEventListener('resize', updateHeights);
  }, [originalPairs.length, isCompleted, shuffledRight]);

  useEffect(() => {
    if (!currentQuestion || dummySubmit) return;
    if (isCompleted) {
      if (savedResponse?.pairs) {
        setShuffledRight(savedResponse.pairs);
      } else {
        setShuffledRight([...originalPairs]);
      }
    } else {
      setShuffledRight(shuffleArray(originalPairs));
    }
  }, [
    currentQuestion?.eid,
    isCompleted,
    originalPairs,
    savedResponse,
    dummySubmit,
  ]);

  const handleDragEnd = (result: DropResult) => {
    if (isCompleted || !result.destination) return;
    const items = Array.from(shuffledRight);
    const [removed] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, removed);
    setShuffledRight(items);
  };

  const isMatchCorrect = (index: number): boolean => {
    if (!isCompleted) return false;
    const currentRightItem = shuffledRight[index];
    const expectedRightItem = originalPairs[index];

    return currentRightItem?.right === expectedRightItem?.right;
  };

  const getCorrectRight = (index: number) => {
    return originalPairs[index]?.right;
  };

  const calculateScore = () => {
    const correctMatches = originalPairs.filter(
      (pair, index) => shuffledRight[index]?.right === pair.right
    ).length;

    const scorePerMatch = currentQuestion.score / originalPairs.length;
    const score = correctMatches * scorePerMatch;
    const isFullyCorrect = correctMatches === originalPairs.length;
    const isPartiallyCorrect = score > 0 && !isFullyCorrect;

    return {
      score,
      isPartiallyCorrect,
      isCorrect: isFullyCorrect,
    };
  };

  const createUserMatchedPairs = () => {
    return originalPairs.map((originalPair, index) => {
      return {
        uid: originalPair.uid,
        left: originalPair.left,
        leftThumbnail: originalPair.leftThumbnail,
        right: shuffledRight[index].right,
        rightThumbnail: shuffledRight[index].rightThumbnail,
      };
    });
  };

  const handleSubmit = async () => {
    if (
      !currentQuestion?.eid ||
      !tpSessionData?.eid ||
      !tpSessionData?.trainingId ||
      isSubmitting
    )
      return;

    const { score, isPartiallyCorrect, isCorrect } = calculateScore();
    const userMatchedPairs = createUserMatchedPairs();

    if (isRetake) {
      // if (tpSessionData?.userProgress?.[0]) {
      const currentDate = new Date();
      const existingProgress = [
        ...(tpSessionData?.userProgress?.[0]?.progress || []),
      ];
      const currentContentIndex = existingProgress?.findIndex(
        (p) => p?.id === contents?.[selectedIndex]?.eid
      );

      const updatedProgressItem = {
        type: contents?.[selectedIndex]?.type,
        id: contents?.[selectedIndex]?.eid,
        score: score,
        isCompleted: isPreview ? false : true,
        responses:
          currentContentIndex >= 0
            ? [
                ...existingProgress?.[currentContentIndex]?.responses?.map(
                  (r) => ({
                    ...r,
                    isCompleted: true,
                  })
                ),
                {
                  qid: currentQuestion?.eid,
                  score: score,
                  isCorrect: isCorrect,
                  options: [],
                  pairs: userMatchedPairs,
                  sequence: [],
                  blanks: [],
                  isCompleted: true,
                  createdAt: currentDate,
                  updatedAt: currentDate,
                },
              ]
            : [
                {
                  qid: currentQuestion?.eid,
                  score: score,
                  isCorrect: isCorrect,
                  options: [],
                  pairs: userMatchedPairs,
                  sequence: [],
                  blanks: [],
                  isCompleted: true,
                  createdAt: currentDate,
                  updatedAt: currentDate,
                },
              ],
        updatedAt: currentDate,
      };

      if (currentContentIndex >= 0) {
        existingProgress[currentContentIndex] = updatedProgressItem;
      } else {
        existingProgress?.push(updatedProgressItem);
      }

      const updatedUserProgress = {
        ...tpSessionData?.userProgress?.[0],
        progress: existingProgress,
        isCompleted: isPreview ? false : true,
      };
      setDummySubmit(true);
      updateTpSessionData({
        ...tpSessionData,
        userProgress: [updatedUserProgress],
      });
      // }

      setQuizSubmissionResult({
        showResult: true,
        isCorrect,
        isPartiallyCorrect,
        score,
      });
      return;
    }
    const isLastQuizQuestion = isLastUnansweredQuestion(
      contents?.[selectedIndex]?.questions,
      tpSessionData?.userProgress
    );

    const currentProgress = tpSessionData?.userProgress?.[0]?.progress.find(
      (p) => p.id === contents[selectedIndex].eid
    );
    const existingResponses = (currentProgress?.responses || []).map(
      cleanResponse
    );

    const mergedResponses = [
      ...existingResponses.filter((r: any) => r.qid !== currentQuestion.eid),
      cleanResponse({
        qid: currentQuestion.eid,
        pairs: userMatchedPairs,
        score,
      }),
    ];
    const totalScore =
      calculateTotalScore(contents[selectedIndex].eid, [
        ...(tpSessionData?.userProgress?.[0]?.progress || []),
      ]) + score;

    setIsSubmitting(true);

    try {
      const result = await service.updateTPProgress.updateTPProgress({
        variables: {
          input: {
            isCompleted: isLastQuizQuestion,
            itemId: contents[selectedIndex].eid,
            sessionId: tpSessionData.eid,
            trainingId: tpSessionData.trainingId,
            responses: mergedResponses,
            score: totalScore,
          },
        },
      });
      if (result?.data?.AddTpProgress) {
        updateTpSessionData({
          ...tpSessionData,
          userProgress: [result.data.AddTpProgress],
        });
        setQuizSubmissionResult({
          showResult: true,
          isCorrect: isCorrect,
          isPartiallyCorrect,
          score,
        });
      }
    } catch (error) {
      console.error('Error submitting match set:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container>
      <QuizQuestionTitle />

      <Flex gap={4} position='relative'>
        <Box width='50%' position='relative'>
          {originalPairs.map((pair, index) => {
            return (
              <Flex
                key={pair.uid}
                position='absolute'
                top={`${
                  shuffledRight[index]
                    ? rightRefs.current[index]?.offsetTop || 0
                    : 0
                }px`}
                left='0'
                width='100%'
                alignItems='center'
                gap={2}
              >
                <Box
                  ref={(el) => (leftRefs.current[index] = el)}
                  w='90%'
                  p='16px'
                  borderLeft={`2px solid ${
                    isCompleted
                      ? isMatchCorrect(index)
                        ? 'rgba(131, 191, 110, 1)'
                        : 'rgba(255, 106, 85, 1)'
                      : 'rgba(42, 133, 255, 1)'
                  }`}
                  borderTopRightRadius='12px'
                  borderBottomRightRadius='12px'
                  bg='white'
                  height={
                    pairHeights[index] ? `${pairHeights[index]}px` : 'auto'
                  }
                  position='relative'
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                >
                  <Flex w='100%' justifyContent='center' gap={3}>
                    {pair?.leftThumbnail ? (
                      <Flex
                        w='20%'
                        flexShrink={0}
                        alignSelf='center'
                        display='flex'
                        justifyContent={
                          pair.left.length > 100 ? 'flex-start' : 'center'
                        }
                        minWidth={pair.left.length > 100 ? '100%' : 'auto'}
                      >
                        <Image
                          src={pair?.leftThumbnail}
                          width={60}
                          height={60}
                          style={{
                            borderRadius: '8px',
                            objectFit: 'contain',
                            border: '1px solid #E2E8F0',
                            backgroundColor: 'white',
                          }}
                        />
                      </Flex>
                    ) : null}

                    <Flex
                      w={pair?.leftThumbnail ? '80%' : '100%'}
                      fontWeight={500}
                      fontSize='15px'
                      align='center'
                      justify='center'
                      wordBreak='break-word'
                      lineHeight='1.4'
                      flexGrow={1}
                      flexBasis={pair.left.length > 100 ? '100%' : 'auto'}
                    >
                      {pair.left}
                    </Flex>
                  </Flex>
                </Box>
                <Center
                  w='10%'
                  flexShrink={0}
                  height={
                    pairHeights[index] ? `${pairHeights[index]}px` : 'auto'
                  }
                >
                  <ArrowForwardIcon boxSize={6} color='gray.500' />
                </Center>
              </Flex>
            );
          })}
        </Box>

        <Box width='50%' position='relative'>
          {/* @ts-ignore */}
          <DragDropContext
            onDragEnd={(result) => {
              handleDragEnd(result);
              setPairHeights([...pairHeights]);
            }}
          >
            {/* @ts-ignore */}
            <Droppable
              droppableId='match-set-droppable'
              isDropDisabled={isCompleted}
            >
              {(provided) => (
                <Box ref={provided.innerRef} {...provided.droppableProps}>
                  {shuffledRight.map((rightItem, index) => {
                    return (
                      // @ts-ignore
                      <Draggable
                        key={rightItem.uid}
                        draggableId={rightItem.uid}
                        index={index}
                        isDragDisabled={isCompleted}
                      >
                        {(providedDrag, snapshotDrag) => (
                          <Box
                            ref={providedDrag.innerRef}
                            {...providedDrag.draggableProps}
                            {...providedDrag.dragHandleProps}
                            mb={2}
                          >
                            <Flex
                              p='16px'
                              flexDir='column'
                              borderRadius='12px'
                              ref={(el) => {
                                rightRefs.current[index] = el;
                              }}
                              minH={
                                pairHeights[index]
                                  ? `${pairHeights[index]}px`
                                  : 'auto'
                              }
                              bg={
                                isCompleted
                                  ? isMatchCorrect(index)
                                    ? 'rgba(181, 228, 202, 0.3)'
                                    : 'rgba(255, 106, 85, 0.2)'
                                  : snapshotDrag.isDragging
                                  ? 'rgba(177, 229, 252, 0.3)'
                                  : 'white'
                              }
                              border={
                                isCompleted
                                  ? isMatchCorrect(index)
                                    ? '1px solid rgba(131, 191, 110, 1)'
                                    : '1px solid rgba(255, 106, 85, 1)'
                                  : '1px solid #E2E8F0'
                              }
                              _hover={
                                !isCompleted
                                  ? {
                                      bg: 'rgba(177, 229, 252, 0.3)',
                                      border: '1px solid rgba(42, 133, 255, 1)',
                                    }
                                  : {}
                              }
                            >
                              <Flex align='center' justify='space-between'>
                                {rightItem?.rightThumbnail && (
                                  <Image
                                    src={rightItem?.rightThumbnail}
                                    height={40}
                                    width={40}
                                    style={{
                                      borderRadius: '8px',
                                      objectFit: 'contain',
                                      border: '1px solid #E2E8F0',
                                      backgroundColor: 'white',
                                    }}
                                  />
                                )}
                                <Text
                                  fontWeight={!isCompleted ? 600 : 500}
                                  fontSize='15px'
                                >
                                  {rightItem.right}
                                </Text>
                                {!isCompleted ? (
                                  <FontAwesomeIcon
                                    icon={faGripDotsVertical as IconProp}
                                    color='#6F767E'
                                  />
                                ) : isMatchCorrect(index) ? (
                                  <FontAwesomeIcon
                                    icon={faCircleCheck as IconProp}
                                    fontSize='20px'
                                    color='rgba(131, 191, 110, 1)'
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    icon={faCircleXmark as IconProp}
                                    fontSize='20px'
                                    color='rgba(255, 106, 85, 1)'
                                  />
                                )}
                              </Flex>
                              {isCompleted && !isMatchCorrect(index) && (
                                <Flex flexDir='column' w='full' mt={2} gap={1}>
                                  <Box
                                    bg='rgba(255, 105, 85, 0.20)'
                                    w='full'
                                    h='1px'
                                  />
                                  <Flex gap='5px' align='center'>
                                    <Box
                                      as='span'
                                      fontSize='12px'
                                      fontWeight={500}
                                    >
                                      Correct answer:
                                    </Box>
                                    <Box fontSize='15px' fontWeight={600}>
                                      {getCorrectRight(index)}
                                    </Box>
                                  </Flex>
                                </Flex>
                              )}
                            </Flex>
                          </Box>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </DragDropContext>
        </Box>
      </Flex>

      <Button
        size='lg'
        colorScheme='blue'
        onClick={() => (isCompleted ? increment() : handleSubmit())}
        isLoading={isSubmitting}
        isDisabled={isSubmitting}
      >
        {isCompleted ? 'Next' : 'Submit'}
      </Button>
    </Container>
  );
};

export default QuizMatch;
