import React, { ChangeEvent, FC, useMemo } from 'react';
import { Box, Checkbox, Flex, List, ListItem } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';

import { SelectOption } from '../../../../../atoms';
import EmptyState from 'sub-components/EmptyState';
import { ActionButton } from 'ui-components';

interface IProps {
  options: SelectOption[];
  anyFilterActive?: boolean;
  value?: string[];
  onChange?: (value: string[]) => void;
  onClose: () => void;
  initRef: React.LegacyRef<HTMLButtonElement>;
}

const GroupOptionList: FC<IProps> = ({
  options = [],
  value = [],
  anyFilterActive = false,
  onChange,
  onClose,
  initRef,
}) => {
  const { t } = useTranslation(['common', 'setting']);
  const onCheckClick = (item: SelectOption) => {
    if (!value.includes(item.value)) {
      onChange?.([...value, item.value]);
    } else {
      onChange?.(value.filter((v) => v !== item.value));
    }
  };

  const onSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
    const keys: string[] = options?.map((val) => val.value) || [];
    if (event.target.checked) {
      onChange?.([...new Set([...value, ...keys])]);
    } else {
      onChange?.([...value.filter((v) => !keys.includes(v))]);
    }
  };

  const isSelectAll = useMemo(() => {
    return (
      !options.some((it) => !value.includes(it.value)) && options?.length > 0
    );
  }, [options, value]);

  if (options?.length === 0 && anyFilterActive) {
    return (
      <EmptyState
        image='Search'
        title='Data not found'
        description='The data being searched or selected from dropdown is not found'
      />
    );
  }

  return (
    <>
      <List maxH='60vh' overflow='auto' mt={2}>
        <ListItem zIndex={1} position='sticky' top='0' bg='#FFFFFF'>
          <Flex pt={1} pb={2} justify='space-between'>
            <Box
              fontSize='13px'
              fontWeight='500'
              textTransform='uppercase'
              color='#6F767E'
            >
              {t('common:select_all')}
            </Box>

            <Checkbox isChecked={isSelectAll} onChange={onSelectAll} />
          </Flex>
        </ListItem>
        {options.map((item) => (
          <ListItem
            key={item.value}
            _notLast={{
              borderBottom: '1px solid #EAEBED',
            }}
          >
            <Flex gap={3} py={3}>
              <Flex flexDir='column' flex={1} overflow='hidden'>
                <Box
                  fontSize='15px'
                  fontWeight='500'
                  color='#1A1D1F'
                  textTransform='capitalize'
                  isTruncated
                >
                  {item.label}
                </Box>
                <Box fontSize='13px' color='#6F767E' isTruncated>
                  <Trans
                    t={t}
                    i18nKey='setting:selectGroupItemDesc'
                    context={item.type}
                    values={{
                      name: item.label,
                    }}
                  />
                </Box>
              </Flex>
              <Checkbox
                isChecked={value.includes(item.value)}
                onChange={() => onCheckClick(item)}
              />
            </Flex>
          </ListItem>
        ))}
      </List>
      <ActionButton
        flex={1}
        fontSize='15px'
        colorScheme='blue'
        actionFn={onClose}
        isFullWidth
        ref={initRef}
      >
        {t('setting:updateAutoAddMembers')}
      </ActionButton>
    </>
  );
};

export default GroupOptionList;
