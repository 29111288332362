import React, { ReactNode } from 'react';
import { FormCategory } from 'types';
import {
  IFormListEntity,
  IFormRespondents,
  IFormResponse,
  IFormResponseByIdData,
  IFormUpdatedBy,
} from '../forms-types';

export interface IFormDataContext {
  formsListData: IFormListEntity;
  formsFeedData: any;
  responseData: IFormResponse; // temporary;
  formResponseByIdData: IFormResponseByIdData;
  selectedTabIndex: number; // 0 => User wise, 1 => Question wise, 2 => Graphs, 3 => Summary
  selectedTemplateTab: string;
  searchString: string;
  sendFormSelections: any[];
  formRespondents: IFormRespondents;
  formCategories: FormCategory[];
  loadingFormCategories: boolean;
  changeTabIndex: (indexNumber: number) => void;
  changeTemplateTab: (value: string) => void;
  changeSearchString: (searchString: string) => void;
  updateFormsListDataHandler: (data: IFormListEntity) => void;
  updateFormsFeedDataHandler: (data: any) => void;
  updateResponseData: (data: any) => void;
  updateFormResponseByIdData: (data: IFormResponseByIdData) => void;
  sendFormSelectionsHandler: (selections: any) => void;
  formRespondentsHandler: (data: IFormRespondents) => void;
  updateFormCategories: (data: FormCategory[]) => void;
  updateFormCategoriesLoading: (value: boolean) => void;
}

const FormDataContext = React.createContext<IFormDataContext>({
  formsListData: {
    count: 0,
    items: [],
    pageInfo: {
      currentPage: 0,
      hasNextPage: false,
      hasPreviousPage: false,
      itemCount: 0,
      pageCount: 0,
      perPage: 0,
    },
  },
  formsFeedData: {},
  responseData: {
    count: 0,
    items: [],
    pageInfo: {
      currentPage: 0,
      hasNextPage: false,
      hasPreviousPage: false,
      itemCount: 0,
      pageCount: 0,
      perPage: 0,
    },
  },
  formResponseByIdData: {
    category: '',
    createdAt: '',
    description: '',
    eid: '',
    lastUpdatedBy: {} as IFormUpdatedBy,
    createdBy: {
      eid: '',
      name: '',
    },
    lastestResponse: {
      createdAt: '',
    },
    thumbnail: '',
    title: '',
    updatedAt: '',
    userList: [],
  },
  selectedTabIndex: 0, // pre-selected User wise tab
  selectedTemplateTab: '', // pre-selected tab
  searchString: '',
  sendFormSelections: [],
  formRespondents: {} as IFormRespondents,
  formCategories: [],
  loadingFormCategories: false,
  changeTabIndex: (indexNumber) => {},
  changeTemplateTab: (value) => {},
  changeSearchString: (searchString) => {},
  updateFormsListDataHandler: (data: IFormListEntity) => {},
  updateFormsFeedDataHandler: (data: any) => {},
  updateResponseData: (data: IFormResponse) => {},
  updateFormResponseByIdData: (data: IFormResponseByIdData) => {},
  sendFormSelectionsHandler: (selections: any) => {},
  formRespondentsHandler: (data: IFormRespondents) => {},
  updateFormCategories: (data: FormCategory[]) => {},
  updateFormCategoriesLoading: (value: boolean) => {},
});

export default FormDataContext as React.Context<IFormDataContext>;
