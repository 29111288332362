import React, { forwardRef } from 'react';
import { Flex } from '@chakra-ui/react';
import { Controller } from 'react-hook-form';

import { QuizQFormInput } from './quiz-form.types';
import { QuizTextInput } from './common';
import { ThumbnailAddButton, ThumbnailView } from './thumbnail-input';

interface IProps {}

const QuestionTitleInput = forwardRef<never, IProps>((props, ref) => {
  return (
    <Flex
      padding='16px 20px'
      borderRadius='12px'
      border='2px solid'
      borderColor='#EFEFEF'
      minHeight='140px'
    >
      <Controller<QuizQFormInput, 'thumbnail'>
        name='thumbnail'
        render={({ field }) => {
          if (field.value) {
            return (
              <ThumbnailView boxSize={100} {...field} value={field.value} />
            );
          }
          return <ThumbnailAddButton {...field} />;
        }}
      />

      <QuizTextInput
        ref={ref}
        fontSize='14px'
        fontWeight='600'
        placeholder='Type your question here'
        {...props}
      />
    </Flex>
  );
});

QuestionTitleInput.displayName = 'QuestionTitleInput';

export default QuestionTitleInput;
