import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { IFormInput } from '../../../../../audits/create-audit/form-types';

interface IProps {}

const QuizTitleInput: FC<IProps> = () => {
  const getWidth = (value: number) => {
    return Math.min(Math.max(value + 8, 30), 58) + 'ch';
  };

  return (
    <Controller<IFormInput, 'title'>
      name='title'
      defaultValue=''
      rules={{
        required: 'Please enter a name for your quiz',
        validate: (value) => {
          if (!value?.trim()) {
            return 'Please enter a valid name for your quiz';
          }
        },
      }}
      render={({ field, fieldState }) => (
        <Flex paddingBottom={4}>
          <FormControl
            width={getWidth(field.value?.length)}
            isInvalid={!!fieldState?.error}
          >
            <Input
              fontSize='20px'
              fontWeight='600'
              variant='unstyled'
              placeholder='Enter quiz name'
              {...field}
              resize='horizontal'
            />

            <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
          </FormControl>
          <Text as='span' color='rgba(255, 106, 85, 1)' pl={1}>
            *
          </Text>
          <Spacer />
        </Flex>
      )}
    />
  );
};

export default QuizTitleInput;
