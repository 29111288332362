export const sortArray = (
  arr: any[],
  key: string,
  order: 'asc' | 'desc' = 'asc'
) => {
  return arr?.sort((a: any, b: any) => {
    const aValue = a[key];
    const bValue = b[key];

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return order === 'asc'
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    } else {
      return order === 'asc' ? aValue - bValue : bValue - aValue;
    }
  });
};

export const prepareLeaderboardMemberData = (
  userWiseData,
  users,
  locations
) => {
  const tempObj = {
    all: [],
  };

  const isUserAlreadyAddedToAllTab = [];

  locations?.forEach((location) => {
    let locationUsers = users
      ?.filter((user) =>
        user?.locations?.some((loc) => loc?.eid === location?.eid)
      )
      ?.map((item) => {
        const data = userWiseData?.find((i) => i?.userId === item?.eid);
        const label = item?.locations?.map((i) => i?.name);

        return {
          userId: item?.eid,
          name: item?.name,
          userRole: item?.role,
          locationName: location?.name,
          tpCompleted: data ? data?.completed : 0,
          tpTotal: data ? data?.total : 0,
          tpLocationCount: data ? data?.locations?.length - 1 : 0,
          completionPercent: data?.total
            ? Number((data?.completed / data?.total) * 100)
            : 0,
          allLocationLabels: label?.join(', '),
          profilePic: item?.profilePic,
        };
      });

    // Sort users by completionPercent (Descending Order)
    locationUsers = locationUsers?.sort(
      (a, b) => b.completionPercent - a.completionPercent
    );

    // Assign rank after sorting
    locationUsers = locationUsers?.map((user, index) => ({
      ...user,
      rank: index + 1,
    }));

    tempObj[`${location?.eid}`] = locationUsers;

    // Add unique users to 'all' list
    locationUsers?.forEach((item) => {
      if (!isUserAlreadyAddedToAllTab.includes(item?.userId)) {
        tempObj?.all?.push(item);
        isUserAlreadyAddedToAllTab.push(item?.userId);
      }
    });
  });

  // Sort 'all' users by completionPercent (Descending Order)
  tempObj['all'] = tempObj?.all?.sort(
    (a, b) => b.completionPercent - a.completionPercent
  );

  // Assign rank after sorting in 'all' category
  tempObj['all'] = tempObj?.all?.map((item, index) => ({
    ...item,
    allUserRank: index + 1,
  }));

  return tempObj;
};

export const trainingprepareLeaderboardMemberData = (
  userWiseData,
  users,
  locations
) => {
  const tempObj = {
    all: [],
  };

  const isUserAlreadyAddedToAllTab = new Set();
  const filteredUsers = users?.filter((user) => user?.type === 'user');

  locations?.forEach((location) => {
    let locationUsers = filteredUsers
      ?.filter((user) =>
        user?.locations?.some((loc) => loc?.eid === location?.eid)
      )
      ?.map((item) => {
        const data = userWiseData?.find((i) => i?.userId === item?.eid);
        const label = item?.locations?.map((i) => i?.name);

        return {
          userId: item?.eid,
          name: item?.name,
          userRole: item?.role,
          locationName: location?.name,
          tpCompleted: data ? data?.completed : 0,
          tpTotal: data ? data?.total : 0,
          tpLocationCount: data ? data?.locations?.length - 1 : 0,
          completionPercent: data?.total
            ? Number((data?.completed / data?.total) * 100)
            : 0,
          allLocationLabels: label?.join(', '),
          profilePic: item?.profilePic,
        };
      });

    locationUsers = locationUsers?.sort(
      (a, b) => b.completionPercent - a.completionPercent
    );

    locationUsers = locationUsers?.map((item, index) => ({
      ...item,
      rank: index + 1,
    }));

    tempObj[`${location?.eid}`] = locationUsers;

    locationUsers?.forEach((item) => {
      if (!isUserAlreadyAddedToAllTab.has(item?.userId)) {
        tempObj.all.push(item);
        isUserAlreadyAddedToAllTab.add(item?.userId);
      }
    });
  });

  tempObj.all = tempObj.all.sort(
    (a, b) => b.completionPercent - a.completionPercent
  );

  tempObj.all = tempObj.all.map((item, index) => ({
    ...item,
    allUserRank: index + 1,
  }));

  return tempObj;
};

export const getMyTrainingLeaderboardMembers = (
  userWiseData,
  users,
  locations
) => {
  const tempObj = {
    all: [],
  };

  const isUserAlreadyAddedToAllTab = new Set();
  const filteredUsers = users?.filter((user) => user?.type === 'user');

  locations?.forEach((location) => {
    let locationUsers = filteredUsers?.map((item) => {
      const data = userWiseData?.find((i) => i?.userId === item?.eid);
      const label = item?.locations?.map((i) => i?.name);

      return {
        userId: item?.eid,
        name: item?.name,
        userRole: item?.role,
        locationName: location?.name,
        tpCompleted: data ? data?.completed : 0,
        tpTotal: data ? data?.total : 0,
        tpLocationCount: data ? data?.locations?.length - 1 : 0,
        completionPercent: data?.total
          ? Number((data?.completed / data?.total) * 100)
          : 0,
        allLocationLabels: label?.join(', '),
        profilePic: item?.profilePic,
      };
    });

    locationUsers = locationUsers?.sort(
      (a, b) => b.completionPercent - a.completionPercent
    );

    locationUsers = locationUsers?.map((item, index) => ({
      ...item,
      rank: index + 1,
    }));

    tempObj[`${location?.eid}`] = locationUsers;

    locationUsers?.forEach((item) => {
      if (!isUserAlreadyAddedToAllTab.has(item?.userId)) {
        tempObj.all.push(item);
        isUserAlreadyAddedToAllTab.add(item?.userId);
      }
    });
  });

  tempObj.all = tempObj.all.sort((a, b) => {
    if (b?.completionPercent !== a?.completionPercent) {
      return b?.completionPercent - a?.completionPercent;
    }

    if (b.completionPercent === a.completionPercent) {
      return a?.name?.localeCompare(b?.name);
    }
  });

  tempObj.all = tempObj.all.map((item, index) => ({
    ...item,
    allUserRank: index + 1,
  }));

  return tempObj;
};
