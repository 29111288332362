import React, { FC, useContext, useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';

import { SearchInput } from 'atoms';
import FormDataContext from 'pages/forms/FormStore/form-data-context';
import FormResponseTabsList from './FormResponseTabsList';
import DateFilter from 'ui-components/DateFilter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCalendar } from '@fortawesome/pro-light-svg-icons';
import {
  IDateFilterOptions,
  IDateFilterRangeEntity,
} from 'ui-components/DateFilter/types';
import { AmplitudeEvent, deployEvent } from '../../../../shared';

const FormResponseTabsContainer: FC<{
  dateRangeFilter: IDateFilterRangeEntity | undefined;
  isAnyTableFilterSelected: boolean;
  selectedDateFilter: IDateFilterOptions | undefined;
  onDateFilterRangeSelectionHandler: (
    range: IDateFilterRangeEntity | undefined
  ) => void;
  setSelectedDateFilter: React.Dispatch<
    React.SetStateAction<IDateFilterOptions | undefined>
  >;
}> = ({
  dateRangeFilter,
  isAnyTableFilterSelected,
  selectedDateFilter,
  onDateFilterRangeSelectionHandler,
  setSelectedDateFilter,
}) => {
  const { t } = useTranslation('form');
  const formCtx = useContext(FormDataContext);

  const [searchQuery, setSearchQuery] = useState('');

  const debouncedSearchStringHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const nextValue = e?.target?.value;
    setSearchQuery(nextValue);
    debouncedSearchStringFn(nextValue);
  };

  const debouncedSearchStringFn = debounce((nextValue: string) => {
    setSearchStringHandler(nextValue);
  }, 100);

  const setSearchStringHandler = (str: string) => {
    formCtx.changeSearchString(str);
  };

  const onFilterSelection = (range: IDateFilterRangeEntity | undefined) => {
    deployEvent(AmplitudeEvent.FORM_RESPONSE_DATE_FILTER_APPLIED);
    onDateFilterRangeSelectionHandler(range);
  };

  if (
    formCtx?.responseData?.items?.length === 0 &&
    !dateRangeFilter?.from &&
    !formCtx?.searchString &&
    !isAnyTableFilterSelected
  ) {
    return null;
  }

  return (
    <Flex>
      <Box w='50%'>
        <FormResponseTabsList dateRangeFilter={dateRangeFilter} />
      </Box>
      <Box w='50%'>
        {formCtx.selectedTabIndex !== 2 && (
          <Flex gap='10px' w='full' justify='flex-end'>
            <DateFilter
              placeholder='Filter response by date'
              placeholderLeftIcon={
                <FontAwesomeIcon
                  icon={faCalendar as IconProp}
                  fontSize='16px'
                  color={
                    !dateRangeFilter?.from
                      ? 'rgba(26, 29, 31, 1)'
                      : 'rgba(42, 133, 255, 1)'
                  }
                />
              }
              onFilterSelection={onFilterSelection}
              selectedDateFilter={selectedDateFilter}
              setSelectedDateFilter={setSelectedDateFilter}
            />
            <Box w='300px'>
              <SearchInput
                size={'md'}
                placeholder={
                  formCtx.selectedTabIndex === 0
                    ? t('search_by_user_role_location_placeholder')
                    : t('search_by_user_placeholder')
                }
                value={searchQuery}
                hideShortcuts
                onChange={(e) => {
                  debouncedSearchStringHandler(e);
                }}
              />
            </Box>
          </Flex>
        )}
      </Box>
    </Flex>
  );
};

export default FormResponseTabsContainer;
