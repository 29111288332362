import React, { forwardRef, useMemo } from 'react';
import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useControllableState,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { SelectOption } from '../../../../../../atoms';

export interface RFilterProps {
  filterValue?: SelectOption;
  onFilterChange?: (value: SelectOption) => void;
  filterOptions: SelectOption[];
}

const BaseResourceFilter = forwardRef<never, RFilterProps>(
  ({ filterValue, onFilterChange, filterOptions = [] }, ref) => {
    const [internalValue, updateValue] = useControllableState<SelectOption>({
      value: filterValue,
      onChange: onFilterChange,
    });

    const _value = useMemo(() => {
      return filterOptions?.find(
        (val) => val.value === (internalValue?.value || '')
      );
    }, [internalValue, filterOptions]);

    return (
      <Menu matchWidth closeOnSelect boundary='scrollParent' autoSelect={false}>
        <MenuButton
          ref={ref}
          as={Button}
          size='lg'
          borderRadius='12px'
          rightIcon={<ChevronDownIcon boxSize='28px' color='#6F767E' />}
          paddingInline='12px'
          variant='outline'
          bg='white'
          width='200px'
          minWidth='200px'
          fontSize='14px'
          fontWeight='600'
          // isDisabled={disabled}
        >
          {_value ? (
            <Box
              textAlign='left'
              whiteSpace='nowrap'
              color='#6F767E'
              isTruncated
            >
              {_value.label}
            </Box>
          ) : (
            <Box opacity='0.5'>Select assign by</Box>
          )}
        </MenuButton>
        <MenuList
          minWidth='200px'
          width='inherit'
          display='flex'
          flexDirection='column'
          padding='16px'
          borderRadius='12px'
          zIndex={100}
          maxHeight='320px'
          overflow='hidden auto'
          gap={1}
        >
          {filterOptions?.map((item) => {
            const isSelected = item.value === internalValue?.value;
            return (
              <MenuItem
                key={item.value}
                gap={3}
                height='48px'
                alignItems='center'
                paddingInline='12px'
                borderRadius='12px'
                cursor='pointer'
                aria-selected={isSelected}
                _selected={{ bg: '#2A85FF1A', color: '#2A85FF' }}
                _hover={{ bg: '#F4F4F3' }}
                onClick={() => updateValue?.(item)}
              >
                <Box
                  flex={1}
                  fontSize='15px'
                  fontWeight='600'
                  isTruncated
                  color='#33383F'
                >
                  {item.label}
                </Box>
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    );
  }
);

BaseResourceFilter.displayName = 'BaseResourceFilter';

export default BaseResourceFilter;
