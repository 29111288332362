import React, { FC } from 'react';
import { Box, Center, CloseButton, Flex } from '@chakra-ui/react';
import { TAssLocationType } from '../../../../../../../types';
import { UserEntityData } from '../../../../../../../shared/graphql/shared-types';

import { ReactComponent as RocketIcon } from '../../../../../../../assets/images/rocket.svg';
import { ReactComponent as ZapIcon } from '../../../../../../../assets/images/zap-light.svg';

interface IProps {
  values: UserEntityData[];
  onRemoveClick?: (value?: string, locationType?: TAssLocationType) => void;
  locationType?: TAssLocationType;
}

export const ALLCheckType: TAssLocationType[] = ['all', 'open', 'development'];

const buttonData: Record<TAssLocationType, string> = {
  all: 'All open and launching locations',
  open: 'All open locations',
  development: 'All launching locations',
  custom: 'Selected locations',
};

const SelectedLocations: FC<IProps> = ({
  values,
  onRemoveClick,
  locationType,
}) => {
  if (locationType && ALLCheckType.includes(locationType)) {
    return (
      <Flex
        gap='10px'
        align='center'
        justify='space-between'
        bg='#EFEFEF'
        paddingY='4px'
        paddingLeft='10px'
        paddingRight='6px'
        borderRadius='7px'
        fontSize='14px'
      >
        <span>{buttonData[locationType]}</span>
        {onRemoveClick ? (
          <CloseButton
            size='sm'
            onClick={(event) => {
              event.preventDefault();
              onRemoveClick?.(undefined, locationType);
            }}
          />
        ) : (
          <Box />
        )}
      </Flex>
    );
  }

  return (
    <>
      {values.map((value) => {
        const isInDev = value.locationStatus === 'development';
        const Icon = isInDev ? RocketIcon : ZapIcon;

        return (
          <Flex
            key={value.eid}
            gap='10px'
            align='center'
            justify='space-between'
            bg={isInDev ? '#CABDFF' : '#B5E4CA99'}
            paddingY='4px'
            paddingLeft='10px'
            paddingRight='6px'
            borderRadius='7px'
          >
            <Center
              boxSize='24px'
              borderRadius='5px'
              bg={isInDev ? '#8E59FF' : '#83BF6E'}
            >
              <Icon width={12} height={12} />
            </Center>
            <span>{value.name}</span>
            {onRemoveClick ? (
              <CloseButton
                size='sm'
                onClick={(event) => {
                  event.preventDefault();
                  onRemoveClick?.(value.eid);
                }}
              />
            ) : (
              <Box />
            )}
          </Flex>
        );
      })}
    </>
  );
};

export default SelectedLocations;
