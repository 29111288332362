import React, { forwardRef, useMemo } from 'react';
import { useReactiveVar } from '@apollo/client';
import {
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  useControllableState,
} from '@chakra-ui/react';
import { Button } from '../../../../../../../atoms';
import { usersEntityObj } from '../../../../../../Header';
import { useUserDataSelector } from '../../../../../../../hooks';
import { toArray } from '../../../../../../../utils';
import { AuthRole } from '../../../../../../../authorization';
import MemberSelectContent from './MemberSelectContent';
import MemberSelectButton from './MemberSelectButton';

interface IProps {
  value?: string[];
  onChange?: (value: string[]) => void;
  onBlur?: () => void;
  disabled?: boolean;
  index: number;
}

const TrainingMemberSelect = forwardRef<never, IProps>(
  ({ value: _value, onChange, disabled, index }, ref) => {
    const authUser = useUserDataSelector((state) => ({
      authRole: state.authRole,
      locations: state.locations?.map((location) => location.eid),
    }));
    const entityUsers = useReactiveVar(usersEntityObj);

    const members = useMemo(() => {
      return toArray(entityUsers)
        .filter((user) => {
          if (user?.type !== 'user' || user.status !== 'active') {
            return false;
          }

          if (authUser?.authRole === AuthRole.LOCATION_OWNER) {
            return (
              user?.locations?.some((loc) =>
                authUser?.locations?.includes(loc.eid)
              ) && user.status === 'active'
            );
          }

          return user.type === 'user' && user.status === 'active';
        })
        .sort((a, b) => a.name!.localeCompare(b.name!));
    }, [entityUsers, authUser]);

    const [internalValue, updateValue] = useControllableState<string[]>({
      value: _value,
      onChange: onChange,
    });

    const values = useMemo(() => {
      return members.filter((val) => internalValue?.includes(val.eid));
    }, [members, internalValue]);

    const onRemoveClick = (data: string) => {
      updateValue(internalValue.filter((val) => val !== data));
    };

    if (disabled) {
      return (
        <MemberSelectButton ref={ref} values={values} disabled={disabled} />
      );
    }

    return (
      <Flex flexDir='column' gap={2}>
        <Popover isLazy matchWidth>
          <PopoverTrigger>
            <MemberSelectButton
              ref={ref}
              values={values}
              onRemoveClick={onRemoveClick}
            />
          </PopoverTrigger>

          <PopoverContent
            width='inherit'
            borderRadius='12px'
            boxShadow='md'
            _focus={{}}
          >
            <MemberSelectContent
              index={index}
              members={members}
              value={internalValue}
              updateValue={updateValue}
            />
          </PopoverContent>
        </Popover>
        {values?.length > 10 ? (
          <Button
            alignSelf='start'
            size='sm'
            variant='ghost'
            colorScheme='blue'
            fontSize='12px'
            onClick={() => updateValue([])}
          >
            Clear all
          </Button>
        ) : null}
      </Flex>
    );
  }
);

TrainingMemberSelect.displayName = 'TrainingMemberSelect';

export default TrainingMemberSelect;
