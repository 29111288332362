import { gql } from '@apollo/client';

export const GET_TRACKING_TP_DATA = gql`
  query TpPaginationNew(
    $filter: FilterFindManyTPInput
    $sort: SortFindManyTPInput
    $page: Int
    $perPage: Int
  ) {
    TpPaginationNew(
      filter: $filter
      sort: $sort
      page: $page
      perPage: $perPage
    ) {
      count
      items {
        eid
        title
        thumbnail
        category
        updatedAt
        createdBy
        createdAt
        status
        lastSession {
          eid
          completedCount
          assignedCount
          updatedAt
          overdueCount
        }
        accessPermission {
          admin
          locationOwner
          superadmin
        }
      }
    }
  }
`;

export const GET_TRACKING_TP_DATA_ORIGINAL = gql`
  query TpPagination(
    $filter: FilterFindManyTPInput
    $sort: SortFindManyTPInput
    $page: Int
    $perPage: Int
  ) {
    TpPagination(filter: $filter, sort: $sort, page: $page, perPage: $perPage) {
      count
      items {
        eid
        title
        thumbnail
        category
        updatedAt
        createdBy
        createdAt
        status
        lastSession {
          eid
          completedCount
          assignedCount
          updatedAt
          overdueCount
        }
        accessPermission {
          admin
          locationOwner
          superadmin
        }
      }
    }
  }
`;

export const GET_TRAINING_CATEGORIES = gql`
  query EntityTpCategories {
    EntityTpCategories {
      eid
      name
    }
  }
`;

export const GET_OVERALL_LOCATION_WISE_ANALYTICS = gql`
  query Query {
    TpOverallLocationsWiseAnalytics
  }
`;

export const GET_OVERALL_USER_WISE_ANALYTICS = gql`
  query Query {
    TpOverallUserWiseAnalytics
  }
`;

export const GET_TP_OVERALL_ANALYTICS = gql`
  query Query($locations: [String!]) {
    TpOverallAnalytics(locations: $locations)
  }
`;

export const GET_TP_OVERALL_USER_DETAILS_ANALYTICS = gql`
  query TpOverallUserDetailsAnalytics($userId: String!) {
    TpOverallUserDetailsAnalytics(userId: $userId) {
      total
      overdue
      needsEvaluation
      inProgress
      evaluated
      completed
      yetToStart
      activeTp
      inActiveTp
    }
  }
`;

export const MARK_TP_ACTIVE = gql`
  mutation ResumeTP($eid: ID!) {
    ResumeTP(eid: $eid) {
      succeed
    }
  }
`;

export const MY_EVALUATIONS_COUNT = gql`
  query MY_EVALUATIONS_COUNT_WEB {
    TpNewEvaluations
  }
`;
