import React, { FC, useMemo, useState } from 'react';
import { Checkbox, Flex, Text } from '@chakra-ui/react';
import { HeaderColors } from 'configs';
import { BoxHeader } from 'ui-components';
import SearchInput from 'atoms/SearchInput';
import { ReactComponent as BackButton } from 'assets/images/back.svg';
import { PageNameType } from './MainContainer';
import { ClearFilterType } from './FilterContainer';
import EmptyState from './EmptyState';
import { FiltersType } from '../../MyTrainingsContainer';
import { ITypes } from '../../TrackPathDashboard/bottomSection/Header';
// import {
//   FiltersType,
//   useTrainingContext,
// } from '../../../TrainingTableContainer';
// import { ITypes } from '../Header';

interface IProps {
  filters: FiltersType;
  setPageName: React.Dispatch<React.SetStateAction<PageNameType>>;
  handleStatusSelection: (
    e: React.ChangeEvent<HTMLInputElement>,
    status: ITypes
  ) => void;
  clearFilters: (type?: ClearFilterType) => void;
}

const StatusFilter: FC<IProps> = ({
  setPageName,
  filters,
  handleStatusSelection,
  clearFilters,
}) => {
  const { statuses, selectedStatusesId } = filters;
  const [statusSearchText, setStatusSearchText] = useState('');
  // const { trackingTpPaginationLoader, trainingCategoriesLoader } =
  //   useTrainingContext();

  // UseMemo for efficient filtering
  const filteredStatus = useMemo(
    () =>
      statuses.filter((status) =>
        status?.name?.toLowerCase().includes(statusSearchText.toLowerCase())
      ),
    [statusSearchText, statuses]
  );

  const statusCount = statuses.length;
  const filteredCount = filteredStatus.length;

  return (
    <>
      <Flex alignItems={'center'} mt={'30px'} justifyContent={'space-between'}>
        <BoxHeader title='Filters' color={HeaderColors.Purple} />
        <Text
          cursor={'pointer'}
          onClick={() => clearFilters('statuses')}
          fontSize={'14px'}
          fontWeight={400}
          color={
            filters?.selectedStatusesId?.length > 0 ? '#2A85FF' : '#6F767E'
          }
        >
          Clear Selection
        </Text>
      </Flex>

      <Flex mt={'20px'} gap={3} alignItems={'center'} marginBottom={'1rem'}>
        <BackButton
          width={'1.2rem'}
          onClick={() => setPageName('base')}
          cursor={'pointer'}
        />
        <Text
          style={{
            fontWeight: 600,
            fontSize: 14,
          }}
        >
          Status
        </Text>
      </Flex>
      {/* <SearchInput
        className='uploaded-by-search-field'
        value={setStatusSearchText}
        onChange={(e) => setStatusSearchText(e?.target?.value)}
        placeholder='Search by name'
        hideShortcuts
      /> */}

      {filteredCount > 0 ? (
        <Flex maxHeight={'300px'} flexDir={'column'} overflowY={'auto'}>
          {filteredStatus?.map((status) => {
            return (
              <Flex
                key={status?.eid}
                gap={4}
                alignItems={'center'}
                padding={'0.5rem 0px'}
              >
                <Checkbox
                  // disabled={
                  //   trackingTpPaginationLoader || trainingCategoriesLoader
                  // }
                  value={status?.eid}
                  onChange={(e) => handleStatusSelection(e, status)}
                  isChecked={
                    filters?.selectedStatusesId?.includes(status?.eid)
                      ? true
                      : false
                  }
                >
                  <Flex alignItems={'center'} gap={4}>
                    <Flex justifyContent={'center'} flexDir={'column'}>
                      <Text fontSize={'14px'}>{status?.name}</Text>
                    </Flex>
                  </Flex>
                </Checkbox>
              </Flex>
            );
          })}
        </Flex>
      ) : (
        <EmptyState view='statuses' />
      )}
    </>
  );
};

export default StatusFilter;
