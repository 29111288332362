import { FC } from 'react';
import { useFormState } from 'react-hook-form';
import { useBlockPrompt, useCloseConfirm } from '../../../../routes';

const TrainingNavigationBlock: FC = () => {
  const isDirty = useFormState().isDirty;

  useCloseConfirm(isDirty);

  useBlockPrompt({
    canShowPrompt: isDirty,
    blockConfig: (nextLocation) => {
      if (nextLocation.pathname !== window.location.pathname) {
        return {
          alertType: 'TrainingChangesAlert',
        };
      }
      return undefined;
    },
  });

  return null;
};

export default TrainingNavigationBlock;
