import moment from 'moment';
import { JSONContent } from 'delightree-editor';
import { toArray } from '../../../../../../../utils';

import { LogEditResponse } from './create-log.graphql';
import { IFormInput } from './useSubmitHandler';

export const mergeContent = (
  content: JSONContent,
  subject: string
): JSONContent => {
  return {
    ...content,
    content: [
      {
        type: 'heading',
        attrs: {
          level: 1,
        },
        content: [
          {
            type: 'text',
            text: subject,
          },
        ],
      },
      ...toArray(content?.content),
    ],
  };
};

export const editableDataConvert = (
  data: LogEditResponse['conversationLogsFindById']
): IFormInput => {
  return {
    eid: data.eid,
    showLO: data?.showLO,
    category: {
      value: data.category,
      label: data.category,
    },
    occurrenceDate: moment(data.occurrenceDate || data.createdAt).toDate(),
    creator: data.createdByUser?.name,
    content: mergeContent(data.content, data.subject),
  };
};
