import { Box, Flex } from '@chakra-ui/react';

const StackedProgressBar = ({
  onTime,
  overDue,
}: {
  onTime: number;
  overDue: number;
}) => {
  const total = onTime + overDue;
  const onTimeWidth = total > 0 ? (onTime / total) * 450 : 0;
  const overDueWidth = total > 0 ? (overDue / total) * 450 : 0;

  const isEmpty = onTimeWidth === 0 && overDueWidth === 0;

  return (
    <Flex
      width='450px'
      height='12px'
      borderRadius='2px'
      overflow='hidden'
      gap='2px'
    >
      {isEmpty ? (
        <Box width='450px' bg='#D3D3D3' borderRadius='2px' />
      ) : (
        <>
          <Box width={`${onTimeWidth}px`} bg='#B5E4CA' borderRadius='2px' />
          <Box width={`${overDueWidth}px`} bg='#FFBC99' borderRadius='2px' />
        </>
      )}
    </Flex>
  );
};

export default StackedProgressBar;
