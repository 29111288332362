import React, { FC } from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Tooltip,
} from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Controller, useFormContext } from 'react-hook-form';
import { FlexCard } from '../../../../../../atoms';
import { toNumber } from '../../../../../../utils';
import { TFormInput } from '../../training-input.types';
import { useEditDisabled } from '../../common';
import SelectInput from './SelectInput';
import { useRepeatingSelect } from './repeating';
import CustomStartDate from './CustomStartDate';
import CustomEndDate from './CustomEndDate';
import { useDurationSelect } from './duration';
import { DependantTraining } from './dependant-training';

import FrequencySelect from './FrequencySelect';
import DeadlineTypeSelect from './DeadlineTypeSelect';
import AssignInfoForNewEmployee from './AssignInfoForNewEmployee';
import CustomDuration from './CustomDuration';
import { convertDurationToDays, convertRepeatCycle } from './schedule.hepers';
import { validateAssignType, validateDeadlineType } from './schedule.validator';
import { useAssignOptions } from './useScheduleOptions';
import { useAssignmentDisabled } from './useAssignmentDisabled';

interface IProps {}

const ScheduleInputs: FC<IProps> = () => {
  const repeatingSelect = useRepeatingSelect();
  const durationSelect = useDurationSelect();
  const { getValues, setValue, setError } = useFormContext<TFormInput>();
  const editDisabled = useEditDisabled();

  const assignmentDisabled = useAssignmentDisabled(editDisabled);

  const assignOptions = useAssignOptions();

  const onFrequencyChange = (onChange: CallableFunction) => {
    return function (value: string) {
      if (value === 'repeat') {
        repeatingSelect({
          values: getValues('repeatCycle')!,
          onSaveChange: (newValue) => {
            setValue('repeatCycle', newValue as never);

            if (
              getValues('frequency') === 'repeat' &&
              getValues('deadlineType') === 'duration'
            ) {
              if (
                toNumber(convertRepeatCycle(newValue)?.dayValue, 0) <
                convertDurationToDays(getValues('duration')!)
              ) {
                setValue('duration', null);
                setError('deadlineType', {
                  type: 'validate',
                  message:
                    'Path duration must be shorter than the training frequency. Please enter a new duration.',
                });
              }
            }

            onChange(value);

            if (getValues('deadlineType') === 'date') {
              setValue('deadlineType', 'noDeadline');
            }
          },
        });
      } else {
        setValue('repeatCycle', null);
        onChange(value);
      }
    };
  };

  const onDeadlineChange = (onChange: CallableFunction) => {
    return function (value: string) {
      if (value === 'duration') {
        durationSelect({
          repeatCycle: getValues('repeatCycle'),
          values: getValues('duration')!,
          onSaveChange: (newValue) => {
            setValue('duration', newValue);
            onChange(value);
          },
        });
      } else {
        setValue('duration', null);
        onChange(value);
      }
    };
  };

  return (
    <FlexCard flexDir='column' bg='#FCFCFC' p='18px 26px' gap={6}>
      <Flex align='center' gap={4}>
        <Flex
          gap={2}
          width='160px'
          fontWeight='600'
          color='#33383F'
          align='center'
        >
          <span>Training frequency</span>
          <Tooltip
            maxWidth='280px'
            paddingX='8px'
            paddingY='6px'
            borderRadius='4px'
            placement='bottom-end'
            label='Setting a repeating frequency means the path must be completed by the selected date in the week, month, or year, based on the chosen frequency.'
          >
            <InfoOutlineIcon />
          </Tooltip>
        </Flex>

        <Controller<TFormInput, 'frequency'>
          name='frequency'
          defaultValue='once'
          disabled={editDisabled}
          render={({ field, fieldState }) => (
            <FormControl flex={3} isInvalid={fieldState.invalid}>
              <FrequencySelect
                {...field}
                onChange={onFrequencyChange(field.onChange)}
              />
              <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
            </FormControl>
          )}
        />
      </Flex>

      <Flex align='center' gap={4}>
        <Box
          alignSelf='start'
          width='160px'
          fontWeight='600'
          color='#33383F'
          lineHeight='48px'
        >
          Completion deadline
        </Box>

        <Flex flex={3} gap={5}>
          <Controller<TFormInput, 'deadlineType'>
            name='deadlineType'
            defaultValue='noDeadline'
            disabled={editDisabled}
            rules={{
              required: 'Completion deadline field is required',
              validate: validateDeadlineType,
              deps: ['duration', 'frequency', 'assigneeUserType'],
            }}
            render={({ field, fieldState }) => (
              <FormControl isInvalid={fieldState.invalid}>
                <DeadlineTypeSelect
                  {...field}
                  onChange={onDeadlineChange(field.onChange)}
                />

                <FormErrorMessage>
                  {fieldState?.error?.message}
                </FormErrorMessage>
              </FormControl>
            )}
          />

          <CustomDuration disabled={editDisabled} />

          <CustomEndDate disabled={editDisabled} />
        </Flex>
      </Flex>

      <Flex align='center' gap={4}>
        <Box width='160px' fontWeight='600' color='#33383F'>
          Assign training
        </Box>

        <Flex flex={3} gap={5}>
          <Controller<TFormInput, 'assignmentType'>
            name='assignmentType'
            defaultValue='now'
            disabled={assignmentDisabled}
            rules={{
              validate: validateAssignType,
              deps: [
                'assigneeUserType',
                'frequency',
                'deadlineType',
                'deadlineDate',
              ],
            }}
            render={({ field, fieldState }) => (
              <FormControl isInvalid={fieldState.invalid}>
                <SelectInput options={assignOptions} {...field} />
                <FormErrorMessage>
                  {fieldState?.error?.message}
                </FormErrorMessage>
              </FormControl>
            )}
          />

          <CustomStartDate disabled={assignmentDisabled} />
        </Flex>
      </Flex>

      <DependantTraining disabled={assignmentDisabled} />

      <AssignInfoForNewEmployee />
    </FlexCard>
  );
};

export default ScheduleInputs;
