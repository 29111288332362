import {
  Center,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  Box,
} from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnsType } from 'antd/es/table';
import { ReactComponent as Sort_ascendingIcon } from 'assets/images/nexus/compliance/sort_ascending.svg';
import { Button, Table } from 'atoms';
import { HeaderColors } from 'configs';
import { FC } from 'react';
import EmptyState from 'sub-components/EmptyState';
import { BoxHeader, Image } from 'ui-components';
import { getImage } from 'utils';
import BaseListRender from './BaseListRender';
import RenderScoreCard from './RenderScoreCards';

interface IProps {
  tab: string;
  renderMemberData?: any;
  selectedLocation?: string;
  // overallUserWiseData: IFinalOverallUserWiseAnalytics[];
}

const MemberList: FC<IProps> = ({
  tab,
  renderMemberData,
  selectedLocation,
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const baseListData =
    renderMemberData?.length > 3
      ? renderMemberData.slice(3, renderMemberData?.length)
      : [];

  const columns: ColumnsType<any> = [
    {
      title: 'Rank',
      dataIndex: 'rank',
      key: 'rank',
      sorter: (a, b) => {
        if (selectedLocation === 'all') {
          return a.allUserRank - b.allUserRank;
        }
        return a.rank - b.rank;
      },
      sortIcon: ({ sortOrder }) => <Sort_ascendingIcon cursor={'pointer'} />,
      width: '20%',
      align: 'center',
      render: (data, record) => (
        <Flex as='span' fontWeight={500} justifyContent='center'>
          {selectedLocation === 'all' ? record?.allUserRank : record?.rank}
        </Flex>
      ),
    },
    {
      title: 'Members',
      dataIndex: 'name',
      key: 'name',
      width: '60%',
      render: (data, record) => (
        <Flex gap={2} alignItems='center'>
          <Image
            src={getImage(record.profilePic, record.name)}
            height={24}
            width={24}
            style={{
              borderRadius: '5px',
            }}
          />
          <Text as='span' color='#111315' fontWeight={600} fontSize='14px'>
            {data}
          </Text>
        </Flex>
      ),
    },

    {
      title: 'Rate(%)',
      dataIndex: 'completionPercent',
      key: 'completionPercent',
      width: '25%',
      align: 'center',
      render: (data) => (
        <Flex color='#000000' fontWeight={500} gap={2} justifyContent='center'>
          {data?.toFixed(2)}
        </Flex>
      ),
    },
  ];

  return (
    <Flex
      width={'100%'}
      gap={8}
      flexDir={'column'}
      mt={'35px'}
      alignItems={'center'}
    >
      {renderMemberData?.length ? (
        <>
          <RenderScoreCard type='overall' completeData={renderMemberData} />
          <BaseListRender
            tab={tab}
            renderMemberData={baseListData}
            selectedLocation={selectedLocation}
          />
          <Button
            variant='unstyled'
            color='#2A85FF'
            fontWeight={600}
            alignItems='center'
            gap={2}
            display='flex'
            onClick={onOpen}
          >
            View all
            <FontAwesomeIcon
              icon={faArrowRight as IconProp}
              fontSize='15px'
              color='#2A85FF'
            />
          </Button>
          <Modal isOpen={isOpen} onClose={onClose} isCentered size='2xl'>
            <ModalOverlay />
            <ModalContent padding='12px' borderRadius='12px'>
              <ModalHeader>
                <BoxHeader
                  title='Training completion rate'
                  color={HeaderColors.Yellow}
                />
              </ModalHeader>
              <ModalCloseButton top='1.5rem' right='1.5rem' fontSize='18px' />
              <ModalBody display='flex' flexDirection='column' gap={5}>
                <Text as='span' color='#272B30' fontWeight={600}>
                  All your members at a glance
                </Text>
                <Box overflowY={'scroll'} maxH={'504px'}>
                  <Table
                    columns={columns}
                    dataSource={renderMemberData}
                    rowKey='id'
                    pagination={false}
                    bordered
                  />
                </Box>
              </ModalBody>
            </ModalContent>
          </Modal>
        </>
      ) : (
        <Center height='100%' width='100%'>
          <EmptyState image='Report' title='No data found' imageSize='300px' />
        </Center>
      )}
    </Flex>
  );
};

export default MemberList;
