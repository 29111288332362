import { useMutation } from '@apollo/client';
import { AppRoute, useHistory } from '../../../../routes';
import { isNullOrUndefined } from '../../../../utils/objectHelper';
import { toArray } from '../../../../utils';
import { AmplitudeEvent, deployEvent } from '../../../../shared';
import { toLauncherConfig } from './config-data.mapper';
import { useLauncherConfigContext } from './context';
import {
  ADD_LAUNCHER_CONFIG,
  UPDATE_LAUNCHER_CONFIG,
} from './launcher-config.graphql';
import { useUpdateAssigneeConfirm } from './useUpdateAssigneeConfirm';
import { getBoardViewName } from './launcher-event.helpers';

export const useConfigSaveAction = () => {
  const history = useHistory();
  const { getConfig, completeSave } = useLauncherConfigContext((state) => ({
    getConfig: state.getConfig,
    completeSave: state.completeSave,
  }));

  const assigneeConfirm = useUpdateAssigneeConfirm();

  const [addLauncher] = useMutation(ADD_LAUNCHER_CONFIG, {
    onCompleted: async (data) => {
      completeSave();
      await new Promise((resolve) => setTimeout(resolve, 1000));
      // history.goBack();
      history.replace({
        pathname: AppRoute.LAUNCHER_BOARD_CONFIG,
        search: history.location.search,
        params: {
          boardId: data.AddLauncher?.eid,
        },
      });
    },
  });

  const [updateLauncher] = useMutation(UPDATE_LAUNCHER_CONFIG, {
    onCompleted: async (data) => {
      completeSave();
      await new Promise((resolve) => setTimeout(resolve, 1000));
      // history.goBack();
      console.log(data);
    },
  });

  return async () => {
    const config = getConfig();

    const inputs = toLauncherConfig(config);

    if (config.locations) {
      await new Promise((resolve, reject) => {
        assigneeConfirm({
          onChange: (value) => {
            inputs.updateLocations = value === 'both';
          },
          onConfirm: () => {
            if (isNullOrUndefined(inputs.updateLocations)) {
              inputs.updateLocations = false;
            }
            resolve(true);
          },
          onCancel: () => reject('Cancelled'),
        });
      });
    }

    console.log(inputs.contents);

    if (config.eid) {
      // @ts-ignore
      inputs.eid = config.eid;
      await updateLauncher({
        variables: {
          input: inputs,
        },
      });

      const taskCount = toArray(inputs?.contents).reduce((acc, val) => {
        return acc + (val?.tasks?.length || 0);
      }, 0);

      deployEvent(AmplitudeEvent.LAUNCHER_SAVE_AND_PUBLISH_CHANGES, {
        view: getBoardViewName(),
        total_tasks: taskCount,
        total_phases: config.contents?.length,
        board_name: config.title,
        board_id: config.eid,
        board_creation_funnel_id: 5,
        // board_creation_method: ''
      });
    } else {
      await addLauncher({
        variables: {
          input: inputs,
        },
      });
    }
  };
};
