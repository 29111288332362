import React, { FC } from 'react';
import { Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { callAll } from '@chakra-ui/utils';
import { Controller, useFormContext } from 'react-hook-form';

import TitleHeader from '../../../../CardEditor/TitleHeader';
import { IFormInput } from '../../audit-schedule.types';
import { OptionButton } from '../shared';
import AuditDueDate from './AuditDueDate';
import { useTranslation } from 'react-i18next';
import DatePickerCustom from 'ui-components/DatePicker/DatePicker';

const SingleTime: FC = () => {
  const { control, setValue, getValues } = useFormContext<IFormInput>();
  const { t } = useTranslation(['task']);

  const resetField = (value: any) => {
    setValue('repeatDetails.auditDueDate', undefined);
  };

  return (
    <Controller<IFormInput, 'repeatDetails.auditDue'>
      name='repeatDetails.auditDue'
      rules={{
        required: 'Please select when the audit is due',
      }}
      render={({ field, fieldState }) => (
        <FormControl isInvalid={fieldState.invalid}>
          <TitleHeader title='When is the audit due?' />
          <Flex w='full' gap={4} mt={2} wrap='wrap'>
            <OptionButton
              // isReadOnly={isEdit}
              isDisabled={field.disabled}
              isSelected={field.value === 'nextWeek'}
              onClick={() => callAll(field.onChange, resetField)('nextWeek')}
            >
              Next week
            </OptionButton>

            <OptionButton
              // isReadOnly={isEdit}
              isDisabled={field.disabled}
              isSelected={field.value === 'endMonth'}
              onClick={() => callAll(field.onChange, resetField)('endMonth')}
            >
              End of month
            </OptionButton>

            <OptionButton
              // isReadOnly={isEdit}
              isDisabled={field.disabled}
              isSelected={field.value === 'endYear'}
              onClick={() => callAll(field.onChange, resetField)('endYear')}
            >
              End of the year
            </OptionButton>

            <Controller
              control={control}
              name='repeatDetails.auditDueDate'
              render={({ field }) => {
                return (
                  <DatePickerCustom
                    placeholder={t('task:customChooseCal')}
                    value={getValues('repeatDetails.auditDueDate')}
                    transKey='task:customDateValue'
                    minDate={new Date()}
                    onChange={(date) => {
                      setValue('repeatDetails.auditDue', date);
                      field.onChange(date);
                    }}
                  />
                );
              }}
            />

            <OptionButton
              // isReadOnly={isEdit}
              isDisabled={field.disabled}
              isSelected={field.value === 'anyTime'}
              onClick={() => callAll(field.onChange, resetField)('anyTime')}
            >
              No deadline
            </OptionButton>
          </Flex>
          <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
        </FormControl>
      )}
    />
  );
};

export default SingleTime;
