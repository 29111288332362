import React, { FC } from 'react';
import { Box, Center, Flex } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Title } from 'ui-components';
import { set, useFormContext } from 'react-hook-form';
import { IFormInput } from './add-location.types';
import { Button } from 'atoms';
import useMakeLiveModal from './useMakeLiveModal';
import useMoveToPrelaunch from './useMoveToPrelaunch';

interface LocationState {
  backToTitle?: string;
}

interface IProps {
  title?: string;
  setMarkedAsLive?: React.Dispatch<React.SetStateAction<boolean>>;
  markedAsLive?: boolean;
  isDataLoading?: boolean;
}

const AddLocationHeader: FC<IProps> = ({
  title,
  setMarkedAsLive,
  markedAsLive,
  isDataLoading,
}) => {
  const history = useHistory();
  const { t } = useTranslation('location');
  const locState = useLocation<LocationState>().state;
  const { getValues } = useFormContext<IFormInput>();

  const makeLiveModal = useMakeLiveModal();
  const moveToPrelaunch = useMoveToPrelaunch();

  const { locationId } = useParams<{ locationId: string }>();

  const locationStatus = getValues('locationStatus').value;

  const onPreLaunchModalClose = () => {
    history.push('/locations');
  };

  const onMarkedAsLiveModalClose = () => {
    setMarkedAsLive?.(true);
  };

  return (
    <Flex justifyContent='space-between' alignItems='center'>
      <Flex gap={3}>
        <Center height='48px' onClick={history.goBack} cursor='pointer'>
          <ArrowBackIcon color='#272B30' boxSize='26px' />
        </Center>
        <Box>
          <Title>{title || t('add_location')}</Title>
          <Box
            fontSize='15px'
            fontWeight='400'
            color='#6F767E'
            cursor='pointer'
            onClick={history.goBack}
          >
            {locState?.backToTitle ?? t('backToLocation')}
          </Box>
        </Box>
      </Flex>
      {locationStatus === 'development' && !markedAsLive && !isDataLoading && (
        <Flex gap={4}>
          <Button
            background='white'
            color='#1A1D1F'
            borderRadius='10px'
            onClick={() =>
              moveToPrelaunch({
                locationId: locationId,
                onPreLaunchModalClose: onPreLaunchModalClose,
                locationName: getValues('locationName'),
              })
            }
          >
            Move to pre-launch
          </Button>
          <Button
            colorScheme='blue'
            borderRadius='10px'
            onClick={() => {
              makeLiveModal({
                onMarkedAsLiveModalClose: onMarkedAsLiveModalClose,
                locationName: getValues('locationName'),
                locationStatus: locationStatus,
              });
            }}
          >
            Make live
          </Button>
        </Flex>
      )}

      {locationStatus === 'preLaunch' && !isDataLoading && (
        <Button
          colorScheme='blue'
          borderRadius='10px'
          onClick={() => {
            makeLiveModal({
              onMarkedAsLiveModalClose: onMarkedAsLiveModalClose,
              locationName: getValues('locationName'),
              locationStatus: locationStatus,
            });
          }}
        >
          Make live
        </Button>
      )}
    </Flex>
  );
};

AddLocationHeader.displayName = 'pages/Locations/AddLocation/AddLocationHeader';

export default AddLocationHeader;
