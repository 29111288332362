import { useQuery } from '@apollo/client';
import React, { FC } from 'react';
import { GET_TP_OVERALL_USERWISE_ANALYTICS } from '../../query/track-dashboard-graphql';
import Loader from 'sub-components/Loader';
import { Center } from '@chakra-ui/react';
import PathsByMemberIdTable from './PathsByMemberIdTable';
import { IOnViewActivityClick } from '../MemberAnalytics';

const PathByMemberIdContainer = ({
  userId,
  userName,
  onViewActivityClick,
}: {
  userId: string;
  userName: string;
  onViewActivityClick: (data: IOnViewActivityClick) => void;
}) => {
  const {
    data,
    loading,
    refetch: refetchUserWiseAnalytics,
  } = useQuery(GET_TP_OVERALL_USERWISE_ANALYTICS, {
    variables: {
      userId: userId,
      filter: {
        userId: [userId],
      },
    },
  });

  return (
    <div>
      {loading ? (
        <Center width='100%'>
          <Loader />
        </Center>
      ) : (
        <>
          <PathsByMemberIdTable
            paths={data?.TpOverallUserDetailsAnalytics?.sessions}
            onViewActivityClick={onViewActivityClick}
            userName={userName}
            refetchUserWiseAnalytics={refetchUserWiseAnalytics}
          />
        </>
      )}
    </div>
  );
};

PathByMemberIdContainer.displayName =
  'displayName:sub-components/training-v2/dashboard/components/TrackPathDashboard/components/PathByMemberIdContainer.tsx';

export default PathByMemberIdContainer;
