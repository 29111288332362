import React, { forwardRef, useMemo } from 'react';
import { useController, useWatch } from 'react-hook-form';
import { AuthRole } from '../../../../../../authorization';
import { toArray } from '../../../../../../utils';
import { usersEntityObj } from '../../../../../Header';
import { IAssigneeInput } from '../../training-input.types';
import {
  LocationSelectButton,
  LocSelectProps,
  TrainingLocationSelect,
} from './location-select';
import { TAssignByType } from '../../../../../../types';

const Admins = [AuthRole.SUPER_ADMIN, AuthRole.ADMIN];

export const isAdminOnly = (authRoles: AuthRole[]): boolean => {
  const _values = toArray(authRoles);

  if (_values.length === 0) return false;

  return _values.every((authRole) => Admins.includes(authRole));
};

interface HQProps {
  value?: string[];
  disabled?: boolean;
}

const HqView = forwardRef<never, HQProps>(({ disabled, value }, ref) => {
  const values = useMemo(() => {
    return usersEntityObj().filter((l) => value?.includes(l.eid));
  }, [value]);

  return (
    <LocationSelectButton
      ref={ref}
      values={values}
      disabled={disabled}
      rightIcon={undefined}
    />
  );
});

HqView.displayName = 'HqView';

interface IProps extends LocSelectProps {
  index: number;
  assignBy: TAssignByType;
}

const GroupLocationSelect = forwardRef<never, IProps>(
  ({ index, assignBy, ...props }, ref) => {
    const authRoles = useWatch<IAssigneeInput, `assignees.${number}.authRoles`>(
      { name: `assignees.${index}.authRoles` }
    );
    const { field } = useController<
      IAssigneeInput,
      `assignees.${number}.locationType`
    >({
      name: `assignees.${index}.locationType`,
      rules: { deps: [`assignees.${index}.locationIds`] },
    });

    if (assignBy === 'authRole' && isAdminOnly(authRoles)) {
      return <HqView ref={ref} disabled={props.disabled} value={props.value} />;
    }

    return (
      <TrainingLocationSelect
        ref={ref}
        {...props}
        locationType={field.value}
        onTypeChange={field.onChange}
      />
    );
  }
);

GroupLocationSelect.displayName = 'GroupLocationSelect';

export default GroupLocationSelect;
