import { QuestionType, QuizData, UserResponse } from '../types';
import { Box, Flex, Image, Stack } from '@chakra-ui/react';

export function generateQuizResponsesTable(
  questionId: string,
  contents: QuizData[],
  userResponses: UserResponse
) {
  const questions = contents.find((c) => c.eid === questionId)?.questions;

  const responses = userResponses.progress.find(
    (r) => r.id === questionId
  )?.responses;

  if (!questions || !responses) return null;

  const normalizeAnswer = (answer: string) => {
    return answer?.trim()?.toLowerCase()?.replace(/\s+/g, '');
  };

  return questions
    .map((question) => {
      const response = responses.find((r) => r.qid === question.eid);

      if (!response) return null;

      let responseData;
      let result: {
        eid: string;
        result: 'correct' | 'partially correct' | 'wrong';
      }[] = [];
      let points = response.score || 0;

      const maxQuestionScore = question.score || 0;

      switch (question.type as QuestionType) {
        case 'multiChoice':
          responseData = (
            <Stack spacing={4}>
              {response.options.map((opt: string, index: number) => {
                const option = question.options.find((o: any) => o.uid === opt);

                return (
                  <Box
                    key={index}
                    textAlign='center'
                    display='flex'
                    gap='8px'
                    alignItems='center'
                  >
                    {option?.thumbnail && (
                      <Image
                        src={option.thumbnail}
                        alt={`Thumbnail for ${option.text}`}
                        boxSize='35px'
                        objectFit='cover'
                        borderRadius='md'
                      />
                    )}
                    {option?.text}
                  </Box>
                );
              })}
            </Stack>
          );

          result = response.options.map((opt: string) => ({
            eid: opt,
            result: question?.options.some((o) => o.uid === opt && o.isCorrect)
              ? 'correct'
              : 'wrong',
          }));
          break;

        case 'trueFalse':
          const userSelectedTrue = response.isCorrect === question.isTrue;
          const isUserCorrect = userSelectedTrue === question.isTrue;

          responseData = <Box>{userSelectedTrue ? 'True' : 'False'}</Box>;

          result = [
            {
              eid: question.eid,
              result: isUserCorrect ? 'correct' : 'wrong',
            },
          ];
          break;

        case 'matchSet':
          responseData = (
            <Stack spacing={4}>
              {response.pairs.map((pair: any, index: number) => (
                <Box
                  key={index}
                  textAlign='center'
                  display='flex'
                  gap='8px'
                  alignItems='center'
                >
                  {pair.leftThumbnail && (
                    <Image
                      src={pair.leftThumbnail}
                      alt={`Thumbnail for ${pair.left}`}
                      boxSize='35px'
                      objectFit='cover'
                      borderRadius='md'
                    />
                  )}
                  {pair.left} - {pair.right}
                  {pair.rightThumbnail && (
                    <Image
                      src={pair.rightThumbnail}
                      alt={`Thumbnail for ${pair.right}`}
                      boxSize='35px'
                      objectFit='cover'
                      borderRadius='md'
                    />
                  )}
                </Box>
              ))}
            </Stack>
          );

          result = response.pairs.map((pair: any) => {
            const correctPair = question.pairs.find(
              (qPair: any) => qPair.uid === pair.uid
            );

            return {
              eid: pair.uid,
              result:
                correctPair &&
                pair.left === correctPair.left &&
                pair.right === correctPair.right
                  ? 'correct'
                  : 'wrong',
            };
          });
          break;

        case 'sequence':
          responseData = (
            <Flex gap={1} flexWrap='wrap'>
              {response.sequence.map((seq: any, index: number) => (
                <Flex
                  key={index}
                  textAlign='center'
                  alignItems='center'
                  gap={1}
                >
                  {seq.thumbnail && (
                    <Image
                      src={seq.thumbnail}
                      alt={`Thumbnail ${seq.text}`}
                      boxSize='35px'
                      objectFit='cover'
                      borderRadius='md'
                    />
                  )}
                  {seq.text}

                  {index !== question.sequence.length - 1 ? '>' : ''}
                </Flex>
              ))}
            </Flex>
          );

          let correctCount = 0;

          for (
            let i = 0;
            i < Math.min(response.sequence.length, response.sequence.length);
            i++
          ) {
            if (response.sequence[i].text === question.sequence[i].text) {
              correctCount++;
            }
          }

          const isFullyCorrect = correctCount === question.sequence.length;
          const isPartiallyCorrect = correctCount > 0 && !isFullyCorrect;

          result = [
            {
              eid: question.label,
              result: isFullyCorrect
                ? 'correct'
                : isPartiallyCorrect
                ? 'partially correct'
                : 'wrong',
            },
          ];
          break;

        case 'fillInBlanks':
          responseData = (
            <Box>{response.blanks?.map((blank: any) => blank)}</Box>
          );

          const normalizeAnswers = response.blanks.map((blank) =>
            normalizeAnswer(blank)
          );

          const isBlanksCorrect = question?.blanks?.some((question) => {
            return normalizeAnswers?.some(
              (ans) => normalizeAnswer(question.answer) === ans
            );
          });

          result = response.blanks?.map((blank: any) => ({
            eid: blank.uid,
            result: isBlanksCorrect ? 'correct' : 'wrong',
          }));
          break;
      }

      return {
        key: question.eid,
        question: question,
        questionType: question.type,
        response: responseData,
        result,
        points: `${
          points
            ? Number(points) % 1 === 0
              ? Number(points)
              : Number(points).toFixed(1)
            : 0
        } / ${maxQuestionScore}`,
        score: points,
        type: question.type,
      };
    })
    .filter(Boolean);
}

export const generateFormResponseTable = (
  questions: any[],
  responses: any[]
) => {
  return questions.map((question) => {
    const answer = responses.find((ans) => ans.qid === question.eid);

    return {
      question: question,
      response: answer,
    };
  });
};
