export const COLORS = [
  '#cbecda',
  '#ffe8bb',
  '#b1e5fc',
  '#ffe0b0',
  '#c5e8d2',
  '#e8e8ea',
  '#ffd6e5',
  '#c1e8e8',
  '#b6d9ed',
  '#ffe5c2',
  '#d7c9ff',
  '#ffd6dc',
  '#ead6ff',
  '#daf2dc',
  '#ffccd5',
  '#ffefd6',
  '#bae5c5',
  '#e9daf1',
  '#c7e6f5',
  '#d0ecd5',
  '#ffd9c8',
  '#ffe0d1',
  '#f2f2f2',
  '#c6e6e6',
  '#e0f0e3',
  '#f7cac9',
  '#a6d4f4',
  '#cff0f0',
  '#d6cdea',
  '#d5e7f7',
  '#ffecb3',
  '#ded1f0',
  '#ffd7a8',
  '#c8e6ff',
  '#f0ebe5',
  '#ffccb3',
  '#e2d5f8',
  '#ffdab9',
  '#c9f7c9',
  '#dbf0e8',
  '#ffc1cc',
  '#bfe6de',
  '#d3eeec',
  '#e5d9f1',
  '#f8e8c6',
  '#b8e0f6',
  '#ffddc9',
  '#ffd9d9',
  '#ffe6d1',
  '#e6e6fa',
];
