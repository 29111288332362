import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { callAll } from '@chakra-ui/utils';
import moment from 'moment';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import TitleHeader from '../../../CardEditor/TitleHeader';
import CheckButton from './CheckButton';
import { IFormInput } from '../task.types';
import { CustomDatePicker } from './CustomDatePicker';
import DueTimeSelect from './DueTimeSelect';
import { taskDueValidation } from '../validation/scheduleValidationRule';
import DatePickerCustom from 'ui-components/DatePicker/DatePicker';
import { FaCalendar } from 'react-icons/fa6';

interface IProps {}

const WhenTaskDue: FC<IProps> = () => {
  const { t } = useTranslation(['task']);
  const { control, setValue, getValues } = useFormContext<IFormInput>();

  const taskDue = useWatch<IFormInput, 'taskDue'>({
    name: 'taskDue',
  });

  const resetField = (value: IFormInput['taskDue']) => {
    switch (value) {
      case 'nextWeek':
        setValue('taskDueDate', undefined);
        break;
      case 'endOfMonth':
      case 'endOfYear':
        setValue('dueTime', undefined);
        setValue('dueTime', undefined);
        setValue('taskDueDate', undefined);
        break;
    }
  };

  return (
    <Box>
      <TitleHeader
        title={t('task:whenTaskDue')}
        desc={t('task:whenTaskDueDesc')}
      />

      <Controller
        control={control}
        name='taskDue'
        rules={{
          required: t('task:validation.taskDueRequired'),
          validate: (value) => taskDueValidation(value, getValues()),
        }}
        render={({ field, fieldState, formState }) => {
          const error = fieldState.error || formState.errors?.taskDueDate;
          return (
            <FormControl my={3} isInvalid={!!error}>
              <Flex gap={2}>
                <CheckButton
                  isSelected={field.value === 'nextWeek'}
                  onClick={() =>
                    callAll(field.onChange, resetField)('nextWeek')
                  }
                >
                  <Trans
                    t={t}
                    i18nKey='task:nextWeekDate'
                    values={{
                      date: moment()
                        .startOf('isoWeek')
                        .add(1, 'week')
                        .day('monday')
                        .format('DD MMM'),
                    }}
                  />
                </CheckButton>

                <CheckButton
                  isSelected={field.value === 'endOfMonth'}
                  onClick={() =>
                    callAll(field.onChange, resetField)('endOfMonth')
                  }
                >
                  <Trans t={t} i18nKey='task:endOfMonth' />
                </CheckButton>

                <CheckButton
                  isSelected={field.value === 'endOfYear'}
                  onClick={() =>
                    callAll(field.onChange, resetField)('endOfYear')
                  }
                >
                  <Trans t={t} i18nKey='task:endOfYear' />
                </CheckButton>

                <Controller
                  control={control}
                  name='taskDueDate'
                  render={({ field }) => {
                    return (
                      <DatePickerCustom
                        placeholder={t('task:customChooseCal')}
                        minDate={new Date()}
                        onChange={(date) => {
                          setValue('taskDue', 'date');
                          field.onChange(date);
                        }}
                        value={getValues('taskDueDate')}
                        transKey='task:customDateValue'
                      />
                    );
                  }}
                />
              </Flex>

              <FormErrorMessage>{error?.message}</FormErrorMessage>
            </FormControl>
          );
        }}
      />

      <DueTimeSelect
        deps={['taskDueDate']}
        isHidden={!['date', 'nextWeek'].includes(taskDue)}
      />
    </Box>
  );
};

export default WhenTaskDue;
