import React, { FC } from 'react';
import {
  FormControl,
  FormErrorMessage,
  IconButton,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { useController } from 'react-hook-form';
import { Input } from '../../../../../../../../atoms';
import { QuizQFormInput } from '../quiz-form.types';

import { ReactComponent as DeleteIcon } from 'assets/images/deleteBin.svg';

interface IProps {
  optionIndex: number;
  totalLength: number;
  onRemoveClick?: () => void;
}

const FillBlankOption: FC<IProps> = ({
  optionIndex,
  totalLength,
  onRemoveClick,
}) => {
  const { field, fieldState } = useController<
    QuizQFormInput,
    `blanks.${number}.answer`
  >({
    name: `blanks.${optionIndex}.answer`,
    rules: {
      required: {
        value: totalLength < 2,
        message: 'Answer variation is required',
      },
      validate: (value) => {
        if (value && !value?.trim()) {
          return 'Enter valid answer variation';
        }
      },
    },
  });

  return (
    <FormControl
      flex={1}
      width='268px'
      minWidth='174px'
      isInvalid={fieldState.invalid}
    >
      <InputGroup _hover={{ button: { visibility: 'visible' } }}>
        <Input
          variant='auditOutline'
          placeholder='Type answer here'
          textAlign='center'
          fontSize='12px'
          {...field}
        />
        {totalLength === 1 ? null : (
          <InputRightElement width={12}>
            <IconButton
              aria-label='remove'
              variant='ghost'
              size='xs'
              visibility='hidden'
              icon={<DeleteIcon width={16} height={16} />}
              onClick={onRemoveClick}
            />
          </InputRightElement>
        )}
      </InputGroup>
      <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
    </FormControl>
  );
};

export default FillBlankOption;
