import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Center, Image } from '@chakra-ui/react';
import Loader from '../../../../../sub-components/Loader';

import SearchEmpty from '../../../../../assets/images/Illustrations.svg';
import emptyImage from '../../../../../assets/images/placeholder/empty-assignees.svg';

interface IProps {
  isLoading?: boolean;
  searchQuery?: string;
  dataLength?: number;
}

const DataNotFound: FC<IProps> = ({
  isLoading,
  searchQuery,
  dataLength = 0,
  children,
}) => {
  const { t } = useTranslation(['setting', 'training']);

  if (!isLoading && !!dataLength) {
    return <>{children}</>;
  }

  if (isLoading) {
    return (
      <Center boxSize='full' borderRadius='10px' minH='40vh'>
        <Loader size='lg' />
      </Center>
    );
  }

  return (
    <Center flexDir='column' flex={1} gap='8px' minH='40vh' mt={-16}>
      <Image
        userSelect='none'
        src={searchQuery ? SearchEmpty : emptyImage}
        boxSize={searchQuery ? '180px' : '220px'}
      />

      <Box fontSize='16px' fontWeight='700' color='#1A1D1F'>
        {t('setting:noMemberFound')}
      </Box>

      <Box
        maxW='250px'
        fontSize='14px'
        fontWeight='500'
        color='#6F767E'
        textAlign='center'
      >
        {t(
          searchQuery
            ? 'training:noTrainingsFoundDesc'
            : 'setting:noMemberFoundDesc'
        )}
      </Box>
    </Center>
  );
};

export default DataNotFound;
