import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { toArray } from '../../../../../../utils';
import VideoResourceContainer from './VideoResourceContainer';
import VideoFooter from './VideoFooter';
import { VideoFormInput } from './video-resource.graphql';
import VideoPreview from './VideoPreview';

export interface SResourceProps {
  onAddClick?: (values: unknown[]) => void;
}

const VideoResourceContent: FC<SResourceProps> = ({ onAddClick }) => {
  const methods = useForm<VideoFormInput>({
    defaultValues: {
      title: '',
    },
  });

  const _onAddClick = (values: unknown) => {
    return onAddClick?.(toArray(values));
  };

  return (
    <FormProvider {...methods}>
      <Flex flex={1} flexDir='column' width='100%'>
        <Flex
          flex={1}
          gap='40px'
          marginX={7}
          // align='start'
          maxHeight='calc(100% - 61px)'
        >
          <VideoResourceContainer />

          <VideoPreview />
        </Flex>

        <VideoFooter onAddClick={_onAddClick} />
      </Flex>
    </FormProvider>
  );
};

export default VideoResourceContent;
