import React, { FC } from 'react';
import { Box, Flex, Image, PopoverBody } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { TrainingContType } from '../../../../../../types';
import { useContentMenuOptions } from './useContentMenuOptions';

interface CCProps {
  title: string;
  description: string;
  icon: string;
  onClick?: () => void;
}

const ContentCard: FC<CCProps> = ({ title, description, icon, onClick }) => {
  return (
    <Flex
      borderBottom='1px solid #EFEFEF'
      borderRadius='12px'
      p='10px 16px'
      cursor='pointer'
      onClick={onClick}
      align='center'
      gap={5}
      _hover={{
        bgColor: '#FCFCFC',
      }}
    >
      <Image boxSize='60px' src={icon} />
      <Flex flex={1} flexDir='column'>
        <Box fontWeight={600} fontSize='14px'>
          {title}
        </Box>
        <Box color='#6F767E' fontWeight={500} fontSize='12px'>
          {description}
        </Box>
      </Flex>
      <FontAwesomeIcon
        icon={faArrowRight as IconProp}
        size='lg'
        color='#6F767E'
      />
    </Flex>
  );
};

interface IProps {
  onClick?: (action: TrainingContType) => void;
}

const AddContentBody: FC<IProps> = ({ onClick }) => {
  const contentCards = useContentMenuOptions(onClick);

  return (
    <PopoverBody paddingInline='24px' paddingBlock='24px'>
      <Flex flexDir='column' gap='1px'>
        <Box fontWeight={600} fontSize='15px' pb={4}>
          Choose one of the following to start adding content to the path
        </Box>

        {contentCards.map((card, index) => (
          <ContentCard
            key={index}
            title={card.title}
            description={card.description}
            icon={card.icon}
            onClick={card.onClick}
          />
        ))}
      </Flex>
    </PopoverBody>
  );
};

export default AddContentBody;
