export const MIN_DATE = '2016-08-19T13:43:16.772Z';

export const FILL_THE_BLANK_REGEX = /_{3,}/;

export const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const EMAIL_REGEX_V2 =
  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const PHONE_NUMBER_REGEX =
  /^\+?([0-9]{1,3})?[-. ]?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

export const YOUTUBE_REGEX =
  /^(http(s)??\:\/\/)?(www\.)?((youtube\.com\/watch\?v=)|(youtu.be\/))([a-zA-Z0-9\-_])+/; // eslint-disable-line

export const VIMEO_REGEX =
  /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)([a-zA-Z0-9_-]+)(?:|\/\?)/;

const SOP_UPLOAD_REGX = /https:\/\/sop-uploads-(prod).s3.amazonaws.com/g;

const IMGIX_REGX = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.imgix.net\/)+/g;

const SOP_UPLOAD_URL = process.env.REACT_APP_IMAGE_URL!;

// const IMGIX_URL = process.env.REACT_APP_IMGIX_URL!;

export { SOP_UPLOAD_REGX, IMGIX_REGX, SOP_UPLOAD_URL };

export const DEFAULT_CHUNK_SIZE = 1048576 * 6;

export const FILENAME_REGEX = /(\/|_[0-9]{3}_)/;

export const EXT_RENAME_REGEX = /[.]\w+$/;

export const CALENDLY_LINK = process.env.REACT_APP_CALENDLY_LINK!;

export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY!;

export const AES_DECRYPTION_KEY = process.env.REACT_APP_AES_DECRYPTION_KEY!;

export const IMAGEKIT_URL_ENDPOINT = process.env.REACT_APP_IMAGEKIT_URL!;

export const AI_SEARCH_ENDPOINT = process.env.REACT_APP_AI_SEARCH_URL!;

type DevelopmentType = 'development' | 'production';

export const DEVELOPMENT_TYPE = process.env
  .REACT_APP_DEPLOYMENT_TYPE! as DevelopmentType;

export const IS_PRODUCTION = DEVELOPMENT_TYPE === 'production';

export const localizationEnabled =
  process.env.REACT_APP_LOCALIZATION! === 'true';
