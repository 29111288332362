import React, { FC } from 'react';
import { TabPanel, TabPanels } from '@chakra-ui/react';
import FormResponseTabPanel from './FormResponseTabPanel';
import AllResponseTabPanel from './AllResponseTabPanel';

interface IProps {
  activeTabIndex: number;
  searchValue: string;
  categoryFilter: string[];
}

const FormTabPanelsRoot: FC<IProps> = ({
  activeTabIndex,
  searchValue,
  categoryFilter,
}) => {
  return (
    <TabPanels>
      <TabPanel p={0}>
        <AllResponseTabPanel
          activeTabIndex={activeTabIndex}
          searchValue={searchValue}
          categoryFilter={categoryFilter}
        />
      </TabPanel>
      <TabPanel p={0}>
        <FormResponseTabPanel
          activeTabIndex={activeTabIndex}
          searchValue={searchValue}
          categoryFilter={categoryFilter}
        />
      </TabPanel>
    </TabPanels>
  );
};

FormTabPanelsRoot.displayName =
  'displayName:pages/forms/modules/FormListInternal/FormTabPanelsRoot';

export default FormTabPanelsRoot;
