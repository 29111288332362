import React, { FC, useMemo, useState } from 'react';
import { Checkbox, Flex, Text } from '@chakra-ui/react';
import { HeaderColors } from 'configs';
import { BoxHeader } from 'ui-components';
import SearchInput from 'atoms/SearchInput';
import { ReactComponent as BackButton } from 'assets/images/back.svg';
import { PageNameType } from './MainContainer';
import { ClearFilterType } from './FilterContainer';
import EmptyState from './EmptyState';
import {
  FiltersType,
  useTrainingContext,
} from '../../../TrainingTableContainer';
import { ITypes } from '../Header';
import { TAnalyticsFilterClearFilter } from '../../components/DetailsPageFilters/AnalyticsBaseFilter';
import { DetailsPageNameType } from '../../components/DetailsPageFilters/DetailsMainContainer';
import {
  DetailsFiltersType,
  IDetailsFilterTypes,
} from '../../components/DetailsPageFilters/AnalyticsContainerHeader';

interface IProps {
  setPageName: React.Dispatch<React.SetStateAction<DetailsPageNameType>>;
  handleStatusSelection: (
    e: React.ChangeEvent<HTMLInputElement>,
    status: IDetailsFilterTypes
  ) => void;
  clearFilters: (type?: TAnalyticsFilterClearFilter) => void;
  detailsFilters: DetailsFiltersType;
  setDetailsFilters: React.Dispatch<React.SetStateAction<DetailsFiltersType>>;
}

const DetailsStatusFilter: FC<IProps> = ({
  setPageName,
  handleStatusSelection,
  clearFilters,
  detailsFilters,
}) => {
  return (
    <>
      <Flex alignItems={'center'} mt={'30px'} justifyContent={'space-between'}>
        <BoxHeader title='Filters' color={HeaderColors.Purple} />
        <Text
          cursor={'pointer'}
          onClick={() => clearFilters('detailsStatuses')}
          fontSize={'14px'}
          fontWeight={400}
          color={
            detailsFilters?.selectedStatusesId?.length > 0
              ? '#2A85FF'
              : '#6F767E'
          }
        >
          Clear Selection
        </Text>
      </Flex>

      <Flex mt={'20px'} gap={3} alignItems={'center'} marginBottom={'1rem'}>
        <BackButton
          width={'1.2rem'}
          onClick={() => setPageName('base')}
          cursor={'pointer'}
        />
        <Text
          style={{
            fontWeight: 600,
            fontSize: 14,
          }}
        >
          Status
        </Text>
      </Flex>

      <Flex maxHeight={'300px'} flexDir={'column'} overflowY={'auto'}>
        {detailsFilters?.memberStatuses.map((status) => {
          return (
            <Flex
              key={status?.eid}
              gap={4}
              alignItems={'center'}
              padding={'0.5rem 0px'}
            >
              <Checkbox
                value={status?.eid}
                onChange={(e) => handleStatusSelection?.(e, status)}
                isChecked={
                  detailsFilters?.selectedStatusesId?.includes(status?.eid)
                    ? true
                    : false
                }
              >
                <Flex alignItems={'center'} gap={4}>
                  <Flex justifyContent={'center'} flexDir={'column'}>
                    <Text fontSize={'14px'}>{status.name}</Text>
                  </Flex>
                </Flex>
              </Checkbox>
            </Flex>
          );
        })}
      </Flex>
    </>
  );
};

export default DetailsStatusFilter;
