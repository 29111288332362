import { SystemStyleObject } from '@chakra-ui/react';

export const getActionScrollStyles = (): SystemStyleObject => {
  return {
    '&::-webkit-scrollbar': {
      width: '5px',
      height: '0px',
    },
    '&::-webkit-scrollbar-track': {
      // background: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#EFEFEF',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  };
};
