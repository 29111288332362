import React, { FC, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AuthRole } from '../../../../authorization';
import { useUserData, useUserEntity } from '../../../../hooks';
import { toArray } from '../../../../utils/utils';

import {
  Container,
  OpenStepHeader,
  SaveNextButton,
  SelectOption,
  useStepperIsOpen,
} from '../common';
import { IFormInput } from '../task.types';
import { useMembersData } from '../Supervisors';

import AssigneeStepper from './AssigneeStepper';
import AssigneeForm from './AssigneeForm';
import { LOCATION_USER_QUERY, LocationUserResponse } from './assignee.graphql';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';
import { shallowEqual } from 'utils';

interface IProps {}

const TaskAssignees: FC<IProps> = () => {
  const { t } = useTranslation(['task']);
  const isOpen = useStepperIsOpen('assignees');
  const { handleSubmit, getValues, setValue } = useFormContext<IFormInput>();
  const authUser = useUserData();
  const locations = useUserEntity((entity) => entity.locations, shallowEqual);

  const [members, setMembers] = useState<SelectOption[]>([]);

  const { getUsersData, loading } = useMembersData(setMembers);

  const [getLocationUser, { loading: memberLoading }] =
    useLazyQuery<LocationUserResponse>(LOCATION_USER_QUERY, {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const locationIds = toArray(authUser?.locations).map((it) => it.eid);
        const _members = toArray(data?.fetchEntityLocations).reduce(
          (acc, location) => {
            if (locationIds.includes(location.eid)) {
              location.members.forEach((user) => {
                if (
                  user.eid !== authUser?.eid &&
                  ![AuthRole.SUPER_ADMIN, AuthRole.ADMIN].includes(
                    user.authRole
                  ) &&
                  ['active', 'pending'].includes(user?.status)
                ) {
                  acc[user.eid] = {
                    label: user.name,
                    value: user.eid,
                    authRole: user.authRole,
                    url: user?.profilePic,
                    role: user?.role,
                    locations: [{ eid: location?.eid, name: location?.name }],
                  };
                }
              });
            }

            return acc;
          },
          {} as Record<string, SelectOption>
        );
        setMembers(Object.values(_members));
      },
    });

  useEffect(() => {
    const authRole = authUser?.authRole!;

    if (authRole === AuthRole.LOCATION_OWNER) {
      getLocationUser({
        variables: {
          eid: authUser?.entityId,
        },
      });
    } else {
      const AdminPrev = [
        AuthRole.ADMIN,
        AuthRole.WORKER,
        AuthRole.LOCATION_OWNER,
      ];

      getUsersData({
        variables: {
          entityId: authUser?.entityId,
          status: ['active'],
          type: ['user'],
          authRole: authRole === AuthRole.ADMIN ? AdminPrev : undefined,
        },
      });
    }
  }, [authUser?.entityId, authUser?.authRole]);

  const captureEvent = () => {
    let assignedToLocations = getValues('assignedToLocation') || [];
    let assignedToRoles = getValues('assignedToRole') || [];
    let assignedToUsers = getValues('assignedToUsers') || [];
    let totalAssignees =
      [...assignedToLocations, ...assignedToRoles, ...assignedToUsers]
        ?.length || 0;

    let memberNames =
      members
        ?.filter((mem) =>
          assignedToUsers?.some((user) => mem?.value?.includes(user))
        )
        ?.map((user) => user?.label) || [];
    let locationNames =
      locations
        ?.filter((loc) =>
          assignedToLocations?.some((location) => loc?.eid?.includes(location))
        )
        ?.map((loc) => loc?.name) || [];

    deployEvent(AmplitudeEventNames.ASSIGNEES_SAVE, {
      task_id: '',
      task_edit_funnel_id: 5,
      create_task_funnel_id: 4,
      total_assignees: totalAssignees,
      jobs: assignedToRoles || [],
      members: memberNames,
      location_assignee_type: getValues('assignedLocationsType'),
      location: locationNames,
    });
  };

  const saveAndNext = () => {
    setValue('completed.assignees', true);
    setValue('filled.assignees', true);
    setValue('currentStep', 'supervisors');
    captureEvent();
  };

  if (!isOpen) {
    return <AssigneeStepper members={members} />;
  }

  return (
    <Container flexDir='column' p='20px'>
      <OpenStepHeader title={t('task:assignees')} />

      <AssigneeForm members={members} loading={loading || memberLoading} />

      <SaveNextButton onClick={handleSubmit(saveAndNext)} />
    </Container>
  );
};

export default TaskAssignees;
