import { MemberTableType } from '../tracking-types';

export interface SortOptionsItem {
  eid: number;
  label: string;
  value: 'asc' | 'desc' | '';
}
export const pathSortOptions = [
  { eid: 1, label: 'No sort', value: 'no-sort' },
  { eid: 2, label: 'A-Z', value: 'a-z' },
  { eid: 3, label: 'Z-A', value: 'z-a' },
];
export const membersAvgRateOptions: SortOptionsItem[] = [
  { eid: 1, label: 'No sort', value: '' },
  { eid: 2, label: 'Lowest First', value: 'asc' },
  { eid: 3, label: 'Highest First', value: 'desc' },
];

export const dateSortOptions = [
  { label: 'No sort', value: 'no-sort' },
  { label: 'Earliest first', value: 'earliest' },
  { label: 'Latest first', value: 'latest' },
];

export const numberSortOptions = [
  { label: 'No sort', value: 'no-sort' },
  { label: 'Lowest first', value: 'lowest' },
  { label: 'Highest first', value: 'highest' },
];

export const membersData: MemberTableType[] = [
  {
    key: '1',
    name: 'Lindsey Stroud',
    avatar: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330',
    location: 'Chennai',
    locationBg: '#DCFCE7',
    role: 'Chef',
    subRole: 'Admin',
    completed: 4,
    total: 28,
    overdue: 2,
    avgCompletion: 60,
    avgScore: 60,
  },
  {
    key: '2',
    name: 'Satya Pichai',
    avatar: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d',
    location: 'Bangalore',
    locationBg: '#E0E7FF',
    role: 'Baker',
    subRole: 'Worker',
    completed: 34,
    total: 34,
    overdue: null,
    avgCompletion: 60,
    avgScore: 60,
  },
  {
    key: '3',
    name: 'Maria Cruz',
    avatar: 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80',
    location: 'Delhi',
    locationBg: '#DCFCE7',
    role: 'Manager',
    subRole: 'Admin',
    completed: 3,
    total: 6,
    overdue: 3,
    avgCompletion: 60,
    avgScore: 60,
  },
  {
    key: '4',
    name: 'Lindsey Stroud',
    avatar: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330',
    location: 'Kochi',
    locationBg: '#DCFCE7',
    role: 'Chef',
    subRole: 'Worker',
    completed: 25,
    total: 29,
    overdue: null,
    avgCompletion: 60,
    avgScore: 60,
  },
  {
    key: '5',
    name: 'Lindsey Stroud',
    avatar: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330',
    location: 'Chennai',
    locationBg: '#DCFCE7',
    role: 'Chef',
    subRole: 'Worker',
    completed: 16,
    total: 29,
    overdue: null,
    avgCompletion: 60,
    avgScore: 60,
  },
];
