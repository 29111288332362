import React, { FC } from 'react';
import {
  Input,
  InputGroup,
  InputRightElement,
  useControllableState,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ActionButton } from '../../../../../../../../../ui-components';

interface IProps {
  id?: string;
  isDisabled?: boolean;
  onLinkAdd?: (value: string) => void;
  placeholder?: string;
}

const AssetLinkPaste: FC<IProps> = ({
  id,
  isDisabled,
  onLinkAdd,
  placeholder,
}) => {
  const [internalValue, updateValue] = useControllableState<string>({
    // value: value,
    // onChange: onChange,
  });

  return (
    <InputGroup>
      <Input
        id={id || 'link-input'}
        placeholder={placeholder}
        borderBottom='none'
        borderLeft='none'
        borderRight='none'
        borderTopRadius='0'
        borderBottomRadius='11px'
        bgColor='#F9F9F9'
        height='50px'
        paddingInlineStart={6}
        paddingInline='24px 58px'
        isDisabled={isDisabled}
        textOverflow='ellipsis'
        onKeyDown={(event) => {
          if (event.code === 'Enter' && internalValue?.length) {
            // @ts-ignore
            event.target?.blur();
            onLinkAdd?.(internalValue);
          }
        }}
        onChange={(event) => updateValue(event.target.value)}
        // onBlur={onInputBlur} // TODO validate on blur
        _focus={{}}
        _placeholder={{
          fontSize: '15px',
          fontWeight: '500',
          color: '#6F767E',
        }}
      />

      <InputRightElement width='57px' height='50px'>
        <ActionButton
          padding={0}
          variant='link'
          colorScheme='blue'
          size='sm'
          isDisabled={!internalValue?.length}
          actionFn={() => onLinkAdd?.(internalValue)}
        >
          <FontAwesomeIcon icon={faArrowRight as IconProp} fontSize='20px' />
        </ActionButton>
      </InputRightElement>
    </InputGroup>
  );
};

export default AssetLinkPaste;
