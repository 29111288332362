import { useReactiveVar } from '@apollo/client';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Box, Flex, HStack, VStack } from '@chakra-ui/react';
import { AUDIT_LIST, TASK_DASHBOARD } from 'appRoutes';
import { AuthRole } from 'authorization';
import { useUserDataSelector, useUserEntity } from 'hooks';
import moment from 'moment';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { usersEntityObj } from 'sub-components/Header';
import { useNexusBoardContext } from 'sub-components/nexus/NexusBase/store/context';
import { BoxHeader } from 'ui-components';
import { AllClear } from '../../../common';
import {
  calculateAttritionScore,
  calculateAuditScore,
  calculateTaskScore,
} from '../../../LeftSection/components/LocationLeaderboard/components/OverallScore';

type FocusArea = {
  type: 'Customer rating' | 'Audit score' | 'Task completion' | 'Overall score';
  locationName: string;
  value: string;
  threshold: number;
  onClick?: () => void;
};

const FOCUS_LIMITS = {
  customerRating: 3,
  auditScore: 65,
  taskCompletion: 65,
  overallScore: 50,
};

const FocusArea: FC = () => {
  const history = useHistory();
  // const businessLocations = useReactiveVar(usersEntityObj);
  const [focusAreas, setFocusAreas] = useState<FocusArea[]>([]);

  const { loggedInUserAuthRole, loggedInUserLocations } = useUserDataSelector(
    (state) => ({
      loggedInUserLocations: state?.locations,
      loggedInUserAuthRole: state?.authRole,
    })
  );

  const { auditEnabled, businessLocations } = useUserEntity((entity) => ({
    auditEnabled: entity?.features?.audit,
    businessLocations: entity?.locations,
  }));

  const { auditData, attritionData, locationRatingData, taskData } =
    useNexusBoardContext((state) => ({
      auditData: state.auditData,
      attritionData: state?.attritionData,
      locationRatingData: state.locationRatingData,
      taskData: state.taskData,
    }));

  const locationsToDisplay = useMemo(() => {
    if ([AuthRole.SUPER_ADMIN, AuthRole.ADMIN].includes(loggedInUserAuthRole)) {
      let nonHQLocations = businessLocations?.filter(
        (loc) => !loc?.isRoot && loc?.locationStatus !== 'preLaunch'
      );
      return (
        nonHQLocations?.map((loc) => ({ eid: loc?.eid, name: loc?.name })) || []
      );
    } else if (loggedInUserAuthRole === AuthRole.LOCATION_OWNER) {
      return (
        loggedInUserLocations
          ?.filter((loc) => loc?.locationStatus !== 'preLaunch')
          ?.map((loc) => ({
            eid: loc?.eid,
            name: loc?.name,
          })) || []
      );
    }
    return [];
  }, [loggedInUserAuthRole, businessLocations, loggedInUserLocations]);

  const getLowestAuditScore = () => {
    let lowestAuditScore = Infinity;
    let lowestAuditLocation = '';

    const currentMonth = moment().format('M');
    locationsToDisplay.forEach((location) => {
      const auditLocationData = auditData?.[location.eid];
      if (auditLocationData) {
        const auditMonthData = auditLocationData[currentMonth];
        if (auditMonthData) {
          if (auditMonthData.maxScoreSum === 0) {
            return;
          }
          const auditScore =
            (auditMonthData.totalScoreSum / auditMonthData.maxScoreSum) * 100;
          if (auditScore < lowestAuditScore) {
            lowestAuditScore = auditScore;
            lowestAuditLocation = location.name;
          }
        }
      }
    });

    return {
      score: lowestAuditScore,
      location: lowestAuditLocation || 'N/A',
    };
  };

  const getLowestTaskCompletionRate = () => {
    let lowestCompletionRate = Infinity;
    let lowestCompletionLocation = '';

    const currentMonth = moment().format('M');
    locationsToDisplay.forEach((location) => {
      const taskLocationData = taskData?.[location.eid];
      if (taskLocationData) {
        const taskMonthData = taskLocationData[currentMonth];
        if (taskMonthData) {
          const totalTasks = taskMonthData.complete + taskMonthData.inComplete;
          if (totalTasks === 0) return;
          const completionRate = (taskMonthData.complete / totalTasks) * 100;
          if (completionRate < lowestCompletionRate) {
            lowestCompletionRate = completionRate;
            lowestCompletionLocation = location.name;
          }
        }
      }
    });

    return {
      rate: lowestCompletionRate,
      location: lowestCompletionLocation || 'N/A',
    };
  };

  const getLowestCustomerRating = () => {
    if (locationRatingData && locationRatingData.length > 0) {
      const lowestRatingLocation = locationRatingData.reduce((prev, curr) => {
        return curr.google.googleRating < prev.google.googleRating
          ? curr
          : prev;
      }, locationRatingData[0]);

      return {
        rating: lowestRatingLocation.google.googleRating,
        location: lowestRatingLocation.locationId,
      };
    }
    return { rating: Infinity, location: 'N/A' };
  };

  const getLowestOverallScoreRate = () => {
    const getCurrentMonth = (): string => {
      const date = new Date();
      return (date.getMonth() + 1).toString();
    };
    const currentMonth = getCurrentMonth();
    const result: {
      locationId: string;
      locationName: string;
      overallScore: number;
    }[] = [];
    locationsToDisplay?.forEach((location, index) => {
      const locationId = location.eid;
      const locationName = location.name;

      // Retrieve audit data for the current month
      const locationAuditData = auditData
        ? auditData[locationId]?.[currentMonth]
        : undefined;
      const auditScore = calculateAuditScore(locationAuditData);

      // Retrieve task data for the current month
      const locationTaskData = taskData
        ? taskData[locationId]?.[currentMonth]
        : undefined;
      const taskScore = calculateTaskScore(locationTaskData);

      // Retrieve attrition data for the current month
      const attritionScore = calculateAttritionScore(attritionData, locationId);

      // Determine if each module is used
      const isTaskUsed =
        (locationTaskData?.complete || 0) +
          (locationTaskData?.inComplete || 0) >
        0;
      const isAuditUsed =
        (locationAuditData?.maxScoreSum || 0) > 0 && auditEnabled;
      // Calculate overall score based on used modules
      const scores = [];
      if (isTaskUsed) scores.push(taskScore);
      if (isAuditUsed) scores.push(auditScore);
      scores.push(attritionScore);

      // Check if any component score is NaN
      const overallScore =
        scores.length > 0
          ? parseFloat(
              (
                scores.reduce((sum, score) => sum + score, 0) / scores.length
              ).toFixed(2)
            )
          : 0;

      // If overallScore is NaN for some reason, assign 0
      const finalOverallScore = isNaN(overallScore) ? 0 : overallScore;
      result?.push({
        locationId: locationId,
        locationName: locationName,
        overallScore: finalOverallScore,
      });
    });
    let descSortedResult = result?.sort(
      (a, b) => b?.overallScore - a?.overallScore
    );
    return descSortedResult?.slice(-1)?.[0];
  };

  useEffect(() => {
    const newFocusAreas: FocusArea[] = [];

    // Get the lowest customer rating
    const { rating, location: ratingLocation } = getLowestCustomerRating();
    if (rating < FOCUS_LIMITS.customerRating) {
      newFocusAreas.push({
        type: 'Customer rating',
        locationName:
          businessLocations?.find((bsLoc) => bsLoc?.eid === ratingLocation)
            ?.name || '-',
        value: rating?.toFixed(2),
        threshold: FOCUS_LIMITS.customerRating,
      });
    }

    // Get the lowest audit score
    if (auditEnabled) {
      const { score: auditScore, location: auditLocation } =
        getLowestAuditScore();
      if (auditScore < FOCUS_LIMITS.auditScore) {
        newFocusAreas.push({
          type: 'Audit score',
          locationName: auditLocation,
          value: auditScore?.toFixed(2),
          threshold: FOCUS_LIMITS.auditScore,
          onClick: () => history?.push(AUDIT_LIST),
        });
      }
    }

    // Get the lowest task completion rate
    const { rate: completionRate, location: completionLocation } =
      getLowestTaskCompletionRate();
    if (completionRate < FOCUS_LIMITS.taskCompletion) {
      newFocusAreas.push({
        type: 'Task completion',
        locationName: completionLocation,
        value: completionRate?.toFixed(2),
        threshold: FOCUS_LIMITS.taskCompletion,
        onClick: () => history?.push(TASK_DASHBOARD),
      });
    }

    // Get the lowest audit score
    const lowestOverallScoredLocation = getLowestOverallScoreRate();
    if (
      lowestOverallScoredLocation &&
      lowestOverallScoredLocation?.overallScore < FOCUS_LIMITS?.overallScore
    ) {
      newFocusAreas?.push({
        type: 'Overall score',
        locationName: lowestOverallScoredLocation?.locationName,
        value: lowestOverallScoredLocation?.overallScore?.toFixed(2),
        threshold: FOCUS_LIMITS?.overallScore,
      });
    }

    setFocusAreas(newFocusAreas);
  }, [
    auditEnabled,
    locationsToDisplay,
    locationRatingData,
    auditData,
    taskData,
  ]);

  const numberOfFocusAreas = focusAreas.length;

  const chunkItems = (focusAreas: FocusArea[], size: number): FocusArea[][] => {
    const chunks: FocusArea[][] = [];
    for (let i = 0; i < focusAreas.length; i += size) {
      chunks.push(focusAreas.slice(i, i + size));
    }
    return chunks;
  };

  const itemChunks = chunkItems(focusAreas, 2);

  const focusAreaItem = (area: FocusArea) => (
    <Flex
      bg='rgba(252, 252, 252, 1)'
      borderRadius='8px'
      border='1px solid rgba(239, 239, 239, 1)'
      p='12px'
      h='full'
      cursor='pointer'
      onClick={area.onClick}
    >
      <Flex flexDir='column' gap={2} w='full' justify='space-between'>
        <HStack align='baseline' justify='space-between' w='full'>
          <Box fontWeight={600}>{area.type}</Box>
          <ArrowForwardIcon />
        </HStack>
        <Box fontSize='12px' fontWeight={500} color='rgba(111, 118, 126, 1)'>
          {area.locationName}
        </Box>
        <Box color='red.500' fontWeight={600} fontSize='16px'>
          {area.value}
          {area.type === 'Task completion' && '%'}
        </Box>
      </Flex>
    </Flex>
  );

  return (
    <VStack gap={4} align='flex-start' w='full'>
      <BoxHeader title='Focus areas' color='rgba(177, 229, 252, 1)' />
      {focusAreas?.length === 0 ? (
        <AllClear />
      ) : (
        <VStack spacing='1rem' align='stretch' w='full'>
          {itemChunks?.map((chunk, index) => (
            <HStack key={index} spacing='1rem' align='start' h='full'>
              {chunk.map((item, index) => (
                <Box
                  key={index}
                  width={chunk.length === 1 ? '100%' : '50%'}
                  h='full'
                >
                  {focusAreaItem(item)}
                </Box>
              ))}
            </HStack>
          ))}
        </VStack>
      )}
    </VStack>
  );
};

export default FocusArea;
