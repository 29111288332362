import React, { FC } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import {
  TRAINING_PATH_V2,
  TRAINING_PATH_V2_ASSIGNEE_COMPLETED_EVP,
  TRAINING_PATH_V2_COMPLETED_WSV,
  TRAINING_PATH_V2_CREATE,
  TRAINING_PATH_V2_DASHBOARD,
  TRAINING_PATH_V2_EDIT,
  TRAINING_PATH_V2_PATHS,
  TRAINING_PATH_V2_PLAY,
  TRAINING_PATH_V2_PLAY_PREVIEW,
  TRAINING_PATH_V2_PLAY_RETAKE,
  TRAINING_PATH_V2_PLAY_VIEW,
} from '../../appRoutes';
import { TrainingPathDashboard, TrainingPathTracking } from './dashboard';
import { CreateTrainingPath, EditTrainingPath } from './create';
import { TrainingPathPlay } from './play';
import NavigateCompletedAssignee from './NavigateCompletedAssignee';

const TrainingRoute: FC = () => {
  let { path } = useRouteMatch();
  const { pathname } = useLocation();
  return (
    <Switch>
      <Route exact path={path} component={TrainingPathDashboard} />
      <Route path={TRAINING_PATH_V2_CREATE} component={CreateTrainingPath} />
      <Route path={TRAINING_PATH_V2_EDIT} component={EditTrainingPath} />
      <Route
        key={pathname}
        path={TRAINING_PATH_V2_ASSIGNEE_COMPLETED_EVP}
        component={NavigateCompletedAssignee}
      />
      <Route
        key={pathname}
        path={TRAINING_PATH_V2_COMPLETED_WSV}
        component={NavigateCompletedAssignee}
      />
      <Route
        exact
        path={TRAINING_PATH_V2_DASHBOARD}
        component={TrainingPathDashboard}
      />
      <Route
        exact
        path={TRAINING_PATH_V2_PATHS}
        component={TrainingPathTracking}
      />
      <Route
        path={TRAINING_PATH_V2_PLAY_PREVIEW}
        component={TrainingPathPlay}
      />
      <Route path={TRAINING_PATH_V2_PLAY_RETAKE} component={TrainingPathPlay} />
      <Route path={TRAINING_PATH_V2_PLAY_VIEW} component={TrainingPathPlay} />
      <Route path={TRAINING_PATH_V2_PLAY} component={TrainingPathPlay} />
    </Switch>
  );
};

export default TrainingRoute;
