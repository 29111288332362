import React, { FC } from 'react';
import { Box, Center, Flex } from '@chakra-ui/react';
import Image from '../../../../ui-components/Image/Image';
import { IconsPlaceholder } from '../../../../ui-components/Search';
import { truncateString } from '../../../../utils';

interface IProps {
  thumbnail: string;
  title: string;
  description: string;
  category?: string;
}

const CustomFormHeader: FC<IProps> = ({
  description,
  title,
  thumbnail,
  category,
}) => {
  return (
    <div className='custom-form-preview-header'>
      <Box position='relative'>
        <Image
          src={thumbnail || IconsPlaceholder['form']}
          width={400}
          height={150}
          style={{
            width: '100%',
            aspectRatio: '2.7',
            objectFit: 'cover',
          }}
        />

        <Center
          position='absolute'
          top={0}
          left={0}
          right={0}
          bottom={0}
          background='linear-gradient(to bottom, #000 0%, rgba(0, 0, 0, 0) 100%)'
          color='white'
          fontWeight='600'
        >
          {truncateString(category, 30)}
        </Center>
      </Box>
      <div className='custom-form-preview-title'>{title}</div>
      <div className='custom-form-preview-description'>{description}</div>
    </div>
  );
};

export default CustomFormHeader;
