import { ApolloProvider, useApolloClient } from '@apollo/client';
import { Box, ModalCloseButton } from '@chakra-ui/react';
import { useCallback, useRef, useState } from 'react';
import { UseConfirm, useConfirm } from 'ui-components';
import PendingEvaluation from './PendingEvaluation';
import { AppRouter } from 'routes';
interface IProps {}

const PendingEvaluationTitle = ({ count }: { count?: number }) => (
  <>
    <Box>{`Pending Evaluation (${count ?? 0})`}</Box>
    <ModalCloseButton top='1.5rem' right='1.5rem' fontSize='18px' />
  </>
);

const usePendingEvaluation = () => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();
  const apolloClient = useApolloClient();
  const [pendingEvalCount, setPendingEvalCount] = useState<number>(0);

  return useCallback(
    ({
      pendingEvaluationCount,
      refetchTrackingAnalytics,
    }: {
      pendingEvaluationCount?: number;
      refetchTrackingAnalytics?: () => void;
    }) => {
      if (confirmRef.current) {
        confirmRef.current.destroy();
      }
      confirmRef.current = confirm({
        Wrapper: ({ children }: any) => {
          return (
            <ApolloProvider client={apolloClient}>
              <AppRouter>{children}</AppRouter>
            </ApolloProvider>
          );
        },
        size: '3xl',
        title: <PendingEvaluationTitle count={pendingEvaluationCount} />,
        content: (
          <PendingEvaluation
            refetchTrackingAnalytics={refetchTrackingAnalytics}
            setPendingEvalCount={setPendingEvalCount}
          />
        ),
        contentProps: {
          borderRadius: '12px',
          p: '2rem 2.5rem',
        },
        headerProps: {
          p: '0px',
        },
        bodyProps: {
          p: '0px',
        },
        isCentered: true,
        footer: null,
      });
    },
    [confirm, apolloClient, pendingEvalCount]
  );
};

usePendingEvaluation.displayName =
  'displayName:sub-components/training-v2/dashboard/components/myTrainings/pendingEvaluation/usePendingEvaluation.tsx';

export default usePendingEvaluation;
