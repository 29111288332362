import { gql } from '@apollo/client';
import { VisibilityStatus } from '../../../../../../types';

export interface FormResourceItem {
  eid: string;
  title: string;
  category: string;
  thumbnail: string;
  createdAt: string;
  lastUpdated?: string;
  createdBy: {
    name: string;
  };
  lastUpdatedBy?: {
    name: string;
  };
  visibility: VisibilityStatus;
}

export interface FormResponse {
  FormsPagination: {
    items: FormResourceItem[];
  };
}

export const FORM_RESOURCE_LIST = gql`
  query FORM_RESOURCE_LIST(
    $perPage: Int
    $filter: FilterFindManyFormsInput
    $sort: SortFindManyFormsInput
  ) {
    FormsPagination(perPage: $perPage, filter: $filter, sort: $sort) {
      items {
        eid
        title
        category
        thumbnail
        createdAt
        lastUpdated
        createdBy {
          name
        }
        lastUpdatedBy {
          name
        }
        visibility
      }
    }
  }
`;

export const FORM_PREVIEW_QUERY = gql`
  query FormToPreview($eid: String!) {
    FormById(eid: $eid) {
      eid
      title
      thumbnail
      description
      questions
      category
    }
  }
`;
