import React, { FC, useState } from 'react';
import { Box, Center, Flex, Divider } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck,
  faCircleXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { QuizQFormInput } from '../../quiz-form/quiz-form.types';
import PreviewContainer from '../PreviewContainer';
import { FILL_THE_BLANK_REGEX } from 'utils/constants';
import { Input } from '../../../../../../../../atoms';
import { IconImage } from '../../../../../../../../ui-components';

interface Props {
  data: QuizQFormInput;
}

const cleanup = (value: string) => {
  return (value || '').trim().toLowerCase();
};

const FillBlankPreview: FC<Props> = ({ data }) => {
  const [userAnswer, setUserAnswer] = useState('');
  const [showResults, setShowResults] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [firstPart = '', secondPart = ''] = React.useMemo(() => {
    return (
      (data.textWithBlanks || data.label)?.split(FILL_THE_BLANK_REGEX) || []
    );
  }, [data.textWithBlanks, data.label]);

  const isCorrect = data.blanks?.some(
    (blank) => cleanup(blank.answer) === cleanup(userAnswer)
  );

  const handleSubmit = async () => {
    setIsSubmitting(true);
    await new Promise((resolve) => setTimeout(resolve, 500));
    setShowResults(true);
    setIsSubmitting(false);
  };

  const answerField = () => {
    if (!showResults) {
      return (
        <Flex paddingX='2px'>
          <Input
            placeholder='Type answer here'
            value={userAnswer}
            onChange={(e) => setUserAnswer(e.target.value)}
            size='sm'
            borderColor='8px'
            height='36px'
            border='none'
            bg='white'
            fontSize='11px'
          />
        </Flex>
      );
    }

    const bgColor = isCorrect
      ? 'rgba(181, 228, 202, 0.3)'
      : 'rgba(255, 106, 85, 0.2)';
    const borderColor = isCorrect
      ? 'rgba(131, 191, 110, 1)'
      : 'rgba(255, 106, 85, 1)';
    const icon = isCorrect ? faCircleCheck : faCircleXmark;
    const iconColor = isCorrect
      ? 'rgba(131, 191, 110, 1)'
      : 'rgba(255, 106, 85, 1)';

    return (
      <Flex flexDir='column' gap={4}>
        <Flex
          bg={bgColor}
          border={`1px solid ${borderColor}`}
          borderRadius='8px'
          p='10px'
          align='center'
          justify='space-between'
        >
          <Box fontSize='10px' fontWeight={500}>
            {userAnswer}
          </Box>
          <FontAwesomeIcon
            icon={icon as IconProp}
            color={iconColor}
            fontSize='16px'
          />
        </Flex>

        {!isCorrect && (
          <>
            <Divider />
            <Flex align='center' gap='5px'>
              <Box fontSize='11px' fontWeight={600}>
                Correct answer{data.blanks!.length > 1 ? 's' : ''}:
              </Box>
              <Box fontWeight={600} fontSize='10px'>
                {data.blanks?.map((b) => b.answer).join(', or ')}
              </Box>
            </Flex>
          </>
        )}
      </Flex>
    );
  };

  return (
    <PreviewContainer
      hideTitle
      title=''
      onSubmit={handleSubmit}
      showSubmit={!showResults}
      isSubmitting={isSubmitting}
      isDisabled={!userAnswer.trim()}
    >
      <Flex flexDir='column' gap={4}>
        <Center
          flexDir='column'
          fontSize='10px'
          fontWeight={600}
          bg='white'
          borderRadius='12px'
          p='24px 16px'
          textAlign='center'
          gap='6px'
        >
          <Box textAlign='center'>Fill in the blanks:</Box>
          {data?.thumbnail ? (
            <IconImage boxSize={64} name='' thumbnail={data?.thumbnail} />
          ) : null}
          <Box textAlign='center'>{firstPart}</Box>
          <Box
            as='span'
            display='inline-block'
            borderWidth='1px'
            borderColor='blue.200'
            borderRadius='md'
            px={4}
            minW='100px'
            minH='18px'
          >
            {!showResults && <>&nbsp;</>}
          </Box>
          <Box textAlign='center'>{secondPart}</Box>
        </Center>

        {answerField()}
      </Flex>
    </PreviewContainer>
  );
};

export default FillBlankPreview;
