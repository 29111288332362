import React, { FC } from 'react';
import { Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import DateInput, {
  DateInputButton,
} from '../../../../../../pages/Locations/Components/DateInput';
import { IDocumentInput } from './compliance.input';
import TitleHeader from '../../../../../CardEditor/TitleHeader';

import { ReactComponent as CalendarIcon } from 'assets/images/calendar.svg';
import DatePickerCustom from 'ui-components/DatePicker/DatePicker';

interface IProps {
  isEditing?: boolean;
}

const ExpiryDateSelectField: FC<IProps> = ({ isEditing }) => {
  const { t } = useTranslation(['nexus']);

  const { getValues } = useFormContext();

  return (
    <Controller<IDocumentInput, 'expiryDate'>
      name='expiryDate'
      // rules={{ required: t('nexus:documents.validations.expDateRequired') }}
      render={({ field, fieldState }) => (
        <FormControl isInvalid={fieldState.invalid}>
          <TitleHeader
            title={
              isEditing
                ? 'New expiry date of document'
                : t('nexus:documents.addExpDate')
            }
            titleStyle={{ marginBottom: '8px' }}
          />
          <Flex>
            <DatePickerCustom
              placeholder=''
              value={field.value}
              minDate={new Date()}
              onChange={field.onChange}
              disableLeftIcon
              rightIcon={<CalendarIcon />}
              colorScheme='gray'
              format='DD-MM-yyyy'
              width='190px'
            />
          </Flex>
          <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
        </FormControl>
      )}
    />
  );
};

ExpiryDateSelectField.displayName =
  'displayName:sub-components/nexus/Compliance/Create/components/common/ExpiryDateSelectField';

export default ExpiryDateSelectField;
