import { AlertDialogCloseButton, Box, Center, Flex } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCamera, faFiles } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';

interface IProps {
  nextState: () => void;
}

const InitialState: FC<IProps> = ({ nextState }) => {
  return (
    <Flex w='full' flexDir='column' gap={4}>
      <Flex flex={1} align='center' justify='space-between' w='full'>
        <Box as='span' fontWeight={600} fontSize='20px'>
          Export report
        </Box>
        <AlertDialogCloseButton
          pos='relative'
          borderRadius='full'
          top='0'
          right='0'
        />
      </Flex>
      <Center gap={4} py='128px'>
        <Center
          w='50%'
          bg='rgba(181, 228, 202, 0.3)'
          borderRadius='12px'
          p='20px 24px'
          flexDir='column'
          h='239px'
          gap={2}
          alignItems='flex-start'
          cursor='pointer'
        >
          <Box mb={2}>
            <FontAwesomeIcon
              icon={faCamera as IconProp}
              color='rgba(131, 191, 110, 1)'
              size='3x'
            />
          </Box>
          <Box as='span' fontWeight={600} fontSize='18px'>
            Export Dashboard Snapshot (PDF)
          </Box>
          <Box as='span' fontWeight={500} color='rgba(111, 118, 126, 1)'>
            Download the current dashboard view{' '}
          </Box>
        </Center>
        <Center
          w='50%'
          bg='rgba(202, 189, 255, 0.3)'
          borderRadius='12px'
          p='20px 24px'
          flexDir='column'
          h='239px'
          gap={2}
          alignItems='flex-start'
          cursor='pointer'
          onClick={nextState}
        >
          <Box mb={2}>
            <FontAwesomeIcon
              icon={faFiles as IconProp}
              color='rgba(142, 89, 255, 1)'
              size='3x'
            />
          </Box>
          <Box as='span' fontWeight={600} fontSize='18px'>
            Generate Custom Report (XLS)
          </Box>
          <Box as='span' fontWeight={500} color='rgba(111, 118, 126, 1)'>
            Choose specific metrics and export a detailed report
          </Box>
        </Center>
      </Center>
    </Flex>
  );
};

InitialState.displayName =
  'displayName:sub-components/nexus/NexusBase/components/LeftSection/components/Heading/components/InitialState';

export default InitialState;
