import { ChevronLeftIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowsRepeat,
  faChevronDown,
  faDown,
  faPlay,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  TRAINING_PATH_V2_DASHBOARD,
  TRAINING_PATH_V2_EDIT,
  TRAINING_PATH_V2_PLAY_PREVIEW,
} from 'appRoutes';
import { Authorize, AuthRole } from 'authorization';
import moment from 'moment';
import { FC, useMemo } from 'react';
import { useHistory } from 'routes';
import { ITrackPathHeaderProps } from 'sub-components/training-v2/shared/types';
import { useCrossTrain } from '../crossTrain/CrossTrainModal';
import { useUserDataSelector } from 'hooks';
import { useReassignModal } from './useReassignModal';
import { TrainingAccessEnum } from 'types';
import { GET_TP_SESSION_DATA } from '../query/track-dashboard-graphql';
import {
  fromAssigneePayload,
  transformAccessRules,
  useAccessPermissionSave,
  useTrainingAccessRule,
} from 'sub-components/training-v2/edit/access-rules';
import data from 'sub-components/forms/create-form/QuestionTypeSelect/data';
import { useLocation } from 'react-router-dom';
import { getTrainingCreator } from 'sub-components/training-v2/create/components/common/useTrainingCreator';
import useCombinedStore from 'zustandStore/store';

interface ILocState {
  backToTitle: string;
}

const TrackPathHeader: FC<ITrackPathHeaderProps> = ({
  crossTrainModalData,
  sessionId,
  crossTrainAcceess,
  editPathAccess,
  adminTpEditAccess,
  tp,
  isTpRepeating,
  repeatingCycles,
  setCycleSessionId,
  cyclesSessionId,
  refetchTpSessionData,
}) => {
  const locState = useLocation<ILocState>()?.state;
  const openCrossTrainModal = useCrossTrain();
  const openReassignModal = useReassignModal();
  const history = useHistory();
  const trainingId = tp && tp.trainingId;
  const { loggedInUserEid, authRole } = useUserDataSelector((state) => ({
    loggedInUserEid: state?.eid,
    authRole: state?.authRole,
  }));
  const toast = useToast({
    position: 'top-right',
    isClosable: true,
    duration: 3000,
  });

  const accessRule = useTrainingAccessRule();
  const permissionSave = useAccessPermissionSave();
  const creator = getTrainingCreator(tp?.training?.createdBy);
  const editAcessActionHandler = () => {
    accessRule({
      initialValue: transformAccessRules(
        tp?.training?.accessPermission,
        creator?.eid ?? ''
      ), //just updating the access rules
      hasSkillVerifier: tp?.hasSkillVerifier,
      creator: creator,
      //@ts-ignore
      details: {
        assigneeUserType: tp?.training?.assigneeUserType,
        ...fromAssigneePayload(tp?.training?.assignees),
        supervisors: tp?.supervisors,
      },
      title: 'Edit access rules',
      buttonText: 'Save changes',
      onPublishClick: async (values) => {
        await permissionSave({
          eid: tp?.training?.eid,
          accessPermission: values,
        });
        refetchTpSessionData?.();
        toast({
          status: 'success',
          title: 'Success',
          description: 'Access rules successfully updated',
        });
      },
    });
  };
  const { setCurrentRepeatingTrainingSessionCycle } = useCombinedStore();
  const canCurrentUserReassign =
    (authRole === AuthRole.SUPER_ADMIN &&
      tp?.training?.accessPermission?.superadmin?.includes(
        TrainingAccessEnum.ASSIGN
      )) ||
    (authRole === AuthRole.ADMIN &&
      tp?.training?.accessPermission?.admin?.includes(
        TrainingAccessEnum.ASSIGN
      )) ||
    tp?.createdBy === loggedInUserEid;

  const tpInactive = tp?.training?.status === 'TRAINING_TERMINATED';
  const redirectionHandler = () => {
    if (locState?.backToTitle) {
      history?.goBack();
    } else {
      history.push({
        pathname: TRAINING_PATH_V2_DASHBOARD,
      });
    }
  };

  const editButtons = [
    {
      label: 'Edit path content',
      onClick: () =>
        history.push({
          pathname: TRAINING_PATH_V2_EDIT,
          params: { trainingId },
          search: '?action=content',
        }),
    },
    {
      label: 'Edit assignee and schedule',
      onClick: () =>
        history.push({
          pathname: TRAINING_PATH_V2_EDIT,
          params: { trainingId },
          search: '?action=assignee',
        }),
    },
    {
      label: 'Edit access',
      onClick: editAcessActionHandler,
    },
  ];
  //helper to return out the valid repeatCycle dates
  const renderRepeatingSessionDates = (startDate?: Date, endDate?: Date) => {
    if (!moment(startDate).isValid() && !moment(endDate).isValid()) return '-';
    if (moment(startDate).isValid() && !moment(endDate).isValid())
      return moment(startDate).format('DD MMM');
    return `${moment(startDate).format('DD MMM')} - ${moment(endDate).format(
      'DD MMM'
    )}`;
  };
  return (
    <Flex justify={'space-between'} align={'center'} mt={'8px'}>
      <Flex direction={'column'} gap={'8px'}>
        <Tooltip label={tp?.training?.title}>
          <Text
            fontWeight={600}
            fontSize={'32px'}
            isTruncated
            maxWidth={'400px'}
            lineHeight={'35px !important'}
          >
            {tp?.training?.title}
          </Text>
        </Tooltip>
        <Flex
          align='center'
          cursor='pointer'
          onClick={redirectionHandler}
          ml={'-3px'}
        >
          <ChevronLeftIcon boxSize={4} color={'#6F767E'} />
          <Text fontSize='15px' fontWeight={500} color={'#6F767E'}>
            {locState?.backToTitle || 'Back to training'}
          </Text>
        </Flex>
      </Flex>
      {/**Action Buttons */}
      <Flex>
        <HStack justify='center' spacing={4}>
          <Box w='fit-content'>
            {isTpRepeating ? (
              <Popover
                placement='bottom'
                arrowSize={16}
                isLazy
                offset={[0, 15]}
              >
                <PopoverTrigger>
                  <Button
                    rightIcon={
                      <FontAwesomeIcon
                        icon={faChevronDown as IconProp}
                        style={{ color: '#6F767E' }}
                      />
                    }
                    size='lg'
                    variant='outline'
                    borderRadius='12px'
                    border='2px'
                    px={6}
                    bg='#FCFCFC'
                    fontWeight={700}
                    fontSize='15px'
                    textColor='#1A1D1F'
                    minWidth={'150px'}
                  >
                    {repeatingCycles[0]?.eid === cyclesSessionId?.eid
                      ? 'Current Cycle'
                      : renderRepeatingSessionDates(
                          cyclesSessionId?.startDate,
                          cyclesSessionId?.endDate
                        )}
                  </Button>
                </PopoverTrigger>
                <PopoverContent
                  border='none'
                  borderRadius='16px'
                  boxShadow={'2xl'}
                  width='fit-content'
                  p={2}
                >
                  <PopoverArrow />
                  <PopoverBody>
                    <VStack spacing={3} align='start'>
                      {repeatingCycles?.map((cycle, index) => (
                        <Button
                          key={cycle.eid}
                          variant='ghost'
                          borderRadius='12px'
                          justifyContent='flex-start'
                          width='100%'
                          _hover={{ bg: '#F0F0F0' }}
                          bg={
                            cyclesSessionId?.eid === cycle?.eid
                              ? '#F0F0F0'
                              : 'inherit'
                          }
                          onClick={() => {
                            setCycleSessionId(cycle);
                            setCurrentRepeatingTrainingSessionCycle(cycle);
                          }}
                        >
                          <Text
                            fontSize='15px'
                            color={index === 0 ? '#1A1D1F' : '#6F767E'}
                            fontWeight={
                              cyclesSessionId?.eid === cycle?.eid ? 700 : 600
                            }
                          >
                            {index === 0
                              ? 'Current cycle'
                              : renderRepeatingSessionDates(
                                  cycle?.startDate,
                                  cycle?.endDate
                                )}
                          </Text>
                        </Button>
                      ))}
                    </VStack>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            ) : (
              canCurrentUserReassign && (
                <Button
                  leftIcon={
                    <FontAwesomeIcon
                      icon={faArrowsRepeat as IconProp}
                      style={{ color: '#6F767E' }}
                    />
                  }
                  size='lg'
                  variant='outline'
                  borderRadius='12px'
                  px={6}
                  bg={'#FCFCFC'}
                  fontWeight={700}
                  fontSize={'15px'}
                  textColor={'#1A1D1F'}
                  onClick={() => {
                    const training = tp?.training;
                    openReassignModal({
                      trainingId: trainingId,
                      sessionId: sessionId,
                      details: {
                        assignmentType: training?.assignmentType,
                        deadlineDate: training?.deadlineDate,
                        deadlineType: training?.deadlineType,
                        duration: training?.duration
                          ? {
                              type: training?.duration?.type,
                              value: training?.duration?.value,
                            }
                          : null,
                        startDate: training?.startDate,
                      },
                    });
                  }}
                >
                  Reassign all
                </Button>
              )
            )}
          </Box>
          <Box w='fit-content'>
            <Button
              leftIcon={
                <FontAwesomeIcon
                  icon={faPlay as IconProp}
                  style={{ color: '#6F767E' }}
                />
              }
              size='lg'
              variant='outline'
              borderRadius='12px'
              border={'2px'}
              px={6}
              bg={'#FCFCFC'}
              fontWeight={700}
              fontSize={'15px'}
              textColor={'#1A1D1F'}
              onClick={() => {
                history.push({
                  pathname: TRAINING_PATH_V2_PLAY_PREVIEW,
                  params: {
                    trainingId,
                    sessionId,
                  },
                });
              }}
            >
              Play path
            </Button>
          </Box>
          <Authorize
            permittedFor='user'
            permittedRoles={[
              AuthRole.SUPER_ADMIN,
              ...(editPathAccess || adminTpEditAccess ? [AuthRole.ADMIN] : []),
              ...(editPathAccess ? [AuthRole.LOCATION_OWNER] : []),
            ]}
          >
            <Popover placement='bottom-start' arrowSize={14} offset={[-12, 12]}>
              <PopoverTrigger>
                <Button
                  rightIcon={
                    <FontAwesomeIcon icon={faChevronDown as IconProp} />
                  }
                  size='lg'
                  borderRadius='12px'
                  colorScheme='blue'
                  px={6}
                >
                  Edit path
                </Button>
              </PopoverTrigger>
              <PopoverContent
                borderRadius='16px'
                boxShadow={'lg'}
                p='14px'
                maxWidth={'260px'}
              >
                <PopoverArrow />
                <PopoverBody>
                  {editButtons.map(({ label, onClick }) => (
                    <Button
                      key={label}
                      variant='ghost'
                      borderRadius='16px'
                      justifyContent='flex-start'
                      width='100%'
                      _hover={{
                        bg: '#F0F0F0',
                        fontWeight: 700,
                        color: '#1A1D1F',
                      }}
                      onClick={onClick}
                      color='#6F767E'
                    >
                      <Text fontSize='14px' fontWeight={600}>
                        {label}
                      </Text>
                    </Button>
                  ))}
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Authorize>

          <Authorize
            permittedFor='user'
            permittedRoles={[AuthRole.LOCATION_OWNER]}
          >
            {crossTrainAcceess && !tpInactive && (
              <Button
                size='lg'
                bg='#4285f4'
                color='white'
                borderRadius='12px'
                px={6}
                _hover={{ bg: '#3367d6' }}
                onClick={() =>
                  openCrossTrainModal(
                    crossTrainModalData,
                    trainingId,
                    sessionId
                  )
                }
              >
                Cross Train
              </Button>
            )}
          </Authorize>
        </HStack>
      </Flex>
    </Flex>
  );
};

TrackPathHeader.displayName =
  'displayName:packages/sop-web/src/sub-components/training-v2/dashboard/components/TrackPathDashboard/components/TrackPathHeader';

export default TrackPathHeader;
