// @ts-ignore
import React, { FC, useState } from 'react';
import { FormCategory } from 'types';
import {
  IFormListEntity,
  IFormRespondents,
  IFormResponse,
  IFormResponseByIdData,
  IFormUpdatedBy,
} from '../forms-types';
import FormDataContext from './form-data-context';
import { defaultFormRespondents } from './form-data-default-values';

const FormDataProvider: FC = (props) => {
  const [formsListData, setFormsListData] = useState<IFormListEntity>({
    count: 0,
    items: [],
    pageInfo: {
      currentPage: 0,
      hasNextPage: false,
      hasPreviousPage: false,
      itemCount: 0,
      pageCount: 0,
      perPage: 0,
    },
  });
  const [formsFeedData, setFormsFeedData] = useState<any>(null);
  const [responseData, setResponseData] = useState<IFormResponse>({
    count: 0,
    items: [],
    pageInfo: {
      currentPage: 0,
      hasNextPage: false,
      hasPreviousPage: false,
      itemCount: 0,
      pageCount: 0,
      perPage: 0,
    },
  });
  const [formCategories, setFormCategories] = useState<FormCategory[]>([]);
  const [loadingFormCategories, setLoadingFormCategories] = useState(false);
  const [formResponseByIdData, setFormResponseByIdData] =
    useState<IFormResponseByIdData>({
      category: '',
      createdAt: '',
      description: '',
      eid: '',
      lastUpdatedBy: {} as IFormUpdatedBy,
      createdBy: {
        eid: '',
        name: '',
      },
      thumbnail: '',
      title: '',
      updatedAt: '',
      userList: [],
    });
  const [sendFormSelections, setSendFormSelections] = useState<any[]>([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [selectedTemplateTab, setSelectedTemplateTab] = useState('');
  const [searchString, setSearchString] = useState('');
  const [formRespondents, setFormRespondents] = useState<IFormRespondents>(
    defaultFormRespondents
  );

  const updateFormsListDataHandler = (data: IFormListEntity) => {
    setFormsListData(data);
  };

  const updateFormsFeedDataHandler = (data: any) => {
    setFormsFeedData(data);
  };

  const changeTabIndex = (indexNumber: number) => {
    setSelectedTabIndex(indexNumber);
  };

  const changeTemplateTab = (value: string) => {
    setSelectedTemplateTab(value);
  };

  const changeSearchString = (searchString: string) => {
    setSearchString(searchString);
  };

  const updateResponseData = (data: IFormResponse) => {
    setResponseData(data);
  };

  const sendFormSelectionsHandler = (selections: any) => {
    setSendFormSelections(selections);
  };

  const updateFormResponseByIdData = (data: IFormResponseByIdData) => {
    setFormResponseByIdData(data);
  };

  const formRespondentsHandler = (data: IFormRespondents) => {
    setFormRespondents(data);
  };

  const updateFormCategories = (data: FormCategory[]) => {
    setFormCategories(data);
  };

  const updateFormCategoriesLoading = (value: boolean) => {
    setLoadingFormCategories(value);
  };

  const formContext = {
    formsListData: formsListData,
    formsFeedData: formsFeedData,
    responseData: responseData,
    selectedTabIndex: selectedTabIndex, // pre-selected User wise tab
    selectedTemplateTab: selectedTemplateTab,
    searchString: searchString,
    sendFormSelections: sendFormSelections,
    formResponseByIdData: formResponseByIdData,
    formRespondents: formRespondents,
    formCategories: formCategories,
    loadingFormCategories: loadingFormCategories,
    updateFormsListDataHandler: updateFormsListDataHandler,
    updateFormsFeedDataHandler: updateFormsFeedDataHandler,
    changeTabIndex: changeTabIndex,
    changeTemplateTab: changeTemplateTab,
    changeSearchString: changeSearchString,
    updateResponseData: updateResponseData,
    sendFormSelectionsHandler: sendFormSelectionsHandler,
    updateFormResponseByIdData: updateFormResponseByIdData,
    formRespondentsHandler: formRespondentsHandler,
    updateFormCategories: updateFormCategories,
    updateFormCategoriesLoading: updateFormCategoriesLoading,
  };

  return (
    <FormDataContext.Provider value={formContext}>
      {props.children}
    </FormDataContext.Provider>
  );
};

export default FormDataProvider;
