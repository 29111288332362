import { ChevronDownIcon } from '@chakra-ui/icons';
import { Box, Flex, forwardRef } from '@chakra-ui/react';
import { Button, ButtonProps } from 'atoms';
import { CustomTooltip } from 'ui-components/Tooltip';

interface IProps extends ButtonProps {
  board?: string;
}

const BoardSelectButton = forwardRef<IProps, 'button'>(
  // @ts-ignore
  ({ board, disabled, ...props }, ref) => {
    return (
      // @ts-ignore
      <Button
        ref={ref}
        borderRadius='12px'
        rightIcon={<ChevronDownIcon boxSize='28px' color='#6F767E' />}
        justifyContent='space-between'
        alignItems='initial'
        px='12px'
        py='8px'
        paddingBlock='6px'
        variant='ghost'
        border={'2px solid #EFEFEF'}
        bg='white'
        width='259px'
        height='40px'
        minHeight={12}
        isDisabled={disabled}
        {...props}
      >
        <Flex gap={2} align='center' flexWrap='wrap'>
          <Box
            fontSize='14px'
            fontWeight='600'
            whiteSpace='nowrap'
            color='#6F767E'
            isTruncated
            maxWidth={'180px'}
          >
            <CustomTooltip>{board}</CustomTooltip>
          </Box>
        </Flex>
      </Button>
    );
  }
);

export default BoardSelectButton;
