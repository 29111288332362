import { gql } from '@apollo/client';

export const LOCATION_DETAILS_QUERY = gql`
  query UserById($eid: String!) {
    userById(eid: $eid) {
      email
      username
      name
      phone
      profilePic
      onboarded
      isDemo
      onboardedAt
      authRole
      type
      role
      preferredLanguage
      entityType
      entityName
      timezone
      entityId
      loginCount
      lastLogin
      isDeleted
      isRoot
      isInvited
      addedBy
      isExternal
      status
      goLiveDate
      address {
        address
        city
        zipCode
        state
      }
      locationType
      locationStatus
      entityType
      countryOfFormation
      stateOfFormation
      taxPayerId
      locationPhone
      locationEmail
      files {
        createdAt
        createdBy {
          eid
          name
          profilePic
        }
        fileSize
        mimetype
        name
        type
        updatedAt
        url
      }
      createdAt
      updatedAt
      eid
      locations {
        eid
        email
        name
      }
      members
      assignedOn
      entity {
        eid
        email
        name
      }
      locationLauncher {
        liveDate
      }
      otherFields {
        createdAt
        fieldId
        files {
          createdAt
          createdBy {
            eid
            name
            profilePic
          }
          fileSize
          mimetype
          name
          type
          updatedAt
          url
        }
        options
        updatedAt
        value
      }
    }
  }
`;

export const UPDATE_FILES = gql`
  mutation UpdateUserById($input: UserUpdateIdInput) {
    updateUserById(input: $input) {
      eid
    }
  }
`;

export const UPDATE_LOCATION_GO_LIVE_DATE = gql`
  mutation UpdateUserById($input: UserUpdateIdInput) {
    updateUserById(input: $input) {
      goLiveDate
    }
  }
`;
