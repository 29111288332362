import React, { forwardRef, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useControllableState,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { IAssigneeInput } from '../../training-input.types';

import { ReactComponent as JobIcon } from '../../../../../../assets/images/training/job-icon.svg';
import { ReactComponent as LocIcon } from '../../../../../../assets/images/location.svg';
import { ReactComponent as RoleIcon } from '../../../../../../assets/images/privacyIcon.svg';
import { ReactComponent as MemberIcon } from '../../../../../../assets/images/userIcon.svg';

const Options = [
  {
    label: 'Job',
    value: 'role',
    icon: JobIcon,
  },
  {
    label: 'Location',
    value: 'location',
    icon: LocIcon,
  },
  {
    label: 'Member',
    value: 'user',
    icon: MemberIcon,
  },
  {
    label: 'Role',
    value: 'authRole',
    icon: RoleIcon,
  },
];

interface IProps {
  value?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  name?: string;
}

const AssignBySelect = forwardRef<never, IProps>(
  ({ value, onChange, disabled, name }, ref) => {
    const [internalValue, updateValue] = useControllableState<string>({
      value: value,
      onChange: onChange,
    });
    const assigneeUserType = useWatch<IAssigneeInput, 'assigneeUserType'>({
      name: 'assigneeUserType',
    });

    const options = useMemo(() => {
      if (assigneeUserType === 'new') {
        return Options.filter((op) => op.value !== 'user');
      }
      return Options;
    }, [assigneeUserType]);

    const _value = useMemo(() => {
      return options.find((val) => val.value === internalValue);
    }, [options, internalValue]);

    return (
      <Menu
        id={name}
        matchWidth
        closeOnSelect
        boundary='scrollParent'
        autoSelect={false}
      >
        <MenuButton
          ref={ref}
          as={Button}
          size='lg'
          borderRadius='12px'
          rightIcon={<ChevronDownIcon boxSize='28px' color='#6F767E' />}
          paddingInline='12px'
          variant='outline'
          bg='white'
          width='full'
          isDisabled={disabled}
        >
          <Flex gap={2} align='center'>
            {_value ? (
              <>
                <_value.icon width={24} height={24} />
                <Box
                  fontSize='14px'
                  fontWeight='600'
                  whiteSpace='nowrap'
                  color='#6F767E'
                >
                  {_value.label}
                </Box>
              </>
            ) : (
              <Box fontSize='14px' fontWeight='600' opacity='0.5'>
                Select assign by
              </Box>
            )}
          </Flex>
        </MenuButton>
        <MenuList
          width='inherit'
          display='flex'
          flexDirection='column'
          padding='16px'
          borderRadius='12px'
          zIndex={100}
          gap={1}
        >
          {options.map((item) => {
            const Icon = item.icon || React.Fragment;
            const isSelected = item.value === internalValue;
            return (
              <MenuItem
                key={item.value}
                gap={3}
                height='48px'
                alignItems='center'
                paddingInline='12px'
                borderRadius='12px'
                cursor='pointer'
                aria-selected={isSelected}
                _selected={{ bg: '#2A85FF1A', color: '#2A85FF' }}
                _hover={{ bg: '#F4F4F3' }}
                onClick={() => updateValue?.(item.value)}
              >
                <Icon
                  width={24}
                  height={24}
                  color={isSelected ? '#2A85FF' : '#6F767E'}
                />
                <Box
                  flex={1}
                  fontSize='15px'
                  fontWeight='600'
                  isTruncated
                  color='#33383F'
                >
                  {item.label}
                </Box>
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    );
  }
);

AssignBySelect.displayName = 'AssignBySelect';

export default AssignBySelect;
