import React, { FC, useMemo } from 'react';
import { Box, Text, Tooltip } from '@chakra-ui/react';
import { ChatGroupEntity } from 'types';
import { useJobLocationData } from '../../select-group/useJobLocationData';
import ThreestepinfoIcon from 'assets/images/threeStepInfo.svg';

type ChatMembersAutoAddSettingsInfoProps = {
  groups: ChatGroupEntity;
};

const ChatMembersAutoAddSettingsInfo: FC<
  ChatMembersAutoAddSettingsInfoProps
> = ({ groups }) => {
  const { locations } = useJobLocationData();

  const _autoAddGroups = useMemo(() => {
    const selectedLocations =
      groups?.details?.locations?.map((loc) => loc.eid) || [];
    const _autoAddAuthRoles = groups?.details?.authRoles || [];
    const _autoAddRoles = groups?.details?.roles || [];
    const _locations = locations
      .filter((loc) => selectedLocations.includes(loc.value))
      .map((loc) => loc.label);

    return { _autoAddAuthRoles, _autoAddRoles, _locations };
  }, [groups?.details, locations]);

  const allRoles = [
    ..._autoAddGroups._autoAddAuthRoles,
    ..._autoAddGroups._autoAddRoles,
  ];
  const hasRoles = allRoles.length > 0;
  const hasLocations = _autoAddGroups._locations.length > 0;

  return (
    <Box
      display='flex'
      alignItems='flex-start'
      bg='blue.50'
      p={4}
      borderRadius='md'
      gap={3}
    >
      <img
        src={ThreestepinfoIcon}
        alt='threeStepInfo'
        width={20}
        height={20}
        style={{ marginTop: '5px' }}
      />

      <Box>
        <Text color='gray.800'>
          All new{' '}
          {hasRoles && (
            <Text as='span' fontWeight='bold'>
              {allRoles.join('s, ')}s
            </Text>
          )}
          {/* Add comma between roles and locations if both exist */}
          {hasRoles && hasLocations && ', '}
          {hasLocations &&
            (_autoAddGroups._locations.length > 2 ? (
              <Tooltip label={_autoAddGroups._locations.slice(2).join(', ')}>
                <Text as='span' fontWeight='bold' cursor='pointer'>
                  {_autoAddGroups._locations.slice(0, 2).join(', ')}{' '}
                  <span style={{ textDecoration: 'underline' }}>
                    +{_autoAddGroups._locations.length - 2} more
                  </span>{' '}
                  members
                </Text>
              </Tooltip>
            ) : (
              _autoAddGroups._locations.map((location, index, arr) => (
                <Text as='span' fontWeight='bold' key={index}>
                  {location} members{index < arr.length - 1 ? ', ' : ' '}
                </Text>
              ))
            ))}
          {
            ' will be automatically added to the channel as new members. You can update this in '
          }
          <Text as='span' fontWeight='bold'>{`'Edit Members'`}</Text>
        </Text>
      </Box>
    </Box>
  );
};

ChatMembersAutoAddSettingsInfo.displayName =
  'displayName:src/pages/Settings/ChatSetting/create-channel/update-channel/members/ChatMembersAutoAddSettingsInfo.tsx';

export default ChatMembersAutoAddSettingsInfo;
