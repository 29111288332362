import React, { FC } from 'react';
import { Flex, Spacer, Tooltip } from '@chakra-ui/react';
import { Controller, useController } from 'react-hook-form';
import { BaseOptionContainer, QuizIconButton, QuizTextInput } from '../common';
import { QuizQFormInput } from '../quiz-form.types';
import { ThumbnailAddButton, ThumbnailView } from '../thumbnail-input';

import { ReactComponent as DeleteIcon } from 'assets/images/deleteBin.svg';
import { ReactComponent as TaskIcon } from 'assets/images/sidebar/task.svg';

interface IProps {
  optionIndex: number;
  totalLength: number;
  isCorrect?: boolean;
  onRemoveClick?: () => void;
  onCheckClick?: () => void;
}

const MultiChoiceOption: FC<IProps> = ({
  optionIndex,
  totalLength,
  isCorrect,
  onRemoveClick,
  onCheckClick,
}) => {
  const { field } = useController<
    QuizQFormInput,
    `options.${number}.thumbnail`
  >({
    name: `options.${optionIndex}.thumbnail`,
  });

  return (
    <BaseOptionContainer
      optionIndex={optionIndex}
      baseKey='options'
      fieldKeys={['text']}
      height='122px'
    >
      <Flex
        justify='space-between'
        padding='6px'
        gap='6px'
        borderTopRadius='10px'
      >
        {field.value ? null : <ThumbnailAddButton {...field} />}

        <Tooltip
          hasArrow
          padding='6px 10px'
          placement='top-start'
          label='Cannot delete option - at least two choices are required to proceed.'
          isDisabled={totalLength > 2}
        >
          <QuizIconButton
            aria-label='delete-option'
            borderRadius='4px'
            borderWidth='1px'
            icon={<DeleteIcon width={12} height={12} />}
            aria-disabled={totalLength <= 2}
            onClick={totalLength <= 2 ? undefined : onRemoveClick}
          />
        </Tooltip>

        <Spacer />

        <QuizIconButton
          aria-label='check-image'
          borderRadius='full'
          variant='ghost'
          aria-checked={isCorrect}
          _checked={{ color: '#83BF6E' }}
          icon={<TaskIcon width={16} height={16} color='inherit' />}
          onClick={onCheckClick}
        />
      </Flex>
      <Flex
        flex={1}
        borderBottomRadius='10px'
        padding='12px 16px'
        overflow='hidden'
        gap={1}
        aria-checked={isCorrect}
        _checked={{ color: '#83BF6E' }}
      >
        {field.value ? (
          <ThumbnailView
            boxSize={60}
            alignSelf='center'
            {...field}
            value={field.value}
          />
        ) : null}

        <Controller<QuizQFormInput, `options.${number}.text`>
          name={`options.${optionIndex}.text`}
          rules={{
            required: 'Option title is required',
            validate: (value) => {
              if (!value?.trim()) {
                return 'Enter valid option title';
              }
            },
          }}
          render={({ field }) => (
            <QuizTextInput placeholder='Type answer option here' {...field} />
          )}
        />
      </Flex>
    </BaseOptionContainer>
  );
};

export default MultiChoiceOption;
