import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { getSxScrollStyles } from '../../common';
import QuizTitleInput from './QuizTitleInput';
import QuizQuestionList from './QuizQuestionList';

interface IProps {}

const QuizResourceContainer: FC<IProps> = () => {
  return (
    <Flex
      flex={1}
      gap={6}
      flexDir='column'
      maxHeight='full'
      maxWidth='calc(100% - 260px - 40px)'
    >
      <Box fontSize='18px' fontWeight='600' color='#6F767E'>
        Add questions
      </Box>
      <Flex
        flexDir='column'
        gap={5}
        overflow='hidden auto'
        paddingTop={1}
        paddingBottom={4}
        paddingRight={4}
        id='quiz-question-list'
        sx={getSxScrollStyles()}
      >
        <QuizTitleInput />

        <QuizQuestionList />
      </Flex>
    </Flex>
  );
};

export default QuizResourceContainer;
