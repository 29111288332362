import React, { FC, useState } from 'react';
import {
  Box,
  Button,
  Center,
  Flex,
  Progress,
  useFormControl,
} from '@chakra-ui/react';
import { Accept, ErrorCode, useDropzone } from 'react-dropzone';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faGif } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans, useTranslation } from 'react-i18next';
import { useUploadImage, useUploadToAws } from '../../../../../../hooks';
import AssetLinkPaste from '../quiz-resource/quiz-form/thumbnail-input/add-image/AssetLinkPaste';

interface IProps {
  accept?: Accept;
  maxSize?: number;
  onFileChange?: (url: string, file?: File) => void | Promise<void>;
  onError?: (error: Object) => void;
  caption: string;
  inputPlaceholder: string;
  validateLink?: (resourceLink: string) => Promise<void>;
}

// TODO url input validator as props

const DropzoneInput: FC<IProps> = ({
  accept,
  maxSize,
  caption,
  inputPlaceholder,
  onFileChange,
  onError,
  validateLink,
}) => {
  const { t } = useTranslation(['common', 'nexus']);

  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const uploadImage = useUploadImage();
  const uploadToAws = useUploadToAws();

  const controlProps = useFormControl<HTMLInputElement>({});

  const _uploadFile = async (
    file: File,
    callback: (progress: number) => void
  ) => {
    if (file.type?.includes('video/')) {
      return uploadToAws(file, callback);
    } else {
      return uploadImage(file, undefined, callback);
    }
  };

  const _onFileChange = async (files: FileList) => {
    if (files?.[0]) {
      try {
        setProgress(0);
        setIsUploading(true);
        const file = files[0];
        const responseUrl = await _uploadFile(file, setProgress);
        if (onFileChange?.constructor?.name === 'AsyncFunction') {
          await onFileChange?.(responseUrl, file);
        } else {
          onFileChange?.(responseUrl, file);
        }
        setIsUploading(false);
      } catch (e) {
        setIsUploading(false);
      }
    }
  };
  const _onLinkChange = async (resourceUrl: string) => {
    if (resourceUrl && validateLink) {
      try {
        setProgress(99);
        setIsUploading(true);
        await validateLink(resourceUrl);

        if (onFileChange?.constructor?.name === 'AsyncFunction') {
          await onFileChange?.(resourceUrl);
        } else {
          onFileChange?.(resourceUrl);
        }

        setIsUploading(false);
      } catch (e) {
        // TODO error throw
        setIsUploading(false);
      }
    }
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    onDropAccepted: async (acceptedFiles) => {
      await _onFileChange(acceptedFiles as unknown as FileList);
    },
    onDropRejected: (rejected) => {
      const error = rejected?.[0]?.errors?.[0];
      if (error && onError) {
        if (error.code === ErrorCode.FileTooLarge) {
          onError({
            code: 'file-too-large',
            message: `The selected file exceeds maximum file size limit (${
              maxSize! / 1000000
            } Mb)`,
          });
        }
      }
    },
    accept: accept,
    maxSize: maxSize,
    multiple: false,
    noClick: true,
    noKeyboard: true,
    disabled: isUploading,
  });

  return (
    <Flex flexDir='column' gap={4}>
      <Flex
        w='full'
        flexDir='column'
        gap='2px'
        border='1px dashed #9A9FA5'
        borderRadius='11px'
        aria-disabled={isUploading}
        _disabled={{ opacity: 0.5, cursor: 'not-allowed' }}
        {...controlProps}
        _invalid={{
          borderColor: 'red.500',
          boxShadow: '0 0 0 1px #ff6a55',
        }}
      >
        <Box
          {...getRootProps()}
          height='164px'
          p={2}
          borderRadius='11px'
          aria-disabled={isUploading}
          _disabled={{ pointerEvents: 'none' }}
        >
          <input {...getInputProps()} />
          <Center h='full' flexDirection='column' gap={1}>
            <FontAwesomeIcon
              icon={faGif as IconProp}
              color='#6F767E'
              fontSize='1.65em'
            />

            <Box mt={2} fontWeight='500' fontSize='15px' color='#33383F'>
              <Trans
                t={t}
                i18nKey='nexus:documents.drapDropDocumentHere'
                components={{
                  span: (
                    <Button
                      variant='link'
                      colorScheme='blue'
                      fontSize='15px'
                      fontWeight='600'
                      onClick={open}
                    />
                  ),
                }}
              />
            </Box>
            <Box fontSize='12px' color='#6F767E'>
              {caption}
            </Box>
          </Center>
        </Box>

        <AssetLinkPaste
          placeholder={inputPlaceholder}
          isDisabled={isUploading}
          onLinkAdd={_onLinkChange}
        />
      </Flex>

      {isUploading && (
        <Flex flexDir='column' gap={3}>
          <Box fontSize='13px' fontWeight='500' color='#272B30'>
            Your file is uploading... Hang tight!
          </Box>

          <Progress
            size='sm'
            borderRadius='full'
            value={progress}
            colorScheme={progress >= 99 ? 'green' : 'blue'}
            isIndeterminate={progress < 1}
            hasStripe={isUploading}
            isAnimated={isUploading}
          />
        </Flex>
      )}
    </Flex>
  );
};

export default DropzoneInput;
