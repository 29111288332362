import React, { useEffect } from 'react';
import { Box, Image, Flex, VStack } from '@chakra-ui/react';
import MOUNTAIN_GIF from 'assets/images/mountain_gif.gif';
import { useTranslation } from 'react-i18next';
import { usePlayContext } from 'sub-components/training-v2/play/store/context';
import { useService } from 'sub-components/training-v2/play/layers';

const MilestoneContent: React.FC = () => {
  const {
    contents,
    isRetake,
    selectedIndex,
    selectedSubIndex,
    tpSessionData,
    updateTpSessionData,
  } = usePlayContext((state) => ({
    isRetake: state.isRetake,
    selectedIndex: state.selectedIndex,
    selectedSubIndex: state.selectedSubIndex,
    contents: state.tpSessionData?.contents || [],
    tpSessionData: state.tpSessionData,
    updateTpSessionData: state.updateTpSessionData,
  }));

  const service = useService();

  const selectedData = React.useMemo(() => {
    return contents?.[selectedIndex];
  }, [contents, selectedIndex, selectedSubIndex]);

  const handleCompletion = async (
    itemId: string,
    sessionId: string,
    trainingId: string
  ) => {
    const userProgress = tpSessionData?.userProgress;

    if (
      userProgress?.[0]?.progress?.some(
        (item) => item.id === itemId && item.isCompleted
      )
    ) {
      return;
    }
    await service.updateTPProgress
      .updateTPProgress({
        variables: {
          input: {
            isCompleted: true,
            itemId: itemId,
            sessionId: sessionId,
            trainingId: trainingId,
            responses: [],
          },
        },
      })
      ?.then((res) => {
        if (res?.data?.AddTpProgress?.eid && tpSessionData) {
          updateTpSessionData({
            ...tpSessionData,
            userProgress: res?.data?.AddTpProgress
              ? [res?.data?.AddTpProgress]
              : [],
          });
        }
      });
  };

  useEffect(() => {
    const currentContent = contents?.[selectedIndex];
    if (tpSessionData?.eid && tpSessionData?.trainingId && !isRetake) {
      handleCompletion(
        currentContent.eid,
        tpSessionData.eid,
        tpSessionData.trainingId
      );
    }
  }, [selectedIndex]);

  const { t } = useTranslation(['card', 'common', 'training']);

  return (
    <Box w='50%' h='100%' overflowY='auto'>
      <VStack spacing='4rem' py='4' minH='100%' justify='center'>
        <Flex fontSize='32px' fontWeight='600' textAlign='center'>
          {selectedData?.title || 'Milestone Name'}
        </Flex>
        <Image
          objectFit='contain'
          boxSize='400px'
          src={selectedData?.thumbnail || MOUNTAIN_GIF}
          alt='milestone-flag'
        />
      </VStack>
    </Box>
  );
};

export default MilestoneContent;
