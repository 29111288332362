import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { SearchInput } from '../../../../../../atoms';
import { getSxScrollStyles } from '../../common';
import BaseResourceFilter, { RFilterProps } from './BaseResourceFilter';

interface IProps extends RFilterProps {
  searchPlaceholder?: string;
  searchQuery?: string;
  onSearchChange?: (value: string) => void;
}

const BaseResourceContainer: FC<IProps> = ({
  children,
  searchPlaceholder,
  searchQuery,
  onSearchChange,
  filterValue,
  onFilterChange,
  filterOptions,
}) => {
  return (
    <Flex
      flex={1}
      gap={4}
      flexDir='column'
      maxHeight='full'
      maxWidth='calc(100% - 260px - 40px)'
    >
      <Flex gap={4}>
        <SearchInput
          size='lg'
          hideShortcuts
          placeholder={searchPlaceholder}
          value={searchQuery}
          onChange={(e) => onSearchChange?.(e.target.value)}
        />

        <BaseResourceFilter
          filterValue={filterValue}
          onFilterChange={onFilterChange}
          filterOptions={filterOptions}
        />
      </Flex>

      <Flex
        flexDir='column'
        gap={2}
        overflow='auto'
        paddingBottom={4}
        sx={getSxScrollStyles()}
      >
        {children}
      </Flex>
    </Flex>
  );
};

export default BaseResourceContainer;
