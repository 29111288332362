import React, { FC } from 'react';

const ProgressDrawer: FC = () => {
  return <></>;
};

ProgressDrawer.displayName =
  'displayName:sub-components/training-v2/play/components/ProgressDrawer/ProgressDrawer';

export default ProgressDrawer;
