import React, { useCallback, useRef } from 'react';
import { ApolloProvider, useApolloClient } from '@apollo/client';
import { useConfirm, UseConfirm } from '../../../../../../../ui-components';
import CreateLogsContent from './CreateLogsContent';

interface IProps {
  locationId: string;
  noteId?: string;
  refetchNoteData?: any;
  onSuccess?: () => void;
  onModalClose?: (wasDirty: boolean) => void;
}

type CreateLogsProps = (props: IProps) => void;

export const useCreateLogs = (): CreateLogsProps => {
  const confirm = useConfirm();
  const apolloClient = useApolloClient();
  const confirmRef = useRef<ReturnType<UseConfirm>>();

  return useCallback(
    ({ locationId, noteId, refetchNoteData, onSuccess, onModalClose }) => {
      if (confirmRef.current) {
        confirmRef.current.destroy();
      }

      confirmRef.current = confirm({
        Wrapper: ({ children }: any) => {
          return (
            <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
          );
        },
        content: (
          <CreateLogsContent
            locationId={locationId}
            noteId={noteId}
            refetchNoteData={refetchNoteData}
            onSuccess={onSuccess}
            onModalClose={onModalClose}
          />
        ),
        isCentered: true,
        contentProps: {
          maxW: '1200px',
          minHeight: '640px',
          borderRadius: '12px',
        },
        bodyProps: {
          padding: 0,
          display: 'flex',
        },
        scrollBehavior: 'inside',
        footer: null,
      });
    },
    []
  );
};
