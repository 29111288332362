import React, { FC } from 'react';
import { Flex, Text, Tooltip, Box, Image } from '@chakra-ui/react';
import openLocation from '../../../../../../assets/images/training/openLocationTypeIcon.svg';
import launchingLocation from '../../../../../../assets/images/training/launcherLocationIcon.svg';
export interface Location {
  status?: string;
  locationStatus?: string;
  userName?: string;
  name?: string;
}
interface LocationBadgeProps {
  locations: Location[];
  size?: string;
}

const LocationBadge: FC<LocationBadgeProps> = ({ locations, size }) => {
  if (!locations.length) {
    return <Text ml={4}>-</Text>;
  }

  const [firstLocation, ...otherLocations] = locations;
  const locationName = firstLocation.userName || firstLocation.name || '';

  return (
    <>
      <Flex
        display={'flex'}
        flexDirection={'row'}
        gap={1}
        alignItems={'center'}
        overflow='hidden'
      >
        <Image
          src={
            firstLocation.status || firstLocation.locationStatus !== 'open'
              ? openLocation
              : launchingLocation
          }
          height={size || '16px'}
          width={size || '16px'}
          flexShrink={0}
        />

        <Flex align='center' gap='6px' width='100%' overflow='hidden'>
          {otherLocations.length ? (
            <Text fontSize='10px' fontWeight='500' color='#6F767E'>
              {firstLocation.userName || firstLocation.name}
            </Text>
          ) : (
            <Text fontSize='10px' fontWeight='500' color='#6F767E'>
              {firstLocation.userName || firstLocation.name}
            </Text>
          )}

          {otherLocations.length > 0 && (
            <Tooltip
              label={
                <Box p={4} borderRadius='8px'>
                  {otherLocations.map((location, idx) => (
                    // @ts-ignore
                    <Flex
                      key={idx}
                      align='center'
                      gap='10px'
                      mb={idx !== otherLocations.length - 1 ? 2 : 0}
                    >
                      <Flex align='center' justify='center'>
                        <Image
                          src={
                            location.status ||
                            location.locationStatus !== 'open'
                              ? openLocation
                              : launchingLocation
                          }
                          height={'16px'}
                          width={'16px'}
                        />
                      </Flex>
                      <Text color='white' fontWeight={600} fontSize='12px'>
                        {location.userName || location.name}
                      </Text>
                    </Flex>
                  ))}
                </Box>
              }
              placement='bottom'
              hasArrow
            >
              <Box display='flex' gap={1}>
                <Text
                  as='span'
                  fontSize='10px'
                  fontWeight='500'
                  color='#6F767E'
                  cursor='pointer'
                  minW={'120px'}
                  textDecoration={'underline'}
                  whiteSpace='nowrap'
                >
                  +{otherLocations.length} more
                </Text>
              </Box>
            </Tooltip>
          )}
        </Flex>
      </Flex>
    </>
  );
};

export default LocationBadge;
