import { useCallback, useMemo } from 'react';
import { useMutation, useReactiveVar } from '@apollo/client';
import { CometChat } from '@cometchat-pro/chat';
import { AuthRole, userObj } from 'sop-commons/src/client';

import { ChannelConfigEntity } from '../../../../types';
import { usersEntityObj } from '../../../../sub-components/Header';
import { UserEntityData } from '../../../../shared/graphql/shared-types';

import { IFormInput } from './create-channel.types';
import { cleanConfig } from './create-channel.helpers';
import {
  ADD_GROUP_QUERY,
  AddGroupResponse,
  AddGroupVariable,
  GroupType,
} from './create-channel.graphql';

import { mapAddToChannel } from './format-data';
import { useJobRoleLocationList } from './select-group';
import { eventBus } from 'shared/eventEmit';

type ICreateChannel = (values: IFormInput) => Promise<CometChat.Group>;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGroupMemberPermission = () => {
  const _userLists = useReactiveVar(usersEntityObj);

  const users = useMemo(() => {
    return [..._userLists].reduce((acc, value) => {
      acc[value.eid] = value;
      return acc;
    }, {} as Record<string, UserEntityData>);
  }, [_userLists]);

  return useCallback(
    (memberId: string, config: ChannelConfigEntity) => {
      const authRole = users[memberId]?.authRole;

      if (authRole === AuthRole.SUPER_ADMIN) {
        return CometChat.GROUP_MEMBER_SCOPE.ADMIN;
      }

      if (config?.type === 'authRole') {
        if (authRole === AuthRole.WORKER) {
          return CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT;
        }

        if (config.members?.includes(memberId)) {
          return CometChat.GROUP_MEMBER_SCOPE.ADMIN;
        }
        // const authRole = users[memberId]?.authRole;
        if (authRole && config.authRoles?.includes(authRole)) {
          return CometChat.GROUP_MEMBER_SCOPE.ADMIN;
        }
        return CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT;
      }
      return CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT;
    },
    [users]
  );
};

export const useSubmitChannel = (): ICreateChannel => {
  const groupOptions = useJobRoleLocationList();

  const getGroupPermission = useGroupMemberPermission();

  const [addGroup] = useMutation<AddGroupResponse, AddGroupVariable>(
    ADD_GROUP_QUERY,
    {
      // onCompleted: (response) => {
      //   toast({
      //     status: 'success',
      //     title: t('common:success'),
      //     // @ts-ignore
      //     description: t('setting:channelCreateSuccess', {
      //       name: response.addNewDefaultChatGroup.name,
      //     }),
      //   });
      // },
      onError: (err) => {
        // eslint-disable-next-line no-console
        console.log('AddNewDefaultChatGroup ==> ', err);
      },
    }
  );

  return useCallback(
    async (values: IFormInput) => {
      const group = new CometChat.Group(
        'group_' + new Date().getTime(),
        values.groupName.trim(),
        values.isPublic
          ? CometChat.GROUP_TYPE.PUBLIC
          : CometChat.GROUP_TYPE.PRIVATE
      );
      group.setTags([userObj().entityId, 'default_group']);

      const inviteConfig = cleanConfig(values.inviteUser);
      group.setMetadata({
        postMessage: cleanConfig(values.postMessage),
        inviteUser: inviteConfig,
        channelCantLeave: values.channelCantLeave,
      } as never);

      await CometChat.createGroup(group);

      const channelData = mapAddToChannel(
        groupOptions,
        values.addToChannel,
        values.autoAddMember
      );

      if (values.assignedToUser?.length) {
        const members = values.assignedToUser.map(
          (member) =>
            new CometChat.GroupMember(
              member,
              getGroupPermission(member, inviteConfig)
            )
        );

        await CometChat.addMembersToGroup(group.getGuid(), members, []);
      }

      await addGroup({
        variables: {
          guid: group.getGuid(),
          name: group.getName(),
          type: group.getType() as GroupType,
          directMembers: values.assignedToUser || [],
          ...channelData,
        },
      });
      //refetch the chat group list
      eventBus.emit('group-created-refetch');
      return group;
    },
    [getGroupPermission]
  );
};
