import React, { FC } from 'react';
import { CloseButton, Flex } from '@chakra-ui/react';
import { UserEntityData } from '../../../../../../../shared/graphql/shared-types';

interface IProps {
  values: UserEntityData[];
  onRemoveClick?: (value: string) => void;
}

const SelectedMembers: FC<IProps> = ({ values, onRemoveClick }) => {
  return (
    <>
      {values.map((value) => (
        <Flex
          key={value.eid}
          gap='10px'
          align='center'
          justify='space-between'
          bg='#F4F4F4'
          paddingY='4px'
          paddingLeft='10px'
          paddingRight='6px'
          borderRadius='7px'
        >
          <span>{value.name}</span>
          {onRemoveClick ? (
            <CloseButton
              size='sm'
              onClick={(event) => {
                event.preventDefault();
                onRemoveClick?.(value.eid);
              }}
            />
          ) : null}
        </Flex>
      ))}
    </>
  );
};

export default SelectedMembers;
