import { Box, Center, Flex, Input, Text } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faGif } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { IEvidenceUploadSkeletonProps } from 'sub-components/training-v2/shared/types';

const EvidenceUploadSkeleton: FC<IEvidenceUploadSkeletonProps> = ({
  accept,
  descType,
  onBlur,
  onChange,
  onFileDrop,
  disabled,
}) => {
  const { t } = useTranslation(['card']);
  const [value, setValue] = useState('');
  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: onFileDrop,
    accept: accept,
    multiple: false,
    noClick: true,
    noKeyboard: true,
    disabled,
  });
  const handleInputBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    onBlur && onBlur(e);
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    onChange && onChange(e);
  };
  return (
    <Flex
      flexDir='column'
      borderRadius='13px'
      border='1px dashed #BBBBBB'
      w='full'
      cursor={disabled ? 'not-allowed' : 'pointer'}
      opacity={disabled ? '0.4' : '1'}
    >
      <Center
        display='flex'
        flexDir='column'
        p={8}
        w='full'
        {...getRootProps()}
        gap={2}
      >
        <input {...getInputProps()} />
        <FontAwesomeIcon icon={faGif as IconProp} color='#6F767E' size='2x' />
        <Flex>
          <Text fontWeight={400} fontSize='16px' color='#272B30'>
            {t('card:dropFiles')}
          </Text>
          <Text>&nbsp;</Text>
          <Text
            fontWeight={600}
            fontSize='16px'
            color='#2A85FF'
            cursor='pointer'
            onClick={open}
          >
            {t('card:browse')}
          </Text>
        </Flex>
        <Text color='#212121B2' fontWeight={400} fontSize='12px'>
          {descType === 'video' ? t('card:videoFiles') : t('card:imgFiles')}
        </Text>
      </Center>
      <Input
        value={value}
        _focus={{ boxShadow: 'none' }}
        placeholder={
          descType === 'video'
            ? t('card:videoUrlPlaceholder')
            : t('card:milestoneImgUrlPlaceholder')
        }
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        bg='#F9F9F9'
        border='none'
        borderTopLeftRadius='0px'
        borderTopRightRadius='0px'
        borderBottomLeftRadius='15px'
        borderBottomRightRadius='15px'
      />
    </Flex>
  );
};
EvidenceUploadSkeleton.displayName =
  'displayName:sub-components/training-v2/shared/quiz/components/responses/components/EvidenceUploadSkeleton.tsx';
export default EvidenceUploadSkeleton;
