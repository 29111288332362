import React, { ReactNode, useCallback, useRef } from 'react';
import { AlertDialogCloseButton, Box, Flex } from '@chakra-ui/react';
import { ApolloProvider, useApolloClient } from '@apollo/client';
import { useConfirm, UseConfirm } from '../../../../../../ui-components';
import { Any } from '../../../../../../types';

export interface RModalProps {
  title: string;
  content?: ReactNode;
}

type ResourceModal = (props: RModalProps) => void;

export const useResourceModal = (): ResourceModal => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();
  const apolloClient = useApolloClient();

  return useCallback((props) => {
    if (confirmRef.current) {
      confirmRef.current?.destroy();
    }

    confirmRef.current = confirm({
      Wrapper: ({ children }: Any) => {
        return (
          <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
        );
      },
      title: (
        <Flex gap='12px' justify='space-between'>
          <Box
            fontSize='20px'
            fontWeight='600'
            lineHeight='32px'
            color='#1a1d1f'
          >
            {props.title}
          </Box>

          <AlertDialogCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
          />
        </Flex>
      ),

      content: props.content,

      isCentered: true,
      headerProps: { paddingX: 7 },
      contentProps: {
        borderRadius: '8px',
        maxWidth: '1024px',
        height: { base: '100%', '2xl': 'unset' },
        minHeight: { base: 'unset', '2xl': '860px' },

        marginTop: 'auto',
        marginBottom: 'auto',

        maxHeight: 'calc(100vh - 6rem)',

        containerProps: {
          // alignItems: 'start',
          paddingTop: '3rem',
          paddingBottom: '3rem',
        },
      },
      bodyProps: {
        paddingX: 0,
        display: 'flex',
        overflow: 'hidden',
        paddingBottom: 0,
      },
      footer: null,
    });
  }, []);
};
