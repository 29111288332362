import React, { forwardRef } from 'react';
import { QuizIconButton } from '../common';
import { useAddImage } from './add-image';

import { ReactComponent as ImageIcon } from '../../../../../../../../assets/images/image.svg';

interface IProps {
  value?: string;
  onChange?: (value: string) => void;
}

const ThumbnailAddButton = forwardRef<never, IProps>(
  ({ value, onChange }, ref) => {
    const addImage = useAddImage();

    return (
      <QuizIconButton
        ref={ref}
        aria-label='add-image'
        variant='outline'
        borderRadius='4px'
        borderWidth='1px'
        icon={<ImageIcon width={12} height={12} />}
        onClick={() => addImage({ value: value, onAddClick: onChange })}
      />
    );
  }
);

ThumbnailAddButton.displayName = 'ThumbnailAddButton';

export default ThumbnailAddButton;
