import {
  Avatar,
  Badge,
  Box,
  Flex,
  HStack,
  Image,
  Text,
  Tooltip,
  useModalContext,
} from '@chakra-ui/react';
import { Table } from 'antd';
import { ColumnsType } from 'atoms';
import moment from 'moment';
import { FC } from 'react';
import crossTrain from 'assets/images/training-path-v2/crossTrain.svg';
import TrainingPathAction from '../TrainingPathAction';
import { getImage } from 'utils';
import { IOnViewActivityClick } from '../MemberAnalytics';

interface IProps {
  paths: any[];
  userName: string;
  onViewActivityClick: (data: IOnViewActivityClick) => void;
  refetchUserWiseAnalytics: () => void;
}

const PathsByMemberIdTable: FC<IProps> = ({
  paths,
  onViewActivityClick,
  userName,
  refetchUserWiseAnalytics,
}) => {
  const getDueStatus = (completedOn?: string, dueDate?: string): string => {
    if (!dueDate) return '-';

    const now = moment();
    const due = moment(dueDate);

    // Not completed
    if (!completedOn) {
      const diff = due.diff(now, 'days');
      if (diff > 0) return `Due in ${diff}d`;
      if (diff === 0) return 'Due today';
      return `Overdue by ${Math.abs(diff)}d`;
    }

    // Completed
    const completed = moment(completedOn);
    if (completed.isSameOrBefore(due, 'day')) {
      return `On time`;
    } else {
      const daysLate = completed.diff(due, 'days');
      return `Late by ${daysLate}d`;
    }
  };

  const { onClose } = useModalContext();

  const getCellStyle = (record: any) => {
    return record?.training?.status === 'TRAINING_TERMINATED'
      ? { opacity: 0.5, cursor: 'not-allowed' }
      : {};
  };

  const columns: ColumnsType = [
    {
      title: (
        <Flex gap={1} align='center'>
          <Text fontSize='12px' fontWeight={500} textColor={'#6F767E'}>
            Path name
          </Text>
        </Flex>
      ),
      dataIndex: 'title',
      key: 'title',
      width: '30%',
      render: (_, record) => {
        const hasCrossTrained = record?.assignedUsers?.[0]?.crossTrained;

        return (
          <Flex
            gap={3}
            align='center'
            position='relative'
            style={getCellStyle(record)}
            _hover={
              record?.training?.status === 'TRAINING_TERMINATED'
                ? {
                    _after: {
                      content: '"Marked as inactive"',
                      position: 'absolute',
                      right: '16px',
                      background: 'rgba(0, 0, 0, 0.75)',
                      color: 'white',
                      padding: '4px 8px',
                      borderRadius: '4px',
                    },
                  }
                : {}
            }
          >
            <Image
              height={'44px'}
              width={'44px'}
              borderRadius={'6px'}
              src={getImage(record?.training?.thumbnail, record.title)}
            />
            <Flex direction={'column'}>
              <Flex gap={2}>
                <Tooltip label={record.title}>
                  <Box
                    as='span'
                    fontWeight={600}
                    fontSize={'15px'}
                    color='#111315'
                    isTruncated
                    maxWidth={'160px'}
                  >
                    {record.title}
                  </Box>
                </Tooltip>

                {hasCrossTrained && (
                  <Tooltip label='Cross training path'>
                    <Image src={crossTrain} />
                  </Tooltip>
                )}
              </Flex>

              <Box as='span' color='#6F767E' fontSize={'12px'} fontWeight={500}>
                Last updated: {moment(record.updatedAt).format('DD MMMM')}
              </Box>
            </Flex>
          </Flex>
        );
      },
    },
    {
      title: (
        <Text fontSize='12px' fontWeight={500} textColor={'#6F767E'}>
          Status
        </Text>
      ),
      dataIndex: 'assignedUsers',
      key: 'assignedUsers',
      width: 450,
      render: (assignedUsers, record) => {
        let status = assignedUsers[0]?.status;
        const dueDate = assignedUsers[0]?.dueDate;
        const completedOn = assignedUsers[0]?.completedOn;

        if (status === 'started' || status === 'assigned') {
          status = 'pending';
        }

        if (
          dueDate &&
          moment(dueDate).isBefore(moment()) &&
          status !== 'completed'
        ) {
          status = 'overdue';
        }

        const dueStatus = getDueStatus(completedOn, dueDate);

        return (
          <Flex flexDirection='column' gap={2}>
            <Flex gap={2} align='center'>
              <Badge
                bg={status === 'completed' ? '#83BF6E33' : '#FFBC9933'}
                textColor={status === 'completed' ? '#83BF6E' : '#FF6A55'}
                fontWeight={700}
                fontSize={'12px'}
                textTransform='capitalize'
                px='8px'
                py='2px'
                borderRadius='4px'
              >
                {status}
              </Badge>
              <Box
                width='4px'
                height='4px'
                background='#6F767E'
                borderRadius='100%'
              ></Box>
              <Box as='span' color='#33383F'>
                {dueStatus}
              </Box>
            </Flex>
            {dueDate ? (
              <Box fontSize='12px' color='#6F767E'>
                Path deadline: {moment(dueDate).format('DD MMM YYYY')}
              </Box>
            ) : null}
          </Flex>
        );
      },
    },
    {
      title: (
        <Text fontSize='12px' fontWeight={500} textColor={'#6F767E'}>
          Progress
        </Text>
      ),
      dataIndex: 'contents',
      key: 'contents',
      align: 'center',
      width: 200,
      render: (_, record) => {
        const completedCount =
          record.userProgress?.[0]?.progress.filter((item) => item.isCompleted)
            .length || 0;
        const totalCount = _.length || 0;

        return (
          <Badge
            color='gray.600'
            fontSize='12px'
            fontWeight={500}
            bg='#EFEFEF'
            px={4}
            borderRadius='6px'
          >
            <HStack spacing={0.1}>
              <Text color='#1A1D1F' fontSize={'14px'}>
                {completedCount}
              </Text>
              <Text color='#1A1D1F' fontSize={'14px'}>
                /
              </Text>
              <Text color='#6F767E'>{totalCount}</Text>
            </HStack>
          </Badge>
        );
      },
    },
    {
      title: (
        <Text fontSize='12px' fontWeight={500} textColor={'#6F767E'}>
          Score
        </Text>
      ),
      dataIndex: 'contents',
      key: 'contents',
      align: 'center',
      width: 200,
      render: (_, record) => {
        return (
          <Text color={'#33383F'} fontSize={'14px'} fontWeight={500}>
            {record.userProgress?.[0]?.scorePercent
              ? Number(record.userProgress?.[0]?.scorePercent).toFixed(2)
              : '-'}
          </Text>
        );
      },
    },
    {
      title: (
        <Text fontSize='12px' fontWeight={500} textColor={'#6F767E'}>
          Action
        </Text>
      ),
      dataIndex: 'contents',
      key: 'contents',
      align: 'center',
      width: 200,
      render: (_, record) => {
        return (
          <TrainingPathAction
            onViewActivityClick={onViewActivityClick}
            userName={userName}
            sessionId={record.eid}
            tp={{
              training: record.training,
              endDate: record.endDate,
              assignedUser: record.assignedUsers?.[0],
              hasSkillVerifier: record.hasSkillVerifier,
              isRepeating: record.isRepeating,
              accessPermission: record.training.accessPermission,
              supervisors: record.supervisors,
              createdBy: record?.createdBy,
              status: record?.training?.status,
            }}
            onClose={onClose}
            onActionCompleted={refetchUserWiseAnalytics}
          />
        );
      },
    },
  ];

  return (
    <Box
      mt={5}
      border='2px solid #EFEFEF'
      borderRadius='lg'
      shadow='sm'
      maxHeight='70vh'
      overflow='scroll'
    >
      <Table
        columns={columns}
        dataSource={paths}
        rowKey='eid'
        pagination={false}
      />
    </Box>
  );
};

PathsByMemberIdTable.displayName =
  'displayName:sub-components/training-v2/dashboard/components/TrackPathDashboard/components/PathsByMemberIdTable.tsx';

export default PathsByMemberIdTable;
