import {
  Center,
  Box,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnsType } from 'antd/es/table';
import { Button, Table } from 'atoms';
import { HeaderColors } from 'configs';
import { FC, useState } from 'react';
import { IFinalOverallUserWiseAnalytics } from 'sub-components/training-v2/shared/types';
import { BoxHeader } from 'ui-components';
import { getImage } from 'utils';
import BaseListRender from './BaseListRender';
import RenderScoreCard from './RenderScoreCards';
import { ReactComponent as Sort_ascendingIcon } from 'assets/images/nexus/compliance/sort_ascending.svg';
import EmptyState from 'sub-components/EmptyState';

interface IProps {
  tab?: string;
  finalMemberData?: any[];
}

const MemberList: FC<IProps> = ({ tab, finalMemberData }) => {
  const [overallUserWiseData, setOverallUserWiseData] = useState<
    IFinalOverallUserWiseAnalytics[]
  >([]);

  const { isOpen, onClose, onOpen } = useDisclosure();

  const columns: ColumnsType<any> = [
    {
      title: 'Rank',
      dataIndex: 'allUserRank',
      key: 'allUserRank',
      sorter: (a, b) => {
        if (
          typeof a?.allUserRank === 'number' &&
          typeof b?.allUserRank === 'number'
        ) {
          return a.allUserRank - b.allUserRank;
        }
        return String(a?.allUserRank).localeCompare(String(b?.allUserRank));
      },
      sortIcon: ({ sortOrder }) => <Sort_ascendingIcon cursor={'pointer'} />,

      width: '20%',
      align: 'center',
      render: (data) => (
        <Flex as='span' fontWeight={500} justifyContent='center'>
          {data}
        </Flex>
      ),
    },
    {
      title: 'Members',
      dataIndex: 'name',
      key: 'name',
      width: '60%',
      render: (data, record) => (
        <Flex gap={2} alignItems='center'>
          <Image
            src={getImage(record?.profilePic, data)}
            width='6'
            height='6'
            borderRadius='5px'
          />
          <Text as='span' color='#111315' fontWeight={600} fontSize='14px'>
            {data}
          </Text>
        </Flex>
      ),
    },

    {
      title: 'Rate(%)',
      dataIndex: 'completionPercent',
      key: 'completionPercent',
      width: '25%',
      align: 'center',
      render: (data) => (
        <Flex color='#000000' fontWeight={500} gap={2} justifyContent='center'>
          {Number(data)?.toFixed(2)}
        </Flex>
      ),
    },
  ];

  return (
    <Flex
      width={'100%'}
      gap={7}
      flexDir={'column'}
      mt={'35px'}
      alignItems={'center'}
    >
      {finalMemberData?.length ? (
        <>
          <RenderScoreCard completeData={finalMemberData} />
          <BaseListRender
            tab={tab}
            overallUserWiseData={finalMemberData}
            emptyStateLabel='Other members performance will be shown here'
          />
          {(finalMemberData || [])?.length > 3 && (
            <Button
              variant='unstyled'
              color='#2A85FF'
              fontWeight={600}
              alignItems='center'
              gap={2}
              display='flex'
              onClick={onOpen}
            >
              View all
              <FontAwesomeIcon
                icon={faArrowRight as IconProp}
                fontSize='15px'
                color='#2A85FF'
              />
            </Button>
          )}

          <Modal isOpen={isOpen} onClose={onClose} isCentered size='2xl'>
            <ModalOverlay />
            <ModalContent padding='12px' borderRadius='12px'>
              <ModalHeader>
                <BoxHeader
                  title='Training completion rate'
                  color={HeaderColors.Yellow}
                />
              </ModalHeader>
              <ModalCloseButton top='1.5rem' right='1.5rem' fontSize='18px' />
              <ModalBody display='flex' flexDirection='column' gap={5}>
                <Text as='span' color='#272B30' fontWeight={600}>
                  All your members at a glance
                </Text>
                <Box overflowY={'auto'} maxHeight={'505px'}>
                  <Table
                    columns={columns}
                    dataSource={finalMemberData}
                    rowKey='id'
                    pagination={false}
                    bordered
                  />
                </Box>
              </ModalBody>
            </ModalContent>
          </Modal>
        </>
      ) : (
        <Center height='100%' width='100%'>
          <EmptyState image='Report' title='No data found' imageSize='300px' />
        </Center>
      )}
    </Flex>
  );
};

MemberList.displayName =
  'displayName:sub-components/training-v2/dashboard/components/myTrainings/topSection/MemberList';

export default MemberList;
