import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { FlexContainer, useEditDisabled } from '../common';
import { TrainingAssignee } from './Assignee';
import { TrainingSchedule } from './Schedule';
import { AddSkillVerifier } from './SkillVerifier';
import { TrainingSupervisor } from './Supervisor';
import AssigneeInfo from './Assignee/AssigneeInfo';
import SupervisorInfoContainer from './Supervisor/SupervisorInfoContainer';

const AssignPublishSection: FC = () => {
  const editDisabled = useEditDisabled();

  return (
    <Flex gap={6} alignItems='start'>
      <Flex flex={1} flexDir='column' gap={6}>
        <TrainingAssignee />
        <TrainingSchedule />
        <AddSkillVerifier disabled={editDisabled} />
        <TrainingSupervisor disabled={editDisabled} />
      </Flex>

      <FlexContainer
        flexDir='column'
        maxWidth='310px'
        position='sticky'
        top='0'
        p='26px 30px'
        gap={4}
      >
        <AssigneeInfo />
        <SupervisorInfoContainer />
      </FlexContainer>
    </Flex>
  );
};

export default AssignPublishSection;
