import {
  Box,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
} from '@chakra-ui/react';
import {
  faArrowDownToLine,
  faEllipsis,
} from '@fortawesome/pro-solid-svg-icons';
import { faEye, faFlag } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Dispatch, FC, useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ColumnsType, Table } from '../../../../../../../atoms';
import { getImage } from 'utils';
import { ProcessDataEntity, Punctuality } from '../../types';
import { EmptyState, Loader } from 'sub-components';
import { PageInfo } from 'types';
import { TablePaginationConfig } from 'antd';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import {
  AuditReportResponse,
  AuditReportVariable,
} from 'pages/Audits/Detail/graphql/common-audit-details.types';
import { GET_AUDIT_REPORT } from 'pages/Audits/Detail/graphql/common-audit-details.graphql';
import DownloadReport from 'sub-components/audits/common/download-report';
import { HeaderColors } from 'configs';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';
import { useLocationOps } from 'hooks';
import _ from 'lodash';

const AuditHistoryTable: FC<{
  data: ProcessDataEntity[];
  pageInfo: PageInfo;
  refetchHandler?: () => void;
  updatePageHandler: (newPage: number) => void;
}> = ({ data, pageInfo, refetchHandler, updatePageHandler }) => {
  const history = useHistory();
  const { getLocationDetailsById } = useLocationOps();
  const [sortConductedOnOrder, setSortConductedOnOrder] = useState<
    'ascend' | 'descend' | null
  >(null);
  const [sortAuditScoreOrder, setSortAuditScoreOrder] = useState<
    'ascend' | 'descend' | null
  >(null);

  const clickHandler = (
    type: string,
    data: ProcessDataEntity,
    selectedOption: any
  ) => {
    if (type === 'view') {
      history.push(
        `/audits/report/lI/${data?.locationId}/sI/${data?.item?.eid}`
      );
    }
  };

  const [getAuditReport, { loading }] = useMutation<
    AuditReportResponse,
    AuditReportVariable
  >(GET_AUDIT_REPORT);

  const getMenuData = useCallback((record: ProcessDataEntity) => {
    const menuList: Array<{ name: string; value: string; icon: JSX.Element }> =
      [
        {
          name: 'View Report',
          value: 'view',
          icon: <FontAwesomeIcon icon={faEye as IconProp} />,
        },
        {
          name: 'Download as PDF',
          value: 'download',
          icon: <FontAwesomeIcon icon={faArrowDownToLine as IconProp} />,
        },
      ];

    return menuList;
  }, []);

  const columns: ColumnsType<ProcessDataEntity> = [
    {
      title: () => <Box pl='20px'>Location</Box>,
      dataIndex: 'locationName',
      key: 'locationName',
      render: (text, record) => {
        if (record.isDateRange) {
          return {
            children: <strong>{record.dateRange}</strong>,
            props: {
              colSpan: 7,
              style: {
                backgroundColor: 'rgba(252, 252, 252, 1)',
                paddingLeft: '20px',
              },
            },
          };
        }
        return text;
      },
    },
    {
      title: 'Conducted on',
      dataIndex: 'conductedOn',
      key: 'conductedOn',
      render: (text, record) =>
        record.isDateRange ? (
          { props: { colSpan: 0 } }
        ) : (
          <Flex>
            {record?.punctuality === Punctuality.MISSED ? (
              <Box color={HeaderColors.Red.Mid}>Missed</Box>
            ) : (
              <Box>{record?.conductedOn}</Box>
            )}
          </Flex>
        ),
    },
    {
      title: 'Punctuality',
      dataIndex: 'punctuality',
      key: 'punctuality',
      render: (text, record) =>
        record.isDateRange ? (
          { props: { colSpan: 0 } }
        ) : (
          <Flex>
            {record?.punctuality === Punctuality.MISSED ? (
              <Box>-</Box>
            ) : (
              <Box>{record?.punctuality}</Box>
            )}
          </Flex>
        ),
    },
    {
      title: 'Audit Score',
      dataIndex: 'auditScore',
      key: 'auditScore',
      render: (text, record) =>
        record.isDateRange ? (
          { props: { colSpan: 0 } }
        ) : (
          <Flex>
            {record?.punctuality === Punctuality.MISSED ? (
              <Box>-</Box>
            ) : (
              <Box>{record?.auditScore}</Box>
            )}
          </Flex>
        ),
    },
    {
      title: 'Total Flags',
      dataIndex: 'totalFlags',
      key: 'totalFlags',
      render: (text, record) =>
        record.isDateRange ? (
          { props: { colSpan: 0 } }
        ) : record?.punctuality === Punctuality.MISSED ? (
          <Flex>
            <Box>-</Box>
          </Flex>
        ) : (
          <Flex align='center' gap='5px'>
            <Box as='span'>{text}</Box>
            <FontAwesomeIcon icon={faFlag as IconProp} />
          </Flex>
        ),
    },
    {
      title: 'Location Owner',
      dataIndex: 'locationOwner',
      key: 'locationOwner',
      render: (value, record) =>
        record.isDateRange ? (
          { props: { colSpan: 0 } }
        ) : value?.eid ? (
          <Flex gap='5px' align='center'>
            <Image
              src={getImage(value?.profilePic, value?.name)}
              boxSize='20px'
              borderRadius='50%'
              alt={value?.name || 'Profile'}
            />
            <Box as='span'>{value?.name}</Box>
          </Flex>
        ) : (
          <Box as='span'>-</Box>
        ),
    },
    {
      title: '',
      key: 'actions',
      dataIndex: 'actions',
      render: (_, record) =>
        record.isDateRange ? (
          { props: { colSpan: 0 } }
        ) : record.isCompleted ? (
          <Menu>
            <MenuButton>
              <FontAwesomeIcon icon={faEllipsis as IconProp} size='2x' />
            </MenuButton>
            <MenuList py={0} borderRadius='16px' p={4}>
              {getMenuData(record)?.map((menu, index) => {
                const isDownloadDisabled =
                  menu.value === 'download' && !record.isCompleted;
                return (
                  <MenuItem
                    key={index}
                    disabled={isDownloadDisabled}
                    _hover={{ borderRadius: '12px' }}
                    p='12px 21px'
                    onClick={(e) => {
                      if (menu.value === 'download') {
                        e?.preventDefault();
                        e?.stopPropagation();
                        return;
                      } else {
                        clickHandler(menu?.value, record, _);
                      }
                    }}
                  >
                    {menu?.value === 'view' ? (
                      <Flex align='center' gap={4} justify='flex-start'>
                        {menu?.icon}
                        <Box as='span' fontSize='15px' fontWeight={600}>
                          {menu.name}
                        </Box>
                      </Flex>
                    ) : (
                      <DownloadReport
                        locationId={record?.locationId || ''}
                        sessionId={record?.item?.eid || ''}
                        disabled={isDownloadDisabled}
                        callbackFn={() => {
                          deployEvent(
                            AmplitudeEventNames.DOWNLOAD_AUDIT_REPORT,
                            {
                              event: {
                                audit_id: record?.item?.eid,
                                location:
                                  getLocationDetailsById(
                                    record?.locationId || ''
                                  )?.name || '',
                                status: _.capitalize(record?.punctuality),
                                from_where: 'Audit History Page',
                              },
                            }
                          );
                          refetchHandler?.();
                        }}
                        loadingComp={
                          <Flex align='center' gap={4} justify='flex-start'>
                            <Loader />
                            <Box
                              as='span'
                              fontSize='15px'
                              fontWeight={600}
                              opacity='.5'
                            >
                              Downloading
                            </Box>
                          </Flex>
                        }
                        reportsUrl={record?.reportsUrl}
                      >
                        <Flex
                          align='center'
                          gap={4}
                          justify='flex-start'
                          opacity={isDownloadDisabled ? '.5' : '1'}
                        >
                          {menu?.icon}
                          <Box as='span' fontSize='15px' fontWeight={600}>
                            {menu.name}
                          </Box>
                        </Flex>
                      </DownloadReport>
                    )}
                  </MenuItem>
                );
              })}
            </MenuList>
          </Menu>
        ) : (
          <Box boxSize='30px' />
        ),
    },
  ];

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, any>,
    sorter: any
  ) => {
    if (sorter.columnKey === 'conductedOn') {
      setSortConductedOnOrder(sorter.order);
      setSortAuditScoreOrder(null); // Reset other sort
    } else if (sorter.columnKey === 'auditScore') {
      setSortAuditScoreOrder(sorter.order);
      setSortConductedOnOrder(null); // Reset other sort
    } else {
      setSortConductedOnOrder(null);
      setSortAuditScoreOrder(null);
    }
  };

  const extractScore = (auditScore: string): number => {
    const match = auditScore.match(/^(\d+(\.\d+)?)%/);
    if (match) {
      return parseFloat(match[1]);
    }
    return 0;
  };

  const sortedData = useMemo(() => {
    if (!data) return [];

    return data.map((parent) => {
      if (parent.isDateRange && parent.children) {
        let sortedChildren = [...parent.children];
        if (sortConductedOnOrder) {
          sortedChildren.sort((a, b) => {
            const aTime = moment(a.conductedOn).unix();
            const bTime = moment(b.conductedOn).unix();
            return sortConductedOnOrder === 'ascend'
              ? aTime - bTime
              : bTime - aTime;
          });
        } else if (sortAuditScoreOrder) {
          sortedChildren.sort((a, b) => {
            const aScore = extractScore(a.auditScore || '0');
            const bScore = extractScore(b.auditScore || '0');
            return sortAuditScoreOrder === 'ascend'
              ? aScore - bScore
              : bScore - aScore;
          });
        }
        return {
          ...parent,
          children: sortedChildren,
        };
      }
      return parent;
    });
  }, [data, sortConductedOnOrder, sortAuditScoreOrder]);

  const paginationConfig: TablePaginationConfig = {
    current: pageInfo.currentPage,
    pageSize: pageInfo.perPage,
    total: pageInfo.itemCount,
    hideOnSinglePage: true,
    onChange: (page, pageSize) => updatePageHandler(page),
    //   onShowSizeChange: (current, size) => {
    //     setPageInfo(prev => ({
    //       ...prev,
    //       currentPage: 1, // Reset to first page when page size changes
    //       perPage: size,
    //     }));
    //   },
  };

  const parentKeys = useMemo(() => {
    return data
      .filter((record) => record.isDateRange)
      .map((record) => `${record.locationId}-${record.dateRange}`);
  }, [data]);

  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={paginationConfig}
      rowKey={(record) =>
        record.isDateRange
          ? `${record.locationId}-${record.dateRange}`
          : record.item?.eid ||
            `${record.locationId}-${record.dateRange}-${record.locationId}`
      }
      expandable={{
        expandedRowKeys: parentKeys,
        onExpand: () => {},
        expandIcon: () => null,
        rowExpandable: (record) => record.isDateRange,
      }}
      onChange={handleTableChange}
      emptyState={
        <EmptyState
          image='MyTask'
          title='No Audit Data Available'
          description='Easily monitor and track audit history for each location from here.'
        />
      }
    />
  );
};

AuditHistoryTable.displayName =
  'pages/Audits/Detail/components/AuditHistory/components/AuditHistoryTable';

export default AuditHistoryTable;
